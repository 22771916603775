import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import type {PromptCryptoToReceiveContainerProps} from '../../../../../CommonNavigationContainers/hooks/usePromptCryptoToReceiveContainer';
import {usePromptCryptoToReceiveContainer} from '../../../../../CommonNavigationContainers/hooks/usePromptCryptoToReceiveContainer';
import {useTabBarLayout} from '../../../../../components';
import {SelectionForReceiveScreen} from '../../../../../screens/SelectionForReceiveScreen';
import type {SmallBottomTabBindingProps} from '../SmallBottomTabBindingProps';

export type PromptCryptoToReceiveBindingProps =
  SmallBottomTabBindingProps<'PromptCryptoToReceive'>;

export default observer(function PromptCryptoToReceiveBinding(
  props: PromptCryptoToReceiveBindingProps,
) {
  const {navigation} = props;
  const containerProps = useMemo<PromptCryptoToReceiveContainerProps>(
    () => ({
      params: props.route.params,
      // eslint-disable-next-line @typescript-eslint/unbound-method
      setParams: navigation.setParams,
      goBack: () => {
        navigation.navigate('ListWallets');
      },
      promptNewWallet: params => {
        navigation.navigate('PromptNewWallet', params);
      },
      showQrToReceiveCrypto: params => {
        navigation.navigate('ShowQrToReceiveCrypto', params);
      },
    }),
    [navigation, props.route.params],
  );

  const screenProps = usePromptCryptoToReceiveContainer(containerProps);

  const layout = useTabBarLayout();
  return (
    <SelectionForReceiveScreen
      {...screenProps}
      keyboardAvoiding
      extra={{bottom: layout.height}}
    />
  );
});
