import {variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui/src';
import {HistorySvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type EmptyHistoryListProps = {
  title: string;
  description: string;
};

export const EmptyHistoryList = observer(
  ({title, description}: EmptyHistoryListProps) => {
    return (
      <Container>
        <Block>
          <HistorySvg />
          <Title>{title}</Title>
          <HistoryText>{description}</HistoryText>
        </Block>
      </Container>
    );
  },
);

const Container = variance(View)(theme => ({
  root: {
    flex: 1,
    paddingBottom: 49,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingBottom: 0,
        backgroundColor: theme.palette.uiPrimary,
        borderRadius: 8,
        borderColor: theme.palette.uiSecondary,
      },
    }),
  },
}));

const Block = variance(View)(() => ({
  root: {
    marginTop: 'auto',
    marginBottom: 'auto',
    alignItems: 'center',
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 20,
    lineHeight: 24,
    marginTop: 20,
  },
}));

const HistoryText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional2,
    fontSize: 14,
    lineHeight: 17,
    marginTop: 15,
  },
}));
