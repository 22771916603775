import {useStrings, useTemplates, useTheme, variance} from '@ncwallet-app/core';
import {Button, Switch} from '@ncwallet-app/ui/src';
import {ArrowLeftWide} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Text, View} from 'react-native';

export type LgSendCommissionHeaderProps = {
  onBackPress: () => void;
  minFreeAmount: string | undefined;
  isEnoughAmountForFreeSend: boolean;
  getHasNoCommission: () => boolean;
  setHasNoCommission: (c: boolean) => void;
};

export default observer(function LgSendCommissionHeader(
  props: LgSendCommissionHeaderProps,
) {
  const theme = useTheme();
  const strings = useStrings();
  const templates = useTemplates();
  const switchHasNoCommission = useCallback(
    (prevValue: boolean) => {
      props.setHasNoCommission(!prevValue);
    },
    [props],
  );

  return (
    <>
      <Row>
        <BackButton
          title={strings['sendCrypto.commission.lgTitle']}
          onPress={props.onBackPress}
          Icon={ArrowLeftWide}
          iconCustomColor={theme.palette.uiAdditional1}
        />
      </Row>
      <Container>
        <SwitchContainer>
          <SwitchTitle>{strings['sendCrypto.comission']}</SwitchTitle>
          <Switch
            disabled={!props.isEnoughAmountForFreeSend}
            getIsActive={props.getHasNoCommission}
            onChange={switchHasNoCommission}
          />
        </SwitchContainer>
        <Info>
          {templates['sendCrypto.minerFee.info']({
            minFreeAmount: props.minFreeAmount ?? 0,
          })}
        </Info>
      </Container>
    </>
  );
});

const Info = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight(),
    color: theme.palette.textAdditional2,
    fontSize: 12,
    lineHeight: 18,
    paddingHorizontal: 15,
    paddingVertical: 10,
    flex: 1,
  },
}));

const SwitchTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 22,
    color: theme.palette.textPrimary,
    marginRight: 30,
  },
}));

const SwitchContainer = variance(View)(theme => ({
  root: {
    minWidth: 250,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderRightWidth: 1,
    borderRightColor: theme.palette.uiSecondary,
  },
}));

const Container = variance(View)(theme => ({
  root: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiPrimary,
    flexDirection: 'row',
    alignItems: 'stretch',
  },
}));

const Row = variance(View)(() => ({
  root: {
    marginBottom: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const BackButton = variance(Button)(() => ({
  root: {
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));
