import {action, computed, makeObservable, observable} from 'mobx';

import type {AppStateHelper} from '../AppStateHelper';
import type {LayoutHelperState} from '../LayoutHelperState';
import {Op} from '../Math';
import {expr} from '../mobx-toolbox';
import type {ReadonlyNavigationContainerState} from '../NavigationContainer';
import type {AddressNetwork} from '../NCWalletServer/AddressInfo';
import type {BlockchainNetwork} from '../NCWalletServer/BlockchainNetworkList';
import type {Service} from '../structure';
import type {Millisecond} from '../Time';
import type {TimeState} from '../TimeState';
import BlockchainNetworkModalStateService from './BlockchainNetworkModalStateService';
import type {BlockchainNetworksRepositoryState} from './BlockchainNetworksRepositoryState';
import type {BlockchainNetworksSuspensionState} from './BlockchainNetworksSuspensionState';
import type {BlockchainNetworksVisibilityState} from './BlockchainNetworksVisibilityState';

export default class BlockchainNetworksVisibilityStateService
  implements BlockchainNetworksVisibilityState, Service
{
  @observable.ref private _modal?: BlockchainNetwork;

  readonly modal;

  constructor(
    private readonly _root: {
      readonly blockchainNetworksRepositoryState: BlockchainNetworksRepositoryState;
      readonly blockchainNetworksSuspensionState: BlockchainNetworksSuspensionState;
      readonly timeState: TimeState;
      readonly navigationContainerState: ReadonlyNavigationContainerState;
      readonly appStateHelper: AppStateHelper;
      readonly layoutHelperState: LayoutHelperState;
    },
  ) {
    makeObservable(this);
    this.modal = new BlockchainNetworkModalStateService(this, _root);
  }

  @computed get networkToDisplay() {
    return this._modal;
  }

  getNetworkToDisplay(
    walletNetwork: AddressNetwork,
  ): BlockchainNetwork | undefined {
    if (this._modal?.wallet_network === walletNetwork) {
      return this._modal;
    }
    return undefined;
  }

  getPendingNetwork(
    walletNetwork: AddressNetwork,
  ): BlockchainNetwork | undefined {
    const {
      blockchainNetworksRepositoryState,
      blockchainNetworksSuspensionState,
    } = this._root;

    const {blockchainNetworksSuspensionQuery} =
      blockchainNetworksSuspensionState;

    if (!blockchainNetworksSuspensionQuery) {
      return undefined;
    }

    const $now = () => Op.add(this._root.timeState.getNow(INTERVAL), INTERVAL);

    const areSuspended = expr(() =>
      blockchainNetworksSuspensionQuery.areSuspended(walletNetwork, $now),
    );

    if (areSuspended) {
      return;
    }

    return blockchainNetworksRepositoryState.blockchainNetworkByWalletNetwork?.get(
      walletNetwork,
    );
  }

  async hideNetwork(walletNetwork: AddressNetwork, now: Millisecond) {
    const blockchainNetwork = this.getNetworkToDisplay(walletNetwork);
    if (!blockchainNetwork) {
      return;
    }
    await this._root.blockchainNetworksSuspensionState.suspendBlockchainNetworks(
      walletNetwork,
      now,
    );
    this._hideNetwork(blockchainNetwork.wallet_network);
  }

  @action async displayNetwork(
    blockchainNetwork: BlockchainNetwork,
  ): Promise<void> {
    if (this._modal === undefined) {
      this._modal = blockchainNetwork;
    }
  }

  @action private _hideNetwork(network: string) {
    if (this._modal?.wallet_network === network) {
      this._modal = undefined;
    }
  }

  subscribe() {
    return this.modal.subscribe();
  }
}

const INTERVAL = 10000 as Millisecond;
