import {fromDate, parseMillisecond} from '@ncwallet-app/core';
import type {LargeHomeStackParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeHomeStackParamList';
import type {LargeSwitchParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {PartialState} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';
import {findLast} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import CalendarModalScreen from '../../../screens/CalendarModalScreen';
import type {CalendarParams} from '../../../screens/CalendarModalScreen/CalendarModalScreen';
import type {LargeHomeStackBindingProps} from '../LargeHomeStackBindingProps';

export type PromptDateOfHistoryPeriodBindingProps =
  LargeHomeStackBindingProps<'PromptDateOfHistoryPeriod'>;

export default observer(function PromptDateOfHistoryPeriodBinding(
  props: PromptDateOfHistoryPeriodBindingProps,
) {
  const {navigation, route} = props;
  const type = route.params.isSourceRequested ? 'from' : 'to';
  const value = parseMillisecond(route.params.value);

  const handleBack = useCallback(() => {
    const state = navigation.getState();
    const rootRoute = findLast(state.routes, _ => _.name === 'Root');
    const previousRoute = rootRoute?.state?.routes[0];
    let previousParams;
    if (previousRoute?.name === 'PromptPeriodToFilterHistory') {
      previousParams = previousRoute.params;
    }

    const params = {
      ...previousParams,
      to: route.params.to,
      from: route.params.from,
    };

    const switchState: PartialState<NavigationState<LargeSwitchParamList>> = {
      index: 0,
      routes: [
        {
          name: 'PromptPeriodToFilterHistory',
          params,
        },
      ],
    };
    const homeStackState: PartialState<
      NavigationState<LargeHomeStackParamList>
    > = {index: 0, routes: [{name: 'Root', state: switchState}]};
    navigation.dispatch(CommonActions.reset(homeStackState));
  }, [navigation, route.params.to, route.params.from]);

  const handleApply = useCallback(
    (_date: CalendarParams) => {
      const date = new Date(_date.year, _date.month, _date.day);
      const state = navigation.getState();
      const rootRoute = findLast(state.routes, _ => _.name === 'Root');
      const previousRoute = rootRoute?.state?.routes[0];
      let previousParams;
      if (previousRoute?.name === 'PromptPeriodToFilterHistory') {
        previousParams = previousRoute.params;
      }

      const params = {
        ...previousParams,
        to: route.params.to,
        from: route.params.from,
      };

      if (
        (type === 'from' &&
          params.to !== undefined &&
          date.getTime() > params.to) ||
        (type === 'to' &&
          params.from !== undefined &&
          date.getTime() < params.from)
      ) {
        params[type] = type === 'from' ? params.to : params.from;
      } else {
        params[type] = fromDate(date);
      }

      const switchState: PartialState<NavigationState<LargeSwitchParamList>> = {
        index: 0,
        routes: [
          {
            name: 'PromptPeriodToFilterHistory',
            params,
          },
        ],
      };
      const homeStackState: PartialState<
        NavigationState<LargeHomeStackParamList>
      > = {index: 0, routes: [{name: 'Root', state: switchState}]};
      navigation.dispatch(CommonActions.reset(homeStackState));
    },
    [navigation, type, route.params.to, route.params.from],
  );

  return (
    <CalendarModalScreen
      value={value && new Date(value)}
      onBack={handleBack}
      onApply={handleApply}
    />
  );
});
