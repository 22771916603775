import type {
  PromptOtpToDeleteIpRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useDeleteIp} from '../../../CommonNavigationContainers/hooks/ipSettings/useDeleteIp';
import PromptToDeleteIpScreen from '../../../screens/PromptToDeleteIpScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {createResetToIpListAction} from '../util/createResetToIpListAction';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptToDeleteIpBindingProps =
  SmallHomeStackBindingProps<'PromptToDeleteIp'>;

export default observer(function PromptToDeleteIpBinding(
  props: PromptToDeleteIpBindingProps,
) {
  const {navigation, route} = props;
  const handleClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const goToOpt = useCallback(
    (params: RouteParams<PromptOtpToDeleteIpRoute>) => {
      navigation.replace('PromptOtpToDeleteIp', params);
    },
    [navigation],
  );

  const resetToIpList = useCallback(() => {
    const navigateTo =
      route.params.type === IpType.Allowed ? 'ListAllowedIp' : 'ListBlockedIp';
    if (hasRouteInHistory(navigation.getState(), navigateTo)) {
      navigation.navigate(navigateTo);
    } else {
      navigation.dispatch(createResetToIpListAction(route.params.type));
    }
  }, [navigation, route.params.type]);
  const {deleteIp} = useDeleteIp(goToOpt, resetToIpList);

  const handleConfirm = useCallback(
    () => deleteIp(route.params.id, route.params.type),
    [deleteIp, route.params.id, route.params.type],
  );

  return (
    <PromptToDeleteIpScreen
      ip={route.params.ip}
      type={route.params.type}
      onClose={handleClose}
      onConfirm={handleConfirm}
    />
  );
});
