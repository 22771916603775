import {useStyles, variance} from '@ncwallet-app/core';
import {Pressable, useIsDimensions} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {HistoryItemContainer} from '../HistoryScreen/SharedComponents';

export type HistoryFilterKindItemContainerProps = {
  onPress: () => void;
  children: React.ReactNode;
  wrap?: boolean;
};

export default observer(function HistoryFilterKindItemContainer({
  onPress,
  children,
  wrap,
}: HistoryFilterKindItemContainerProps) {
  const isLg = useIsDimensions('lg');
  const wrapperBorderStyles = useWrapperBorderStyles();
  if (isLg) {
    return (
      <Padding>
        <Wrapper
          borderStyle={wrapperBorderStyles.root}
          dataSet={{app: 'filter-item'}}
          onPress={onPress}
          wrap={wrap}>
          {children}
        </Wrapper>
      </Padding>
    );
  }

  return (
    <HistoryItemContainer onPress={onPress}>{children}</HistoryItemContainer>
  );
});

const Padding = variance(View)(() => ({
  root: {
    width: '50%',
    paddingHorizontal: 10,
  },
}));

const useWrapperBorderStyles = () =>
  useStyles(theme => ({
    root: {
      borderWidth: 1,
      borderRadius: 8,
      borderColor: theme.palette.uiSecondary,
    },
  }));

const Wrapper = variance(Pressable)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  wrap: {
    marginRight: 30,
  },
}));
