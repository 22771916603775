/* eslint-disable @typescript-eslint/unbound-method */
import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant, Input, InputVariant} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import type {AddressFormStateProps} from '../../shared/AccountDataForm/AddressFormState/useAddressFormState';
import {
  ADDRESS_INDEX_LENGTH,
  CITY_NAME_LENGTH,
  STREET_NAME_LENGTH,
  useAddressFormState,
} from '../../shared/AccountDataForm/AddressFormState/useAddressFormState';
import SelectCountry from '../../shared/SelectCountry';

export type AddressFormProps = {
  onCountrySelect: () => void;
} & AddressFormStateProps;

export default observer(function AddressForm(props: AddressFormProps) {
  const strings = useStrings();

  const {formState, handleSubmit} = useAddressFormState(props);
  return (
    <AddressFormHolder>
      <SelectCountry
        value={formState.country}
        onPress={props.onCountrySelect}
        variant={InputVariant.Default}
      />
      <SizedBox />
      <Input
        placeholder={strings['profileSettingsScreen.verification.address.city']}
        value={formState.city}
        onChangeText={formState.setCity}
        variant={InputVariant.Default}
        maxLength={CITY_NAME_LENGTH}
      />
      <SizedBox />
      <Input
        placeholder={
          strings['profileSettingsScreen.verification.address.street']
        }
        value={formState.street}
        onChangeText={formState.setStreet}
        variant={InputVariant.Default}
        maxLength={STREET_NAME_LENGTH}
      />
      <SizedBox />
      <Input
        placeholder={
          strings['profileSettingsScreen.verification.address.index']
        }
        value={formState.index}
        maxLength={ADDRESS_INDEX_LENGTH}
        onChangeText={formState.setIndex}
      />
      <Space />
      <FormButton
        title={strings['profileSettingsScreen.verification.address.submit']}
        variant={ButtonVariant.Primary}
        onPress={handleSubmit}
      />
    </AddressFormHolder>
  );
});

const AddressFormHolder = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 10,
  },
}));

const FormButton = variance(Button)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const Space = variance(View)(() => ({
  root: {
    height: 20,
    width: '100%',
    flexShrink: 0,
  },
}));
