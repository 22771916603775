import {useRoot} from '@ncwallet-app/core';
import type {AddressRedirectError} from '@ncwallet-app/core/src/AddressUriHelper';
import {
  shouldStoreAddressOnError,
  useGetAddressErrorText,
} from '@ncwallet-app/core/src/AddressUriHelper';
import {setStringAsync} from 'expo-clipboard';
import {useEffect} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useOnAddressUriRedirectErrorHandler = (
  address: string | undefined,
  error: AddressRedirectError | undefined,
  resetParams: () => void,
) => {
  const root = useRoot();

  const getAddressErrorText = useGetAddressErrorText();
  useEffect(() => {
    if (!error) {
      return;
    }

    resetParams();
    root.navigationContainer.ref?.resetRoot({
      index: 0,
      routes: [{name: 'Root'}],
    });
    const errorText = getAddressErrorText(error);
    root.flashMessage.showMessage({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      title: errorText!,
      variant: 'danger',
      timeout: 6000,
    });
    if (address && shouldStoreAddressOnError(error)) {
      void setStringAsync(address);
    }
  }, [address, error, getAddressErrorText, resetParams, root]);
};
