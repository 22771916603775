import {useLogButton, useRoot, useStrings} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {PendingRequestScreen} from '../../screens/PendingRequestScreen';
import type {AuthStackBindingProps} from './AuthStackBindingProps';

export type PendingConnectionBindingProps =
  AuthStackBindingProps<'PendingConnection'>;

export default observer(() => {
  const {connectionSpawner} = useRoot();
  const logButtonComponent = useLogButton();
  const onCancel = useCallback(() => {
    void connectionSpawner.giveUpConnection();
  }, [connectionSpawner]);
  const strings = useStrings();
  return (
    <PendingRequestScreen
      title={strings['waitingAuthScreen.pending']}
      cancelText={strings['waitingAuthScreen.cancelButton']}
      onCancel={onCancel}
      LogButton={logButtonComponent}
    />
  );
});
