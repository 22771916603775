import type {QrCodeHistoryEntry} from '@ncwallet-app/core';
import {
  colored,
  shortenWalletAddress,
  sized,
  useDateFormatting,
  useStrings,
  variance,
} from '@ncwallet-app/core';
import {CurrencyCircleLogo, TouchableOpacity} from '@ncwallet-app/ui';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type QrCodeHistoryListItemProps = {
  entry: QrCodeHistoryEntry;
  onPress: () => void;
  onRightRowPress: () => void;
};

export const QrCodeHistoryListItem = observer(
  (props: QrCodeHistoryListItemProps) => {
    const {entry, onPress, onRightRowPress} = props;
    const strings = useStrings();
    const {formatDateTimeWithoutYear} = useDateFormatting();

    return (
      <Root onPress={onPress}>
        <CurrencyCircleLogo
          code={entry.currency?.code ?? 'UNKNOWN'}
          size={40}
        />
        <CenterContainer>
          <DateTimeText>
            {formatDateTimeWithoutYear(entry.createdAt)}
          </DateTimeText>
          <CurrencyRow>
            <CurrencyNameText>
              {entry.currency?.name ?? strings['unknownCurrency.name']}
            </CurrencyNameText>
            {entry.currency && (
              <CurrencyCodeText> / {entry.currency.code}</CurrencyCodeText>
            )}
          </CurrencyRow>
        </CenterContainer>

        <RightRow>
          <AddressText>{shortenWalletAddress(entry.address)}</AddressText>
          <TouchableOpacity onPress={onRightRowPress} hitSlop={HIT_SLOP}>
            <ArrowRight />
          </TouchableOpacity>
        </RightRow>
      </Root>
    );
  },
);

const Root = variance(TouchableOpacity)(() => ({
  root: {
    padding: 15,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const CenterContainer = variance(View)(() => ({
  root: {
    flex: 1,
    marginHorizontal: 15,
  },
}));

const DateTimeText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight(),
    color: theme.palette.textAdditional2,
    fontSize: 12,
  },
}));

const CurrencyRow = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5,
  },
}));

const RightRow = variance(View)(() => ({
  root: {
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

const CurrencyNameText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textMain,
    fontSize: 16,
  },
}));

const CurrencyCodeText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textAdditional2,
    fontSize: 16,
  },
}));

const AddressText = variance(Text)(theme => ({
  root: {
    marginRight: 15,
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional1,
    fontSize: 12,
  },
}));

const ArrowRight = sized(colored(ArrowRightSvg, '#B3B3B3'), 9, 14);

const HIT_SLOP = {top: 20, bottom: 20, left: 20, right: 20};
