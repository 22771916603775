import type {Url} from '@ncwallet-app/core';
import {
  sized,
  urlLinks,
  useRoot,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {useKeyboardDismiss} from '@ncwallet-app/core/src/hooks';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {
  Button,
  ButtonVariant,
  CenteredCardLayout,
  LG_BREAKPOINT,
  ModalContainer,
  SafeAreaInset,
  SafeAreaScrollView,
  TouchableOpacity,
  useIsDimensions,
  VerificationCodeInput,
} from '@ncwallet-app/ui';
import {
  NCWalletLogoDarkSvg,
  NCWalletLogoSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {
  ArrowLeftWide,
  CrossSvg,
  FingerprintSvg,
  HelpSvg,
  LockSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {isEmpty} from 'lodash';
import {action, observable, reaction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {EXTRA_BOTTOM_OFFSET} from '../../constants';

export type SubmitOtpScreenProps =
  | ForcedSubmitOtpScreenProps
  | OptionalSubmitOtpScreenProps
  | SwitchOtpScreenProps;

export type ForcedSubmitOtpScreenProps = {
  layout: typeof FORCED;
  appVersion: string;
} & BaseSubmitOtpScreenProps;

export type OptionalSubmitOtpScreenProps = {
  layout?: typeof OPTIONAL | typeof CARD | typeof MODAL | undefined;
  appVersion?: string;
} & BaseSubmitOtpScreenProps;

export type SwitchOtpScreenProps = {
  layout: typeof SWITCH_SCREEN;
  backText: string;
} & BaseSubmitOtpScreenProps;

export type BaseSubmitOtpScreenProps = {
  getCode?: () => string | undefined;
  getError: () => string | undefined;
  resetError: () => void;
  titleText: string;
  submitText: string;
  twoFaProvider?: TwoFaProviderKind;
  resendCodeInterval?: number;
  resetInterval?: () => void;
  onTwoFaResendCode?: () => Promise<void>;
  onSubmit: (code: string) => void;
  onCancel?: () => void;
  onFaqHelpPress?: () => void;
  onExternalLinkPress?: () => void;
  showBiometricButton?: boolean;
  onBiometricPress?: () => void;
};

export const FORCED = Symbol();
export const OPTIONAL = Symbol();
export const CARD = Symbol();
export const MODAL = Symbol();
export const SWITCH_SCREEN = Symbol();

export default observer((props: SubmitOtpScreenProps) => {
  const {
    layout,
    getCode: _getCode,
    getError,
    resetError,
    titleText,
    onSubmit,
    twoFaProvider,
    onTwoFaResendCode,
    onExternalLinkPress,
    showBiometricButton,
    onBiometricPress,
    resetInterval,
    resendCodeInterval,
  } = props;
  const strings = useStrings();
  const [codeBox] = useState(() => observable.box(_getCode?.() ?? ''));
  const getCode = useCallback(() => codeBox.get(), [codeBox]);
  const setCode = useMemo(
    () =>
      action((code: string) => {
        codeBox.set(code);
        if (!isEmpty(getError())) {
          resetError();
        }
      }),
    [codeBox, getError, resetError],
  );
  const getPlaceholder = useCallback(() => '0'.repeat(CODE_LENGTH), []);
  const {location} = useRoot();

  useEffect(
    () =>
      reaction(
        () => _getCode?.(),
        code => {
          if (code !== undefined) {
            codeBox.set(code);
          }
        },
      ),
    [_getCode, codeBox],
  );

  const handleSubmit = useKeyboardDismiss(onSubmit);

  useEffect(
    () =>
      reaction(
        () => codeBox.get().length === CODE_LENGTH,
        isFulfilled => {
          if (isFulfilled) {
            handleSubmit(codeBox.get());
          }
        },
      ),
    [codeBox, handleSubmit],
  );

  const handleDefaultExternalLink = useCallback(async () => {
    switch (twoFaProvider) {
      case TwoFaProviderKind.Telegram:
        return location.open(urlLinks.incorrectTwoFaTg as Url);
      case TwoFaProviderKind.Akm:
        return location.open(urlLinks.incorrectTwoFaAKM as Url);
      default:
        return location.open(urlLinks.incorrectTwoFaGA as Url);
    }
  }, [twoFaProvider, location]);

  const title = (
    <>
      <TwoFactorText>
        {titleText}
        <HelpTouchable
          onPress={onExternalLinkPress ?? handleDefaultExternalLink}>
          <HelpIcon />
        </HelpTouchable>
      </TwoFactorText>
    </>
  );

  const input = useMemo(
    () => (
      <VerificationCodeInput
        maxLength={CODE_LENGTH}
        getPlaceholder={getPlaceholder}
        getValue={getCode}
        setValue={setCode}
        getError={getError}
        onExternalLinkPress={onExternalLinkPress ?? handleDefaultExternalLink}
      />
    ),
    [
      getCode,
      getError,
      getPlaceholder,
      onExternalLinkPress,
      setCode,
      handleDefaultExternalLink,
    ],
  );

  const biometricButton = useMemo(
    () =>
      showBiometricButton ? (
        <ResendButtonContainer small={false}>
          <Button
            Icon={FingerprintSvg}
            style={styles.biometryBtn}
            title={strings['twoFactorScreen.biometric']}
            variant={ButtonVariant.PrimaryLight}
            onPress={onBiometricPress}
          />
        </ResendButtonContainer>
      ) : null,
    [onBiometricPress, showBiometricButton, strings],
  );

  const [countdown, setCountdown] = useState(resendCodeInterval);
  const timer = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    setCountdown(resendCodeInterval);
    if (resendCodeInterval) {
      timer.current = setInterval(() => {
        setCountdown(prev => {
          if (prev && prev > 0) {
            return prev - 1;
          }
          resetInterval?.();
          clearInterval(timer.current);
          return 0;
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [resetInterval, resendCodeInterval]);

  const resendTelegramButton = useMemo(() => {
    const text = strings['twoFaPromptOtpScreen.submit'];
    const secondsText = strings['twoFaPromptOtpScreen.timeout'];
    const c = countdown ? `(${countdown}${secondsText})` : '';
    return twoFaProvider === TwoFaProviderKind.Telegram && onTwoFaResendCode ? (
      <ResendButtonContainer small={false}>
        <Button
          disabled={!!countdown}
          title={`${text} ${c}`}
          variant={ButtonVariant.PrimaryLight}
          onPress={onTwoFaResendCode}
        />
      </ResendButtonContainer>
    ) : null;
  }, [twoFaProvider, onTwoFaResendCode, countdown, strings]);

  const isLg = useIsDimensions('lg');
  const theme = useTheme();
  return layout === FORCED ? (
    <Root appVersion={props.appVersion} onBackPress={props.onCancel}>
      {!isLg && (
        <Header withLogout>
          <TouchableOpacity onPress={props.onCancel}>
            <Logout>{strings['twoFactorAuthScreen.logout']}</Logout>
          </TouchableOpacity>
          <AppVersion>{props.appVersion}</AppVersion>
        </Header>
      )}
      <SafeAreaScrollView
        insets={SafeAreaInset.BOTTOM}
        keyboardAvoiding
        extra={{bottom: EXTRA_BOTTOM_OFFSET}}
        keyboardVerticalOffset={50}>
        <TwoFactorLogoContainer>
          <NCWalletIcon />
          <LockIcon>
            <LockSvg color="#fff" width={15} height={17} />
          </LockIcon>
        </TwoFactorLogoContainer>
        {title}
        {input}
        {resendTelegramButton}
        {biometricButton}
      </SafeAreaScrollView>
    </Root>
  ) : layout === CARD ? (
    <SafeAreaScrollView
      insets={SafeAreaInset.BOTTOM}
      keyboardAvoiding
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      keyboardVerticalOffset={showBiometricButton ? 50 : 100}
      contentContainerStyle={isLg ? styles.contentZeroPadding : styles.content}>
      <Center>
        {title}
        {input}
      </Center>
      {resendTelegramButton}
      {biometricButton}
    </SafeAreaScrollView>
  ) : layout === MODAL ? (
    <Backdrop>
      <ModalCard>
        <ModalCardHeader>
          {titleText}
          <TouchableOpacity onPress={props.onCancel} hitSlop={HIT_SLOP}>
            <CrossIcon />
          </TouchableOpacity>
        </ModalCardHeader>
        <Center>{input}</Center>
      </ModalCard>
      {resendTelegramButton}
    </Backdrop>
  ) : layout === SWITCH_SCREEN ? (
    <Container>
      <Header>
        <BackButton
          title={props.backText}
          onPress={props.onCancel}
          Icon={ArrowLeftWide}
          iconCustomColor={theme.palette.uiAdditional1}
        />
      </Header>

      <SafeAreaScrollView
        insets={SafeAreaInset.BOTTOM}
        extra={{bottom: EXTRA_BOTTOM_OFFSET}}
        keyboardVerticalOffset={showBiometricButton ? 50 : 100}
        contentContainerStyle={
          isLg ? styles.contentZeroPadding : styles.content
        }>
        <Center>
          {title}
          {input}
          {resendTelegramButton}
          {biometricButton}
        </Center>
      </SafeAreaScrollView>
    </Container>
  ) : (
    <CenteredCardLayout appVersion={props.appVersion}>
      <SafeAreaScrollView
        insets={SafeAreaInset.BOTTOM}
        extra={{bottom: EXTRA_BOTTOM_OFFSET}}
        contentContainerStyle={styles.content}>
        <Center>
          {title}
          {input}
          {resendTelegramButton}
          {biometricButton}
        </Center>
      </SafeAreaScrollView>
    </CenteredCardLayout>
  );
});

export const CODE_LENGTH = 6;

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  contentZeroPadding: {
    paddingBottom: 0,
  },
  biometryBtn: {
    marginTop: 'auto',
  },
});

const TwoFactorText = variance(Text)(theme => ({
  root: {
    maxWidth: '95%',
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 18,
    ...theme.fontByWeight('600'),
    color: theme.palette.textPrimary,
    textAlign: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 18,
        lineHeight: 28,
      },
    }),
  },
}));

const Root = variance(CenteredCardLayout)(theme => ({
  root: {
    zIndex: 2,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
      },
    }),
  },
}));

const Logout = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textMain,
    fontSize: 15,
  },
}));

const AppVersion = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional2,
    fontSize: 13,
    textAlign: 'right',
  },
}));

const TwoFactorLogoContainer = variance(View)(theme => ({
  root: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 50,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingTop: 80,
      },
    }),
  },
}));

const NCWalletIcon = observer(() => {
  const {appearance} = useRoot();
  return appearance.isDark ? (
    <NCWalletLogoSvg width={85} height={85} />
  ) : (
    <NCWalletLogoDarkSvg width={85} height={85} />
  );
});

const LockIcon = variance(View)(theme => ({
  root: {
    position: 'absolute',
    right: -14,
    bottom: -10,
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 35,
    borderRadius: 100,
    borderWidth: 5,
    borderColor: theme.palette.background,
    backgroundColor: theme.palette.info,
  },
}));

const Center = variance(View)(() => ({
  root: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}));

const ModalCardHeader = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    display: 'flex',
    padding: 20,
    color: theme.palette.textPrimary,
    fontSize: 18,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const ModalCard = variance(View)(theme => ({
  root: {
    borderRadius: 8,
    height: 400,
    backgroundColor: theme.palette.background,
    width: 380,
  },
}));
const Backdrop = variance(ModalContainer)(theme => ({
  root: {
    backgroundColor: theme.chroma('#000000').alpha(0.7).hex(),
  },
}));

const CrossIcon = sized(CrossSvg, 22, 22);

const Container = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.background,
        paddingBottom: 0,
        borderRadius: 10,
        ...theme.bar(10),
      },
    }),
  },
}));
const Header = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 60,
    padding: 30,
  },
  withLogout: {
    padding: 0,
    minHeight: 'auto',
    marginTop: 30,
    marginBottom: 60,
  },
}));

const BackButton = variance(Button)(() => ({
  root: {
    marginBottom: 20,
    padding: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));

const ResendButtonContainer = variance(View)(() => ({
  root: {
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  small: {
    alignSelf: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 300,
  },
}));

const HelpTouchable = variance(TouchableOpacity)(() => ({
  root: {},
}));

const HelpIcon = variance(HelpSvg)(
  () => ({
    root: {
      position: 'relative',
      top: 3,
      marginLeft: 5,
    },
  }),
  theme => ({
    color: theme.palette.textAdditional3,
    width: 18,
    height: 18,
  }),
);

const HIT_SLOP = {top: 35, bottom: 35, left: 35, right: 35};
