import type {Maybe} from '../Maybe';
import type {BiometricSettings} from './BiometricSettings';
import type {BiometricSettingsManager} from './BiometricSettingsManager';

export default class StubBiometricSettingsManager
  implements BiometricSettingsManager
{
  hasTwoFaBiometrics = () => false;
  hasSignInBiometrics = () => false;
  addSignInBiometrics(): Promise<Maybe<BiometricSettings>> {
    throw new Error('not implemented');
  }

  addTwoFaBiometrics(): Promise<Maybe<BiometricSettings>> {
    throw new Error('not implemented');
  }

  removeSignInBiometrics(): Promise<Maybe<BiometricSettings>> {
    throw new Error('not implemented');
  }

  removeTwoFaBiometrics(): Promise<Maybe<BiometricSettings>> {
    throw new Error('not implemented');
  }

  removeAllBiometrics(): Promise<Maybe<BiometricSettings>> {
    throw new Error('not implemented');
  }
}
