import type {
  PromptFeedbackRoute,
  RouteTransition,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {PromptRateContainer} from '../../../CommonNavigationContainers/containers/PromptRateContainer';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptRateBindingProps = SmallHomeStackBindingProps<'PromptRate'>;

export default observer(function PromptRateBinding(
  props: PromptRateBindingProps,
) {
  const {navigation} = props;

  const getIsFocused = useNavigationGetIsFocused();

  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({index: 0, routes: [{name: 'ListMenuCategories'}]});
  }, [navigation]);

  const promptFeedback = useCallback<RouteTransition<PromptFeedbackRoute>>(
    params => {
      navigation.replace('PromptFeedback', params);
    },
    [navigation],
  );

  return (
    <PromptRateContainer
      goBack={goBack}
      promptFeedback={promptFeedback}
      getIsFocused={getIsFocused}
    />
  );
});
