import {range} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {ScrollView} from 'react-native-gesture-handler';

import WalletSkeleton from './WalletSkeleton';

type WalletListSkeletonProps = {
  itemCount?: number;
};

export default observer(function WalletListSkeleton(
  props: WalletListSkeletonProps,
) {
  const {itemCount = 1} = props;

  return (
    <ScrollView overScrollMode="never">
      {range(0, itemCount).map(n => (
        <WalletSkeleton key={n} />
      ))}
    </ScrollView>
  );
});
