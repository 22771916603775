import {useTheme} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Helmet} from 'react-helmet';

export default observer(function HeadThemeColor() {
  const theme = useTheme();
  return (
    <Helmet>
      <meta name="theme-color" content={theme.palette.background} />
    </Helmet>
  );
});
