import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {getVerificationProgress} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';

import useAccountVerificationBindingState from '../../../CommonNavigationContainers/hooks/AccountVerification';
import {useSmProfileSettingsBindingState} from '../../../Navigation/HomeStack/ProfileSettingsBinding/SmProfileSettingsBinding/useSmProfileSettingsBindingState';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import ProfileSettingsScreen from '../../../screens/ProfileSettingsScreen/ProfileSettingsScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ShowProfileBindingProps = SmallHomeStackBindingProps<'ShowProfile'>;

export default observer(function ShowProfileBinding(
  props: ShowProfileBindingProps,
) {
  const {navigation, route} = props;
  const state = useSmProfileSettingsBindingState();
  const verificationState = useAccountVerificationBindingState();
  const {personalData, addressData} = verificationState;
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh();
          });
        }
      }),
    [state, getIsFocused, getIsReady],
  );
  const goToUpdateNickname = useCallback(() => {
    navigation.navigate('PromptNickname');
  }, [navigation]);

  const goToUpdateUserId = useCallback(() => {
    navigation.navigate('PromptUserId');
  }, [navigation]);
  const goToUpdateEmail = useCallback(
    (value: string) => {
      navigation.navigate('PromptEmail', {email: value});
    },
    [navigation],
  );
  const goToUpdateBaseCrypt = useCallback(() => {
    navigation.navigate('PromptBaseCryptoSetting');
  }, [navigation]);
  const goToUpdateFiat = useCallback(() => {
    navigation.navigate('PromptBaseFiatSetting');
  }, [navigation]);

  const goToIdentity = useCallback(() => {
    navigation.navigate('ListPersonalData');
  }, [navigation]);

  const goToDelete = useCallback(() => {
    navigation.navigate('PromptAccountDeletion');
  }, [navigation]);

  const onLinkToEmailPress = useCallback(() => {
    navigation.navigate('PromptLinkToEmail');
  }, [navigation]);

  const onLinkToTelegramPress = useCallback(() => {
    navigation.navigate('PromptLinkToTelegram');
  }, [navigation]);
  const user = state.getUser();
  return (
    <ProfileSettingsScreen
      onDisplayNamePress={goToUpdateNickname}
      onUserIdPress={goToUpdateUserId}
      onEmailPress={goToUpdateEmail}
      onLinkToEmailPress={onLinkToEmailPress}
      onLinkToTelegramPress={onLinkToTelegramPress}
      onIdentityPress={goToIdentity}
      onBaseCryptoPress={goToUpdateBaseCrypt}
      onBaseFiatPress={goToUpdateFiat}
      getUser={state.getUser}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      onDeletePress={goToDelete}
      verificationProgress={
        user && verificationState.isLoaded
          ? getVerificationProgress(user, personalData, addressData)
          : undefined
      }
      params={route.params}
    />
  );
});
