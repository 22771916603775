import {
  sized,
  useStrings,
  useTemplates,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {LG_BREAKPOINT, Pressable} from '@ncwallet-app/ui';
import {WarningSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {HelpSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Linking, Text as RnText, View} from 'react-native';

export type ReceiveWarningProps = {
  isBitcoinDefaultNetwork: boolean;
  network: string;
};

export default observer(function ReceiveWarning({
  isBitcoinDefaultNetwork,
  network,
}: ReceiveWarningProps) {
  const onPress = useCallback(
    () =>
      Linking.openURL(
        'https://ncwallet.net/faq/which-blockchain-network-is-used-in-nc-wallet-for-withdrawing-and-receiving-cryptocurrency%3F-can-i-change-it-to-another-one/',
      ),
    [],
  );
  const strings = useStrings();
  const template = useTemplates();
  const theme = useTheme();
  return (
    <Container green={isBitcoinDefaultNetwork}>
      <Icon>
        <WarningIcon />
      </Icon>
      <TextContainer green={isBitcoinDefaultNetwork}>
        <Text>
          {isBitcoinDefaultNetwork
            ? strings['receiveCryptoScreen.warning.bitcoin.text']
            : template['receiveCryptoScreen.warning.text']({
                network: network,
              })}
        </Text>
        {!isBitcoinDefaultNetwork && (
          <HelpPress onPress={onPress}>
            <HelpIcon color={theme.palette.primary} />
          </HelpPress>
        )}
      </TextContainer>
    </Container>
  );
});

const HelpIcon = sized(HelpSvg, 17);
const WarningIcon = sized(WarningSvg, 22, 22);

const Container = variance(View)(theme => ({
  root: {
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'stretch',
    borderWidth: 1,
    borderColor: theme.palette.primary,
    backgroundColor: theme.palette.primary,
    // marginTop: 20,
    marginBottom: 10,
    marginHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 1,
        marginTop: 0,
        marginHorizontal: 0,
        marginBottom: 0,
      },
    }),
  },
  green: {
    borderColor: theme.palette.success,
    backgroundColor: theme.palette.success,
  },
}));

const Icon = variance(View)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 36,
    flexShrink: 0,
  },
}));

const TextContainer = variance(View)(theme => ({
  root: {
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: theme.palette.additional2,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  green: {
    backgroundColor: theme.palette.additional6,
  },
}));

const Text = variance(RnText)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 20,
    flex: 1,
    color: theme.palette.textSecondary,
  },
}));

const HelpPress = variance(Pressable)(() => ({
  root: {
    alignSelf: 'center',
    minWidth: 17,
    marginLeft: 'auto',
  },
}));
