import type {BaseSafeAreaProps} from '@ncwallet-app/ui/src';
import {SafeAreaScrollView} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';

import type {IdentityVerificationListProps} from './IdentityVerificationList';
import IdentityVerificationList from './IdentityVerificationList';

export type IdentityVerificationScreenProps = BaseSafeAreaProps &
  IdentityVerificationListProps;

export default observer(function IdentityVerificationScreen(
  props: IdentityVerificationScreenProps,
) {
  const {insets, extra, ...rest} = props;

  return (
    <>
      <SafeAreaScrollView insets={insets} extra={extra}>
        <IdentityVerificationList {...rest} />
      </SafeAreaScrollView>
    </>
  );
});
