// shorten address for user presentation, e.g.:
// Zdnw2R1uQHwPd4HVbMWfzpvjmDHwPd4HVbVbMWfzp -> Zdnw2...MWfzp
// eslint-disable-next-line import-x/prefer-default-export
export function shortenWalletAddress(
  address: string,
  shownCharNumberFromStartAndEnd = 5,
) {
  const separator = '...';
  if (address.length <= separator.length + shownCharNumberFromStartAndEnd * 2) {
    return address;
  } else {
    const startChars = address.slice(0, shownCharNumberFromStartAndEnd);
    const endChars = address.slice(
      address.length - shownCharNumberFromStartAndEnd,
    );
    return `${startChars}${separator}${endChars}`;
  }
}
