import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';

// eslint-disable-next-line import-x/prefer-default-export
export const filterCurrencySelectionItems = (
  items: CurrencySelectionListItemData[] | undefined,
  search: string = '',
) => {
  const trimmedSearch = search.trim().toLowerCase();
  return items?.filter(
    _ =>
      _.cryptoCurrency.name.toLowerCase().includes(trimmedSearch) ||
      _.cryptoCurrency.code.toLowerCase().includes(trimmedSearch),
  );
};
