import {variance} from '@ncwallet-app/core';
import {
  LG_BREAKPOINT,
  useIsDimensions,
  WalletActionList,
} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React from 'react';
import type {ViewProps} from 'react-native';
import {View} from 'react-native';

export type CurrencyListContainerProps = PropsWithChildren<ViewProps>;

export default observer(function CurrencyListContainer(
  props: CurrencyListContainerProps,
) {
  const {children, ...rest} = props;
  const isLg = useIsDimensions('lg');

  if (isLg) {
    return <LgListContainer {...rest}>{children}</LgListContainer>;
  }

  return <ListContainer {...rest}>{children}</ListContainer>;
});

const LgListContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    gap: 15,
  },
}));

const ListContainer = variance(WalletActionList)(theme => ({
  root: {
    borderTopWidth: 1,
    borderColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderTopWidth: 0,
      },
    }),
  },
}));
