import type {
  JsonRpcClient,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '@ncwallet-app/core';
import {unwrap} from '@ncwallet-app/core/src/EitherAdapter';
import type {Session} from '@ncwallet-app/core/src/NCWalletServer/sessions/Session';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {fromSession} from '../../../screens/SessionsHistoryScreen/HistorySessionInfo';

export default class SessionHistoryBindingState {
  @observable private _sessions: Session[] | undefined;
  private _total: number = 0;
  private _isPending: boolean = false;

  @computed get sessionHistory() {
    return this._sessions?.map(fromSession);
  }

  private _getLastItemTimestamp() {
    if (this._sessions) {
      return this._sessions[this._sessions.length - 1].created_at;
    } else {
      return undefined;
    }
  }

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {
    makeObservable(this);
  }

  getSessionHistory() {
    return this.sessionHistory;
  }

  @action.bound
  async refresh() {
    const sessionsRes = await unwrap(
      this._root.ncWalletJsonRpcClient.call('sessions.history.pagination', {
        limit: 50,
      }),
    );
    runInAction(() => {
      this._sessions = sessionsRes.items;
      this._total = sessionsRes.total;
    });
  }

  async onEndReached() {
    if (this._isPending) {
      return;
    }
    if (!this._getLastItemTimestamp()) {
      return;
    }
    if (this._total === 0) {
      return;
    }
    try {
      this._isPending = true;
      const lastCreatedAt = this._getLastItemTimestamp();
      const response = await unwrap(
        this._root.ncWalletJsonRpcClient.call('sessions.history.pagination', {
          limit: 20,
          last_ts: lastCreatedAt,
        }),
      );
      runInAction(() => {
        this._sessions?.push(...response.items);
        this._total = response.total;
      });
    } finally {
      this._isPending = false;
    }
  }
}
