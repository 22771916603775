import {isNil} from 'lodash';

import type {Tagged} from '../../Opaque';
import type {AccountDocumentFileInfo} from './AccountDocumentFileInfo';

export type AccountDocument = {
  id: AccountDocumentId;
  type: AccountDocumentType;
  status: AccountDocumentStatus;
  description?: string;
  files?: AccountDocumentFileInfo[];
  comment?: string;
  meta?: object;
};

export enum AccountDocumentStatus {
  Uploaded = 'uploaded',
  OnReview = 'on_review',
  Verified = 'verified',
  Failed = 'failed',
  Deleted = 'deleted',
}

export type AccountDocumentType = string;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCOUNT_DOCUMENT_ID = Symbol();
export type AccountDocumentId = Tagged<string, typeof ACCOUNT_DOCUMENT_ID>;

export const documentCanBeReviewed = (d: AccountDocument) =>
  d.status === AccountDocumentStatus.Uploaded ||
  d.status === AccountDocumentStatus.Failed;

const MAX_FILES_PER_DOCUMENT = 3;

export const fileCanBeUploadedForDocument = (d: AccountDocument) =>
  isNil(d.files) || d.files.length < MAX_FILES_PER_DOCUMENT;
