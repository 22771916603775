import {sized, useStrings, useTheme, variance} from '@ncwallet-app/core';
import {Input, InputVariant} from '@ncwallet-app/ui/src';
import {HelpSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Pressable, Text, View} from 'react-native';

import type {SendCommentProps} from './SendCommentInput';

export default observer(function LgSendCommentInput(props: SendCommentProps) {
  const theme = useTheme();
  const strings = useStrings();
  const Label = () => {
    return (
      <LabelView>
        {props.isBlockchainComment ? (
          <Text>{strings['BlockchainComment']}</Text>
        ) : (
          <>
            <Text>{strings['receiveCryptoScreen.commentFormLabel']}</Text>
            <Pressable onPress={props.goToFAQ}>
              <HelpIcon
                style={{marginLeft: 5}}
                color={theme.palette.uiAdditional1}
              />
            </Pressable>
          </>
        )}
      </LabelView>
    );
  };
  return (
    <Root wideStyle={props.wideStyle}>
      <Input
        ref={props.inputRef}
        value={props.value}
        label={<Label />}
        placeholder={strings['receiveCryptoScreen.commentFormPlaceholder']}
        placeholderTextColor={theme.palette.textAdditional3}
        showSoftInputOnFocus={props.showKeyboard}
        onFocus={props.onFocus}
        onChangeText={props.onInputChange}
        variant={props.error ? InputVariant.Error : InputVariant.Default}
      />
    </Root>
  );
});
const HelpIcon = sized(HelpSvg, 17);
const Root = variance(View)(() => ({
  root: {
    paddingVertical: 10,
    paddingHorizontal: 15,
  },

  wideStyle: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    flex: 1,
    marginLeft: 20,
  },
}));

const LabelView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
