import type {CryptoCurrencyCode, DecimalString} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useEffect, useState} from 'react';

import {BuyLinkBindingState} from './BuyLinkBindingState';

interface BuyLinkBindingStateProps {
  currency?: CryptoCurrencyCode;
  amount?: DecimalString;
}

// eslint-disable-next-line import-x/prefer-default-export
export const useBuyLinkBindingState = (props: BuyLinkBindingStateProps) => {
  const {amount, currency} = props;
  const root = useRoot();
  const [state] = useState(new BuyLinkBindingState(root));

  useEffect(() => {
    if (currency) {
      state.setCurrency(currency);
    }
  }, [amount, currency, state]);

  return state;
};
