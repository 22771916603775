import type {AccessToken, MultiFactorToken, RefreshToken} from '../../units';

export type AuthResult = DirectAuthResult | MultiFactorAuthResult;

export type DirectAuthResult = {
  access_token: AccessToken;
  refresh_token: RefreshToken;
};

export type MultiFactorAuthResult = {
  multi_factor_token: MultiFactorToken;
};

export const isMultiFactorResult = (
  _: AuthResult,
): _ is MultiFactorAuthResult =>
  Object.prototype.hasOwnProperty.call(_, 'multi_factor_token');

export const isDirectResult = (_: AuthResult): _ is DirectAuthResult =>
  Object.prototype.hasOwnProperty.call(_, 'access_token') &&
  Object.prototype.hasOwnProperty.call(_, 'refresh_token');
