import {configureBigNumber, extendDayjs} from '@ncwallet-app/core/src';
import {maybeCompleteAuthSession} from 'expo-web-browser';
import {initializeApp} from 'firebase/app';
import React from 'react';
import {createRoot} from 'react-dom/client';

import App from './App';
import {FIREBASE_CONFIG} from './Messaging';
import {config, Sentry} from './Sentry';

maybeCompleteAuthSession({
  skipRedirectCheck: true,
});

if (!__DEV__) {
  Sentry.init(config);
}

initializeApp(FIREBASE_CONFIG);

extendDayjs();
configureBigNumber();

const element = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(element!);
document.addEventListener('mousedown', e => {
  if (e.button === 1) {
    e.preventDefault();
  } else {
    return null;
  }
});
root.render(<App />);
