import {observer} from 'mobx-react-lite';
import type {ReactElement} from 'react';
import React from 'react';

import {SelectionCryptoTopMessage} from '../../atoms';
import CurrencySelectionList from './CurrencySelectionList';
import type {CurrencySelectionListItemData} from './CurrencySelectionListItemData';

export type FilteredToEmptyCurrencySelectionListProps = {
  allCurrencies: CurrencySelectionListItemData[] | undefined;
  refreshControl: ReactElement;
  onCurrencyPress: (currency: CurrencySelectionListItemData) => void;
};

export default observer(function FilteredToEmptyCurrencySelectionList(
  props: FilteredToEmptyCurrencySelectionListProps,
) {
  return (
    <>
      <SelectionCryptoTopMessage
        texts={{
          title: 'currencySelection.notFound.title',
          message: 'currencySelection.notFound.message',
        }}
      />

      <CurrencySelectionList
        onCurrencyPress={props.onCurrencyPress}
        currencies={props.allCurrencies}
        refreshControl={props.refreshControl}
      />
    </>
  );
});
