import type {CurrencyCode} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core';
import {useIsExchangeAllowed} from '@ncwallet-app/navigation/src/Navigation/hooks/useIsExchangeAllowed';
import {WalletActionKind} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';

import CurrencyListContainer from './CurrencyListContainer';
import CurrencyListItem from './CurrencyListItem';

export type CurrencyActionListProps = {
  currencyCode: CurrencyCode | undefined;
  onReceivePress: () => void;
  onSendPress: () => void;
  onBuyPress: () => void;
  onExchangePress: () => void;
  onHistoryPress: () => void;
  receiveEnabled: boolean;
  buyEnabled: boolean;
  sendEnabled: boolean;
  exchangeEnabled: boolean;
  currencyName?: string;
};

export default observer(function CurrencyActionList({
  currencyCode,
  onReceivePress,
  onSendPress,
  onBuyPress,
  onExchangePress,
  receiveEnabled,
  buyEnabled,
  sendEnabled,
  exchangeEnabled,
  onHistoryPress,
  currencyName,
}: CurrencyActionListProps) {
  const {isExchangeAllowed} = useIsExchangeAllowed();

  return (
    <Wrapper>
      <ListItem
        dataSet={{app: 'currency-item'}}
        disabled={!buyEnabled}
        type={WalletActionKind.Buy}
        onPress={onBuyPress}
        currencyName={currencyName}
      />
      <ListItem
        dataSet={{app: 'currency-item'}}
        disabled={!receiveEnabled}
        type={WalletActionKind.Receive}
        onPress={onReceivePress}
        currencyName={currencyName}
      />
      <ListItem
        dataSet={{app: 'currency-item'}}
        currencyCode={currencyCode}
        currencyName={currencyName}
        disabled={!sendEnabled}
        type={WalletActionKind.Send}
        onPress={onSendPress}
      />
      {isExchangeAllowed && (
        <ListItem
          dataSet={{app: 'currency-item'}}
          disabled={!exchangeEnabled}
          currencyName={currencyName}
          type={WalletActionKind.Exchange}
          onPress={onExchangePress}
        />
      )}
      <ListItem
        dataSet={{app: 'currency-item'}}
        type={WalletActionKind.History}
        onPress={onHistoryPress}
      />
    </Wrapper>
  );
});

const Wrapper = variance(CurrencyListContainer)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const ListItem = variance(CurrencyListItem)(() => ({
  root: {
    flexGrow: 1,
  },
}));
