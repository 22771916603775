import type {CurrenciesRateHistoryItem} from '@ncwallet-app/core';
import {getHistoryItemChange} from '@ncwallet-app/core';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';

import PriceDiff from '../../../../components/PriceDiff';

type PriceDiffViewProps = {
  getRateHistory: () => CurrenciesRateHistoryItem[] | undefined;
};
export default observer(function PriceDiffView(props: PriceDiffViewProps) {
  const rateHistory = props.getRateHistory();
  if (isNil(rateHistory)) {
    return null;
  }
  const diff = getHistoryItemChange(rateHistory, true);
  const firstPrice = rateHistory.length ? rateHistory[0].rate : '0';

  return <PriceDiff firstPrice={firstPrice} diff={diff} offsetTop />;
});
