import type {ISODateString, OtpCode} from '@ncwallet-app/core';
import {useRoot, useStrings} from '@ncwallet-app/core';
import dayjs from 'dayjs';
import {useCallback, useRef} from 'react';

import {useBaseErrorDescription} from '.';

export type PromptOtpToDeleteAccountContainerProps = {
  navigationReset: () => void;
};
export const usePromptOtpToDeleteAccountContainer = (
  props: PromptOtpToDeleteAccountContainerProps,
) => {
  const root = useRoot();
  const [getError, setError, resetError] = useBaseErrorDescription();
  const isSubmittingRef = useRef(false);
  const strings = useStrings();

  const onSubmit = useCallback(
    async (code: string) => {
      if (isSubmittingRef.current) {
        return;
      }
      isSubmittingRef.current = true;
      const res = await root.ncWalletJsonRpcClient.call('accounts.delete', {
        code_2fa: code as OtpCode,
        utc_2fa: dayjs().utc().toISOString() as ISODateString,
      });
      isSubmittingRef.current = false;
      if (res.success) {
        root.flashMessage.showMessage({
          title: strings['deleteAccount.success'],
          variant: 'success',
        });
        await root.authHelper.signOut({
          local: true,
          reason: 'usePromptOtpToDeleteAccountContainer',
        });
        props.navigationReset();
      } else {
        setError(res.left);
      }
    },
    [
      props,
      root.authHelper,
      root.flashMessage,
      root.ncWalletJsonRpcClient,
      setError,
      strings,
    ],
  );

  return {
    getError,
    resetError,
    onSubmit,
  };
};
