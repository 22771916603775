import {Alert, Linking, Platform} from 'react-native';

export enum Target {
  Blank = '_blank',
  Self = '_self',
  Parent = '_parent',
  Top = '_top',
}

export const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.ncwallet';
export const APP_STORE_LINK =
  'https://apps.apple.com/app/ncwallet/id1615381976';

export default async function openLink(url: string, target?: Target) {
  // Checking if the link is supported for links with custom URL scheme.
  const supported = await Linking.canOpenURL(url);

  if (supported) {
    // Opening the link with some app, if the URL scheme is "http" the web link should be opened
    // by some browser in the native
    if (Platform.OS === 'web') {
      if (target === undefined) {
        window.location.href = url;
      } else {
        window.open(url, target);
      }
    } else {
      await Linking.openURL(url);
    }
  } else {
    Alert.alert(`Don't know how to open this URL: ${url}`);
  }
}
