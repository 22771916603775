import {FULFILLED, useRoot} from '@ncwallet-app/core';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import SecuritySettingsScreen from '../../../screens/SecuritySettingsScreen/SecuritySettingsScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ListSecuritySettingsBindingProps =
  SmallHomeStackBindingProps<'ListSecuritySettings'>;

export default observer(function ListSecuritySettingsBinding(
  props: ListSecuritySettingsBindingProps,
) {
  const {navigation} = props;
  const root = useRoot();

  const goToSessionHistory = useCallback(() => {
    navigation.navigate('ListSessionHistory');
  }, [navigation]);

  const goToActiveSessions = useCallback(() => {
    navigation.navigate('ListActiveSessions');
  }, [navigation]);

  const goToAllowedIpList = useCallback(() => {
    navigation.navigate('ListAllowedIp');
  }, [navigation]);

  const goToBlockedIpList = useCallback(() => {
    navigation.navigate('ListBlockedIp');
  }, [navigation]);

  const onTwoFaSettingsPress = useCallback(() => {
    const {state} = root.accountStore;
    if (state?.status !== FULFILLED) {
      return;
    }
    if (state.result.tfa) {
      navigation.navigate('ShowTwoFaSettings');
    } else {
      navigation.navigate('TwoFaWelcome');
    }
  }, [navigation, root]);

  const onBiometricPress = useCallback(() => {
    navigation.navigate('PromptBiometricSettings');
  }, [navigation]);

  const onPinPress = useCallback(() => {
    navigation.navigate('CheckPin');
  }, [navigation]);

  return (
    <SecuritySettingsScreen
      onSessionHistoryPress={goToSessionHistory}
      onActiveSessionsPress={goToActiveSessions}
      onAllowedIpPress={goToAllowedIpList}
      onBlockedIpPress={goToBlockedIpList}
      onTwoFaSettingsPress={onTwoFaSettingsPress}
      onBiometricPress={onBiometricPress}
      onPinPress={onPinPress}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});
