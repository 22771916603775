import {useRoot} from '@ncwallet-app/core/';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptToDeleteDocumentFileScreen from '../../../screens/PromptToDeleteIdentityUploadedDocumentScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptToDeleteDocumentFileBindingProps =
  SmallHomeStackBindingProps<'PromptToDeleteDocumentFile'>;

export default observer(function PromptToDeleteDocumentFileBinding(
  props: PromptToDeleteDocumentFileBindingProps,
) {
  const {navigation, route} = props;
  const {documentId, file} = route.params;
  const {accountDocumentManager} = useRoot();

  const handleClose = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }

    navigation.replace('ListDocuments');
  }, [navigation]);

  const handleConfirm = useCallback(async () => {
    const res = await accountDocumentManager.deleteFiles(documentId, [file]);
    if (res.success) {
      handleClose();
    }

    if (!res.success) {
      navigation.replace('ListDocuments');
    }
  }, [navigation, accountDocumentManager, handleClose, documentId, file]);

  return (
    <PromptToDeleteDocumentFileScreen
      onConfirm={handleConfirm}
      onClose={handleClose}
    />
  );
});
