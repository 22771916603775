import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {ButtonVariant, useIsDimensions} from '@ncwallet-app/ui';
import {CopySvg, ShareSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {
  ActionButton,
  Divider,
  WalletAddressAction,
  WalletAddressActions,
  WalletAddressContainer,
  WalletAddressValue,
  WalletAddressValueContainer,
} from '../../../shared/WalletAddres';
import type {AffiliateTabKind} from './AffiliateKind';
import {useExtraAffiliateShareString} from './useExtraAffiliateShareString';

export type AffiliateProgramAddressProps = {
  onCopy: (data: string) => void;
  onShare: (data: string, secondaryString?: string) => void;
  appKind: AffiliateTabKind;
  info: string;
  shareShown?: boolean;
};

const LARGE_COPY_WIDTH = 51;
const LARGE_COPY_AND_SHARE_WIDTH = 101;

export default observer(function AffiliateProgramAddress(
  props: AffiliateProgramAddressProps,
) {
  const strings = useStrings();
  const theme = useTheme();
  const isLg = useIsDimensions('lg');
  const {onCopy, shareShown, onShare, info} = props;
  const extraShareString = useExtraAffiliateShareString(props.appKind);
  return (
    <>
      <WalletAddressContainer
        affiliate
        onPress={() => {
          onCopy(info);
        }}>
        <>
          <WalletAddressValueContainer>
            <WalletAddressValue numberOfLines={1} ellipsizeMode="tail">
              {info}
            </WalletAddressValue>
          </WalletAddressValueContainer>
          {isLg && (
            <WalletAddressActions
              style={{
                maxWidth: shareShown
                  ? LARGE_COPY_AND_SHARE_WIDTH
                  : LARGE_COPY_WIDTH,
              }}>
              <Divider />
              <WalletAddressAction
                onPress={() => {
                  onCopy(info);
                }}>
                <CopySvg color={theme.palette.info} />
              </WalletAddressAction>
              {shareShown && (
                <>
                  <Divider />
                  <WalletAddressAction
                    onPress={() => {
                      onShare(info, extraShareString);
                    }}>
                    <ShareSvg color={theme.palette.info} />
                  </WalletAddressAction>
                </>
              )}
            </WalletAddressActions>
          )}
        </>
      </WalletAddressContainer>
      {!isLg && (
        <Row>
          <ActionButton
            onPress={() => {
              onCopy(props.info);
            }}
            title={strings['receiveCryptoScreen.copy']}
            variant={ButtonVariant.PrimaryLight}
            Icon={CopySvg}
          />
          {shareShown && (
            <ActionButton
              onPress={() => {
                onShare(info, extraShareString);
              }}
              offsetLeft
              title={strings['receiveCryptoScreen.share']}
              variant={ButtonVariant.PrimaryLight}
              Icon={ShareSvg}
            />
          )}
        </Row>
      )}
    </>
  );
});

const Row = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    marginTop: 15,
  },
}));
