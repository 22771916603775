import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React, {useEffect} from 'react';
import {Platform} from 'react-native';

import type {Palette} from './Coloring';
import type {Theme} from './Theme';
import ThemeContext from './ThemeContext';

type ThemeProviderProps = {
  theme: Theme;
};

export default observer(function ThemeProvider(
  props: PropsWithChildren<ThemeProviderProps>,
) {
  const {theme, ...rest} = props;

  useEffect(() => {
    if (Platform.OS === 'web') {
      Object.keys(theme.palette).forEach(key => {
        document.documentElement.style.setProperty(
          `--${key}`,
          theme.palette[key as keyof Readonly<Palette>],
        );
      });
    }
  }, [theme]);

  return <ThemeContext.Provider value={theme} {...rest} />;
});
