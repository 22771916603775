import {PathImpl} from '../CommonNavigationScheme/Path';
import type {Url} from '../units';
import {first} from '../util';
import splitByPrefix from '../util/splitByPrefix';

export const EXTERNAL_REF = Symbol();

/**
 * @example com.example://?ref=x.x.x.x -> x.x.x.x
 * @example com.example://ref/x/x/x/x -> x/x/x/x
 * @example com.example://ref -> EXTERNAL_REF
 */
export default function parseInstallReferrer(
  _: Url,
): string | typeof EXTERNAL_REF | undefined {
  const split = splitByPrefix(_);
  if (split === undefined) {
    return undefined;
  }
  const [prefix, rest] = split;
  const path = PathImpl.parse(rest);
  const refParam = first(path.params?.[REF_SEGMENT]);
  if (typeof refParam === 'string' && refParam !== '') {
    return refParam;
  }
  const refPrefix = join(prefix, REF_SEGMENT);
  if (_.startsWith(refPrefix)) {
    const refRest = _.substring(refPrefix.length);
    const delimiter = '/';
    if (refRest.startsWith(delimiter)) {
      const refPath = refRest.substring(delimiter.length);
      return refPath === '' ? undefined : refPath;
    }
    return EXTERNAL_REF;
  }
  return undefined;
}

function join(prefix: Url, segment: string) {
  const delimiter = prefix.endsWith('/') ? '' : '/';
  return `${prefix}${delimiter}${segment}`;
}

const REF_SEGMENT = 'ref';
