import {useDateFormatting, useStrings, variance} from '@ncwallet-app/core';
import {DeviceIcon, Pressable} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import type {HistorySessionInfo} from '../../SessionsHistoryScreen/HistorySessionInfo';

export type SessionListHistoryItemProps = {
  session: HistorySessionInfo;
  isHighlighted?: boolean;
  single?: boolean;
  isFirst?: boolean;
};

export default observer(function SessionHistoryListItem(
  props: SessionListHistoryItemProps,
) {
  const {session, isHighlighted, single, isFirst} = props;
  const strings = useStrings();
  const {formatDateTimeWithYear} = useDateFormatting();
  const date = formatDateTimeWithYear(props.session.time);

  return (
    <SessionItemView
      isHighlighted={isHighlighted}
      single={single}
      isBorderVisible={!isFirst || !single}>
      <SessionItemIconView>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <DeviceIcon type={session.platform!} />
      </SessionItemIconView>
      <SessionItemBodyView>
        <SessionItemTop>
          <SessionItemRow>
            <SessionItemTime>{date}</SessionItemTime>
            {session.status === 'blocked' && (
              <Status>{strings['secureSettingsScreen.blocked']}</Status>
            )}
          </SessionItemRow>
          <SessionItemTitle numberOfLines={1}>{session.title}</SessionItemTitle>
        </SessionItemTop>
        <SessionItemInfo>{session.info}</SessionItemInfo>
      </SessionItemBodyView>
    </SessionItemView>
  );
});

const SessionItemView = variance(Pressable)(
  theme => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
      paddingRight: 15,
      borderBottomWidth: 1,
      borderColor: theme.palette.uiSecondary,
    },
    isHighlighted: {
      borderColor: theme.palette.additional2,
      backgroundColor: theme.palette.additional4,
    },
    single: {
      borderRadius: 8,
      borderWidth: 1,
    },
  }),
  theme => ({
    borderStyle: {
      borderBottomWidth: 1,
      borderColor: theme.palette.uiSecondary,
    },
  }),
);

const SessionItemRow = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Status = variance(Text)(theme => ({
  root: {
    marginLeft: 5,
    ...theme.fontByWeight('500'),
    fontSize: 11,
    lineHeight: 15,
    color: theme.palette.error,
  },
}));

const SessionItemIconView = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const SessionItemBodyView = variance(View)(() => ({
  root: {
    flex: 1,
    width: '100%',
    marginLeft: 15,
  },
}));

const SessionItemTop = variance(View)(() => ({
  root: {},
}));

const SessionItemTitle = variance(Text)(theme => ({
  root: {
    flex: 1,
    paddingRight: 10,
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 13,
  },
}));

const SessionItemInfo = variance(Text)(theme => ({
  root: {
    marginTop: 5,
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 12,
  },
}));

const SessionItemTime = variance(Text)(theme => ({
  root: {
    display: 'flex',
    ...theme.fontByWeight('500'),
    fontSize: 11,
    color: theme.palette.textAdditional2,
  },
}));
