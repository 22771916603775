import type {AccountDocument, AccountDocumentType} from './AccountDocument';

export type AccountsDocumentsUpload = {
  params: {
    document_type: string;
    filedata: string;
    filename?: string;
    description?: string;
  };
  result: AccountDocument;
};

export const toUploadedDocumentFile = (
  fileData: string,
  documentType: AccountDocumentType,
  filename?: string,
): AccountsDocumentsUpload['params'] => ({
  filedata: fileData,
  document_type: documentType,
  filename,
});
