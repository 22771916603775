import type {ParamListBase} from '@react-navigation/native';
import type {NavigationState, PartialState} from '@react-navigation/routers';
import {compact, findLast} from 'lodash';
import type {ValueOf} from 'type-fest';

import type {
  DistinctPartialRoute,
  ShallowCommonRouteMeta,
  ShallowCommonState,
} from '../CommonNavigationScheme';
import {
  DEBUG_ROUTE,
  kindToNameMap,
  LIST_ACTIVE_SESSIONS_ROUTE,
  LIST_DOCUMENTS_ROUTE,
  LIST_HISTORY_ROUTE,
  LIST_WALLETS_ROUTE,
  PROMPT_CRYPTO_TO_RECEIVE_ROUTE,
  PROMPT_CRYPTO_TO_SEND_ROUTE,
  PROMPT_SESSION_DELETION,
  PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE,
  SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE,
  SHOW_TRANSACTION_REPORT_ROUTE,
} from '../CommonNavigationScheme';
import {divideBy, last} from '../util';
import type {
  SmallBottomTabRouteMeta,
  SmallHomeStackRouteMeta,
} from './RouteGroup';
import type {SmallBottomTabParamList} from './SmallHomeStack/SmallBottomTab/SmallBottomTabParamList';
import type {SmallHomeStackParamList} from './SmallHomeStack/SmallHomeStackParamList';

export default function commonToSmall(
  common: ShallowCommonState,
): PartialState<NavigationState<SmallHomeStackParamList>> {
  const routes = divideBy<SmallBottomTabRouteMeta, SmallHomeStackRouteMeta>(
    common as (SmallBottomTabRouteMeta | SmallHomeStackRouteMeta)[],
    (_): _ is SmallBottomTabRouteMeta =>
      _.route.kind === LIST_WALLETS_ROUTE ||
      _.route.kind === DEBUG_ROUTE ||
      _.route.kind === PROMPT_CRYPTO_TO_RECEIVE_ROUTE ||
      _.route.kind === PROMPT_CRYPTO_TO_SEND_ROUTE ||
      _.route.kind === PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE ||
      _.route.kind === LIST_HISTORY_ROUTE,
  ).flatMap<DistinctPartialRoute<SmallHomeStackParamList>>(group => {
    if (group.isTarget) {
      const top = last(group.chunk);
      const wallets = findLast(
        group.chunk,
        _ => _.route.kind === LIST_WALLETS_ROUTE,
      );
      const receive = findLast(
        group.chunk,
        _ => _.route.kind === PROMPT_CRYPTO_TO_RECEIVE_ROUTE,
      );
      const exchange = findLast(
        group.chunk,
        _ => _.route.kind === PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE,
      );
      const send = findLast(
        group.chunk,
        _ => _.route.kind === PROMPT_CRYPTO_TO_SEND_ROUTE,
      );
      const history = findLast(
        group.chunk,
        _ => _.route.kind === LIST_HISTORY_ROUTE,
      );
      const debug = findLast(group.chunk, _ => _.route.kind === DEBUG_ROUTE);
      const tabs = compact([wallets, receive, exchange, send, history, debug]);
      const index = tabs.findIndex(_ => _.route.kind === top.route.kind);
      const state: PartialState<NavigationState<SmallBottomTabParamList>> = {
        index,
        routes: tabs.map(translateBottomTabRoute),
        routeNames: BOTTOM_TABS_IN_ORDER.slice(),
      };
      return [{name: 'Root', path: top.path?.toString(), state}];
    }
    return group.chunk.flatMap(translateHomeStackRoute);
  });
  return {
    index: routes.length - 1,
    routes,
  };
}

const BOTTOM_TABS_IN_ORDER = [
  'ListWallets',
  'PromptCryptoToReceive',
  'PromptSourceCryptoToExchange',
  'PromptCryptoToSend',
  'ListHistory',
  'Debug',
] as const;

function translateHomeStackRoute(
  meta: SmallHomeStackRouteMeta,
  index: number,
  state: SmallHomeStackRouteMeta[],
): DistinctPartialRoute<SmallHomeStackParamList>[] {
  const {route} = meta;
  const path = meta.path?.toString();
  switch (route.kind) {
    case SHOW_TRANSACTION_REPORT_ROUTE: {
      return [];
    }
    case LIST_DOCUMENTS_ROUTE: {
      return [{name: kindToNameMap[route.kind], path}];
    }
    case LIST_ACTIVE_SESSIONS_ROUTE: {
      const next = state.at(index + 1);
      const promptConfirmationToEndSession =
        next?.route.kind === PROMPT_SESSION_DELETION
          ? next.route.params
          : undefined;
      return [
        {
          name: kindToNameMap[route.kind],
          path,
          params: {promptConfirmationToEndSession},
        },
      ];
    }
    case SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE: {
      return [
        {
          name: kindToNameMap[route.kind],
          path,
          params: {...route.params},
        },
      ];
    }
  }
  return createStateFromMeta(meta);
}

function translateBottomTabRoute(
  meta: SmallBottomTabRouteMeta,
): DistinctPartialRoute<SmallBottomTabParamList> {
  return createStateFromMeta<SmallBottomTabParamList>(meta)[0];
}

function createStateFromMeta<T extends ParamListBase>(
  meta: ShallowCommonRouteMeta,
): DistinctPartialRoute<T>[] {
  const kind = meta.route.kind;
  const path = meta.path?.toString();
  const name: ValueOf<typeof kindToNameMap> | undefined =
    kindToNameMap[kind as keyof typeof kindToNameMap];
  if (Object.is(name, undefined)) {
    return [];
  }
  const _params = Reflect.get(meta.route, 'params') as object | undefined;
  const _state = Reflect.get(meta.route, 'state') as object | undefined;
  const params = _params || _state ? {..._params, ..._state} : undefined;
  const route = {name, path, params} as DistinctPartialRoute<T>;
  return [route];
}
