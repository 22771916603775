import {range} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {ScrollView} from 'react-native-gesture-handler';

import HistorySkeletonListItem from './HistorySkeletonListItem';

export type HistorySkeletonListProps = {
  itemsCount?: number;
};

export default observer(function HistorySkeletonList(
  props: HistorySkeletonListProps,
) {
  const {itemsCount = 4} = props;
  return (
    <ScrollView overScrollMode="never">
      {range(0, itemsCount).map(idx => (
        <HistorySkeletonListItem key={idx} />
      ))}
    </ScrollView>
  );
});
