import {MOCK_BITCOIN_CURRENCY, MOCK_USD_CURRENCY} from '@ncwallet-app/core';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {EXTRA_BOTTOM_OFFSET} from '../../screens/constants';
import {WalletType} from '../../screens/HistoryScreen/WalletIcon';
import type {HistoryWalletEntry} from '../../screens/HistoryWalletScreen';
import {HistoryWalletScreen} from '../../screens/HistoryWalletScreen';

export default observer(function PromptWalletGroupToFilterHistoryContainer() {
  return (
    <HistoryWalletScreen
      data={mockData}
      onPress={noop}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});

const mockData: HistoryWalletEntry = {
  all: {
    id: 1,
    walletType: WalletType.All,
    walletFiat: MOCK_USD_CURRENCY,
    walletFiatValue: 0.0,
    walletCurrencyValue: 0.00000321,
    walletCurrency: MOCK_BITCOIN_CURRENCY,
  },
  private: {
    id: 2,
    walletType: WalletType.Private,
    walletFiat: MOCK_USD_CURRENCY,
    walletFiatValue: 12.187,
    walletCurrencyValue: 0.38923701,
    walletCurrency: MOCK_BITCOIN_CURRENCY,
  },
  trading: {
    id: 3,
    walletType: WalletType.Trading,
    walletFiat: MOCK_USD_CURRENCY,
    walletFiatValue: 3.591,
    walletCurrencyValue: 0.38923701,
    walletCurrency: MOCK_BITCOIN_CURRENCY,
  },
};
