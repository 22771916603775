import type {Millisecond, Wallet} from '@ncwallet-app/core';
import {
  fromISODateString,
  getDirectionFromTransactionFilter,
  getTypeFromTransactionFilter,
  OPERATION_KIND_MAP,
  parseMillisecond,
  success,
  TIMEOUT_ERROR,
  toCurrencyDescriptionFromCrypto,
  useRoot,
} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {
  ListHistoryFiltersRoute,
  ListHistoryRoute,
  RouteParams,
  ShowTransactionRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {WalletTransactions} from '@ncwallet-app/core/src/WalletTransactionsRequestHelper';
import dayjs from 'dayjs';
import {autorun, observable, reaction, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useTabBarLayout} from '../../components';
import {
  useCryptoCurrencies,
  useNavigationGetIsFocused,
} from '../../Navigation/hooks';
import {HistoryScreen} from '../../screens/HistoryScreen';
import type {HistoryListItemData} from '../../screens/HistoryScreen/HistoryList/HistoryListItemData';

export type ListHistoryContainerProps = {
  params?: RouteParams<ListHistoryRoute>;
  listHistoryFilters: (params: RouteParams<ListHistoryFiltersRoute>) => void;
  showTransaction: (params: RouteParams<ShowTransactionRoute>) => void;
};

export default observer(function ListHistoryContainer(
  props: ListHistoryContainerProps,
) {
  const {params, listHistoryFilters, showTransaction} = props;
  const walletId = params?.walletId;
  const from = parseMillisecond(params?.from);
  const to = dayjs(parseMillisecond(params?.to))
    .endOf('day')
    .valueOf() as Millisecond;
  const transactionFilterKind = params?.transactionFilterKind;

  const [containerTimeoutErrorBox] = useState(() => observable.box(false));
  const [walletsBox] = useState(() => observable.box<Wallet[] | undefined>());
  const root = useRoot();

  const refreshWallets = useCallback(async () => {
    const wallets_ = await root.walletStore.refreshWallets();
    if (!wallets_.success) {
      if (wallets_.left.kind === TIMEOUT_ERROR) {
        containerTimeoutErrorBox.set(true);
      }
      return;
    }
    runInAction(() => {
      walletsBox.set(wallets_.right);
    });
  }, [containerTimeoutErrorBox, root.walletStore, walletsBox]);

  const getWalletIds = useCallback(async () => {
    if (walletId !== undefined) {
      return success(new Set([walletId]));
    }
    const wallets_ = await root.walletStore.refreshWallets();
    if (!wallets_.success) {
      return wallets_;
    }
    return success(new Set(wallets_.right.map(_ => _.id)));
  }, [root, walletId]);

  const goToFilters = useCallback(() => {
    listHistoryFilters({walletId, from, to, transactionFilterKind});
  }, [listHistoryFilters, walletId, from, to, transactionFilterKind]);

  const layout = useTabBarLayout();
  const extra = useMemo(() => ({bottom: layout.height}), [layout.height]);
  const {
    areCryptoCurrenciesLoading,
    getCryptoCurrency,
    loadCryptoCurrencies,
    timeoutErrorBox,
  } = useCryptoCurrencies();
  const [transactionsPendingBox] = useState(() => observable.box(false));
  const [transactionsBox] = useState(() =>
    observable.box<WalletTransactions.Aggregation | undefined>(),
  );
  const refresh = useCallback(async () => {
    if (transactionsPendingBox.get()) {
      return;
    }
    runInAction(() => {
      transactionsPendingBox.set(true);
    });
    try {
      const userOwned_ = await getWalletIds();
      if (!userOwned_.success) {
        if (userOwned_.left.kind === TIMEOUT_ERROR) {
          containerTimeoutErrorBox.set(true);
        }
        return;
      }
      const transactions_ = await root.walletTransactionsRequestHelper.getNext(
        userOwned_.right,
        transactionFilterKind &&
          getTypeFromTransactionFilter(transactionFilterKind),
        transactionFilterKind &&
          getDirectionFromTransactionFilter(transactionFilterKind),
        {
          from,
          to,
          limit: 20,
        },
      );
      if (!transactions_.success) {
        if (transactions_.left.kind === TIMEOUT_ERROR) {
          containerTimeoutErrorBox.set(true);
        }
        return;
      }
      runInAction(() => {
        transactionsBox.set(transactions_.right);
      });
    } finally {
      runInAction(() => {
        transactionsPendingBox.set(false);
      });
    }
  }, [
    transactionsPendingBox,
    getWalletIds,
    root.walletTransactionsRequestHelper,
    transactionFilterKind,
    from,
    to,
    containerTimeoutErrorBox,
    transactionsBox,
  ]);

  const getHistoryItems = useCallback((): HistoryListItemData[] | undefined => {
    return transactionsBox.get()?.items.map(_ => {
      const cryptoCurrency = getCryptoCurrency(_.currencyCode);
      const crypto =
        cryptoCurrency && toCurrencyDescriptionFromCrypto(cryptoCurrency);
      return {
        id: `${_.id}@${_.walletId}`,
        status: _.status,
        kind: OPERATION_KIND_MAP[_.kind],
        cryptoCode: _.currencyCode,
        cryptoName: crypto?.name,
        cryptoFractionDigits: crypto?.fractionDigits,
        cryptoValue: _.amount,
        uri: _.front_info?.icon,
        date: fromISODateString(_.createdAt),
      };
    });
  }, [getCryptoCurrency, transactionsBox]);

  const getIsRefreshing = useCallback(
    () =>
      expr(() => transactionsPendingBox.get() || areCryptoCurrenciesLoading()),
    [areCryptoCurrenciesLoading, transactionsPendingBox],
  );
  const getIsFocused = useNavigationGetIsFocused();

  const onEndReached = useCallback(async () => {
    if (transactionsPendingBox.get()) {
      return;
    }
    const current = transactionsBox.get();
    if (!current || current.items.length === 0 || current.remainingItems <= 0) {
      return;
    }
    runInAction(() => {
      transactionsPendingBox.set(true);
    });
    try {
      const lastCreatedAt = current.items[current.items.length - 1].createdAt;
      const userOwned_ = await getWalletIds();
      if (!userOwned_.success) {
        return;
      }

      const next_ = await root.walletTransactionsRequestHelper.getNext(
        userOwned_.right,
        transactionFilterKind &&
          getTypeFromTransactionFilter(transactionFilterKind),
        transactionFilterKind &&
          getDirectionFromTransactionFilter(transactionFilterKind),
        {
          from,
          to,
          limit: 20,
          lastCreatedAt,
        },
      );
      if (!next_.success) {
        return;
      }
      runInAction(() => {
        transactionsBox.set({
          items: current.items.concat(next_.right.items),
          remainingItems: next_.right.remainingItems,
        });
      });
    } finally {
      runInAction(() => {
        transactionsPendingBox.set(false);
      });
    }
  }, [
    transactionsPendingBox,
    transactionsBox,
    getWalletIds,
    transactionFilterKind,
    root,
    from,
    to,
  ]);
  const getCurrencyCode = useCallback(
    () => walletsBox.get()?.find(_ => _.id === walletId)?.currency,
    [walletId, walletsBox],
  );

  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          void runInAction(async () => {
            await refreshWallets();
            void refresh();
            void loadCryptoCurrencies();
          });
        }
      }),
    [
      getIsReady,
      getIsFocused,
      loadCryptoCurrencies,
      refresh,
      refreshWallets,
      walletId,
    ],
  );

  useEffect(
    () =>
      reaction(
        () => timeoutErrorBox.get() || containerTimeoutErrorBox.get(),
        shouldRequest => {
          if (shouldRequest) {
            timeoutErrorBox.set(false);
            containerTimeoutErrorBox.set(false);
            root.rpcTimeoutErrorVisibility.registerAction(async () => {
              await refreshWallets();
              await refresh();
              await loadCryptoCurrencies();
            });
          }
        },
      ),
    [
      loadCryptoCurrencies,
      root.rpcTimeoutErrorVisibility,
      timeoutErrorBox,
      containerTimeoutErrorBox,
      refreshWallets,
      refresh,
    ],
  );

  useEffect(() => {
    return root.ncWalletJsonRpcServer.notification(
      'event',
      (_params, response, next) => {
        if (
          _params.type === 'wallet_balance_update' ||
          _params.type === 'withdrawal_complete'
        ) {
          void refresh();
        }
        next();
      },
    );
  }, [root.ncWalletJsonRpcServer, refresh]);

  const handleItemPress = useCallback(
    (historyItem: HistoryListItemData) => {
      const [id] = historyItem.id.split('@');
      showTransaction({id});
    },
    [showTransaction],
  );

  return (
    <HistoryScreen
      onItemPress={handleItemPress}
      onFiltersPress={goToFilters}
      extra={extra}
      getIsRefreshing={getIsRefreshing}
      getHistoryItems={getHistoryItems}
      onRefresh={refresh}
      onEndReached={onEndReached}
      from={from}
      to={to}
      transactionFilterKind={transactionFilterKind}
      getCurrencyCode={getCurrencyCode}
    />
  );
});
