import type {CountryNameData} from '@ncwallet-app/core/src/Localization/constant';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {usePromptToSelectVerificationCountryBindingState} from '../../../CommonNavigationContainers/hooks/usePromptToSelectVerificationCountryBindingState';
import PromptToSelectVerificationCountryScreen from '../../../screens/PromptToSelectVerificationCountryScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptToSelectVerificationCountryBindingProps =
  SmallHomeStackBindingProps<'PromptIdentityVerificationAddress'>;

export default observer(function PromptToSelectVerificationCountryBinding(
  props: PromptToSelectVerificationCountryBindingProps,
) {
  const {navigation} = props;
  const state = usePromptToSelectVerificationCountryBindingState();

  const handleCountrySelect = useCallback(
    (country: CountryNameData) => {
      navigation.navigate('PromptResidentialAddress', {country: country.name});
    },
    [navigation],
  );

  const handleSearch = useCallback(
    (searchQuery: string) => {
      state.search(searchQuery);
    },
    [state],
  );

  return (
    <PromptToSelectVerificationCountryScreen
      countryList={state.countries}
      onSearch={handleSearch}
      searchQuery={state.searchQuery}
      onCountrySelect={handleCountrySelect}
    />
  );
});
