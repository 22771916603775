import chroma from 'chroma-js';

import type {UniversalColor} from './color';
import color from './color';

/**
 * Choose one of the two colors that contrasts the most with the target color.
 * @param _target
 * @param _foreground
 * @param _background
 * @return The most contrast color
 */
export default function getContrastColor<T extends UniversalColor>(
  _target: UniversalColor,
  _foreground: T,
  _background: T,
): T {
  const target = color(_target);
  const foreground = color(_foreground);
  const background = color(_background);
  return chroma.contrast(background, target) >
    chroma.contrast(foreground, target)
    ? _background
    : _foreground;
}
