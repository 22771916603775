import {
  error,
  FULFILLED,
  success,
  useRoot,
  useStrings,
} from '@ncwallet-app/core';
import type {
  CheckBiometricsPinRoute,
  PromptOtpToBiometricsRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransitionMap} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransitionMap';
import {useCallback} from 'react';
import {Linking, Platform} from 'react-native';

export type BiometricSettingsContainerProps = RouteTransitionMap<
  PromptOtpToBiometricsRoute | CheckBiometricsPinRoute
>;
export const usePromptBiometricSettingsContainer = (
  props: BiometricSettingsContainerProps,
) => {
  const {promptOtpToBiometrics, checkBiometricsPin} = props;
  const root = useRoot();
  const strings = useStrings();
  const showErrorFlashMessage = useCallback(
    (title: string, cb?: () => void) => {
      root.flashMessage.showMessage({
        title,
        variant: 'danger',
        onPress: cb,
      });
    },
    [root],
  );

  const checkAvailability = useCallback(async () => {
    const openSettings = () =>
      Platform.OS === 'ios'
        ? Linking.openURL('App-prefs:root=General')
        : Linking.sendIntent('android.settings.SETTINGS');
    const isSensorAvailable = await root.biometrics.isSensorAvailable();
    if (!isSensorAvailable) {
      showErrorFlashMessage(strings['biometricsError.setUp'], openSettings);
      return error(undefined);
    }

    const isEnrolled = await root.biometrics.isEnrolled();
    if (!isEnrolled) {
      showErrorFlashMessage(
        strings['biometricsError.notEnrolled'],
        openSettings,
      );
      return error(undefined);
    }
    const prompt = await root.biometrics.simplePrompt({
      promptMessage: strings['biometricsError.isRequired'],
      cancelButtonText: strings['promptBiometric.cancel'],
    });

    if (!prompt.success) {
      return error(undefined);
    }

    return success();
  }, [root, strings, showErrorFlashMessage]);

  const enableTwoFaBiometrics = useCallback(async () => {
    const isChangePossible = await checkAvailability();

    if (isChangePossible.success) {
      promptOtpToBiometrics();
    }
  }, [checkAvailability, promptOtpToBiometrics]);

  const hasTwoFaOnAccount = useCallback(() => {
    const {state} = root.accountStore;
    if (state?.status !== FULFILLED) {
      return;
    }

    return Boolean(state.result.tfa);
  }, [root]);

  const enableSignInBiometrics = useCallback(async () => {
    const isChangePossible = await checkAvailability();

    if (isChangePossible.success) {
      checkBiometricsPin();
    }
  }, [checkAvailability, checkBiometricsPin]);

  const toggleTwoFaBiometrics = useCallback(() => {
    if (!hasTwoFaOnAccount()) {
      showErrorFlashMessage(strings['errors.twoFaRequired']);
      return;
    }
    if (!root.biometricSettingsManager.hasTwoFaBiometrics()) {
      void enableTwoFaBiometrics();
    } else {
      void root.biometricSettingsManager.removeTwoFaBiometrics();
    }
  }, [
    strings,
    showErrorFlashMessage,
    hasTwoFaOnAccount,
    enableTwoFaBiometrics,
    root.biometricSettingsManager,
  ]);

  const toggleSignInBiometrics = useCallback(() => {
    if (!root.biometricSettingsManager.hasSignInBiometrics()) {
      void enableSignInBiometrics();
    } else {
      void root.biometricSettingsManager.removeSignInBiometrics();
    }
  }, [enableSignInBiometrics, root.biometricSettingsManager]);

  const hasSignInBiometrics = useCallback(
    () =>
      root.biometrics.localKeysExist &&
      root.biometricSettingsManager.hasSignInBiometrics(),
    [root.biometricSettingsManager, root.biometrics.localKeysExist],
  );

  const hasTwoFaBiometrics = useCallback(
    () =>
      root.biometrics.localKeysExist &&
      root.biometricSettingsManager.hasTwoFaBiometrics(),
    [root.biometricSettingsManager, root.biometrics.localKeysExist],
  );

  return {
    localKeysExist: root.biometrics.localKeysExist,
    hasSignInBiometrics,
    hasTwoFaBiometrics,
    toggleTwoFaBiometrics,
    toggleSignInBiometrics,
  };
};
