import type {BaseAsyncOptions} from '../Async';
import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import type {RouterSource} from '../structure';

export const TUNNEL_INCOMING_MESSAGE = Symbol();
export const TUNNEL_INCOMING_ERROR = Symbol();
export const TUNNEL_OUTGOING_MESSAGE = Symbol();
export const TUNNEL_OUTGOING_ERROR = Symbol();

export interface Tunnel<I, O> extends ReadonlyTunnel<I, O> {
  send(
    message: O,
    options?: BaseAsyncOptions,
  ): Promise<Either<void, GlobalError>>;
}

export interface ReadonlyTunnel<I, O> {
  readonly io: RouterSource<TunnelIoRouterMap<I, O>>;
}

export type TunnelIoRouterMap<I, O> = {
  [TUNNEL_INCOMING_MESSAGE]: (message: I) => void;
  [TUNNEL_INCOMING_ERROR]: (error: GlobalError) => void;
  [TUNNEL_OUTGOING_MESSAGE]: (message: O) => void;
  [TUNNEL_OUTGOING_ERROR]: (error: GlobalError) => void;
};
