import {useTheme, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Platform, Text, View} from 'react-native';

import {CrossSvg} from '../../../assets/svg/colorless';
import {TouchableOpacity} from '../../atoms';

export type HeaderTitleProps = {
  title?: React.ReactNode;
  center?: boolean;
  onClose?: () => void;
};

export const HeaderTitle = observer(
  ({title, onClose, center}: HeaderTitleProps) => {
    const theme = useTheme();

    if (Platform.OS === 'web') {
      return (
        <Root>
          <Title center={center}>{title ? title : ''}</Title>
          <TouchableOpacity onPress={onClose}>
            <CrossSvg color={theme.palette.uiMain} />
          </TouchableOpacity>
        </Root>
      );
    } else {
      return title ? (
        <Root>
          <Title center={center}>{title}</Title>
        </Root>
      ) : (
        <Swipe />
      );
    }
  },
);

const Root = variance(View)(theme => ({
  root: {
    minHeight: 53,
    paddingVertical: 15,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiPrimary,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 22,
    color: theme.palette.textMain,
  },
  center: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}));

const Swipe = variance(View)(theme => ({
  root: {
    width: 25,
    height: 5,
    marginVertical: 10,
    borderRadius: 5,
    marginRight: 'auto',
    marginLeft: 'auto',
    backgroundColor: theme.palette.uiSecondary,
  },
}));
