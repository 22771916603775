import type {PixelRatio} from '../DevicePixelRatio';
import type {DeviceScreenState} from '../DeviceScreenState';
import type {UserPreferenceState} from '../Localization';
import type {
  Advert,
  AdvertSize,
  Dictionary,
  ImageBySize,
} from '../NCWalletServer';
import type {Uri} from '../units';
import type {AdvertContext, AdvertHelper} from './AdvertHelper';
import pickSize from './pickSize';

export default class AdvertHelperImpl implements AdvertHelper {
  constructor(
    private readonly _root: {
      readonly deviceScreenState: DeviceScreenState;
      readonly userPreferenceState: UserPreferenceState;
    },
  ) {}

  localizeAndPickImages(_: Advert): AdvertContext {
    return {
      text: {
        title: this._localise(_.title),
        body: this._localise(_.body),
        actions: _.actions?.map(__ => ({title: this._localise(__.title)})),
      },
      images: {
        image: this._pickImage(_.image),
        icon: this._pickImage(_.icon),
      },
      advert: _,
    };
  }

  private _localise(_: string | Dictionary): string {
    if (typeof _ === 'string') {
      return _;
    }
    return _[this._root.userPreferenceState.languageCode] ?? _.en ?? '';
  }

  private _pickImage(_: ImageBySize): Uri {
    const sizes = new Set(Object.keys(_) as AdvertSize[]);
    const picked = pickSize(
      () => this._root.deviceScreenState.pixelRatio,
      sizes,
      MAP,
    );
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return _[picked]!;
  }
}

const MAP = Object.freeze({
  /**
   * mdpi
   */
  xs: 1,
  /**
   * xhdpi
   */
  m: 2,
  /**
   * xxhdpi
   */
  l: 3,
  /**
   * xxxhdpi
   */
  xl: 4,
} as Record<AdvertSize, PixelRatio>);
