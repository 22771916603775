import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {IpInfo} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';

import {useIpSettings} from '../../../CommonNavigationContainers/hooks/ipSettings/useIpSettings';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {IpListScreen} from '../../../screens/UserSettingsScreens/IpListScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ListAllowedIpsBindingProps =
  SmallHomeStackBindingProps<'ListAllowedIp'>;

export default observer(function ListAllowedIpsBinding(
  props: ListAllowedIpsBindingProps,
) {
  const {navigation} = props;
  const {ipSettings, loadIpSettings} = useIpSettings();
  const goToFormIpModal = useCallback(
    (ipInfo: IpInfo) => {
      navigation.navigate('PromptUpdateIp', {
        name: ipInfo.name,
        ip: ipInfo.ip,
        id: ipInfo.id,
        type: IpType.Allowed,
      });
    },
    [navigation],
  );
  const toAdd = useCallback(() => {
    navigation.navigate('PromptAddIp', {type: IpType.Allowed});
  }, [navigation]);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void loadIpSettings();
          });
        }
      }),
    [getIsFocused, getIsReady, loadIpSettings],
  );
  return (
    <IpListScreen
      type={IpType.Allowed}
      onIpPress={goToFormIpModal}
      onAdd={toAdd}
      keyboardAvoiding
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      ipInfos={ipSettings ? ipSettings.white_ips : undefined}
    />
  );
});
