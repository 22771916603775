import {useRoot} from '@ncwallet-app/core/';
import type {PromptSessionDeletionRoute} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {
  LIST_ACTIVE_SESSIONS_ROUTE,
  LIST_ALLOWED_IPS_ROUTE,
  LIST_BLOCKED_IPS_ROUTE,
  LIST_SESSION_HISTORY_ROUTE,
  PROMPT_ADD_IP_ROUTE,
  PROMPT_OTP_TO_ADD_IP_ROUTE,
  PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE,
  PROMPT_OTP_TO_DELETE_IP_ROUTE,
  PROMPT_OTP_TO_UPDATE_IP_ROUTE,
  PROMPT_SESSION_DELETION,
  PROMPT_TO_CLEAR_ALLOWED_LIST_ROUTE,
  PROMPT_TO_DELETE_IP_ROUTE,
  PROMPT_UPDATE_IP_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {ListSecuritySettingsSessionsRoute} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {SecuritySettingsCard} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {SessionId} from '@ncwallet-app/core/src/NCWalletServer/sessions/Session';
import {useCallback} from 'react';

import type {LargeSwitchBindingProps} from '../../../../LargeNavigationRoot/LargeHomeStack/bindings/LargeSwitch/LargeSwitchBindingProps';
import type {SessionCardState} from '../../../../screens/LgSecuritySettingsScreen/Sessions/SessionKind';
import {SessionKind} from '../../../../screens/LgSecuritySettingsScreen/Sessions/SessionKind';
import type {SessionsProps} from '../../../../screens/LgSecuritySettingsScreen/Sessions/SessionsHolder';
import {useActiveSessionsBindingState} from '../../ActiveSessionsBinding/useActiveSessionsBindingState';
import {useSessionHistoryBindingState} from '../../SessionHistoryBinding/useSessionHistoryBindingState';
import {useIpSettingsCard} from './useIpSettingsCard';

// eslint-disable-next-line import-x/prefer-default-export
export const useLgSecuritySettingsSessionsCardBindingState = (
  props: LargeSwitchBindingProps<'ListSecuritySettings'>,
): SessionsProps => {
  const {navigation, route} = props;
  const root = useRoot();
  const activeSessionsState = useActiveSessionsBindingState();
  const sessionHistoryState = useSessionHistoryBindingState();

  const getCurrentSession = useCallback(() => {
    const session = activeSessionsState.getCurrentSession();
    return session ? [session] : undefined;
  }, [activeSessionsState]);

  const getOtherSessions = useCallback(
    () => activeSessionsState.getOtherSessions(),
    [activeSessionsState],
  );

  const deleteSession = useCallback(
    (sessionId: SessionId) => {
      activeSessionsState.delete(sessionId);
    },
    [activeSessionsState],
  );

  const getSessions = useCallback(() => {
    return sessionHistoryState.sessionHistory;
  }, [sessionHistoryState]);
  const onEndReached = useCallback(() => {
    return sessionHistoryState.onEndReached();
  }, [sessionHistoryState]);
  const goToListSessionHistory = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.Sessions,
      focusedSessions: {kind: LIST_SESSION_HISTORY_ROUTE},
    });
  }, [navigation]);

  const goToListActiveSessions = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.Sessions,
      focusedSessions: {kind: LIST_ACTIVE_SESSIONS_ROUTE},
    });
  }, [navigation]);

  const onBack = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.Sessions,
      focusedSessions: undefined,
    });
  }, [navigation]);

  const promptConfirmationToEndSession = useCallback(
    (params: PromptSessionDeletionRoute['params']) => {
      navigation.setParams({
        focusedCard: SecuritySettingsCard.Sessions,
        focusedSessions: {
          kind: PROMPT_SESSION_DELETION,
          params,
        },
      });
    },
    [navigation],
  );

  const settingsCardProps = useIpSettingsCard(props);

  return {
    ...settingsCardProps,
    getCurrentSession,
    getOtherSessions,
    getSessions,
    deleteSession,
    sessionCardState: routeToSessionCardState(route.params?.focusedSessions),
    selectedSessionId:
      route.params?.focusedSessions?.kind === PROMPT_SESSION_DELETION
        ? route.params.focusedSessions.params.sessionId
        : undefined,
    goToListSessionHistory,
    goToListActiveSessions,
    onBack,
    promptConfirmationToEndSession,
    onEndReached,
    currentTwoFaProvider: root.twoFaSettingsState.currentTwoFaProvider,
  };
};

function routeToSessionCardState(
  route: ListSecuritySettingsSessionsRoute | undefined,
): SessionCardState {
  if (!route) {
    return {kind: SessionKind.ListSecuritySettings};
  }
  switch (route.kind) {
    case LIST_ALLOWED_IPS_ROUTE:
      return {kind: SessionKind.ListAllowedIps};
    case LIST_BLOCKED_IPS_ROUTE:
      return {
        kind: SessionKind.ListBlockedIps,
      };
    case PROMPT_UPDATE_IP_ROUTE:
      return {
        kind: SessionKind.PromptUpdateIp,
        name: route.params.name,
        ip: route.params.ip,
        id: route.params.id,
        type: route.params.type,
      };
    case LIST_SESSION_HISTORY_ROUTE:
      return {kind: SessionKind.ListSessionHistory};
    case PROMPT_ADD_IP_ROUTE:
      return {kind: SessionKind.PromptToAddIp, type: route.params.type};
    case LIST_ACTIVE_SESSIONS_ROUTE:
    case PROMPT_SESSION_DELETION:
      return {kind: SessionKind.ListActiveSessions};

    case PROMPT_TO_DELETE_IP_ROUTE:
      return {
        kind: SessionKind.PromptToDeleteIp,
        id: route.params.id,
        ip: route.params.ip,
        type: route.params.type,
      };
    case PROMPT_OTP_TO_DELETE_IP_ROUTE:
      return {
        kind: SessionKind.PromptOtpToDeleteIp,
        id: route.params.id,
        type: route.params.type,
      };

    case PROMPT_OTP_TO_ADD_IP_ROUTE:
      return {
        kind: SessionKind.PromptOtpToAddIp,
        ...route.params,
      };
    case PROMPT_OTP_TO_UPDATE_IP_ROUTE:
      return {
        kind: SessionKind.PromptOtpToUpdateIp,
        ...route.params,
      };
    case PROMPT_TO_CLEAR_ALLOWED_LIST_ROUTE:
      return {
        kind: SessionKind.PromptToClearAllowedList,
      };
    case PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE:
      return {
        kind: SessionKind.PromptOtpToClearAllowedList,
      };
  }
}
