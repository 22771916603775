import {unwrap} from '../EitherAdapter';
import type {JsonKeyValueMap, JsonKeyValueStore} from '../JsonKeyValueStore';
import type {LastBlockChianNetworkSuggestionTs} from '../KeyValueStore';
import type {Millisecond} from '../Time';
import BlockchainNetworksSuspensionQueryImpl from './BlockchainNetworksSuspensionQueryImpl';
import type {
  BlockchainNetworksSuspensionQuery,
  BlockchainNetworksSuspensionRepository,
} from './BlockchainNetworksSuspensionRepository';

export default class BlockchainNetworksSuspensionRepositoryImpl
  implements BlockchainNetworksSuspensionRepository
{
  constructor(
    private readonly _root: {
      readonly jsonKeyValueStore: JsonKeyValueStore<JsonKeyValueMap>;
    },
  ) {}

  async createBlockChainNetworksQuery(): Promise<BlockchainNetworksSuspensionQuery> {
    const record = await this._getRecord();
    return new BlockchainNetworksSuspensionQueryImpl(record);
  }

  async suspendBlockChainNetworks(network: string, now: Millisecond) {
    const record = await this._getRecord();
    await this._setRecord({...record, [network]: {ts: now}});
  }

  private async _setRecord(record: LastBlockChianNetworkSuggestionTs) {
    await unwrap(
      this._root.jsonKeyValueStore.set(
        'lastBlockchainNetworkWarningSuggestionTs',
        record,
      ),
    );
  }

  private async _getRecord() {
    return (
      (await unwrap(
        this._root.jsonKeyValueStore.get(
          'lastBlockchainNetworkWarningSuggestionTs',
        ),
      )) ?? {}
    );
  }
}
