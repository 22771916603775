import {action, comparer, makeObservable, observable, reaction} from 'mobx';

import {FULFILLED, PENDING, REJECTED} from '../AsyncAtom';
import type {AccountIdStore, AuthHelper, AuthState} from '../Auth';
import type {GeneralJsonRpcError, GlobalError} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {success} from '../fp';
import type {FreshAccessTokenState} from '../FreshAccessTokenState';
import type {
  Connection,
  ConnectionState,
  JsonRpcClient,
  JsonRpcServer,
  JsonRpcSettledCallRouterSource,
} from '../JsonRpc';
import {ConnectionStatus} from '../JsonRpc';
import type {
  CommonError,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  NCWalletReverseCallScheme,
  NCWalletReverseNotificationScheme,
} from '../NCWalletServer';
import type {AccountUpdateParams} from '../NCWalletServer/AccountUpdateParams';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import type {TwoFaProviderKind} from '../TwoFaHelper';
import type {TwoFaSettingsState} from '../TwoFaSettingsState/TwoFaSettingsState';
import type {AccessToken} from '../units';
import type {AccountContext, AccountStore} from './AccountStore';

export default class AccountStoreService implements AccountStore, Service {
  @observable.ref private _state?: AccountStore['state'];

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcServer: JsonRpcServer<
        NCWalletReverseCallScheme,
        NCWalletReverseNotificationScheme
      >;
      readonly errorRepository: ErrorRepository;
      readonly authState: AuthState;
      readonly accountIdStore: AccountIdStore;
      readonly authHelper: AuthHelper;
      readonly connection: Connection;
      readonly freshAccessTokenState: FreshAccessTokenState;
      readonly connectionState: ConnectionState;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly ncWalletJsonRpcCallRouterSource: JsonRpcSettledCallRouterSource<NCWalletCallScheme>;
      readonly twoFaSettingsState: TwoFaSettingsState;
    },
  ) {
    makeObservable(this);
  }

  private _setState = action((state?: AccountStore['state']) => {
    this._state = state;
  });

  get state() {
    return this._state;
  }

  update = async (
    params: AccountUpdateParams,
  ): Promise<
    Either<AccountContext, GlobalError | GeneralJsonRpcError<CommonError>>
  > => {
    const connectionId = this._root.connection.getId();
    const outcome = await this._root.ncWalletJsonRpcClient.call(
      'accounts.update',
      params,
    );

    if (outcome.success) {
      const context = {...outcome.right, connectionId};
      this._setState({status: FULFILLED, result: context});
      return success(context);
    }
    return outcome;
  };

  private async _updateAccountThroughAuthorization(token: AccessToken) {
    const connectionId = this._root.connection.getId();
    if (
      this._state?.status === FULFILLED &&
      this._state.result.connectionId === connectionId
    ) {
      return;
    }
    this._setState({status: PENDING});
    const state_ = await this._root.ncWalletJsonRpcClient.call(
      'accounts.auth',
      {token},
    );

    if (state_.success) {
      const context = {...state_.right, connectionId};
      if (state_.right.totp_settings.channels) {
        this._root.twoFaSettingsState.setCurrentTwoFaProvider(
          Object.keys(
            state_.right.totp_settings.channels,
          )[0] as TwoFaProviderKind,
        );
      }
      this._setState({status: FULFILLED, result: context});
    } else {
      this._setState({status: REJECTED, error: state_.left});
    }

    return state_;
  }

  private _authorizeConnectionAfterPin() {
    return reaction(
      () =>
        [
          this._root.connectionState.latestStatus === ConnectionStatus.Open,
          this._root.freshAccessTokenState.token,
        ] as const,
      async ([isConnectionOpen, token]) => {
        if (!isConnectionOpen || token === undefined) {
          return;
        }

        await this._updateAccountThroughAuthorization(token);
      },
      {equals: comparer.shallow},
    );
  }

  private _updateAccountWhenTwoFAEnabled() {
    return this._root.ncWalletJsonRpcCallRouterSource.listen(
      'accounts.totp.set',
      event => {
        if (
          event.result.success &&
          this._state?.status === FULFILLED &&
          !this._state.result.tfa
        ) {
          this._setState({
            status: FULFILLED,
            result: {...this._state.result, tfa: true},
          });
        }
      },
    );
  }

  private _updateAccountWhenTwoFADisabled() {
    return this._root.ncWalletJsonRpcCallRouterSource.listen(
      'accounts.totp.delete',
      event => {
        if (
          event.result.success &&
          this._state?.status === FULFILLED &&
          this._state.result.tfa
        ) {
          this._setState({
            status: FULFILLED,
            result: {...this._state.result, tfa: false},
          });
        }
      },
    );
  }

  private _updateAccountWhenServerForces() {
    return this._root.ncWalletJsonRpcServer.notification('event', params => {
      if (params.type === 'account_info' && this._state?.status === FULFILLED) {
        this._setState({
          status: FULFILLED,
          result: {
            ...this._state.result,
            ...params.data,
          },
        });
      }
    });
  }

  reset() {
    this._setState();
  }

  subscribe() {
    return batchDisposers(
      this._authorizeConnectionAfterPin(),
      this._updateAccountWhenTwoFAEnabled(),
      this._updateAccountWhenTwoFADisabled(),
      this._updateAccountWhenServerForces(),
    );
  }
}
