import dayjs from 'dayjs';
import {isEmpty} from 'lodash';

import type {ISODateString} from '../Time';

export enum PinErrorKind {
  PinInvalid = 'pin_invalid',
  PinNotSet = 'pin_not_set',
}

export type PinInvalidError = {
  kind: PinErrorKind.PinInvalid;
  nextAllowedAttempt: ISODateString | undefined;
};

export type PinNotSetError = {
  kind: PinErrorKind.PinNotSet;
};

export const createPinInvalidError = (
  nextAllowedAttempt: string | undefined,
): PinInvalidError => ({
  kind: PinErrorKind.PinInvalid,
  nextAllowedAttempt:
    !isEmpty(nextAllowedAttempt) && dayjs(nextAllowedAttempt).isValid()
      ? (nextAllowedAttempt as ISODateString)
      : undefined,
});

export const createPinNotSerError = (): PinNotSetError => ({
  kind: PinErrorKind.PinNotSet,
});
