import type {Uri, Url} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonIconPosition,
  ButtonVariant,
  LG_BREAKPOINT,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Image, Text, View} from 'react-native';

export type InstallNativeModalProps = {
  icon: Uri;
  title: string;
  body: string;
  actions?: AdvertAction[];
  onActionPress: (link?: string) => void;
  onClose: () => void;
};

export type AdvertAction = {
  link?: Url;
  title: string;
  icon?: Uri;
};

export default observer(function InstallNativeModal(
  props: InstallNativeModalProps,
) {
  const {title, body, actions, onActionPress, icon} = props;

  return (
    <>
      <ContentWrapper>
        <Content>
          {icon !== '' && (
            <Img
              resizeMode="contain"
              resizeMethod="resize"
              source={{uri: icon}}
            />
          )}
          <TextBlock>
            <Title>{title}</Title>
            <Paragraph>{body}</Paragraph>
          </TextBlock>
        </Content>
      </ContentWrapper>
      <BtnContainer>
        {actions?.map((_, i, array) => (
          <Button
            key={_.link}
            title={_.title}
            onPress={() => {
              onActionPress(_.link);
            }}
            variant={
              i === array.length - 1
                ? ButtonVariant.Primary
                : ButtonVariant.PrimaryLight
            }
            iconPosition={ButtonIconPosition.Right}
            Icon={_.icon ? () => <Image source={{uri: _.icon}} /> : undefined}
          />
        ))}
      </BtnContainer>
    </>
  );
});

const ContentWrapper = variance(View)(theme => ({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
    paddingHorizontal: 15,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: undefined,
        justifyContent: undefined,
        alignItems: undefined,
        paddingVertical: 20,
      },
    }),
  },
}));

const Content = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    gap: 15,
  },
}));

const Img = variance(Image)(() => ({
  root: {
    width: 64,
    height: 64,
    borderRadius: 10,
  },
}));

const TextBlock = variance(View)(() => ({
  root: {
    flex: 1,
    padding: 2,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    color: theme.palette.textPrimary,
    marginBottom: 6,
  },
}));

const Paragraph = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    lineHeight: 26,
    color: theme.palette.textAdditional1,
  },
}));

const BtnContainer = variance(View)(theme => ({
  root: {
    marginHorizontal: 20,
    gap: 20,
    paddingBottom: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginTop: 20,
      },
    }),
  },
}));
