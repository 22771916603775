import {variance} from '@ncwallet-app/core';
import type {CountryNameData} from '@ncwallet-app/core/src/Localization/constant';
import {FlagIcon, LG_BREAKPOINT, TouchableOpacity} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type CountryListItemProps = {
  item: CountryNameData;
  onPress: (selectedCountry: CountryNameData) => void;
};

export default observer(function CountryListItem(props: CountryListItemProps) {
  const {item, onPress} = props;
  return (
    <ContainerView
      onPress={() => {
        onPress(item);
      }}>
      <FlagView>
        <FlagIcon code={item.alpha2} />
      </FlagView>
      <CountryText>{item.name}</CountryText>
    </ContainerView>
  );
});

const ContainerView = variance(TouchableOpacity)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingVertical: 10,
        paddingHorizontal: 10,
      },
    }),
  },
}));

const FlagView = variance(View)(() => ({
  root: {
    width: 30,
    height: 22,
    marginRight: 15,
  },
}));

const CountryText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    color: theme.palette.textPrimary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 14,
      },
    }),
  },
}));
