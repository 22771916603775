import {FULFILLED, useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, reaction, runInAction} from 'mobx';
import {useCallback, useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../hooks';
import {BaseCryptoSettingsBindingState} from './BaseCryptoSettingsBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const useBaseCryptoSettingsBindingState = () => {
  const root = useRoot();
  const [state] = useState(() => new BaseCryptoSettingsBindingState(root));

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  const refresh = useCallback(() => {
    if (root.accountStore.state?.status === FULFILLED) {
      const {base_fiat} = root.accountStore.state.result;
      runInAction(() => {
        void state.refresh(base_fiat);
      });
    }
  }, [root, state]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          refresh();
        }
      }),
    [getIsFocused, getIsReady, refresh],
  );

  useEffect(
    () =>
      reaction(
        () => state.isTimeoutError,
        shouldRequest => {
          if (shouldRequest) {
            root.rpcTimeoutErrorVisibility.registerAction(refresh);
          }
        },
      ),
    [root, state, refresh],
  );

  return state;
};
