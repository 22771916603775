import type {AdvertisingIdentifier} from '../AdvertisingIdentifier';
import type {DeviceInfo} from '../DeviceInfo';
import type {
  DeviceRegistrationResult,
  DeviceRestClient,
} from '../DeviceRestClient';
import {wrap} from '../EitherAdapter';
import type {GlobalError} from '../Error';
import {failure, success} from '../fp';
import type {InstallReferrerIdentification} from '../InstallReferrerIdentification';
import type {
  JsonKeyValueMap,
  JsonKeyValueStore,
  JsonSecureKeyValueMap,
} from '../JsonKeyValueStore';
import type {Maybe} from '../Maybe';
import type {SentryLog} from '../SentryLog';
import type {SessionContextProvider} from '../SessionContext/SessionContextProvider';
import type {DeviceId} from '../units';
import oneTaskAtATime from '../util/oneTaskAtATime';
import type {DeviceIdentification} from './DeviceIdentification';

export default class DeviceIdentificationImpl implements DeviceIdentification {
  constructor(
    private readonly _root: {
      readonly jsonKeyValueStore: JsonKeyValueStore<JsonKeyValueMap>;
      readonly jsonSecureKeyValueStore: JsonKeyValueStore<JsonSecureKeyValueMap>;
      readonly deviceRestClient: DeviceRestClient;
      readonly deviceInfo: DeviceInfo;
      readonly installReferrerIdentification: InstallReferrerIdentification;
      readonly advertisingIdentifier: AdvertisingIdentifier;
      readonly sessionContextProvider: SessionContextProvider;
      readonly sentryLog: SentryLog;
    },
  ) {}

  private async _registerDevice(): Promise<Maybe<DeviceRegistrationResult>> {
    const advertisingId_ = await wrap(() =>
      this._root.advertisingIdentifier.getInfo(),
    );
    let advertisingId;
    if (advertisingId_.success) {
      advertisingId = advertisingId_.right;
    } else {
      this._logMessage('Failed to get ad id', advertisingId_.left);
    }
    const installReferrer_ = await wrap(() =>
      this._root.installReferrerIdentification.getInstallReferrer(),
    );
    let installReferrer;
    if (installReferrer_.success) {
      installReferrer = installReferrer_.right;
    } else {
      this._logMessage('Failed to get install referrer', installReferrer_.left);
    }

    const app_id = this._root.deviceInfo.getBundleId();
    const app_ver = this._root.deviceInfo.getAppVersionWithBuildNumber();
    const platform = this._root.sessionContextProvider
      .getContext()
      .platform.toLowerCase();

    const registration_ = await this._root.deviceRestClient.registration({
      app_id,
      app_ver,
      ...(advertisingId
        ? {attribution_map: {tenjin: {aifa: advertisingId}}}
        : {}),
      install_referrer: installReferrer,
      platform,
    });
    if (!registration_.success) {
      return failure(registration_.left);
    }
    return registration_;
  }

  private async _getDeviceId(): Promise<Maybe<DeviceId>> {
    const record_ = await this._readDeviceIdFromAnyStorage();
    if (!record_.success) {
      return record_;
    }
    if (record_.right === undefined) {
      const registration_ = await this._registerDevice();
      if (!registration_.success) {
        return registration_;
      }
      const set_ = await this._root.jsonSecureKeyValueStore.set('device', {
        id: registration_.right.uid,
      });
      if (!set_.success) {
        return set_;
      }
      return success(registration_.right.uid);
    }
    return success(record_.right.id);
  }

  private static readonly _getDeviceId = oneTaskAtATime(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    DeviceIdentificationImpl.prototype._getDeviceId,
  );

  getDeviceId() {
    return DeviceIdentificationImpl._getDeviceId.call(this);
  }

  async spoofDeviceId(_: DeviceId): Promise<Maybe<void>> {
    return this._root.jsonSecureKeyValueStore.set('device', {id: _});
  }

  private async _readDeviceIdFromAnyStorage() {
    const secure_ = await this._root.jsonSecureKeyValueStore.get('device');
    if (secure_.success && secure_.right !== undefined) {
      return success(secure_.right);
    }
    return this._root.jsonKeyValueStore.get('device');
  }

  private _logMessage(subject: string, error: GlobalError) {
    this._root.sentryLog.write(
      `${subject}: ${error.description} ${String(error.raw)}`,
    );
  }
}
