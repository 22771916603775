import type {NavigationContainer} from '../NavigationContainer';
import type {AppViewport} from './AppViewport';

export default class CommonAppViewportImpl implements AppViewport {
  constructor(
    private readonly _root: {
      readonly navigationContainer: NavigationContainer;
    },
  ) {}

  get isPopup() {
    return false;
  }

  async expand() {
    this._root.navigationContainer.ref?.resetRoot();
  }
}
