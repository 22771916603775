import type {AccountLinkageHandler} from '../AccountLinkageHandler';
import type {FlashMessage} from '../FlashMessage';
import type {Localization} from '../Localization';
import type {TelegramCredentials} from '../units';
import type {LinkToTelegram} from './LinkToTelegram';

export default class LinkToTelegramImpl implements LinkToTelegram {
  constructor(
    protected readonly _root: {
      readonly flashMessage: FlashMessage;
      readonly localization: Localization;
      readonly accountLinkageHandler: AccountLinkageHandler;
    },
  ) {}

  async linkToTelegram(token: TelegramCredentials) {
    try {
      await this._root.accountLinkageHandler.link('telegram', token);

      this._root.flashMessage.showMessage({
        title: this._root.localization.getTranslation(
          'linkToTelegram.messages.success',
        ),
        variant: 'success',
      });
    } catch {
      this._root.flashMessage.showMessage({
        title: this._root.localization.getTranslation(
          'linkToTelegram.messages.error',
        ),
        variant: 'danger',
      });
    }
  }
}
