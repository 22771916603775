import {FADE_DURATION} from '@ncwallet-app/ui';
import {useEffect, useState} from 'react';
import {
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

// eslint-disable-next-line import-x/prefer-default-export
export const useAuthStackAnimation = <T>(route: T | undefined) => {
  const translation = useSharedValue(1);

  const [bufferRoute, setBufferRoute] = useState(() => route);
  useEffect(() => {
    if (route === undefined && bufferRoute !== undefined) {
      translation.value = withTiming(0, {duration: FADE_DURATION}, ready => {
        if (ready) {
          runOnJS(setBufferRoute)(undefined);
        }
      });
    } else if (route !== undefined && bufferRoute === undefined) {
      setBufferRoute(route);
      // `translation.value = 1` does not always trigger a component re-render; withTiming with a short duration is used instead
      translation.value = withTiming(1, {duration: 10});
    } else if (route !== undefined && bufferRoute !== undefined) {
      setBufferRoute(route);
    }
  }, [route, bufferRoute, translation]);
  return {
    bufferRoute,
    animatedStyle: useAnimatedStyle(() => ({
      opacity: translation.value,
      transform: [
        {translateY: interpolate(translation.value, [0, 1], [-100, 0])},
      ],
    })),
  };
};
