import type {LargeSwitchParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {PartialState} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import NotifyAboutSuccessfulSendingContainer from '../../../../../CommonNavigationContainers/containers/NotifyAboutSuccessfulSendingContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type NotifyAboutSuccessfulSendingBindingProps =
  LargeSwitchBindingProps<'NotifyAboutSuccessfulSending'>;

export default observer(function NotifyAboutSuccessfulSendingBinding(
  props: NotifyAboutSuccessfulSendingBindingProps,
) {
  const {navigation, route} = props;

  const onSubmit = useCallback(() => {
    const switchState: PartialState<NavigationState<LargeSwitchParamList>> = {
      index: 0,
      routes: [{name: 'ListWallets'}],
    };
    navigation.dispatch(CommonActions.reset(switchState));
  }, [navigation]);

  const onBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <NotifyAboutSuccessfulSendingContainer
      params={route.params}
      onBack={onBack}
      onSubmit={onSubmit}
    />
  );
});
