import type {CancellablePromiseEither} from '../CancellablePromise';
import type {CancellationError} from '../Error';
import {CANCELLATION_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {Millisecond} from '../Time';
import type {EventLoopHelper} from './EventLoopHelper';

export default class EventLoopHelperImpl implements EventLoopHelper {
  constructor(
    private readonly _root: {readonly errorRepository: ErrorRepository},
  ) {}

  delay(timeout: Millisecond): CancellablePromiseEither<void, never> {
    let cancel: () => void;
    const promise = new Promise<Either<void, CancellationError>>(resolve => {
      const listener = () => {
        resolve(success());
      };
      const id = setTimeout(listener, timeout);
      cancel = () => {
        clearTimeout(id);
        resolve(
          error(
            this._root.errorRepository.create<CancellationError>({
              kind: CANCELLATION_ERROR,
            }),
          ),
        );
      };
    });
    Reflect.set(promise, 'cancel', () => {
      cancel();
    });
    return promise as CancellablePromiseEither<void, never>;
  }
}
