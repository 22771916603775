import type {BaseAsyncOptions} from '../Async';
import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import type {CryptoCurrencyCode, CurrencyValue} from '../Money';
import type {WalletId} from '../NCWalletServer';
import type {
  TransactionDirection,
  TransactionFrontInfo,
  TransactionId,
  TransactionType,
  WalletTransactionsInfo,
} from '../NCWalletServer/WalletsTransactions';
import type {ISODateString, Millisecond} from '../Time';
import type {AggregationItemStatus} from './AggregationItemStatus';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace WalletTransactions {
  export interface RequestHelper {
    getNext(
      userOwned: Set<WalletId>,
      transactionType?: TransactionType,
      direction?: TransactionDirection,
      params?: WalletTransactions.GetNextParams,
      options?: BaseAsyncOptions,
    ): Promise<Either<Aggregation, GlobalError>>;

    getTransactionById(
      userOwned: Set<WalletId>,
      id: TransactionId,
    ): Promise<Either<AggregationDetailedItem, GlobalError>>;
  }

  export type GetNextParams = {
    limit?: number;
    from?: Millisecond;
    to?: Millisecond;
    // do not lose nanoseconds here
    lastCreatedAt?: ISODateString;
  };

  export type Aggregation = {
    items: AggregationItem[];
    remainingItems: number;
  };

  export type AggregationItem<
    Code extends CryptoCurrencyCode = CryptoCurrencyCode,
  > = {
    id: TransactionId;
    createdAt: ISODateString;
    fromWalletId?: WalletId;
    walletId: WalletId;
    currencyCode: Code;
    amount: CurrencyValue<Code>;
    kind: AggregationItemKind;
    status: AggregationItemStatus;
    info: WalletTransactionsInfo;
    front_info?: TransactionFrontInfo;
    comment: string | null;
  };

  export type AggregationDetailedItem<
    Code extends CryptoCurrencyCode = CryptoCurrencyCode,
  > = Omit<AggregationItem<Code>, 'amount'> & {
    amountTo: CurrencyValue<Code>;
    amountFrom: CurrencyValue<Code>;
  };

  export enum AggregationItemKind {
    Exchange,
    Rollback,
    Incoming,
    Withdrawal,
    ReferralIncoming,
    PromoIncoming,
  }
}
