import {useTheme} from '@ncwallet-app/core';
import {isEmpty} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {Defs, G, LinearGradient, Path, Stop, Svg} from 'react-native-svg';
import type {NumberProp} from 'react-native-svg/src/lib/extract/types';

import asymptote from './asymptote';
import bezierPath from './bezierPath';
import normalize from './normalize';

export type LineChartDatum = {
  value: number;
};

export type LineChartProps = {
  data: LineChartDatum[];
  width: number;
  height: number;
  withGradient?: boolean;
  offset?: Partial<{
    top: number;
    right: number;
    bottom: number;
    left: number;
  }>;
  gradientDirection?: Partial<{
    x1: NumberProp;
    y1: NumberProp;
    x2: NumberProp;
    y2: NumberProp;
  }>;
};

function generateUniqId() {
  return new Date().getTime();
}

export default observer(function LineChart(props: LineChartProps) {
  const {
    width: containerWidth,
    height: containerHeight,
    offset,
    gradientDirection = {},
  } = props;
  const theme = useTheme();
  if (isEmpty(props.data) || props.data.length < 2) {
    return null;
  }

  const {top = 2, right = 0, bottom = 0, left = 0} = offset ?? {};
  const chartWidth = containerWidth - left - right;
  const height = containerHeight - bottom - top;
  let [minValue, maxValue, normalizedData] = normalize(
    props.data.map(v => v.value),
  );

  const yLabelFraction = 5;

  if (minValue === maxValue) {
    [minValue, maxValue, normalizedData] = asymptote(
      minValue,
      normalizedData.length,
      yLabelFraction,
    );
  }

  const d = bezierPath(chartWidth, height, normalizedData);
  const gradientId = `currency-gradient-${generateUniqId()}`;
  return (
    <View>
      <Svg
        width={containerWidth}
        height={containerHeight}
        viewBox={`0 0 ${containerWidth} ${containerHeight}`}
        fill="none">
        {props.withGradient && (
          <Defs>
            <LinearGradient
              id={gradientId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
              {...gradientDirection}>
              <Stop
                offset="0%"
                stopColor={theme.palette.primary}
                stopOpacity={0.4}
              />
              <Stop
                offset="100%"
                stopColor={theme.palette.background}
                stopOpacity={0.1}
              />
            </LinearGradient>
          </Defs>
        )}
        <G transform={`translate(${left}, ${top})`}>
          {props.withGradient && (
            <Path
              d={`${d} L ${containerWidth} ${containerHeight} L 0 ${containerHeight}`}
              fill={`url(#${gradientId})`}
              strokeWidth={1}
            />
          )}
          <Path
            d={d}
            stroke={theme.palette.primary}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </G>
      </Svg>
    </View>
  );
});
