import type {OtpCode} from '@ncwallet-app/core';
import {useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import type {NavigationProp, ParamListBase} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import {useBaseErrorDescription} from '../../../CommonNavigationContainers/hooks';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {useSendTelegramOtp} from '../../../Navigation/hooks/useSendTelegramOtp';
import {
  CARD,
  SubmitOtpScreen,
} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {useBiometryAlreadySetErrorDetect} from './CheckBiometricsPinBinding';

type PromptOtpToBiometricsBindingProps =
  SmallHomeStackBindingProps<'PromptOtpToBiometrics'>;

export default observer(function PromptOtpToBiometricsBinding(
  props: PromptOtpToBiometricsBindingProps,
) {
  const {navigation} = props;
  const {twoFaSettingsState, deviceInfo} = useRoot();
  const strings = useStrings();
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {resetErrorText, getErrorText, handleSubmit} = useOtpToBiometricsProps(
    isSubmitting,
    setIsSubmitting,
    navigation,
  );

  const handleTwoFaResendCode = useCallback(async () => {
    try {
      await sendOtp(null, {forceChannel: false});
    } catch {
      /* empty */
    }
  }, [sendOtp]);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (getIsReady() && getIsFocused()) {
      if (
        twoFaSettingsState.currentTwoFaProvider === TwoFaProviderKind.Telegram
      ) {
        void handleTwoFaResendCode();
      }
    }
  }, [getIsReady, getIsFocused, twoFaSettingsState, handleTwoFaResendCode]);

  return (
    <SubmitOtpScreen
      layout={CARD}
      resetError={resetErrorText}
      getError={getErrorText}
      titleText={strings['promptOtpToBiometrics.title']}
      submitText={strings['promptOtpToBiometrics.submit']}
      onSubmit={handleSubmit}
      appVersion={deviceInfo.getAppVersionWithBuildNumber()}
      twoFaProvider={twoFaSettingsState.currentTwoFaProvider}
      onTwoFaResendCode={handleTwoFaResendCode}
      resendCodeInterval={interval}
      resetInterval={resetInterval}
    />
  );
});

export const useOtpToBiometricsProps = (
  isSubmitting: boolean,
  setIsSubmitting: (v: boolean) => void,
  navigation: NavigationProp<ParamListBase>,
) => {
  const root = useRoot();
  const [getErrorText, setErrorText, resetErrorText] =
    useBaseErrorDescription();

  const checkIsAlreadySet = useBiometryAlreadySetErrorDetect();

  const handleSubmit = useCallback(
    async (code: string) => {
      if (isSubmitting) {
        return;
      }

      setIsSubmitting(true);
      const res = await root.biometricSettingsManager.addTwoFaBiometrics(
        code as OtpCode,
      );

      setIsSubmitting(false);

      if (res.success) {
        navigation.reset({
          index: 0,
          routes: [{name: 'Root'}, {name: 'PromptBiometricSettings'}],
        });
      } else {
        checkIsAlreadySet(res);
        setErrorText(res.left);
      }
    },
    [
      checkIsAlreadySet,
      isSubmitting,
      navigation,
      root.biometricSettingsManager,
      setErrorText,
      setIsSubmitting,
    ],
  );

  return {handleSubmit, getErrorText, resetErrorText};
};
