import type {WalletLimitId} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {
  PromptOtpToLimitRemovalRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {LimitRemovalAction} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {useCallback, useRef} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useDeleteLimit = (
  goToPromptOtp: (params: RouteParams<PromptOtpToLimitRemovalRoute>) => void,
  onSuccess: () => void,
) => {
  const root = useRoot();
  const isSubmittedRef = useRef(false);
  const deleteLimit = useCallback(
    async (limitId: WalletLimitId, action: LimitRemovalAction) => {
      if (isSubmittedRef.current) {
        return;
      }
      isSubmittedRef.current = true;

      const isTwoFaRequiredRes =
        await root.twoFaHelper.isTwoFaRequiredForLimits();

      if (!isTwoFaRequiredRes.success) {
        isSubmittedRef.current = false;
        return;
      }

      if (isTwoFaRequiredRes.right) {
        isSubmittedRef.current = false;
        goToPromptOtp({limitId, action});
        return;
      }

      const res = await root.ncWalletJsonRpcClient.call(
        action === LimitRemovalAction.CancelDelete
          ? 'wallets.limits.delete.cancel'
          : 'wallets.limits.delete',
        {limit_id: limitId},
      );
      isSubmittedRef.current = false;
      if (res.success) {
        onSuccess();
        return;
      }
    },
    [goToPromptOtp, onSuccess, root],
  );

  return {deleteLimit};
};
