import {BigNumber} from 'bignumber.js';
import {isNil} from 'lodash';

import type {CryptoProtocol} from '../../LinkingOptionsProvider/constant';
import {
  CRYPTO_PROTOCOL_TO_CODE_MAP,
  KNOWN_CRYPTO_PROTOCOLS,
} from '../../LinkingOptionsProvider/constant';
import type {TransactionFilterKind} from '../../models';
import type {
  CryptoCurrencyCode,
  CurrencyCode,
  DecimalString,
  RateValue,
} from '../../Money';
import type {
  CryptoAddress,
  WalletId,
  WalletLimitId,
  WalletLimitPeriod,
} from '../../NCWalletServer';
import type {
  AccountDocumentId,
  AccountDocumentType,
} from '../../NCWalletServer/AccountsDocuments';
import type {AccountDocumentFileName} from '../../NCWalletServer/AccountsDocuments/AccountDocumentFileInfo';
import type {AddressNetwork} from '../../NCWalletServer/AddressInfo';
import type {Ip, IpInfoId} from '../../NCWalletServer/ipSettings/IpInfo';
import type {IpType} from '../../NCWalletServer/ipSettings/IpType';
import type {SessionId} from '../../NCWalletServer/sessions/Session';
import type {OtpSecret} from '../../Otp';
import type {Base32} from '../../Rfc4648';
import type {Millisecond} from '../../Time';
import {turnOut} from '../../util';
import probe from '../../util/probe';
import type {
  AddIdentityNewDocumentKindRoute,
  BaseFilterParams,
  BaseSendParams,
  BlockedIpsListRoute,
  CheckBiometricsPinRoute,
  CheckPinRoute,
  DebugRoute,
  ExchangeReceipt,
  ExchangeSummary,
  FallBackRoute,
  FeeParams,
  GeneratePayMeLinkRoute,
  IdentityVerificationUploadDocumentRoute,
  LimitRemovalAction,
  ListActiveSessionsRoute,
  ListAllowedIpsRoute,
  ListAuthAppsRoute,
  ListDocumentsRoute,
  ListEnvironmentsRoute,
  ListHistoryFiltersRoute,
  ListHistoryRoute,
  ListInputAddressesRoute,
  ListLimitsRoute,
  ListLogsRoute,
  ListMenuCategoriesRoute,
  ListNotificationSettingsRoute,
  ListNotificationsRoute,
  ListPersonalDataRoute,
  ListQrCodeHistoryRoute,
  ListSecuritySettingsRoute,
  ListSessionHistoryRoute,
  ListSignOutReasonLogsRoute,
  NotifyAboutSuccessfulExchangeRoute,
  NotifyAboutSuccessfulPinChangeRoute,
  NotifyAboutSuccessfulSendingRoute,
  PromptAccountDeletionRoute,
  PromptAddressFormatRoute,
  PromptAffiliateProgramRoute,
  PromptAmountToSendRoute,
  PromptBaseCryptoRoute,
  PromptBaseFiatRoute,
  PromptBiometricSettingsRoute,
  PromptCloseAppRoute,
  PromptCommentToSendRoute,
  PromptCommissionForSendingRoute,
  PromptConfirmationForExchangeRoute,
  PromptConfirmationToSendCryptoRoute,
  PromptCryptoToExchangeRoute,
  PromptCryptoToReceiveRoute,
  PromptCryptoToSendRoute,
  PromptDateOfHistoryPeriodRoute,
  PromptDocumentRoute,
  PromptEmailRoute,
  PromptEnvironmentFormRoute,
  PromptExchangeReceiptRoute,
  PromptFeedbackRoute,
  PromptIdentityVerificationAddressRoute,
  PromptInputNetworkRoute,
  PromptLanguageRoute,
  PromptLimitRemovalRoute,
  PromptLimitUpdateRoute,
  PromptNameAndBirthdateRoute,
  PromptNewLimitRoute,
  PromptNewPinRoute,
  PromptNewWalletRoute,
  PromptNicknameRoute,
  PromptOtpToAddIpRoute,
  PromptOtpToBiometricsRoute,
  PromptOtpToChangeTwoFaSettingsRoute,
  PromptOtpToClearAllowedListRoute,
  PromptOtpToDeleteAccountRoute,
  PromptOtpToDeleteIpRoute,
  PromptOtpToDisableTwoFaRoute,
  PromptOtpToEnableTwoFaRoute,
  PromptOtpToExchangeRoute,
  PromptOtpToLimitCreateRoute,
  PromptOtpToLimitRemovalRoute,
  PromptOtpToLimitUpdateRoute,
  PromptOtpToSendCryptoRoute,
  PromptOutputAddressRoute,
  PromptOutputNetworkRoute,
  PromptPeriodToFilterHistoryRoute,
  PromptRateRoute,
  PromptReceiveNetworkRoute,
  PromptResidentialAddressRoute,
  PromptSelectTwoFaProviderRoute,
  PromptSessionDeletionRoute,
  PromptSignOutRoute,
  PromptSourceCryptoToExchangeRoute,
  PromptThemeRoute,
  PromptToClearAllowedListRoute,
  PromptToDeleteDocumentFileRoute,
  PromptToDeleteIpRoute,
  PromptTransactionKindToFilterHistoryRoute,
  PromptUpdateIpRoute,
  PromptUserIdRoute,
  PromptWalletGroupToFilterHistoryRoute,
  PromptWalletToFilterHistoryRoute,
  RedirectToSendRoute,
  RedirectToSendRouteParams,
  SendReceipt,
  ShowLimitRoute,
  ShowProfileRoute,
  ShowQrCodeScannerRoute,
  ShowQrRoute,
  ShowQrToReceiveCryptoRoute,
  ShowTransactionReportRoute,
  ShowTransactionRoute,
  ShowTwoFaGeneratedSecretRoute,
  ShowTwoFASettingsRoute,
  ShowWalletRoute,
  StarValue,
  StrictCommonRouteMeta,
  StrictCommonState,
  TwoFaWelcomeRoute,
} from '../CommonState';
import {
  FALL_BACK_ROUTE,
  GENERATE_PAY_ME_LINK_ROUTE,
  IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE,
  LIST_HISTORY_FILTERS_ROUTE,
  LIST_HISTORY_ROUTE,
  LIST_INPUT_ADDRESSES_ROUTE,
  LIST_MENU_CATEGORIES_ROUTE,
  LIST_NOTIFICATIONS_ROUTE,
  LIST_QR_CODE_HISTORY_ROUTE,
  LIST_SECURITY_SETTINGS_ROUTE,
  LIST_WALLETS_ROUTE,
  NOTIFY_ABOUT_SUCCESSFUL_EXCHANGE_ROUTE,
  NOTIFY_ABOUT_SUCCESSFUL_SENDING_ROUTE,
  PROMPT_ACCOUNT_DELETION_ROUTE,
  PROMPT_ADD_IP_ROUTE,
  PROMPT_ADDRESS_FORMAT_ROUTE,
  PROMPT_AFFILIATE_PROGRAM_ROUTE,
  PROMPT_AMOUNT_TO_SEND_ROUTE,
  PROMPT_CLOSE_APP_ROUTE,
  PROMPT_COMMENT_TO_SEND_ROUTE,
  PROMPT_COMMISSION_FOR_SENDING_ROUTE,
  PROMPT_CONFIRMATION_FOR_EXCHANGE_ROUTE,
  PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE,
  PROMPT_CRYPTO_TO_EXCHANGE_ROUTE,
  PROMPT_CRYPTO_TO_RECEIVE_ROUTE,
  PROMPT_CRYPTO_TO_SEND_ROUTE,
  PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE,
  PROMPT_DOCUMENT_ROUTE,
  PROMPT_EXCHANGE_RECEIPT_ROUTE,
  PROMPT_FEEDBACK_ROUTE,
  PROMPT_INPUT_NETWORK_ROUTE,
  PROMPT_LANGUAGE_ROUTE,
  PROMPT_LIMIT_REMOVAL_ROUTE,
  PROMPT_LIMIT_UPDATE_ROUTE,
  PROMPT_NEW_LIMIT_ROUTE,
  PROMPT_NEW_PIN_ROUTE,
  PROMPT_NEW_WALLET_ROUTE,
  PROMPT_OTP_TO_ADD_IP_ROUTE,
  PROMPT_OTP_TO_DELETE_IP_ROUTE,
  PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_EXCHANGE_ROUTE,
  PROMPT_OTP_TO_LIMIT_CREATE_ROUTE,
  PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE,
  PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE,
  PROMPT_OTP_TO_SEND_CRYPTO_ROUTE,
  PROMPT_OTP_TO_UPDATE_IP_ROUTE,
  PROMPT_OUTPUT_ADDRESS_ROUTE,
  PROMPT_OUTPUT_NETWORK_ROUTE,
  PROMPT_PERIOD_TO_FILTER_HISTORY_ROUTE,
  PROMPT_RATE_ROUTE,
  PROMPT_RECEIVE_NETWORK_ROUTE,
  PROMPT_SESSION_DELETION,
  PROMPT_SIGN_OUT_ROUTE,
  PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE,
  PROMPT_THEME_ROUTE,
  PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE,
  PROMPT_TO_DELETE_IP_ROUTE,
  PROMPT_UPDATE_IP_ROUTE,
  PROMPT_WALLET_TO_FILTER_HISTORY_ROUTE,
  REDIRECT_TO_SEND_ROUTE,
  SHOW_LIMIT_ROUTE,
  SHOW_QR_CODE_SCANNER_ROUTE,
  SHOW_QR_ROUTE,
  SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE,
  SHOW_TRANSACTION_REPORT_ROUTE,
  SHOW_TRANSACTION_ROUTE,
  SHOW_TWO_FA_GENERATED_SECRET_ROUTE,
  SHOW_WALLET_ROUTE,
} from '../CommonState';
import type {Path} from '../Path';
import {LazyPathImpl, PathImpl, popPathFromChain} from '../Path';
import routePathMap from './routePathMap';

export default function pathToState(path: Path): StrictCommonState {
  const first = probe(path.pathname, 0);
  const second = probe(path.pathname, 1);
  if (first !== undefined && ROOT_ALIASES.has(first)) {
    return rootPathToState(path);
  }
  const kind = pathRouteMap[first as keyof typeof pathRouteMap] as
    | StrictCommonRouteMeta['route']['kind']
    | undefined;
  switch (kind) {
    case undefined:
    case FALL_BACK_ROUTE:
      return createFallbackState(path);
    case LIST_WALLETS_ROUTE:
      return rootPathToState(path);
    case REDIRECT_TO_SEND_ROUTE: {
      if (path.pathname.length < 2) {
        return createFallbackState(path);
      }
      const [, crypto, address, amount, net, minFreeWithdrawAmount] =
        path.pathname;
      const addressNetwork = decodeURIComponent(net) as AddressNetwork;
      const route: RedirectToSendRoute = {
        kind,
        params: {
          crypto: !address
            ? undefined
            : (crypto.toUpperCase() as CryptoCurrencyCode),
          address: (!address ? crypto : address) as CryptoAddress,
          amount,
          addressNetwork,
          minFreeWithdrawAmount,
        },
      };
      return [{route, path}];
    }
    case LIST_MENU_CATEGORIES_ROUTE:
    case PROMPT_THEME_ROUTE:
    case PROMPT_LANGUAGE_ROUTE:
    case LIST_NOTIFICATIONS_ROUTE:
    case PROMPT_DOCUMENT_ROUTE:
    case PROMPT_ACCOUNT_DELETION_ROUTE:
    case PROMPT_AFFILIATE_PROGRAM_ROUTE:
    case SHOW_QR_CODE_SCANNER_ROUTE:
    case PROMPT_SIGN_OUT_ROUTE:
    case PROMPT_CLOSE_APP_ROUTE:
    case PROMPT_NEW_PIN_ROUTE:
    case PROMPT_RATE_ROUTE: {
      const route:
        | ListMenuCategoriesRoute
        | PromptThemeRoute
        | PromptLanguageRoute
        | ListNotificationsRoute
        | PromptDocumentRoute
        | PromptAccountDeletionRoute
        | ShowQrCodeScannerRoute
        | PromptSignOutRoute
        | PromptCloseAppRoute
        | PromptAffiliateProgramRoute
        | PromptNewPinRoute
        | PromptRateRoute = {kind};
      return popFromChainIfPossible({route, path});
    }
    case LIST_SECURITY_SETTINGS_ROUTE:
    case SHOW_TWO_FA_GENERATED_SECRET_ROUTE: {
      const onTwoFaEnabled = extractPath(path, 'onEnabled');
      const code = extractString(path, 'c');
      const route: ListSecuritySettingsRoute | ShowTwoFaGeneratedSecretRoute = {
        kind,
        params: {onTwoFaEnabled, code},
      };
      return [{route, path}];
    }
    case PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE: {
      const secret = extractString(path, 's');
      if (isVoid(secret)) {
        return createFallbackState(path);
      }
      const onTwoFaEnabled = extractPath(path, 'onEnabled');
      const route: PromptOtpToEnableTwoFaRoute = {
        kind,
        params: {onTwoFaEnabled, secret: secret as Base32<OtpSecret>},
      };
      return [{route, path}];
    }
    case PROMPT_SESSION_DELETION: {
      const sessionId = extractString<SessionId>(path, 'sessionId');
      if (sessionId === undefined) {
        return createFallbackState(path);
      }
      const route: PromptSessionDeletionRoute = {
        kind,
        params: {sessionId},
      };
      return popFromChainIfPossible({
        route,
        path,
      });
    }
    case PROMPT_CRYPTO_TO_SEND_ROUTE: {
      const route: PromptCryptoToSendRoute = {
        kind,
        params: {
          search: path.params?.s?.[0] ?? undefined,
          addressTo: extractString(path, 'ato'),
        },
      };
      return [{path, route}];
    }
    case PROMPT_NEW_WALLET_ROUTE:
    case PROMPT_CRYPTO_TO_RECEIVE_ROUTE:
    case PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE:
    case PROMPT_WALLET_TO_FILTER_HISTORY_ROUTE: {
      const route:
        | PromptNewWalletRoute
        | PromptCryptoToReceiveRoute
        | PromptSourceCryptoToExchangeRoute
        | PromptWalletToFilterHistoryRoute = {
        kind,
        params: {search: path.params?.s?.[0] ?? undefined},
      };
      return [{path, route}];
    }
    case SHOW_WALLET_ROUTE: {
      const currencyCode = extractString(path, 'c');
      if (isVoid(second) || isVoid(currencyCode)) {
        return createFallbackState(path);
      }
      const route: ShowWalletRoute = {
        kind,
        params: {
          walletId: second as WalletId,
          currencyCode,
        },
      };
      return [{path, route}];
    }
    case PROMPT_INPUT_NETWORK_ROUTE:
    case PROMPT_ADDRESS_FORMAT_ROUTE: {
      const addressNetwork = extractString<AddressNetwork>(path, 'net');
      const currency = extractString<CryptoCurrencyCode>(path, 'c');
      const addressCurrency = extractString<CryptoCurrencyCode>(path, 'ac');
      if (
        second === undefined ||
        isVoid(addressNetwork) ||
        isVoid(currency) ||
        isVoid(addressCurrency)
      ) {
        return createFallbackState(path);
      }
      const route: PromptAddressFormatRoute | PromptInputNetworkRoute = {
        kind,
        params: {
          walletId: second as WalletId,
          addressCurrency,
          addressNetwork,
        },
      };
      return popFromChainIfPossible({
        path,
        route,
      });
    }
    case PROMPT_RECEIVE_NETWORK_ROUTE: {
      const addressNetwork = extractString<AddressNetwork>(path, 'net');
      const addressCurrency = extractString<CryptoCurrencyCode>(path, 'c');
      const address = extractString<CryptoAddress>(path, 'a');
      const networkFilter = extractString<AddressNetwork>(path, 'snet');
      const currencyFilter = extractString<CryptoCurrencyCode>(path, 'sc');
      if (
        second === undefined ||
        isVoid(addressNetwork) ||
        isVoid(addressCurrency) ||
        isVoid(address) ||
        isVoid(networkFilter) ||
        isVoid(currencyFilter)
      ) {
        return createFallbackState(path);
      }
      const route: PromptReceiveNetworkRoute = {
        kind,
        params: {
          walletId: second as WalletId,
          address,
          addressCurrency,
          addressNetwork,
          networkFilter,
          currencyFilter,
        },
      };
      return popFromChainIfPossible({
        path,
        route,
      });
    }
    case LIST_INPUT_ADDRESSES_ROUTE: {
      const network = extractString<AddressNetwork>(path, 'net');
      const currency = extractString<CryptoCurrencyCode>(path, 'c');
      const address = extractString<CryptoAddress>(path, 'a');
      const networkFilter = extractString<AddressNetwork>(path, 'snet');
      const currencyFilter = extractString<CryptoCurrencyCode>(path, 'sc');
      if (
        second === undefined ||
        isVoid(network) ||
        isVoid(currency) ||
        isVoid(address) ||
        isVoid(networkFilter) ||
        isVoid(currencyFilter)
      ) {
        return createFallbackState(path);
      }
      const route: ListInputAddressesRoute = {
        kind,
        params: {
          walletId: second as WalletId,
          address,
          addressNetwork: network,
          addressCurrency: currency,
          networkFilter,
          currencyFilter,
        },
      };
      return [{route, path}];
    }
    case SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE: {
      const address = path.params?.a?.[0] ?? undefined;
      const network = extractString<AddressNetwork>(path, 'net');
      const currency = extractString<CryptoCurrencyCode>(path, 'c');
      if (
        second === undefined ||
        address === undefined ||
        isVoid(network) ||
        isVoid(currency)
      ) {
        return createFallbackState(path);
      }
      const route: ShowQrToReceiveCryptoRoute = {
        kind,
        params: {
          walletId: second as WalletId,
          address: address as CryptoAddress,
          addressNetwork: network,
          addressCurrency: currency,
        },
      };
      return [{route, path}];
    }
    case PROMPT_OUTPUT_ADDRESS_ROUTE: {
      const network = extractString<AddressNetwork>(path, 'net');
      const currency = extractString<CryptoCurrencyCode>(path, 'c');
      const minFreeWithdrawAmount = extractPositiveDecimalString(path, 'mfwa');
      if (second === undefined || isVoid(network) || isVoid(currency)) {
        return createFallbackState(path);
      }
      const route: PromptOutputAddressRoute = {
        kind,
        params: {
          walletId: second as WalletId,
          addressNetwork: network,
          addressCurrency: currency,
          addressTo: path.params?.ato?.[0] ?? undefined,
          userTo: path.params?.uto?.[0] ?? undefined,
          amount: extractPositiveDecimalString(path, 'sum'),
          comment: path.params?.mes?.[0] ?? undefined,
          isEditable: extractBoolean(path, 'edit'),
          minFreeWithdrawAmount,
          ...(path.params && extractFeeParams(path)),
        },
      };
      return [{route, path}];
    }
    case PROMPT_OUTPUT_NETWORK_ROUTE:
    case PROMPT_AMOUNT_TO_SEND_ROUTE: {
      const base = extractBaseSendParams(path);
      const addressTo = path.params?.ato?.[0] ?? undefined;
      if (base === undefined || addressTo === undefined) {
        return createFallbackState(path);
      }
      const route: PromptAmountToSendRoute | PromptOutputNetworkRoute = {
        kind,
        params: {
          addressTo: addressTo,
          amount: extractPositiveDecimalString(path, 'sum'),
          isEditable: extractBoolean(path, 'edit'),
          ...base,
          ...(path.params && extractFeeParams(path)),
        },
      };
      return [{route, path}];
    }
    case SHOW_QR_ROUTE: {
      const address = path.params?.a?.[0] ?? undefined;
      const addressNetwork = extractString<AddressNetwork>(path, 'net');
      const addressCurrency = extractString<CryptoCurrencyCode>(path, 'c');
      if (
        second === undefined ||
        address === undefined ||
        isVoid(addressNetwork) ||
        isVoid(addressCurrency)
      ) {
        return createFallbackState(path);
      }
      const route: ShowQrRoute = {
        kind,
        params: {
          walletId: second as WalletId,
          address: address as CryptoAddress,
          addressNetwork,
          addressCurrency,
        },
      };
      return [{route, path}];
    }
    case GENERATE_PAY_ME_LINK_ROUTE: {
      const route: GeneratePayMeLinkRoute = {
        kind,
        params: {
          address: path.params?.address?.[0] as CryptoAddress,
          currency: path.params?.currency?.[0] as CryptoCurrencyCode,
          amount: extractPositiveDecimalString(path, 'amount'),
        },
      };
      return [{route, path}];
    }
    case PROMPT_COMMENT_TO_SEND_ROUTE: {
      const base = extractBaseSendParams(path);
      const addressTo = path.params?.ato?.[0] ?? undefined;
      const amount = extractPositiveDecimalString(path, 'sum');
      if (
        base === undefined ||
        addressTo === undefined ||
        amount === undefined
      ) {
        return createFallbackState(path);
      }
      const route: PromptCommentToSendRoute = {
        kind,
        params: {
          addressTo,
          comment: extractString(path, 'mes'),
          amount,
          ...base,
          ...(path.params && extractFeeParams(path)),
        },
      };
      return [{route, path}];
    }
    case PROMPT_ADD_IP_ROUTE: {
      const type = extractString<IpType>(path, 'type');
      if (!type) {
        return createFallbackState(path);
      }
      const route = {kind, params: {type}};
      return [{route, path}];
    }
    case PROMPT_UPDATE_IP_ROUTE:
    case PROMPT_OTP_TO_UPDATE_IP_ROUTE: {
      const ip = extractString<Ip>(path, 'ip');
      const id = extractString<IpInfoId>(path, 'id');
      const name = extractString(path, 'n');
      const type = extractString<IpType>(path, 'type');
      if (!ip || !id || !name || !type) {
        return createFallbackState(path);
      }

      const route = {kind, params: {id, ip, name, type}};
      return [{route, path}];
    }
    case PROMPT_OTP_TO_DELETE_IP_ROUTE: {
      const id = extractString<IpInfoId>(path, 'id');
      const type = extractString<IpType>(path, 'type');

      if (!id || !type) {
        return createFallbackState(path);
      }

      const route: PromptOtpToDeleteIpRoute | PromptToDeleteIpRoute = {
        kind,
        params: {id, type},
      };
      return [{route, path}];
    }
    case PROMPT_TO_DELETE_IP_ROUTE: {
      const id = extractString<IpInfoId>(path, 'id');
      const ip = extractString<Ip>(path, 'ip');
      const type = extractString<IpType>(path, 'type');

      if (!id || !ip || !type) {
        return createFallbackState(path);
      }

      const route = {kind, params: {id, ip, type}};
      return [{route, path}];
    }
    case PROMPT_OTP_TO_ADD_IP_ROUTE: {
      const ip = extractString<Ip>(path, 'ip');
      const type = extractString<IpType>(path, 'type');
      const name = extractString(path, 'n');

      if (!ip || !type || !name) {
        return createFallbackState(path);
      }

      const route: PromptOtpToAddIpRoute = {
        kind,
        params: {ip, type, name},
      };
      return [{route, path}];
    }

    case PROMPT_COMMISSION_FOR_SENDING_ROUTE:
    case PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE: {
      const base = extractBaseSendParams(path);
      const addressTo = path.params?.ato?.[0] ?? undefined;
      const amount = extractPositiveDecimalString(path, 'sum');
      if (
        base === undefined ||
        addressTo === undefined ||
        amount === undefined
      ) {
        return createFallbackState(path);
      }
      if (kind === PROMPT_COMMISSION_FOR_SENDING_ROUTE) {
        const route: PromptCommissionForSendingRoute = {
          kind,
          params: {
            fee: extractPositiveDecimalString(path, 'fee'),
            isEditable: extractBoolean(path, 'edit'),
            addressTo: addressTo,
            amount,
            hasCommission: ignoreFalse(extractBoolean(path, 'com')),
            comment: path.params?.mes?.[0] ?? undefined,
            ...base,
          },
        };
        return [{route, path}];
      }
      const route: PromptConfirmationToSendCryptoRoute = {
        kind,
        params: {
          comment: path.params?.mes?.[0] ?? undefined,
          addressTo: addressTo,
          amount,
          ...base,
          ...(path.params && extractFeeParams(path)),
        },
      };
      return [{route, path}];
    }
    case PROMPT_OTP_TO_SEND_CRYPTO_ROUTE: {
      if (second === undefined) {
        return createFallbackState(path);
      }
      const receipt = extractSendReceipt(path);
      if (receipt === undefined) {
        return createFallbackState(path);
      }
      const route: PromptOtpToSendCryptoRoute = {
        kind,
        params: {withdrawId: second, ...receipt},
      };
      return [{route, path}];
    }
    case NOTIFY_ABOUT_SUCCESSFUL_SENDING_ROUTE: {
      const receipt = extractSendReceipt(path);
      if (receipt === undefined) {
        return createFallbackState(path);
      }
      const route: NotifyAboutSuccessfulSendingRoute = {
        kind,
        params: {error: path.params?.e?.[0] ?? undefined, ...receipt},
      };
      return [{route, path}];
    }
    case PROMPT_EXCHANGE_RECEIPT_ROUTE: {
      const receipt = extractExchangeReceipt(path);
      if (receipt === undefined) {
        return createFallbackState(path);
      }
      const route: PromptExchangeReceiptRoute = {
        kind,
        params: receipt,
      };
      return [{route, path}];
    }
    case PROMPT_CRYPTO_TO_EXCHANGE_ROUTE: {
      const receipt = extractExchangeReceipt(path);
      const isSourceRequested = extractBoolean(path, 'src');
      if (receipt === undefined || isSourceRequested === undefined) {
        return createFallbackState(path);
      }
      const route: PromptCryptoToExchangeRoute = {
        kind,
        params: {
          isSourceRequested,
          disabledCrypto: extractString(path, 'disabled') as
            | CryptoCurrencyCode
            | undefined,
          search: path.params?.s?.[0] ?? undefined,
          ...receipt,
        },
      };
      return [{route, path}];
    }
    case PROMPT_OTP_TO_EXCHANGE_ROUTE:
    case PROMPT_CONFIRMATION_FOR_EXCHANGE_ROUTE: {
      const token = extractString(path, 'token');
      const exchangeRate = extractPositiveDecimalString(path, 'r') as RateValue<
        CurrencyCode,
        CurrencyCode
      >;
      const date = extractInteger<Millisecond>(path, 'd', 36);
      const summary = extractExchangeSummary(path);
      const walletIdFrom = extractString<WalletId>(path, 'wFrom');

      if (
        isVoid(token) ||
        isVoid(exchangeRate) ||
        isVoid(date) ||
        isVoid(summary) ||
        isVoid(walletIdFrom)
      ) {
        return createFallbackState(path);
      }
      const fee = extractString(path, 'fee');
      const route:
        | PromptConfirmationForExchangeRoute
        | PromptOtpToExchangeRoute = {
        kind,
        params: {
          token,
          exchangeRate,
          date,
          fee,
          walletIdFrom,
          walletIdTo: extractString<WalletId>(path, 'wTo'),
          isTargetValueLastChanged: !!extractBoolean(path, 'isT'),
          ...summary,
        },
      };
      return [{route, path}];
    }
    case NOTIFY_ABOUT_SUCCESSFUL_EXCHANGE_ROUTE: {
      const summary = extractExchangeSummary(path);
      if (isVoid(summary)) {
        return createFallbackState(path);
      }
      const route: NotifyAboutSuccessfulExchangeRoute = {kind, params: summary};
      return [{route, path}];
    }
    case LIST_HISTORY_ROUTE: {
      const walletId = extractString<WalletId>(path, 'wId');
      const base = extractBaseFilterParams(path);
      const route: ListHistoryRoute = {
        kind,
        params: {walletId, ...base},
      };
      return [{route, path}];
    }
    case IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE: {
      const documentType = extractString<AccountDocumentType>(path, 'type');
      const route: IdentityVerificationUploadDocumentRoute = {
        kind,
        params: {type: documentType ?? ''},
      };
      return [{route, path}];
    }
    case PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE: {
      const documentId = extractString<AccountDocumentId>(path, 'documentId');
      const file = extractString<AccountDocumentFileName>(path, 'file');

      if (documentId === undefined || file === undefined) {
        return createFallbackState(path);
      }

      const route: PromptToDeleteDocumentFileRoute = {
        kind,
        params: {
          documentId,
          file,
        },
      };
      return [{route, path}];
    }
    case LIST_HISTORY_FILTERS_ROUTE: {
      const walletId = extractString<WalletId>(path, 'wid');
      const base = extractBaseFilterParams(path);
      const route: ListHistoryFiltersRoute = {
        kind,
        params: {walletId, ...base},
      };
      return [{route, path}];
    }
    case PROMPT_PERIOD_TO_FILTER_HISTORY_ROUTE: {
      const route: PromptPeriodToFilterHistoryRoute = {
        kind,
        params: {
          from: extractInteger(path, 'from', 36),
          to: extractInteger(path, 'to', 36),
        },
      };
      return [{route, path}];
    }
    case PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE: {
      const isSourceRequested = extractBoolean(path, 's');
      if (isVoid(isSourceRequested)) {
        return createFallbackState(path);
      }
      const route: PromptDateOfHistoryPeriodRoute = {
        kind,
        params: {
          isSourceRequested,
          value: extractInteger(path, 'value', 36),
        },
      };
      return popFromChainIfPossible({route, path});
    }
    case SHOW_TRANSACTION_ROUTE: {
      if (isVoid(second)) {
        return createFallbackState(path);
      }
      const route: ShowTransactionRoute = {kind, params: {id: second}};
      return [{route, path}];
    }

    case SHOW_TRANSACTION_REPORT_ROUTE: {
      const txId = extractString(path, 'id');

      if (txId === undefined) {
        return createFallbackState(path);
      }

      const route: ShowTransactionReportRoute = {
        kind,
        params: {
          id: txId,
        },
      };
      return [{route, path}];
    }
    case PROMPT_FEEDBACK_ROUTE: {
      const rating = extractInteger<StarValue>(path, 'r', 10);
      if (isVoid(rating)) {
        return createFallbackState(path);
      }
      const route: PromptFeedbackRoute = {kind, params: {rating}};
      return popFromChainIfPossible({route, path});
    }
    case LIST_QR_CODE_HISTORY_ROUTE: {
      const route: ListQrCodeHistoryRoute = {
        kind,
        params: {
          address: extractString(path, 'address'),
          error: extractInteger(path, 'error'),
        },
      };
      return [{route, path}];
    }

    case SHOW_LIMIT_ROUTE: {
      const walletId = extractString<WalletId>(path, 'w');
      const period = extractString<WalletLimitPeriod>(path, 'p');
      if (isVoid(walletId) || isVoid(period)) {
        return createFallbackState(path);
      }

      const route: ShowLimitRoute = {
        kind,
        params: {walletId, period},
      };

      return [{route, path}];
    }
    case PROMPT_NEW_LIMIT_ROUTE: {
      const walletId = extractString<WalletId>(path, 'w');
      const currency = extractString<CryptoCurrencyCode>(path, 'c');

      const period = extractString<WalletLimitPeriod>(path, 'p');

      if (isVoid(walletId) || isVoid(currency) || isVoid(period)) {
        return createFallbackState(path);
      }

      const route: PromptNewLimitRoute = {
        kind,
        params: {walletId, currency, period},
      };

      return [{route, path}];
    }

    case PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE:
    case PROMPT_OTP_TO_LIMIT_CREATE_ROUTE: {
      const walletId = extractString<WalletId>(path, 'w');
      const period = extractString<WalletLimitPeriod>(path, 'p');
      const limit = extractPositiveDecimalString(path, 'l');
      if (isVoid(walletId) || isVoid(period) || isVoid(limit)) {
        return createFallbackState(path);
      }

      const route: PromptOtpToLimitCreateRoute | PromptOtpToLimitUpdateRoute = {
        kind,
        params: {walletId, period, limit},
      };

      return [{route, path}];
    }

    case PROMPT_LIMIT_UPDATE_ROUTE: {
      const limit = extractPositiveDecimalString(path, 'l');
      const currency = extractString<CryptoCurrencyCode>(path, 'c');
      const walletId = extractString<WalletId>(path, 'w');
      const period = extractString<WalletLimitPeriod>(path, 'p');
      if (
        isVoid(limit) ||
        isVoid(currency) ||
        isVoid(walletId) ||
        isVoid(period)
      ) {
        return createFallbackState(path);
      }

      const route: PromptLimitUpdateRoute = {
        kind,
        params: {
          limit,
          period,
          walletId,
          currency,
        },
      };
      return [{route, path}];
    }
    case PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE:
    case PROMPT_LIMIT_REMOVAL_ROUTE: {
      const limitId = extractString<WalletLimitId>(path, 'id');
      const action = extractInt<LimitRemovalAction>(path, 'a');
      if (isVoid(limitId) || isVoid(action)) {
        return createFallbackState(path);
      }
      const route: PromptLimitRemovalRoute | PromptOtpToLimitRemovalRoute = {
        kind,
        params: {limitId, action},
      };
      return [{route, path}];
    }
  }

  return [{route: createSimpleRoute(kind), path}];
}

const pathRouteMap = turnOut(routePathMap);

const ROOT_ALIASES = new Set(['ref', 'index.html', 'popup.html']);

function rootPathToState(path: Path): StrictCommonState {
  const uri = extractString(path, 'uri');
  if (uri) {
    const params = parseRedirectToSendRouteParamsFromUri(uri);
    if (params) {
      const route: RedirectToSendRoute = {
        kind: REDIRECT_TO_SEND_ROUTE,
        params,
      };
      return [{route, path}];
    }
  }
  return [{route: {kind: LIST_WALLETS_ROUTE}, path}];
}

function popFromChainIfPossible(top: StrictCommonRouteMeta): StrictCommonState {
  const previous = popPathFromChain(top.path);
  if (previous === undefined) {
    return [top];
  }
  return [...pathToState(previous), top];
}

function parseRedirectToSendRouteParamsFromUri(
  uri: string,
): RedirectToSendRouteParams | undefined {
  const matches = parseCryptoUri(uri);
  if (isNil(matches)) {
    return undefined;
  }
  const _protocol = matches.protocol;
  if (!(KNOWN_CRYPTO_PROTOCOLS as string[]).includes(_protocol)) {
    return undefined;
  }
  const rest = matches.rest;
  const path = PathImpl.parse(rest);
  const protocol = _protocol as CryptoProtocol;
  const crypto = CRYPTO_PROTOCOL_TO_CODE_MAP[protocol];
  const [address] = path.pathname;

  const amount = extractPositiveDecimalString(path, 'amount');
  const value = extractPositiveDecimalString(path, 'value');

  const result: RedirectToSendRouteParams = {
    crypto,
    address: address as CryptoAddress,
    amount,
    value,
  };

  if (uri.includes('transfer')) {
    result.externalFrom = uri;
  }

  return result;
}

export const parseCryptoUri = (uri: string) => {
  const matches = uri.match(/^(?:web\+)?(.+?):\/*(.+)$/);
  if (matches === null) {
    return undefined;
  }

  return {protocol: matches[1], rest: matches[2]};
};

function extractBaseFilterParams(path: Path): BaseFilterParams {
  return {
    from: extractInteger(path, 'from', 36),
    to: extractInteger(path, 'to', 36),
    transactionFilterKind: translateTransactionFilterKind(
      extractString(path, 'kind'),
    ),
  };
}

function translateTransactionFilterKind(
  _: string | undefined,
): TransactionFilterKind | undefined {
  switch (_) {
    case 'all':
    case 'to':
    case 'from':
    case 'withdraw':
    case 'incoming':
    case 'exchange':
    case 'rollback':
      return _ as TransactionFilterKind;
  }
  return undefined;
}

function extractExchangeSummary(path: Path): ExchangeSummary | undefined {
  const valueFrom = extractString(path, 'vFrom');
  const valueTo = extractString(path, 'vTo');
  const currencyFrom = extractString<CryptoCurrencyCode>(path, 'cFrom');
  const currencyTo = extractString<CryptoCurrencyCode>(path, 'cTo');
  if (
    isVoid(valueFrom) ||
    isVoid(valueTo) ||
    isVoid(currencyFrom) ||
    isVoid(currencyTo)
  ) {
    return undefined;
  }
  return {valueFrom, valueTo, currencyFrom, currencyTo};
}

function isVoid(_: unknown): _ is undefined {
  return _ === undefined;
}

function extractExchangeReceipt(path: Path): ExchangeReceipt | undefined {
  const [, second] = path.pathname;
  if (isVoid(second)) {
    return undefined;
  }
  const walletIdTo = extractString<WalletId>(path, 'to');
  const currencyTo = extractString<CryptoCurrencyCode>(path, 'cto');
  const value = extractString(path, 'v');
  const isTargetValue = extractBoolean(path, 't');

  return {
    walletIdFrom: second as WalletId,
    walletIdTo,
    currencyTo,
    value,
    isTargetValue,
  };
}

function extractSendReceipt(path: Path): SendReceipt | undefined {
  if (isVoid(path.params)) {
    return undefined;
  }
  const addressTo = extractString(path, 'ato');
  const amount = extractPositiveDecimalString(path, 'sum');
  const currency = extractString(path, 'ccc');
  if (isVoid(addressTo) || isVoid(amount) || isVoid(currency)) {
    return undefined;
  }
  return {addressTo, amount, currency};
}

function extractBaseSendParams(path: Path): BaseSendParams | undefined {
  const [, second] = path.pathname;
  if (isVoid(second) || isVoid(path.params)) {
    return undefined;
  }
  const currency = extractString<CryptoCurrencyCode>(path, 'ccc');
  const addressCurrency = extractString<CryptoCurrencyCode>(path, 'ac');
  const addressNetwork = extractString<AddressNetwork>(path, 'net');
  const minFreeWithdrawAmount = extractPositiveDecimalString(path, 'mfwa');
  if (isVoid(currency) || isVoid(addressNetwork) || isVoid(addressCurrency)) {
    return undefined;
  }
  return {
    walletId: second as WalletId,
    currency,
    addressNetwork,
    addressCurrency,
    minFreeWithdrawAmount,
  };
}

function extractFeeParams(path: Path): FeeParams {
  return {
    fee: extractPositiveDecimalString(path, 'fee'),
    feeChangeDisabled: extractBoolean(path, 'fixed'),
  } as FeeParams;
}

function createFallbackState(path: Path): StrictCommonState {
  const route: FallBackRoute = {kind: FALL_BACK_ROUTE};
  return [{route, path}];
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
function extractString<T extends string>(
  path: Path,
  key: string,
): T | undefined {
  return (path.params?.[key]?.[0] ?? undefined) as T | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
function extractInt<T extends number>(path: Path, key: string): T | undefined {
  const str = extractString(path, key);
  if (isNil(str)) {
    return undefined;
  }
  const parsed = parseInt(str, 10);
  if (!isFinite(parsed)) {
    return undefined;
  }

  return parsed as T;
}

function extractPositiveDecimalString(
  path: Path,
  key: string,
): DecimalString | undefined {
  const str = extractString(path, key);
  return isVoid(str) || !BigNumber(str).isPositive() ? undefined : str;
}

function extractPath(path: Path, key: string): Path | undefined {
  const _ = extractString(path, key);
  return isVoid(_) ? _ : new LazyPathImpl(_);
}

function extractBoolean(path: Path, key: string): boolean | undefined {
  const _ = path.params?.[key]?.[0];
  return (
    _ === null ||
    (_ === undefined ? _ : booleanMap[_ as keyof typeof booleanMap])
  );
}

const booleanMap = {
  0: false,
  1: true,
  false: false,
  true: true,
  no: false,
  yes: true,
  off: false,
  on: true,
} as const;

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
function extractInteger<T extends number>(
  path: Path,
  key: string,
  radix = 10,
): T | undefined {
  const _ = extractString(path, key);
  return _ === undefined ? _ : (parseInt(_, radix) as T);
}

function ignoreFalse(_: boolean | undefined): true | undefined {
  return _ === false ? undefined : _;
}

type SimpleRoute =
  | ListNotificationSettingsRoute
  | ShowProfileRoute
  | PromptNicknameRoute
  | PromptUserIdRoute
  | PromptEmailRoute
  | PromptNameAndBirthdateRoute
  | PromptResidentialAddressRoute
  | ListPersonalDataRoute
  | ListDocumentsRoute
  | PromptBaseCryptoRoute
  | PromptBaseFiatRoute
  | ShowTwoFASettingsRoute
  | ListAuthAppsRoute
  | PromptOtpToDisableTwoFaRoute
  | PromptOtpToChangeTwoFaSettingsRoute
  | ListAllowedIpsRoute
  | PromptUpdateIpRoute
  | BlockedIpsListRoute
  | ListSessionHistoryRoute
  | ListActiveSessionsRoute
  | PromptSessionDeletionRoute
  | CheckPinRoute
  | CheckBiometricsPinRoute
  | PromptOtpToBiometricsRoute
  | PromptBiometricSettingsRoute
  | PromptNewPinRoute
  | NotifyAboutSuccessfulPinChangeRoute
  | ListLimitsRoute
  | PromptWalletGroupToFilterHistoryRoute
  | PromptTransactionKindToFilterHistoryRoute
  | ShowQrCodeScannerRoute
  | ListQrCodeHistoryRoute
  | PromptRateRoute
  | DebugRoute
  | ListLogsRoute
  | ListSignOutReasonLogsRoute
  | ListEnvironmentsRoute
  | PromptOtpToDeleteAccountRoute
  | PromptEnvironmentFormRoute
  | PromptToClearAllowedListRoute
  | PromptOtpToClearAllowedListRoute
  | AddIdentityNewDocumentKindRoute
  | PromptIdentityVerificationAddressRoute
  | PromptToDeleteDocumentFileRoute
  | TwoFaWelcomeRoute
  | PromptSelectTwoFaProviderRoute;

function createSimpleRoute(kind: SimpleRoute['kind']) {
  return {kind} as SimpleRoute;
}
