import type {Millisecond} from '@ncwallet-app/core';
import {
  toSecond,
  useRoot,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {Op} from '@ncwallet-app/core/src/Math';
import type {OptionalGetter} from '@ncwallet-app/core/src/mobx-toolbox';
import {use} from '@ncwallet-app/core/src/mobx-toolbox';
import {
  Button,
  ButtonColor,
  LG_BREAKPOINT,
  ModalContainer,
  TouchableOpacity,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  AppStoreSvg,
  GooglePlaySvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import type {ComponentProps} from 'react';
import React, {useCallback} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import RateModalBody from './RateModalBody';

export type RateModalScreenProps = {
  getRating: OptionalGetter<number>;
  onChange: (rating: number) => void;
  onClose: () => void;

  getDeadline?: OptionalGetter<Millisecond | undefined>;

  getIsAppStoreButtonDisplayed?: OptionalGetter<boolean | undefined>;
  onGoToAppStore?: () => void;

  getIsGooglePlayButtonDisplayed?: OptionalGetter<boolean | undefined>;
  onGoToGooglePlay?: () => void;
};

export default observer(function RateModalScreen(props: RateModalScreenProps) {
  const strings = useStrings();
  const {
    getRating,
    onChange,
    onClose,
    getDeadline,
    getIsAppStoreButtonDisplayed,
    onGoToAppStore,
    getIsGooglePlayButtonDisplayed,
    onGoToGooglePlay,
  } = props;

  const isAppStoreButtonDisplayed = use(getIsAppStoreButtonDisplayed);
  const isGooglePlayButtonDisplayed = use(getIsGooglePlayButtonDisplayed);
  const shouldAskFeedback =
    isAppStoreButtonDisplayed || isGooglePlayButtonDisplayed;
  const exactlyOneStoreAvailable =
    isAppStoreButtonDisplayed !== isGooglePlayButtonDisplayed;

  const root = useRoot();
  const getCountdown = useCallback(() => {
    const deadline = use(getDeadline);
    if (deadline === undefined) {
      return undefined;
    }
    const now = root.timeState.getNow(INTERVAL);
    const compensated = Op.add(now, INTERVAL);
    if (compensated > deadline) {
      return undefined;
    }
    return `${Math.floor(toSecond(Op.subtract(deadline, now)))}s`;
  }, [getDeadline, root]);

  const isLg = useIsDimensions('lg');

  return (
    <RateModalBackdrop>
      <Content>
        <RateModalHeader
          getShouldAskFeedback={shouldAskFeedback}
          onClose={onClose}
        />
        <RateModalBody
          getRating={getRating}
          getShouldAskFeedback={shouldAskFeedback}
          onRatingChange={onChange}
        />
        <View style={isLg && styles.buttonContainer}>
          <CloseButton
            style={!isLg && styles.button}
            borderStyle={!isLg && styles.button}
            title={
              shouldAskFeedback
                ? strings['rateModalScreen.cancelWithRate']
                : strings['rateModalScreen.cancel']
            }
            color={ButtonColor.Secondary}
            onPress={onClose}
          />
          {isGooglePlayButtonDisplayed && (
            <ButtonWithCountdown
              style={!isLg && styles.button}
              borderStyle={!isLg && styles.button}
              getCountdown={getCountdown}
              title={strings['rateModalScreen.goToGoogleStore']}
              onPress={onGoToGooglePlay}
              color={ButtonColor.White}
              Icon={!exactlyOneStoreAvailable ? GooglePlaySvg : undefined}
            />
          )}
          {isAppStoreButtonDisplayed && (
            <ButtonWithCountdown
              style={!isLg && styles.button}
              borderStyle={!isLg && styles.button}
              getCountdown={getCountdown}
              title={strings['rateModalScreen.goToAppstore']}
              onPress={onGoToAppStore}
              color={ButtonColor.White}
              Icon={!exactlyOneStoreAvailable ? AppStoreSvg : undefined}
            />
          )}
          {!shouldAskFeedback && (
            <DoneButton
              style={!isLg && styles.button}
              borderStyle={!isLg && styles.button}
              title={strings['rateModalScreen.done']}
              color={ButtonColor.White}
              onPress={onClose}
            />
          )}
        </View>
      </Content>
    </RateModalBackdrop>
  );
});

const INTERVAL = 500 as Millisecond;

const styles = StyleSheet.create({
  button: {
    borderRadius: 0,
  },
  buttonContainer: {
    gap: 10,
  },
});

const Content = variance(View)(theme => ({
  root: {
    width: 315,
    backgroundColor: theme.palette.background,
    borderRadius: 6,
    overflow: 'hidden',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 450,
        paddingHorizontal: 20,
        paddingVertical: 20,
        shadowColor: '#000000',
        shadowOffset: {
          width: 0,
          height: 10,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
      },
    }),
  },
}));

type RateModalHeaderProps = {
  getShouldAskFeedback?: OptionalGetter<boolean | undefined>;
  onClose?: () => void;
};

const RateModalHeader = observer(function RateModalHeader(
  props: RateModalHeaderProps,
) {
  const {getShouldAskFeedback, onClose} = props;
  const strings = useStrings();
  const theme = useTheme();
  const isLg = useIsDimensions('lg');
  return (
    <RateModalHeaderView>
      <RateModalHeaderText>
        {use(getShouldAskFeedback)
          ? strings['rateModalScreen.titleWithRate']
          : strings['RateModalScreen.title']}
      </RateModalHeaderText>
      {isLg && (
        <RateModalClose>
          <TouchableOpacity onPress={onClose}>
            <CrossSvg color={theme.palette.uiMain} />
          </TouchableOpacity>
        </RateModalClose>
      )}
    </RateModalHeaderView>
  );
});

const RateModalHeaderView = variance(View)(theme => ({
  root: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 15,
    minHeight: 50,
    backgroundColor: theme.palette.uiPrimary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left',
        minHeight: 'auto',
        paddingHorizontal: 0,
        backgroundColor: theme.palette.background,
        paddingVertical: 0,
        marginBottom: 15,
      },
    }),
  },
}));

const RateModalClose = variance(View)(theme => ({
  root: {
    position: 'absolute',
    right: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        position: 'relative',
        right: 0,
      },
    }),
  },
}));

const RateModalHeaderText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 22,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        position: 'relative',
        width: 'auto',
        left: 0,
        marginLeft: 0,
        marginRight: 0,
      },
    }),
  },
}));

const RateModalBackdrop = variance(ModalContainer)(theme => ({
  root: {
    backgroundColor: theme.chroma('#000000').alpha(0.7).hex(),
  },
}));

type ButtonWithCountdownProps = {
  getCountdown?: OptionalGetter<string | undefined>;
} & ComponentProps<typeof DoneButton>;

const ButtonWithCountdown = observer(function ButtonWithCountdown(
  props: ButtonWithCountdownProps,
) {
  const {getCountdown, ...rest} = props;
  const isCountdownDisplayed = expr(() => use(getCountdown) !== undefined);
  const button = <DoneButton {...rest} />;
  if (isCountdownDisplayed) {
    return (
      <View>
        {button}
        <ElapsedTimeToCloseView>
          <ElapsedTimeToCloseText>{use(getCountdown)}</ElapsedTimeToCloseText>
        </ElapsedTimeToCloseView>
      </View>
    );
  }
  return button;
});

const ElapsedTimeToCloseView = variance(View)(() => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 20,
    justifyContent: 'center',
  },
}));

const ElapsedTimeToCloseText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 20,
    color: theme.palette.whitey,
  },
}));

const DoneButton = variance(Button)(theme => ({
  root: {
    borderWidth: 0,
    width: '100%',
    backgroundColor: theme.palette.info,
  },
}));

const CloseButton = variance(Button)(theme => ({
  root: {
    borderWidth: 0,
    backgroundColor: theme.palette.uiPrimary,
    width: '100%',
  },
}));
