import {useRoot} from '@ncwallet-app/core';
import type {AvailableLanguageCode} from '@ncwallet-app/core/src/Localization/constant';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {LanguageSettingsScreen} from '../../../screens/UserSettingsScreens/LanguageSettingsScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptLanguageBindingProps =
  SmallHomeStackBindingProps<'PromptLanguage'>;

export default observer(function PromptLanguageBinding(
  props: PromptLanguageBindingProps,
) {
  const {navigation} = props;
  const {userPreferenceState} = useRoot();
  const onLanguagePress = useCallback(
    (language: AvailableLanguageCode) => {
      void userPreferenceState.setLanguageCode(language);
      navigation.goBack();
    },
    [navigation, userPreferenceState],
  );

  return (
    <LanguageSettingsScreen
      activeItem={userPreferenceState.languageCode}
      insets={SafeAreaInset.BOTTOM}
      onLanguagePress={onLanguagePress}
    />
  );
});
