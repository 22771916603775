import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import type {
  CurrencyDescription,
  DecimalString,
  WalletLimitPeriod,
} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';

import LimitSkeleton from '../shared/LimitSkeleton';
import CreateLimitForm from './CreateLimitForm/CreateLimitForm';

export type PromptNewLimitCardProps = {
  onSubmit: (limit: DecimalString, period: WalletLimitPeriod) => void;
  period: WalletLimitPeriod;
  perOnePeriodOnly?: boolean;
  crypto: CurrencyDescription | undefined;
  error: LocaleKeys | undefined;
  resetError: () => void;
};

export default observer(function PromptNewLimitCard(
  props: PromptNewLimitCardProps,
) {
  const {resetError} = props;
  if (!props.crypto) {
    return <LimitSkeleton />;
  }
  return (
    <CreateLimitForm
      crypto={props.crypto}
      onSubmit={props.onSubmit}
      period={props.period}
      showPeriodTabs={!props.perOnePeriodOnly}
      error={props.error}
      resetError={resetError}
    />
  );
});
