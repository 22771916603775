import {useStrings, variance} from '@ncwallet-app/core';
import type {IpInfo} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  SafeAreaFlatList,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import type {FlatListProps, ListRenderItem, TextInput} from 'react-native';
import {View} from 'react-native';

import {useFocusInputOnNavigationFocus} from '../../../Navigation/hooks';
import IpListEmpty from './IpListEmpty';
import {IpListItem} from './IpListItem';
import IpListSkeleton from './IpListSkeleton';
import IpListWarning from './IpListWarning';

export type IpListProps = {
  ipInfos?: IpInfo[];
  type: IpType;
  isWarningShown?: boolean;
  onAdd: () => void;
  onClearAllowedList?: () => void;
  onIpPress: (ipInfo: IpInfo) => void;
};

export const IpList = observer((props: IpListProps) => {
  const {ipInfos, type, isWarningShown, onAdd, onClearAllowedList, onIpPress} =
    props;
  const ref = useRef<TextInput>(null);
  useFocusInputOnNavigationFocus(ref);
  const strings = useStrings();

  const renderItem: ListRenderItem<IpInfo> = useCallback(
    ({item}) => (
      <IpListItem
        item={item}
        onPress={() => {
          onIpPress(item);
        }}
      />
    ),
    [onIpPress],
  );

  if (ipInfos === undefined) {
    return <IpListSkeleton />;
  }

  return (
    <Container>
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {ipInfos && type === IpType.Blocked && isWarningShown && (
        <IpListWarning onClearPressed={onClearAllowedList} />
      )}
      {ipInfos.length ? (
        <BorderedContainer withBorderTop={isWarningShown}>
          <SafeAreaFlatList
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            data={ipInfos}
          />
        </BorderedContainer>
      ) : null}
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {ipInfos && !ipInfos.length && <IpListEmpty type={type} />}
      <ListIpButton
        title={strings['secureSettings.ipList.addButton']}
        color={ButtonColor.Secondary}
        variant={ButtonVariant.Highlighted}
        onPress={onAdd}
        testID="add-ip-btn"
      />
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    flex: 1,
    paddingBottom: 50,
  },
}));

const BorderedContainer = variance(View)(theme => ({
  root: {
    flex: 1,
  },
  withBorderTop: {
    flex: 1,
    borderTopWidth: 1,
    borderTopColor: theme.palette.uiSecondary,
  },
}));

const ListIpButton = variance(Button)(() => ({
  root: {
    marginTop: 'auto',
    marginHorizontal: 10,
  },
}));

type ListProps = FlatListProps<IpInfo>;
const keyExtractor: ListProps['keyExtractor'] = item => item.ip;
