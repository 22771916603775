import {variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {SafeAreaFlatList} from '@ncwallet-app/ui';
import {values} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {ListRenderItem} from 'react-native';
import {Text, View} from 'react-native';

import {HistoryWalletListItem} from './HistoryWalletListItem';
import type {HistoryWalletEntry, WalletProps} from './HistoryWalletScreen';

export type HistoryWalletListProps = BaseSafeAreaProps & {
  data: HistoryWalletEntry;
  onPress: () => void;
};

export default observer(function HistoryWalletList(
  props: HistoryWalletListProps,
) {
  const {data, ...rest} = props;
  const renderItem: ListRenderItem<WalletProps> = useCallback(
    ({item}) => <HistoryWalletListItem data={item} />,
    [],
  );
  const keyExtractor = useCallback(
    (item: WalletProps) => item.id.toString(),
    [],
  );
  return (
    <SafeAreaFlatList
      data={values(data)}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ListHeaderComponent={Header}
      stickyHeaderIndices={[0]}
      {...rest}
    />
  );
});

const Header = observer(() => {
  return (
    <HeaderRoot>
      <GroupTitle>Choose Wallet:</GroupTitle>
    </HeaderRoot>
  );
});

const HeaderRoot = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.background,
    paddingTop: 20,
    padding: 15,
    borderBottomColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
  },
}));

const GroupTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 22,
    color: theme.palette.textPrimary,
  },
}));
