import {useStrings, variance} from '@ncwallet-app/core';
import {SimpleButton} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {View} from 'react-native';

import type {AuthVariant, VariantItem} from '../AuthScreen';
import {AuthKind, OAuthVariant} from '../AuthScreen';
import {AppleIcon, GoogleIcon} from '../AuthScreen/icons';

export type MdAuthButtonProps = {
  authKind: AuthKind;
  onPress: (variant: AuthVariant) => void;
};

export default observer(function MdAuthButton({
  onPress,
  authKind,
}: MdAuthButtonProps) {
  const strings = useStrings();
  const listItems = useMemo((): VariantItem[] => {
    return authKind === AuthKind.Login
      ? [
          {
            title: strings['welcomeScreen.logInVia.google'],
            Icon: GoogleIcon,
            variant: OAuthVariant.Google,
          },
          {
            title: strings['welcomeScreen.logInVia.apple'],
            Icon: AppleIcon,
            variant: OAuthVariant.Apple,
          },
        ]
      : [
          {
            title: strings['welcomeScreen.signInVia.google'],
            Icon: GoogleIcon,
            variant: OAuthVariant.Google,
          },
          {
            title: strings['welcomeScreen.signInVia.apple'],
            Icon: AppleIcon,
            variant: OAuthVariant.Apple,
          },
        ];
  }, [strings, authKind]);

  return (
    <Container>
      {listItems.map((item: VariantItem, index: number) => (
        <ItemRoot key={index}>
          <SimpleButton
            Icon={item.Icon}
            title={item.title}
            onPress={() => {
              onPress(item.variant);
            }}
            testID={
              AuthKind.Login === authKind
                ? `login-${item.variant.toLocaleLowerCase()}-btn`
                : `signin-${item.variant.toLocaleLowerCase()}-btn`
            }
          />
        </ItemRoot>
      ))}
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    maxWidth: 375,
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 15,
    paddingHorizontal: 15,
  },
}));

const ItemRoot = variance(View)(() => ({
  root: {
    marginBottom: 20,
  },
}));
