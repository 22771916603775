import type {Millisecond} from '@ncwallet-app/core';
import {useRoot, variance} from '@ncwallet-app/core';
import type {AdvertContext} from '@ncwallet-app/core/src/Advert';
import {ModalContainer, SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import AdvertScreen from '../../screens/AdvertScreen';
import AdvertScreenBody from '../../screens/AdvertScreen/AdvertScreenBody';
import {EXTRA_BOTTOM_OFFSET} from '../../screens/constants';
import useAdvertProps from './useAdvertProps';

export type SplashSpotBindingProps = {
  context: AdvertContext;
  hideAd: (now: Millisecond) => Promise<void>;
};

export default observer(function SplashSpotBinding(
  props: SplashSpotBindingProps,
) {
  const {context, hideAd} = props;

  const root = useRoot();
  const [closeBanner, onActionPress, actions] = useAdvertProps(context, hideAd);

  const isLarge = root.layoutHelperState.isLarge;
  return isLarge ? (
    <Backdrop>
      <AdvertScreenBody
        body={context.text.body}
        title={context.text.title}
        image={context.images.image}
        actions={actions}
        onActionPress={onActionPress}
        onClose={closeBanner}
      />
    </Backdrop>
  ) : (
    <AdvertScreen
      body={context.text.body}
      title={context.text.title}
      image={context.images.image}
      actions={actions}
      onActionPress={onActionPress}
      onClose={closeBanner}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});

const Backdrop = variance(ModalContainer)(theme => ({
  root: {
    backgroundColor: theme.chroma('#000000').alpha(0.7).hex(),
  },
}));
