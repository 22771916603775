import {variance} from '@ncwallet-app/core';
import type {TabRowBaseItem} from '@ncwallet-app/ui';
import {TabRow} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {View} from 'react-native';

import {AffiliateTabKind} from './AffiliateKind';

type CurrencyReceiveTabKeyProps = {
  activeTabKey: AffiliateTabKind;
  onTabPress: (tabKey: AffiliateTabKind) => void;
  style?: StyleProp<ViewStyle>;
};

// eslint-disable-next-line import-x/prefer-default-export
export const Tabs = observer((props: CurrencyReceiveTabKeyProps) => {
  const tabs: TabRowBaseItem<AffiliateTabKind>[] = useMemo(
    () => [
      {
        key: AffiliateTabKind.Landing,
        text: 'affiliateScreen.tab.land',
      },
      {
        key: AffiliateTabKind.App,
        text: 'affiliateScreen.tab.app',
      },
      {
        key: AffiliateTabKind.Telegram,
        text: 'affiliateScreen.tab.telegram',
      },
    ],
    [],
  );
  return (
    <TabContainer>
      <TabRow
        items={tabs}
        onPress={tab => {
          props.onTabPress(tab.key);
        }}
        idKey={tab => tab.key}
        style={props.style}
        active={tab => tab.key === props.activeTabKey}
        textKey={tab => tab.text}
      />
    </TabContainer>
  );
});

const TabContainer = variance(View)(() => ({
  root: {
    paddingVertical: 20,
  },
}));
