import {WarningSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {InfoSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import React, {useMemo} from 'react';

import useRoot from '../../Root/hooks/useRoot';
import {variance} from '../../styling';
import {UserStatusError} from '../types';

export default function useStateErrorIcon() {
  const {userStatusErrorDetector} = useRoot();

  return useMemo((): React.ReactNode => {
    switch (userStatusErrorDetector.userStatusError) {
      case UserStatusError.Verification:
        return <InfoIcon />;
      case UserStatusError.Suspended:
      case UserStatusError.Banned:
        return <WarningSvgIcon />;
      default:
        return <></>;
    }
  }, [userStatusErrorDetector.userStatusError]);
}

const InfoIcon = variance(InfoSvg)(
  () => ({root: {}}),
  theme => ({
    color: theme.palette.whitey,
    width: 22,
    height: 22,
  }),
);

const WarningSvgIcon = variance(WarningSvg)(
  () => ({root: {}}),
  theme => ({
    color: theme.palette.whitey,
    width: 22,
    height: 22,
  }),
);
