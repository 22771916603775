import type {
  JsonRpcClient,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  WalletId,
  WalletLimit,
} from '@ncwallet-app/core';
import {WalletLimitStatus} from '@ncwallet-app/core';
import {BigNumber} from 'bignumber.js';
import {computed, makeObservable, observable, runInAction} from 'mobx';

// eslint-disable-next-line import-x/prefer-default-export
export class WalletLimitHelper {
  @observable private _limits: WalletLimit[] | undefined;

  @computed private get _walletIdToActiveLimits() {
    if (!this._limits) {
      return new Map<WalletId, WalletLimit[]>();
    }

    const activeLimits = this._limits.filter(
      l => l.status === WalletLimitStatus.Active,
    );

    return activeLimits.reduce((acc, limit) => {
      const walletLimits = acc.get(limit.wallet_id);
      if (walletLimits) {
        acc.set(limit.wallet_id, [...walletLimits, limit]);
      } else {
        acc.set(limit.wallet_id, [limit]);
      }

      return acc;
    }, new Map<WalletId, WalletLimit[]>());
  }

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {
    makeObservable(this);
  }

  async refresh() {
    const res = await this._root.ncWalletJsonRpcClient.call(
      'wallets.limits',
      {},
    );
    if (res.success) {
      runInAction(() => {
        this._limits = res.right.limits;
      });
    }
    return res;
  }

  getCurrentRemainingLimit(walletId: WalletId) {
    const limits = this._walletIdToActiveLimits.get(walletId);
    if (!limits) {
      return undefined;
    }

    const remainingLimits = limits.map(l =>
      BigNumber(l.limit).minus(l.accrued).toString(),
    );

    return BigNumber.min(...remainingLimits).toString();
  }
}
