import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, runInAction} from 'mobx';
import {useCallback, useEffect, useState} from 'react';

import {CurrencyHistoryRefresher} from '../../../shared/CurrencyHistoryRefresher';
import {CurrencySelectionListConverter} from '../../../shared/CurrencySelectionListConverter';
import {useRateHistoryStateOnWideScreen} from '../../../shared/useRateHistoryStateOnWideScreen';
import {useNavigationGetIsFocused} from '../../hooks';
import {SelectionForHistoryFilterBindingState} from './SelectionForHistoryFilterBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const useSelectionForHistoryFilterBindingState = () => {
  const root = useRoot();
  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  const [state] = useState(
    () =>
      new SelectionForHistoryFilterBindingState(
        root,
        new CurrencySelectionListConverter(root),
        new CurrencyHistoryRefresher(root),
      ),
  );

  useEffect(() => state.subscribe(), [state]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, state],
  );

  useRateHistoryStateOnWideScreen(state);

  const refresh = useCallback(() => {
    void state.refresh();
  }, [state]);

  return [state, refresh] as const;
};
