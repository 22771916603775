import type {LiteralBreakpoint} from '@ncwallet-app/ui';
import {useIsDimensions} from '@ncwallet-app/ui';
import {useEffect} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useRateHistoryStateOnWideScreen = (
  state: {
    activateRateHistoryLoad: () => void;
    deactivateRateHistoryLoad: () => void;
  },
  breakpoint: LiteralBreakpoint = 'lg',
) => {
  const isLg = useIsDimensions(breakpoint);
  useEffect(() => {
    if (isLg) {
      state.activateRateHistoryLoad();
    } else {
      state.deactivateRateHistoryLoad();
    }
  }, [isLg, state]);
};
