import {comparer, reaction} from 'mobx';

import type {ReadonlyNavigationContainerState} from '../NavigationContainer';
import type {Advert, AdvertSpot} from '../NCWalletServer';
import type {Service} from '../structure';
import getCurrentRouteKind from '../util/getCurrentRouteKind';
import type {AdvertSpotState} from './AdVisibilityState';
import BaseAdvertSpotStateImpl from './BaseAdvertSpotStateImpl';
import type {CommonAdvertSpotState} from './CommonAdvertSpotState';

export default abstract class TriggerAdvertSpotStateService
  extends BaseAdvertSpotStateImpl
  implements AdvertSpotState, Service
{
  protected constructor(
    protected readonly _spot: AdvertSpot,
    protected readonly _common: CommonAdvertSpotState,
    protected readonly _root: {
      readonly navigationContainerState: ReadonlyNavigationContainerState;
    },
  ) {
    super(_spot, _common);
  }

  protected abstract get _isOnSpot(): boolean;

  subscribe() {
    return reaction(this._computeChanges, this._processChanges, {
      equals: comparer.shallow,
    });
  }

  private readonly _computeChanges = (): TriggerChanges => {
    const pending = this._common.getPendingAd(this._spot);
    if (!pending) {
      return [];
    }

    const isInSpot = this._isOnSpot;
    return [
      pending,
      isInSpot,
      isInSpot &&
        getCurrentRouteKind(this._root.navigationContainerState.latestState),
    ];
  };

  private readonly _processChanges = async (
    current: TriggerChanges,
    previous: TriggerChanges,
  ) => {
    if (current.length === 0 || previous.length === 0) {
      return;
    }
    const [pending, isOnSpot, trigger] = current;
    const [, , _trigger] = previous;
    const triggered = isOnSpot && !Object.is(trigger, _trigger);
    if (triggered) {
      await this._common.displayAd(pending);
    }
  };
}

export type TriggerChanges =
  | []
  | [pending: Advert, isOnSpot: boolean, trigger: unknown];
