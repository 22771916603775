import {runInAction} from 'mobx';
import {InteractionManager, Platform} from 'react-native';

import {throwIfAborted} from '../Async';
import type {BaseAsyncOptions} from '../Async';

export default async function executeWithPostpone<R>(
  executor: (postpone: Postpone) => Promise<R>,
  options?: BaseAsyncOptions,
): Promise<R> {
  throwIfAborted(options?.signal);
  if (options?.signal?.aborted) {
    throw options.signal.reason ?? new Error('The operation was aborted');
  }
  const mutations: Mutation[] = [];
  const result = await executor(_ => {
    mutations.push(_);
  });
  const flush = () => {
    runInAction(() => {
      for (const mutate of mutations) {
        mutate();
      }
    });
  };
  if (Platform.OS === 'web') {
    flush();
  } else {
    const cancellable = InteractionManager.runAfterInteractions(flush);
    const abortListener = () => {
      cancellable.cancel();
    };
    options?.signal?.addEventListener('abort', abortListener, {once: true});
    await new Promise<void>((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
      cancellable.then(resolve, reject);
    });
    options?.signal?.removeEventListener('abort', abortListener);
  }
  return result;
}

export type Mutation = () => void;
export type Postpone = (mutate: Mutation) => void;

export type BaseTransactionOptions = {
  postpone?: Postpone;
};
