import type {
  CryptoAddress,
  CryptoCurrencyCode,
  CurrencyStore,
  DecimalString,
  JsonRpcClient,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  WalletId,
  WalletStore,
} from '@ncwallet-app/core';
import {getMaxAmountForWithdrawal, success} from '@ncwallet-app/core';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import type {WalletsWithdrawalsEstimatedFeeOptions} from '@ncwallet-app/core/src/NCWalletServer/WalletsWithdrawalsEstimatedFee';
import {BigNumber} from 'bignumber.js';
import {isNil} from 'lodash';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import type {WalletLimitHelper} from '../../../CommonNavigationContainers/hooks/usePromptExchangeReceiptContainer/WalletLimitHelper';

export enum SendCommissionBindingStateStatus {
  Load,
  Error,
  Ready,
}

export class SendCommissionBindingState {
  @observable private currencyCode: CryptoCurrencyCode | undefined;
  @observable private _addressCurrency: CryptoCurrencyCode | undefined;
  @observable private _addressNetwork: AddressNetwork | undefined;
  @observable private _addressTo: string | undefined;
  @observable private _status = SendCommissionBindingStateStatus.Load;
  @observable fees: DecimalString[] | undefined;
  @observable freeSendAvailableForCrypto = true;
  @observable isEnoughAmountForFreeSend = true;
  @observable freeSendLocked = false;
  @observable noCommission = true;
  @observable fee: DecimalString | undefined;
  @observable minFreeAmount: DecimalString | undefined;

  @computed
  private get crypto() {
    return (
      this.currencyCode &&
      this._root.currencyStore.getCryptoCurrency(this.currencyCode)
    );
  }

  get status() {
    return this._status;
  }

  @computed get recommendedFee(): DecimalString | undefined {
    if (!this.fees || this.fees.length === 0) {
      return undefined;
    }
    const middleIndex = Math.ceil((this.fees.length - 1) / 2);
    return this.fees[middleIndex];
  }

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
    },
    private readonly _limitHelper: WalletLimitHelper,
  ) {
    makeObservable(this);
  }

  getHasNoCommission = () => this.noCommission;

  @action.bound
  async setHasNoCommission(noCommission: boolean) {
    this.noCommission = noCommission;
    if (!noCommission) {
      await this._updateFee();
    }
    this.fee = this.noCommission ? '0' : this.recommendedFee;
  }

  getMax(walletId: WalletId) {
    const remainingLimit = this._limitHelper.getCurrentRemainingLimit(walletId);
    const wallet = this._root.walletStore.getWalletById(walletId);
    return wallet && getMaxAmountForWithdrawal(wallet, remainingLimit);
  }

  @action.bound
  async refresh(
    currencyCode: CryptoCurrencyCode,
    addressNetwork: AddressNetwork,
    addressCurrency: CryptoCurrencyCode,
    addressTo: CryptoAddress,
    amount: DecimalString,
    fee?: DecimalString,
    minFreeWithdrawAmount?: DecimalString,
  ) {
    this.currencyCode = currencyCode;
    this._addressCurrency = addressCurrency;
    this._addressNetwork = addressNetwork;
    this._addressTo = addressTo;

    const [currenciesRes, feesRes] = await Promise.all([
      this._root.currencyStore.refreshCryptoCurrencies(),
      this._getFee(fee, addressCurrency, addressNetwork, {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        address_to: addressTo ?? null,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        amount: amount ?? null,
      }),
      this._root.walletStore.refreshWallets(),
      this._limitHelper.refresh(),
    ]);

    if (!currenciesRes.success || !feesRes.success) {
      runInAction(
        () => (this._status = SendCommissionBindingStateStatus.Error),
      );
      return;
    }

    runInAction(() => {
      this.fees = feesRes.right.fees.map(f => f.fee);
      this.minFreeAmount =
        minFreeWithdrawAmount ||
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.crypto!.options.min_free_withdrawal_amount;
      this.freeSendAvailableForCrypto = !isNil(this.minFreeAmount);

      this.isEnoughAmountForFreeSend =
        this.freeSendAvailableForCrypto &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        BigNumber(amount).isGreaterThanOrEqualTo(this.minFreeAmount!);

      if (!this.fee || !this.fees.includes(this.fee)) {
        void this.setHasNoCommission(this.isEnoughAmountForFreeSend);
      }
      this._status = SendCommissionBindingStateStatus.Ready;
    });
  }

  @action.bound
  setFee(fee: string) {
    this.fee = fee;
  }

  @action.bound
  private async _updateFee() {
    const res = await this._getFee(
      undefined,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._addressCurrency!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._addressNetwork!,
      {address_to: this._addressTo ?? null, amount: null},
    );
    if (res.success) {
      this.fees = res.right.fees.map(f => f.fee);
    }
  }

  private _getFee(
    fee: DecimalString | undefined,
    currency: CryptoCurrencyCode,
    network: AddressNetwork,
    options: WalletsWithdrawalsEstimatedFeeOptions,
  ) {
    if (fee === undefined) {
      return this._root.ncWalletJsonRpcClient.call(
        'wallets.withdrawals.estimated_fee',
        {
          currency,
          network,
          options,
        },
      );
    }
    this.fee = fee;
    this.fees = [fee];
    return success({fees: [{fee: fee}]});
  }
}
