import type {BiometricSignature} from '../Biometrics';
import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import type {Maybe} from '../Maybe';
import type {AuthResult, OtpCode} from '../NCWalletServer';
import type {
  BaseOAuth2PreSignInParams,
  OAuth2ProviderMap,
} from '../OAuth2RestClient';
import type {DeviceId, MultiFactorToken, RefreshToken} from '../units';
import type {PinCode} from '../UserIdentity';
import type {AuthResponse} from './AuthQuery';

export interface AuthHelper {
  reset(): Promise<Either<AuthResponse, GlobalError>>;
  preSignIn<T extends keyof OAuth2ProviderMap>(
    params: BaseOAuth2PreSignInParams<T>,
  ): Promise<Maybe<AuthResponse>>;
  signInBySetPin(pin: PinCode): Promise<Maybe<AuthResponse>>;
  signInByPin(pin: PinCode): Promise<Maybe<AuthResponse>>;
  signInByOtp(code: OtpCode): Promise<Maybe<AuthResponse>>;
  signInByRefreshToken(
    pin: PinCode,
    token: RefreshToken,
    deviceId: DeviceId,
  ): Promise<Maybe<AuthResponse>>;
  signInByBiometrics(params: BiometricAuthParams): Promise<Maybe<AuthResponse>>;
  completeLinkage(params: AuthResult): Promise<Maybe<AuthResponse>>;
  touch(): Promise<Maybe<AuthResponse>>;
  signOut(options?: SignOutOptions): Promise<Maybe<AuthResponse>>;
}

export type SignOutOptions = {
  local?: boolean;
  reason?: string;
};

export type BiometricAuthParams =
  | {
      kind: BiometricAuthKind.SignIn;
      token: MultiFactorToken;
      signature: BiometricSignature;
    }
  | {
      kind: BiometricAuthKind.Refresh;
      token: RefreshToken;
      signature: BiometricSignature;
    };

export enum BiometricAuthKind {
  SignIn,
  Refresh,
}
