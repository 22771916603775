import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import queryString from 'query-string';

import type {AccountStore} from '../AccountStore';
import {FULFILLED} from '../AsyncAtom';
import {BaseRestClientImpl} from '../BaseRestClient';
import type {Configuration} from '../Configuration';
import type {Crypto} from '../Crypto';
import type {GlobalError} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {FlashMessage} from '../FlashMessage';
import type {Either} from '../fp';
import type {FreshTokenState} from '../FreshTokenState';
import type {Http} from '../Http';
import type {Json} from '../Json';
import type {JsonRpcClient} from '../JsonRpc';
import type {Localization} from '../Localization';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {Base32, Rfc4648} from '../Rfc4648';
import {TwoFaProviderKind} from '../TwoFaHelper';
import type {TwoFaSettingsState} from '../TwoFaSettingsState/TwoFaSettingsState';
import type {MultiFactorToken, Uri, Url} from '../units';
import type {
  HotpLinkParams,
  Otp,
  OtpSecret,
  OtpType,
  TotpLinkParams,
} from './Otp';
import {HOTP, SHA1, SHA256} from './Otp';

const {stringify} = queryString;

export default class OtpImpl extends BaseRestClientImpl implements Otp {
  private static readonly _OTP_SECRET_LENGTH = 16;

  @observable
  private _telegramOtpInterval = 0;

  @computed
  get telegramOtpInterval() {
    return this._telegramOtpInterval;
  }

  set telegramOtpInterval(v: number) {
    runInAction(() => {
      this._telegramOtpInterval = v;
    });
  }

  constructor(
    protected readonly _root: {
      readonly crypto: Crypto;
      readonly rfc4648: Rfc4648;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly errorRepository: ErrorRepository;
      readonly configuration: Configuration;
      readonly http: Http;
      readonly json: Json;
      readonly flashMessage: FlashMessage;
      readonly twoFaSettingsState: TwoFaSettingsState;
      readonly freshMultiFactorTokenState: FreshTokenState<MultiFactorToken>;
      readonly localization: Localization;
      readonly accountStore: AccountStore;
    },
  ) {
    super(_root);
    makeObservable(this);
  }

  async generateSecret(): Promise<Either<Base32<OtpSecret>, GlobalError>> {
    const buffer = new ArrayBuffer(OtpImpl._OTP_SECRET_LENGTH) as OtpSecret;
    const filling_ = await this._root.crypto.fillWithRandomBytes(buffer);
    if (!filling_.success) {
      return filling_;
    }
    return this._root.rfc4648.toBase32(buffer, {shouldIncludePadding: false});
  }

  formAuthenticatorLink(
    type: OtpType,
    params: HotpLinkParams | TotpLinkParams,
  ): Uri {
    const typeTag = type === HOTP ? 'hotp' : 'totp';
    const accountName = encodeURIComponent(params.accountName);
    const issuer = encodeURIComponent(params.issuer);
    const labelDelimiter = encodeURIComponent(': ');
    const label = issuer + labelDelimiter + accountName;
    const algorithm =
      params.algorithm === SHA1
        ? 'SHA1'
        : params.algorithm === SHA256
          ? 'SHA256'
          : 'SHA512';
    const queryParams: Record<string, string> = {
      secret: params.secret,
      issuer: params.issuer,
      algorithm,
    };
    if (params.digits !== undefined) {
      queryParams.digits = params.digits.toString(10);
    }
    if (params.counter !== undefined) {
      queryParams.counter = params.counter.toString(10);
    }
    if (params.period !== undefined) {
      queryParams.period = params.period.toString(10);
    }
    return `otpauth://${typeTag}/${label}?${stringify(queryParams)}` as Uri;
  }

  @action
  async requestOptToTelegram(
    currentTwoFaProvider: TwoFaProviderKind | null,
    options: {forceChannel?: boolean},
    secret: Base32<OtpSecret>,
  ) {
    let _secret = secret;
    if (currentTwoFaProvider === TwoFaProviderKind.Telegram) {
      if (
        this._root.twoFaSettingsState.currentTwoFaProvider !==
        TwoFaProviderKind.Ga
      ) {
        _secret = secret;
      }
    }

    const payload: {secret?: string} = {};
    if (this._root.accountStore.state?.status !== FULFILLED) {
      return;
    }

    if (_secret && !this._root.accountStore.state.result.tfa) {
      payload.secret = _secret;
    }

    if (options.forceChannel) {
      await this._root.ncWalletJsonRpcClient.call(
        'accounts.totp.channels.set',
        {
          add_to_channels: [TwoFaProviderKind.Telegram],
        },
      );
    }

    const res = await this._root.ncWalletJsonRpcClient.call(
      'accounts.totp.channels.send_code',
      payload,
    );

    if (!res.success) {
      this._root.flashMessage.showMessage({
        title: this._root.localization.getTranslation('notFound.title'),
        variant: 'danger',
      });
      return;
    }

    const errorMessage = res.right.messages.find(
      it =>
        !it.success &&
        'error' in it &&
        it.channel === TwoFaProviderKind.Telegram,
    );

    if (errorMessage) {
      if (this.telegramOtpInterval === 0) {
        if (errorMessage.error) {
          if (errorMessage.error.details) {
            this.telegramOtpInterval = errorMessage.error.details[
              'interval'
            ] as unknown as number;
          }
        }
      }
      return;
    }

    const data = res.right.messages.find(
      it => it.success && it.channel === TwoFaProviderKind.Telegram,
    );

    if (data?.details) {
      if ('interval' in data.details) {
        this.telegramOtpInterval = data.details[
          'interval'
        ] as unknown as number;
      }
    }

    this._root.flashMessage.showMessage({
      title: this._root.localization.getTranslation(
        'twoFaPromptOtpScreen.successMessage',
      ),
      variant: 'success',
    });
  }

  async requestOptToTelegramViaRest() {
    try {
      const secret = await this.generateSecret();
      if (!secret.success) {
        return;
      }

      if (!this._root.freshMultiFactorTokenState.token) {
        return;
      }

      const res = await this._call(
        'POST',
        'api/v1/auth/secure/send-totp-code' as Url,
        {
          secret: secret.right,
          multi_factor_token: this._root.freshMultiFactorTokenState.token,
        },
      );

      if (!res.success) {
        this._root.flashMessage.showMessage({
          title: 'Error',
          variant: 'danger',
        });
        return;
      }

      // @ts-expect-error Poor code to be rewritten
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      const errorMessage = res.right?.messages.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (it: any) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          !it.success &&
          'error' in it &&
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          it.channel === TwoFaProviderKind.Telegram,
      );

      if (errorMessage) {
        if (this.telegramOtpInterval === 0) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (errorMessage.error) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (errorMessage.error.details) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              this.telegramOtpInterval =
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-unsafe-member-access
                errorMessage.error!.details!['interval'];
            }
          }
        }
        return;
      }

      // @ts-expect-error Poor code to be rewritten
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      const data = res.right.messages.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
        (it: any) => it.success && it.channel === TwoFaProviderKind.Telegram,
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (data?.details) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if ('interval' in data.details) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-non-null-assertion,@typescript-eslint/no-unsafe-member-access
          this.telegramOtpInterval = data.details!['interval'];
        }
      }

      this._root.flashMessage.showMessage({
        title: 'Code was send. See Telegram client',
        variant: 'success',
      });
    } catch {
      this._root.flashMessage.showMessage({
        title: 'Error',
        variant: 'danger',
      });
    }
  }

  protected get _base() {
    return this._root.configuration.current.values.ncWalletRestApiUrl;
  }

  protected get _timeout() {
    return this._root.configuration.current.values.ncWalletRestApiTimeout;
  }
}
