import type {CryptoCurrencyCode} from '@ncwallet-app/core';
import {useRoot, WalletLimitPeriod} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {
  PROMPT_NEW_LIMIT_ROUTE,
  SHOW_LIMIT_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {autorun, reaction, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {ListLimitsBindingState} from '../../../../../../CommonNavigationContainers/hooks/walletLimits/ListLimitsBindingState';
import {
  useNavigationGetIsFocused,
  useSetTwoFaGenerateSecret,
} from '../../../../../../Navigation/hooks';
import {useSendTelegramOtp} from '../../../../../../Navigation/hooks/useSendTelegramOtp';
import LgLimitsSettingsScreen from '../../../../../../screens/LgLimitsSettingsScreen';
import type {LimitCardProps} from '../../../../../../screens/LgLimitsSettingsScreen/LimitCard/LimitCard';
import type {PromptLimitOtpTelegram} from '../../../../../../screens/LgLimitsSettingsScreen/LimitCard/PromptLimitOtpCard';
import type {WalletLimitsCardProps} from '../../../../../../screens/LgLimitsSettingsScreen/WalletLimitsCard/WalletLimitsCard';
import type {WalletsWithoutLimitsCardProps} from '../../../../../../screens/LgLimitsSettingsScreen/WalletsWithoutLimitsCard/WalletsWithoutLimitsCard';
import {getDefaultForSelectionActiveLimit} from '../../../../../../shared/WalletLimitsItemData';
import type {LargeSwitchBindingProps} from '../../LargeSwitchBindingProps';
import usePromptLimitRemovalCard from './usePromptLimitRemovalCard';
import usePromptLimitUpdateCard from './usePromptLimitUpdateCard';
import usePromptNewLimitCard from './usePromptNewLimitCard';
import {usePromptOtpToLimitCreateCard} from './usePromptOtpToLimitCreateCard';
import {usePromptOtpToLimitRemovalCard} from './usePromptOtpToLimitRemovalCard';
import useShowLimitCard from './useShowLimitCard';

export type ListLimitsBindingProps = LargeSwitchBindingProps<'ListLimits'>;

export default observer(function ListLimitsBinding(
  props: ListLimitsBindingProps,
) {
  const {navigation, route} = props;
  const root = useRoot();
  const [state] = useState(() => new ListLimitsBindingState(root));
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, state],
  );

  useEffect(
    () =>
      reaction(
        () => state.isTimeoutError,
        shouldRequest => {
          if (shouldRequest) {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            root.rpcTimeoutErrorVisibility.registerAction(state.refresh);
          }
        },
      ),
    [state, root],
  );

  const onAddPress = useCallback(() => {
    navigation.navigate('PromptNewWallet');
  }, [navigation]);

  const walletLimitsCardProps = useMemo(
    (): WalletLimitsCardProps => ({
      getLimits: state.getLimitItems,
      getSearch: state.getLimitSearch,
      onSelect: l => {
        navigation.setParams({
          focusedSelectedLimit: {
            kind: SHOW_LIMIT_ROUTE,
            params: {
              walletId: l.walletId,
              period: getDefaultForSelectionActiveLimit(l).period,
            },
          },
        });
      },
      onSearchChange: (value: string) => {
        state.setSearch(value, {searchLimits: true, keepBoth: true});
      },
    }),
    [navigation, state],
  );

  const walletsWithoutLimitsCardProps = useMemo(
    (): WalletsWithoutLimitsCardProps => ({
      getWallets: state.getWalletItems,
      getSearch: state.getWalletSearch,
      onAddPress,
      onSelect: w => {
        navigation.setParams({
          focusedSelectedLimit: {
            kind: PROMPT_NEW_LIMIT_ROUTE,
            params: {
              walletId: w.walletId,
              currency: w.currency.code,
              period: WalletLimitPeriod.Daily,
            },
          },
        });
      },
      onSearchChange: (value: string) => {
        state.setSearch(value, {searchWallets: true, keepBoth: true});
      },
    }),
    [state, onAddPress, navigation],
  );
  const loadLimit = useCallback(
    (code: CryptoCurrencyCode) =>
      state.getLimitItems()?.find(l => l.currency.code === code),
    [state],
  );

  const {getSecret} = useSetTwoFaGenerateSecret();
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();

  const handleSendTelegramOpt = useCallback(
    async (withSecret = true) => {
      const secret = getSecret();
      if (secret === undefined) {
        return;
      }

      try {
        await sendOtp(
          root.twoFaSettingsState.currentTwoFaProvider ?? null,
          {forceChannel: true},
          withSecret ? secret : undefined,
        );
      } catch {
        /* empty */
      }
    },
    [sendOtp, root.twoFaSettingsState.currentTwoFaProvider, getSecret],
  );

  const telegramOtp = useMemo((): PromptLimitOtpTelegram => {
    return {
      currentTwoFaProvider: root.twoFaSettingsState.currentTwoFaProvider,
      onTwoFaResendCode: handleSendTelegramOpt,
      resendCodeInterval: interval,
      resetInterval,
    };
  }, [
    handleSendTelegramOpt,
    interval,
    resetInterval,
    root.twoFaSettingsState.currentTwoFaProvider,
  ]);

  const showLimitCardProps = useShowLimitCard(props);
  const promptLimitUpdateCardProps = usePromptLimitUpdateCard(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    state.refresh,
    loadLimit,
    props,
  );
  const promptNewLimitCardProps = usePromptNewLimitCard(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    state.refresh,
    loadLimit,
    props,
  );
  const promptOtpToLimitCreateCardProps = usePromptOtpToLimitCreateCard(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    state.refresh,
    telegramOtp,
    props,
  );
  const promptOtpToLimitUpdateCardProps = usePromptOtpToLimitCreateCard(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    state.refresh,
    telegramOtp,
    props,
  );

  const promptLimitRemovalCardProps = usePromptLimitRemovalCard(
    state.getLimit,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    state.refresh,
    props,
  );

  const promptOtpToLimitRemovalCardProps = usePromptOtpToLimitRemovalCard(
    state.getLimit,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    state.refresh,
    telegramOtp,
    props,
  );

  const limitCardProps = useMemo(
    (): LimitCardProps => ({
      selectedLimitRouteKind: route.params?.focusedSelectedLimit?.kind,
      showLimitCardProps,
      promptLimitUpdateCardProps,
      promptNewLimitCardProps,
      promptOtpToLimitCreateCardProps,
      promptOtpToLimitUpdateCardProps,
      promptOtpToLimitRemovalCardProps,
      promptLimitRemovalCardProps,
    }),
    [
      promptLimitRemovalCardProps,
      promptLimitUpdateCardProps,
      promptNewLimitCardProps,
      promptOtpToLimitCreateCardProps,
      promptOtpToLimitRemovalCardProps,
      promptOtpToLimitUpdateCardProps,
      route.params?.focusedSelectedLimit?.kind,
      showLimitCardProps,
    ],
  );

  return (
    <LgLimitsSettingsScreen
      walletLimitsCardProps={walletLimitsCardProps}
      walletsWithoutLimitsCardProps={walletsWithoutLimitsCardProps}
      limitCardProps={limitCardProps}
    />
  );
});
