import {action, computed, makeObservable, observable} from 'mobx';

import type {
  AccountDocument,
  AccountDocumentId,
  AccountDocumentType,
} from '../NCWalletServer/AccountsDocuments';
import {AccountDocumentStatus} from '../NCWalletServer/AccountsDocuments';
import type {Resettable} from '../OnLogoutResetService';
import type {AccountDocumentStore} from './AccountDocumentStore';

// eslint-disable-next-line import-x/prefer-default-export
export class AccountDocumentStoreImpl
  implements AccountDocumentStore, Resettable
{
  @observable private _documents: AccountDocument[] | undefined;

  @computed private get _idToDocument(): Map<
    AccountDocumentId,
    AccountDocument
  > {
    return new Map(this._documents?.map(d => [d.id, d]));
  }

  @computed private get _typeToDocument(): Map<
    AccountDocumentType,
    AccountDocument
  > {
    return new Map(this._documents?.map(d => [d.type, d]));
  }

  constructor() {
    makeObservable(this);
  }
  getAll(): AccountDocument[] | undefined {
    return this._documents;
  }

  getById(id: AccountDocumentId): AccountDocument | undefined {
    return this._idToDocument.get(id);
  }

  getByType(documentType: AccountDocumentType): AccountDocument | undefined {
    return this._typeToDocument.get(documentType);
  }

  @action.bound
  setAll(documents: AccountDocument[]) {
    this._documents = documents;
  }

  @action.bound
  update(accountDocument: AccountDocument) {
    if (!this._documents) {
      return;
    }

    const isCreated = !this._idToDocument.has(accountDocument.id);

    if (isCreated) {
      this._documents = [...this._documents, accountDocument];
    } else if (accountDocument.status === AccountDocumentStatus.Deleted) {
      this._documents = this._documents.filter(
        d => d.id !== accountDocument.id,
      );
    } else {
      this._documents = this._documents.map(d =>
        d.id === accountDocument.id ? accountDocument : d,
      );
    }
  }

  @action.bound
  reset() {
    this._documents = undefined;
  }
}
