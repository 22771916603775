import {useRoot, useStrings} from '@ncwallet-app/core';
import {HeartDarkSvg, HeartSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {AuthKind} from '../../screens/AuthScreen';
import {MdAuthLayout} from '../../screens/MdAuthLayout';
import type {AuthStackBindingProps} from './AuthStackBindingProps';
import useOnLogInPress from './useOnLogInPress';

export type MdLoginBindingProps = AuthStackBindingProps<'MdLogin'>;

export default observer(function WebLoginBinding(props: MdLoginBindingProps) {
  const {deviceInfo} = useRoot();
  const strings = useStrings();

  const onBackPress = useCallback(() => {
    props.navigation.navigate('Welcome');
  }, [props.navigation]);
  const onLogInPress = useOnLogInPress();

  return (
    <MdAuthLayout
      authKind={AuthKind.Login}
      appVersion={deviceInfo.getAppVersionWithBuildNumber()}
      title={strings['mdLogin.title']}
      description={strings['mdLogin.description']}
      Icon={LoginIcon}
      onButtonPress={onLogInPress}
      onBackPress={onBackPress}
    />
  );
});

const LoginIcon = observer(() => {
  const {appearance} = useRoot();
  return appearance.isDark ? <HeartSvg /> : <HeartDarkSvg />;
});
