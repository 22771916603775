import type GorhomBottomSheet from '@gorhom/bottom-sheet';
import {useDateFormatting, useStrings, variance} from '@ncwallet-app/core';
import {
  BottomSheet,
  Button,
  ButtonColor,
  ButtonVariant,
  DeviceIcon,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import type {Ref} from 'react';
import React, {useCallback} from 'react';
import {Text, View} from 'react-native';

import type {SessionInfo} from '../ActiveSessionsScreen/SessionInfo';

type SessionRemovalBottomSheetProps = {
  sheetRef?: Ref<GorhomBottomSheet>;
  session: SessionInfo;
  onCancelPress: () => void;
  onDeletePress: () => void;
};

export default observer(function SessionRemovalBottomSheet(
  props: SessionRemovalBottomSheetProps,
) {
  const {onCancelPress} = props;
  const strings = useStrings();
  const status =
    props.session.status === 'blocked'
      ? strings['secureSettingsScreen.blocked']
      : props.session.hasActiveConnection
        ? strings['secureSettingsScreen.activeSessions.onlineStatus']
        : strings['secureSettingsScreen.activeSessions.loggedInStatus'];

  const {formatDateTimeWithYear} = useDateFormatting();
  const date = formatDateTimeWithYear(props.session.time);

  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        onCancelPress();
      }
    },
    [onCancelPress],
  );

  return (
    <BottomSheet
      ref={props.sheetRef}
      index={0}
      onChange={onChange}
      animateOnMount
      snapPoints={[300]}
      enablePanDownToClose
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      title={strings['secureSettingsScreen.activeSessions.endSession']}
      onClose={onCancelPress}
      children={
        <BottomSheetBody>
          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
          {props.session ? (
            <>
              <BottomSheetSessionView>
                <SessionIcon>
                  <DeviceIcon type={props.session.platform} />
                </SessionIcon>
                <SessionItemBodyView>
                  <SessionItemBodyTop>
                    <SessionDate>{date}</SessionDate>
                    <SessionStatus
                      error={props.session.status === 'blocked'}
                      hasActiveConnection={props.session.hasActiveConnection}>
                      {status}
                    </SessionStatus>
                  </SessionItemBodyTop>
                  <SessionTitle>{props.session.title} </SessionTitle>
                  <SessionText>{props.session.info}</SessionText>
                </SessionItemBodyView>
              </BottomSheetSessionView>

              <BottomSheetButton
                variant={ButtonVariant.Danger}
                title={
                  strings['secureSettingsScreen.activeSessions.deleteButton']
                }
                onPress={props.onDeletePress}
                color={ButtonColor.Error}
              />

              <BottomSheetButton
                variant={ButtonVariant.PrimaryLight}
                title={
                  strings['secureSettingsScreen.activeSessions.cancelButton']
                }
                onPress={onCancelPress}
                color={ButtonColor.Secondary}
              />
            </>
          ) : null}
        </BottomSheetBody>
      }
    />
  );
});

const BottomSheetBody = variance(View)(() => ({
  root: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: 15,
  },
}));

const BottomSheetSessionView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -15,
    marginRight: -15,
    padding: 15,
    borderBottomWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const SessionIcon = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const SessionItemBodyView = variance(View)(() => ({
  root: {
    marginLeft: 15,
  },
}));

const SessionItemBodyTop = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const SessionTitle = variance(Text)(theme => ({
  root: {
    marginBottom: 5,
    ...theme.fontByWeight('700'),
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.textPrimary,
  },
}));

const SessionStatus = variance(Text)(theme => ({
  root: {
    fontSize: 12,
    ...theme.fontByWeight('500'),
    lineHeight: 15,
    color: theme.palette.success,
  },
  hasActiveConnection: {
    color: theme.palette.info,
  },
  error: {
    color: theme.palette.error,
  },
}));

const SessionText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 12,
    lineHeight: 15,
    color: theme.palette.textAdditional2,
  },
}));

const SessionDate = variance(Text)(theme => ({
  root: {
    marginRight: 5,
    ...theme.fontByWeight('500'),
    fontSize: 11,
    lineHeight: 15,
    color: theme.palette.textAdditional2,
  },
}));

const BottomSheetButton = variance(Button)(theme => ({
  root: {
    marginTop: 20,
    borderColor: theme.palette.uiSecondary,
  },
}));
