import type {Millisecond} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {AdvertContext} from '@ncwallet-app/core/src/Advert';
import {observer} from 'mobx-react-lite';
import React from 'react';

import AffiliateAdvertScreen, {
  AffiliateAdvertModal,
} from '../../screens/AffiliateAdvertScreen';
import useAdvertProps from './useAdvertProps';

export type NewModalSpotBindingProps = {
  context: AdvertContext;
  hideAd: (now: Millisecond) => Promise<void>;
};

export default observer(function NewModalSpotBinding(
  props: NewModalSpotBindingProps,
) {
  const {context, hideAd} = props;

  const root = useRoot();
  const [closeBanner, onActionPress, actions] = useAdvertProps(context, hideAd);

  const isLarge = root.layoutHelperState.isLarge;
  return isLarge ? (
    <AffiliateAdvertModal
      body={context.text.body}
      title={context.text.title}
      image={context.images.image}
      actions={actions}
      onActionPress={onActionPress}
      onClose={closeBanner}
    />
  ) : (
    <AffiliateAdvertScreen
      body={context.text.body}
      title={context.text.title}
      image={context.images.image}
      actions={actions}
      onActionPress={onActionPress}
      onClose={closeBanner}
    />
  );
});
