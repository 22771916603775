import type {PreAuthCredentials} from '../Credentials';
import type {BiometricSettingsDto} from '../NCWalletServer/Biometrics/BiometricSettingsDto';

export type BiometricSettings = {
  hasTwoFaBiometrics: boolean;
  hasSignInBiometrics: boolean;
};

export const fromBiometricSettingsDto = (
  dto: BiometricSettingsDto,
): BiometricSettings => ({
  hasTwoFaBiometrics: dto.has_2fa_biometrics,
  hasSignInBiometrics: dto.has_sign_in_biometrics,
});

export const fromPreAuthResponse = (preAuth: PreAuthCredentials) => ({
  hasSignInBiometrics: preAuth.hasSignInBiometrics,
  hasTwoFaBiometrics: preAuth.hasTwoFaBiometrics,
});
