import {fromDate, parseMillisecond} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import useStackNavigateBack from '../../../CommonNavigationContainers/hooks/useStackNavigateBack';
import CalendarModalScreen from '../../../screens/CalendarModalScreen';
import type {CalendarParams} from '../../../screens/CalendarModalScreen/CalendarModalScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptDateOfHistoryPeriodBindingProps =
  SmallHomeStackBindingProps<'PromptDateOfHistoryPeriod'>;

export default observer(function PromptDateOfHistoryPeriodBinding(
  props: PromptDateOfHistoryPeriodBindingProps,
) {
  const {navigation, route} = props;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const params = route.params ?? {};
  const type = params.isSourceRequested ? 'from' : 'to';
  const value = parseMillisecond(params.value);

  const navigateBack = useStackNavigateBack(navigation);

  const handleApply = useCallback(
    (from: CalendarParams) => {
      const date = new Date(from.year, from.month, from.day);
      const _params = {
        to: route.params.to,
        from: route.params.from,
      };

      if (
        (type === 'from' &&
          _params.to !== undefined &&
          date.getTime() > _params.to) ||
        (type === 'to' &&
          _params.from !== undefined &&
          date.getTime() < _params.from)
      ) {
        _params[type] = type === 'from' ? _params.to : _params.from;
      } else {
        _params[type] = fromDate(date);
      }

      navigateBack('PromptPeriodToFilterHistory', _params);
    },
    [navigateBack, type, route.params.to, route.params.from],
  );

  const handleBack = useCallback(() => {
    navigateBack('PromptPeriodToFilterHistory', route.params);
  }, [navigateBack, route.params]);

  return (
    <CalendarModalScreen
      value={value && new Date(value)}
      onBack={handleBack}
      onApply={handleApply}
    />
  );
});
