import type {SmallHomeStackParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallHomeStackParamList';
import type {PartialState} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import type {SmallHomeStackBindingProps} from '..';
import NotifyAboutSuccessfulExchangeContainer from '../../../CommonNavigationContainers/containers/NotifyAboutSuccessfulExchangeContainer';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type NotifyAboutSuccessfulExchangeBindingProps =
  SmallHomeStackBindingProps<'NotifyAboutSuccessfulExchange'>;

export default observer(function NotifyAboutSuccessfulExchangeBinding(
  props: NotifyAboutSuccessfulExchangeBindingProps,
) {
  const {navigation, route} = props;
  const navigateToRoot = useCallback(() => {
    const rootState: PartialState<NavigationState<SmallHomeStackParamList>> = {
      index: 0,
      routes: [{name: 'Root'}],
    };
    if (hasRouteInHistory(navigation.getState(), 'Root')) {
      navigation.navigate('Root');
    } else {
      navigation.dispatch(CommonActions.reset(rootState));
    }
  }, [navigation]);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <NotifyAboutSuccessfulExchangeContainer
      params={route.params}
      onBack={goBack}
      onSubmit={navigateToRoot}
    />
  );
});
