import {
  formatCryptoValue,
  keepMeaningfulDigitsInFiat,
  variance,
} from '@ncwallet-app/core';
import PriceDiff from '@ncwallet-app/navigation/src/components/PriceDiff';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {ArrowRightSvg} from '../../../assets/svg/colorless';
import {CurrencyCircleLogo, TouchableListItem} from '../../atoms';
import {LineChart} from '../LineChart';
import type {CurrencySelectionListItemData} from './CurrencySelectionListItemData';

export type MdCurrencyListItemProps = {
  data: CurrencySelectionListItemData;
  valueShown?: boolean;
  onPress: () => void;
  testID?: string;
};

export default observer(function MdCurrencyListItem(
  props: MdCurrencyListItemProps,
) {
  const cryptoCurrency = props.data.cryptoCurrency;
  const fiatCurrency = props.data.getFiatCurrency?.();
  const chartData = props.data.getChartData?.();
  const diff = props.data.getDiff?.();
  const rate = props.data.getRate?.();

  const firstPrice = chartData && chartData.length ? chartData[0].value : 0;

  return (
    <BorderWrap>
      <Container
        onPress={props.onPress}
        Icon={ArrowRightSvg}
        disabled={props.data.disabled}
        testID={props.testID}>
        <Inner>
          <CurrencyCircleLogo code={cryptoCurrency.code} size={40} />
          <CurrencyData>
            <Title>{cryptoCurrency.name}</Title>
            {props.valueShown && (
              <WalletTotal>
                {formatCryptoValue(
                  props.data.value,
                  props.data.cryptoCurrency.fractionDigits,
                )}{' '}
                {cryptoCurrency.code}
              </WalletTotal>
            )}
          </CurrencyData>
          {chartData && (
            <>
              <Graph>
                <LineChart data={chartData} width={100} height={24} />
              </Graph>
              <CurrencyInfo>
                {!isNil(rate) && (
                  <CryptoRate>
                    {keepMeaningfulDigitsInFiat(rate)} {fiatCurrency?.code}
                  </CryptoRate>
                )}
                <SizedView />
                {!isNil(diff) && (
                  <CurrencyDiffContainer>
                    <PriceDiff
                      firstPrice={firstPrice.toString(10)}
                      diff={diff}
                    />
                  </CurrencyDiffContainer>
                )}
              </CurrencyInfo>
            </>
          )}
        </Inner>
      </Container>
    </BorderWrap>
  );
});

const BorderWrap = variance(View)(theme => ({
  root: {
    borderBottomColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
  },
}));

const Container = variance(TouchableListItem)(() => ({
  root: {
    paddingRight: 25,
    paddingLeft: 15,
  },
}));

const Graph = variance(View)(() => ({
  root: {
    marginLeft: 'auto',
    marginRight: 20,
  },
}));

const Inner = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },
}));

const CurrencyData = variance(View)(() => ({
  root: {
    marginLeft: 15,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('bold'),
    fontSize: 16,
  },
}));

const CurrencyInfo = variance(View)(() => ({
  root: {
    width: 140,
    marginRight: 18,
  },
}));

const CryptoRate = variance(Text)(theme => ({
  root: {
    marginTop: 2,
    color: theme.palette.textMain,
    ...theme.fontByWeight('600'),
    textAlign: 'right',
  },
}));

const CurrencyDiffContainer = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    textAlign: 'right',
    color: '#B3B3B3',
  },
}));

const WalletTotal = variance(Text)(theme => ({
  root: {
    marginTop: 5,
    ...theme.fontByWeight('500'),
    fontSize: 12,
    color: theme.palette.textAdditional2,
  },
}));

const SizedView = variance(View)(() => ({
  root: {
    height: 5,
  },
}));
