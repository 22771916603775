import {useStrings} from '@ncwallet-app/core';
import {useMemo} from 'react';

export type PinScreenTexts = {
  title: string;
  backText: string;
  errorText?: string;
  errorActionText?: string;
};

export const useCreatePinTexts = (): PinScreenTexts => {
  const strings = useStrings();

  return useMemo(
    () => ({
      title: strings['createPinScreen.create.title'],
      backText: strings['createPinScreen.create.goBack'],
      errorText: strings['createPinScreen.confirm.error'],
      errorActionText: '',
    }),
    [strings],
  );
};

export const useConfirmPinTexts = (): PinScreenTexts => {
  const strings = useStrings();

  return useMemo(
    () => ({
      title: strings['createPinScreen.confirm.title'],
      backText: strings['createPinScreen.confirm.goBack'],
      errorText: '',
      errorActionText: '',
    }),
    [strings],
  );
};

export const useCheckPinTexts = (stateLess?: boolean): PinScreenTexts => {
  const strings = useStrings();

  return useMemo(
    () => ({
      title: strings['checkPinScreen.title'],
      backText: stateLess ? '' : strings['authErrorScreen.resignOut'],
      errorText: stateLess ? strings['checkPinScreen.error'] : '',
      errorActionText: strings['checkPinScreen.errorAction'],
    }),
    [stateLess, strings],
  );
};

export const useSecurePinTextsOld = (): PinScreenTexts => {
  const strings = useStrings();

  return useMemo(
    () => ({
      title: strings['setPinSecure.pinOld.title'],
      backText: '',
      errorText: strings['setPinSecure.pinOld.error'],
      errorActionText: strings['checkPinScreen.errorAction'],
    }),
    [strings],
  );
};

export const useSecurePinTextsNew = (): PinScreenTexts => {
  const strings = useStrings();

  return useMemo(
    () => ({
      title: strings['setPinSecure.pinNew.title'],
      backText: '',
      errorText: strings['setPinSecure.pinNew.error'],
      errorActionText: '',
    }),
    [strings],
  );
};
