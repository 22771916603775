import {compact, omit} from 'lodash';

import type {BaseError} from '../Error';
import {TIMEOUT_ERROR} from '../Error';
import type {Json} from '../Json';
import type {Localization} from '../Localization';
import {
  COMMON_REST_ERROR_TO_LOCALE,
  isCommonErrorResponse,
} from '../NCWalletServer';
import {isCommonRestErrorDescription} from '../NCWalletServer/CommonRestError';
import type {CommonErrorSummaryTranslator} from './CommonErrorSummaryTranslator';
import type {
  ErrorDetails,
  ErrorParser,
  ErrorParserOptions,
} from './ErrorParser';

export default class ErrorParserImpl implements ErrorParser {
  constructor(
    private readonly _root: {
      readonly json: Json;
      readonly localization: Localization;
    },
    private readonly _commonErrorSummaryTranslator: CommonErrorSummaryTranslator,
  ) {}

  describe(error: BaseError, options?: ErrorParserOptions): ErrorDetails {
    let originalName;
    let originalMessage;
    if (error.raw instanceof Error) {
      originalName = error.raw.name;
      originalMessage = error.raw.message;
    }
    let remainder;
    if (!options?.ignoreUnknownProperties) {
      const remainingObject = omit(error, ['kind', 'description', 'raw']);
      const remainingKeys = Object.keys(remainingObject);
      if (remainingKeys.length > 0) {
        const remainder_ = this._root.json.stringify(remainingObject, 2);
        remainder = remainder_.success
          ? remainder_.right
          : 'Error details cannot be presented';
      }
    }

    const code = Reflect.get(Reflect.get(error, 'body') ?? {}, 'code') as
      | number
      | undefined;
    const summary = this._parseSummary(error);
    const header = compact([
      originalName && `[${originalName}]`,
      originalMessage === summary ? undefined : originalMessage,
    ]).join(': ');
    const details = compact([header, remainder]).join('\n');
    return {
      summary,
      details,
      code,
    };
  }

  private _parseSummary(error: BaseError) {
    if (isCommonErrorResponse(error)) {
      return this._commonErrorSummaryTranslator.translate(error.body);
    } else if (isCommonRestErrorDescription(error)) {
      return this._root.localization.getTranslation(
        COMMON_REST_ERROR_TO_LOCALE[error.body.code],
      );
    } else if (error.kind === TIMEOUT_ERROR) {
      return this._root.localization.getTranslation('errors.failedWithTimeout');
    } else {
      return error.description;
    }
  }
}
