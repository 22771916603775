// eslint-disable-next-line import-x/no-deprecated
import {BarCodeScanner} from 'expo-barcode-scanner';
import {
  CameraType,
  Camera as ExpoCamera,
  getCameraPermissionsAsync,
  requestCameraPermissionsAsync,
} from 'expo-camera';
import React, {forwardRef} from 'react';
import {StyleSheet} from 'react-native';

import type {
  Camera,
  DocCameraComponentType,
  QrCameraComponentProps,
} from './Camera';

const baseComponentProps = {
  type: CameraType.back,
  ratio: '16:9',
  style: StyleSheet.absoluteFillObject,
};
const BAR_CODE_SCANNER_SETTINGS = {
  // eslint-disable-next-line @typescript-eslint/no-deprecated,@typescript-eslint/no-unsafe-member-access,import-x/no-deprecated
  barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
};

export default class CommonCameraImpl implements Camera {
  getCameraPermissionsAsync = getCameraPermissionsAsync;
  requestCameraPermissionsAsync = requestCameraPermissionsAsync;
  DocCameraComponent = forwardRef<ExpoCamera>((_, ref) => {
    return <ExpoCamera {...baseComponentProps} ref={ref} />;
  }) as DocCameraComponentType;
  QrCameraComponent = (props: QrCameraComponentProps) => {
    return (
      <ExpoCamera
        {...baseComponentProps}
        barCodeScannerSettings={BAR_CODE_SCANNER_SETTINGS}
        onBarCodeScanned={({data}) => {
          props.onBarCodeScanned(data);
        }}
      />
    );
  };
}
