import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

import {
  AAVECircleSvg,
  ApeCircleSvg,
  AudCircleSvg,
  AXSCircleSvg,
  BCHCircleSvg,
  BitcoinCircleSvg,
  BNBCircleSvg,
  BrlCircleSvg,
  BUSDCircleSvg,
  CadCircleSvg,
  ChfCircleSvg,
  CHZCircleSvg,
  CnyCircleSvg,
  CTCCircleSvg,
  DaiCircleSvg,
  DdkCircleSvg,
  DogeCircleSvg,
  EthereumCircleSvg,
  EurCircleSvg,
  FTTCircleSvg,
  GbpCircleSvg,
  HashCoinCircleSvg,
  HexCircleSvg,
  HkdCircleSvg,
  InrCircleSvg,
  JpyCircleSvg,
  KrwCircleSvg,
  LDOCircleSvg,
  LinkCircleSvg,
  LtcCircleSvg,
  ManaCircleSvg,
  MaticPolygonCircleSvg,
  MoneroCircleSvg,
  MxnCircleSvg,
  MyrCircleSvg,
  NokCircleSvg,
  NzdCircleSvg,
  PepeCircleSvg,
  PolCircleSvg,
  QNTCircleSvg,
  RoboxCircleSvg,
  RubCircleSvg,
  SandCircleSvg,
  SekCircleSvg,
  SgdCircleSvg,
  ShibainuCircleSvg,
  SOLCircleSVG,
  TetherCircleSvg,
  TonCircleSvg,
  TrxCircleSvg,
  TwdCircleSvg,
  UniCircleSvg,
  UnknownCircleSvg,
  USDCircleSvg,
  UsdCircleSvg,
  WBTCCircleSvg,
  WETHCircleSvg,
  XRPCircleSvg,
  ZarCircleSvg,
} from '../../assets/svg/colored';
import {LockSvg} from '../../assets/svg/colorless';

interface CurrencyCircleLogoProps {
  code: string;
  size: number;
  isLocked?: boolean;
}

const circleLogoMap = new Map([
  ['BTC', BitcoinCircleSvg],
  ['BCH', BCHCircleSvg],
  ['USDC', USDCircleSvg],
  ['BUSD', BUSDCircleSvg],
  ['WBTC', WBTCCircleSvg],
  ['FTT', FTTCircleSvg],
  ['LINK', LinkCircleSvg],
  ['APE', ApeCircleSvg],
  ['ROX', RoboxCircleSvg],
  ['SAND', SandCircleSvg],
  ['QNT', QNTCircleSvg],
  ['AXS', AXSCircleSvg],
  ['AAVE', AAVECircleSvg],
  ['LDO', LDOCircleSvg],
  ['CHZ', CHZCircleSvg],
  ['XMR', MoneroCircleSvg],
  ['ETH', EthereumCircleSvg],
  ['WETH', WETHCircleSvg],
  ['USDT', TetherCircleSvg],
  ['SHIB', ShibainuCircleSvg],
  ['HEX', HexCircleSvg],
  ['MATIC', MaticPolygonCircleSvg],
  ['POLYGON', MaticPolygonCircleSvg],
  ['MANA', ManaCircleSvg],
  ['BNB', BNBCircleSvg],
  ['CTC', CTCCircleSvg],
  ['USD', UsdCircleSvg],
  ['EUR', EurCircleSvg],
  ['RUB', RubCircleSvg],
  ['CNY', CnyCircleSvg],
  ['GBP', GbpCircleSvg],
  ['INR', InrCircleSvg],
  ['JPY', JpyCircleSvg],
  ['KRW', KrwCircleSvg],
  ['AUD', AudCircleSvg],
  ['CAD', CadCircleSvg],
  ['CHF', ChfCircleSvg],
  ['SEK', SekCircleSvg],
  ['MXN', MxnCircleSvg],
  ['NZD', NzdCircleSvg],
  ['SGD', SgdCircleSvg],
  ['HKD', HkdCircleSvg],
  ['NOK', NokCircleSvg],
  ['BRL', BrlCircleSvg],
  ['ZAR', ZarCircleSvg],
  ['DDK', DdkCircleSvg],
  ['TWD', TwdCircleSvg],
  ['MYR', MyrCircleSvg],
  ['SOL', SOLCircleSVG],
  ['DOGE', DogeCircleSvg],
  ['TRX', TrxCircleSvg],
  ['LTC', LtcCircleSvg],
  ['PEPE', PepeCircleSvg],
  ['UNI', UniCircleSvg],
  ['DAI', DaiCircleSvg],
  ['XRP', XRPCircleSvg],
  ['TON', TonCircleSvg],
  ['POL', PolCircleSvg],
  ['HSH', HashCoinCircleSvg],
  ['UNKNOWN', UnknownCircleSvg],
]);

export default observer(function CurrencyCircleLogo(
  props: CurrencyCircleLogoProps,
) {
  const icon = circleLogoMap.get(props.code);
  const CircleLogo = icon ?? (() => <CurrencyCode>{props.code}</CurrencyCode>);

  const containerStyles = useMemo(
    () => ({width: props.size, height: props.size}),
    [props.size],
  );

  return (
    <Container
      style={containerStyles}
      withBackground={!icon || props.code === 'UNKNOWN'}>
      <CircleLogo />
      {props.isLocked ? (
        <LockIcon>
          <LockSvg color={'white'} />
        </LockIcon>
      ) : null}
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 9999,
  },
  withBackground: {
    backgroundColor: theme.palette.uiMain,
  },
}));

const LockIcon = variance(View)(theme => ({
  root: {
    position: 'absolute',
    bottom: -2,
    right: -2,
    borderWidth: 2,
    borderColor: theme.palette.background,
    borderRadius: 50,
    width: 18,
    height: 18,
    backgroundColor: theme.palette.success,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const CurrencyCode = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 13,
    color: theme.palette.whitey,
  },
}));
