import {variance} from '@ncwallet-app/core';
import type {CountryNameData} from '@ncwallet-app/core/src/Localization/constant';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import CountryList from './CountryList/CountryList';
import CountryListSearch from './CountryList/CountryListSearch';

export type PromptToSelectVerificationCountryScreenProps = {
  countryList: CountryNameData[];
  searchQuery: string;
  onSearch: (searchQuery: string) => void;
  onCountrySelect: (country: CountryNameData) => void;
};

export default observer(function PromptToSelectVerificationCountryScreen(
  props: PromptToSelectVerificationCountryScreenProps,
) {
  const {countryList, searchQuery, onCountrySelect, onSearch} = props;
  return (
    <Root>
      <CountryListSearch onSearch={onSearch} />
      <CountryList
        data={countryList}
        onCountrySelect={onCountrySelect}
        searchQuery={searchQuery}
      />
    </Root>
  );
});

const Root = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));
