import type {DeviceId, PinCode, RefreshToken} from '@ncwallet-app/core';
import {useLogExporter, useRoot} from '@ncwallet-app/core';
import type {MessageVariant} from '@ncwallet-app/core/src/FlashMessage';
import {secureKeys} from '@ncwallet-app/core/src/KeyValueStore/secureKeys';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {noop, random} from 'lodash';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useCallback} from 'react';
import {Platform, View} from 'react-native';

export type DebugContainerProps = {
  goToLogs: () => void;
  goToSignOutReasonLogs: () => void;
  goToEnvironmentList: () => void;
  goToEnvironmentForm: () => void;
  bottomTabBarHeight?: number;
};

export default observer(function DebugContainer(props: DebugContainerProps) {
  const {
    goToLogs,
    goToSignOutReasonLogs,
    goToEnvironmentList,
    goToEnvironmentForm,
    bottomTabBarHeight = 0,
  } = props;
  const {
    authHelper,
    manualTestHelper,
    flashMessage,
    jsonSecureKeyValueStore,
    platformSpecificComponents,
  } = useRoot();
  const DebugScreen = platformSpecificComponents.DebugScreen;
  const {saveLog, shareLog, copyLog} = useLogExporter();
  const onRefreshTokenSet = useCallback(
    (pin: string, token: string, deviceId: string) =>
      authHelper.signInByRefreshToken(
        pin as PinCode,
        token as RefreshToken,
        deviceId as DeviceId,
      ),
    [authHelper],
  );

  const getBiometricsEnabled = useCallback(() => expr(() => false), []);
  const toggleBiometrics = noop;

  const signOut = useCallback(
    () => authHelper.signOut({reason: 'DebugContainer'}),
    [authHelper],
  );
  const showDemoMessage = useCallback(() => {
    const variant: MessageVariant = (
      ['primary', 'success', 'info', 'danger'] as MessageVariant[]
    )[random(0, 3)];
    flashMessage.showMessage({
      timeout: 100000,
      title: 'Test message',
      text: 'Test long long long long long long long long long long long long long long message text ',
      variant,
    });
  }, [flashMessage]);

  const styles = {flex: 1, paddingBottom: bottomTabBarHeight};
  const clearAsyncStorage = useCallback(async () => {
    const keys = await AsyncStorage.getAllKeys();
    await AsyncStorage.clear();
    flashMessage.showMessage({
      title: 'Async store cleared',
      variant: 'success',
      text: `Deleted keys are: ${keys.join(', ')}`,
      timeout: 8000,
    });
  }, [flashMessage]);
  const clearLocalStorage = useCallback(() => {
    if (Platform.OS === 'web') {
      window.localStorage.clear();
    }
  }, []);
  const clearSessionStorage = useCallback(() => {
    if (Platform.OS === 'web') {
      window.sessionStorage.clear();
    }
  }, []);

  const clearSecureStorage = useCallback(async () => {
    for (const secureKey of secureKeys) {
      await jsonSecureKeyValueStore.delete(secureKey);
    }
    flashMessage.showMessage({
      title: 'Secure store cleared',
      variant: 'success',
      text: `Deleted keys are: ${[...secureKeys].join(', ')}`,
      timeout: 8000,
    });
  }, [flashMessage, jsonSecureKeyValueStore]);

  const showAsyncStorage = useCallback(async () => {
    return AsyncStorage.getAllKeys().then(keyArray => {
      return AsyncStorage.multiGet(keyArray).then(keyValArray => {
        const _storage: Record<string, string | null> = {};
        for (const key of keyValArray) {
          _storage[key[0]] = key[1];
        }

        return _storage;
      });
    });
  }, []);

  return (
    <View style={styles}>
      <DebugScreen
        onNativeCrashPress={manualTestHelper.nativeCrash}
        goToLogs={goToLogs}
        goToSignOutReasonLogs={goToSignOutReasonLogs}
        showAsyncStorage={showAsyncStorage}
        clearAsyncStorage={clearAsyncStorage}
        clearLocalStorage={clearLocalStorage}
        clearSessionStorage={clearSessionStorage}
        goToEnvironmentList={goToEnvironmentList}
        clearSecureStorage={clearSecureStorage}
        goToEnvironmentForm={goToEnvironmentForm}
        getBiometricsEnabled={getBiometricsEnabled}
        toggleBiometrics={toggleBiometrics}
        onRefreshTokenSet={onRefreshTokenSet}
        onSignOut={signOut}
        onShowDemoMessage={showDemoMessage}
        saveLog={saveLog}
        shareLog={shareLog}
        copyLog={copyLog}
      />
    </View>
  );
});
