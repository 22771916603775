import type {Millisecond, TransactionFilterKind} from '@ncwallet-app/core';
import {useStyles, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {CommonRefreshControl, LG_BREAKPOINT} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import HistoryFilterHeader from './HistoryFilterHeader';
import {HistoryList} from './HistoryList/HistoryList';
import type {HistoryListItemData} from './HistoryList/HistoryListItemData';
import {Inner, ListWrapper, Root} from './HistoryList/sharedStyles';

export type HistoryScreenProps = BaseSafeAreaProps & {
  onItemPress: (history: HistoryListItemData) => void;
  onFiltersPress: () => void;
  getHistoryItems: () => HistoryListItemData[] | undefined;
  getIsRefreshing: () => boolean;
  onRefresh: () => void;
  onEndReached: () => void;
  getCurrencyCode: () => string | undefined;
  from?: Millisecond;
  to?: Millisecond;
  transactionFilterKind?: TransactionFilterKind;
};

export default observer(function HistoryScreen(props: HistoryScreenProps) {
  const {
    onItemPress,
    getHistoryItems,
    onFiltersPress,
    getIsRefreshing,
    onRefresh,
    onEndReached,
    getCurrencyCode,
    from,
    to,
    transactionFilterKind,
    ...rest
  } = props;
  const currencyCode = getCurrencyCode();
  const ListHeaderComponent = useCallback(
    () => (
      <HistoryFilterHeader
        currencyCode={currencyCode}
        from={from}
        to={to}
        transactionFilterKind={transactionFilterKind}
        onFiltersPress={onFiltersPress}
      />
    ),
    [currencyCode, from, onFiltersPress, to, transactionFilterKind],
  );
  const refreshControl = useMemo(
    () => (
      <CommonRefreshControl
        getIsRefreshing={getIsRefreshing}
        onRefresh={onRefresh}
      />
    ),
    [getIsRefreshing, onRefresh],
  );

  const styles = useStyles(theme => ({
    listContainer: {
      flexGrow: 1,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          paddingBottom: 0,
        },
      }),
    },
  }));

  return (
    <Root>
      <Inner>
        <ListHeaderComponent />
        <ListWrapper>
          <List
            getHistoryItems={getHistoryItems}
            contentContainerStyle={styles.listContainer}
            onPress={onItemPress}
            refreshControl={refreshControl}
            onEndReached={onEndReached}
            onFiltersPress={onFiltersPress}
            getCurrencyCode={getCurrencyCode}
            from={from}
            to={to}
            {...rest}
          />
        </ListWrapper>
      </Inner>
    </Root>
  );
});

const List = variance(HistoryList)(theme => ({
  root: {
    borderRadius: 8,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.uiPrimary,
      },
    }),
  },
}));
