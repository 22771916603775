import {useRoot} from '@ncwallet-app/core';
import {autorun, runInAction} from 'mobx';
import {useEffect} from 'react';

import type {RateModalBindingState} from './RateModalBindingState';

export default function useSendRatingEvent(state: RateModalBindingState) {
  const root = useRoot();
  useEffect(
    () =>
      autorun(() => {
        if (state.isRatingSet) {
          const isReady = runInAction(
            () => root.appStateHelper.isReadyToMakeRequests,
          );
          if (isReady) {
            void runInAction(() =>
              root.ncWalletJsonRpcClient.call('events.create', {
                type: 'rating',
                meta: {rating: state.getRating()},
              }),
            );
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            root.appStateHelper.isReadyToMakeRequests; // runtime dependency
          }
        }
      }),
    [root, state],
  );
}
