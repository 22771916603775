import {fromISODateString, useRoot} from '@ncwallet-app/core/';
import type {GroupId} from '@ncwallet-app/core/src/NCWalletServer/Logs';
import type {LogsFindResult} from '@ncwallet-app/core/src/NCWalletServer/LogsFind';
import {observable, runInAction} from 'mobx';
import {useCallback, useState} from 'react';

import type {Notification} from './NotificationItem';
import {NotificationGroup} from './NotificationItem';

// eslint-disable-next-line import-x/prefer-default-export
export function useNotifications() {
  const [notificationsPendingBox] = useState(() => observable.box(false));
  const [notificationsBox] = useState(() =>
    observable.box<LogsFindResult | undefined>(),
  );
  const root = useRoot();
  const refresh = useCallback(async () => {
    if (notificationsPendingBox.get()) {
      return;
    }
    runInAction(() => {
      notificationsPendingBox.set(true);
    });
    try {
      const notifications_ = await root.ncWalletJsonRpcClient.call(
        'logs.find',
        {
          limit: 20,
          order_by: ['-timestamp'],
        },
      );
      if (!notifications_.success) {
        return;
      }
      runInAction(() => {
        notificationsBox.set(notifications_.right);
      });
    } finally {
      runInAction(() => {
        notificationsPendingBox.set(false);
      });
    }
  }, [root, notificationsBox, notificationsPendingBox]);

  const getNotificationItems = useCallback((): Notification[] | undefined => {
    const {userPreferenceState} = root;
    const GROUP_MAP: Record<GroupId, NotificationGroup> = {
      auth: NotificationGroup.Auth,
      transactions: NotificationGroup.Transactions,
      settings: NotificationGroup.Settings,
    };
    return notificationsBox.get()?.items.map(_ => {
      return {
        id: _.id,
        body:
          _.body_i18n[userPreferenceState.languageCode] ?? _.body_i18n.en ?? '',
        title:
          _.title_i18n[userPreferenceState.languageCode] ??
          _.title_i18n.en ??
          '',
        createdAt: fromISODateString(_.timestamp),
        group: GROUP_MAP[_.group_id],
      };
    });
  }, [notificationsBox, root]);
  const getIsRefreshing = useCallback(
    () => notificationsPendingBox.get(),
    [notificationsPendingBox],
  );

  const onEndReached = useCallback(async () => {
    if (notificationsPendingBox.get()) {
      return;
    }
    const current = notificationsBox.get();
    if (
      !current ||
      current.items.length === 0 ||
      current.items.length >= current.total
    ) {
      return;
    }
    runInAction(() => {
      notificationsPendingBox.set(true);
    });
    try {
      const lastCreatedAt = current.items[current.items.length - 1].timestamp;
      const next_ = await root.ncWalletJsonRpcClient.call('logs.find', {
        limit: 20,
        to_datetime: lastCreatedAt,
        order_by: ['-timestamp'],
      });
      if (!next_.success) {
        return;
      }
      runInAction(() => {
        notificationsBox.set({
          items: current.items.concat(next_.right.items),
          total: current.items.length + next_.right.total,
        });
      });
    } finally {
      runInAction(() => {
        notificationsPendingBox.set(false);
      });
    }
  }, [root, notificationsBox, notificationsPendingBox]);

  return {
    refresh: refresh,
    getIsRefreshing: getIsRefreshing,
    onEndReached: onEndReached,
    getNotificationItems: getNotificationItems,
  };
}
