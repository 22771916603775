import {useBoolean, useRoot} from '@ncwallet-app/core';
import {autorun} from 'mobx';
import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React, {useEffect} from 'react';
import type {ViewProps} from 'react-native';
import {StyleSheet, View} from 'react-native';

import {Loader} from '../Loader';
import AnimatedLogoMask from './AnimatedLogoMask';
import {
  LAUNCHER_ANIMATION_DELAY,
  LAUNCHER_ANIMATION_DURATION,
  LauncherRoot,
  launcherStyles,
  SHRINK,
} from './launcherStyles';

// Веб анимации сделаны через css из-за мелькания в хроме на ноутбуках mac os
// Посмотреть как выглядит можно в втором пункте задачи NCW-1352 (воспроизводится не только в эстеншионе)
export default observer(function Launcher(props: PropsWithChildren<ViewProps>) {
  const root = useRoot();
  const {bootSplash, loadingIndicator, windowDimensionsState} = root;

  const [isStartUpAnimationStarted, startAnimation] = useBoolean(false);
  const isStartUpAnimationFinished = root.splashScreenState.animationFinished;
  const finishAnimation = root.splashScreenBinding.onAnimationFinished;

  useEffect(
    () =>
      autorun(() => {
        if (
          root.appStateHelper.isReadyToHideSplashScreen &&
          bootSplash.completed &&
          !isStartUpAnimationFinished
        ) {
          setTimeout(startAnimation, LAUNCHER_ANIMATION_DELAY);
          // продолжительность анимации также задана в css
          setTimeout(
            finishAnimation,
            LAUNCHER_ANIMATION_DELAY + LAUNCHER_ANIMATION_DURATION,
          );
        }
      }),
    [
      root,
      bootSplash,
      isStartUpAnimationFinished,
      startAnimation,
      finishAnimation,
    ],
  );

  const {height, width} = windowDimensionsState.window;
  return (
    <LauncherRoot>
      <View
        style={launcherStyles.content}
        nativeID={isStartUpAnimationStarted ? 'main' : undefined}>
        {props.children}

        {isStartUpAnimationFinished && (
          <Loader loadingStatus={loadingIndicator.loadingStatus} />
        )}
      </View>
      {!isStartUpAnimationFinished && (
        <View
          style={launcherStyles.logo}
          nativeID={isStartUpAnimationStarted ? 'launcher' : undefined}>
          <AnimatedLogoMask
            width={width / SHRINK}
            height={height / SHRINK}
            style={StyleSheet.absoluteFill}
          />
        </View>
      )}
    </LauncherRoot>
  );
});
