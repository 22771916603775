import {useRoot} from '@ncwallet-app/core';
import {AccountDataRequestHelperImpl} from '@ncwallet-app/core/src/AccountDataRequestHelper';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, runInAction} from 'mobx';
import {useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import AccountVerificationBindingState from './AccountVerificationBindingState';

export default function useAccountVerificationBindingState() {
  const root = useRoot();
  const [state] = useState(
    () =>
      new AccountVerificationBindingState(
        root,
        new AccountDataRequestHelperImpl(root),
      ),
  );
  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh();
          });
        }
      }),
    [getIsReady, getIsFocused, state],
  );

  return state;
}
