import {useRoot, useTheme, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {NCWalletLogoDarkSvg, NCWalletLogoSvg} from '../../assets/svg/colored';
import {ErrorLabelSvg, MaintenanceCone} from '../../assets/svg/colorless';

export default observer(function ErrorWalletLogo() {
  const theme = useTheme();
  return (
    <>
      <NCWalletIcon />
      <ErrorLabel color={theme.palette.background} />
    </>
  );
});

export const MaintenanceWalletLogo = observer(function MaintenanceWalletLogo() {
  const theme = useTheme();
  return (
    <>
      <NCWalletIcon />
      <MaintenanceLabel color={theme.palette.background} />
    </>
  );
});

const NCWalletIcon = observer(() => {
  const {appearance} = useRoot();
  const size = 86;
  return appearance.isDark ? (
    <NCWalletLogoSvg width={size} height={size} />
  ) : (
    <NCWalletLogoDarkSvg width={size} height={size} />
  );
});

const MaintenanceLabel = variance(MaintenanceCone)(() => ({
  root: {
    width: 38,
    height: 38,
    position: 'absolute',
    bottom: -10,
    right: 0,
  },
}));

const ErrorLabel = variance(ErrorLabelSvg)(() => ({
  root: {
    width: 38,
    height: 38,
    position: 'absolute',
    bottom: -10,
    right: 0,
  },
}));
