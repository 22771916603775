import {useRoot, variance} from '@ncwallet-app/core';
import type {
  Message,
  RequestedMessage,
} from '@ncwallet-app/core/src/FlashMessage';
import {autorun, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import {useIsDimensions} from '../../../util';
import GestureMessage from './GestureMessage';
import type {MessageHeight} from './MessageView';

export type FlashMessageListViewProps = {
  getMessages: () => Message[];
  onRemoveMessage: (id: string) => void;
  onShowMessage: (message: RequestedMessage) => void;
};

const HEADER_HEIGHT = 72;

export default observer(function FlashMessageListView({
  getMessages,
  onRemoveMessage,
}: FlashMessageListViewProps) {
  const insets = useSafeAreaInsets();
  const isLg = useIsDimensions('lg');
  const isXl = useIsDimensions('xl');
  const [isVisibleBox] = useState(() => observable.box(false));
  const getIsVisible = useCallback(() => isVisibleBox.get(), [isVisibleBox]);
  const {windowDimensionsState} = useRoot();
  const {width} = windowDimensionsState.window;
  const [messageHeightsBox] = useState(() =>
    observable.box<MessageHeight[]>([]),
  );

  const setHeight = useCallback(
    ({id, height}: MessageHeight) => {
      const messageExist = Boolean(
        messageHeightsBox.get().find(_ => _.id === id),
      );
      if (!messageExist) {
        const newMessageHeights: MessageHeight[] = [
          {id, height},
          ...messageHeightsBox.get(),
        ];
        runInAction(() => {
          messageHeightsBox.set(newMessageHeights);
        });
      }
    },
    [messageHeightsBox],
  );

  const handleRemove = useCallback(
    (id: string) => {
      onRemoveMessage(id);
      runInAction(() => {
        messageHeightsBox.set(messageHeightsBox.get().filter(_ => _.id !== id));
      });
    },
    [messageHeightsBox, onRemoveMessage],
  );

  useEffect(
    () =>
      autorun(() => {
        if (getMessages().length > 0) {
          runInAction(() => {
            isVisibleBox.set(true);
          });
        } else {
          setTimeout(() => {
            runInAction(() => {
              isVisibleBox.set(false);
            });
          }, 500);
        }
      }),
    [isVisibleBox, getMessages],
  );

  if (!getIsVisible() && !getMessages().length) {
    return null;
  }
  return (
    <View
      style={[
        styles.root,
        {
          top: insets.top,
        },
        isLg && {
          ...styles.largeRoot,
          ...{top: insets.top + HEADER_HEIGHT},
        },
        isXl && {
          right: (width - 1210) / 2,
        },
      ]}>
      <MessageWrapper>
        {getMessages().map(_ => (
          <GestureMessage
            key={_.id}
            message={_}
            onDismiss={handleRemove}
            setHeight={setHeight}
            messageHeights={messageHeightsBox.get()}
          />
        ))}
      </MessageWrapper>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 15,
    paddingTop: 15,
  },
  largeRoot: {
    width: 400,
    left: 'auto',
  },
});

const MessageWrapper = variance(View)(() => ({
  root: {
    width: '100%',
  },
}));
