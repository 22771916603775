import type {Route} from '@react-navigation/core';
import {getActionFromState as getActionFromStateDefault} from '@react-navigation/core';
import type {PathConfig} from '@react-navigation/core/src/types';
import type {
  InitialState,
  LinkingOptions,
  ParamListBase,
} from '@react-navigation/native';
import type {DocumentTitleOptions} from '@react-navigation/native/lib/typescript/src/types';
import type {NavigationState, PartialState} from '@react-navigation/routers';

import type {
  DistinctPartialRoute,
  NavigationContainerConfig,
  ShallowCommonState,
} from '../CommonNavigationScheme';
import {pathToState, stateToPath} from '../CommonNavigationScheme';
import {LazyPathImpl} from '../CommonNavigationScheme/Path';
import commonToLarge from '../LargeNavigationScheme/commonToLarge';
import type {LargeHomeStackParamList} from '../LargeNavigationScheme/LargeHomeStack/LargeHomeStackParamList';
import type {LargeSwitchParamList} from '../LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import largeToCommon from '../LargeNavigationScheme/largeToCommon';
import type {LocationSource} from '../Location';
import type {SpecialLocation} from '../SpecialLocation';
import BaseNavigationContainerBindingImpl from './BaseNavigationContainerBindingImpl';
import type {NavigationContainerBinding} from './NavigationContainerBinding';
import type {NavigationContainerState} from './NavigationContainerState';

export default class LargeNavigationContainerBindingImpl
  extends BaseNavigationContainerBindingImpl
  implements NavigationContainerBinding
{
  constructor(
    protected readonly _root: {
      readonly navigationContainerState: NavigationContainerState;
      readonly locationSource: LocationSource;
      readonly specialLocation: SpecialLocation;
    },
  ) {
    super(_root);
  }

  get initialState(): InitialState | undefined {
    return this._latestState && commonToLarge(this._latestState);
  }

  readonly onStateChange = (state: NavigationState | undefined) => {
    if (!state) {
      this._root.navigationContainerState.setState(undefined);
      return;
    }
    this._root.navigationContainerState.setState(
      largeToCommon(
        state as InitialState as PartialState<
          NavigationState<LargeHomeStackParamList>
        >,
      ),
    );
  };

  get linking(): LinkingOptions<ParamListBase> {
    return {
      ...super.linking,
      getPathFromState(state) {
        const common = largeToCommon(
          state as PartialState<NavigationState<LargeHomeStackParamList>>,
        );
        return stateToPath(common).toString();
      },
      getStateFromPath(path) {
        const common = pathToState(new LazyPathImpl(path));
        return commonToLarge(common);
      },
      getActionFromState(state) {
        return getActionFromStateDefault(
          state,
          LargeNavigationContainerBindingImpl._LARGE_HOME_STACK_CONFIG,
        );
      },
    };
  }

  get documentTitle(): DocumentTitleOptions {
    return {
      enabled: true,
      formatter(
        _options: Record<string, unknown> | undefined,
        _route: Route<string> | undefined,
      ) {
        const route = _route as
          | DistinctPartialRoute<LargeHomeStackParamList & LargeSwitchParamList>
          | undefined;
        switch (route?.name) {
          case 'FallBack':
            return 'Not Found';
          default:
            return 'NCWallet';
        }
      },
    };
  }

  protected _getCurrentState(): ShallowCommonState | undefined {
    const state = this.ref?.getState();
    return (
      state &&
      largeToCommon(
        state as InitialState as PartialState<
          NavigationState<LargeHomeStackParamList>
        >,
      )
    );
  }

  private static readonly _LARGE_SWITCH_CONFIG: PathConfig<LargeSwitchParamList> =
    {
      initialRouteName: 'ListWallets',
    };

  private static readonly _LARGE_HOME_STACK_CONFIG: NavigationContainerConfig<LargeHomeStackParamList> =
    {
      initialRouteName: 'Root',
      screens: {
        Root: LargeNavigationContainerBindingImpl._LARGE_SWITCH_CONFIG as PathConfig<object>,
      },
    };
}
