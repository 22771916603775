import {Linking} from 'react-native';

import type {GlobalError} from '../Error';
import {UNKNOWN_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {Url} from '../units';
import type {Location} from './Location';

export default abstract class BaseHeadlessLocationImpl implements Location {
  protected constructor(
    protected readonly _root: {readonly errorRepository: ErrorRepository},
  ) {}

  abstract open(locator: Url): Promise<Either<void, GlobalError>>;

  async canOpen(locator: Url): Promise<Either<boolean, GlobalError>> {
    try {
      return success(await Linking.canOpenURL(locator));
    } catch (raw) {
      return error(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          description: 'Checking url failed',
          raw,
        }),
      );
    }
  }
}
