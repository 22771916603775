import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {EXTRA_BOTTOM_OFFSET} from '../../../../screens/constants';
import TwoFaSettingsScreen from '../../../../screens/UserSettingsScreens/TwoFaSettingsScreen/TwoFaSettingsScreen';
import type {SmallHomeStackBindingProps} from '../../SmallHomeStackBindingProps';
import {useShowTwoFaStatusBindingState} from './useShowTwoFaStatusBindingState';

export type ShowTwoFaStatusBindingProps =
  SmallHomeStackBindingProps<'ShowTwoFaSettings'>;

export default observer(function ShowTwoFaStatusBinding(
  props: ShowTwoFaStatusBindingProps,
) {
  const {navigation} = props;
  const state = useShowTwoFaStatusBindingState();

  const onDisableTwoFaPress = useCallback(() => {
    navigation.navigate('PromptOtpToDisableTwoFa', {
      providerKind: state.currentTwoFaProvider,
    });
  }, [navigation, state.currentTwoFaProvider]);

  const onSubmitPress = useCallback(() => {
    navigation.navigate('PromptOtpToChangeTwoFaSettings', {
      included: state.getIncluded(),
      excluded: state.getExcluded(),
    });
  }, [navigation, state]);

  const handleTwoFaSelect = useCallback(() => {
    navigation.navigate('PromptSelectTwoFaProvider');
  }, [navigation]);

  return (
    <TwoFaSettingsScreen
      isEdited={state.isEdited}
      actions={state.actions}
      onActionPress={state.switchAction}
      onDisableTwoFaPress={onDisableTwoFaPress}
      onSubmitPress={onSubmitPress}
      onTwoFaKindSelect={handleTwoFaSelect}
      currentTwoFaProvider={state.currentTwoFaProvider}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});
