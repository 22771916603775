import type {BiometricSettings} from '../Biometrics';
import {fromBiometricSettingsDto} from '../Biometrics';
import type {
  AuthResult,
  DirectAuthResult,
  PreAuthResult,
} from '../NCWalletServer';
import {isMultiFactorResult} from '../NCWalletServer';
import type {AccessToken, MultiFactorToken, RefreshToken} from '../units';

export type Credentials = AuthCredentials | PreAuthCredentials;

export type AuthCredentials = DirectCredentials | MultiFactorCredentials;

export type DirectCredentials = {
  isPreAuth: false;
  isDirect: true;
  refreshToken: RefreshToken;
  accessToken: AccessToken;
};

export type MultiFactorCredentials = {
  isPreAuth: false;
  isDirect: false;
  multiFactorToken: MultiFactorToken;
};

export type PreAuthCredentials = {
  isPreAuth: true;
  isDirect: false;
  multiFactorToken: MultiFactorToken;
  hasPin: boolean;
  isNew: boolean;
  isFirstSignIn: boolean;
  hasRemote2faChannels: boolean;
} & BiometricSettings;

export function fromDirectAuthResult(_: DirectAuthResult): DirectCredentials {
  return {
    isPreAuth: false,
    isDirect: true,
    refreshToken: _.refresh_token,
    accessToken: _.access_token,
  };
}

export function fromAuthResult(_: AuthResult): AuthCredentials {
  return isMultiFactorResult(_)
    ? {
        isPreAuth: false,
        isDirect: false,
        multiFactorToken: _.multi_factor_token,
      }
    : fromDirectAuthResult(_);
}

export function fromPreAuthResult(_: PreAuthResult): PreAuthCredentials {
  return {
    isPreAuth: true,
    isDirect: false,
    multiFactorToken: _.multi_factor_token,
    hasPin: _.has_pin,
    isNew: !!_.is_new,
    isFirstSignIn: !!_.is_new,
    hasRemote2faChannels: _.has_remote_2fa_channels,
    ...fromBiometricSettingsDto(_),
  };
}
