import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import type {ReactNode} from 'react';
import React from 'react';
import {Text, View} from 'react-native';

export type HeaderDropdownTopProps = {
  text: string;
  action?: ReactNode;
};

export default observer(function HeaderDropdownTop(
  props: HeaderDropdownTopProps,
) {
  const {text, action} = props;
  return (
    <Container>
      <Row>
        <Title>{text}</Title>
        {action && action}
      </Row>
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
  },
}));

const Row = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    paddingVertical: 13,
    paddingHorizontal: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiAdditional2,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
    fontSize: 16,
    lineHeight: 19,
  },
}));
