import type {CurrenciesRateHistoryItem} from '@ncwallet-app/core';
import {scaleLinear} from 'd3-scale';
import {curveBumpX, line} from 'd3-shape';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';
import type {Path} from 'react-native-redash';
import {parse} from 'react-native-redash';

import {CHART_HEIGHT} from './constants';

export type CurrencyGraphData = {
  path: Path;
  minRate: number;
  maxRate: number;
  fromDate: number;
  toDate: number;
};

export const toCurrencyGraphData = (
  rateHistory: CurrenciesRateHistoryItem[],
  width: number,
): CurrencyGraphData | undefined => {
  if (isEmpty(rateHistory)) {
    return undefined;
  }

  const points = rateHistory.map(toDataPoint);

  const rates = points.map(value => value[0]);
  const dates = points.map(value => value[1]);
  const minRate = Math.min(...rates);
  const maxRate = Math.max(...rates);
  const fromDate = Math.min(...dates);
  const toDate = Math.max(...dates);
  return {
    minRate: minRate,
    maxRate: maxRate,
    fromDate,
    toDate,
    path: parse(
      line()
        .x(([, x]) =>
          scaleLinear().domain([fromDate, toDate]).range([0, width])(x),
        )
        .y(([y]) =>
          scaleLinear().domain([minRate, maxRate]).range([CHART_HEIGHT, 0])(y),
        )
        .curve(curveBumpX)(points) as string,
    ),
  };
};

export const toDataPoint = (c: CurrenciesRateHistoryItem) =>
  [parseFloat(c.rate), dayjs(c.timestamp).unix()] as [number, number];
