import type {AddressInfo} from '@ncwallet-app/core';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {getCompositeId} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {SafeAreaFlatList} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import type {ReactElement} from 'react';
import React, {useCallback} from 'react';

import {AddressListItem} from './AddressListItem';
import AddressListSkeleton from './AddressListSkeleton';

export type AddressListProps = BaseSafeAreaProps & {
  data: AddressInfo[] | undefined;
  showAddressNetwork: boolean;
  defaultNetwork?: AddressNetwork;
  refreshControl: ReactElement;
  onAddressPress: (address: AddressInfo) => void;
};

export const AddressList = observer((props: AddressListProps) => {
  const {data, showAddressNetwork, defaultNetwork, onAddressPress, ...rest} =
    props;
  const dataLength = data && data.length;

  const renderEmptyData = useCallback(
    () => <AddressListSkeleton itemCount={4} />,
    [],
  );

  return (
    <SafeAreaFlatList
      data={data}
      extraData={onAddressPress}
      keyExtractor={getCompositeId}
      ListEmptyComponent={renderEmptyData}
      renderItem={({item, index}) => (
        <AddressListItem
          item={item}
          showAddressNetwork={showAddressNetwork}
          defaultNetwork={defaultNetwork}
          last={index === dataLength}
          onAddressPress={() => {
            onAddressPress(item);
          }}
        />
      )}
      {...rest}
    />
  );
});
