import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import {success} from '../fp';
import type {JsonRpcClient} from '../JsonRpc';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {
  AccountAddressData,
  AccountAddressDataWrapper,
  AccountDataId,
  AccountDataWrapper,
  AccountPersonalData,
  AccountPersonalDataWrapper,
  AccountsDataCreate,
  AccountsDataUpdate,
} from '../NCWalletServer/AccountsData';
import {
  AccountDataStatus,
  AccountDataType,
} from '../NCWalletServer/AccountsData';
import type {AccountDataRequestHelper} from './AccountDataRequestHelper';

export default class AccountDataRequestHelperImpl
  implements AccountDataRequestHelper
{
  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {}

  async getAll() {
    const res = await this._root.ncWalletJsonRpcClient.call(
      'accounts.data.get.list',
      {},
    );

    if (!res.success) {
      return res;
    }

    const items = res.right.items;

    return success({
      personal: items.find(d => d.type === AccountDataType.Personal),
      address: items.find(d => d.type === AccountDataType.Address),
    });
  }

  async update(
    data: AccountDataWrapper,
  ): Promise<Either<AccountDataWrapper, GlobalError>> {
    return this._root.ncWalletJsonRpcClient.call('accounts.data.update', data);
  }

  async savePersonalData(
    id: AccountDataId | undefined,
    data: AccountPersonalData,
  ): Promise<Either<AccountPersonalDataWrapper, GlobalError>> {
    const res = await this._baseSave(id, {
      data,
      type: AccountDataType.Personal,
    });
    return res.success ? success(res.right as AccountPersonalDataWrapper) : res;
  }

  async sendToReview(id: AccountDataId) {
    return this._root.ncWalletJsonRpcClient.call('accounts.data.update', {
      id,
      status: AccountDataStatus.OnReview,
    });
  }

  async saveAddressData(
    id: AccountDataId | undefined,
    data: AccountAddressData,
  ): Promise<Either<AccountAddressDataWrapper, GlobalError>> {
    const res = await this._baseSave(id, {data, type: AccountDataType.Address});
    return res.success ? success(res.right as AccountAddressDataWrapper) : res;
  }

  private _baseSave(
    id: AccountDataId | undefined,
    params:
      | {type: AccountDataType.Personal; data: AccountPersonalData}
      | {type: AccountDataType.Address; data: AccountAddressData},
  ) {
    return id ? this._baseUpdate({id, ...params}) : this._baseCreate(params);
  }

  private _baseCreate(params: AccountsDataCreate['params']) {
    return this._root.ncWalletJsonRpcClient.call(
      'accounts.data.create',
      params,
    );
  }

  private _baseUpdate(params: AccountsDataUpdate['params']) {
    return this._root.ncWalletJsonRpcClient.call(
      'accounts.data.update',
      params,
    );
  }
}
