import type {ViewStyle} from 'react-native';

export type ViewStyleSplit = {
  inner?: InnerViewStyle;
  outer?: OuterViewStyle;
  other?: OtherViewStyle;
};

export type InnerViewStyle = Pick<ViewStyle, InnerViewStyleKey>;
export type OuterViewStyle = Pick<ViewStyle, OuterViewStyleKey>;
export type OtherViewStyle = Omit<
  ViewStyle,
  InnerViewStyleKey | OuterViewStyleKey
>;

export default function splitViewStyle(style: ViewStyle): ViewStyleSplit {
  const result: Partial<
    Record<'inner' | 'outer' | 'other', Record<string, unknown>>
  > = {};
  for (const _key in style) {
    if (!Object.prototype.hasOwnProperty.call(style, _key)) {
      continue;
    }
    const key = _key as keyof typeof style;
    if (INNER_SET.has(key)) {
      result.inner = result.inner ?? {};
      result.inner[key] = style[key];
    } else if (OUTER_SET.has(key)) {
      result.outer = result.outer ?? {};
      result.outer[key] = style[key];
    } else {
      result.other = result.other ?? {};
      result.other[key] = style[key];
    }
  }
  return result as ViewStyleSplit;
}

const INNER = [
  // FlexStyle
  'alignContent',
  'alignItems',
  'borderBottomWidth',
  'borderEndWidth',
  'borderLeftWidth',
  'borderRightWidth',
  'borderStartWidth',
  'borderTopWidth',
  'borderWidth',
  'flexDirection',
  'rowGap',
  'gap',
  'columnGap',
  'flexWrap',
  'justifyContent',
  'overflow',
  'padding',
  'paddingBottom',
  'paddingEnd',
  'paddingHorizontal',
  'paddingLeft',
  'paddingRight',
  'paddingStart',
  'paddingTop',
  'paddingVertical',
  // TransformsStyle
  'transform',
  'transformMatrix',
  'rotation',
  'scaleX',
  'scaleY',
  'translateX',
  'translateY',
] as const;

const INNER_SET = new Set<string>(INNER);

const OUTER = [
  // ViewStyle
  'elevation',
  // FlexStyle
  'alignSelf',
  'aspectRatio',
  'bottom',
  'end',
  'flex',
  'flexBasis',
  'flexGrow',
  'flexShrink',
  'height',
  'left',
  'margin',
  'marginBottom',
  'marginEnd',
  'marginHorizontal',
  'marginLeft',
  'marginRight',
  'marginStart',
  'marginTop',
  'marginVertical',
  'maxHeight',
  'maxWidth',
  'minHeight',
  'minWidth',
  'position',
  'right',
  'start',
  'top',
  'width',
  'zIndex',
  // ShadowStyleIOS
  'shadowOffset',
  'shadowOpacity',
  'shadowRadius',
] as const;

const OUTER_SET = new Set<string>(OUTER);

export type InnerViewStyleKey = (typeof INNER)[number];
export type OuterViewStyleKey = (typeof OUTER)[number];

export type OtherViewStyleKey = Exclude<
  keyof ViewStyle,
  InnerViewStyleKey | OuterViewStyleKey
>;
