import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import DebugContainer from '../../../../../CommonNavigationContainers/containers/DebugContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type DebugBindingProps = LargeSwitchBindingProps<'Debug'>;

export default observer(function DebugBinding(props: DebugBindingProps) {
  const {navigation} = props;
  const goToLogs = () => {
    navigation.navigate('ListLogs');
  };
  const goToSignOutReasonLogs = () => {
    navigation.navigate('ListSignOutReasonLogs');
  };
  const goToEnvironmentList = useCallback(() => {
    navigation.navigate('ListEnvironment');
  }, [navigation]);
  const goToEnvironmentForm = useCallback(() => {
    navigation.navigate('PromptEnvironmentForm');
  }, [navigation]);

  return (
    <DebugContainer
      goToEnvironmentForm={goToEnvironmentForm}
      goToLogs={goToLogs}
      goToSignOutReasonLogs={goToSignOutReasonLogs}
      goToEnvironmentList={goToEnvironmentList}
    />
  );
});
