import {useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';

import {useDeleteIpWithOtp} from '../../../CommonNavigationContainers/hooks/ipSettings/useDeleteIpWithOtp';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {useSendTelegramOtp} from '../../../Navigation/hooks/useSendTelegramOtp';
import {
  CARD,
  SubmitOtpScreen,
} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {createResetToIpListAction} from '../util/createResetToIpListAction';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptOtpToDeleteIpBindingProps =
  SmallHomeStackBindingProps<'PromptOtpToDeleteIp'>;

export default observer(function PromptOtpToDeleteIpBinding(
  props: PromptOtpToDeleteIpBindingProps,
) {
  const {navigation, route} = props;
  const root = useRoot();
  const strings = useStrings();
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();

  const resetToIpList = useCallback(() => {
    const navigateTo =
      route.params.type === IpType.Allowed ? 'ListAllowedIp' : 'ListBlockedIp';
    if (hasRouteInHistory(navigation.getState(), navigateTo)) {
      navigation.navigate(navigateTo);
    } else {
      navigation.dispatch(createResetToIpListAction(route.params.type));
    }
  }, [navigation, route.params.type]);

  const {getErrorText, resetErrorText, deleteWitOtp} =
    useDeleteIpWithOtp(resetToIpList);

  const handleSubmit = useCallback(
    (code: string) => deleteWitOtp(route.params.id, route.params.type, code),
    [deleteWitOtp, route.params.id, route.params.type],
  );

  const handleTwoFaResendCode = useCallback(async () => {
    try {
      await sendOtp(null, {forceChannel: false});
    } catch {
      /* empty */
    }
  }, [sendOtp]);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (getIsReady() && getIsFocused()) {
      if (
        root.twoFaSettingsState.currentTwoFaProvider ===
        TwoFaProviderKind.Telegram
      ) {
        void handleTwoFaResendCode();
      }
    }
  }, [getIsReady, getIsFocused, root, handleTwoFaResendCode]);

  return (
    <SubmitOtpScreen
      layout={CARD}
      resetError={resetErrorText}
      getError={getErrorText}
      titleText={strings['promptOtpToUpdateIpList.title']}
      submitText={strings['promptOtpToUpdateIpList.submit']}
      onSubmit={handleSubmit}
      appVersion={root.deviceInfo.getAppVersionWithBuildNumber()}
      twoFaProvider={root.twoFaSettingsState.currentTwoFaProvider}
      onTwoFaResendCode={handleTwoFaResendCode}
      resendCodeInterval={interval}
      resetInterval={resetInterval}
    />
  );
});
