import {last} from '../../util';
import {GLOBAL_PARAMS} from './constant';
import type {Path} from './Path';
import type {PathChain} from './PathChain';
import PathImpl from './PathImpl';
import stripHistory from './stripHistory';

export default function packPathChain(chain: PathChain): Path {
  const top = last(chain);
  if (chain.length === 1) {
    return top;
  }
  const rest = chain.slice(0, -1).reverse();
  return new PathImpl(top.pathname, {
    ...top.params,
    [GLOBAL_PARAMS.history]: rest.map(stripHistory).map(_ => _.toString()),
  });
}
