import {Platform} from 'react-native';

import getWebOsKind from './getWebOsKind';
import {OSKind} from './OSKind';

export * from './OSKind';

export const getOSKind = (): OSKind => {
  switch (Platform.OS) {
    case 'ios':
      return OSKind.IOs;
    case 'macos':
      return OSKind.MacOS;
    case 'android':
      return OSKind.Android;
    case 'windows':
      return OSKind.Windows;
    case 'web':
      return getWebOsKind();
    default:
      return OSKind.Unknown;
  }
};
