import type {CryptoAddress} from '@ncwallet-app/core';
import {isNetworkChangePossible, useRoot, useStrings} from '@ncwallet-app/core';
import {useAccountState} from '@ncwallet-app/core/src/AccountStore';
import type {
  GeneratePayMeLinkRoute,
  ListInputAddressesRoute,
  PromptAddressFormatRoute,
  PromptCryptoToReceiveRoute,
  PromptInputNetworkRoute,
  PromptReceiveNetworkRoute,
  RouteParams,
  ShowQrToReceiveCryptoRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {DepositType} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransitionMap} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransitionMap';
import type {
  AddressNetwork,
  AddressType,
} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {setStringAsync} from 'expo-clipboard';
import {noop} from 'lodash';
import {useCallback, useMemo} from 'react';

import {useReceiveCryptoBindingState} from '../../Navigation/HomeStack/ReceiveCryptoBinding/useReceiveCryptoBindingState';
import {EXTRA_BOTTOM_OFFSET} from '../../screens/constants';
import type {ReceiveCryptoScreenProps} from '../../screens/ReceiveCryptoScreen';
import {ReceiveCryptoTabKey} from '../../screens/ReceiveCryptoScreen/ReceiveCryptoTabKey';
import {
  useRedirectToNotFoundIfNoAddress,
  useRedirectToNotFoundIfNoWalletWithId,
} from './redirectToNotFoundOnWrongDataHooks';

export type ShowQrToReceiveCryptoContainerProps = {
  params: RouteParams<ShowQrToReceiveCryptoRoute>;
  setNewAddressParams: (
    address: CryptoAddress,
    network: AddressNetwork | undefined,
    type: AddressType | undefined,
  ) => void;
  showQr?: () => void;
  generatePayMeLink?: (params: GeneratePayMeLinkRoute['params']) => void;
  promptReceiveCurrency?: (
    params: PromptCryptoToReceiveRoute['params'],
  ) => void;
} & RouteTransitionMap<
  | ListInputAddressesRoute
  | PromptAddressFormatRoute
  | PromptInputNetworkRoute
  | PromptReceiveNetworkRoute
>;

export default function useReceiveCryptoScreenProps(
  props: ShowQrToReceiveCryptoContainerProps,
): ReceiveCryptoScreenProps {
  const {
    params,
    listInputAddresses,
    generatePayMeLink,
    promptReceiveNetwork,
    promptReceiveCurrency,
  } = props;

  const {flashMessage, sharing} = useRoot();
  const strings = useStrings();
  const {walletId, address, addressNetwork, addressCurrency, depositType} =
    params;
  const {account} = useAccountState();
  const state = useReceiveCryptoBindingState(walletId, address, addressNetwork);

  useRedirectToNotFoundIfNoAddress(walletId, address);
  useRedirectToNotFoundIfNoWalletWithId(walletId);
  const {addressInfo, crypto} = state;
  const goToGenerateLink = useCallback(() => {
    generatePayMeLink?.({address, currency: params.addressCurrency});
  }, [address, params, generatePayMeLink]);
  const goToAddresses = useCallback(() => {
    listInputAddresses({
      walletId,
      address,
      addressNetwork: addressNetwork,
      addressCurrency,
      networkFilter: addressNetwork,
      currencyFilter: addressCurrency,
    });
  }, [listInputAddresses, walletId, address, addressNetwork, addressCurrency]);

  const onAddressTypePress = useCallback(() => {
    if (!state.wallet || !state.crypto) {
      return;
    }
    promptReceiveNetwork({
      walletId,
      address,
      addressNetwork,
      addressCurrency,
      networkFilter: addressNetwork,
      currencyFilter: addressCurrency,
    });
  }, [
    address,
    addressCurrency,
    addressNetwork,
    promptReceiveNetwork,
    state.crypto,
    state.wallet,
    walletId,
  ]);

  const onCurrencyPress = useCallback(
    () => promptReceiveCurrency?.({}),
    [promptReceiveCurrency],
  );

  const updateDescription = useCallback(async () => {
    if (!state.isUpdating) {
      void state.updateDescription();
      flashMessage.showMessage({
        title: strings['receiveCryptoScreen.successAddComment'],
        variant: 'success',
      });
    }
  }, [state, flashMessage, strings]);
  const copy = useCallback(
    (data: string) => {
      void setStringAsync(data);
      flashMessage.showMessage({
        title: strings.copied,
        variant: 'success',
      });
    },
    [flashMessage, strings.copied],
  );
  const isBitcoinDefaultNetwork = useMemo(
    () =>
      state.crypto?.options.default_network === state.addressInfo?.network &&
      state.wallet?.currency === 'BTC',
    [
      state.wallet?.currency,
      state.addressInfo?.network,
      state.crypto?.options.default_network,
    ],
  );

  const contractType = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!addressInfo || !crypto || !addressCurrency) {
      return undefined;
    }

    return crypto.options.currencies_in.find(
      c => c.network === addressInfo.network && c.currency === addressCurrency,
    )?.contract_type;
  }, [crypto, addressInfo, addressCurrency]);

  const initialTab = useMemo(() => {
    switch (depositType) {
      case DepositType.Buy:
        return ReceiveCryptoTabKey.Buy;
      case DepositType.Receive:
        return ReceiveCryptoTabKey.Receive;
      default:
        return ReceiveCryptoTabKey.Receive;
    }
  }, [depositType]);

  return {
    copy,
    isBitcoinDefaultNetwork: isBitcoinDefaultNetwork,
    contractType: contractType,
    comment: state.description,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    onChangeComment: state.setDescription,
    onSaveCommentPress: updateDescription,
    walletName: strings['currencyScreen.walletTitle'],
    address,
    currencyCode: state.wallet?.currency,
    cryptoCurrency: state.cryptoCurrency,
    fiatCurrency: state.fiatCurrency,
    networkCurrency: params.addressCurrency,
    cryptoValue: state.wallet?.total,
    fiatValue: state.fiatValue,
    userID: `@${account?.user_id ?? 'UserId'}`,
    goToAddresses,
    onCommentSubmit: noop,
    addressNameShown:
      !!state.crypto &&
      (state.crypto.options.show_network ||
        isNetworkChangePossible(state.crypto, 'in')),
    addressName: state.addressInfo?.name,
    addressType: state.addressInfo?.type,
    keyboardAvoiding: true,
    extra: {bottom: EXTRA_BOTTOM_OFFSET},
    showQr: props.showQr,
    goToGenerateLink: goToGenerateLink,
    onAddressTypePress,
    onCurrencyPress: onCurrencyPress,
    shareMessage: sharing.shareMessage,
    initialTab,
  };
}
