import {variance} from '@ncwallet-app/core';
import {Text, View} from 'react-native';

export const BottomContainer = variance(View)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

export const ErrorTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    textAlign: 'center',
    marginBottom: 20,
    marginTop: 'auto',
    fontSize: 13,
    lineHeight: 20,
    color: theme.palette.error,
  },
  withoutOffset: {
    marginTop: 0,
  },
}));
