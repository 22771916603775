import type {Millisecond} from '@ncwallet-app/core';
import {parseMillisecond} from '@ncwallet-app/core';
import type {
  PromptPeriodToFilterHistoryRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo, useState} from 'react';

import {EXTRA_BOTTOM_OFFSET} from '../../screens/constants';
import {
  HistoryPeriodFilterScreen,
  parseDate,
} from '../../screens/HistoryPeriodFilterScreen';

export type PromptPeriodToFilterHistoryContainerProps = {
  params: RouteParams<PromptPeriodToFilterHistoryRoute>;
  onApply: (from: Millisecond | undefined, to: Millisecond | undefined) => void;
  onSourceOpen: (to: Millisecond, from: Millisecond) => void;
  onDestinationOpen: (to: Millisecond, from: Millisecond) => void;
};

export default observer(function PromptPeriodToFilterHistoryContainer(
  props: PromptPeriodToFilterHistoryContainerProps,
) {
  const {onDestinationOpen, onSourceOpen, params, onApply} = props;
  const initialFrom = parseMillisecond(params?.from);
  const initialTo = parseMillisecond(params?.to);

  const [calendarStartValue, setCalendarStartValue] = useState({
    from: '',
    to: '',
  });

  const from = useMemo(
    () =>
      initialFrom
        ? initialFrom
        : (parseDate(calendarStartValue.from) as Millisecond),
    [calendarStartValue.from, initialFrom],
  );

  const to = useMemo(
    () =>
      initialTo ? initialTo : (parseDate(calendarStartValue.to) as Millisecond),
    [calendarStartValue.to, initialTo],
  );

  const handleFromOpen = useCallback(() => {
    onSourceOpen(to, from);
  }, [to, from, onSourceOpen]);

  const handleToOpen = useCallback(() => {
    onDestinationOpen(to, from);
  }, [to, from, onDestinationOpen]);

  return (
    <HistoryPeriodFilterScreen
      initialFrom={initialFrom}
      initialTo={initialTo}
      onApply={onApply}
      insets={SafeAreaInset.BOTTOM}
      extra={extra}
      fromOpen={handleFromOpen}
      toOpen={handleToOpen}
      calendarStartValue={calendarStartValue}
      setCalendarStartValue={setCalendarStartValue}
    />
  );
});

const extra = {bottom: EXTRA_BOTTOM_OFFSET};
