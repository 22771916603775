import {formatCryptoValue} from '../util';

export default function useBalanceCryptoValue(
  value: string | number,
  fractionDigits?: number,
) {
  const limit = 16;
  const stringValue = value.toString();
  const dotIndex = stringValue.indexOf('.');
  const lengthBeforeDot = dotIndex === -1 ? stringValue.length : dotIndex;

  if (lengthBeforeDot + 1 >= limit) {
    return stringValue.slice(0, lengthBeforeDot);
  }

  const formatValue = formatCryptoValue(stringValue, fractionDigits);

  if (formatValue.length > limit) {
    return formatValue.slice(0, limit);
  }

  return formatValue;
}
