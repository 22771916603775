import type {Analytics} from '../Analytics';
import type {GlobalError} from '../Error';
import {UNKNOWN_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {Json} from '../Json';
import type {Location} from '../Location';
import type {HeadlessMessagingHelper, MessageContext} from '../Messaging';
import type {
  HeadlessLocalNotifications,
  LocalNotificationContext,
  LocalNotificationId,
} from './HeadlessLocalNotifications';
import {EventType} from './HeadlessLocalNotifications';
import type {LocalNotificationData} from './NotificationData';

export default abstract class BaseHeadlessLocalNotificationsImpl
  implements HeadlessLocalNotifications
{
  protected constructor(
    protected readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly json: Json;
      readonly analytics: Analytics;
      readonly location: Location;
      readonly headlessMessagingHelper: HeadlessMessagingHelper;
    },
  ) {}

  protected async _wrapContext(
    context: MessageContext,
  ): Promise<Either<LocalNotificationData, GlobalError>> {
    if (!Object.is(context.message.type, 'notification')) {
      return error(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          description: 'Unknown message type',
        }),
      );
    }
    const contextString_ = this._root.json.stringify(context);
    if (!contextString_.success) {
      return contextString_;
    }
    return success({
      designator: 'local-notification-data',
      context: contextString_.right,
    });
  }

  abstract scheduleNotification(
    context: MessageContext,
  ): Promise<Either<void, GlobalError>>;

  abstract cancelNotification(
    id: LocalNotificationId,
  ): Promise<Either<void, GlobalError>>;

  async disposeNotification(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id: LocalNotificationId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    groupId: string,
  ): Promise<Either<void, GlobalError>> {
    return success();
  }

  async hideNotificationDrawer(): Promise<Either<void, GlobalError>> {
    return success();
  }

  async handle(
    context: LocalNotificationContext,
  ): Promise<Either<void, GlobalError>> {
    const {id, data, actionId, type} = context;
    const messageContext_ =
      await this._root.headlessMessagingHelper.exchangeNotificationDataForMessageContext(
        data,
      );
    if (!messageContext_.success) {
      return messageContext_;
    }
    const messageContext = messageContext_.right;
    switch (type) {
      case EventType.Dismissed: {
        if (id !== undefined && messageContext.message.group) {
          await this.disposeNotification(id, messageContext.message.group.id);
        }
        await this._root.analytics.report(messageContext.event_url, {
          event: 'close',
          meta: messageContext.meta,
          data: {},
        });
        break;
      }
      case EventType.Delivered: {
        const isInstant = messageContext.url === undefined;
        if (isInstant) {
          await this._root.analytics.report(messageContext.event_url, {
            event: 'show',
            meta: messageContext.meta,
            data: {},
          });
        }
        break;
      }
      case EventType.Press: {
        const action = messageContext.message.actions?.find(
          _ => _.action === actionId,
        );
        await this.hideNotificationDrawer();
        const url = action ? action.url : messageContext.message.url;
        if (url) {
          await this._root.location.open(url);
        }
        const meta = messageContext.meta;
        await this._root.analytics.report(
          messageContext.event_url,
          action
            ? {event: 'action', meta, data: {action_name: action.action}}
            : {event: 'click', meta, data: {}},
        );
        if (id) {
          await this.cancelNotification(id);
        }
        break;
      }
      case EventType.Initial: {
        await this._root.analytics.report(messageContext.event_url, {
          event: 'click',
          meta: messageContext.meta,
          data: {},
        });
        if (id) {
          await this.cancelNotification(id);
        }
        break;
      }
    }
    return success();
  }
}
