import type {CryptoCurrencyCode, WalletLimit} from '@ncwallet-app/core';
import {useRoot, WalletLimitPeriod} from '@ncwallet-app/core';
import {
  LimitRemovalAction,
  PROMPT_LIMIT_REMOVAL_ROUTE,
  PROMPT_LIMIT_UPDATE_ROUTE,
  PROMPT_NEW_LIMIT_ROUTE,
  SHOW_LIMIT_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {useCallback, useEffect, useState} from 'react';

import type {ShowLimitCardProps} from '../../../../../../screens/LgLimitsSettingsScreen/LimitCard/ShowLimitCard/ShowLimitCard';
import {ShowLimitBindingState} from '../../../../../../SmallNavigationRoot/SmallHomeStack/bindings/ShowLimitBinding/ShowLimitBindingState';
import type {LargeSwitchBindingProps} from '../../LargeSwitchBindingProps';

export default function useShowLimitCard(
  props: LargeSwitchBindingProps<'ListLimits'>,
): ShowLimitCardProps {
  // ниже временная реализация
  const root = useRoot();
  const {route, navigation} = props;
  const [state] = useState(() => new ShowLimitBindingState(root));

  useEffect(() => {
    if (route.params?.focusedSelectedLimit?.kind === SHOW_LIMIT_ROUTE) {
      void state.refresh(route.params.focusedSelectedLimit.params.walletId);
    } else {
      state.reset();
    }
  }, [route.params, state]);

  const onChangePress = useCallback(
    (l: WalletLimit) => {
      navigation.setParams({
        focusedSelectedLimit: {
          kind: PROMPT_LIMIT_UPDATE_ROUTE,
          params: {
            limit: l.limit,
            currency: l.currency,
            walletId: l.wallet_id,
            period: l.period,
          },
        },
      });
    },
    [navigation],
  );

  const onDeletePress = useCallback(
    (l: WalletLimit) => {
      navigation.setParams({
        focusedSelectedLimit: {
          kind: PROMPT_LIMIT_REMOVAL_ROUTE,
          params: {limitId: l.id, action: LimitRemovalAction.Delete},
        },
      });
    },
    [navigation],
  );
  const onDeleteWaitingForActivationLimitPress = useCallback(
    (l: WalletLimit) => {
      navigation.setParams({
        focusedSelectedLimit: {
          kind: PROMPT_LIMIT_REMOVAL_ROUTE,
          params: {
            limitId: l.id,
            action: LimitRemovalAction.DeleteWaitingForActivationLimit,
          },
        },
      });
    },
    [navigation],
  );

  const onCancelDeletePress = useCallback(
    (l: WalletLimit) => {
      navigation.setParams({
        focusedSelectedLimit: {
          kind: PROMPT_LIMIT_REMOVAL_ROUTE,
          params: {
            limitId: l.id,
            action: LimitRemovalAction.CancelDelete,
          },
        },
      });
    },
    [navigation],
  );

  const onCreatePress = useCallback(
    (
      currency: CryptoCurrencyCode,
      period: WalletLimitPeriod,
      perOnePeriodOnly?: boolean,
    ) => {
      if (route.params?.focusedSelectedLimit?.kind === SHOW_LIMIT_ROUTE) {
        navigation.setParams({
          focusedSelectedLimit: {
            kind: PROMPT_NEW_LIMIT_ROUTE,
            params: {
              period,
              currency,
              walletId: route.params.focusedSelectedLimit.params.walletId,
              perOnePeriodOnly,
            },
          },
        });
      }
    },
    [route.params, navigation],
  );

  const period =
    route.params?.focusedSelectedLimit?.kind === SHOW_LIMIT_ROUTE
      ? route.params.focusedSelectedLimit.params.period
      : WalletLimitPeriod.Daily;

  return {
    getDailyActiveLimit: state.getDailyActiveLimit,
    getWaitingForActivationDailyLimit: state.getWaitingForActivationDailyLimit,
    getActiveMonthlyLimit: state.getActiveMonthlyLimit,
    getWaitingForActivationMonthlyLimit:
      state.getWaitingForActivationMonthlyLimit,
    period,
    currency: state.currency,
    onCreatePress,
    onChangePress,
    onDeletePress,
    onDeleteWaitingForActivationLimitPress,
    onCancelDeletePress,
  };
}
