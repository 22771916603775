import {useStrings, variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT, SearchHeader} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

export type CountryListSearchProps = {
  onSearch: (searchQuery: string) => void;
};

export default observer(function CountryListSearch(
  props: CountryListSearchProps,
) {
  const {onSearch} = props;
  const strings = useStrings();
  return (
    <CountrySearchHeader
      placeholder={
        strings['profileVerification.selectCountry.search.placeholder']
      }
      onSubmit={onSearch}
      onValueChange={onSearch}
    />
  );
});

const CountrySearchHeader = variance(SearchHeader)(theme => ({
  root: {
    backgroundColor: theme.palette.background,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 20,
        padding: 0,
        borderBottomWidth: 0,
      },
    }),
  },
}));
