import {useTheme} from '@ncwallet-app/core';
import {PlatformType} from '@ncwallet-app/core/src/models';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {
  AndroidSvg,
  ChromeSvg,
  DesktopSvg,
  IosSvg,
  TelegramSvg,
} from '../../assets/svg/colorless';

type DeviceIconProps = {
  type: PlatformType;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deviceIconMap: Record<PlatformType, React.ComponentType<any>> = {
  [PlatformType.ANDROID]: AndroidSvg,
  [PlatformType.IOS]: IosSvg,
  [PlatformType.WEB]: DesktopSvg,
  [PlatformType.CHROME]: ChromeSvg,
  [PlatformType.TELEGRAM]: TelegramSvg,
  [PlatformType.UNKNOWN]: DesktopSvg,
};

export default observer(function DeviceIcon(props: DeviceIconProps) {
  const theme = useTheme();
  const Icon = deviceIconMap[props.type];
  switch (props.type) {
    case PlatformType.CHROME:
    case PlatformType.WEB:
      return (
        <Icon
          color={theme.palette.background}
          secondColor={theme.palette.info}
        />
      );
    case PlatformType.IOS:
      return (
        <Icon
          color={theme.palette.background}
          secondColor={theme.palette.uiSystem}
        />
      );
    case PlatformType.ANDROID:
      return (
        <Icon
          color={theme.palette.background}
          secondColor={theme.palette.success}
        />
      );
    case PlatformType.TELEGRAM:
      return <Icon color={theme.palette.background} secondColor="#229ED9" />;
    default:
      return null;
  }
});
