import {computed, makeObservable} from 'mobx';

import type {AdvertSpot} from '../NCWalletServer';
import type {Millisecond} from '../Time';
import type {AdvertSpotState} from './AdVisibilityState';
import type {CommonAdvertSpotState} from './CommonAdvertSpotState';

export default class BaseAdvertSpotStateImpl implements AdvertSpotState {
  protected constructor(
    protected readonly _spot: AdvertSpot,
    protected readonly _common: CommonAdvertSpotState,
  ) {
    makeObservable(this);
  }

  @computed get adToDisplay() {
    return this._common.getAdToDisplay(this._spot);
  }

  async hideAd(now: Millisecond) {
    return this._common.hideAd(this._spot, now);
  }
}
