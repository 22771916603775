import {computed, makeObservable} from 'mobx';

import type {ShallowCommonState} from '../CommonNavigationScheme';
import type {LayoutHelperState} from '../LayoutHelperState';
import type {ReadonlyNavigationContainerState} from '../NavigationContainer';
import type {Service} from '../structure';
import getCurrentRouteKind from '../util/getCurrentRouteKind';
import {routesWithoutAds} from '../util/routesWithoutAds';
import type {AdvertSpotState} from './AdVisibilityState';
import type {CommonAdvertSpotState} from './CommonAdvertSpotState';
import TriggerAdvertSpotStateService from './TriggerAdvertSpotStateService';

export default class NewModalAdvertSpotStateService
  extends TriggerAdvertSpotStateService
  implements AdvertSpotState, Service
{
  constructor(
    private readonly _isDesktop: boolean,
    _common: CommonAdvertSpotState,
    protected readonly _root: {
      readonly navigationContainerState: ReadonlyNavigationContainerState;
      readonly layoutHelperState: LayoutHelperState;
    },
  ) {
    super(
      _isDesktop ? 'new_modal_desktop' : 'new_modal_mobile',
      _common,
      _root,
    );
    makeObservable(this);
  }

  @computed protected get _isOnSpot() {
    const {layoutHelperState, navigationContainerState} = this._root;
    const isDesktop = this._isDesktop;
    return (
      ((isDesktop && layoutHelperState.isLarge) ||
        (!isDesktop && !layoutHelperState.isLarge)) &&
      isAllowedScreen(navigationContainerState.latestState)
    );
  }
}

export function isAllowedScreen(_?: ShallowCommonState) {
  return !!_ && !routesWithoutAds.has(getCurrentRouteKind(_));
}
