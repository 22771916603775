import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {
  BodyTag,
  ClickableIcon,
  ClickableTag,
  DeleteIpActionContainer,
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../../ProfileSettingsScreen/LgProfileCards/useCardStyles';

export type PromptClearAllowedListCardProps = {
  onBack: () => void;
  onConfirm: () => void;
};

export default observer(function PromptClearAllowedListCard(
  props: PromptClearAllowedListCardProps,
) {
  const {onBack, onConfirm} = props;
  const strings = useStrings();
  return (
    <LgProfileCardContainer>
      <HeaderTag>
        <ClickableTag onPress={onBack}>
          <ClickableIcon />
          <HeaderTitleTag>
            {strings['cleanWhiteListModal.title']}
          </HeaderTitleTag>
        </ClickableTag>
      </HeaderTag>
      <BodyTag>
        <DeleteIpActionContainer>
          <Button
            variant={ButtonVariant.PrimaryLight}
            title={strings['deleteIpModal.confirm']}
            onPress={onConfirm}
          />
          <SizedBox />
          <Button
            variant={ButtonVariant.Danger}
            title={strings['cleanWhiteListModal.cancel']}
            onPress={onBack}
          />
        </DeleteIpActionContainer>
      </BodyTag>
    </LgProfileCardContainer>
  );
});
const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));
