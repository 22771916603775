import {StorageAccessFramework, writeAsStringAsync} from 'expo-file-system';

import type {GlobalError} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import BaseFileSystemImpl from './BaseFileSystemImpl';
import type {FileSystem} from './FileSystem';

export default class AndroidFileSystemImpl
  extends BaseFileSystemImpl
  implements FileSystem
{
  constructor(
    protected readonly _root: {readonly errorRepository: ErrorRepository},
  ) {
    super(_root);
  }

  async save(
    content: string,
    filename: string,
    mimeType: string,
  ): Promise<Either<void, GlobalError>> {
    try {
      const permissions_ =
        await StorageAccessFramework.requestDirectoryPermissionsAsync();
      if (!permissions_.granted) {
        return error(this._createPermissionError());
      }
      const uri = await StorageAccessFramework.createFileAsync(
        permissions_.directoryUri,
        filename,
        mimeType,
      );
      await writeAsStringAsync(uri, content, {encoding: 'utf8'});
      return success(undefined);
    } catch (raw) {
      return error(this._createUnknownError(raw));
    }
  }
}
