import type {CryptoCurrencyCode, WalletId} from '@ncwallet-app/core';
import type {
  PromptCryptoToExchangeRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useTabBarLayout} from '../../components';
import {filterCurrencySelectionItems} from '../../Navigation/hooks';
import {SelectionForExchangeScreen} from '../../screens/SelectionForExchangeScreen';
import {ScreenType} from '../../screens/SelectionForExchangeScreen/ScreenType';
import {useSelectionForExchangeBindingState} from '../hooks/usePromptSourceCryptoToExchangeContainer/useSelectionForExchangeBindingState';

export type PromptCryptoToExchangeContainerProps = {
  isLg: boolean;
  params: RouteParams<PromptCryptoToExchangeRoute>;
  onCurrencyPress: (crypto: CryptoCurrencyCode, walletId?: WalletId) => void;
  onSearchChange: (search: string) => void;
  onReceivePress: () => void;
  onBack: () => void;
  onAddPress: () => void;
};

export default observer(function PromptCryptoToExchangeContainer(
  props: PromptCryptoToExchangeContainerProps,
) {
  const {
    isLg,
    onReceivePress,
    onCurrencyPress: _onCurrencyPress,
    params,
    onSearchChange,
    onBack,
    onAddPress,
  } = props;
  const layout = useTabBarLayout();
  const search = params.search;
  const {state, handleManualRefresh} = useSelectionForExchangeBindingState(
    params.isSourceRequested,
    params.disabledCrypto,
  );

  const onCurrencyPress = useCallback(
    (currency: CurrencySelectionListItemData) => {
      _onCurrencyPress(
        currency.cryptoCurrency.code as CryptoCurrencyCode,
        currency.walletId,
      );
    },
    [_onCurrencyPress],
  );

  const shownCurrencies = filterCurrencySelectionItems(
    state.currencies,
    search,
  );

  const getIsRefreshing = useCallback(() => {
    return (
      state.getIsLoading() && (!state.currencies || state.getIsManualRefresh())
    );
  }, [state]);

  return (
    <SelectionForExchangeScreen
      isLg={isLg}
      screenType={ScreenType.Modal}
      search={search}
      keyboardAvoiding
      onSearchChange={onSearchChange}
      onReceivePress={onReceivePress}
      shownCurrencies={shownCurrencies}
      allCurrencies={state.currencies}
      onCurrencyPress={onCurrencyPress}
      onRefresh={handleManualRefresh}
      getIsRefreshing={getIsRefreshing}
      onBack={onBack}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: layout.height}}
      onAddPress={onAddPress}
    />
  );
});
