export default function bezierPath(
  width: number,
  height: number,
  data: number[],
) {
  if (data.length <= 1) {
    return '';
  }
  const stepX = width ? width / (data.length - 1) : 1;
  return data
    .map((value, i) => {
      if (i === 0) {
        const y = (1 - value) * height;
        return `M0 ${y}`;
      }
      const x = i * stepX;
      const y = (1 - value) * height;
      const k =
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        data[i + 1] === undefined
          ? (data[i - 1] - data[i]) / stepX
          : (data[i - 1] - data[i + 1]) / (2 * stepX);
      const b = y - k * x;
      const x2 = x - stepX / 2;
      const y2 = k * x2 + b;
      return `S${x2} ${y2}, ${x} ${y}`;
    })
    .join(' ');
}
