import {useStrings, variance} from '@ncwallet-app/core/src';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
} from '@ncwallet-app/ui/src';
import {CopySvg, ShareSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {WalletInfo} from './WalletInfo';

type UsernameFragmentProps = {
  userData: string;
  onCopy: (data: string) => void;
  onShare: (data: string) => void;
  shareShown: boolean;
  goToAddresses: () => void;
};

export const UsernameTabView = observer((props: UsernameFragmentProps) => {
  const strings = useStrings();
  const linkBaseUrl = 'https://www.NCWallet.com';
  const getDeepLink = (payload: string) => `${linkBaseUrl}/${payload}`;

  return (
    <>
      <UsernameInfo>
        <UsernameInfoHeader>
          {strings['receiveCryptoScreen.acceptPaymentsByUsername']}
        </UsernameInfoHeader>
        <UsernameId>{props.userData}</UsernameId>
      </UsernameInfo>
      <UsernameActions>
        <ReceiveButton
          title={strings['receiveCryptoScreen.copy']}
          onPress={() => {
            props.onCopy(props.userData);
          }}
          variant={ButtonVariant.PrimaryLight}
          color={ButtonColor.Secondary}
          Icon={CopySvg}
        />
        {props.shareShown && (
          <>
            <Spacer />
            <ReceiveButton
              title={strings['receiveCryptoScreen.share']}
              onPress={() => {
                props.onShare(props.userData);
              }}
              variant={ButtonVariant.PrimaryLight}
              color={ButtonColor.Secondary}
              Icon={ShareSvg}
            />
          </>
        )}
      </UsernameActions>
      <WalletInfo
        shareShown={props.shareShown}
        label={strings['receiveCryptoScreen.deepLinkLabel']}
        info={getDeepLink(props.userData)}
        goToAddresses={props.goToAddresses}
        onCopy={() => {
          props.onCopy(getDeepLink(props.userData));
        }}
        onShare={() => {
          props.onShare(getDeepLink(props.userData));
        }}
      />
    </>
  );
});

export const UsernameInfo = variance(View)(theme => ({
  root: {
    padding: 15,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        padding: 0,
      },
    }),
  },
}));

export const UsernameInfoHeader = variance(Text)(theme => ({
  root: {
    marginBottom: 30,
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional2,
  },
}));

export const UsernameId = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 36,
  },
}));

export const UsernameActions = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    marginBottom: 40,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
        marginBottom: 0,
      },
    }),
  },
}));

export const DeeplinkTitle = variance(Text)(theme => ({
  root: {
    marginBottom: 20,
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional2,
  },
}));

const ReceiveButton = variance(Button)(() => ({
  root: {
    flex: 1,
    marginBottom: 30,
  },
}));

const Spacer = variance(View)(() => ({
  root: {
    width: 15,
  },
}));
