import {makeObservable, observable, runInAction} from 'mobx';

import type {AdvertId, AdvertSpot} from '../NCWalletServer';
import type {Disposer, Service} from '../structure';
import type {Millisecond} from '../Time';
import type {
  AdSuspensionQuery,
  AdSuspensionRepository,
} from './AdSuspensionRepository';
import type {AdSuspensionState} from './AdSuspensionState';

export default class AdSuspensionStateService
  implements AdSuspensionState, Service
{
  @observable.ref private _query?: AdSuspensionQuery;

  constructor(
    private readonly _root: {
      readonly adSuspensionRepository: AdSuspensionRepository;
    },
  ) {
    makeObservable(this);
  }

  get adSuspensionQuery() {
    return this._query;
  }

  async suspendAd(
    id: AdvertId,
    spot: AdvertSpot,
    now: Millisecond,
  ): Promise<void> {
    await this._root.adSuspensionRepository.suspendAd(id, spot, now);
    await this._load();
  }

  subscribe(): Disposer | undefined {
    void this._load();
    return undefined;
  }

  private async _load() {
    const query = await this._root.adSuspensionRepository.createAdsQuery();
    runInAction(() => {
      this._query = query;
    });
  }
}
