import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {
  PromptOtpToAddIpRoute,
  PromptOtpToDeleteIpRoute,
  PromptOtpToUpdateIpRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {
  LIST_ALLOWED_IPS_ROUTE,
  LIST_BLOCKED_IPS_ROUTE,
  PROMPT_ADD_IP_ROUTE,
  PROMPT_OTP_TO_ADD_IP_ROUTE,
  PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE,
  PROMPT_OTP_TO_DELETE_IP_ROUTE,
  PROMPT_OTP_TO_UPDATE_IP_ROUTE,
  PROMPT_TO_CLEAR_ALLOWED_LIST_ROUTE,
  PROMPT_TO_DELETE_IP_ROUTE,
  PROMPT_UPDATE_IP_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {SecuritySettingsCard} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {IpInfo} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {autorun, runInAction} from 'mobx';
import {useCallback, useEffect} from 'react';

import {useAddIp} from '../../../../CommonNavigationContainers/hooks/ipSettings/useAddIp';
import {useAddIpWithOtp} from '../../../../CommonNavigationContainers/hooks/ipSettings/useAddIpWithOtp';
import {useClearAllowedIpList} from '../../../../CommonNavigationContainers/hooks/ipSettings/useClearAllowedIpList';
import {useClearAllowedIpListWithOtp} from '../../../../CommonNavigationContainers/hooks/ipSettings/useClearAllowedIpListWithOtp';
import {useDeleteIp} from '../../../../CommonNavigationContainers/hooks/ipSettings/useDeleteIp';
import {useDeleteIpWithOtp} from '../../../../CommonNavigationContainers/hooks/ipSettings/useDeleteIpWithOtp';
import {useIpSettings} from '../../../../CommonNavigationContainers/hooks/ipSettings/useIpSettings';
import {useUpdateIp} from '../../../../CommonNavigationContainers/hooks/ipSettings/useUpdateIp';
import {useUpdateIpWithOtp} from '../../../../CommonNavigationContainers/hooks/ipSettings/useUpdateIpWithOtp';
import type {LargeSwitchBindingProps} from '../../../../LargeNavigationRoot/LargeHomeStack/bindings/LargeSwitch/LargeSwitchBindingProps';
import {useNavigationGetIsFocused} from '../../../hooks';

// eslint-disable-next-line import-x/prefer-default-export
export const useIpSettingsCard = (
  props: LargeSwitchBindingProps<'ListSecuritySettings'>,
) => {
  const {navigation, route} = props;
  const {loadIpSettings, ipSettings} = useIpSettings();
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  useEffect(
    () =>
      autorun(() => {
        if (
          getIsReady() &&
          getIsFocused() &&
          (route.params?.focusedSessions?.kind === LIST_ALLOWED_IPS_ROUTE ||
            route.params?.focusedSessions?.kind === LIST_BLOCKED_IPS_ROUTE)
        ) {
          runInAction(() => {
            void loadIpSettings();
          });
        }
      }),
    [
      getIsFocused,
      getIsReady,
      loadIpSettings,
      route.params?.focusedSessions?.kind,
    ],
  );

  const goToListAllowedIps = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.Sessions,
      focusedSessions: {kind: LIST_ALLOWED_IPS_ROUTE},
    });
  }, [navigation]);

  const goToListBlockedIps = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.Sessions,
      focusedSessions: {kind: LIST_BLOCKED_IPS_ROUTE},
    });
  }, [navigation]);

  const goToPromptAddOtp = useCallback(
    (params: RouteParams<PromptOtpToAddIpRoute>) => {
      navigation.setParams({
        focusedCard: SecuritySettingsCard.Sessions,
        focusedSessions: {
          kind: PROMPT_OTP_TO_ADD_IP_ROUTE,
          params,
        },
      });
    },
    [navigation],
  );

  const goToPromptUpdateOtp = useCallback(
    (params: RouteParams<PromptOtpToUpdateIpRoute>) => {
      navigation.setParams({
        focusedCard: SecuritySettingsCard.Sessions,
        focusedSessions: {
          kind: PROMPT_OTP_TO_UPDATE_IP_ROUTE,
          params,
        },
      });
    },
    [navigation],
  );

  const goToIpList = useCallback(
    (type: IpType) => {
      navigation.setParams({
        focusedCard: SecuritySettingsCard.Sessions,
        focusedSessions: {
          kind:
            type === IpType.Allowed
              ? LIST_ALLOWED_IPS_ROUTE
              : LIST_BLOCKED_IPS_ROUTE,
        },
      });
    },
    [navigation],
  );

  const focusedSessionKind = route.params?.focusedSessions?.kind;
  const {
    addIp: baseAddIp,
    getErrorText: getAddIpFormErrorText,
    resetErrorText: resetAddIpFormErrorText,
  } = useAddIp(goToPromptAddOtp, goToIpList);

  useEffect(() => {
    if (focusedSessionKind !== PROMPT_ADD_IP_ROUTE) {
      resetAddIpFormErrorText();
    }
  }, [focusedSessionKind, resetAddIpFormErrorText]);

  const {
    updateIp,
    getErrorText: getEditIpFormErrorText,
    resetErrorText: resetEditIpFormErrorText,
  } = useUpdateIp(goToPromptUpdateOtp, goToIpList);

  useEffect(() => {
    if (focusedSessionKind !== PROMPT_UPDATE_IP_ROUTE) {
      resetEditIpFormErrorText();
    }
  }, [focusedSessionKind, resetEditIpFormErrorText]);

  const {
    getErrorText: getOtpToDeleteIpErrorText,
    resetErrorText: resetOtpToDeleteIpErrorText,
    deleteWitOtp,
  } = useDeleteIpWithOtp(goToIpList);

  useEffect(() => {
    if (focusedSessionKind !== PROMPT_OTP_TO_DELETE_IP_ROUTE) {
      resetOtpToDeleteIpErrorText();
    }
  }, [
    focusedSessionKind,
    resetEditIpFormErrorText,
    resetOtpToDeleteIpErrorText,
  ]);

  const {
    getErrorText: getOtpToUpdateIpErrorText,
    resetErrorText: resetOtpToUpdateIpErrorText,
    updateWithOtp,
  } = useUpdateIpWithOtp(goToIpList);

  useEffect(() => {
    if (focusedSessionKind !== PROMPT_OTP_TO_UPDATE_IP_ROUTE) {
      resetOtpToUpdateIpErrorText();
    }
  }, [
    focusedSessionKind,
    resetEditIpFormErrorText,
    resetOtpToUpdateIpErrorText,
  ]);

  const {
    getErrorText: getOtpToAddIpErrorText,
    resetErrorText: resetOtpToAddIpErrorText,
    addWithOtp,
  } = useAddIpWithOtp(goToIpList);

  useEffect(() => {
    if (focusedSessionKind !== PROMPT_OTP_TO_ADD_IP_ROUTE) {
      resetOtpToAddIpErrorText();
    }
  }, [focusedSessionKind, resetEditIpFormErrorText, resetOtpToAddIpErrorText]);

  const addIp = useCallback(
    (ip: string, name: string) => {
      if (focusedSessionKind === PROMPT_ADD_IP_ROUTE) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return baseAddIp(ip, name, route.params!.focusedSessions.params.type);
      }
    },
    [baseAddIp, focusedSessionKind, route.params],
  );

  const promptDeleteIp = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const focusedSessions = route.params!.focusedSessions;
    if (focusedSessions?.kind !== PROMPT_UPDATE_IP_ROUTE) {
      return;
    }
    const {id, ip, type} = focusedSessions.params;
    navigation.setParams({
      focusedCard: SecuritySettingsCard.Sessions,
      focusedSessions: {
        kind: PROMPT_TO_DELETE_IP_ROUTE,
        params: {
          id,
          ip,
          type,
        },
      },
    });
  }, [navigation, route.params]);

  const onEditIpFormSubmit = useCallback(
    (ip: string, name: string) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const focusedSessions = route.params!.focusedSessions;
      if (focusedSessions?.kind === PROMPT_UPDATE_IP_ROUTE) {
        const {id, type} = focusedSessions.params;
        return updateIp(id, type, ip, name);
      }
    },
    [route.params, updateIp],
  );

  const goToPromptAddIp = useCallback(
    (type: IpType) => {
      navigation.setParams({
        focusedCard: SecuritySettingsCard.Sessions,
        focusedSessions: {
          kind: PROMPT_ADD_IP_ROUTE,
          params: {type},
        },
      });
    },
    [navigation],
  );

  const goToPromptUpdateIp = useCallback(
    (info: IpInfo) => {
      navigation.setParams({
        focusedCard: SecuritySettingsCard.Sessions,
        focusedSessions: {
          kind: PROMPT_UPDATE_IP_ROUTE,
          params: {
            id: info.id,
            name: info.name,
            ip: info.ip,
            type: info.type,
          },
        },
      });
    },
    [navigation],
  );

  const goToPromptOtpToDeleteIp = useCallback(
    (params: RouteParams<PromptOtpToDeleteIpRoute>) => {
      navigation.setParams({
        focusedCard: SecuritySettingsCard.Sessions,
        focusedSessions: {
          kind: PROMPT_OTP_TO_DELETE_IP_ROUTE,
          params,
        },
      });
    },
    [navigation],
  );

  const goToPromptOtpToClearAllowedList = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.Sessions,
      focusedSessions: {
        kind: PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE,
      },
    });
  }, [navigation]);

  const {deleteIp} = useDeleteIp(goToPromptOtpToDeleteIp, goToIpList);
  const {clearAllowedList} = useClearAllowedIpList(
    goToPromptOtpToClearAllowedList,
    goToListBlockedIps,
  );

  const {
    clearAllowedIpListWithOtp,
    getErrorText: getOtpToClearAllowedListErrorText,
    resetErrorText: resetOtpToClearAllowedLIstErrorText,
  } = useClearAllowedIpListWithOtp(goToListBlockedIps);

  const confirmDeleteIp = useCallback(async () => {
    if (route.params?.focusedSessions?.kind !== PROMPT_TO_DELETE_IP_ROUTE) {
      return;
    }
    const {id, type} = route.params.focusedSessions.params;
    return deleteIp(id, type);
  }, [deleteIp, route.params]);

  const onSubmitOtpToUpdateIp = useCallback(
    (code: string) => {
      if (
        route.params?.focusedSessions?.kind !== PROMPT_OTP_TO_UPDATE_IP_ROUTE
      ) {
        return;
      }
      const {id, ip, name, type} = route.params.focusedSessions.params;
      return updateWithOtp(id, ip, name, type, code);
    },
    [updateWithOtp, route.params],
  );

  const onSubmitOtpToAddIp = useCallback(
    (code: string) => {
      if (route.params?.focusedSessions?.kind !== PROMPT_OTP_TO_ADD_IP_ROUTE) {
        return;
      }
      const {ip, name, type} = route.params.focusedSessions.params;
      return addWithOtp(ip, name, type, code);
    },
    [addWithOtp, route.params],
  );

  const onSubmitOtpToDeleteIp = useCallback(
    (code: string) => {
      if (
        route.params?.focusedSessions?.kind !== PROMPT_OTP_TO_DELETE_IP_ROUTE
      ) {
        return;
      }
      const {id, type} = route.params.focusedSessions.params;
      return deleteWitOtp(id, type, code);
    },
    [deleteWitOtp, route.params],
  );

  const goToPromptClearAllowedList = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.Sessions,
      focusedSessions: {
        kind: PROMPT_TO_CLEAR_ALLOWED_LIST_ROUTE,
      },
    });
  }, [navigation]);

  return {
    ipSettings,
    goToListAllowedIps,
    goToListBlockedIps,
    goToPromptAddIp,
    getAddIpFormErrorText,
    resetAddIpFormErrorText,
    getEditIpFormErrorText,
    resetEditIpFormErrorText,
    goToPromptUpdateIp,
    addIp,
    promptDeleteIp,
    confirmDeleteIp,
    onEditIpFormSubmit,
    onSubmitOtpToDeleteIp,
    getOtpToDeleteIpErrorText,
    resetOtpToDeleteIpErrorText,
    onSubmitOtpToAddIp,
    getOtpToAddIpErrorText,
    resetOtpToAddIpErrorText,
    onSubmitOtpToUpdateIp,
    getOtpToUpdateIpErrorText,
    resetOtpToUpdateIpErrorText,
    goToPromptClearAllowedList,
    confirmClearAllowedList: clearAllowedList,
    onSubmitOtpToClearAllowedList: clearAllowedIpListWithOtp,
    getOtpToClearAllowedListErrorText,
    resetOtpToClearAllowedLIstErrorText,
  };
};
