import {useStyles} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {FlatListProps, ListRenderItem} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import type {HistorySessionInfo} from '../../SessionsHistoryScreen/HistorySessionInfo';
import type {SessionListHistoryItemProps} from './SessionHistoryListItem';

export type SessionListProps = Partial<FlatListProps<HistorySessionInfo>> & {
  getSessions: () => HistorySessionInfo[] | undefined;
  onSessionPress?: (session: HistorySessionInfo) => void;
  RenderItemComponent: React.ComponentType<SessionListHistoryItemProps>;
  preventShrink?: boolean;
  onEndReached: () => void;
};

const keyExtractor = (entry: HistorySessionInfo) => entry.id;

export default observer(function SessionListHistory(props: SessionListProps) {
  const {
    getSessions,
    onSessionPress,
    RenderItemComponent,
    preventShrink = false,
    onEndReached,
    ...rest
  } = props;

  const renderItem: ListRenderItem<HistorySessionInfo> = useCallback(
    ({item, index}) => {
      return <RenderItemComponent isFirst={index === 0} session={item} />;
    },
    [RenderItemComponent],
  );

  const style = useStyles(theme => ({
    container: {
      flexShrink: preventShrink ? 0 : 1,
      flexGrow: 0,
      borderWidth: 1,
      borderRadius: 8,
      borderColor: theme.palette.uiSecondary,
      backgroundColor: theme.palette.uiPrimary,
    },
  }));

  return (
    <FlatList
      overScrollMode="never"
      data={getSessions()}
      renderItem={renderItem}
      extraData={onSessionPress}
      keyExtractor={keyExtractor}
      style={style.container}
      onEndReached={onEndReached}
      {...rest}
    />
  );
});
