export const PENDING = Symbol();
export const FULFILLED = Symbol();
export const REJECTED = Symbol();

export type PromiseState<R, E, P = void> =
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  | (P extends void
      ? {readonly status: typeof PENDING; readonly params?: P}
      : {readonly status: typeof PENDING; readonly params: P})
  | {readonly status: typeof FULFILLED; readonly result: R}
  | {readonly status: typeof REJECTED; readonly error: E};
