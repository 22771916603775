import type {PixelRatio} from '../DevicePixelRatio';
import type {OptionalGetter} from '../mobx-toolbox';
import {expr, use} from '../mobx-toolbox';
import type {AdvertSize} from '../NCWalletServer';
import {first} from '../util';

export type PixelRatioBySize = Record<AdvertSize, PixelRatio>;

export default function pickSize(
  $pixelRatio: OptionalGetter<PixelRatio>,
  sizes: Set<AdvertSize>,
  map: PixelRatioBySize,
): AdvertSize {
  if (sizes.size === 0) {
    throw new Error('No size to choose from');
  }
  if (sizes.size === 1) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return first(sizes)!;
  }
  let current: AdvertSize | undefined;
  for (const size of SIZES) {
    if (sizes.has(size)) {
      if (current === undefined) {
        current = size;
      } else {
        if (expr(() => use($pixelRatio) <= map[size])) {
          current = size;
        }
      }
    }
  }
  if (!current) {
    throw new Error('No proper size given');
  }
  return current;
}

const SIZES: readonly AdvertSize[] = Object.freeze(['xl', 'l', 'm', 'xs']);
