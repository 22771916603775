import type {ISODateString, OtpCode} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {IpInfoId} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import type {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import dayjs from 'dayjs';
import {useCallback, useState} from 'react';

import {useBaseErrorDescription} from '..';

// eslint-disable-next-line import-x/prefer-default-export
export const useDeleteIpWithOtp = (goToIpList: (type: IpType) => void) => {
  const root = useRoot();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [getErrorText, setError, resetErrorText] = useBaseErrorDescription();

  const deleteWitOtp = useCallback(
    async (id: IpInfoId, type: IpType, code: string) => {
      if (isSubmitting) {
        return;
      }

      setIsSubmitting(true);
      const res = await root.ncWalletJsonRpcClient.call(
        'accounts.ip_settings.delete',
        {
          id,
          code_2fa: code as OtpCode,
          utc_2fa: dayjs().utc().toISOString() as ISODateString,
        },
      );
      setIsSubmitting(false);
      if (res.success) {
        goToIpList(type);
      } else {
        setError(res.left);
      }
    },
    [isSubmitting, root.ncWalletJsonRpcClient, goToIpList, setError],
  );

  return {
    getErrorText,
    resetErrorText,
    deleteWitOtp,
  };
};
