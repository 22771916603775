import {action, computed, flow, makeObservable, observable} from 'mobx';
import type {ColorSchemeName} from 'react-native';
import {Platform, Appearance as RNAppearance} from 'react-native';
import type {AsyncReturnType} from 'type-fest';

import {bind} from '../fp';
import {define, THEME_KIND} from '../persistence';
import type {Disposer, Service} from '../structure';
import {batchDisposers} from '../structure';
import {darkPalette, lightPalette, ThemeImpl} from '../styling';
import type {
  Dimensions,
  WindowDimensions,
  WindowDimensionsState,
} from '../WindowDimensions';
import {WindowDimensionsStatic} from '../WindowDimensions';
import type {
  Appearance,
  PreferredThemeKind,
  SystemThemeKind,
} from './Appearance';
import {ThemeKind} from './Appearance';

export default class AppearanceService implements Appearance, Service {
  private static _themeModeTransitionMap = new Map([
    [ThemeKind.Auto, ThemeKind.Light],
    [ThemeKind.Light, ThemeKind.Dark],
    [ThemeKind.Dark, ThemeKind.Auto],
  ]);

  @observable
  private _systemThemeKind: SystemThemeKind = colorSchemeToThemeKind(
    RNAppearance.getColorScheme(),
  );
  @observable private _preferredThemeKind: PreferredThemeKind =
    ThemeKind.Unknown;

  get systemThemeKind() {
    return this._systemThemeKind;
  }

  get preferredThemeKind() {
    return this._preferredThemeKind;
  }

  @computed get actualThemeKind() {
    const themeKind =
      this._preferredThemeKind === ThemeKind.Auto
        ? this._systemThemeKind
        : this._preferredThemeKind;
    if (themeKind === ThemeKind.Unknown) {
      return ThemeKind.Light;
    }
    return themeKind;
  }

  @computed get isDark() {
    return this.actualThemeKind === ThemeKind.Dark;
  }

  @observable.ref private _theme = this._createTheme();

  constructor(
    private readonly _root: {
      readonly windowDimensionsState: WindowDimensionsState;
      readonly windowDimensions: WindowDimensions;
    },
  ) {
    makeObservable(this);
  }

  private _createTheme(
    window = WindowDimensionsStatic.getInitialDimensions().window,
    screen = WindowDimensionsStatic.getInitialDimensions().screen,
  ) {
    return this.isDark
      ? new ThemeImpl(darkPalette, window, screen)
      : new ThemeImpl(lightPalette, window, screen);
  }

  get theme() {
    return this._theme;
  }

  private _load = flow(function* (this: AppearanceService) {
    const _getThemeMode = (yield getThemeMode()) as AsyncReturnType<
      typeof getThemeMode
    >;
    if (!_getThemeMode.success) {
      this._preferredThemeKind = ThemeKind.Unknown;
    } else if (_getThemeMode.right === null) {
      this._preferredThemeKind = ThemeKind.Auto;
    } else {
      this._preferredThemeKind = _getThemeMode.right;
    }
    this._theme = this._createTheme();
  });

  toggleThemeKind = bind(
    action(async () => {
      const next = this.isDark ? ThemeKind.Light : ThemeKind.Dark;
      return this.setThemeMode(next);
    }),
    this,
  );

  togglePreferredThemeKind = bind(
    action(async () => {
      const next =
        AppearanceService._themeModeTransitionMap.get(
          this._preferredThemeKind,
        ) ?? ThemeKind.Auto;
      return this.setThemeMode(next);
    }),
    this,
  );

  setThemeMode = bind(
    flow(function* (this: AppearanceService, next: ThemeKind) {
      yield putThemeMode(next);
      this._preferredThemeKind = next;
      this._theme = this._createTheme();
    }),
    this,
  );

  private _initialize() {
    const loading = this._load();
    return (() => {
      loading.cancel();
    }) as Disposer;
  }

  private _timeout: NodeJS.Timeout | null = null;
  private _resetCurrentTimeout() {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
  }
  private _listenToColorSchemeChanges() {
    const listener: RNAppearance.AppearanceListener = ({colorScheme}) => {
      // на ios при event изменения темы происходит дважды, один раз с неккоретным значением
      // ниже костыль, чтобы обходить это
      if (Platform.OS === 'ios') {
        this._resetCurrentTimeout();
        this._timeout = setTimeout(() => {
          this._updateTheme(colorScheme);
        }, 500);
      } else {
        this._updateTheme(colorScheme);
      }
    };
    const sub = RNAppearance.addChangeListener(listener);
    return (() => {
      this._resetCurrentTimeout();
      sub.remove();
    }) as Disposer;
  }

  private _onSizeChange = action((update: Dimensions) => {
    this._theme = this._createTheme(update.window, update.screen);
  });

  private _listenToDimensionsChanges() {
    return this._root.windowDimensions.updates.listen(this._onSizeChange);
  }

  subscribe() {
    return batchDisposers(
      this._initialize(),
      this._listenToColorSchemeChanges(),
      this._listenToDimensionsChanges(),
    );
  }

  @action.bound
  private _updateTheme(colorScheme: ColorSchemeName) {
    this._systemThemeKind = colorSchemeToThemeKind(colorScheme);
    this._theme = this._createTheme();
  }
}

const [getThemeMode, putThemeMode] = define<ThemeKind>(THEME_KIND);

const colorSchemeToThemeKind = (scheme: ColorSchemeName): SystemThemeKind => {
  switch (scheme) {
    case 'light':
      return ThemeKind.Light;
    case 'dark':
      return ThemeKind.Dark;
  }
  return ThemeKind.Unknown;
};
