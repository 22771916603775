import type {AuthHelper} from '../Auth';
import {unwrap} from '../EitherAdapter';
import type {ExpiredTokenRecovery} from './ExpiredTokenRecovery';

export default class CommonExpiredTokenRecoveryImpl
  implements ExpiredTokenRecovery
{
  constructor(
    private readonly _root: {
      readonly authHelper: AuthHelper;
    },
  ) {}

  async recover() {
    await unwrap(this._root.authHelper.reset());
  }
}
