import type {WalletId} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {getDefaultAddressInfo} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {useCallback} from 'react';

type Params = {
  walletId: WalletId;
  networkFilter: AddressNetwork;
};

// eslint-disable-next-line import-x/prefer-default-export
export const useCreateDefaultAddress = () => {
  const {walletStore} = useRoot();
  return useCallback(
    async (params: Params) => {
      const wallet = walletStore.getWalletById(params.walletId);
      if (!wallet) {
        return undefined;
      }
      await walletStore.createAddress(params.walletId, params.networkFilter);
      return getDefaultAddressInfo(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        walletStore.getWalletById(params.walletId)!.addresses,
        params.networkFilter,
      )?.address;
    },
    [walletStore],
  );
};
