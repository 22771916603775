import {useRoot} from '@ncwallet-app/core';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {LogsScreen} from '../../screens/LogsScreen';

export default observer(function ListSignOutLogsContainer() {
  const {signOutReasonLog} = useRoot();

  const {flashMessage} = useRoot();

  const copy = useCallback(() => {
    void setStringAsync(JSON.stringify(signOutReasonLog.records));
    flashMessage.showMessage({
      title: 'Copied',
      variant: 'success',
    });
  }, [flashMessage, signOutReasonLog]);

  return (
    <LogsScreen
      onButtonPress={copy}
      logRecords={signOutReasonLog.records}
      buttonText={'Copy to clipboard'}
    />
  );
});
