import type {Either, GlobalError, PinCode} from '@ncwallet-app/core';
import {
  getFormattedTimeFromNow,
  isUtcDateAfterNow,
  urlLinks,
  useRoot,
  useStrings,
  useTemplates,
} from '@ncwallet-app/core';
import type {
  PinInvalidError,
  PinNotSetError,
} from '@ncwallet-app/core/src/ErrorRecognizer/PinError';
import {PinErrorKind} from '@ncwallet-app/core/src/ErrorRecognizer/PinError';
import type {IObservableValue} from 'mobx';
import {observable, runInAction} from 'mobx';
import {useCallback, useMemo, useState} from 'react';
import {Linking} from 'react-native';

import {FULL_PIN_LENGTH} from '../../screens/PinScreen/constants';
import type {PinScreenTexts} from '../../screens/PinScreen/PinScreenTexts';
import type {CheckPinInfo} from '../../SmallNavigationRoot/SmallHomeStack/bindings/CheckPinBinding';

export const useCheckPinContainer = (
  checkPin: (
    pin: PinCode,
  ) => Promise<Either<void, PinInvalidError | PinNotSetError | GlobalError>>,
  setPinCodeCallBack: (pin: PinCode) => void,
  baseTexts: PinScreenTexts,
) => {
  const [pinCodeBox] = useState(() => observable.box(''));
  const getPinCode = useCallback(() => pinCodeBox.get(), [pinCodeBox]);
  const [infoShownBox] = useState(() =>
    observable.box<CheckPinInfo>({showError: false, texts: baseTexts}),
  );
  const setPinCode = useSetPin(
    checkPin,
    setPinCodeCallBack,
    infoShownBox,
    pinCodeBox,
  );
  const onErrorActionPress = useCallback(() => {
    void Linking.openURL(urlLinks.supportPin);
  }, []);
  const getErrorShown = useCallback(
    () => infoShownBox.get().showError,
    [infoShownBox],
  );
  const texts = useMemo(() => infoShownBox.get().texts, [infoShownBox]);
  return {getPinCode, setPinCode, texts, onErrorActionPress, getErrorShown};
};

export const useSetPin = (
  checkPin: (
    pin: PinCode,
  ) => Promise<Either<void, PinInvalidError | PinNotSetError | GlobalError>>,
  onSuccess: (pin: PinCode) => void,
  errorShownBox: IObservableValue<CheckPinInfo>,
  pinCodeBox: IObservableValue<string>,
) => {
  const {userPreferenceState, flashMessage, authHelper} = useRoot();
  const template = useTemplates();
  const strings = useStrings();
  return useCallback(
    async (pinCode: string) => {
      const oldErrorState = errorShownBox.get();
      runInAction(() => {
        pinCodeBox.set(pinCode);
        errorShownBox.set({...oldErrorState, showError: false});
      });

      if (pinCode.length !== FULL_PIN_LENGTH) {
        return;
      }

      const check_ = await checkPin(pinCode as PinCode);

      if (check_.success) {
        onSuccess(pinCode as PinCode);
        runInAction(() => {
          pinCodeBox.set('');
        });
        return;
      }

      if (check_.left.kind === PinErrorKind.PinNotSet) {
        flashMessage.showMessage({
          title: strings['error.pinNotSet'],
          variant: 'danger',
        });
        await authHelper.signOut({reason: 'Pin not set'});
        pinCodeBox.set('');
        return;
      }

      if (
        check_.left.kind === PinErrorKind.PinInvalid &&
        check_.left.nextAllowedAttempt &&
        isUtcDateAfterNow(check_.left.nextAllowedAttempt)
      ) {
        const formattedTimeFromNow = getFormattedTimeFromNow(
          check_.left.nextAllowedAttempt,
          userPreferenceState.languageCode,
        );

        runInAction(() => {
          oldErrorState.texts.errorText = template[
            'checkPinScreen.tooManyAttempsError'
          ]({interval: formattedTimeFromNow});
        });
      }

      runInAction(() => {
        errorShownBox.set({...oldErrorState, showError: true});
        pinCodeBox.set('');
      });
    },
    [
      strings,
      errorShownBox,
      checkPin,
      pinCodeBox,
      onSuccess,
      flashMessage,
      authHelper,
      userPreferenceState.languageCode,
      template,
    ],
  );
};
