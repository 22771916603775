import {range} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {ScrollView} from 'react-native-gesture-handler';

import NotificationListSkeletonItem from './NotificationListSkeletonItem';

export type NotificationListSkeletonProps = {
  itemsCount?: number;
};

export default observer(function NotificationListSkeleton(
  props: NotificationListSkeletonProps,
) {
  const {itemsCount = 8} = props;

  return (
    <ScrollView overScrollMode="never">
      {range(0, itemsCount).map(idx => (
        <NotificationListSkeletonItem key={idx} />
      ))}
    </ScrollView>
  );
});
