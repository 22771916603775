import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import DeleteAccountModal from '../../../screens/DeleteAccountScreen/DeleteAccountModal';
import {usePromptAccountDeletionBindingState} from '../../../shared/usePromptAccountDeletionBindingState';
import type {LargeHomeStackBindingProps} from '../LargeHomeStackBindingProps';

export type PromptAccountDeletionBindingProps =
  LargeHomeStackBindingProps<'PromptAccountDeletion'>;

export default observer(function PromptAccountDeletionBinding({
  navigation,
}: PromptAccountDeletionBindingProps) {
  const onClose = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({index: 0, routes: [{name: 'Root'}]});
  }, [navigation]);

  const {
    handleDelete: _handleDelete,
    isUserBalanceEmpty,
    handleSupportPress,
  } = usePromptAccountDeletionBindingState();

  const root = useRoot();
  const handleDelete = useCallback(async () => {
    const isTwoFaRequired =
      await root.twoFaHelper.isTwoFaRequiredForAccountDeletion();
    if (!isTwoFaRequired.success) {
      return;
    }
    if (isTwoFaRequired.right) {
      navigation.replace('PromptOtpToDeleteAccount');
    } else {
      await _handleDelete();
      navigation.reset({index: 0, routes: [{name: 'Root'}]});
    }
  }, [_handleDelete, root, navigation]);

  return (
    <DeleteAccountModal
      onClose={onClose}
      onDelete={handleDelete}
      haveFounds={!isUserBalanceEmpty}
      onSupport={handleSupportPress}
    />
  );
});
