import {useIsDimensions} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {ViewProps} from 'react-native';
import {View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import type {StyleProps} from 'react-native-reanimated';

export type WebContainerProps = {
  children: React.ReactNode;
  styles: StyleProps;
} & ViewProps;

export default observer(function WebContainer({
  children,
  styles,
  ...rest
}: WebContainerProps) {
  const isLg = useIsDimensions('lg');

  if (isLg) {
    return (
      <ScrollView
        overScrollMode="never"
        {...rest}
        contentContainerStyle={styles}>
        {children}
      </ScrollView>
    );
  }

  return (
    <View {...rest} style={styles}>
      {children}
    </View>
  );
});
