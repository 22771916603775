import {useMemo} from 'react';

import useRoot from '../../Root/hooks/useRoot';
import {useTheme} from '../../styling';
import {UserStatusError} from '../types';

export default function useStateErrorColor() {
  const {userStatusErrorDetector} = useRoot();
  const theme = useTheme();

  return useMemo((): string | null => {
    switch (userStatusErrorDetector.userStatusError) {
      case UserStatusError.Verification:
        return theme.palette.info;
      case UserStatusError.Suspended:
      case UserStatusError.Banned:
        return theme.palette.blackout;
      default:
        return null;
    }
  }, [
    theme.palette.blackout,
    theme.palette.info,
    userStatusErrorDetector.userStatusError,
  ]);
}
