import type {GlobalError} from '../Error';
import {GENERAL_JSON_RPC_ERROR, GENERAL_REST_CLIENT_ERROR} from '../Error';
import {CommonErrorCode} from '../NCWalletServer';
import {CommonRestErrorCode} from '../NCWalletServer/CommonRestError';
import type {ISODateString} from '../Time';
import type {PinInvalidError, PinNotSetError} from './PinError';
import {
  createPinInvalidError,
  createPinNotSerError,
  PinErrorKind,
} from './PinError';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class ErrorRecognizerStatic {
  static isCodeMismatchInRest(error: GlobalError) {
    return ErrorRecognizerStatic._getRestErrorCode(error) === '2fa_mismatch';
  }

  static isTokenInvalidInRest(error: GlobalError) {
    if (error.kind !== GENERAL_REST_CLIENT_ERROR || error.statusCode !== 400) {
      return false;
    }
    const restError = ErrorRecognizerStatic._getRestErrorCode(error);
    return restError === CommonRestErrorCode.TokenInvalid;
  }

  static isTokenExpiredInRest(error: GlobalError) {
    if (error.kind !== GENERAL_REST_CLIENT_ERROR || error.statusCode !== 400) {
      return false;
    }
    const restError = ErrorRecognizerStatic._getRestErrorCode(error);
    return restError === CommonRestErrorCode.TokenExpired;
  }

  static isTokenInvalidInJsonRpc(error: GlobalError) {
    return (
      error.kind === GENERAL_JSON_RPC_ERROR &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      error.body.code === CommonErrorCode.InvalidToken
    );
  }

  static isTokenExpiredInJsonRpc(error: GlobalError) {
    return (
      error.kind === GENERAL_JSON_RPC_ERROR &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      error.body.code === CommonErrorCode.TokenExpired
    );
  }

  static isUserIpNotInAllowedList(error: GlobalError) {
    return error.kind === GENERAL_JSON_RPC_ERROR && error.body.code === 2007;
  }

  static isUserIpInBlockedListRest(error: GlobalError) {
    return (
      error.kind === GENERAL_REST_CLIENT_ERROR &&
      error.statusCode === 400 &&
      ErrorRecognizerStatic._getRestErrorCode(error) === 'ip_in_black_list'
    );
  }

  static isUserBannedInRest(error: GlobalError) {
    return (
      error.kind === GENERAL_REST_CLIENT_ERROR &&
      error.statusCode === 400 &&
      ErrorRecognizerStatic._getRestErrorCode(error) === 'account_banned'
    );
  }

  static isUserBannedInJsonRpc(error: GlobalError) {
    return (
      error.kind === GENERAL_JSON_RPC_ERROR &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      error.body.code === CommonErrorCode.UserBanned
    );
  }

  static isUserIpNotInAllowedListRest(error: GlobalError) {
    return (
      error.kind === GENERAL_REST_CLIENT_ERROR &&
      error.statusCode === 400 &&
      ErrorRecognizerStatic._getRestErrorCode(error) === 'ip_not_in_white_list'
    );
  }

  static isUserIpInBlockedList(error: GlobalError) {
    return error.kind === GENERAL_JSON_RPC_ERROR && error.body.code === 2006;
  }

  static isMaintenance(error: GlobalError) {
    return (
      ErrorRecognizerStatic._getRestErrorCode(error) ===
        'app_temporarily_unavailable' ||
      (error.kind === GENERAL_JSON_RPC_ERROR && error.body.code === 10000)
    );
  }

  static isPinAlreadySet(error: GlobalError) {
    return ErrorRecognizerStatic._getRestErrorCode(error) === 'pin_already_set';
  }

  static getPinError(
    error: GlobalError,
  ): PinInvalidError | PinNotSetError | undefined {
    return this._getPinErrorFromRest(error) ?? this._getPinErrorFromWs(error);
  }

  private static _getPinErrorFromRest(error: GlobalError) {
    const restErrorCode = this._getRestErrorCode(error);
    if (restErrorCode === PinErrorKind.PinNotSet) {
      return createPinNotSerError();
    }
    if (restErrorCode !== PinErrorKind.PinInvalid) {
      return;
    }

    const nextAllowedAttempt = this._getRestErrorData<{
      next_allowed_attempt: ISODateString;
    }>(error)?.data.next_allowed_attempt;

    return createPinInvalidError(nextAllowedAttempt);
  }

  private static _getPinErrorFromWs(error: GlobalError) {
    if (
      error.kind !== GENERAL_JSON_RPC_ERROR ||
      (error.body.code !== 2024 && error.body.code !== 2022)
    ) {
      return undefined;
    }
    const data = error.body.data as
      | {next_allowed_attempt?: string | undefined}
      | undefined;

    if (error.body.code === 2022) {
      return createPinNotSerError();
    }

    return createPinInvalidError(data?.next_allowed_attempt);
  }

  private static _getRestErrorCode(error: GlobalError): string | undefined {
    if (
      error.kind === GENERAL_REST_CLIENT_ERROR &&
      typeof error.body === 'object' &&
      error.body !== null
    ) {
      return Reflect.get(error.body, 'code') as string | undefined;
    }
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private static _getRestErrorData<T>(error: GlobalError) {
    return error.kind === GENERAL_REST_CLIENT_ERROR &&
      typeof error.body === 'object'
      ? (error.body as {data: T} | undefined)
      : undefined;
  }
}
