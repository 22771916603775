import {GLOBAL_PARAMS} from './constant';
import type {Path} from './Path';
import PathImpl from './PathImpl';

export default function stripHistory(_: Path): Path {
  if (_.params === undefined) {
    return _;
  }
  if (_.params[GLOBAL_PARAMS.history] === undefined) {
    return _;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {[GLOBAL_PARAMS.history]: history, ...params} = _.params;
  return new PathImpl(_.pathname, params);
}
