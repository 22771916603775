import {variance} from '@ncwallet-app/core';
import {View} from 'react-native';

// eslint-disable-next-line import-x/prefer-default-export
export const CurrencyCard = variance(View)(theme => ({
  root: {
    flexGrow: 1,

    backgroundColor: theme.palette.background,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    shadowColor: theme.palette.blackout,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
  },
}));
