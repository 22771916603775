import type {PinCode} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {LoadingIndicatorStatus} from '@ncwallet-app/core/src/LoadingIndicator';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import {CreatePinScreen} from '../../screens/CreatePinScreen';
import {
  useConfirmPinTexts,
  useCreatePinTexts,
} from '../../screens/PinScreen/PinScreenTexts';
import type {AuthStackBindingProps} from './AuthStackBindingProps';

export type CreatePinBindingProps = AuthStackBindingProps<'CreatePin'>;

export default observer(function CreatePinBinding() {
  const {authHelper, deviceInfo, loadingIndicator} = useRoot();
  const appVersion = deviceInfo.getAppVersion();
  const onSuccess = useCallback(
    async (pinCode: PinCode) => {
      loadingIndicator.setLoadingStatus(LoadingIndicatorStatus.Active);
      await authHelper.signInBySetPin(pinCode);
      loadingIndicator.setLoadingStatus(LoadingIndicatorStatus.Inactive);
    },
    [authHelper, loadingIndicator],
  );
  const onCancel = useCallback(
    () => authHelper.signOut({reason: 'CreatePinBinding'}),
    [authHelper],
  );

  const createTexts = useCreatePinTexts();
  const confirmTexts = useConfirmPinTexts();
  const screenTexts = useMemo(
    () => ({
      create: createTexts,
      confirm: confirmTexts,
    }),
    [confirmTexts, createTexts],
  );

  return (
    <CreatePinScreen
      appVersion={appVersion}
      onSuccess={onSuccess}
      onCancel={onCancel}
      screenTexts={screenTexts}
    />
  );
});
