import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant, LG_BREAKPOINT} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import type {BuyLinkBindingState} from '../../Navigation/HomeStack/BuyLinkBinding/BuyLinkBindingState';
import {BottomContainer, ErrorTitle} from '../SendScreen/SharedComponents';
import BuyAmountInput from './BuyAmountInput';

export type BuyTabViewProps = {
  amount: BuyLinkBindingState['amount'];
  currencyCode: BuyLinkBindingState['currencyCode'];
  setAmount: BuyLinkBindingState['setAmount'];
  onSubmit: BuyLinkBindingState['onSubmit'];
  errorText?: BuyLinkBindingState['errorText'];
  disabled?: BuyLinkBindingState['disabled'];
};

export default observer(function BuyTabView(props: BuyTabViewProps) {
  const {amount, currencyCode, errorText, setAmount, onSubmit, disabled} =
    props;
  const strings = useStrings();

  return (
    <Root>
      <BuyAmountInput
        wideStyle
        amount={amount}
        onChangeAmount={setAmount}
        currency={currencyCode}
        autoFocus={true}
      />

      <BottomContainer>
        {errorText && <ErrorTitle withoutOffset>{errorText}</ErrorTitle>}
        <SubmitButton
          title={strings['receiveCryptoScreen.fiat.button']}
          variant={ButtonVariant.Primary}
          onPress={onSubmit}
          disabled={disabled}
        />
      </BottomContainer>
    </Root>
  );
});

const Root = variance(View)(theme => ({
  root: {
    flex: 1,
    marginTop: 20,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
      },
    }),
  },
}));

const SubmitButton = variance(Button)(theme => ({
  root: {
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 345,
      },
    }),
  },
}));
