import type {ScaledSize} from 'react-native';

import {FRAME, FRAME_BORDER_WIDTH, QR_CODE_SECTION_RATIO} from './constants';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default abstract class PositionHelperStatic {
  static getFrameTopPosition(window: ScaledSize, forBorder?: boolean) {
    const top = ((window.height - FRAME) / 2) * QR_CODE_SECTION_RATIO;
    if (forBorder) {
      return top - FRAME_BORDER_WIDTH;
    }
    return top;
  }

  static getFrameBottomPosition(window: ScaledSize, forBorder?: boolean) {
    const top =
      PositionHelperStatic.getFrameTopPosition(window, forBorder) + FRAME;
    if (forBorder) {
      return top + FRAME_BORDER_WIDTH * 2; // Because getFrameTopPosition will return the position up to the top border
    }
    return top;
  }

  static getFrameLeftPosition(window: ScaledSize, forBorder?: boolean) {
    const left = (window.width - FRAME) / 2;
    if (forBorder) {
      return left - FRAME_BORDER_WIDTH;
    }
    return left;
  }
}
