import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import Animated from 'react-native-reanimated';

import BurningMessage from './BurningMessage';
import type {MessageViewProps} from './MessageView';
import {useGestureAnimation} from './useGestureAnimation';

export default observer(function GestureMessage(props: MessageViewProps) {
  const animatedStyle = useGestureAnimation(
    props.message.timeout,
    props.message.id,
    props.messageHeights,
  );

  return (
    <AnimatedView style={animatedStyle}>
      <BurningMessage {...props} />
    </AnimatedView>
  );
});

const AnimatedView = variance(Animated.View)(() => ({
  root: {
    width: '100%',
  },
}));
