import {Switch} from '@ncwallet-app/ui/src';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet} from 'react-native';

import type {SettingsItemProps} from '../UserSettingsScreens/SharedComponents/SettingsItem';
import {
  ItemCol,
  ItemTitle,
  ItemTouchable,
} from '../UserSettingsScreens/SharedComponents/SettingsItem';

export type SecuritySettingsItemProps = SettingsItemProps & {
  switched?: boolean;
  getIsActive?: () => boolean;
  onToggle?: () => void;
  testID?: string;
};

export default observer(function SecuritySettingsItem(
  props: SecuritySettingsItemProps,
) {
  const title = props.getTitle?.() ?? props.title;

  return (
    <ItemTouchable
      onPress={props.onPress}
      Icon={!props.switched ? ArrowRightSvg : null}
      disabled={props.disabled}
      bodyStyle={style.spaceBetween}
      testID={props.testID}>
      <ItemCol>
        <ItemTitle>{title}</ItemTitle>
      </ItemCol>
      {props.switched && (
        <Switch
          onChange={props.onToggle}
          style={style.switch}
          getIsActive={props.getIsActive}
        />
      )}
    </ItemTouchable>
  );
});

const style = StyleSheet.create({
  spaceBetween: {
    justifyContent: 'space-between',
    width: '100%',
  },
  switch: {
    marginTop: -6.5,
    marginBottom: -6.5,
  },
});
