import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {
  BodyTag,
  ClickableIcon,
  ClickableTag,
  DeleteIpActionContainer,
  DeleteIpText,
  HeaderTag,
  HeaderTitleTag,
} from '../../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import {LimitCard} from '../shared/LimitCard';

export type PromptLimitRemovalCardProps = {
  isCancel: boolean;
  onSubmit: () => void;
  onBack: () => void;
};

export default observer(function PromptLimitRemovalCard(
  props: PromptLimitRemovalCardProps,
) {
  const strings = useStrings();

  const title = props.isCancel
    ? strings['currencyLimitScreen.cancelChange']
    : strings['currencyLimitScreen.disableLimit'];

  return (
    <LimitCard>
      <HeaderTag>
        <ClickableTag onPress={props.onBack}>
          <ClickableIcon />
          <HeaderTitleTag>{title}</HeaderTitleTag>
        </ClickableTag>
      </HeaderTag>
      <BodyTag>
        {!props.isCancel && (
          <DeleteIpText>
            {strings['currencyLimitScreen.warningLabel']}
          </DeleteIpText>
        )}
        <DeleteIpActionContainer>
          <Button
            variant={ButtonVariant.PrimaryLight}
            title={strings['currencyLimitScreen.yesButton']}
            onPress={props.onSubmit}
            testID="disable-submit-btn"
          />
          <SizedBox />
          <Button
            variant={ButtonVariant.Danger}
            title={strings['currencyLimitScreen.cancelButton']}
            onPress={props.onBack}
            testID="cancel-submit-btn"
          />
        </DeleteIpActionContainer>
      </BodyTag>
    </LimitCard>
  );
});

const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));
