import type {AdsRecord} from '../KeyValueStore';
import {CompactAdvertSpot} from '../KeyValueStore';
import type {OptionalGetter} from '../mobx-toolbox';
import {expr, use} from '../mobx-toolbox';
import type {AdvertId, AdvertSpot} from '../NCWalletServer';
import type {Millisecond, Second} from '../Time';
import {fromSecond} from '../Time';
import type {
  AdSuspensionQuery,
  TimeoutsBySpotById,
} from './AdSuspensionRepository';

export default class AdSuspensionQueryImpl implements AdSuspensionQuery {
  constructor(private readonly _record: AdsRecord) {}

  areSuspendedGlobally(
    global: TimeoutsBySpotById,
    $now: OptionalGetter<Millisecond>,
  ): boolean {
    const {bySpotById} = this._record;

    for (const [id, timeoutsBySpot] of global) {
      const {bySpot} = bySpotById?.[id] ?? {};

      if (bySpot === undefined) {
        continue;
      }

      for (const [_spot, timeout] of timeoutsBySpot) {
        const spot = CompactAdvertSpot[_spot];
        const suspended = bySpot[spot];

        if (suspended?.at === undefined) {
          continue;
        }

        const suspendedAt = fromSecond(suspended.at);

        if (expr(() => use($now) < suspendedAt + timeout)) {
          return true;
        }
      }
    }
    return false;
  }

  selectPending(
    local: TimeoutsBySpotById,
    $now: OptionalGetter<Millisecond>,
  ): Set<AdvertId> {
    const pendingIds = new Set<AdvertId>();
    const {bySpotById} = this._record;

    byId: for (const [id, timeoutsBySpot] of local) {
      const {bySpot} = bySpotById?.[id] ?? {};

      if (bySpot === undefined) {
        pendingIds.add(id);
        continue;
      }

      for (const [_spot, timeout] of timeoutsBySpot) {
        const spot = CompactAdvertSpot[_spot];
        const suspended = bySpot[spot];

        if (suspended?.at === undefined) {
          continue;
        }

        const suspendedAt = fromSecond(suspended.at);

        if (expr(() => use($now) < suspendedAt + timeout)) {
          continue byId;
        }
      }
      pendingIds.add(id);
    }
    return pendingIds;
  }

  selectLongestIntact(
    ids: ReadonlySet<AdvertId>,
    _spot: AdvertSpot,
  ): AdvertId | undefined {
    const spot = CompactAdvertSpot[_spot];
    const {bySpotById} = this._record;
    let oldest: OldestRecord | undefined;

    for (const id of ids) {
      const suspendedAt = bySpotById?.[id]?.bySpot?.[spot]?.at;
      const neverDisplayed = suspendedAt === undefined;
      if (neverDisplayed) {
        return id;
      }

      oldest = oldest
        ? suspendedAt <= oldest.suspendedAt
          ? {id, suspendedAt}
          : oldest
        : {id, suspendedAt};
    }
    return oldest?.id;
  }
}

type OldestRecord = {
  id: AdvertId;
  suspendedAt: Second;
};
