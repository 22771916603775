/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import {
  sized,
  urlLinks,
  useStrings,
  useStyles,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import type {IpInfo} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {Button, ButtonVariant, TouchableOpacity} from '@ncwallet-app/ui';
import {HelpSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {isEmpty} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Linking} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import {
  BodyTag,
  ClickableIcon,
  ClickableTag,
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import {IpListItem} from '../../UserSettingsScreens/IpListScreen/IpListItem';
import SessionIpListEmpty from './SessionIpListEmpty';
import SessionIpListWarning from './SessionIpListWarning';
import SessionIpSkeletonList from './SessionIpSkeletonList';

export type SessionIpListProps = {
  type: IpType;
  ipInfos: IpInfo[] | undefined;
  onBack: () => void;
  goToAddNew: () => void;
  toEdit: (ipInfo: IpInfo) => void;
  isAllowedIpListWarningShown: boolean;
  onClearAllowedList?: () => void;
};

export default observer(function SessionIpList({
  type,
  ipInfos,
  onBack,
  isAllowedIpListWarningShown,
  onClearAllowedList,
  goToAddNew,
  toEdit,
}: SessionIpListProps) {
  const strings = useStrings();
  const listStyles = useListStyles();
  const theme = useTheme();
  const handleHelpPress = useCallback(
    () => Linking.openURL(urlLinks.ipListsFaq),
    [],
  );

  return (
    <LgProfileCardContainer>
      <HeaderTag>
        <ClickableTag onPress={onBack}>
          <ClickableIcon />
          <HeaderTitleTag offsetLeft>
            {type === IpType.Blocked
              ? strings['lgSecureSettingsScreen.sessionsBlackList']
              : strings['lgSecureSettingsScreen.sessionsWhiteList']}
          </HeaderTitleTag>
        </ClickableTag>
        <TouchableOpacity onPress={handleHelpPress}>
          <HelpIcon color={theme.palette.uiAdditional1} />
        </TouchableOpacity>
      </HeaderTag>
      <BodyTag>
        {ipInfos && isAllowedIpListWarningShown && (
          <SessionIpListWarning onClearPressed={onClearAllowedList} />
        )}

        {!ipInfos && <SessionIpSkeletonList />}
        {ipInfos && isEmpty(ipInfos) && <SessionIpListEmpty type={type} />}
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {ipInfos?.length! > 0 && (
          <FlatList
            overScrollMode="never"
            data={ipInfos}
            style={listStyles.list}
            renderItem={({item, index}) => (
              <IpListItem
                item={item}
                onPress={() => {
                  toEdit(item);
                }}
                wide
                testID={`${index + 1}-ip-item`}
              />
            )}
            keyExtractor={item => item.ip}
          />
        )}
        <AddNewButton
          onPress={goToAddNew}
          title={strings['secureSettings.ipList.addButton']}
          variant={ButtonVariant.Primary}
          testID="add-ip-btn"
        />
      </BodyTag>
    </LgProfileCardContainer>
  );
});

const HelpIcon = sized(HelpSvg, 17);

const AddNewButton = variance(Button)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const useListStyles = () =>
  useStyles(theme => ({
    list: {
      maxHeight: 304,
      marginBottom: 20,
      backgroundColor: theme.palette.uiPrimary,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: theme.palette.uiSecondary,
    },
  }));
