import {useTheme} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {Platform, StyleSheet} from 'react-native';

import type {PressableProps} from './Pressable';
import {Pressable} from './Pressable';

const FLASH_COLOR = '#000';

export type PressableNativeFeedbackProps = PressableProps & {
  containerStyle?: StyleProp<ViewStyle>;
  borderStyle?: StyleProp<ViewStyle>;
  androidFlashColor?: string;
};

export const PressableNativeFeedback = observer(
  function PressableNativeFeedback(props: PressableNativeFeedbackProps) {
    const theme = useTheme();
    const {
      children,
      androidFlashColor = FLASH_COLOR,
      containerStyle,
      borderStyle,
      style,
      ...rest
    } = props;
    const flashColor_ = useMemo(() => {
      if (Platform.OS === 'android') {
        return theme.chroma(androidFlashColor).alpha(0.1).hex();
      }

      if (Platform.OS === 'web') {
        return theme.chroma(theme.palette.uiSecondary).alpha(0.5).hex();
      }

      const backgroundColor =
        StyleSheet.flatten(containerStyle)?.backgroundColor;
      if (backgroundColor) {
        return theme
          .chroma(backgroundColor as string)
          .darken(0.5)
          .hex();
      }
      return undefined;
    }, [androidFlashColor, containerStyle, theme]);
    return (
      <Pressable
        pressedColor={flashColor_}
        android_ripple={{
          color: flashColor_,
        }}
        borderStyle={borderStyle}
        style={[containerStyle, style]}
        {...rest}>
        {children}
      </Pressable>
    );
  },
);
