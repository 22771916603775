import {useStrings} from '@ncwallet-app/core';
import type {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {BottomSheet} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import TwoFaProviderList from './TwoFaProviderList';

export type SelectTwoFaProviderScreenProps = {
  selectedTwoFaProvider: TwoFaProviderKind | undefined;
  providers: TwoFaProviderKind[];
  onClose: () => void;
  onTwoFaProviderSelect: (kind: TwoFaProviderKind) => void;
};

export default observer(function SelectTwoFaProviderScreen(
  props: SelectTwoFaProviderScreenProps,
) {
  const {selectedTwoFaProvider, providers, onClose, onTwoFaProviderSelect} =
    props;
  const strings = useStrings();

  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        onClose();
      }
    },
    [onClose],
  );

  const computeSnapPoints = useCallback(() => {
    switch (providers.length) {
      case 1:
        return 280;
      case 2:
        return 360;
      default:
        return 440;
    }
  }, [providers]);

  return (
    <BottomSheet
      index={0}
      onChange={onChange}
      animateOnMount
      snapPoints={[computeSnapPoints()]}
      enablePanDownToClose
      title={strings['twoFaSelectScreen.title']}
      onClose={props.onClose}
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}>
      <TwoFaProviderList
        selectedTwoFaProvider={selectedTwoFaProvider}
        providers={providers}
        onTwoFaProviderSelect={onTwoFaProviderSelect}
      />
    </BottomSheet>
  );
});
