import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import type {SharedValue} from 'react-native-reanimated';
import Animated, {useAnimatedStyle, withSpring} from 'react-native-reanimated';
import type {Vector} from 'react-native-redash';

const CURSOR = 11;

export type DotCursorProps = {
  cursorTranslation: Vector<Animated.SharedValue<number>>;
  isActiveCursor: SharedValue<boolean>;
};

export default observer((props: DotCursorProps) => {
  const {cursorTranslation, isActiveCursor} = props;

  const dotStyle = useAnimatedStyle(() => {
    const translateX = cursorTranslation.x.value - CURSOR / 2;
    const translateY = cursorTranslation.y.value - CURSOR / 2;
    const opacity = withSpring(isActiveCursor.value ? 1 : 0);
    return {
      opacity,
      transform: [
        {translateX},
        {translateY},
        {scale: withSpring(isActiveCursor.value ? 1 : 0)},
      ],
    };
  }, [cursorTranslation, isActiveCursor]);

  return (
    <View style={StyleSheet.absoluteFill}>
      <Animated.View style={StyleSheet.absoluteFill}>
        <AnimatedRoot style={dotStyle} />
      </Animated.View>
    </View>
  );
});

const AnimatedRoot = variance(Animated.View)(theme => ({
  root: {
    width: CURSOR,
    height: CURSOR,
    borderRadius: CURSOR / 2,
    borderWidth: 2,
    borderColor: theme.palette.primary,
    backgroundColor: theme.palette.background,
  },
}));
