import {useTheme} from '@ncwallet-app/core/src';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';

import {LightSpinnerSvg} from '../../assets/svg/colored';
import {SpinnerSvg} from '../../assets/svg/colorless';

export enum SpinnerVariant {
  Primary = 'primary',
  Secondary = 'uiSecondary',
  Success = 'success',
  Danger = 'error',
}

export enum SpinnerKind {
  Default = 'default',
  Light = 'light',
}

type SpinnerProps = {
  size?: number;
  variant?: SpinnerVariant;
  spinDuration?: number;
  kind?: SpinnerKind;
};

export const Spinner = observer(
  ({
    size = 90,
    variant = SpinnerVariant.Primary,
    spinDuration = 850,
    kind = SpinnerKind.Default,
  }: SpinnerProps) => {
    const theme = useTheme();
    const rotation = useSharedValue(0);
    const animatedStyle = useAnimatedStyle(
      () => ({
        transform: [{rotateZ: `${rotation.value}deg`}],
      }),
      [rotation],
    );

    useEffect(() => {
      rotation.value = withRepeat(
        withTiming(rotation.value + 360, {
          duration: spinDuration,
          easing: Easing.linear,
        }),
        -1,
        false,
      );
    }, [rotation, spinDuration]);

    switch (kind) {
      case SpinnerKind.Light: {
        return (
          <Animated.View style={[{width: size, height: size}, animatedStyle]}>
            <LightSpinnerSvg width={size} height={size} />
          </Animated.View>
        );
      }
      default: {
        return (
          <Animated.View style={[{width: size, height: size}, animatedStyle]}>
            <SpinnerSvg
              width={size}
              height={size}
              color={theme.palette[variant]}
            />
          </Animated.View>
        );
      }
    }
  },
);
