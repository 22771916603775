import {variance} from '@ncwallet-app/core';
import {SafeAreaInset, SafeAreaSectionList} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {SectionListData, SectionListRenderItemInfo} from 'react-native';
import {StyleSheet, View} from 'react-native';

import {EXTRA_BOTTOM_OFFSET} from '../constants';
import type {
  ItemEntity,
  SectionEntity,
} from '../SwitchHierarchyScreen/SwitchHierarchyScreen';

export type LgNotificationSettingsProps = {
  sections: SectionListData<ItemEntity, SectionEntity>[];
  renderItem: (
    info: SectionListRenderItemInfo<ItemEntity, SectionEntity>,
  ) => JSX.Element;
  renderSectionHeader: (info: {
    section: SectionListData<ItemEntity, SectionEntity>;
  }) => JSX.Element;
  renderSectionFooter: () => JSX.Element;
  ItemSeparatorComponent: () => JSX.Element;
  ListHeaderComponent: () => JSX.Element;
  refreshControl: JSX.Element;
};

export default observer(function LgNotificationSettings({
  sections,
  renderItem,
  renderSectionHeader,
  renderSectionFooter,
  ItemSeparatorComponent,
  refreshControl,
}: LgNotificationSettingsProps) {
  const sectionProps = {
    insets: SafeAreaInset.BOTTOM,
    extra: {bottom: EXTRA_BOTTOM_OFFSET},
    renderItem: renderItem,
    renderSectionHeader: renderSectionHeader,
    renderSectionFooter: renderSectionFooter,
    ItemSeparatorComponent: ItemSeparatorComponent,
    refreshControl: refreshControl,
    contentContainerStyle: styles.container,
    keyExtractor: (item: ItemEntity) => item.id,
  };

  if (!sections.length) {
    return (
      <Container>
        <Card />
        <Card horizontalOffset />
        <Card />
      </Container>
    );
  }

  return (
    <Container>
      {sections.map((section, index) => {
        return (
          <Card horizontalOffset={index === 1} key={index}>
            <SafeAreaSectionList {...sectionProps} sections={[section]} />
          </Card>
        );
      })}
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    overflow: 'visible',
    paddingBottom: 30,
    paddingTop: 0,
  },
}));

const Card = variance(View)(theme => ({
  root: {
    flex: 1,
    maxWidth: 380,
    maxHeight: 429,
    backgroundColor: theme.palette.background,
    borderRadius: 8,
    ...theme.bar(10),
  },

  horizontalOffset: {
    marginHorizontal: 20,
  },
}));

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    borderRadius: 8,
  },
});
