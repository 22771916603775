import type {
  Core,
  JsonKeyValueMap,
  JsonKeyValueStore,
  JsonSecureKeyValueMap,
  KeyValueMap,
  KeyValueStore,
  Root,
  SecureKeyValueMap,
  Service,
} from '@ncwallet-app/core';
import {
  BaseRootService,
  batchDisposers,
  DeepLinkingLocationSourceService,
  HttpFactory,
  InstallReferrerIdentificationService,
  JsonKeyValueStoreImpl,
  KeyValueStoreImpl,
  LocalAuthClientService,
  MessagingLogBridgeService,
  StubBiometricSettingsManager,
  StubBiometricsService,
  StubBiometricStateHelperImpl,
  StubBootSplashImpl,
} from '@ncwallet-app/core';
import AdvertisingIdentifierSubImpl from '@ncwallet-app/core/src/AdvertisingIdentifier/AdvertisingIdentifierSubImpl';
import {CommonAppViewportImpl} from '@ncwallet-app/core/src/AppViewport';
import CommonCameraImpl from '@ncwallet-app/core/src/Camera/CommonCameraImpl';
import {CommonPlatformSpecificComponents} from '@ncwallet-app/core/src/Components/CommonPlatformSpecificComponents';
import {DebugLocationDetectorService} from '@ncwallet-app/core/src/DebugLocationDetector';
import {UniversalDeviceInfoImpl} from '@ncwallet-app/core/src/DeviceInfo';
import {WebDeviceScreenService} from '@ncwallet-app/core/src/DeviceScreenState';
import {CommonExpiredTokenRecoveryImpl} from '@ncwallet-app/core/src/ExpiredTokenRecovery';
import {StubHapticsImpl} from '@ncwallet-app/core/src/Haptics';
import parseInstallReferrer from '@ncwallet-app/core/src/InstallReferrerIdentification/parseInstallReferrer';
import {StubLinkToEmailServiceImpl} from '@ncwallet-app/core/src/LinkToEmailService';
import {StubManualTestHelperImpl} from '@ncwallet-app/core/src/ManualTestHelper';
import {MessageHandlerImpl} from '@ncwallet-app/core/src/Messaging';
import {MessagingRootService} from '@ncwallet-app/core/src/MessagingRoot';
import {CommonQrCodeScannerImpl} from '@ncwallet-app/core/src/QrCodeScanner';
import {SentryLogService} from '@ncwallet-app/core/src/SentryLog';
import {StubSharingImpl} from '@ncwallet-app/core/src/Sharing';
import {StubTelegramMiniAppImpl} from '@ncwallet-app/core/src/TelegramMiniApp';

import {WebHeadlessLocalNotificationsImpl} from '../LocalNotifications';
import {WebLocationImpl} from '../Location';
import {WebMessagingService} from '../Messaging';
import {WebMessagingIdentityService} from '../MessagingIdentity';
import {
  createNotificationPermissions,
  WebPrepareNotificationsFactory,
} from '../NotificationPermissions';
import {WebAppleOAuth2ProviderService} from '../OAuth2Provider';
import {Sentry} from '../Sentry';
import {WebSessionContextProviderImpl} from '../SessionContext';
import {WebTelegramCredentialsProviderImpl} from '../TelegramCredentialsProvider';
import {WebTransactionReportImpl} from '../TransactionReport';
import {WebAlertImpl} from '../WebAlert';

export default class WebRootService
  extends BaseRootService
  implements Root, Service
{
  constructor(protected readonly _core: Core) {
    super(_core);
  }

  readonly installReferrerIdentification =
    new InstallReferrerIdentificationService(this, parseInstallReferrer);
  readonly alert = new WebAlertImpl();
  private readonly _httpFactory = new HttpFactory(this);
  readonly http = this._httpFactory.create();
  readonly deviceInfo = new UniversalDeviceInfoImpl();
  readonly keyValueStore = new KeyValueStoreImpl<KeyValueMap>(this);
  readonly jsonKeyValueStore = new JsonKeyValueStoreImpl(
    this,
    this.keyValueStore as KeyValueStore<JsonKeyValueMap>,
  );
  readonly secureKeyValueStore = this
    .keyValueStore as unknown as KeyValueStore<SecureKeyValueMap>;
  readonly jsonSecureKeyValueStore = this
    .jsonKeyValueStore as unknown as JsonKeyValueStore<JsonSecureKeyValueMap>;
  readonly location = new WebLocationImpl(this);
  readonly locationSource = new DeepLinkingLocationSourceService(this);
  readonly appleOAuth2Provider = new WebAppleOAuth2ProviderService(this);
  readonly headlessLocalNotifications = new WebHeadlessLocalNotificationsImpl(
    this,
  );
  readonly localNotifications = this.headlessLocalNotifications;
  readonly messaging = new WebMessagingService(this);
  readonly notificationPermissions = createNotificationPermissions();
  private readonly _prepareNotificationsFactory =
    new WebPrepareNotificationsFactory(this);
  readonly prepareNotifications = this._prepareNotificationsFactory.create();
  readonly messagingIdentity = new WebMessagingIdentityService(this);
  readonly biometrics = new StubBiometricsService();
  readonly biometricSettingsManager = new StubBiometricSettingsManager();
  readonly biometricStateHelper = new StubBiometricStateHelperImpl();
  readonly bootSplash = new StubBootSplashImpl();
  readonly manualTestHelper = new StubManualTestHelperImpl();
  readonly haptics = new StubHapticsImpl();
  readonly sharing = new StubSharingImpl(this);
  readonly sessionContextProvider = new WebSessionContextProviderImpl();
  readonly messageHandler = new MessageHandlerImpl(this);
  private readonly _messagingLogBridge = new MessagingLogBridgeService(this);
  readonly advertisingIdentifier = new AdvertisingIdentifierSubImpl();
  readonly sentryLog = new SentryLogService(this, Sentry);
  readonly camera = new CommonCameraImpl();
  readonly telegramMiniApp = new StubTelegramMiniAppImpl();
  readonly linkToEmailService = new StubLinkToEmailServiceImpl();
  readonly platformSpecificComponents = new CommonPlatformSpecificComponents();
  readonly deviceScreenState = new WebDeviceScreenService();
  readonly expiredTokenRecovery = new CommonExpiredTokenRecoveryImpl(this);
  readonly debugLocationDetector = new DebugLocationDetectorService(this);
  readonly authClient = new LocalAuthClientService(this);
  readonly authHelper = this.authClient;
  readonly telegramCredentialsProvider = new WebTelegramCredentialsProviderImpl(
    this,
  );
  readonly transactionReport = new WebTransactionReportImpl(this);
  private readonly _messagingRoot = new MessagingRootService(this);
  readonly qrCodeScanner = new CommonQrCodeScannerImpl(this);
  readonly appViewport = new CommonAppViewportImpl(this);

  get analytics() {
    return this._messagingRoot.analytics;
  }

  get remoteMessageStore() {
    return this._messagingRoot.remoteMessageStore;
  }

  get headlessMessaging() {
    return this._messagingRoot.headlessMessaging;
  }

  get headlessMessagingHelper() {
    return this._messagingRoot.headlessMessagingHelper;
  }

  subscribe() {
    return batchDisposers(
      super.subscribe(),
      this.authClient.subscribe(),
      this.installReferrerIdentification.subscribe(),
      this.debugLocationDetector.subscribe(),
      this.appleOAuth2Provider.subscribe(),
      this.messaging.subscribe(),
      this.prepareNotifications?.subscribe(),
      this.messagingIdentity.subscribe(),
      this._messagingLogBridge.subscribe(),
      this.sentryLog.subscribe(),
      this.deviceScreenState.subscribe(),
      this._messagingRoot.subscribe(),
    );
  }
}
