import type {
  CryptoCurrencyCode,
  DecimalString,
  WalletId,
} from '@ncwallet-app/core';
import {FULFILLED, useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, reaction, runInAction} from 'mobx';
import {useCallback, useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {CurrencyHistoryRefresher} from '../../../shared/CurrencyHistoryRefresher';
import {useRateHistoryStateOnWideScreen} from '../../../shared/useRateHistoryStateOnWideScreen';
import {ExchangeFormBindingState} from './ExchangeFormBindingState';
import {ExchangeFormClientSideValidator} from './ExchangeFormClientSideValidator';
import {WalletLimitHelper} from './WalletLimitHelper';

export default function useExchangeFormBindingState(
  walletIdFrom: WalletId,
  walletIdTo: WalletId | undefined,
  currencyTo: CryptoCurrencyCode | undefined,
  value?: DecimalString,
  isValueTo?: boolean,
) {
  const root = useRoot();
  const {accountStore} = root;
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  const [currencyHistoryRefresher] = useState(
    () => new CurrencyHistoryRefresher(root),
  );
  const [state] = useState(
    () =>
      new ExchangeFormBindingState(
        root,
        currencyHistoryRefresher,
        new WalletLimitHelper(root),
        new ExchangeFormClientSideValidator(root),
      ),
  );

  const refresh = useCallback(async () => {
    if (accountStore.state?.status === FULFILLED) {
      const {base_fiat} = accountStore.state.result;
      await state.updateParams(
        walletIdFrom,
        walletIdTo,
        currencyTo,
        base_fiat,
        value,
        isValueTo,
      );
    }
  }, [
    accountStore,
    state,
    walletIdFrom,
    walletIdTo,
    currencyTo,
    value,
    isValueTo,
  ]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void refresh();
          });
        }
      }),
    [refresh, getIsFocused, getIsReady],
  );

  useEffect(
    () =>
      reaction(
        () => state.isTimeoutError,
        shouldRequest => {
          if (shouldRequest) {
            root.rpcTimeoutErrorVisibility.registerAction(refresh);
          }
        },
      ),
    [refresh, root.rpcTimeoutErrorVisibility, state.isTimeoutError],
  );

  useEffect(
    () => currencyHistoryRefresher.subscribe(),
    [currencyHistoryRefresher],
  );
  useRateHistoryStateOnWideScreen(state, 'lg');

  return state;
}
