import type {ParamListBase} from '@react-navigation/native';
import type {NavigationState, PartialState} from '@react-navigation/routers';
import {findLast} from 'lodash';
import type {ValueOf} from 'type-fest';

import type {
  DistinctPartialRoute,
  ShallowCommonRouteMeta,
  ShallowCommonState,
} from '../CommonNavigationScheme';
import {
  CHECK_BIOMETRICS_PIN_ROUTE,
  CHECK_PIN_ROUTE,
  GENERATE_PAY_ME_LINK_ROUTE,
  IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE,
  kindToNameMap,
  LIST_ACTIVE_SESSIONS_ROUTE,
  LIST_ALLOWED_IPS_ROUTE,
  LIST_AUTH_APPS_ROUTE,
  LIST_BLOCKED_IPS_ROUTE,
  LIST_DOCUMENTS_ROUTE,
  LIST_LIMITS_ROUTE,
  LIST_MENU_CATEGORIES_ROUTE,
  LIST_NOTIFICATION_SETTINGS_ROUTE,
  LIST_NOTIFICATIONS_ROUTE,
  LIST_PERSONAL_DATA_ROUTE,
  LIST_SECURITY_SETTINGS_ROUTE,
  LIST_SESSION_HISTORY_ROUTE,
  NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE,
  PROMPT_ACCOUNT_DELETION_ROUTE,
  PROMPT_ADD_IP_ROUTE,
  PROMPT_AFFILIATE_PROGRAM_ROUTE,
  PROMPT_AMOUNT_TO_SEND_ROUTE,
  PROMPT_BASE_CRYPTO_ROUTE,
  PROMPT_BASE_FIAT_ROUTE,
  PROMPT_BIOMETRIC_SETTINGS_ROUTE,
  PROMPT_COMMENT_TO_SEND_ROUTE,
  PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE,
  PROMPT_DOCUMENT_ROUTE,
  PROMPT_EMAIL_ROUTE,
  PROMPT_FEEDBACK_ROUTE,
  PROMPT_IDENTITY_VERIFICATION_ADDRESS_ROUTE,
  PROMPT_LANGUAGE_ROUTE,
  PROMPT_LIMIT_REMOVAL_ROUTE,
  PROMPT_LIMIT_UPDATE_ROUTE,
  PROMPT_NAME_AND_BIRTHDATE_ROUTE,
  PROMPT_NEW_LIMIT_ROUTE,
  PROMPT_NEW_PIN_ROUTE,
  PROMPT_NICKNAME_ROUTE,
  PROMPT_OTP_TO_ADD_IP_ROUTE,
  PROMPT_OTP_TO_BIOMETRICS_ROUTE,
  PROMPT_OTP_TO_DELETE_IP_ROUTE,
  PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_LIMIT_CREATE_ROUTE,
  PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE,
  PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE,
  PROMPT_OTP_TO_UPDATE_IP_ROUTE,
  PROMPT_OUTPUT_NETWORK_ROUTE,
  PROMPT_RATE_ROUTE,
  PROMPT_RESIDENTIAL_ADDRESS_ROUTE,
  PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE,
  PROMPT_SESSION_DELETION,
  PROMPT_THEME_ROUTE,
  PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE,
  PROMPT_TO_DELETE_IP_ROUTE,
  PROMPT_UPDATE_IP_ROUTE,
  PROMPT_USER_ID_ROUTE,
  SHOW_LIMIT_ROUTE,
  SHOW_PROFILE_ROUTE,
  SHOW_QR_CODE_SCANNER_ROUTE,
  SHOW_QR_ROUTE,
  SHOW_TRANSACTION_REPORT_ROUTE,
  SHOW_TWO_FA_GENERATED_SECRET_ROUTE,
  SHOW_TWO_FA_SETTINGS_ROUTE,
  TWO_FA_WELCOME_ROUTE,
} from '../CommonNavigationScheme';
import type {Path} from '../CommonNavigationScheme/Path';
import type {DistinctSwitchHistoryRecord} from '../CommonNavigationScheme/SwitchRouter/DistinctSwitchHistoryRecord';
import {divideBy, last} from '../util';
import type {LargeHomeStackParamList} from './LargeHomeStack/LargeHomeStackParamList';
import type {
  LargeSwitchParamList,
  ListSecuritySettingsPinRoute,
  ListSecuritySettingsSessionsRoute,
  ListSecuritySettingsTwoFaRoute,
  MainHeaderRoute,
  ProfileBaseCurrencyRoute,
  ProfileDetailsRoute,
  ProfileVerificationRoute,
  SelectedLimitRoute,
  SettingsHeaderRoute,
} from './LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {
  ProfileCard,
  SecuritySettingsCard,
} from './LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {LargeHomeStackRouteMeta, LargeSwitchRouteMeta} from './RouteGroup';

export default function commonToLarge(
  common: ShallowCommonState,
): PartialState<NavigationState<LargeHomeStackParamList>> {
  const routes = divideBy<LargeHomeStackRouteMeta, LargeSwitchRouteMeta>(
    common as (LargeHomeStackRouteMeta | LargeSwitchRouteMeta)[],
    (_): _ is LargeHomeStackRouteMeta =>
      _.route.kind === PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE ||
      _.route.kind === SHOW_QR_CODE_SCANNER_ROUTE ||
      _.route.kind === PROMPT_RATE_ROUTE ||
      _.route.kind === PROMPT_ACCOUNT_DELETION_ROUTE ||
      _.route.kind === PROMPT_FEEDBACK_ROUTE,
  ).flatMap<DistinctPartialRoute<LargeHomeStackParamList>>(group => {
    if (group.isTarget) {
      return group.chunk.flatMap(translateHomeStackRoute);
    }
    const switchRoutes = group.chunk.flatMap(translateSwitchRoute);
    const top = last(switchRoutes) as
      | DistinctPartialRoute<LargeSwitchParamList>
      | undefined;
    const rest = switchRoutes.slice(0, -1);
    const state: PartialState<NavigationState<LargeSwitchParamList>> = {
      index: 0,
      routes: top ? [top] : [],
      history: rest,
    };
    return [{name: 'Root', path: top?.path, state}];
  });
  return {
    index: routes.length - 1,
    routes,
  };
}

function translateSwitchRoute(
  meta: LargeSwitchRouteMeta,
  index: number,
  state: LargeSwitchRouteMeta[],
): DistinctSwitchHistoryRecord<LargeSwitchParamList>[] {
  const {route} = meta;
  const path = meta.path?.toString();
  switch (route.kind) {
    case LIST_MENU_CATEGORIES_ROUTE:
    case PROMPT_THEME_ROUTE:
    case PROMPT_LANGUAGE_ROUTE:
    case LIST_NOTIFICATIONS_ROUTE:
    case PROMPT_NICKNAME_ROUTE:
    case PROMPT_USER_ID_ROUTE:
    case PROMPT_EMAIL_ROUTE:
    case LIST_PERSONAL_DATA_ROUTE:
    case PROMPT_NAME_AND_BIRTHDATE_ROUTE:
    case PROMPT_RESIDENTIAL_ADDRESS_ROUTE:
    case LIST_DOCUMENTS_ROUTE:
    case PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE:
    case IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE:
    case PROMPT_DOCUMENT_ROUTE:
    case PROMPT_BASE_CRYPTO_ROUTE:
    case PROMPT_BASE_FIAT_ROUTE:
    case TWO_FA_WELCOME_ROUTE:
    case PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE:
    case SHOW_TWO_FA_SETTINGS_ROUTE:
    case SHOW_TWO_FA_GENERATED_SECRET_ROUTE:
    case LIST_AUTH_APPS_ROUTE:
    case PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE:
    case PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE:
    case CHECK_PIN_ROUTE:
    case CHECK_BIOMETRICS_PIN_ROUTE:
    case PROMPT_OTP_TO_BIOMETRICS_ROUTE:
    case PROMPT_BIOMETRIC_SETTINGS_ROUTE:
    case PROMPT_NEW_PIN_ROUTE:
    case NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE:
    case LIST_ALLOWED_IPS_ROUTE:
    case PROMPT_UPDATE_IP_ROUTE:
    case PROMPT_ADD_IP_ROUTE:
    case LIST_BLOCKED_IPS_ROUTE:
    case LIST_SESSION_HISTORY_ROUTE:
    case LIST_ACTIVE_SESSIONS_ROUTE:
    case PROMPT_SESSION_DELETION:
    case PROMPT_NEW_LIMIT_ROUTE:
    case PROMPT_LIMIT_UPDATE_ROUTE:
    case SHOW_LIMIT_ROUTE:
    case PROMPT_LIMIT_REMOVAL_ROUTE:
    case PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE:
    case PROMPT_OTP_TO_LIMIT_CREATE_ROUTE:
    case PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE:
    case PROMPT_TO_DELETE_IP_ROUTE:
    case PROMPT_OTP_TO_DELETE_IP_ROUTE:
    case PROMPT_OTP_TO_ADD_IP_ROUTE:
    case PROMPT_OTP_TO_UPDATE_IP_ROUTE:
    case PROMPT_IDENTITY_VERIFICATION_ADDRESS_ROUTE:
    case SHOW_TRANSACTION_REPORT_ROUTE:
      return [];
    case PROMPT_COMMENT_TO_SEND_ROUTE: {
      return [
        {
          name: 'PromptOutputAddress',
          path,
          params: {
            walletId: route.params.walletId,
            addressNetwork: route.params.addressNetwork,
            addressTo: route.params.addressTo,
            addressCurrency: route.params.currency,
            amount: route.params.amount,
            minFreeWithdrawAmount: route.params.minFreeWithdrawAmount,
            isEditable: true,
            fee: route.params.fee,
            feeChangeDisabled: route.params.feeChangeDisabled,
          },
        },
      ];
    }
    case PROMPT_AFFILIATE_PROGRAM_ROUTE: {
      return [{name: 'PromptAffiliateProgram'}];
    }
    case PROMPT_AMOUNT_TO_SEND_ROUTE: {
      return [
        {
          name: 'PromptOutputAddress',
          path,
          params: {
            walletId: route.params.walletId,
            addressNetwork: route.params.addressNetwork,
            addressTo: route.params.addressTo,
            addressCurrency: route.params.currency,
            amount: route.params.amount,
            isEditable: route.params.isEditable,
            fee: route.params.fee,
            feeChangeDisabled: route.params.feeChangeDisabled,
            minFreeWithdrawAmount: route.params.minFreeWithdrawAmount,
          },
        },
      ];
    }
    case SHOW_QR_ROUTE: {
      return [
        {
          name: 'ShowQrToReceiveCrypto',
          params: {
            walletId: route.params.walletId,
            addressNetwork: route.params.addressNetwork,
            addressCurrency: route.params.addressCurrency,
            address: route.params.address,
          },
        },
      ];
    }
    case GENERATE_PAY_ME_LINK_ROUTE: {
      return [{name: 'PromptCryptoToReceive'}];
    }
    case PROMPT_OUTPUT_NETWORK_ROUTE: {
      return [
        {
          name: 'PromptOutputNetwork',
          path,
          params: {
            walletId: route.params.walletId,
            addressNetwork: route.params.addressNetwork,
            addressCurrency: route.params.addressCurrency,
            addressTo: route.params.addressTo,
            isEditable: route.params.isEditable,
            fee: route.params.fee,
            feeChangeDisabled: route.params.feeChangeDisabled,
            currency: route.params.currency,
            minFreeWithdrawAmount: route.params.minFreeWithdrawAmount,
          },
        },
      ];
    }
    case LIST_NOTIFICATION_SETTINGS_ROUTE: {
      const headerRoutes = pickUntil(state, index + 1, isSettingsHeader);
      const headerTop = last(headerRoutes);
      return [
        {
          name: 'ListNotificationSettings',
          path: headerTop?.path?.toString() ?? path,
          params: {focusedHeader: headerTop?.route},
        },
      ];
    }
    case SHOW_PROFILE_ROUTE: {
      const profileMetas = pickUntil(state, index + 1, isProfileCardWithHeader);
      const focusedHeader = findLast(profileMetas, isSettingsHeader);
      const topProfileMeta = findLast(profileMetas, isProfileCard);
      let focusedCard;
      if (topProfileMeta) {
        if (isProfileDetails(topProfileMeta)) {
          focusedCard = ProfileCard.Details;
        } else if (isProfileVerification(topProfileMeta)) {
          focusedCard = ProfileCard.Verification;
        } else if (isProfileBaseCurrency(topProfileMeta)) {
          focusedCard = ProfileCard.BaseCurrency;
        }
      }
      const focusedDetails = findLast(profileMetas, isProfileDetails);
      const focusedVerification = findLast(profileMetas, isProfileVerification);
      const focusedBaseCurrency = findLast(profileMetas, isProfileBaseCurrency);
      return [
        {
          name: 'ShowProfile',
          path: last(profileMetas)?.path?.toString() ?? path,
          params: {
            focusedHeader: focusedHeader?.route,
            focusedCard,
            focusedBaseCurrency: focusedBaseCurrency?.route,
            focusedDetails: focusedDetails?.route,
            focusedVerification: focusedVerification?.route,
          },
        },
      ];
    }
    case LIST_SECURITY_SETTINGS_ROUTE: {
      const securityMetas = pickUntil(
        state,
        index + 1,
        isSecuritySettingsCardWithHeader,
      );
      const focusedHeader = findLast(securityMetas, isSettingsHeader);
      const topSecurityMeta = findLast(securityMetas, isSecuritySettingsCard);
      let focusedCard;
      if (topSecurityMeta) {
        if (isSecuritySettingsTwoFa(topSecurityMeta)) {
          focusedCard = SecuritySettingsCard.TwoFa;
        } else if (isSecuritySettingsPin(topSecurityMeta)) {
          focusedCard = SecuritySettingsCard.Pin;
        } else if (isSecuritySettingsSessions(topSecurityMeta)) {
          focusedCard = SecuritySettingsCard.Sessions;
        }
      }
      const focusedTwoFa = findLast(securityMetas, isSecuritySettingsTwoFa);
      const focusedPin = findLast(securityMetas, isSecuritySettingsPin);
      const focusedSessions = findLast(
        securityMetas,
        isSecuritySettingsSessions,
      );
      return [
        {
          name: 'ListSecuritySettings',
          path: last(securityMetas)?.path?.toString() ?? path,
          params: {
            focusedHeader: focusedHeader?.route,
            focusedCard,
            focusedTwoFa: focusedTwoFa?.route,
            focusedPin: focusedPin?.route,
            focusedSessions: focusedSessions?.route,
            ...route.params,
          },
        },
      ];
    }
    case LIST_LIMITS_ROUTE: {
      const limitsMeta = pickUntil(state, index + 1, isLimits);
      const limitRoute = last(limitsMeta);
      return [
        {
          name: 'ListLimits',
          path: limitRoute?.path?.toString() ?? path,
          params: {
            focusedSelectedLimit: limitRoute?.route,
          },
        },
      ];
    }
  }
  const headerRoutes = pickUntil(state, index + 1, isMainHeader);
  const headerTop = last(headerRoutes);
  return createStateFromMeta(
    meta,
    headerTop,
  ) as DistinctSwitchHistoryRecord<LargeSwitchParamList>[];
}

type RouteMeta<T> = {
  route: T;
  path?: Path;
};

function isSecuritySettingsCardWithHeader(
  _: ShallowCommonRouteMeta,
): _ is RouteMeta<
  | SettingsHeaderRoute
  | ListSecuritySettingsTwoFaRoute
  | ListSecuritySettingsPinRoute
  | ListSecuritySettingsSessionsRoute
> {
  return isSettingsHeader(_) || isSecuritySettingsCard(_);
}

function isSecuritySettingsCard(
  _: ShallowCommonRouteMeta,
): _ is RouteMeta<
  | ListSecuritySettingsTwoFaRoute
  | ListSecuritySettingsPinRoute
  | ListSecuritySettingsSessionsRoute
> {
  return (
    isSecuritySettingsTwoFa(_) ||
    isSecuritySettingsPin(_) ||
    isSecuritySettingsSessions(_)
  );
}

function isSecuritySettingsTwoFa(
  _: ShallowCommonRouteMeta,
): _ is RouteMeta<ListSecuritySettingsTwoFaRoute> {
  switch (_.route.kind) {
    case 'SHOW_TWO_FA_SETTINGS_ROUTE':
    case 'SHOW_TWO_FA_GENERATED_SECRET_ROUTE':
    case 'LIST_AUTH_APPS_ROUTE':
    case 'PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE':
    case 'PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE':
      return true;
  }
  return false;
}

function isSecuritySettingsPin(
  _: ShallowCommonRouteMeta,
): _ is RouteMeta<ListSecuritySettingsPinRoute> {
  switch (_.route.kind) {
    case 'PROMPT_BIOMETRIC_SETTINGS_ROUTE':
    case 'CHECK_PIN_ROUTE':
    case 'CHECK_BIOMETRICS_PIN_ROUTE':
    case 'PROMPT_OTP_TO_BIOMETRICS_ROUTE':
    case 'PROMPT_NEW_PIN_ROUTE':
    case 'NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE':
      return true;
  }
  return false;
}

function isSecuritySettingsSessions(
  _: ShallowCommonRouteMeta,
): _ is RouteMeta<ListSecuritySettingsSessionsRoute> {
  switch (_.route.kind) {
    case 'LIST_ALLOWED_IPS_ROUTE':
    case 'PROMPT_UPDATE_IP_ROUTE':
    case 'PROMPT_ADD_IP_ROUTE':
    case 'LIST_BLOCKED_IPS_ROUTE':
    case 'PROMPT_TO_DELETE_IP_ROUTE':
    case 'PROMPT_OTP_TO_DELETE_IP_ROUTE':
    case 'PROMPT_OTP_TO_ADD_IP_ROUTE':
    case 'PROMPT_OTP_TO_UPDATE_IP_ROUTE':
    case 'LIST_SESSION_HISTORY_ROUTE':
    case 'LIST_ACTIVE_SESSIONS_ROUTE':
    case 'PROMPT_SESSION_DELETION_ROUTE':
      return true;
  }
  return false;
}

function isLimits(
  _: ShallowCommonRouteMeta,
): _ is RouteMeta<SelectedLimitRoute> {
  switch (_.route.kind) {
    case 'PROMPT_NEW_LIMIT_ROUTE':
    case 'SHOW_LIMIT_ROUTE':
    case 'PROMPT_LIMIT_UPDATE_ROUTE':
    case 'PROMPT_LIMIT_REMOVAL_ROUTE':
      return true;
  }
  return false;
}

function isProfileCardWithHeader(
  _: ShallowCommonRouteMeta,
): _ is RouteMeta<
  | SettingsHeaderRoute
  | ProfileDetailsRoute
  | ProfileVerificationRoute
  | ProfileBaseCurrencyRoute
> {
  return isSettingsHeader(_) || isProfileCard(_);
}

function isProfileCard(
  _: ShallowCommonRouteMeta,
): _ is RouteMeta<
  ProfileDetailsRoute | ProfileVerificationRoute | ProfileBaseCurrencyRoute
> {
  return (
    isProfileDetails(_) || isProfileVerification(_) || isProfileBaseCurrency(_)
  );
}

function isProfileDetails(
  _: ShallowCommonRouteMeta,
): _ is RouteMeta<ProfileDetailsRoute> {
  switch (_.route.kind) {
    case 'PROMPT_NICKNAME_ROUTE':
    case 'PROMPT_USER_ID_ROUTE':
    case 'PROMPT_EMAIL_ROUTE':
      return true;
  }
  return false;
}

function isProfileVerification(
  _: ShallowCommonRouteMeta,
): _ is RouteMeta<ProfileVerificationRoute> {
  switch (_.route.kind) {
    case 'LIST_PERSONAL_DATA_ROUTE':
    case 'PROMPT_NAME_AND_BIRTHDATE_ROUTE':
    case 'PROMPT_RESIDENTIAL_ADDRESS_ROUTE':
    case 'LIST_DOCUMENTS_ROUTE':
    case 'IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE':
    case 'PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE':
      return true;
  }
  return false;
}

function isProfileBaseCurrency(
  _: ShallowCommonRouteMeta,
): _ is RouteMeta<ProfileBaseCurrencyRoute> {
  switch (_.route.kind) {
    case 'PROMPT_BASE_CRYPTO_ROUTE':
    case 'PROMPT_BASE_FIAT_ROUTE':
      return true;
  }
  return false;
}

function isSettingsHeader(
  _: ShallowCommonRouteMeta,
): _ is RouteMeta<SettingsHeaderRoute> {
  switch (_.route.kind) {
    case 'PROMPT_THEME_ROUTE':
    case 'PROMPT_LANGUAGE_ROUTE':
    case 'LIST_NOTIFICATIONS_ROUTE':
      return true;
  }
  return false;
}

type ShallowMainHeaderRouteMeta = RouteMeta<MainHeaderRoute>;

function isMainHeader(
  _: ShallowCommonRouteMeta,
): _ is ShallowMainHeaderRouteMeta {
  return isSettingsHeader(_) || _.route.kind === 'LIST_MENU_CATEGORIES_ROUTE';
}

function translateHomeStackRoute(
  meta: LargeHomeStackRouteMeta,
): DistinctPartialRoute<LargeHomeStackParamList> {
  return createStateFromMeta<LargeHomeStackParamList>(meta)[0];
}

function createStateFromMeta<T extends ParamListBase>(
  meta: ShallowCommonRouteMeta,
  header?: ShallowMainHeaderRouteMeta,
): DistinctPartialRoute<T>[] {
  const kind = meta.route.kind;
  const path = meta.path?.toString();
  const name: ValueOf<typeof kindToNameMap> | undefined =
    kindToNameMap[kind as keyof typeof kindToNameMap];
  if (Object.is(name, undefined)) {
    return [];
  }
  const _params = Reflect.get(meta.route, 'params') as object | undefined;
  const _state = Reflect.get(meta.route, 'state') as object | undefined;
  const params =
    _params || _state || header
      ? {..._params, ..._state, ...{focusedHeader: header?.route}}
      : undefined;
  const route = {
    name,
    path: header?.path?.toString() ?? path,
    params,
  } as DistinctPartialRoute<T>;
  return [route];
}

function pickUntil<Target, Stop>(
  array: readonly (Target | Stop)[],
  start: number,
  detector: (_: Target | Stop) => _ is Target,
): Target[] {
  const result: Target[] = [];
  for (let i = start; i < array.length; i++) {
    const item = array[i];
    if (!detector(item)) {
      break;
    }
    result.push(item);
  }
  return result;
}
