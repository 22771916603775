import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export type LimitActionsProps = {
  onEditPress: () => void;
  onDeletePress: () => void;
};

export default observer(function LimitActions(props: LimitActionsProps) {
  const {onEditPress, onDeletePress} = props;
  const strings = useStrings();

  return (
    <ActionsContainer>
      <LimitAction
        variant={ButtonVariant.Primary}
        title={strings['currencyLimitScreen.changeButton']}
        onPress={onEditPress}
        testID="edit-limit-btn"
      />
      <SizedBox />
      <LimitAction
        variant={ButtonVariant.Danger}
        title={strings['currencyLimitScreen.limitInfo.cancel']}
        onPress={onDeletePress}
        testID="disable-limit-btn"
      />
    </ActionsContainer>
  );
});

const ActionsContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    marginTop: 'auto',
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    width: 15,
  },
}));

const LimitAction = variance(Button)(() => ({
  root: {
    flex: 1,
  },
}));
