import {success} from '../fp';
import type {JsonRpcClient} from '../JsonRpc';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {
  AccountDocument,
  AccountDocumentId,
  AccountDocumentType,
} from '../NCWalletServer/AccountsDocuments';
import {
  AccountDocumentStatus,
  toUploadedDocumentFile,
} from '../NCWalletServer/AccountsDocuments';
import type {AccountDocumentRequestHelper} from './AccountDocumentRequestHelper';

// eslint-disable-next-line import-x/prefer-default-export
export class AccountDocumentRequestHelperImpl
  implements AccountDocumentRequestHelper
{
  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {}

  async getDocuments() {
    const res = await this._root.ncWalletJsonRpcClient.call(
      'accounts.documents.get.list',
      {},
    );

    return res.success ? success(res.right.items) : res;
  }

  async sendDocumentsToReview(ids: AccountDocumentId[]) {
    const res = await Promise.all(
      ids.map(id =>
        this._root.ncWalletJsonRpcClient.call('accounts.documents.update', {
          id,
          status: AccountDocumentStatus.OnReview,
        }),
      ),
    );
    const errorRes = res.find(r => !r.success);
    if (errorRes) {
      return errorRes;
    }

    const documents = res.map(r => r.success && r.right) as AccountDocument[];
    return success(documents);
  }

  async uploadFile(
    fileData: string,
    documentType: AccountDocumentType,
    fileName?: string,
  ) {
    return this._root.ncWalletJsonRpcClient.call(
      'accounts.documents.upload',
      toUploadedDocumentFile(fileData, documentType, fileName),
    );
  }

  async deleteFiles(id: AccountDocumentId, fileIds: string[]) {
    return this._root.ncWalletJsonRpcClient.call('accounts.documents.update', {
      id,
      delete_files: fileIds,
    });
  }
}
