import {useStrings, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {LG_BREAKPOINT, SearchHeader} from '@ncwallet-app/ui';
import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import type {CryptoSectionItemData} from './CryptoSectionItem';
import {CryptoSectionList} from './CryptoSectionList';

export type BaseCryptoSettingProps = BaseSafeAreaProps & {
  getBaseCrypto: () => CryptoSectionItemData | undefined;
  getOtherCryptos: () => CryptoSectionItemData[] | undefined;
  filter: string;
  onFilterChange: (filter: string) => void;
  onFilterSubmit?: (filter: string) => void;
  onCryptoSelect: (crypto: CryptoSectionItemData) => void;
};

export const BaseCryptoSettingScreen = observer(
  (props: BaseCryptoSettingProps) => {
    const {
      getBaseCrypto,
      getOtherCryptos,
      filter,
      onFilterChange,
      onFilterSubmit,
      onCryptoSelect,
      ...rest
    } = props;
    const strings = useStrings();

    return (
      <Root>
        <ProfileSearchHeader
          value={filter}
          onValueChange={onFilterChange}
          onSubmit={onFilterSubmit || noop}
          placeholder={
            strings['profileSettingsScreen.searchCryptoCurrencyPlaceholder']
          }
        />
        <CryptoSectionList
          getBaseCrypto={getBaseCrypto}
          getOtherCryptos={getOtherCryptos}
          onCryptoSelect={onCryptoSelect}
          {...rest}
        />
      </Root>
    );
  },
);

const Root = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const ProfileSearchHeader = variance(SearchHeader)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingBottom: 13,
    paddingTop: 0,
    backgroundColor: theme.palette.uiPrimary,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        paddingBottom: 0,
        backgroundColor: 'transparent',
        borderBottomWidth: 0,
      },
    }),
  },
}));
