import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {useCalendarInfo} from './CalendarModalSettings';

export default observer(function CalendarModalDays() {
  const calendarBaseInfo = useCalendarInfo();
  return (
    <CalendarDays>
      {calendarBaseInfo.shortDays.map((day, index) => (
        <CalendarDaysCell key={index}>{day}</CalendarDaysCell>
      ))}
    </CalendarDays>
  );
});

const CalendarDays = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: 10,
  },
}));

const CalendarDaysCell = variance(Text)(theme => ({
  root: {
    width: 40,
    height: 40,
    textAlign: 'center',
    lineHeight: 40,
    ...theme.fontByWeight('600'),
    color: theme.palette.textPrimary,
    fontSize: 12,
  },
}));
