import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import type {Json} from '../Json';
import type {NotificationData} from '../LocalNotifications';
import type {HeadlessMessaging} from './HeadlessMessaging';
import type {HeadlessMessagingHelper} from './HeadlessMessagingHelper';
import type {MessageContext} from './MessageContext';

export default class HeadlessMessagingHelperImpl
  implements HeadlessMessagingHelper
{
  constructor(
    private readonly _root: {
      readonly json: Json;
      readonly headlessMessaging: HeadlessMessaging;
    },
  ) {}

  async exchangeNotificationDataForMessageContext(
    data: NotificationData,
  ): Promise<Either<MessageContext, GlobalError>> {
    if (data.designator === 'remote-notification-data') {
      const description_ = this._root.json.parse(data.description);
      if (!description_.success) {
        return description_;
      }
      return this._root.headlessMessaging.fetchContext(description_.right);
    } else {
      return this._root.json.parse(data.context);
    }
  }
}
