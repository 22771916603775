import type {CurrencyDescription, DecimalString} from '@ncwallet-app/core';
import {keepMeaningfulDigitsInFiat, variance} from '@ncwallet-app/core';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text} from 'react-native';

type CryptoRateTextProps = {
  getCryptoRate: () => DecimalString | undefined;
  getFiatCurrency: () => CurrencyDescription | undefined;
};

export default observer(function CryptoRateText(props: CryptoRateTextProps) {
  const cryptoRate = props.getCryptoRate();
  const fiatCurrency = props.getFiatCurrency();
  if (isNil(cryptoRate)) {
    return null;
  }
  return (
    <CryptoRate>
      {keepMeaningfulDigitsInFiat(cryptoRate)} {fiatCurrency?.code}
    </CryptoRate>
  );
});

const CryptoRate = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('600'),
    textAlign: 'right',
    marginTop: 5,
  },
}));
