import type {JsonSerializable} from '../Json/JsonSubject';

export type JsonRpcVersion = '2.0';
export type JsonRpcId = number | string | null;

export type Request<P extends JsonSerializable = JsonSerializable> = {
  jsonrpc: JsonRpcVersion;
  method: string;
  params?: P;
  id?: JsonRpcId;
};

export type SuccessResponse<R extends JsonSerializable = JsonSerializable> = {
  jsonrpc: JsonRpcVersion;
  id: JsonRpcId;
  result: R;
};

export type JsonRpcError<T extends JsonSerializable = JsonSerializable> = {
  code: number;
  message: string;
  data?: T;
};

export type ErrorResponse<E extends JsonRpcError = JsonRpcError> = {
  jsonrpc: JsonRpcVersion;
  id: JsonRpcId;
  error: E;
};

export type Response<
  R extends JsonSerializable = JsonSerializable,
  E extends JsonRpcError = JsonRpcError,
> = SuccessResponse<R> | ErrorResponse<E>;

export const isRequest = (request: unknown): request is Request =>
  typeof request === 'object' &&
  request !== null &&
  !Array.isArray(request) &&
  'jsonrpc' in request &&
  request.jsonrpc === '2.0' &&
  'method' in request &&
  typeof request.method === 'string' &&
  (!('id' in request) ||
    typeof request.id === 'number' ||
    typeof request.id === 'string' ||
    request.id === null);

export const isResponse = (response: unknown): response is Response =>
  typeof response === 'object' &&
  response !== null &&
  !Array.isArray(response) &&
  'jsonrpc' in response &&
  response.jsonrpc === '2.0' &&
  ('result' in response || 'error' in response) &&
  'id' in response &&
  (typeof response.id === 'number' ||
    typeof response.id === 'string' ||
    response.id === null);

export const isSuccess = (response: Response): response is SuccessResponse =>
  'result' in response;

export const isError = (response: Response): response is ErrorResponse =>
  'error' in response;
