import {range} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {ScrollView} from 'react-native-gesture-handler';

import CurrencySkeletonItem from './CurrencySkeletonItem';

type CurrencyListSkeletonProps = {
  itemCount?: number;
};

export default observer(function CurrencySkeletonList(
  props: CurrencyListSkeletonProps,
) {
  const {itemCount = 1} = props;

  return (
    <ScrollView overScrollMode="never">
      {range(0, itemCount).map(n => (
        <CurrencySkeletonItem key={n} />
      ))}
    </ScrollView>
  );
});
