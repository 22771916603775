import type {TelegramInitData} from '../units';
import type {TelegramMiniApp} from './TelegramMiniApp';

export default class StubTelegramMiniAppImpl implements TelegramMiniApp {
  readonly isAvailable = false;
  readonly initData = '' as TelegramInitData;
  readonly appShouldBeClosed = false;
  readonly isNativeMobileTelegramApp = false;

  openLink() {}
  closeThisInstance() {}
}
