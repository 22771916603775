import type {
  RouteTransition,
  ShowQrToReceiveCryptoRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useAddressTypeSelectionScreenProps} from '../../../CommonNavigationContainers/hooks/useAddressTypeSelectionScreenProps';
import ReceiveCryptoAddressTypeBottomSheet from '../../../screens/ReceiveCryptoScreen/ReceiveCryptoAddressTypeBottomSheet';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {goToSmallHomeStackTab} from '../util/goToSmallHomeStackTab';

export type PromptAddressFormatBindingProps =
  SmallHomeStackBindingProps<'PromptAddressFormat'>;

export default observer(function PromptAddressFormatBinding(
  props: PromptAddressFormatBindingProps,
) {
  const {navigation, route} = props;

  const onBackPress = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    goToSmallHomeStackTab('PromptCryptoToReceive', navigation);
  }, [navigation]);

  const handleSelect: RouteTransition<ShowQrToReceiveCryptoRoute> = useCallback(
    params => {
      navigation.navigate('ShowQrToReceiveCrypto', params);
    },
    [navigation],
  );

  const screenProps = useAddressTypeSelectionScreenProps({
    params: route.params,
    goBack: onBackPress,
    showQrToReceiveCrypto: handleSelect,
  });

  return (
    <ReceiveCryptoAddressTypeBottomSheet
      onClosePress={screenProps.onBack}
      onTypeSelect={screenProps.onSelect}
      types={screenProps.addressTypes}
      defaultType={screenProps.defaultAddressType}
    />
  );
});
