import type {Theme} from '@ncwallet-app/core';
import {AggregationItemStatus} from '@ncwallet-app/core/src/WalletTransactionsRequestHelper';

// eslint-disable-next-line import-x/prefer-default-export
export const mapStatusToColor = (theme: Theme) => {
  return new Map([
    [
      AggregationItemStatus.Created,
      {primary: theme.palette.success, secondary: theme.palette.additional6},
    ],
    [
      AggregationItemStatus.Reversed,
      {primary: theme.palette.success, secondary: theme.palette.additional6},
    ],
    [
      AggregationItemStatus.Canceled,
      {primary: theme.palette.error, secondary: theme.palette.additional5},
    ],
    [
      AggregationItemStatus.Pending,
      {primary: theme.palette.info, secondary: theme.palette.additional3},
    ],
    [
      AggregationItemStatus.Completed,
      {primary: theme.palette.success, secondary: theme.palette.additional6},
    ],
    [
      AggregationItemStatus.Error,
      {primary: theme.palette.error, secondary: theme.palette.additional5},
    ],
    [
      AggregationItemStatus.Rejected,
      {primary: theme.palette.error, secondary: theme.palette.additional5},
    ],
  ]);
};
