import {useStrings, variance} from '@ncwallet-app/core';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  Input,
  InputType,
  SafeAreaScrollView,
} from '@ncwallet-app/ui';
import {isEmpty} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {StyleSheet, Text} from 'react-native';

export type AddIpScreenProps = BaseSafeAreaProps & {
  type: IpType;
  wide?: boolean;
  onAdd: (ip: string, name: string) => void;
  getError?: () => string | undefined;
  resetError?: () => void;
};

export default observer(function AddIpScreen(props: AddIpScreenProps) {
  const {type, onAdd, wide, getError, resetError, ...rest} = props;
  const strings = useStrings();
  const [nameValue, setNameValue] = useState('');
  const [ipValue, setIpValue] = useState('');
  const placeholderIp =
    type === IpType.Blocked
      ? strings['secureSettings.ipList.placeholderIpBlock']
      : strings['secureSettings.ipList.placeholderIpAllow'];
  const error = getError && getError();

  const setName = useCallback(
    (value: string) => {
      setNameValue(value);
      if (!isEmpty(error) && resetError) {
        resetError();
      }
    },
    [error, resetError],
  );

  const setIp = useCallback(
    (value: string) => {
      setIpValue(value);
      if (!isEmpty(error) && resetError) {
        resetError();
      }
    },
    [error, resetError],
  );

  return (
    <SafeAreaScrollView {...rest} style={!wide && styles.container}>
      <Input
        type={InputType.Email}
        label={strings['secureSettings.ipList.labelIpName']}
        placeholder={strings['secureSettings.ipList.placeholderIpName']}
        value={nameValue}
        onChangeText={setName}
        autoFocus={false}
        testID="name-ip-input"
      />
      <Input
        type={InputType.Email}
        label={strings['secureSettings.ipList.labelIp']}
        placeholder={placeholderIp}
        value={ipValue}
        onChangeText={setIp}
        containerStyle={styles.input}
        autoFocus={false}
        testID="ip-input"
      />
      {!isEmpty(error) && <DangerText>{error}</DangerText>}
      {
        <DangerText>
          {type === IpType.Blocked
            ? strings['secureSettings.ipList.dangerText']
            : strings['secureSettings.ipList.dangerAllowListText']}
        </DangerText>
      }
      <ListIpButton
        title={strings['secureSettings.ipList.addButton']}
        color={ButtonColor.Secondary}
        variant={ButtonVariant.Highlighted}
        onPress={() => {
          onAdd(ipValue, nameValue);
        }}
        testID="add-ip-btn"
      />
    </SafeAreaScrollView>
  );
});

const styles = StyleSheet.create({
  input: {
    marginTop: 30,
  },
  container: {
    paddingTop: 20,
    paddingHorizontal: 10,
    flex: 1,
  },
});

const DangerText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.error,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    marginVertical: 20,
  },
}));

const ListIpButton = variance(Button)(() => ({
  root: {
    marginTop: 'auto',
  },
}));
