import {useStrings, variance} from '@ncwallet-app/core';
import {BottomSheet, Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';

export type PromptSignOutScreenProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export default observer(function PromptSignOutScreen(
  props: PromptSignOutScreenProps,
) {
  const {onCancel, onConfirm} = props;
  const strings = useStrings();
  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        onCancel();
      }
    },
    [onCancel],
  );
  return (
    <BottomSheet
      index={0}
      onChange={onChange}
      animateOnMount
      enablePanDownToClose
      snapPoints={[240]}
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      title={strings['userSettings.logOut']}
      onClose={onCancel}
      children={
        <Container>
          <Button
            onPress={onConfirm}
            title={strings['sendCrypto.code.submit']}
            variant={ButtonVariant.Primary}
          />
          <Divider />
          <Button
            onPress={onCancel}
            title={strings['deleteAccountModal.cancel']}
            variant={ButtonVariant.SecondaryLight}
          />
        </Container>
      }
    />
  );
});

const Divider = variance(View)(() => ({
  root: {
    height: 20,
  },
}));

const Container = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingTop: 20,
  },
}));
