import {observer} from 'mobx-react-lite';
import React from 'react';

import {SendAddressInput} from '../SendAddressInput';
import {InputsRow} from './Shared';

export type LgAddressTabViewProps = {
  addressNameShown: boolean;
  addressName: string | undefined;
  networkChangeEnabled: boolean;
  onAddressNamePress: () => void;
  addressTo: string;
  addressToError?: string;
  showAddressAction: boolean;
  onAddressActionPress: () => void;
  onAddressToChange: (addressTo: string) => void;
  networkCurrency: string | undefined;
  onBlurAddressInput: () => Promise<void>;
  contractType: string | undefined | null;
};

export default observer(function LgAddressTabView(
  props: LgAddressTabViewProps,
) {
  return (
    <>
      <InputsRow>
        <SendAddressInput
          networkCurrency={props.networkCurrency}
          networkChangeEnabled={props.networkChangeEnabled}
          onAddressNamePress={props.onAddressNamePress}
          addressNameShown={props.addressNameShown}
          addressName={props.addressName}
          wideStyle
          error={!!props.addressToError}
          value={props.addressTo}
          onBlur={props.onBlurAddressInput}
          onInputChange={props.onAddressToChange}
          showAction={props.showAddressAction}
          onActionPress={props.onAddressActionPress}
          contractType={props.contractType}
          testID="wallet-input"
        />
      </InputsRow>
    </>
  );
});
