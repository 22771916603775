import dayjs from 'dayjs';
import {isEmpty, isNil, last, maxBy, sortBy} from 'lodash';

import type {Tagged} from '../Opaque';
import type {ISODateString} from '../Time';
import type {CryptoAddress} from './Wallet';

export type AddressInfo = {
  // address is not unique inside of currencies_in / currencies_out arrays
  // if unique identifier needed, address + network should be used
  address: CryptoAddress;
  type: AddressType | undefined;
  network: AddressNetwork;
  description: string | undefined;
  name: string;
  created_at: ISODateString;
  blockchain: AddressBlockchain;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ADDRESS_BLOCKCHAIN = Symbol();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ADDRESS_TYPE = Symbol();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ADDRESS_NETWORK = Symbol();

export type AddressBlockchain = Tagged<string, typeof ADDRESS_BLOCKCHAIN>;
export type AddressType = Tagged<string, typeof ADDRESS_TYPE>;
export type AddressNetwork = Tagged<string, typeof ADDRESS_NETWORK>;

// getDefaultAddressInfo может возвращать некорректный адрес для сетей, у которых нет адреса по-умолчанию
// и не подходят адреса других сетей (например Solana в USDT кошелька)
// В таких случаях до вызова нужно убедиться что есть подходящий адрес и если нет, то создать его
export const getDefaultAddressInfo = (
  addresses: AddressInfo[],
  defaultNetwork: AddressNetwork,
  defaultType?: AddressType,
  skipEmptyDefaultNetworkCheck?: boolean,
): AddressInfo | undefined => {
  const sorted = sortBy(addresses, a => dayjs(a.created_at).unix());

  const addressesWithDefaultNetwork = sorted.filter(
    a => a.network === defaultNetwork,
  );

  if (isEmpty(addressesWithDefaultNetwork) && !skipEmptyDefaultNetworkCheck) {
    return last(sorted);
  }

  if (isNil(defaultType)) {
    return last(addressesWithDefaultNetwork);
  }

  const addressesWithDefaultNetworkAndType = addressesWithDefaultNetwork.filter(
    a => a.type === defaultType,
  );

  return isEmpty(addressesWithDefaultNetworkAndType)
    ? last(addressesWithDefaultNetworkAndType)
    : last(addressesWithDefaultNetwork);
};

export const getLastCreatedAddressInfo = (
  addresses: AddressInfo[],
  network?: AddressNetwork,
  type?: AddressType,
) => {
  const relevantAddresses = addresses.filter(
    a => (!network || a.network === network) && (!type || a.type === type),
  );

  return maxBy(relevantAddresses, _ => new Date(_.created_at));
};

export const getCompositeId = (i: AddressInfo): string =>
  `${i.address}-${i.network}`;
