import type {WalletsLimitsCreate} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {
  PROMPT_NEW_LIMIT_ROUTE,
  PROMPT_OTP_TO_LIMIT_CREATE_ROUTE,
  PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE,
  SHOW_LIMIT_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {useCallback, useEffect} from 'react';

import {useCreateLimitWithOtp} from '../../../../../../CommonNavigationContainers/hooks/walletLimits/useCreateLimitWithOtp';
import type {
  PromptLimitOtpCardProps,
  PromptLimitOtpTelegram,
} from '../../../../../../screens/LgLimitsSettingsScreen/LimitCard/PromptLimitOtpCard';
import type {LargeSwitchBindingProps} from '../../LargeSwitchBindingProps';

// eslint-disable-next-line import-x/prefer-default-export
export const usePromptOtpToLimitCreateCard = (
  refresh: () => void,
  telegramOtp: PromptLimitOtpTelegram,
  props: LargeSwitchBindingProps<'ListLimits'>,
): PromptLimitOtpCardProps => {
  const {navigation, route} = props;
  const root = useRoot();
  const selectedCard = route.params?.focusedSelectedLimit;

  const onSuccess = useCallback(
    (res: WalletsLimitsCreate['result']) => {
      refresh();

      navigation.setParams({
        focusedSelectedLimit: {
          kind: SHOW_LIMIT_ROUTE,
          params: {
            walletId: res.wallet_limit.wallet_id,
            period: res.wallet_limit.period,
          },
        },
      });
    },
    [navigation, refresh],
  );

  const {createLimitWithOpt, resetErrorText, getErrorText} =
    useCreateLimitWithOtp(onSuccess);

  useEffect(() => {
    if (
      selectedCard?.kind !== PROMPT_OTP_TO_LIMIT_CREATE_ROUTE &&
      selectedCard?.kind !== PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE
    ) {
      resetErrorText();
    }
  }, [resetErrorText, selectedCard?.kind]);

  const onSubmit = useCallback(
    (code: string) => {
      if (
        selectedCard?.kind !== PROMPT_OTP_TO_LIMIT_CREATE_ROUTE &&
        selectedCard?.kind !== PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE
      ) {
        return;
      }
      const {walletId, limit, period} = selectedCard.params;
      return createLimitWithOpt(walletId, limit, period, code);
    },
    [createLimitWithOpt, selectedCard],
  );

  const onBack = useCallback(() => {
    if (selectedCard?.kind === PROMPT_OTP_TO_LIMIT_CREATE_ROUTE) {
      const wallet = root.walletStore.getWalletById(
        selectedCard.params.walletId,
      );
      // кошелек может отсутствовать если во время загрузки данных пользователь нажал назад
      // (что возможно только после обновлении страницы в вебе)
      if (!wallet) {
        return;
      }
      navigation.setParams({
        focusedSelectedLimit: {
          kind: PROMPT_NEW_LIMIT_ROUTE,
          params: {
            walletId: selectedCard.params.walletId,
            currency: wallet.currency,
            period: selectedCard.params.period,
          },
        },
      });
    } else if (selectedCard?.kind === PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE) {
      navigation.setParams({
        focusedSelectedLimit: {
          kind: SHOW_LIMIT_ROUTE,
          params: {
            walletId: selectedCard.params.walletId,
            period: selectedCard.params.period,
          },
        },
      });
    }
  }, [navigation, root.walletStore, selectedCard]);

  return {
    onBack,
    onSubmit,
    resetErrorText,
    getErrorText,
    ...telegramOtp,
  };
};
