import type {CountryNameData} from './constant';
import type {Localization} from './Localization';
import type {
  PluralTranslationKeys,
  TemplateSubstitution,
  TranslationKey,
  TranslationProvider,
} from './TranslationProvider';
import type {UserPreferenceState} from './UserPreferenceState';

export default class LocalizationImpl implements Localization {
  constructor(
    private readonly _root: {
      readonly translationProvider: TranslationProvider;
      readonly userPreferenceState: UserPreferenceState;
    },
  ) {}

  getPluralKey({
    count,
    translationKeys,
  }: {
    count: number;
    translationKeys: PluralTranslationKeys;
  }): TranslationKey {
    if (count === 1) {
      return translationKeys.one;
    }

    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;
    const isTeens = lastTwoDigits >= 11 && lastTwoDigits <= 14;

    if (lastDigit >= 2 && lastDigit <= 4 && !isTeens) {
      return translationKeys.few;
    }

    if (lastDigit === 0 || (lastDigit >= 5 && lastDigit <= 9) || isTeens) {
      return translationKeys.many;
    }

    return translationKeys.other;
  }

  getTranslation(translation: TranslationKey): string {
    const language = this._root.userPreferenceState.languageCode;
    return this._root.translationProvider.getTranslation(language, translation);
  }

  getCountryNamesData(): CountryNameData[] {
    const language = this._root.userPreferenceState.languageCode;
    return this._root.translationProvider.getCountryNamesData(language);
  }

  executeTemplate(
    translation: TranslationKey,
    substitution: TemplateSubstitution,
  ): string {
    const language = this._root.userPreferenceState.languageCode;
    return this._root.translationProvider.executeTemplate(
      language,
      translation,
      substitution,
    );
  }
}
