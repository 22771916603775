import {isEmpty} from 'lodash';

import type {BaseAsyncOptions} from '../Async';
import type {JsonRpcClient} from '../JsonRpc';
import type {CryptoCurrencyCode} from '../Money';
import type {
  CryptoAddress,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {AddressNetwork} from '../NCWalletServer/AddressInfo';

export enum SendAddressValidatorError {
  EmptyAddress,
  CannotBePerformedOnUserAddress,
  AddressInvalid,
  Aborted,
}

export class SendAddressValidator {
  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {}

  validate = async (
    address: string,
    currency: CryptoCurrencyCode,
    userWalletAddresses: CryptoAddress[],
    network?: AddressNetwork,
    options?: BaseAsyncOptions,
  ): Promise<SendAddressValidatorError | undefined> => {
    if (options?.signal?.aborted) {
      return SendAddressValidatorError.Aborted;
    }

    if (isEmpty(address)) {
      return SendAddressValidatorError.EmptyAddress;
    } else if (userWalletAddresses.includes(address as CryptoAddress)) {
      return SendAddressValidatorError.CannotBePerformedOnUserAddress;
    }

    const isValidRes = await this._root.ncWalletJsonRpcClient.call(
      'wallets.addresses.validate',
      {address, currency, network},
      options,
    );

    if (!isValidRes.success) {
      return undefined;
    }

    return isValidRes.right.is_valid
      ? undefined
      : SendAddressValidatorError.AddressInvalid;
  };
}
