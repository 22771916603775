import type {CameraCapturedPicture} from '@ncwallet-app/core';
import {
  APP_WINDOW_ACTIVE,
  openPlatformSettings,
  PermissionStatus,
  uriToFileData,
  useRoot,
} from '@ncwallet-app/core';
import type {PermissionResponse} from 'expo-barcode-scanner';
import {isNil} from 'lodash';
import {reaction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import {
  getVerificationFileFromCamera,
  useHandleVerificationFileError,
} from '../../../CommonNavigationContainers/hooks/AccountVerification';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {useGetPermissionTexts} from '../../../screens/PromptDocumentCameraPermissionsScreen/useGetPermissionTexts';
import ShowDocumentCameraScreen from '../../../screens/ShowDocumentCameraScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ShowDocumentCameraBindingProps =
  SmallHomeStackBindingProps<'ShowDocumentCamera'>;

export default observer(function ShowDocumentCameraBinding(
  props: ShowDocumentCameraBindingProps,
) {
  const {navigation, route} = props;
  const {documentTypeId} = route.params;
  const root = useRoot();
  const getIsFocused = useNavigationGetIsFocused();
  const handleVerificationFileError = useHandleVerificationFileError();
  const handleClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);
  const [cameraPermissions, setCameraPermissions] =
    useState<PermissionResponse>();
  const permissionTexts = useGetPermissionTexts('camera');

  const requestPermission = useCallback(async () => {
    try {
      const response = await root.camera.requestCameraPermissionsAsync();
      setCameraPermissions(response);
    } catch {
      /* empty */
    }
  }, [root.camera]);

  useEffect(() => {
    return reaction(
      () => getIsFocused(),
      async isFocused => {
        if (isFocused) {
          const response = await root.camera.getCameraPermissionsAsync();
          setCameraPermissions(response);
          if (
            response.canAskAgain &&
            response.status === PermissionStatus.UNDETERMINED
          ) {
            await requestPermission();
          }
        }
      },
      {fireImmediately: true},
    );
  }, [getIsFocused, requestPermission, root.camera]);

  useEffect(
    () =>
      root.appWindow.updates.listen(APP_WINDOW_ACTIVE, async () => {
        try {
          const response = await root.camera.getCameraPermissionsAsync();
          setCameraPermissions(response);
        } catch {
          /* empty */
        }
      }),
    [root.appWindow, root.camera],
  );

  const handleOpenSettings = useCallback(() => {
    if (cameraPermissions?.canAskAgain) {
      void requestPermission();
    } else {
      openPlatformSettings();
    }
  }, [requestPermission, cameraPermissions]);

  const handleTakePhoto = useCallback(
    async (event: CameraCapturedPicture | undefined) => {
      const fileRes = await getVerificationFileFromCamera(event);
      if (!fileRes.success) {
        handleVerificationFileError(fileRes.left);
        return;
      }
      const {uri, fileName} = fileRes.right;
      const uploadRes = await root.accountDocumentManager.uploadFile(
        await uriToFileData(uri),
        documentTypeId,
        fileName,
      );
      if (uploadRes.success) {
        handleClose();
      }
    },
    [root, documentTypeId, handleVerificationFileError, handleClose],
  );

  return (
    <ShowDocumentCameraScreen
      isPermissionsUndetermined={
        isNil(cameraPermissions) ||
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        cameraPermissions.status === PermissionStatus.UNDETERMINED
      }
      canAskPermissionsAgain={cameraPermissions?.canAskAgain}
      onSettingsPress={handleOpenSettings}
      permissionTexts={permissionTexts}
      isPermissionGranted={cameraPermissions?.granted}
      onTakePhoto={handleTakePhoto}
      onClose={handleClose}
    />
  );
});
