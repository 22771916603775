import {reaction} from 'mobx';

import type {CriticalErrorState} from '../CriticalErrorState';
import type {Connection, ConnectionSpawner} from '../JsonRpc';
import type {NavigationContainer} from '../NavigationContainer';
import type {Service} from '../structure';

export default class CriticalErrorDetectorService implements Service {
  constructor(
    private readonly _root: {
      readonly criticalErrorState: CriticalErrorState;
      readonly navigationContainer: NavigationContainer;
      readonly connection: Connection;
      readonly connectionSpawner: ConnectionSpawner;
    },
  ) {}

  subscribe() {
    return reaction(
      () => this._root.criticalErrorState.latest !== undefined,
      async (isDetected, wasDetected) => {
        if (wasDetected === true && !isDetected) {
          await this._root.connectionSpawner.tryConnection();
          return;
        }
        if (isDetected) {
          this._root.navigationContainer.ref?.resetRoot({
            index: 0,
            routes: [{name: 'Root'}],
          });
          await this._root.connectionSpawner.giveUpConnection();
          await this._root.connection.disconnect();
          return;
        }
      },
      {fireImmediately: true, name: 'CriticalErrorDetectorService#subscribe'},
    );
  }
}
