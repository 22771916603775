import type {
  PromptOtpToAddIpRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {useHeaderHeight} from '@react-navigation/elements';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useAddIp} from '../../../CommonNavigationContainers/hooks/ipSettings/useAddIp';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import AddIpScreen from '../../../screens/UserSettingsScreens/AddIpScreen/AddIpScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {createResetToIpListAction} from '../util/createResetToIpListAction';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptAddIpBindingProps = SmallHomeStackBindingProps<'PromptAddIp'>;

export default observer(function PromptAddIpBinding(
  props: PromptAddIpBindingProps,
) {
  const {
    navigation,
    route: {params},
  } = props;

  const headerHeight = useHeaderHeight();

  const goToPromptOtp = useCallback(
    (p: RouteParams<PromptOtpToAddIpRoute>) => {
      navigation.navigate('PromptOtpToAddIp', p);
    },
    [navigation],
  );

  const toIpList = useCallback(() => {
    const navigateTo =
      params.type === IpType.Allowed ? 'ListAllowedIp' : 'ListBlockedIp';
    if (hasRouteInHistory(navigation.getState(), navigateTo)) {
      navigation.navigate(navigateTo);
    } else {
      navigation.dispatch(createResetToIpListAction(params.type));
    }
  }, [navigation, params.type]);

  const {addIp, getErrorText, resetErrorText} = useAddIp(
    goToPromptOtp,
    toIpList,
  );

  const onAdd = useCallback(
    (ip: string, name: string) => addIp(ip, name, params.type),
    [addIp, params.type],
  );

  return (
    <AddIpScreen
      onAdd={onAdd}
      getError={getErrorText}
      resetError={resetErrorText}
      type={params.type}
      keyboardAvoiding
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      keyboardVerticalOffset={headerHeight}
    />
  );
});
