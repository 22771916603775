import type {Millisecond} from '@ncwallet-app/core';
import {delayResolve} from '@ncwallet-app/core';
import {useNavigation} from '@react-navigation/native';
import type {RefObject} from 'react';
import {useEffect} from 'react';
import type {TextInput} from 'react-native';
import {Keyboard} from 'react-native';

// eslint-disable-next-line import-x/prefer-default-export
export const useFocusInputOnNavigationFocus = (
  inputRef: RefObject<TextInput>,
) => {
  const navigation = useNavigation();
  useEffect(() => {
    const unsubscribeBlur = navigation.addListener('beforeRemove', () => {
      Keyboard.dismiss();
    });
    const unsubscribeFocus = navigation.addListener('focus', async () => {
      await delayResolve(500 as Millisecond);
      inputRef.current?.focus();
    });
    return () => {
      unsubscribeFocus();
      unsubscribeBlur();
    };
  }, [inputRef, navigation]);
};
