import {variance} from '@ncwallet-app/core/src';
import type {ReactNode} from 'react';
import React, {useEffect, useRef} from 'react';
import {Platform, View} from 'react-native';
import type {SharedValue} from 'react-native-gesture-handler/lib/typescript/handlers/gestures/reanimatedWrapper';
import type {Path, Vector} from 'react-native-redash';
import {clamp, getYForX} from 'react-native-redash';

export type WebHoverWrapProps = {
  children: ReactNode;
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  isActiveCursor: SharedValue<boolean>;
  cursorTranslation: Vector<SharedValue<number>>;
  currentPath?: Path;
  width: number;
  Container?: View | null;
  setHelperToEnd?: () => void;
  showInfo: SharedValue<boolean>;
};

export default function WebHoverWrap({
  onHoverIn,
  onHoverOut,
  isActiveCursor,
  children,
  cursorTranslation,
  currentPath,
  Container,
  width,
  showInfo,
}: WebHoverWrapProps) {
  const ref = useRef<View>(null);
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    let requestId: ReturnType<typeof requestAnimationFrame> | undefined;
    if (Platform.OS === 'web') {
      const element = ref.current as unknown as HTMLElement;

      const handleIn = (e: MouseEvent) => {
        if (timer.current) {
          clearTimeout(timer.current);
        }
        const x = clamp(
          window.innerWidth > 710
            ? e.pageX - element.getBoundingClientRect().left
            : e.pageX,
          0,
          width,
        );
        requestId = requestAnimationFrame(() => {
          isActiveCursor.value = true;
          showInfo.value = true;
          cursorTranslation.x.value = x;
          cursorTranslation.y.value =
            (currentPath && getYForX(currentPath, x)) || 0;
        });
        onHoverIn?.();
      };

      const handleOut = () => {
        timer.current = setTimeout(() => {
          showInfo.value = false;
        }, 10000);
        onHoverOut?.();
      };
      element.addEventListener('mousemove', handleIn);
      element.addEventListener('mouseleave', handleOut);
      element.addEventListener('click', handleOut);
      return () => {
        if (requestId) {
          cancelAnimationFrame(requestId);
        }
        element.removeEventListener('mousemove', handleIn);
        element.removeEventListener('mouseleave', handleOut);
        element.removeEventListener('click', handleOut);
      };
    }
  }, [
    Container,
    currentPath,
    cursorTranslation,
    isActiveCursor,
    onHoverIn,
    onHoverOut,
    showInfo,
    timer,
    width,
  ]);
  if (Platform.OS === 'web') {
    return <WebRoot ref={ref}>{children}</WebRoot>;
  }

  return <>{children}</>;
}

const WebRoot = variance(View)(() => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
}));
