import type {DecimalString, Theme} from '@ncwallet-app/core';
import {useStyles, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {ColorValue} from 'react-native';
import {View} from 'react-native';

export type WalletLimitProgressBarProps = {
  value: DecimalString;
  total: DecimalString;
};

export const computeRemainingLimitPercent = (total: number, value: number) => {
  if (value === total) {
    return 1;
  }
  return Math.round((100 * (total - value)) / total);
};

export const mapLimitValueToColor = (
  theme: Theme,
  value: number,
): ColorValue => {
  if (value >= 50) {
    return theme.palette.success;
  }
  if (value < 50 && value >= 30) {
    return theme.palette.attention;
  }
  if (value < 30 && value >= 2) {
    return theme.palette.error;
  }
  return theme.palette.uiAdditional1;
};

export default observer(function WalletLimitProgressBar(
  props: WalletLimitProgressBarProps,
) {
  const {value, total} = props;
  const remainLimit = computeRemainingLimitPercent(
    JSON.parse(total) as number,
    JSON.parse(value) as number,
  );
  const barStyles = useStyles(theme => ({
    background: {
      width: `${remainLimit}%`,
      backgroundColor: mapLimitValueToColor(theme, remainLimit),
    },
  }));

  return (
    <>
      <WalletLimitProgressBarView>
        <WalletLimitProgressBarHandle style={barStyles.background} />
      </WalletLimitProgressBarView>
    </>
  );
});

const WalletLimitProgressBarView = variance(View)(theme => ({
  root: {
    width: '100%',
    height: 4,
    borderRadius: 4,
    backgroundColor: theme.palette.uiSecondary,
    overflow: 'hidden',
  },
}));

const WalletLimitProgressBarHandle = variance(View)(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
  },
}));
