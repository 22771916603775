import {urlLinks, useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {BigNumber} from 'bignumber.js';
import {autorun, runInAction} from 'mobx';
import {useCallback, useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../Navigation/hooks';

// eslint-disable-next-line import-x/prefer-default-export
export function usePromptAccountDeletionBindingState() {
  const root = useRoot();
  const strings = useStrings();

  const handleDelete = useCallback(async () => {
    const res = await root.ncWalletJsonRpcClient.call('accounts.delete', {});

    if (res.success) {
      root.flashMessage.showMessage({
        title: strings['deleteAccount.success'],
        variant: 'success',
      });
      return root.authHelper.signOut({
        local: true,
        reason: 'usePromptAccountDeletionBindingState',
      });
    } else {
      const error = root.errorParser.describe(res.left);
      const url = urlLinks.support;
      root.flashMessage.showMessage({
        title: error.summary,
        link: url,
        variant: 'info',
      });
    }
  }, [root, strings]);

  const handleSupportPress = useCallback(() => {
    const url = urlLinks.support;
    void root.location.open(url);
  }, [root.location]);

  const isUserBalanceEmpty = useIsUserBalanceEmpty();

  return {
    handleDelete,
    handleSupportPress,
    isUserBalanceEmpty,
  };
}

const useIsUserBalanceEmpty = () => {
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  const root = useRoot();
  const [isUserBalanceEmpty, setIsUserBalanceEmpty] = useState(false);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void root.walletStore.refreshWallets();
          });
        }
      }),
    [getIsFocused, getIsReady, root],
  );

  useEffect(
    () =>
      autorun(() => {
        const wallets = root.walletStore.getWallets();
        setIsUserBalanceEmpty(
          !!wallets && wallets.every(w => BigNumber(w.total).isZero()),
        );
      }),
    [root],
  );

  return isUserBalanceEmpty;
};
