import {TransactionFilterKind} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptTransactionKindToFilterHistoryContainer from '../../../CommonNavigationContainers/containers/PromptTransactionKindToFilterHistoryContainer';
import useStackNavigateBack from '../../../CommonNavigationContainers/hooks/useStackNavigateBack';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptTransactionKindToFilterHistoryBindingProps =
  SmallHomeStackBindingProps<'PromptTransactionKindToFilterHistory'>;

export default observer(function PromptTransactionKindToFilterHistoryBinding(
  props: PromptTransactionKindToFilterHistoryBindingProps,
) {
  const {navigation} = props;

  const navigateBack = useStackNavigateBack(navigation);
  const onSelect = useCallback(
    (transactionFilterKind: TransactionFilterKind) => {
      navigateBack('ListHistoryFilters', {
        transactionFilterKind:
          transactionFilterKind === TransactionFilterKind.All
            ? undefined
            : transactionFilterKind,
      });
    },
    [navigateBack],
  );

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.replace('ListHistoryFilters');
  }, [navigation]);

  return (
    <PromptTransactionKindToFilterHistoryContainer
      onSelect={onSelect}
      onBack={onBack}
    />
  );
});
