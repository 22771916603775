import {useStrings, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {AffiliateTabKind} from './AffiliateKind';
import AffiliateProgramAddress from './AffiliateProgramAddress';

export type AffiliateProgramCardProps = {
  onCopy: (data: string) => void;
  onShare: (data: string) => void;
  firstLink: string;
  activeTab: AffiliateTabKind;
  secondLink: string;
  telegramLink: string;
  shareShown?: boolean;
};

export default observer(function AffiliateProgramCard(
  props: AffiliateProgramCardProps,
) {
  const {
    onCopy,
    firstLink,
    secondLink,
    telegramLink,
    shareShown,
    activeTab,
    onShare,
  } = props;
  const strings = useStrings();
  return (
    <Container>
      {activeTab === AffiliateTabKind.Landing && (
        <Cell>
          <CellTitle>{strings['affiliateScreen.tell']}</CellTitle>
          <AffiliateProgramAddress
            appKind={activeTab}
            onCopy={onCopy}
            onShare={onShare}
            shareShown={shareShown}
            info={firstLink}
          />
        </Cell>
      )}
      {activeTab === AffiliateTabKind.App && (
        <Cell>
          <CellTitle>{strings['affiliateScreen.invite']}</CellTitle>
          <AffiliateProgramAddress
            appKind={activeTab}
            onCopy={onCopy}
            onShare={onShare}
            shareShown={shareShown}
            info={secondLink}
          />
        </Cell>
      )}
      {activeTab === AffiliateTabKind.Telegram && (
        <Cell>
          <CellTitle>{strings['affiliateScreen.tell.telegram']}</CellTitle>
          <AffiliateProgramAddress
            appKind={activeTab}
            onCopy={onCopy}
            onShare={onShare}
            shareShown={shareShown}
            info={telegramLink}
          />
        </Cell>
      )}
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: theme.palette.uiSecondary,
  },
}));

const Cell = variance(View)(() => ({
  root: {
    padding: 15,
  },
}));

const CellTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    color: theme.palette.textMain,
  },
}));
