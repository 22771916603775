import {useRoot, variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui';
import {range, round} from 'lodash';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useMemo} from 'react';
import {View} from 'react-native';

import {PinMaskDot} from './PinMaskDot';

export type PinMaskProps = {
  getFilledLength: () => number;
  fullPinLength: number;
  smallDots?: boolean;
};

export const PinMask = observer(function PinMask(props: PinMaskProps) {
  const {windowDimensionsState} = useRoot();
  const marginVertical = expr(() => {
    const {width} = windowDimensionsState.window;
    if (width >= LG_BREAKPOINT) {
      return 20;
    }
    const {height} = windowDimensionsState.window;
    const aspectRatio = round(height / width, 2);
    return aspectRatio < 2 ? 15 : 30;
  });

  const style = useMemo(() => ({marginVertical}), [marginVertical]);

  const filledLength = props.getFilledLength();

  return (
    <Row style={style}>
      {range(0, props.fullPinLength).map(n => (
        <DotWrapper key={n}>
          <PinMaskDot filled={n < filledLength} smallDots={props.smallDots} />
        </DotWrapper>
      ))}
    </Row>
  );
});

const Row = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const DotWrapper = variance(View)(theme => ({
  root: {
    marginRight: 15,
    marginLeft: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginRight: 7,
        marginLeft: 7,
      },
    }),
  },
}));
