import {useStrings, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text} from 'react-native';

export default observer(function EmptySectionTitle() {
  const strings = useStrings();
  return <EmptyText>{strings['sectionList.empty']}</EmptyText>;
});

const EmptyText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    color: theme.palette.uiMain,
    padding: 15,
  },
}));
