import {makeObservable, observable, runInAction} from 'mobx';

import type {AddressNetwork} from '../NCWalletServer/AddressInfo';
import type {Disposer, Service} from '../structure';
import type {Millisecond} from '../Time';
import type {
  BlockchainNetworksSuspensionQuery,
  BlockchainNetworksSuspensionRepository,
} from './BlockchainNetworksSuspensionRepository';
import type {BlockchainNetworksSuspensionState} from './BlockchainNetworksSuspensionState';

export default class BlockchainNetworksSuspensionStateService
  implements BlockchainNetworksSuspensionState, Service
{
  @observable.ref private _query?: BlockchainNetworksSuspensionQuery;

  constructor(
    private readonly _root: {
      readonly blockchainNetworksSuspensionRepository: BlockchainNetworksSuspensionRepository;
    },
  ) {
    makeObservable(this);
  }

  get blockchainNetworksSuspensionQuery() {
    return this._query;
  }

  async suspendBlockchainNetworks(
    walletNetwork: AddressNetwork,
    now: Millisecond,
  ): Promise<void> {
    await this._root.blockchainNetworksSuspensionRepository.suspendBlockChainNetworks(
      walletNetwork,
      now,
    );
    await this._load();
  }

  subscribe(): Disposer | undefined {
    void this._load();
    return undefined;
  }

  private async _load() {
    const query =
      await this._root.blockchainNetworksSuspensionRepository.createBlockChainNetworksQuery();
    runInAction(() => {
      this._query = query;
    });
  }
}
