import type {CurrencyDescription, DecimalString} from '@ncwallet-app/core/src';
import {
  keepMeaningfulDigitsInFiat,
  sized,
  useBalanceCryptoValue,
  useTheme,
  variance,
} from '@ncwallet-app/core/src';
import {CurrencyCircleLogo, TouchableOpacity} from '@ncwallet-app/ui';
import {CopySvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {isNil} from 'lodash';
import type {FC} from 'react';
import React from 'react';
import type {Insets} from 'react-native';
import {Text, View} from 'react-native';

export type WalletInfoProps = {
  walletName: string | undefined;
  cryptoCurrency: CurrencyDescription | undefined;
  cryptoValue: DecimalString | undefined;
  fiatCurrency: CurrencyDescription | undefined;
  fiatValue: DecimalString | undefined;
  address: string | undefined;
  onCopy: () => void;
};

export const WalletInfo: FC<WalletInfoProps> = props => {
  const {
    cryptoCurrency,
    cryptoValue,
    fiatCurrency,
    walletName,
    onCopy,
    address,
  } = props;
  const fiatValue = !isNil(fiatCurrency)
    ? keepMeaningfulDigitsInFiat(props.fiatValue ? props.fiatValue : 0)
    : undefined;

  const formatCrypto = useBalanceCryptoValue(
    cryptoValue || '0',
    cryptoCurrency?.fractionDigits,
  );

  const theme = useTheme();

  return (
    <Container>
      <LeftContainer>
        <CurrencyCircleLogo code={cryptoCurrency?.code || ''} size={40} />
        <CenterContainer>
          <PrimaryText>{walletName}</PrimaryText>
          <Address>
            <SecondaryText address>
              {address?.slice(0, 4)}...{address?.slice(-5)}
            </SecondaryText>
            <TouchableOpacity onPress={onCopy} hitSlop={HIT_SLOP}>
              <CopyIcon color={theme.palette.info} />
            </TouchableOpacity>
          </Address>
        </CenterContainer>
      </LeftContainer>
      <ValueView>
        <PrimaryText>
          {formatCrypto} {cryptoCurrency?.code}
        </PrimaryText>
        <SecondaryText>
          {fiatValue} {fiatCurrency?.code}
        </SecondaryText>
      </ValueView>
    </Container>
  );
};

const HIT_SLOP: Insets = {top: 32, right: 32, bottom: 32, left: 32};

const CopyIcon = sized(CopySvg, 16, 16);

const Container = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
}));

const LeftContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const CenterContainer = variance(View)(() => ({
  root: {
    marginLeft: 15,
  },
}));

const PrimaryText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    fontSize: 15,
    textAlign: 'left',
    color: theme.palette.textPrimary,
  },
}));

const SecondaryText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.textAdditional2,
  },

  address: {
    marginRight: 4,
  },
}));

const Address = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
  },
}));

const ValueView = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));
