import type {WalletSlideData} from '@ncwallet-app/core';
import {formatCryptoValue, useStrings, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {OrangeCircleSvg} from '../../../assets/svg/colored';
import {TouchableListItem} from '../../atoms';

export type CurrencyAllProps = {
  total: WalletSlideData;
  onPress: () => void;
};

export default observer(function CurrencyAll(props: CurrencyAllProps) {
  const strings = useStrings();

  return (
    <Container onPress={props.onPress}>
      <Inner>
        <OrangeCircleSvg />
        <CurrencyData>
          <Title>{strings['historyFilterScreen.selectCoin.all']} </Title>
          <WalletTotal>
            {formatCryptoValue(
              props.total.cryptoValue,
              props.total.getCryptoCurrency()?.fractionDigits,
            )}{' '}
            {props.total.getCryptoCurrency()?.code}
          </WalletTotal>
        </CurrencyData>
      </Inner>
    </Container>
  );
});

const Container = variance(TouchableListItem)(theme => ({
  root: {
    paddingRight: 25,
    paddingLeft: 15,
    borderBottomColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
  },
}));

const Inner = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
  },
}));

const CurrencyData = variance(View)(() => ({
  root: {
    marginLeft: 15,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('bold'),
    fontSize: 16,
  },
}));

const WalletTotal = variance(Text)(theme => ({
  root: {
    marginTop: 5,
    ...theme.fontByWeight('500'),
    fontSize: 12,
    color: theme.palette.textAdditional2,
  },
}));
