import type {AccountPersonalData} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {action, computed, makeObservable, observable} from 'mobx';

import {getOptionalString} from '../AddressFormState/AddressFormState';
import {formatDate, getPersonalFormErrors} from './validatePersonalForm';

// eslint-disable-next-line import-x/prefer-default-export
export class PersonalFormState {
  @observable firstName = '';
  @observable lastName = '';
  @observable birthday = '';
  @observable _isSubmitted = false;

  @computed get errorMap() {
    return this._isSubmitted ? getPersonalFormErrors(this) : undefined;
  }

  @computed get isValid() {
    return !this.errorMap || !Object.values(this.errorMap).some(e => e);
  }

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setFirstName(firstName: string) {
    this._isSubmitted = false;
    this.firstName = firstName;
  }

  @action.bound
  setLastName(lastName: string) {
    this._isSubmitted = false;
    this.lastName = lastName;
  }

  @action.bound
  setBirthday(birthday: string) {
    this._isSubmitted = false;
    this.birthday = formatDate(birthday);
  }

  getValue(): AccountPersonalData {
    return {
      first_name: this.firstName.trim(),
      last_name: this.lastName.trim(),
      birthday: getOptionalString(this.birthday),
    };
  }

  @action.bound
  setInitialValue(data: AccountPersonalData | undefined) {
    if (data) {
      this.firstName = data.first_name;
      this.lastName = data.last_name;
      this.birthday = data.birthday ?? '';
    } else {
      this.reset();
    }
  }

  @action.bound
  reset() {
    this.firstName = '';
    this.lastName = '';
    this.birthday = '';
    this._isSubmitted = false;
  }

  @action.bound
  submit() {
    this._isSubmitted = true;
  }
}
