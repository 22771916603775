import AsyncStorage from '@react-native-async-storage/async-storage';
import {isArray} from 'lodash';
import {makeObservable, observable} from 'mobx';

import type {Disposer} from '../../structure';
import type {Millisecond} from '../../Time';
import type {Log, LogRecord} from '../Log';
import type {LogContent} from '../LogContent';
import EvictingQueue from './EvictingQueue';

// временный лог для поиска причины разлогинивания
export default class SignOutReasonLog implements Log {
  private MAX_LENGTH = 30;

  @observable private _log = new EvictingQueue<LogRecord>(this.MAX_LENGTH);
  private _id = 0;

  private _isInitialized = false;

  get records() {
    return this._log.getContent();
  }

  constructor() {
    makeObservable(this);
  }

  write(content: LogContent) {
    const maxStringSize = 300;
    const record = {
      id: this._id++,
      capturedAt: new Date().getTime() as Millisecond,
      content: {body: content.body.substring(0, maxStringSize)},
    };
    this._updateLog([...this.records, record]);
  }

  reset() {
    this._updateLog([]);
  }

  subscribe(): Disposer | undefined {
    void this._init();
    return undefined;
  }

  private async _init() {
    const recordsFromStorage = await this._getRecordsFromStorage();
    const addedDuringInit = this.records;
    this._isInitialized = true;
    this._updateLog([...recordsFromStorage, ...addedDuringInit]);
  }

  private async _getRecordsFromStorage(): Promise<Array<LogRecord>> {
    try {
      return await this._getStorageContent();
    } catch (e) {
      console.error('SignOutReasonLog._getInitialContent failed', e);
      return [];
    }
  }

  private async _getStorageContent(): Promise<Array<LogRecord>> {
    const jsonValue = await AsyncStorage.getItem('SignOutReasonLog');
    if (!jsonValue) {
      return [];
    }

    const parsed = JSON.stringify(jsonValue);
    return isArray(parsed) ? parsed : [];
  }

  private _updateLog(records: LogRecord[]) {
    if (!this._isInitialized) {
      return;
    }
    this._log = new EvictingQueue<LogRecord>(this.MAX_LENGTH, records);
    void this._syncWithStorage();
  }

  private async _syncWithStorage() {
    if (!this._isInitialized) {
      return;
    }
    try {
      await AsyncStorage.setItem(
        'SignOutReasonLog',
        JSON.stringify(this.records),
      );
    } catch (e) {
      console.error('SignOutReasonLog._syncWithStorage failed', e);
    }
  }
}
