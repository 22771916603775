import {useRoot, useStrings} from '@ncwallet-app/core';
import {setStringAsync} from 'expo-clipboard';
import {useCallback} from 'react';
import {useThrottledCallback} from 'use-debounce';

const DEBOUNCE_INTERVAL = 3000;

// eslint-disable-next-line import-x/prefer-default-export
export const useReceiveScreenActions = (
  onShare: (data: string) => void,
  walletAddress: string,
  copyMessageKey?: string,
) => {
  const strings = useStrings();
  const {flashMessage} = useRoot();
  const copyAddressHandler = useCallback(
    (data: string) => {
      void setStringAsync(data);
      flashMessage.showMessage({
        title: copyMessageKey || strings['qrCodeHistory.onCopyMessage'],
        variant: 'success',
      });
    },
    [flashMessage, strings, copyMessageKey],
  );

  const copyAddressThrottled = useThrottledCallback(
    copyAddressHandler,
    DEBOUNCE_INTERVAL,
    {trailing: false},
  );

  const shareThrottled = useThrottledCallback(
    () => {
      onShare(walletAddress);
    },
    DEBOUNCE_INTERVAL,
    {trailing: false},
  );

  return {copyAddressThrottled, shareThrottled};
};
