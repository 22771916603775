import {useStrings, useStyles, variance} from '@ncwallet-app/core';
import {isEmpty, isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React from 'react';
import {Platform, Text, View} from 'react-native';

import {ArrowRightBigSVG} from '../../assets/svg/colorless';
import {useIsDimensions} from '../../util';
import {Button, ButtonColor, ButtonIconPosition, ButtonVariant} from '../atoms';
import {LG_BREAKPOINT} from '../grid';
import type {SafeAreaScrollViewProps} from './SafeAreaLayout';
import {SafeAreaScrollView} from './SafeAreaLayout';

export type SendCryptoModalScreenProps = SafeAreaScrollViewProps & {
  stepCurrent?: number;
  stepTotal?: number;
  error?: string;
  onSubmit: () => void;
  isEditable?: boolean;
  navigateButtonText?: string;
  disabled?: boolean;
  getHasNoCommission?: () => boolean;
};

export const SendCryptoModal = observer(
  (props: PropsWithChildren<SendCryptoModalScreenProps>) => {
    const {
      stepCurrent,
      stepTotal,
      onSubmit,
      children,
      error,
      disabled,
      isEditable,
      getHasNoCommission,
      ...rest
    } = props;
    const strings = useStrings();
    const styles = useLgStyles();
    const isLg = useIsDimensions('lg');
    const navigateButtonText =
      props.navigateButtonText || strings['sendCrypto.nextButton'];

    return (
      <SafeAreaScrollView
        contentContainerStyle={styles.container}
        style={styles.scroll}
        keyboardShouldPersistTaps="handled"
        {...rest}>
        {children}
        <SendCryptoBottomContainer>
          <ErrorContainer>
            {error?.split(' ').map((err, index: number) => (
              <ErrorText offsetRight key={index}>
                {err}
              </ErrorText>
            ))}
          </ErrorContainer>

          {!isEditable && !isLg && !isNil(stepCurrent) && !isNil(stepTotal) && (
            <CurrentStep>
              {`${strings['sendCrypto.step']} ${stepCurrent}/${stepTotal}`}
            </CurrentStep>
          )}
          {getHasNoCommission?.() && (
            <TextNoCommision>
              {strings['Send.Step.Message.NoCommission']}
            </TextNoCommision>
          )}
          <SubmitButton
            title={navigateButtonText}
            disabled={!isEmpty(error) || disabled}
            onPress={onSubmit}
            variant={ButtonVariant.Highlighted}
            color={ButtonColor.Secondary}
            iconPosition={ButtonIconPosition.Right}
            Icon={ArrowRightBigSVG}
            testID="commission-submit-btn"
          />
        </SendCryptoBottomContainer>
      </SafeAreaScrollView>
    );
  },
);

const useLgStyles = () =>
  useStyles(theme => ({
    scroll: {
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          backgroundColor: theme.palette.uiPrimary,
        },
      }),
    },
    container: {
      paddingTop: 15,
      paddingHorizontal: 15,
      borderRadius: 20,
      ...Platform.select({
        web: {
          paddingBottom: 15,
        },
      }),
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          paddingTop: 30,
          paddingHorizontal: 30,
          paddingBottom: 30,
          borderRadius: 8,
          backgroundColor: theme.palette.background,
        },
      }),
    },
  }));

const SubmitButton = variance(Button)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 345,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
}));

const SendCryptoBottomContainer = variance(View)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const CurrentStep = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
    marginVertical: 20,
    textAlign: 'center',
  },
}));
const TextNoCommision = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional2,
    marginBottom: 20,
    textAlign: 'center',
  },
}));
export const ErrorText = variance(Text)(theme => ({
  root: {
    height: 20,
    ...theme.fontByWeight('500'),
    fontSize: 13,
    marginTop: 'auto',
    color: theme.palette.error,
    textAlign: 'center',
  },
  offsetRight: {
    marginRight: 5,
  },
}));

const ErrorContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    maxWidth: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}));
