import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import type {Opaque} from '../Opaque';
import type {RouterSource} from '../structure';

export interface Connection {
  readonly status: RouterSource<ConnectionStatusRouterMap>;
  connect(): Promise<Either<void, GlobalError>>;
  disconnect(): Promise<Either<void, GlobalError>>;
  reconnect(): Promise<Either<void, GlobalError>>;
  getStatus(): ConnectionStatus;
  getId(): ConnectionId;
}

export interface ConnectionState {
  readonly id: ConnectionId;
  readonly latestStatus: ConnectionStatus;
  readonly latestError: GlobalError | undefined;
}

export enum ConnectionStatus {
  Connecting = 0,
  Open = 1,
  Closing = 2,
  Closed = 3,
}

export type ConnectionStatusRouterMap = {
  [ConnectionStatus.Connecting]: () => void;
  [ConnectionStatus.Open]: () => void;
  [ConnectionStatus.Closing]: () => void;
  [ConnectionStatus.Closed]: (report: ConnectionCloseReport) => void;
};

export type ConnectionCloseReport = {
  wasClean: boolean;
  code?: number;
  reason?: string;
};

export const CONNECTION_ID = Symbol();
export type ConnectionId = Opaque<number, typeof CONNECTION_ID>;
