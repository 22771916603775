import {useDateFormatting, variance} from '@ncwallet-app/core';
import {Button, ButtonColor, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Linking, Platform, Text, View} from 'react-native';

import type {NewsItemData} from './NewsList';

export type NewsItemProps = Omit<NewsItemData, 'id' | 'isVisible'>;

export default observer(function NewsItem({
  title,
  date,
  description,
  url,
}: NewsItemProps) {
  const {formatDateTimeWithoutYear} = useDateFormatting();
  const handleNewsPress = useCallback(() => {
    if (Platform.OS === 'web') {
      window.open(url);
    } else {
      void Linking.openURL(url);
    }
  }, [url]);

  return (
    <Container>
      <DateText>{formatDateTimeWithoutYear(date)}</DateText>
      <Title
        title={title}
        variant={ButtonVariant.Text}
        color={ButtonColor.Default}
        onPress={handleNewsPress}
      />
      <Description numberOfLines={4}>{description}</Description>
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    marginTop: 20,
    marginHorizontal: 15,
    borderBottomColor: theme.palette.uiPrimary,
    borderBottomWidth: 1,
    paddingBottom: 20,
  },
}));

const DateText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 11,
    lineHeight: 13,
    marginBottom: 5,
    color: theme.palette.textAdditional2,
  },
}));

const Title = variance(Button)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    justifyContent: 'flex-start',
    padding: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
    lineHeight: 22,
    marginBottom: 5,
    color: theme.palette.textPrimary,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 20,
    color: theme.palette.textAdditional1,
  },
}));
