import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {TwoFaWelcomeScreen} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type TwoFaWelcomeBindingProps =
  SmallHomeStackBindingProps<'TwoFaWelcome'>;

export default observer(function TwoFaWelcomeBinding(
  props: TwoFaWelcomeBindingProps,
) {
  const {navigation, route} = props;

  const handleTwoFaEnable = useCallback(() => {
    navigation.navigate('PromptSelectTwoFaProvider', route.params);
  }, [navigation, route.params]);

  return <TwoFaWelcomeScreen onTwoFaEnable={handleTwoFaEnable} />;
});
