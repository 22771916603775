import type {SafeAreaFlatListProps} from '@ncwallet-app/ui';
import {SafeAreaFlatList} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import type {HistorySessionInfo} from './HistorySessionInfo';
import SessionsHistoryItem from './SessionHistoryItem';
import SessionHistorySkeletonList from './SessionHistorySkeletonList';

export type SessionsHistoryListProps<ItemT = HistorySessionInfo> = Partial<
  SafeAreaFlatListProps<ItemT>
> & {
  data: HistorySessionInfo[] | undefined;
  onEndReached: () => void;
};
const keyExtractor = (entry: HistorySessionInfo) => entry.id;

export const SessionsHistoryList = observer(
  (props: SessionsHistoryListProps) => {
    const {data, onEndReached, ...rest} = props;
    return (
      <SafeAreaFlatList
        ListEmptyComponent={<SessionHistorySkeletonList />}
        renderItem={({item}) => <SessionsHistoryItem data={item} />}
        keyExtractor={keyExtractor}
        enabledWebBottomOffset
        data={data}
        onEndReached={onEndReached}
        {...rest}
      />
    );
  },
);
