import {useStrings, variance} from '@ncwallet-app/core';
import {BottomSheet} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import type {InstallTelegramModalProps} from './InstallTelegramBody';
import InstallTelegramBody from './InstallTelegramBody';

export type InstallTelegramScreenProps = InstallTelegramModalProps;

export default observer(function InstallTelegramScreen(
  props: InstallTelegramScreenProps,
) {
  const {bottom} = useSafeAreaInsets();
  const strings = useStrings();
  const {onClose, ...rest} = props;
  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        onClose();
      }
    },
    [onClose],
  );
  return (
    <BottomSheet
      index={0}
      onChange={onChange}
      animateOnMount
      enableDynamicSizing
      enablePanDownToClose
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      onClose={onClose}
      enableAutoHeight
      children={
        <BodyWrapper style={{paddingBottom: bottom}}>
          <InstallTelegramBody onClose={onClose} {...rest} />
        </BodyWrapper>
      }
      title={strings['suggestApp.modalTitle']}
    />
  );
});
const BodyWrapper = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));
