import {useMemo} from 'react';

import useRoot from '../../Root/hooks/useRoot';
import {UserStatusError} from '../types';

export default function useIsSuspendedOrBanned() {
  const {userStatusErrorDetector} = useRoot();

  return useMemo((): boolean => {
    switch (userStatusErrorDetector.userStatusError) {
      case UserStatusError.Suspended:
      case UserStatusError.Banned:
        return true;
      default:
        return false;
    }
  }, [userStatusErrorDetector.userStatusError]);
}
