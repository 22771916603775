import type {CurrencyDescription} from '@ncwallet-app/core';
import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonColor, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {SendScreenUserForm} from './SendScreenUserForm';
import {BottomContainer, ErrorTitle} from './SharedComponents';

export type UserTabViewProps = {
  userTo: string;
  cryptoCurrency: CurrencyDescription | undefined;
  onUserActionPress: () => void;
  onUserToChange: (userTo: string) => void;
  onSubmit: () => void;
  isEditable?: boolean;
  stepCurrent: number;
  stepTotal: number;
  error?: string;
};

export const UserTabView = observer((props: UserTabViewProps) => {
  const strings = useStrings();
  return (
    <Root>
      <SendScreenUserForm
        showKeyboard={false}
        value={props.userTo}
        onFocus={props.onUserActionPress}
        onInputChange={props.onUserToChange}
        error={!!props.error}
      />
      <BottomContainer>
        <ErrorTitle>{props.error}</ErrorTitle>
        {!props.isEditable && (
          <CurrentStep>{`${strings['sendCrypto.step']} ${props.stepCurrent}/${props.stepTotal}`}</CurrentStep>
        )}
        <SubmitButton
          title={strings['sendCrypto.confirm.submit']}
          variant={ButtonVariant.Highlighted}
          color={ButtonColor.Secondary}
          disabled={false}
          onPress={props.onSubmit}
        />
      </BottomContainer>
    </Root>
  );
});

const Root = variance(View)(() => ({
  root: {
    flex: 1,
    marginTop: 30,
  },
}));

const SubmitButton = variance(Button)(() => ({
  root: {
    marginTop: 'auto',
    marginHorizontal: 15,
  },
}));

const CurrentStep = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 20,
  },
}));
