import {useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';

import {useAddIpWithOtp} from '../../../CommonNavigationContainers/hooks/ipSettings/useAddIpWithOtp';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {useSendTelegramOtp} from '../../../Navigation/hooks/useSendTelegramOtp';
import {
  CARD,
  SubmitOtpScreen,
} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {createResetToIpListAction} from '../util/createResetToIpListAction';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptOtpToAddIpBindingProps =
  SmallHomeStackBindingProps<'PromptOtpToAddIp'>;

export default observer(function PromptOtpToAddIpBinding(
  props: PromptOtpToAddIpBindingProps,
) {
  const {
    navigation,
    route: {params},
  } = props;
  const root = useRoot();
  const strings = useStrings();
  const resetToIpList = useCallback(() => {
    const navigateTo =
      params.type === IpType.Allowed ? 'ListAllowedIp' : 'ListBlockedIp';
    if (hasRouteInHistory(navigation.getState(), navigateTo)) {
      navigation.navigate(navigateTo);
    } else {
      navigation.dispatch(createResetToIpListAction(params.type));
    }
  }, [navigation, params.type]);
  const {getErrorText, addWithOtp, resetErrorText} =
    useAddIpWithOtp(resetToIpList);
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();

  const handleSubmit = useCallback(
    (code: string) => addWithOtp(params.ip, params.name, params.type, code),
    [addWithOtp, params.ip, params.name, params.type],
  );

  const handleTwoFaResendCode = useCallback(async () => {
    try {
      await sendOtp(null, {forceChannel: false});
    } catch {
      /* empty */
    }
  }, [sendOtp]);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (getIsReady() && getIsFocused()) {
      if (
        root.twoFaSettingsState.currentTwoFaProvider ===
        TwoFaProviderKind.Telegram
      ) {
        void handleTwoFaResendCode();
      }
    }
  }, [getIsReady, getIsFocused, root, handleTwoFaResendCode]);

  return (
    <SubmitOtpScreen
      layout={CARD}
      resetError={resetErrorText}
      getError={getErrorText}
      titleText={strings['promptOtpToUpdateIpList.title']}
      submitText={strings['promptOtpToUpdateIpList.submit']}
      onSubmit={handleSubmit}
      appVersion={root.deviceInfo.getAppVersionWithBuildNumber()}
      twoFaProvider={root.twoFaSettingsState.currentTwoFaProvider}
      onTwoFaResendCode={handleTwoFaResendCode}
      resendCodeInterval={interval}
      resetInterval={resetInterval}
    />
  );
});
