import type {AuthHelper} from '../Auth';
import type {
  AppleOAuth2Provider,
  GoogleOAuth2Provider,
} from '../OAuth2Provider';
import {SUCCESS} from '../OAuth2Provider';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';

export default class OAuth2ConsumerService implements Service {
  constructor(
    private readonly _root: {
      readonly appleOAuth2Provider: AppleOAuth2Provider;
      readonly googleOAuth2Provider?: GoogleOAuth2Provider;
      readonly authHelper: AuthHelper;
    },
  ) {}

  subscribe() {
    return batchDisposers(
      this._root.appleOAuth2Provider.outcome.listen(SUCCESS, async _ => {
        await this._root.authHelper.preSignIn({
          provider: 'apple',
          token: _.idToken,
        });
      }),
      this._root.googleOAuth2Provider?.outcome.listen(SUCCESS, _ => {
        if ('idToken' in _) {
          return this._root.authHelper.preSignIn({
            provider: 'google',
            token: _.idToken,
          });
        } else {
          return this._root.authHelper.preSignIn({
            provider: 'google_access_token',
            token: _.accessToken,
          });
        }
      }),
    );
  }
}
