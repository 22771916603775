import type {Resettable} from '../OnLogoutResetService';
import type {RouterSource} from '../structure';

export interface RetryStrategy extends Resettable {
  readonly state: RouterSource<RetryStrategyStateMap>;
  start(): void;
  stop(): void;
}

export const RETRY_STRATEGY_IDLE = Symbol();
export const RETRY_STRATEGY_PENDING = Symbol();
export type RetryStrategyStatus =
  | typeof RETRY_STRATEGY_IDLE
  | typeof RETRY_STRATEGY_PENDING;

export type RetryStrategyStateMap = {
  [RETRY_STRATEGY_IDLE]: (result: RetryStrategyResult) => void;
  [RETRY_STRATEGY_PENDING]: () => void;
};

export interface RetryStrategyState {
  readonly latestStatus: RetryStrategyStatus;
  readonly latestResult: RetryStrategyResult | undefined;
  readonly previousResult: RetryStrategyResult | undefined;
}

export const RETRY_STRATEGY_SUCCEEDED = Symbol();
export const RETRY_STRATEGY_CANCELED = Symbol();
export const RETRY_STRATEGY_GAVE_UP = Symbol();
export type RetryStrategyResult =
  | typeof RETRY_STRATEGY_SUCCEEDED
  | typeof RETRY_STRATEGY_CANCELED
  | typeof RETRY_STRATEGY_GAVE_UP;

export interface AttemptProvider {
  /**
   * @throws {never}
   */
  attempt(): Promise<AttemptResult>;
}

export const ATTEMPT_RESULT_TRY_AGAIN = Symbol();
export const ATTEMPT_RESULT_DONE = Symbol();

export type AttemptResult =
  | typeof ATTEMPT_RESULT_TRY_AGAIN
  | typeof ATTEMPT_RESULT_DONE;
