import {
  cacheDirectory,
  EncodingType,
  writeAsStringAsync,
} from 'expo-file-system';
import {shareAsync} from 'expo-sharing';

import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import {error, success} from '../fp';
import BaseFileSystemImpl from './BaseFileSystemImpl';
import type {FileSystem} from './FileSystem';

export default class IosFileSystemImpl
  extends BaseFileSystemImpl
  implements FileSystem
{
  async save(
    content: string,
    filename: string,
    mimeType: string,
  ): Promise<Either<void, GlobalError>> {
    try {
      if (!cacheDirectory) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('No cache directory available');
      }
      const uri = cacheDirectory + `/${filename}`;
      await writeAsStringAsync(uri, content, {encoding: EncodingType.UTF8});
      await shareAsync(uri, {mimeType});
      return success(undefined);
    } catch (raw) {
      return error(this._createUnknownError(raw));
    }
  }
}
