import type {LastBlockChianNetworkSuggestionTs} from '../KeyValueStore';
import type {OptionalGetter} from '../mobx-toolbox';
import {expr, use} from '../mobx-toolbox';
import type {AddressNetwork} from '../NCWalletServer/AddressInfo';
import type {Millisecond} from '../Time';
import type {BlockchainNetworksSuspensionQuery} from './BlockchainNetworksSuspensionRepository';

export default class BlockchainNetworksSuspensionQueryImpl
  implements BlockchainNetworksSuspensionQuery
{
  constructor(private readonly _record: LastBlockChianNetworkSuggestionTs) {}

  areSuspended(
    walletNetwork: AddressNetwork,
    $now: OptionalGetter<Millisecond>,
  ): boolean {
    const network = this._record[walletNetwork];

    if (!network) {
      return false;
    }

    const suspendedTs = network.ts;

    if (suspendedTs === undefined) {
      return false;
    }

    return expr(() => use($now) < suspendedTs + DAY_TIMEOUT);
  }
}

const DAY_TIMEOUT = 24 * 60 * 60 * 1000;
