import type {Base32, OtpSecret} from '@ncwallet-app/core/';
import {useRoot} from '@ncwallet-app/core/';
import type {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {runInAction} from 'mobx';
import {useCallback} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useSendTelegramOtp = () => {
  const root = useRoot();

  const sendOtp = useCallback(
    async (
      currentTwoFaProvider: TwoFaProviderKind | null,
      options: {forceChannel?: boolean},
      secret?: Base32<OtpSecret>,
    ) => {
      runInAction(() => {
        void root.otp.requestOptToTelegram(
          currentTwoFaProvider,
          options,
          secret,
        );
      });
    },
    [root],
  );

  const sendRestOtp = useCallback(async () => {
    await root.otp.requestOptToTelegramViaRest();
  }, [root]);

  const resetInterval = useCallback(() => {
    root.otp.telegramOtpInterval = 0;
  }, [root]);

  return {
    interval: root.otp.telegramOtpInterval,
    resetInterval,
    sendOtp,
    sendRestOtp,
  };
};
