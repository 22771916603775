import type {CryptoCurrencyCode, DecimalString} from '@ncwallet-app/core';
import {getDefaultInOutCurrency, useRoot} from '@ncwallet-app/core';
import {
  AddressRedirectError,
  shouldStoreAddressOnError,
  useGetAddressErrorText,
} from '@ncwallet-app/core/src/AddressUriHelper';
import type {
  PromptAmountToSendRoute,
  PromptOutputAddressRoute,
  RouteTransition,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {getDefaultAddressParams} from '@ncwallet-app/core/src/NCWalletServer/InOutCurrency';
import {setStringAsync} from 'expo-clipboard';
import {useCallback, useRef} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useNavigateToSendByQr = (
  promptOutputAddress: RouteTransition<PromptOutputAddressRoute>,
  promptAmountToSend: RouteTransition<PromptAmountToSendRoute>,
  onError?: () => void,
) => {
  const root = useRoot();
  const getAddressErrorText = useGetAddressErrorText();
  const processRef = useRef(false);
  const handleError = useCallback(
    (address: string, errorCode: AddressRedirectError) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const errorText = getAddressErrorText(errorCode)!;
      root.flashMessage.showMessage({
        title: errorText,
        variant:
          errorCode === AddressRedirectError.NoCryptoCode
            ? 'success'
            : 'danger',
        timeout: 6000,
      });
      if (shouldStoreAddressOnError(errorCode)) {
        void setStringAsync(address);
      }
      onError?.();
    },
    [getAddressErrorText, onError, root],
  );

  return useCallback(
    async (
      address: string,
      code?: CryptoCurrencyCode,
      amount?: DecimalString,
      addressNetwork?: AddressNetwork,
    ) => {
      if (processRef.current) {
        return;
      }
      processRef.current = true;
      const [cryptoRes, walletRes] = await Promise.all([
        root.addressUriHelper.getCrypto(code),
        root.addressUriHelper.getWallet(address, code),
      ]);
      if (!cryptoRes.success) {
        handleError(address, cryptoRes.left);
        return;
      }
      if (!walletRes.success) {
        handleError(address, walletRes.left);
        return;
      }

      const wallet = walletRes.right;
      const currencyOut = getDefaultInOutCurrency(cryptoRes.right, 'out');
      const defaultAddressNetwork =
        getDefaultAddressParams(currencyOut).addressNetwork;

      promptAmountToSend({
        ...getDefaultAddressParams(currencyOut),
        addressNetwork: addressNetwork ?? defaultAddressNetwork,
        amount,
        walletId: wallet.id,
        addressTo: address,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        currency: code!,
      });

      processRef.current = false;
    },
    [root, handleError, promptAmountToSend],
  );
};
