import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import type {Tagged} from '../Opaque';
import type {Base32} from '../Rfc4648';
import type {Second} from '../Time';
import type {TwoFaProviderKind} from '../TwoFaHelper';
import type {Uri} from '../units';

export interface Otp {
  generateSecret(): Promise<Either<Base32<OtpSecret>, GlobalError>>;
  formAuthenticatorLink(type: typeof HOTP, params: HotpLinkParams): Uri;
  formAuthenticatorLink(type: typeof TOTP, params: TotpLinkParams): Uri;
  telegramOtpInterval: number;
  requestOptToTelegram(
    currentTwoFaProvider: TwoFaProviderKind | null,
    options: {forceChannel?: boolean},
    secret?: Base32<OtpSecret>,
  ): Promise<void>;
  requestOptToTelegramViaRest(): Promise<void>;
}

export const OTP_SECRET = Symbol();
export type OtpSecret = Tagged<ArrayBuffer, typeof OTP_SECRET>;

export const HOTP = Symbol();
export const TOTP = Symbol();
export type OtpType = typeof HOTP | typeof TOTP;

export type BaseOtpLinkParams = {
  accountName: string;
  issuer: string;
  secret: Base32<OtpSecret>;
  algorithm?: OtpAlgorithm;
  digits?: 6 | 8;
};

export const SHA1 = Symbol();
export const SHA256 = Symbol();
export const SHA512 = Symbol();
export type OtpAlgorithm = typeof SHA1 | typeof SHA256 | typeof SHA512;

export type TotpLinkParams = {
  counter?: number;
  period?: Second;
} & BaseOtpLinkParams;

export type HotpLinkParams = {
  counter: number;
  period: Second;
} & BaseOtpLinkParams;
