/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {WalletSlideData} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef} from 'react';
import type {FlatListProps, ListRenderItem} from 'react-native';
import {StyleSheet} from 'react-native';
import type {FlatList} from 'react-native-gesture-handler';

import {Separator} from '../../atoms';
import type {SafeAreaFlatListProps} from '../SafeAreaLayout';
import {SafeAreaFlatList} from '../SafeAreaLayout';
import CurrencyAll from './CurrencyAll';
import {CurrencyListItem} from './CurrencyListItem';
import type {CurrencySelectionListItemData} from './CurrencySelectionListItemData';

export type SmCurrencySelectionListProps<
  ItemT = CurrencySelectionListItemData,
> = Partial<SafeAreaFlatListProps<ItemT>> & {
  totalShownCurrencies?: WalletSlideData | undefined;
  onAllPress?: () => void;
  getData?: () => ItemT[] | undefined;
  onCurrencyPress: (currency: ItemT) => void;
  CustomSeparator?: React.ComponentType;
  showValueAsRate?: boolean;
};

export const SmCurrencySelectionList = observer(
  (props: SmCurrencySelectionListProps) => {
    const {
      onCurrencyPress,
      CustomSeparator = Separator,
      showValueAsRate,
      ...rest
    } = props;
    const data = props.getData?.() ?? props.data;
    const listRef = useRef<FlatList>(null);
    useEffect(() => {
      listRef.current?.scrollToIndex({index: 0}); // Reset list scroll position see NCW-1729 p.1
    });

    const renderItem: ListRenderItem<CurrencySelectionListItemData> =
      useCallback(
        ({item, index}) => (
          <CurrencyListItem
            showValueAsRate={showValueAsRate}
            data={item}
            onPress={() => {
              onCurrencyPress(item);
            }}
            testID={`${index + 1}-coin-item`}
          />
        ),
        [onCurrencyPress, showValueAsRate],
      );

    const renderCurrencyAllItem = useCallback(() => {
      return props.totalShownCurrencies ? (
        <CurrencyAll
          total={props.totalShownCurrencies}
          onPress={props.onAllPress!}
        />
      ) : null;
    }, [props.totalShownCurrencies, props.onAllPress]);

    return (
      <SafeAreaFlatList
        ref={listRef}
        style={styles.root}
        contentContainerStyle={styles.container}
        data={data}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ItemSeparatorComponent={CustomSeparator}
        ListHeaderComponent={renderCurrencyAllItem}
        {...rest}
      />
    );
  },
);

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    flexGrow: 1,
  },
});

type ListProps = FlatListProps<CurrencySelectionListItemData>;

const keyExtractor: ListProps['keyExtractor'] = item => item.id;
