import type {Url} from '@ncwallet-app/core';
import {urlLinks, useRoot, variance} from '@ncwallet-app/core';
import {
  useStateErrorColor,
  useStateErrorIcon,
  useStateErrorText,
} from '@ncwallet-app/core/src/UserStatusErrorDetector';
import type {NavigationProp, ParamListBase} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Text, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';

import {HelpSvg} from '../../../assets/svg/colorless';

export type UserStateErrorLgProps = {
  navigation: NavigationProp<ParamListBase>;
};

export default observer(function UserStateErrorLg({
  navigation,
}: UserStateErrorLgProps) {
  const {location} = useRoot();
  const stateErrorIcon = useStateErrorIcon();
  const stateErrorText = useStateErrorText();
  const stateErrorColor = useStateErrorColor();
  const rootStyle = useMemo(
    () => (stateErrorColor ? {backgroundColor: stateErrorColor} : {}),
    [stateErrorColor],
  );
  const onPress = useCallback(() => {
    navigation.navigate('ShowProfile');
  }, [navigation]);

  const onKYCHelpPress = useCallback(() => {
    void location.open(urlLinks.kycFaq as Url);
  }, [location]);

  return (
    <Root style={rootStyle}>
      <TouchableOpacityStyled activeOpacity={0.8} onPress={onPress}>
        <Container>
          <MainBlock>
            {stateErrorIcon}
            <TextStyled>{stateErrorText && stateErrorText}</TextStyled>
            <TouchableOpacity onPress={onKYCHelpPress}>
              <HelpIcon />
            </TouchableOpacity>
          </MainBlock>
        </Container>
      </TouchableOpacityStyled>
    </Root>
  );
});

const Root = variance(View)(theme => ({
  root: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const TouchableOpacityStyled = variance(TouchableOpacity)(
  () => ({root: {}}),
  () => ({
    containerStyle: {
      width: '100%',
    },
  }),
);

const Container = variance(View)(() => ({
  root: {
    width: '100%',
    alignItems: 'center',
  },
}));

const MainBlock = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 9,
    gap: 10,
  },
}));

const TextStyled = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    fontSize: 14,
    color: theme.palette.whitey,
  },
}));

const HelpIcon = variance(HelpSvg)(
  () => ({
    root: {},
  }),
  () => ({
    color: 'rgba(255, 255, 255, 0.5)',
    width: 16,
    height: 16,
  }),
);
