import {useStrings, variance} from '@ncwallet-app/core';
import type {PromptSessionDeletionRoute} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {SessionId} from '@ncwallet-app/core/src/NCWalletServer/sessions/Session';
import {Button, ButtonVariant, Pressable} from '@ncwallet-app/ui/';
import {ArrowLeftSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useCallback} from 'react';
import {Text, View} from 'react-native';

import type {SessionInfo} from '../../ActiveSessionsScreen/SessionInfo';
import {
  BodyTag,
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import SessionActiveListItem from './SessionActiveListItem';
import type {BaseSessionMenuProps} from './SessionsHolder';
import SessionsList from './SessionsList';

export type SessionsActiveListProps = {
  getCurrentSession: () => SessionInfo[] | undefined;
  getOtherSessions: () => SessionInfo[] | undefined;
  deleteSession: (sessionId: SessionId) => void;
  selectedSessionId?: SessionId;
  goToListActiveSessions: () => void;
  promptConfirmationToEndSession: (
    params: PromptSessionDeletionRoute['params'],
  ) => void;
} & BaseSessionMenuProps;

export default observer(function SessionsActiveList(
  props: SessionsActiveListProps,
) {
  const {
    getCurrentSession,
    getOtherSessions,
    onBack,
    deleteSession,
    selectedSessionId,
    goToListActiveSessions,
    promptConfirmationToEndSession,
  } = props;
  const strings = useStrings();

  const otherSessionsLength = expr(() => getOtherSessions()?.length);
  const selectedSession = expr(() =>
    selectedSessionId === undefined
      ? undefined
      : getOtherSessions()?.find(_ => _.id === selectedSessionId),
  );

  const onSessionPress = useCallback(
    (sessionId: SessionId) => {
      promptConfirmationToEndSession({sessionId});
    },
    [promptConfirmationToEndSession],
  );

  const handleSessionDelete = useCallback(() => {
    if (selectedSessionId === undefined) {
      return;
    }
    deleteSession(selectedSessionId);
    goToListActiveSessions();
  }, [deleteSession, goToListActiveSessions, selectedSessionId]);

  if (selectedSession) {
    return (
      <LgProfileCardContainer>
        <HeaderTag>
          <HeaderTitleTag>
            {strings['secureSettingsScreen.activeSessions.endSession']}
          </HeaderTitleTag>
        </HeaderTag>
        <BodyTag>
          <SessionActiveListItem
            session={selectedSession}
            isHighlighted
            isSingle
          />
          <ButtonGroup>
            <Button
              title={
                strings['secureSettingsScreen.activeSessions.deleteButton']
              }
              onPress={handleSessionDelete}
              variant={ButtonVariant.Danger}
            />
            <SizedBox />
            <Button
              title={
                strings['secureSettingsScreen.activeSessions.cancelButton']
              }
              onPress={goToListActiveSessions}
              variant={ButtonVariant.PrimaryLight}
            />
          </ButtonGroup>
        </BodyTag>
      </LgProfileCardContainer>
    );
  }

  return (
    <LgProfileCardContainer>
      <HeaderTag>
        <Clickable onPress={onBack}>
          <ClickableIcon />
          <HeaderTitleTag>
            {strings['secureSettingsScreen.active']}
          </HeaderTitleTag>
        </Clickable>
      </HeaderTag>
      <BodyTag>
        <ListTitle>
          {strings['secureSettingsScreen.activeSessions.current']}
        </ListTitle>
        <SessionsList
          getSessions={getCurrentSession}
          RenderItemComponent={SessionActiveListItem}
          preventShrink
        />
        <SizedBox />
        {otherSessionsLength ? (
          <>
            <ListTitle>
              {strings['secureSettingsScreen.activeSessions.others']}
            </ListTitle>
            <SessionsList
              getSessions={getOtherSessions}
              onSessionPress={onSessionPress}
              RenderItemComponent={SessionActiveListItem}
            />
          </>
        ) : null}
      </BodyTag>
    </LgProfileCardContainer>
  );
});

const ListTitle = variance(Text)(theme => ({
  root: {
    marginBottom: 15,
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
  },
}));

const Clickable = variance(Pressable)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const ClickableIcon = variance(ArrowLeftSvg)(theme => ({
  root: {
    marginRight: 10,
    color: theme.palette.uiMain,
  },
}));

const ButtonGroup = variance(View)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));
