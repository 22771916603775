import {range} from 'lodash';
import {nanoid} from 'nanoid/non-secure';
import type {ComponentType, FC} from 'react';
import React, {useMemo} from 'react';
import type {SvgProps} from 'react-native-svg';

export default function withUniqueGradientId(
  Icon: ComponentType<SvgProps>,
): FC<SvgProps> {
  return props => {
    const gradientIds = useMemo(getGradientIds, []);
    return <Icon gradient-ids={gradientIds} {...props} />;
  };
}

const getGradientIds = () => {
  // should be same as numberOfIds in svgr.config.js:4
  const numberOfIds = 15;
  const id = nanoid();

  return range(0, numberOfIds).map<{[key: string]: string}>((acc, n) => {
    const currentId = `${id}-${n}`;
    return {id: currentId, url: `url(#${currentId})`};
  });
};
