import type {
  CryptoAddress,
  CryptoCurrencyCode,
  CurrenciesRateHistoryStore,
  CurrenciesRateStore,
  CurrencyDescription,
  CurrencyStore,
  DecimalString,
  FiatCurrencyCode,
  JsonRpcClient,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  OnTimeoutError,
  Wallet,
  WalletId,
  WalletStore,
} from '@ncwallet-app/core';
import {
  getMaxAmountForWithdrawal,
  isFreeWithdrawalAvailable,
  isMinFeeMoreThanAvailableMax,
  isNetworkChangePossible,
  isNetworkShown,
  maxAmountRestrictedByLimit,
  MoneyStatic,
  TIMEOUT_ERROR,
  toCurrencyDescriptionFromCrypto,
  toCurrencyDescriptionFromFiat,
} from '@ncwallet-app/core';
import type {
  SendAddressValidator,
  SendAddressValidatorError,
} from '@ncwallet-app/core/src/AddressUriHelper';
import {unwrap} from '@ncwallet-app/core/src/EitherAdapter';
import type {FlashMessage} from '@ncwallet-app/core/src/FlashMessage';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import type {WalletsWithdrawalsEstimatedFeeOptions} from '@ncwallet-app/core/src/NCWalletServer/WalletsWithdrawalsEstimatedFee';
import {getMinFee} from '@ncwallet-app/core/src/NCWalletServer/WalletsWithdrawalsEstimatedFee';
import type {SentryLog} from '@ncwallet-app/core/src/SentryLog';
import createJsonRpcTimeoutErrorMessage from '@ncwallet-app/core/src/SentryLog/createJsonRpcTimeoutErrorMessage';
import {BigNumber} from 'bignumber.js';
import {debounce, isNil} from 'lodash';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {SendCommissionBindingStateStatus} from '../../../Navigation/HomeStack/SendCommision/SendCommissionBindingState';
import type {WalletLimitHelper} from '../usePromptExchangeReceiptContainer/WalletLimitHelper';
import type {
  SendAmountValidator,
  SendAmountValidatorError,
} from './SendAmountValidator';

const DEBOUNCE_TIMEOUT_MS = 500;

// eslint-disable-next-line import-x/prefer-default-export
export class PromptOutputAddressBindingState implements OnTimeoutError {
  @observable private _walletId: WalletId | undefined;
  @observable private _baseFiat: FiatCurrencyCode | undefined;
  @observable private _prevFindFeeAddress = '';
  @observable private _addressNetwork: AddressNetwork | undefined;
  @observable private _addressCurrency: CryptoCurrencyCode | undefined;
  @observable private _addressTo: CryptoAddress | undefined;
  @observable private _status = SendCommissionBindingStateStatus.Load;
  @observable private _minFreeWithdrawAmountFromParams: string | undefined;
  @observable fees: DecimalString[] | undefined;
  @observable amount: DecimalString | undefined;
  @observable addressTo = '';
  @observable comment: string | undefined;
  @observable amountError: SendAmountValidatorError | undefined;
  @observable addressToError: SendAddressValidatorError | undefined;
  @observable freeSendAvailableForCrypto = false;
  @observable isEnoughAmountForFreeSend = true;
  @observable freeSendLocked = false;
  @observable noCommission = true;
  @observable fee: DecimalString | undefined;
  @observable minFreeAmount: DecimalString | undefined;
  isValidatingAddressTo: boolean = false;

  @observable private _isTimeoutError = false;

  @computed get isTimeoutError() {
    return this._isTimeoutError;
  }

  get status() {
    return this._status;
  }

  @computed get recommendedFee(): DecimalString | undefined {
    if (!this.fees || this.fees.length === 0) {
      return undefined;
    }
    const middleIndex = Math.ceil((this.fees.length - 1) / 2);
    return this.fees[middleIndex];
  }

  @computed get addressNetwork(): AddressNetwork | undefined {
    return this._addressNetwork;
  }

  @computed get wallet(): Wallet | undefined {
    return (
      this._walletId && this._root.walletStore.getWalletById(this._walletId)
    );
  }

  @computed get minFee() {
    if (
      !!this._minFreeWithdrawAmountFromParams ||
      !this.crypto ||
      isFreeWithdrawalAvailable(this.crypto) ||
      !this.fees
    ) {
      return undefined;
    }
    return getMinFee(this.fees);
  }

  @computed get max() {
    return (
      this.crypto &&
      this.wallet &&
      getMaxAmountForWithdrawal(this.wallet, this._remainingLimit, this.minFee)
    );
  }

  @computed get isMinFeeMoreThanAvailableMax() {
    if (!(this.crypto && this.wallet)) {
      return false;
    }

    return isMinFeeMoreThanAvailableMax(
      this.wallet,
      this._remainingLimit,
      this.minFee,
    );
  }

  @computed get maxRestrictedByWalletLimit() {
    return (
      !this.wallet ||
      maxAmountRestrictedByLimit(this.wallet, this._remainingLimit)
    );
  }

  @computed
  private get _remainingLimit() {
    return (
      this.wallet &&
      this._walletLimitHelper.getCurrentRemainingLimit(this.wallet.id)
    );
  }

  @computed get min(): DecimalString {
    return this.crypto?.options.min_withdrawal_amount || '0';
  }

  @computed get fiatTotal(): DecimalString | undefined {
    if (!this.wallet || !this._baseFiat) {
      return;
    }

    const rate = this._root.currenciesRateStore.getRate(
      this.wallet.currency,
      this._baseFiat,
    )?.rate;

    return rate && MoneyStatic.convert(this.wallet.total, rate);
  }

  @computed get fiatCurrency(): CurrencyDescription | undefined {
    const fiat =
      this._baseFiat &&
      this._root.currencyStore.getFiatCurrency(this._baseFiat);
    return fiat && toCurrencyDescriptionFromFiat(fiat);
  }

  @computed get cryptoCurrency(): CurrencyDescription | undefined {
    return this.crypto && toCurrencyDescriptionFromCrypto(this.crypto);
  }

  @computed
  private get crypto() {
    return (
      this.wallet &&
      this._root.currencyStore.getCryptoCurrency(this.wallet.currency)
    );
  }

  @computed get isFreeWithdrawalUnavailable() {
    return (
      (this._minFreeWithdrawAmountFromParams &&
        BigNumber(this._minFreeWithdrawAmountFromParams).isZero()) ||
      (this.crypto ? !isFreeWithdrawalAvailable(this.crypto) : false)
    );
  }

  @computed get isFreeWithdrawalAvailable(): boolean {
    return (
      !!this._minFreeWithdrawAmountFromParams ||
      !this.crypto ||
      isFreeWithdrawalAvailable(this.crypto)
    );
  }

  @computed get isEnoughAmountForFreeWithdrawal() {
    const minFreeWithdrawalAmount =
      this._minFreeWithdrawAmountFromParams ||
      this.crypto?.options.min_withdrawal_amount;
    return (
      !isNil(this.amount) &&
      !isNil(minFreeWithdrawalAmount) &&
      BigNumber(this.amount).isGreaterThanOrEqualTo(minFreeWithdrawalAmount)
    );
  }

  private getOutputCurrency() {
    if (!this.crypto || !this._addressNetwork || !this._addressCurrency) {
      return undefined;
    }

    return this.crypto.options.currencies_out.find(
      c =>
        c.network === this._addressNetwork &&
        c.currency === this._addressCurrency,
    );
  }

  @computed get addressName(): string | undefined {
    return this.getOutputCurrency()?.name;
  }

  @computed get networkCurrency(): string | undefined {
    return this.getOutputCurrency()?.currency;
  }

  @computed get contractType(): string | undefined | null {
    return this.getOutputCurrency()?.contract_type;
  }

  @computed get networkShown() {
    return !!this.crypto && isNetworkShown(this.crypto, 'out');
  }

  @computed get networkChangeEnabled() {
    return !!this.crypto && isNetworkChangePossible(this.crypto, 'out');
  }

  @computed get isBlockchainComment(): boolean {
    return (
      this.crypto?.options.currencies_out.find(
        c => c.network === this._addressNetwork,
      )?.allow_comment ?? false
    );
  }

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly flashMessage: FlashMessage;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
      readonly currenciesRateStore: CurrenciesRateStore;
      readonly currenciesRateHistoryStore: CurrenciesRateHistoryStore;
      readonly sentryLog: SentryLog;
    },
    private readonly _addressValidator: SendAddressValidator,
    private readonly _amountValidator: SendAmountValidator,
    private readonly _walletLimitHelper: WalletLimitHelper,
  ) {
    makeObservable(this);
  }

  getHasNoCommission = () => this.noCommission;

  @action.bound
  async setHasNoCommission(noCommission: boolean) {
    this.noCommission = noCommission;
    if (!noCommission) {
      void this._updateFeeDebounded();
    }
    runInAction(() => {
      this.fee = this.noCommission ? '0' : this.recommendedFee;
    });
  }

  getMax(walletId: WalletId) {
    const remainingLimit =
      this._walletLimitHelper.getCurrentRemainingLimit(walletId);
    const wallet = this._root.walletStore.getWalletById(walletId);
    return wallet && getMaxAmountForWithdrawal(wallet, remainingLimit);
  }

  @action.bound
  setFee(fee: string) {
    this.fee = fee;
  }

  @action.bound
  private async _updateFee() {
    const res = await this._getFee(
      undefined,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._addressCurrency!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._addressNetwork!,
      {
        address_to: this.addressTo || null,
        amount: this.amount ?? null,
      },
    );
    runInAction(() => {
      this.fees = res.fees.map(f => f.fee);
    });
  }

  @action.bound
  private async _getFee(
    fee: DecimalString | undefined,
    currency: CryptoCurrencyCode,
    network: AddressNetwork,
    options: WalletsWithdrawalsEstimatedFeeOptions,
  ) {
    if (fee === undefined) {
      const res = await this._root.ncWalletJsonRpcClient.call(
        'wallets.withdrawals.estimated_fee',
        {
          currency,
          network,
          options,
        },
      );
      if (!res.success) {
        if (res.left.kind === TIMEOUT_ERROR) {
          this._isTimeoutError = true;
          this._root.sentryLog.write(
            createJsonRpcTimeoutErrorMessage(
              '[wallets.withdrawals.estimated_fee]',
              {currency, network},
            ),
          );
        }
        return {fees: []};
      }
      return res.right;
    }
    this.fee = fee;
    this.fees = [fee];
    return {fees: [{fee: fee}]};
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  private _updateFeeDebounded = debounce(this._updateFee, DEBOUNCE_TIMEOUT_MS);

  @action.bound
  async refresh(
    baseFiat: FiatCurrencyCode,
    walletId: WalletId,
    addressNetwork: AddressNetwork,
    addressCurrency: CryptoCurrencyCode,
    addressTo?: CryptoAddress,
    amount?: DecimalString,
    fee?: DecimalString,
    minFreeWithdrawAmount?: DecimalString,
  ) {
    this._baseFiat = baseFiat;
    this._walletId = walletId;
    this._addressNetwork = addressNetwork;
    this._addressCurrency = addressCurrency;
    this._addressTo = addressTo;
    this._minFreeWithdrawAmountFromParams = minFreeWithdrawAmount;
    const feesRes = await this._getFee(fee, addressCurrency, addressNetwork, {
      address_to: addressTo ?? null,
      amount: amount ?? null,
    });

    const responses = await Promise.all([
      this._root.currencyStore.refreshCryptoCurrencies(),
      this._root.walletStore.refreshWallets(),
      this._root.currencyStore.refreshFiatCurrencies(),
      this._walletLimitHelper.refresh(),
    ]);

    this._isTimeoutError = responses.some(
      it => !it.success && it.left.kind === TIMEOUT_ERROR,
    );

    const [currenciesRes, walletRes] = responses;
    if (this.addressTo || this.addressToError) {
      await this.validateAddressTo();
    }
    if ((this.amount && this.amount !== '0') || this.amountError) {
      this.validateAmount();
    }

    if (
      this._addressNetwork === 'undefined' ||
      !this._root.currencyStore
        .getCryptoCurrency(this._addressCurrency)
        ?.options.currencies_out.find(co => co.network === this._addressNetwork)
    ) {
      this._addressNetwork = this._root.currencyStore.getCryptoCurrency(
        this._addressCurrency,
      )?.options.default_network;
      if (addressNetwork !== 'undefined') {
        this._root.flashMessage.showMessage({
          title: 'error.cantFindNetwork',
          variant: 'danger',
        });
      }
    }

    if (!currenciesRes.success || !walletRes.success) {
      runInAction(
        () => (this._status = SendCommissionBindingStateStatus.Error),
      );
      return;
    }

    runInAction(() => {
      this.fees = feesRes.fees.map(f => f.fee);

      this.minFreeAmount =
        minFreeWithdrawAmount ||
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.crypto!.options.min_free_withdrawal_amount;

      this.freeSendAvailableForCrypto = !isNil(this.minFreeAmount);

      this.isEnoughAmountForFreeSend =
        this.freeSendAvailableForCrypto &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        BigNumber(amount ?? 0).isGreaterThanOrEqualTo(this.minFreeAmount!);

      if (!this.fee || !this.fees.includes(this.fee)) {
        void this.setHasNoCommission(this.isEnoughAmountForFreeSend);
      }
      this._status = SendCommissionBindingStateStatus.Ready;
    });
    const wallet = walletRes.right.find(w => w.id === walletId);
    return this._root.currenciesRateStore.refreshRate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      wallet!.currency,
      this._baseFiat,
    );
  }

  @action.bound
  setAmount(amount: DecimalString | undefined) {
    const _amount = amount ?? '0';
    const n = BigNumber(_amount);
    if (!n.isFinite() || n.isNegative()) {
      return;
    }

    this.amountError = undefined;
    this.amount = _amount;

    this.isEnoughAmountForFreeSend =
      this.freeSendAvailableForCrypto &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      n.isGreaterThanOrEqualTo(this.minFreeAmount!);
    void this.setHasNoCommission(this.isEnoughAmountForFreeSend);
  }

  @action.bound
  setComment(comment: string) {
    this.comment = comment;
  }

  @action.bound
  setAddressTo(addressTo: string) {
    this.addressToError = undefined;
    this.addressTo = addressTo;
  }

  @action.bound
  validateAmount(): SendAmountValidatorError | undefined {
    this.amountError = this._amountValidator.validate(
      this.amount,
      this.min,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.max!,
      !!this.minFee && BigNumber(this.minFee).isGreaterThan(0),
      !!this._remainingLimit,
    );
    return this.amountError;
  }

  @action.bound
  async validateAddressTo(): Promise<SendAddressValidatorError | undefined> {
    this.isValidatingAddressTo = true;
    const res = await this._addressValidator.validate(
      this.addressTo,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._addressCurrency!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.wallet!.addresses.map(a => a.address),
      this._addressNetwork,
    );
    runInAction(() => {
      this.addressToError = res;
      this.isValidatingAddressTo = false;
    });

    return this.addressToError;
  }

  @action.bound
  async onBlurAddressInput() {
    if (
      this._addressNetwork &&
      this._addressCurrency &&
      this.addressTo &&
      this.addressTo !== this._prevFindFeeAddress
    ) {
      const error = await this.validateAddressTo();
      if (!error) {
        await this._getFee(
          undefined,
          this._addressCurrency,
          this._addressNetwork,
          {address_to: this.addressTo || null, amount: this.amount ?? null},
        );
        this._prevFindFeeAddress = this.addressTo;
      }
    }
  }

  @action.bound
  private async _refreshFeesForMaxAmountCalculation(
    addressNetwork: AddressNetwork,
    addressCurrency: CryptoCurrencyCode,
    options: WalletsWithdrawalsEstimatedFeeOptions,
  ) {
    const feesRes = await unwrap(
      this._root.ncWalletJsonRpcClient.call(
        'wallets.withdrawals.estimated_fee',
        {
          currency: addressCurrency,
          network: addressNetwork,
          options,
        },
      ),
    );
    runInAction(() => {
      this.fees = feesRes.fees.map(f => f.fee);
    });
  }
}
