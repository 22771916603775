import {useTheme, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {ViewProps} from 'react-native';
import {Text, View} from 'react-native';

import {ArrowLeftSvg} from '../assets/svg/colorless';
import {LG_BREAKPOINT, SafeAreaInset, SafeAreaLayout} from '../components';
import {TouchableOpacity} from '../components/atoms/TouchableOpacity';

export type CenteredCardLayoutProps = {
  onBackPress?: () => void;
  appVersion?: string;
  fullHeightOnSmallScreen?: boolean;
  hideBackButton?: boolean;
} & ViewProps;

export default observer(function CenteredCardLayout(
  props: CenteredCardLayoutProps,
) {
  const {
    appVersion,
    children,
    fullHeightOnSmallScreen,
    hideBackButton,
    ...rest
  } = props;
  const theme = useTheme();

  return (
    <>
      <LayoutBackground
        insets={
          fullHeightOnSmallScreen ? SafeAreaInset.NONE : SafeAreaInset.TOP
        }>
        <ModalView {...rest} fullHeightOnSmallScreen={fullHeightOnSmallScreen}>
          <Header>
            {!hideBackButton && (
              <TouchableOpacity onPress={props.onBackPress}>
                <ArrowLeftSvg color={theme.palette.uiMain} />
              </TouchableOpacity>
            )}
            <Version>v.{appVersion}</Version>
          </Header>
          {children}
        </ModalView>
      </LayoutBackground>
    </>
  );
});

const LayoutBackground = variance(SafeAreaLayout)(() => ({
  root: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: 'transparent',
  },
}));

const ModalView = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.background,
    shadowColor: '#a45405',
    position: 'relative',
    zIndex: 23,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.2,
    elevation: 4,
    borderRadius: 10,
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    marginTop: 0,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: '100%',
        maxWidth: 800,
        height: 668,
        paddingBottom: 15,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      },
    }),
  },

  fullHeightOnSmallScreen: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
    }),
  },
}));

const Header = variance(View)(theme => ({
  root: {
    display: 'none',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: 30,
        paddingBottom: 0,
      },
    }),
  },
}));

const Version = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.uiMain,
    marginLeft: 'auto',
  },
}));
