import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React from 'react';
import {Platform, View} from 'react-native';

import {LG_BREAKPOINT} from '../../grid';

export type CurrencySelectionViewContainerProps = PropsWithChildren<{
  smBottom?: boolean;
}>;

export default observer(function CurrencySelectionViewContainer(
  props: CurrencySelectionViewContainerProps,
) {
  return (
    <Root>
      <Inner smBottom={props.smBottom}>{props.children}</Inner>
    </Root>
  );
});

const Root = variance(View)(theme => ({
  root: {
    flex: 1,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.uiPrimary,
      },
    }),
  },
}));

const Inner = variance(View)(theme => ({
  root: {
    flex: 1,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        backgroundColor: theme.palette.background,
        padding: 30,
        paddingBottom: 30,
        ...theme.bar(10),
      },
    }),
  },
  smBottom: {
    paddingBottom: 30,
    ...Platform.select({
      web: {
        paddingBottom: 0,
      },
    }),
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingBottom: 30,
      },
    }),
  },
}));
