import type {CurrencyStore, WalletStore} from '@ncwallet-app/core';
import {FULFILLED} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import type {FiatCurrency} from '@ncwallet-app/core/src/NCWalletServer/FiatCurrency';
import {action, computed, makeObservable, observable} from 'mobx';

// eslint-disable-next-line import-x/prefer-default-export
export class BaseFiatSettingsBindingState {
  @observable filter = '';

  @computed get _baseFiat() {
    const account =
      this._root.accountStore.state?.status === FULFILLED &&
      this._root.accountStore.state.result;

    if (!account) {
      return;
    }

    return this._root.currencyStore.getFiatCurrency(account.base_fiat);
  }

  @computed get _otherFiats() {
    const fiats = this._root.currencyStore.getFiatCurrencies();
    const baseFiat = this.getBaseFiat();
    if (!fiats || !baseFiat) {
      return;
    }
    return fiats.filter(fiat => fiat.code !== baseFiat.code);
  }

  constructor(
    private readonly _root: {
      readonly accountStore: AccountStore;
      readonly currencyStore: CurrencyStore;
      readonly walletStore: WalletStore;
    },
  ) {
    makeObservable(this);
  }

  @action.bound
  setFilter(filter: string) {
    this.filter = filter;
  }

  getBaseFiat = () => this._baseFiat;

  getOtherFiats = () => {
    const fiats = this._otherFiats;
    const trimmedFilter = this.filter.trim().toLowerCase();
    if (trimmedFilter.length === 0) {
      return fiats;
    }
    return fiats?.filter(
      c =>
        c.code.toLowerCase().includes(trimmedFilter) ||
        c.name.toLowerCase().includes(trimmedFilter),
    );
  };

  refresh() {
    return this._root.currencyStore.refreshFiatCurrencies();
  }

  update = (fiat: FiatCurrency) => {
    return this._root.accountStore.update({base_fiat: fiat.code});
  };
}
