import {GLOBAL_PARAMS} from './constant';
import LazyPathImpl from './LazyPathImpl';
import type {Path} from './Path';
import type {PathChain} from './PathChain';
import PathImpl from './PathImpl';
import stripHistory from './stripHistory';

export default function extractPathChain(path: Path): PathChain {
  if (path.params === undefined) {
    return [path];
  }
  const {[GLOBAL_PARAMS.history]: history, ...params} = path.params;
  if (history === undefined) {
    return [path];
  }
  const rest = history
    .flatMap<Path>(_ => (_ === null ? [] : [new LazyPathImpl(_)]))
    .map(stripHistory)
    .reverse();
  return [...rest, new PathImpl(path.pathname, params)];
}
