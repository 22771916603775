import {useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {CommonActions} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';

import {useCreateLimitWithOtp} from '../../../CommonNavigationContainers/hooks/walletLimits/useCreateLimitWithOtp';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {useSendTelegramOtp} from '../../../Navigation/hooks/useSendTelegramOtp';
import SubmitOtpScreen, {
  CARD,
} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen/SubmitOtpScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptOtpToLimitCreateBindingProps =
  SmallHomeStackBindingProps<'PromptOtpToLimitCreate'>;

export default observer(function PromptOtpToLimitCreateBinding(
  props: PromptOtpToLimitCreateBindingProps,
) {
  const {
    navigation,
    route: {params},
  } = props;
  const strings = useStrings();
  const {twoFaSettingsState} = useRoot();
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();

  const resetToLimitList = useCallback(() => {
    if (hasRouteInHistory(navigation.getState(), 'ListLimits')) {
      navigation.navigate('ListLimits');
    } else {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [{name: 'Root'}, {name: 'ListLimits'}],
        }),
      );
    }
  }, [navigation]);

  const handleTwoFaResendCode = useCallback(async () => {
    try {
      await sendOtp(null, {forceChannel: false});
    } catch {
      /* empty */
    }
  }, [sendOtp]);

  const {getErrorText, createLimitWithOpt, resetErrorText} =
    useCreateLimitWithOtp(resetToLimitList);

  const handleSubmit = useCallback(
    (code: string) =>
      createLimitWithOpt(params.walletId, params.limit, params.period, code),
    [createLimitWithOpt, params.limit, params.period, params.walletId],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (getIsReady() && getIsFocused()) {
      if (
        twoFaSettingsState.currentTwoFaProvider === TwoFaProviderKind.Telegram
      ) {
        void handleTwoFaResendCode();
      }
    }
  }, [getIsReady, getIsFocused, twoFaSettingsState, handleTwoFaResendCode]);

  return (
    <SubmitOtpScreen
      layout={CARD}
      titleText={strings['promptOtpToUpdateLimitList.title']}
      submitText={strings['promptOtpToUpdateLimitList.submit']}
      resetError={resetErrorText}
      onCancel={goBack}
      onSubmit={handleSubmit}
      getError={getErrorText}
      twoFaProvider={twoFaSettingsState.currentTwoFaProvider}
      onTwoFaResendCode={handleTwoFaResendCode}
      resendCodeInterval={interval}
      resetInterval={resetInterval}
    />
  );
});
