import {useRoot} from '@ncwallet-app/core/src';
import {NotificationFeedbackType} from 'expo-haptics';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';

import {CongratulationsScreen} from '../../screens/CongratulationsScreen';

// eslint-disable-next-line import-x/prefer-default-export
export const CongratulationsBinding = observer(() => {
  const {authHelper, deviceInfo, haptics} = useRoot();
  const goToApp = useCallback(() => authHelper.touch(), [authHelper]);
  useEffect(() => {
    void haptics.notification(NotificationFeedbackType.Success);
  }, [haptics]);
  return (
    <CongratulationsScreen
      goToApp={goToApp}
      appVersion={deviceInfo.getAppVersionWithBuildNumber()}
    />
  );
});
