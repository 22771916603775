import {FULFILLED, useRoot} from '@ncwallet-app/core/';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import useAccountVerificationBindingState from '../../../CommonNavigationContainers/hooks/AccountVerification';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {IdentityVerificationScreen} from '../../../screens/IdentityVerificationScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ListPersonalDataBindingProps =
  SmallHomeStackBindingProps<'ListPersonalData'>;

export default observer(function ListPersonalDataBinding(
  props: ListPersonalDataBindingProps,
) {
  const {navigation} = props;
  const {accountStore} = useRoot();
  const verificationState = useAccountVerificationBindingState();
  const {personalData, addressData} = verificationState;

  const handleUsernamePress = useCallback(() => {
    navigation.navigate('PromptNameAndBirthdate');
  }, [navigation]);

  const handleBirthdatePress = useCallback(() => {
    navigation.navigate('PromptNameAndBirthdate');
  }, [navigation]);

  const handleAddressPress = useCallback(() => {
    navigation.navigate('PromptResidentialAddress');
  }, [navigation]);

  const handleUploadPress = useCallback(() => {
    navigation.navigate('ListDocuments');
  }, [navigation]);

  const isVerificationRequired =
    accountStore.state?.status === FULFILLED &&
    accountStore.state.result.settings.verification_required;

  return (
    <IdentityVerificationScreen
      isVerificationRequired={isVerificationRequired}
      personalData={personalData}
      addressData={addressData}
      onUsernamePress={handleUsernamePress}
      onBirthDatePress={handleBirthdatePress}
      onAddressPress={handleAddressPress}
      onUploadPress={handleUploadPress}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});
