import {comparer, reaction} from 'mobx';

import type {AppStateHelper} from '../AppStateHelper';
import type {JsonRpcClient} from '../JsonRpc';
import {MESSAGING_PROTOCOL_VERSION} from '../Messaging';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {Service} from '../structure';
import type {MessagingIdentity} from './MessagingIdentity';

export default class MessagingIdentityNotifierService implements Service {
  constructor(
    private readonly _root: {
      readonly messagingIdentity: MessagingIdentity;
      readonly appStateHelper: AppStateHelper;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {}

  subscribe() {
    return reaction(
      () =>
        [
          this._root.appStateHelper.isReadyToMakeRequests,
          this._root.messagingIdentity.token,
        ] as const,
      async ([isReadyToMakeRequests, token]) => {
        if (isReadyToMakeRequests && token !== undefined) {
          await this._root.ncWalletJsonRpcClient.call('accounts.register_fcm', {
            token,
            version: MESSAGING_PROTOCOL_VERSION,
          });
        }
      },
      {equals: comparer.shallow},
    );
  }
}
