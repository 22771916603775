import type {ComponentType, FC} from 'react';
import React from 'react';
import type {ColorValue} from 'react-native';
import type {SvgProps} from 'react-native-svg';

export default function colored(
  Icon: ComponentType<SvgProps>,
  color: ColorValue,
): FC<SvgProps> {
  return props => <Icon color={color} {...props} />;
}
