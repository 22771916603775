import {BigNumber} from 'bignumber.js';

import type {CryptoCurrencyCode, DecimalString} from '../Money';
import type {AddressNetwork} from './AddressInfo';

export type WalletsWithdrawalsEstimatedFee = {
  params: {
    currency: CryptoCurrencyCode;
    network: AddressNetwork;
    options: WalletsWithdrawalsEstimatedFeeOptions;
  };
  result: {
    fees: WithdrawalFee[];
  };
};

export type WalletsWithdrawalsEstimatedFeeOptions = {
  address_to: string | null;
  amount: string | null;
};

export type WithdrawalFee = {
  fee: DecimalString;
};

export const getMinFee = (fees: WithdrawalFee[] | DecimalString[]) => {
  if (fees.length === 0) {
    return '0';
  }

  const feeValues = fees.map(f => {
    if (typeof f === 'string') {
      return f;
    } else if ('fee' in f) {
      return f.fee;
    }
    return '0';
  });

  return BigNumber.min(...feeValues).toString();
};
