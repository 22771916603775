// eslint-disable-next-line import-x/prefer-default-export
export enum VerificationSteps {
  Start,
  PersonalInfoNameStep,
  Address,
  SelectCountry,
  DocumentsStep,
  AddDocumentKindStep,
  UploadDocumentStep,
  RemoveUploadedDocumentStep,
}
