import type {ForwardRefExoticComponent, JSX, RefAttributes} from 'react';
import type {ViewProps} from 'react-native';

export type CameraCapturedPicture = {
  width: number;
  height: number;
  uri: string;
  base64?: string;
  exif?: unknown;
};

export enum PermissionStatus {
  /**
   * User has granted the permission.
   */
  GRANTED = 'granted',
  /**
   * User hasn't granted or denied the permission yet.
   */
  UNDETERMINED = 'undetermined',
  /**
   * User has denied the permission.
   */
  DENIED = 'denied',
}

export interface PermissionResponse {
  status: PermissionStatus;
  expires: 'never' | number;
  granted: boolean;
  canAskAgain: boolean;
}

type ExpoCamera = {
  takePictureAsync: (props?: {
    onPictureSaved: (event: CameraCapturedPicture) => void;
  }) => Promise<CameraCapturedPicture>;
};
export type DocCameraComponentType = ForwardRefExoticComponent<
  RefAttributes<ExpoCamera>
>;
export type CameraComponent = ExpoCamera & ViewProps;
export type QrCameraComponentProps = {onBarCodeScanned: (code: string) => void};

export interface Camera {
  getCameraPermissionsAsync: () => Promise<PermissionResponse>;
  requestCameraPermissionsAsync: () => Promise<PermissionResponse>;
  QrCameraComponent: (props: QrCameraComponentProps) => JSX.Element;
  DocCameraComponent?: DocCameraComponentType;
}
