import type {ParsedQuery} from 'query-string';
import queryString from 'query-string';

import type {Path, PathParam, PathParamList, PathParams} from './Path';

export type LoosePathParam = string | null | undefined;
export type LoosePathParamList = LoosePathParam | readonly LoosePathParam[];
export type LoosePathParams = Readonly<Record<string, LoosePathParamList>>;

const {parse, stringify} = queryString;

export default class PathImpl implements Path {
  private readonly _params: PathParams | undefined;

  constructor(
    public readonly pathname: readonly string[] = [],
    params?: LoosePathParams,
  ) {
    this._params = PathImpl._translateLooseParams(params);
  }

  get params(): PathParams | undefined {
    return this._params;
  }

  private static _translateLooseParams(
    loose: LoosePathParams | undefined,
  ): PathParams | undefined {
    if (loose === undefined) {
      return loose;
    }
    const params: PathParams<true> = {};
    for (const [key, value] of Object.entries(loose)) {
      if (value === undefined) {
        continue;
      }
      if (Array.isArray(value)) {
        const filtered = value.filter(_ => _ !== undefined) as PathParam[];
        if (filtered.length === 0) {
          continue;
        }
        params[key] = value as PathParamList<true>;
        continue;
      }
      params[key] = [value as string | null];
    }
    return params;
  }

  toString() {
    return PathImpl._assemble(
      this.pathname,
      this.params && stringify(this.params),
    );
  }

  private static _assemble(
    _pathname?: readonly string[],
    query?: string,
    hash: string = '',
  ): string {
    const pathname = '/' + (_pathname?.join('/') ?? '');
    const search = query === undefined || query === '' ? '' : `?${query}`;
    return `${pathname}${search}${hash}`;
  }

  public static parse(path: string): PathImpl {
    const {pathname, query} = PathImpl._splitPath(path);
    const rawParams = parse(query);
    const params = PathImpl._translateQueryParams(rawParams);
    return new PathImpl(pathname.replace(/^\/+/, '').split(/\/+/), params);
  }

  private static _translateQueryParams(_: ParsedQuery): PathParams {
    const params: Record<string, PathParamList> = {};
    for (const [key, value] of Object.entries(_)) {
      params[key] = PathImpl._translateQueryParam(value);
    }
    return params;
  }

  private static _translateQueryParam(
    _: string | null | (string | null)[],
  ): PathParamList<true> {
    if (Array.isArray(_)) {
      if (_.length === 0) {
        return [null];
      }
      return _ as PathParamList<true>;
    }
    return [_];
  }

  private static _splitPath(path: string) {
    const matches = path.match(/^(.*?)(?:\?(.*?))?(#.*)?$/);
    return {
      pathname: matches?.[1] ?? '',
      query: matches?.[2] ?? '',
      hash: matches?.[3] ?? '',
    };
  }
}
