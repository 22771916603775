import {useStrings, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  Input,
  InputType,
  SafeAreaScrollView,
} from '@ncwallet-app/ui';
import {isEmpty} from 'lodash';
import React, {useCallback, useRef, useState} from 'react';
import type {TextInput} from 'react-native';
import {StyleSheet, Text, View} from 'react-native';

import {useFocusInputOnNavigationFocus} from '../../../Navigation/hooks';

export type IpListModalProps = BaseSafeAreaProps & {
  ipName: string;
  ip: string;
  placeholder: string;
  wide?: boolean;
  onSubmit: (ip: string, name: string) => void;
  onDeletePress: () => void;
  getError?: () => string | undefined;
  resetError?: () => void;
};

export const IpModal = (props: IpListModalProps) => {
  const {
    onSubmit,
    onDeletePress,
    ipName,
    ip,
    placeholder,
    wide,
    getError,
    resetError,
    ...rest
  } = props;
  const ref = useRef<TextInput>(null);
  useFocusInputOnNavigationFocus(ref);
  const [ipNameValue, setIpNameValue] = useState(ipName);
  const [ipValue, setIpValue] = useState(ip);
  const strings = useStrings();
  const error = getError && getError();

  const setName = useCallback(
    (value: string) => {
      setIpNameValue(value);
      if (!isEmpty(error) && resetError) {
        resetError();
      }
    },
    [error, resetError],
  );

  const setIp = useCallback(
    (value: string) => {
      setIpValue(value);
      if (!isEmpty(error) && resetError) {
        resetError();
      }
    },
    [error, resetError],
  );

  return (
    <SafeAreaScrollView
      keyboardVerticalOffset={40}
      contentContainerStyle={!wide && styles.container}
      {...rest}>
      <View style={styles.content}>
        <Input
          ref={ref}
          type={InputType.Default}
          label={strings['secureSettings.ipList.labelIpName']}
          placeholder={strings['secureSettings.ipList.placeholderIpName']}
          value={ipNameValue}
          onChangeText={setName}
        />
        <Input
          ref={ref}
          type={InputType.Default}
          label={strings['secureSettings.ipList.labelIp']}
          placeholder={placeholder}
          value={ipValue}
          onChangeText={setIp}
          containerStyle={styles.input}
        />
        {error && <DangerText>{error}</DangerText>}
      </View>
      <View style={styles.col}>
        <Button
          variant={ButtonVariant.Highlighted}
          color={ButtonColor.Secondary}
          onPress={() => {
            onSubmit(ipValue, ipNameValue);
          }}
          title={strings['secureSettings.ipList.save']}
          testID="save-ip-btn"
        />
        <ModalButton
          variant={ButtonVariant.Danger}
          style={styles.button}
          color={ButtonColor.Error}
          onPress={onDeletePress}
          title={strings['secureSettings.ipList.deleteButton']}
          testID="rem-ip-btn"
        />
      </View>
    </SafeAreaScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingHorizontal: 10,
  },
  button: {
    marginTop: 20,
  },
  col: {
    marginTop: 'auto',
  },
  input: {
    marginTop: 30,
  },
  content: {
    marginBottom: 20,
  },
});

const ModalButton = variance(Button)(theme => ({
  root: {
    borderColor: theme.palette.uiSecondary,
  },
}));

const DangerText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.error,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    marginVertical: 20,
  },
}));
