import {Portal} from '@gorhom/portal';
import {useTheme, variance} from '@ncwallet-app/core/src';
import {observer} from 'mobx-react-lite';
import React, {useMemo, useRef, useState} from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {Platform, StatusBar, Text, View} from 'react-native';

import {InfoSvg} from '../../../assets/svg/colorless';
import {useIsDimensions} from '../../../util';
import {TouchableOpacity} from '../../atoms/TouchableOpacity';
import {TooltipModal} from './TooltipModal';
import {TooltipVariant} from './TooltipVariant';

type TooltipProps = {
  variant?: TooltipVariant;
  style?: StyleProp<ViewStyle>;
  title: string;
  text: string;
  closeText: string;
};

// eslint-disable-next-line import-x/prefer-default-export
export const Tooltip = observer((props: TooltipProps) => {
  const {variant = TooltipVariant.Primary} = props;
  const theme = useTheme();
  const ref = useRef<View>(null);
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({x: 0, y: 0});
  const isLg = useIsDimensions('lg');
  const isStylesForWebAndLg = useMemo(
    () => Platform.OS === 'web' || isLg,
    [isLg],
  );
  const open = () => {
    if (!ref.current) {
      return;
    }
    ref.current.measureInWindow((x, y, width, height) => {
      setPosition({
        x: Math.floor(x),
        y: isStylesForWebAndLg
          ? Math.floor(y)
          : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            Math.floor(y + height + StatusBar.currentHeight!),
      });
      setVisible(true);
    });
  };

  const ColoredInfoSvg = () => (
    <InfoSvg
      color={visible ? theme.palette.primary : theme.palette.uiAdditional1}
    />
  );

  return (
    <>
      <Portal>
        <TooltipModal
          visible={visible}
          position={position}
          variant={variant}
          onClose={() => {
            setVisible(false);
          }}>
          <TooltipHeaderContainer>
            <InfoSvg color="#fff" />
            <TooltipTitle>{props.title}</TooltipTitle>
          </TooltipHeaderContainer>
          <TooltipBody>
            <TooltipText>{props.text}</TooltipText>

            <TooltipCloseTouchable
              onPress={() => {
                setVisible(false);
              }}>
              <TooltipCloseTouchableText>
                {props.closeText}
              </TooltipCloseTouchableText>
            </TooltipCloseTouchable>
          </TooltipBody>
        </TooltipModal>
      </Portal>

      <View ref={ref} collapsable={false}>
        <TouchableOpacity onPress={open} style={props.style}>
          <ColoredInfoSvg />
        </TouchableOpacity>
      </View>
    </>
  );
});

const TooltipHeaderContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
}));

const TooltipTitle = variance(Text)(theme => ({
  root: {
    marginLeft: 10,
    ...theme.fontByWeight('700'),
    fontSize: 14,
    color: theme.palette.whitey,
  },
}));

const TooltipBody = variance(View)(theme => ({
  root: {
    padding: 10,
    paddingRight: 20,
    borderRadius: 6,
    backgroundColor: theme.palette.uiAdditional2,
  },
}));

const TooltipText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
    lineHeight: 22,
  },
}));

const TooltipCloseTouchable = variance(TouchableOpacity)(() => ({
  root: {
    marginTop: 15,
    alignSelf: 'flex-end',
  },
}));

const TooltipCloseTouchableText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.info,
  },
}));
