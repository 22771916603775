import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import type {DecimalString, WalletLimitPeriod} from '@ncwallet-app/core';
import {toCurrencyDescriptionFromCrypto} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {
  PromptOtpToLimitCreateRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {SafeAreaInset} from '@ncwallet-app/ui/src';
import {useHeaderHeight} from '@react-navigation/elements';
import {CommonActions} from '@react-navigation/native';
import {isNil} from 'lodash';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import {useCreateLimit} from '../../../CommonNavigationContainers/hooks/walletLimits/useCreateLimit';
import {useGetWalletLimits} from '../../../CommonNavigationContainers/hooks/walletLimits/useGetWalletLimits';
import {
  useCryptoCurrencies,
  useNavigationGetIsFocused,
} from '../../../Navigation/hooks';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {
  mapValidateErrorToString,
  validateLimitValue,
} from '../../../screens/LgLimitsSettingsScreen/validateLimitValue';
import SetLimitScreen from '../../../screens/UserSettingsScreens/SetLimitScreen/SetLimitScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptNewLimitBindingProps =
  SmallHomeStackBindingProps<'PromptNewLimit'>;

export default observer(function PromptNewLimitBinding(
  props: PromptNewLimitBindingProps,
) {
  const {navigation, route} = props;
  const header = useHeaderHeight();
  const [error, setError] = useState<LocaleKeys | undefined>(undefined);
  const resetToLimitList = useCallback(() => {
    if (hasRouteInHistory(navigation.getState(), 'ListLimits')) {
      navigation.navigate('ListLimits');
    } else {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [{name: 'Root'}, {name: 'ListLimits'}],
        }),
      );
    }
  }, [navigation]);
  const {getCryptoCurrency, loadCryptoCurrencies} = useCryptoCurrencies();
  const currency = getCryptoCurrency(route.params.currency);

  const goToOtp = useCallback(
    (params: RouteParams<PromptOtpToLimitCreateRoute>) => {
      navigation.navigate('PromptOtpToLimitCreate', params);
    },
    [navigation],
  );

  const {createLimit} = useCreateLimit(goToOtp, resetToLimitList);
  const {dailyLimit, monthlyLimit} = useGetWalletLimits(route.params.walletId);

  const handleSubmit = useCallback(
    (limit: DecimalString, period: WalletLimitPeriod) => {
      const wid = route.params.walletId;
      const errorKind = validateLimitValue(
        period,
        limit,
        monthlyLimit?.limit,
        dailyLimit?.limit,
      );

      if (isNil(errorKind)) {
        setError(undefined);
        void createLimit(wid, limit, period);
        return;
      }

      const errorLocaleKey = mapValidateErrorToString(errorKind);
      setError(errorLocaleKey);
    },
    [dailyLimit, monthlyLimit, createLimit, route.params.walletId],
  );

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void loadCryptoCurrencies();
          });
        }
      }),
    [getIsFocused, getIsReady, loadCryptoCurrencies],
  );

  return (
    <SetLimitScreen
      crypto={currency && toCurrencyDescriptionFromCrypto(currency)}
      initialPeriod={route.params.period}
      initialValue={undefined}
      onSubmit={handleSubmit}
      periodTabsShown={!dailyLimit && !monthlyLimit}
      insets={SafeAreaInset.BOTTOM}
      keyboardVerticalOffset={header}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      keyboardAvoiding
      error={error}
      resetError={() => {
        setError(undefined);
      }}
    />
  );
});
