import type {
  CryptoAddress,
  CryptoCurrencyCode,
  DecimalString,
  WalletId,
} from '@ncwallet-app/core';
import {FULFILLED, useRoot} from '@ncwallet-app/core';
import {SendAddressValidator} from '@ncwallet-app/core/src/AddressUriHelper';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {autorun, reaction, runInAction} from 'mobx';
import {useCallback, useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {WalletLimitHelper} from '../usePromptExchangeReceiptContainer/WalletLimitHelper';
import {PromptOutputAddressBindingState} from './PromptOutputAddressBindingState';
import {SendAmountValidator} from './SendAmountValidator';

// eslint-disable-next-line import-x/prefer-default-export
export const usePromptOutputAddressBindingState = (
  walletId: WalletId,
  addressNetwork: AddressNetwork,
  addressCurrency: CryptoCurrencyCode,
  addressTo: CryptoAddress | undefined,
  amount: DecimalString | undefined,
  comment?: string,
  minFreeWithdrawAmount?: string,
  fee?: string,
) => {
  const root = useRoot();
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  const [state] = useState(
    () =>
      new PromptOutputAddressBindingState(
        root,
        new SendAddressValidator(root),
        new SendAmountValidator(),
        new WalletLimitHelper(root),
      ),
  );

  const refresh = useCallback(() => {
    if (root.accountStore.state?.status === FULFILLED) {
      void state.refresh(
        root.accountStore.state.result.base_fiat,
        walletId,
        addressNetwork,
        addressCurrency,
        addressTo,
        amount,
        fee,
        minFreeWithdrawAmount,
      );
    }
  }, [
    addressTo,
    minFreeWithdrawAmount,
    addressCurrency,
    addressNetwork,
    amount,
    root,
    state,
    walletId,
    fee,
  ]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            // noinspection JSIgnoredPromiseFromCall
            refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, refresh, root],
  );

  useEffect(
    () =>
      reaction(
        () => state.isTimeoutError,
        shouldRequest => {
          if (shouldRequest) {
            root.rpcTimeoutErrorVisibility.registerAction(refresh);
          }
        },
      ),
    [root, refresh, state.isTimeoutError],
  );

  useEffect(() => {
    if (
      state.amount !== amount ||
      state.addressTo !== addressTo ||
      state.comment !== comment
    ) {
      state.setAddressTo(addressTo ?? '');
      state.setAmount(amount);
      state.setComment(comment ?? '');
    }
    if (fee && state.fee !== fee) {
      void state.setHasNoCommission(false);
      state.setFee(fee);
    }
  }, [addressTo, amount, state, comment, fee]);
  return [state, refresh] as const;
};
