import {useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo} from 'react';

import type {PromptOtpToExchangeContainerProps} from '../../../../../CommonNavigationContainers/hooks/usePromptOtpToExchangeContainer';
import {usePromptOtpToExchangeContainer} from '../../../../../CommonNavigationContainers/hooks/usePromptOtpToExchangeContainer';
import {useNavigationGetIsFocused} from '../../../../../Navigation/hooks';
import {useSendTelegramOtp} from '../../../../../Navigation/hooks/useSendTelegramOtp';
import {
  SubmitOtpScreen,
  SWITCH_SCREEN,
} from '../../../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptOtpToExchangeBindingProps =
  LargeSwitchBindingProps<'PromptOtpToExchange'>;

export default observer(function PromptOtpToExchangeBinding({
  route,
  navigation,
}: PromptOtpToExchangeBindingProps) {
  const {twoFaSettingsState} = useRoot();
  const strings = useStrings();
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();

  const handleTwoFaResendCode = useCallback(async () => {
    try {
      await sendOtp(null, {forceChannel: false});
    } catch {
      /* empty */
    }
  }, [sendOtp]);

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({
      index: 0,
      routes: [{name: 'PromptSourceCryptoToExchange'}],
    });
  }, [navigation]);

  const containerProps = useMemo<PromptOtpToExchangeContainerProps>(
    () => ({
      params: route.params,
      notifyAboutSuccessfulExchange: params => {
        navigation.navigate('NotifyAboutSuccessfulExchange', params);
      },
      promptConfirmationForExchange: params => {
        navigation.navigate('PromptConfirmationForExchange', params);
      },
    }),
    [navigation, route.params],
  );

  const screenProps = usePromptOtpToExchangeContainer(containerProps);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (getIsReady() && getIsFocused()) {
      if (
        twoFaSettingsState.currentTwoFaProvider === TwoFaProviderKind.Telegram
      ) {
        void handleTwoFaResendCode();
      }
    }
  }, [getIsReady, getIsFocused, twoFaSettingsState, handleTwoFaResendCode]);

  return (
    <SubmitOtpScreen
      layout={SWITCH_SCREEN}
      backText={strings['promptOtpToExchange.back']}
      titleText={strings['promptOtpToExchange.title']}
      submitText={strings['promptOtpToExchange.submit']}
      onCancel={onBack}
      twoFaProvider={twoFaSettingsState.currentTwoFaProvider}
      onTwoFaResendCode={handleTwoFaResendCode}
      resendCodeInterval={interval}
      resetInterval={resetInterval}
      {...screenProps}
    />
  );
});
