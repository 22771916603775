/* eslint-disable @typescript-eslint/unbound-method */
import {useRoot, useShareAvailable} from '@ncwallet-app/core';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {useHeaderHeight} from '@react-navigation/elements';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Platform} from 'react-native';

import {useGeneratePayMeLinkBindingState} from '../../../Navigation/HomeStack/GeneratePayMeLinkBinding/useGeneratePayMeLinkBindingState';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import GeneratePayMeLinkScreen from '../../../screens/GeneratePayMeLinkScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type GeneratePayMeLinkBingingProps =
  SmallHomeStackBindingProps<'GeneratePayMeLink'>;

export default observer(function GeneratePayMeLinkBinding({
  route,
}: GeneratePayMeLinkBingingProps) {
  const {amount, currency, address} = route.params;
  const {sharing} = useRoot();
  const state = useGeneratePayMeLinkBindingState({
    amount,
    currency,
    address,
  });

  const header = useHeaderHeight();

  const shareShown = useShareAvailable();

  return (
    <GeneratePayMeLinkScreen
      shareShown={shareShown}
      onShare={sharing.shareMessage}
      keyboardAvoiding
      behavior={Platform.OS === 'web' ? 'position' : 'padding'}
      keyboardVerticalOffset={header}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      link={state.link}
      amount={state.amount}
      currency={state.currencyCode}
      error={state.error}
      savedLink={state.savedLink}
      setAmount={state.setAmount}
      setLink={state.setLink}
      generateLink={state.generateLink}
      onSaveName={state.onSaveName}
      onMakeAvailableEdit={state.onMakeAvailableEdit}
      isAvailableEdit={state.isAvailableEdit}
    />
  );
});
