import type {NavigationProp, ParamListBase} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/native';
import {useCallback} from 'react';

export default function useNavigatePurgingDuplicates<T extends ParamListBase>(
  navigation: NavigationProp<T>,
) {
  return useCallback(
    <K extends keyof T>(
      ...args: undefined extends T[K]
        ? [name: K, params?: T[K]]
        : [name: K, params: T[K]]
    ) => {
      const [name, params] = args;
      navigation.dispatch(state => {
        const routes = [
          ...state.routes.filter(_ => (_.name as keyof T) !== name),
          {name: name as string, params},
        ];

        return CommonActions.reset({
          ...state,
          routes: routes,
          index: routes.length - 1,
        });
      });
    },
    [navigation],
  );
}
