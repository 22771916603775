import type {CurrencyCode, DecimalString} from '@ncwallet-app/core';
import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {DecimalInput, IconPosition, InputVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {TextInput, TextInputProps} from 'react-native';
import {Text, View} from 'react-native';

type BuyAmountInputProps = TextInputProps & {
  amount: DecimalString | undefined;
  onChangeAmount: (value: DecimalString | undefined) => void;
  currency: CurrencyCode | undefined;
  showKeyboard?: boolean;
  onFocus?: () => void;
  inputRef?: React.Ref<TextInput>;
  wideStyle?: boolean;
  error?: boolean;
};

export default observer(function BuyAmountInput(props: BuyAmountInputProps) {
  const {
    amount,
    onFocus,
    showKeyboard,
    onChangeAmount,
    currency,
    wideStyle,
    error,
    inputRef,
    ...rest
  } = props;
  const strings = useStrings();
  const theme = useTheme();

  const renderActionView = useCallback(() => {
    return (
      <AmountActionView>
        {currency && <AmountCryptoCode>{currency}</AmountCryptoCode>}
      </AmountActionView>
    );
  }, [currency]);

  return (
    <Root wideStyle={wideStyle}>
      <DecimalInput
        ref={inputRef}
        fractionDigits={8}
        value={amount}
        label={strings['receiveCryptoScreen.fiat.amount']}
        placeholder={strings['sendCrypto.paymentForm.placeholder']}
        placeholderTextColor={theme.palette.textAdditional3}
        iconPosition={IconPosition.Right}
        onFocus={onFocus}
        showSoftInputOnFocus={showKeyboard}
        onChangeDecimal={onChangeAmount}
        Slot={renderActionView}
        variant={error ? InputVariant.Error : InputVariant.Default}
        {...rest}
      />
    </Root>
  );
});

const AmountActionView = variance(View)(theme => ({
  root: {
    justifyContent: 'center',
    paddingHorizontal: 18,
    backgroundColor: theme.palette.uiPrimary,
    borderLeftWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const AmountCryptoCode = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    textTransform: 'uppercase',
  },
}));

const Root = variance(View)(() => ({
  root: {
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  wideStyle: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    flex: 1,
  },
}));
