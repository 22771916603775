import {variance} from '@ncwallet-app/core';
import ExchangeInput from '@ncwallet-app/navigation/src/screens/ExchangeFormScreen/ExchangeInput';
import {Text, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

import {LG_BREAKPOINT} from '../grid';
import {SafeAreaScrollView} from '../organisms/SafeAreaLayout';
import {Button} from './Button';
import {TouchableOpacity} from './TouchableOpacity';

export const GraphContainer = variance(View)(() => ({
  root: {
    flex: 1,
    flexBasis: 70,
    marginTop: 14,
    marginBottom: 14,
  },
}));

export const GraphContainerText = variance(Text)(theme => ({
  root: {
    marginBottom: 10,
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
  },
}));

export const ExchangeInputVariance = variance(ExchangeInput)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 2,
      },
    }),
  },
}));

export const SizedView = variance(View)(() => ({
  root: {
    width: 30,
  },
}));

export const Container = variance(SafeAreaScrollView)(() => ({
  root: {
    flex: 1,
    overflow: 'visible',
  },
}));

export const Inner = variance(ScrollView)(theme => ({
  root: {
    flex: 1,
    paddingTop: 30,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        paddingBottom: 10,
        backgroundColor: theme.palette.uiPrimary,
        ...theme.bar(10),
      },
    }),
  },
}));

export const Row = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 15,
      },
    }),
  },
}));

export const TopRow = variance(Row)(() => ({
  root: {
    justifyContent: 'space-between',
    marginBottom: 30,
  },
}));

export const ScreenTitleView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const ScreenTitleText = variance(Text)(theme => ({
  root: {
    marginLeft: 5,
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 18,
  },
}));

export const SwapCurrenciesTouchable = variance(TouchableOpacity)(theme => ({
  root: {
    flexDirection: 'row',
    marginLeft: 'auto',
    color: theme.palette.info,
    zIndex: 5,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        justifyContent: 'center',
        marginTop: 5,
        marginBottom: 5,
        marginLeft: -15,
        marginRight: -15,
        paddingVertical: 14,
        backgroundColor: theme.palette.additional3,
      },
    }),
  },
}));

export const SwapCurrenciesEmpty = variance(View)(() => ({
  root: {
    height: 45,
    marginVertical: 5,
  },
}));

export const SwapCurrenciesText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.info,
    fontSize: 14,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        ...theme.fontByWeight('700'),
        fontSize: 16,
      },
    }),
  },
}));

export const ExchangeRateText = variance(Text)(theme => ({
  root: {
    marginVertical: 20,
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional3,
    textAlign: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginVertical: 0,
      },
    }),
  },
}));

export const ErrorMinAmount = variance(TouchableOpacity)(() => ({
  root: {
    marginLeft: 5,
  },
}));

export const ExchangeErrorTextContainer = variance(View)(() => ({
  root: {
    marginTop: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    height: 20,
  },
  heightAuto: {
    height: undefined,
  },
}));

export const ExchangeErrorText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 13,
    color: theme.palette.error,
    textAlign: 'center',
  },
}));

export const ExchangeSubmit = variance(Button)(theme => ({
  root: {
    marginTop: 5,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        alignSelf: 'flex-end',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 30,
        paddingHorizontal: 130,
      },
    }),
  },
}));

export const GraphInfoView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
  },
}));

export const FiatValueText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
  },
}));

export const DayPeriodText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 12,
    color: theme.palette.textMain,
  },
}));
