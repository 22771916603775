import type {CurrencyDescription, DecimalString} from '@ncwallet-app/core';
import {
  keepMeaningfulDigitsInFiat,
  sized,
  useBalanceCryptoValue,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {
  CurrencyCircleLogo,
  LG_BREAKPOINT,
  PressableNativeFeedback,
  useIsDimensions,
} from '@ncwallet-app/ui/src';
import {CopySvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {BigNumber} from 'bignumber.js';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import PriceDiff from '../../../components/PriceDiff';

export type HeaderProps = {
  firstPrice: DecimalString | undefined;
  cryptoCurrency: CurrencyDescription | undefined;
  fiatRate: DecimalString | undefined;
  fiatCurrency: CurrencyDescription | undefined;
  cryptoValue?: DecimalString | undefined;
  fiatValue?: DecimalString | undefined;
  address?: string | undefined;
  onCopy?: () => void;
};

export default observer(function Header(props: HeaderProps) {
  return (
    <Root>
      <HeaderData {...props} />
    </Root>
  );
});

const HeaderData = observer((props: HeaderProps) => {
  const strings = useStrings();
  const {
    firstPrice,
    fiatCurrency,
    cryptoValue,
    cryptoCurrency,
    fiatValue,
    address,
    fiatRate,
    onCopy,
  } = props;
  const isLg = useIsDimensions('lg');
  const theme = useTheme();
  const formatCrypto = useBalanceCryptoValue(
    cryptoValue || '0',
    cryptoCurrency?.fractionDigits,
  );

  const diff =
    !isNil(fiatRate) &&
    !isNil(firstPrice) &&
    BigNumber(firstPrice).isGreaterThan(0)
      ? BigNumber(firstPrice).minus(fiatRate).div(firstPrice).toString()
      : undefined;

  if (isLg) {
    return (
      <HeaderMdContainer>
        <HeaderMdBannerRight>
          <CurrencyContainer>
            <CurrencyCircleLogo
              code={props.cryptoCurrency?.code || ''}
              size={32}
            />
            <CurrencyNameContainer>
              <CurrencyName>{props.cryptoCurrency?.code}</CurrencyName>
              <SizedBox />
              <CurrencyName>
                {strings['currencyGraph.mdHeader.price']}
              </CurrencyName>
            </CurrencyNameContainer>
            <CurrencyInfo>
              {!isNil(fiatRate) && (
                <Price offsetBottom>
                  <Text>{keepMeaningfulDigitsInFiat(fiatRate)}</Text>
                  <SizedBox />
                  <AccentCurrency offsetLeft>
                    {fiatCurrency?.code}
                  </AccentCurrency>
                </Price>
              )}
              <PriceDiffWrap>
                {!isNil(firstPrice) && !isNil(diff) && (
                  <PriceDiff firstPrice={firstPrice} diff={diff} medium />
                )}
              </PriceDiffWrap>
            </CurrencyInfo>
          </CurrencyContainer>
        </HeaderMdBannerRight>
        <MdDivider />
        <HeaderMdBannerLeft>
          <HeaderMdRow offsetBottom>
            <Total>{strings['currencyScreen.walletTitle']}</Total>
            {!isNil(fiatRate) && (
              <Price>
                <Text>{formatCrypto}</Text>
                <SizedBox />
                <AccentCurrency offsetLeft>
                  {cryptoCurrency?.code}
                </AccentCurrency>
              </Price>
            )}
          </HeaderMdRow>
          <HeaderMdRow>
            <LgAddressContainer>
              <LgAddress>
                {address?.slice(0, 4)}...{address?.slice(-5)}
              </LgAddress>
              <HeaderIcon onPress={onCopy}>
                <CopyIcon color={theme.palette.info} />
              </HeaderIcon>
            </LgAddressContainer>
            <CurrencyValue>{`${
              (fiatValue && keepMeaningfulDigitsInFiat(fiatValue)) ?? ''
            } ${fiatCurrency?.code ?? ''}`}</CurrencyValue>
          </HeaderMdRow>
        </HeaderMdBannerLeft>
      </HeaderMdContainer>
    );
  }

  return (
    <HeaderDataView>
      <MarketText>{strings['currencyGraph.smHeader.market']}</MarketText>
      <Price>
        <Text>{fiatRate && keepMeaningfulDigitsInFiat(fiatRate)}</Text>
        <AccentCurrency> {fiatCurrency?.code}</AccentCurrency>
      </Price>
      <ChangeInfoView>
        <PriceDiffWrap>
          {!isNil(firstPrice) && !isNil(diff) && (
            <PriceDiff firstPrice={firstPrice} diff={diff} />
          )}
        </PriceDiffWrap>
      </ChangeInfoView>
    </HeaderDataView>
  );
});

const CopyIcon = sized(CopySvg, 16, 16);

const Root = variance(View)(() => ({
  root: {
    height: 65,
    marginBottom: 35,
  },
}));

const CurrencyContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
}));

const LgAddressContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const LgAddress = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 12,
    lineHeight: 15,
    marginRight: 4,
    color: theme.palette.textAdditional2,
  },
}));

const Total = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.textPrimary,
  },
}));

const HeaderMdRow = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  offsetBottom: {
    marginBottom: 5,
  },
}));

const HeaderMdBannerRight = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 12,
    borderRadius: 8,
    backgroundColor: theme.palette.additional4,
    borderWidth: 1,
    borderColor: theme.palette.primary,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const HeaderIcon = variance(PressableNativeFeedback)(() => ({
  root: {
    padding: 3,
  },
}));

const HeaderMdBannerLeft = variance(View)(theme => ({
  root: {
    flex: 1,
    paddingHorizontal: 15,
    paddingVertical: 12,
    borderRadius: 8,
    backgroundColor: theme.palette.uiPrimary,
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const MdDivider = variance(View)(() => ({
  root: {
    width: 20,
  },
}));

const MarketText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.textAdditional1,
    marginBottom: 5,
    textAlign: 'center',
  },
}));

const CurrencyValue = variance(Text)(theme => ({
  root: {
    fontSize: 12,
    lineHeight: 15,
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
  },
}));

const CurrencyNameContainer = variance(View)(() => ({
  root: {
    marginLeft: 10,
    marginRight: 15,
    flexDirection: 'row',
    alignSelf: 'center',
    width: '100%',
    flex: 1,
    flexWrap: 'wrap',
  },
}));

const CurrencyName = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.textPrimary,
  },
}));

const CurrencyInfo = variance(View)(() => ({
  root: {
    alignItems: 'flex-end',
  },

  logo: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 20,
  },
}));

const HeaderMdContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const PriceDiffWrap = variance(View)(() => ({
  root: {
    height: 15,
  },
}));

const HeaderDataView = variance(View)(() => ({
  root: {
    alignItems: 'center',
  },
}));

const Price = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textPrimary,
    fontSize: 32,
    lineHeight: 39,
    textAlign: 'right',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 14,
        lineHeight: 15,
      },
    }),
  },

  offsetBottom: {
    marginBottom: 5,
  },
}));

const ChangeInfoView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    paddingHorizontal: 5,
    paddingVertical: 3,
    backgroundColor: theme.palette.uiPrimary,
    borderRadius: 10,
  },
}));

const AccentCurrency = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.primary,
  },
  offsetLeft: {
    marginLeft: 5,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    width: 5,
  },
}));
