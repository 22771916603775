import {action, makeObservable, observable} from 'mobx';
import {createRef} from 'react';
import type {
  NativeSyntheticEvent,
  TextInput,
  TextInputEndEditingEventData,
  TextInputProps,
  TextInputSubmitEditingEventData,
} from 'react-native';

export default class TextInputState implements ReadonlyTextInputState {
  @observable private _value?: string;
  @observable private _isFocused = false;
  private _ref = createRef<TextInput>();

  constructor(
    private readonly _options?: {
      defaultValue?: string;
      processText?: (text: string) => string;
    },
  ) {
    makeObservable(this);
    this._setValue(_options?.defaultValue);
  }

  private _onFocus = action(() => {
    this._isFocused = true;
  });

  private _onBlur = action(() => {
    this._isFocused = false;
  });

  @action private _setValue(value?: string) {
    if (value === undefined) {
      this._value = undefined;
    } else {
      this._value = this._options?.processText?.(value) ?? value;
    }
  }

  private _onChangeText = (value: string) => {
    this._setValue(value);
  };

  private _onEndEditing = (
    event: NativeSyntheticEvent<TextInputEndEditingEventData>,
  ) => {
    this._setValue(event.nativeEvent.text);
  };

  private _onSubmitEditing = (
    event: NativeSyntheticEvent<TextInputSubmitEditingEventData>,
  ) => {
    this._setValue(event.nativeEvent.text);
  };

  get value() {
    return this._value;
  }

  get isFocused() {
    return this._isFocused;
  }

  get handlers() {
    return {
      value: this._value,
      onChangeText: this._onChangeText,
      onEndEditing: this._onEndEditing,
      onSubmitEditing: this._onSubmitEditing,
      onFocus: this._onFocus,
      onBlur: this._onBlur,
      ref: this._ref,
    };
  }

  setValue = (value?: string) => {
    this._setValue(value);
  };

  focus = () => {
    this._ref.current?.focus();
  };

  blur = () => {
    this._ref.current?.blur();
  };

  @action.bound reset() {
    this._setValue(this._options?.defaultValue);
    this._ref.current?.blur();
    this._isFocused = false;
  }
}

export interface ReadonlyTextInputState {
  value: string | undefined;
  isFocused: boolean;
  handlers: TextInputProps;
}
