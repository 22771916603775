import {useRoot, useTheme, variance} from '@ncwallet-app/core';
import {Pressable} from '@ncwallet-app/ui/';
import {range} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {View} from 'react-native';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

export type DotsProps = {
  totalItems: number;
  currentSlideIndex: number;
  onPress: (idx: number) => void;
};

export const Pagination = observer(function Pagination(props: DotsProps) {
  const {totalItems, currentSlideIndex, onPress} = props;
  const {windowDimensionsState} = useRoot();
  const {width} = windowDimensionsState.window;
  return (
    <Root style={{width: width - 100}}>
      {range(0, totalItems).map((_, index) => (
        <DotItem
          key={index}
          isActive={currentSlideIndex === index}
          onPress={() => {
            onPress(index);
          }}
        />
      ))}
    </Root>
  );
});

type DotItemProps = {
  isActive: boolean;
  onPress: () => void;
};

const DotItem = observer(({isActive, onPress}: DotItemProps) => {
  const animatedValue = useSharedValue(0);
  const {palette} = useTheme();
  useEffect(() => {
    animatedValue.value = withSpring(isActive ? 1 : 0);
  }, [animatedValue, isActive]);
  const animatedStyles = useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      animatedValue.value,
      [0, 1],
      ['#8d8d8d', palette.primary],
    );
    return {
      backgroundColor,
    };
  }, [animatedValue, palette]);
  return (
    <Pressable onPress={onPress}>
      <Dot style={[animatedStyles]} />
    </Pressable>
  );
});

const Root = variance(View)(() => ({
  root: {
    paddingVertical: 30,
    flexDirection: 'row',
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
  },
}));

const Dot = variance(Animated.View)(theme => ({
  root: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.uiSecondary,
    marginHorizontal: 4,
  },
}));
