import {ReadyState} from '../Connection';
import type {LogContent} from './LogContent';

export default class WebSocketReadyStateLogContent implements LogContent {
  constructor(
    public readonly url: string,
    public readonly connectionId: number,
    public readonly readyState: ReadyState,
  ) {}

  get body() {
    const readyState = ReadyState[this.readyState];
    const url = this.readyState === ReadyState.Connecting ? this.url : '';
    const delimiter = url ? '\n' : '';
    return `#${this.connectionId} ${readyState}${delimiter}${url}`;
  }
}
