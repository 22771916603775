import {BigNumber} from 'bignumber.js';

import type {BaseAsyncOptions} from '../Async';
import type {CancellationError, GlobalError} from '../Error';
import {CANCELLATION_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {JsonRpcClient} from '../JsonRpc';
import type {CryptoCurrencyCode, CurrencyValue} from '../Money';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  WalletId,
} from '../NCWalletServer';
import type {
  Transaction,
  TransactionDirection,
  TransactionId,
  TransactionType,
} from '../NCWalletServer/WalletsTransactions';
import type {Time} from '../Time';
import {toISODateString} from '../Time';
import {getAggregationItemStatus} from './AggregationItemStatus';
import {WalletTransactions} from './WalletTransactionsRequestHelper';

import AggregationDetailedItem = WalletTransactions.AggregationDetailedItem;

export default class WalletTransactionsRequestHelperImpl
  implements WalletTransactions.RequestHelper
{
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly time: Time;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {}

  private static _kindMap = {
    exchange: WalletTransactions.AggregationItemKind.Exchange,
    withdraw: WalletTransactions.AggregationItemKind.Withdrawal,
    rollback: WalletTransactions.AggregationItemKind.Rollback,
    incoming: WalletTransactions.AggregationItemKind.Incoming,
    referral_incoming: WalletTransactions.AggregationItemKind.ReferralIncoming,
    promo_incoming: WalletTransactions.AggregationItemKind.PromoIncoming,
    refund: WalletTransactions.AggregationItemKind.Incoming,
  };

  async getNext(
    userOwned: Set<WalletId>,
    transactionType?: TransactionType,
    direction?: TransactionDirection,
    params?: WalletTransactions.GetNextParams,
    options?: BaseAsyncOptions,
  ): Promise<Either<WalletTransactions.Aggregation, GlobalError>> {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }
    const call_ = await this._root.ncWalletJsonRpcClient.call(
      'wallets.transactions',
      {
        date_from: params?.from && toISODateString(params.from),
        date_to: params?.to && toISODateString(params.to),
        limit: params?.limit,
        last_ts: params?.lastCreatedAt,
        wallets_ids: [...userOwned],
        direction,
        types: transactionType && [transactionType],
      },
      {signal: options?.signal},
    );
    if (!call_.success) {
      return call_;
    }

    const transactions = call_.right;
    return success({
      items: transactions.items.flatMap(t =>
        WalletTransactionsRequestHelperImpl.toAggregationItems(
          t,
          userOwned,
          direction,
        ),
      ),
      remainingItems: transactions.total - transactions.items.length,
    });
  }
  private static toAggregationType(t: Transaction): TransactionType {
    if (
      t.front_info?.frontType &&
      WalletTransactionsRequestHelperImpl._kindMap[t.front_info.frontType]
    ) {
      return t.front_info.frontType;
    }
    return t.type;
  }
  async getTransactionById(
    userOwned: Set<WalletId>,
    id: TransactionId,
  ): Promise<Either<AggregationDetailedItem, GlobalError>> {
    const call_ = await this._root.ncWalletJsonRpcClient.call(
      'wallets.transactions.get',
      {transaction_id: id},
    );

    if (!call_.success) {
      return call_;
    }

    const {transaction} = call_.right;
    const type =
      WalletTransactionsRequestHelperImpl.toAggregationType(transaction);
    const aggregatedTransaction: AggregationDetailedItem = {
      id: transaction.id,
      createdAt: transaction.created_at,
      status: getAggregationItemStatus(transaction),
      kind: WalletTransactionsRequestHelperImpl._kindMap[type],
      info: transaction.info,
      fromWalletId: transaction.from_wallet_id,
      walletId: transaction.to_wallet_id,
      currencyCode: transaction.to_currency,
      front_info: transaction.front_info,
      amountTo: transaction.to_amount,
      amountFrom: transaction.from_amount,
      comment: transaction.comment,
    };
    return success(aggregatedTransaction);
  }

  private static toAggregationItems(
    t: Transaction,
    userOwned: Set<WalletId>,
    direction?: TransactionDirection,
  ) {
    const sharedFields = {
      id: t.id,
      createdAt: t.created_at,
      status: getAggregationItemStatus(t),
      kind: WalletTransactionsRequestHelperImpl._kindMap[
        this.toAggregationType(t)
      ],
      info: t.info,
      front_info: t.front_info,
      comment: t.comment,
    };

    const res = [];

    if (userOwned.has(t.to_wallet_id) && (!direction || direction === 'to')) {
      res.push({
        ...sharedFields,
        walletId: t.to_wallet_id,
        currencyCode: t.to_currency,
        amount: t.to_amount,
      });
    }

    if (
      userOwned.has(t.from_wallet_id) &&
      (!direction || direction === 'from')
    ) {
      res.push({
        ...sharedFields,
        walletId: t.from_wallet_id,
        currencyCode: t.from_currency,
        amount: BigNumber(t.from_amount)
          .negated()
          .toString() as CurrencyValue<CryptoCurrencyCode>,
      });
    }

    return res;
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }
}
