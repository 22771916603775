import {useTheme, variance} from '@ncwallet-app/core';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {ArrowLeftWide} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text} from 'react-native';

type HeaderDropdownBackProps = {
  onPress: () => void;
  title?: string;
};

export default observer(function HeaderDropdownBack(
  props: HeaderDropdownBackProps,
) {
  const {onPress, title} = props;
  const theme = useTheme();

  return (
    <BackTouchable onPress={onPress}>
      <ArrowLeftWide color={theme.palette.uiMain} />
      <BackTouchableText>{title}</BackTouchableText>
    </BackTouchable>
  );
});

const BackTouchable = variance(TouchableOpacity)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 15,
    marginBottom: 30,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiAdditional2,
  },
}));

const BackTouchableText = variance(Text)(theme => ({
  root: {
    marginLeft: 20,
    ...theme.fontByWeight('700'),
    fontSize: 16,
    color: theme.palette.textMain,
  },
}));
