import {BigNumber} from 'bignumber.js';
import {isNil} from 'lodash';
import queryString from 'query-string';

import {PathImpl} from '../CommonNavigationScheme/Path';
import type {CurrencyStore} from '../dataStores';
import type {AddressNetwork} from '../NCWalletServer/AddressInfo';
import {first} from '../util';
import {CURRENCY_PREFIX_MAP} from './addPrefixForAddress';
import type {AddressKind, AddressParser, Receipt} from './AddressParser';
import {
  BITCOIN,
  ETHEREUM,
  getCurrencyCodeByAddressKind,
  MATIC,
  TETHER,
  TON,
} from './AddressParser';

const {parse} = queryString;

export default class AddressParserImpl implements AddressParser {
  constructor(
    private readonly _root: {
      readonly currencyStore: CurrencyStore;
    },
  ) {}

  parse(input: string): Receipt {
    const receipt = this._inputParseStrategy(input);
    if (receipt && receipt.kind) {
      return receipt;
    }

    return this.parseAddress(input);
  }

  parseAddress(input: string): Receipt {
    if (input.includes('transfer') || input.includes('?value')) {
      const path = PathImpl.parse(input);
      const address = first(path.params?.address);
      return {address: address ?? '', kind: undefined, finished: false};
    }
    const result = input.match(AddressParserImpl._ADDRESS_URL_REGEX);
    if (!result) {
      return {address: ''};
    }

    const [, address, queryString] = result;
    let amount;
    if (queryString) {
      const params = parse(queryString);
      if (typeof params.amount === 'string') {
        amount = BigNumber(params.amount).isPositive()
          ? params.amount
          : undefined;
      }
    }

    return {address, amount, finished: true};
  }

  private _inputParseStrategy(input: string) {
    const isTransferable = input.includes('transfer');
    const isNative = input.includes('?value');
    const isTonPaymentLink = input.includes('ton://transfer/');

    if (isTonPaymentLink) {
      return this._parseTonPaymentLink(input);
    }

    if (isTransferable) {
      return this._parseTransferableReceiptUrl(input);
    }

    if (isNative) {
      return this._parseNativeReceiptUrl(input);
    }

    return AddressParserImpl._parseReceiptUrl(input);
  }

  private static _parseReceiptUrl(input: string): Receipt | undefined {
    const result = input.match(AddressParserImpl._PROTOCOL_ADDRESS_URL_REGEX);

    if (result === null) {
      return undefined;
    }

    const [, coin, address, searchString] = result;
    const kind = AddressParserImpl._COIN_MAP[coin];
    let amount;
    if (searchString) {
      const params = parse(searchString);
      if (typeof params.amount === 'string') {
        amount = BigNumber(params.amount).isPositive()
          ? params.amount
          : undefined;
      }
    }
    return {kind, address, amount};
  }

  private _parseNativeReceiptUrl(input: string): Receipt | undefined {
    const result = input.match(AddressParserImpl._PROTOCOL_ADDRESS_URL_REGEX);
    if (result === null) {
      return undefined;
    }

    const [, blockchain, address, searchString] = result;
    const kind = AddressParserImpl._COIN_MAP[blockchain];
    if (!kind) {
      return undefined;
    }

    const code = getCurrencyCodeByAddressKind(kind);
    const cryptoExtra = this._root.currencyStore.getCryptoCurrenciesExtra();

    const lookingExtra = cryptoExtra?.find(
      it =>
        it.blockchain === blockchain &&
        (it.network === 'mainnet' || it.network === 'dev') &&
        it.contract === null,
    );

    if (!lookingExtra) {
      return undefined;
    }

    const params = parse(searchString);
    const _amount = typeof params.value === 'string' ? params.value : undefined;
    const [, exp] = _amount?.split('e') ?? [];
    const amountFinal = exp
      ? String(Number(_amount) / 10 ** Number(exp))
      : _amount;

    return {
      code,
      kind,
      address,
      amount: amountFinal ? amountFinal : undefined,
    };
  }

  private _parseTransferableReceiptUrl(input: string): Receipt | undefined {
    const matchRes = input.match(AddressParserImpl._PROTOCOL_ADDRESS_URL_REGEX);
    if (!matchRes) {
      return undefined;
    }

    const [, , contract] = matchRes;
    const chainId = input.match(/@([0-9]+)/gm);
    const chainIdFinal = chainId ? chainId[0].split('@')[1] : 1;
    const path = PathImpl.parse(input);
    const address = first(path.params?.address);
    const uint256 = first(path.params?.uint256);

    if (!address) {
      return undefined;
    }

    const cryptoExtra = this._root.currencyStore.getCryptoCurrenciesExtra();
    const lookingExtra = cryptoExtra?.find(
      it =>
        it.contract === contract.toLowerCase() &&
        it.chain_id === Number(chainIdFinal),
    );

    if (!lookingExtra) {
      return undefined;
    }

    const kind = AddressParserImpl._COIN_MAP[lookingExtra.blockchain];
    const {blockchain, network} = lookingExtra;
    const finalNetwork = `${blockchain}:${AddressParserImpl._normalizeNetwork(
      network,
    )}`;

    return {
      kind: kind,
      code: lookingExtra.currency,
      address: address,
      amount: isNil(uint256)
        ? undefined
        : BigNumber(uint256)
            .dividedBy(10 ** lookingExtra.decimals)
            .toString(),
      network: finalNetwork as AddressNetwork,
    };
  }

  private _parseTonPaymentLink(input: string): Receipt | undefined {
    const cryptoExtra = this._root.currencyStore.getCryptoCurrenciesExtra();
    const [, tail] = input.split('ton://transfer/');
    const [address, amount] = tail.split('?amount=');
    const code = getCurrencyCodeByAddressKind(TON);
    const lookingExtra = cryptoExtra?.find(
      it => it.currency === 'TON' && it.contract === null,
    );

    if (!lookingExtra) {
      return;
    }

    const finalNetwork = `${lookingExtra.blockchain}:${lookingExtra.network}`;

    return {
      kind: TON,
      code,
      address: address,
      amount: amount,
      network: finalNetwork as AddressNetwork,
    };
  }

  private static readonly _PROTOCOL_ADDRESS_URL_REGEX =
    /([0-9A-z]+):([0-9A-z-]+)(?:\?(.*))?/;

  private static readonly _ADDRESS_URL_REGEX = /([0-9A-z-]+)(?:\?(.*))?/;

  private static readonly _COIN_MAP: Partial<Record<string, AddressKind>> = {
    [CURRENCY_PREFIX_MAP.BTC]: BITCOIN,
    [CURRENCY_PREFIX_MAP.ETH]: ETHEREUM,
    [CURRENCY_PREFIX_MAP.USDT]: TETHER,
    [CURRENCY_PREFIX_MAP.MATIC]: MATIC,
    [CURRENCY_PREFIX_MAP.TON]: TON,
  };

  private static _normalizeNetwork(network: string) {
    if (network.includes('main')) {
      return 'main';
    }
    return network;
  }
}
