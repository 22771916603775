import {openPlatformSettings} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptDocumentCameraPermissionScreen from '../../../screens/PromptDocumentCameraPermissionsScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptDocumentPermissionBindingProps =
  SmallHomeStackBindingProps<'PromptDocumentPermission'>;

export default observer(function PromptDocumentPermissionBinding(
  props: PromptDocumentPermissionBindingProps,
) {
  const {navigation, route} = props;
  const {permissionKind} = route.params;
  const handleClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);
  const onConfirm = useCallback(() => {
    openPlatformSettings();
    handleClose();
  }, [handleClose]);

  return (
    <PromptDocumentCameraPermissionScreen
      permissionKind={permissionKind}
      onConfirm={onConfirm}
      onClose={handleClose}
    />
  );
});
