import type {CryptoCurrencyCode} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, reaction, runInAction} from 'mobx';
import {useCallback, useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {CurrencyHistoryRefresher} from '../../../shared/CurrencyHistoryRefresher';
import {CurrencySelectionListConverter} from '../../../shared/CurrencySelectionListConverter';
import {useRateHistoryStateOnWideScreen} from '../../../shared/useRateHistoryStateOnWideScreen';
import SelectionForExchangeBindingState from './SelectionForExchangeBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const useSelectionForExchangeBindingState = (
  exchangeFrom: boolean,
  disabledCode?: CryptoCurrencyCode,
) => {
  const root = useRoot();
  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  const [state] = useState(
    () =>
      new SelectionForExchangeBindingState(
        root,
        new CurrencySelectionListConverter(root),
        new CurrencyHistoryRefresher(root),
      ),
  );

  useEffect(() => state.subscribe(), [state]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh(exchangeFrom, disabledCode);
          });
        }
      }),
    [disabledCode, exchangeFrom, getIsFocused, getIsReady, state],
  );

  useRateHistoryStateOnWideScreen(state);

  const handleManualRefresh = useCallback(() => {
    void state.manualRefresh(exchangeFrom, disabledCode);
  }, [disabledCode, exchangeFrom, state]);

  useEffect(
    () =>
      reaction(
        () => state.isTimeoutError,
        shouldFire => {
          if (shouldFire) {
            root.rpcTimeoutErrorVisibility.registerAction(async () => {
              await root.walletsState.refresh();
              handleManualRefresh();
            });
          }
        },
      ),
    [
      state,
      root.rpcTimeoutErrorVisibility,
      root.walletsState,
      handleManualRefresh,
    ],
  );

  return {state, handleManualRefresh} as const;
};
