import {useStrings} from '@ncwallet-app/core';
import {useAccountState} from '@ncwallet-app/core/src/AccountStore';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {InputScreen} from '../../../components';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptUserIdBindingProps =
  SmallHomeStackBindingProps<'PromptUserId'>;

export default observer(function PromptUserIdBinding(
  props: PromptUserIdBindingProps,
) {
  const {navigation} = props;
  const state = useAccountState();
  const strings = useStrings();

  const handleSubmit = useCallback(
    async (id: string) => {
      void state.update({user_id: id});
      navigation.goBack();
    },
    [navigation, state],
  );

  return (
    <InputScreen
      title="Title"
      onSubmit={handleSubmit}
      value={state.account?.user_id || ''}
      label={strings['profileSettingsScreen.updateUserIdLabel']}
      placeholder={strings['profileSettingsScreen.updateUserIdPlaceholder']}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      keyboardAvoiding
    />
  );
});
