import {useTheme, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  ModalContainer,
  Spinner,
} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';

import type {ShowTransactionReportProps} from './ShowTransactionReportProps';

export default observer(function ShowTransactionReportModalScreen(
  props: ShowTransactionReportProps,
) {
  const {title, description, Icon, onClose, isLoading, actions} = props;
  const theme = useTheme();

  return (
    <Backdrop>
      <Body>
        <IconWrapper onPress={onClose}>
          <CrossSvg color={theme.palette.whitey} />
        </IconWrapper>
        <Icon />
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ButtonGroup>
          {isLoading ? (
            <SpinnerWrapper>
              <Spinner size={50} />
            </SpinnerWrapper>
          ) : (
            <>
              {actions.map(action => (
                <DetailButton
                  key={action.title}
                  Icon={action.Icon}
                  variant={ButtonVariant.PrimaryLight}
                  title={action.title}
                  onPress={action.onPress}
                  color={ButtonColor.Secondary}
                />
              ))}
            </>
          )}
        </ButtonGroup>
      </Body>
    </Backdrop>
  );
});

const SpinnerWrapper = variance(View)(() => ({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Backdrop = variance(ModalContainer)(theme => ({
  root: {
    backgroundColor: theme.chroma('#000000').alpha(0.7).hex(),
  },
}));

const IconWrapper = variance(TouchableOpacity)(theme => ({
  root: {
    position: 'absolute',
    top: 15,
    right: 15,
    borderRadius: 100,
    backgroundColor: theme.chroma(theme.palette.textPrimary).alpha(0.2).hex(),
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ButtonGroup = variance(View)(() => ({
  root: {
    alignSelf: 'stretch',
    marginTop: 40,
    gap: 20,
    flexDirection: 'row',
  },
}));

const DetailButton = variance(Button)(() => ({
  root: {
    flexGrow: 1,
  },
}));

const Body = variance(View)(theme => ({
  root: {
    padding: 40,
    paddingBottom: 20,
    borderRadius: 6,
    maxWidth: 620,
    width: '100%',
    backgroundColor: theme.palette.background,
    alignItems: 'center',
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 26,
    lineHeight: 36,
    marginTop: 20,
    marginBottom: 5,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    fontSize: 16,
    lineHeight: 26,
  },
}));
