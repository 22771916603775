import type {AccountAddressData} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import useAccountVerificationBindingState from '../../../CommonNavigationContainers/hooks/AccountVerification';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {IdentityVerificationAddressScreen} from '../../../screens/IdentityVerificationAddressScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptResidentialAddressBindingProps =
  SmallHomeStackBindingProps<'PromptResidentialAddress'>;

export default observer(function PromptResidentialAddressBinding(
  props: PromptResidentialAddressBindingProps,
) {
  const {navigation, route} = props;
  const verificationState = useAccountVerificationBindingState();

  const onSubmit = async (data: AccountAddressData) => {
    const res = await verificationState.saveAddressData(data);
    if (res.success) {
      navigation.navigate('ListDocuments');
    }
  };

  const handleCountrySelectPress = useCallback(() => {
    navigation.navigate('PromptIdentityVerificationAddress');
  }, [navigation]);

  return (
    <IdentityVerificationAddressScreen
      value={verificationState.addressData?.data}
      country={route.params?.country}
      onCountrySelect={handleCountrySelectPress}
      onSubmit={onSubmit}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});
