import {sized, useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {WarningSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type SessionIpListWarningProps = {
  onClearPressed?: () => void;
};

export default observer(function SessionIpListWarning(
  props: SessionIpListWarningProps,
) {
  const {onClearPressed} = props;
  const strings = useStrings();
  return (
    <IpWarningBanner>
      <Banner>
        <BannerIcon>
          <WarningIcon />
        </BannerIcon>
        <BannerText>{strings['blockedIpList.warning']}</BannerText>
      </Banner>
      <Button
        variant={ButtonVariant.Danger}
        title={strings['blockedIpList.action']}
        disabled={!onClearPressed}
        onPress={onClearPressed}
      />
    </IpWarningBanner>
  );
});

const IpWarningBanner = variance(View)(() => ({
  root: {
    marginBottom: 20,
  },
}));

const Banner = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'stretch',
    marginBottom: 20,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.primary,
  },
}));

const BannerIcon = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.primary,
    paddingHorizontal: 10,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    justifyContent: 'center',
  },
}));

const BannerText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    flex: 1,
    padding: 10,
    backgroundColor: theme.palette.additional2,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    color: theme.palette.textSecondary,
    fontSize: 14,
    lineHeight: 24,
  },
}));

const WarningIcon = sized(WarningSvg, 22, 22);
