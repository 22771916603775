import type React from 'react';
import type {SvgProps} from 'react-native-svg';

import {
  ADFlagSvg,
  AEFlagSvg,
  AFFlagSvg,
  AGFlagSvg,
  AIFlagSvg,
  ALFlagSvg,
  AMFlagSvg,
  AOFlagSvg,
  ARFlagSvg,
  ASFlagSvg,
  ATFlagSvg,
  AUFlagSvg,
  AWFlagSvg,
  AXFlagSvg,
  AZFlagSvg,
  BAFlagSvg,
  BBFlagSvg,
  BDFlagSvg,
  BEFlagSvg,
  BFFlagSvg,
  BGFlagSvg,
  BHFlagSvg,
  BIFlagSvg,
  BJFlagSvg,
  BLFlagSvg,
  BMFlagSvg,
  BNFlagSvg,
  BOFlagSvg,
  BQFlagSvg,
  BRFlagSvg,
  BSFlagSvg,
  BTFlagSvg,
  BVFlagSvg,
  BWFlagSvg,
  BYFlagSvg,
  BZFlagSvg,
  CAFlagSvg,
  CCFlagSvg,
  CDFlagSvg,
  CFFlagSvg,
  CGFlagSvg,
  CHFlagSvg,
  CIFlagSvg,
  CKFlagSvg,
  CLFlagSvg,
  CMFlagSvg,
  CNFlagSvg,
  COFlagSvg,
  CRFlagSvg,
  CUFlagSvg,
  CVFlagSvg,
  CWFlagSvg,
  CXFlagSvg,
  CYFlagSvg,
  CZFlagSvg,
  DeFlagSvg,
  DJFlagSvg,
  DKFlagSvg,
  DMFlagSvg,
  DOFlagSvg,
  DZFlagSvg,
  ECFlagSvg,
  EEFlagSvg,
  EGFlagSvg,
  EHFlagSvg,
  EnFlagSvg,
  ERFlagSvg,
  EsFlagSvg,
  ETFlagSvg,
  EUFlagSvg,
  FIFlagSvg,
  FJFlagSvg,
  FKFlagSvg,
  FMFlagSvg,
  FOFlagSvg,
  FrFlagSvg,
  GAFlagSvg,
  GBFlagSvg,
  GDFlagSvg,
  GEFlagSvg,
  GFFlagSvg,
  GGFlagSvg,
  GHFlagSvg,
  GIFlagSvg,
  GLFlagSvg,
  GMFlagSvg,
  GNFlagSvg,
  GPFlagSvg,
  GQFlagSvg,
  GRFlagSvg,
  GSFlagSvg,
  GTFlagSvg,
  GUFlagSvg,
  GWFlagSvg,
  GYFlagSvg,
  HKFlagSvg,
  HMFlagSvg,
  HNFlagSvg,
  HRFlagSvg,
  HTFlagSvg,
  HUFlagSvg,
  IDFlagSvg,
  IEFlagSvg,
  ILFlagSvg,
  IMFlagSvg,
  INFlagSvg,
  IOFlagSvg,
  IQFlagSvg,
  IRFlagSvg,
  ISFlagSvg,
  ItFlagSvg,
  JEFlagSvg,
  JMFlagSvg,
  JOFlagSvg,
  JPFlagSvg,
  KEFlagSvg,
  KGFlagSvg,
  KHFlagSvg,
  KIFlagSvg,
  KMFlagSvg,
  KNFlagSvg,
  KPFlagSvg,
  KRFlagSvg,
  KWFlagSvg,
  KYFlagSvg,
  KZFlagSvg,
  LAFlagSvg,
  LBFlagSvg,
  LCFlagSvg,
  LIFlagSvg,
  LKFlagSvg,
  LRFlagSvg,
  LSFlagSvg,
  LTFlagSvg,
  LUFlagSvg,
  LVFlagSvg,
  LYFlagSvg,
  MAFlagSvg,
  MCFlagSvg,
  MDFlagSvg,
  MEFlagSvg,
  MFFlagSvg,
  MGFlagSvg,
  MHFlagSvg,
  MKFlagSvg,
  MLFlagSvg,
  MMFlagSvg,
  MNFlagSvg,
  MOFlagSvg,
  MPFlagSvg,
  MQFlagSvg,
  MRFlagSvg,
  MSFlagSvg,
  MTFlagSvg,
  MUFlagSvg,
  MVFlagSvg,
  MWFlagSvg,
  MXFlagSvg,
  MYFlagSvg,
  MZFlagSvg,
  NAFlagSvg,
  NCFlagSvg,
  NEFlagSvg,
  NFFlagSvg,
  NGFlagSvg,
  NIFlagSvg,
  NLFlagSvg,
  NOFlagSvg,
  NPFlagSvg,
  NRFlagSvg,
  NUFlagSvg,
  NZFlagSvg,
  OMFlagSvg,
  PAFlagSvg,
  PEFlagSvg,
  PFFlagSvg,
  PGFlagSvg,
  PHFlagSvg,
  PKFlagSvg,
  PLFlagSvg,
  PMFlagSvg,
  PNFlagSvg,
  PRFlagSvg,
  PSFlagSvg,
  PtBRFlagSvg,
  PtFlagSvg,
  PWFlagSvg,
  PYFlagSvg,
  QAFlagSvg,
  REFlagSvg,
  ROFlagSvg,
  RSFlagSvg,
  RuFlagSvg,
  RWFlagSvg,
  SAFlagSvg,
  SBFlagSvg,
  SCFlagSvg,
  SDFlagSvg,
  SEFlagSvg,
  SGFlagSvg,
  SHFlagSvg,
  SIFlagSvg,
  SJFlagSvg,
  SKFlagSvg,
  SLFlagSvg,
  SMFlagSvg,
  SNFlagSvg,
  SOFlagSvg,
  SRFlagSvg,
  SSFlagSvg,
  STFlagSvg,
  SVFlagSvg,
  SXFlagSvg,
  SYFlagSvg,
  SZFlagSvg,
  TCFlagSvg,
  TDFlagSvg,
  TFFlagSvg,
  TGFlagSvg,
  THFlagSvg,
  TJFlagSvg,
  TKFlagSvg,
  TLFlagSvg,
  TMFlagSvg,
  TNFlagSvg,
  TOFlagSvg,
  TRFlagSvg,
  TTFlagSvg,
  TVFlagSvg,
  TWFlagSvg,
  TZFlagSvg,
  UAFlagSvg,
  UGFlagSvg,
  UMFlagSvg,
  USFlagSvg,
  UYFlagSvg,
  UZFlagSvg,
  VAFlagSvg,
  VCFlagSvg,
  VEFlagSvg,
  VGFlagSvg,
  VIFlagSvg,
  VNFlagSvg,
  VUFlagSvg,
  WFFlagSvg,
  WSFlagSvg,
  XKFlagSvg,
  YEFlagSvg,
  YTFlagSvg,
  ZAFlagSvg,
  ZMFlagSvg,
  ZWFlagSvg,
} from '../../../assets/svg/colored';

// eslint-disable-next-line import-x/prefer-default-export
export const flags = {
  FR: FrFlagSvg,
  RU: RuFlagSvg,
  EN: EnFlagSvg,
  IT: ItFlagSvg,
  DE: DeFlagSvg,
  PT: PtFlagSvg,
  PT_BR: PtBRFlagSvg,
  ES: EsFlagSvg,
  AD: ADFlagSvg,
  AE: AEFlagSvg,
  AF: AFFlagSvg,
  AG: AGFlagSvg,
  AI: AIFlagSvg,
  AM: AMFlagSvg,
  AS: ASFlagSvg,
  AR: ARFlagSvg,
  AT: ATFlagSvg,
  AL: ALFlagSvg,
  AU: AUFlagSvg,
  AO: AOFlagSvg,
  AW: AWFlagSvg,
  AX: AXFlagSvg,
  AZ: AZFlagSvg,
  BA: BAFlagSvg,
  BB: BBFlagSvg,
  BD: BDFlagSvg,
  BE: BEFlagSvg,
  BF: BFFlagSvg,
  BG: BGFlagSvg,
  BH: BHFlagSvg,
  BI: BIFlagSvg,
  BJ: BJFlagSvg,
  BL: BLFlagSvg,
  BM: BMFlagSvg,
  BN: BNFlagSvg,
  BO: BOFlagSvg,
  BQ: BQFlagSvg,
  BR: BRFlagSvg,
  BS: BSFlagSvg,
  BT: BTFlagSvg,
  BV: BVFlagSvg,
  BW: BWFlagSvg,
  BY: BYFlagSvg,
  BZ: BZFlagSvg,
  CA: CAFlagSvg,
  CC: CCFlagSvg,
  CD: CDFlagSvg,
  CF: CFFlagSvg,
  CG: CGFlagSvg,
  CH: CHFlagSvg,
  CI: CIFlagSvg,
  CK: CKFlagSvg,
  CL: CLFlagSvg,
  CM: CMFlagSvg,
  CN: CNFlagSvg,
  CO: COFlagSvg,
  CR: CRFlagSvg,
  CU: CUFlagSvg,
  CV: CVFlagSvg,
  CW: CWFlagSvg,
  CX: CXFlagSvg,
  CY: CYFlagSvg,
  CZ: CZFlagSvg,
  DJ: DJFlagSvg,
  DK: DKFlagSvg,
  DM: DMFlagSvg,
  DO: DOFlagSvg,
  DZ: DZFlagSvg,
  EC: ECFlagSvg,
  EE: EEFlagSvg,
  EG: EGFlagSvg,
  EH: EHFlagSvg,
  ER: ERFlagSvg,
  ET: ETFlagSvg,
  EU: EUFlagSvg,
  FI: FIFlagSvg,
  FJ: FJFlagSvg,
  FK: FKFlagSvg,
  FM: FMFlagSvg,
  FO: FOFlagSvg,
  GA: GAFlagSvg,
  GB: GBFlagSvg,
  GD: GDFlagSvg,
  GE: GEFlagSvg,
  GF: GFFlagSvg,
  GG: GGFlagSvg,
  GH: GHFlagSvg,
  GI: GIFlagSvg,
  GL: GLFlagSvg,
  GM: GMFlagSvg,
  GN: GNFlagSvg,
  GP: GPFlagSvg,
  GQ: GQFlagSvg,
  GR: GRFlagSvg,
  GS: GSFlagSvg,
  GT: GTFlagSvg,
  GU: GUFlagSvg,
  GW: GWFlagSvg,
  GY: GYFlagSvg,
  HK: HKFlagSvg,
  HM: HMFlagSvg,
  HN: HNFlagSvg,
  HR: HRFlagSvg,
  HT: HTFlagSvg,
  HU: HUFlagSvg,
  ID: IDFlagSvg,
  IE: IEFlagSvg,
  IL: ILFlagSvg,
  IM: IMFlagSvg,
  IN: INFlagSvg,
  IO: IOFlagSvg,
  IQ: IQFlagSvg,
  IR: IRFlagSvg,
  IS: ISFlagSvg,
  JE: JEFlagSvg,
  JM: JMFlagSvg,
  JO: JOFlagSvg,
  JP: JPFlagSvg,
  KE: KEFlagSvg,
  KG: KGFlagSvg,
  KH: KHFlagSvg,
  KI: KIFlagSvg,
  KM: KMFlagSvg,
  KN: KNFlagSvg,
  KP: KPFlagSvg,
  KR: KRFlagSvg,
  KW: KWFlagSvg,
  KY: KYFlagSvg,
  KZ: KZFlagSvg,
  LA: LAFlagSvg,
  LB: LBFlagSvg,
  LC: LCFlagSvg,
  LI: LIFlagSvg,
  LK: LKFlagSvg,
  LR: LRFlagSvg,
  LS: LSFlagSvg,
  LT: LTFlagSvg,
  LU: LUFlagSvg,
  LV: LVFlagSvg,
  LY: LYFlagSvg,
  MA: MAFlagSvg,
  MC: MCFlagSvg,
  MD: MDFlagSvg,
  ME: MEFlagSvg,
  MF: MFFlagSvg,
  MG: MGFlagSvg,
  MH: MHFlagSvg,
  MK: MKFlagSvg,
  ML: MLFlagSvg,
  MM: MMFlagSvg,
  MN: MNFlagSvg,
  MO: MOFlagSvg,
  MP: MPFlagSvg,
  MQ: MQFlagSvg,
  MR: MRFlagSvg,
  MS: MSFlagSvg,
  MT: MTFlagSvg,
  MU: MUFlagSvg,
  MV: MVFlagSvg,
  MW: MWFlagSvg,
  MX: MXFlagSvg,
  MY: MYFlagSvg,
  MZ: MZFlagSvg,
  NA: NAFlagSvg,
  NC: NCFlagSvg,
  NE: NEFlagSvg,
  NF: NFFlagSvg,
  NG: NGFlagSvg,
  NI: NIFlagSvg,
  NL: NLFlagSvg,
  NO: NOFlagSvg,
  NP: NPFlagSvg,
  NR: NRFlagSvg,
  NU: NUFlagSvg,
  NZ: NZFlagSvg,
  OM: OMFlagSvg,
  PA: PAFlagSvg,
  PE: PEFlagSvg,
  PF: PFFlagSvg,
  PG: PGFlagSvg,
  PH: PHFlagSvg,
  PK: PKFlagSvg,
  PL: PLFlagSvg,
  PM: PMFlagSvg,
  PN: PNFlagSvg,
  PR: PRFlagSvg,
  PS: PSFlagSvg,
  PW: PWFlagSvg,
  PY: PYFlagSvg,
  QA: QAFlagSvg,
  RE: REFlagSvg,
  RO: ROFlagSvg,
  RS: RSFlagSvg,
  RW: RWFlagSvg,
  SA: SAFlagSvg,
  SB: SBFlagSvg,
  SC: SCFlagSvg,
  SD: SDFlagSvg,
  SE: SEFlagSvg,
  SG: SGFlagSvg,
  SH: SHFlagSvg,
  SI: SIFlagSvg,
  SJ: SJFlagSvg,
  SK: SKFlagSvg,
  SL: SLFlagSvg,
  SM: SMFlagSvg,
  SN: SNFlagSvg,
  SO: SOFlagSvg,
  SR: SRFlagSvg,
  SS: SSFlagSvg,
  ST: STFlagSvg,
  SV: SVFlagSvg,
  SX: SXFlagSvg,
  SY: SYFlagSvg,
  SZ: SZFlagSvg,
  TC: TCFlagSvg,
  TD: TDFlagSvg,
  TF: TFFlagSvg,
  TG: TGFlagSvg,
  TH: THFlagSvg,
  TJ: TJFlagSvg,
  TK: TKFlagSvg,
  TL: TLFlagSvg,
  TM: TMFlagSvg,
  TN: TNFlagSvg,
  TO: TOFlagSvg,
  TR: TRFlagSvg,
  TT: TTFlagSvg,
  TV: TVFlagSvg,
  TW: TWFlagSvg,
  TZ: TZFlagSvg,
  UA: UAFlagSvg,
  UG: UGFlagSvg,
  UM: UMFlagSvg,
  US: USFlagSvg,
  UY: UYFlagSvg,
  UZ: UZFlagSvg,
  VA: VAFlagSvg,
  VC: VCFlagSvg,
  VE: VEFlagSvg,
  VG: VGFlagSvg,
  VI: VIFlagSvg,
  VN: VNFlagSvg,
  VU: VUFlagSvg,
  WF: WFFlagSvg,
  XK: XKFlagSvg,
  WS: WSFlagSvg,
  YE: YEFlagSvg,
  YT: YTFlagSvg,
  ZA: ZAFlagSvg,
  ZM: ZMFlagSvg,
  ZW: ZWFlagSvg,
} as Record<string, React.FC<SvgProps>>;
