import queryString from 'query-string';

import type {GeneralRestClientError, GlobalError, UnknownError} from '../Error';
import {GENERAL_REST_CLIENT_ERROR, UNKNOWN_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {Http} from '../Http';
import type {Json, JsonSerializable} from '../Json';
import type {Message, MessageMeta, RemoteStoredMessageUrl} from './Message';
import type {RemoteMessageStore} from './RemoteMessageStore';
import {MESSAGING_PROTOCOL_VERSION} from './units';

const {stringify} = queryString;

export default class RemoteMessageStoreImpl implements RemoteMessageStore {
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly json: Json;
      readonly http: Http;
    },
  ) {}

  async fetch(
    messageUrl: RemoteStoredMessageUrl,
    meta: MessageMeta,
  ): Promise<Either<Message, GlobalError>> {
    try {
      const init = `${messageUrl}?${stringify({
        sv: MESSAGING_PROTOCOL_VERSION,
      })}`;

      const body_ = this._root.json.stringify(meta);
      if (!body_.success) {
        return body_;
      }
      const response_ = await this._root.http.fetch(init, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: body_.right,
        mode: 'cors',
      });
      if (!response_.success) {
        return response_;
      }
      let responseBody;
      try {
        responseBody = (await response_.right.json()) as JsonSerializable;
      } catch {
        /* empty */
      }
      if (response_.right.ok && responseBody !== undefined) {
        return success(responseBody as Message);
      }
      return error(
        this._root.errorRepository.create<GeneralRestClientError>({
          kind: GENERAL_REST_CLIENT_ERROR,
          raw: response_.right,
          body: responseBody,
          statusCode: response_.right.status,
        }),
      );
    } catch (raw) {
      return error(
        this._root.errorRepository.create<UnknownError>({
          kind: UNKNOWN_ERROR,
          raw,
          description: 'Remote message request failed',
        }),
      );
    }
  }
}
