import {variance} from '@ncwallet-app/core';
import {BaseSkeleton, SkeletonColor} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export default observer(function HistorySkeletonListItem() {
  return (
    <HistorySkeletonView>
      <BaseSkeleton width={40} height={40} isRounded />
      <HistorySkeletonBodyView>
        <HistorySkeletonBodyTopView>
          <BaseSkeleton width={110} height={15} />
          <BaseSkeleton width={120} height={15} />
        </HistorySkeletonBodyTopView>
        <HistorySkeletonBodyBottomView>
          <BaseSkeleton width={80} height={15} color={SkeletonColor.Light} />
          <BaseSkeleton width={80} height={15} color={SkeletonColor.Light} />
        </HistorySkeletonBodyBottomView>
      </HistorySkeletonBodyView>
    </HistorySkeletonView>
  );
});

const HistorySkeletonView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const HistorySkeletonBodyView = variance(View)(() => ({
  root: {
    flex: 1,
    marginLeft: 15,
  },
}));

const HistorySkeletonBodyTopView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
}));

const HistorySkeletonBodyBottomView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
