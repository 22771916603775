import type {AccountPersonalData} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {useCallback, useEffect, useState} from 'react';

import {PersonalFormState} from './PersonalFormState';

export type PersonalFormStateProps = {
  value: AccountPersonalData | undefined;
  onSubmit: (form: AccountPersonalData) => void;
};
export const FIRST_NAME_LENGTH = 30;
export const LAST_NAME_LENGTH = 30;
export const BIRTHDAY_LENGTH = 10;
export const usePersonalFormState = (props: PersonalFormStateProps) => {
  const {value, onSubmit} = props;

  const [formState] = useState(() => new PersonalFormState());
  useEffect(() => {
    formState.setInitialValue(value);
  }, [formState, value]);

  const handleSubmit = useCallback(() => {
    formState.submit();
    if (formState.isValid) {
      onSubmit(formState.getValue());
    }
  }, [formState, onSubmit]);

  return {formState, handleSubmit};
};
