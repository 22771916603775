import {variance} from '@ncwallet-app/core';
import {ModalContainer, TouchableOpacity} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {BackHandler, View} from 'react-native';

import PromptCloseAppScreen from '../../../screens/PromptCloseAppScreen';
import type {LargeHomeStackBindingProps} from '../LargeHomeStackBindingProps';

export type PromptAccountDeletionBindingProps =
  LargeHomeStackBindingProps<'PromptCloseApp'>;

export default observer(function PromptAccountDeletionBinding({
  navigation,
}: PromptAccountDeletionBindingProps) {
  const handleCloseApp = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
    BackHandler.exitApp();
  }, [navigation]);

  const handleCancel = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.reset({index: 0, routes: [{name: 'Root'}]});
    }
  }, [navigation]);

  return (
    <Backdrop>
      <Card>
        <Circle>
          <TouchableOpacity onPress={handleCancel}>
            <CrossIcon />
          </TouchableOpacity>
        </Circle>
        <PromptCloseAppScreen
          onConfirm={handleCloseApp}
          onCancel={handleCancel}
        />
      </Card>
    </Backdrop>
  );
});

const Circle = variance(View)(theme => ({
  root: {
    position: 'absolute',
    top: 15,
    right: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: theme.palette.uiSecondary,
  },
}));

const CrossIcon = variance(CrossSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.whitey,
    width: 22,
    height: 22,
  }),
);

const Backdrop = variance(ModalContainer)(theme => ({
  root: {
    backgroundColor: theme.chroma('#000000').alpha(0.7).hex(),
  },
}));

const Card = variance(View)(theme => ({
  root: {
    borderRadius: 8,
    backgroundColor: theme.palette.background,
    width: 380,
    paddingBottom: 20,
  },
}));
