import {useRoot} from '@ncwallet-app/core';
import type {
  PromptOtpToDeleteIpRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {IpInfoId} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import type {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {useCallback, useState} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useDeleteIp = (
  goToPromptOtp: (params: RouteParams<PromptOtpToDeleteIpRoute>) => void,
  goToIpList: (type: IpType) => void,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const root = useRoot();

  const deleteIp = useCallback(
    async (id: IpInfoId, type: IpType) => {
      if (isSubmitting) {
        return;
      }

      setIsSubmitting(true);

      const isTwoFaRequiredRes =
        await root.twoFaHelper.isTwoFaRequiredForIpSettings();
      if (!isTwoFaRequiredRes.success) {
        setIsSubmitting(false);
        return;
      }
      if (isTwoFaRequiredRes.right) {
        setIsSubmitting(false);
        goToPromptOtp({id, type});
        return;
      }

      const res = await root.ncWalletJsonRpcClient.call(
        'accounts.ip_settings.delete',
        {id},
      );
      setIsSubmitting(false);
      if (res.success) {
        goToIpList(type);
      } else {
        root.flashMessage.showMessage({
          title: root.errorParser.describe(res.left).summary,
          variant: 'danger',
        });
      }
    },
    [goToIpList, goToPromptOtp, isSubmitting, root],
  );

  return {
    deleteIp,
  };
};
