import type {CryptoCurrencyCode} from '@ncwallet-app/core';
import type {PromptOutputAddressRoute} from '@ncwallet-app/core/src/CommonNavigationScheme';

import type {ParamsForOutput} from '../hooks/getParamsForOutputAfterQrScan';

type Route = {params?: {addressCurrency?: CryptoCurrencyCode}};

export const checkCryptoCurrencyAfterScanning = (
  prevRoute: Route,
  params: ParamsForOutput,
) => {
  return (
    prevRouteGuard(prevRoute) &&
    (params.addressNetwork === prevRoute.params.addressNetwork ||
      prevRoute.params.addressNetwork.includes('ethereum'))
  );
};

export function prevRouteGuard(
  route: Route,
): route is PromptOutputAddressRoute {
  return !!route.params?.addressCurrency;
}
