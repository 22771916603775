import type {
  AdvertisingId,
  AdvertisingIdentifier,
} from './AdvertisingIdentifier';

export default class AdvertisingIdentifierSubImpl
  implements AdvertisingIdentifier
{
  async getInfo(): Promise<AdvertisingId | undefined> {
    return undefined;
  }
}
