import type {BaseAsyncOptions} from '../Async';
import type {CancellationError, GlobalError} from '../Error';
import {CANCELLATION_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {JsonKeyValueMap, JsonKeyValueStore} from '../JsonKeyValueStore';
import type {Time} from '../Time';
import type {
  AddressHistoryItem,
  AddressHistoryRepository,
} from './AddressHistoryRepository';

export default class AddressHistoryRepositoryImpl
  implements AddressHistoryRepository
{
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly time: Time;
      readonly jsonKeyValueStore: JsonKeyValueStore<JsonKeyValueMap>;
    },
  ) {}

  async getAll(): Promise<Either<AddressHistoryItem[], GlobalError>> {
    const collection_ = await this._root.jsonKeyValueStore.get(
      'addressHistoryCollection',
    );
    if (!collection_.success) {
      return collection_;
    }
    if (!collection_.right) {
      return success([]);
    }
    return success(
      collection_.right.entries.map(([address, updatedAt]) => ({
        address,
        updatedAt,
      })),
    );
  }

  async add(
    address: string,
    options?: BaseAsyncOptions,
  ): Promise<Either<void, GlobalError>> {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }
    const collection_ = await this._root.jsonKeyValueStore.get(
      'addressHistoryCollection',
      options,
    );
    if (!collection_.success) {
      return collection_;
    }
    const now = this._root.time.now();
    if (!collection_.right) {
      return this._root.jsonKeyValueStore.set(
        'addressHistoryCollection',
        {entries: [[address, now]]},
        options,
      );
    }
    return this._root.jsonKeyValueStore.set(
      'addressHistoryCollection',
      {
        entries: [
          [address, now],
          ...collection_.right.entries.filter(([_]) => _ !== address),
        ],
      },
      options,
    );
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }
}
