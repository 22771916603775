import {useRoot} from '@ncwallet-app/core';
import type {IpSettings} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/AccountsIpSettingsGet';
import {useCallback, useState} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useIpSettings = () => {
  const root = useRoot();
  const [ipSettings, setIpSettings] = useState<IpSettings | undefined>();

  const loadIpSettings = useCallback(async () => {
    const ipListRes = await root.ncWalletJsonRpcClient.call(
      'accounts.ip_settings.get',
      {},
    );
    if (!ipListRes.success) {
      return;
    }
    setIpSettings({
      white_ips: ipListRes.right.white_ips ?? [],
      black_ips: ipListRes.right.black_ips ?? [],
    });
  }, [root.ncWalletJsonRpcClient]);

  return {
    ipSettings,
    loadIpSettings,
  };
};
