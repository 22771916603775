import type {
  CommonNavigationAction,
  DefaultRouterOptions,
  NavigationState,
  ParamListBase,
  PartialState,
  Router,
} from '@react-navigation/native';
import type {RouterConfigOptions} from '@react-navigation/routers/lib/typescript/src/types';
import {nanoid} from 'nanoid/non-secure';

import {first} from '../../util';
import probe from '../../util/probe';

export type SwitchActionType = {
  type: 'JUMP_TO';
  payload: {name: string; params?: object};
  source?: string;
  target?: string;
};

export type SwitchRouterOptions = DefaultRouterOptions;

export type SwitchNavigationState<ParamList extends ParamListBase> = Omit<
  NavigationState<ParamList>,
  'history'
> & {
  type: 'switch';
  history: SwitchHistoryRecord[];
};

export type SwitchHistoryRecord<
  RouteName extends string = string,
  Params extends object | undefined = object | undefined,
> = {
  name: RouteName;
  path?: string;
  state?: NavigationState | PartialState<NavigationState>;
} & (undefined extends Params
  ? {params?: Readonly<Params>}
  : {params: Readonly<Params>});

const ROUTE_KEY = 'switch';

export type SwitchActionHelpers<ParamList extends ParamListBase> = {
  /**
   * Jump to an existing screen.
   */
  jumpTo<RouteName extends Extract<keyof ParamList, string>>(
    ...args: undefined extends ParamList[RouteName]
      ? [screen: RouteName, params?: ParamList[RouteName]]
      : [screen: RouteName, params: ParamList[RouteName]]
  ): void;
};

export const SwitchActions = {
  jumpTo(name: string, params?: object): SwitchActionType {
    return {type: 'JUMP_TO', payload: {name, params}};
  },
};

export default function SwitchRouter(options: SwitchRouterOptions) {
  const {initialRouteName} = options;
  const router: Router<
    SwitchNavigationState<ParamListBase>,
    SwitchActionType | CommonNavigationAction
  > = {
    type: 'switch',

    getInitialState(config: RouterConfigOptions) {
      const {routeNames, routeParamList} = config;

      const name = initialRouteName ?? first(routeNames);

      return {
        stale: false,
        type: 'switch',
        key: `switch-${nanoid()}`,
        index: 0,
        routes:
          name !== undefined
            ? [
                {
                  key: ROUTE_KEY,
                  name,
                  params: routeParamList[name],
                },
              ]
            : [],
        routeNames,
        history: [],
      };
    },

    getRehydratedState(partialState, config) {
      const {routeNames, routeParamList} = config;

      const state = partialState;

      if (state.stale === false) {
        return state;
      }

      const name = initialRouteName ?? first(routeNames);

      let currentRoute;
      if (state.index === undefined) {
        if (name !== undefined) {
          currentRoute = state.routes.find(_ => _.name === name);
        }
      } else {
        currentRoute = state.routes[state.index];
      }

      if (!currentRoute || !routeNames.includes(currentRoute.name)) {
        if (name !== undefined) {
          currentRoute = {
            name: name,
            params: routeParamList[name],
          };
        }
      }

      return {
        stale: false,
        type: 'switch',
        key: state.key ?? `switch-${nanoid()}`,
        index: 0,
        routes:
          currentRoute !== undefined
            ? [
                {
                  ...currentRoute,
                  key: ROUTE_KEY,
                },
              ]
            : [],
        routeNames,
        history: state.history ?? [],
      };
    },

    getStateForRouteNamesChange(state, config) {
      // Route names never change by design
      return router.getInitialState(config);
    },

    getStateForRouteFocus(state) {
      return state;
    },

    getStateForAction(state, action, config) {
      const {routeParamList, routeNames} = config;
      switch (action.type) {
        case 'NAVIGATE': {
          if (
            action.payload.name === undefined ||
            !routeNames.includes(action.payload.name)
          ) {
            return null;
          }

          const currentRoute = probe(state.routes, state.index);

          if (!currentRoute) {
            return {
              ...state,
              index: 0,
              routes: [
                {
                  key: ROUTE_KEY,
                  name: action.payload.name,
                  params: {
                    ...routeParamList[action.payload.name],
                    ...action.payload.params,
                  },
                  path: action.payload.path,
                },
              ],
            };
          }

          return {
            ...state,
            index: 0,
            routes: [
              {
                key: ROUTE_KEY,
                name: action.payload.name,
                params: action.payload.merge
                  ? {
                      ...routeParamList[action.payload.name],
                      ...currentRoute.params,
                      ...action.payload.params,
                    }
                  : {
                      ...routeParamList[action.payload.name],
                      ...action.payload.params,
                    },
                path: action.payload.path,
              },
            ],
            history: [
              ...state.history,
              {
                name: currentRoute.name,
                params: currentRoute.params,
                path: currentRoute.path,
                state: currentRoute.state,
              },
            ],
          };
        }

        case 'GO_BACK': {
          if (state.history.length === 0) {
            return null;
          }

          const record = state.history[state.history.length - 1];

          return {
            ...state,
            index: 0,
            routes: [
              {
                key: ROUTE_KEY,
                name: record.name,
                params: record.params,
                path: record.path,
                state: record.state,
              },
            ],
            history: state.history.slice(0, -1),
          };
        }

        case 'SET_PARAMS': {
          const currentRoute = probe(state.routes, state.index);

          if (!currentRoute) {
            return null;
          }

          return {
            ...state,
            index: 0,
            routes: [
              {
                ...currentRoute,
                params: {...currentRoute.params, ...action.payload.params},
                path: undefined,
              },
            ],
          };
        }

        case 'JUMP_TO': {
          const currentRoute = probe(state.routes, state.index);
          if (!currentRoute || currentRoute.name !== action.payload.name) {
            return router.getStateForAction(
              state,
              {...action, type: 'NAVIGATE'},
              config,
            );
          }
          return {
            ...state,
            index: 0,
            routes: [{...currentRoute, params: action.payload.params}],
          };
        }

        case 'RESET': {
          const nextState = action.payload as
            | SwitchNavigationState<ParamListBase>
            | PartialState<SwitchNavigationState<ParamListBase>>
            | undefined;

          if (!nextState) {
            return null;
          }

          if (
            nextState.routes.length === 0 ||
            nextState.routes.some(
              (route: {name: string}) => !state.routeNames.includes(route.name),
            )
          ) {
            return null;
          }

          if (nextState.stale === false) {
            if (
              state.routeNames.length !== nextState.routeNames.length ||
              nextState.routeNames.some(
                name => !state.routeNames.includes(name),
              )
            ) {
              return null;
            }

            const nextRoute = probe(nextState.routes, nextState.index);

            if (!nextRoute) {
              return null;
            }

            return {
              ...nextState,
              index: 0,
              routes: [
                {
                  ...nextRoute,
                  key: ROUTE_KEY,
                },
              ],
            };
          }

          return nextState;
        }

        default:
          return null;
      }
    },

    shouldActionChangeFocus(action) {
      return action.type === 'NAVIGATE';
    },

    actionCreators: SwitchActions,
  };

  return router;
}
