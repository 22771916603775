import type {QrCodeHistoryEntry} from '@ncwallet-app/core/';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import QrCodeHistoryList from './QrCodeHistoryList';

export type QrCodeHistoryScreenProps = BaseSafeAreaProps & {
  getEntries: () => QrCodeHistoryEntry[] | undefined;
  onEntrySelect: (entry: QrCodeHistoryEntry) => void;
  onEntryNavigate: (entry: QrCodeHistoryEntry) => void;
  onRefresh: () => void;
  getIsRefreshing: () => boolean;
  goBack: () => void;
};

export default observer(function QrCodeHistoryScreen(
  props: QrCodeHistoryScreenProps,
) {
  return <QrCodeHistoryList {...props} />;
});
