import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, runInAction} from 'mobx';
import {useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../hooks';
import SessionHistoryBindingState from './SessionHistoryBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const useSessionHistoryBindingState = () => {
  const root = useRoot();
  const [state] = useState(() => new SessionHistoryBindingState(root));

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, state],
  );

  return state;
};
