import {useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo} from 'react';

import type {PromptOtpToExchangeContainerProps} from '../../../CommonNavigationContainers/hooks/usePromptOtpToExchangeContainer';
import {usePromptOtpToExchangeContainer} from '../../../CommonNavigationContainers/hooks/usePromptOtpToExchangeContainer';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {useSendTelegramOtp} from '../../../Navigation/hooks/useSendTelegramOtp';
import {
  CARD,
  SubmitOtpScreen,
} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptOtpToExchangeBindingProps =
  SmallHomeStackBindingProps<'PromptOtpToExchange'>;

export default observer(function PromptOtpToExchangeBinding({
  route,
  navigation,
}: PromptOtpToExchangeBindingProps) {
  const strings = useStrings();
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();
  const root = useRoot();

  const handleTwoFaResendCode = useCallback(async () => {
    try {
      await sendOtp(null, {forceChannel: false});
    } catch {
      /* empty */
    }
  }, [sendOtp]);

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({index: 0, routes: [{name: 'Root'}]});
  }, [navigation]);

  const containerProps = useMemo<PromptOtpToExchangeContainerProps>(
    () => ({
      params: route.params,
      notifyAboutSuccessfulExchange: params => {
        navigation.navigate('NotifyAboutSuccessfulExchange', params);
      },
      promptConfirmationForExchange: params => {
        navigation.navigate('PromptConfirmationForExchange', params);
      },
    }),
    [navigation, route.params],
  );

  const screenProps = usePromptOtpToExchangeContainer(containerProps);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (getIsReady() && getIsFocused()) {
      if (
        root.twoFaSettingsState.currentTwoFaProvider ===
        TwoFaProviderKind.Telegram
      ) {
        void handleTwoFaResendCode();
      }
    }
  }, [getIsReady, getIsFocused, root, handleTwoFaResendCode]);

  return (
    <SubmitOtpScreen
      layout={CARD}
      titleText={strings['promptOtpToExchange.title']}
      submitText={strings['promptOtpToExchange.submit']}
      twoFaProvider={root.twoFaSettingsState.currentTwoFaProvider}
      onTwoFaResendCode={handleTwoFaResendCode}
      resendCodeInterval={interval}
      resetInterval={resetInterval}
      onCancel={onBack}
      {...screenProps}
    />
  );
});
