import {useStrings, variance} from '@ncwallet-app/core';
import {NewspaperSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import NewsItem from './NewsItem';

export type NewsItemData = {
  id: string;
  date: Date;
  url: string;
  title: string;
  description: string;
  isVisible: boolean;
};

export type NewsProps = {
  data: NewsItemData[];
};

export default observer(function NewsList({data}: NewsProps) {
  const renderNewsItem = ({item}: {item: NewsItemData}) => {
    return item.isVisible ? (
      <NewsItem
        date={item.date}
        title={item.title}
        description={item.description}
        url={item.url}
      />
    ) : null;
  };
  const strings = useStrings();
  return (
    <Container>
      <Header>
        <NewspaperSvg />
        <HeaderTitle>{strings['news.title']}</HeaderTitle>
      </Header>
      <FlatList
        overScrollMode="never"
        data={data}
        renderItem={renderNewsItem}
        keyExtractor={(item: NewsItemData) => item.id}
      />
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    flex: 1,
    borderRadius: 10,
    backgroundColor: theme.palette.background,
    shadowColor: theme.palette.blackout,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
  },
}));

const Header = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderBottomColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
  },
}));

const HeaderTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 18,
    lineHeight: 22,
    marginLeft: 10,
  },
}));
