import type {
  AuthCredentials,
  DirectCredentials,
  PreAuthCredentials,
} from '../Credentials';
import {
  fromAuthResult,
  fromDirectAuthResult,
  fromPreAuthResult,
} from '../Credentials';
import {success} from '../fp';
import type {Log} from '../Log';
import type {Maybe} from '../Maybe';
import type {AuthResult} from '../NCWalletServer';
import type {
  OAuth2PreSignInParams,
  OAuth2ProviderMap,
  OAuth2RefreshParams,
  OAuth2RestClient,
  OAuth2SignInParams,
  OAuth2TwoFaParams,
} from '../OAuth2RestClient';
import type {OAuth2RestClientHelper} from './Oauth2RestClientHelper';

export default class OAuth2RestClientHelperImpl
  implements OAuth2RestClientHelper
{
  constructor(
    private readonly _root: {
      readonly oAuth2RestClient: OAuth2RestClient;
      readonly signOutReasonLog: Log;
    },
  ) {}

  async preSignIn<T extends keyof OAuth2ProviderMap>(
    params: OAuth2PreSignInParams<T>,
  ): Promise<Maybe<PreAuthCredentials>> {
    const res = await this._root.oAuth2RestClient.preSignIn(params);
    return res.success ? success(fromPreAuthResult(res.right)) : res;
  }

  async signInBySetPin(
    params: OAuth2SignInParams,
  ): Promise<Maybe<AuthCredentials>> {
    const res = await this._root.oAuth2RestClient.signInBySetPin(params);
    return res.success ? success(fromAuthResult(res.right)) : res;
  }

  async signIn(params: OAuth2SignInParams): Promise<Maybe<AuthCredentials>> {
    const res = await this._root.oAuth2RestClient.signInByPin(params);
    return res.success ? success(fromAuthResult(res.right)) : res;
  }

  async twoFa(params: OAuth2TwoFaParams): Promise<Maybe<DirectCredentials>> {
    const res = await this._root.oAuth2RestClient.twoFa(params);
    return res.success ? success(fromDirectAuthResult(res.right)) : res;
  }

  async refresh(
    params: OAuth2RefreshParams,
  ): Promise<Maybe<DirectCredentials>> {
    this._root.signOutReasonLog.write({body: 'refresh called'});
    const res = await this._root.oAuth2RestClient.refresh(params);
    this._logRefreshResult(res);
    return res.success ? success(fromDirectAuthResult(res.right)) : res;
  }

  private _logRefreshResult(res: Maybe<AuthResult>) {
    if (res.success) {
      this._root.signOutReasonLog.write({body: 'refresh success'});
    } else {
      const message = `refresh failed - ${JSON.stringify(res.left)}`;
      this._root.signOutReasonLog.write({body: message});
    }
  }
}
