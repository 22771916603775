import {useRoot} from '@ncwallet-app/core';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {useCallback, useState} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useClearAllowedIpList = (
  goToPromptOtp: () => void,
  goToIpList: () => void,
) => {
  const root = useRoot();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clearAllowedList = useCallback(async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const isTwoFaRequiredRes =
      await root.twoFaHelper.isTwoFaRequiredForIpSettings();

    if (!isTwoFaRequiredRes.success) {
      setIsSubmitting(false);
      return;
    }

    if (isTwoFaRequiredRes.right) {
      setIsSubmitting(false);
      goToPromptOtp();
      return;
    }

    const res = await root.ncWalletJsonRpcClient.call(
      'accounts.ip_settings.clear',
      {type: IpType.Allowed},
    );
    setIsSubmitting(false);

    if (res.success) {
      goToIpList();
    } else {
      root.flashMessage.showMessage({
        title: root.errorParser.describe(res.left).summary,
        variant: 'danger',
      });
    }
  }, [goToIpList, goToPromptOtp, isSubmitting, root]);

  return {
    clearAllowedList,
  };
};
