import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {AccountDocumentId} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import type {AccountDocumentFileInfo} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments/AccountDocumentFileInfo';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';

import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import IdentityVerificationUploadDocumentScreen from '../../../screens/IdentityVerificationUploadDocument/IdentityVerificationUploadDocumentScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type IdentityVerificationUploadDocumentBindingProps =
  SmallHomeStackBindingProps<'IdentityVerificationUploadDocument'>;

export default observer(function IdentityVerificationUploadDocumentBinding(
  props: IdentityVerificationUploadDocumentBindingProps,
) {
  const {navigation} = props;
  const documentType = props.route.params.type;
  const {accountDocumentManager} = useRoot();

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void accountDocumentManager.refresh();
          });
        }
      }),
    [accountDocumentManager, getIsFocused, getIsReady],
  );

  const handleUploadPickerPress = useCallback(() => {
    navigation.navigate('PromptToChooseIdentityFile', {type: documentType});
  }, [navigation, documentType]);

  const handleContinuePress = useCallback(() => {
    navigation.navigate('ListDocuments');
  }, [navigation]);

  const navigateToDelete = useCallback(
    (id: AccountDocumentId, file: AccountDocumentFileInfo) => {
      navigation.navigate('PromptToDeleteDocumentFile', {
        documentId: id,
        file: file.name,
      });
    },
    [navigation],
  );

  return (
    <IdentityVerificationUploadDocumentScreen
      accountDocument={accountDocumentManager.getByType(documentType)}
      onContinuePress={handleContinuePress}
      onUploadPickerPress={handleUploadPickerPress}
      onDeleteFile={navigateToDelete}
    />
  );
});
