import {variance} from '@ncwallet-app/core';
import {Button} from '@ncwallet-app/ui/src';
import {View} from 'react-native';

export const InputsRow = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    marginTop: 25,
  },
}));

export const LgSubmitButton = variance(Button)(() => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 345,
    width: '100%',
  },
}));
