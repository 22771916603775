import type {WalletListItemData} from '@ncwallet-app/core';
import {useBalanceCryptoValue, variance} from '@ncwallet-app/core';
import {CurrencyCircleLogo, TouchableListItem} from '@ncwallet-app/ui';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import CryptoRateText from './CryptoRateText';
import FiatValueText from './FiatValueText';
import PriceDiffView from './PriceDiffView';
import WalletListItemLineChart from './WalletListItemLineChart';

export type WalletListItemProps = {
  data: WalletListItemData;
  onPress: () => void;
};

export const BaseWalletListItem = observer((props: WalletListItemProps) => {
  const {data, onPress} = props;
  const cryptoCurrency = data.getCryptoCurrency();

  const formattedCryptoValue = useBalanceCryptoValue(
    data.cryptoValue,
    cryptoCurrency?.fractionDigits,
  );

  return (
    <Container onPress={onPress} Icon={ArrowRightSvg}>
      <Inner>
        {cryptoCurrency && (
          <CurrencyCircleLogo code={cryptoCurrency.code} size={40} />
        )}
        <InfoContainer>
          <InfoRow>
            <Title>{cryptoCurrency?.name}</Title>
            <WalletListItemLineChart getRateHistory={data.getRateHistory} />
          </InfoRow>
          <InfoRow>
            <CryptoValue>
              {formattedCryptoValue} {cryptoCurrency?.code}
            </CryptoValue>
            <CryptoRateText
              getCryptoRate={data.getCryptoRate}
              getFiatCurrency={data.getFiatCurrency}
            />
          </InfoRow>
          <InfoRow withoutOffsetBottom>
            <FiatValueText
              getFiatCurrency={data.getFiatCurrency}
              getFiatValue={data.getFiatValue}
            />

            <PriceDiffView getRateHistory={data.getRateHistory} />
          </InfoRow>
        </InfoContainer>
      </Inner>
    </Container>
  );
});

const Container = variance(TouchableListItem)(theme => ({
  root: {
    paddingRight: 25,
    paddingLeft: 15,
    backgroundColor: theme.palette.background,
  },
}));

const InfoRow = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
  },
  withoutOffsetBottom: {
    marginBottom: 0,
  },
}));

const Inner = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
  },
}));

const InfoContainer = variance(View)(() => ({
  root: {
    marginLeft: 15,
    width: '100%',
    paddingRight: 67,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('bold'),
    fontSize: 16,
    lineHeight: 24,
  },
}));

const CryptoValue = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('500'),
    marginTop: 5,
  },
}));
