import {range} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {ScrollView} from 'react-native-gesture-handler';

import SessionHistorySkeleton from './SessionHistorySkeleton';

export type SessionHistorySkeletonListProps = {
  itemsCount?: number;
};

export default observer(function SessionHistorySkeletonList(
  props: SessionHistorySkeletonListProps,
) {
  const {itemsCount = 6} = props;
  return (
    <ScrollView overScrollMode="never">
      {range(0, itemsCount).map(idx => (
        <SessionHistorySkeleton key={idx} />
      ))}
    </ScrollView>
  );
});
