import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React from 'react';
import type {ImageSourcePropType, StyleProp, ViewStyle} from 'react-native';
import {ImageBackground} from 'react-native';

export type WideLayoutBackgroundProps = PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
}>;

export default observer(function WideLayoutBackground(
  props: WideLayoutBackgroundProps,
) {
  const {children, style} = props;
  return (
    <Background
      source={
        require('../../../ui/src/assets/background.png') as ImageSourcePropType
      }
      resizeMode={'cover'}
      style={style}>
      {children}
    </Background>
  );
});

const Background = variance(ImageBackground)(theme => ({
  root: {
    backgroundColor: theme.palette.uiPrimary,
    flex: 1,
    width: '100%',
    height: '100%',
  },
}));
