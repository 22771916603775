import {
  NCWalletLogoDarkSvg,
  WalletPrivateSvg,
  WalletTradingSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {observer} from 'mobx-react-lite';
import React from 'react';

export type WalletIconProps = {
  type: WalletType;
  width: number;
  height: number;
};

export enum WalletType {
  All = 'All',
  Private = 'Private',
  Trading = 'Trading',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const operationIconMap: Record<WalletType, React.ComponentType<any>> = {
  [WalletType.All]: NCWalletLogoDarkSvg,
  [WalletType.Private]: WalletPrivateSvg,
  [WalletType.Trading]: WalletTradingSvg,
};

export const WalletIcon = observer((props: WalletIconProps) => {
  const Icon = operationIconMap[props.type];
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return Icon ? <Icon width={props.width} height={props.height} /> : null;
});
