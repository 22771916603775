import {variance} from '@ncwallet-app/core';
import {Text, View} from 'react-native';

export const LimitCardHeader = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

export const LimitCardHeaderTitle = variance(Text)(theme => ({
  root: {
    marginRight: 'auto',
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
    fontSize: 16,
  },
}));

export const LimitCardHeaderCurrency = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const LimitCardHeaderCurrencyTitle = variance(Text)(theme => ({
  root: {
    marginLeft: 10,
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
    fontSize: 16,
  },
}));
