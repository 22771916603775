import type {WalletId, WalletListItemData} from '@ncwallet-app/core';
import type {MainRouteName} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import useScreenHeaderWideProps from '../hooks/useScreenHeaderWideProps';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';
import HeaderModals from './HeaderFrame/HeaderModals';
import MainHeader from './MainHeader';
import MainLayout from './MainLayout';

export type MainLayoutContainerProps = {
  isReady: boolean;
  children: React.ReactNode;
} & LargeSwitchBindingProps<MainRouteName>;

export default observer(function MainLayoutContainer(
  props: MainLayoutContainerProps,
) {
  const {isReady, children, navigation} = props;
  const headerProps = useScreenHeaderWideProps(props);
  const onCurrencyAdd = useCallback(() => {
    navigation.jumpTo('PromptNewWallet');
  }, [navigation]);
  const onWalletSelect = useCallback(
    (_: WalletListItemData) => {
      navigation.jumpTo('ShowWallet', {
        walletId: _.id as WalletId,
        currencyCode: _.cryptoCurrencyCode,
      });
    },
    [navigation],
  );
  const Header = useMemo(
    () => isReady && <MainHeader {...headerProps} />,
    [headerProps, isReady],
  );

  const Modals = useMemo(
    () => isReady && <HeaderModals {...headerProps} />,
    [headerProps, isReady],
  );
  const navigateToTransaction = useCallback(() => {
    navigation.navigate('ListHistory');
  }, [navigation]);
  return (
    <MainLayout
      Header={Header}
      Modals={Modals}
      onCurrencyAdd={onCurrencyAdd}
      onWalletSelect={onWalletSelect}
      onLastTransactionPress={navigateToTransaction}
      navigation={navigation}>
      {children}
    </MainLayout>
  );
});
