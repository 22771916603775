import {useRoot, useShareAvailable, useStrings} from '@ncwallet-app/core';
import {setStringAsync} from 'expo-clipboard';
import {useCallback} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useBaseActions = () => {
  const {flashMessage, sharing} = useRoot();
  const strings = useStrings();
  const shareAvailable = useShareAvailable();

  const onCopy = useCallback(
    (data: string) => {
      void setStringAsync(data);
      flashMessage.showMessage({
        title: strings.copied,
        variant: 'success',
      });
    },
    [flashMessage, strings.copied],
  );

  return {shareAvailable, share: sharing.shareMessage, onCopy};
};
