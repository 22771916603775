import type {
  CryptoCurrency,
  CryptoCurrencyCode,
  CurrencyValue,
} from '@ncwallet-app/core';
import {
  fromISODateString,
  OPERATION_KIND_MAP,
  success,
  TIMEOUT_ERROR,
  TransactionFilterKind,
  useRoot,
  useStrings,
} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import type {BlockchainNetwork} from '@ncwallet-app/core/src/NCWalletServer/BlockchainNetworkList';
import type {TransactionId} from '@ncwallet-app/core/src/NCWalletServer/WalletsTransactions';
import {WalletTransactions} from '@ncwallet-app/core/src/WalletTransactionsRequestHelper';
import {BigNumber} from 'bignumber.js';
import {setStringAsync} from 'expo-clipboard';
import {noop} from 'lodash';
import {autorun, observable, reaction, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../Navigation/hooks';
import useGoToSupport from '../../Navigation/hooks/useGoToSupport';
import {HistoryDetailsScreen} from '../../screens/HistoryDetails';
import type {
  HistoryBlockchainNetworkDetail,
  HistoryDetailItem,
  HistoryExchangeDetail,
  HistoryIncomingDetail,
} from '../../screens/HistoryDetails/HistoryDetailItem';

import AggregationDetailedItem = WalletTransactions.AggregationDetailedItem;

export type ShowTransactionContainerProps = {
  historyId: string;
  onBack: () => void;
  toNotFound?: () => void;
  promptTransactionReport?: (id: string) => void;
};

const prepareTransaction = (
  transaction: AggregationDetailedItem,
  currency: CryptoCurrency,
  historyId: string,
  blockchainNetworks?: ReadonlyMap<AddressNetwork, BlockchainNetwork>,
): HistoryDetailItem &
  HistoryExchangeDetail &
  HistoryIncomingDetail &
  HistoryBlockchainNetworkDetail => {
  const network = transaction.front_info?.service
    ? `${transaction.front_info.service}:${transaction.front_info.network ?? ''}`
    : transaction.front_info?.network;
  return {
    id: historyId,
    kind: OPERATION_KIND_MAP[transaction.kind],
    status: transaction.status,
    cryptoCode: transaction.currencyCode,
    cryptoFractionDigits: currency.decimals,
    date: fromISODateString(transaction.createdAt),
    cryptoName: currency.code,
    cryptoValue:
      transaction.front_info?.frontType === 'withdraw'
        ? (BigNumber(transaction.amountTo)
            .negated()
            .toString() as CurrencyValue<CryptoCurrencyCode>)
        : transaction.amountTo,
    txId: transaction.front_info?.txId || undefined,
    address: transaction.front_info?.toAddress || undefined,
    fromAddress: transaction.front_info?.fromAddress || undefined,
    infoNetwork: network || undefined,
    infoFromCurrency: (transaction.front_info?.fromCurrency ?? undefined) as
      | CryptoCurrencyCode
      | undefined,
    infoToCurrency: (transaction.front_info?.toCurrency ?? undefined) as
      | CryptoCurrencyCode
      | undefined,
    comment: transaction.front_info?.comment || undefined,
    rate: Number(transaction.front_info?.rate) || undefined,
    amountTo: transaction.amountTo,
    amountFrom: BigNumber(transaction.amountFrom)
      .negated()
      .toString() as CurrencyValue<CryptoCurrencyCode>,
    walletFee: transaction.front_info?.walletFee || undefined,
    minerFee: transaction.front_info?.minerFee || undefined,
    infoCurrency: (transaction.front_info?.currency ?? undefined) as
      | CryptoCurrencyCode
      | undefined,
    nonDefaultNetwork: currency.options.default_network !== network,
    showFields: transaction.front_info?.showFields,
    uri: transaction.front_info?.icon,
    blockchainNetwork:
      blockchainNetworks?.get(network as AddressNetwork) || undefined,
  };
};

type State = {
  name?: string;
  showInfo: boolean;
};

export default observer(function ShowTransactionContainer(
  props: ShowTransactionContainerProps,
) {
  const {historyId, onBack, toNotFound, promptTransactionReport} = props;
  const {
    flashMessage,
    walletTransactionsRequestHelper,
    currencyStore,
    walletStore,
    blockchainNetworksRepositoryState,
    transactionReportHelper,
    rpcTimeoutErrorVisibility,
  } = useRoot();

  const strings = useStrings();

  const [transactionBox] = useState(() =>
    observable.box<
      | (HistoryDetailItem & HistoryExchangeDetail & HistoryIncomingDetail)
      | undefined
    >(),
  );
  const [state, setState] = useState<State>({
    name: undefined,
    showInfo: false,
  });
  const [timeoutErrorBox] = useState(() => observable.box(false));

  const getNetworkName = useCallback(
    (
      transaction: HistoryDetailItem &
        HistoryExchangeDetail &
        HistoryIncomingDetail,
    ) => {
      if (!transaction.infoNetwork) {
        return undefined;
      }
      const currency = currencyStore.getCryptoCurrency(
        transaction.cryptoCode as CryptoCurrencyCode,
      );
      if (transaction.kind === TransactionFilterKind.Withdraw) {
        const currencyWithNetwork = currency?.options.currencies_out.find(
          i => i.network === transaction.infoNetwork,
        );
        setState(prev => ({
          ...prev,
          name:
            currencyWithNetwork !== undefined
              ? currencyWithNetwork.name
              : undefined,
        }));
      }

      if (transaction.kind === TransactionFilterKind.Incoming) {
        const currencyWithNetwork = currency?.options.currencies_in.find(
          i => i.network === transaction.infoNetwork,
        );
        setState(prev => ({
          ...prev,
          name:
            currencyWithNetwork !== undefined
              ? currencyWithNetwork.name
              : undefined,
        }));
      }
    },
    [currencyStore],
  );

  const getWalletIds = useCallback(async () => {
    const wallets_ = await walletStore.refreshWallets();
    if (!wallets_.success) {
      return wallets_;
    }
    return success(new Set(wallets_.right.map(_ => _.id)));
  }, [walletStore]);

  const showNetworkAndCurrency = useCallback(
    (
      transaction: HistoryDetailItem &
        HistoryExchangeDetail &
        HistoryIncomingDetail,
    ) => {
      const currency = currencyStore.getCryptoCurrency(
        transaction.cryptoCode as CryptoCurrencyCode,
      );
      if (transaction.kind === TransactionFilterKind.Incoming) {
        setState(prev => ({
          ...prev,
          showInfo:
            currency?.options.default_network !== transaction.infoNetwork ||
            transaction.infoFromCurrency !== transaction.infoToCurrency,
        }));
      }
      if (transaction.kind === TransactionFilterKind.Withdraw) {
        setState(prev => ({
          ...prev,
          showInfo:
            currency?.options.default_network !== transaction.infoNetwork ||
            currency?.code !== transaction.infoCurrency,
        }));
      }
    },
    [currencyStore],
  );

  const getHistoryItem = useCallback(async () => {
    const userOwned_ = await getWalletIds();

    if (!userOwned_.success) {
      if (userOwned_.left.kind === TIMEOUT_ERROR) {
        timeoutErrorBox.set(true);
      }
      return;
    }

    const transaction =
      await walletTransactionsRequestHelper.getTransactionById(
        userOwned_.right,
        historyId as TransactionId,
      );

    if (!transaction.success) {
      if (transaction.left.kind === TIMEOUT_ERROR) {
        timeoutErrorBox.set(true);
        return;
      }
      toNotFound?.();
      return;
    }

    const aggregatedTransaction = transaction.right;

    const currency = currencyStore.getCryptoCurrency(
      aggregatedTransaction.currencyCode,
    );
    const blockchainNetworks =
      blockchainNetworksRepositoryState.blockchainNetworkByWalletNetwork;

    if (!currency || !blockchainNetworks) {
      return;
    }
    const finalTransaction = prepareTransaction(
      aggregatedTransaction,
      currency,
      historyId,
      blockchainNetworks,
    );

    runInAction(() => {
      transactionBox.set(finalTransaction);
      getNetworkName(finalTransaction);
      showNetworkAndCurrency(finalTransaction);
    });
  }, [
    getWalletIds,
    walletTransactionsRequestHelper,
    historyId,
    currencyStore,
    blockchainNetworksRepositoryState.blockchainNetworkByWalletNetwork,
    timeoutErrorBox,
    toNotFound,
    transactionBox,
    getNetworkName,
    showNetworkAndCurrency,
  ]);

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void currencyStore.refreshCryptoCurrencies();
            void getHistoryItem();
          });
        }
      }),
    [
      getIsReady,
      getIsFocused,
      getHistoryItem,
      currencyStore,
      blockchainNetworksRepositoryState,
    ],
  );

  useEffect(
    () =>
      reaction(
        () => timeoutErrorBox.get(),
        shouldRequest => {
          if (shouldRequest) {
            rpcTimeoutErrorVisibility.registerAction(async () => {
              await currencyStore.refreshCryptoCurrencies();
              await getHistoryItem();
            });
          }
        },
      ),
    [rpcTimeoutErrorVisibility, currencyStore, getHistoryItem, timeoutErrorBox],
  );

  const handleCopy = useCallback(
    (data: string) => {
      void setStringAsync(data);
      flashMessage.showMessage({
        title: strings.copied,
        variant: 'success',
      });
    },
    [flashMessage, strings],
  );

  const handleTransactionReport = useCallback(async () => {
    const txId = transactionBox.get()?.id;
    if (!txId) {
      return;
    }

    await transactionReportHelper.generateSingleTransactionReport({
      transactionId: txId,
    });

    if (promptTransactionReport && !transactionReportHelper.error) {
      promptTransactionReport(txId);
    }
  }, [promptTransactionReport, transactionBox, transactionReportHelper]);

  const goToSupport = useGoToSupport();
  return (
    <HistoryDetailsScreen
      transaction={transactionBox.get()}
      onCopy={handleCopy}
      onRepeat={noop}
      infoNetwork={state.name}
      onContactSupport={goToSupport}
      onBack={onBack}
      onTransactionReport={handleTransactionReport}
      isLoading={transactionReportHelper.isLoading}
      error={transactionReportHelper.error}
    />
  );
});
