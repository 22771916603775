/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain */
import type {WalletListItemData} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {CloseEye} from '@ncwallet-app/ui/src/assets/svg/colored';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Animated} from 'react-native';

import WalletListItemSwipeContainer from '../WalletListItemSwipeContainer';
import {BaseWalletListItem} from './BaseWalletListItem';

export type WalletListItemProps = {
  data: WalletListItemData;
  onPress: () => void;
  hideWallet: () => void;
};

export default observer(function WalletListItem(props: WalletListItemProps) {
  const {hideWallet, data, onPress} = props;
  const cryptoCurrency = data.getCryptoCurrency();
  const RightAction = useMemo(
    () => (progress?: Animated.AnimatedInterpolation<number>) => {
      const trans = progress?.interpolate({
        inputRange: [-1, 1],
        outputRange: [0, 1],
      });
      return (
        <AnimEyeContainer style={trans && {opacity: trans}}>
          <EyeContainer onPress={hideWallet}>
            <CloseEye />
          </EyeContainer>
        </AnimEyeContainer>
      );
    },
    [hideWallet],
  );
  return (
    <WalletListItemSwipeContainer
      canChange={cryptoCurrency?.canChange!}
      value={data.cryptoValue}
      renderRight={RightAction}>
      <BaseWalletListItem {...props} onPress={onPress} />
    </WalletListItemSwipeContainer>
  );
});

const EyeContainer = variance(TouchableOpacity)(() => ({
  root: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const AnimEyeContainer = variance(Animated.View)(theme => ({
  root: {
    width: 96,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary,
  },
}));
