import {action, makeObservable, observable} from 'mobx';

import {FULFILLED, PENDING, REJECTED} from '../AsyncAtom';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import type {AuthClient} from './AuthClient';
import type {AuthState} from './AuthState';

export default class AuthStateService implements AuthState, Service {
  @observable.ref private _latest?: AuthState['latest'];

  constructor(
    private readonly _root: {
      readonly authClient: AuthClient;
    },
  ) {
    makeObservable(this);
  }

  get latest(): AuthState['latest'] | undefined {
    return this._latest;
  }

  subscribe() {
    return batchDisposers(
      this._root.authClient.requests.domain.listen(
        action(event => {
          if (this._latest?.status === PENDING) {
            return;
          }
          // @ts-expect-error This is an error of TypeScript compiler. Here, the type RouterMapEvents<AuthRequestMap> is not assignable to itself.
          this._latest = {
            status: PENDING,
            params: event,
          };
        }),
      ),
      this._root.authClient.responses.domain.listen(
        action(event => {
          this._latest = {status: FULFILLED, result: event.args[0]};
        }),
      ),
      this._root.authClient.errors.listen(
        action(_ => {
          this._latest = {status: REJECTED, error: _};
        }),
      ),
    );
  }
}
