/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {WalletSlideData} from '@ncwallet-app/core';
import {useStyles, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {FlatListProps, ListRenderItem} from 'react-native';
import {View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import CurrencyAll from './CurrencyAll';
import type {CurrencySelectionListItemData} from './CurrencySelectionListItemData';
import MdCurrencyListItem from './MdCurrencyListItem';

export type MdCurrencyListProps = Partial<
  FlatListProps<CurrencySelectionListItemData>
> & {
  totalShownCurrencies?: WalletSlideData | undefined;
  onAllPress?: () => void;
  showValueAsRate?: boolean;
  getData?: () => CurrencySelectionListItemData[] | undefined;
  onCurrencyPress: (item: CurrencySelectionListItemData) => void;
};

const keyExtractor = (entry: CurrencySelectionListItemData) => entry.id;

export default observer(function MdCurrencySelectionList(
  props: MdCurrencyListProps,
) {
  const {getData, onCurrencyPress, showValueAsRate = true, ...rest} = props;
  const data = getData?.() ?? props.data;
  const styles = useStyles(theme => ({
    container: {
      flexGrow: 1,
    },
    list: {
      flex: 2,
      backgroundColor: theme.palette.uiPrimary,
    },
  }));
  const renderItem: ListRenderItem<CurrencySelectionListItemData> = useCallback(
    ({item, index}) => (
      <MdCurrencyListItem
        data={item}
        valueShown={showValueAsRate}
        onPress={() => {
          onCurrencyPress(item);
        }}
        testID={`${index + 1}-coin-item`}
      />
    ),
    [onCurrencyPress, showValueAsRate],
  );

  const renderCurrencyAllItem = useCallback(() => {
    return props.totalShownCurrencies ? (
      <CurrencyAll
        total={props.totalShownCurrencies}
        onPress={props.onAllPress!}
      />
    ) : null;
  }, [props.totalShownCurrencies, props.onAllPress]);

  return (
    <ListWrapper>
      <FlatList
        data={data}
        style={styles.list}
        contentContainerStyle={styles.container}
        extraData={onCurrencyPress}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ListHeaderComponent={renderCurrencyAllItem}
        {...rest}
      />
    </ListWrapper>
  );
});

const ListWrapper = variance(View)(theme => ({
  root: {
    flex: 1,
    borderRadius: 8,
    borderColor: theme.palette.uiSecondary,
    borderWidth: 1,
    overflow: 'hidden',
  },
}));
