import type {
  ContentServiceRestClient,
  News,
} from '@ncwallet-app/core/src/ContentServiceRestClient';
import type {AvailableLanguageCode} from '@ncwallet-app/core/src/Localization/constant';
import dayjs from 'dayjs';
import {useCallback, useEffect, useState} from 'react';

import type {NewsItemData} from './NewsList';

// eslint-disable-next-line import-x/prefer-default-export
export const useFetchWalletNews = (
  lang: AvailableLanguageCode,
  contentServiceRestClient: ContentServiceRestClient,
) => {
  const [news, setNews] = useState<NewsItemData[]>([]);

  const getNews = useCallback(async () => {
    const res = await contentServiceRestClient.getNews({
      lang,
      placings: ['ncw'],
      order_by: '-published_at',
    });

    if (!res.success) {
      setNews([]);
      return;
    }

    setNews(mapResponseToNews(lang, res.right.items));
  }, [lang, contentServiceRestClient]);

  useEffect(() => {
    void getNews();
  }, [lang, getNews]);

  return [news];
};

const mapResponseToNews = (
  lang: AvailableLanguageCode,
  items: News[],
): NewsItemData[] => {
  return items
    .map(it => {
      return {
        id: it.id.toString(),
        date: it.published_at,
        title: it.title,
        isVisible: it.is_visible,
        description: it.description.replace(/<\/?[^>]+(>|$)/g, ''),
        url: `https://ncwallet.net/news/${it.name}`,
      };
    })
    .filter(it => dayjs().isAfter(it.date));
};
