import {computed, makeObservable} from 'mobx';

import type {AppStateHelper} from '../AppStateHelper';
import type {ShallowCommonState} from '../CommonNavigationScheme';
import {LIST_WALLETS_ROUTE} from '../CommonNavigationScheme';
import type {LayoutHelperState} from '../LayoutHelperState';
import type {ReadonlyNavigationContainerState} from '../NavigationContainer';
import type {Service} from '../structure';
import getCurrentRouteKind from '../util/getCurrentRouteKind';
import type {AdvertSpotState} from './AdVisibilityState';
import type {CommonAdvertSpotState} from './CommonAdvertSpotState';
import TriggerAdvertSpotStateService from './TriggerAdvertSpotStateService';

export default class SplashAdvertSpotStateService
  extends TriggerAdvertSpotStateService
  implements AdvertSpotState, Service
{
  constructor(
    private readonly _isDesktop: boolean,
    _common: CommonAdvertSpotState,
    protected readonly _root: {
      readonly navigationContainerState: ReadonlyNavigationContainerState;
      readonly appStateHelper: AppStateHelper;
      readonly layoutHelperState: LayoutHelperState;
    },
  ) {
    super(_isDesktop ? 'splash_desktop' : 'splash_mobile', _common, _root);
    makeObservable(this);
  }

  @computed protected get _isOnSpot() {
    const {appStateHelper, layoutHelperState, navigationContainerState} =
      this._root;
    const isDesktop = this._isDesktop;
    return (
      appStateHelper.isReadyToShowUserInterface &&
      ((isDesktop && layoutHelperState.isLarge) ||
        (!isDesktop && !layoutHelperState.isLarge)) &&
      isHomeScreen(navigationContainerState.latestState)
    );
  }
}

export function isHomeScreen(_?: ShallowCommonState) {
  return getCurrentRouteKind(_) === LIST_WALLETS_ROUTE;
}
