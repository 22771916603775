import type {
  AddressInfo,
  CryptoAddress,
  CurrenciesRateStore,
  CurrencyDescription,
  CurrencyStore,
  FiatCurrencyCode,
  OnTimeoutError,
  Service,
  Wallet,
  WalletId,
  WalletStore,
} from '@ncwallet-app/core';
import {
  MoneyStatic,
  TIMEOUT_ERROR,
  toCurrencyDescriptionFromCrypto,
  toCurrencyDescriptionFromFiat,
} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import {getAccountFromState} from '@ncwallet-app/core/src/AccountStore';
import type {
  AddressNetwork,
  AddressType,
} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';

// eslint-disable-next-line import-x/prefer-default-export
export class ReceiveCryptoBindingState implements Service, OnTimeoutError {
  @observable isCreating = false;
  @observable isUpdating = false;
  @observable private _walletId: WalletId | undefined;
  @observable private _address: CryptoAddress | undefined;
  @observable private _network: AddressNetwork | undefined;
  @observable private _addressType: AddressType | undefined;
  @observable private _isTimeoutError = false;

  @computed get isTimeoutError() {
    return this._isTimeoutError;
  }

  @computed get wallet(): Wallet | undefined {
    return (
      this._walletId && this._root.walletStore.getWalletById(this._walletId)
    );
  }

  @computed get isReady() {
    return this.wallet && this.cryptoCurrency;
  }

  @computed get cryptoCurrency(): CurrencyDescription | undefined {
    return this.crypto && toCurrencyDescriptionFromCrypto(this.crypto);
  }

  @computed get fiatCurrency(): CurrencyDescription | undefined {
    if (!this._baseFiat) {
      return undefined;
    }
    const fiat = this._root.currencyStore.getFiatCurrency(this._baseFiat);
    return fiat && toCurrencyDescriptionFromFiat(fiat);
  }

  @computed get fiatValue() {
    if (!this._baseFiat || !this.wallet) {
      return undefined;
    }

    const rate = this._root.currenciesRateStore.getRate(
      this.wallet.currency,
      this._baseFiat,
    )?.rate;

    return rate && MoneyStatic.convert(this.wallet.total, rate);
  }

  @computed get crypto() {
    const code = this.wallet?.currency;
    return code && this._root.currencyStore.getCryptoCurrency(code);
  }

  @computed get addressInfo(): AddressInfo | undefined {
    if (!this._address || !this.wallet) {
      return undefined;
    }
    return this.wallet.addresses.find(
      a =>
        a.address === this._address &&
        (!a.network || a.network === this._network),
    );
  }

  @observable description: string = '';

  @computed
  private get _baseFiat(): FiatCurrencyCode | undefined {
    return getAccountFromState(this._root.accountStore.state)?.base_fiat;
  }

  constructor(
    private readonly _root: {
      readonly walletStore: WalletStore;
      readonly accountStore: AccountStore;
      readonly currencyStore: CurrencyStore;
      readonly currenciesRateStore: CurrenciesRateStore;
    },
  ) {
    makeObservable(this);
  }

  @action.bound
  async setAddressParams(
    address: CryptoAddress,
    network?: AddressNetwork,
    type?: AddressType,
  ) {
    this._address = address;
    this._network = network;
    this._addressType = type;
  }

  @action.bound
  setDescription(description: string): void {
    this.description = description;
  }

  @action.bound
  async refresh(walletId: WalletId) {
    this._walletId = walletId;

    void this._root.currencyStore.refreshCryptoCurrencies();
    void this._root.currencyStore.refreshFiatCurrencies();

    const walletsRes = await this._root.walletStore.refreshWallets();
    if (!walletsRes.success) {
      this._isTimeoutError = walletsRes.left.kind === TIMEOUT_ERROR;
      return;
    }

    const responses = await Promise.all([
      this._root.currencyStore.refreshCryptoCurrencies(),
      this._root.currencyStore.refreshFiatCurrencies(),
      this._root.currenciesRateStore.refreshRate(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.wallet!.currency,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._baseFiat!,
      ),
    ]);

    this._isTimeoutError = responses.some(
      it => !it.success && it.left.kind === TIMEOUT_ERROR,
    );
  }

  @action.bound
  async createAddress(network: AddressNetwork, type?: AddressType) {
    this.isCreating = true;
    const res = this._root.walletStore.createAddress(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._walletId!,
      network,
      type,
    );
    runInAction(() => {
      this.isCreating = false;
    });

    return res;
  }

  @action.bound
  async updateDescription() {
    this.isUpdating = true;
    await this._root.walletStore.updateAddress(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._walletId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._address!,
      this.description,
    );
    runInAction(() => {
      this.isUpdating = false;
    });
  }

  subscribe() {
    return this.syncDescription();
  }

  private syncDescription() {
    return reaction(
      () => this.addressInfo?.description,
      description => {
        this.description = description || '';
      },
    );
  }
}
