import {CommonActions} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useClearAllowedIpList} from '../../../CommonNavigationContainers/hooks/ipSettings/useClearAllowedIpList';
import PromptToClearAllowedListScreen from '../../../screens/PromptToClearAllowedListScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptToClearWhiteListBindingProps =
  SmallHomeStackBindingProps<'PromptToClearAllowedList'>;

export default observer(function PromptToClearAllowedListBinding(
  props: PromptToClearWhiteListBindingProps,
) {
  const {navigation} = props;

  const goToPromptOtp = useCallback(() => {
    navigation.replace('PromptOtpToClearAllowedList');
  }, [navigation]);

  const goToIpList = useCallback(() => {
    if (hasRouteInHistory(navigation.getState(), 'ListBlockedIp')) {
      navigation.navigate('ListBlockedIp');
    } else {
      navigation.dispatch(
        CommonActions.reset({
          index: 2,
          routes: [
            {name: 'Root'},
            {name: 'ListSecuritySettings'},
            {name: 'ListBlockedIp'},
          ],
        }),
      );
    }
  }, [navigation]);

  const {clearAllowedList} = useClearAllowedIpList(goToPromptOtp, goToIpList);

  const handleClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <PromptToClearAllowedListScreen
      onConfirm={clearAllowedList}
      onClose={handleClose}
    />
  );
});
