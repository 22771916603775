import {useDateFormatting, useStrings, variance} from '@ncwallet-app/core';
import type {IpInfo} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import {TouchableListItem} from '@ncwallet-app/ui';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type ListIpItemProps = {
  item: IpInfo;
  onPress: () => void;
  wide?: boolean;
  testID?: string;
};

export const IpListItem = observer((props: ListIpItemProps) => {
  const strings = useStrings();
  const {formatDate, formatTime} = useDateFormatting();

  return (
    <Container
      Icon={ArrowRightSvg}
      onPress={props.onPress}
      wide={props.wide}
      testID={props.testID}>
      <Row>
        <Col>
          <Title>{props.item.name}</Title>
          <ListIpText last>{props.item.ip}</ListIpText>
        </Col>
        <Col>
          <ListIpText second>
            {strings['secureSettings.ipList.addDate']}{' '}
            {formatDate(props.item.created_at)}
          </ListIpText>
          <ListIpText second last>
            {formatTime(props.item.created_at)}
          </ListIpText>
        </Col>
      </Row>
    </Container>
  );
});

const Container = variance(TouchableListItem)(theme => ({
  root: {
    padding: 15,
    borderBottomWidth: 1,
    borderColor: theme.palette.uiSecondary,
    justifyContent: 'space-between',
  },
  wide: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
}));

const Row = variance(View)(() => ({
  root: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    paddingRight: 20,
  },
}));

const Col = variance(View)(() => ({
  root: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.textPrimary,
  },
}));

const ListIpText = variance(Text)(theme => ({
  root: {
    fontSize: 13,
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
  },
  last: {
    marginTop: 5,
  },
  second: {
    textAlign: 'right',
  },
}));
