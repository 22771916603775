import type {Advert, Millisecond} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import InstallNativeSpotBinding from './InstallNativeSpotBinding';
import InstallTelegramSpotBinding from './InstallTelegramSpotBinding';
import NewModalSpotBinding from './NewModalSpotBinding';
import SplashSpotBinding from './SplashSpotBinding';

export default observer(function ModalStack() {
  const {adVisibilityState, layoutHelperState, advertHelper} = useRoot();
  const {
    splashDesktop,
    splashMobile,
    newModalDesktop,
    newModalMobile,
    installNativeDesktop,
    installNativeMobile,
    installTelegramDesktop,
    installTelegramMobile,
  } = adVisibilityState;
  let ad: Advert | undefined;
  if (layoutHelperState.isLarge) {
    if (splashDesktop.adToDisplay) {
      ad = splashDesktop.adToDisplay;
    } else if (newModalDesktop.adToDisplay) {
      ad = newModalDesktop.adToDisplay;
    } else if (installNativeDesktop.adToDisplay) {
      ad = installNativeDesktop.adToDisplay;
    } else if (installTelegramDesktop.adToDisplay) {
      ad = installTelegramDesktop.adToDisplay;
    }
  } else {
    if (splashMobile.adToDisplay) {
      ad = splashMobile.adToDisplay;
    } else if (newModalMobile.adToDisplay) {
      ad = newModalMobile.adToDisplay;
    } else if (installNativeMobile.adToDisplay) {
      ad = installNativeMobile.adToDisplay;
    } else if (installTelegramMobile.adToDisplay) {
      ad = installTelegramMobile.adToDisplay;
    }
  }
  const context = useMemo(() => {
    return ad && advertHelper.localizeAndPickImages(ad);
  }, [ad, advertHelper]);
  const hideAd = useCallback(
    async (now: Millisecond) => {
      if (layoutHelperState.isLarge) {
        if (adVisibilityState.splashDesktop.adToDisplay) {
          await adVisibilityState.splashDesktop.hideAd(now);
        }
        if (adVisibilityState.newModalDesktop.adToDisplay) {
          await adVisibilityState.newModalDesktop.hideAd(now);
        }
        if (adVisibilityState.installNativeDesktop.adToDisplay) {
          await adVisibilityState.installNativeDesktop.hideAd(now);
        }
        if (adVisibilityState.installTelegramDesktop.adToDisplay) {
          await adVisibilityState.installTelegramDesktop.hideAd(now);
        }
      } else {
        if (adVisibilityState.splashMobile.adToDisplay) {
          await adVisibilityState.splashMobile.hideAd(now);
        }
        if (adVisibilityState.newModalMobile.adToDisplay) {
          await adVisibilityState.newModalMobile.hideAd(now);
        }
        if (adVisibilityState.installNativeMobile.adToDisplay) {
          await adVisibilityState.installNativeMobile.hideAd(now);
        }
        if (adVisibilityState.installTelegramMobile.adToDisplay) {
          await adVisibilityState.installTelegramMobile.hideAd(now);
        }
      }
    },
    [layoutHelperState, adVisibilityState],
  );
  if (context) {
    switch (context.advert.spot) {
      case 'new_modal_desktop':
      case 'new_modal_mobile':
        return <NewModalSpotBinding context={context} hideAd={hideAd} />;
      case 'splash_desktop':
      case 'splash_mobile':
        return <SplashSpotBinding context={context} hideAd={hideAd} />;
      case 'install_native_desktop':
      case 'install_native_mobile':
        return <InstallNativeSpotBinding context={context} hideAd={hideAd} />;
      case 'install_telegram_desktop':
      case 'install_telegram_mobile':
        return <InstallTelegramSpotBinding context={context} hideAd={hideAd} />;
    }
  }
  return null;
});
