export type BaseAsyncFunction = (
  this: unknown,
  ...args: unknown[]
) => Promise<unknown>;

export type AsyncFunctionClone<T extends BaseAsyncFunction> = (
  this: ThisParameterType<T>,
  ...args: Parameters<T>
) => ReturnType<T>;

export default function oneTaskAtATime<T extends BaseAsyncFunction>(
  f: T,
  ref: {current: ReturnType<T> | undefined} = {current: undefined},
): AsyncFunctionClone<T> {
  return function (this, ...args) {
    if (ref.current !== undefined) {
      return ref.current;
    }
    const next = f.apply(this, args) as ReturnType<T>;
    void next.finally(() => {
      ref.current = undefined;
    });
    ref.current = next;
    return next;
  };
}
