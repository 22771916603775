import type {
  CryptoCurrency,
  CryptoCurrencyCode,
  CurrencyStore,
  FiatCurrencyCode,
  OnTimeoutError,
  Service,
  Wallet,
  WalletStore,
} from '@ncwallet-app/core';
import {
  isEnoughMoneyForSendOrExchange,
  TIMEOUT_ERROR,
} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import {getAccountFromState} from '@ncwallet-app/core/src/AccountStore';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {computed, makeObservable, observable, runInAction} from 'mobx';

import type {CurrencyHistoryRefresher} from '../../../shared/CurrencyHistoryRefresher';
import type {CurrencySelectionListConverter} from '../../../shared/CurrencySelectionListConverter';
import {
  getCurrencyWalletPairsForAllCurrencies,
  getCurrencyWalletPairsForUserWallets,
} from '../../../shared/currencySelectionListConverterHelpers';

export default class SelectionForExchangeBindingState
  implements Service, OnTimeoutError
{
  @computed private get _baseFiat(): FiatCurrencyCode | undefined {
    return getAccountFromState(this._root.accountStore.state)?.base_fiat;
  }
  @observable private _exchangeFrom = false;
  @observable private _disabledCodes = new Set<CryptoCurrencyCode>();

  @observable private _isLoading: boolean = false;
  @observable private _isManualRefresh = false;
  @observable private _isTimeoutError = false;

  @computed get isTimeoutError() {
    return this._isTimeoutError;
  }

  @computed get currencies(): CurrencySelectionListItemData[] | undefined {
    const wallets = this._root.walletStore.getWallets();
    const currencies = this._root.currencyStore.getCryptoCurrencies();

    if (!this._baseFiat || !wallets || !currencies) {
      return;
    }

    return this.getPairs(this._exchangeFrom, currencies, wallets).map(
      ([c, w]) => this.itemConverter.toItem(c, w, this._disabledCodes),
    );
  }

  getCurrenciesForSource() {
    const wallets = this._root.walletStore.getWallets();
    let currencies = this._root.currencyStore.getCryptoCurrencies();

    if (currencies) {
      currencies = currencies.filter(currency => currency.options.change);
    }

    if (!this._baseFiat || !wallets || !currencies) {
      return;
    }

    return this.getPairs(true, currencies, wallets).map(([c, w]) =>
      this.itemConverter.toItem(c, w, this._disabledCodes),
    );
  }

  constructor(
    private readonly _root: {
      readonly accountStore: AccountStore;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
    },
    private readonly itemConverter: CurrencySelectionListConverter,
    private readonly historyRefresher: CurrencyHistoryRefresher,
  ) {
    makeObservable(this);
  }

  getIsLoading = () => this._isLoading;
  getIsManualRefresh = () => this._isManualRefresh;

  async manualRefresh(
    exchangeFrom: boolean,
    disabledCode?: CryptoCurrencyCode,
  ) {
    runInAction(() => {
      this._isManualRefresh = true;
    });

    await this.refresh(exchangeFrom, disabledCode);

    runInAction(() => {
      this._isManualRefresh = false;
    });
  }

  async refresh(exchangeFrom: boolean, disabledCode?: CryptoCurrencyCode) {
    runInAction(() => {
      this._isLoading = true;
      this._exchangeFrom = exchangeFrom;
      this._disabledCodes = new Set(disabledCode ? [disabledCode] : []);
    });
    const responses = await Promise.all([
      this._root.currencyStore.refreshFiatCurrencies(),
      this._root.walletStore.refreshWallets(),
      this._root.currencyStore.refreshCryptoCurrencies(),
    ]);
    runInAction(() => {
      this._isTimeoutError = responses.some(
        it => !it.success && it.left.kind === TIMEOUT_ERROR,
      );

      this._isLoading = false;

      this.historyRefresher.setStaleCodes(
        this.currencies?.map(
          c => c.cryptoCurrency.code as CryptoCurrencyCode,
        ) ?? [],
      );
    });
  }

  subscribe() {
    return this.historyRefresher.subscribe();
  }

  activateRateHistoryLoad() {
    this.historyRefresher.activate();
  }

  deactivateRateHistoryLoad() {
    this.historyRefresher.deactivate();
  }

  private getPairs = (
    exchangeFrom: boolean,
    currencies: CryptoCurrency[],
    wallets: Wallet[],
  ) => {
    if (exchangeFrom) {
      return getCurrencyWalletPairsForUserWallets(currencies, wallets).filter(
        ([, w]) => isEnoughMoneyForSendOrExchange(w),
      );
    } else {
      return getCurrencyWalletPairsForAllCurrencies(currencies, wallets);
    }
  };
}
