import {variance} from '@ncwallet-app/core';
import {BaseSkeleton} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export default observer(function WalletListSkeleton() {
  return (
    <>
      <WalletListSkeletonView>
        <BaseSkeletonIcon width={40} height={40} isRounded />
        <BaseSkeleton width={115} height={20} />
      </WalletListSkeletonView>
      <WalletListSkeletonView>
        <BaseSkeletonIcon width={40} height={40} isRounded />
        <BaseSkeleton width={115} height={20} />
      </WalletListSkeletonView>
      <WalletListSkeletonView>
        <BaseSkeletonIcon width={40} height={40} isRounded />
        <BaseSkeleton width={115} height={20} />
      </WalletListSkeletonView>
      <WalletListSkeletonView>
        <BaseSkeletonIcon width={40} height={40} isRounded />
        <BaseSkeleton width={115} height={20} />
      </WalletListSkeletonView>
      <WalletListSkeletonView>
        <BaseSkeletonIcon width={40} height={40} isRounded />
        <BaseSkeleton width={115} height={20} />
      </WalletListSkeletonView>
    </>
  );
});

const WalletListSkeletonView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const BaseSkeletonIcon = variance(BaseSkeleton)(() => ({
  root: {
    marginRight: 15,
  },
}));
