import {useStrings, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonVariant,
  LG_BREAKPOINT,
  useIsDimensions,
} from '@ncwallet-app/ui';
import ErrorWalletLogo from '@ncwallet-app/ui/src/components/atoms';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type NotFoundScreenProps = {
  toMain: () => void;
  version?: string;
};

export default observer(function NotFoundScreen({
  toMain,
  version,
}: NotFoundScreenProps) {
  const strings = useStrings();
  const isLg = useIsDimensions('lg');
  return (
    <Root>
      <Inner>
        <Version>{!isLg ? version : ''}</Version>
        <Body>
          <IconContainer>
            <ErrorWalletLogo />
          </IconContainer>
          <Title>{strings['notFound.title']}</Title>
          <Description>{strings['notFound.description']}</Description>
        </Body>
        <Btn
          onPress={toMain}
          title={strings['notFound.button']}
          variant={ButtonVariant.Primary}
        />
      </Inner>
    </Root>
  );
});
const Root = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.primary,
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        backgroundColor: theme.palette.uiPrimary,
        ...theme.bar(10),
      },
    }),
  },
}));

const IconContainer = variance(View)(() => ({
  root: {
    marginBottom: 40,
    width: 104,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const Version = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.textAdditional2,
    alignSelf: 'flex-end',
  },
}));

const Body = variance(View)(() => ({
  root: {
    marginVertical: 15,
  },
}));

const Btn = variance(Button)(theme => ({
  root: {
    width: '100%',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 350,
      },
    }),
  },
}));

const Inner = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.background,
    flex: 1,
    paddingHorizontal: 15,
    paddingTop: 30,
    paddingBottom: 50,
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        paddingHorizontal: 30,
        paddingBottom: 30,
      },
    }),
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 18,
    lineHeight: 20,
    marginBottom: 15,
    color: theme.palette.error,
    textAlign: 'center',
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.textAdditional1,
    textAlign: 'center',
  },
}));
