import type {
  CryptoCurrencyCode,
  CurrencyStore,
  InOutCurrency,
} from '@ncwallet-app/core';
import {action, computed, makeObservable, observable} from 'mobx';

export default class PromptOutputNetworkBindingState {
  @observable private _cryptoCode: CryptoCurrencyCode | undefined;

  constructor(
    private readonly _root: {
      readonly currencyStore: CurrencyStore;
    },
  ) {
    makeObservable(this);
  }

  @computed get networks(): InOutCurrency[] | undefined {
    const currency =
      this._cryptoCode &&
      this._root.currencyStore.getCryptoCurrency(this._cryptoCode);
    return currency?.options.currencies_out;
  }

  @action.bound
  async refresh(cryptoCode: CryptoCurrencyCode) {
    this._cryptoCode = cryptoCode;
    void this._root.currencyStore.refreshCryptoCurrencies();
  }
}
