import {useStyles, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import WalletLimitItem from '../../../shared/WalletLimitItem';
import type {WalletLimitsItemData} from '../../../shared/WalletLimitsItemData';
import WalletLimitsSkeleton from './WalletLimitsSkeleton';

export type LimitCardListProps = {
  data: WalletLimitsItemData[] | undefined;
  onSelect: (limit: WalletLimitsItemData) => void;
};

export default observer(function WalletLimitList(props: LimitCardListProps) {
  const {data, onSelect} = props;
  const styles = useStyles(theme => ({
    list: {
      flex: 1,
      borderRadius: 8,
      backgroundColor: theme.palette.uiPrimary,
      borderWidth: 1,
      borderColor: theme.palette.uiSecondary,
    },
  }));

  return (
    <>
      <FlatList
        data={data}
        style={styles.list}
        renderItem={({item}) => (
          <WalletLimitItem
            data={item}
            onPress={() => {
              onSelect(item);
            }}
          />
        )}
        ListEmptyComponent={<WalletLimitsSkeleton />}
        ItemSeparatorComponent={ListSeparator}
      />
    </>
  );
});

const ListSeparator = variance(View)(theme => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.uiSecondary,
  },
}));
