import type {PlatformOSType} from 'react-native';

export enum PlatformType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  WEB = 'WEB',
  CHROME = 'CHROME',
  TELEGRAM = 'TELEGRAM',
  UNKNOWN = 'UNKNOWN',
}

export const osToPlatformType = (os: PlatformOSType) => {
  switch (os) {
    case 'ios':
      return PlatformType.IOS;
    case 'android':
      return PlatformType.ANDROID;
    case 'web':
      if ('chrome' in globalThis) {
        return PlatformType.CHROME;
      }
      if ('Telegram' in globalThis) {
        return PlatformType.TELEGRAM;
      }
      return PlatformType.WEB;
    default:
      return PlatformType.UNKNOWN;
  }
};
