import {makeRedirectUri} from 'expo-auth-session';
import {getBundleId} from 'react-native-device-info';

import type {Url} from '../units';
import splitByPrefix from '../util/splitByPrefix';
import type {SpecialLocation, SpecialLocator} from './SpecialLocation';
import {EXTERNAL, INTERNAL} from './SpecialLocation';

export default class SpecialLocationImpl implements SpecialLocation {
  private static readonly _NATIVE_REDIRECT_URI = `${getBundleId()}://`;

  getOauthRedirect() {
    return makeRedirectUri({
      native: SpecialLocationImpl._NATIVE_REDIRECT_URI,
    }) as Url;
  }

  parse(_: string): SpecialLocator {
    if (_.startsWith('/')) {
      // internal link
      return {kind: INTERNAL, rest: _};
    }
    const split = splitByPrefix(_ as Url);
    if (!split) {
      return {kind: EXTERNAL};
    }
    const [prefix, rest] = split;
    return {kind: INTERNAL, prefix, rest};
  }
}
