import {action, computed, makeObservable, observable} from 'mobx';

import type {SplashScreenBinding, SplashScreenState} from './SplashScreen';

export default class SplashScreenStateImpl
  implements SplashScreenState, SplashScreenBinding
{
  @observable private _animationFinished = false;

  constructor() {
    makeObservable(this);
  }

  @computed get animationFinished() {
    return this._animationFinished;
  }

  readonly onAnimationFinished = action(() => {
    this._animationFinished = true;
  });
}
