import {findKey} from 'lodash';

import {SendAddressValidatorError} from './SendAddressValidator';

export enum AddressRedirectError {
  NoCryptoCode,
  NetworkError,
  UnsupportedCrypto,
  MultipleWallets,
  NoWallet,
  EmptyAddress,
  CannotBePerformedOnUserAddress,
  AddressInvalid,
  Aborted,
}

const errorsMap: Record<SendAddressValidatorError, AddressRedirectError> = {
  [SendAddressValidatorError.EmptyAddress]: AddressRedirectError.EmptyAddress,
  [SendAddressValidatorError.CannotBePerformedOnUserAddress]:
    AddressRedirectError.CannotBePerformedOnUserAddress,
  [SendAddressValidatorError.AddressInvalid]:
    AddressRedirectError.AddressInvalid,
  [SendAddressValidatorError.Aborted]: AddressRedirectError.Aborted,
};

export const toAddressRedirectError = (e: SendAddressValidatorError) =>
  errorsMap[e];

export const fromAddressRedirectError = (e: AddressRedirectError) =>
  findKey(errorsMap, value => value === e) as
    | SendAddressValidatorError
    | undefined;

export const shouldStoreAddressOnError = (e: AddressRedirectError) =>
  e !== AddressRedirectError.CannotBePerformedOnUserAddress &&
  e !== AddressRedirectError.EmptyAddress;
