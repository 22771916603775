import {variance} from '@ncwallet-app/core';
import {BaseSkeleton} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export default observer(function LimitsSettingsEmptyListSkeleton() {
  return (
    <EmptyListSkeletonView>
      <BaseSkeletonIcon width={40} height={40} isRounded />
      <BaseSkeleton width={100} height={20} />
    </EmptyListSkeletonView>
  );
});

const EmptyListSkeletonView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
}));

const BaseSkeletonIcon = variance(BaseSkeleton)(() => ({
  root: {
    marginRight: 15,
  },
}));
