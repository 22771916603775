import type {Millisecond} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {BlockchainNetworkStatusMessage} from '@ncwallet-app/core/src/NCWalletServer/BlockchainNetworkList';
import {useCallback} from 'react';

import type {BlockchainNetworkWarningScreenProps} from '../../screens/BlockchainNetworkWarningScreen/BlockchainNetworkWarningScreenProps';

// eslint-disable-next-line import-x/prefer-default-export
export const useBlockChainNetworkProps = (
  context: BlockchainNetworkStatusMessage,
  hideModal: (now: Millisecond) => Promise<void>,
): BlockchainNetworkWarningScreenProps => {
  const {appearance, userPreferenceState, time} = useRoot();
  const {btnText, img, title, desc} = context;

  const onClose = useCallback(
    async () => hideModal(time.now()),
    [hideModal, time],
  );
  const lang = userPreferenceState.languageCode;

  return {
    onClose,
    title: title[lang],
    img: appearance.isDark ? img.light : img.dark,
    desc: desc[lang],
    btnText: btnText[lang],
  };
};
