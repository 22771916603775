import {useLogButton, useRoot, useStrings} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import type {AuthLoginErrorScreenProps} from '../../screens/AuthErrorScreen';
import {AuthErrorScreen} from '../../screens/AuthErrorScreen';

export default observer(function PreCloseMessageBinding() {
  const logButtonComponent = useLogButton();
  const root = useRoot();
  const strings = useStrings();
  const appVersion = root.deviceInfo.getAppVersionWithBuildNumber();

  const closeThisInstance = useCallback(async () => {
    root.telegramMiniApp.closeThisInstance();
  }, [root]);

  const screenProps: AuthLoginErrorScreenProps = {
    appVersion,
    errorMessage: strings['preCloseWindow.title'],
    errorDescription: strings['preCloseWindow.description'],
    cancelText: strings['preCloseWindow.button'],
    onCancel: closeThisInstance,
    onBack: closeThisInstance,
  };

  return <AuthErrorScreen {...screenProps} LogButton={logButtonComponent} />;
});
