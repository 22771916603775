import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {LG_BREAKPOINT} from '../grid';

export type ListHeaderProps = {
  title: string;
};

export default observer(function SelectionCryptoTitle(props: ListHeaderProps) {
  const {title} = props;

  return (
    <ListHeaderView>
      <ListHeaderTitle>{title}:</ListHeaderTitle>
    </ListHeaderView>
  );
});

const ListHeaderTitle = variance(Text)(theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 10,
    ...theme.fontByWeight('bold'),
    fontSize: 18,
    color: theme.palette.textMain,
  },
}));

const ListHeaderView = variance(View)(theme => ({
  root: {
    borderBottomWidth: 1,
    paddingHorizontal: 15,
    borderBottomColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderBottomWidth: 0,
        paddingHorizontal: 0,
      },
    }),
  },
}));
