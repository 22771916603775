import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {View} from 'react-native';

import {useGlobalWebNumberInput} from '../../Navigation/hooks';
import {FULL_PIN_LENGTH} from '../PinScreen/constants';
import {PinForm} from '../PinScreen/PinForm';
import PinKeyboard from '../PinScreen/PinKeyboard';
import type {PinScreenTexts} from '../PinScreen/PinScreenTexts';

export type CheckPinProps = {
  getErrorShown: () => boolean;
  texts: PinScreenTexts;
  getPin: () => string;
  setPin: (value: string) => void;
  onErrorActionPress?: () => void;
};

export default observer(function CheckPin(props: CheckPinProps) {
  const {getPin, setPin, onErrorActionPress} = props;
  const [currentKey, setCurrentKey] = useState({value: 0});

  const handleNumberPress = useCallback(
    (n: number) => {
      const pin = getPin();
      if (pin.length === FULL_PIN_LENGTH) {
        return;
      }
      setPin(pin.length < FULL_PIN_LENGTH ? `${pin}${n}` : pin);
      setCurrentKey(prev => ({...prev, value: n}));
    },
    [getPin, setPin],
  );
  const getFilledLength = useCallback(() => getPin().length, [getPin]);
  const handleBackspacePress = useCallback(() => {
    const pin = getPin();
    setPin(pin.substring(0, pin.length - 1));
    setCurrentKey(prev => ({...prev, value: -1}));
  }, [getPin, setPin]);

  useGlobalWebNumberInput(handleNumberPress, handleBackspacePress);
  return (
    <PinContainer>
      <PinForm
        title={props.texts.title}
        getFilledLength={getFilledLength}
        fullPinLength={FULL_PIN_LENGTH}
        hasError={props.getErrorShown()}
        errorText={props.texts.errorText}
        errorAction={props.texts.errorActionText}
        onErrorActionPress={onErrorActionPress}
        smallText={true}
      />

      <PinKeyboard
        currentKey={currentKey}
        onNumberPress={handleNumberPress}
        onBackspacePress={handleBackspacePress}
      />
    </PinContainer>
  );
});

const PinContainer = variance(View)(() => ({
  root: {
    alignItems: 'center',
    flex: 1,
  },
}));
