import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import type {JsonSerializable} from '../Json';
import type {Disposer} from '../structure';
import type {
  CallHandler,
  JsonRpcServer,
  Next,
  NotificationHandler,
  ResponseHandler,
} from './JsonRpcServer';
import type {JsonRpcError, JsonRpcId, Request, Response} from './Protocol';
import {isRequest} from './Protocol';
import type {CallScheme, NotificationScheme} from './Scheme';
import type {Tunnel} from './Tunnel';
import {TUNNEL_INCOMING_MESSAGE} from './Tunnel';

export default class JsonRpcServerService<
  C extends CallScheme = CallScheme,
  N extends NotificationScheme = NotificationScheme,
  E extends JsonRpcError = JsonRpcError,
> implements JsonRpcServer<C, N, E>
{
  private readonly _handlers = new Map<
    keyof C | keyof N,
    Set<
      CallHandler<
        unknown,
        ResponseHandler<Either<unknown, unknown>> | undefined
      >
    >
  >();

  constructor(
    private readonly _tunnel: Tunnel<JsonSerializable, JsonSerializable>,
  ) {}

  private _listen(
    method: keyof C | keyof N,
    handler: CallHandler<unknown, unknown>,
  ) {
    const set = this._handlers.get(method);
    if (set !== undefined) {
      set.add(handler);
    } else {
      this._handlers.set(method, new Set([handler]));
    }
    return (() => {
      if ((this._handlers.get(method)?.size ?? 0) <= 1) {
        this._handlers.delete(method);
      } else {
        this._handlers.get(method)?.delete(handler);
      }
    }) as Disposer;
  }

  notification<K extends keyof N>(
    method: K,
    handler: NotificationHandler<N[K]['params']>,
  ) {
    return this._listen(method, handler as CallHandler<unknown, unknown>);
  }

  call<K extends keyof C>(
    method: K,
    handler: CallHandler<
      C[K]['params'],
      ResponseHandler<Either<C[K]['result'], E | JsonRpcError>>
    >,
  ) {
    return this._listen(method, handler as CallHandler<unknown, unknown>);
  }

  private _getNext(
    request: Request,
    _handlers?: readonly CallHandler<
      unknown,
      ResponseHandler<Either<unknown, unknown>> | undefined
    >[],
  ): Next {
    if (_handlers === undefined || _handlers.length === 0) {
      return finalHandler(request);
    } else {
      const handler = _handlers[_handlers.length - 1];
      const head = _handlers.slice(0, _handlers.length - 1);
      if (request.id === undefined) {
        return () =>
          handler(
            request.params,
            undefined,
            this._getNext(request, head),
            undefined as unknown as JsonRpcId,
          );
      } else {
        const id = request.id;
        const responseHandler: ResponseHandler<Either<unknown, unknown>> = {
          respond: async (
            outcome: Either<unknown, unknown>,
          ): Promise<Either<void, GlobalError>> => {
            if (outcome.success) {
              const response: Response = {
                jsonrpc: '2.0',
                result: outcome.right as JsonSerializable,
                id,
              };
              return this._tunnel.send(response);
            } else {
              const response: Response = {
                jsonrpc: '2.0',
                error: outcome.left as JsonRpcError,
                id,
              };
              return this._tunnel.send(response);
            }
          },
        };
        return () =>
          handler(
            request.params,
            responseHandler,
            this._getNext(request, head),
            id,
          );
      }
    }
  }

  private async _onRequest(request: Request) {
    const handlers = this._handlers.get(request.method);
    this._getNext(request, handlers && [...handlers])();
  }

  private _onMessage = async (raw: JsonSerializable) => {
    if (Array.isArray(raw)) {
      throw new Error('Batch requests are not implemented');
    } else if (isRequest(raw)) {
      await this._onRequest(raw);
    }
  };

  reset() {
    this._handlers.clear();
  }

  subscribe() {
    return this._tunnel.io.listen(TUNNEL_INCOMING_MESSAGE, this._onMessage);
  }
}

const finalHandler = (request: Request) => () => {
  if (__DEV__) {
    const params = JSON.stringify(request.params, undefined, 2);
    console.warn(
      `The request #${String(request.id)} ${request.method}(${params}) has not been processed by any handler.`,
    );
  }
  return () => {};
};
