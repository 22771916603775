import {variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import type {LimitCardProps} from './LimitCard/LimitCard';
import LimitCard from './LimitCard/LimitCard';
import type {WalletLimitsCardProps} from './WalletLimitsCard/WalletLimitsCard';
import WalletLimitsCard from './WalletLimitsCard/WalletLimitsCard';
import type {WalletsWithoutLimitsCardProps} from './WalletsWithoutLimitsCard/WalletsWithoutLimitsCard';
import WalletsWithoutLimitsCard from './WalletsWithoutLimitsCard/WalletsWithoutLimitsCard';

export type LgLimitsSettingsScreenProps = {
  walletLimitsCardProps: WalletLimitsCardProps;
  walletsWithoutLimitsCardProps: WalletsWithoutLimitsCardProps;
  limitCardProps: LimitCardProps;
};

export default observer(function LgLimitsSettingsScreen(
  props: LgLimitsSettingsScreenProps,
) {
  return (
    <Container>
      <WalletsWithoutLimitsCard {...props.walletsWithoutLimitsCardProps} />
      <WalletLimitsCard {...props.walletLimitsCardProps} />
      <LimitCard {...props.limitCardProps} />
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    overflow: 'visible',
    paddingBottom: 30,
    paddingTop: 0,

    ...theme.mediaQuery({
      [1220]: {
        paddingHorizontal: 30,
      },

      [LG_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
}));
