import type {
  CryptoCurrency,
  CurrencyDescription,
  Wallet,
  WalletId,
  WalletLimit,
} from '@ncwallet-app/core';
import {
  toCurrencyDescriptionFromCrypto,
  WalletLimitPeriod,
  WalletLimitStatus,
  walletsByCurrencyPopularitySorter,
} from '@ncwallet-app/core';
import {groupBy, isEmpty} from 'lodash';

export type WalletLimitsItemData = {
  walletId: WalletId;
  currency: CurrencyDescription;
  dailyLimit?: WalletLimit;
  monthlyLimit?: WalletLimit;
  hasChanged: boolean;
};

export const toWalletLimitsItemDataList = (
  limits: WalletLimit[],
  wallets: Wallet[],
  currencies: CryptoCurrency[],
): WalletLimitsItemData[] => {
  const currenciesOrder = new Map(currencies.map((c, i) => [c.code, i]));
  const codeToCurrency = new Map(currencies.map(c => [c.code, c]));
  const walletIdToLimits = groupBy(limits, l => l.wallet_id);

  return wallets
    .slice()
    .sort((w1, w2) =>
      walletsByCurrencyPopularitySorter(w1, w2, currenciesOrder),
    )
    .reduce((acc: WalletLimitsItemData[], w): WalletLimitsItemData[] => {
      const currencyLimits = walletIdToLimits[w.id];
      if (isEmpty(currencyLimits)) {
        return acc;
      }

      const dailyLimit = currencyLimits.find(
        l =>
          l.period === WalletLimitPeriod.Daily &&
          l.status === WalletLimitStatus.Active,
      );
      const monthlyLimit = currencyLimits.find(
        l =>
          l.period === WalletLimitPeriod.Monthly &&
          l.status === WalletLimitStatus.Active,
      );

      return [
        ...acc,
        toWalletLimitsItemData(
          w.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          codeToCurrency.get(w.currency)!,
          dailyLimit,
          monthlyLimit,
        ),
      ];
    }, []);
};

const toWalletLimitsItemData = (
  walletId: WalletId,
  c: CryptoCurrency,
  dailyLimit: WalletLimit | undefined,
  monthlyLimit: WalletLimit | undefined,
) => ({
  walletId,
  currency: toCurrencyDescriptionFromCrypto(c),
  dailyLimit,
  monthlyLimit,
  hasChanged: !!(
    dailyLimit?.deactivated_at ||
    dailyLimit?.deleted_at ||
    monthlyLimit?.deactivated_at ||
    monthlyLimit?.deleted_at
  ),
});

export const getDefaultForSelectionActiveLimit = (w: WalletLimitsItemData) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  w.dailyLimit ? w.dailyLimit : w.monthlyLimit!;
