import type {Analytics} from '../Analytics';
import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import {success} from '../fp';
import type {Json} from '../Json';
import type {HeadlessLocalNotifications} from '../LocalNotifications';
import type {HeadlessMessaging} from './HeadlessMessaging';
import type {MessageDescription} from './Message';
import type {MessageContext} from './MessageContext';
import type {MessageHandler} from './MessageHandler';

export default class MessageHandlerImpl implements MessageHandler {
  constructor(
    protected readonly _root: {
      readonly json: Json;
      readonly analytics: Analytics;
      readonly headlessMessaging: HeadlessMessaging;
      readonly headlessLocalNotifications: HeadlessLocalNotifications;
    },
  ) {}

  async handle(
    description: MessageDescription,
  ): Promise<Either<MessageContext, GlobalError>> {
    const meta_ = this._root.json.parse(description.meta);
    if (!meta_.success) {
      return meta_;
    }
    await this._root.analytics.report(description.event_url, {
      event: 'received',
      meta: meta_.right,
      data: {},
    });
    const context_ =
      await this._root.headlessMessaging.fetchContext(description);
    if (!context_.success) {
      return context_;
    }
    const schedule_ =
      await this._root.headlessLocalNotifications.scheduleNotification(
        context_.right,
      );
    if (!schedule_.success) {
      return schedule_;
    }
    return success(context_.right);
  }
}
