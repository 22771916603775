import type {
  CryptoCurrencyCode,
  CurrencyStore,
  FiatCurrencyCode,
  OnTimeoutError,
  Service,
  WalletId,
  WalletStore,
} from '@ncwallet-app/core';
import {
  getDefaultInOutCurrency,
  isEnoughMoneyForSendOrExchange,
  TIMEOUT_ERROR,
} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import {getAccountFromState} from '@ncwallet-app/core/src/AccountStore';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {computed, makeObservable, observable, runInAction} from 'mobx';

import type {CurrencyHistoryRefresher} from '../../../shared/CurrencyHistoryRefresher';
import type {CurrencySelectionListConverter} from '../../../shared/CurrencySelectionListConverter';
import {getCurrencyWalletPairsForUserWallets} from '../../../shared/currencySelectionListConverterHelpers';

export default class SelectionForSendBindingState
  implements Service, OnTimeoutError
{
  @computed
  private get _baseFiat(): FiatCurrencyCode | undefined {
    return getAccountFromState(this._root.accountStore.state)?.base_fiat;
  }

  @observable private _isLoading: boolean = false;
  @observable private _isManualRefresh = false;
  @observable private _isTimeoutError = false;

  @computed get isTimeoutError() {
    return this._isTimeoutError;
  }

  @computed get currencies(): CurrencySelectionListItemData[] | undefined {
    const wallets = this._root.walletStore.getWallets();
    const currencies = this._root.currencyStore.getCryptoCurrencies();
    if (!this._baseFiat || !wallets || !currencies) {
      return;
    }

    const disabledCodes = new Set(
      currencies.filter(c => !c.options.withdraw).map(c => c.code),
    );

    return getCurrencyWalletPairsForUserWallets(currencies, wallets)
      .filter(([, w]) => isEnoughMoneyForSendOrExchange(w))
      .map(([c, w]) => this.itemConverter.toItem(c, w, disabledCodes));
  }

  constructor(
    private readonly _root: {
      readonly accountStore: AccountStore;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
    },
    private readonly itemConverter: CurrencySelectionListConverter,
    private readonly historyRefresher: CurrencyHistoryRefresher,
  ) {
    makeObservable(this);
  }

  getIsLoading = () => this._isLoading;
  getIsManualRefresh = () => this._isManualRefresh;

  manualRefresh = async () => {
    try {
      runInAction(() => {
        this._isManualRefresh = true;
      });
      await this.refresh();
    } finally {
      runInAction(() => {
        this._isManualRefresh = false;
      });
    }
  };

  async refresh() {
    runInAction(() => {
      this._isTimeoutError = false;
      this._isLoading = true;
    });
    const responses = await Promise.all([
      this._root.currencyStore.refreshFiatCurrencies(),
      this._root.walletStore.refreshWallets(),
      this._root.currencyStore.refreshCryptoCurrencies(),
    ]);
    runInAction(() => {
      this._isTimeoutError = responses.some(
        it => !it.success && it.left.kind === TIMEOUT_ERROR,
      );
      this._isLoading = false;

      this.historyRefresher.setStaleCodes(
        this.currencies?.map(
          c => c.cryptoCurrency.code as CryptoCurrencyCode,
        ) ?? [],
      );
    });
  }

  subscribe() {
    return this.historyRefresher.subscribe();
  }

  activateRateHistoryLoad() {
    this.historyRefresher.activate();
  }

  deactivateRateHistoryLoad() {
    this.historyRefresher.deactivate();
  }

  getDefaultOutCurrency(walletId: WalletId) {
    const wallet = this._root.walletStore.getWalletById(walletId);
    const currency =
      wallet && this._root.currencyStore.getCryptoCurrency(wallet.currency);
    return currency && getDefaultInOutCurrency(currency, 'out');
  }
}
