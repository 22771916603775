import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {
  HistoryItemColumn,
  HistoryItemContainer,
  HistoryItemCurrency,
  HistoryItemFiatCurrency,
  HistoryItemTitle,
} from '../HistoryScreen/SharedComponents';
import {WalletIcon} from '../HistoryScreen/WalletIcon';
import type {WalletProps} from './HistoryWalletScreen';

type HistoryWalletItemProps = {
  data: WalletProps;
};

// eslint-disable-next-line import-x/prefer-default-export
export const HistoryWalletListItem = observer(
  (props: HistoryWalletItemProps) => {
    return (
      <HistoryItemContainer onPress={noop}>
        <WalletIcon type={props.data.walletType} width={40} height={40} />
        <HistoryItemTitle>{props.data.walletType}</HistoryItemTitle>
        <HistoryItemColumn>
          <HistoryItemFiatCurrency>
            {props.data.walletFiatValue} {props.data.walletFiat.code}
          </HistoryItemFiatCurrency>
          <HistoryItemCurrency>
            {props.data.walletCurrencyValue} {props.data.walletCurrency.code}
          </HistoryItemCurrency>
        </HistoryItemColumn>
      </HistoryItemContainer>
    );
  },
);
