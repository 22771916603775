import {getAddIpFaqLink, useStrings} from '@ncwallet-app/core';
import type {IpSettings} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/AccountsIpSettingsGet';
import type {IpInfo} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import type {SessionsActiveListProps} from './ActiveSessionsCard';
import ActiveSessionsCard from './ActiveSessionsCard';
import EditSessionIpCard from './EditSessionIpCard';
import IpSettingsOtpCard from './IpSettingsOtpCard';
import PromptClearAllowedListCard from './PromptClearAllowedListCard';
import SessionDeleteIp from './SessionDeleteIp';
import SessionIpList from './SessionIpList';
import SessionIpNew from './SessionIpNew';
import type {SessionCardState} from './SessionKind';
import {SessionKind} from './SessionKind';
import type {SessionsHistoryListProps} from './SessionsHistoryList';
import SessionsHistoryList from './SessionsHistoryList';
import type {SessionsMenuProps} from './SessionsMenu';
import SessionsMenu from './SessionsMenu';

export type SessionsProps = SessionsMenuProps &
  SessionsActiveListProps &
  SessionsHistoryListProps & {
    sessionCardState: SessionCardState;
    onEditIpFormSubmit: (ip: string, name: string) => void;
    addIp: (ip: string, name: string) => void;
    getAddIpFormErrorText: () => string | undefined;
    resetAddIpFormErrorText: () => void;
    getEditIpFormErrorText: () => string | undefined;
    resetEditIpFormErrorText: () => void;
    ipSettings: IpSettings | undefined;
    goToPromptUpdateIp: (info: IpInfo) => void;
    goToPromptAddIp: (type: IpType) => void;
    promptDeleteIp: () => void;
    confirmDeleteIp: () => void;
    onSubmitOtpToDeleteIp: (code: string) => void;
    getOtpToDeleteIpErrorText: () => string | undefined;
    resetOtpToDeleteIpErrorText: () => void;
    onSubmitOtpToAddIp: (code: string) => void;
    getOtpToAddIpErrorText: () => string | undefined;
    resetOtpToAddIpErrorText: () => void;
    onSubmitOtpToUpdateIp: (code: string) => void;
    getOtpToUpdateIpErrorText: () => string | undefined;
    resetOtpToUpdateIpErrorText: () => void;
    confirmClearAllowedList: () => void;
    goToPromptClearAllowedList: () => void;
    onSubmitOtpToClearAllowedList: (code: string) => void;
    getOtpToClearAllowedListErrorText: () => string | undefined;
    resetOtpToClearAllowedLIstErrorText: () => void;
    currentTwoFaProvider: TwoFaProviderKind | undefined;
    onTwoFaResendCode?: () => Promise<void>;
    resendCodeInterval?: number;
    resetInterval?: () => void;
  };

export type BaseSessionMenuProps = {
  onBack: () => void;
};

export default observer(function SessionHolder(props: SessionsProps) {
  const {sessionCardState} = props;
  const strings = useStrings();
  const {goToListAllowedIps, goToListBlockedIps} = props;

  const goToListByIpType = useCallback(
    (type: IpType) => {
      if (type === IpType.Allowed) {
        goToListAllowedIps();
      } else {
        goToListBlockedIps();
      }
    },
    [goToListAllowedIps, goToListBlockedIps],
  );

  switch (sessionCardState.kind) {
    case SessionKind.ListSessionHistory:
      return <SessionsHistoryList {...props} />;
    case SessionKind.ListActiveSessions:
      return <ActiveSessionsCard {...props} />;
    case SessionKind.ListAllowedIps:
      return (
        <SessionIpList
          toEdit={props.goToPromptUpdateIp}
          goToAddNew={() => {
            props.goToPromptAddIp(IpType.Allowed);
          }}
          onBack={props.onBack}
          ipInfos={props.ipSettings ? props.ipSettings.white_ips : undefined}
          type={IpType.Allowed}
          isAllowedIpListWarningShown={false}
        />
      );

    case SessionKind.ListBlockedIps:
      return (
        <SessionIpList
          goToAddNew={() => {
            props.goToPromptAddIp(IpType.Blocked);
          }}
          onBack={props.onBack}
          ipInfos={props.ipSettings ? props.ipSettings.black_ips : undefined}
          type={IpType.Blocked}
          toEdit={props.goToPromptUpdateIp}
          onClearAllowedList={props.goToPromptClearAllowedList}
          isAllowedIpListWarningShown={
            !!props.ipSettings && props.ipSettings.white_ips.length > 0
          }
        />
      );
    case SessionKind.PromptToAddIp:
      return (
        <SessionIpNew
          type={sessionCardState.type}
          onBack={() => {
            goToListByIpType(sessionCardState.type);
          }}
          onSubmit={props.addIp}
          getError={props.getAddIpFormErrorText}
          resetError={props.resetAddIpFormErrorText}
          faqLink={getAddIpFaqLink(sessionCardState.type)}
        />
      );
    case SessionKind.PromptUpdateIp:
      return (
        <EditSessionIpCard
          onDeletePress={props.promptDeleteIp}
          ip={sessionCardState.ip}
          ipName={sessionCardState.name}
          type={IpType.Allowed}
          onSubmit={props.onEditIpFormSubmit}
          getError={props.getEditIpFormErrorText}
          resetError={props.resetEditIpFormErrorText}
          onBack={() => {
            goToListByIpType(sessionCardState.type);
          }}
          placeholder={
            sessionCardState.type === IpType.Allowed
              ? strings['secureSettings.ipList.placeholderIpAllow']
              : strings['secureSettings.ipList.placeholderIpBlock']
          }
        />
      );
    case SessionKind.PromptToDeleteIp:
      return (
        <SessionDeleteIp
          type={sessionCardState.type}
          ip={sessionCardState.ip}
          onConfirm={props.confirmDeleteIp}
          onBack={() => {
            goToListByIpType(sessionCardState.type);
          }}
        />
      );

    case SessionKind.PromptOtpToAddIp:
      return (
        <IpSettingsOtpCard
          currentTwoFaProvider={props.currentTwoFaProvider}
          type={sessionCardState.type}
          onBack={() => {
            goToListByIpType(sessionCardState.type);
          }}
          onSubmit={props.onSubmitOtpToAddIp}
          getError={props.getOtpToAddIpErrorText}
          onResetError={props.resetOtpToAddIpErrorText}
          onTwoFaResendCode={props.onTwoFaResendCode}
          resendCodeInterval={props.resendCodeInterval}
          resetInterval={props.resetInterval}
        />
      );

    case SessionKind.PromptOtpToDeleteIp:
      return (
        <IpSettingsOtpCard
          type={sessionCardState.type}
          currentTwoFaProvider={props.currentTwoFaProvider}
          onBack={() => {
            goToListByIpType(sessionCardState.type);
          }}
          onSubmit={props.onSubmitOtpToDeleteIp}
          getError={props.getOtpToDeleteIpErrorText}
          onResetError={props.resetOtpToDeleteIpErrorText}
          onTwoFaResendCode={props.onTwoFaResendCode}
          resendCodeInterval={props.resendCodeInterval}
          resetInterval={props.resetInterval}
        />
      );

    case SessionKind.PromptOtpToUpdateIp:
      return (
        <IpSettingsOtpCard
          type={sessionCardState.type}
          currentTwoFaProvider={TwoFaProviderKind.Ga}
          onBack={() => {
            goToListByIpType(sessionCardState.type);
          }}
          onSubmit={props.onSubmitOtpToUpdateIp}
          getError={props.getOtpToUpdateIpErrorText}
          onResetError={props.resetOtpToDeleteIpErrorText}
          onTwoFaResendCode={props.onTwoFaResendCode}
          resendCodeInterval={props.resendCodeInterval}
          resetInterval={props.resetInterval}
        />
      );

    case SessionKind.PromptToClearAllowedList:
      return (
        <PromptClearAllowedListCard
          onConfirm={props.confirmClearAllowedList}
          onBack={props.goToListBlockedIps}
        />
      );

    case SessionKind.PromptOtpToClearAllowedList:
      return (
        <IpSettingsOtpCard
          type={IpType.Allowed}
          currentTwoFaProvider={TwoFaProviderKind.Ga}
          onBack={props.goToListBlockedIps}
          onSubmit={props.onSubmitOtpToClearAllowedList}
          getError={props.getOtpToClearAllowedListErrorText}
          onResetError={props.resetOtpToClearAllowedLIstErrorText}
          onTwoFaResendCode={props.onTwoFaResendCode}
          resendCodeInterval={props.resendCodeInterval}
          resetInterval={props.resetInterval}
        />
      );

    case SessionKind.ListSecuritySettings:
      return <SessionsMenu {...props} />;
  }
});
