import {isChromeExtension} from '@ncwallet-app/navigation/src/Navigation/hooks/isChromeExtension';
import {getLocaleKeyByTotpActionType} from '@ncwallet-app/navigation/src/screens/UserSettingsScreens/TwoFaSettingsScreen/getLocaleKeyByTotpActionType';
import dayjs from 'dayjs';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import type {
  JsonKeyValueStore,
  JsonSecureKeyValueMap,
} from '../JsonKeyValueStore';
import type {JsonRpcClient} from '../JsonRpc';
import type {
  Account,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  OtpCode,
} from '../NCWalletServer';
import type {
  TotpAction,
  TotpActionOption,
  TotpActionType,
} from '../NCWalletServer/AccountsTotpActions';
import type {AccountsTotpSettingsSet} from '../NCWalletServer/accountsTotpSettingsSet';
import type {ISODateString} from '../Time';
import {TwoFaProviderKind} from '../TwoFaHelper';
import {getOSKind, OSKind} from '../util/getOSKind';
import type {TwoFaSettingsState} from './TwoFaSettingsState';

// eslint-disable-next-line import-x/prefer-default-export
export class TwoFaSettingsStateImpl implements TwoFaSettingsState {
  @observable private _currentTwoFaProvider: TwoFaProviderKind | undefined;
  @observable private _prevTwoFaProvider: TwoFaProviderKind | undefined;
  @observable excluded = new Set<TotpActionType>();
  private _initialExcluded = new Set<TotpActionType>();
  @observable private _actions: TotpAction[] | undefined;

  @computed get currentTwoFaProvider() {
    return this._currentTwoFaProvider;
  }

  @computed get prevTwoFaProvider() {
    return this._prevTwoFaProvider;
  }

  setCurrentTwoFaProvider(kind: TwoFaProviderKind | undefined) {
    this._prevTwoFaProvider = this._currentTwoFaProvider;
    this._currentTwoFaProvider = kind;
    if (kind !== TwoFaProviderKind.Telegram) {
      void this._root.jsonSecureKeyValueStore.delete('hasRemoteChannel');
    }
  }

  @computed get actions(): TotpActionOption[] | undefined {
    return this._actions
      ?.filter(
        a =>
          a.editable &&
          // если текст для действия не добавлен, не показывать его
          // (возможно при добавлении нового действия на беке и его отсутствии в старой версии)
          getLocaleKeyByTotpActionType(a.type),
      )
      .map(a => ({
        type: a.type,
        enabled: !this.excluded.has(a.type),
      }));
  }

  @computed get isEdited() {
    return !(
      this.excluded.size === this._initialExcluded.size &&
      [...this.excluded].every(a => this._initialExcluded.has(a))
    );
  }

  constructor(
    private readonly _root: {
      readonly jsonSecureKeyValueStore: JsonKeyValueStore<JsonSecureKeyValueMap>;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {
    makeObservable(this);
  }

  getExcluded() {
    return [...this.excluded];
  }

  getIncluded() {
    return (this.actions || []).filter(o => o.enabled).map(o => o.type);
  }

  async refresh() {
    const [actionsRes, settingsRes] = await Promise.all([
      this._root.ncWalletJsonRpcClient.call('accounts.totp.actions', {}),
      this._root.ncWalletJsonRpcClient.call('accounts.totp.settings.get', {}),
    ]);

    if (!actionsRes.success || !settingsRes.success) {
      return;
    }

    runInAction(() => {
      this._updateLocalState(settingsRes.right);
      this._actions = actionsRes.right;
      if (settingsRes.right.channels) {
        const channels = Object.keys(settingsRes.right.channels);
        this.setCurrentTwoFaProvider(
          Object.keys(settingsRes.right.channels)[
            channels.length - 1
          ] as TwoFaProviderKind,
        );
      }
    });
  }

  @action.bound
  reset() {
    this.excluded = new Set(this._initialExcluded);
  }

  @action.bound
  async switchAction(actionOption: TotpActionOption) {
    const newExcluded = new Set(this.excluded);
    if (actionOption.enabled) {
      newExcluded.add(actionOption.type);
    } else {
      newExcluded.delete(actionOption.type);
    }
    this.excluded = newExcluded;
  }

  async update(
    code: OtpCode,
    excluded: TotpActionType[],
    included: TotpActionType[],
  ) {
    const res = await this._root.ncWalletJsonRpcClient.call(
      'accounts.totp.settings.set',
      {
        add_to_exclude: excluded,
        delete_from_exclude: included,
        code_2fa: code,
        utc_2fa: dayjs().utc().toISOString() as ISODateString,
      },
    );
    if (res.success) {
      this._updateLocalState(res.right);
    }
    return res;
  }

  getAvailableProviders(account?: Account) {
    const os = getOSKind();
    const providers = [TwoFaProviderKind.Ga];
    const telegramUsername = account?.telegram_username;

    if (!isChromeExtension() || telegramUsername) {
      providers.push(TwoFaProviderKind.Telegram);
    }
    if (os === OSKind.IOs || os === OSKind.MacOS) {
      providers.push(TwoFaProviderKind.Akm);
    }
    return providers;
  }

  async getOptimisticTwoFaProvider() {
    const hasRemoteChannel =
      await this._root.jsonSecureKeyValueStore.get('hasRemoteChannel');

    if (!hasRemoteChannel.success) {
      return;
    }

    return hasRemoteChannel.right ? TwoFaProviderKind.Telegram : undefined;
  }

  @action.bound
  private _updateLocalState(res: AccountsTotpSettingsSet['result']) {
    this._initialExcluded = new Set(
      Object.keys(res.exclude) as TotpActionType[],
    );
    this.excluded = this._initialExcluded;
  }
}
