import type {CurrencyDescription, DecimalString} from '@ncwallet-app/core';
import {
  formatFiatValue,
  useBalanceCryptoValue,
  variance,
} from '@ncwallet-app/core';
import {useTheme} from '@ncwallet-app/core/src/styling';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import type {SvgProps} from 'react-native-svg';

import {BaseSkeleton, CurrencyCircleLogo, SkeletonColor} from '../../atoms';
import {LG_BREAKPOINT} from '../../grid';

export type ReceiveWalletInfoProps = {
  cryptoCurrency?: CurrencyDescription;
  cryptoValue?: DecimalString;
  fiatCurrency?: CurrencyDescription;
  fiatValue?: DecimalString;
  walletName: string;
  Icon?: React.ComponentType<SvgProps>;
  onCurrencyPress?: () => void;
};

export const WalletHeader = observer((props: ReceiveWalletInfoProps) => {
  const theme = useTheme();
  const Icon = props.Icon;

  const formatCryptoValue = useBalanceCryptoValue(
    props.cryptoValue || '0',
    props.cryptoCurrency?.fractionDigits,
  );

  return (
    <WalletInfoContainer onPress={props.onCurrencyPress}>
      <WalletLogoContainer>
        {props.cryptoCurrency ? (
          <CurrencyCircleLogo code={props.cryptoCurrency.code} size={40} />
        ) : (
          <BaseSkeleton width={40} height={40} isRounded />
        )}
      </WalletLogoContainer>
      <WalletDescription>
        {props.cryptoCurrency ? (
          <>
            <Row>
              <CryptoName>{props.cryptoCurrency.name} </CryptoName>
              <WalletCryptoValue>
                {formatCryptoValue} <Text>{props.cryptoCurrency.code}</Text>
              </WalletCryptoValue>
            </Row>
            <Row>
              <WalletType>{props.walletName}</WalletType>
              {props.fiatCurrency && !isNil(props.fiatValue) ? (
                <WalletFiatValue>
                  {formatFiatValue(props.fiatValue)}{' '}
                  <Text>{props.fiatCurrency.code}</Text>
                </WalletFiatValue>
              ) : (
                <BaseSkeleton width={105} height={20} gap={5} />
              )}
            </Row>
          </>
        ) : (
          <>
            <BaseSkeleton width={55} height={20} gap={5} />
            <BaseSkeleton width={85} height={15} color={SkeletonColor.Light} />
          </>
        )}
      </WalletDescription>
      {Icon && <Icon style={styles.icon} color={theme.palette.uiAdditional1} />}
    </WalletInfoContainer>
  );
});

const Row = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const WalletInfoContainer = variance(TouchableOpacity)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 72,
    padding: 15,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiPrimary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderWidth: 1,
        borderRadius: 8,
      },
    }),
  },
}));

const WalletLogoContainer = variance(View)(() => ({
  root: {
    position: 'relative',
    marginRight: 5,
  },
}));

const WalletDescription = variance(View)(() => ({
  root: {
    justifyContent: 'space-between',
    flex: 1,
  },
}));

const CryptoName = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 16,
  },
}));

const WalletType = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 13,
  },
}));

const WalletFiatValue = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    textAlign: 'right',
    fontSize: 13,
    color: theme.palette.textAdditional2,
  },
}));

const WalletCryptoValue = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textSecondary,
    fontSize: 14,
    textAlign: 'right',
    marginLeft: 'auto',
  },
}));

const styles = StyleSheet.create({
  icon: {
    marginLeft: 15,
  },
});
