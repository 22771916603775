import {toArray} from 'lodash';
import type {
  ForwardedRef,
  PropsWithChildren,
  ReactElement,
  RefAttributes,
} from 'react';
import React from 'react';
import type {
  ScrollViewProps as RNScrollViewProps,
  SectionListProps as RNSectionListProps,
} from 'react-native';
import {
  RefreshControl as RNRefreshControl,
  ScrollView as RNScrollView,
  SectionList as RNSectionList,
} from 'react-native';
import type {NativeViewGestureHandlerProps} from 'react-native-gesture-handler';
import {createNativeWrapper} from 'react-native-gesture-handler';

const nativeViewProps = [
  'id',
  'enabled',
  'shouldCancelWhenOutside',
  'hitSlop',
  'cancelsTouchesInView',
  'userSelect',
  'waitFor',
  'simultaneousHandlers',
  'onBegan',
  'onFailed',
  'onCancelled',
  'onActivated',
  'onEnded',
  'onGestureEvent',
  'onHandlerStateChange',
  'shouldActivateOnStart',
  'disallowInterruption',
];
export const RefreshControl = createNativeWrapper(RNRefreshControl, {
  disallowInterruption: true,
  shouldCancelWhenOutside: false,
});
export type RefreshControl = typeof RefreshControl & RNRefreshControl;

const GHScrollView = createNativeWrapper<PropsWithChildren<RNScrollViewProps>>(
  RNScrollView,
  {
    disallowInterruption: true,
    shouldCancelWhenOutside: false,
  },
);
export const ScrollView = React.forwardRef<
  RNScrollView,
  RNScrollViewProps & NativeViewGestureHandlerProps
>((props, ref) => {
  const refreshControlGestureRef = React.useRef<RefreshControl>(null);
  const {refreshControl, waitFor, ...rest} = props;

  return (
    <GHScrollView
      {...rest}
      // @ts-expect-error `ref` exists on `GHScrollView`
      ref={ref}
      waitFor={[...toArray(waitFor ?? []), refreshControlGestureRef]}
      // @ts-expect-error we don't pass `refreshing` prop as we only want to override the ref
      refreshControl={
        refreshControl
          ? React.cloneElement(refreshControl, {
              // @ts-expect-error for reasons unknown to me, `ref` doesn't exist on the type inferred by TS
              ref: refreshControlGestureRef,
            })
          : undefined
      }
    />
  );
});
// backward type compatibility with https://github.com/software-mansion/react-native-gesture-handler/blob/db78d3ca7d48e8ba57482d3fe9b0a15aa79d9932/react-native-gesture-handler.d.ts#L440-L457
// include methods of wrapped components by creating an intersection type with the RN component instead of duplicating them.
export type ScrollView = typeof GHScrollView & RNScrollView;

export const SectionListComponent = React.forwardRef((props, ref) => {
  const refreshControlGestureRef = React.useRef<RefreshControl>(null);

  const {waitFor, refreshControl, ...rest} = props;

  const sectionListProps = {};
  const scrollViewProps = {};
  for (const [propName, value] of Object.entries(rest)) {
    // https://github.com/microsoft/TypeScript/issues/26255
    if ((nativeViewProps as readonly string[]).includes(propName)) {
      // @ts-expect-error - this function cannot have generic type, so we have to ignore this error
      scrollViewProps[propName] = value as unknown;
    } else {
      // @ts-expect-error - this function cannot have generic type, so we have to ignore this error
      sectionListProps[propName] = value as unknown;
    }
  }

  return (
    <RNSectionList
      ref={ref}
      {...sectionListProps}
      renderScrollComponent={scrollProps => (
        <ScrollView
          // https://github.com/facebook/react-native/issues/26610#issuecomment-1340040633
          scrollIndicatorInsets={{right: 1}}
          {...{
            ...scrollProps,
            ...scrollViewProps,
            waitFor: [...toArray(waitFor ?? []), refreshControlGestureRef],
          }}
        />
      )}
      // @ts-expect-error we don't pass `refreshing` prop as we only want to override the ref
      refreshControl={
        refreshControl
          ? React.cloneElement(refreshControl, {
              // @ts-expect-error for reasons unknown to me, `ref` doesn't exist on the type inferred by TS
              ref: refreshControlGestureRef,
            })
          : undefined
      }
    />
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) as <ItemT = any, SectionT = any>(
  props: PropsWithChildren<
    RNSectionListProps<ItemT, SectionT> &
      RefAttributes<RNSectionList<ItemT, SectionT>> &
      NativeViewGestureHandlerProps
  >,
  ref: ForwardedRef<RNSectionList<ItemT, SectionT>>,
) => ReactElement | null;
export type SectionListType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ItemT = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SectionT = any,
> = typeof SectionListComponent & RNSectionList<ItemT, SectionT>;
