import type {Credentials} from '../Credentials';
import type {GlobalError} from '../Error';
import type {Jwt} from '../Jwt';
import type {AccountId} from '../NCWalletServer';
import type {BusSource, RouterSource, Service} from '../structure';
import {BusImpl, RouterImpl} from '../structure';
import type {Time} from '../Time';
import type {
  AccountEventsMap,
  AccountIdentity,
  AccountIdStore,
} from './AccountIdStore';
import {INITIALIZE, LOG_IN, LOG_OUT, SWITCH} from './AccountIdStore';
import type {AuthClient} from './AuthClient';
import {AUTHORIZED, MULTI_FACTOR, UNAUTHORIZED} from './AuthQuery';

export default class AccountIdStoreService implements AccountIdStore, Service {
  private _initialized = false;
  private _identity?: AccountIdentity;
  private readonly _events = new RouterImpl<AccountEventsMap>();
  private readonly _errors = new BusImpl<(_: GlobalError) => unknown>();

  constructor(
    private readonly _root: {
      readonly time: Time;
      readonly jwt: Jwt;
      readonly authClient: AuthClient;
    },
  ) {}

  get events(): RouterSource<AccountEventsMap> {
    return this._events;
  }

  get errors(): BusSource<(_: GlobalError) => unknown> {
    return this._errors;
  }

  subscribe() {
    return this._root.authClient.responses.domain.listen(event => {
      switch (event.theme) {
        case AUTHORIZED:
        case MULTI_FACTOR:
          this._processCredentials(event.args[0].credentials);
          break;
        case UNAUTHORIZED:
          if (this._initialized) {
            if (this._identity) {
              this._events.send(LOG_OUT, this._identity);
            }
          } else {
            this._events.send(INITIALIZE);
          }
          this._identity = undefined;
          break;
      }
      this._initialized = true;
    });
  }

  private _processCredentials(credentials: Credentials) {
    const token = credentials.isDirect
      ? credentials.refreshToken
      : credentials.multiFactorToken;
    const jwt_ = this._root.jwt.parse(token);
    if (!jwt_.success) {
      this._errors.send(jwt_.left);
      return;
    }
    const accountId = jwt_.right.payload.sub as string as AccountId;
    const identity = {accountId, credentials};
    if (this._initialized) {
      if (this._identity) {
        if (this._identity.accountId !== accountId) {
          this._events.send(SWITCH, identity, this._identity);
        }
      } else {
        this._events.send(LOG_IN, identity);
      }
    } else {
      this._events.send(INITIALIZE, identity);
    }
    this._identity = identity;
  }
}
