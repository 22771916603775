import {useStrings, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {
  RefreshControlPropsAndroid,
  RefreshControlPropsIOS,
  RefreshControl as RNRefreshControl,
} from 'react-native';

import {RefreshControl} from './RefreshControl';

export type CommonRefreshControlProps = {
  getIsRefreshing: () => boolean;
  onRefresh: () => void;
} & RefreshControlPropsIOS &
  RefreshControlPropsAndroid;

export default observer<CommonRefreshControlProps, RNRefreshControl>(
  React.forwardRef(function CommonRefreshControl(props, ref) {
    const {getIsRefreshing, onRefresh, ...rest} = props;
    const refreshing = getIsRefreshing();
    const strings = useStrings();

    return (
      <StyledRefreshControl
        ref={ref}
        refreshing={refreshing}
        onRefresh={onRefresh}
        title={strings['refreshControl.title']}
        enabled
        {...rest}
      />
    );
  }),
);

export const StyledRefreshControl = variance(RefreshControl)(
  () => ({
    root: {},
  }),
  theme => ({
    tintColor: theme.palette.primary,
    titleColor: theme.palette.textSecondary,
    colors: [theme.palette.primary],
    progressBackgroundColor: theme.palette.background,
  }),
);
