import type {
  CurrenciesRateHistoryItem,
  CurrencyDescription,
  DecimalString,
  RateHistoryPeriod,
} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core';
import {
  FADE_DURATION,
  LG_BREAKPOINT,
  Spinner,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {clamp} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {getYForX, useVector} from 'react-native-redash';

import {GRAPH_HEIGHT} from './constants';
import {toCurrencyGraphData} from './CurrencyGraphData';
import Graph from './Graph';
import Header from './Header';
import RangeTabs from './RangeTabs';

export type CurrencyGraphProps = {
  isLoading: boolean;
  rateHistory: CurrenciesRateHistoryItem[] | undefined;
  chartWidth: number;
  firstPrice: DecimalString | undefined;
  activePeriod: RateHistoryPeriod;
  onPeriodChange: (period: RateHistoryPeriod) => void;
  fiatCurrency: CurrencyDescription | undefined;
  fiatRate: DecimalString | undefined;
  cryptoCurrency?: CurrencyDescription | undefined;
  cryptoValue?: DecimalString | undefined;
  fiatValue?: DecimalString | undefined;
  Container?: View | null;
};

export default observer(function CurrencyGraph(props: CurrencyGraphProps) {
  const {
    chartWidth,
    rateHistory,
    activePeriod,
    onPeriodChange,
    cryptoCurrency,
    fiatCurrency,
    firstPrice,
    fiatValue,
    cryptoValue,
    Container,
  } = props;

  const currentGraph = useMemo(() => {
    return rateHistory
      ? toCurrencyGraphData(rateHistory, chartWidth)
      : undefined;
  }, [chartWidth, rateHistory]);

  const isLg = useIsDimensions('lg');
  const isActiveCursor = useSharedValue(true);
  const cursorTranslation = useVector();
  const showInfo = useSharedValue(false);
  const spinnerOpacity = useSharedValue(0);
  const [showSpinner, setShowSpinner] = useState(props.isLoading);

  const setHelperToEnd = useCallback(() => {
    showInfo.value = false;
    const startX = clamp(chartWidth - 1, 0, chartWidth);
    cursorTranslation.x.value = startX;
    cursorTranslation.y.value =
      (currentGraph && getYForX(currentGraph.path, startX)) || 0;
  }, [
    chartWidth,
    currentGraph,
    cursorTranslation.x,
    cursorTranslation.y,
    showInfo,
  ]);

  useEffect(() => {
    if (props.isLoading) {
      setHelperToEnd();
    }
  }, [setHelperToEnd, showInfo, props.isLoading]);

  const spinnerAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: spinnerOpacity.value,
    };
  });

  useEffect(() => {
    if (props.isLoading) {
      setShowSpinner(true);
      spinnerOpacity.value = withTiming(1, {duration: FADE_DURATION});
    } else {
      spinnerOpacity.value = withTiming(
        0,
        {duration: FADE_DURATION},
        finished => {
          if (finished && !props.isLoading) {
            runOnJS(setShowSpinner)(false);
          }
        },
      );
    }
  }, [props.isLoading, spinnerOpacity]);

  return (
    <View>
      <ContentView>
        <View>
          {!isLg && (
            <Header
              fiatRate={props.fiatRate}
              firstPrice={firstPrice}
              cryptoCurrency={cryptoCurrency}
              fiatCurrency={fiatCurrency}
              cryptoValue={cryptoValue}
              fiatValue={fiatValue}
            />
          )}
          {currentGraph && currentGraph.path.curves.length > 1 && (
            <Graph
              currencyGraph={currentGraph}
              cursorTranslation={cursorTranslation}
              chartWidth={chartWidth}
              isActiveCursor={isActiveCursor}
              activePeriod={activePeriod}
              fiatCurrency={fiatCurrency}
              Container={Container}
            />
          )}
        </View>
        {showSpinner && (
          <SpinnerView style={spinnerAnimatedStyle}>
            <Spinner />
          </SpinnerView>
        )}
      </ContentView>
      <RangeTabs activeTabKey={activePeriod} onTabPress={onPeriodChange} />
    </View>
  );
});

const ContentView = variance(View)(theme => ({
  root: {
    height: GRAPH_HEIGHT,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        height: GRAPH_HEIGHT - 70,
      },
    }),
  },
}));

const SpinnerView = variance(Animated.View)(theme => ({
  root: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.palette.background,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
