import {FULFILLED, useRoot} from '@ncwallet-app/core/';
import {useAccountState} from '@ncwallet-app/core/src/AccountStore';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import {SelectTwoFaProviderScreen} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptSelectTwoFaProviderBindingProps =
  SmallHomeStackBindingProps<'PromptSelectTwoFaProvider'>;

export default observer(function PromptSelectTwoFaProviderBinding(
  props: PromptSelectTwoFaProviderBindingProps,
) {
  const {navigation, route} = props;
  const {twoFaSettingsState, accountStore} = useRoot();

  const handleOnClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const selectedTwoFaProvider = useMemo(() => {
    if (accountStore.state?.status !== FULFILLED) {
      return;
    }

    return accountStore.state.result.tfa
      ? twoFaSettingsState.currentTwoFaProvider
      : undefined;
  }, [accountStore, twoFaSettingsState]);

  const handleTwoFaProviderSelect = useCallback(
    async (kind: TwoFaProviderKind) => {
      handleOnClose();
      if (kind === TwoFaProviderKind.Telegram) {
        const account =
          accountStore.state?.status === FULFILLED && accountStore.state.result;

        if (!account) {
          return;
        }

        if (!account.telegram_username) {
          navigation.replace('PromptLinkToTelegram');
          return;
        }

        navigation.navigate('PromptOtpToEnableTwoFa', {
          providerKind: kind,
          onTwoFaEnabled: route.params?.onTwoFaEnabled,
        });
        return;
      }

      if (selectedTwoFaProvider) {
        navigation.replace('PromptOtpToDisableTwoFa', {
          redirectToEnableKind: TwoFaProviderKind.Ga,
          providerKind: TwoFaProviderKind.Telegram,
        });
        return;
      }
      navigation.replace('ShowTwoFaGenerateSecret', {
        providerKind: kind,
        onTwoFaEnabled: route.params?.onTwoFaEnabled,
      });
    },
    [
      handleOnClose,
      selectedTwoFaProvider,
      navigation,
      route.params?.onTwoFaEnabled,
      accountStore,
    ],
  );

  const account = useAccountState().account;
  const availableProviders = useMemo(() => {
    return twoFaSettingsState.getAvailableProviders(account);
  }, [account, twoFaSettingsState]);

  return (
    <SelectTwoFaProviderScreen
      selectedTwoFaProvider={selectedTwoFaProvider}
      providers={availableProviders}
      onClose={handleOnClose}
      onTwoFaProviderSelect={handleTwoFaProviderSelect}
    />
  );
});
