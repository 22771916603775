import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';

import type {NewsItemData} from './NewsList';
import NewsList from './NewsList';
import type {TipItemData} from './TipList';
import TipList from './TipList';

export type MainFooterProps = {
  news: NewsItemData[];
  tips: TipItemData[];
};

export default observer(function MainFooter({news, tips}: MainFooterProps) {
  return (
    <View style={styles.root}>
      <View style={styles.left}>
        <TipList data={tips} />
      </View>
      <View style={styles.right}>
        <NewsList data={news} />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    height: 520,
    borderRadius: 10,
  },
  left: {
    flex: 1,
    paddingRight: 10,
  },
  right: {
    flex: 1,
    paddingLeft: 10,
  },
});
