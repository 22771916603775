import {setStringAsync} from 'expo-clipboard';
import {useCallback} from 'react';

import {useRoot} from '../Root/hooks';

export default function useLogExporter() {
  const {logExporter, log, alert} = useRoot();

  const copyLog = useCallback(() => {
    void setStringAsync(logExporter.getContent(log));
    alert.alert(
      'Success',
      'The value has been copied to clipboard',
      [{style: 'default', text: 'OK'}],
      {cancelable: true},
    );
  }, [alert, log, logExporter]);

  const saveLog = useCallback(async () => {
    try {
      await logExporter.save();
      alert.alert(
        'Success',
        'The value is successfully saved to the file',
        [{style: 'default', text: 'OK'}],
        {cancelable: true},
      );
    } catch {
      alert.alert('Log exporter error');
    }
  }, [alert, logExporter]);

  const shareLog = useCallback(async () => {
    try {
      await logExporter.share();
    } catch {
      alert.alert('Share log error');
    }
  }, [alert, logExporter]);

  return {copyLog, saveLog, shareLog};
}
