import type {Maybe} from '../Maybe';
import {isPromise} from '../util';

function unwrap<T>(_: Maybe<T>): T;
function unwrap<T>(_: Promise<Maybe<T>>): Promise<T>;
function unwrap<T>(_: Promise<Maybe<T>> | Maybe<T>): T | Promise<T> {
  if (isPromise(_)) {
    return _.then(__ => {
      if (__.success) {
        return __.right;
      } else {
        throw __.left.raw;
      }
    });
  }
  if (_.success) {
    return _.right;
  }
  throw _.left.raw;
}

export default unwrap;
