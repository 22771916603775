import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import type {PromptConfirmationForExchangeContainerProps} from '../../../../../CommonNavigationContainers/hooks/usePromptConfirmationForExchangeContainer';
import {usePromptConfirmationForExchangeContainer} from '../../../../../CommonNavigationContainers/hooks/usePromptConfirmationForExchangeContainer';
import ExchangeCryptoConfirmScreenWide from '../../../../../screens/ExchangeCryptoConfirmScreen/ExchangeCryptoConfirmScreenWide';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptConfirmationForExchangeBindingProps =
  LargeSwitchBindingProps<'PromptConfirmationForExchange'>;

export default observer(function PromptConfirmationForExchangeBinding(
  props: PromptConfirmationForExchangeBindingProps,
) {
  const {route, navigation} = props;

  const containerProps = useMemo<PromptConfirmationForExchangeContainerProps>(
    () => ({
      params: route.params,
      setParams: params => {
        navigation.setParams(params);
      },
      notifyAboutSuccessfulExchange: params => {
        navigation.navigate('NotifyAboutSuccessfulExchange', params);
      },
      promptOtpToExchange: params => {
        navigation.navigate('PromptOtpToExchange', params);
      },
      goBack: () => {
        if (navigation.canGoBack()) {
          navigation.goBack();
          return;
        }
        navigation.reset({
          index: 0,
          routes: [{name: 'PromptSourceCryptoToExchange'}],
        });
      },
    }),
    [navigation, route.params],
  );

  const screenProps = usePromptConfirmationForExchangeContainer(containerProps);

  return (
    <ExchangeCryptoConfirmScreenWide
      {...screenProps}
      onBackPress={containerProps.goBack}
    />
  );
});
