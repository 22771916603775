import type {AccountAddressData} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {isEmpty, isEqual} from 'lodash';
import {action, makeObservable, observable} from 'mobx';

export class AddressFormState {
  // all address fields are optional
  @observable country = '';
  @observable city = '';
  @observable street = '';
  @observable index = '';
  @observable _initial: AccountAddressData | undefined;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setCity(city: string) {
    this.city = city;
  }

  @action.bound
  setStreet(street: string) {
    this.street = street;
  }

  @action.bound
  setIndex(index: string) {
    this.index = index;
  }

  @action.bound
  setInitialValue(
    data: AccountAddressData | undefined,
    country: string | undefined,
  ) {
    this.country = (isEmpty(country) ? data?.country : country) ?? '';

    if (isEqual(this._initial, data)) {
      return;
    }

    this._initial = data;
    this.city = data?.city ?? '';
    this.street = data?.street ?? '';
    this.index = data?.index ?? '';
  }

  getValue(): AccountAddressData {
    return {
      country: getOptionalString(this.country),
      city: getOptionalString(this.city),
      street: getOptionalString(this.street),
      index: getOptionalString(this.index),
    };
  }
}

export const getOptionalString = (str: string) => {
  const trimmed = str.trim();
  return trimmed.length === 0 ? undefined : trimmed;
};
