import type {WalletSlideData} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';

import WalletSlide from './WalletSlide';

export type WalletsSliderProps = {
  getWallet: () => WalletSlideData | undefined;
  onLastTransactionPress: () => void;
};

export default observer(function WalletsSlider(props: WalletsSliderProps) {
  return (
    <WalletSlide
      getWallet={props.getWallet}
      onLastTransactionPress={props.onLastTransactionPress}
    />
  );
});
