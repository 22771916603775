import type {AccountStore} from '../AccountStore';
import {FULFILLED} from '../AsyncAtom';
import {error, success} from '../fp';
import type {JsonRpcClient} from '../JsonRpc';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {TotpActionType} from '../NCWalletServer/AccountsTotpActions';
import type {TwoFaHelper} from './TwoFaHelper';

export default class TwoFaHelperImpl implements TwoFaHelper {
  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly accountStore: AccountStore;
    },
  ) {}

  isTwoFaRequiredForExchange() {
    return this.isTwoFaRequired('exchange');
  }

  isTwoFaRequiredForAccountDeletion() {
    return this.isTwoFaRequired('delete_account');
  }

  isTwoFaRequiredForLimits() {
    return this.isTwoFaRequired('set_limit');
  }

  isTwoFaRequiredForIpSettings() {
    return this.isTwoFaRequired('manage_ips');
  }

  private async isTwoFaRequired(field: TotpActionType) {
    if (this._root.accountStore.state?.status !== FULFILLED) {
      throw new Error(
        'TwoFaHelperImpl.isTwoFaRequired called when account state not fulfilled',
      );
    }

    if (!this._root.accountStore.state.result.tfa) {
      return success(false);
    }

    const otpSettingsRes = await this._root.ncWalletJsonRpcClient.call(
      'accounts.totp.settings.get',
      {},
    );

    return otpSettingsRes.success
      ? success(!otpSettingsRes.right.exclude[field])
      : error(otpSettingsRes.left);
  }
}
