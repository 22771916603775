import type {Path} from './Path';
import PathImpl from './PathImpl';

export default class LazyPathImpl implements Path {
  private _path?: Path;

  constructor(private readonly _origin: string) {}

  get pathname() {
    return this._parseOrGetFromCache().pathname;
  }

  get params() {
    return this._parseOrGetFromCache().params;
  }

  toString() {
    return this._origin;
  }

  private _parseOrGetFromCache() {
    if (!this._path) {
      this._path = PathImpl.parse(this._origin);
    }
    return this._path;
  }
}
