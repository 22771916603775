import type {CryptoCurrency, Wallet} from '@ncwallet-app/core';
import {walletsByCurrencyPopularitySorter} from '@ncwallet-app/core';

export const getCurrencyWalletPairsForUserWallets = (
  currencies: CryptoCurrency[],
  wallets: Wallet[],
): [CryptoCurrency, Wallet][] => {
  const currenciesMap = new Map(currencies.map(c => [c.code, c]));

  const popularityMap = new Map(
    currencies.map((crypto, i) => [crypto.code, i]),
  );
  const sortedWallets = wallets
    .slice()
    .sort((w1, w2) => walletsByCurrencyPopularitySorter(w1, w2, popularityMap));

  const currencyWalletPairs: [CryptoCurrency, Wallet][] = [];

  sortedWallets.forEach(w => {
    const currency = currenciesMap.get(w.currency);
    if (currency) {
      currencyWalletPairs.push([currency, w]);
    }
  });

  return currencyWalletPairs;
};

export const getCurrencyWalletPairsForAllCurrencies = (
  currencies: CryptoCurrency[],
  wallets: Wallet[],
): [CryptoCurrency, Wallet | undefined][] => {
  const userWallets = getCurrencyWalletPairsForUserWallets(currencies, wallets);
  const walletMap = new Map(wallets.map(wallet => [wallet.currency, wallet]));

  const other: [CryptoCurrency, undefined][] = currencies
    .filter(c => !walletMap.get(c.code))
    .map(c => [c, undefined]);

  return [...userWallets, ...other];
};
