import queryString from 'query-string';

import type {GeneralRestClientError, GlobalError, UnknownError} from '../Error';
import {GENERAL_REST_CLIENT_ERROR, UNKNOWN_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {Http} from '../Http';
import type {Json, JsonSerializable} from '../Json';
import type {AnalyticsEventUrl} from '../Messaging';
import {MESSAGING_PROTOCOL_VERSION} from '../Messaging';
import type {Analytics} from './Analytics';
import type {ReportParams} from './Report';

const {stringify} = queryString;

export default class AnalyticsImpl implements Analytics {
  constructor(
    private readonly _root: {
      readonly json: Json;
      readonly errorRepository: ErrorRepository;
      readonly http: Http;
    },
  ) {}

  async report(
    eventUrl: AnalyticsEventUrl,
    params: ReportParams,
  ): Promise<Either<void, GlobalError>> {
    try {
      const init = `${eventUrl}?${stringify({sv: MESSAGING_PROTOCOL_VERSION})}`;

      const body_ = this._root.json.stringify(params);
      if (!body_.success) {
        return body_;
      }
      const response_ = await this._root.http.fetch(init, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: body_.right,
        mode: 'cors',
      });
      if (!response_.success) {
        return response_;
      }
      if (response_.right.ok) {
        return success();
      }
      let responseBody;
      try {
        responseBody = (await response_.right.json()) as JsonSerializable;
      } catch {
        /* empty */
      }
      return error(
        this._root.errorRepository.create<GeneralRestClientError>({
          kind: GENERAL_REST_CLIENT_ERROR,
          raw: response_.right,
          body: responseBody,
          statusCode: response_.right.status,
        }),
      );
    } catch (raw) {
      return error(
        this._root.errorRepository.create<UnknownError>({
          kind: UNKNOWN_ERROR,
          raw,
          description: 'Analytics request failed',
        }),
      );
    }
  }
}
