import type {
  TransactionDirection,
  TransactionType,
} from '../NCWalletServer/WalletsTransactions';

export enum ReportFileType {
  PDF = 'pdf',
}

export interface TransactionReport {
  readonly isError: boolean;
  readonly isLoading: boolean;
  shareReport(): Promise<void>;
  downloadReport(): Promise<void>;
}

export interface TransactionReportHelper {
  readonly token: string | undefined;
  readonly isLoading: boolean;
  readonly error: string | undefined;
  generateMultipleTransactionReports(
    options: MultipleTransactionReportsOptions,
  ): Promise<void>;
  generateSingleTransactionReport(
    options: SingleTransactionReportOptions,
  ): Promise<void>;
}

export type SingleTransactionReportOptions = {
  transactionId: string;
  fileType?: ReportFileType;
};

export type MultipleTransactionReportsOptions = {
  file_type?: ReportFileType;
  order_by?: string[];
  wallets_ids?: string[];
  limit?: number;
  offset?: number;
  date_from?: string;
  date_to?: string;
  last_ts?: string;
  direction?: TransactionDirection;
  types?: TransactionType[];
};
