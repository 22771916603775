import {action, computed, makeObservable, observable} from 'mobx';
import {nanoid} from 'nanoid';

import type {FlashMessage, Message, RequestedMessage} from './FlashMessage';

export default class FlashMessageImpl implements FlashMessage {
  private readonly _messages = observable.map<string, Message>([], {
    deep: false,
    name: 'FlashMessageImpl#_messages',
  });

  private static _DEFAULT_TIMEOUT = 3000;

  constructor() {
    makeObservable(this);
  }

  @computed
  get messages() {
    return [...this._messages.values()].reverse();
  }

  getMessages = () => this.messages;

  showMessage = action((message: RequestedMessage) => {
    const id = message.id ?? nanoid();
    const newMessage = {
      timeout: FlashMessageImpl._DEFAULT_TIMEOUT,
      ...message,
      id,
    };
    this._messages.set(id, newMessage);
  });

  removeMessage = action((id: string) => {
    this._messages.delete(id);
  });
}
