import type {CurrencyDescription} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import type {WalletType} from '../HistoryScreen/WalletIcon';
import HistoryWalletList from './HistoryWalletList';

export type HistoryWalletScreenProps = BaseSafeAreaProps & {
  data: HistoryWalletEntry;
  onPress: () => void;
};

export type WalletProps = {
  id: number;
  walletType: WalletType;
  walletFiat: CurrencyDescription;
  walletFiatValue: number;
  walletCurrencyValue: number;
  walletCurrency: CurrencyDescription;
};

export type HistoryWalletEntry = {
  all: WalletProps;
  private: WalletProps;
  trading: WalletProps;
};

export default observer(function HistoryWalletScreen(
  props: HistoryWalletScreenProps,
) {
  return (
    <Root>
      <HistoryWalletList {...props} />
    </Root>
  );
});

const Root = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));
