import type {
  Millisecond,
  TransactionFilterKind,
  WalletId,
} from '@ncwallet-app/core';
import {parseMillisecond} from '@ncwallet-app/core';
import type {SmallBottomTabParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallBottomTab/SmallBottomTabParamList';
import type {SmallHomeStackParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallHomeStackParamList';
import type {PartialState} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ListHistoryFiltersContainer from '../../../CommonNavigationContainers/containers/ListHistoryFiltersContainer';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ListHistoryFiltersBindingProps =
  SmallHomeStackBindingProps<'ListHistoryFilters'>;

export default observer(function ListHistoryFiltersBinding(
  props: ListHistoryFiltersBindingProps,
) {
  const {navigation, route} = props;
  const from = parseMillisecond(route.params?.from);
  const to = parseMillisecond(route.params?.to);

  const goToWalletList = useCallback(() => {
    navigation.navigate('PromptWalletGroupToFilterHistory');
  }, [navigation]);

  const goToHistoryFilterKind = useCallback(() => {
    navigation.navigate('PromptTransactionKindToFilterHistory');
  }, [navigation]);

  const goToCurrencyList = useCallback(() => {
    navigation.navigate('PromptWalletToFilterHistory');
  }, [navigation]);

  const goToDateSelection = useCallback(() => {
    navigation.navigate('PromptPeriodToFilterHistory', {from, to});
  }, [from, navigation, to]);

  const navigateBackToHistory = useCallback(
    (
      _walletId?: WalletId,
      _from?: Millisecond,
      _to?: Millisecond,
      _transactionFilterKind?: TransactionFilterKind,
    ) => {
      const bottomTabState: PartialState<
        NavigationState<SmallBottomTabParamList>
      > = {
        index: 0,
        routes: [
          {
            name: 'ListHistory',
            params: {
              walletId: _walletId || undefined,
              from: _from,
              to: _to,
              transactionFilterKind: _transactionFilterKind,
            },
          },
        ],
      };
      const homeStackState: PartialState<
        NavigationState<SmallHomeStackParamList>
      > = {
        index: 0,
        routes: [
          {
            name: 'Root',
            state: bottomTabState,
          },
        ],
      };
      navigation.dispatch(CommonActions.reset(homeStackState));
    },
    [navigation],
  );

  const promptTransactionReport = useCallback(
    (id: string) => {
      navigation.navigate('ShowTransactionReport', {id});
    },
    [navigation],
  );

  return (
    <ListHistoryFiltersContainer
      params={route.params}
      goToWalletList={goToWalletList}
      goToHistoryFilterKind={goToHistoryFilterKind}
      goToCurrencyList={goToCurrencyList}
      goToDateSelection={goToDateSelection}
      navigateBackToHistory={navigateBackToHistory}
      promptTransactionReport={promptTransactionReport}
    />
  );
});
