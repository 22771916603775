/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {
  CurrencyStore,
  JsonRpcClient,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  OnTimeoutError,
  WalletLimit,
  WalletLimitId,
  WalletStore,
} from '@ncwallet-app/core';
import {
  TIMEOUT_ERROR,
  walletsByCurrencyPopularitySorter,
} from '@ncwallet-app/core';
import type {SentryLog} from '@ncwallet-app/core/src/SentryLog';
import createJsonRpcTimeoutErrorMessage from '@ncwallet-app/core/src/SentryLog/createJsonRpcTimeoutErrorMessage';
import type {TwoFaHelper} from '@ncwallet-app/core/src/TwoFaHelper';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import type {WalletItemData} from '../../../shared/WalletItem';
import type {WalletLimitsItemData} from '../../../shared/WalletLimitsItemData';
import {toWalletLimitsItemDataList} from '../../../shared/WalletLimitsItemData';

// eslint-disable-next-line import-x/prefer-default-export
export class ListLimitsBindingState implements OnTimeoutError {
  @observable private _search: string | undefined;
  @observable private _limitsSearch: string | undefined;
  @observable private _walletsSearch: string | undefined;
  @observable private _keepBoth: boolean | undefined;
  @observable private _limits: WalletLimit[] | undefined;
  @observable private _isPending = false;
  @observable private _searchThroughLimits = false;
  @observable private _searchThroughWallets = false;
  @observable private _isTimeoutError = false;

  @computed get isTimeoutError() {
    return this._isTimeoutError;
  }

  @computed
  private get _limitItems(): WalletLimitsItemData[] | undefined {
    const currencies = this._root.currencyStore.getCryptoCurrencies();
    const wallets = this._root.walletStore.getWallets();
    if (currencies && this._limits && wallets) {
      const limits = toWalletLimitsItemDataList(
        this._limits,
        wallets,
        currencies,
      );

      if (this._searchThroughLimits || this._keepBoth) {
        return limits.filter(limit => {
          if (!this._limitsSearch) {
            return limit;
          }
          return limit.currency.name
            .toLocaleLowerCase()
            .includes(this._limitsSearch.toLocaleLowerCase());
        });
      }

      return limits;
    }
  }

  @computed
  private get _walletItems(): WalletItemData[] | undefined {
    const currencies = this._root.currencyStore.getCryptoCurrencies();
    const wallets = this._root.walletStore.getWallets();
    if (!currencies || !this._limits || !wallets) {
      return;
    }

    const currenciesOrder = new Map(currencies.map((c, i) => [c.code, i]));
    const cryptoWithLimit = new Set(this._limits.map(c => c.currency));
    const processedWallets = wallets
      .slice()
      .filter(c => !cryptoWithLimit.has(c.currency))
      .sort((w1, w2) =>
        walletsByCurrencyPopularitySorter(w1, w2, currenciesOrder),
      )
      .map(w => ({
        walletId: w.id,
        currency: this._root.currencyStore.getCryptoCurrency(w.currency)!,
      }));

    if (this._searchThroughWallets || this._keepBoth) {
      return processedWallets.filter(wallet => {
        if (!this._walletsSearch) {
          return wallet;
        }

        return wallet.currency.name
          .toLocaleLowerCase()
          .includes(this._walletsSearch.toLocaleLowerCase());
      });
    }
    return processedWallets;
  }

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
      readonly twoFaHelper: TwoFaHelper;
      readonly sentryLog: SentryLog;
    },
  ) {
    makeObservable(this);
  }

  getWalletItems = () => this._walletItems;
  getLimitItems = () => this._limitItems;

  getSearch = () => this._search;
  getWalletSearch = () => this._walletsSearch;
  getLimitSearch = () => this._limitsSearch;
  getLimit = (limitId: WalletLimitId) =>
    this._limits?.find(limit => limit.id === limitId);

  @action.bound
  async refresh() {
    const responses = await Promise.all([
      this._root.currencyStore.refreshCryptoCurrencies(),
      this._root.walletStore.refreshWallets(),
    ]);
    void this._refreshLimits();

    runInAction(() => {
      this._isTimeoutError = responses.some(
        it => !it.success && it.left.kind === TIMEOUT_ERROR,
      );
    });
  }

  @action.bound
  setSearch(
    filter: string,
    {searchLimits = false, searchWallets = false, keepBoth = false},
  ) {
    this._searchThroughLimits = searchLimits;
    this._searchThroughWallets = searchWallets;
    this._keepBoth = keepBoth;

    if (this._searchThroughLimits) {
      this._search = this._limitsSearch = filter;
    }

    if (this._searchThroughWallets) {
      this._search = this._walletsSearch = filter;
    }
  }

  private async _refreshLimits() {
    const res = await this._root.ncWalletJsonRpcClient.call(
      'wallets.limits',
      {},
    );

    if (!res.success) {
      if (res.left.kind === TIMEOUT_ERROR) {
        this._root.sentryLog.write(
          createJsonRpcTimeoutErrorMessage('[wallets.limits]'),
        );
        this._isTimeoutError = true;
      }
    }

    if (res.success) {
      runInAction(() => {
        this._limits = res.right.limits;
      });
    }
  }
}
