import {variance} from '@ncwallet-app/core';
import {ModalContainer} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import AffiliateAdvertBody from './AffiliateAdvertBody';
import type {AffiliateAdvertScreenProps} from './AffiliateAdvertScreen';

export type AffiliateAdvertModalProps = AffiliateAdvertScreenProps;

export default observer(function AffiliateAdvertModal(
  props: AffiliateAdvertModalProps,
) {
  return (
    <Backdrop>
      <AffiliateAdvertBody {...props} />
    </Backdrop>
  );
});

const Backdrop = variance(ModalContainer)(theme => ({
  root: {
    backgroundColor: theme.chroma('#000000').alpha(0.7).hex(),
  },
}));
