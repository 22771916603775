import {sized, useStrings, useTheme, variance} from '@ncwallet-app/core';
import type {WalletTransactions} from '@ncwallet-app/core/src/WalletTransactionsRequestHelper';
import {RoundShape} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import WalletSlideTransactionBody from './WalletSlideTransactionBody';
import WalletSlideTransactionStatus from './WalletSlideTransactionStatus';

export type WalletSlideTransactionProps = {
  transaction: WalletTransactions.AggregationItem | undefined;
  onPress: () => void;
};

export default observer(function WalletSlideTransaction(
  props: WalletSlideTransactionProps,
) {
  const {transaction, onPress} = props;
  const strings = useStrings();
  const theme = useTheme();

  return (
    <TransactionView>
      <TransactionHeaderRow>
        {transaction && (
          <WalletSlideTransactionStatus status={transaction.status} />
        )}
        <TransactionHeaderContainer>
          <RoundIcon color={theme.palette.uiAdditional2} />
          <TransactionHeaderView>
            <TransactionHeaderText>
              {strings['walletsScreen.lastTransaction']}:
            </TransactionHeaderText>
          </TransactionHeaderView>
          <RoundIcon
            color={theme.palette.uiAdditional2}
            style={{transform: [{scaleX: -1}]}} // mirror the icon above
          />
        </TransactionHeaderContainer>
      </TransactionHeaderRow>

      <TransactionBodyView>
        {transaction ? (
          <WalletSlideTransactionBody
            transaction={transaction}
            onPress={onPress}
          />
        ) : (
          <TransactionEmptyHistoryText>
            {strings['walletsScreen.noTransactions']}
          </TransactionEmptyHistoryText>
        )}
      </TransactionBodyView>
    </TransactionView>
  );
});

const RoundIcon = sized(RoundShape, 9, 9);

const TransactionHeaderRow = variance(View)(() => ({
  root: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
}));

const TransactionHeaderContainer = variance(View)(() => ({
  root: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginLeft: 10,
    zIndex: 15,
  },
}));

const TransactionHeaderView = variance(View)(theme => ({
  root: {
    alignSelf: 'flex-start',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    backgroundColor: theme.palette.uiAdditional2,
  },
}));

const TransactionHeaderText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 12,
  },
}));

const TransactionView = variance(View)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const TransactionBodyView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 44,
    paddingHorizontal: 20,
    backgroundColor: theme.palette.uiAdditional2,
  },
}));

const TransactionEmptyHistoryText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 12,
    lineHeight: 15,
    color: theme.palette.textAdditional3,
  },
}));
