import dayjs from 'dayjs';

import type {ISODateString} from '../../Time';

export enum RateHistoryPeriod {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
  All = 'All',
}

export const rateHistoryPeriodToInterval = (
  period: RateHistoryPeriod,
  end: Date,
): {
  time_start: ISODateString;
  time_end: ISODateString;
  interval: 'daily' | 'hourly';
} => {
  if (period === RateHistoryPeriod.Day) {
    return {
      time_start: dayjs(end).subtract(1, 'day').toISOString() as ISODateString,
      time_end: end.toISOString() as ISODateString,
      interval: 'hourly',
    };
  } else if (period === RateHistoryPeriod.Week) {
    return {
      time_start: dayjs(end).subtract(7, 'day').toISOString() as ISODateString,
      time_end: end.toISOString() as ISODateString,
      interval: 'hourly',
    };
  } else if (period === RateHistoryPeriod.Month) {
    return {
      time_start: dayjs(end)
        .subtract(1, 'month')
        .toISOString() as ISODateString,
      time_end: end.toISOString() as ISODateString,
      interval: 'daily',
    };
  } else if (period === RateHistoryPeriod.Year) {
    return {
      time_start: dayjs(end).subtract(1, 'year').toISOString() as ISODateString,
      time_end: end.toISOString() as ISODateString,
      interval: 'daily',
    };
  }

  const enoughForAllUsersInterval = 10;
  return {
    time_start: dayjs(end)
      .subtract(enoughForAllUsersInterval, 'year')
      .toISOString() as ISODateString,
    time_end: end.toISOString() as ISODateString,
    interval: 'daily',
  };
};
