/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import type {
  CurrencyDescription,
  DecimalString,
  WalletLimitPeriod,
} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import EditLimitForm from './EditLimitForm';

export type PromptLimitUpdateCardProps = {
  onSubmit: (limit: DecimalString, period: WalletLimitPeriod) => void;
  crypto: CurrencyDescription | undefined;
  initialValue: string | undefined;
  initialPeriod: WalletLimitPeriod | undefined;
  error: LocaleKeys | undefined;
  resetError: () => void;
};

export default observer(function PromptLimitUpdateCard(
  props: PromptLimitUpdateCardProps,
) {
  const {resetError} = props;
  const handleSubmit = useCallback(
    (limit: DecimalString) => {
      props.onSubmit(limit, props.initialPeriod!);
    },
    [props],
  );
  return (
    <EditLimitForm
      initialValue={props.initialValue!}
      currency={props.crypto!}
      onSubmit={handleSubmit}
      error={props.error}
      resetError={resetError}
    />
  );
});
