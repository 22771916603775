import {StyleSheet} from 'react-native';

export const ACTIVE_OPACITY = 0.3;

export default StyleSheet.create({
  body: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.3,
  },
  active: {
    opacity: ACTIVE_OPACITY,
  },
  iconContainer: {
    marginLeft: 'auto',
  },
});
