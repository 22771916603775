/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {
  CryptoCurrencyCode,
  CurrenciesRateHistoryStore,
  CurrenciesRateStore,
  FiatCurrencyCode,
  Service,
} from '@ncwallet-app/core';
import {RateHistoryPeriod} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import {getAccountFromState} from '@ncwallet-app/core/src/AccountStore';
import {action, computed, makeObservable, observable, when} from 'mobx';

// eslint-disable-next-line import-x/prefer-default-export
export class CurrencyHistoryRefresher implements Service {
  @computed private get _baseFiat(): FiatCurrencyCode | undefined {
    return getAccountFromState(this._root.accountStore.state)?.base_fiat;
  }

  @observable private _loadHistoryActivated: boolean = false;
  @observable private _staleCodes = new Set<CryptoCurrencyCode>();

  private _doNotRefreshRates: boolean = false;

  constructor(
    private readonly _root: {
      readonly accountStore: AccountStore;
      readonly currenciesRateStore: CurrenciesRateStore;
      readonly currenciesRateHistoryStore: CurrenciesRateHistoryStore;
    },
  ) {
    makeObservable(this);
  }

  @action.bound
  setStaleCodes(codes: CryptoCurrencyCode[]) {
    this._staleCodes = new Set(codes);
  }

  @action
  activate() {
    this._loadHistoryActivated = true;
  }

  @action
  deactivate() {
    this._loadHistoryActivated = false;
  }

  subscribe(config?: {doNotRefreshRates: boolean}) {
    this._doNotRefreshRates = !!config?.doNotRefreshRates;
    return this.refreshHistoryOnDataChange();
  }

  private refreshHistoryOnDataChange() {
    return when(
      () => {
        return !!(
          this._loadHistoryActivated &&
          this._staleCodes.size > 0 &&
          this._baseFiat
        );
      },
      async () => {
        const codes = Array.from(this._staleCodes);
        this._staleCodes = new Set();
        codes.forEach(code => {
          void this._root.currenciesRateHistoryStore.refreshLatestHistoryRes(
            code,
            this._baseFiat!,
            RateHistoryPeriod.Month,
          );
        });

        if (!this._doNotRefreshRates) {
          void this._root.currenciesRateStore.batchRefreshRates(
            codes.map(c => ({from: c, to: this._baseFiat!})),
          );
        }
      },
    );
  }
}
