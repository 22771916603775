import type {PinCode} from '@ncwallet-app/core';
import {useRoot, useStrings} from '@ncwallet-app/core';
import {
  CHECK_BIOMETRICS_PIN_ROUTE,
  CHECK_PIN_ROUTE,
  NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE,
  PROMPT_BIOMETRIC_SETTINGS_ROUTE,
  PROMPT_NEW_PIN_ROUTE,
  PROMPT_OTP_TO_BIOMETRICS_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {ListSecuritySettingsPinRoute} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {SecuritySettingsCard} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {flow} from 'mobx';
import {useCallback, useMemo, useRef} from 'react';

import {useCheckPinContainer} from '../../../../CommonNavigationContainers/hooks/useCheckPinContainer';
import {usePromptBiometricSettingsContainer} from '../../../../CommonNavigationContainers/hooks/usePromptBiometricSettingsContainer';
import type {LargeSwitchBindingProps} from '../../../../LargeNavigationRoot/LargeHomeStack/bindings/LargeSwitch/LargeSwitchBindingProps';
import type {PinCardProps} from '../../../../screens/LgSecuritySettingsScreen/PinCard';
import {LgPinCardStatus} from '../../../../screens/LgSecuritySettingsScreen/PinCard';
import {
  useCheckPinTexts,
  useConfirmPinTexts,
  useSecurePinTextsNew,
  useSecurePinTextsOld,
} from '../../../../screens/PinScreen/PinScreenTexts';
import {useAddPinBiometricsByRpc} from '../../../../SmallNavigationRoot/SmallHomeStack/bindings/CheckBiometricsPinBinding';
import {usePinValidation} from '../../../../SmallNavigationRoot/SmallHomeStack/bindings/CheckPinBinding';
import {useOtpToBiometricsProps} from '../../../../SmallNavigationRoot/SmallHomeStack/bindings/PromptOtpToBiometricsBinding';

// eslint-disable-next-line import-x/prefer-default-export
export const useLgSecuritySettingsPinCardBindingState = (
  props: LargeSwitchBindingProps<'ListSecuritySettings'>,
): PinCardProps => {
  const {navigation, route} = props;
  const strings = useStrings();
  const root = useRoot();
  const isOtpSubmitRef = useRef(false);
  const pinTexts_ = useSecurePinTextsOld();
  const biometryPinTexts_ = useCheckPinTexts(true);
  const pinCardTexts = useCreatePinText();
  const promptNewPin = useCallback(
    (pin: PinCode) => {
      navigation.setParams({
        focusedCard: SecuritySettingsCard.Pin,
        focusedPin: {
          kind: PROMPT_NEW_PIN_ROUTE,
          params: {oldPin: pin},
        },
      });
    },
    [navigation],
  );
  const promptBiometrySettings = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.Pin,
      focusedPin: {
        kind: PROMPT_BIOMETRIC_SETTINGS_ROUTE,
      },
    });
  }, [navigation]);

  const validatePinByRpc = usePinValidation();
  const pinProps = useCheckPinContainer(
    validatePinByRpc,
    promptNewPin,
    pinTexts_,
  );

  const addPinBiometricsByRpc = useAddPinBiometricsByRpc();
  const biometryPinProps = useCheckPinContainer(
    addPinBiometricsByRpc,
    promptBiometrySettings,
    biometryPinTexts_,
  );

  const onPinSuccess = useMemo(
    () =>
      flow(function* (pinCode: PinCode) {
        if (route.params?.focusedPin?.kind !== PROMPT_NEW_PIN_ROUTE) {
          return;
        }
        navigation.setParams({
          focusedCard: SecuritySettingsCard.Pin,
          focusedPin: {
            kind: PROMPT_NEW_PIN_ROUTE,
            params: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              oldPin: route.params.focusedPin.params!.oldPin,
              load: true,
            },
          },
        });
        yield root.ncWalletJsonRpcClient.call('accounts.pin.set', {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          old_pin: route.params.focusedPin.params!.oldPin,
          pin: pinCode,
        });
        navigation.setParams({
          focusedCard: SecuritySettingsCard.Pin,
          focusedPin: {kind: NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE},
        });
        root.flashMessage.showMessage({
          title:
            // eslint-disable-next-line @typescript-eslint/no-deprecated
            root.translation.strings['lgSecureSettingsScreen.pinCardSuccess'],
          variant: 'success',
        });
      }),
    [
      navigation,
      root.flashMessage,
      root.ncWalletJsonRpcClient,
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      root.translation.strings,
      route.params,
    ],
  );

  const checkPin = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.Pin,
      focusedPin: {
        kind: CHECK_PIN_ROUTE,
      },
    });
  }, [navigation]);

  const checkBiometricsPin = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.Pin,
      focusedPin: {kind: CHECK_BIOMETRICS_PIN_ROUTE},
    });
  }, [navigation]);
  const promptOtpToBiometrics = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.Pin,
      focusedPin: {kind: PROMPT_OTP_TO_BIOMETRICS_ROUTE},
    });
  }, [navigation]);

  const {resetErrorText, getErrorText, handleSubmit} = useOtpToBiometricsProps(
    isOtpSubmitRef.current,
    (v: boolean) => {
      isOtpSubmitRef.current = v;
    },
    navigation,
  );

  const {
    hasSignInBiometrics,
    hasTwoFaBiometrics,
    toggleTwoFaBiometrics,
    toggleSignInBiometrics,
  } = usePromptBiometricSettingsContainer({
    checkBiometricsPin,
    promptOtpToBiometrics,
  });

  return {
    pinCardStatus: route.params?.focusedPin
      ? getPinCardStatus(route.params.focusedPin)
      : LgPinCardStatus.Start,
    setPin: pinProps.setPinCode,
    onPinSuccess,
    pinCardTexts,
    biometryPinTexts: biometryPinProps.texts,
    pinTexts: pinProps.texts,
    getPin: pinProps.getPinCode,
    getPinErrorShown: pinProps.getErrorShown,
    onErrorActionPress: pinProps.onErrorActionPress,
    setBiometryPin: biometryPinProps.setPinCode,
    getBiometryPin: biometryPinProps.getPinCode,
    getBiometryPinErrorShown: biometryPinProps.getErrorShown,
    checkPin,
    onBiometricPress: toggleSignInBiometrics,
    getIsActive: hasSignInBiometrics,
    getIsTransactionActive: hasTwoFaBiometrics,
    onTransactionPress: toggleTwoFaBiometrics,
    twoFaProps: {
      resetError: resetErrorText,
      submitText: strings['promptOtpToBiometrics.submit'],
      onSubmit: handleSubmit,
      titleText: strings['promptOtpToBiometrics.title'],
      getError: getErrorText,
      currentTwoFaProvider: undefined,
    },
  };
};

function getPinCardStatus(
  route: ListSecuritySettingsPinRoute,
): LgPinCardStatus {
  switch (route.kind) {
    case PROMPT_BIOMETRIC_SETTINGS_ROUTE:
      return LgPinCardStatus.Start;
    case CHECK_PIN_ROUTE:
      return LgPinCardStatus.Check;
    case CHECK_BIOMETRICS_PIN_ROUTE:
      return LgPinCardStatus.CheckBiometrics;
    case PROMPT_OTP_TO_BIOMETRICS_ROUTE:
      return LgPinCardStatus.OtpBiometrics;
    case PROMPT_NEW_PIN_ROUTE:
      return route.params?.load ? LgPinCardStatus.Load : LgPinCardStatus.Update;
    case NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE:
      return LgPinCardStatus.Ok;
  }
}

const useCreatePinText = () => {
  const enterNewPinTexts = useSecurePinTextsNew();
  const confirmTexts = useConfirmPinTexts();
  return useMemo(
    () => ({
      create: enterNewPinTexts,
      confirm: {...confirmTexts, backText: ''},
    }),
    [confirmTexts, enterNewPinTexts],
  );
};
