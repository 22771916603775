import type {SmallHomeStackParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallHomeStackParamList';
import type {NavigationState} from '@react-navigation/routers';
import {last} from 'lodash';

// eslint-disable-next-line import-x/prefer-default-export
export const checkPrevRoute = (
  state: NavigationState,
  routeName: keyof SmallHomeStackParamList,
) => {
  const {routes, history} = state;
  return (
    last(routes)?.name === routeName ||
    last(history as typeof routes)?.name === routeName
  );
};
