import {createStylesHook, useStrings, useTheme} from '@ncwallet-app/core';
import {
  Button,
  ButtonVariant,
  SafeAreaInset,
  SafeAreaLayout,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {ActivityIndicator, View} from 'react-native';

export type PendingRedirectionScreenProps = {
  onCancel: () => void;
};

export default observer(function PendingRedirectionScreen(
  props: PendingRedirectionScreenProps,
) {
  const {onCancel} = props;
  const theme = useTheme();
  const styles = useStyles();
  const strings = useStrings();
  return (
    <SafeAreaLayout insets={SafeAreaInset.BOTTOM} style={styles.root}>
      <View style={styles.indicatorContainer}>
        <ActivityIndicator color={theme.palette.primary} size="large" />
      </View>
      <Button
        style={styles.button}
        variant={ButtonVariant.Danger}
        onPress={onCancel}
        title={strings['redirection.giveUp']}
      />
    </SafeAreaLayout>
  );
});

const useStyles = createStylesHook(theme => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background,
  },
  indicatorContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginBottom: 16,
    maxWidth: 345,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
