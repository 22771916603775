import type {ISODateString} from '@ncwallet-app/core';
import {useStrings} from '@ncwallet-app/core';
import dayjs from 'dayjs';
import {useMemo} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useDiffFormattedTime = (endTime: ISODateString | undefined) => {
  const strings = useStrings();
  return useMemo(() => {
    const limitRemainTime = dayjs(endTime);
    const utcOffset = dayjs().utcOffset();

    const diff = limitRemainTime.diff(
      dayjs().subtract(utcOffset, 'minute'),
      'minute',
    );

    const hours = diff / 60 < 1 ? 1 : Math.trunc(diff / 60);
    const lessThenChar = hours > 1 ? '' : '<';

    return `${lessThenChar}${hours}${strings['limitsScreen.hours']}`;
  }, [endTime, strings]);
};
