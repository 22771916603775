import type {
  CryptoCurrency,
  CurrencyDescription,
  DecimalString,
} from '@ncwallet-app/core';
import {keepMeaningfulDigitsInFiat} from '@ncwallet-app/core';
import {CurrencyCircleLogo} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import type {FC} from 'react';
import React from 'react';

import type {SettingsItemProps} from '../SharedComponents/SettingsItem';
import {
  CurrencyValue,
  ItemCol,
  ItemLabel,
  ItemTouchable,
  TitleWithLabel,
} from '../SharedComponents/SettingsItem';

type CryptoSectionItemProps = SettingsItemProps & CryptoSectionItemData;

export type CryptoSectionItemData = {
  crypto: CryptoCurrency;
  baseFiat: CurrencyDescription;
  value: DecimalString;
};

export const CryptoSectionItem: FC<CryptoSectionItemProps> = observer(props => {
  const title = props.getTitle?.() ?? props.title;
  const error = props.getError?.() ?? props.error;

  return (
    <ItemTouchable onPress={props.onPress} second>
      <CurrencyCircleLogo code={props.crypto.code} size={40} />
      <ItemCol icon>
        <TitleWithLabel error={error} noMargin>
          {title}
        </TitleWithLabel>
        <CurrencyValue>
          {`${keepMeaningfulDigitsInFiat(props.value)} ${props.baseFiat.code}`}
        </CurrencyValue>
        {!!props.label && <ItemLabel withBottomMargin>{props.label}</ItemLabel>}
      </ItemCol>
    </ItemTouchable>
  );
});
