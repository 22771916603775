import type {InOutCurrency} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {
  PromptAddressFormatRoute,
  PromptInputNetworkRoute,
  RouteParams,
  ShowQrToReceiveCryptoRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransitionMap} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransitionMap';
import {getLastCreatedAddressInfo} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {getDefaultAddressParams} from '@ncwallet-app/core/src/NCWalletServer/InOutCurrency';
import {useCallback, useState} from 'react';

import type {NetworkSelectionScreenProps} from '../../screens/NetworkSelectionScreen/NetworkSelectionScreen';

export type InputNetworkSelectionContainerProps = {
  params: RouteParams<PromptInputNetworkRoute>;
} & RouteTransitionMap<ShowQrToReceiveCryptoRoute | PromptAddressFormatRoute>;

export const useInputNetworkSelectionScreenProps = (
  props: InputNetworkSelectionContainerProps,
): NetworkSelectionScreenProps => {
  const root = useRoot();
  const {params, showQrToReceiveCrypto, promptAddressFormat} = props;
  const {walletId, addressNetwork, addressCurrency} = params;
  const [isCreating, setIsCreating] = useState(false);

  const crypto = root.currencyStore.getCryptoCurrency(addressCurrency);

  const onSelect = useCallback(
    async (currencyIn: InOutCurrency) => {
      if (isCreating) {
        return;
      }
      if (currencyIn.address_types.length > 1) {
        promptAddressFormat({
          walletId,
          ...getDefaultAddressParams(currencyIn),
        });
        return;
      }

      setIsCreating(true);
      const res = await root.walletStore.createAddress(
        walletId,
        currencyIn.network,
        currencyIn.default_type,
      );
      setIsCreating(false);
      if (res.success) {
        const newAddress = getLastCreatedAddressInfo(
          res.right.addresses,
          currencyIn.network,
        );
        if (!newAddress) {
          return;
        }
        showQrToReceiveCrypto({
          walletId,
          address: newAddress.address,
          addressNetwork: newAddress.network,
          addressCurrency: params.addressCurrency,
        });
      }
    },
    [
      isCreating,
      params.addressCurrency,
      promptAddressFormat,
      root.walletStore,
      showQrToReceiveCrypto,
      walletId,
    ],
  );

  return {
    networks: crypto?.options.currencies_in,
    currency: addressCurrency,
    network: addressNetwork,
    onSelect,
  };
};
