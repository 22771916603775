import type {ISODateString, PlatformType} from '@ncwallet-app/core';
import type {Session} from '@ncwallet-app/core/src/NCWalletServer/sessions/Session';
import {isEmpty} from 'lodash';

import {getSessionTitle} from '../ActiveSessionsScreen/SessionInfo';

export type HistorySessionInfo = {
  id: string;
  platform?: PlatformType;
  title: string;
  info: string;
  time: ISODateString;
  status: 'active' | 'blocked';
};

export const fromSession = (s: Session): HistorySessionInfo => ({
  id: s.id,
  title: getSessionTitle(s),
  platform: s.device_info.platform,
  info: safeConcat([s.ip, s.city], ' - '),
  time: s.created_at,
  status: s.status,
});

const safeConcat = (
  strings: (string | undefined)[],
  separator = ', ',
): string => {
  return strings.filter(s => !isEmpty(s)).join(separator);
};
