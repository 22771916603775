import {
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';

import {unwrap} from '../EitherAdapter';
import type {ConnectionState, JsonRpcClient} from '../JsonRpc';
import {ConnectionStatus} from '../JsonRpc';
import type {ReadonlyNavigationContainerState} from '../NavigationContainer';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {AddressNetwork} from '../NCWalletServer/AddressInfo';
import type {BlockchainNetwork} from '../NCWalletServer/BlockchainNetworkList';
import type {Service} from '../structure';
import type {
  BlockchainNetworksRepositoryState,
  ByWalletNetwork,
} from './BlockchainNetworksRepositoryState';

export default class BlockchainNetworksRepositoryStateService
  implements BlockchainNetworksRepositoryState, Service
{
  @observable.ref private _blockchainNetworks?: BlockchainNetwork[];

  constructor(
    private readonly _root: {
      readonly connectionState: ConnectionState;
      readonly navigationContainerState: ReadonlyNavigationContainerState;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {
    makeObservable(this);
  }

  @computed get blockchainNetworkByWalletNetwork() {
    if (!this._blockchainNetworks) {
      return undefined;
    }

    const byWalletNetwork: ByWalletNetwork<true> = new Map();
    for (const network of this._blockchainNetworks) {
      byWalletNetwork.set(network.wallet_network as AddressNetwork, network);
    }
    return byWalletNetwork;
  }

  subscribe() {
    return reaction(
      () => this._root.connectionState.latestStatus === ConnectionStatus.Open,
      async isConnected => {
        if (isConnected) {
          await this._load();
        }
      },
    );
  }

  private async _load() {
    const result = await unwrap(
      this._root.ncWalletJsonRpcClient.call('blockchain_network.list', {}),
    );
    runInAction(() => {
      this._blockchainNetworks = result;
    });
  }
}
