import {PlatformType} from '@ncwallet-app/core';
import type {SessionContext} from '@ncwallet-app/core/src/SessionContext/SessionContext';
import type {SessionContextProvider} from '@ncwallet-app/core/src/SessionContext/SessionContextProvider';

// eslint-disable-next-line import-x/prefer-default-export
export class WebSessionContextProviderImpl implements SessionContextProvider {
  getContext(): SessionContext {
    return {
      platform: PlatformType.WEB,
    };
  }
}
