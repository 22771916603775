import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, runInAction} from 'mobx';
import {useEffect} from 'react';

import {useNavigationGetIsFocused} from '../../../../Navigation/hooks';

// eslint-disable-next-line import-x/prefer-default-export
export const useShowTwoFaStatusBindingState = () => {
  const root = useRoot();
  const {twoFaSettingsState} = root;
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void twoFaSettingsState.refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, twoFaSettingsState],
  );

  return twoFaSettingsState;
};
