import type {OtpCode} from '@ncwallet-app/core';
import {useRoot, useStrings} from '@ncwallet-app/core';
import type {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import {useBaseErrorDescription} from '../../CommonNavigationContainers/hooks';
import {
  FORCED,
  SubmitOtpScreen,
} from '../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import {useNavigationGetIsFocused} from '../hooks';
import {useSendTelegramOtp} from '../hooks/useSendTelegramOtp';

export default observer(() => {
  const [getError, setError, resetError] = useBaseErrorDescription();
  const root = useRoot();
  const onSubmit = useCallback(
    async (code: string) => {
      const authorize_ = await root.authHelper.signInByOtp(code as OtpCode);
      if (!authorize_.success) {
        setError(authorize_.left);
      }
    },
    [root, setError],
  );

  const onCancel = useCallback(
    () => root.authHelper.signOut({reason: 'AuthByOtpBinding'}),
    [root],
  );
  const appVersion = root.deviceInfo.getAppVersionWithBuildNumber();
  const strings = useStrings();
  const getIsFocused = useNavigationGetIsFocused();
  const {sendRestOtp, interval, resetInterval} = useSendTelegramOtp();
  const [optimisticTwoFaProvider, setOptimisticTwoFaProvider] = useState<
    TwoFaProviderKind | undefined
  >();

  const handleTwoFaResendCode = useCallback(async () => {
    try {
      await sendRestOtp();
    } catch {
      /* empty */
    }
  }, [sendRestOtp]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsFocused()) {
          void runInAction(async () => {
            void root.twoFaSettingsState.refresh();
            const optimisticTwoFaProvider =
              await root.twoFaSettingsState.getOptimisticTwoFaProvider();

            setOptimisticTwoFaProvider(optimisticTwoFaProvider);
          });
        }
      }),
    [getIsFocused, root.twoFaSettingsState],
  );

  return (
    <SubmitOtpScreen
      resetError={resetError}
      layout={FORCED}
      appVersion={appVersion}
      twoFaProvider={optimisticTwoFaProvider}
      onTwoFaResendCode={handleTwoFaResendCode}
      resendCodeInterval={interval}
      resetInterval={resetInterval}
      getError={getError}
      titleText={strings['twoFactorAuthScreen.title']}
      submitText={strings['twoFactorAuthScreen.confirm']}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
});
