import type {
  InitialState,
  LinkingOptions,
  NavigationContainerRef,
  ParamListBase,
} from '@react-navigation/native';
import type {DocumentTitleOptions} from '@react-navigation/native/lib/typescript/src/types';
import type {NavigationState} from '@react-navigation/routers';
import {action, computed, makeObservable, observable} from 'mobx';

import type {ShallowCommonState} from '../CommonNavigationScheme';
import {
  KNOWN_CRYPTO_PROTOCOLS,
  PREFIXES,
  PRODUCTION_WEB_PREFIX,
} from '../LinkingOptionsProvider/constant';
import type {LocationSource} from '../Location';
import type {SpecialLocation} from '../SpecialLocation';
import {EXTERNAL} from '../SpecialLocation';
import type {Url} from '../units';
import type {NavigationContainer} from './NavigationContainer';
import type {NavigationContainerBinding} from './NavigationContainerBinding';
import type {NavigationContainerState} from './NavigationContainerState';

export default abstract class BaseNavigationContainerBindingImpl
  implements NavigationContainerBinding, NavigationContainer
{
  @observable.ref private _ref?: NavigationContainerRef<ParamListBase>;
  @observable.ref private _state?: NavigationState;
  @observable private _isReady = false;

  protected constructor(
    protected readonly _root: {
      readonly navigationContainerState: NavigationContainerState;
      readonly locationSource: LocationSource;
      readonly specialLocation: SpecialLocation;
    },
  ) {
    makeObservable(this);
  }

  get isReady() {
    return this._isReady;
  }

  get state() {
    return this._state;
  }

  @computed
  get isConfigured() {
    return this._isReady && this._state !== undefined;
  }

  @computed get ref() {
    return this._ref;
  }

  abstract get initialState(): InitialState | undefined;

  @action
  protected _onStateChange(state: NavigationState | undefined) {
    this._state = state;
  }

  abstract onStateChange(state: NavigationState | undefined): void;

  onReady = action(() => {
    const state = this._getCurrentState();
    if (state) {
      this._root.navigationContainerState.initialize(state);
    }
    this._isReady = true;
  });

  onRef = action((ref: NavigationContainerRef<ParamListBase> | null) => {
    this._ref = ref ?? undefined;
  });

  get linking(): LinkingOptions<ParamListBase> {
    const that = this;
    return {
      prefixes: PREFIXES.slice(),
      filter(address: string) {
        return (
          that._root.specialLocation.parse(address as Url).kind !== EXTERNAL
        );
      },
      async getInitialURL() {
        const url = await that._root.locationSource.getInitial();
        if (!url.success) {
          return null;
        }
        if (shouldUriBeWrapped(url.right)) {
          return wrapKnownCryptoUriIntoUrl(url.right);
        }
        return url.right;
      },
      subscribe(listener: (url: string) => void) {
        return that._root.locationSource.updates.listen(async address => {
          if (shouldUriBeWrapped(address)) {
            address = wrapKnownCryptoUriIntoUrl(address) as Url;
          }
          listener(address);
        });
      },
    };
  }

  abstract get documentTitle(): DocumentTitleOptions;

  protected get _latestState() {
    return this._root.navigationContainerState.latestState;
  }

  protected abstract _getCurrentState(): ShallowCommonState | undefined;
}

function shouldUriBeWrapped(uri: string): boolean {
  return KNOWN_CRYPTO_PROTOCOLS.some(_ => {
    const nativePrefix = `${_}:`;
    const browserPrefix = `web+${_}:`;
    return uri.startsWith(nativePrefix) || uri.startsWith(browserPrefix);
  });
}

function wrapKnownCryptoUriIntoUrl(uri: string): string {
  return `${PRODUCTION_WEB_PREFIX}?uri=${encodeURIComponent(uri)}`;
}
