import React from 'react';
import {Pressable as RNPressable} from 'react-native';

import type {PressableProps} from './PressableProps';

export default function Pressable(props: PressableProps) {
  const {
    children,
    borderStyle,
    isBorderVisible = true,
    disabled,
    style,
    pressedColor,
    ...rest
  } = props;
  return (
    <RNPressable
      style={({pressed}) => [
        {
          backgroundColor: pressed ? pressedColor : undefined,
        },
        style,
        isBorderVisible && borderStyle,
      ]}
      disabled={disabled}
      {...rest}>
      {children}
    </RNPressable>
  );
}
