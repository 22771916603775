import {
  getDefaultAddressInfoForWallet,
  getDefaultInOutCurrency,
  useRoot,
  useStrings,
} from '@ncwallet-app/core';
import type {
  PromptCryptoToReceiveRoute,
  PromptNewWalletRoute,
  RouteParams,
  ShowQrToReceiveCryptoRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransitionMap} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransitionMap';
import {getDefaultAddressParams} from '@ncwallet-app/core/src/NCWalletServer/InOutCurrency';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {useCallback} from 'react';

import {filterCurrencySelectionItems} from '../../../Navigation/hooks';
import type {SelectionForReceiveScreenProps} from '../../../screens/SelectionForReceiveScreen';
import useSelectionForReceiveBindingState from './useSelectionForReceiveBindingState';

export type PromptCryptoToReceiveContainerProps = {
  params: RouteParams<PromptCryptoToReceiveRoute>;
  setParams: (params: Partial<RouteParams<PromptCryptoToReceiveRoute>>) => void;
  goBack: () => void;
} & RouteTransitionMap<PromptNewWalletRoute | ShowQrToReceiveCryptoRoute>;

export const usePromptCryptoToReceiveContainer = (
  props: PromptCryptoToReceiveContainerProps,
): SelectionForReceiveScreenProps => {
  const {
    params = {},
    setParams,
    goBack,
    promptNewWallet,
    showQrToReceiveCrypto,
  } = props;
  const search = params.search;
  const root = useRoot();
  const strings = useStrings();

  const state = useSelectionForReceiveBindingState();

  const setSearch = useCallback(
    (s: string) => {
      setParams({search: s});
    },
    [setParams],
  );

  const goToCurrencyScreen = useCallback(
    async (item: CurrencySelectionListItemData) => {
      if (state.isSubmitting) {
        return;
      }
      let wallet;
      if (item.walletId) {
        wallet = root.walletStore.getWalletById(item.walletId);
        if (wallet === undefined) {
          return;
        }
        if (!wallet.is_visible) {
          const walletRes = await state.updateWalletVisibility(item, true);
          if (!walletRes.success) {
            return;
          }

          wallet = walletRes.right;
        }
      } else {
        const walletRes = await state.create(item);
        if (!walletRes.success) {
          return;
        }

        wallet = walletRes.right;
      }

      const currency = root.currencyStore.getCryptoCurrency(wallet.currency);

      if (currency === undefined) {
        return;
      }

      const inCurrency = getDefaultInOutCurrency(currency, 'in');

      const addressInfo = getDefaultAddressInfoForWallet(
        wallet,
        currency,
        'in',
      );

      if (addressInfo === undefined) {
        return;
      }

      showQrToReceiveCrypto({
        walletId: wallet.id,
        address: addressInfo.address,
        ...getDefaultAddressParams(inCurrency),
      });
    },
    [root.currencyStore, root.walletStore, showQrToReceiveCrypto, state],
  );

  const shownCurrencies = filterCurrencySelectionItems(
    state.currencies,
    search,
  );

  const getIsRefreshing = useCallback(() => {
    return (
      state.getIsLoading() && (!state.currencies || state.getIsManualRefresh())
    );
  }, [state]);

  return {
    search: search,
    onSearchChange: setSearch,
    activeTab: state.tab,
    onTabPress: state.setTab,
    shownCurrencies: shownCurrencies,
    allCurrencies: state.allCurrencies,
    onRefresh: state.manualRefresh,
    getIsRefreshing: getIsRefreshing,
    onBack: goBack,
    onCurrencyPress: goToCurrencyScreen,
    goToAddPage: promptNewWallet,
    listTitle: strings['selectionForReceiveScreen.currencyListTitle'],
  };
};
