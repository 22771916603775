import {useStrings, variance} from '@ncwallet-app/core';
import {
  LG_BREAKPOINT,
  SearchHeader,
  useIsDimensions,
} from '@ncwallet-app/ui/src';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import LimitsSettingsEmptySearch from '../../../shared/LimitsSettingsEmptySearch';
import type {WalletLimitsItemData} from '../../../shared/WalletLimitsItemData';
import WalletLimitList from './WalletLimitList';
import WalletLimitsEmpty from './WalletLimitsEmpty';

export type WalletLimitsCardBodyProps = {
  limits: WalletLimitsItemData[] | undefined;
  searchValue: string | undefined;
  onSearchChange: (s: string) => void;
  onSubmit: () => void;
  onSelect: (limit: WalletLimitsItemData) => void;
};

export default observer(function WalletLimitsCardBody(
  props: WalletLimitsCardBodyProps,
) {
  const {limits, searchValue, onSearchChange, onSubmit, onSelect} = props;
  const strings = useStrings();
  const isLg = useIsDimensions('lg');
  const isSearchEmptyResult = searchValue && !isNil(limits) && !limits.length;

  if (!isNil(limits) && !limits.length && !searchValue) {
    return <WalletLimitsEmpty />;
  }
  return (
    <>
      <ActiveLimitSearchHeader
        value={searchValue}
        onValueChange={onSearchChange}
        onSubmit={onSubmit}
        placeholder={
          strings['profileSettingsScreen.searchCryptoCurrencyPlaceholder']
        }
      />
      <SizedBox />
      {isSearchEmptyResult ? (
        <LimitsSettingsEmptySearch searchValue={searchValue} isLg={isLg} />
      ) : (
        <WalletLimitList data={limits} onSelect={onSelect} />
      )}
    </>
  );
});

const ActiveLimitSearchHeader = variance(SearchHeader)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingBottom: 13,
    paddingTop: 0,
    backgroundColor: theme.palette.uiPrimary,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        paddingBottom: 0,
        backgroundColor: 'transparent',
        borderBottomWidth: 0,
      },
    }),
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));
