import type {
  Millisecond,
  TransactionFilterKind,
  WalletId,
} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ListHistoryFiltersContainer from '../../../../../CommonNavigationContainers/containers/ListHistoryFiltersContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type ListHistoryFiltersBindingProps =
  LargeSwitchBindingProps<'ListHistoryFilters'>;

export default observer(function ListHistoryFiltersBinding(
  props: ListHistoryFiltersBindingProps,
) {
  const {navigation, route} = props;

  const goToWalletList = useCallback(() => {
    navigation.navigate('PromptWalletGroupToFilterHistory', {...route.params});
  }, [navigation, route.params]);

  const goToHistoryFilterKind = useCallback(() => {
    navigation.navigate('PromptTransactionKindToFilterHistory', {
      ...route.params,
    });
  }, [navigation, route.params]);

  const goToCurrencyList = useCallback(() => {
    navigation.navigate('PromptWalletToFilterHistory', {...route.params});
  }, [navigation, route.params]);

  const goToDateSelection = useCallback(() => {
    navigation.navigate('PromptPeriodToFilterHistory', {...route.params});
  }, [navigation, route.params]);

  const navigateBackToHistory = useCallback(
    (
      _walletId?: WalletId,
      _from?: Millisecond,
      _to?: Millisecond,
      _transactionFilterKind?: TransactionFilterKind,
    ) => {
      navigation.navigate('ListHistory', {
        walletId: _walletId,
        from: _from,
        to: _to,
        transactionFilterKind: _transactionFilterKind,
      });
    },
    [navigation],
  );

  const promptTransactionReport = useCallback(
    (id: string) => {
      navigation.navigate('ShowTransactionReport', {id});
    },
    [navigation],
  );

  return (
    <ListHistoryFiltersContainer
      params={route.params}
      goToWalletList={goToWalletList}
      goToHistoryFilterKind={goToHistoryFilterKind}
      goToCurrencyList={goToCurrencyList}
      goToDateSelection={goToDateSelection}
      navigateBackToHistory={navigateBackToHistory}
      promptTransactionReport={promptTransactionReport}
    />
  );
});
