import {BaseRestClientImpl} from '../BaseRestClient';
import type {Configuration} from '../Configuration';
import type {ErrorRepository} from '../ErrorRepository';
import type {Http} from '../Http';
import type {Json} from '../Json';
import type {Maybe} from '../Maybe';
import type {Url} from '../units';
import type {
  ContentServiceRestClient,
  GetNewsParams,
  NewsResult,
} from './ContentServiceRestClient';

export default class ContentServiceRestClientImpl
  extends BaseRestClientImpl
  implements ContentServiceRestClient
{
  constructor(
    protected readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly configuration: Configuration;
      readonly http: Http;
      readonly json: Json;
    },
  ) {
    super(_root);
  }

  protected get _base() {
    return this._root.configuration.current.values.ncWalletRestApiUrl;
  }

  protected get _timeout() {
    return this._root.configuration.current.values.ncWalletRestApiTimeout;
  }

  async getNews(params: GetNewsParams): Promise<Maybe<NewsResult>> {
    return (await this._call(
      'GET',
      `api/v1/${params.lang}/news?placings=${params.placings?.join(',') ?? ''}&order_by=${params.order_by ?? ''}` as Url,
    )) as Maybe<NewsResult>;
  }
}
