import type {Uri, Url} from '@ncwallet-app/core';
import {sized, useTheme, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonVariant,
  LG_BREAKPOINT,
  Pressable,
} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {ImageStyle} from 'react-native';
import {Image, Text, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type AdvertScreenBodyProps = {
  image: Uri;
  title: string;
  body: string;
  actions?: AdvertAction[];
  onActionPress: (link?: string) => void;
  onClose: () => void;
};

export type AdvertAction = {
  link?: Url;
  title: string;
};

export default observer(function AdvertScreenBody(
  props: AdvertScreenBodyProps,
) {
  const theme = useTheme();
  const {image, title, body, actions, onActionPress, onClose} = props;
  const crossTop = useSafeAreaInsets().top;
  return (
    <Container>
      <CrossPress style={{top: 15 + crossTop}} onPress={onClose}>
        <CrossContainer>
          <CrossIcon color={theme.palette.whitey} />
        </CrossContainer>
      </CrossPress>
      <Img source={{uri: image}} resizeMode={'cover'} />
      <Body>
        <Title>{title}</Title>
        <Description>{body}</Description>
        {actions?.map(_ => (
          <Btn
            key={_.link}
            onPress={() => {
              onActionPress(_.link);
            }}
            variant={ButtonVariant.Primary}
            title={_.title}
          />
        ))}
      </Body>
    </Container>
  );
});

const CrossPress = variance(Pressable)(theme => ({
  root: {
    right: 15,
    width: 32,
    height: 32,
    borderRadius: 32,
    position: 'absolute',
    left: undefined,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        position: 'absolute',
        top: 15,
        right: 15,
        left: undefined,
      },
    }),
  },
}));

const CrossIcon = sized(CrossSvg, 22, 22);

const CrossContainer = variance(View)(theme => ({
  root: {
    backgroundColor: theme.chroma(theme.palette.blackout).alpha(0.2).hex(),
    width: 32,
    height: 32,
    marginLeft: 'auto',
    borderRadius: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Container = variance(View)(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    maxWidth: undefined,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 620,
        flex: undefined,
        borderRadius: 8,
        overflow: 'hidden',
        backgroundColor: theme.palette.background,
        width: '100%',
      },
    }),
  },
}));

const Img = variance(Image)(theme => ({
  root: {
    zIndex: -1,
    flexGrow: 1,
    borderTopLeftRadius: 8,
    position: 'relative',
    borderTopRightRadius: 8,
    width: '100%',
    ...(theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexGrow: undefined,
        height: 250,
      },
    }) as ImageStyle),
  },
}));

const Body = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.background,
    paddingTop: 30,
    paddingHorizontal: 15,
    paddingBottom: 50,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingVertical: 20,
        paddingHorizontal: 40,
      },
    }),
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    textAlign: 'center',
    marginBottom: 10,
    color: theme.palette.textPrimary,
    fontSize: 26,
    lineHeight: 36,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    lineHeight: 26,
    color: theme.palette.textAdditional1,
    textAlign: 'center',
    marginBottom: 40,
  },
}));

const Btn = variance(Button)(theme => {
  return {
    root: {
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          maxWidth: 345,
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      }),
    },
  };
});
