import type {CryptoCurrency, WalletId} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core';
import {
  CurrencyCircleLogo,
  PressableNativeFeedback,
} from '@ncwallet-app/ui/src';
import {PlusSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type WalletWithoutLimitsProps = {
  data: WalletItemData;
  onPress: () => void;
  testID?: string;
};

export type WalletItemData = {
  walletId: WalletId;
  currency: CryptoCurrency;
};

export default observer(function WalletItem(props: WalletWithoutLimitsProps) {
  const {data, onPress, testID} = props;

  return (
    <NoSetLimitItemContainer onPress={onPress} testID={testID}>
      <NoSetLimitItemIcon>
        <CurrencyCircleLogo code={data.currency.code} size={40} />
      </NoSetLimitItemIcon>
      <NoSetLimitItemBody>
        <NoSetLimitItemTitle>{data.currency.name}</NoSetLimitItemTitle>
      </NoSetLimitItemBody>
      <NoSetLimitRight>
        <PlusIcon />
      </NoSetLimitRight>
    </NoSetLimitItemContainer>
  );
});

const NoSetLimitItemContainer = variance(PressableNativeFeedback)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
}));

const NoSetLimitItemIcon = variance(View)(() => ({
  root: {
    marginRight: 15,
  },
}));

const NoSetLimitItemBody = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const NoSetLimitItemTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
    fontSize: 16,
  },
}));

const NoSetLimitRight = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const PlusIcon = variance(PlusSvg)(
  () => ({
    root: {
      marginLeft: 8,
    },
  }),
  theme => ({
    width: 12,
    color: theme.palette.info,
  }),
);
