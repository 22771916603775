import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {CommonActions} from '@react-navigation/routers';

// eslint-disable-next-line import-x/prefer-default-export
export const createResetToIpListAction = (type: IpType) => {
  return CommonActions.reset({
    index: 2,
    routes: [
      {name: 'Root'},
      {name: 'ListSecuritySettings'},
      {name: type === IpType.Allowed ? 'ListAllowedIp' : 'ListBlockedIp'},
    ],
  });
};
