import {useRoot} from '@ncwallet-app/core';
import type {Receipt} from '@ncwallet-app/core/src/AddressParser';
import {getCurrencyCodeByAddressKind} from '@ncwallet-app/core/src/AddressParser';
import type {
  PromptAmountToSendRoute,
  PromptOutputAddressRoute,
  RouteTransition,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {LargeHomeStackParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeHomeStackParamList';
import type {LargeSwitchParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {
  NavigationProp,
  ParamListBase,
  PartialState,
} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';
import {CommonActions} from '@react-navigation/routers';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';

import {
  useNavigationGetIsFocused,
  useNavigationGetIsTransitioning,
} from '../../../Navigation/hooks';
import {useGetParamsForOutputAfterQrScan} from '../../../Navigation/hooks/getParamsForOutputAfterQrScan';
import {useNavigateToSendByQr} from '../../../Navigation/hooks/useNavigateToSendByQr';
import {checkCryptoCurrencyAfterScanning} from '../../../Navigation/utils/checkCryptoCurrencyAfterScanning';
import {QrCodeScreen} from '../../../screens/QrCodeScreen';
import type {LargeHomeStackBindingProps} from '../LargeHomeStackBindingProps';

export type ShowQrCodeScannerBindingProps =
  LargeHomeStackBindingProps<'ScanQrCodeScanner'>;

export default observer(function ShowQrCodeScannerBinding(
  props: ShowQrCodeScannerBindingProps,
) {
  const {navigation} = props;

  const promptOutputAddress = useCallback<
    RouteTransition<PromptOutputAddressRoute | PromptAmountToSendRoute>
  >(
    params => {
      const switchState: PartialState<NavigationState<LargeSwitchParamList>> = {
        index: 0,
        routes: [{name: 'PromptOutputAddress', params}],
      };
      const homeStackState: PartialState<
        NavigationState<LargeHomeStackParamList>
      > = {index: 0, routes: [{name: 'Root', state: switchState}]};
      navigation.dispatch(CommonActions.reset(homeStackState));
    },
    [navigation],
  );
  const listQrCodeHistory = useCallback(() => {
    const switchState: PartialState<NavigationState<LargeSwitchParamList>> = {
      index: 0,
      routes: [{name: 'ListQrCodeHistory'}],
    };
    const homeStackState: PartialState<
      NavigationState<LargeHomeStackParamList>
    > = {index: 0, routes: [{name: 'Root', state: switchState}]};
    navigation.dispatch(CommonActions.reset(homeStackState));
  }, [navigation]);
  const _navigateToSendByQr = useNavigateToSendByQr(
    promptOutputAddress,
    promptOutputAddress,
    listQrCodeHistory,
  );
  const getIsTransitioning = useNavigationGetIsTransitioning(navigation);
  const getIsFocused = useNavigationGetIsFocused();
  const root = useRoot();
  const processRef = useRef(false);

  const navigateToSendByQr = useCallback(
    async (receipt: Receipt) => {
      await _navigateToSendByQr(
        receipt.address,
        receipt.kind && getCurrencyCodeByAddressKind(receipt.kind),
        receipt.amount,
      );
    },
    [_navigateToSendByQr],
  );
  const getParamsForOutput = useGetParamsForOutputAfterQrScan();
  const onCodeScanned = useCallback(
    async (code: string) => {
      if (processRef.current || !getIsFocused()) {
        return;
      }
      processRef.current = true;
      const outcome = await root.addressHistoryRepository.add(code);
      if (!outcome.success) {
        return;
      }

      const receipt = root.addressParser.parse(code);
      const prevRouter = getPrevRoute(navigation);
      void setStringAsync(receipt.address);
      if (prevRouter?.name === 'PromptOutputAddress') {
        let params = getParamsForOutput(receipt);
        if (!params.walletId) {
          await navigateToSendByQr(receipt);
          return;
        }
        if (checkCryptoCurrencyAfterScanning(prevRouter, params)) {
          params = {
            ...prevRouter.params,
            amount: params.amount,
            addressTo: params.addressTo,
          };
        } else {
          params = {
            ...prevRouter.params,
            ...params,
          };
        }
        const nextSwitchState: PartialState<
          NavigationState<LargeSwitchParamList>
        > = {
          index: 0,
          routes: [
            {
              name: 'PromptOutputAddress',
              key: prevRouter.key,
              path: prevRouter.path,
              params,
            },
          ],
        };
        const homeStackState: PartialState<
          NavigationState<LargeHomeStackParamList>
        > = {index: 0, routes: [{name: 'Root', state: nextSwitchState}]};
        navigation.dispatch(CommonActions.reset(homeStackState));
      } else {
        // noinspection ES6MissingAwait
        await navigateToSendByQr(receipt);
      }

      processRef.current = false;
    },
    [
      getIsFocused,
      getParamsForOutput,
      navigateToSendByQr,
      navigation,
      root.addressHistoryRepository,
      root.addressParser,
    ],
  );

  const handleClose = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({index: 0, routes: [{name: 'Root'}]});
  }, [navigation]);

  return (
    <QrCodeScreen
      onClose={handleClose}
      getIsFocused={getIsFocused}
      getIsTransitioning={getIsTransitioning}
      onCodeScanned={onCodeScanned}
      onHistoryPress={listQrCodeHistory}
    />
  );
});

function getPrevRoute(navigation: NavigationProp<ParamListBase>) {
  const navigationState = navigation.getState();
  const currentRoteIndex = navigationState.index;
  return navigationState.routes[currentRoteIndex - 1].state?.routes[0];
}
