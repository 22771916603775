import type {LocaleDict} from '@nc-wallet/localization/locale/LocaleStrings';
import {useRoot, useStrings, variance} from '@ncwallet-app/core';
import type {AuthRoute} from '@ncwallet-app/core/src/AuthNavigationHelper';
import {
  ASK_NEW_PIN,
  ASK_OTP,
  ASK_PIN,
  CONGRATULATE,
  PRE_CLOSE_MESSAGE,
  SHOW_ACCOUNT_ERROR,
  SHOW_AUTH_ERROR,
  SHOW_CONNECTION_ERROR,
  SHOW_CRITICAL_ERROR,
  SHOW_PENDING_AUTH,
  SHOW_PENDING_CONNECTION,
  TOKEN_EXPIRED_ERROR,
  WELCOME,
} from '@ncwallet-app/core/src/AuthNavigationHelper';
import {createSwitchNavigator} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {AuthBackground} from '@ncwallet-app/ui/src/structure/AuthBackground';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef} from 'react';
import {Keyboard, View} from 'react-native';
import Animated from 'react-native-reanimated';

import {DEFAULT_ROUTE_NAME} from '../constants';
import AuthByOtpBinding from './AuthByOtpBinding';
import type {AuthParamList} from './AuthParamList';
import CheckPinBinding from './CheckPinBinding';
import CommonErrorBinding from './CommonErrorBinding';
import {CongratulationsBinding} from './CongratulationsBinding';
import CreatePinBinding from './CreatePinBinding';
import MdCreateWalletBinding from './MdCreateWalletBinding';
import MdLoginBinding from './MdLoginBinding';
import PendingAuthBinding from './PendingAuthBinding';
import PendingConnectionBinding from './PendingConnectionBinding';
import PreCloseMessageBinding from './PreCloseMessageBinding';
import TokenExpiredErrorBinding from './TokenExpiredErrorBinding';
import {useAuthStackAnimation} from './useAuthStackAnimation';
import {WelcomeBinding} from './WelcomeBinding';

const {Navigator, Screen} = createSwitchNavigator<AuthParamList>();

// eslint-disable-next-line import-x/prefer-default-export
export const AuthStack = observer(() => {
  const {authNavigationHelper} = useRoot();
  const strings = useStrings();

  const {route} = authNavigationHelper;

  const pristine = useRef(route === undefined);

  const {bufferRoute, animatedStyle} = useAuthStackAnimation(route);

  useEffect(() => {
    if (pristine.current && route) {
      if (Keyboard.isVisible()) {
        Keyboard.dismiss();
      }
    }
    pristine.current = route === undefined;
  }, [route]);

  return bufferRoute === undefined ? null : (
    <Root>
      <AuthBackground />
      <NavigationWrapper style={animatedStyle}>
        <Navigator screenOptions={screenOptions}>
          {getCurrentScreens(strings, bufferRoute)}
        </Navigator>
      </NavigationWrapper>
    </Root>
  );
});

function getCurrentScreens(strings: Readonly<LocaleDict>, route: AuthRoute) {
  switch (route.kind) {
    case PRE_CLOSE_MESSAGE:
      return (
        <Screen
          name="PreCloseMessage"
          options={{title: getAuthErrorTitle(strings)}}
          component={PreCloseMessageBinding}
        />
      );
    case SHOW_CRITICAL_ERROR:
      return (
        <Screen
          name="CriticalError"
          options={{title: getAuthErrorTitle(strings)}}
          component={CommonErrorBinding}
          initialParams={route.params}
        />
      );
    case SHOW_PENDING_AUTH:
      return (
        <Screen
          name="PendingAuth"
          options={{title: strings['screenTitle.pendingAuth']}}
          component={PendingAuthBinding}
        />
      );
    case ASK_PIN:
      return (
        <Screen
          name="CheckPin"
          options={{title: strings['screenTitle.checkPin']}}
          component={CheckPinBinding}
        />
      );
    case TOKEN_EXPIRED_ERROR:
      return (
        <Screen
          name="TokenExpiredError"
          options={{title: getAuthErrorTitle(strings)}}
          component={TokenExpiredErrorBinding}
        />
      );
    case SHOW_AUTH_ERROR:
      return (
        <Screen
          name="AuthError"
          options={{title: getAuthErrorTitle(strings)}}
          component={CommonErrorBinding}
          initialParams={route.params}
        />
      );
    case WELCOME:
      return (
        <>
          <Screen
            name="Welcome"
            options={{title: strings['screenTitle.welcome']}}
            component={WelcomeBinding}
          />
          <Screen
            name="MdLogin"
            options={{title: strings['screenTitle.mdLogin']}}
            component={MdLoginBinding}
          />
          <Screen
            name="CreateWallet"
            options={{title: strings['screenTitle.createWallet']}}
            component={MdCreateWalletBinding}
          />
        </>
      );
    case ASK_OTP:
      return (
        <Screen
          name="AuthByOtp"
          options={{title: strings['screenTitle.authByOtp']}}
          component={AuthByOtpBinding}
        />
      );
    case CONGRATULATE:
      return (
        <Screen
          name="Congratulations"
          options={{title: strings['screenTitle.congratulations']}}
          component={CongratulationsBinding}
        />
      );
    case ASK_NEW_PIN:
      return (
        <Screen
          name="CreatePin"
          options={{title: strings['screenTitle.createPin']}}
          component={CreatePinBinding}
        />
      );
    case SHOW_PENDING_CONNECTION:
      return (
        <Screen
          name="PendingConnection"
          options={{title: strings['screenTitle.pendingConnection']}}
          component={PendingConnectionBinding}
        />
      );
    case SHOW_CONNECTION_ERROR:
      return (
        <Screen
          name="ConnectionError"
          options={{title: strings['screenTitle.connectionError']}}
          component={CommonErrorBinding}
          initialParams={route.params}
        />
      );
    case SHOW_ACCOUNT_ERROR:
      return (
        <Screen
          name="AccountStoreError"
          options={{title: strings['screenTitle.accountStoreError']}}
          component={CommonErrorBinding}
          initialParams={route.params}
        />
      );
  }
}

function getAuthErrorTitle(strings: Readonly<LocaleDict>) {
  return `${strings['screenTitle.authError']} | ${DEFAULT_ROUTE_NAME}`;
}

const Root = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const NavigationWrapper = variance(Animated.View)(() => ({
  root: {
    flex: 1,
  },
}));

const screenOptions = {headerShown: false, animationEnabled: false};
