import {action, makeObservable, observable} from 'mobx';
import {AppState as RNAppState} from 'react-native';

import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import type {AppWindow, AppWindowStatus} from './AppWindow';
import {
  APP_WINDOW_ACTIVE,
  APP_WINDOW_BACKGROUND,
  APP_WINDOW_BLUR,
  APP_WINDOW_EXTENSION,
  APP_WINDOW_FOCUS,
  APP_WINDOW_INACTIVE,
  APP_WINDOW_UNKNOWN,
} from './AppWindow';
import type {AppWindowState} from './AppWindowState';
import translateStatus from './translateStatus';

export default class AppWindowStateService implements AppWindowState, Service {
  @observable.ref private _status = translateStatus(RNAppState.currentState);
  @observable.ref private _isFocused = true;

  constructor(private readonly _root: {readonly appWindow: AppWindow}) {
    makeObservable(this);
  }

  get status() {
    AppWindowStateService._noop(this._status);
    return translateStatus(RNAppState.currentState);
  }

  get isFocused() {
    return this._isFocused;
  }

  private _onFocus = action((isFocused: boolean) => {
    this._isFocused = isFocused;
  });

  private _onStatus = action((status: AppWindowStatus) => {
    this._status = status;
  });

  subscribe() {
    const {updates} = this._root.appWindow;
    return batchDisposers(
      updates.listen(APP_WINDOW_FOCUS, this._onFocus),
      updates.listen(APP_WINDOW_BLUR, this._onFocus),
      updates.listen(APP_WINDOW_ACTIVE, this._onStatus),
      updates.listen(APP_WINDOW_BACKGROUND, this._onStatus),
      updates.listen(APP_WINDOW_INACTIVE, this._onStatus),
      updates.listen(APP_WINDOW_EXTENSION, this._onStatus),
      updates.listen(APP_WINDOW_UNKNOWN, this._onStatus),
    );
  }

  private static _noop = (...args: unknown[]) => {
    void args;
  };
}
