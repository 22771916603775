import {useRoot} from '@ncwallet-app/core';
import type {OAuth2ProviderMap} from '@ncwallet-app/core/src/OAuth2RestClient';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import LinkToEmailModal from '../../../screens/LinkToEmailScreen/LinkToEmailModal';
import type {LargeHomeStackBindingProps} from '../LargeHomeStackBindingProps';

export type PromptLinkToEmailBindingProps =
  LargeHomeStackBindingProps<'PromptLinkToEmail'>;

export default observer(function PromptLinkToEmailBinding({
  navigation,
}: PromptLinkToEmailBindingProps) {
  const {linkToEmailService} = useRoot();
  const onClose = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({index: 0, routes: [{name: 'Root'}]});
  }, [navigation]);

  const goToRoot = useCallback(() => {
    navigation.navigate('Root');
  }, [navigation]);

  const handleLinkPress = useCallback(
    async (type: keyof OAuth2ProviderMap) => {
      linkToEmailService.linkWithEmail(type, goToRoot);
    },
    [goToRoot, linkToEmailService],
  );

  return (
    <LinkToEmailModal onClose={onClose} handleLinkPress={handleLinkPress} />
  );
});
