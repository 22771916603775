import type {CryptoCurrencyCode} from '@ncwallet-app/core';
import {formatCryptoValue, variance} from '@ncwallet-app/core';
import {CurrencyCircleLogo, LG_BREAKPOINT} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type ExchangeCardProps = {
  crypto: CryptoCurrencyCode;
  value: string;
  label?: string;
};

export default observer(function ExchangeCard(props: ExchangeCardProps) {
  const {crypto, value, label} = props;
  return (
    <CardContainerView>
      <CurrencyCircleContainer>
        <CurrencyCircleLogo code={crypto} size={40} />
        <CurrencyCircleText>{crypto}</CurrencyCircleText>
      </CurrencyCircleContainer>
      <CurrencyInfo>
        {label && <Label>{label}</Label>}
        <Value>{formatCryptoValue(value)} </Value>
        <CryptoCodeText>{crypto}</CryptoCodeText>
      </CurrencyInfo>
    </CardContainerView>
  );
});

const CardContainerView = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    paddingVertical: 15,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiPrimary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingVertical: 30,
        minHeight: 'auto',
        minWidth: '100%',
        borderWidth: 0,
      },
    }),
  },
}));

const CurrencyCircleContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
}));

const CurrencyInfo = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
}));

const CurrencyCircleText = variance(Text)(theme => ({
  root: {
    display: 'none',
    marginLeft: 10,
    color: theme.palette.textPrimary,
    ...theme.fontByWeight('700'),
    fontSize: 18,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        display: 'flex',
        minHeight: 'auto',
      },
    }),
  },
}));

const Label = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 11,
    color: theme.palette.textPrimary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        display: 'none',
      },
    }),
  },
}));

const Value = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    color: theme.palette.primary,
    lineHeight: 17,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 24,
        color: theme.palette.textPrimary,
        lineHeight: 29,
      },
    }),
  },
}));

const CryptoCodeText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    color: theme.palette.textPrimary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 24,
        color: theme.palette.primary,
      },
    }),
  },
}));
