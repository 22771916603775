import type {BaseAsyncOptions} from '../Async';
import type {CancellationError, GlobalError} from '../Error';
import {CANCELLATION_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error} from '../fp';
import type {Json, ParsedJson} from '../Json';
import type {KeyValueStore} from '../KeyValueStore';
import type {
  AbstractJsonKeyValueMap,
  JsonKeyValueStore,
} from './JsonKeyValueStore';

export default class JsonKeyValueStoreImpl<
  KV extends AbstractJsonKeyValueMap = AbstractJsonKeyValueMap,
> implements JsonKeyValueStore<KV>
{
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly json: Json;
    },
    private readonly _store: KeyValueStore<KV>,
  ) {}

  async get<K extends keyof KV>(
    key: K,
    options?: BaseAsyncOptions,
  ): Promise<Either<ParsedJson<KV[K]> | undefined, GlobalError>> {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }
    const value_ = await this._store.get(key, options);
    if (!value_.success || value_.right === undefined) {
      return value_;
    }
    return this._root.json.parse(value_.right);
  }

  async set<K extends keyof KV>(
    key: K,
    value: ParsedJson<KV[K]>,
    options?: BaseAsyncOptions,
  ): Promise<Either<void, GlobalError>> {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }
    const stringify_ = this._root.json.stringify(value);
    if (!stringify_.success) {
      return stringify_;
    }
    return this._store.set(key, stringify_.right as KV[K], options);
  }

  async delete(
    key: keyof KV,
    options?: BaseAsyncOptions,
  ): Promise<Either<void, GlobalError>> {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }
    return this._store.delete(key, options);
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }
}
