import type {CryptoCurrencyCode, DecimalString} from '../../Money';
import type {Tagged} from '../../Opaque';
import type {ISODateString} from '../../Time';
import type {WalletId} from '../Wallet';

export type WalletLimit = {
  id: WalletLimitId;
  wallet_id: WalletId;
  currency: CryptoCurrencyCode;
  period: WalletLimitPeriod;
  limit: DecimalString;
  accrued: DecimalString;
  activated_at?: ISODateString;
  deactivated_at?: ISODateString;
  deleted_at?: ISODateString;
  status: WalletLimitStatus;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WALLET_LIMIT_ID = Symbol();
export type WalletLimitId = Tagged<string, typeof WALLET_LIMIT_ID>;

export enum WalletLimitPeriod {
  Daily = 'daily',
  Monthly = 'monthly',
}

export enum WalletLimitStatus {
  Active = 'active',
  WaitingForActivation = 'waiting_for_activation',
  Inactive = 'inactive',
  Deleted = 'deleted',
}
