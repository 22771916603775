import type {AddressInfo, InOutCurrency} from '@ncwallet-app/core';
import {
  sized,
  useStrings,
  useStyles,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  CommonRefreshControl,
  CurrencySelectionViewContainer,
  LG_BREAKPOINT,
  Pressable,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  ArrowLeftSvg,
  BlockchainSvg,
  PlusSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

import {AddressList} from './AddressList';

export type ReceiveCryptoAddressesScreenProps = BaseSafeAreaProps & {
  selectedAddress?: AddressInfo;
  showAddressNetwork: boolean;
  defaultNetwork?: AddressNetwork;
  availableNetworks: InOutCurrency[] | undefined;
  addresses: AddressInfo[] | undefined;
  onAddressSelect: (address: AddressInfo) => void;
  onNewPress: () => void;
  getIsLoading: () => boolean;
  onBackPress: () => void;
  onRefresh: () => void;
  goToChangeNetwork: () => void;
};

export default observer(function ReceiveCryptoAddressesScreen(
  props: ReceiveCryptoAddressesScreenProps,
) {
  const {
    addresses,
    showAddressNetwork,
    defaultNetwork,
    onAddressSelect,
    getIsLoading,
    onRefresh,
    onNewPress,
    goToChangeNetwork,
    availableNetworks,
    ...rest
  } = props;

  const refreshControl = useMemo(
    () => (
      <CommonRefreshControl
        getIsRefreshing={getIsLoading}
        onRefresh={onRefresh}
      />
    ),
    [getIsLoading, onRefresh],
  );

  const isLg = useIsDimensions('lg');
  const theme = useTheme();
  const strings = useStrings();
  const style = useLgContainerStyle();

  return (
    <CurrencySelectionViewContainer smBottom>
      {isLg && (
        <Header>
          <BackButton
            title={strings['lgReceiveCryptoAddressesScreen.backButton']}
            onPress={props.onBackPress}
            Icon={ArrowLeftSvg}
            iconCustomColor={theme.palette.uiAdditional1}
          />
          {availableNetworks && availableNetworks.length > 1 && (
            <Change onPress={goToChangeNetwork}>
              <ChangeText>
                {strings['receiveCryptoScreen.networkBottomSheet.title']}
              </ChangeText>
              <BlockIcon color={theme.palette.info} />
            </Change>
          )}
        </Header>
      )}
      <AddressList
        defaultNetwork={defaultNetwork}
        showAddressNetwork={showAddressNetwork}
        data={addresses}
        refreshControl={refreshControl}
        style={isLg && style.root}
        contentContainerStyle={isLg && style.container}
        onAddressPress={onAddressSelect}
        {...rest}
      />
      <ButtonContainer>
        <NewButton
          title={strings['receiveCryptoScreen.newButton']}
          onPress={onNewPress}
          variant={ButtonVariant.PrimaryLight}
          color={ButtonColor.Secondary}
          Icon={PlusSvg}
        />
      </ButtonContainer>
    </CurrencySelectionViewContainer>
  );
});

const BlockIcon = sized(BlockchainSvg, 22, 22);

const BackButton = variance(Button)(() => ({
  root: {
    padding: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));

const ButtonContainer = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 20,
    backgroundColor: theme.palette.background,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginTop: 20,
      },
    }),
  },
}));

const Header = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
}));

const Change = variance(Pressable)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const ChangeText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 19,
    color: theme.palette.info,
    marginRight: 10,
  },
}));

const NewButton = variance(Button)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 345,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
      },
    }),
  },
}));

const useLgContainerStyle = () =>
  useStyles(theme => ({
    root: {
      borderRadius: 8,
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.uiSecondary,
    },
    container: {
      backgroundColor: theme.palette.uiPrimary,
      borderWidth: 1,
      borderRadius: 8,
      borderColor: theme.palette.uiSecondary,
      borderBottomWidth: 0,
    },
  }));
