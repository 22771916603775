import type {ProfileUser} from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen';

import type {
  AccountAddressDataWrapper,
  AccountPersonalDataWrapper,
} from './AccountData';
import {AccountDataStatus} from './AccountData';

export enum AccountVerificationProgress {
  Verified,
  NotRequired,
  OnReview,
  Required,
}

export const getVerificationProgress = (
  profile: ProfileUser,
  personalData: AccountPersonalDataWrapper | undefined,
  addressData: AccountAddressDataWrapper | undefined,
) => {
  if (profile.isVerified) {
    return AccountVerificationProgress.Verified;
  }

  if (!profile.isVerificationRequired) {
    return AccountVerificationProgress.NotRequired;
  }

  if (
    personalData?.status === AccountDataStatus.OnReview &&
    (!addressData || addressData.status === AccountDataStatus.OnReview)
  ) {
    return AccountVerificationProgress.OnReview;
  }

  return AccountVerificationProgress.Required;
};
