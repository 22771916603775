import type {CameraCapturedPicture, CameraComponent} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {View} from 'react-native';

import {AskCameraPermissionsView} from './AskCameraPermissionsView';
import CameraFooter from './CameraFooter';
import CameraHeader from './CameraHeader';
import CameraLayer from './CameraLayer';

export type ShowDocumentCameraScreenProps = {
  isPermissionsUndetermined: boolean;
  permissionTexts: {title: string; text: string};
  canAskPermissionsAgain: boolean | undefined;
  isPermissionGranted: boolean | undefined;
  onSettingsPress: () => void;
  onTakePhoto: (event: CameraCapturedPicture) => void;
  onClose: () => void;
};
export default observer(function ShowDocumentCameraScreen(
  props: ShowDocumentCameraScreenProps,
) {
  const {
    isPermissionsUndetermined,
    permissionTexts,
    isPermissionGranted,
    canAskPermissionsAgain,
    onSettingsPress,
    onTakePhoto,
    onClose,
  } = props;
  const cameraRef = useRef<CameraComponent>(null);
  const isLoadingRef = useRef(false);
  const handleTakePhotoPress = useCallback(async () => {
    if (cameraRef.current && !isLoadingRef.current) {
      isLoadingRef.current = true;
      // noinspection JSIgnoredPromiseFromCall
      await cameraRef.current.takePictureAsync({
        onPictureSaved: async e => {
          onTakePhoto(e);
          isLoadingRef.current = false;
        },
      });
    }
  }, [onTakePhoto]);

  if (isPermissionsUndetermined) {
    return (
      <Root>
        <CameraHeader onClose={onClose} />
      </Root>
    );
  }

  return (
    <Root>
      <CameraHeader onClose={onClose} />
      {isPermissionGranted ? (
        <>
          <CameraView>
            <CameraLayer cameraRef={cameraRef} />
          </CameraView>
          <CameraFooter
            disabled={isLoadingRef.current}
            onTakePhoto={handleTakePhotoPress}
          />
        </>
      ) : (
        <AskCameraPermissionsView
          permissionTexts={permissionTexts}
          canAskPermissionsAgain={canAskPermissionsAgain}
          onSettingsPress={onSettingsPress}
        />
      )}
    </Root>
  );
});

const Root = variance(View)(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.blackout,
    flex: 1,
  },
}));

const CameraView = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));
