import type {LargeSwitchParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {PartialState} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ShowTransactionContainer from '../../../../../CommonNavigationContainers/containers/ShowTransactionContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type ShowTransactionBindingProps =
  LargeSwitchBindingProps<'ShowTransaction'>;

export default observer(function ShowTransactionBinding(
  props: ShowTransactionBindingProps,
) {
  const {route, navigation} = props;

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    const switchState: PartialState<NavigationState<LargeSwitchParamList>> = {
      index: 0,
      routes: [{name: 'ListHistory'}],
    };
    navigation.dispatch(CommonActions.reset(switchState));
  }, [navigation]);

  const toNotFound = useCallback(() => {
    navigation.navigate('FallBack');
  }, [navigation]);

  const promptTransactionReport = useCallback(
    (id: string) => {
      navigation.navigate('ShowTransactionReport', {id});
    },
    [navigation],
  );

  return (
    <ShowTransactionContainer
      historyId={route.params.id}
      toNotFound={toNotFound}
      onBack={onBack}
      promptTransactionReport={promptTransactionReport}
    />
  );
});
