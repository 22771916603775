import type {
  CancellationError,
  Disposer,
  UnknownError,
} from '@ncwallet-app/core';
import {
  batchDisposers,
  CANCELLATION_ERROR,
  error,
  REJECTED,
  UNKNOWN_ERROR,
  USER_CANCELLATION_ERROR,
  useRoot,
} from '@ncwallet-app/core';
import type {
  ListWalletsRoute,
  RedirectToSendRoute,
  RouteParams,
  RouteTransition,
  ShallowCommonState,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {
  RetryTaskControllerImpl,
  useTaskStatus,
} from '@ncwallet-app/core/src/TaskController';
import {autorun} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import FailedRedirectionScreen from '../../screens/FailedRedirectionScreen';
import PendingRedirectionScreen from '../../screens/PendingRedirectionScreen';

export type RedirectToSendContainerProps = {
  params: RouteParams<RedirectToSendRoute>;
  resetRoot: (state: ShallowCommonState) => void;
  listWallets: RouteTransition<ListWalletsRoute>;
};

export default observer(function RedirectToSendContainer(
  props: RedirectToSendContainerProps,
) {
  const {params, resetRoot, listWallets} = props;
  const root = useRoot();

  const [controller] = useState(
    () =>
      new RetryTaskControllerImpl(
        async (signal: AbortSignal) => {
          if (signal.aborted) {
            return error(
              root.errorRepository.create<CancellationError>({
                kind: CANCELLATION_ERROR,
                raw: signal.reason,
              }),
            );
          }
          const state_ = await root.addressUriHelper.prepareRedirection(
            params,
            {signal},
          );
          if (!state_.success) {
            return state_;
          }
          resetRoot(state_.right);
          return state_;
        },
        (raw: unknown) =>
          root.errorRepository.create<UnknownError>({
            kind: UNKNOWN_ERROR,
            raw,
          }),
      ),
  );
  useEffect(
    () =>
      batchDisposers(
        autorun(() => {
          if (root.appStateHelper.isReadyToMakeRequests) {
            controller.execute();
          }
        }),
        (() => {
          controller.cancel();
        }) as Disposer,
      ),
    [controller, root],
  );
  useEffect(
    () =>
      controller.state.outcome.listen(REJECTED, _ => {
        if (
          _.kind === USER_CANCELLATION_ERROR ||
          _.kind === CANCELLATION_ERROR
        ) {
          return;
        }
        const {summary, details} = root.errorParser.describe(_);
        root.flashMessage.showMessage({
          timeout: 5000,
          variant: 'danger',
          title: summary,
          text: details,
        });
      }),
    [controller, root],
  );
  const status = useTaskStatus(controller.state);
  const onTryAgain = useCallback(() => {
    controller.execute();
  }, [controller]);
  const onCancel = useCallback(() => {
    controller.cancel();
    listWallets();
  }, [controller, listWallets]);

  return status === REJECTED ? (
    <FailedRedirectionScreen
      onBackToTheApp={listWallets}
      onTryAgain={onTryAgain}
    />
  ) : (
    <PendingRedirectionScreen onCancel={onCancel} />
  );
});
