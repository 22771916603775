import type {
  TransactionDirection,
  TransactionType,
} from '../NCWalletServer/WalletsTransactions';
import {WalletTransactions} from '../WalletTransactionsRequestHelper';

export enum TransactionFilterKind {
  All = 'all',
  To = 'to', // Все приходы (Incoming + Exchange)
  From = 'from', // Все уходящие (Withdrawal + Exchange)

  Withdraw = 'withdraw',
  Incoming = 'incoming', // Incoming = Receive, обратное Withdrawal
  Exchange = 'exchange',
  Rollback = 'rollback',
  ReferralIncoming = 'referral_incoming',
  PromoIncoming = 'promo_incoming',
}

export const getDirectionFromTransactionFilter = (
  filter: TransactionFilterKind,
): TransactionDirection | undefined => {
  if (
    filter === TransactionFilterKind.To ||
    filter === TransactionFilterKind.From
  ) {
    return filter as TransactionDirection;
  }
};

export const getTypeFromTransactionFilter = (
  filter: TransactionFilterKind,
): TransactionType | undefined => {
  if (
    filter === TransactionFilterKind.Exchange ||
    filter === TransactionFilterKind.Incoming ||
    filter === TransactionFilterKind.Rollback ||
    filter === TransactionFilterKind.Withdraw
  ) {
    return filter as TransactionType;
  }
};

export const OPERATION_KIND_MAP = {
  [WalletTransactions.AggregationItemKind.Incoming]:
    TransactionFilterKind.Incoming,
  [WalletTransactions.AggregationItemKind.Exchange]:
    TransactionFilterKind.Exchange,
  [WalletTransactions.AggregationItemKind.Withdrawal]:
    TransactionFilterKind.Withdraw,
  [WalletTransactions.AggregationItemKind.Rollback]:
    TransactionFilterKind.Rollback,
  [WalletTransactions.AggregationItemKind.ReferralIncoming]:
    TransactionFilterKind.ReferralIncoming,
  [WalletTransactions.AggregationItemKind.PromoIncoming]:
    TransactionFilterKind.PromoIncoming,
} as const;
