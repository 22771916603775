import {variance} from '@ncwallet-app/core';
import {Pressable} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text} from 'react-native';

export type CalendarCellProps = {
  day: number;
  active: boolean;
  disabled: boolean;
  first: boolean;
  last: boolean;
  onPress: () => void;
  index: number;
  isDaysOutsideTheMonth: boolean;
};

export default observer(function CalendarCell({
  day,
  active,
  disabled,
  last,
  first,
  onPress,
  index,
  isDaysOutsideTheMonth,
}: CalendarCellProps) {
  return (
    <Item
      active={active}
      last={last && day <= 7}
      first={first}
      disabledStyle={disabled}
      isDaysOutsideTheMonthStyle={isDaysOutsideTheMonth}
      onPress={onPress}
      dataSet={{['app']: 'calendar-item'}}
      fistOfLastRow={isDaysOutsideTheMonth && index === 35}
      lastOfSecondDisabledRow={isDaysOutsideTheMonth && index === 34}
      lastWithSecondRow={last && day > 7}>
      <ItemText
        active={active}
        disabled={disabled}
        isDaysOutsideTheMonth={isDaysOutsideTheMonth}>
        {day}
      </ItemText>
    </Item>
  );
});

const Item = variance(Pressable)(theme => ({
  root: {
    width: 40,
    height: 40,
  },

  active: {
    borderRadius: 6,
    backgroundColor: theme.palette.primary,
  },
  disabledStyle: {
    opacity: 0.2,
  },
  isDaysOutsideTheMonthStyle: {
    backgroundColor: theme.palette.uiPrimary,
    borderRadius: 0,
  },
  last: {
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },

  first: {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  fistOfLastRow: {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  lastOfSecondDisabledRow: {
    borderTopRightRadius: 6,
  },
  lastWithSecondRow: {
    borderBottomRightRadius: 6,
  },
}));

const ItemText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 40,
    textAlign: 'center',
    color: theme.palette.textPrimary,
  },
  active: {
    ...theme.fontByWeight('600'),
    color: theme.palette.background,
  },

  isDaysOutsideTheMonth: {
    ...theme.fontByWeight('400'),
    color: theme.palette.uiSecondary,
  },
  disabled: {},
}));
