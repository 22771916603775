import {variance} from '@ncwallet-app/core';
import {CommonRefreshControl, SafeAreaFlatList} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import type {FlatListProps} from 'react-native';
import {View} from 'react-native';

import type {Notification} from './NotificationItem';
import NotificationsItem from './NotificationItem';

export type NotificationListProps = {
  getIsRefreshing: () => boolean;
  getNotificationItems: () => Notification[] | undefined;
  onRefresh: () => void;
  onEndReached: () => void;
};

const keyExtractor = (entry: Notification) => entry.id;

export const NotificationList = observer((props: NotificationListProps) => {
  const {getIsRefreshing, onRefresh, onEndReached} = props;
  const data = props.getNotificationItems();
  const refreshControl = useMemo(
    () => (
      <CommonRefreshControl
        getIsRefreshing={getIsRefreshing}
        onRefresh={onRefresh}
      />
    ),
    [getIsRefreshing, onRefresh],
  );
  return (
    <SafeAreaFlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={Separator}
      enabledWebBottomOffset
      refreshControl={refreshControl}
      onEndReached={onEndReached}
    />
  );
});

type ListProps = FlatListProps<Notification>;

const renderItem: ListProps['renderItem'] = ({item}) => (
  <NotificationsItem data={item} />
);

const Separator = variance(View)(theme => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.uiSecondary,
  },
}));
