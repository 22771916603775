import type {LargeSwitchParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {PartialState} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ShowTransactionReportContainer from '../../../../../CommonNavigationContainers/containers/ShowTransactionReportContainer';
import type {LargeHomeStackBindingProps} from '../../../LargeHomeStackBindingProps';

export type ShowTransactionReportBindingProps =
  LargeHomeStackBindingProps<'ShowTransactionReport'>;

export default observer(function ShowTransactionReportBinding(
  props: ShowTransactionReportBindingProps,
) {
  const {navigation} = props;

  const handleClose = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }

    const switchState: PartialState<NavigationState<LargeSwitchParamList>> = {
      index: 0,
      routes: [{name: 'ListHistory'}],
    };
    navigation.dispatch(CommonActions.reset(switchState));
  }, [navigation]);

  return <ShowTransactionReportContainer onClose={handleClose} />;
});
