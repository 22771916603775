import {sized, variance} from '@ncwallet-app/core';
import {WarningSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type WarningProps = {
  content: string | React.ReactNode;
};

export default observer(function Warning(props: WarningProps) {
  const {content} = props;

  return (
    <Banner>
      <BannerIcon>
        <WarningIcon />
      </BannerIcon>
      {typeof content === 'string' ? (
        <BannerText>{content}</BannerText>
      ) : (
        <BannerContent>{content}</BannerContent>
      )}
    </Banner>
  );
});

const Banner = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'stretch',
    marginBottom: 20,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.primary,
  },
}));

const BannerIcon = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.primary,
    paddingHorizontal: 10,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    justifyContent: 'center',
  },
}));

const BannerText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    flex: 1,
    padding: 10,
    backgroundColor: theme.palette.additional2,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    color: theme.palette.textSecondary,
    fontSize: 14,
    lineHeight: 24,
  },
}));

const BannerContent = variance(View)(theme => ({
  root: {
    flex: 1,
    padding: 10,
    backgroundColor: theme.palette.additional2,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
}));

const WarningIcon = sized(WarningSvg, 22, 22);
