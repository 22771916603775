import {useStrings, variance} from '@ncwallet-app/core';
import {
  BaseSkeleton,
  Button,
  ButtonIconPosition,
  ButtonVariant,
  SafeAreaInset,
  SafeAreaScrollView,
} from '@ncwallet-app/ui';
import {OpenLinkSVG} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import type {ImageSourcePropType} from 'react-native';
import {Image, Text} from 'react-native';

import {EXTRA_BOTTOM_OFFSET} from '../../constants';
import {AffiliateTabKind} from './AffiliateKind';
import type {AffiliateProgramCardProps} from './AffiliateProgramCard';
import AffiliateProgramCard from './AffiliateProgramCard';
import type {AffiliateStatisticCardProps} from './AffiliateStatisticCard';
import AffiliateStatisticCard from './AffiliateStatisticCard';
import {Tabs} from './Tabs';

export type AffiliateProgramScreenProps = {
  onMore: () => void;
  haveStatistic?: boolean;
  isLoading: boolean;
} & AffiliateStatisticCardProps &
  Omit<AffiliateProgramCardProps, 'activeTab'>;

export default observer(function AffiliateProgramScreen(
  props: AffiliateProgramScreenProps,
) {
  const {onMore, haveStatistic, isLoading, ...rest} = props;
  const [activeTab, setTab] = useState(AffiliateTabKind.Landing);
  const strings = useStrings();
  return (
    <Container
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}>
      {isLoading ? (
        <Skeleton height={167} />
      ) : !haveStatistic ? (
        <>
          <Icon
            source={
              require('../../../../../ui/src/assets/images/affiliate-transparent.png') as ImageSourcePropType
            }
          />
          <Title>{strings['affiliateScreen.title']}</Title>
        </>
      ) : (
        <AffiliateStatisticCard {...rest} />
      )}
      <Desc>{strings['affiliateScreen.description']}</Desc>
      <Tabs activeTabKey={activeTab} onTabPress={setTab} />
      <AffiliateProgramCard activeTab={activeTab} {...rest} />
      <Btn
        Icon={OpenLinkSVG}
        onPress={onMore}
        title={strings['affiliateScreen.btn.more']}
        iconPosition={ButtonIconPosition.Right}
        variant={ButtonVariant.PrimaryLight}
      />
    </Container>
  );
});

const Container = variance(SafeAreaScrollView)(() => ({
  root: {
    flex: 1,
    paddingHorizontal: 15,
    paddingVertical: 30,
  },
}));

const Skeleton = variance(BaseSkeleton)(() => ({
  root: {
    borderRadius: 10,
    marginBottom: 20,
  },
}));

const Icon = variance(Image)(() => ({
  root: {
    width: 153,
    height: 118,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 20,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 26,
    lineHeight: 36,
    marginBottom: 20,
    textAlign: 'center',
    color: theme.palette.textMain,
  },
}));

const Desc = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'center',
    color: theme.palette.textAdditional1,
  },
}));

const Btn = variance(Button)(() => ({
  root: {
    marginTop: 20,
  },
}));
