import type {Either} from '@ncwallet-app/core';

export default function useNativeGoogleAuth(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onGoogleIdTokenReceived: (outcome: Either<string, unknown>) => void,
) {
  return () => {
    console.warn('Not implemented on this platform');
  };
}
