import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import {success} from '../fp';
import type {Jwt, JwtString, JwtSummary} from './Jwt';

export default class CachingJwtImpl implements Jwt {
  private readonly _cache = new Map<JwtString, JwtSummary>();

  constructor(private readonly _jwt: Jwt) {}

  parse(token: JwtString): Either<JwtSummary, GlobalError> {
    const summary = this._cache.get(token);
    if (summary === undefined) {
      const outcome = this._jwt.parse(token);
      if (outcome.success) {
        this._displace(token, outcome.right);
      }
      return outcome;
    } else {
      this._push(token, summary);
      return success(summary);
    }
  }

  private _displace(token: JwtString, summary: JwtSummary) {
    const surplus = this._cache.size - CachingJwtImpl.CACHE_SIZE;
    if (surplus >= 0) {
      this._popFront(surplus);
    }
    this._push(token, summary);
  }

  private _popFront(count = 1) {
    const keys = this._cache.keys();
    for (let i = 0; i < count; i++) {
      const item = keys.next();
      if (item.done) {
        break;
      }
      this._cache.delete(item.value);
    }
  }

  private _push(token: JwtString, summary: JwtSummary) {
    this._cache.delete(token);
    this._cache.set(token, summary);
  }

  private static readonly CACHE_SIZE = 10;
}
