import type {ReactNode} from 'react';
import React, {forwardRef} from 'react';
import {RefreshControl as RNRefreshControl} from 'react-native';

import type {RefreshControlProps} from './RefreshControlProps';

export default forwardRef<RNRefreshControl, RefreshControlProps>(
  function RefreshControl(props, ref): ReactNode {
    return <RNRefreshControl ref={ref} {...props} />;
  },
);
