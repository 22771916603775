import type {Millisecond} from '@ncwallet-app/core';
import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  Input,
  InputType,
  LG_BREAKPOINT,
  SafeAreaScrollView,
  TouchableListItem,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  ArrowLeftWide,
  CalendarWithoutCircle,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import dayjs from 'dayjs';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';

export type HistoryPeriodFilterScreenProps = BaseSafeAreaProps & {
  initialFrom?: Millisecond;
  initialTo?: Millisecond;
  onApply: (from: Millisecond | undefined, to: Millisecond | undefined) => void;
  fromOpen: () => void;
  toOpen: () => void;
  calendarStartValue: {
    from: string;
    to: string;
  };
  setCalendarStartValue: (val: {from: string; to: string}) => void;
};

const formatDateInput = (value: string): string => {
  return value.replace(/[^\d.]/g, '');
};

const MAX_INPUT_DATE_LENGTH = 10; // YYYY.MM.DD

export default observer(function HistoryPeriodFilterScreen(
  props: HistoryPeriodFilterScreenProps,
) {
  const {
    initialFrom,
    initialTo,
    onApply,
    fromOpen,
    toOpen,
    setCalendarStartValue,
    calendarStartValue,
  } = props;
  const [getFrom, setFrom] = useDate(initialFrom);
  const [getTo, setTo] = useDate(initialTo);

  const onApplyPress = useCallback(() => {
    const today = dayjs().valueOf() as Millisecond;
    let parsedFrom = parseDate(getFrom());
    let parsedTo = parseDate(getTo());

    if (parsedFrom && parsedTo && parsedFrom > parsedTo) {
      parsedTo = parsedFrom;
    }

    if (parsedTo && parsedTo > today) {
      parsedTo = today;
    }

    if (parsedFrom && parsedFrom > today) {
      parsedFrom = today;
    }

    setTo(formatDate(parsedTo));
    setFrom(formatDate(parsedFrom));
    onApply(parsedFrom, parsedTo);
  }, [getFrom, getTo, onApply, setTo, setFrom]);

  const onResetPress = useCallback(() => {
    onApply(undefined, undefined);
  }, [onApply]);

  useEffect(() => {
    if (initialFrom) {
      setFrom(formatDate(initialFrom));
    }

    if (initialTo) {
      setTo(formatDate(initialTo));
    }
  }, [initialFrom, initialTo, setFrom, setTo]);

  const from = getFrom();

  const FromCalendarIcon = useCallback(
    () => (
      <TouchableListItem onPress={fromOpen}>
        <Col>
          <CalendarWithoutCircle />
        </Col>
      </TouchableListItem>
    ),
    [fromOpen],
  );

  const to = getTo();

  const ToCalendarIcon = useCallback(
    () => (
      <TouchableListItem onPress={toOpen}>
        <Col>
          <CalendarWithoutCircle />
        </Col>
      </TouchableListItem>
    ),
    [toOpen],
  );

  const theme = useTheme();
  const strings = useStrings();
  const isLg = useIsDimensions('lg');

  return (
    <Root
      contentContainerStyle={!isLg ? styles.container : styles.mdContainer}
      {...props}>
      <Inner>
        {isLg && (
          <MdHeader>
            <BackButton
              title={strings['historyPeriodFilterScreen.backButton']}
              Icon={ArrowLeftWide}
              iconCustomColor={theme.palette.uiAdditional1}
              onPress={onApplyPress}
            />
          </MdHeader>
        )}
        <Title>{strings['historyPeriodFilterScreen.title']}:</Title>
        <InputContainer>
          <Block first>
            <Input
              type={InputType.Default}
              label={strings['historyPeriodFilterScreen.inputFrom.label']}
              placeholder={
                strings['historyPeriodFilterScreen.inputFrom.placeholder']
              }
              maxLength={MAX_INPUT_DATE_LENGTH}
              value={from}
              onChangeText={(text: string) => {
                setCalendarStartValue({...calendarStartValue, from: text});
                setFrom(formatDateInput(text));
              }}
              keyboardType="numeric"
              autoFocus={false}
              Slot={FromCalendarIcon}
            />
            <HelpText>
              {strings['historyPeriodFilterScreen.inputFrom.helpText']}
            </HelpText>
          </Block>
          <Block>
            <Input
              type={InputType.Default}
              label={strings['historyPeriodFilterScreen.inputTo.label']}
              placeholder={
                strings['historyPeriodFilterScreen.inputTo.placeholder']
              }
              maxLength={MAX_INPUT_DATE_LENGTH}
              value={to}
              onChangeText={(text: string) => {
                setCalendarStartValue({...calendarStartValue, to: text});
                setTo(formatDateInput(text));
              }}
              keyboardType="numeric"
              autoFocus={false}
              Slot={ToCalendarIcon}
            />
            <HelpText>
              {strings['historyPeriodFilterScreen.inputTo.helpText']}
            </HelpText>
          </Block>
        </InputContainer>
        <ButtonContainer>
          <ButtonApply
            first
            title={strings['historyPeriodFilterScreen.submit']}
            color={ButtonColor.Secondary}
            variant={ButtonVariant.Highlighted}
            onPress={onApplyPress}
          />
          <ButtonApply
            title={strings['historyPeriodFilterScreen.reset']}
            color={ButtonColor.Secondary}
            variant={
              isLg ? ButtonVariant.PrimaryLight : ButtonVariant.SecondaryLight
            }
            onPress={onResetPress}
          />
        </ButtonContainer>
      </Inner>
    </Root>
  );
});

const useDate = (initial?: Millisecond) => {
  const [dateBox] = useState(() =>
    observable.box<string | undefined>(formatDate(initial)),
  );
  const getDate = useCallback(() => dateBox.get(), [dateBox]);
  const setDate = useMemo(
    () =>
      action((_: string | undefined) => {
        dateBox.set(_);
      }),
    [dateBox],
  );
  const [isOpenBox] = useState(() => observable.box(false));
  const getIsOpen = useCallback(() => isOpenBox.get(), [isOpenBox]);
  const onOpen = useMemo(
    () =>
      action(() => {
        isOpenBox.set(true);
      }),
    [isOpenBox],
  );
  const onConfirm = useMemo(
    () =>
      action((_: Date) => {
        isOpenBox.set(false);
        dateBox.set(formatDate(_.valueOf() as Millisecond));
      }),
    [dateBox, isOpenBox],
  );
  const onCancel = useMemo(
    () =>
      action(() => {
        isOpenBox.set(false);
      }),
    [isOpenBox],
  );
  return [getDate, setDate, getIsOpen, onOpen, onConfirm, onCancel] as const;
};

const DATE_FORMAT = 'DD.MM.YY';

export const formatDate = (_: Millisecond | undefined) =>
  _ === undefined ? undefined : dayjs(_).format(DATE_FORMAT);

export const parseDate = (_: string | undefined): Millisecond | undefined => {
  if (_ === undefined) {
    return undefined;
  }

  const date = dayjs(_, ['DD.MM.YYYY', DATE_FORMAT]);
  if (!date.isValid()) {
    return undefined;
  }
  return date.valueOf() as Millisecond;
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    flexGrow: 1,
    position: 'relative',
  },
  mdContainer: {
    paddingBottom: 0,
  },

  tab: {
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
  },
});

const Root = variance(SafeAreaScrollView)(theme => ({
  root: {
    flex: 1,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.uiPrimary,
        paddingBottom: 0,
        borderRadius: 10,
        ...theme.bar(10),
      },
    }),
  },
}));

const Inner = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        backgroundColor: theme.palette.background,
        padding: 30,
      },
    }),
  },
}));

const InputContainer = variance(View)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    }),
  },
}));

const MdHeader = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
}));

const BackButton = variance(Button)(() => ({
  root: {
    padding: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));

const Col = variance(View)(theme => ({
  root: {
    width: 50,
    height: 50,
    backgroundColor: theme.palette.uiPrimary,
    alignItems: 'center',
    justifyContent: 'center',
    borderLeftColor: theme.palette.uiSecondary,
    borderLeftWidth: 1,
  },
}));

const HelpText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional2,
    marginTop: 15,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 18,
    lineHeight: 22,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    padding: 15,
    paddingTop: 10,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 15,
        fontSize: 16,
        lineHeight: 19,
      },
    }),
  },
}));

const Block = variance(View)(theme => ({
  root: {
    marginHorizontal: 15,
    paddingVertical: 15,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginHorizontal: 0,
        maxWidth: 345,
        flex: 1,
      },
    }),
  },
  first: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderBottomWidth: 0,
        marginRight: 20,
      },
    }),
  },
}));

const ButtonApply = variance(Button)(theme => ({
  root: {
    marginTop: 8,
    marginHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginTop: 'auto',
        flex: 1,
        maxWidth: 345,
        marginHorizontal: 0,
      },
    }),
  },

  first: {
    marginTop: 'auto',
  },

  apply: {
    backgroundColor: theme.palette.primary,
  },
}));

const ButtonContainer = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      },
    }),
  },
}));
