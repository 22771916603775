import type {Either} from '@ncwallet-app/core/src/fp';
import type {Tagged} from '@ncwallet-app/core/src/Opaque';
import type React from 'react';
import type {SvgProps} from 'react-native-svg';

export enum BaseAuthVariant {
  Mail = 'Mail',
}

export enum OAuthVariant {
  Google = 'Google',
  Apple = 'Apple',
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Telegram = 'Telegram',
}

export type AuthVariant = BaseAuthVariant | OAuthVariant;
export type VariantItem = {
  title: string;
  Icon: React.ComponentType<SvgProps>;
  variant: AuthVariant;
};

export enum AuthKind {
  Login,
  SignIn,
}

export const ID_TOKEN = Symbol('Google ID Token');
export type IdToken = Tagged<string, typeof ID_TOKEN>;

export type TokenResponse = Either<IdToken, unknown>;
