import type {
  PromptCryptoToReceiveRoute,
  PromptCryptoToSendRoute,
  PromptNewWalletRoute,
  PromptOutputAddressRoute,
  PromptSourceCryptoToExchangeRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransitionMap} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransitionMap';
import {getDefaultAddressParams} from '@ncwallet-app/core/src/NCWalletServer/InOutCurrency';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {useCallback} from 'react';

import {filterCurrencySelectionItems} from '../../../Navigation/hooks';
import type {SelectionForSendScreenProps} from '../../../screens/SelectionForSendScreen';
import useSelectionForSendBindingState from './useSelectionForSendBindingState';

export type PromptCryptoToSendContainerProps = {
  params: RouteParams<PromptCryptoToSendRoute>;
  goBack: () => void;
} & RouteTransitionMap<
  | PromptCryptoToReceiveRoute
  | PromptSourceCryptoToExchangeRoute
  | PromptNewWalletRoute
  | PromptOutputAddressRoute
>;

export const usePromptCryptoToSendContainer = (
  props: PromptCryptoToSendContainerProps,
): Omit<SelectionForSendScreenProps, 'isLg'> => {
  const {
    params = {},
    goBack,
    promptNewWallet,
    promptCryptoToReceive,
    promptSourceCryptoToExchange,
    promptOutputAddress,
  } = props;
  const {search} = params;
  const state = useSelectionForSendBindingState();

  const goToPromptOutputAddress = useCallback(
    async (d: CurrencySelectionListItemData) => {
      if (d.walletId === undefined) {
        return;
      }

      const currencyOut = state.getDefaultOutCurrency(d.walletId);

      if (currencyOut === undefined) {
        return;
      }

      promptOutputAddress({
        walletId: d.walletId,
        addressTo: params.addressTo,
        ...getDefaultAddressParams(currencyOut),
      });
    },
    [params.addressTo, promptOutputAddress, state],
  );

  const shownCurrencies = filterCurrencySelectionItems(
    state.currencies,
    search,
  );
  const getIsRefreshing = useCallback(() => {
    return (
      state.getIsLoading() && (!state.currencies || state.getIsManualRefresh())
    );
  }, [state]);

  return {
    allCurrencies: state.currencies,
    shownCurrencies: shownCurrencies,
    onAddPress: promptNewWallet,
    onCurrencyPress: goToPromptOutputAddress,
    onExchangePress: promptSourceCryptoToExchange,
    onReceivePress: promptCryptoToReceive,
    onRefresh: state.manualRefresh,
    onBack: goBack,
    getIsRefreshing: getIsRefreshing,
  };
};
