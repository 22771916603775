import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {ArrowLeftWide} from '../../../assets/svg/colorless';
import {useIsDimensions} from '../../../util';
import {Button, SearchHeader} from '../../atoms';
import {LG_BREAKPOINT} from '../../grid';

export type CurrencySelectionHeaderProps = {
  withSecondaryBackground?: boolean;
  search?: string;
  onSearchChange?: (s: string) => void;
  onBackPress?: () => void;
  Tab?: React.ReactNode;
  hideSearch?: boolean;
  title?: string;
};

export default observer(function CurrencySelectionHeader(
  props: CurrencySelectionHeaderProps,
) {
  const strings = useStrings();
  const isLg = useIsDimensions('lg');
  const theme = useTheme();
  const Tab = props.Tab;

  return (
    <Container>
      {isLg && (
        <Header>
          <BackButton
            title={props.title || strings['historyOperationFilterScreen.title']}
            onPress={props.onBackPress}
            Icon={ArrowLeftWide}
            iconCustomColor={theme.palette.uiAdditional1}
          />
          {Tab}
        </Header>
      )}
      {!props.hideSearch && (
        <SearchSelectionHeader
          withSecondaryBackground={props.withSecondaryBackground}
          getValue={() => props.search}
          onValueChange={props.onSearchChange}
          onSubmit={noop}
          placeholder={strings['currencySelectionScreen.searchPlaceholder']}
        />
      )}
    </Container>
  );
});
const BackButton = variance(Button)(() => ({
  root: {
    marginBottom: 20,
    padding: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));

const Container = variance(View)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {},
    }),
  },
}));

const SearchSelectionHeader = variance(SearchHeader)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        padding: 0,
        backgroundColor: theme.palette.background,
        marginBottom: 20,
      },
    }),
  },
  withSecondaryBackground: {
    backgroundColor: theme.palette.uiPrimary,
  },
}));

const Header = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 60,
  },
}));
