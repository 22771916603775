import {FULFILLED, useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {StarValue} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {APP_STORE_LINK} from '@ncwallet-app/core/src/Links';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';
import {Linking, Platform} from 'react-native';

import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {FeedbackModalScreen} from '../../../screens/FeedbackModalScreen';
import FeedbackModalBindingState from './FeedbackModalBindingState';

export type PromptFeedbackContainerProps = {
  rating: StarValue;
  goHome: () => void;
};

export default observer(function PromptFeedbackContainer(
  props: PromptFeedbackContainerProps,
) {
  const {goHome, rating} = props;
  const root = useRoot();
  const strings = useStrings();
  const [state] = useState(
    () => new FeedbackModalBindingState(root.ncWalletJsonRpcClient),
  );

  useEffect(() => {
    state.setRating(rating);
  }, [rating, state]);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();
  useEffect(
    () =>
      autorun(() => {
        if (
          getIsReady() &&
          getIsFocused() &&
          root.accountStore.state?.status === FULFILLED
        ) {
          const {email} = root.accountStore.state.result;
          runInAction(() => {
            // noinspection JSIgnoredPromiseFromCall
            state.setEmail(email);
          });
        }
      }),
    [getIsFocused, getIsReady, root.accountStore, state],
  );

  const submit = useCallback(async () => {
    try {
      await state.submit();
      root.flashMessage.showMessage({
        title: strings['support.messageSent'],
        variant: 'success',
      });
      goHome();
    } catch {
      /* empty */
    }
  }, [goHome, root.flashMessage, state, strings]);

  const goToStore = useCallback(() => {
    void Linking.openURL(APP_STORE_LINK);
  }, []);

  return (
    <FeedbackModalScreen
      email={state.email}
      message={state.message}
      emailErrorShown={state.emailErrorShown}
      onEmailChange={state.setEmail}
      onMessageChange={state.setMessage}
      onClose={goHome}
      onSubmit={submit}
      isGoToAppStoreButtonVisible={Platform.OS === 'ios'}
      onGoToAppStoreButtonPress={goToStore}
    />
  );
});
