import type {
  DecimalString,
  WalletId,
  WalletLimitPeriod,
  WalletsLimitsCreate,
} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useCallback, useRef} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useCreateLimit = (
  goToPromptOtp: (params: {
    walletId: WalletId;
    limit: DecimalString;
    period: WalletLimitPeriod;
  }) => void,
  onSuccess: (res: WalletsLimitsCreate['result']) => void,
) => {
  const root = useRoot();
  const isSubmittedRef = useRef(false);
  const createLimit = useCallback(
    async (
      walletId: WalletId,
      limit: DecimalString,
      period: WalletLimitPeriod,
    ) => {
      if (isSubmittedRef.current) {
        return;
      }

      isSubmittedRef.current = true;
      const isTwoFaRequiredRes =
        await root.twoFaHelper.isTwoFaRequiredForLimits();
      if (!isTwoFaRequiredRes.success) {
        isSubmittedRef.current = false;
        return;
      }

      if (isTwoFaRequiredRes.right) {
        isSubmittedRef.current = false;
        goToPromptOtp({walletId, limit, period});
        return;
      }

      const res = await root.ncWalletJsonRpcClient.call(
        'wallets.limits.create',
        {
          wallet_id: walletId,
          limit,
          limit_period: period,
        },
      );

      isSubmittedRef.current = false;
      if (res.success) {
        onSuccess(res.right);
        return;
      }
    },
    [onSuccess, goToPromptOtp, root],
  );

  return {
    createLimit,
  };
};
