import {useStrings, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text} from 'react-native';

import WalletLimitsWarning from '../../../shared/WalletLimitsWarning';

export default observer(function WalletLimitsEmpty() {
  const strings = useStrings();

  return (
    <>
      <WalletLimitsCardEmptyText>
        {strings['limitsSettingsScreen.noLimits.title']}
      </WalletLimitsCardEmptyText>
      <WalletLimitsWarning>
        <WalletLimitsWarningText>
          {strings['limitsSettingsScreen.noLimits.warningText']}
        </WalletLimitsWarningText>
      </WalletLimitsWarning>
    </>
  );
});

const WalletLimitsCardEmptyText = variance(Text)(theme => ({
  root: {
    marginBottom: 20,
    ...theme.fontByWeight('500'),
    fontSize: 16,
    color: theme.palette.textAdditional1,
  },
}));

const WalletLimitsWarningText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 20,
    color: theme.palette.textSecondary,
  },
}));
