import {useRoot, useStrings} from '@ncwallet-app/core';
import type {
  PromptOtpToUpdateIpRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {
  Ip,
  IpInfoId,
} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import type {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {isEmpty} from 'lodash';
import {useCallback, useState} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useUpdateIp = (
  goToPromptOtp: (params: RouteParams<PromptOtpToUpdateIpRoute>) => void,
  goToIpList: (type: IpType) => void,
) => {
  const strings = useStrings();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const root = useRoot();
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  const updateIp = useCallback(
    async (id: IpInfoId, type: IpType, ip: string, name: string) => {
      if (isEmpty(ip) || isEmpty(name)) {
        setErrorText(strings['createIp.addIpErrorOnIpOrName']);
        return;
      }
      if (isSubmitting) {
        return;
      }

      setIsSubmitting(true);
      const isTwoFaRequiredRes =
        await root.twoFaHelper.isTwoFaRequiredForIpSettings();
      if (!isTwoFaRequiredRes.success) {
        setIsSubmitting(false);
        return;
      }

      if (isTwoFaRequiredRes.right) {
        setIsSubmitting(false);
        goToPromptOtp({id, ip: ip as Ip, name, type});
        return;
      }

      const res = await root.ncWalletJsonRpcClient.call(
        'accounts.ip_settings.update',
        {id, ip, name, type},
      );
      setIsSubmitting(false);
      if (res.success) {
        goToIpList(type);
      } else {
        const errorParsed = root.errorParser.describe(res.left).summary;
        setErrorText(errorParsed);
      }
    },
    [
      isSubmitting,
      root.twoFaHelper,
      root.ncWalletJsonRpcClient,
      root.errorParser,
      strings,
      goToPromptOtp,
      goToIpList,
    ],
  );

  const getErrorText = useCallback(() => errorText, [errorText]);
  const resetErrorText = useCallback(() => {
    setErrorText(undefined);
  }, []);

  return {
    updateIp,
    getErrorText,
    resetErrorText,
  };
};
