import type {DecimalString} from '@ncwallet-app/core';
import {useStrings, useTemplates} from '@ncwallet-app/core';
import {useMemo} from 'react';

import {SendAmountValidatorError} from './SendAmountValidator';

// eslint-disable-next-line import-x/prefer-default-export
export const useSendAmountErrorTexts = (
  min: DecimalString,
  minFee: DecimalString | undefined,
) => {
  const strings = useStrings();
  const templates = useTemplates();
  return useMemo(
    (): Record<SendAmountValidatorError, string> => ({
      [SendAmountValidatorError.ShouldBeMoreThanZero]:
        strings['sendCrypto.validation.amountShouldBeMoreThanZero'],
      [SendAmountValidatorError.MoreThanMax]:
        strings['sendCrypto.validation.amountShouldBeLessThanMax'],
      [SendAmountValidatorError.LessThanMin]: templates[
        'sendCrypto.validation.amountShouldBeMoreThanMin'
      ]({min}),
      [SendAmountValidatorError.MoreThanMaxWithFee]: templates[
        'sendCrypto.validation.amountWithNetworkFeeExceedsMax'
      ]({
        fee: minFee,
      }),
      [SendAmountValidatorError.MoreThanLimit]:
        strings['sendCrypto.validation.amountShouldBeLessThanLimit'],
      [SendAmountValidatorError.MoreThanLimitWithFee]: templates[
        'sendCrypto.validation.amountWithNetworkFeeExceedsLimit'
      ]({fee: minFee}),
    }),
    [strings, templates, min, minFee],
  );
};
