import type {WalletSlideData} from '@ncwallet-app/core';
import {useStrings} from '@ncwallet-app/core';
import {expr} from '@ncwallet-app/core/src/mobx-toolbox';
import type {
  BaseSafeAreaProps,
  CurrencySelectionListItemData,
} from '@ncwallet-app/ui';
import {
  CommonRefreshControl,
  CurrenciesListState,
  CurrencySelectionHeader,
  CurrencySelectionList,
  CurrencySelectionViewContainer,
  CurrencySkeletonList,
  FilteredToEmptyCurrencySelectionList,
  getCurrenciesListState,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

export type SelectionForHistoryScreenProps = BaseSafeAreaProps & {
  search: string | undefined;
  onSearchChange: (search: string) => void;
  totalShownCurrencies?: WalletSlideData | undefined;
  shownCurrencies: CurrencySelectionListItemData[] | undefined;
  allCurrencies: CurrencySelectionListItemData[] | undefined;
  onCurrencyPress: (currency: CurrencySelectionListItemData) => void;
  onAllPress?: () => void;
  getIsLoading: () => boolean;
  onRefresh: () => void;
  onBack?: () => void;
};

export default observer(function SelectionForHistoryScreen(
  props: SelectionForHistoryScreenProps,
) {
  const {getIsLoading, onRefresh, ...rest} = props;

  const getIsRefreshing = useCallback(
    () => expr(() => getIsLoading()),
    [getIsLoading],
  );
  const refreshControl = useMemo(
    () => (
      <CommonRefreshControl
        getIsRefreshing={getIsRefreshing}
        onRefresh={onRefresh}
      />
    ),
    [getIsRefreshing, onRefresh],
  );

  const strings = useStrings();

  const listState = getCurrenciesListState(
    props.allCurrencies,
    props.shownCurrencies,
  );

  return (
    <CurrencySelectionViewContainer>
      <CurrencySelectionHeader
        withSecondaryBackground={true}
        search={props.search}
        onSearchChange={props.onSearchChange}
        onBackPress={props.onBack}
      />

      {listState === CurrenciesListState.Loading && (
        <CurrencySkeletonList itemCount={4} />
      )}

      {listState === CurrenciesListState.Normal && (
        <CurrencySelectionList
          title={strings['currencySelection.useFilterCoins']}
          currencies={props.shownCurrencies}
          refreshControl={refreshControl}
          {...rest}
        />
      )}

      {listState === CurrenciesListState.FilteredToEmpty && (
        <FilteredToEmptyCurrencySelectionList
          {...props}
          refreshControl={refreshControl}
        />
      )}
    </CurrencySelectionViewContainer>
  );
});
