import {variance} from '@ncwallet-app/core';
import {TouchableListItem} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React from 'react';
import {View} from 'react-native';

export type SessionItemContainerProps = PropsWithChildren<{
  onPress?: () => void;
}>;

export const SessionItemContainer = observer(
  (props: SessionItemContainerProps) => {
    const {onPress, children} = props;
    return onPress ? (
      <TouchableContainer onPress={onPress} Icon={CrossSvg}>
        {children}
      </TouchableContainer>
    ) : (
      <StaticContainer>{children}</StaticContainer>
    );
  },
);

const StaticContainer = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const TouchableContainer = variance(TouchableListItem)(theme => ({
  root: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));
