import {autorun, reaction} from 'mobx';

import type {AccountStore} from '../AccountStore';
import {FULFILLED} from '../AsyncAtom';
import type {DeviceIdentificationState} from '../DeviceIdentification';
import type {Account} from '../NCWalletServer';
import {batchDisposers} from '../structure';
import type {Sentry, SentryUser} from './Sentry';
import type {ScopedLogData, SentryLog} from './SentryLog';

export default class SentryLogService implements SentryLog {
  constructor(
    private _root: {
      readonly deviceIdentificationState: DeviceIdentificationState;
      readonly accountStore: AccountStore;
    },
    private _sentry: Sentry,
  ) {}

  write(message: string) {
    this._sentry.captureMessage(message);
  }

  writeWithScope({message, contextName, context, tags}: ScopedLogData) {
    this._sentry.withScope(scope => {
      scope.setContext(contextName, context);
      Object.keys(tags).forEach(key => {
        scope.setTag(key, tags[key]);
      });
      this._sentry.captureMessage(message);
    });
  }

  private _syncAccountContext() {
    return reaction(
      () => this._root.accountStore.state,
      state => {
        const user =
          state?.status === FULFILLED ? this._fromAccount(state.result) : null;

        this._sentry.setUser(user);
      },
    );
  }

  private _fromAccount(account: Account): SentryUser {
    return {
      id: account.id,
      email: account.email,
      username: account.name,
    };
  }

  private _addDeviceContext() {
    return autorun(() => {
      const {deviceId} = this._root.deviceIdentificationState;
      if (deviceId !== undefined) {
        this._sentry.setContext('context', {deviceId});
      }
    });
  }

  subscribe() {
    return batchDisposers(this._addDeviceContext(), this._syncAccountContext());
  }
}
