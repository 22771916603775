function first<First>(_: readonly [First, ...unknown[]]): First;
function first<Rest, Last>(_: readonly [...Rest[], Last]): Rest | Last;
function first<T>(_: Iterable<T> | readonly T[] | undefined): T | undefined;
function first(_: undefined): undefined;
function first<T>(_: readonly T[] | Iterable<T> | undefined): T | undefined {
  if (_ === undefined) {
    return _;
  }
  if (Array.isArray(_)) {
    return _[0] as T;
  }
  return _[Symbol.iterator]().next().value as T;
}

export default first;
