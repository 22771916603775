import {useStrings, variance} from '@ncwallet-app/core';
import type {OptionalGetter} from '@ncwallet-app/core/src/mobx-toolbox';
import {use} from '@ncwallet-app/core/src/mobx-toolbox';
import {LG_BREAKPOINT} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import RateModalIcon from './RateModalIcon';
import RateModalStars from './RateModalStars';

export type RateModalBodyProps = {
  onRatingChange: (rating: number) => void;
  getShouldAskFeedback?: OptionalGetter<boolean | undefined>;
  getRating: OptionalGetter<number>;
};

export default observer(function RateModalBody(props: RateModalBodyProps) {
  const {getRating, onRatingChange, getShouldAskFeedback} = props;
  const strings = useStrings();
  const [first, second] =
    strings['rateModalScreen.subtitleWithRate'].split('. ');
  return (
    <Container>
      {use(getShouldAskFeedback) ? (
        <RateModalMessage>
          <RateModalIconStyled />
          <View>
            <SubtitleItem>{first}.</SubtitleItem>
            <SubtitleItem>{second}</SubtitleItem>
          </View>
        </RateModalMessage>
      ) : (
        <SubtitleItem withHeight>
          {strings['rateModalScreen.subtitle']}
        </SubtitleItem>
      )}
      <RateModalStars onRatingChange={onRatingChange} getRating={getRating} />
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.background,
    paddingTop: 20,
    paddingHorizontal: 30,
    textAlign: 'center',
    alignItems: 'center',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderTopWidth: 0,
        borderBottomWidth: 0,
        alignItems: 'flex-start',
        paddingTop: 0,
        paddingHorizontal: 0,
      },
    }),
  },
}));

const SubtitleItem = variance(Text)(theme => ({
  root: {
    textAlign: 'center',
    color: theme.palette.textAdditional2,
    fontSize: 14,
    lineHeight: 17,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        textAlign: 'left',
      },
    }),
  },
  withHeight: {
    height: 34,
  },
}));

const RateModalIconStyled = variance(RateModalIcon)(theme => ({
  root: {
    marginBottom: 10,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 0,
        marginRight: 20,
      },
    }),
  },
}));

const RateModalMessage = variance(View)(theme => ({
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 15,
    width: '100%',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        padding: 10,
        backgroundColor: theme.palette.additional3,
        borderRadius: 8,
      },
    }),
  },
}));
