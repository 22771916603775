import {useEffect, useMemo} from 'react';
import {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
} from 'react-native-reanimated';

import {FADE_DURATION} from '../../../util';
import type {MessageHeight} from './MessageView';

const animationConfig = {duration: FADE_DURATION};

// eslint-disable-next-line import-x/prefer-default-export
export const useGestureAnimation = (
  timeout: number,
  id: string,
  messageHeights: MessageHeight[],
) => {
  const opacitySharedValue = useSharedValue(0);
  const topSharedValue = useSharedValue(-50);

  const topPosition = useMemo<number>(() => {
    let resultTop = 0;
    for (let i = 0; i < messageHeights.length; i++) {
      const el = messageHeights[i];
      if (el.id === id) {
        break;
      }
      resultTop = Math.round(resultTop + el.height + 10);
    }
    return resultTop;
  }, [id, messageHeights]);

  useEffect(() => {
    const _timeout = timeout - 2 * FADE_DURATION;
    opacitySharedValue.value = withTiming(1, animationConfig, finished => {
      if (finished) {
        opacitySharedValue.value = withDelay(
          _timeout,
          withTiming(0, animationConfig),
        );
      }
    });
  }, [opacitySharedValue, timeout]);

  useEffect(() => {
    topSharedValue.value = withTiming(topPosition, animationConfig);
  }, [topPosition, topSharedValue]);

  return useAnimatedStyle(() => ({
    position: 'absolute',
    width: '100%',
    opacity: opacitySharedValue.value,
    top: topSharedValue.value,
  }));
};
