import type {CurrencyDescription} from './CurrencyDescription';

export const MOCK_BITCOIN_CURRENCY: CurrencyDescription = {
  name: 'Bitcoin',
  code: 'BTC',
  fractionDigits: 8,
};

export const MOCK_MONERO_CURRENCY: CurrencyDescription = {
  name: 'Monero',
  code: 'XMR',
  fractionDigits: 8,
};

export const MOCK_USD_CURRENCY = {
  name: 'US Dollar',
  code: 'USD',
  fractionDigits: 2,
};

export const MOCK_ALL_CURRENCY = [
  {
    name: 'Euro',
    code: 'EUR',
    fractionDigits: 2,
  },
  {
    name: 'Russian Ruble',
    code: 'RUB',
    fractionDigits: 2,
  },
];
