import {useStrings} from '@ncwallet-app/core';
import type {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {
  BodyTag,
  ClickableIcon,
  ClickableTag,
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import type {IpListModalProps} from '../../UserSettingsScreens/IpListScreen';
import {IpModal} from '../../UserSettingsScreens/IpListScreen';

export type EditSessionIpCardProps = IpListModalProps & {
  onBack: () => void;
  type: IpType;
};

export default observer(function EditSessionIpCard({
  onBack,
  ...rest
}: EditSessionIpCardProps) {
  const strings = useStrings();
  return (
    <LgProfileCardContainer>
      <HeaderTag>
        <ClickableTag onPress={onBack}>
          <ClickableIcon />
          <HeaderTitleTag>
            {strings['lgSecureSettingsScreen.editIp']}
          </HeaderTitleTag>
        </ClickableTag>
      </HeaderTag>
      <BodyTag>
        <IpModal {...rest} wide />
      </BodyTag>
    </LgProfileCardContainer>
  );
});
