import type {
  PromptLimitRemovalRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {LimitRemovalAction} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {CommonActions} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useDeleteLimit} from '../../../CommonNavigationContainers/hooks/walletLimits/useDeleteLimit';
import RemoveLimitBottomSheet from '../../../screens/UserSettingsScreens/RemoveLimitBottomSheet/RemoveLimitBottomSheet';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptLimitRemovalBindingProps =
  SmallHomeStackBindingProps<'PromptLimitRemoval'>;

export default observer(function PromptLimitRemovalBinding(
  props: PromptLimitRemovalBindingProps,
) {
  const {navigation, route} = props;

  const handleModalClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const resetToLimitList = useCallback(() => {
    if (hasRouteInHistory(navigation.getState(), 'ListLimits')) {
      navigation.navigate('ListLimits');
    } else {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [{name: 'Root'}, {name: 'ListLimits'}],
        }),
      );
    }
  }, [navigation]);

  const goToOtpPrompt = useCallback(
    (params: RouteParams<PromptLimitRemovalRoute>) => {
      navigation.replace('PromptOtpToLimitRemoval', params);
    },
    [navigation],
  );

  const {deleteLimit} = useDeleteLimit(goToOtpPrompt, resetToLimitList);

  const handleDeletePress = useCallback(
    () => deleteLimit(route.params.limitId, route.params.action),
    [deleteLimit, route.params],
  );

  return (
    <RemoveLimitBottomSheet
      onCancelPress={handleModalClose}
      onDeletePress={handleDeletePress}
      isCancel={route.params.action !== LimitRemovalAction.Delete}
    />
  );
});
