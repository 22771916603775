import {useCallback, useRef} from 'react';

function useStableCallback<T extends (this: object, ...args: never[]) => void>(
  fn?: T,
): (this: ThisParameterType<T>, ...args: Parameters<T>) => void;
function useStableCallback<T extends (this: object, ...args: never[]) => never>(
  fn: T,
): (this: ThisParameterType<T>, ...args: Parameters<T>) => ReturnType<T>;
function useStableCallback<T extends (this: object, ...args: never[]) => never>(
  fn?: T,
): (this: ThisParameterType<T>, ...args: Parameters<T>) => ReturnType<T> {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  return useCallback(function wrap(
    this: ThisParameterType<T>,
    ...args: Parameters<T>
  ): ReturnType<T> {
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression,@typescript-eslint/no-unsafe-return
    return fnRef.current?.apply(this, args) as ReturnType<T>;
  }, []);
}

export default useStableCallback;
