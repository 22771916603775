import {useTheme} from '@ncwallet-app/core';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';

import type {SettingsItemProps} from '../UserSettingsScreens/SharedComponents/SettingsItem';
import {
  ItemCol,
  ItemLabel,
  ItemTouchableWithLabel,
  TitleWithLabel,
} from '../UserSettingsScreens/SharedComponents/SettingsItem';

export default observer(function ProfileSettingsItem(props: SettingsItemProps) {
  const title = props.getTitle?.() ?? props.title;
  const error = props.getError?.() || props.error;
  const theme = useTheme();
  return (
    <ItemTouchableWithLabel
      onPress={props.onPress}
      Icon={ArrowRightSvg}
      disabled={props.disabled}
      selected={props.selected}
      iconColor={props.selected ? theme.palette.primary : undefined}>
      <ItemCol>
        {!!props.label && (
          <ItemLabel selected={props.selected}>{props.label}</ItemLabel>
        )}
        {!!title && (
          <TitleWithLabel
            success={props.success}
            error={error}
            warning={props.warning}>
            {title}
          </TitleWithLabel>
        )}
      </ItemCol>
    </ItemTouchableWithLabel>
  );
});
