import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  Pressable,
  TouchableOpacity,
} from '@ncwallet-app/ui/src';
import {
  AppStoreSvg,
  GooglePlaySvg,
  PcSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {ArrowLeftSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Linking, Platform, Text} from 'react-native';

import {useSetTwoFaGenerateSecret} from '../../Navigation/hooks';
import {
  BodyTag,
  HeaderTag,
  HeaderTitleTag,
  InfoTextTag,
} from '../ProfileSettingsScreen/LgProfileCards/useCardStyles';

export type TwoFaCardAuthenticatorViewProps = {
  onBack: () => void;
};

export default observer(function TwoFaCardAuthenticatorView({
  onBack,
}: TwoFaCardAuthenticatorViewProps) {
  const theme = useTheme();
  const strings = useStrings();
  const handleGoToStore = useCallback((webLink: string, mobLink: string) => {
    if (Platform.OS === 'web') {
      window.open(webLink);
    } else {
      if (mobLink) {
        void Linking.openURL(mobLink);
      }
    }
  }, []);

  const {getAuthenticatorStoreLinks} = useSetTwoFaGenerateSecret();
  const {googleMobLink, googleWebLink, iosMobLink, iosWebLink, extensionLink} =
    getAuthenticatorStoreLinks();

  return (
    <>
      <HeaderTag>
        <TouchableOpacity onPress={onBack}>
          <ArrowLeftSvg color={theme.palette.uiMain} />
        </TouchableOpacity>
        <HeaderTitleTag offsetRight>
          {strings['secureTwoFactorAuthScreen.install']}
        </HeaderTitleTag>
      </HeaderTag>
      <BodyTag>
        <InfoTextTag noTop>
          {strings['secureTwoFactorAuthScreen.autentificationTitle']}
        </InfoTextTag>
        <StoreButton
          onPress={() => {
            handleGoToStore(iosWebLink, iosMobLink);
          }}
          first
          dataSet={{['app-button']: 'button-Primary-color-Default'}}>
          <AppStoreIcon />
          <StoreButtonText>
            {strings['secureTwoFactorAuthScreen.button.ios']}
          </StoreButtonText>
        </StoreButton>
        <StoreButton
          dataSet={{['app-button']: 'button-Primary-color-Default'}}
          onPress={() => {
            handleGoToStore(googleWebLink, googleMobLink);
          }}>
          <GooglePlayIcon />
          <StoreButtonText>
            {strings['secureTwoFactorAuthScreen.button.android']}
          </StoreButtonText>
        </StoreButton>
        <StoreButton
          onPress={() => {
            handleGoToStore(extensionLink, extensionLink);
          }}
          dataSet={{['app-button']: 'button-Primary-color-Default'}}>
          <PcSvg />
          <StoreButtonText>
            {strings['secureTwoFactorAuthScreen.button.extension']}
          </StoreButtonText>
        </StoreButton>
        <AlreadyButton
          title={strings['secureTwoFactorAuthScreen.alreadyInstalled']}
          variant={ButtonVariant.PrimaryLight}
          onPress={onBack}
          color={ButtonColor.Secondary}
        />
      </BodyTag>
    </>
  );
});

const StoreButton = variance(Pressable)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: theme.palette.info,
    borderRadius: 6,
    marginTop: 20,
  },
  first: {
    marginTop: 25,
  },
}));

const StoreButtonText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.whitey,
    fontSize: 16,
    lineHeight: 50,
    marginLeft: 10,
  },
}));

const AlreadyButton = variance(Button)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const AppStoreIcon = variance(AppStoreSvg)(() => ({
  root: {
    maxHeight: 28,
  },
}));

const GooglePlayIcon = variance(GooglePlaySvg)(() => ({
  root: {
    maxHeight: 28,
  },
}));
