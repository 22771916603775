import type {InOutCurrency} from '@ncwallet-app/core';
import {useStrings, variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT, Tooltip, TouchableOpacity} from '@ncwallet-app/ui';
import {ArrowDownWideSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import formatCurrencyShortDescription from '@ncwallet-app/ui/src/util/formatCurrencyShortDescription';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type ReceiveAddressTypeProps = {
  networks?: InOutCurrency[] | undefined;
  addressNameShown: boolean;
  addressName?: string;
  onAddressTypePress: () => void;
  contractType?: string | null;
};

export default observer(function ReceiveAddressType(
  props: ReceiveAddressTypeProps,
) {
  const {
    networks,
    addressNameShown,
    addressName,
    onAddressTypePress,
    contractType,
  } = props;
  const strings = useStrings();
  const manyAvailableNetworks = networks && networks.length > 1;
  const formattedCurrencyShortDescription = formatCurrencyShortDescription(
    addressName,
    contractType,
  );

  return (
    <AddressTypeContainer>
      <AddressTypeRow>
        <AddressTypeLabel>
          {strings['receiveCryptoScreen.blockchain']}{' '}
        </AddressTypeLabel>
        {addressNameShown && (
          <Tooltip
            title={strings['receiveCryptoScreen.blockchain']}
            text={strings['receiveCryptoScreen.address.tooltip.network']}
            closeText={strings['receiveCryptoScreen.address.tooltip.close']}
          />
        )}
      </AddressTypeRow>
      {manyAvailableNetworks ? (
        <AddressTypeClick onPress={onAddressTypePress}>
          <AddressTypeText>{formattedCurrencyShortDescription}</AddressTypeText>
          <ArrowDownIcon />
        </AddressTypeClick>
      ) : (
        <AddressTypeText>{formattedCurrencyShortDescription}</AddressTypeText>
      )}
    </AddressTypeContainer>
  );
});

const ArrowDownIcon = variance(ArrowDownWideSvg)(
  () => ({
    root: {
      marginLeft: 7,
    },
  }),
  theme => ({
    width: 12,
    height: 12,
    color: theme.palette.uiMain,
  }),
);

const AddressTypeContainer = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingHorizontal: 15,
    paddingBottom: 20,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 'auto',
        flex: 1,
        backgroundColor: theme.palette.uiPrimary,
        paddingHorizontal: 15,
        paddingTop: 13,
        paddingBottom: 13,
        marginHorizontal: 0,
        borderColor: theme.palette.uiSecondary,
        borderWidth: 1,
        borderRadius: 8,
        marginTop: 0,
      },
    }),
  },
}));

const AddressTypeLabel = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 19,
    color: theme.palette.textPrimary,
    marginRight: 6,
  },
}));

const AddressTypeText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'right',
    color: theme.palette.textPrimary,
    marginVertical: 6,
  },
}));

const AddressTypeRow = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginVertical: 6,
  },
}));

const AddressTypeClick = variance(TouchableOpacity)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
