import type {CryptoCurrencyCode} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, runInAction} from 'mobx';
import {useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import PromptOutputNetworkBindingState from './PromptOutputNetworkBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export function usePromptOutputNetworkBindingState(
  cryptoCode: CryptoCurrencyCode,
) {
  const root = useRoot();
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  const [state] = useState(() => new PromptOutputNetworkBindingState(root));

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh(cryptoCode);
          });
        }
      }),
    [cryptoCode, getIsFocused, getIsReady, state],
  );

  return state;
}
