import {variance, WalletLimitPeriod} from '@ncwallet-app/core';
import type {TabRowBaseItem} from '@ncwallet-app/ui/src';
import {TabRow} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {View} from 'react-native';

export type SetLimitTabsProps = {
  period: WalletLimitPeriod;
  onTabPress: (period: WalletLimitPeriod) => void;
};

export default observer(function SetLimitTabs(props: SetLimitTabsProps) {
  const {period, onTabPress} = props;
  const tabs: TabRowBaseItem<WalletLimitPeriod>[] = useMemo(
    () => [
      {
        key: WalletLimitPeriod.Daily,
        text: 'limitsSettingsScreen.tabLimitDay',
      },
      {
        key: WalletLimitPeriod.Monthly,
        text: 'limitsSettingsScreen.tabLimitMonth',
      },
    ],
    [],
  );

  return (
    <Container>
      <TabRow
        items={tabs}
        onPress={tab => {
          onTabPress(tab.key);
        }}
        idKey={tab => tab.key}
        active={tab => tab.key === period}
        textKey={tab => tab.text}
      />
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    marginBottom: 10,
  },
}));
