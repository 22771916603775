import type {Millisecond} from '../Time';
import type {Uri, Url} from '../units';
import type {ConfigurationValues} from './ConfigurationValues';

const devValues: ConfigurationValues = {
  ncWalletSiteUrl: 'https://ncwallet-app.dvlt.net' as Url,
  ncWalletRestApiUrl: 'https://ncw-api.dvlt.net/' as Url,
  ncWalletRestApiTimeout: 10000 as Millisecond,
  ncWalletJsonRpcUrl: 'wss://ncw-api.dvlt.net/api/v1/ws' as Url,
  ncWalletJsonRpcTimeout: 15000 as Millisecond,
  googleMobileOauthClientId:
    '198164349577-arhggpicqdg808gc2iu75trphumd7j5j.apps.googleusercontent.com',
  googleWebOauthClientId:
    '198164349577-0g1rgov0a0jc1bm1ir04feof9bvudo94.apps.googleusercontent.com',
  appleOauthRedirectUri: 'https://ncwallet-app.dvlt.net/oauth' as Uri,
  appleOauthClientId: 'com.ncwallet.oauth',
  telegramBotId: '6992924603',
  telegramBotName: 'bespalyi_dev_wal_bot',
  miniAppUrl: 'https://ncwallet-app2.dvlt.net',
  telegramOauthOrigin: 'https://ncwallet-app.dvlt.net',
  googleRedirectUriForEmileLinking:
    'https://ncw-api.dvlt.net/api/v1/google/oauth',
  appleRedirectUriForEmileLinking:
    'https://ncw-api.dvlt.net/api/v1/apple/oauth',
};

export default devValues;
