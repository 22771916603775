import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToShowUserInterface} from '@ncwallet-app/core/src/AppStateHelper';
import {NavigationContainer} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React from 'react';

import SmallHomeStack from './SmallHomeStack/SmallHomeStack';

export default observer(function SmallNavigationRoot() {
  const {smallNavigationContainerBinding: binding, navigationContainerTheme} =
    useRoot();
  const getIsReady = useGetIsReadyToShowUserInterface();
  const isReady = getIsReady();
  return (
    <NavigationContainer
      documentTitle={{enabled: isReady}}
      theme={navigationContainerTheme.theme}
      initialState={binding.initialState}
      onStateChange={binding.onStateChange}
      linking={binding.linking}
      ref={binding.onRef}
      onReady={binding.onReady}>
      <SmallHomeStack />
    </NavigationContainer>
  );
});
