import type {
  TelegramCredentials,
  TelegramCredentialsRecord,
  Url,
} from '@ncwallet-app/core';
import {first} from '@ncwallet-app/core';
import type {BaseAsyncOptions} from '@ncwallet-app/core/src/Async';
import {PathImpl} from '@ncwallet-app/core/src/CommonNavigationScheme/Path';
import type {Configuration} from '@ncwallet-app/core/src/Configuration';
import {unwrap} from '@ncwallet-app/core/src/EitherAdapter';
import type {
  Json,
  JsonSerializable,
  JsonString,
} from '@ncwallet-app/core/src/Json';
import type {TelegramCredentialsProvider} from '@ncwallet-app/core/src/TelegramCredentialsProvider';
import splitByPrefix from '@ncwallet-app/core/src/util/splitByPrefix';
import {openAuthSessionAsync} from 'expo-web-browser';
import queryString from 'query-string';

export default class WebTelegramCredentialsProviderImpl
  implements TelegramCredentialsProvider
{
  constructor(
    private readonly _root: {
      readonly json: Json;
      readonly configuration: Configuration;
    },
  ) {}

  async signIn(): Promise<TelegramCredentials> {
    const controller = new AbortController();
    const {signal} = controller;
    try {
      return await Promise.race([
        this._naiveSignIn(),
        this._heuristicSignIn({signal}),
      ]);
    } finally {
      controller.abort();
    }
  }

  /**
   * Sign in the user using Telegram services.
   * Assume the Telegram service to actually redirect to a given address.
   */
  private async _naiveSignIn(): Promise<TelegramCredentials> {
    const {telegramBotId, telegramOauthOrigin} =
      this._root.configuration.values;
    const url = getTelegramAuthUrl(
      telegramBotId,
      telegramOauthOrigin,
      telegramOauthOrigin,
    );
    const result = await openAuthSessionAsync(url, telegramOauthOrigin);
    if (result.type !== 'success') {
      throw new Error('Auth session aborted', {cause: result});
    }
    const split = splitByPrefix(result.url as Url);
    if (!split) {
      throw new TypeError(`Unknown auth response: ${result.url}`);
    }
    const [, rest] = split;
    const path = PathImpl.parse(rest);
    const {id, first_name, last_name, username, photo_url, auth_date, hash} =
      path.params ?? {};
    const credentials = {
      id: parseInt(first(id) ?? '0', 10),
      first_name: first(first_name) ?? '',
      last_name: first(last_name) ?? '',
      username: first(username) ?? '',
      photo_url: first(photo_url) ?? '',
      auth_date: parseInt(first(auth_date) ?? '0', 10),
      hash: first(hash) ?? '',
    } satisfies TelegramCredentialsRecord;
    return unwrap(this._root.json.stringify(credentials));
  }

  /**
   * Sign in the user using Telegram services.
   * Assume the Telegram service to post a message with credentials as a part of the **undocumented feature**.
   */
  private _heuristicSignIn(
    options?: BaseAsyncOptions,
  ): Promise<TelegramCredentials> {
    return new Promise(resolve => {
      window.addEventListener(
        'message',
        async event => {
          if (
            !event.isTrusted ||
            event.origin !== TELEGRAM_ORIGIN ||
            typeof event.data !== 'string'
          ) {
            return;
          }
          try {
            const credentials = await this._getTelegramAuthData(event.data);
            resolve(credentials);
          } catch {
            /* empty */
          }
        },
        {signal: options?.signal},
      );
    });
  }

  private async _getTelegramAuthData(_: string) {
    const parsed = unwrap(this._root.json.parse(_ as JsonString));
    if (isTelegramAuthResponse(parsed)) {
      const {telegramOauthOrigin} = this._root.configuration.values;
      if (parsed.origin === telegramOauthOrigin) {
        return unwrap(this._root.json.stringify(parsed.result));
      }
    }
    throw new Error('No telegram auth data detected');
  }
}

function getTelegramAuthUrl(botId: string, origin: string, returnTo: string) {
  const baseUrl = TELEGRAM_ORIGIN;
  const params = {
    bot_id: botId,
    origin,
    return_to: returnTo,
  };
  return `${baseUrl}/auth?${queryString.stringify(params)}`;
}

const TELEGRAM_ORIGIN = 'https://oauth.telegram.org';

type TelegramAuthResponse = {
  event: 'auth_result';
  result: TelegramCredentialsRecord;
  origin: string;
};

function isTelegramAuthResponse(
  _: JsonSerializable,
): _ is TelegramAuthResponse {
  return (
    typeof _ === 'object' &&
    _ !== null &&
    'event' in _ &&
    _.event === 'auth_result' &&
    'result' in _ &&
    'origin' in _
  );
}
