import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptFeedbackContainer from '../../../CommonNavigationContainers/containers/PromptFeedbackContainer/PromptFeedbackContainer';
import type {LargeHomeStackBindingProps} from '../LargeHomeStackBindingProps';

export type PromptFeedbackBindingProps =
  LargeHomeStackBindingProps<'PromptFeedback'>;

export default observer(function PromptFeedbackBinding(
  props: PromptFeedbackBindingProps,
) {
  const {navigation, route} = props;

  const goHome = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({index: 0, routes: [{name: 'Root'}]});
  }, [navigation]);

  return (
    <PromptFeedbackContainer rating={route.params.rating} goHome={goHome} />
  );
});
