import {detect} from 'detect-browser';

import {OSKind} from './OSKind';

export default function getWebOsKind() {
  const d = detect();
  if (!d || d.type !== 'browser' || !d.os) {
    return OSKind.Unknown;
  }
  switch (d.os) {
    case 'Mac OS':
      return OSKind.MacOS;
    case 'iOS':
      return OSKind.IOs;
    case 'Android OS':
      return OSKind.Android;
    case 'Linux':
      return OSKind.Linux;
  }
  if (d.os.startsWith('Windows')) {
    return OSKind.Windows;
  }
  return OSKind.Unknown;
}
