import {env} from '@ncwallet-app/core';
import UniversalDeviceInfoImpl from '@ncwallet-app/core/src/DeviceInfo/UniversalDeviceInfoImpl';
import {Integrations} from '@sentry/tracing';

export default {
  dsn: env.NC_WALLET_SENTRY_DSN_WEB,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
  release: new UniversalDeviceInfoImpl().getAppVersionWithBuildNumber(),
};
