import {
  sized,
  urlLinks,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import type {
  AccountAddressData,
  AccountDataStatus,
  AccountPersonalData,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {
  getAddress,
  getFullName,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {useIsSuspendedOrBanned} from '@ncwallet-app/core/src/UserStatusErrorDetector';
import {ButtonVariant, Pressable} from '@ncwallet-app/ui/src';
import {
  ArrowRightSvg,
  CheckSvg,
  HelpSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Linking, Text, View} from 'react-native';

import {useContactUs} from '../../../../shared/useContactUs';
import Warning from '../../../../shared/Warning';
import {
  BodyTag,
  CardButtonTag,
  Divider,
  HeaderTag,
  HeaderTitleTag,
  Row,
  Tag,
  TagContainer,
} from '../useCardStyles';
import {VerificationStatusText} from './VerificationStatusText';
import VerificationTouchableListItem from './VerificationTouchableListItem';

export type VerificationProps = {
  personalData: AccountPersonalData | undefined;
  personalDataStatus: AccountDataStatus | undefined;
  addressData: AccountAddressData | undefined;
  addressDataStatus: AccountDataStatus | undefined;
  onNamePress: () => void;
  onAddressPress: () => void;
  onDocumentsUploadPress: () => void;
  isVerificationRequired: boolean;
  isVerifiedAccount: boolean;
  isPersonalDataVerified: boolean;
  isAddressDataVerified: boolean;
  isAvailableEditPersonalData: boolean;
  isAvailableEditAddressData: boolean;
};

export default observer(function Verification(props: VerificationProps) {
  const {
    personalData,
    personalDataStatus,
    addressData,
    addressDataStatus,
    onNamePress,
    onAddressPress,
    onDocumentsUploadPress,
    isVerifiedAccount,
    isPersonalDataVerified,
    isAddressDataVerified,
    isAvailableEditPersonalData,
    isAvailableEditAddressData,
    isVerificationRequired,
  } = props;
  const strings = useStrings();
  const contactUs = useContactUs();
  const handleHelpPress = useCallback(async () => {
    await Linking.openURL(urlLinks.accountVerification);
  }, []);
  const theme = useTheme();
  const getStatus = useCallback(() => {
    if (isVerifiedAccount) {
      return strings['profileSettingsScreen.verification.verifiedStatus'];
    }

    if (!isVerificationRequired) {
      return strings['profileSettingsScreen.verificationNotTag'];
    }

    return '';
  }, [isVerifiedAccount, isVerificationRequired, strings]);

  const status = getStatus();
  const isDisabledAllVerificationItems =
    !isVerificationRequired || isVerifiedAccount;
  const isSuspendedOrBanned = useIsSuspendedOrBanned();

  return (
    <>
      <HeaderTag>
        <Row>
          <HeaderTitleTag>
            {strings['profileSettingsScreen.verificationTitle']}
          </HeaderTitleTag>
          {isSuspendedOrBanned ? (
            <TagContainer error>
              <Tag>{strings.Status_Suspended}</Tag>
            </TagContainer>
          ) : (
            <>
              {!!status && (
                <TagContainer success={isVerifiedAccount}>
                  <Tag>{status}</Tag>
                </TagContainer>
              )}
              <FaqIconPressable onPress={handleHelpPress}>
                <HelpIcon color={theme.palette.uiAdditional1} />
              </FaqIconPressable>
            </>
          )}
        </Row>
      </HeaderTag>
      <BodyTag>
        {isSuspendedOrBanned && <Warning content={strings.suspendMessage} />}
        <BorderedView>
          <BorderedViewTop>
            <BorderedViewTitle>
              {strings['profileSettingsScreen.verification.personInfo.title']}
            </BorderedViewTitle>
            {!isVerifiedAccount && isVerificationRequired && (
              <VerificationStatusText status={personalDataStatus} />
            )}
          </BorderedViewTop>
          <VerificationTouchableListItem
            label={
              strings['profileSettingsScreen.verification.personInfo.nameLabel']
            }
            value={personalData ? getFullName(personalData) : '-'}
            onPress={onNamePress}
            Icon={
              isPersonalDataVerified || isVerifiedAccount
                ? CheckSvg
                : ArrowRightSvg
            }
            disabled={
              !isAvailableEditPersonalData || isDisabledAllVerificationItems
            }
            iconColor={
              isPersonalDataVerified || isVerifiedAccount
                ? theme.palette.success
                : undefined
            }
          />
          <Divider />
          <VerificationTouchableListItem
            label={
              strings[
                'profileSettingsScreen.verification.personInfo.birthDateLabel'
              ]
            }
            value={personalData?.birthday ?? '-'}
            onPress={onNamePress}
            Icon={
              isPersonalDataVerified || isVerifiedAccount
                ? CheckSvg
                : ArrowRightSvg
            }
            disabled={
              !isAvailableEditPersonalData || isDisabledAllVerificationItems
            }
            iconColor={
              isPersonalDataVerified || isVerifiedAccount
                ? theme.palette.success
                : undefined
            }
          />
        </BorderedView>
        <SizedBox />
        {isSuspendedOrBanned ? (
          <CardButtonTag
            title={strings.contactSupport}
            variant={ButtonVariant.Primary}
            onPress={contactUs}
          />
        ) : (
          <>
            <BorderedView>
              <BorderedViewTop>
                <BorderedViewTitle>
                  {strings['profileSettingsScreen.verification.address.title']}
                </BorderedViewTitle>
                {!isVerifiedAccount && isVerificationRequired && (
                  <VerificationStatusText status={addressDataStatus} />
                )}
              </BorderedViewTop>
              <VerificationTouchableListItem
                label={addressData ? getAddress(addressData) : '-'}
                isLabelLarge
                onPress={onAddressPress}
                Icon={
                  isAddressDataVerified || isVerifiedAccount
                    ? CheckSvg
                    : ArrowRightSvg
                }
                iconColor={
                  isPersonalDataVerified || isVerifiedAccount
                    ? theme.palette.success
                    : undefined
                }
                large
                disabled={
                  !isAvailableEditAddressData || isDisabledAllVerificationItems
                }
              />
            </BorderedView>
            <SizedBox />
            <BorderedView>
              <BorderedViewTop>
                <BorderedViewTitle>
                  {
                    strings[
                      'profileSettingsScreen.verification.documents.title'
                    ]
                  }
                </BorderedViewTitle>
              </BorderedViewTop>
              <VerificationTouchableListItem
                label={
                  strings['profileSettingsScreen.verification.document.upload']
                }
                isLabelLarge
                onPress={onDocumentsUploadPress}
                Icon={ArrowRightSvg}
                large
                disabled={isDisabledAllVerificationItems}
              />
            </BorderedView>
          </>
        )}
      </BodyTag>
    </>
  );
});

const HelpIcon = sized(HelpSvg, 23);

const BorderedView = variance(View)(theme => ({
  root: {
    borderRadius: 12,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    overflow: 'hidden',
  },
  disabled: {
    opacity: 0.5,
  },
}));

const BorderedViewTop = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 20,
    backgroundColor: theme.palette.background,
    borderBottomWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const BorderedViewTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    color: theme.palette.textMain,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));

const FaqIconPressable = variance(Pressable)(() => ({
  root: {
    marginLeft: 5,
  },
}));
