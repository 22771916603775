/* eslint-disable @typescript-eslint/unbound-method */
import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant, Input, InputVariant} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import type {AddressFormStateProps} from '../../../../shared/AccountDataForm/AddressFormState/useAddressFormState';
import {
  ADDRESS_INDEX_LENGTH,
  CITY_NAME_LENGTH,
  STREET_NAME_LENGTH,
  useAddressFormState,
} from '../../../../shared/AccountDataForm/AddressFormState/useAddressFormState';
import SelectCountry from '../../../../shared/SelectCountry';
import {
  BodyTag,
  HeaderTag,
  HeaderTitleTag,
  InfoTextTag,
  LabelTag,
  StepText,
} from '../useCardStyles';
import BackButton from './BackButton';

export type AddressFormProps = {
  onBack: () => void;
  onCountrySelect: () => void;
} & AddressFormStateProps;

export default observer(function AddressDataForm(props: AddressFormProps) {
  const strings = useStrings();

  const {formState, handleSubmit} = useAddressFormState(props);

  return (
    <>
      <HeaderTag>
        <BackButton onPress={props.onBack} />
        <HeaderTitleTag>
          {strings['profileSettingsScreen.verification.address.title']}
        </HeaderTitleTag>
        <StepText>
          {strings['profileSettingsScreen.verificationStep']} 2/3
        </StepText>
      </HeaderTag>
      <BodyTag>
        <InfoTextTag>
          {strings['profileSettingsScreen.addressForm.description']}
        </InfoTextTag>
        <LabelTag>
          {strings['profileSettingsScreen.verification.address.title']}
        </LabelTag>
        <SelectCountry
          onPress={props.onCountrySelect}
          value={formState.country}
          variant={InputVariant.Default}
        />
        <SizedBox />
        <Input
          variant={InputVariant.Default}
          placeholder={
            strings['profileSettingsScreen.verification.address.city']
          }
          value={formState.city}
          onChangeText={formState.setCity}
          maxLength={CITY_NAME_LENGTH}
        />
        <SizedBox />
        <Input
          variant={InputVariant.Default}
          placeholder={
            strings['profileSettingsScreen.verification.address.street']
          }
          value={formState.street}
          onChangeText={formState.setStreet}
          maxLength={STREET_NAME_LENGTH}
        />
        <SizedBox />
        <Input
          placeholder={
            strings['profileSettingsScreen.verification.address.index']
          }
          value={formState.index}
          onChangeText={formState.setIndex}
          maxLength={ADDRESS_INDEX_LENGTH}
        />

        <SubmitButton
          title={strings['profileSettingsScreen.nextButton']}
          variant={ButtonVariant.Primary}
          onPress={handleSubmit}
        />
      </BodyTag>
    </>
  );
});

const SubmitButton = variance(Button)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 10,
  },
}));
