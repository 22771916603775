import {action, makeObservable, observable} from 'mobx';

import type {LoadingIndicator} from './LoadingIndicator';
import {LoadingIndicatorStatus} from './LoadingIndicator';

export default class LoadingIndicatorImpl implements LoadingIndicator {
  @observable private _loadingStatus: LoadingIndicatorStatus =
    LoadingIndicatorStatus.Inactive;

  get loadingStatus() {
    return this._loadingStatus;
  }

  @action.bound setLoadingStatus(status: LoadingIndicatorStatus) {
    this._loadingStatus = status;
  }

  constructor() {
    makeObservable(this);
  }
}
