import {SafeAreaInset} from '@ncwallet-app/ui';
import {useHeaderHeight} from '@react-navigation/elements';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {RefreshControl} from 'react-native';

import type {PromptExchangeReceiptContainerProps} from '../../../CommonNavigationContainers/hooks/usePromptExchangeReceiptContainer';
import {usePromptExchangeReceiptContainer} from '../../../CommonNavigationContainers/hooks/usePromptExchangeReceiptContainer';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {ExchangeFormScreen} from '../../../screens/ExchangeFormScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptExchangeReceiptBindingProps =
  SmallHomeStackBindingProps<'PromptExchangeReceipt'>;

export default observer(function PromptExchangeReceiptBinding(
  props: PromptExchangeReceiptBindingProps,
) {
  const {navigation, route} = props;

  const containerProps = useMemo<PromptExchangeReceiptContainerProps>(
    () => ({
      params: route.params,
      setParams: params => {
        navigation.setParams(params);
      },
      promptConfirmationForExchange: params => {
        navigation.navigate('PromptConfirmationForExchange', params);
      },
      promptCryptoToExchange: params => {
        navigation.navigate('PromptCryptoToExchange', params);
      },
      fallBack: () => {
        navigation.navigate('FallBack');
      },
    }),
    [navigation, route.params],
  );

  const screenProps = usePromptExchangeReceiptContainer(containerProps);

  const header = useHeaderHeight();

  const refreshControl = useMemo(
    () => (
      <RefreshControl
        refreshing={
          screenProps.currencyTo !== undefined &&
          screenProps.currencyFrom !== undefined &&
          screenProps.isLoading
        }
        onRefresh={screenProps.refreshExchangeRate}
      />
    ),
    [
      screenProps.currencyFrom,
      screenProps.currencyTo,
      screenProps.isLoading,
      screenProps.refreshExchangeRate,
    ],
  );

  return (
    <ExchangeFormScreen
      {...screenProps}
      refreshControl={refreshControl}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      keyboardAvoiding={true}
      keyboardVerticalOffset={header}
    />
  );
});
