import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import type {PromptSourceCryptoToExchangeContainerProps} from '../../../../../CommonNavigationContainers/hooks/usePromptSourceCryptoToExchangeContainer';
import {usePromptSourceCryptoToExchangeContainer} from '../../../../../CommonNavigationContainers/hooks/usePromptSourceCryptoToExchangeContainer';
import {useTabBarLayout} from '../../../../../components';
import {SelectionForExchangeScreen} from '../../../../../screens/SelectionForExchangeScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptSourceCryptoToExchangeBindingProps =
  LargeSwitchBindingProps<'PromptSourceCryptoToExchange'>;

export default observer(function PromptSourceCryptoToExchangeBinding(
  props: PromptSourceCryptoToExchangeBindingProps,
) {
  const {navigation, route} = props;

  const containerProps = useMemo<PromptSourceCryptoToExchangeContainerProps>(
    () => ({
      params: route.params,
      goBack: () => {
        navigation.goBack();
      },
      setParams: params => {
        navigation.setParams(params);
      },
      promptExchangeReceipt: params => {
        navigation.navigate('PromptExchangeReceipt', params);
      },
      promptCryptoToReceive: params => {
        navigation.navigate('PromptCryptoToReceive', params);
      },
      promptNewWallet: params => {
        navigation.navigate('PromptNewWallet', params);
      },
    }),
    [navigation, route.params],
  );

  const screenProps = usePromptSourceCryptoToExchangeContainer(containerProps);
  const layout = useTabBarLayout();

  return (
    <SelectionForExchangeScreen
      {...screenProps}
      isLg={true}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: layout.height}}
    />
  );
});
