import {createStylesHook, useStrings} from '@ncwallet-app/core';
import {
  Button,
  ButtonVariant,
  SafeAreaInset,
  SafeAreaLayout,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

export type FailedRedirectionScreenProps = {
  onTryAgain: () => void;
  onBackToTheApp: () => void;
};

export default observer(function FailedRedirectionScreen(
  props: FailedRedirectionScreenProps,
) {
  const {onTryAgain, onBackToTheApp} = props;
  const styles = useStyles();
  const strings = useStrings();
  return (
    <SafeAreaLayout insets={SafeAreaInset.BOTTOM} style={styles.root}>
      <Button
        style={styles.button}
        variant={ButtonVariant.Primary}
        onPress={onTryAgain}
        title={strings['redirection.tryAgain']}
      />
      <Button
        style={styles.button}
        variant={ButtonVariant.Danger}
        onPress={onBackToTheApp}
        title={strings['redirection.giveUp']}
      />
    </SafeAreaLayout>
  );
});

const useStyles = createStylesHook(theme => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background,
    justifyContent: 'flex-end',
  },
  button: {
    marginBottom: 16,
    maxWidth: 345,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
