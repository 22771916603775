import {computed, makeObservable} from 'mobx';

import type {LayoutHelperState} from '../LayoutHelperState';
import type {
  LinkingOptionsProvider,
  NavigationContainer,
  NavigationContainerBinding,
} from '../NavigationContainer';

export default class LinkingOptionsProviderImpl
  implements LinkingOptionsProvider
{
  constructor(
    private readonly _root: {
      readonly layoutHelperState: LayoutHelperState;
      readonly largeNavigationContainerBinding: NavigationContainerBinding &
        NavigationContainer;
      readonly smallNavigationContainerBinding: NavigationContainerBinding &
        NavigationContainer;
    },
  ) {
    makeObservable(this);
  }

  @computed
  get linking() {
    if (this._root.layoutHelperState.isLarge) {
      return this._root.largeNavigationContainerBinding.linking;
    }
    return this._root.smallNavigationContainerBinding.linking;
  }
}
