import {sized, useRoot, useStrings, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  TouchableOpacity,
} from '@ncwallet-app/ui';
import {
  AddWalletDarkSvg,
  AddWalletSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {PlusSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type EmptyWalletListProps = {
  title?: string;
  description?: string;
  addButtonText?: string;
  onAddPress: () => void;
  onReceivePress?: () => void;
};

export default observer(function EmptyWalletList(props: EmptyWalletListProps) {
  const {title, description, addButtonText, onAddPress, onReceivePress} = props;
  const {appearance} = useRoot();
  const strings = useStrings();

  return (
    <Container>
      <TouchableOpacity onPress={onAddPress}>
        {appearance.isDark ? <AddWalletDarkSvg /> : <AddWalletIcon />}
      </TouchableOpacity>
      <Title>{title || strings['emptyWallets.title']}</Title>
      <Description>
        {description || strings['emptyWallets.description']}
      </Description>
      {onReceivePress && (
        <ReceiveBtn
          title={strings['currencyScreen.walletAction.receiveTitle']}
          onPress={onReceivePress}
          Icon={PlusSvg}
          color={ButtonColor.Success}
          variant={ButtonVariant.Highlighted}
        />
      )}
      <Btn
        title={addButtonText || strings['emptyWallets.button']}
        onPress={onAddPress}
        Icon={PlusSvg}
        variant={ButtonVariant.Primary}
      />
    </Container>
  );
});

const AddWalletIcon = sized(AddWalletSvg, 80, 80);

const Container = variance(View)(() => ({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 20,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 15,
    color: theme.palette.textMain,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    textAlign: 'center',
    marginBottom: 20,
    color: theme.palette.textAdditional2,
  },
}));

const Btn = variance(Button)(() => ({
  root: {
    width: '100%',
  },
}));

const ReceiveBtn = variance(Btn)(() => ({
  root: {
    marginBottom: 20,
  },
}));
