import {useStrings} from '@ncwallet-app/core/src';
import type {
  BaseSafeAreaProps,
  CurrencySelectionListItemData,
} from '@ncwallet-app/ui';
import {
  CommonRefreshControl,
  CurrenciesListState,
  CurrencySelectionHeader,
  CurrencySelectionList,
  CurrencySelectionViewContainer,
  CurrencySkeletonList,
  FilteredToEmptyCurrencySelectionList,
  getCurrenciesListState,
  SelectionCryptoTopMessage,
  WalletActionKind,
  WalletActionList,
} from '@ncwallet-app/ui';
import {useIsDimensions} from '@ncwallet-app/ui/src/util';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {StyleSheet} from 'react-native';
import {isTablet} from 'react-native-device-info';

import EmptyWalletList from '../../shared/EmptyWalletList';
import CurrencyListItem from '../CurrencyScreen/CurrencyListItem';
import type {SelectionForReceiveTabKey} from './SelectionForReceiveTabKey';
import {Tabs} from './Tabs';

export type SelectionForReceiveScreenProps = BaseSafeAreaProps & {
  search: string | undefined;
  onSearchChange: (search: string) => void;
  activeTab: SelectionForReceiveTabKey;
  onTabPress: (tab: SelectionForReceiveTabKey) => void;
  shownCurrencies: CurrencySelectionListItemData[] | undefined;
  allCurrencies: CurrencySelectionListItemData[] | undefined;
  getIsRefreshing: () => boolean;
  onCurrencyPress: (currency: CurrencySelectionListItemData) => void;
  onRefresh: () => void;
  listTitle: string;
  onBack: () => void;
  goToAddPage: () => void;
};

export const SelectionForReceiveScreen = observer(
  (props: SelectionForReceiveScreenProps) => {
    const {
      getIsRefreshing,
      search,
      onSearchChange,
      onBack,
      onRefresh,
      allCurrencies,
      shownCurrencies,
      goToAddPage,
      ...rest
    } = props;

    const listState = getCurrenciesListState(allCurrencies, shownCurrencies);
    const isLg = useIsDimensions('lg');
    const strings = useStrings();

    const refreshControl = useMemo(
      () => (
        <CommonRefreshControl
          getIsRefreshing={getIsRefreshing}
          onRefresh={onRefresh}
        />
      ),
      [getIsRefreshing, onRefresh],
    );
    return (
      <CurrencySelectionViewContainer>
        <CurrencySelectionHeader
          search={search}
          onSearchChange={onSearchChange}
          onBackPress={onBack}
          title={strings.backToChooseCoin}
          hideSearch={isTablet() || listState === CurrenciesListState.Empty}
          Tab={
            listState !== CurrenciesListState.Empty && (
              <Tabs
                wideStyle
                activeTabKey={props.activeTab}
                onTabPress={props.onTabPress}
              />
            )
          }
        />

        {!isLg && listState !== CurrenciesListState.Empty && (
          <Tabs activeTabKey={props.activeTab} onTabPress={props.onTabPress} />
        )}

        {listState === CurrenciesListState.Loading && (
          <CurrencySkeletonList itemCount={4} />
        )}

        {listState === CurrenciesListState.Normal && (
          <CurrencySelectionList
            currencies={shownCurrencies}
            refreshControl={refreshControl}
            {...rest}
          />
        )}

        {listState === CurrenciesListState.Empty &&
          (isLg ? (
            <NoCurrenciesView onAddPress={goToAddPage} />
          ) : (
            <EmptyWalletList
              title={strings['selectionForReceive.empty.text']}
              description={strings['selectionForReceive.empty.description']}
              addButtonText={strings['ButtonForReceive.empty.text']}
              onAddPress={goToAddPage}
            />
          ))}

        {listState === CurrenciesListState.FilteredToEmpty && (
          <FilteredToEmptyCurrencySelectionList
            refreshControl={refreshControl}
            {...props}
          />
        )}
      </CurrencySelectionViewContainer>
    );
  },
);

type NoCurrenciesViewProps = {
  onReceivePress?: () => void;
  onAddPress: () => void;
};

export const NoCurrenciesView = (props: NoCurrenciesViewProps) => {
  return (
    <>
      <SelectionCryptoTopMessage
        texts={{
          title: 'selectionForReceive.empty.text',
          message: 'selectionForReceive.empty.description',
        }}
      />
      <WalletActionList contentContainerStyle={styles.container}>
        {props.onReceivePress && (
          <CurrencyListItem
            type={WalletActionKind.Receive}
            onPress={props.onReceivePress}
            dataSet={{app: 'currency-item'}}
          />
        )}
        <CurrencyListItem
          type={WalletActionKind.CreateNew}
          onPress={props.onAddPress}
          dataSet={{app: 'currency-item'}}
        />
      </WalletActionList>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 15,
  },
});
