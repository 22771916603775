import {useTheme, variance} from '@ncwallet-app/core';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {ArrowDownWideSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

type CurrencySelectButtonProps = {
  currencyCode: string | undefined;
  onPress: () => void;
  testID?: string;
};

// eslint-disable-next-line import-x/prefer-default-export
export const CurrencySelectButton = observer(
  (props: CurrencySelectButtonProps) => {
    const {onPress, currencyCode, testID} = props;
    const theme = useTheme();

    return (
      <CurrencySelectView>
        <CurrencySelectTouchable onPress={onPress} testID={testID}>
          <CurrencySelectText>{currencyCode}</CurrencySelectText>
          <ArrowDownWideSvg
            width={12}
            height={12}
            color={theme.palette.uiAdditional1}
          />
        </CurrencySelectTouchable>
      </CurrencySelectView>
    );
  },
);

const CurrencySelectView = variance(View)(theme => ({
  root: {
    minWidth: 90,
    height: '100%',
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.uiAdditional1,
  },
}));

const CurrencySelectTouchable = variance(TouchableOpacity)(
  theme => ({
    root: {
      flexDirection: 'row',
      paddingHorizontal: 9,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.uiPrimary,
    },
    container: {
      height: '100%',
    },
  }),
  (theme, styles) => ({
    containerStyle: styles.container,
  }),
);

const CurrencySelectText = variance(Text)(theme => ({
  root: {
    marginRight: 5,
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 16,
  },
}));
