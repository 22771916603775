import {computed, makeObservable} from 'mobx';

import {AppLifecycleService} from '../AppLifecycle';
import {ConfigurationService} from '../Configuration';
import type {NavigationContainerState} from '../NavigationContainer';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import type {Core} from './Core';

export default abstract class BaseCoreService implements Core, Service {
  protected constructor() {
    makeObservable(this);
  }

  readonly appLifecycle = new AppLifecycleService();
  readonly configuration = new ConfigurationService(this);

  get debug() {
    return this.configuration;
  }

  @computed get initialized() {
    return this.appLifecycle.initialized && this.configuration.initialized;
  }

  subscribe() {
    return batchDisposers(
      this.appLifecycle.subscribe(),
      this.configuration.subscribe(),
    );
  }

  abstract readonly navigationContainerState: NavigationContainerState;
}
