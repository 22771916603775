import {useStyles} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {ImageSourcePropType} from 'react-native';
import {ImageBackground} from 'react-native';

import {useIsDimensions} from '../util';

// eslint-disable-next-line import-x/prefer-default-export
export const AuthBackground = observer(() => {
  const isLgScreen = useIsDimensions('lg');
  const styles = useStyles(theme => ({
    root: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.primary,
    },
  }));

  return isLgScreen ? (
    <ImageBackground
      source={require('./md-background.jpg') as ImageSourcePropType}
      resizeMode={'cover'}
      style={styles.root}
    />
  ) : null;
});
