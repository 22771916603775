import {makeObservable, observable, runInAction} from 'mobx';

import type {Maybe} from '../Maybe';
import type {DeviceId} from '../units';
import type {DeviceIdentification} from './DeviceIdentification';
import type {DeviceIdentificationState} from './DeviceIdentificationState';

export default class DeviceIdentificationStateImpl
  implements DeviceIdentificationState, DeviceIdentification
{
  @observable.ref private _deviceId: DeviceId | undefined = undefined;

  constructor(
    private readonly _root: {
      readonly deviceIdentification: DeviceIdentification;
    },
  ) {
    makeObservable(this);
  }

  get deviceId() {
    return this._deviceId;
  }

  async getDeviceId(): Promise<Maybe<DeviceId>> {
    const deviceId_ = await this._root.deviceIdentification.getDeviceId();
    if (deviceId_.success) {
      runInAction(() => {
        this._deviceId = deviceId_.right;
      });
    }
    return deviceId_;
  }

  async spoofDeviceId(_: DeviceId): Promise<Maybe<void>> {
    const spoof_ = await this._root.deviceIdentification.spoofDeviceId(_);
    if (spoof_.success) {
      runInAction(() => {
        this._deviceId = _;
      });
    }
    return spoof_;
  }
}
