import {sized, useTheme, variance} from '@ncwallet-app/core';
import {LampSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type TipItemProps = {
  title: string;
  description: string;
  onTryPress?: () => void;
};

export default observer(function TipItem({title, description}: TipItemProps) {
  const theme = useTheme();
  return (
    <Container>
      <Header>
        <IconContainer>
          <LampIcon color={theme.palette.uiPrimary} />
        </IconContainer>
        <HeaderTitle>{title}</HeaderTitle>
      </Header>
      <Description>{description}</Description>
    </Container>
  );
});

const LampIcon = sized(LampSvg, 16, 16);

const Container = variance(View)(theme => ({
  root: {
    marginTop: 20,
    marginHorizontal: 15,
    borderBottomColor: theme.palette.uiPrimary,
    borderBottomWidth: 1,
  },
}));

const Header = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
}));

const HeaderTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 22,
    marginLeft: 10,
    color: theme.palette.textPrimary,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 20,
    color: theme.palette.textAdditional1,
    marginBottom: 10,
  },
}));

const IconContainer = variance(View)(theme => ({
  root: {
    width: 30,
    height: 30,
    borderRadius: 4,
    backgroundColor: theme.palette.uiPrimary,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4,
  },
}));
