import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import type {PromptSessionDeletionRoute} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {PressableNativeFeedback} from '@ncwallet-app/ui';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {
  BodyTag,
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../../ProfileSettingsScreen/LgProfileCards/useCardStyles';

export type SessionsMenuProps = {
  goToListAllowedIps: () => void;
  goToListBlockedIps: () => void;
  goToListSessionHistory: () => void;
  goToListActiveSessions: () => void;
  promptConfirmationToEndSession: (
    params: PromptSessionDeletionRoute['params'],
  ) => void;
};

export default observer(function SessionsMenu(props: SessionsMenuProps) {
  const {
    goToListAllowedIps,
    goToListBlockedIps,
    goToListSessionHistory,
    goToListActiveSessions,
  } = props;
  const theme = useTheme();
  const strings = useStrings();

  return (
    <LgProfileCardContainer>
      <HeaderTag>
        <HeaderTitleTag>
          {strings['lgSecureSettingsScreen.sessionsTitle']}
        </HeaderTitleTag>
      </HeaderTag>
      <BodyTag>
        <SectionsContainer>
          <Section onPress={goToListAllowedIps} testID="allow-ip-btn">
            <SectionTitle>
              {strings['lgSecureSettingsScreen.sessionsWhiteList']}
            </SectionTitle>
            <ArrowRightSvg color={theme.palette.uiMain} />
          </Section>
          <Section onPress={goToListBlockedIps} testID="block-ip-btn">
            <SectionTitle>
              {strings['lgSecureSettingsScreen.sessionsBlackList']}
            </SectionTitle>
            <ArrowRightSvg color={theme.palette.uiMain} />
          </Section>
          <Section
            onPress={goToListSessionHistory}
            testID="session-history-btn">
            <SectionTitle>
              {strings['lgSecureSettingsScreen.sessionsHistory']}
            </SectionTitle>
            <ArrowRightSvg color={theme.palette.uiMain} />
          </Section>
          <Section
            noBorder
            isBorderVisible={false}
            onPress={goToListActiveSessions}
            testID="active-sessions-btn">
            <SectionTitle>
              {strings['lgSecureSettingsScreen.sessionsActive']}
            </SectionTitle>
            <ArrowRightSvg color={theme.palette.uiMain} />
          </Section>
        </SectionsContainer>
      </BodyTag>
    </LgProfileCardContainer>
  );
});

const SectionsContainer = variance(View)(theme => ({
  root: {
    maxHeight: 204,
    backgroundColor: theme.palette.uiPrimary,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const Section = variance(PressableNativeFeedback)(
  theme => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 20,
      paddingVertical: 15,
      borderBottomWidth: 1,
      borderColor: theme.palette.uiSecondary,
    },
    disabled: {
      opacity: 0.5,
    },
    noBorder: {
      borderBottomWidth: 0,
    },
  }),
  theme => ({
    borderStyle: {
      borderBottomWidth: 1,
      borderColor: theme.palette.uiSecondary,
    },
  }),
);

const SectionTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.textPrimary,
  },
}));
