import {withUniqueGradientIds} from '@ncwallet-app/core';

import AddWalletDarkSvg_ from './add-wallet-dark.svg';
import AddWalletSvg_ from './add-wallet.svg';
import AppStoreSvg_ from './app-store.svg';
import BucketSvg_ from './bucket.svg';
import AAVECircleSvg_ from './currency/aave-circle.svg';
import ApeCircleSvg_ from './currency/ape-circle.svg';
import HexCircleSvg_ from './currency/hex-circle.svg';
import ManaCircleSvg_ from './currency/mana-circle.svg';
import RoboxCircleSvg_ from './currency/robox-circle.svg';
import SandCircleSvg_ from './currency/sand-circle.svg';
import SOLCircleSVG_ from './currency/sol-circle.svg';
import WETHCircleSvg_ from './currency/weth-circle.svg';
import HeartDarkSvg_ from './heart-dark.svg';
import HeartSvg_ from './heart.svg';
import LgAffEarningsMonth_ from './lg-aff-earnings-month.svg';
import LgAffEarnings_ from './lg-aff-earnings.svg';
import LgAffLastUser_ from './lg-aff-last-user.svg';
import LgAffUser_ from './lg-aff-user.svg';
import NCWalletLogoDarkSvg_ from './nc-wallet-logo-dark.svg';
import NCWalletLogoWebDarkSvg_ from './nc-wallet-logo-web-dark.svg';
import NCWalletLogoWebSvg_ from './nc-wallet-logo-web.svg';
import NCWalletLogoSvg_ from './nc-wallet-logo.svg';

export const LgAffUser = withUniqueGradientIds(LgAffUser_);
export const LgAffLastUser = withUniqueGradientIds(LgAffLastUser_);
export const LgAffEarnings = withUniqueGradientIds(LgAffEarnings_);
export const LgAffEarningsMonth = withUniqueGradientIds(LgAffEarningsMonth_);
export const NCWalletLogoDarkSvg = withUniqueGradientIds(NCWalletLogoDarkSvg_);
export const NCWalletLogoWebDarkSvg = withUniqueGradientIds(
  NCWalletLogoWebDarkSvg_,
);
export const NCWalletLogoWebSvg = withUniqueGradientIds(NCWalletLogoWebSvg_);
export const HexCircleSvg = withUniqueGradientIds(HexCircleSvg_);
export const NCWalletLogoSvg = withUniqueGradientIds(NCWalletLogoSvg_);
export const HeartSvg = withUniqueGradientIds(HeartSvg_);
export const HeartDarkSvg = withUniqueGradientIds(HeartDarkSvg_);
export const AppStoreSvg = withUniqueGradientIds(AppStoreSvg_);
export const AddWalletSvg = withUniqueGradientIds(AddWalletSvg_);
export const AddWalletDarkSvg = withUniqueGradientIds(AddWalletDarkSvg_);

export {default as OrangeShapeBackgroundSvg} from './orange-shape-background.svg';
export {default as FrFlagSvg} from './flags/fr.svg';
export {default as RuFlagSvg} from './flags/ru.svg';
export {default as EnFlagSvg} from './flags/en.svg';
export {default as ItFlagSvg} from './flags/it.svg';
export {default as DeFlagSvg} from './flags/de.svg';
export {default as PtFlagSvg} from './flags/pt.svg';
export {default as PtBRFlagSvg} from './flags/pt_BR.svg';
export {default as EsFlagSvg} from './flags/es.svg';

export {default as ADFlagSvg} from './flags/ad.svg';
export {default as AEFlagSvg} from './flags/ae.svg';
export {default as AFFlagSvg} from './flags/af.svg';
export {default as AGFlagSvg} from './flags/ag.svg';
export {default as AIFlagSvg} from './flags/ai.svg';
export {default as AMFlagSvg} from './flags/am.svg';
export {default as ASFlagSvg} from './flags/as.svg';
export {default as ARFlagSvg} from './flags/ar.svg';
export {default as ATFlagSvg} from './flags/at.svg';
export {default as ALFlagSvg} from './flags/al.svg';
export {default as AOFlagSvg} from './flags/ao.svg';
export {default as AUFlagSvg} from './flags/au.svg';
export {default as AWFlagSvg} from './flags/aw.svg';
export {default as AXFlagSvg} from './flags/ax.svg';
export {default as AZFlagSvg} from './flags/az.svg';
export {default as BAFlagSvg} from './flags/ba.svg';
export {default as BBFlagSvg} from './flags/bb.svg';
export {default as BDFlagSvg} from './flags/bd.svg';
export {default as BEFlagSvg} from './flags/be.svg';
export {default as BFFlagSvg} from './flags/bf.svg';
export {default as BGFlagSvg} from './flags/bg.svg';
export {default as BHFlagSvg} from './flags/bh.svg';
export {default as BIFlagSvg} from './flags/bi.svg';
export {default as BJFlagSvg} from './flags/bj.svg';
export {default as BLFlagSvg} from './flags/bl.svg';
export {default as BMFlagSvg} from './flags/bm.svg';
export {default as BNFlagSvg} from './flags/bn.svg';
export {default as BOFlagSvg} from './flags/bo.svg';
export {default as BQFlagSvg} from './flags/bq.svg';
export {default as BRFlagSvg} from './flags/br.svg';
export {default as BSFlagSvg} from './flags/bs.svg';
export {default as BTFlagSvg} from './flags/bt.svg';
export {default as BVFlagSvg} from './flags/bv.svg';
export {default as BWFlagSvg} from './flags/bw.svg';
export {default as BYFlagSvg} from './flags/by.svg';
export {default as BZFlagSvg} from './flags/bz.svg';
export {default as CAFlagSvg} from './flags/ca.svg';
export {default as CCFlagSvg} from './flags/cc.svg';
export {default as CDFlagSvg} from './flags/cd.svg';
export {default as CFFlagSvg} from './flags/cf.svg';
export {default as CGFlagSvg} from './flags/cg.svg';
export {default as CHFlagSvg} from './flags/ch.svg';
export {default as CIFlagSvg} from './flags/ci.svg';
export {default as CKFlagSvg} from './flags/ck.svg';
export {default as CLFlagSvg} from './flags/cl.svg';
export {default as CMFlagSvg} from './flags/cm.svg';
export {default as CNFlagSvg} from './flags/cn.svg';
export {default as COFlagSvg} from './flags/co.svg';
export {default as CRFlagSvg} from './flags/cr.svg';
export {default as CUFlagSvg} from './flags/cu.svg';
export {default as CVFlagSvg} from './flags/cv.svg';
export {default as CWFlagSvg} from './flags/cw.svg';
export {default as CXFlagSvg} from './flags/cx.svg';
export {default as CYFlagSvg} from './flags/cy.svg';
export {default as CZFlagSvg} from './flags/cz.svg';
export {default as DEFlagSvg} from './flags/de.svg';
export {default as DJFlagSvg} from './flags/dj.svg';
export {default as DKFlagSvg} from './flags/dk.svg';
export {default as DMFlagSvg} from './flags/dm.svg';
export {default as DOFlagSvg} from './flags/do.svg';
export {default as DZFlagSvg} from './flags/dz.svg';
export {default as ECFlagSvg} from './flags/ec.svg';
export {default as EEFlagSvg} from './flags/ee.svg';
export {default as EGFlagSvg} from './flags/eg.svg';
export {default as EHFlagSvg} from './flags/eh.svg';
export {default as ERFlagSvg} from './flags/er.svg';
export {default as ESFlagSvg} from './flags/es.svg';
export {default as ETFlagSvg} from './flags/et.svg';
export {default as EUFlagSvg} from './flags/eu.svg';
export {default as FIFlagSvg} from './flags/fi.svg';
export {default as FJFlagSvg} from './flags/fj.svg';
export {default as FKFlagSvg} from './flags/fk.svg';
export {default as FMFlagSvg} from './flags/fm.svg';
export {default as FOFlagSvg} from './flags/fo.svg';
export {default as FRFlagSvg} from './flags/fr.svg';
export {default as GAFlagSvg} from './flags/ga.svg';
export {default as GBFlagSvg} from './flags/gb.svg';
export {default as GDFlagSvg} from './flags/gd.svg';
export {default as GEFlagSvg} from './flags/ge.svg';
export {default as GFFlagSvg} from './flags/gf.svg';
export {default as GGFlagSvg} from './flags/gg.svg';
export {default as GHFlagSvg} from './flags/gh.svg';
export {default as GIFlagSvg} from './flags/gi.svg';
export {default as GLFlagSvg} from './flags/gl.svg';
export {default as GMFlagSvg} from './flags/gm.svg';
export {default as GNFlagSvg} from './flags/gn.svg';
export {default as GPFlagSvg} from './flags/gp.svg';
export {default as GQFlagSvg} from './flags/gq.svg';
export {default as GRFlagSvg} from './flags/gr.svg';
export {default as GSFlagSvg} from './flags/gs.svg';
export {default as GTFlagSvg} from './flags/gt.svg';
export {default as GUFlagSvg} from './flags/gu.svg';
export {default as GWFlagSvg} from './flags/gw.svg';
export {default as GYFlagSvg} from './flags/gy.svg';
export {default as HKFlagSvg} from './flags/hk.svg';
export {default as HMFlagSvg} from './flags/hm.svg';
export {default as HNFlagSvg} from './flags/hn.svg';
export {default as HRFlagSvg} from './flags/hr.svg';
export {default as HTFlagSvg} from './flags/ht.svg';
export {default as HUFlagSvg} from './flags/hu.svg';
export {default as IDFlagSvg} from './flags/id.svg';
export {default as IEFlagSvg} from './flags/ie.svg';
export {default as ILFlagSvg} from './flags/il.svg';
export {default as IMFlagSvg} from './flags/im.svg';
export {default as INFlagSvg} from './flags/in.svg';
export {default as IOFlagSvg} from './flags/io.svg';
export {default as IQFlagSvg} from './flags/iq.svg';
export {default as IRFlagSvg} from './flags/ir.svg';
export {default as ISFlagSvg} from './flags/is.svg';
export {default as ITFlagSvg} from './flags/it.svg';
export {default as JEFlagSvg} from './flags/je.svg';
export {default as JMFlagSvg} from './flags/jm.svg';
export {default as JOFlagSvg} from './flags/jo.svg';
export {default as JPFlagSvg} from './flags/jp.svg';
export {default as KEFlagSvg} from './flags/ke.svg';
export {default as KGFlagSvg} from './flags/kg.svg';
export {default as KHFlagSvg} from './flags/kh.svg';
export {default as KIFlagSvg} from './flags/ki.svg';
export {default as KMFlagSvg} from './flags/km.svg';
export {default as KNFlagSvg} from './flags/kn.svg';
export {default as KPFlagSvg} from './flags/kp.svg';
export {default as KRFlagSvg} from './flags/kr.svg';
export {default as KWFlagSvg} from './flags/kw.svg';
export {default as KYFlagSvg} from './flags/ky.svg';
export {default as KZFlagSvg} from './flags/kz.svg';
export {default as LAFlagSvg} from './flags/la.svg';
export {default as LBFlagSvg} from './flags/lb.svg';
export {default as LCFlagSvg} from './flags/lc.svg';
export {default as LIFlagSvg} from './flags/li.svg';
export {default as LKFlagSvg} from './flags/lk.svg';
export {default as LRFlagSvg} from './flags/lr.svg';
export {default as LSFlagSvg} from './flags/ls.svg';
export {default as LTFlagSvg} from './flags/lt.svg';
export {default as LUFlagSvg} from './flags/lu.svg';
export {default as LVFlagSvg} from './flags/lv.svg';
export {default as LYFlagSvg} from './flags/ly.svg';
export {default as MAFlagSvg} from './flags/ma.svg';
export {default as MCFlagSvg} from './flags/mc.svg';
export {default as MDFlagSvg} from './flags/md.svg';
export {default as MEFlagSvg} from './flags/me.svg';
export {default as MFFlagSvg} from './flags/mf.svg';
export {default as MGFlagSvg} from './flags/mg.svg';
export {default as MHFlagSvg} from './flags/mh.svg';
export {default as MKFlagSvg} from './flags/mk.svg';
export {default as MLFlagSvg} from './flags/ml.svg';
export {default as MMFlagSvg} from './flags/mm.svg';
export {default as MNFlagSvg} from './flags/mn.svg';
export {default as MOFlagSvg} from './flags/mo.svg';
export {default as MPFlagSvg} from './flags/mp.svg';
export {default as MQFlagSvg} from './flags/mq.svg';
export {default as MRFlagSvg} from './flags/mr.svg';
export {default as MSFlagSvg} from './flags/ms.svg';
export {default as MTFlagSvg} from './flags/mt.svg';
export {default as MUFlagSvg} from './flags/mu.svg';
export {default as MVFlagSvg} from './flags/mv.svg';
export {default as MWFlagSvg} from './flags/mw.svg';
export {default as MXFlagSvg} from './flags/mx.svg';
export {default as MYFlagSvg} from './flags/my.svg';
export {default as MZFlagSvg} from './flags/mz.svg';
export {default as NAFlagSvg} from './flags/na.svg';
export {default as NCFlagSvg} from './flags/nc.svg';
export {default as NEFlagSvg} from './flags/ne.svg';
export {default as NFFlagSvg} from './flags/nf.svg';
export {default as NGFlagSvg} from './flags/ng.svg';
export {default as NIFlagSvg} from './flags/ni.svg';
export {default as NLFlagSvg} from './flags/nl.svg';
export {default as NOFlagSvg} from './flags/no.svg';
export {default as NPFlagSvg} from './flags/np.svg';
export {default as NRFlagSvg} from './flags/nr.svg';
export {default as NUFlagSvg} from './flags/nu.svg';
export {default as NZFlagSvg} from './flags/nz.svg';
export {default as OMFlagSvg} from './flags/om.svg';
export {default as PAFlagSvg} from './flags/pa.svg';
export {default as PEFlagSvg} from './flags/pe.svg';
export {default as PFFlagSvg} from './flags/pf.svg';
export {default as PGFlagSvg} from './flags/pg.svg';
export {default as PHFlagSvg} from './flags/ph.svg';
export {default as PKFlagSvg} from './flags/pk.svg';
export {default as PLFlagSvg} from './flags/pl.svg';
export {default as PMFlagSvg} from './flags/pm.svg';
export {default as PNFlagSvg} from './flags/pn.svg';
export {default as PRFlagSvg} from './flags/pr.svg';
export {default as PSFlagSvg} from './flags/ps.svg';
export {default as PTFlagSvg} from './flags/pt.svg';
export {default as PWFlagSvg} from './flags/pw.svg';
export {default as PYFlagSvg} from './flags/py.svg';
export {default as QAFlagSvg} from './flags/qa.svg';
export {default as REFlagSvg} from './flags/re.svg';
export {default as ROFlagSvg} from './flags/ro.svg';
export {default as RSFlagSvg} from './flags/rs.svg';
export {default as RUFlagSvg} from './flags/ru.svg';
export {default as RWFlagSvg} from './flags/rw.svg';
export {default as SAFlagSvg} from './flags/sa.svg';
export {default as SBFlagSvg} from './flags/sb.svg';
export {default as SCFlagSvg} from './flags/sc.svg';
export {default as SDFlagSvg} from './flags/sd.svg';
export {default as SEFlagSvg} from './flags/se.svg';
export {default as SGFlagSvg} from './flags/sg.svg';
export {default as SHFlagSvg} from './flags/sh.svg';
export {default as SIFlagSvg} from './flags/si.svg';
export {default as SJFlagSvg} from './flags/sj.svg';
export {default as SKFlagSvg} from './flags/sk.svg';
export {default as SLFlagSvg} from './flags/sl.svg';
export {default as SMFlagSvg} from './flags/sm.svg';
export {default as SNFlagSvg} from './flags/sn.svg';
export {default as SOFlagSvg} from './flags/so.svg';
export {default as SRFlagSvg} from './flags/sr.svg';
export {default as SSFlagSvg} from './flags/ss.svg';
export {default as STFlagSvg} from './flags/st.svg';
export {default as SVFlagSvg} from './flags/sv.svg';
export {default as SXFlagSvg} from './flags/sx.svg';
export {default as SYFlagSvg} from './flags/sy.svg';
export {default as SZFlagSvg} from './flags/sz.svg';
export {default as TCFlagSvg} from './flags/tc.svg';
export {default as TDFlagSvg} from './flags/td.svg';
export {default as TFFlagSvg} from './flags/tf.svg';
export {default as TGFlagSvg} from './flags/tg.svg';
export {default as THFlagSvg} from './flags/th.svg';
export {default as TJFlagSvg} from './flags/tj.svg';
export {default as TKFlagSvg} from './flags/tk.svg';
export {default as TLFlagSvg} from './flags/tl.svg';
export {default as TMFlagSvg} from './flags/tm.svg';
export {default as TNFlagSvg} from './flags/tn.svg';
export {default as TOFlagSvg} from './flags/to.svg';
export {default as TRFlagSvg} from './flags/tr.svg';
export {default as TTFlagSvg} from './flags/tt.svg';
export {default as TVFlagSvg} from './flags/tv.svg';
export {default as TWFlagSvg} from './flags/tw.svg';
export {default as TZFlagSvg} from './flags/tz.svg';
export {default as UAFlagSvg} from './flags/ua.svg';
export {default as UGFlagSvg} from './flags/ug.svg';
export {default as UMFlagSvg} from './flags/um.svg';
export {default as USFlagSvg} from './flags/us.svg';
export {default as UYFlagSvg} from './flags/uy.svg';
export {default as UZFlagSvg} from './flags/uz.svg';
export {default as VAFlagSvg} from './flags/va.svg';
export {default as VCFlagSvg} from './flags/vc.svg';
export {default as VEFlagSvg} from './flags/ve.svg';
export {default as VGFlagSvg} from './flags/vg.svg';
export {default as VIFlagSvg} from './flags/vi.svg';
export {default as VNFlagSvg} from './flags/vn.svg';
export {default as VUFlagSvg} from './flags/vu.svg';
export {default as WFFlagSvg} from './flags/wf.svg';
export {default as XKFlagSvg} from './flags/xk.svg';
export {default as WSFlagSvg} from './flags/ws.svg';
export {default as YEFlagSvg} from './flags/ye.svg';
export {default as YTFlagSvg} from './flags/yt.svg';
export {default as ZAFlagSvg} from './flags/za.svg';
export {default as ZMFlagSvg} from './flags/zm.svg';
export {default as ZWFlagSvg} from './flags/zw.svg';

export {default as MailSvg} from './mail.svg';
export {default as ExchangeSvg} from './exchange.svg';
export {default as BackspaceSymbolSvg} from '../colorless/backspace-symbol.svg';
export {default as FacebookSvg} from './facebook.svg';
export {default as GoogleSvg} from './google.svg';
export {default as TwitterSvg} from './twitter.svg';
export {default as IMacSvg} from './iMac.svg';
export {default as PhoneSvg} from './phone.svg';
export {default as HistorySvg} from './history.svg';
export {default as GooglePlaySvg} from './google-play.svg';
export {default as ExchangeAltSvg} from './walletActions/exchange.svg';
export {default as ReceiveSvg} from './walletActions/receive.svg';
export {default as BuySvg} from './walletActions/buy.svg';
export {default as SendSvg} from './walletActions/send.svg';
export {default as MoneroCircleSvg} from './currency/monero-circle.svg';
export {default as BitcoinCircleSvg} from './currency/bitcoin-circle.svg';
export {default as EthereumCircleSvg} from './currency/ethereum-circle.svg';
export {default as MaticPolygonCircleSvg} from './currency/matic-polygon-circle.svg';
export {default as ShibainuCircleSvg} from './currency/shibainu-circle.svg';
export {default as TetherCircleSvg} from './currency/tether-circle.svg';
export {default as BCHCircleSvg} from './currency/bch-circle.svg';
export {default as USDCircleSvg} from './currency/usdc-circle.svg';
export {default as BUSDCircleSvg} from './currency/busd-circle.svg';
export {default as WBTCCircleSvg} from './currency/wbtc-circle.svg';
export {default as FTTCircleSvg} from './currency/ftt-circle.svg';
export {default as LinkCircleSvg} from './currency/link-circle.svg';
export {default as DogeCircleSvg} from './currency/doge-circle.svg';
export {default as LtcCircleSvg} from './currency/ltc-circle.svg';
export {default as TrxCircleSvg} from './currency/trx-circle.svg';
export {default as PepeCircleSvg} from './currency/pepe-circle.svg';
export {default as UniCircleSvg} from './currency/uni-circle.svg';
export {default as DaiCircleSvg} from './currency/dai-circle.svg';
export {default as XRPCircleSvg} from './currency/xrp-circle.svg';
export {default as TonCircleSvg} from './currency/ton-circle.svg';
export {default as PolCircleSvg} from './currency/pol-circle.svg';
export {default as HashCoinCircleSvg} from './currency/hashcoin-circle.svg';
export const RoboxCircleSvg = withUniqueGradientIds(RoboxCircleSvg_);
export const ApeCircleSvg = withUniqueGradientIds(ApeCircleSvg_);
export const AAVECircleSvg = withUniqueGradientIds(AAVECircleSvg_);
export const SandCircleSvg = withUniqueGradientIds(SandCircleSvg_);
export const ManaCircleSvg = withUniqueGradientIds(ManaCircleSvg_);
export const WETHCircleSvg = withUniqueGradientIds(WETHCircleSvg_);
export const SOLCircleSVG = withUniqueGradientIds(SOLCircleSVG_);
export {default as QNTCircleSvg} from './currency/qnt-circle.svg';
export {default as AXSCircleSvg} from './currency/axs-circle.svg';
export {default as MaticCircleSvg} from './currency/matic-circle.svg';
export {default as LDOCircleSvg} from './currency/ldo-circle.svg';
export {default as CHZCircleSvg} from './currency/chz-circle.svg';
export {default as BNBCircleSvg} from './currency/bnb-circle.svg';
export {default as CTCCircleSvg} from './currency/ctc-circle.svg';
export {default as UsdCircleSvg} from './fiat/usd.svg';
export {default as EurCircleSvg} from './fiat/eur.svg';
export {default as RubCircleSvg} from './fiat/rub.svg';
export {default as KrwCircleSvg} from './fiat/krw.svg';
export {default as GbpCircleSvg} from './fiat/gbp.svg';
export {default as InrCircleSvg} from './fiat/inr.svg';
export {default as JpyCircleSvg} from './fiat/jpy.svg';
export {default as CnyCircleSvg} from './fiat/cny.svg';
export {default as AudCircleSvg} from './fiat/aud.svg';
export {default as CadCircleSvg} from './fiat/cad.svg';
export {default as ChfCircleSvg} from './fiat/chf.svg';
export {default as SekCircleSvg} from './fiat/sek.svg';
export {default as MxnCircleSvg} from './fiat/mxn.svg';
export {default as NzdCircleSvg} from './fiat/nzd.svg';
export {default as SgdCircleSvg} from './fiat/sgd.svg';
export {default as HkdCircleSvg} from './fiat/hkd.svg';
export {default as NokCircleSvg} from './fiat/nok.svg';
export {default as BrlCircleSvg} from './fiat/brl.svg';
export {default as ZarCircleSvg} from './fiat/zar.svg';
export {default as DdkCircleSvg} from './fiat/ddk.svg';
export {default as TwdCircleSvg} from './fiat/twd.svg';
export {default as MyrCircleSvg} from './fiat/myr.svg';
export {default as UnknownCircleSvg} from './fiat/unknown-fiat.svg';

export {default as AppleSvg} from './apple.svg';
export {default as CircledTrashSvg} from './trash.svg';
export {default as WalletPrivateSvg} from './wallet-private.svg';
export {default as WalletTradingSvg} from './wallet-trading.svg';
export {default as LightSpinnerSvg} from './light-spinner.svg';
export {default as HistorySmallSvg} from './history-small.svg';

export {default as NewspaperSvg} from './newspaper.svg';
export {default as OrangeCircleSvg} from './orange-circle.svg';
export {default as PcSvg} from './pc.svg';

export {default as CloseEye} from './close-eye.svg';
export {default as WarningSvg} from './Warning.svg';
export const BucketSvg = withUniqueGradientIds(BucketSvg_);
export {default as CircleStatisticSvg} from './circle-statistic.svg';
export {default as InSvg} from './affiliateSocial/in.svg';
export {default as PtSvg} from './affiliateSocial/pt.svg';
export {default as RdSvg} from './affiliateSocial/rd.svg';
export {default as TgSvg} from './affiliateSocial/tg.svg';
export {default as TwSvg} from './affiliateSocial/tw.svg';
export {default as VkSvg} from './affiliateSocial/vk.svg';
export {default as WaSvg} from './affiliateSocial/wa.svg';
export {default as UserCardSvg} from './user-card.svg';
export {default as UserCardDarkSvg} from './user-card-dark.svg';
export {default as CloseAppSvg} from './close-app.svg';
export {default as CloseAppDarkSvg} from './close-app-dark.svg';
export {default as TransactionReportSvg} from './transaction-report.svg';
