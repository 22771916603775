import type {BaseAsyncOptions} from '../Async';
import type {
  CancellationError,
  GlobalError,
  KeyValueStoreDeleteError,
  KeyValueStoreGetError,
  KeyValueStoreSetError,
} from '../Error';
import {
  CANCELLATION_ERROR,
  KEY_VALUE_STORE_DELETE_ERROR,
  KEY_VALUE_STORE_GET_ERROR,
  KEY_VALUE_STORE_SET_ERROR,
} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {failure, success} from '../fp';
import type {AbstractKeyValueMap, KeyValueStore} from './KeyValueStore';

export default class KeyValueStoreImpl<
  KV extends AbstractKeyValueMap = AbstractKeyValueMap,
> implements KeyValueStore<KV>
{
  constructor(
    private readonly _root: {readonly errorRepository: ErrorRepository},
  ) {}

  async get<K extends keyof KV>(
    key: K,
    options?: BaseAsyncOptions,
  ): Promise<Either<KV[K] | undefined, GlobalError>> {
    if (options?.signal?.aborted) {
      return failure(this._createCancellationError(options.signal.reason));
    }
    try {
      const value = localStorage.getItem(String(key));
      return success((value ?? undefined) as KV[K] | undefined);
    } catch (raw) {
      return failure(
        this._root.errorRepository.create<KeyValueStoreGetError>({
          kind: KEY_VALUE_STORE_GET_ERROR,
          raw,
        }),
      );
    }
  }

  async set<K extends keyof KV>(
    key: K,
    value: KV[K],
    options?: BaseAsyncOptions,
  ): Promise<Either<void, GlobalError>> {
    if (options?.signal?.aborted) {
      return failure(this._createCancellationError(options.signal.reason));
    }
    try {
      localStorage.setItem(String(key), value);
      return success();
    } catch (raw) {
      return failure(
        this._root.errorRepository.create<KeyValueStoreSetError>({
          kind: KEY_VALUE_STORE_SET_ERROR,
          raw,
        }),
      );
    }
  }

  async delete(
    key: keyof KV,
    options?: BaseAsyncOptions,
  ): Promise<Either<void, GlobalError>> {
    if (options?.signal?.aborted) {
      return failure(this._createCancellationError(options.signal.reason));
    }
    try {
      localStorage.removeItem(String(key));
      return success();
    } catch (raw) {
      return failure(
        this._root.errorRepository.create<KeyValueStoreDeleteError>({
          kind: KEY_VALUE_STORE_DELETE_ERROR,
          raw,
        }),
      );
    }
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }
}
