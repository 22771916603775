import {computed, makeObservable} from 'mobx';

import {LG_BREAKPOINT} from '../Layout';
import type {WindowDimensionsState} from '../WindowDimensions';
import type {LayoutHelperState} from './LayoutHelperState';

export default class LayoutHelperStateImpl implements LayoutHelperState {
  constructor(
    private readonly _root: {
      readonly windowDimensionsState: WindowDimensionsState;
    },
  ) {
    makeObservable(this);
  }

  @computed get isLarge() {
    return this._root.windowDimensionsState.window.width >= LG_BREAKPOINT;
  }
}
