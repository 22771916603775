/* eslint-disable @typescript-eslint/no-deprecated */
import {useTheme, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import type {StyleProp, TextInput, ViewStyle} from 'react-native';
import {View} from 'react-native';

import {SearchSvg} from '../../assets/svg/colorless';
import {Input, InputType} from './Input';

export type SearchHeaderProps = {
  getValue?: () => string | undefined;
  /**
   * @deprecated
   */
  value?: string;
  placeholder: string;
  onValueChange?: (value: string) => void;
  onSubmit: (search: string) => void;
  style?: StyleProp<ViewStyle>;
};

export const SearchHeader = observer((props: SearchHeaderProps) => {
  const {onValueChange} = props;
  const ref = useRef<TextInput>(null);
  const theme = useTheme();

  const SearchIcon = useCallback(() => {
    return <SearchSvg color={theme.palette.primary} />;
  }, [theme]);

  const handleOnChange = useCallback(
    (_: string) => onValueChange?.(_),
    [onValueChange],
  );

  return (
    <Container style={props.style}>
      <Input
        ref={ref}
        type={InputType.Default}
        Icon={SearchIcon}
        iconBackgroundShown={false}
        placeholder={props.placeholder}
        value={props.getValue?.() ?? props.value}
        onChangeText={handleOnChange}
      />
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.primary,
    padding: 15,
  },
}));
