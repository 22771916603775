import type {
  CryptoCurrencyCode,
  CurrencyStore,
  DecimalString,
  JsonRpcClient,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  Wallet,
  WalletId,
  WalletStore,
} from '@ncwallet-app/core';
import {
  getMaxAmountForWithdrawal,
  isFreeWithdrawalAvailable,
  isMinFeeMoreThanAvailableMax,
  maxAmountRestrictedByLimit,
} from '@ncwallet-app/core';
import type {FlashMessage} from '@ncwallet-app/core/src/FlashMessage';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import type {
  WalletsWithdrawalsEstimatedFeeOptions,
  WithdrawalFee,
} from '@ncwallet-app/core/src/NCWalletServer/WalletsWithdrawalsEstimatedFee';
import {getMinFee} from '@ncwallet-app/core/src/NCWalletServer/WalletsWithdrawalsEstimatedFee';
import {BigNumber} from 'bignumber.js';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import type {SendAmountValidator} from '../../../../CommonNavigationContainers/hooks/PromptOutputAddressBindingState/SendAmountValidator';
import type {WalletLimitHelper} from '../../../../CommonNavigationContainers/hooks/usePromptExchangeReceiptContainer/WalletLimitHelper';

// eslint-disable-next-line import-x/prefer-default-export
export class PromptAmountToSendBindingState {
  @observable fees: WithdrawalFee[] | undefined;
  @observable private _currency: CryptoCurrencyCode | undefined;
  @observable private _walletId: WalletId | undefined;
  @observable private _minFreeWithdrawAmount: string | undefined;
  @observable private _addressNetwork: AddressNetwork | undefined;
  @observable private _addressCurrency: CryptoCurrencyCode | undefined;

  @computed get crypto() {
    return (
      this._currency &&
      this._root.currencyStore.getCryptoCurrency(this._currency)
    );
  }

  @computed get wallet(): Wallet | undefined {
    return (
      this._walletId && this._root.walletStore.getWalletById(this._walletId)
    );
  }

  @computed get min(): DecimalString {
    return this.crypto?.options.min_withdrawal_amount ?? '0';
  }

  @computed get isFreeWithdrawalUnavailable() {
    return (
      (this._minFreeWithdrawAmount &&
        BigNumber(this._minFreeWithdrawAmount).isZero()) ||
      (this.crypto ? !isFreeWithdrawalAvailable(this.crypto) : false)
    );
  }

  @computed get minFee() {
    if (
      !this.crypto ||
      !!this._minFreeWithdrawAmount ||
      isFreeWithdrawalAvailable(this.crypto) ||
      !this.fees
    ) {
      return undefined;
    }
    return getMinFee(this.fees);
  }

  @computed get max() {
    return (
      this.crypto &&
      this.wallet &&
      getMaxAmountForWithdrawal(this.wallet, this._remainingLimit, this.minFee)
    );
  }

  @computed get isMinFeeMoreThanAvailableMax() {
    if (!(this.crypto && this.wallet)) {
      return false;
    }

    return isMinFeeMoreThanAvailableMax(
      this.wallet,
      this._remainingLimit,
      this.minFee,
    );
  }

  @computed get maxRestrictedByWalletLimit() {
    return (
      !this.wallet ||
      maxAmountRestrictedByLimit(this.wallet, this._remainingLimit)
    );
  }

  @computed
  private get _remainingLimit() {
    return (
      this.wallet &&
      this._walletLimitHelper.getCurrentRemainingLimit(this.wallet.id)
    );
  }

  @computed get addressNetwork(): AddressNetwork {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._addressNetwork!;
  }

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
      readonly flashMessage: FlashMessage;
    },
    private readonly _walletLimitHelper: WalletLimitHelper,
    private readonly _amountValidator: SendAmountValidator,
  ) {
    makeObservable(this);
  }

  @action.bound
  async refresh(
    currency: CryptoCurrencyCode,
    walletId: WalletId,
    addressNetwork: AddressNetwork,
    addressCurrency: CryptoCurrencyCode,
    addressTo: string,
    amount?: DecimalString,
    minFreeWithdrawAmount?: string,
  ) {
    this._currency = currency;
    this._walletId = walletId;
    this._minFreeWithdrawAmount = minFreeWithdrawAmount;
    this._addressNetwork = addressNetwork;
    this._addressCurrency = addressCurrency;
    void this._root.currencyStore.refreshCryptoCurrencies();
    void this._walletLimitHelper.refresh();

    if (
      this._addressNetwork === 'undefined' ||
      !this._root.currencyStore
        .getCryptoCurrency(this._addressCurrency)
        ?.options.currencies_out.find(co => co.network === this._addressNetwork)
    ) {
      this._addressNetwork = this._root.currencyStore.getCryptoCurrency(
        this._addressCurrency,
      )?.options.default_network;

      if (addressNetwork !== 'undefined') {
        this._root.flashMessage.showMessage({
          title: 'error.cantFindNetwork',
          variant: 'danger',
        });
      }
    }

    void this._refreshFeesForMaxAmountCalculation(
      addressNetwork,
      addressCurrency,
      {
        address_to: addressTo,
        amount: amount ?? null,
      },
    );
  }

  validate(amount: DecimalString | undefined) {
    if (!(this.crypto && this.wallet && this.fees)) {
      return undefined;
    }

    return this._amountValidator.validate(
      amount,
      this.min,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.max!,
      !!this.minFee && BigNumber(this.minFee).isGreaterThan(0),
      !!this._remainingLimit,
    );
  }

  private async _refreshFeesForMaxAmountCalculation(
    addressNetwork: AddressNetwork,
    addressCurrency: CryptoCurrencyCode,
    options: WalletsWithdrawalsEstimatedFeeOptions,
  ) {
    const feesRes = await this._root.ncWalletJsonRpcClient.call(
      'wallets.withdrawals.estimated_fee',
      {
        currency: addressCurrency,
        network: addressNetwork,
        options,
      },
    );
    if (feesRes.success) {
      runInAction(() => {
        this.fees = feesRes.right.fees;
      });
    }
  }
}
