import type {WalletLimit} from '@ncwallet-app/core';
import {formatCryptoValue, useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {useDiffFormattedTime} from './useDiffFormattedTime';

export type LimitNewInfoProps = {
  title: string;
  limit: WalletLimit;
  onCancel?: () => void;
};

export default observer(function LimitNewInfo(props: LimitNewInfoProps) {
  const {title, limit, onCancel} = props;
  const strings = useStrings();
  const formattedTime = useDiffFormattedTime(limit.activated_at);

  return (
    <LimitNewInfoContainer>
      <LimitNewInfoLabel>{title}</LimitNewInfoLabel>
      <LimitNewInfoValue>{formatCryptoValue(limit.limit)}</LimitNewInfoValue>
      <LimitApplyTime>
        {strings['limitsSettingsScreen.newLimit.applyIn']} {formattedTime}
      </LimitApplyTime>
      <LimitAction
        disabled={!onCancel}
        variant={ButtonVariant.Danger}
        title={strings['currencyLimitScreen.cancleChanges']}
        onPress={onCancel}
      />
    </LimitNewInfoContainer>
  );
});

const LimitNewInfoContainer = variance(View)(() => ({
  root: {
    paddingTop: 15,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
}));

const LimitNewInfoLabel = variance(Text)(theme => ({
  root: {
    marginBottom: 10,
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 16,
    textAlign: 'center',
  },
}));

const LimitNewInfoValue = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 18,
    color: theme.palette.textAdditional3,
    textAlign: 'center',
  },
}));

const LimitAction = variance(Button)(() => ({
  root: {
    marginTop: 20,
  },
}));

const LimitApplyTime = variance(Text)(theme => ({
  root: {
    marginTop: 5,
    ...theme.fontByWeight('500'),
    color: theme.palette.info,
    textAlign: 'center',
    fontSize: 13,
  },
}));
