import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ListNotificationSettingsContainer from '../../../CommonNavigationContainers/containers/ListNotificationSettingsContainer';
import {useNavigationGetIsTransitioning} from '../../../Navigation/hooks';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ListNotificationSettingsBindingProps =
  SmallHomeStackBindingProps<'ListNotificationSettings'>;

export default observer(function ListNotificationSettingsBinding(
  props: ListNotificationSettingsBindingProps,
) {
  const {navigation} = props;
  const getIsTransitioning = useNavigationGetIsTransitioning(navigation);

  const handleNavigateToLinkTelegram = useCallback(() => {
    navigation.navigate('PromptLinkToTelegram');
  }, [navigation]);

  return (
    <ListNotificationSettingsContainer
      getIsTransitioning={getIsTransitioning}
      goToLinkTelegram={handleNavigateToLinkTelegram}
    />
  );
});
