import {BigNumber} from 'bignumber.js';

import type {DecimalString} from './DecimalString';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class MoneyStatic {
  static convert(source: DecimalString, rate: DecimalString) {
    return BigNumber(source).multipliedBy(rate).toString();
  }
}
