import {variance} from '@ncwallet-app/core';
import {Pressable} from '@ncwallet-app/ui';

export default variance(Pressable)(theme => ({
  root: {
    position: 'relative',
    width: 44,
    height: 44,
    borderRadius: 8,
    borderWidth: 3,
    borderColor: theme.palette.uiPrimary,
    backgroundColor: theme.palette.uiAdditional2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  active: {
    backgroundColor: theme.palette.uiPrimary,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));
