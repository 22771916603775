import {useStyles, variance} from '@ncwallet-app/core';
import type {FC} from 'react';
import React from 'react';
import {Platform, View} from 'react-native';

import {
  AudCircleSvg,
  BrlCircleSvg,
  CadCircleSvg,
  ChfCircleSvg,
  CnyCircleSvg,
  DdkCircleSvg,
  EurCircleSvg,
  GbpCircleSvg,
  HkdCircleSvg,
  InrCircleSvg,
  JpyCircleSvg,
  KrwCircleSvg,
  MxnCircleSvg,
  MyrCircleSvg,
  NokCircleSvg,
  NzdCircleSvg,
  RubCircleSvg,
  SekCircleSvg,
  SgdCircleSvg,
  TwdCircleSvg,
  UnknownCircleSvg,
  UsdCircleSvg,
  ZarCircleSvg,
} from '../../assets/svg/colored';
import {LG_BREAKPOINT} from '../grid';

export type FiatCircleLogoProps = {
  code: string;
  size: number;
  active: boolean;
};

const fiatLogoMap = {
  ['USD']: UsdCircleSvg,
  ['EUR']: EurCircleSvg,
  ['RUB']: RubCircleSvg,
  ['CNY']: CnyCircleSvg,
  ['GBP']: GbpCircleSvg,
  ['INR']: InrCircleSvg,
  ['JPY']: JpyCircleSvg,
  ['KRW']: KrwCircleSvg,
  ['AUD']: AudCircleSvg,
  ['CAD']: CadCircleSvg,
  ['CHF']: ChfCircleSvg,
  ['SEK']: SekCircleSvg,
  ['MXN']: MxnCircleSvg,
  ['NZD']: NzdCircleSvg,
  ['SGD']: SgdCircleSvg,
  ['HKD']: HkdCircleSvg,
  ['NOK']: NokCircleSvg,
  ['BRL']: BrlCircleSvg,
  ['ZAR']: ZarCircleSvg,
  ['DDK']: DdkCircleSvg,
  ['TWD']: TwdCircleSvg,
  ['MYR']: MyrCircleSvg,
  ['UNKNOWN']: UnknownCircleSvg,
};

export const FiatCircleLogo: FC<FiatCircleLogoProps> = props => {
  const fiatCode = props.code as keyof typeof fiatLogoMap;
  const FiatLogo =
    fiatCode in fiatLogoMap ? fiatLogoMap[fiatCode] : fiatLogoMap.UNKNOWN;
  const styles = useMyStyles(props.size);
  return (
    <Circle
      active={props.active}
      style={{width: props.size, height: props.size}}>
      <FiatLogo style={styles.icon} />
    </Circle>
  );
};

const Circle = variance(View)(theme => ({
  root: {
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.uiMain,
  },
  active: {
    backgroundColor: theme.palette.primary,
  },
}));

const useMyStyles = (height: number) =>
  useStyles(theme => ({
    icon: {
      width: 15,
      height: height - 20,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          ...Platform.select({
            web: {
              width: 'auto',
            },
          }),
          height: height - 10,
        },
      }),
    },
  }));
