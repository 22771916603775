import type {BaseError} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {action, observable} from 'mobx';
import {useCallback, useMemo, useState} from 'react';

export default function useBaseErrorDescription() {
  const root = useRoot();
  const [errorBox] = useState(() => observable.box<string | undefined>());
  const setError = useMemo(
    () =>
      action((error: BaseError) => {
        const {summary} = root.errorParser.describe(error);
        errorBox.set(summary);
      }),
    [errorBox, root.errorParser],
  );
  const getError = useCallback(() => errorBox.get(), [errorBox]);
  const resetError = useCallback(() => {
    errorBox.set(undefined);
  }, [errorBox]);

  return [getError, setError, resetError] as const;
}
