import {useStrings, variance} from '@ncwallet-app/core';
import type {AddressType} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type AddressBottomSheetListProps = {
  defaultType: AddressType | undefined;
  addressTypes: AddressType[];
  onPress: (type: AddressType) => void;
};

export default observer(function AddressBottomSheetList({
  defaultType,
  addressTypes,
  onPress,
}: AddressBottomSheetListProps) {
  const strings = useStrings();
  return (
    <>
      {addressTypes.map((type, index) => (
        <AddressBottomSheetItem
          key={index}
          onPress={() => {
            onPress(type);
          }}
          noBorder={index === addressTypes.length - 1}>
          <AddressBottomSheetItemText>{type}</AddressBottomSheetItemText>
          {!isNil(defaultType) && type === defaultType && (
            <TagWrapper>
              <Tag>{strings['lgAddressTypeScreen.recommended']}</Tag>
            </TagWrapper>
          )}
        </AddressBottomSheetItem>
      ))}
    </>
  );
});

const AddressBottomSheetItem = variance(TouchableOpacity)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 20,
    borderBottomColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  noBorder: {
    borderBottomWidth: 0,
  },
}));

const AddressBottomSheetItemText = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 19,
  },
}));

const TagWrapper = variance(View)(theme => ({
  root: {
    paddingHorizontal: 5,
    borderWidth: 1,
    borderColor: theme.palette.primary,
    borderRadius: 20,
    marginLeft: 15,
    marginRight: 'auto',
  },
}));

const Tag = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 11,
    lineHeight: 15,
    color: theme.palette.primary,
  },
}));
