import {action, createAtom, makeObservable, observable} from 'mobx';

import type {GlobalError, NetworkError} from '../Error';
import {NETWORK_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Service} from '../structure';
import type {Connection, ConnectionState} from './Connection';
import {ConnectionStatus} from './Connection';

export default class ConnectionStateService
  implements ConnectionState, Service
{
  private readonly _reportId = () => {
    this._id.reportChanged();
  };
  private readonly _id = createAtom(
    'ConnectionState#id',
    () =>
      this._root.connection.status.listen(
        ConnectionStatus.Connecting,
        this._reportId,
      ),
    () => {
      this._root.connection.status.forget(
        ConnectionStatus.Connecting,
        this._reportId,
      );
    },
  );
  private readonly _reportStatus = () => {
    this._latestStatus.reportChanged();
  };
  private readonly _latestStatus = createAtom(
    'ConnectionState#latestStatus',
    () => this._root.connection.status.domain.listen(this._reportStatus),
    () => {
      this._root.connection.status.domain.forget(this._reportStatus);
    },
  );
  @observable.ref private _latestError?: GlobalError;

  constructor(
    private readonly _root: {
      readonly connection: Connection;
      readonly errorRepository: ErrorRepository;
    },
  ) {
    makeObservable(this);
  }

  get id() {
    this._id.reportObserved();
    return this._root.connection.getId();
  }

  get latestStatus() {
    this._latestStatus.reportObserved();
    return this._root.connection.getStatus();
  }

  get latestError() {
    return this._latestError;
  }

  subscribe() {
    return this._root.connection.status.listen(
      ConnectionStatus.Closed,
      action(report => {
        this._latestError = this._root.errorRepository.create<NetworkError>({
          kind: NETWORK_ERROR,
          description: `Connection was closed ${
            report.wasClean ? 'cleanly' : 'dirtily'
          }: ${report.code ?? '???'} ${report.reason ?? '???'}`,
        });
      }),
    );
  }
}
