export const SENDER_ID = '198164349577';

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyChljmB4pHg9zMI_eNtIFW2LSt6BF20r6A',
  authDomain: 'nc-wallet.firebaseapp.com',
  projectId: 'nc-wallet',
  storageBucket: 'nc-wallet.appspot.com',
  messagingSenderId: SENDER_ID,
  appId: '1:198164349577:web:4144dca0529113fcf2fc58',
  measurementId: 'G-M9YM86D9WH',
} as const;

export const VAPID_KEY =
  'BBhXzTgRfK1tpDAdePMo01dh6mSOI-8fGLCFVAJdbLCUpYU3r9VkaLAjZ4rCkSVtnptKIlBYgp4s2GDLn-VpPKk';
