import type {PinCode} from '@ncwallet-app/core';
import {urlLinks, useStrings, useTheme, variance} from '@ncwallet-app/core';
import {LoadingIndicatorStatus} from '@ncwallet-app/core/src/LoadingIndicator';
import {Button, ButtonVariant, Loader, Switch} from '@ncwallet-app/ui/src';
import {InfoSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Linking, Platform, Pressable, Text, View} from 'react-native';

import type {PinScreenTexts} from '../PinScreen/PinScreenTexts';
import {
  BodyTag,
  HeaderTag,
  HeaderTitleTag,
  InfoTextTag,
  LgProfileCardContainer,
} from '../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import CheckPin from './CheckPin';
import type {TwoFaCardSetCodeProps} from './TwoFaCardSetCode';
import TwoFaCardSetCode from './TwoFaCardSetCode';
import UpdatePinCard from './UpdatePinCard';

export type PinCardProps = {
  pinCardStatus: LgPinCardStatus;
  onPinSuccess: (pin: PinCode) => void;
  pinCardTexts: {
    create: PinScreenTexts;
    confirm: PinScreenTexts;
  };
  checkPin: () => void;
  getPinErrorShown: () => boolean;
  pinTexts: PinScreenTexts;
  getPin: () => string;
  setPin: (value: string) => void;
  getBiometryPinErrorShown: () => boolean;
  biometryPinTexts: PinScreenTexts;
  getBiometryPin: () => string;
  setBiometryPin: (value: string) => void;
  onBiometricPress: () => void;
  getIsActive?: () => boolean;
  onErrorActionPress?: () => void;
  getIsTransactionActive?: () => boolean;
  onTransactionPress: () => void;
  twoFaProps: TwoFaCardSetCodeProps;
};

export default observer(function PinCard(props: PinCardProps) {
  const {pinCardStatus} = props;
  const strings = useStrings();
  const theme = useTheme();
  const isWeb = Platform.OS === 'web';
  const openUrl = useCallback((url: string) => Linking.openURL(url), []);
  return (
    <LgProfileCardContainer horizontalOffset>
      <HeaderTag>
        <HeaderTitleTag>
          {strings['lgSecureSettingsScreen.pinCardTitle']}
        </HeaderTitleTag>
      </HeaderTag>
      <BodyTag>
        {(pinCardStatus === LgPinCardStatus.Start ||
          pinCardStatus === LgPinCardStatus.Ok) && (
          <>
            <ButtonLabel>{strings['biometricSettings.title']}</ButtonLabel>
            <InfoTextTag withTop withBottom danger={isWeb}>
              {isWeb
                ? strings['biometricsError.notAvailiableOnBrowser']
                : strings['biometricSettings.choose']}
            </InfoTextTag>
            <Biometric>
              <BiometricRow withBorderBottom>
                <BiometricLabel>
                  {strings['biometricSettings.login']}
                </BiometricLabel>
                <Info onPress={() => openUrl(urlLinks.biometricsLogFaq)}>
                  <InfoSvg color={theme.palette.uiAdditional1} />
                </Info>
                <Switch
                  disabled={isWeb}
                  getIsActive={props.getIsActive}
                  onPress={props.onBiometricPress}
                />
              </BiometricRow>
              <BiometricRow>
                <BiometricLabel>
                  {strings['biometricSettings.transaction']}
                </BiometricLabel>
                <Info
                  onPress={() => openUrl(urlLinks.biometricsTransactionFaq)}>
                  <InfoSvg color={theme.palette.uiAdditional1} />
                </Info>
                <Switch
                  disabled={isWeb}
                  getIsActive={props.getIsTransactionActive}
                  onPress={props.onTransactionPress}
                />
              </BiometricRow>
            </Biometric>
            <ButtonLabel>
              {strings['lgSecureSettingsScreen.pinCardButtonLabel']}
            </ButtonLabel>
            <PinButton
              title={strings['lgSecureSettingsScreen.pinCardButton']}
              onPress={props.checkPin}
              variant={ButtonVariant.Primary}
              testID="change-pin-btn"
            />
            <InfoTextTag withTop>
              {strings['lgSecureSettingsScreen.pinCardSubtitle']}
            </InfoTextTag>
          </>
        )}
        {pinCardStatus === LgPinCardStatus.Check && (
          <CheckPin
            getErrorShown={props.getPinErrorShown}
            texts={props.pinTexts}
            {...props}
          />
        )}
        {pinCardStatus === LgPinCardStatus.OtpBiometrics && (
          <TwoFaCardSetCode {...props.twoFaProps} />
        )}
        {pinCardStatus === LgPinCardStatus.CheckBiometrics && (
          <CheckPin
            getErrorShown={props.getPinErrorShown}
            texts={props.biometryPinTexts}
            setPin={props.setBiometryPin}
            getPin={props.getBiometryPin}
            onErrorActionPress={props.onErrorActionPress}
          />
        )}
        {pinCardStatus === LgPinCardStatus.Update && (
          <UpdatePinCard
            {...props}
            smallText={true}
            onSuccess={props.onPinSuccess}
            screenTexts={props.pinCardTexts}
          />
        )}
        {pinCardStatus === LgPinCardStatus.Load && (
          <Loader loadingStatus={LoadingIndicatorStatus.Active} />
        )}
      </BodyTag>
    </LgProfileCardContainer>
  );
});

const PinButton = variance(Button)(() => ({
  root: {
    marginTop: 25,
  },
}));

const Biometric = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.uiPrimary,
    borderColor: theme.palette.uiSecondary,
    borderWidth: 1,
    borderRadius: 8,
    marginBottom: 25,
  },
}));

const BiometricRow = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  withBorderBottom: {
    borderBottomColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
  },
}));

const Info = variance(Pressable)(() => ({
  root: {
    marginLeft: 5,
    marginRight: 'auto',
  },
}));

const BiometricLabel = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('600'),
    fontSize: 16,
    lineHeight: 19,
    color: theme.palette.textPrimary,
  },
}));

const ButtonLabel = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 15,
    lineHeight: 20,
    color: theme.palette.textMain,
  },
}));

export enum LgPinCardStatus {
  Start,
  Check,
  Update,
  Ok,
  CheckBiometrics,
  OtpBiometrics,
  Load,
}
