import {
  PROMPT_LANGUAGE_ROUTE,
  PROMPT_THEME_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import {useIsHeaderModalsFocused} from '../../hooks/useIsHeaderModalsFocused';
import type {ScreenHeaderWideProps} from '../../hooks/useScreenHeaderWideProps';
import HeaderProfileMenu from '../HeaderProfileMenu';
import NotificationsMenu from '../WalletLayout/NotificationsMenu';
import Overlay from './Overlay';

export type HeaderModalsProps = ScreenHeaderWideProps & {
  hideProfileMenu?: boolean;
};

export default observer(function HeaderModals(props: HeaderModalsProps) {
  const {
    onAffiliatePress,
    onNotificationSettings,
    onLogoutPress,
    onRatePress,
    onHelpPress,
    onSecurityPress,
    hideProfileMenu,
    onProfileSettings,
    focusedMenu,
    onLimitsPress,
    toggleMenu,
  } = props;
  const {
    areNotificationsVisible,
    isMenuVisible,
    isThemeModalFocused,
    isLanguageModalFocused,
  } = useIsHeaderModalsFocused(focusedMenu);
  const onClose = useCallback(() => {
    toggleMenu();
  }, [toggleMenu]);
  const toggleThemeMenu = useCallback(() => {
    toggleMenu(PROMPT_THEME_ROUTE);
  }, [toggleMenu]);
  const visible = useMemo(
    () => (isMenuVisible && !hideProfileMenu) || areNotificationsVisible,
    [isMenuVisible, hideProfileMenu, areNotificationsVisible],
  );
  const toggleLanguageMenu = useCallback(() => {
    toggleMenu(PROMPT_LANGUAGE_ROUTE);
  }, [toggleMenu]);

  if (!visible) {
    return null;
  }

  return (
    <Overlay onClose={onClose}>
      {areNotificationsVisible && (
        <NotificationsMenu onNotificationSettings={onNotificationSettings} />
      )}
      {isMenuVisible && !hideProfileMenu && (
        <HeaderProfileMenu
          onAffiliatePress={onAffiliatePress}
          isLanguageModalFocused={isLanguageModalFocused}
          isThemeModalFocused={isThemeModalFocused}
          toggleThemeMenu={toggleThemeMenu}
          toggleLanguageMenu={toggleLanguageMenu}
          onNotificationSettings={onNotificationSettings}
          onLogoutPress={onLogoutPress}
          onRatePress={onRatePress}
          onHelpPress={onHelpPress}
          onSecurityPress={onSecurityPress}
          onProfileSettings={onProfileSettings}
          onLimitPress={onLimitsPress}
        />
      )}
    </Overlay>
  );
});
