import type {SessionId} from '@ncwallet-app/core/src/NCWalletServer/sessions/Session';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useActiveSessionsBindingState} from '../../../Navigation/HomeStack/ActiveSessionsBinding/useActiveSessionsBindingState';
import {ActiveSessionsScreen} from '../../../screens/ActiveSessionsScreen';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ListActiveSessionsBindingProps =
  SmallHomeStackBindingProps<'ListActiveSessions'>;

export default observer(function ListActiveSessionsBinding(
  props: ListActiveSessionsBindingProps,
) {
  const {navigation, route} = props;

  const state = useActiveSessionsBindingState();

  const selectedSessionId =
    route.params?.promptConfirmationToEndSession?.sessionId;
  const listActiveSessions = useCallback(() => {
    navigation.setParams({promptConfirmationToEndSession: undefined});
  }, [navigation]);
  const promptConfirmationToEndSession = useCallback(
    (id: SessionId) => {
      // navigation.setParams({promptConfirmationToEndSession: {sessionId: id}});
      navigation.navigate('PromptSessionDeletion', {sessionId: id});
    },
    [navigation],
  );

  return (
    <ActiveSessionsScreen
      other={state.other}
      current={state.current}
      onSessionDelete={state.delete}
      selectedSessionId={selectedSessionId}
      listActiveSessions={listActiveSessions}
      promptConfirmationToEndSession={promptConfirmationToEndSession}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});
