// eslint-disable-next-line import-x/no-anonymous-default-export
export default (
  value: number,
  length: number,
  zeroBar: number,
): [number, number, number[]] => {
  if (value > 0) {
    return [0, value * 2, new Array<number>(length).fill(0.5)];
  } else if (value < 0) {
    return [value * 2, 0, new Array<number>(length).fill(0.5)];
  }
  return [0, zeroBar, new Array<number>(length).fill(0)];
};
