import type {
  CryptoCurrency,
  CryptoCurrencyCode,
  CurrenciesRateHistoryStore,
  CurrenciesRateStore,
  CurrencyStore,
  FiatCurrencyCode,
  JsonRpcClient,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  OnTimeoutError,
  Service,
  Wallet,
  WalletStore,
} from '@ncwallet-app/core';
import {
  getHistoryItemChange,
  RateHistoryPeriod,
  success,
  TIMEOUT_ERROR,
  toCurrencyDescriptionFromCrypto,
  toCurrencyDescriptionFromFiat,
  toLineChartDatum,
} from '@ncwallet-app/core';
import {getHistoryFromResponse} from '@ncwallet-app/core/src/dataStores/CurrenciesRateHistoryStore/AcceptedRateHistoryResponse';
import type {FlashMessage} from '@ncwallet-app/core/src/FlashMessage';
// eslint-disable-next-line import-x/no-deprecated
import type {LegacyLocalization} from '@ncwallet-app/core/src/Localization';
import type {FiatCurrency} from '@ncwallet-app/core/src/NCWalletServer/FiatCurrency';
import type {RPCTimeoutErrorVisibility} from '@ncwallet-app/core/src/RPCTimeoutErrorVisibility';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {isEmpty} from 'lodash';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import type {CurrencyHistoryRefresher} from '../../../shared/CurrencyHistoryRefresher';

// eslint-disable-next-line import-x/prefer-default-export
export class SelectionForAddBindingState implements Service, OnTimeoutError {
  @observable private _baseFiat: FiatCurrencyCode | undefined;

  @observable private _isLoading = false;
  @observable private _isSubmitting = false;
  @observable private _isTimeoutError = false;

  @computed
  get isTimeoutError() {
    return this._isTimeoutError;
  }

  @computed get currencies(): CurrencySelectionListItemData[] | undefined {
    const wallets = this._root.walletStore.getWallets();
    const currencies = this._root.currencyStore.getCryptoCurrencies();
    if (!this._baseFiat || wallets === undefined || currencies === undefined) {
      return;
    }
    const usedCurrencies = this.getUsedCurrencies(currencies, wallets);
    const baseFiat = this._root.currencyStore.getFiatCurrency(this._baseFiat);
    const isAllRatesLoaded = usedCurrencies.every(c =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._root.currenciesRateStore.getRate(c.code, this._baseFiat!),
    );

    if (!baseFiat || (!isAllRatesLoaded && wallets.length > 0)) {
      return;
    }

    return usedCurrencies.map(c =>
      this.toCurrencySelectionListItemData(baseFiat, c),
    );
  }

  constructor(
    protected readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
      readonly currenciesRateStore: CurrenciesRateStore;
      readonly currenciesRateHistoryStore: CurrenciesRateHistoryStore;
      readonly flashMessage: FlashMessage;
      // eslint-disable-next-line @typescript-eslint/no-deprecated,import-x/no-deprecated
      readonly translation: LegacyLocalization;
      readonly rpcTimeoutErrorVisibility: RPCTimeoutErrorVisibility;
    },
    private readonly historyRefresher: CurrencyHistoryRefresher,
  ) {
    makeObservable(this);
  }

  getCurrencies = () => this.currencies;

  isLoading = () => this._isLoading;
  isSubmitting = () => this._isSubmitting;

  @action.bound
  async refresh(baseFiat: FiatCurrencyCode) {
    this._isTimeoutError = false;
    this._baseFiat = baseFiat;

    this._isLoading = true;
    const [fiatsRes, cryptosRes, walletsRes] = await Promise.all([
      this._root.currencyStore.refreshFiatCurrencies(),
      this._root.currencyStore.refreshCryptoCurrencies(),
      this._root.walletStore.refreshWallets(),
    ]);

    runInAction(() => {
      this._isTimeoutError = [fiatsRes, cryptosRes, walletsRes].some(
        it => !it.success && it.left.kind === TIMEOUT_ERROR,
      );
      this._isLoading = false;
    });

    if (!fiatsRes.success || !cryptosRes.success || !walletsRes.success) {
      return;
    }

    const usedCurrencies = this.getUsedCurrencies(
      cryptosRes.right,
      walletsRes.right,
    );

    const rates = await this._root.currenciesRateStore.batchRefreshRates(
      usedCurrencies.map(c => ({from: c.code, to: baseFiat})),
    );

    if (rates.some(it => !it.success && it.left.kind === TIMEOUT_ERROR)) {
      return;
    }

    this.historyRefresher.setStaleCodes(usedCurrencies.map(c => c.code));
  }

  @action.bound
  async submit(item: CurrencySelectionListItemData) {
    this._isSubmitting = true;

    const cryptoCode = item.cryptoCurrency.code as CryptoCurrencyCode;
    const wallets = this._root.walletStore.getWalletsByCode(cryptoCode);
    if (isEmpty(wallets)) {
      this._root.flashMessage.showMessage({
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        title: this._root.translation.templates['wallet.added']({
          currency: item.cryptoCurrency.name,
        }),
        variant: 'success',
      });
    }
    const res = isEmpty(wallets)
      ? await this._root.walletStore.createWallet(cryptoCode)
      : await this.makeAllCurrencyWalletsVisible(wallets);

    runInAction(() => {
      this._isSubmitting = false;
    });

    return res;
  }

  subscribe() {
    return this.historyRefresher.subscribe({doNotRefreshRates: true});
  }

  activateRateHistoryLoad() {
    this.historyRefresher.activate();
  }

  deactivateRateHistoryLoad() {
    this.historyRefresher.deactivate();
  }

  private async makeAllCurrencyWalletsVisible(wallets: Wallet[]) {
    const updateRequests = await Promise.all(
      wallets
        .filter(w => !w.is_visible)
        .map(w => this._root.walletStore.updateWalletVisibility(w.id, true)),
    );
    const failed = updateRequests.find(c => !c.success);
    return failed ?? success();
  }

  private getUsedCurrencies(currencies: CryptoCurrency[], wallets: Wallet[]) {
    const walletMap = new Map(wallets.map(wallet => [wallet.currency, wallet]));
    return currencies
      .filter(c => !walletMap.get(c.code) || !walletMap.get(c.code)?.is_visible)
      .filter(c => c.options.addable);
  }

  private toCurrencySelectionListItemData(
    baseFiat: FiatCurrency,
    c: CryptoCurrency,
  ): CurrencySelectionListItemData {
    const getHistory = () => {
      const res = this._root.currenciesRateHistoryStore.getLatestHistoryRes(
        c.code,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._baseFiat!,
        RateHistoryPeriod.Month,
      );
      return res && getHistoryFromResponse(res);
    };

    return {
      id: c.code,
      valueCurrency: toCurrencyDescriptionFromFiat(baseFiat),
      cryptoCurrency: toCurrencyDescriptionFromCrypto(c),
      value: (
        this._root.currenciesRateStore.getRate(c.code, baseFiat.code)?.rate ?? 0
      ).toString(),
      getFiatCurrency: () => {
        const fiat = this._root.currencyStore.getFiatCurrency(baseFiat.code);
        return fiat && toCurrencyDescriptionFromFiat(fiat);
      },
      getChartData: () => getHistory()?.map(toLineChartDatum),
      getDiff: () => {
        const rate = this._root.currenciesRateStore.getRate(
          c.code,
          baseFiat.code,
        );
        const history = getHistory();
        return (
          rate &&
          history &&
          getHistoryItemChange(
            [...history, {rate: rate.rate, timestamp: rate.last_updated}],
            true,
          )
        );
      },
      getRate: () => {
        return this._root.currenciesRateStore.getRate(c.code, baseFiat.code)
          ?.rate;
      },
    };
  }
}
