import type {ParamListBase} from '@react-navigation/native';
import {CommonActions, StackActions} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';
import {useCallback} from 'react';

export default function useStackNavigateBack<
  ParamList extends ParamListBase,
  RouteName extends Extract<keyof ParamList, string>,
>(
  navigation: StackNavigationProp<ParamList, RouteName>,
): <PrevRouteName extends Extract<keyof ParamList, string>>(
  name: PrevRouteName,
  params: ParamList[PrevRouteName],
) => void {
  return useCallback(
    (name, params) => {
      navigation.dispatch(state => {
        const caller = state.routes.find(_ => _.name === name);
        return caller
          ? CommonActions.navigate({
              key: caller.key,
              params,
              merge: true,
            })
          : StackActions.replace(name, params);
      });
    },
    [navigation],
  );
}
