import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import NotFoundScreen from '../../../screens/NotFoundScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type FallBackBindingProps = SmallHomeStackBindingProps<'FallBack'>;

export default observer(function FallBackBinding({
  navigation,
}: FallBackBindingProps) {
  const {deviceInfo} = useRoot();
  const appVersion = deviceInfo.getAppVersionWithBuildNumber();
  const toMain = useCallback(() => {
    navigation.replace('Root');
  }, [navigation]);
  return <NotFoundScreen toMain={toMain} version={appVersion} />;
});
