import type {
  CurrenciesRateHistoryItem,
  DecimalString,
  FiatCurrencyCode,
} from '@ncwallet-app/core';
import {
  keepMeaningfulDigitsInFiat,
  toLineChartDatum,
  useStrings,
} from '@ncwallet-app/core';
import {useSize} from '@ncwallet-app/core/src/ReactNativeUtil';
import {BaseSkeleton, LineChart} from '@ncwallet-app/ui/src';
import {
  FiatValueText,
  GraphContainer,
  GraphContainerText,
  GraphInfoView,
} from '@ncwallet-app/ui/src/components/atoms/exchangeComponents';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import PriceDiff from '../../components/PriceDiff';

export type CurrencyGraphProps = {
  rateHistory: CurrenciesRateHistoryItem[] | undefined;
  rate: DecimalString | undefined;
  baseFiat: FiatCurrencyCode | undefined;
  currencyGraphDiff: DecimalString | undefined;
  isLoaded: boolean;
};

export default observer(function CurrencyGraph(props: CurrencyGraphProps) {
  const {rateHistory, baseFiat, currencyGraphDiff} = props;
  const [{width: graphWidth}, onLayout] = useSize();
  const firstCryptoValue = props.rateHistory?.[0]?.rate;
  const percent = currencyGraphDiff ? currencyGraphDiff : '0';
  const strings = useStrings();
  const data = useMemo(() => rateHistory?.map(toLineChartDatum), [rateHistory]);

  return (
    <GraphContainer onLayout={onLayout}>
      <GraphContainerText>{strings['exchangeCrypto.price']}</GraphContainerText>

      {!props.isLoaded && <GraphSkeleton />}
      {props.isLoaded && (
        <>
          {data && (
            <LineChart
              data={data}
              width={graphWidth}
              height={55}
              withGradient
            />
          )}
          <GraphInfoView>
            {!isNil(props.rate) && (
              <FiatValueText>
                {keepMeaningfulDigitsInFiat(props.rate)} {baseFiat}
              </FiatValueText>
            )}
            {!isNil(firstCryptoValue) && (
              <PriceDiff firstPrice={firstCryptoValue} diff={percent} bold />
            )}
          </GraphInfoView>
        </>
      )}
    </GraphContainer>
  );
});

const GraphSkeleton = () => (
  <>
    <BaseSkeleton height={55} />
    <GraphInfoView>
      <BaseSkeleton width={70} height={15} />
      <BaseSkeleton width={70} height={15} />
    </GraphInfoView>
  </>
);
