import type GorhomBottomSheet from '@gorhom/bottom-sheet';
import type {CryptoCurrencyCode, InOutCurrency} from '@ncwallet-app/core';
import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {BottomSheet, Pressable} from '@ncwallet-app/ui';
import {CheckSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import formatCurrencyShortDescription from '@ncwallet-app/ui/src/util/formatCurrencyShortDescription';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {FlatList, Text, View} from 'react-native';

export type ReceiveCryptoAddressNetworkBottomSheetProps = {
  onSelect: (c: InOutCurrency) => void;
  onClosePress: () => void;
  currency: CryptoCurrencyCode;
  network: AddressNetwork;
  networks: InOutCurrency[] | undefined;
};

export default observer<
  ReceiveCryptoAddressNetworkBottomSheetProps,
  GorhomBottomSheet
>(
  React.forwardRef(function ReceiveCryptoAddressNetworkBottomSheet(props, ref) {
    const {onClosePress, networks, network, currency, onSelect} = props;
    const strings = useStrings();
    const theme = useTheme();
    const onChange = useCallback(
      (index: number) => {
        if (index === -1) {
          onClosePress();
        }
      },
      [onClosePress],
    );

    if (!props.networks) {
      return null;
    }
    return (
      <BottomSheet
        ref={ref}
        index={0}
        animateOnMount
        onClose={onClosePress}
        onChange={onChange}
        enablePanDownToClose
        backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
        snapPoints={[60 * (networks?.length || 1) + 100]}
        title={strings['receiveCryptoScreen.networkBottomSheet.title']}
        children={
          <FlatList
            data={networks}
            ItemSeparatorComponent={Divider}
            renderItem={({item}) => (
              <Item
                onPress={() => {
                  onSelect(item);
                }}>
                <ItemNetwork>
                  {formatCurrencyShortDescription(
                    item.name,
                    item.contract_type,
                  )}
                </ItemNetwork>
                {item.network === network && item.currency === currency && (
                  <CheckSvg color={theme.palette.info} />
                )}
              </Item>
            )}
          />
        }
      />
    );
  }),
);

const Item = variance(Pressable)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
}));

const ItemNetwork = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 19,
    color: theme.palette.textPrimary,
  },
}));

const Divider = variance(View)(theme => ({
  root: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.uiSecondary,
  },
}));
