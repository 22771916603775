import {useStrings, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui/src';
import {SafeAreaScrollView} from '@ncwallet-app/ui/src';
import {useHeaderHeight} from '@react-navigation/elements';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import type {AddressFormProps} from './AddressForm';
import AddressForm from './AddressForm';

export type IdentityVerificationAddressScreenProps = BaseSafeAreaProps &
  AddressFormProps;

export default observer(function IdentityVerificationAddressScreen(
  props: IdentityVerificationAddressScreenProps,
) {
  const {country, onCountrySelect, value, onSubmit, ...rest} = props;
  const header = useHeaderHeight();
  const strings = useStrings();

  return (
    <SafeAreaScrollView
      {...rest}
      keyboardVerticalOffset={header}
      keyboardAvoiding>
      <AddressTopView>
        <AddressTopText>
          {strings['profileSettingsScreen.addressForm.description']}
        </AddressTopText>
      </AddressTopView>
      <AddressFormView>
        <AddressForm
          country={country}
          onCountrySelect={onCountrySelect}
          value={value}
          onSubmit={onSubmit}
        />
      </AddressFormView>
    </SafeAreaScrollView>
  );
});

const AddressTopView = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
}));

const AddressTopText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 14,
  },
}));

const AddressFormView = variance(View)(() => ({
  root: {
    flex: 1,
    marginTop: 10,
    paddingHorizontal: 15,
  },
}));
