import {useRoot, useShareAvailable, useStrings} from '@ncwallet-app/core';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';

import ShowQrScreen from '../../../screens/ShowQrScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ShowQrBindingProps = SmallHomeStackBindingProps<'ShowQr'>;

export default observer(function ShowQrBinding(props: ShowQrBindingProps) {
  const {navigation, route} = props;
  const onBackPress = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.navigate('ShowQrToReceiveCrypto', route.params);
  }, [navigation, route.params]);
  useEffect(() => {
    if (!navigation.canGoBack()) {
      navigation.replace('ShowQrToReceiveCrypto', route.params);
    }
  }, [navigation, route.params]);
  const {address, addressCurrency} = route.params;
  const {flashMessage, sharing} = useRoot();
  const strings = useStrings();

  const copy = useCallback(
    (data: string) => {
      void setStringAsync(data);
      flashMessage.showMessage({
        title: strings.copied,
        variant: 'success',
      });
    },
    [flashMessage, strings.copied],
  );

  const shareShown = useShareAvailable();
  return (
    <ShowQrScreen
      shareShown={shareShown}
      walletAddress={address}
      onShare={sharing.shareMessage}
      onCopy={copy}
      onClosePress={onBackPress}
      networkCurrency={addressCurrency}
    />
  );
});
