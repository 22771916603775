import type {
  CryptoCurrency,
  CryptoCurrencyCode,
  CurrenciesRateHistoryStore,
  CurrenciesRateStore,
  CurrencyStore,
  FiatCurrencyCode,
  Wallet,
} from '@ncwallet-app/core';
import {
  getHistoryItemChange,
  MoneyStatic,
  RateHistoryPeriod,
  toCurrencyDescriptionFromCrypto,
  toCurrencyDescriptionFromFiat,
  toLineChartDatum,
} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import {getAccountFromState} from '@ncwallet-app/core/src/AccountStore';
import {getHistoryFromResponse} from '@ncwallet-app/core/src/dataStores/CurrenciesRateHistoryStore/AcceptedRateHistoryResponse';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {isNil} from 'lodash';
import {computed, makeObservable} from 'mobx';

// eslint-disable-next-line import-x/prefer-default-export
export class CurrencySelectionListConverter {
  @computed
  private get _baseFiat(): FiatCurrencyCode | undefined {
    return getAccountFromState(this._root.accountStore.state)?.base_fiat;
  }

  constructor(
    private readonly _root: {
      readonly accountStore: AccountStore;
      readonly currencyStore: CurrencyStore;
      readonly currenciesRateStore: CurrenciesRateStore;
      readonly currenciesRateHistoryStore: CurrenciesRateHistoryStore;
    },
  ) {
    makeObservable(this);
  }

  toItem = (
    c: CryptoCurrency,
    w?: Wallet,
    disabledCodes?: Set<CryptoCurrencyCode>,
  ): CurrencySelectionListItemData => {
    const getHistory = () => {
      const res = this._root.currenciesRateHistoryStore.getLatestHistoryRes(
        c.code,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._baseFiat!,
        RateHistoryPeriod.Month,
      );
      return res && getHistoryFromResponse(res);
    };

    const getRate = () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._root.currenciesRateStore.getRate(c.code, this._baseFiat!)?.rate;

    return {
      id: w?.id ?? c.code,
      walletId: w?.id,
      cryptoCurrency: toCurrencyDescriptionFromCrypto(c),
      valueCurrency: toCurrencyDescriptionFromCrypto(c),
      value: w?.total ?? '0',
      disabled: disabledCodes && disabledCodes.has(c.code),
      getRate: getRate,
      getFiatValue: () => {
        const rate = getRate();
        return rate && !isNil(w?.total)
          ? MoneyStatic.convert(w.total, rate)
          : undefined;
      },
      getFiatCurrency: () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const fiat = this._root.currencyStore.getFiatCurrency(this._baseFiat!);
        return fiat && toCurrencyDescriptionFromFiat(fiat);
      },
      getChartData: () => getHistory()?.map(toLineChartDatum),
      getDiff: () => {
        const lastRate = this._root.currenciesRateStore.getRate(
          c.code,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this._baseFiat!,
        );
        const history = getHistory();
        return (
          history &&
          lastRate &&
          getHistoryItemChange(
            [
              ...history,
              {rate: lastRate.rate, timestamp: lastRate.last_updated},
            ],
            true,
          )
        );
      },
    };
  };
}
