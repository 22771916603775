import {useTheme, variance} from '@ncwallet-app/core';
import type {PressableProps} from '@ncwallet-app/ui';
import {Pressable} from '@ncwallet-app/ui';
import {ArrowLeftSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';

export type BackButtonProps = PressableProps;

export default observer(function BackButton(props: BackButtonProps) {
  const theme = useTheme();

  return (
    <BackButtonPressable {...props}>
      <ArrowLeftSvg color={theme.palette.uiMain} />
    </BackButtonPressable>
  );
});

const BackButtonPressable = variance(Pressable)(() => ({
  root: {
    marginRight: 10,
  },
}));
