import type {SmallBottomTabParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallBottomTab/SmallBottomTabParamList';
import type {SmallHomeStackParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallHomeStackParamList';
import type {
  NavigationProp,
  ParamListBase,
  PartialState,
} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';

// eslint-disable-next-line import-x/prefer-default-export
export function goToSmallHomeStackTab(
  name: keyof SmallBottomTabParamList,
  _navigation: NavigationProp<ParamListBase>,
) {
  const navigation = _navigation as NavigationProp<SmallHomeStackParamList>;
  const bottomTabState: PartialState<NavigationState<SmallBottomTabParamList>> =
    {
      index: 0,
      routes: [{name}],
    };
  const rootState: PartialState<NavigationState<SmallHomeStackParamList>> = {
    index: 0,
    routes: [{name: 'Root', state: bottomTabState}],
  };
  navigation.dispatch(CommonActions.reset(rootState));
}
