import type {ErrorRepository} from '../ErrorRepository';
import type {
  AccountDocument,
  AccountDocumentId,
  AccountDocumentType,
} from '../NCWalletServer/AccountsDocuments';
import {documentCanBeReviewed} from '../NCWalletServer/AccountsDocuments';
import type {AccountDocumentManager} from './AccountDocumentManager';
import type {AccountDocumentRequestHelper} from './AccountDocumentRequestHelper';
import type {AccountDocumentStore} from './AccountDocumentStore';

export default class AccountDocumentManagerImpl
  implements AccountDocumentManager
{
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly accountDocumentStore: AccountDocumentStore;
    },
    private readonly _reqHelper: AccountDocumentRequestHelper,
  ) {}

  getAll() {
    return this._root.accountDocumentStore.getAll();
  }

  getById(id: AccountDocumentId): AccountDocument | undefined {
    return this._root.accountDocumentStore.getById(id);
  }

  getByType(documentType: AccountDocumentType): AccountDocument | undefined {
    return this._root.accountDocumentStore.getByType(documentType);
  }

  async refresh() {
    const res = await this._reqHelper.getDocuments();
    if (res.success) {
      this._root.accountDocumentStore.setAll(res.right);
    }
    return res;
  }

  async uploadFile(
    fileData: string,
    documentType: AccountDocumentType,
    fileName?: string,
  ) {
    const res = await this._reqHelper.uploadFile(
      fileData,
      documentType,
      fileName,
    );
    if (res.success) {
      this._root.accountDocumentStore.update(res.right);
    }
    return res;
  }

  async deleteFiles(id: AccountDocumentId, fileIds: string[]) {
    const res = await this._reqHelper.deleteFiles(id, fileIds);
    if (res.success) {
      this._root.accountDocumentStore.update(res.right);
    }
    return res;
  }

  async sendDocumentsToReview() {
    const documents = this._root.accountDocumentStore.getAll();
    if (!documents) {
      throw new Error('sendDocumentsToReview called before documents loaded');
    }
    const ids = documents.filter(documentCanBeReviewed).map(d => d.id);
    const res = await this._reqHelper.sendDocumentsToReview(ids);

    if (res.success) {
      res.right.forEach(this._root.accountDocumentStore.update);
    }

    return res;
  }
}
