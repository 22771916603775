import type {CryptoCurrencyCode, DecimalString} from '../Money';
import type {AddressNetwork} from '../NCWalletServer/AddressInfo';

export interface AddressParser {
  parse(input: string): Receipt;

  parseAddress(input: string): Receipt;
}

export const BITCOIN = Symbol();
export const ETHEREUM = Symbol();
export const TETHER = Symbol();
export const MATIC = Symbol();
export const TON = Symbol();
export const TRON = Symbol();

export type Receipt = {
  kind?: AddressKind;
  address: string;
  code?: CryptoCurrencyCode;
  amount?: DecimalString;
  network?: AddressNetwork;
  finished?: boolean;
};

export type AddressKind =
  | typeof BITCOIN
  | typeof ETHEREUM
  | typeof TETHER
  | typeof MATIC
  | typeof TON
  | typeof TRON;

export function getCurrencyCodeByAddressKind(
  kind: AddressKind,
): CryptoCurrencyCode {
  return CURRENCY_MAP[kind];
}

const CURRENCY_MAP = {
  [BITCOIN]: 'BTC',
  [ETHEREUM]: 'ETH',
  [TETHER]: 'USDT',
  [MATIC]: 'MATIC',
  [TON]: 'TON',
  [TRON]: 'TRX',
} as const;
