import type {BottomSheetMethods} from '@gorhom/bottom-sheet/lib/typescript/types';
import {useStrings, variance} from '@ncwallet-app/core';
import {BottomSheet, Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {View} from 'react-native';

export type PromptToClearWhiteListScreenProps = {
  onConfirm: () => void;
  onClose: () => void;
};

export default observer(function PromptToClearWhiteListScreen(
  props: PromptToClearWhiteListScreenProps,
) {
  const {onConfirm, onClose} = props;
  const strings = useStrings();
  const ref = useRef<BottomSheetMethods>(null);
  const isConfirmedRef = useRef(false);

  const handleConfirm = useCallback(() => {
    isConfirmedRef.current = true;
    ref.current?.close();
    onConfirm();
  }, [onConfirm]);

  const onChange = useCallback(
    (index: number) => {
      if (index === -1 && !isConfirmedRef.current) {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <BottomSheet
      ref={ref}
      index={0}
      onChange={onChange}
      animateOnMount
      snapPoints={[260]}
      enablePanDownToClose
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      title={strings['cleanWhiteListModal.title']}
      onClose={onClose}
      children={
        <Container>
          <Button
            onPress={handleConfirm}
            title={strings['cleanWhiteListModal.confirm']}
            variant={ButtonVariant.PrimaryLight}
          />
          <Divider />
          <Button
            onPress={onClose}
            title={strings['cleanWhiteListModal.cancel']}
            variant={ButtonVariant.Danger}
          />
        </Container>
      }
    />
  );
});

const Container = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingTop: 20,
  },
}));
const Divider = variance(View)(() => ({
  root: {
    height: 20,
  },
}));
