import type {ReactNode} from 'react';
import React, {memo} from 'react';
import {
  TouchableWithoutFeedback as RNTouchableWithoutFeedback,
  StyleSheet,
  View,
} from 'react-native';

import splitViewStyle from '../../organisms/splitViewStyle';
import type {TouchableWithoutFeedbackProps} from './TouchableWithoutFeedbackProps';

function TouchableWithoutFeedback(
  props: TouchableWithoutFeedbackProps,
): ReactNode {
  const {
    nativeID,
    id,
    style,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldActivateOnStart,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    disallowInterruption,
    containerStyle,
    children,
    ...rest
  } = props;
  if (containerStyle) {
    const {inner, outer, other} = splitViewStyle(
      StyleSheet.flatten(style) ?? {},
    );
    return (
      <View style={[containerStyle, outer, other]}>
        <RNTouchableWithoutFeedback id={id ?? nativeID} {...rest}>
          <View style={[StyleSheet.absoluteFill, inner, other]}>
            {children}
          </View>
        </RNTouchableWithoutFeedback>
      </View>
    );
  }
  return (
    <RNTouchableWithoutFeedback id={id ?? nativeID} {...rest}>
      <View style={style}>{children}</View>
    </RNTouchableWithoutFeedback>
  );
}

export default memo(TouchableWithoutFeedback);
