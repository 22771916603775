import {sized, useStrings, useTheme, variance} from '@ncwallet-app/core';
import {useIsDimensions} from '@ncwallet-app/ui/src';
import {
  AffiliateUserSVG,
  BellSvg,
  CircleHalfSvg,
  GlobeSvg,
  HelpSvg,
  LimitsSvg,
  SecureSvg,
  SignOutSvg,
  StarSvg,
  UserSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {useWindowDimensions, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import type {SvgProps} from 'react-native-svg';

import {isChromeExtension} from '../../Navigation/hooks/isChromeExtension';
import UserSettingsScreenItem from './UserSettingsListItem';

export type UserSettingsScreenListProps = {
  onLanguagePress: () => void;
  onProfilePress: () => void;
  onSecurityPress: () => void;
  onLimitsPress: () => void;
  onNotificationPress?: () => void;
  onRatePress: () => void;
  onHelpPress: () => void;
  onLogoutPress: () => void;
  onAffiliatePress: () => void;
  goToStyle: () => void;
  showRate?: boolean;
};

const DEBOUNCE_LOCK_TIME = 1200;

export default observer(function UserSettingsList(
  props: UserSettingsScreenListProps,
) {
  const strings = useStrings();
  const isLg = useIsDimensions('lg');

  const isExtension = isChromeExtension();
  const [lockScreen, setLockScreen] = useState(false);
  const onPress = useCallback(
    (cb: () => void) => {
      setLockScreen(true);
      if (!lockScreen) {
        cb();
        setTimeout(() => {
          setLockScreen(false);
        }, DEBOUNCE_LOCK_TIME);
      }
    },
    [lockScreen],
  );
  const {height} = useWindowDimensions();
  const {bottom} = useSafeAreaInsets();

  return (
    <Container style={{maxHeight: height - bottom}}>
      <Group offset bottomLine={!isLg && isExtension}>
        <UserSettingsScreenItem
          onPress={() => {
            onPress(props.onProfilePress);
          }}
          icon={<ItemIcon Icon={UserSvg} />}
          text={strings['userSettings.profile']}
          withoutArrow={isLg}
          testID="menu-profile"
        />
        <UserSettingsScreenItem
          icon={<ItemIcon Icon={SecureSvg} />}
          onPress={() => {
            onPress(props.onSecurityPress);
          }}
          text={strings['userSettings.secure']}
          withoutArrow={isLg}
          testID="menu-secure"
        />
        <UserSettingsScreenItem
          onPress={() => {
            onPress(props.onLimitsPress);
          }}
          icon={<ItemIcon Icon={LimitsSvg} />}
          text={strings['userSettings.limits']}
          withoutArrow={isLg}
          testID="menu-limits"
        />
        <UserSettingsScreenItem
          onPress={() => {
            onPress(props.onNotificationPress || noop);
          }}
          icon={<ItemIcon Icon={BellSvg} />}
          text={strings['userSettings.notifications']}
          withoutArrow={isLg}
          testID="menu-notifications"
        />
        <UserSettingsScreenItem
          onPress={() => {
            onPress(props.onHelpPress);
          }}
          icon={<ItemIcon Icon={HelpSvg} />}
          text={strings['userSettings.help']}
          withoutArrow={isLg}
          testID="menu-help"
        />
        <UserSettingsScreenItem
          onPress={() => {
            onPress(props.onAffiliatePress);
          }}
          icon={<ItemIcon Icon={AffiliateUserSVG} />}
          text={strings['userSettings.affiliate']}
          withoutArrow={isLg}
          last={isExtension}
          testID="menu-affiliate"
        />
        {(props.showRate || !isExtension) && (
          <UserSettingsScreenItem
            onPress={() => {
              onPress(props.onRatePress);
            }}
            icon={<ItemIcon Icon={StarSvgSized} />}
            text={strings['userSettings.rateUs']}
            withoutArrow={isLg}
            last
            testID="menu-rateUs"
          />
        )}
      </Group>
      <Group topLine={!isLg && isExtension}>
        <UserSettingsScreenItem
          onPress={() => {
            onPress(props.goToStyle);
          }}
          icon={<ItemIcon Icon={CircleHalfSvg} />}
          text={strings['userSettings.style']}
          testID="menu-theme"
        />
        <UserSettingsScreenItem
          onPress={() => {
            onPress(props.onLanguagePress);
          }}
          icon={<ItemIcon Icon={GlobeSvg} />}
          text={strings['userSettings.language']}
          testID="menu-language"
        />
        <UserSettingsScreenItem
          onPress={() => {
            onPress(props.onLogoutPress);
          }}
          icon={<ItemIcon Icon={SignOutSvg} danger={isLg} />}
          text={strings['userSettings.logOut']}
          testID="menu-logout"
          last
          withoutArrow
          danger={isLg}
        />
      </Group>
    </Container>
  );
});

type ItemIconProps = {
  Icon: React.ComponentType<SvgProps>;
  danger?: boolean;
};

const ItemIcon = observer((props: ItemIconProps) => {
  const {Icon, danger} = props;
  const theme = useTheme();
  const isLg = useIsDimensions('lg');

  return isLg ? (
    <IconContainerWithDecoration>
      <Icon color={danger ? theme.palette.error : theme.palette.uiMain} />
    </IconContainerWithDecoration>
  ) : (
    <IconContainer>
      <Icon color={theme.palette.icon1} />
    </IconContainer>
  );
});

const Container = variance(ScrollView)(
  () => ({
    root: {
      flex: 1,
    },
  }),
  () => ({
    contentContainerStyle: {
      paddingBottom: 50,
    },
  }),
);

const Group = variance(View)(theme => ({
  root: {},
  offset: {
    marginBottom: 50,
  },
  bottomLine: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    marginBottom: 40,
  },
  topLine: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.uiSecondary,
  },
}));

const IconContainer = variance(View)(() => ({
  root: {
    paddingVertical: 10,
    borderRadius: 6,
  },
}));

const IconContainerWithDecoration = variance(IconContainer)(theme => ({
  root: {
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiAdditional2,
  },
}));

const StarSvgSized = sized(StarSvg, 20, 20);
