import {useStrings, variance} from '@ncwallet-app/core';
import type {SessionId} from '@ncwallet-app/core/src/NCWalletServer/sessions/Session';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {SafeAreaFlatList} from '@ncwallet-app/ui';
import {isEmpty} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {ListRenderItem} from 'react-native';
import {View} from 'react-native';

import SessionHeader from '../UserSettingsScreens/SharedComponents/SessionHeader';
import type {SessionInfo} from './SessionInfo';
import {SessionsItem} from './SessionsItem';
import SessionsSkeleton from './SessionsSkeleton';

export type ActiveSessionListProps = BaseSafeAreaProps & {
  currentSession?: SessionInfo;
  data?: SessionInfo[];
  onPress: (id: SessionId) => void;
};

export const OtherSessionsList = observer((props: ActiveSessionListProps) => {
  const {onPress, ...rest} = props;

  const renderItem: ListRenderItem<SessionInfo> = useCallback(
    ({item}) => (
      <SessionsItem
        data={item}
        onPress={() => {
          onPress(item.id);
        }}
      />
    ),
    [onPress],
  );

  const ListHeaderFinal = useCallback(
    () => (
      <ListHeader
        currentSession={props.currentSession}
        other={props.data ?? []}
      />
    ),
    [props.data, props.currentSession],
  );

  return (
    <SafeAreaFlatList
      ListHeaderComponent={() => <ListHeaderFinal />}
      renderItem={renderItem}
      enabledWebBottomOffset
      keyExtractor={keyExtractor}
      data={props.data ?? []}
      {...rest}
    />
  );
});

const keyExtractor = (entry: SessionInfo) => entry.id;

export const ListHeader = ({
  other,
  currentSession,
}: {
  other: SessionInfo[];
  currentSession?: SessionInfo;
}) => {
  const strings = useStrings();
  return (
    <>
      <CurrentSessionContainer>
        <SessionHeader
          title={strings['secureSettingsScreen.activeSessions.current']}
        />
        {currentSession ? (
          <SessionsItem data={currentSession} />
        ) : (
          <SessionsSkeleton />
        )}
      </CurrentSessionContainer>
      {!isEmpty(other) && (
        <SessionHeader
          title={strings['secureSettingsScreen.activeSessions.others']}
        />
      )}
    </>
  );
};

const CurrentSessionContainer = variance(View)(() => ({
  root: {
    marginBottom: 30,
  },
}));
