import {useStrings, useTheme, variance} from '@ncwallet-app/core/src';
import type {TotpActionOption} from '@ncwallet-app/core/src/NCWalletServer/AccountsTotpActions';
import type {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {Pressable} from '@ncwallet-app/ui';
import {RestoreSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import TwoFaSettingsScreen from '../UserSettingsScreens/TwoFaSettingsScreen/TwoFaSettingsScreen';

export type TwoFaSettingsCardProps = {
  actions: TotpActionOption[] | undefined;
  isEdited: boolean;
  onReset: () => void;
  onActionPress: (a: TotpActionOption) => void;
  currentTwoFaProvider: TwoFaProviderKind | undefined;
  selectedTwoFaProvider: TwoFaProviderKind | undefined;
  onDisableTwoFaPress: () => void;
  onTwoFaSelect: () => void;
  onSubmitPress: () => void;
};

export default observer(function TwoFaSettingsCard(
  props: TwoFaSettingsCardProps,
) {
  const strings = useStrings();
  const theme = useTheme();

  return (
    <LgProfileCardContainer>
      <HeaderTag>
        <HeaderTitleTag>
          {strings['lgSecureSettingsScreen.twoFACardTitle']}
        </HeaderTitleTag>
        {props.isEdited && (
          <ResetTwoFaButton onPress={props.onReset}>
            <RestoreSvg color={theme.palette.info} />
          </ResetTwoFaButton>
        )}
      </HeaderTag>
      <TwoFaSettingsScreen
        onTwoFaKindSelect={props.onTwoFaSelect}
        offsetBottom
        {...props}
      />
    </LgProfileCardContainer>
  );
});

const ResetTwoFaButton = variance(Pressable)(() => ({
  root: {
    marginLeft: 'auto',
  },
}));
