import type {Millisecond} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptPeriodToFilterHistoryContainer from '../../../CommonNavigationContainers/containers/PromptPeriodToFilterHistoryContainer';
import useStackNavigateBack from '../../../CommonNavigationContainers/hooks/useStackNavigateBack';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptPeriodToFilterHistoryBindingProps =
  SmallHomeStackBindingProps<'PromptPeriodToFilterHistory'>;

export default observer(function PromptPeriodToFilterHistoryBinding(
  props: PromptPeriodToFilterHistoryBindingProps,
) {
  const {navigation, route} = props;

  const navigateBack = useStackNavigateBack(navigation);
  const onApply = useCallback(
    (from: Millisecond | undefined, to: Millisecond | undefined) => {
      navigateBack('ListHistoryFilters', {from, to});
    },
    [navigateBack],
  );

  const onSourceOpen = useCallback(
    (to: Millisecond, from: Millisecond) => {
      navigation.navigate('PromptDateOfHistoryPeriod', {
        isSourceRequested: true,
        to,
        from,
      });
    },
    [navigation],
  );

  const onDestinationOpen = useCallback(
    (to: Millisecond, from: Millisecond) => {
      navigation.navigate('PromptDateOfHistoryPeriod', {
        isSourceRequested: false,
        to,
        from,
      });
    },
    [navigation],
  );

  return (
    <PromptPeriodToFilterHistoryContainer
      params={route.params}
      onSourceOpen={onSourceOpen}
      onApply={onApply}
      onDestinationOpen={onDestinationOpen}
    />
  );
});
