import type {CryptoCurrencyCode, WalletId} from '@ncwallet-app/core';
import {last} from '@ncwallet-app/core';
import type {
  PromptExchangeReceiptRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {CommonActions} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptCryptoToExchangeContainer from '../../../../../CommonNavigationContainers/containers/PromptCryptoToExchangeContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptCryptoToExchangeBindingProps =
  LargeSwitchBindingProps<'PromptCryptoToExchange'>;

export default observer(function PromptCryptoToExchangeBinding(
  props: PromptCryptoToExchangeBindingProps,
) {
  const {navigation, route} = props;
  const {params} = route;

  const onCurrencyPress = useCallback(
    (crypto: CryptoCurrencyCode, walletId?: WalletId) => {
      const navigationState = navigation.getState();
      const formRoute = last(navigationState.history);

      if (formRoute?.name !== 'PromptExchangeReceipt') {
        navigation.popToTop();
        return;
      }

      const isSwap =
        walletId &&
        params.walletIdTo &&
        ((!params.isSourceRequested && walletId === params.walletIdFrom) ||
          (params.isSourceRequested && walletId === params.walletIdTo));

      let newParams: RouteParams<PromptExchangeReceiptRoute>;
      if (isSwap) {
        newParams = {
          walletIdFrom: params.walletIdTo,
          walletIdTo: params.walletIdFrom,
          value: params.value,
          isTargetValue: !params.isTargetValue,
        };
      } else {
        newParams = !params.isSourceRequested
          ? {
              currencyTo: crypto,
              walletIdTo: walletId,
              walletIdFrom: params.walletIdFrom,
              value: params.value,
              isTargetValue: params.isTargetValue,
            }
          : {
              currencyTo: params.currencyTo,
              walletIdTo: params.walletIdTo,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              walletIdFrom: walletId!,
              value: params.value,
              isTargetValue: params.isTargetValue,
            };
      }

      navigation.dispatch(state =>
        CommonActions.reset({
          ...state,
          routes: [
            {
              ...last(state.history),
              name: 'PromptExchangeReceipt',
              params: newParams,
            },
          ],
          history: state.history.slice(0, -1),
        }),
      );
    },
    [
      navigation,
      params.currencyTo,
      params.isSourceRequested,
      params.isTargetValue,
      params.value,
      params.walletIdFrom,
      params.walletIdTo,
    ],
  );

  const onSearchChange = useCallback(
    (search: string) => {
      navigation.setParams({search});
    },
    [navigation],
  );

  const onReceivePress = useCallback(() => {
    navigation.navigate('PromptCryptoToReceive');
  }, [navigation]);

  const onBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onAddPress = useCallback(() => {
    navigation.navigate('PromptNewWallet');
  }, [navigation]);

  return (
    <PromptCryptoToExchangeContainer
      isLg={true}
      params={route.params}
      onBack={onBack}
      onSearchChange={onSearchChange}
      onCurrencyPress={onCurrencyPress}
      onReceivePress={onReceivePress}
      onAddPress={onAddPress}
    />
  );
});
