import {BaseRestClientImpl} from '../BaseRestClient';
import type {Configuration} from '../Configuration';
import type {ErrorRepository} from '../ErrorRepository';
import type {Http} from '../Http';
import type {Json} from '../Json';
import type {Maybe} from '../Maybe';
import type {Url} from '../units';
import type {
  DeviceRegistrationParams,
  DeviceRegistrationResult,
  DeviceRestClient,
} from './DeviceRestClient';

export default class DeviceRestClientImpl
  extends BaseRestClientImpl
  implements DeviceRestClient
{
  constructor(
    protected readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly configuration: Configuration;
      readonly http: Http;
      readonly json: Json;
    },
  ) {
    super(_root);
  }

  protected get _base() {
    return this._root.configuration.current.values.ncWalletRestApiUrl;
  }

  protected get _timeout() {
    return this._root.configuration.current.values.ncWalletRestApiTimeout;
  }

  async registration(
    params: DeviceRegistrationParams,
  ): Promise<Maybe<DeviceRegistrationResult>> {
    return this._call('POST', 'api/v1/devices/registration' as Url, params);
  }
}
