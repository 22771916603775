import {BottomSheet} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {BackHandler} from 'react-native';

import PromptCloseAppScreen from '../../../screens/PromptCloseAppScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptSignOutBindingProps =
  SmallHomeStackBindingProps<'PromptCloseApp'>;

export default observer(function PromptCloseAppBinding(
  props: PromptSignOutBindingProps,
) {
  const {navigation} = props;
  const handleCloseApp = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
    BackHandler.exitApp();
  }, [navigation]);

  const handleCancel = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.reset({index: 0, routes: [{name: 'Root'}]});
    }
  }, [navigation]);

  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        handleCancel();
      }
    },
    [handleCancel],
  );

  return (
    <BottomSheet
      index={0}
      onChange={onChange}
      animateOnMount
      enablePanDownToClose
      enableDynamicSizing
      enableAutoHeight
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      onClose={handleCancel}
      children={
        <PromptCloseAppScreen
          onConfirm={handleCloseApp}
          onCancel={handleCancel}
        />
      }
    />
  );
});
