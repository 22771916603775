import {useStrings} from '@ncwallet-app/core';
import {BottomSheet} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import DeleteScreenBody from './DeleteAccountScreenBody';

export type DeleteScreenProps = {
  onClose: () => void;
  onDelete: () => void;
  haveFounds: boolean;
  onSupport: () => void;
};

export default observer(function DeleteScreen({
  onClose,
  onSupport,
  haveFounds,
  onDelete,
}: DeleteScreenProps) {
  const strings = useStrings();
  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        onClose();
      }
    },
    [onClose],
  );
  return (
    <BottomSheet
      index={0}
      onChange={onChange}
      animateOnMount
      snapPoints={[320]}
      enablePanDownToClose
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      title={strings['deleteAccountModal.title']}
      onClose={onClose}
      children={
        <DeleteScreenBody
          onClose={onClose}
          onDelete={onDelete}
          haveFounds={haveFounds}
          onSupport={onSupport}
        />
      }
    />
  );
});
