import type {ValueOf} from 'type-fest';

import type {BaseListener} from '../Bus';
import {BusImpl} from '../Bus';
import {DeferredCollectionImpl} from '../DeferredCollection';
import type {Disposer} from '../Service';
import type {
  MetaRouterMap,
  Router,
  RouterMap,
  RouterMapEvents,
  RouterSource,
} from './Router';

export default class RouterImpl<M extends RouterMap> implements Router<M> {
  private readonly _domain = new BusImpl<(event: RouterMapEvents<M>) => void>();
  private readonly _listeners = new DeferredCollectionImpl<
    ReadonlyListeners<M>,
    Listeners<M>
  >(
    new Map(),
    _ => new Map([..._.entries()].map(__ => [__[0], new Set(__[1])])),
  );
  private _afterBeingListened?: RouterImpl<MetaRouterMap<M>>;
  private _beforeBeingForgot?: RouterImpl<MetaRouterMap<M>>;

  get listeners(): ReadonlyListeners<M> {
    return this._listeners.deferred;
  }

  getListeners<K extends keyof M>(theme: K): ReadonlySet<M[K]> {
    return (this._listeners.deferred.get(theme) ??
      RouterImpl._EMPTY_SET) as ReadonlySet<BaseListener> as ReadonlySet<M[K]>;
  }

  get isBeingListened(): boolean {
    return this.listeners.size > 0;
  }

  get afterBeingListened(): RouterSource<MetaRouterMap<M>> {
    if (this._afterBeingListened) {
      return this._afterBeingListened;
    }
    this._afterBeingListened = new RouterImpl();
    return this._afterBeingListened;
  }

  get beforeBeingForgot(): RouterSource<MetaRouterMap<M>> {
    if (this._beforeBeingForgot) {
      return this._beforeBeingForgot;
    }
    this._beforeBeingForgot = new RouterImpl();
    return this._beforeBeingForgot;
  }

  send<K extends keyof M>(theme: K, ...args: Parameters<M[K]>) {
    if (this._domain.isBeingListened) {
      this._domain.send({theme, args});
    }
    this._listeners.guard(actual => {
      const listeners = actual.get(theme);
      if (listeners) {
        for (const listener of listeners) {
          listener(...args);
        }
      }
    });
  }

  listen<K extends keyof M>(theme: K, listener: M[K]) {
    let listeners = this._listeners.deferred.get(theme);
    if (!listeners) {
      listeners = new Set();
      this._listeners.deferred.set(theme, listeners);
    }
    listeners.add(listener);
    // @ts-expect-error This is a bug of TypeScript <=5.6.3
    this._afterBeingListened?.send(theme, listener);
    return (() => {
      this.forget(theme, listener);
    }) as Disposer;
  }

  once<K extends keyof M>(theme: K, listener: M[K]) {
    const _listener = ((...args: Parameters<M[K]>) => {
      listener(...args);
      this.forget(theme, _listener);
    }) as M[K];
    return this.listen(theme, _listener);
  }

  forget<K extends keyof M>(theme: K, listener: M[K]) {
    // @ts-expect-error This is a bug of TypeScript <=5.6.3
    this._beforeBeingForgot?.send(theme, listener);
    const listeners = this._listeners.deferred.get(theme);
    if (listeners) {
      listeners.delete(listener);
      if (listeners.size === 0) {
        this._listeners.deferred.delete(theme);
      }
    }
  }

  get domain(): RouterSource<M>['domain'] {
    return this._domain;
  }

  private static readonly _EMPTY_SET = new Set<never>();
}

type Listeners<M extends RouterMap> = Map<keyof M, Set<ValueOf<M>>>;
type ReadonlyListeners<M extends RouterMap> = ReadonlyMap<
  keyof M,
  ReadonlySet<ValueOf<M>>
>;
