import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useThrottledCallback} from 'use-debounce';

import {CameraBackgroundMask} from './CameraBackgroundMask';

export type CameraLayerProps = {
  onBarCodeScanned: (code: string) => void;
  visibleCamera: boolean;
};

export default observer(function CameraLayer(props: CameraLayerProps) {
  const {onBarCodeScanned, visibleCamera} = props;
  const {
    camera: {QrCameraComponent},
  } = useRoot();
  const handleBarCodeScannedThrottled = useThrottledCallback(
    onBarCodeScanned,
    1000,
    {trailing: false},
  );

  return (
    <View style={styles.root}>
      {visibleCamera && (
        <QrCameraComponent onBarCodeScanned={handleBarCodeScannedThrottled} />
      )}
      <View style={StyleSheet.absoluteFillObject}>
        <CameraBackgroundMask />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
