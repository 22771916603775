import type {CryptoCurrencyCode, DecimalString} from '@ncwallet-app/core';
import type {SafeAreaScrollViewProps} from '@ncwallet-app/ui';
import {SendCryptoModal} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useRef} from 'react';
import type {TextInput} from 'react-native';

import {useFocusInputOnNavigationFocus} from '../../Navigation/hooks';
import SendAmountInput from './SendAmountInput';

type SendCryptoPaymentFormScreenProps = {
  max: DecimalString | undefined;
  maxRestrictedByWalletLimit: boolean;
  currency: CryptoCurrencyCode;
  amount: DecimalString | undefined;
  stepCurrent: number;
  stepTotal: number;
  error: string | undefined;
  maxError: string | undefined;
  isEditable?: boolean;
  onAmountChange: (amount: DecimalString | undefined) => void;
  onSubmit: (amount: DecimalString | undefined) => void;
} & SafeAreaScrollViewProps;

// eslint-disable-next-line import-x/prefer-default-export
export const SendAmountInputScreen = observer(
  (props: SendCryptoPaymentFormScreenProps) => {
    const {
      amount,
      stepCurrent,
      stepTotal,
      onAmountChange,
      onSubmit,
      max,
      maxError,
      maxRestrictedByWalletLimit,
      error,
      currency,
      isEditable = false,
      ...rest
    } = props;
    const inputRef = useRef<TextInput>(null);
    useFocusInputOnNavigationFocus(inputRef);

    return (
      <SendCryptoModal
        stepCurrent={stepCurrent}
        stepTotal={stepTotal}
        isEditable={isEditable}
        error={error || maxError}
        onSubmit={() => {
          onSubmit(amount);
        }}
        {...rest}>
        <SendAmountInput
          cryptoCurrency={currency}
          max={max}
          maxRestrictedByWalletLimit={maxRestrictedByWalletLimit}
          maxValueAvailable={!maxError}
          inputRef={inputRef}
          error={!!error}
          value={amount}
          onInputChange={onAmountChange}
        />
      </SendCryptoModal>
    );
  },
);
