import {action, computed, makeObservable, observable} from 'mobx';
import type {ScaledSize} from 'react-native';

import type {Service} from '../structure';
import type {Dimensions, WindowDimensions} from './WindowDimensions';
import type {WindowDimensionsState} from './WindowDimensionsState';
import WindowDimensionsStatic from './WindowDimensionsStatic';

export default class WindowDimensionsStateService
  implements WindowDimensionsState, Service
{
  @observable.ref private _dimensions =
    WindowDimensionsStatic.getInitialDimensions();

  constructor(
    private readonly _root: {readonly windowDimensions: WindowDimensions},
  ) {
    makeObservable(this);
  }

  get window(): ScaledSize {
    return this._dimensions.window;
  }

  @computed.struct
  get screen(): ScaledSize {
    return this._dimensions.screen;
  }

  private readonly _onChange = action((dimensions: Dimensions) => {
    this._dimensions = {...dimensions};
  });

  subscribe() {
    const {updates} = this._root.windowDimensions;
    return updates.listen(this._onChange);
  }
}
