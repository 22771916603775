import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';

import FlashMessageListView from './FlashMessageListView';

export default observer(function FlashMessageInstance() {
  const {
    flashMessage: {getMessages, removeMessage, showMessage},
  } = useRoot();
  return (
    <FlashMessageListView
      getMessages={getMessages}
      onRemoveMessage={removeMessage}
      onShowMessage={showMessage}
    />
  );
});
