import type {Millisecond} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {AdvertContext} from '@ncwallet-app/core/src/Advert';
import {observer} from 'mobx-react-lite';
import React from 'react';

import InstallNativeScreen, {
  InstallNativeModal,
} from '../../screens/InstallNativeScreen';
import useAdvertProps from './useAdvertProps';

export type InstallNativeSpotBindingProps = {
  context: AdvertContext;
  hideAd: (now: Millisecond) => Promise<void>;
};

export default observer(function InstallNativeSpotBinding(
  props: InstallNativeSpotBindingProps,
) {
  const {context, hideAd} = props;

  const root = useRoot();
  const [closeBanner, onActionPress, actions] = useAdvertProps(context, hideAd);

  const isLarge = root.layoutHelperState.isLarge;
  return isLarge ? (
    <InstallNativeModal
      body={context.text.body}
      title={context.text.title}
      icon={context.images.icon}
      actions={actions}
      onActionPress={onActionPress}
      onClose={closeBanner}
    />
  ) : (
    <InstallNativeScreen
      body={context.text.body}
      title={context.text.title}
      icon={context.images.icon}
      actions={actions}
      onActionPress={onActionPress}
      onClose={closeBanner}
    />
  );
});
