import {urlLinks, useStrings, useTheme, variance} from '@ncwallet-app/core';
import type {ErrorDetails} from '@ncwallet-app/core/src/ErrorParser';
import {
  Button,
  ButtonVariant,
  ErrorText,
  Input,
  Pressable,
  TouchableOpacity,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  CopySvg,
  RandomSVG,
  ShareSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Linking, StyleSheet, Text, View} from 'react-native';

import {useReceiveScreenActions} from '../ReceiveCryptoScreen/useReceiveScreenActions';

export type LinkNameInputProps = {
  generateLink: () => void;
  onChangeName: (link: string) => void;
  onShare: (data: string) => void;
  link: string;
  savedLink: string | undefined;
  error?: ErrorDetails;
  disabled?: boolean;
  shareShown?: boolean;
  onFocus?:
    | ((options?: {x: number; y: number; animated: boolean}) => void)
    | undefined;
};

export default observer(function LinkNameInput({
  generateLink,
  onChangeName,
  link,
  savedLink,
  error,
  disabled,
  onFocus,
  onShare,
  shareShown,
}: LinkNameInputProps) {
  const theme = useTheme();
  const isLg = useIsDimensions('lg');
  const strings = useStrings();
  const fullLink = useMemo(() => {
    return `${urlLinks.payMeLink}${savedLink ?? ''}`;
  }, [savedLink]);
  const {copyAddressThrottled, shareThrottled} = useReceiveScreenActions(
    onShare,
    fullLink,
    strings.copied,
  );

  const renderActionComponent = useCallback(() => {
    return (
      <NameActionView>
        <NameActionTouchable disabled={disabled} onPress={generateLink}>
          <RandomSVG color={theme.palette.info} />
        </NameActionTouchable>
      </NameActionView>
    );
  }, [theme.palette.info, generateLink, disabled]);
  const renderLabel = useCallback(() => {
    const pressLink = () =>
      savedLink && Linking.openURL(`${urlLinks.payMeLink}${savedLink}`);
    return (
      <Label>
        <LabelText>{urlLinks.payMeLink}</LabelText>
        <Pressable onPress={pressLink}>
          <LabelLink>{savedLink || 'link_name'}</LabelLink>
        </Pressable>
      </Label>
    );
  }, [savedLink]);

  if (isLg) {
    return (
      <Container>
        <Wrapper>
          <Input
            label={renderLabel()}
            containerStyle={styles.inputContainer}
            placeholder={strings['generatePayMeLink.name.placeholder']}
            placeholderTextColor={theme.palette.textAdditional3}
            Slot={renderActionComponent}
            value={link}
            onChangeText={onChangeName}
            disabled={disabled}
            onPressIn={() => onFocus?.({x: 0, y: 10000, animated: true})}
          />

          {disabled && (
            <>
              {shareShown && (
                <SmallActionButton onPress={shareThrottled}>
                  <ShareSvg color={theme.palette.info} />
                </SmallActionButton>
              )}
              <SmallActionButton onPress={() => copyAddressThrottled(fullLink)}>
                <CopySvg color={theme.palette.info} />
              </SmallActionButton>
            </>
          )}
        </Wrapper>
        {error && (
          <ErrorContainer static={!isLg}>
            {error.summary.split(' ').map((err, index: number) => (
              <ErrorText offsetRight key={index}>
                {err}
              </ErrorText>
            ))}
          </ErrorContainer>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <Input
        label={renderLabel()}
        containerStyle={styles.inputContainer}
        placeholder={strings['generatePayMeLink.name.placeholder']}
        placeholderTextColor={theme.palette.textAdditional3}
        Slot={renderActionComponent}
        value={link}
        onChangeText={onChangeName}
        disabled={disabled}
        onPressIn={() => onFocus?.({x: 0, y: 10000, animated: true})}
      />
      {error && (
        <ErrorContainer static={!isLg}>
          {error.summary.split(' ').map((err, index: number) => (
            <ErrorText offsetRight key={index}>
              {err}
            </ErrorText>
          ))}
        </ErrorContainer>
      )}
      {disabled && (
        <Buttons>
          {shareShown && (
            <ActionButton
              onPress={shareThrottled}
              title={strings['receiveCryptoScreen.share']}
              variant={ButtonVariant.PrimaryLight}
              Icon={ShareSvg}
              offsetRight
            />
          )}
          <ActionButton
            onPress={() => copyAddressThrottled(fullLink)}
            title={strings['receiveCryptoScreen.copy']}
            variant={ButtonVariant.PrimaryLight}
            Icon={CopySvg}
            disabled={!savedLink}
          />
        </Buttons>
      )}
    </Container>
  );
});

const styles = StyleSheet.create({
  inputContainer: {flexGrow: 1, marginBottom: 0},
});

const Container = variance(View)(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
}));

const Wrapper = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 15,
  },
}));

const NameActionView = variance(View)(theme => ({
  root: {
    justifyContent: 'center',
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.uiSecondary,
  },
}));

const NameActionTouchable = variance(TouchableOpacity)(theme => ({
  root: {
    padding: 15,
    backgroundColor: theme.palette.uiPrimary,
  },
}));

const Label = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const LabelLink = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.info,
  },
}));

const LabelText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.textPrimary,
  },
}));

const ErrorContainer = variance(View)(() => ({
  root: {
    position: 'absolute',
    bottom: -25,
    flexDirection: 'row',
    maxWidth: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  static: {
    position: 'relative',
    bottom: 0,
    marginTop: 10,
  },
}));

const Buttons = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const ActionButton = variance(Button)(() => ({
  root: {
    flex: 1,
  },
  offsetRight: {
    marginRight: 15,
  },
}));

export const SmallActionButton = variance(TouchableOpacity)(theme => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    backgroundColor: theme.palette.additional3,
    borderRadius: 6,
  },

  simple: {
    backgroundColor: 'transparent',
    width: 40,
    maxWidth: 40,
    height: 40,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
}));
