import {action, makeObservable, observable, runInAction} from 'mobx';

import type {BaseAsyncOptions} from '../../Async';
import type {AccountIdStore} from '../../Auth';
import type {CancellationError} from '../../Error';
import {CANCELLATION_ERROR, TIMEOUT_ERROR} from '../../Error';
import type {ErrorRepository} from '../../ErrorRepository';
import {failure, success} from '../../fp';
import type {JsonRpcClient} from '../../JsonRpc';
import type {Maybe} from '../../Maybe';
import type {CurrencyCode} from '../../Money';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../../NCWalletServer';
import type {SentryLog} from '../../SentryLog';
import createJsonRpcTimeoutErrorMessage from '../../SentryLog/createJsonRpcTimeoutErrorMessage';
import type {BaseTransactionOptions} from '../../util';
import type {AcceptedRateHistoryResponse} from './AcceptedRateHistoryResponse';
import type {CurrenciesRateHistoryStore} from './CurrenciesRateHistoryStore';
import type {RateHistoryPeriod} from './RateHistoryPeriod';
import {rateHistoryPeriodToInterval} from './RateHistoryPeriod';

export default class CurrenciesRateHistoryStoreImpl
  implements CurrenciesRateHistoryStore
{
  @observable.ref private _historyMap = new Map<
    string,
    AcceptedRateHistoryResponse
  >();

  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly accountIdStore: AccountIdStore;
      readonly sentryLog: SentryLog;
    },
  ) {
    makeObservable(this);
  }

  getLatestHistoryRes<From extends CurrencyCode, To extends CurrencyCode>(
    from: From,
    to: To,
    period: RateHistoryPeriod,
  ) {
    return this._historyMap.get(
      CurrenciesRateHistoryStoreImpl.stringifyParams(from, to, period),
    ) as AcceptedRateHistoryResponse<From, To> | undefined;
  }

  async refreshLatestHistoryRes(
    from: CurrencyCode,
    to: CurrencyCode,
    period: RateHistoryPeriod,
    options?: BaseAsyncOptions & BaseTransactionOptions,
  ) {
    return this.refreshLatestHistoryBatched(
      new Set([from]),
      to,
      period,
      options,
    );
  }

  async refreshLatestHistoryBatched(
    from: Set<CurrencyCode>,
    to: CurrencyCode,
    period: RateHistoryPeriod,
    options?: BaseAsyncOptions & BaseTransactionOptions,
  ): Promise<Maybe<void>> {
    if (options?.signal?.aborted) {
      return failure(this._createCancellationError(options.signal.reason));
    }
    const end = new Date();
    const outcomeEntries = await Promise.all(
      [...from].map(from_currency =>
        this._root.ncWalletJsonRpcClient
          .call(
            'currencies.rate.history',
            {
              from_currency,
              to_currency: to,
              ...rateHistoryPeriodToInterval(period, end),
            },
            options,
          )
          .then(_ => [from_currency, _] as const),
      ),
    );

    const resultEntries: RateEntry[] = [];
    for (const outcomeEntry of outcomeEntries) {
      const [from_currency, outcome] = outcomeEntry;
      if (outcome.success) {
        resultEntries.push([from_currency, success(outcome.right.items)]);
      } else {
        if (outcome.left.kind === TIMEOUT_ERROR) {
          this._root.sentryLog.write(
            createJsonRpcTimeoutErrorMessage('[currencies.rate.history]', {
              from_currency,
            }),
          );
          resultEntries.push([from_currency, failure(outcome.left)]);
        } else {
          return outcome;
        }
      }
    }

    const newMap = new Map(this._historyMap);
    for (const resultEntry of resultEntries) {
      const [from_currency, result] = resultEntry;
      const key = CurrenciesRateHistoryStoreImpl.stringifyParams(
        from_currency,
        to,
        period,
      );
      newMap.set(key, result);
    }

    const _runInAction = options?.postpone ?? runInAction;
    _runInAction(() => {
      this._historyMap = newMap;
    });

    return success();
  }

  private static stringifyParams(
    from: CurrencyCode,
    to: CurrencyCode,
    period: RateHistoryPeriod,
  ) {
    return `${from}-${to}-${period}`;
  }

  @action.bound
  reset() {
    this._historyMap = new Map<string, AcceptedRateHistoryResponse>();
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }
}

type RateEntry = [from: CurrencyCode, response: AcceptedRateHistoryResponse];
