import type {
  AppleIdToken,
  Either,
  Email,
  ErrorRepository,
  GlobalError,
  Service,
  UserCancellationError,
} from '@ncwallet-app/core';
import {
  error,
  RouterImpl,
  success,
  UNKNOWN_ERROR,
  USER_CANCELLATION_ERROR,
} from '@ncwallet-app/core';
import type {Configuration} from '@ncwallet-app/core/src/Configuration';
import type {Json} from '@ncwallet-app/core/src/Json';
import type {UserPreferenceState} from '@ncwallet-app/core/src/Localization';
import type {AvailableLanguageCode} from '@ncwallet-app/core/src/Localization/constant';
import type {
  AppleOAuth2Credentials,
  AppleOAuth2Provider,
  OAuth2OutcomeMap,
} from '@ncwallet-app/core/src/OAuth2Provider';
import {ERROR, SUCCESS} from '@ncwallet-app/core/src/OAuth2Provider';
import {autorun} from 'mobx';
import {nanoid} from 'nanoid';

export default class WebAppleOAuth2ProviderService
  implements AppleOAuth2Provider, Service
{
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly json: Json;
      readonly configuration: Configuration;
      readonly userPreferenceState: UserPreferenceState;
    },
  ) {}

  signIn(): Either<void, GlobalError> {
    try {
      AppleID.auth
        .signIn()
        .then(_ => {
          if (_.user) {
            const user_ = this._root.json.stringify(_.user);
            if (user_.success) {
              localStorage.setItem('appleOAuthUser', user_.right);
            }
          }
          this._outcome.send(SUCCESS, {idToken: _.authorization.id_token});
        })
        .catch((_: unknown) => {
          let result: GlobalError;
          if (
            WebAppleOAuth2ProviderService._isSignInErrorI(_) &&
            _.error === 'user_cancelled_authorize'
          ) {
            result = this._root.errorRepository.create<UserCancellationError>({
              kind: USER_CANCELLATION_ERROR,
              raw: _,
            });
          } else {
            result = this._root.errorRepository.create({
              kind: UNKNOWN_ERROR,
              raw: _,
            });
          }
          this._outcome.send(ERROR, result);
        });
    } catch (raw) {
      return error(
        this._root.errorRepository.create({kind: UNKNOWN_ERROR, raw}),
      );
    }
    return success();
  }

  private readonly _outcome = new RouterImpl<
    OAuth2OutcomeMap<AppleOAuth2Credentials>
  >();

  get outcome(): AppleOAuth2Provider['outcome'] {
    return this._outcome;
  }

  subscribe() {
    return autorun(() => {
      this._appendScript(this._root.userPreferenceState.languageCode);
    });
  }

  private _previous: Node | undefined;

  private _appendScript(language: AvailableLanguageCode) {
    try {
      if (this._previous) {
        document.body.removeChild(this._previous);
      }
      const source = this._getScriptSource(language);
      const same = document.querySelector(`script[src="${source}"]`);
      if (same !== null) {
        return;
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = source;
      script.addEventListener('load', this._onLoad);
      document.body.appendChild(script);
      this._previous = script;
    } catch {
      /* empty */
    }
  }

  private readonly _onLoad = () => {
    AppleID.auth.init({
      clientId: this._root.configuration.values.appleOauthClientId,
      scope: WebAppleOAuth2ProviderService._SCOPE,
      redirectURI: this._getRedirectUri(),
      state: this._getAuthState(),
      nonce: nanoid(),
      usePopup: true,
    });
  };

  private _getRedirectUri() {
    return this._root.configuration.values.appleOauthRedirectUri;
  }

  // noinspection JSMethodCanBeStatic
  private _getAuthState() {
    return 'Initial user authentication request';
  }

  private _getScriptSource(language: AvailableLanguageCode) {
    const localeWithRegion =
      WebAppleOAuth2ProviderService.getLocaleWithRegion(language);
    return `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${localeWithRegion}/appleid.auth.js`;
  }

  private static _isSignInErrorI(_: unknown): _ is SignInErrorI {
    return (
      typeof _ === 'object' &&
      _ !== null &&
      typeof Reflect.get(_, 'error') === 'string'
    );
  }

  private static readonly _LOCALE_REGION_MAP = {
    de: 'DE',
    en: 'US',
    es: 'ES',
    fr: 'FR',
    it: 'IT',
    pt: 'BR',
    ru: 'RU',
  } as const;

  private static getLocaleWithRegion(language: AvailableLanguageCode) {
    const region = WebAppleOAuth2ProviderService._LOCALE_REGION_MAP[language];
    return `${language}_${region}`;
  }

  private static readonly _SCOPE = 'name email';
}

declare interface ClientConfigI {
  readonly clientId: string;
  readonly scope: string;
  readonly redirectURI: string;
  readonly state: string;
  readonly nonce?: string;
  readonly usePopup: boolean;
}

declare interface SignInResponseI {
  user?: UserI;
  authorization: AuthorizationI;
}

declare interface SignInErrorI {
  /**
   * @example 'user_cancelled_authorize'
   */
  error: string;
}

declare type UserI = {
  email: Email;
  name: NameI;
};

declare interface AuthorizationI {
  code: string;
  id_token: AppleIdToken;
  state: string;
}

declare type NameI = {
  firstName: string;
  lastName: string;
};

declare interface AuthI {
  readonly auth: {
    init(config: ClientConfigI): void;
    signIn(config?: ClientConfigI): Promise<SignInResponseI>;
  };
}

declare let AppleID: AuthI;
