import type {CryptoCurrencyCode} from '../Money';

export default function addPrefixForAddress(
  code: CryptoCurrencyCode,
  address: string,
) {
  return CURRENCY_PREFIX_MAP[code]
    ? `${CURRENCY_PREFIX_MAP[code]}:${address}`
    : address;
}

// При добавлении новой валюты следует вызывать в apps/mobile
// npx uri-scheme add <prefix> ( npx uri-scheme add bitcoin).
// Необходимо для работы deep link'ов и соответственно,
// открытию приложения по скану qr кода камерой снаружи приложения
// протестировать корректную работу в эмуляторе можно вызвав команду
//  npx uri-scheme open <prefix>:<address> --<platform>
// ( npx uri-scheme open bitcoin:bcrt1qa6j7ayqhnfapclra4xs05l2e9sw3jxdg8eavq --android )
export const CURRENCY_PREFIX_MAP = {
  BTC: 'bitcoin',
  ETH: 'ethereum',
  USDT: 'tether',
  MATIC: 'matic',
  TON: 'ton',
  TRX: 'tron',
};
