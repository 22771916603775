/* eslint-disable @typescript-eslint/unbound-method */
import type {FiatCurrency} from '@ncwallet-app/core/src/NCWalletServer/FiatCurrency';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useBaseFiatSettingsBindingState} from '../../../Navigation/HomeStack/BaseFiatSettingsBinding/useBaseFiatSettingsBindingState';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {BaseFiatSettingScreen} from '../../../screens/UserSettingsScreens/BaseFiatSettingScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptBaseFiatBindingProps =
  SmallHomeStackBindingProps<'PromptBaseFiatSetting'>;

export default observer(function PromptBaseFiatBinding(
  props: PromptBaseFiatBindingProps,
) {
  const {navigation} = props;
  const state = useBaseFiatSettingsBindingState();

  const handleFiatSelect = useCallback(
    async (fiat: FiatCurrency) => {
      void state.update(fiat);
      navigation.goBack();
    },
    [navigation, state],
  );

  return (
    <BaseFiatSettingScreen
      baseFiat={state.getBaseFiat}
      otherFiat={state.getOtherFiats}
      filter={state.filter}
      onFilterChange={state.setFilter}
      onFiatSelect={handleFiatSelect}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      keyboardAvoiding
    />
  );
});
