import {variance} from '@ncwallet-app/core';
import {View} from 'react-native';

// eslint-disable-next-line import-x/prefer-default-export
export const ModalContainer = variance(View)(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.chroma(theme.palette.blackout).alpha(0.3).hex(),
    borderRadius: 10,
  },
}));
