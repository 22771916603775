import {useTheme, variance} from '@ncwallet-app/core';
import {isChromeExtension} from '@ncwallet-app/navigation/src/Navigation/hooks/isChromeExtension';
import {observer} from 'mobx-react-lite';
import type {ReactNode} from 'react';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {
  BellSvg,
  BurgerSvg,
  ExpandSVG,
  QrSvg,
} from '../../../assets/svg/colorless';
import {TouchableOpacity} from '../../atoms/TouchableOpacity';
import {LG_BREAKPOINT} from '../../grid';
import {SafeAreaInset, SafeAreaLayout} from '../SafeAreaLayout';

export type RootScreenHeaderProps = {
  title: ReactNode;
  customTitle?: ReactNode;
  unreadNotificationsTotal: number;
  onBurgerPress: () => void;
  onNotificationsPress: () => void;
  showQrCodeButton: boolean;
  onQrCodePress: () => void;
  onExpandPress: () => void;
};

export default observer(function RootScreenHeader(
  props: RootScreenHeaderProps,
) {
  const {customTitle, showQrCodeButton} = props;
  const theme = useTheme();
  const isExtension = isChromeExtension();
  return (
    <RootView insets={SafeAreaInset.TOP}>
      <ContentView>
        <ColumnView>
          <TouchableOpacity
            hitSlop={HIT_SLOP}
            activeOpacity={0.8}
            onPress={props.onBurgerPress}
            testID="main-burger-btn">
            <ButtonContentView>
              <BurgerSvg color={theme.palette.whitey} />
            </ButtonContentView>
          </TouchableOpacity>
          <ButtonContentView>
            {isExtension && (
              <TouchableOpacity
                hitSlop={HIT_SLOP}
                activeOpacity={0.8}
                onPress={props.onExpandPress}>
                <ExpandSVG color={theme.palette.whitey} />
              </TouchableOpacity>
            )}
          </ButtonContentView>
        </ColumnView>
        <TitleContainer>
          {customTitle ? (
            customTitle
          ) : (
            <TitleView>
              <TitleText
                numberOfLines={1}
                adjustsFontSizeToFit
                minimumFontScale={0.7}>
                {props.title}
              </TitleText>
            </TitleView>
          )}
        </TitleContainer>
        <ColumnView>
          <TouchableOpacity
            activeOpacity={0.8}
            onPress={props.onNotificationsPress}>
            <ButtonContentView>
              <BellSvg color={theme.palette.whitey} />
              {props.unreadNotificationsTotal > 0 && (
                <NotificationsCounterView>
                  <NotificationCounterText>
                    {props.unreadNotificationsTotal}
                  </NotificationCounterText>
                </NotificationsCounterView>
              )}
            </ButtonContentView>
          </TouchableOpacity>
          {showQrCodeButton && (
            <TouchableOpacity
              style={styles.qrButton}
              onPress={props.onQrCodePress}
              activeOpacity={0.8}>
              <ButtonContentView>
                <QrSvg color={theme.palette.whitey} />
              </ButtonContentView>
            </TouchableOpacity>
          )}
        </ColumnView>
      </ContentView>
    </RootView>
  );
});

const HIT_SLOP = {top: 5, right: 5, bottom: 5, left: 5};

const styles = StyleSheet.create({
  qrButton: {
    marginLeft: 5,
  },
});

const RootView = variance(SafeAreaLayout)(theme => ({
  root: {
    backgroundColor: theme.palette.primary,
  },
}));

const ContentView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: theme.palette.primary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        height: 70,
      },
    }),
  },
}));

const TitleContainer = variance(View)(() => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const TitleView = variance(View)(theme => ({
  root: {
    borderRadius: 24,
    paddingHorizontal: 12,
    paddingVertical: 6,
    backgroundColor: theme.chroma(theme.palette.primary).darken(0.4).hex(),
  },
}));

const TitleText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    fontSize: 16,
    color: theme.palette.whitey,
    textAlign: 'center',
  },
}));

const ButtonContentView = variance(View)(() => ({
  root: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ColumnView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const NotificationsCounterView = variance(View)(theme => ({
  root: {
    position: 'absolute',
    width: 20,
    height: 15,
    top: 0,
    right: 0,
    borderRadius: 2,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.error,
  },
}));

const NotificationCounterText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.whitey,
    fontSize: 10,
  },
}));
