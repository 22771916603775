import {useRoot} from '@ncwallet-app/core';
import {isHomeScreen as checkIsHomeScreen} from '@ncwallet-app/core/src/Advert/SplashAdvertSpotStateService';
import {useEffect} from 'react';
import {BackHandler, Platform} from 'react-native';

// eslint-disable-next-line import-x/prefer-default-export
export const useHandleBack = () => {
  const {navigationContainerState, smallNavigationContainerBinding: binding} =
    useRoot();

  useEffect(() => {
    if (Platform.OS === 'android') {
      const subscription = BackHandler.addEventListener(
        'hardwareBackPress',
        () => {
          const isHomeScreen = checkIsHomeScreen(
            navigationContainerState.latestState,
          );
          const canGoBack = binding.ref?.canGoBack();
          if (canGoBack) {
            return false;
          }
          if (isHomeScreen) {
            binding.ref?.navigate('PromptCloseApp');
          } else {
            binding.ref?.reset({index: 0, routes: [{name: 'ListWallets'}]});
          }
          return true;
        },
      );

      return () => {
        subscription.remove();
      };
    }
  }, [navigationContainerState.latestState, binding]);
};
