import {useTheme} from '@ncwallet-app/core';
import React, {useMemo} from 'react';

import {
  BuySvg,
  ExchangeAltSvg,
  ReceiveSvg,
  SendSvg,
} from '../../../assets/svg/colored';
import {HistorySvg, PlusSvg} from '../../../assets/svg/colorless';
import type {WalletData} from './WalletAction';
import {WalletActionKind} from './WalletAction';

// eslint-disable-next-line import-x/prefer-default-export
export const useGetWalletData = () => {
  const theme = useTheme();

  return useMemo((): WalletData => {
    return {
      [WalletActionKind.Buy]: {
        name: 'currencyScreen.walletAction.buyTitle',
        description: 'currencyScreen.walletAction.buyDescription',
        icon: <BuySvg />,
      },
      [WalletActionKind.Exchange]: {
        name: 'currencyScreen.walletAction.exchangeTitle',
        description: 'currencyScreen.walletAction.exchangeDescription',
        icon: <ExchangeAltSvg color={theme.palette.textPrimary} />,
      },
      [WalletActionKind.Send]: {
        name: 'currencyScreen.walletAction.sendTitle',
        description: 'currencyScreen.walletAction.sendDescription',
        icon: <SendSvg color={theme.palette.textPrimary} />,
      },
      [WalletActionKind.Receive]: {
        name: 'currencyScreen.walletAction.receiveTitle',
        description: 'currencyScreen.walletAction.receiveDescription',
        icon: <ReceiveSvg color={theme.palette.textPrimary} />,
      },
      [WalletActionKind.History]: {
        name: 'currencyScreen.walletAction.historyTitle',
        description: 'currencyScreen.walletAction.historyDescription',
        icon: (
          <HistorySvg
            color={theme.palette.primary}
            secondColor={theme.palette.background}
          />
        ),
      },
      [WalletActionKind.Add]: {
        name: 'currencyScreen.walletAction.addTitle',
        description: 'currencyScreen.walletAction.addDescription',
        icon: <PlusSvg color={theme.palette.info} />,
      },
      [WalletActionKind.CreateNew]: {
        name: 'ButtonForReceive.empty.text',
        description: 'buttonForReceive.empty.description',
        icon: <PlusSvg color={theme.palette.info} />,
      },
    };
  }, [theme]);
};
