import {observer} from 'mobx-react-lite';
import React from 'react';

import {RPCTimeoutErrorModalScreen} from '../../screens/RPCTimeoutErrorModalScreen';

export type RPCTimeoutErrorBindingProps = {
  onClose: () => void;
};

export default observer(function RPCTimeoutErrorBinding(
  props: RPCTimeoutErrorBindingProps,
) {
  const {onClose} = props;
  return <RPCTimeoutErrorModalScreen onClose={onClose} />;
});
