import {sized, useRoot, useStrings, variance} from '@ncwallet-app/core';
import {SafeAreaInset, SafeAreaLayout} from '@ncwallet-app/ui';
import {
  ExchangeSvg,
  NCWalletLogoDarkSvg,
  NCWalletLogoSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import type {NativeScrollEvent, NativeSyntheticEvent} from 'react-native';
import {Platform, StyleSheet} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

import {Pagination} from './Pagination';
import type {SlideItem} from './Slide';
import {Slide} from './Slide';

type Event = NativeSyntheticEvent<NativeScrollEvent>;

// eslint-disable-next-line import-x/prefer-default-export
export const WelcomeSlider = observer(() => {
  const stepCarousel = useRef<ScrollView>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const strings = useStrings();
  const slides: SlideItem[] = useMemo(() => {
    return [
      {
        Icon: NCWalletIcon,
        title: strings['welcomeSlider.slide1.title'],
        description: strings['welcomeSlider.slide1.description'],
      },
      {
        Icon: ExchangeIconThemed,
        title: strings['welcomeSlider.slide2.title'],
        description: strings['welcomeSlider.slide2.description'],
      },
    ];
  }, [strings]);
  const totalItems = slides.length;
  const handleScrollEnd = useCallback((e: Event) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!e) {
      return;
    }
    const {nativeEvent} = e;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (nativeEvent && nativeEvent.contentOffset) {
      if (nativeEvent.contentOffset.x === 0) {
        setCurrentSlideIndex(0);
      } else {
        const approxCurrentSlide =
          nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width;
        setCurrentSlideIndex(parseInt(approxCurrentSlide.toFixed(0), 10));
      }
    }
  }, []);

  const handlePaginationDotPress = useCallback((idx: number) => {
    if (idx === 1) {
      stepCarousel.current?.scrollToEnd({animated: true});
    } else {
      stepCarousel.current?.scrollTo({x: 0, y: 0, animated: true});
    }
  }, []);

  return (
    <Root insets={SafeAreaInset.TOP}>
      <ScrollView
        overScrollMode="never"
        style={styles.scrollView}
        contentContainerStyle={[
          styles.contentContainer,
          {width: `${slides.length * 100}%`},
        ]}
        ref={stepCarousel}
        horizontal
        dataSet={{'app-list': 'root-horizontal'}}
        pagingEnabled={Platform.OS !== 'web'}
        decelerationRate="fast"
        snapToAlignment="center"
        scrollEventThrottle={16}
        showsHorizontalScrollIndicator={false}
        onScroll={handleScrollEnd}>
        {slides.map((_, index) => (
          <Slide
            key={index}
            style={styles.slide}
            dataSet={{'app-list': 'page'}}
            {..._}
          />
        ))}
      </ScrollView>
      <Pagination
        totalItems={totalItems}
        currentSlideIndex={currentSlideIndex}
        onPress={handlePaginationDotPress}
      />
    </Root>
  );
});

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  contentContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  slide: {
    flex: 1,
  },
});

const NCWalletIcon = observer(() => {
  const {appearance} = useRoot();
  if (appearance.isDark) {
    return <NCWalletLogoIcon />;
  }
  return <NCWalletLogoDarkIcon />;
});

const ExchangeIconThemed = observer(() => {
  const {appearance} = useRoot();
  return <ExchangeIcon color={appearance.theme.palette.textMain} />;
});

const NCWalletLogoIcon = sized(NCWalletLogoSvg, 110, 104);
const ExchangeIcon = sized(ExchangeSvg, 100);
const NCWalletLogoDarkIcon = sized(NCWalletLogoDarkSvg, 110, 104);

const Root = variance(SafeAreaLayout)(theme => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    zIndex: -1,
  },
}));
