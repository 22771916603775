import {createNullableContext} from '@ncwallet-app/core';
import type {LayoutRectangle} from 'react-native';

export type TabBarLayout = {
  width: number;
  height: number;
};

export type TabBarContextProps = {
  changeLayout: (layout: LayoutRectangle) => void;
  visible: boolean;
  open: () => void;
  hide: () => void;
  getTabBarLayout: () => TabBarLayout;
};

export const TabBarContext = createNullableContext<TabBarContextProps>();
