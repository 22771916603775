import {useRoot, useStrings, useTheme, variance} from '@ncwallet-app/core';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {GearSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo} from 'react';
import {Platform, Text, View} from 'react-native';

import {NotificationsScreen} from '../../../../../../screens/NotificationsScreen';
import {useNotifications} from '../../../../../../screens/NotificationsScreen/useNotifications';

export type NotificationsMenuProps = {
  onNotificationSettings?: () => void;
};

export default observer(function NotificationsMenu({
  onNotificationSettings,
}: NotificationsMenuProps) {
  const {refresh, getIsRefreshing, getNotificationItems, onEndReached} =
    useNotifications();
  const strings = useStrings();
  const {windowDimensionsState} = useRoot();
  const {height, width} = windowDimensionsState.window;

  const containerStyle = useMemo(
    () => ({
      height: height - 100,
      right: (width - 1210) / 2,
      ...Platform.select({
        web: {
          right: 30,
        },
      }),
    }),
    [height, width],
  );

  useEffect(() => {
    void refresh();
  }, [refresh]);
  const theme = useTheme();
  return (
    <Container style={containerStyle}>
      <Header>
        <Title>{strings['headerNotificationsPopup.title']}</Title>
        <TouchableOpacity onPress={onNotificationSettings}>
          <GearSvg color={theme.palette.uiMain} />
        </TouchableOpacity>
      </Header>
      <NotificationsScreen
        getIsRefreshing={getIsRefreshing}
        getNotificationItems={getNotificationItems}
        onRefresh={refresh}
        onEndReached={onEndReached}
      />
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    position: 'absolute',
    top: -5,
    paddingTop: 20,
    paddingHorizontal: 15,
    backgroundColor: theme.palette.uiPrimary,
    borderRadius: 10,
    maxWidth: 375,
    width: '100%',
    maxHeight: 670,
    flex: 1,
    bottom: 0,
  },
}));

const Header = variance(View)(theme => ({
  root: {
    padding: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiAdditional2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 19,
    color: theme.palette.textPrimary,
  },
}));
