import {useCallback} from 'react';
import type {EmitterSubscription} from 'react-native';
import {Keyboard} from 'react-native';

export default function useKeyboardDismiss<
  T extends (...args: never[]) => void,
>(callback: T) {
  return useCallback(
    (...args: Parameters<T>) => {
      let keyboardSub: EmitterSubscription | undefined;
      const handleAction = () => {
        keyboardSub?.remove();
        callback(...args);
      };
      if (Keyboard.isVisible()) {
        keyboardSub = Keyboard.addListener('keyboardDidHide', handleAction);
        Keyboard.dismiss();
        return;
      }
      handleAction();
    },
    [callback],
  );
}
