import type {ISODateString, OtpCode} from '@ncwallet-app/core';
import {useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {CommonActions} from '@react-navigation/native';
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef} from 'react';

import {useBaseErrorDescription} from '../../../CommonNavigationContainers/hooks';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {useSendTelegramOtp} from '../../../Navigation/hooks/useSendTelegramOtp';
import {
  CARD,
  SubmitOtpScreen,
} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptOtpToDisableTwoFaBindingProps =
  SmallHomeStackBindingProps<'PromptOtpToDisableTwoFa'>;

export default observer(function PromptOtpToDisableTwoFaBinding(
  props: PromptOtpToDisableTwoFaBindingProps,
) {
  const {navigation, route} = props;
  const root = useRoot();
  const [getError, setError, resetError] = useBaseErrorDescription();
  const isSubmittingRef = useRef(false);
  const strings = useStrings();
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();

  const onSubmit = useCallback(
    async (code: string) => {
      if (isSubmittingRef.current) {
        return;
      }

      isSubmittingRef.current = true;
      const delete_ = await root.ncWalletJsonRpcClient.call(
        'accounts.totp.delete',
        {
          code_2fa: code as OtpCode,
          utc_2fa: dayjs().utc().toISOString() as ISODateString,
        },
      );
      isSubmittingRef.current = false;
      if (!delete_.success) {
        setError(delete_.left);
      } else {
        await root.jsonSecureKeyValueStore.delete('twoFaSecret');
        await root.twoFaSettingsState.refresh();
        if (hasRouteInHistory(navigation.getState(), 'ListSecuritySettings')) {
          navigation.navigate('ListSecuritySettings');
        } else {
          navigation.dispatch(
            CommonActions.reset({
              index: 1,
              routes: [{name: 'Root'}, {name: 'ListSecuritySettings'}],
            }),
          );
        }

        root.flashMessage.showMessage({
          title:
            strings[
              'secureTwoFactorAuthScreen.disableTwoFactorAuth.successMessage'
            ],
          variant: 'danger',
        });
      }
    },
    [root, setError, navigation, strings],
  );

  const handleTwoFaResendCode = useCallback(async () => {
    try {
      await sendOtp(null, {forceChannel: false});
    } catch {
      /* empty */
    }
  }, [sendOtp]);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (getIsReady() && getIsFocused()) {
      if (
        root.twoFaSettingsState.currentTwoFaProvider ===
        TwoFaProviderKind.Telegram
      ) {
        void handleTwoFaResendCode();
      }
    }
  }, [getIsReady, getIsFocused, root, handleTwoFaResendCode]);

  return (
    <SubmitOtpScreen
      layout={CARD}
      resetError={resetError}
      getError={getError}
      resendCodeInterval={interval}
      resetInterval={resetInterval}
      twoFaProvider={
        route.params?.providerKind ?? route.params?.redirectToEnableKind
      }
      onTwoFaResendCode={
        !root.twoFaSettingsState.currentTwoFaProvider ||
        root.twoFaSettingsState.currentTwoFaProvider ===
          TwoFaProviderKind.Telegram
          ? handleTwoFaResendCode
          : undefined
      }
      titleText={
        strings['secureTwoFactorAuthScreen.disableTwoFactorAuth.title']
      }
      submitText={
        strings['secureTwoFactorAuthScreen.disableTwoFactorAuth.button']
      }
      onSubmit={onSubmit}
    />
  );
});
