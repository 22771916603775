import type {Either, GoogleIdToken, TelegramBotName} from '@ncwallet-app/core';
import {useRoot, useStrings} from '@ncwallet-app/core';
import {ERROR} from '@ncwallet-app/core/src/OAuth2Provider';
import {useCallback, useEffect} from 'react';
import {Platform} from 'react-native';

import type {AuthVariant} from '../../screens/AuthScreen';
import {OAuthVariant} from '../../screens/AuthScreen';
import useGoogleAuth from '../../screens/AuthScreen/hooks/useGoogleAuth';

// eslint-disable-next-line import-x/no-anonymous-default-export
export default () => {
  const root = useRoot();
  const strings = useStrings();
  const signInWithGoogle = useCallback(
    (outcome: Either<string, unknown>) => {
      if (!outcome.success) {
        if (
          outcome.left instanceof Error &&
          isTimeRelatedIssue(outcome.left.message)
        ) {
          root.flashMessage.showMessage({
            timeout: 15000,
            variant: 'danger',
            title: strings.Chain_Validation_Faild_Title,
            text: strings.Chain_Validation_Faild_Description,
          });
        }
        return;
      }

      void root.authHelper.preSignIn({
        provider: 'google',
        token: outcome.right as GoogleIdToken,
      });
    },
    [root, strings],
  );
  const initiateGoogleAuth = useGoogleAuth(signInWithGoogle);
  useEffect(() => {
    if (Platform.OS === 'ios') {
      return root.appleOAuth2Provider.outcome.listen(ERROR, () => {
        root.flashMessage.showMessage({
          timeout: 15000,
          variant: 'danger',
          // eslint-disable-next-line @typescript-eslint/no-deprecated
          title: root.translation.strings.Transaction_Failed,
        });
      });
    }
  }, [root]);
  return useCallback(
    async (_: AuthVariant) => {
      switch (_) {
        case OAuthVariant.Telegram:
          await root.authHelper.preSignIn({
            provider: 'telegram',
            token: root.telegramMiniApp.initData,
            telegram_bot_name: root.configuration.values
              .telegramBotName as TelegramBotName,
          });
          break;
        case OAuthVariant.Apple:
          root.appleOAuth2Provider.signIn();
          break;
        case OAuthVariant.Google:
          if (root.googleOAuth2Provider) {
            root.googleOAuth2Provider.signIn();
          } else {
            await initiateGoogleAuth();
          }
          break;
      }
    },
    [root, initiateGoogleAuth],
  );
};

function isTimeRelatedIssue(message: string) {
  switch (Platform.OS) {
    case 'android':
      return message === 'NETWORK_ERROR';
    case 'ios':
      return message.includes('Code=-15');
  }
  return false;
}
