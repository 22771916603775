import {action} from 'mobx';

import type {AccountIdStore} from '../Auth';
import {LOG_OUT} from '../Auth';
import type {Service} from '../structure';
import type {OnLogoutResetService} from './OnLogoutResetService';
import type {Resettable} from './Resettable';

export default class OnLogoutResetServiceImpl
  implements OnLogoutResetService, Service
{
  constructor(
    private readonly _root: {
      readonly accountIdStore: AccountIdStore;
    } & Record<(typeof SERVICE_NAME_LIST)[number], Resettable>,
  ) {}

  subscribe() {
    return this._root.accountIdStore.events.listen(
      LOG_OUT,
      action(() => {
        for (const name of SERVICE_NAME_LIST) {
          this._root[name].reset();
        }
      }),
    );
  }
}

const SERVICE_NAME_LIST = [
  'accountStore',
  'currencyStore',
  'walletStore',
  'walletsBalanceHistoryStore',
  'lastTransactionStore',
  'currenciesRateStore',
  'currenciesRateHistoryStore',
  'activeSessionsStore',
  'accountDocumentStore',
  'retryStrategyState',
] as const;
