import {useMemo} from 'react';

import {useRoot} from '../Root/hooks';
import {SendAddressValidatorError} from './SendAddressValidator';

export default function useSendAddressErrorTexts() {
  const {localization} = useRoot();

  return useMemo(
    (): Record<SendAddressValidatorError, string> => ({
      [SendAddressValidatorError.EmptyAddress]: localization.getTranslation(
        'sendCrypto.validation.addressShouldBeNonEmpty',
      ),
      [SendAddressValidatorError.CannotBePerformedOnUserAddress]:
        localization.getTranslation(
          'sendCrypto.validation.sendCannotBePerformedOnUserAddress',
        ),
      [SendAddressValidatorError.AddressInvalid]: localization.getTranslation(
        'sendCrypto.validation.addressInvalid',
      ),
      [SendAddressValidatorError.Aborted]: 'The operation aborted.',
    }),
    [localization],
  );
}
