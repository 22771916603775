import type {CancellablePromiseEither} from '../../CancellablePromise';
import type {CancellationError, GlobalError} from '../../Error';
import {CANCELLATION_ERROR} from '../../Error';
import type {ErrorRepository} from '../../ErrorRepository';
import type {Either} from '../../fp';
import {error, success} from '../../fp';
import type {RouterMap, RouterSource} from '../Router';
import type {RouterHelper} from './RouterHelper';

export default class RouterHelperImpl<M extends RouterMap>
  implements RouterHelper<M>
{
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
    },
    private readonly _source: RouterSource<M>,
  ) {}

  when<K extends keyof M>(
    theme: K,
    condition?: (...args: Parameters<M[K]>) => boolean,
  ): CancellablePromiseEither<Parameters<M[K]>, GlobalError> {
    let cancel: () => void;
    const promise = new Promise<Either<Parameters<M[K]>, CancellationError>>(
      resolve => {
        const listener = ((...args: Parameters<M[K]>) => {
          if (condition === undefined || condition(...args)) {
            this._source.forget(theme, listener);
            resolve(success(args));
          }
        }) as M[K];
        cancel = () => {
          this._source.forget(theme, listener);
          resolve(
            error(
              this._root.errorRepository.create<CancellationError>({
                kind: CANCELLATION_ERROR,
              }),
            ),
          );
        };
        this._source.listen(theme, listener);
      },
    );
    Reflect.set(promise, 'cancel', () => {
      cancel();
    });
    return promise as CancellablePromiseEither<Parameters<M[K]>, never>;
  }
}
