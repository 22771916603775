import {variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import type {FC} from 'react';
import React from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {Text, View} from 'react-native';

type SettingsGroupTitleProps = {
  first?: boolean;
  title: string;
  Tag?: React.ReactElement | null;
  style?: StyleProp<ViewStyle>;
};

// eslint-disable-next-line import-x/prefer-default-export
export const SettingsGroupTitle: FC<SettingsGroupTitleProps> = observer(
  props => {
    const {Tag} = props;
    return (
      <Row first={props.first} style={props.style}>
        <Title>{props.title}</Title>
        {Tag && Tag}
      </Row>
    );
  },
);

const Row = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingBottom: 16,
    paddingTop: 40,
    borderBottomWidth: 1,
    borderColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingTop: 15,
        paddingHorizontal: 0,
        paddingBottom: 10,
        borderBottomWidth: 0,
      },
    }),
  },
  first: {
    paddingTop: 20,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingTop: 0,
      },
    }),
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    fontSize: 18,
    lineHeight: 22,
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 14,
        lineHeight: 17,
      },
    }),
  },
}));
