import type {
  WalletId,
  WalletListItemData,
  WalletSlideData,
} from '@ncwallet-app/core';
import {useTheme, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps, InsetsProps} from '@ncwallet-app/ui';
import {CommonRefreshControl, OrangeShapeBackground} from '@ncwallet-app/ui';
import {LinearGradient} from 'expo-linear-gradient';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useCallback, useMemo} from 'react';
import {Platform, View} from 'react-native';

import {WalletList} from './WalletList/WalletList';
import {WalletListHeader} from './WalletListHeader';
import WalletsSlider from './WalletsSlider';

export type SmallWalletsScreenProps = BaseSafeAreaProps & {
  getTotal: () => WalletSlideData | undefined;
  getWallets: () => WalletListItemData[] | undefined;
  onWalletSelect: (d: WalletListItemData) => void;
  onCurrencyAdd: () => void;
  insets?: InsetsProps;
  getIsLoading: () => boolean;
  getIsManualRefresh: () => boolean;
  onRefresh: () => void;
  hideWallet: (id: WalletId) => void;
  onLastTransactionPress: () => void;
};

export default observer(function SmallWalletsScreen(
  props: SmallWalletsScreenProps,
) {
  const {
    getTotal,
    getWallets,
    onWalletSelect,
    onCurrencyAdd,
    getIsLoading,
    getIsManualRefresh,
    onRefresh,
    onLastTransactionPress,
    ...rest
  } = props;
  const theme = useTheme();

  const getWalletCount = useCallback(
    () => expr(() => getWallets()?.filter(_ => _.is_visible).length),
    [getWallets],
  );
  const getIsRefreshing = useCallback(
    () => expr(() => getIsLoading() && getIsManualRefresh()),
    [getIsLoading, getIsManualRefresh],
  );

  const refreshControl = useMemo(
    () => (
      <WalletsRefreshControl
        getIsRefreshing={getIsRefreshing}
        onRefresh={onRefresh}
      />
    ),
    [getIsRefreshing, onRefresh],
  );

  const ListHeader = useCallback(
    () => (
      <>
        <Container
          colors={[
            theme.palette.mainCardBackgroundStart,
            theme.palette.mainCardBackgroundEnd,
          ]}>
          <OrangeShapeBackground />
          <WalletsSlider
            getWallet={getTotal}
            onLastTransactionPress={onLastTransactionPress}
          />
        </Container>
        <WalletListHeader
          getWalletCount={getWalletCount}
          onAddCurrencyPress={onCurrencyAdd}
        />
      </>
    ),
    [theme, onCurrencyAdd, getTotal, getWalletCount, onLastTransactionPress],
  );

  return (
    <Root>
      <WalletList
        ListHeaderComponent={ListHeader}
        getWallets={getWallets}
        onWalletPress={onWalletSelect}
        refreshControl={refreshControl}
        onCurrencyAddPress={onCurrencyAdd}
        {...rest}
      />
    </Root>
  );
});

const Root = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const Container = variance(LinearGradient)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    overflow: 'hidden',
  },
}));

const WalletsRefreshControl = variance(CommonRefreshControl)(
  () => ({root: {}}),
  theme => ({
    ...Platform.select({
      ios: {
        tintColor: theme.palette.textMain,
        titleColor: theme.palette.textMain,
      },
    }),
  }),
);
