import {variance} from '@ncwallet-app/core';
import {Pressable} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type SettingsMenuItemProps = {
  onPress: () => void;
  icon: React.ReactNode;
  title: string;
  active: boolean;
  disabled?: boolean;
  testID?: string;
};

export default observer(function SettingsMenuItem({
  onPress,
  icon,
  title,
  active,
  disabled,
  testID,
}: SettingsMenuItemProps) {
  return (
    <Container
      disabled={disabled}
      isDisabled={disabled}
      underlayColor="transparent"
      dataSet={{app: 'setting-item'}}
      onPress={onPress}
      testID={testID}>
      <IconContainer>{icon}</IconContainer>
      <Title active={active}>{title}</Title>
    </Container>
  );
});

const Container = variance(Pressable)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 3,
    paddingLeft: 3,
    paddingRight: 15,
    backgroundColor: theme.palette.uiPrimary,
    marginRight: 15,
    borderRadius: 8,
  },
  isDisabled: {
    opacity: 0.5,
  },
}));

const IconContainer = variance(View)(theme => ({
  root: {
    height: 38,
    width: 38,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    backgroundColor: theme.palette.uiAdditional2,
    marginRight: 15,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.textPrimary,
  },

  active: {
    color: theme.palette.primary,
  },
}));
