import type {ISODateString, OtpCode} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import dayjs from 'dayjs';
import {useCallback, useState} from 'react';

import {useBaseErrorDescription} from '..';

// eslint-disable-next-line import-x/prefer-default-export
export const useAddIpWithOtp = (goToIpList: (type: IpType) => void) => {
  const root = useRoot();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [getErrorText, setErrorText, resetErrorText] =
    useBaseErrorDescription();

  const addWithOtp = useCallback(
    async (ip: string, name: string, type: IpType, code: string) => {
      if (isSubmitting) {
        return;
      }

      setIsSubmitting(true);
      const res = await root.ncWalletJsonRpcClient.call(
        'accounts.ip_settings.add',
        {
          ip,
          name,
          type,
          code_2fa: code as OtpCode,
          utc_2fa: dayjs().utc().toISOString() as ISODateString,
        },
      );

      setIsSubmitting(false);
      if (res.success) {
        goToIpList(type);
      } else {
        setErrorText(res.left);
      }
    },
    [goToIpList, isSubmitting, root.ncWalletJsonRpcClient, setErrorText],
  );

  return {
    addWithOtp,
    getErrorText,
    resetErrorText,
  };
};
