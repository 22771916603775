import type {Semver} from '@ncwallet-app/core';
import {useTheme, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  CenteredCardLayout,
  LG_BREAKPOINT,
  SafeAreaInset,
  SafeAreaLayout,
  TouchableOpacity,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {ArrowLeftSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import ErrorWalletLogo from '@ncwallet-app/ui/src/components/atoms';
import {MaintenanceWalletLogo} from '@ncwallet-app/ui/src/components/atoms/ErrorWalletLogo';
import {observer} from 'mobx-react-lite';
import type {ComponentType} from 'react';
import React from 'react';
import {Text, View} from 'react-native';

export type AuthLoginErrorScreenProps = {
  appVersion: Semver;
  cancelText: string;
  onCancel: () => void;
  errorMessage: string;
  errorDescription?: string;
  onBack?: () => void;
  LogButton?: ComponentType;
  errorContent?: string;
  isMaintenance?: boolean;
  nextText?: string;
  onNext?: () => void;
};

export const AuthErrorScreen = observer(function AuthErrorScreen(
  props: AuthLoginErrorScreenProps,
) {
  const LogButton = props.LogButton;
  const {errorContent, isMaintenance} = props;
  const isLgScreen = useIsDimensions('lg');
  const theme = useTheme();
  return (
    <Root
      appVersion={props.appVersion}
      onBackPress={props.onBack}
      hideBackButton={isMaintenance}>
      {!isLgScreen && (
        <SmHeaderContainer>
          {!isMaintenance && (
            <TouchableOpacity onPress={props.onBack}>
              <ArrowLeftSvg color={theme.palette.uiMain} />
            </TouchableOpacity>
          )}
          <AppVersion style={{marginLeft: 'auto'}}>
            v{props.appVersion}
          </AppVersion>
        </SmHeaderContainer>
      )}
      <LogoContainer>
        {isMaintenance ? <MaintenanceWalletLogo /> : <ErrorWalletLogo />}
      </LogoContainer>

      <ErrorTitle maintenance={isMaintenance}>{props.errorMessage}</ErrorTitle>
      {props.errorDescription !== undefined && (
        <ErrorCaption>{props.errorDescription}</ErrorCaption>
      )}
      {errorContent !== undefined && (
        <ErrorCaption fadeout>{errorContent}</ErrorCaption>
      )}
      <ActionsContainer insets={SafeAreaInset.BOTTOM}>
        {LogButton && <LogButton />}

        <GoBackButton
          title={props.cancelText}
          onPress={props.onCancel}
          variant={ButtonVariant.PrimaryLight}
          color={ButtonColor.Secondary}
        />
        {props.nextText && props.onNext && (
          <GoBackButton
            title={props.nextText}
            onPress={props.onNext}
            variant={ButtonVariant.Primary}
            color={ButtonColor.Secondary}
          />
        )}
      </ActionsContainer>
    </Root>
  );
});

const Root = variance(CenteredCardLayout)(theme => ({
  root: {
    zIndex: 2,
    paddingHorizontal: 15,
    paddingTop: 30,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingTop: 0,
        paddingHorizontal: 0,
      },
    }),
  },
}));

const AppVersion = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional2,
    fontSize: 13,
    textAlign: 'right',
  },
}));

const LogoContainer = variance(View)(theme => ({
  root: {
    position: 'relative',
    width: 104,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 45,
    transform: [{translateX: 15}],
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        transform: [{translateX: 0}],
        marginTop: 'auto',
        marginBottom: 40,
      },
    }),
  },
}));

const ErrorTitle = variance(Text)(theme => ({
  root: {
    marginBottom: 15,
    ...theme.fontByWeight('700'),
    fontSize: 18,
    color: theme.palette.error,
    textAlign: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 24,
        lineHeight: 46,
        paddingHorizontal: 20,
      },
    }),
  },
  maintenance: {
    color: theme.palette.primary,
  },
}));

const ErrorCaption = variance(Text)(theme => ({
  root: {
    marginBottom: 15,
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional1,
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 20,
      },
    }),
  },
  fadeout: {
    color: theme.palette.textAdditional2,
  },
}));

const ActionsContainer = variance(SafeAreaLayout)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const GoBackButton = variance(Button)(theme => ({
  root: {
    marginBottom: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 35,
        maxWidth: 345,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
      },
    }),
  },
}));

const SmHeaderContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 120,
    width: '100%',
  },
}));
