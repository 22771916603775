export default function divideBy<A, B>(
  array: (A | B)[],
  detector: Detector<A, B>,
): Group<A, B>[] {
  if (array.length === 0) {
    return [];
  }
  let current: Group<A, B>;
  const first = array[0];
  current = {isTarget: detector(first), chunk: [first]} as Group<A, B>;
  const result: Group<A, B>[] = [];
  for (let i = 1; i < array.length; ++i) {
    const value = array[i];
    const isTarget = detector(value);
    if (current.isTarget === isTarget) {
      current.chunk.push(value as A & B);
    } else {
      result.push(current);
      current = {isTarget, chunk: [value]} as Group<A, B>;
    }
  }
  result.push(current);
  return result;
}

export type Detector<A, B> = (_: A | B) => _ is A;

export type Group<A, B> =
  | {chunk: Chunk<A>; isTarget: true}
  | {chunk: Chunk<B>; isTarget: false};

export type Chunk<T> = [...T[], T];
