import {urlLinks, useRoot} from '@ncwallet-app/core';
import {useCallback} from 'react';
import {Platform} from 'react-native';

export default function useGoToSupport() {
  const root = useRoot();
  return useCallback(() => {
    if (Platform.OS === 'web') {
      window.open(urlLinks.support);
    } else {
      void root.location.open(urlLinks.support);
    }
  }, [root.location]);
}
