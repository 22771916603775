import {getLocales} from 'expo-localization';

import type {AppWindow} from '../AppWindow';
import {APP_WINDOW_BACKGROUND, APP_WINDOW_BLUR} from '../AppWindow';
import type {BusSource, Service} from '../structure';
import {BusImpl} from '../structure';
import type {Locale, SystemLocaleProvider} from './SystemLocaleProvider';

export default class SystemLocaleProviderService
  implements SystemLocaleProvider, Service
{
  private _locales = new BusImpl<(_: Locale[]) => unknown>();
  private _reportedLocales: Locale[] = this.getLocales();

  constructor(private readonly _root: {readonly appWindow: AppWindow}) {}

  getLocales(): Locale[] {
    return getLocales().map(_ => ({
      languageCode: _.languageCode ?? 'en',
      languageTag: _.languageTag,
    }));
  }

  get locales(): BusSource<(_: Locale[]) => unknown> {
    return this._locales;
  }

  subscribe() {
    return this._root.appWindow.updates.domain.listen(event => {
      if (
        event.theme !== APP_WINDOW_BACKGROUND &&
        event.theme !== APP_WINDOW_BLUR
      ) {
        const next = this.getLocales();
        if (
          !SystemLocaleProviderService._compareLocalesStructurally(
            this._reportedLocales,
            next,
          )
        ) {
          this._locales.send(next);
          this._reportedLocales = next;
        }
      }
    });
  }

  /**
   * Returns true if the values are equal
   * @param a
   * @param b
   * @private
   */
  private static _compareLocalesStructurally(a: Locale[], b: Locale[]) {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      // checking sparse arrays
      if (!(i in a) && !(i in b)) {
        continue;
      }
      if (i in a && !(i in b)) {
        return false;
      }
      if (!(i in a) && i in b) {
        return false;
      }
      // checking values
      if (
        !SystemLocaleProviderService._compareRecordsStructurally(a[i], b[i])
      ) {
        return false;
      }
    }
    return true;
  }

  /**
   * Returns true if the values are equal
   * @param a
   * @param b
   * @private
   */
  private static _compareRecordsStructurally(
    a: Record<string | number | symbol, unknown>,
    b: Record<string | number | symbol, unknown>,
  ) {
    for (const key of Object.keys(a)) {
      if (!(key in b)) {
        return false;
      }
      if (!Object.is(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }
}
