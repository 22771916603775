import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import BaseSkeleton, {SkeletonColor} from '../../atoms/BaseSkeleton';

export default observer(function CurrencySkeletonItem() {
  return (
    <SkeletonListItem>
      <View>
        <BaseSkeleton width={40} height={40} isRounded />
      </View>
      <SkeletonListItemMiddle>
        <BaseSkeleton width={55} height={20} gap={5} />
        <BaseSkeleton width={105} height={15} color={SkeletonColor.Light} />
      </SkeletonListItemMiddle>
    </SkeletonListItem>
  );
});

const SkeletonListItem = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 25,
    paddingLeft: 15,
    paddingVertical: 14,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const SkeletonListItemMiddle = variance(View)(() => ({
  root: {
    marginRight: 'auto',
    marginLeft: 15,
  },
}));
