import {
  InSvg,
  PtSvg,
  RdSvg,
  TgSvg,
  TwSvg,
  VkSvg,
  WaSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import type {ReactNode} from 'react';
import React from 'react';

type Item = {
  t: string;
  Icon: ReactNode;
};

const iconProps = {
  width: 30,
  height: 30,
};

// eslint-disable-next-line import-x/prefer-default-export
export const config: Item[] = [
  {
    t: 'wa',
    Icon: <WaSvg {...iconProps} />,
  },
  {
    t: 'li',
    Icon: <InSvg {...iconProps} />,
  },
  {
    t: 'pt',
    Icon: <PtSvg {...iconProps} />,
  },
  {
    t: 'rd',
    Icon: <RdSvg {...iconProps} />,
  },
  {
    t: 'tg',
    Icon: <TgSvg {...iconProps} />,
  },
  {
    t: 'tw',
    Icon: <TwSvg {...iconProps} />,
  },
  {
    t: 'vk',
    Icon: <VkSvg {...iconProps} />,
  },
];
