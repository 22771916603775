import type {
  PromptFeedbackRoute,
  RouteTransition,
  StarValue,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {autorun, runInAction} from 'mobx';
import {useEffect} from 'react';

import type {RateModalBindingState} from './RateModalBindingState';

export default function usePromptFeedback(
  getIsFocused: () => boolean,
  promptFeedback: RouteTransition<PromptFeedbackRoute>,
  state: RateModalBindingState,
) {
  useEffect(
    () =>
      autorun(() => {
        if (
          state.isRatingSet &&
          !state.isRatingGood &&
          runInAction(getIsFocused)
        ) {
          runInAction(() => {
            promptFeedback({rating: state.getRating() as StarValue});
          });
        }
      }),
    [getIsFocused, promptFeedback, state],
  );
}
