import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import type {PromptCryptoToSendContainerProps} from '../../../../../CommonNavigationContainers/hooks/usePromptCryptoToSendContainer';
import {usePromptCryptoToSendContainer} from '../../../../../CommonNavigationContainers/hooks/usePromptCryptoToSendContainer';
import {useTabBarLayout} from '../../../../../components';
import SelectionForSendScreen from '../../../../../screens/SelectionForSendScreen/SelectionForSendScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptCryptoToSendBindingProps =
  LargeSwitchBindingProps<'PromptCryptoToSend'>;

export default observer(function PromptCryptoToSendBinding(
  props: PromptCryptoToSendBindingProps,
) {
  const layout = useTabBarLayout();
  const {navigation} = props;

  const containerProps = useMemo<PromptCryptoToSendContainerProps>(
    () => ({
      params: props.route.params,
      promptNewWallet: () => {
        navigation.navigate('PromptNewWallet');
      },
      promptCryptoToReceive: () => {
        navigation.navigate('PromptCryptoToReceive');
      },
      promptSourceCryptoToExchange: () => {
        navigation.navigate('PromptSourceCryptoToExchange');
      },
      promptOutputAddress: params => {
        navigation.navigate('PromptOutputAddress', params);
      },
      goBack: () => {
        navigation.goBack();
      },
    }),
    [navigation, props.route.params],
  );

  const screenProps = usePromptCryptoToSendContainer(containerProps);

  return (
    <SelectionForSendScreen
      {...screenProps}
      isLg={true}
      keyboardAvoiding
      extra={{bottom: layout.height}}
    />
  );
});
