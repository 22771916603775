import {CommonActions} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptOtpToSendCryptoContainer from '../../../CommonNavigationContainers/containers/PromptOtpToSendCryptoContainer';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptOtpToSendCryptoBindingProps =
  SmallHomeStackBindingProps<'PromptOtpToSendCrypto'>;

export default observer(function PromptOtpToSendCryptoBinding(
  props: PromptOtpToSendCryptoBindingProps,
) {
  const {navigation, route} = props;
  const {withdrawId} = route.params;

  const notifyAboutSuccessfulSending = useCallback(() => {
    if (
      hasRouteInHistory(navigation.getState(), 'NotifyAboutSuccessfulSending')
    ) {
      navigation.navigate('NotifyAboutSuccessfulSending', route.params);
    } else {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            {name: 'Root'},
            {name: 'NotifyAboutSuccessfulSending', params: route.params},
          ],
        }),
      );
    }
  }, [navigation, route.params]);

  const onBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <PromptOtpToSendCryptoContainer
      withdrawId={withdrawId}
      onBack={onBack}
      notifyAboutSuccessfulSending={notifyAboutSuccessfulSending}
    />
  );
});
