// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class SearchParams {
  static getPropertyFromQueryString(url: string, propKey: string): string {
    const queryString: string | undefined = url.split('#')[1] || '';
    const pairs = queryString.split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      if (pair[0] === propKey) {
        return pair[1];
      }
    }
    return '';
  }
}
