import {action, makeObservable, observable} from 'mobx';

import type {ShallowCommonState} from '../CommonNavigationScheme';
import type {NavigationContainerState} from './NavigationContainerState';

export default class NavigationContainerStateImpl
  implements NavigationContainerState
{
  @observable.ref private _latestState?: ShallowCommonState;

  constructor() {
    makeObservable(this);
  }

  get latestState() {
    return this._latestState;
  }

  @action setState(state?: ShallowCommonState) {
    this._latestState = state;
  }

  @action initialize(state: ShallowCommonState) {
    if (!this._latestState) {
      this._latestState = state;
    }
  }
}
