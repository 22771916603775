import type {QrCodeHistoryEntry} from '@ncwallet-app/core';
import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {
  Button,
  CommonRefreshControl,
  LG_BREAKPOINT,
  SafeAreaFlatList,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  ArrowLeftWide,
  QrHistorySvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

import {EmptyHistoryList} from '../HistoryScreen/HistoryList/EmptyHistoryList';
import {
  Inner,
  ListWrapper,
  Root,
} from '../HistoryScreen/HistoryList/sharedStyles';
import {QrCodeHistoryListItem} from './QrCodeHistoryListItem';

export type QrCodeHistoryListProps = BaseSafeAreaProps & {
  getEntries: () => QrCodeHistoryEntry[] | undefined;
  onEntrySelect: (entry: QrCodeHistoryEntry) => void;
  onEntryNavigate: (entry: QrCodeHistoryEntry) => void;
  onRefresh: () => void;
  getIsRefreshing: () => boolean;
  goBack: () => void;
};

const keyExtractor = (entry: QrCodeHistoryEntry) => String(entry.id);

export default observer(function QrCodeHistoryList(
  props: QrCodeHistoryListProps,
) {
  const {
    getEntries,
    onEntrySelect,
    onEntryNavigate,
    onRefresh,
    getIsRefreshing,
    goBack,
    ...rest
  } = props;
  const entries = getEntries();
  const refreshControl = useMemo(
    () => (
      <CommonRefreshControl
        getIsRefreshing={getIsRefreshing}
        onRefresh={onRefresh}
      />
    ),
    [getIsRefreshing, onRefresh],
  );

  const strings = useStrings();
  const isLg = useIsDimensions('lg');
  const theme = useTheme();
  return (
    <Root>
      <Inner>
        {isLg && (
          <Header>
            <BackButton
              title={''}
              onPress={goBack}
              Icon={ArrowLeftWide}
              iconCustomColor={theme.palette.uiAdditional1}
            />
            <QrHistorySvg color={theme.palette.info} />
            <HeaderText>{strings['screenTitle.history']}</HeaderText>
          </Header>
        )}
        <ListWrapper>
          {entries?.length === 0 ? (
            <EmptyHistoryList
              title={strings['emptyHistoryScreen.title']}
              description={strings['emptyHistoryScreen.qrDescription']}
            />
          ) : (
            <SafeAreaFlatList
              data={entries}
              ItemSeparatorComponent={Separator}
              renderItem={({item}) => (
                <QrCodeHistoryListItem
                  entry={item}
                  onPress={() => {
                    onEntrySelect(item);
                  }}
                  onRightRowPress={() => {
                    onEntryNavigate(item);
                  }}
                />
              )}
              keyExtractor={keyExtractor}
              refreshControl={refreshControl}
              {...rest}
            />
          )}
        </ListWrapper>
      </Inner>
    </Root>
  );
});

const Header = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
}));

const BackButton = variance(Button)(() => ({
  root: {
    padding: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
    marginRight: 20,
  },
}));

const HeaderText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    color: theme.palette.textPrimary,
    marginLeft: 5,
  },
}));

const Separator = variance(View)(theme => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.uiSecondary,
    marginRight: 25,
    marginLeft: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginLeft: 0,
        marginRight: 0,
      },
    }),
  },
}));
