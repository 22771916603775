import {useStrings} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  Input,
  InputType,
  SafeAreaScrollView,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef, useState} from 'react';
import type {TextInput} from 'react-native';
import {StyleSheet} from 'react-native';

import {useFocusInputOnNavigationFocus} from '../../Navigation/hooks';

export type InputModalScreenProps = BaseSafeAreaProps & {
  title: string;
  onSubmit: (value: string) => void;
  value: string;
  placeholder: string;
  label: string;
};

export default observer(function InputScreen(props: InputModalScreenProps) {
  const {onSubmit, value, placeholder, label, ...rest} = props;
  const ref = useRef<TextInput>(null);
  useFocusInputOnNavigationFocus(ref);
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  const strings = useStrings();

  return (
    <SafeAreaScrollView contentContainerStyle={styles.container} {...rest}>
      <Input
        ref={ref}
        type={InputType.Email}
        label={label}
        placeholder={placeholder}
        value={currentValue}
        onChangeText={setCurrentValue}
      />
      <Button
        style={styles.button}
        variant={ButtonVariant.Highlighted}
        color={ButtonColor.Secondary}
        onPress={() => {
          onSubmit(currentValue.trim());
        }}
        title={strings['profileSettingsScreen.updateBtn']}
      />
    </SafeAreaScrollView>
  );
});

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingHorizontal: 10,
  },
  button: {
    marginTop: 'auto',
  },
});
