import {useStyles, variance} from '@ncwallet-app/core';
import type {CountryNameData} from '@ncwallet-app/core/src/Localization/constant';
import {
  LG_BREAKPOINT,
  SafeAreaFlatList,
  SafeAreaInset,
  Separator,
} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {FlatListProps} from 'react-native';

import CountryListEmpty from './CountryListEmpty';
import CountryListItem from './CountryListItem';

export type CountryListProps<T> = {
  data: T[];
  searchQuery: string;
  onCountrySelect: (country: CountryNameData) => void;
};

type ListProps = FlatListProps<CountryNameData>;

export default observer(function CountryList(
  props: CountryListProps<CountryNameData>,
) {
  const {data, searchQuery, onCountrySelect} = props;
  const styles = useListStyles();

  const renderItem: ListProps['renderItem'] = useCallback(
    ({item}: {item: CountryNameData}) => (
      <CountryListItem item={item} onPress={onCountrySelect} />
    ),
    [onCountrySelect],
  );

  return (
    <SafeAreaFlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={CustomSeparator}
      ListEmptyComponent={<CountryListEmpty searchQuery={searchQuery} />}
      style={styles.largeList}
      insets={SafeAreaInset.BOTTOM}
      keyboardAvoiding
    />
  );
});

const keyExtractor = (item: CountryNameData) => String(item.id);

const CustomSeparator = variance(Separator)(() => ({
  root: {
    marginRight: 0,
    marginLeft: 0,
  },
}));

const useListStyles = () =>
  useStyles(theme => ({
    largeList: {
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          backgroundColor: theme.palette.uiPrimary,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: theme.palette.uiSecondary,
        },
      }),
    },
  }));
