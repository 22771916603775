import type {Url} from '../units';

export interface SpecialLocation {
  getOauthRedirect(): Url;
  parse(locator: string): SpecialLocator;
}

export const INTERNAL = Symbol();
export const EXTERNAL = Symbol();

export interface Internal {
  kind: typeof INTERNAL;
  prefix?: string;
  rest: string;
}

export interface External {
  kind: typeof EXTERNAL;
}

export type SpecialLocator = Internal | External;
