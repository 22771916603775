import type {MessageContext, Messaging} from '../Messaging';
import type {Service} from '../structure';
import type {Log} from './Log';
import MessageContextLogContent from './MessageContextLogContent';

export default class MessagingLogBridgeService implements Service {
  constructor(
    private readonly _root: {readonly messaging: Messaging; readonly log: Log},
  ) {}

  private readonly _onMessage = (context: MessageContext) => {
    this._root.log.write(new MessageContextLogContent(context));
  };

  subscribe() {
    return this._root.messaging.income.listen(this._onMessage);
  }
}
