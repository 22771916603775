import {TransactionFilterKind, useTheme, variance} from '@ncwallet-app/core';
import type {TransactionType} from '@ncwallet-app/core/src/NCWalletServer/WalletsTransactions';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {
  TransactionAllRoundedSvg,
  TransactionAllSvg,
  TransactionExchangeRoundedSvg,
  TransactionExchangeSvg,
  TransactionFromRoundedSvg,
  TransactionFromSvg,
  TransactionIncomingRoundedSvg,
  TransactionIncomingSvg,
  TransactionRollbackRoundedSvg,
  TransactionRollbackSvg,
  TransactionToRoundedSvg,
  TransactionToSvg,
  TransactionWithdrawRoundedSvg,
  TransactionWithdrawSvg,
} from '../../assets/svg/colorless';

export type TransactionIconProps = {
  size: number;
  kind: TransactionFilterKind | TransactionType;
};

export const TransactionIcon = observer((props: TransactionIconProps) => {
  const {kind} = props;
  const theme = useTheme();
  const Icon = iconMapFlat[kind as TransactionFilterKind];

  return (
    <View style={{width: props.size, height: props.size}}>
      <Container flat>
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {Icon ? (
          <Icon
            color={theme.palette.foreground}
            resizeMode="contain"
            width={props.size || 20}
            height={props.size || 20}
            style={{width: props.size || 20, height: props.size || 20}}
          />
        ) : (
          <Circle />
        )}
      </Container>
    </View>
  );
});

export type TransactionFilterIconProps = {
  kind: TransactionFilterKind;
  size: number;
  flat?: boolean;
};

export const TransactionFilterIcon = observer(
  (props: TransactionFilterIconProps) => {
    const {flat = false} = props;
    if (isNil(iconMapFlat[props.kind]) || isNil(iconMapRounded[props.kind])) {
      return null;
    }
    const FilterIcon = flat
      ? iconMapFlat[props.kind]
      : iconMapRounded[props.kind];
    const theme = useTheme();
    return (
      <View style={{width: props.size, height: props.size}}>
        <Container flat={flat}>
          <FilterIcon color={theme.palette.foreground} />
        </Container>
      </View>
    );
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const iconMapFlat: Record<TransactionFilterKind, React.ComponentType<any>> = {
  [TransactionFilterKind.Incoming]: TransactionIncomingSvg,
  [TransactionFilterKind.Withdraw]: TransactionWithdrawSvg,
  [TransactionFilterKind.Exchange]: TransactionExchangeSvg,
  [TransactionFilterKind.Rollback]: TransactionRollbackSvg,
  [TransactionFilterKind.ReferralIncoming]: TransactionIncomingSvg,
  [TransactionFilterKind.PromoIncoming]: TransactionIncomingSvg,
  [TransactionFilterKind.All]: TransactionAllSvg,
  [TransactionFilterKind.To]: TransactionToSvg,
  [TransactionFilterKind.From]: TransactionFromSvg,
};

const iconMapRounded: Record<
  TransactionFilterKind,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.ComponentType<any>
> = {
  [TransactionFilterKind.Incoming]: TransactionIncomingRoundedSvg,
  [TransactionFilterKind.Withdraw]: TransactionWithdrawRoundedSvg,
  [TransactionFilterKind.Exchange]: TransactionExchangeRoundedSvg,
  [TransactionFilterKind.Rollback]: TransactionRollbackRoundedSvg,
  [TransactionFilterKind.ReferralIncoming]: TransactionIncomingRoundedSvg,
  [TransactionFilterKind.PromoIncoming]: TransactionIncomingRoundedSvg,
  [TransactionFilterKind.All]: TransactionAllRoundedSvg,
  [TransactionFilterKind.To]: TransactionToRoundedSvg,
  [TransactionFilterKind.From]: TransactionFromRoundedSvg,
};

const Container = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    borderRadius: 50,
    backgroundColor: theme.palette.background,
  },
  flat: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    flex: 1,
  },
}));
const Circle = variance(View)(theme => ({
  root: {
    borderRadius: 12,
    width: 15,
    height: 15,
    backgroundColor: theme.palette.uiAdditional1,
  },
}));
