import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {UserCardIcon} from '@ncwallet-app/core/src/Components/UserCardIcon';
import {SimpleButton} from '@ncwallet-app/ui';
import {MailSVG} from '@ncwallet-app/ui/src/assets/svg/colorless/';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Text, View} from 'react-native';

import {AppleIcon, GoogleIcon} from '../AuthScreen/icons';
import type {LinkToEmailModalProps} from './LinkToEmailModal';

export type LinkToEmailScreenBodyProps = LinkToEmailModalProps & {
  inBottomSheet: boolean;
};

export default observer(function LinkToEmailScreenBody({
  handleLinkPress,
  inBottomSheet,
}: LinkToEmailScreenBodyProps) {
  const strings = useStrings();
  const theme = useTheme();
  const mainBlockStyle = useMemo(() => {
    return inBottomSheet ? {flex: 1} : {};
  }, [inBottomSheet]);

  const handleLinkWithEmailByGoogle = useCallback(async () => {
    await handleLinkPress('google');
  }, [handleLinkPress]);

  const handleLinkWithEmailByApple = useCallback(async () => {
    await handleLinkPress('apple');
  }, [handleLinkPress]);

  return (
    <>
      <MainBlock style={mainBlockStyle}>
        <UserCardIcon
          Icon={<MailSVG width={20} height={20} color={theme.palette.whitey} />}
        />
        <Title>{strings['linkToEmail.title']}</Title>
        <Description>
          {strings['linkToEmail.toEmailDescription1']}{' '}
          <BoldDescription>
            {strings['linkToEmail.toEmailDescription2']}
          </BoldDescription>
        </Description>
      </MainBlock>
      <Buttons>
        <SimpleButton
          title={strings['welcomeScreen.logInVia.google']}
          Icon={GoogleIcon}
          onPress={handleLinkWithEmailByGoogle}
          testID="login-google-btn"
        />
        <SimpleButton
          title={strings['welcomeScreen.logInVia.apple']}
          Icon={AppleIcon}
          onPress={handleLinkWithEmailByApple}
          testID="login-apple-btn"
        />
      </Buttons>
    </>
  );
});

const MainBlock = variance(View)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 26,
    lineHeight: 36,
    marginBottom: 5,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional1,
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'center',
  },
}));

const BoldDescription = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textAdditional1,
    fontSize: 16,
    lineHeight: 26,
  },
}));

const Buttons = variance(View)(() => ({
  root: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    gap: 10,
  },
}));
