import type {NotificationPermissions} from '@ncwallet-app/core/src/NotificationPermissions';
import {StubNotificationPermissionsImpl} from '@ncwallet-app/core/src/NotificationPermissions';

import {isNotificationApiSupported} from '../LocalNotifications/isNotificationApiSupported';
import WebNotificationPermissionsImpl from './WebNotificationPermissionsImpl';

export default function createNotificationPermissions(): NotificationPermissions {
  if (isNotificationApiSupported()) {
    return new WebNotificationPermissionsImpl();
  }
  return new StubNotificationPermissionsImpl();
}
