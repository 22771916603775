import {sized, useRoot, useStrings, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
  SafeAreaInset,
  SafeAreaLayout,
} from '@ncwallet-app/ui';
import {HistoryTabSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {EXTRA_BOTTOM_OFFSET} from '../constants';
import PositionHelperStatic from './PositionHelperStatic';

export type LabelLayerProps = {
  onHistoryPress: () => void;
};

export default observer(function LabelLayer(props: LabelLayerProps) {
  const {onHistoryPress} = props;
  const strings = useStrings();
  const {windowDimensionsState} = useRoot();
  const paddingTop = expr(() =>
    PositionHelperStatic.getFrameBottomPosition(
      windowDimensionsState.window,
      true,
    ),
  );
  const style = useMemo(() => ({paddingTop}), [paddingTop]);
  return (
    <SafeAreaLayout
      style={[styles.root, style]}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}>
      <View style={styles.content}>
        <View style={styles.typo}>
          <Title>{strings['qrCodeScreen.text']}</Title>
        </View>
        <View style={styles.footer}>
          <ShowButton
            onPress={onHistoryPress}
            Icon={HistoryIcon}
            title={strings['qrCodeScreen.showHistory']}
            variant={ButtonVariant.Highlighted}
            color={ButtonColor.Secondary}
          />
        </View>
      </View>
    </SafeAreaLayout>
  );
});

const HistoryIcon = sized(HistoryTabSvg, 22);

const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
  },
  content: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  typo: {
    flex: 1,
    paddingTop: 50,
  },
  footer: {
    marginTop: 15,
    alignSelf: 'stretch',
    alignItems: 'center',
  },
});

export const Title = variance(Text)(theme => ({
  root: {
    color: theme.palette.whitey,
    ...theme.fontByWeight('700'),
    marginBottom: 15,
    textAlign: 'center',
    fontSize: 20,
    lineHeight: 24,
  },
}));

export const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    lineHeight: 20,
    color: theme.palette.textAdditional3,
    textAlign: 'center',
  },
}));

const ShowButton = variance(Button)(theme => ({
  root: {
    alignSelf: 'stretch',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        alignSelf: 'auto',
        minWidth: 345,
      },
    }),
  },
}));
