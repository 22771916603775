import {now} from 'mobx-utils';

import type {Millisecond} from '../Time';
import type {TimeState} from './TimeState';

export default class TimeStateImpl implements TimeState {
  getNow(interval: Millisecond) {
    return now(interval) as Millisecond;
  }
}
