import type {CommonRoute} from '../CommonNavigationScheme';
import {
  PROMPT_OUTPUT_ADDRESS_ROUTE,
  SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE,
} from '../CommonNavigationScheme';

// eslint-disable-next-line import-x/prefer-default-export
export const routesWithBlockchainNetworksWarning = new Set<CommonRoute['kind']>(
  [PROMPT_OUTPUT_ADDRESS_ROUTE, SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE],
);
