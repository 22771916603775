// eslint-disable-next-line import-x/no-relative-packages
import rootPackageJson from '../../../../package.json';
import type {BundleId, Semver} from '../units';
import type {DeviceInfo} from './DeviceInfo';

export default class UniversalDeviceInfoImpl implements DeviceInfo {
  getBundleId() {
    return 'com.ncwallet' as BundleId;
  }

  getAppVersionWithBuildNumber() {
    return rootPackageJson.version as Semver;
  }

  getAppVersion() {
    return this.getAppVersionWithBuildNumber();
  }
}
