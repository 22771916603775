import type {CryptoCurrencyCode} from '@ncwallet-app/core';
import {useStrings, variance} from '@ncwallet-app/core';
import {addPrefixForAddress} from '@ncwallet-app/core/src/AddressParser';
import {Button, ButtonVariant, LG_BREAKPOINT, Qr} from '@ncwallet-app/ui';
import {CopySvg, ShareSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

import {useReceiveScreenActions} from '../ReceiveCryptoScreen/useReceiveScreenActions';

export type ShowQrScreenBodyProps = {
  walletAddress: string;
  onCopy: (data: string) => void;
  onShare: (data: string) => void;
  networkCurrency?: CryptoCurrencyCode;
  shareShown: boolean;
};

export default observer(function ShowQrScreenBody(
  props: ShowQrScreenBodyProps,
) {
  const {walletAddress, onShare, shareShown, networkCurrency} = props;
  const addressWithPrefix =
    networkCurrency && addPrefixForAddress(networkCurrency, walletAddress);
  const {copyAddressThrottled, shareThrottled} = useReceiveScreenActions(
    onShare,
    walletAddress,
  );
  const strings = useStrings();
  return (
    <Container>
      <QrContainer>
        <Qr text={addressWithPrefix || walletAddress} />
      </QrContainer>
      <Address>{walletAddress}</Address>
      <Buttons>
        {shareShown && (
          <ActionButton
            onPress={shareThrottled}
            title={strings['receiveCryptoScreen.share']}
            variant={ButtonVariant.PrimaryLight}
            Icon={ShareSvg}
          />
        )}
        <ActionButton
          onPress={() => copyAddressThrottled(walletAddress)}
          title={strings['receiveCryptoScreen.copy']}
          variant={ButtonVariant.PrimaryLight}
          Icon={CopySvg}
        />
      </Buttons>
    </Container>
  );
});

const Container = variance(ScrollView)(
  () => ({
    root: {
      flex: 1,
      paddingHorizontal: 15,
      paddingTop: 20,
    },
  }),
  theme => ({
    contentContainerStyle: {
      paddingBottom: 30,
      gap: 20,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          gap: 40,
          paddingBottom: 20,
        },
      }),
    },
  }),
);

const ActionButton = variance(Button)(() => ({
  root: {
    flex: 1,
  },
}));

const QrContainer = variance(View)(theme => ({
  root: {
    width: 220,
    aspectRatio: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    padding: 15,
    borderRadius: 10,
    borderWidth: 4,
    borderColor: theme.palette.info,
    backgroundColor: '#fff',
  },
}));

const Address = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'center',
    color: theme.palette.textSecondary,
  },
}));

const Buttons = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    gap: 20,
  },
}));
