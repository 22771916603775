/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint,@typescript-eslint/no-explicit-any */
import {observer} from 'mobx-react-lite';
import type {ForwardedRef, PropsWithChildren, ReactElement} from 'react';
import React, {useMemo} from 'react';
import type {
  DefaultSectionT,
  DimensionValue,
  FlatListProps,
  KeyboardAvoidingViewProps,
  ScrollViewProps,
  SectionListProps,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {
  KeyboardAvoidingView,
  Platform,
  SectionList,
  StyleSheet,
  View,
} from 'react-native';
import type {NativeViewGestureHandlerProps} from 'react-native-gesture-handler';
import {FlatList, ScrollView} from 'react-native-gesture-handler';
import type {Edge} from 'react-native-safe-area-context';
import {SafeAreaView} from 'react-native-safe-area-context';

import {useIsDimensions} from '../../util';
import type {SectionListType} from '../atoms/SectionList';
import {SectionListComponent} from '../atoms/SectionList';
import splitViewStyle from './splitViewStyle';

export enum SafeAreaInset {
  TOP = 0b10,
  BOTTOM = 0b01,
  ALL = 0b11,
  NONE = 0b00,
}

export type InsetsProp = SafeAreaInset | SafeAreaInset[];
export type ExtraProps = Partial<{
  top: number;
  bottom: number;
}>;
export type InsetsProps = Partial<{
  insets: InsetsProp;
  extra: ExtraProps;
}>;
export type SafeKeyboardProps = {
  keyboardAvoiding?: boolean;
  enabledWebBottomOffset?: boolean;
} & KeyboardAvoidingViewProps;

export type BaseSafeAreaProps = InsetsProps & SafeKeyboardProps;

export type SafeAreaLayoutProps = InsetsProps & ViewProps;
export const SafeAreaLayout = observer<
  PropsWithChildren<SafeAreaLayoutProps>,
  View
>(
  React.forwardRef((props, ref) => {
    const {insets, style, extra, ...rest} = props;
    const extraStyle = useExtraStyle(insets, extra);
    const edges = translateEdges(insets);
    return (
      <SafeAreaView
        edges={edges}
        ref={ref}
        style={[extraStyle, style]}
        {...rest}
      />
    );
  }),
);

export type SafeAreaFlatListProps<ItemT> = BaseSafeAreaProps &
  FlatListProps<ItemT> &
  NativeViewGestureHandlerProps;
const BaseSafeAreaFlatList = <ItemT extends any>(
  props: SafeAreaFlatListProps<ItemT>,
  ref: ForwardedRef<FlatList<ItemT>>,
) => {
  const {
    insets,
    contentContainerStyle,
    extra,
    refreshControl,
    onRefresh,
    ListHeaderComponent,
    ListFooterComponent,
    ...rest
  } = props;
  const [Header, Footer] = useListGutters(
    insets,
    ListHeaderComponent,
    ListFooterComponent,
  );
  const extraStyle = useExtraStyle(insets, extra);
  return (
    <SafeKeyboardView {...props}>
      <FlatList
        overScrollMode="never"
        ref={ref}
        refreshControl={refreshControl}
        onRefresh={onRefresh}
        contentContainerStyle={[
          extraStyle,
          styles.container,
          contentContainerStyle,
        ]}
        ListHeaderComponent={Header}
        ListFooterComponent={Footer}
        {...rest}
      />
    </SafeKeyboardView>
  );
};

export const SafeAreaFlatList = observer(
  React.forwardRef(BaseSafeAreaFlatList) as <ItemT extends any>(
    p: SafeAreaFlatListProps<ItemT> & {ref?: ForwardedRef<FlatList<ItemT>>},
  ) => ReactElement,
);

export type SafeAreaSectionListProps<
  ItemT,
  SectionT = DefaultSectionT,
> = BaseSafeAreaProps & SectionListProps<ItemT, SectionT>;
const BaseSafeAreaSectionList = <
  ItemT extends any,
  SectionT extends DefaultSectionT = DefaultSectionT,
>(
  props: SafeAreaSectionListProps<ItemT, SectionT>,
  ref: ForwardedRef<SectionListType<ItemT, SectionT>>,
) => {
  const {
    insets,
    contentContainerStyle,
    extra,
    ListHeaderComponent,
    ListFooterComponent,
    ...rest
  } = props;
  const [Header, Footer] = useListGutters(
    insets,
    ListHeaderComponent,
    ListFooterComponent,
  );
  const extraStyle = useExtraStyle(insets, extra);
  if (Platform.OS === 'web') {
    return (
      <SafeKeyboardView {...props}>
        <SectionList
          ref={ref}
          contentContainerStyle={[
            extraStyle,
            styles.container,
            contentContainerStyle,
          ]}
          ListHeaderComponent={Header}
          ListFooterComponent={Footer}
          {...rest}
        />
      </SafeKeyboardView>
    );
  }
  return (
    <SafeKeyboardView {...props}>
      <SectionListComponent
        ref={ref}
        contentContainerStyle={[
          extraStyle,
          styles.container,
          contentContainerStyle,
        ]}
        ListHeaderComponent={Header}
        ListFooterComponent={Footer}
        {...rest}
      />
    </SafeKeyboardView>
  );
};
export const SafeAreaSectionList = observer(
  React.forwardRef(BaseSafeAreaSectionList) as <
    ItemT extends any,
    SectionT extends DefaultSectionT = DefaultSectionT,
  >(
    p: SafeAreaSectionListProps<ItemT, SectionT> & {
      ref?: ForwardedRef<SectionListType<ItemT, SectionT>>;
    },
  ) => ReactElement,
);

export type SafeAreaScrollViewProps = BaseSafeAreaProps & ScrollViewProps;
export const SafeAreaScrollView = observer(
  React.forwardRef(
    (props: SafeAreaScrollViewProps, ref: ForwardedRef<ScrollView>) => {
      const {insets, contentContainerStyle, extra, children, ...rest} = props;
      const {inner, outer, other} = useMemo(
        () => splitViewStyle(StyleSheet.flatten(contentContainerStyle) ?? {}),
        [contentContainerStyle],
      );
      const edges = translateEdges(insets);
      const extraStyle = useExtraStyle(insets, extra);
      return (
        <SafeKeyboardView {...props}>
          <ScrollView
            overScrollMode="never"
            ref={ref}
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={[styles.container, outer, other]}
            {...rest}>
            <SafeAreaView
              style={[styles.container, extraStyle, inner, other]}
              edges={edges}>
              {children}
            </SafeAreaView>
          </ScrollView>
        </SafeKeyboardView>
      );
    },
  ),
);

type GutterProp =
  | React.ComponentType<any>
  | React.ReactElement
  | null
  | undefined;

function useListGutters(
  insets: InsetsProp = SafeAreaInset.NONE,
  ListHeaderComponent?: GutterProp,
  ListFooterComponent?: GutterProp,
): [Header: GutterProp, Footer: GutterProp] {
  const sum = sumInsets(insets);

  const isTop = !!(sum & SafeAreaInset.TOP);

  const isBottom = !!(sum & SafeAreaInset.BOTTOM);

  const Header = useMemo(
    () =>
      isTop ? (
        <SafeAreaView edges={TOP}>
          {renderAnyway(ListHeaderComponent)}
        </SafeAreaView>
      ) : (
        ListHeaderComponent
      ),
    [ListHeaderComponent, isTop],
  );

  const Footer = useMemo(
    () =>
      isBottom ? (
        <SafeAreaView edges={BOTTOM}>
          {renderAnyway(ListFooterComponent)}
        </SafeAreaView>
      ) : (
        ListFooterComponent
      ),
    [ListFooterComponent, isBottom],
  );

  return [Header, Footer];
}

function renderAnyway(_: GutterProp): React.ReactNode {
  return typeof _ === 'function' ? React.createElement(_) : _;
}

const _getDefaultBehaviorByPlatform = () => {
  return Platform.OS === 'android'
    ? 'height'
    : Platform.OS === 'ios'
      ? 'padding'
      : undefined;
};

const SafeKeyboardView = observer(
  React.forwardRef(
    (
      props: PropsWithChildren<SafeKeyboardProps>,
      ref: ForwardedRef<KeyboardAvoidingView>,
    ) => {
      const {
        keyboardVerticalOffset = 0,
        keyboardAvoiding,
        children,
        behavior,
      } = props;
      const isLg = useIsDimensions('lg');
      if (keyboardAvoiding) {
        return (
          <KeyboardAvoidingView
            ref={ref}
            style={[
              styles.root,
              props.enabledWebBottomOffset && styles.addOffsetBottom,
              isLg && styles.visible,
            ]}
            behavior={behavior || _getDefaultBehaviorByPlatform()}
            keyboardVerticalOffset={keyboardVerticalOffset}>
            {children}
          </KeyboardAvoidingView>
        );
      }
      return (
        <View
          style={[
            styles.root,
            props.enabledWebBottomOffset && styles.addOffsetBottom,
            isLg && styles.visible,
          ]}>
          {children}
        </View>
      );
    },
  ),
);

const styles = StyleSheet.create({
  root: {
    flex: 1,
    ...Platform.select({
      web: {
        width: '100%',
        maxHeight: '100vh' as unknown as `${number}%`,
        overflow: 'auto' as unknown as 'visible',
      },
      default: {},
    }),
  },
  visible: {
    ...Platform.select({
      web: {
        overflow: 'visible',
      },
      default: {},
    }),
  },
  addOffsetBottom: {
    ...Platform.select({
      web: {
        width: '100%',
        maxHeight: 'calc(100vh - 60px)' as DimensionValue,
      },
      default: {},
    }),
  },
  container: {
    flexGrow: 1,
    ...Platform.select({
      web: {
        width: '100%',
      },
      default: {},
    }),
  },
});

function useExtraStyle(
  insets: InsetsProp = SafeAreaInset.ALL,
  extra?: ExtraProps,
): ViewStyle | undefined {
  const sum = sumInsets(insets);
  if (sum === SafeAreaInset.NONE) {
    return undefined;
  }
  const result: ViewStyle = {};

  if (sum & SafeAreaInset.BOTTOM) {
    // default to zero for backward compatibility
    result.paddingBottom = extra?.bottom ?? 0;
  }

  if (sum & SafeAreaInset.TOP) {
    // default to zero for backward compatibility
    result.paddingTop = extra?.top ?? 0;
  }
  return result;
}

function translateEdges(_: InsetsProp = SafeAreaInset.NONE): readonly Edge[] {
  return translateEdge(sumInsets(_));
}

function sumInsets(_: InsetsProp): SafeAreaInset {
  return Array.isArray(_) ? _.reduce(sumTwoInsets, SafeAreaInset.NONE) : _;
}

function translateEdge(_: SafeAreaInset): readonly Edge[] {
  switch (_) {
    case SafeAreaInset.TOP:
      return TOP;
    case SafeAreaInset.BOTTOM:
      return BOTTOM;
    case SafeAreaInset.ALL:
      return ALL;
    case SafeAreaInset.NONE:
      return NONE;
  }
}

const TOP: readonly Edge[] = ['top'];
const BOTTOM: readonly Edge[] = ['bottom'];
const ALL: readonly Edge[] = ['top', 'bottom'];
const NONE: readonly Edge[] = [];

function sumTwoInsets(a: SafeAreaInset, b: SafeAreaInset) {
  return (a | b) as SafeAreaInset;
}
