import {observer} from 'mobx-react-lite';
import React from 'react';

import type {Notification} from './NotificationItem';
import {NotificationList} from './NotificationList';
import NotificationListSkeleton from './NotificationListSkeleton';

export type NotificationsScreenProps = {
  getIsRefreshing: () => boolean;
  getNotificationItems: () => Notification[] | undefined;
  onRefresh: () => void;
  onEndReached: () => void;
};

export const NotificationsScreen = observer(
  (props: NotificationsScreenProps) => {
    return props.getNotificationItems() ? (
      <NotificationList {...props} />
    ) : (
      <NotificationListSkeleton />
    );
  },
);
