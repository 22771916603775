import {useStrings, variance} from '@ncwallet-app/core';
import {Pressable} from '@ncwallet-app/ui';
import {ArrowLeftSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {
  BodyTag,
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import type {HistorySessionInfo} from '../../SessionsHistoryScreen/HistorySessionInfo';
import SessionHistoryListItem from './SessionHistoryListItem';
import SessionListHistory from './SessionListHistory';
import type {BaseSessionMenuProps} from './SessionsHolder';

export type SessionsHistoryListProps = BaseSessionMenuProps & {
  getSessions: () => HistorySessionInfo[] | undefined;
  onEndReached: () => void;
};

export default observer(function SessionsHistoryList(
  props: SessionsHistoryListProps,
) {
  const {onBack, getSessions, onEndReached} = props;
  const strings = useStrings();

  return (
    <LgProfileCardContainer>
      <HeaderTag>
        <Clickable onPress={onBack}>
          <ClickableIcon />
          <HeaderTitleTag>
            {strings['lgSecureSettingsScreen.sessionsHistory']}
          </HeaderTitleTag>
        </Clickable>
      </HeaderTag>
      <BodyTag>
        <SessionListHistory
          getSessions={getSessions}
          onSessionPress={noop}
          RenderItemComponent={SessionHistoryListItem}
          onEndReached={onEndReached}
        />
      </BodyTag>
    </LgProfileCardContainer>
  );
});

const Clickable = variance(Pressable)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const ClickableIcon = variance(ArrowLeftSvg)(theme => ({
  root: {
    marginRight: 10,
    color: theme.palette.uiMain,
  },
}));
