import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import type {DecimalString, WalletLimitPeriod} from '@ncwallet-app/core';
import {toCurrencyDescriptionFromCrypto, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {
  PromptOtpToLimitUpdateRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {SafeAreaInset} from '@ncwallet-app/ui/src';
import {useHeaderHeight} from '@react-navigation/elements';
import {CommonActions} from '@react-navigation/routers';
import {isNil} from 'lodash';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import {useCreateLimit} from '../../../CommonNavigationContainers/hooks/walletLimits/useCreateLimit';
import {useGetWalletLimits} from '../../../CommonNavigationContainers/hooks/walletLimits/useGetWalletLimits';
import {
  useCryptoCurrencies,
  useNavigationGetIsFocused,
} from '../../../Navigation/hooks';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {
  mapValidateErrorToString,
  validateLimitValue,
} from '../../../screens/LgLimitsSettingsScreen/validateLimitValue';
import SetLimitScreen from '../../../screens/UserSettingsScreens/SetLimitScreen/SetLimitScreen';
import {useMapLimitPeriodToLabelText} from '../../../shared/useMapLimitPeriodToLabelText';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptNewLimitBindingProps =
  SmallHomeStackBindingProps<'PromptLimitUpdate'>;

export default observer(function PromptNewLimitBinding(
  props: PromptNewLimitBindingProps,
) {
  const {navigation, route} = props;
  const limitPeriodToLabelTextMap = useMapLimitPeriodToLabelText();
  const header = useHeaderHeight();
  const strings = useStrings();

  const {getCryptoCurrency, loadCryptoCurrencies} = useCryptoCurrencies();
  const currency = getCryptoCurrency(route.params.currency);
  const getIsFocused = useNavigationGetIsFocused();
  const [error, setError] = useState<LocaleKeys | undefined>(undefined);
  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void loadCryptoCurrencies();
          });
        }
      }),
    [getIsFocused, getIsReady, loadCryptoCurrencies],
  );

  useEffect(() => {
    navigation.setOptions({
      title: strings['screenTitle.changeLimit'].replace(
        '{period}',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        limitPeriodToLabelTextMap.get(route.params.period)!.toLocaleLowerCase(),
      ),
    });
  }, [navigation, strings, limitPeriodToLabelTextMap, route.params.period]);

  const resetToLimitList = useCallback(() => {
    if (hasRouteInHistory(navigation.getState(), 'ListLimits')) {
      navigation.navigate('ListLimits');
    } else {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [{name: 'Root'}, {name: 'ListLimits'}],
        }),
      );
    }
  }, [navigation]);

  const goToOtp = useCallback(
    (params: RouteParams<PromptOtpToLimitUpdateRoute>) => {
      navigation.navigate('PromptOtpToLimitUpdate', params);
    },
    [navigation],
  );

  const {createLimit} = useCreateLimit(goToOtp, resetToLimitList);
  const {dailyLimit, monthlyLimit} = useGetWalletLimits(route.params.walletId);

  const handleSubmit = useCallback(
    (limit: DecimalString, period: WalletLimitPeriod) => {
      const wid = route.params.walletId;
      const errorKind = validateLimitValue(
        period,
        limit,
        monthlyLimit?.limit,
        dailyLimit?.limit,
      );

      if (isNil(errorKind)) {
        setError(undefined);
        void createLimit(wid, limit, period);
        return;
      }

      const errorLocaleKey = mapValidateErrorToString(errorKind);
      setError(errorLocaleKey);
    },
    [dailyLimit, monthlyLimit, createLimit, route.params.walletId],
  );

  return (
    <SetLimitScreen
      crypto={currency && toCurrencyDescriptionFromCrypto(currency)}
      initialPeriod={route.params.period}
      initialValue={route.params.limit}
      showWarning
      onSubmit={handleSubmit}
      insets={SafeAreaInset.BOTTOM}
      keyboardVerticalOffset={header}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      keyboardAvoiding
      isUpdate
      error={error}
      resetError={() => {
        setError(undefined);
      }}
    />
  );
});
