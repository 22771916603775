import {variance} from '@ncwallet-app/core';
import {CenteredCardLayout} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';
import type {SvgProps} from 'react-native-svg';

import type {AuthKind, AuthVariant} from '../AuthScreen';
import MdAuthButton from './MdAuthButton';

export type MdAuthLayoutProps = {
  authKind: AuthKind;
  appVersion?: string;
  Icon: React.ComponentType<SvgProps>;
  title: string;
  description: string;
  onButtonPress: (variant: AuthVariant) => void;
  onBackPress: () => void;
};

export default observer(function MdAuthLayout({
  appVersion,
  Icon,
  title,
  description,
  onButtonPress,
  onBackPress,
  authKind,
}: MdAuthLayoutProps) {
  return (
    <CenteredCardLayout appVersion={appVersion} onBackPress={onBackPress}>
      <IconContainer>
        <Icon />
      </IconContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <MdAuthButton onPress={onButtonPress} authKind={authKind} />
    </CenteredCardLayout>
  );
});

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 24,
    lineHeight: 46,
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 10,
    color: theme.palette.textPrimary,
  },
}));

const IconContainer = variance(View)(() => ({
  root: {
    marginTop: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    zIndex: 230,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 18,
    lineHeight: 28,
    textAlign: 'center',
    paddingHorizontal: 50,
    color: theme.palette.textAdditional2,
  },
}));
