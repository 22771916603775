import {variance} from '@ncwallet-app/core';
import {
  LG_BREAKPOINT,
  ModalContainer,
  SafeAreaInset,
  SafeAreaScrollView,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {EXTRA_BOTTOM_OFFSET} from '../constants';
import FeedbackModalBody from './FeedbackModalBody';

export type FeedbackModalScreenProps = {
  email: string;
  message: string;
  onEmailChange: (email: string) => void;
  onMessageChange: (message: string) => void;
  emailErrorShown: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isGoToAppStoreButtonVisible: boolean;
  onGoToAppStoreButtonPress: () => void;
};

export default observer(function FeedbackModalScreen(
  props: FeedbackModalScreenProps,
) {
  const isLg = useIsDimensions('lg');
  const content = (
    <SafeAreaScrollView
      keyboardAvoiding
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}>
      <Content>
        <FeedbackModalBody
          email={props.email}
          emailErrorShown={props.emailErrorShown}
          message={props.message}
          onEmailChange={props.onEmailChange}
          onMessageChange={props.onMessageChange}
          onClose={props.onClose}
          onSubmit={props.onSubmit}
          isGoToAppStoreButtonVisible={props.isGoToAppStoreButtonVisible}
          onGoToAppStoreButtonPress={props.onGoToAppStoreButtonPress}
        />
      </Content>
    </SafeAreaScrollView>
  );

  return isLg ? (
    <FeedbackModalBackdrop>{content}</FeedbackModalBackdrop>
  ) : (
    content
  );
});

const Content = variance(View)(theme => ({
  root: {
    width: '100%',
    flex: 1,
    justifyContent: 'space-between',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    }),
  },
}));

const FeedbackModalBackdrop = variance(ModalContainer)(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.chroma('#000000').alpha(0.7).hex(),
  },
}));
