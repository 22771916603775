import type {CurrenciesRateHistoryItem} from '@ncwallet-app/core';
import {toLineChartDatum} from '@ncwallet-app/core';
import {LineChart} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

export type WalletListItemLineChartProps = {
  getRateHistory: () => CurrenciesRateHistoryItem[] | undefined;
};

export default observer(function WalletListItemLineChart(
  props: WalletListItemLineChartProps,
) {
  const rateHistory = props.getRateHistory();
  const chartData = useMemo(
    () => rateHistory?.map(toLineChartDatum),
    [rateHistory],
  );
  if (!chartData) {
    return null;
  }
  return <LineChart data={chartData} width={100} height={24} />;
});
