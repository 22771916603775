import {variance} from '@ncwallet-app/core';
import {TouchableOpacity} from '@ncwallet-app/ui';

export default variance(TouchableOpacity)(theme => ({
  root: {
    padding: 15,
  },
  active: {
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginBottom: 0,
    backgroundColor: theme.palette.uiPrimary,
  },
}));
