import type {Millisecond, Second} from './units';

function fromSecond(_: Second): Millisecond;
function fromSecond(_: Second | undefined): Millisecond | undefined;
function fromSecond(_: Second | null): Millisecond | null;
function fromSecond(
  _: Second | undefined | null,
): Millisecond | undefined | null;
function fromSecond(
  _: Second | undefined | null,
): Millisecond | undefined | null {
  if (typeof _ === 'number') {
    return (_ * 1000) as Millisecond;
  }
  return _;
}

export default fromSecond;
