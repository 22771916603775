/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import {sized, useStrings, useTheme, variance} from '@ncwallet-app/core';
import {
  CommonRefreshControl,
  LG_BREAKPOINT,
  Pressable,
  SafeAreaSectionList,
  Switch,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {HelpSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useCallback, useMemo} from 'react';
import type {
  SectionListData,
  SectionListRenderItemInfo,
  ViewProps,
} from 'react-native';
import {Linking, StyleSheet, Text, View} from 'react-native';

import {LgNotificationSettings} from '../LgNotificationSettings';

export type HierarchyItem = {
  id: string;
  title: LocaleKeys;
  isDisabled: boolean;
  getIsActive: () => boolean;
};

export type HierarchySection = {
  items: HierarchyItem[];
} & HierarchyItem;

export type SwitchHierarchyScreenProps = {
  title: LocaleKeys;
  getIsActive: () => boolean | undefined;
  onToggle: () => void;
  getIsTelegramActive: () => boolean;
  onTelegramToggle: () => void;
  getSections: () => HierarchySection[] | undefined;
  onSectionToggle: (sectionId: string) => void;
  onItemToggle: (sectionId: string, itemId: string) => void;
  getIsRefreshing: () => boolean;
  onRefresh: () => void;
};

export default observer(function SwitchHierarchyScreen(
  props: SwitchHierarchyScreenProps,
) {
  const {
    title,
    getIsActive: _getIsActive,
    onToggle,
    onSectionToggle,
    onItemToggle,
    getSections,
    getIsRefreshing,
    onRefresh,
    getIsTelegramActive,
    onTelegramToggle,
  } = props;

  const isLg = useIsDimensions('lg');

  const renderItem = useCallback(
    (info: SectionListRenderItemInfo<ItemEntity, SectionEntity>) => (
      <Item
        title={info.item.title}
        isDisabled={info.item.isDisabled}
        getIsActive={info.item.getIsActive}
        sectionId={info.section.key!}
        itemId={info.item.id}
        onItemToggle={onItemToggle}
      />
    ),

    [onItemToggle],
  );

  const renderSectionHeader = useCallback(
    (info: {section: SectionListData<ItemEntity, SectionEntity>}) => {
      return (
        <SectionHeaderView>
          <SectionHeader
            title={info.section.title}
            getIsActive={info.section.getIsActive}
            sectionId={info.section.key!}
            isDisabled={info.section.isDisabled}
            onSectionToggle={onSectionToggle}
          />
          {info.section.data.length > 0 ? <Separator /> : null}
        </SectionHeaderView>
      );
    },
    [onSectionToggle],
  );

  const renderSectionFooter = useCallback(
    () => (
      <View style={styles.sectionFooter}>
        <Separator />
      </View>
    ),
    [],
  );

  const _sections = getSections();

  const sections: SectionListData<ItemEntity, SectionEntity>[] = useMemo(
    () =>
      _sections?.map(_ => ({
        title: _.title,
        getIsActive: _.getIsActive,
        key: _.id,
        data: _.items,
        isDisabled: _.isDisabled,
      })) ?? [],
    [_sections],
  );

  const sectionsPresent = expr(() => {
    const _ = getSections();
    return _ && _.length > 0;
  });

  const getIsActive = useCallback(
    () => _getIsActive() ?? false,
    [_getIsActive],
  );

  const ListHeaderComponent = useCallback(
    () => (
      <Header>
        <SectionItemView
          isHeader
          isSection
          hasBottomSeparator={sectionsPresent}
          title={title}
          getIsActive={getIsActive}
          onToggle={onToggle}
        />
        <SectionItemView
          isHeader
          isSection
          hasBottomSeparator={sectionsPresent}
          title={
            'userSettings.notifications.TelegramNotifications' as LocaleKeys
          }
          getIsActive={getIsTelegramActive}
          onToggle={onTelegramToggle}
        />
      </Header>
    ),
    [
      getIsActive,
      onToggle,
      sectionsPresent,
      title,
      getIsTelegramActive,
      onTelegramToggle,
    ],
  );

  const LgListHeaderComponent = useCallback(
    () => (
      <Header>
        <SectionItemView
          title={title}
          getIsActive={getIsActive}
          onToggle={onToggle}
          noOffset
        />
        <SectionItemView
          title={
            'userSettings.notifications.TelegramNotifications' as LocaleKeys
          }
          getIsActive={getIsTelegramActive}
          onToggle={onTelegramToggle}
          noOffset
        />
      </Header>
    ),
    [getIsActive, onToggle, title, getIsTelegramActive, onTelegramToggle],
  );

  const refreshControl = useMemo(
    () => (
      <CommonRefreshControl
        getIsRefreshing={getIsRefreshing}
        onRefresh={onRefresh}
      />
    ),
    [getIsRefreshing, onRefresh],
  );

  const sectionListCommonProps = {
    sections: sections,
    renderItem: renderItem,
    renderSectionHeader: renderSectionHeader,
    renderSectionFooter: renderSectionFooter,
    ItemSeparatorComponent: ItemSeparatorComponent,
    ListHeaderComponent: ListHeaderComponent,
    refreshControl: refreshControl,
  };

  if (isLg) {
    return (
      <Container>
        <LgListHeaderComponent />
        <LgNotificationSettings {...sectionListCommonProps} />
      </Container>
    );
  }
  return (
    <SafeAreaSectionList
      {...sectionListCommonProps}
      contentContainerStyle={styles.container}
      enabledWebBottomOffset
      keyExtractor={item => item.id}
    />
  );
});

export type ItemEntity = {
  id: string;
  title: LocaleKeys;
  isDisabled: boolean;
  getIsActive: () => boolean;
};

export type SectionEntity = {
  title: LocaleKeys;
  isDisabled: boolean;
  getIsActive: () => boolean;
};

type BaseItemViewProps = {
  itemId?: string;
  title: LocaleKeys;
  isDisabled?: boolean;
  getIsActive: () => boolean;
};

type ItemProps = {
  sectionId: string;
  itemId: string;
  onItemToggle: (sectionId: string, itemId: string) => void;
} & BaseItemViewProps;

const Item = observer((props: ItemProps) => {
  const {title, isDisabled, getIsActive, sectionId, itemId, onItemToggle} =
    props;
  const onToggle = useCallback(() => {
    onItemToggle(sectionId, itemId);
  }, [itemId, onItemToggle, sectionId]);
  return (
    <ItemView
      itemId={itemId}
      style={styles.nestedItem}
      title={title}
      isDisabled={isDisabled}
      getIsActive={getIsActive}
      onToggle={onToggle}
    />
  );
});

type SectionHeaderProps = {
  sectionId: string;
  onSectionToggle: (sectionId: string) => void;
} & BaseItemViewProps;

const SectionHeader = observer((props: SectionHeaderProps) => {
  const {title, isDisabled, getIsActive, sectionId, onSectionToggle} = props;
  const onToggle = useCallback(() => {
    onSectionToggle(sectionId);
  }, [onSectionToggle, sectionId]);
  return (
    <ItemView
      isSection
      title={title}
      getIsActive={getIsActive}
      isDisabled={isDisabled}
      onToggle={onToggle}
    />
  );
});

type ItemViewProps = {
  isHeader?: boolean;
  isSection?: boolean;
  hasBottomSeparator?: boolean;
  onToggle: () => void;
  noOffset?: boolean;
} & BaseItemViewProps &
  ViewProps;

const ItemView = observer<ItemViewProps, View>(
  React.forwardRef((props, ref) => {
    const {
      itemId,
      title,
      getIsActive,
      isSection = false,
      isHeader = false,
      hasBottomSeparator = false,
      isDisabled,
      onToggle,
      noOffset,
      ...rest
    } = props;

    const theme = useTheme();
    const strings = useStrings();
    const handleUrlPress = useCallback(async () => {
      await Linking.openURL(
        'https://ncwallet.net/faq/what-is-two-factor-authentication-(2fa)-and-how-is-it-used-in-nc-wallet/',
      );
    }, []);
    return (
      <Row
        ref={ref}
        header={isHeader}
        bottomSeparator={hasBottomSeparator}
        {...rest}>
        <Row section={isSection && !isHeader} noOffset={noOffset}>
          <Title section={isSection}>{strings[title]}</Title>
          <SizedBox />
          {(itemId === '2fa_enabled' ||
            itemId === '2fa_disabled' ||
            itemId === '2fa_settings_updated') && (
            <Pressable onPress={handleUrlPress}>
              <HelpIcon color={theme.palette.uiAdditional1} />
            </Pressable>
          )}
        </Row>
        <Switch
          getIsActive={getIsActive}
          onChange={onToggle}
          disabled={isDisabled}
        />
      </Row>
    );
  }),
);

const HelpIcon = sized(HelpSvg, 17);

const SizedBox = variance(View)(() => ({
  root: {
    width: 5,
  },
}));

const ItemSeparatorComponent = () => (
  <Separator style={styles.nestedSeparator} />
);

const Container = variance(View)(() => ({
  root: {
    flex: 1,
    maxWidth: 1180,
    marginHorizontal: 'auto',
  },
}));

const Header = variance(View)(theme => ({
  root: {
    justifyContent: 'space-between',
    padding: 15,
    gap: 10,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme.palette.background,
        borderRadius: 8,
        padding: 10,
        gap: 20,
        marginBottom: 20,
        ...theme.bar(10),
      },
    }),
  },
}));

const Row = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    height: 59,
  },
  header: {
    backgroundColor: theme.palette.additional4,
    borderBottomColor: theme.palette.primary,
    paddingLeft: 0,
    paddingRight: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      },
    }),
  },
  section: {
    paddingHorizontal: 0,
  },
  bottomSeparator: {
    borderBottomWidth: 1,
  },
  noOffset: {
    paddingHorizontal: 0,
  },
}));

const Title = variance(Text)(
  theme => ({
    root: {
      flexGrow: 1,
      flexShrink: 1,
      ...theme.fontByWeight('600'),
      color: theme.palette.textPrimary,
      fontSize: 16,
      lineHeight: 20,
    },
    section: {
      ...theme.fontByWeight('700'),
      fontSize: 18,
      lineHeight: 22,
    },
  }),
  () => ({
    ellipsizeMode: 'middle',
    numberOfLines: 1,
  }),
);

const Separator = variance(View)(theme => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.uiSecondary,
    alignSelf: 'stretch',
  },
}));

const SectionHeaderView = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.background,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      },
    }),
  },
}));

const SectionItemView = variance(ItemView)(theme => ({
  root: {
    flex: 1,
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: theme.palette.additional4,
    borderColor: theme.palette.primary,
  },
}));

const styles = StyleSheet.create({
  root: {
    flexGrow: 0,
    flexShrink: 0,
  },
  container: {
    flexGrow: 1,
  },
  nestedSeparator: {
    marginLeft: 20,
    marginRight: 15,
  },
  nestedItem: {
    paddingLeft: 5,
  },
  sectionFooter: {
    marginBottom: 20,
  },
});
