import {clamp} from 'lodash';
import {action, computed, makeObservable, observable} from 'mobx';

import type {RateModalBindingState} from './RateModalBindingState';

export default class RateModalBindingStateImpl
  implements RateModalBindingState
{
  /**
   * App rating from 0 to 5
   *
   * 0 means no value set
   */
  @observable.ref private _rating = 0;

  constructor() {
    makeObservable(this);
  }

  readonly getRating = () => {
    return this._rating;
  };

  readonly changeRating = action((_rating: number) => {
    this._rating = clamp(_rating, 0, 5);
  });

  @computed get isRatingGood() {
    return this._rating > 3;
  }

  @computed get isRatingSet() {
    return this._rating > 0;
  }
}
