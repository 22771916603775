import type {
  AuthHelper,
  AuthState,
  Biometrics,
  BiometricSettingsManager,
  BiometricStateHelper,
  ISODateString,
  PinCode,
} from '@ncwallet-app/core';
import {
  ErrorRecognizerStatic,
  FULFILLED,
  getFormattedTimeFromNow,
  isUtcDateAfterNow,
  REJECTED,
} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import type {
  PinInvalidError,
  PinNotSetError,
} from '@ncwallet-app/core/src/ErrorRecognizer/PinError';
import {PinErrorKind} from '@ncwallet-app/core/src/ErrorRecognizer/PinError';
import type {FlashMessage} from '@ncwallet-app/core/src/FlashMessage';
import type {Haptics} from '@ncwallet-app/core/src/Haptics';
import type {LoadingIndicator} from '@ncwallet-app/core/src/LoadingIndicator';
import type {
  // eslint-disable-next-line import-x/no-deprecated
  LegacyLocalization,
  UserPreferenceState,
} from '@ncwallet-app/core/src/Localization';
import {NotificationFeedbackType} from 'expo-haptics';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';

import {FULL_PIN_LENGTH} from '../../../screens/PinScreen/constants';
import {DELAY_FOR_VISIBLE_FULL_PIN} from './constants';

// eslint-disable-next-line import-x/prefer-default-export
export class CheckPinBindingState {
  @observable private _pinCode = '';
  @observable private _errorText: string | undefined;
  private isBiometricsActive = false;

  private _pinTimeoutRef: ReturnType<typeof setTimeout> | undefined;

  @observable private _canSignInWithBiometrics: boolean = false;

  @computed get canSignInWithBiometrics() {
    return this._canSignInWithBiometrics;
  }

  @computed get errorText() {
    return this._pinCode.length === 0 ? this._errorText : undefined;
  }

  constructor(
    private readonly _root: {
      readonly authState: AuthState;
      readonly accountStore: AccountStore;
      readonly authHelper: AuthHelper;
      readonly loadingIndicator: LoadingIndicator;
      readonly userPreferenceState: UserPreferenceState;
      readonly biometricStateHelper: BiometricStateHelper;
      readonly flashMessage: FlashMessage;
      readonly biometricSettingsManager: BiometricSettingsManager;
      readonly biometrics: Biometrics;
      readonly haptics: Haptics;
      // eslint-disable-next-line @typescript-eslint/no-deprecated,import-x/no-deprecated
      readonly translation: LegacyLocalization;
    },
  ) {
    makeObservable(this);
  }

  getPinCode = () => this._pinCode;

  async refresh() {
    this._checkPinError();

    const canSignInWithBiometrics =
      await this._root.biometricStateHelper.canSignInWithBiometrics();

    runInAction(() => {
      this._canSignInWithBiometrics = canSignInWithBiometrics;
    });
  }

  @action.bound
  private _updateErrorText(text?: string) {
    this._errorText = text;
  }

  @action.bound
  setPinCode(pinCode: string) {
    this._pinCode = pinCode;
    if (this._pinCode.length !== FULL_PIN_LENGTH) {
      clearTimeout(this._pinTimeoutRef);
      return;
    }

    this._pinTimeoutRef = setTimeout(
      () => this._verifyPin(pinCode as PinCode),
      DELAY_FOR_VISIBLE_FULL_PIN,
    );
  }

  private _checkPinError() {
    if (this._root.authState.latest?.status === REJECTED) {
      const error = ErrorRecognizerStatic.getPinError(
        this._root.authState.latest.error,
      );
      switch (error?.kind) {
        case PinErrorKind.PinInvalid:
          this._updateTextForInvalidError(error);
          break;
        case PinErrorKind.PinNotSet:
          void this._signOutForPinNotSet(error);
          break;
        default:
          return;
      }
    } else {
      this._updateErrorText(undefined);
    }
  }

  @action.bound
  private async _verifyPin(pinCode: PinCode) {
    const res = await this._root.authHelper.signInByPin(pinCode);
    if (!res.success) {
      this._handleInvalidPin();
    }
  }

  @action.bound
  private _handleInvalidPin() {
    void this._root.haptics.notification(NotificationFeedbackType.Error);
  }

  activateBiometrics = async () => {
    if (this.isBiometricsActive) {
      return;
    }
    this.isBiometricsActive = true;
    try {
      await this._root.biometrics.signInByBiometric();
    } finally {
      this.isBiometricsActive = false;
    }
  };

  signOut = () =>
    this._root.authHelper.signOut({reason: 'CheckPinBinding, onCancel'});

  onUnmount() {
    clearTimeout(this._pinTimeoutRef);
  }

  private _updateTextForInvalidError = (error: PinInvalidError | undefined) => {
    if (error?.kind !== PinErrorKind.PinInvalid) {
      return;
    }
    const text =
      error.nextAllowedAttempt && isUtcDateAfterNow(error.nextAllowedAttempt)
        ? this._formatTooManyAttemptsError(error.nextAllowedAttempt)
        : // eslint-disable-next-line @typescript-eslint/no-deprecated
          this._root.translation.strings['checkPinScreen.error'];

    this._updateErrorText(text);
  };

  private _signOutForPinNotSet = async (error: PinNotSetError | undefined) => {
    if (error?.kind !== PinErrorKind.PinNotSet) {
      return;
    }

    this._root.flashMessage.showMessage({
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      title: this._root.translation.strings['error.pinNotSet'],
      variant: 'danger',
    });
    await this._root.authHelper.signOut({reason: 'Pin not set'});
  };

  private _formatTooManyAttemptsError(nextAllowedAttempt: ISODateString) {
    const fromNow = getFormattedTimeFromNow(
      nextAllowedAttempt,
      this._root.userPreferenceState.languageCode,
    );

    // eslint-disable-next-line @typescript-eslint/no-deprecated
    return this._root.translation.templates[
      'checkPinScreen.tooManyAttempsError'
    ]({interval: fromNow});
  }

  clearBiometryIfKeysNotExist() {
    return reaction(
      () =>
        !this._root.biometrics.localKeysExist &&
        this._root.accountStore.state?.status === FULFILLED &&
        (this._root.accountStore.state.result.settings.biometry.sign_in ||
          this._root.accountStore.state.result.settings.biometry['2fa']),
      (thereAreNoKeys: boolean) => {
        if (thereAreNoKeys) {
          void this._root.biometricSettingsManager.removeAllBiometrics();
        }
      },
      {fireImmediately: true},
    );
  }
}
