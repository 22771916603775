import type {ISODateString} from '@ncwallet-app/core';
import {useDateFormatting, variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT, TouchableOpacity} from '@ncwallet-app/ui';
import {
  CrossSvg,
  PaperSheetSVG,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type UploadedResultItemProps = {
  file: string;
  isFirst: boolean;
  uploadedAt: ISODateString;
  onDelete: () => void;
};

export default observer(function UploadedResultItem(
  props: UploadedResultItemProps,
) {
  const {file = '/no_name.undefined', isFirst, uploadedAt, onDelete} = props;
  const {formatDateTimeWithYear} = useDateFormatting();
  const folders = file.split('/');
  const fulName = folders[folders.length - 1];
  const [, format] = fulName.split('.');
  return (
    <RootView first={isFirst}>
      <ImageView>
        <PaperSheetIcon />
      </ImageView>
      <BodyView>
        <TitleText ellipsizeMode={'tail'} numberOfLines={1}>
          {formatDateTimeWithYear(uploadedAt)}
        </TitleText>
        <InfoView>
          <InfoText>{format.toUpperCase()}</InfoText>
        </InfoView>
      </BodyView>
      <ActionTouchable onPress={onDelete}>
        <CrossIcon />
      </ActionTouchable>
    </RootView>
  );
});

const RootView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: -15,
    paddingHorizontal: 15,
    paddingVertical: 7,
    borderBottomWidth: 1,
    borderColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderTopWidth: 1,
        borderBottomWidth: 0,
      },
    }),
  },
  first: {
    borderTopWidth: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderTopWidth: 0,
      },
    }),
  },
  error: {
    backgroundColor: theme.palette.additional5,
  },
}));

const ImageView = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 56,
    aspectRatio: 1,
    marginRight: 10,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiPrimary,
  },
}));

const PaperSheetIcon = variance(PaperSheetSVG)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.uiMain,
  }),
);

const BodyView = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const TitleText = variance(Text)(theme => ({
  root: {
    marginBottom: 10,
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
    fontSize: 14,
  },
}));

const InfoView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const InfoText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 13,
    color: theme.palette.textAdditional3,
  },
}));

const CrossIcon = variance(CrossSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.uiMain,
  }),
);

const ActionTouchable = variance(TouchableOpacity)(
  () => ({
    root: {},
    container: {
      marginLeft: 15,
    },
  }),
  (_, styles) => ({
    containerStyle: styles.container,
  }),
);
