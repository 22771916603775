/* eslint-disable @typescript-eslint/unbound-method */
import {useStrings, variance} from '@ncwallet-app/core';
import type {FiatCurrency} from '@ncwallet-app/core/src/NCWalletServer/FiatCurrency';
import {LG_BREAKPOINT, SearchHeader} from '@ncwallet-app/ui/src';
import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import type {BaseCryptoSettingsBindingState} from '../../../Navigation/HomeStack/BaseCryptoSettingsBinding/BaseCryptoSettingsBindingState';
import type {BaseFiatSettingsBindingState} from '../../../Navigation/HomeStack/BaseFiatSettingsBinding/BaseFiatSettingsBindingState';
import {LgCryptoSectionList} from '../../UserSettingsScreens/BaseCryptoSettingScreen';
import type {CryptoSectionItemData} from '../../UserSettingsScreens/BaseCryptoSettingScreen/CryptoSectionItem';
import {LgFiatSectionList} from '../../UserSettingsScreens/BaseFiatSettingScreen';
import {
  BodyTag,
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from './useCardStyles';

export type BaseCardProps = {
  currencyTab: CurrencyTab;
  focusCryptoTab: () => void;
  focusFiatTab: () => void;
  baseCryptoState: BaseCryptoSettingsBindingState;
  baseFiatState: BaseFiatSettingsBindingState;
  onCryptoSelect: (crypto: CryptoSectionItemData) => void;
  onFiatSelect: (fiat: FiatCurrency) => void;
  baseCurrency: CryptoSectionItemData | undefined;
  othersCurrency: CryptoSectionItemData[] | undefined;
  baseFiat: FiatCurrency | undefined;
  othersFiat: FiatCurrency[] | undefined;
  showDoneMessage: () => void;
};

export enum CurrencyTab {
  Crypto,
  Fiat,
}

export default observer(function BaseCard(props: BaseCardProps) {
  const {
    currencyTab,
    focusCryptoTab,
    focusFiatTab,
    baseCryptoState,
    baseFiatState,
    onCryptoSelect,
    onFiatSelect,
    baseCurrency,
    othersCurrency,
    baseFiat,
    othersFiat,
  } = props;
  const strings = useStrings();
  return (
    <LgProfileCardContainer>
      <HeaderTag smPadding>
        <HeaderTitleTag>
          {strings['profileSettingsScreen.baseTitle']}
        </HeaderTitleTag>
      </HeaderTag>
      <BodyTag smHidePadding>
        <Switcher>
          <SwitcherTextContainer active={currencyTab === CurrencyTab.Crypto}>
            <SwitcherText
              active={currencyTab === CurrencyTab.Crypto}
              onPress={focusCryptoTab}>
              {strings['profileSettingsScreen.baseCrypto']}
            </SwitcherText>
          </SwitcherTextContainer>
          <SwitcherTextContainer active={currencyTab === CurrencyTab.Fiat}>
            <SwitcherText
              active={currencyTab === CurrencyTab.Fiat}
              onPress={focusFiatTab}>
              {strings['profileSettingsScreen.baseFiat']}
            </SwitcherText>
          </SwitcherTextContainer>
        </Switcher>
        {currencyTab === CurrencyTab.Crypto && (
          <>
            <ProfileSearchHeader
              value={baseCryptoState.filter}
              onValueChange={baseCryptoState.setFilter}
              onSubmit={noop}
              placeholder={
                strings['profileSettingsScreen.searchCryptoCurrencyPlaceholder']
              }
            />
            <LgCryptoSectionList
              baseFiatCode={baseFiat?.code}
              baseCurrency={baseCurrency}
              othersCurrency={othersCurrency}
              onCryptoSelect={onCryptoSelect}
            />
          </>
        )}
        {currencyTab === CurrencyTab.Fiat && (
          <>
            <ProfileSearchHeader
              value={baseFiatState.filter}
              onValueChange={baseFiatState.setFilter}
              onSubmit={noop}
              placeholder={
                strings['profileSettingsScreen.searchCryptoCurrencyPlaceholder']
              }
            />
            <LgFiatSectionList
              baseFiat={baseFiat}
              othersFiat={othersFiat}
              onFiatSelect={onFiatSelect}
            />
          </>
        )}
      </BodyTag>
    </LgProfileCardContainer>
  );
});

const Switcher = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
    width: '100%',
    padding: 4,
    borderWidth: 1,
    backgroundColor: theme.palette.uiPrimary,
    borderColor: theme.palette.uiSecondary,
    borderRadius: 15,
    height: 36,
    marginBottom: 15,
  },
}));

const SwitcherTextContainer = variance(View)(theme => ({
  root: {
    flex: 1,
    height: '100%',
  },
  active: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary,
  },
}));

const SwitcherText = variance(Text)(theme => ({
  root: {
    flex: 1,
    height: '100%',
    textAlign: 'center',
    lineHeight: 28,
    fontSize: 13,
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional3,
  },
  active: {
    color: theme.palette.whitey,
    ...theme.fontByWeight('700'),
  },
}));

const ProfileSearchHeader = variance(SearchHeader)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingBottom: 13,
    paddingTop: 0,
    backgroundColor: theme.palette.uiPrimary,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        paddingBottom: 0,
        backgroundColor: 'transparent',
        borderBottomWidth: 0,
      },
    }),
  },
}));
