import {useDateFormatting, useStrings, variance} from '@ncwallet-app/core';
import {DeviceIcon} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import type {HistorySessionInfo} from './HistorySessionInfo';

export type SessionHistoryItemProps = {
  data: HistorySessionInfo;
};

export default observer(function SessionHistoryItem(
  props: SessionHistoryItemProps,
) {
  const {formatDateTimeWithYear} = useDateFormatting();
  const date = formatDateTimeWithYear(props.data.time);
  const strings = useStrings();
  return (
    <Container>
      {props.data.platform ? (
        <Icon>
          <DeviceIcon type={props.data.platform} />
        </Icon>
      ) : null}
      <Col>
        <Row>
          <Date>{date}</Date>
          {props.data.status === 'blocked' && (
            <Status>{strings['secureSettingsScreen.blocked']}</Status>
          )}
        </Row>
        <Title numberOfLines={1}>{props.data.title}</Title>
        <InfoText>{props.data.info}</InfoText>
      </Col>
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const Status = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    lineHeight: 15,
    fontSize: 11,
    color: theme.palette.error,
    marginLeft: 5,
  },
}));

const Icon = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const Col = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 15,
    justifyContent: 'center',
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    flex: 1,
    ...theme.fontByWeight('700'),
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.textPrimary,
  },
}));

const InfoText = variance(Text)(theme => ({
  root: {
    marginTop: 3,
    ...theme.fontByWeight('500'),
    fontSize: 12,
    lineHeight: 15,
    color: theme.palette.textAdditional2,
  },
}));

const Row = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Date = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 11,
    lineHeight: 15,
    color: theme.palette.textAdditional2,
  },
}));
