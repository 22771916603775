import {BigNumber} from 'bignumber.js';
import {isNil} from 'lodash';

import type {CryptoCurrencyCode, CurrencyValue, DecimalString} from '../Money';
import type {Tagged} from '../Opaque';
import type {ISODateString} from '../Time';
import type {AddressInfo} from './AddressInfo';
import {getDefaultAddressInfo} from './AddressInfo';
import type {CryptoCurrency} from './CryptoCurrency';
import {getDefaultInOutCurrency} from './InOutCurrency';

export type Wallet<Code extends CryptoCurrencyCode = CryptoCurrencyCode> = {
  id: WalletId;
  addresses: AddressInfo[];
  /**
   * @example "My first wallet"
   */
  description: string;
  currency: Code;
  /**
   * @example "2021-12-21T09:43:42.575081"
   */
  created_at: ISODateString;
  updated_at: ISODateString;
  total: CurrencyValue<Code>;
  is_visible: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WALLET_ID = Symbol();
/**
 * @example "PLZORP6QZAA6FNCTFHPA"
 */
export type WalletId = Tagged<string, typeof WALLET_ID>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CRYPTO_ADDRESS = Symbol();
/**
 * @example "mmJVa4zAkmi5QkJ167QmWBm2GbUmgyCT46"
 */
export type CryptoAddress = Tagged<string, typeof CRYPTO_ADDRESS>;

export const isEnoughMoneyForSendOrExchange = (w: {total: DecimalString}) => {
  const minimalTotal = '0.00000001';
  return BigNumber(w.total).isGreaterThanOrEqualTo(minimalTotal);
};

export const walletsByCurrencyPopularitySorter = (
  w1: {currency: CryptoCurrencyCode},
  w2: {currency: CryptoCurrencyCode},
  currencyToPopularityMap: Map<CryptoCurrencyCode, number>,
): number => {
  const lowestPopularity = 99999;
  const w1Popularity =
    currencyToPopularityMap.get(w1.currency) ?? lowestPopularity;
  const w2Popularity =
    currencyToPopularityMap.get(w2.currency) ?? lowestPopularity;
  return w1Popularity < w2Popularity ? -1 : 1;
};

export const getDefaultAddressInfoForWallet = <
  Code extends CryptoCurrencyCode = CryptoCurrencyCode,
>(
  w: Wallet<Code>,
  c: CryptoCurrency<Code>,
  direction: 'in' | 'out',
) => {
  const currencyIn = getDefaultInOutCurrency(c, direction);
  return getDefaultAddressInfo(
    w.addresses,
    currencyIn.network,
    currencyIn.default_type,
  );
};

export const maxAmountRestrictedByLimit = (
  w: Wallet,
  remainingLimit: DecimalString | undefined,
): boolean =>
  !isNil(remainingLimit) && BigNumber(remainingLimit).isLessThan(w.total);

export const getMaxAmountForExchange = (
  w: Wallet,
  remainingLimit: DecimalString | undefined,
): DecimalString => {
  return !isNil(remainingLimit) && maxAmountRestrictedByLimit(w, remainingLimit)
    ? remainingLimit
    : w.total;
};

export const getMaxAmountForWithdrawal = (
  w: Wallet,
  remainingLimit: DecimalString | undefined,
  minFee?: DecimalString,
): DecimalString => {
  const baseMax = getMaxAmountForExchange(w, remainingLimit);
  if (!minFee) {
    return baseMax;
  }

  const maxMinusFee = BigNumber(baseMax).minus(minFee).toString();
  return BigNumber.max(maxMinusFee, 0).toString();
};

export const isMinFeeMoreThanAvailableMax = (
  w: Wallet,
  remainingLimit: DecimalString | undefined,
  minFee?: DecimalString,
): boolean => {
  const baseMax = getMaxAmountForWithdrawal(w, remainingLimit);

  return (
    !!minFee &&
    BigNumber(minFee).isGreaterThan('0') &&
    BigNumber(baseMax).isLessThanOrEqualTo(minFee)
  );
};
