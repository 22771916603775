import type {InOutCurrency} from '../NCWalletServer';
import type {CryptoCurrency} from '../NCWalletServer/CryptoCurrency';
import type {FiatCurrency} from '../NCWalletServer/FiatCurrency';

export type CurrencyDescription = {
  /**
   * @example "BTC"
   * @example "USD"
   */
  code: string;
  /**
   * @example "Bitcoin"
   * @example "US Dollar"
   */
  name: string;
  /**
   * @example 8
   * @example 2
   */
  fractionDigits: number;
  canChange?: boolean;
  showNetwork?: boolean;
  networks?: InOutCurrency[] | undefined;
  outNetworks?: InOutCurrency[] | undefined;
};

export const toCurrencyDescriptionFromCrypto = (
  crypto: CryptoCurrency,
): CurrencyDescription => ({
  code: crypto.code,
  name: crypto.name,
  fractionDigits: crypto.decimals ?? 0,
  canChange: crypto.options.is_visible_changeable,
  showNetwork: crypto.options.show_network,
  networks: crypto.options.currencies_in,
  outNetworks: crypto.options.currencies_out,
});

export const toCurrencyDescriptionFromFiat = (
  fiat: FiatCurrency,
): CurrencyDescription => ({
  code: fiat.code,
  name: fiat.name,
  fractionDigits: fiat.decimals,
});
