import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import type {SessionInfo} from '../ActiveSessionsScreen/SessionInfo';
import SessionRemovalBottomSheet from './PromtSessionDeletionBottomSheet';

export type SessionRemovalBottomSheetScreenProps = BaseSafeAreaProps & {
  session: SessionInfo | undefined;
  onCancelPress: () => void;
  onDeletePress: () => void;
};

export default observer(function SessionRemovalBottomSheetScreen(
  props: SessionRemovalBottomSheetScreenProps,
) {
  const {session, onCancelPress, onDeletePress} = props;

  if (!session) {
    return <></>;
  }

  return (
    <SessionRemovalBottomSheet
      session={session}
      onCancelPress={onCancelPress}
      onDeletePress={onDeletePress}
    />
  );
});
