import type {Url} from '@ncwallet-app/core';
import {urlLinks, useRoot, variance} from '@ncwallet-app/core';
import {
  useStateErrorColor,
  useStateErrorText,
} from '@ncwallet-app/core/src/UserStatusErrorDetector';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Text, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';

import {HelpSvg} from '../../../assets/svg/colorless';

export type UserStateErrorSmProps = {
  onTitlePress: () => void;
};

export default observer(function UserStateErrorSm(
  props: UserStateErrorSmProps,
) {
  const {location} = useRoot();
  const {onTitlePress} = props;
  const stateErrorText = useStateErrorText();
  const stateErrorColor = useStateErrorColor();
  const titleStyle = useMemo(
    () => (stateErrorColor ? {backgroundColor: stateErrorColor} : {}),
    [stateErrorColor],
  );

  const onKYCHelpPress = useCallback(() => {
    void location.open(urlLinks.kycFaq as Url);
  }, [location]);

  return (
    <TouchableOpacity activeOpacity={0.8} onPress={onTitlePress}>
      <TitleView style={titleStyle}>
        <TitleText
          numberOfLines={1}
          adjustsFontSizeToFit
          minimumFontScale={0.7}>
          {stateErrorText}
        </TitleText>
        <TouchableOpacity onPress={onKYCHelpPress}>
          <HelpIcon />
        </TouchableOpacity>
      </TitleView>
    </TouchableOpacity>
  );
});

const TitleView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 24,
    paddingHorizontal: 12,
    paddingVertical: 6,
    backgroundColor: theme.chroma(theme.palette.primary).darken(0.4).hex(),
    gap: 10,
  },
}));

const TitleText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    fontSize: 16,
    color: theme.palette.whitey,
    textAlign: 'center',
  },
}));

const HelpIcon = variance(HelpSvg)(
  () => ({root: {}}),
  () => ({
    color: 'rgba(255, 255, 255, 0.5)',
    width: 16,
    height: 16,
  }),
);
