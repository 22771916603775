import type {CryptoCurrencyCode} from '@ncwallet-app/core';
import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {
  Input,
  LG_BREAKPOINT,
  Pressable,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {MinusSVG, PlusSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {toString} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import type {GeneratePayMeLinkBindingState} from '../../Navigation/HomeStack/GeneratePayMeLinkBinding/GeneratePayMeLinkBindingState';

export type LinkAmountInputProps = {
  amount?: string;
  onChangeAmount: (val: string) => void;
  currency?: CryptoCurrencyCode;
  disabled?: boolean;
  onMakeAvailableEdit: GeneratePayMeLinkBindingState['onMakeAvailableEdit'];
  onFocus?:
    | ((options?: {x: number; y: number; animated: boolean}) => void)
    | undefined;
};

export default observer(function LinkAmountInput({
  amount,
  onChangeAmount,
  currency,
  onMakeAvailableEdit,
  onFocus,
}: LinkAmountInputProps) {
  const [show, setShow] = useState(false);
  const theme = useTheme();
  const strings = useStrings();
  const renderSlot = useCallback(() => {
    return <AmountSlot>{currency}</AmountSlot>;
  }, [currency]);
  const isLg = useIsDimensions('lg');
  useEffect(() => {
    if (amount && amount !== '0') {
      setShow(true);
    }
  }, [amount, onMakeAvailableEdit]);
  return (
    <Container>
      <Top>
        <TopText>{strings['generatePayMeLink.amount.label']}</TopText>
        <Toggle
          onPress={() => {
            setShow(!show);
          }}>
          {show ? (
            <MinusSVG color={theme.palette.info} />
          ) : (
            <PlusSvg color={theme.palette.info} />
          )}
        </Toggle>
      </Top>
      {(show || isLg) && (
        <Wrapper>
          <Input
            containerStyle={styles.inputContainer}
            placeholder={strings['generatePayMeLink.amount.placeholder']}
            value={amount && toString(amount)}
            onChangeText={onChangeAmount}
            keyboardType="numeric"
            Slot={renderSlot}
            disabled={!show}
            onPressIn={() => onFocus?.({x: 0, y: 10000, animated: true})}
          />
          <AmountSubtitle>
            {strings['generatePayMeLink.large.subtitle']}
          </AmountSubtitle>
        </Wrapper>
      )}
    </Container>
  );
});

const styles = StyleSheet.create({
  inputContainer: {
    flexGrow: 1,
  },
});

const Container = variance(View)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 1,
      },
    }),
  },
}));

const Wrapper = variance(View)(theme => ({
  root: {
    flexGrow: 1,
    gap: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
}));

const Toggle = variance(Pressable)(() => ({
  root: {
    padding: 10,
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Top = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        justifyContent: 'flex-start',
      },
    }),
  },
}));

const TopText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.textPrimary,
  },
}));

const AmountSlot = variance(Text)(theme => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.uiPrimary,
    borderLeftWidth: 1,
    padding: 15,
    textAlign: 'center',
    color: theme.palette.textPrimary,
    borderLeftColor: theme.palette.uiSecondary,
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 17,
  },
}));

const AmountSubtitle = variance(Text)(theme => ({
  root: {
    flex: 1,
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 22,
    color: theme.palette.textAdditional1,
    marginBottom: 20,
  },
}));
