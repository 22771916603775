import {getOSKind, OSKind} from '@ncwallet-app/core/src/util/getOSKind';
import {
  AppStoreSvg,
  GooglePlaySvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import React from 'react';
import type {StyleProp, ViewStyle} from 'react-native';

type RateModalIconProps = {
  style?: StyleProp<ViewStyle>;
};

export default function RateModalIcon(props: RateModalIconProps) {
  switch (getOSKind()) {
    case OSKind.Android:
      return <GooglePlaySvg {...props} />;
    case OSKind.IOs:
    case OSKind.MacOS:
      return <AppStoreSvg {...props} />;
    default:
      return null;
  }
}
