/* eslint-disable @typescript-eslint/unbound-method */
import type {CryptoAddress} from '@ncwallet-app/core';
import {useStrings} from '@ncwallet-app/core';
import type {
  PromptCommissionForSendingRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {BigNumber} from 'bignumber.js';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import {SendCommissionBindingStateStatus} from '../../Navigation/HomeStack/SendCommision/SendCommissionBindingState';
import {useSendCommissionBindingState} from '../../Navigation/HomeStack/SendCommision/useSendCommissionBindingState';
import {EXTRA_BOTTOM_OFFSET} from '../../screens/constants';
import SendCommissionScreen from '../../screens/SendCommisionScreen/SendCommissionScreen';

export type PromptCommissionForSendingContainerProps = {
  params: RouteParams<PromptCommissionForSendingRoute>;
  onBack: () => void;
  onSubmit: (fee: string | undefined) => void;
};

export default observer(function PromptCommissionForSendingContainer(
  props: PromptCommissionForSendingContainerProps,
) {
  const {onBack, onSubmit: _onSubmit, params} = props;
  const {
    isEditable,
    currency,
    amount,
    walletId,
    fee,
    addressTo,
    addressNetwork,
    addressCurrency,
    minFreeWithdrawAmount,
  } = params;
  const state = useSendCommissionBindingState(
    currency,
    addressNetwork,
    addressCurrency,
    addressTo as CryptoAddress,
    amount,
    fee,
    minFreeWithdrawAmount,
  );
  const strings = useStrings();

  const onSubmit = useCallback(() => {
    _onSubmit(state.fee);
  }, [_onSubmit, state.fee]);

  const max = state.getMax(walletId);
  const isAmountWithFeeMoreThatMax = useMemo(() => {
    if (state.fee === undefined || !Number(state.fee) || !amount || !max) {
      return false;
    }

    const _fee = BigNumber(state.fee);
    const _amount = BigNumber(amount);
    const _max = BigNumber(max);

    return (
      _fee.isFinite() &&
      _amount.isFinite() &&
      _max.isFinite() &&
      _fee.plus(_amount).isGreaterThanOrEqualTo(_max)
    );
  }, [state.fee, amount, max]);
  return (
    <SendCommissionScreen
      error={
        isAmountWithFeeMoreThatMax
          ? strings['sendCrypto.validation.amountAndFeeMoreThanTotal']
          : undefined
      }
      onBackPress={onBack}
      getHasNoCommission={state.getHasNoCommission}
      setHasNoCommission={state.setHasNoCommission}
      minFreeAmount={state.minFreeAmount}
      commissionSwitcherHidden={!state.freeSendAvailableForCrypto}
      isEnoughAmountForFreeSend={state.isEnoughAmountForFreeSend}
      stepCurrent={3}
      stepTotal={3}
      currency={currency}
      isEditable={isEditable}
      onSubmit={onSubmit}
      recommendedFee={state.recommendedFee}
      fees={state.fees}
      fee={state.fee}
      setFee={state.setFee}
      disabled={state.status === SendCommissionBindingStateStatus.Load}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});
