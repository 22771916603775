import type {
  TranslationProvider,
  UserPreferenceState,
} from '@ncwallet-app/core/src/Localization';
import {action, computed, makeObservable, observable} from 'mobx';

// eslint-disable-next-line import-x/prefer-default-export
export class PromptToSelectVerificationCountryBindingState {
  @observable private _searchQuery = '';

  constructor(
    private readonly _root: {
      readonly userPreferenceState: UserPreferenceState;
      readonly translationProvider: TranslationProvider;
    },
  ) {
    makeObservable(this);
  }

  @computed
  get countries() {
    const countries = this._root.translationProvider.getCountryNamesData(
      this._root.userPreferenceState.languageCode,
    );

    if (this._searchQuery.length) {
      return countries.filter(it =>
        it.name.toLowerCase().includes(this._searchQuery.toLowerCase()),
      );
    }

    return countries;
  }

  get searchQuery() {
    return this._searchQuery;
  }

  @action
  search(query: string) {
    this._searchQuery = query;
  }

  @action.bound
  resetSearch() {
    this._searchQuery = '';
  }
}
