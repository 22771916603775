import {FULFILLED} from '../AsyncAtom';
import type {AuthState} from '../Auth';
import {MULTI_FACTOR, PRE_AUTH} from '../Auth';
import type {JwtHelper} from '../Auth/JwtHelper';
import {BaseFreshTokenStateImpl} from '../FreshTokenState';
import type {Millisecond} from '../Time';
import type {TimeState} from '../TimeState';
import type {MultiFactorToken} from '../units';
import type {FreshMultiFactorTokenState} from './FreshMultiFactorTokenState';

export default class FreshMultiFactorTokenStateImpl
  extends BaseFreshTokenStateImpl<MultiFactorToken>
  implements FreshMultiFactorTokenState
{
  constructor(
    protected readonly _root: {
      readonly authState: AuthState;
      readonly timeState: TimeState;
      readonly jwtHelper: JwtHelper;
    },
  ) {
    super(_root, 1000 as Millisecond);
  }

  protected _getToken(): MultiFactorToken | undefined {
    const {latest} = this._root.authState;
    if (latest?.status !== FULFILLED) {
      return undefined;
    }
    const {result} = latest;
    if (result.kind === PRE_AUTH || result.kind === MULTI_FACTOR) {
      return result.credentials.multiFactorToken;
    }
    return undefined;
  }
}
