import {FULFILLED, PENDING, REJECTED} from '../AsyncAtom';
import type {Either} from '../fp';
import {error} from '../fp';
import type {RouterSource} from '../structure';
import {RouterImpl} from '../structure';
import type {
  TaskController,
  TaskState,
  TaskStatus,
  TaskStatusMap,
} from './TaskController';

export default class RetryTaskControllerImpl<R, E, A extends unknown[] = []>
  implements TaskController<R, E, A>
{
  private _currentStatus?: TaskStatus;
  private _controller?: AbortController;
  private _promise?: Promise<Either<R, E>>;
  private readonly _outcome = new RouterImpl<TaskStatusMap<R, E, A>>();
  public readonly state: TaskState<R, E, A>;

  constructor(
    private readonly _execute: (
      signal: AbortSignal,
      ...args: A
    ) => Promise<Either<R, E>>,
    private readonly _wrapRawError: (_: unknown) => E,
  ) {
    const that = this;
    this.state = {
      getStatus() {
        return that._currentStatus;
      },
      get outcome(): RouterSource<TaskStatusMap<R, E, A>> {
        return that._outcome;
      },
    };
  }

  cancel() {
    this._controller?.abort();
    this._currentStatus = REJECTED;
  }

  execute(...args: A) {
    this._controller?.abort();
    this._currentStatus = PENDING;
    this._outcome.send(PENDING, ...args);
    this._controller = new AbortController();
    const promise = this._execute(this._controller.signal, ...args)
      .then(_ => {
        if (promise === this._promise) {
          if (_.success) {
            this._currentStatus = FULFILLED;
            this._outcome.send(FULFILLED, _.right);
          } else {
            this._currentStatus = REJECTED;
            this._outcome.send(REJECTED, _.left);
          }
        }
        return _;
      })
      .catch((raw: unknown) => {
        const wrapped = this._wrapRawError(raw);
        if (promise === this._promise) {
          this._currentStatus = REJECTED;
          this._outcome.send(REJECTED, wrapped);
        }
        return error(wrapped);
      });
    this._promise = promise;
  }
}
