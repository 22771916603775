import type {GlobalError} from '../Error';
import {UNKNOWN_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {Json} from '../Json';
import type {HeadlessMessaging} from './HeadlessMessaging';
import type {MessageDescription} from './Message';
import type {MessageContext} from './MessageContext';
import type {RemoteMessageStore} from './RemoteMessageStore';

export default class HeadlessMessagingImpl implements HeadlessMessaging {
  constructor(
    protected readonly _parent: {
      readonly errorRepository: ErrorRepository;
      readonly remoteMessageStore: RemoteMessageStore;
      readonly json: Json;
    },
  ) {}

  async fetchContext(
    description: MessageDescription,
  ): Promise<Either<MessageContext, GlobalError>> {
    const meta_ = this._parent.json.parse(description.meta);
    if (!meta_.success) {
      return meta_;
    }
    const meta = meta_.right;
    switch (description.type) {
      case 'stored': {
        const {url, event_url} = description;
        const message_ = await this._parent.remoteMessageStore.fetch(url, meta);
        if (!message_.success) {
          return message_;
        }
        const message = message_.right;
        return success({message, event_url, meta, url});
      }
      case 'instant': {
        const {data, event_url} = description;
        const message_ = this._parent.json.parse(data);
        if (!message_.success) {
          return message_;
        }
        const message = message_.right;
        return success({message, event_url, meta});
      }
      default:
        return error(
          this._parent.errorRepository.create({
            kind: UNKNOWN_ERROR,
            description: `Unknown message description type: ${
              (description as MessageDescription).type
            }`,
          }),
        );
    }
  }
}
