import type {
  Millisecond,
  TransactionFilterKind,
  WalletId,
} from '@ncwallet-app/core';
import {
  getDirectionFromTransactionFilter,
  getTypeFromTransactionFilter,
  parseMillisecond,
  toCurrencyDescriptionFromCrypto,
  useRoot,
} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {
  ListHistoryFiltersRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {SafeAreaInset} from '@ncwallet-app/ui';
import dayjs from 'dayjs';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';

import {useNavigationGetIsFocused} from '../../Navigation/hooks';
import {EXTRA_BOTTOM_OFFSET} from '../../screens/constants';
import {HistoryFilterScreen} from '../../screens/HistoryFilterScreen';

export type ListHistoryFiltersContainerProps = {
  params: RouteParams<ListHistoryFiltersRoute>;
  goToWalletList: () => void;
  goToHistoryFilterKind: () => void;
  goToCurrencyList: () => void;
  goToDateSelection: () => void;
  navigateBackToHistory: (
    walletId?: WalletId,
    from?: Millisecond,
    to?: Millisecond,
    transactionFilterKind?: TransactionFilterKind,
  ) => void;
  promptTransactionReport?: (id: string) => void;
};

export default observer(function ListHistoryFiltersContainer(
  props: ListHistoryFiltersContainerProps,
) {
  const {
    params,
    goToCurrencyList,
    goToDateSelection,
    goToHistoryFilterKind,
    goToWalletList,
    navigateBackToHistory,
    promptTransactionReport,
  } = props;
  const {walletId, transactionFilterKind} = params ?? {};
  const from = parseMillisecond(params?.from);
  const to = parseMillisecond(params?.to);

  const onAccept = useCallback(() => {
    navigateBackToHistory(
      params?.walletId,
      params?.from,
      params?.to,
      params?.transactionFilterKind,
    );
  }, [navigateBackToHistory, params]);
  const root = useRoot();

  const getWallet = useCallback(
    () => walletId && root.walletStore.getWalletById(walletId),
    [walletId, root],
  );

  const getWalletTotal = useCallback(() => getWallet()?.total, [getWallet]);

  const getWalletCurrency = useCallback(() => {
    const wallet = getWallet();
    const currency =
      wallet && root.currencyStore.getCryptoCurrency(wallet.currency);
    return currency && toCurrencyDescriptionFromCrypto(currency);
  }, [root, getWallet]);

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void root.walletStore.refreshWallets();
            void root.currencyStore.refreshCryptoCurrencies();
          });
        }
      }),
    [getIsFocused, getIsReady, root],
  );

  const handleTransactionReport = useCallback(async () => {
    const wallets: WalletId[] = [];
    if (params && params.walletId) {
      wallets.push(params.walletId);
    } else {
      const wallets_ = root.walletStore.getWallets();
      if (wallets_) {
        wallets.push(...wallets_.map(w => w.id));
      }
    }

    const transactionType =
      transactionFilterKind &&
      getTypeFromTransactionFilter(transactionFilterKind);

    await root.transactionReportHelper.generateMultipleTransactionReports({
      wallets_ids: wallets,
      ...(from && {date_from: dayjs(from).utc().local().format()}),
      date_to: dayjs(to).endOf('day').utc().local().format(),
      types: transactionType && [transactionType],
      direction:
        transactionFilterKind &&
        getDirectionFromTransactionFilter(transactionFilterKind),
    });
    if (
      params &&
      wallets.length > 0 &&
      promptTransactionReport &&
      !root.transactionReportHelper.error
    ) {
      promptTransactionReport(wallets[0]);
    }
  }, [
    params,
    from,
    to,
    transactionFilterKind,
    promptTransactionReport,
    root.transactionReportHelper,
    root.walletStore,
  ]);

  return (
    <HistoryFilterScreen
      walletId={walletId}
      goToWalletList={goToWalletList}
      goToCurrencyList={goToCurrencyList}
      goToOperationList={goToHistoryFilterKind}
      goToDateSelection={goToDateSelection}
      getWalletTotal={getWalletTotal}
      getWalletCurrency={getWalletCurrency}
      transactionFilterKind={transactionFilterKind}
      from={from}
      to={to}
      onAccept={onAccept}
      onReset={navigateBackToHistory}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      onTransactionReport={handleTransactionReport}
      isLoading={root.transactionReportHelper.isLoading}
      error={root.transactionReportHelper.error}
    />
  );
});
