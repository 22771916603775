import {useCallback} from 'react';

import useStrings from '../Root/hooks/useStrings';
import {
  AddressRedirectError,
  fromAddressRedirectError,
} from './AddressRedirectError';
import useSendAddressErrorTexts from './useSendAddressErrorTexts';

export default function useGetAddressErrorText() {
  const strings = useStrings();
  const addressErrorTexts = useSendAddressErrorTexts();
  return useCallback(
    (code: AddressRedirectError) => {
      const addressValidationError = fromAddressRedirectError(code);
      if (addressValidationError) {
        return addressErrorTexts[addressValidationError];
      } else if (code === AddressRedirectError.NoCryptoCode) {
        return strings['qrScan.validation.noCryptoCodeInQr'];
      } else if (code === AddressRedirectError.UnsupportedCrypto) {
        return strings['qrScan.validation.unsupportedCrypto'];
      } else if (code === AddressRedirectError.MultipleWallets) {
        return strings['qrScan.validation.multipleWallets'];
      } else if (code === AddressRedirectError.NoWallet) {
        return strings['qrScan.validation.noWallet'];
      }
    },
    [addressErrorTexts, strings],
  );
}
