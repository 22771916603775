import type {Http} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import {getAccountFromState} from '@ncwallet-app/core/src/AccountStore';
import type {Configuration} from '@ncwallet-app/core/src/Configuration';
import type {FlashMessage} from '@ncwallet-app/core/src/FlashMessage';
import type {Localization} from '@ncwallet-app/core/src/Localization';
import type {
  TransactionReport,
  TransactionReportHelper,
} from '@ncwallet-app/core/src/TransactionReport';
import {setStringAsync} from 'expo-clipboard';
import {action, computed, makeObservable, observable} from 'mobx';

export default class WebTransactionReportImpl implements TransactionReport {
  @observable private _isError = false;
  @observable private _isLoading = false;

  constructor(
    readonly _root: {
      readonly http: Http;
      readonly accountStore: AccountStore;
      readonly configuration: Configuration;
      readonly transactionReportHelper: TransactionReportHelper;
      readonly flashMessage: FlashMessage;
      readonly localization: Localization;
    },
  ) {
    makeObservable(this);
  }

  @computed
  get isLoading() {
    return this._isLoading;
  }

  @computed
  get isError() {
    return this._isError;
  }

  @action.bound
  async shareReport() {
    const url = this._getReportUrl();

    if ('share' in navigator) {
      await navigator.share({
        title: 'NC Wallet transaction report',
        url,
      });
    } else {
      void setStringAsync(url);
      this._root.flashMessage.showMessage({
        title: this._root.localization.getTranslation('copied'),
        variant: 'success',
      });
    }
  }

  @action.bound
  async downloadReport() {
    const url = this._getReportUrl();
    window.open(url);
  }

  private _getReportUrl() {
    const token = this._root.transactionReportHelper.token;
    const accountId = getAccountFromState(this._root.accountStore.state)?.id;
    return `${this._root.configuration.current.values.ncWalletRestApiUrl}api/v1/reports?token=${token ?? ''}&account_id=${accountId ?? ''}`;
  }
}
