import {FULFILLED, useRoot} from '@ncwallet-app/core';

// eslint-disable-next-line import-x/prefer-default-export
export const useIsSuspended = () => {
  const {accountStore} = useRoot();
  return (
    accountStore.state?.status === FULFILLED &&
    accountStore.state.result.settings.is_suspended
  );
};
