import type {ShallowCommonState} from '@ncwallet-app/core/src/CommonNavigationScheme';
import commonToSmall from '@ncwallet-app/core/src/SmallNavigationScheme/commonToSmall';
import {CommonActions} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import RedirectToSendContainer from '../../../CommonNavigationContainers/containers/RedirectToSendContainer';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type RedirectToSendBindingProps =
  SmallHomeStackBindingProps<'RedirectToSend'>;

export default observer(function RedirectToSendBinding(
  props: RedirectToSendBindingProps,
) {
  const {navigation, route} = props;
  const listWallets = useCallback(() => {
    navigation.replace('Root');
  }, [navigation]);
  const resetRoot = useCallback(
    (state: ShallowCommonState) => {
      navigation.dispatch(CommonActions.reset(commonToSmall(state)));
    },
    [navigation],
  );
  return (
    <RedirectToSendContainer
      listWallets={listWallets}
      resetRoot={resetRoot}
      params={route.params}
    />
  );
});
