import {computed, createAtom, makeObservable} from 'mobx';

import type {AccountStore} from '../AccountStore';
import {FULFILLED} from '../AsyncAtom';
import {success} from '../fp';
import type {Maybe} from '../Maybe';
import type {AvailableLanguageCode} from './constant';
import type {LanguageTranslationBridge} from './LanguageTranslationBridge';
import type {SystemLanguageProvider} from './SystemLanguageProvider';
import type {LanguageTag, SystemLocaleProvider} from './SystemLocaleProvider';
import type {UserPreferenceState} from './UserPreferenceState';

export default class UserPreferenceStateImpl implements UserPreferenceState {
  private readonly _systemLanguageCodeAtom = createAtom(
    'UserPreferenceStateService#_systemLanguageCodeAtom',
    () => this._root.systemLocaleProvider.locales.listen(this._localesHandler),
    () => {
      this._root.systemLocaleProvider.locales.forget(this._localesHandler);
    },
  );

  private readonly _localesHandler = () => {
    this._systemLanguageCodeAtom.reportChanged();
  };

  constructor(
    private readonly _root: {
      readonly systemLocaleProvider: SystemLocaleProvider;
      readonly languageTranslationBridge: LanguageTranslationBridge;
      readonly systemLanguageProvider: SystemLanguageProvider;
      readonly accountStore: AccountStore;
    },
  ) {
    makeObservable(this);
  }

  @computed get languageCode(): AvailableLanguageCode {
    const {state} = this._root.accountStore;
    if (state?.status === FULFILLED) {
      return this._extractCode(state.result.language);
    }
    return this._systemLanguageCode;
  }

  private get _systemLanguageCode() {
    this._systemLanguageCodeAtom.reportObserved();
    return this._root.systemLanguageProvider.getLanguage();
  }

  async setLanguageCode(_: AvailableLanguageCode): Promise<Maybe<void>> {
    const update_ = await this._root.accountStore.update({language: _});
    if (!update_.success) {
      return update_;
    }
    return success();
  }

  private _extractCode(tag: LanguageTag) {
    const code = tag.slice(0, 2);
    return this._root.languageTranslationBridge.pickClosestLanguage(
      code,
      () => this._systemLanguageCode,
    );
  }
}
