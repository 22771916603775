import type {DecimalString} from '../..';
import type {AddressNetwork, AddressType} from './AddressInfo';
import type {Wallet, WalletId} from './Wallet';

export const ADDRESS_CREATION_NOT_ALLOWED = 3012;

export type WalletsAddressesCreate = {
  params: {
    wallet_id: WalletId;
    name?: string;
    address_type?: AddressType;
    network: AddressNetwork;
  };
  result: Wallet;
  error: {
    code: DecimalString;
    message: string;
    data: WalletsAddressesErrorData;
  };
};

export type WalletsAddressesErrorData = {
  mintrans: number;
};
