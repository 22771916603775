import {isEqual} from 'lodash';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import type {BaseAsyncOptions} from '../../Async';
import type {AccountIdStore} from '../../Auth';
import type {
  CancellationError,
  GeneralJsonRpcError,
  GlobalError,
  TimeoutError,
} from '../../Error';
import {CANCELLATION_ERROR, TIMEOUT_ERROR} from '../../Error';
import type {ErrorRepository} from '../../ErrorRepository';
import type {Either} from '../../fp';
import {error} from '../../fp';
import type {JsonRpcClient} from '../../JsonRpc';
import type {CryptoCurrencyCode, FiatCurrencyCode} from '../../Money';
import type {
  CommonError,
  CryptoCurrency,
  CryptoCurrencyExtra,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../../NCWalletServer';
import type {FiatCurrency} from '../../NCWalletServer/FiatCurrency';
import type {SentryLog} from '../../SentryLog';
import createJsonRpcTimeoutErrorMessage from '../../SentryLog/createJsonRpcTimeoutErrorMessage';
import type {BaseTransactionOptions} from '../../util';
import type {CurrencyStore} from './CurrencyStore';

export default class CurrencyStoreImpl implements CurrencyStore {
  @observable.ref private _cryptoCurrencies: CryptoCurrency[] | undefined;
  @observable.ref private _cryptoCurrenciesExtra:
    | CryptoCurrencyExtra[]
    | undefined;
  @observable.ref private _fiatCurrencies: FiatCurrency[] | undefined;

  @computed
  private get _cryptoMap() {
    return (
      this._cryptoCurrencies &&
      new Map(this._cryptoCurrencies.map(c => [c.code, c]))
    );
  }

  @computed
  private get _fiatMap() {
    return (
      this._fiatCurrencies &&
      new Map(this._fiatCurrencies.map(f => [f.code, f]))
    );
  }

  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly accountIdStore: AccountIdStore;
      readonly sentryLog: SentryLog;
    },
  ) {
    makeObservable(this);
  }

  getCryptoCurrencies(): CryptoCurrency[] | undefined {
    return this._cryptoCurrencies;
  }

  getCryptoCurrenciesExtra() {
    return this._cryptoCurrenciesExtra;
  }

  getFiatCurrencies(): FiatCurrency[] | undefined {
    return this._fiatCurrencies;
  }

  getFiatCurrency(code: FiatCurrencyCode): FiatCurrency | undefined {
    return this._fiatMap?.get(code);
  }

  getCryptoCurrency(code: CryptoCurrencyCode): CryptoCurrency | undefined {
    return this._cryptoMap?.get(code);
  }

  async refreshCryptoCurrencies(
    options?: BaseAsyncOptions & BaseTransactionOptions,
  ): Promise<
    Either<CryptoCurrency[], GlobalError | GeneralJsonRpcError<CommonError>>
  > {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }

    const res = await this._root.ncWalletJsonRpcClient.call(
      'currencies.crypto.list',
      undefined,
      options,
    );

    if (!res.success) {
      if (res.left.kind === TIMEOUT_ERROR) {
        this._root.sentryLog.write(
          createJsonRpcTimeoutErrorMessage('[currencies.crypto.list'),
        );
        return error(this._createTimeoutError(res.left));
      }
    }

    if (res.success && !isEqual(this._cryptoCurrencies, res.right)) {
      const _runInAction = options?.postpone ?? runInAction;
      _runInAction(() => {
        this._cryptoCurrencies = res.right.slice();
      });
    }
    await this.refreshCryptoCurrenciesExtra();
    return res;
  }

  async refreshCryptoCurrenciesExtra() {
    const res = await this._root.ncWalletJsonRpcClient.call(
      'currencies.crypto.extra_info',
      undefined,
      {},
    );

    if (!res.success) {
      if (res.left.kind === TIMEOUT_ERROR) {
        this._root.sentryLog.write(
          createJsonRpcTimeoutErrorMessage('[currencies.crypto.extra_info]'),
        );
      }
    }

    if (res.success && !isEqual(this._cryptoCurrenciesExtra, res.right)) {
      runInAction(() => {
        this._cryptoCurrenciesExtra = res.right.slice();
      });
    }
  }

  async refreshFiatCurrencies(
    options?: BaseAsyncOptions & BaseTransactionOptions,
  ): Promise<
    Either<FiatCurrency[], GlobalError | GeneralJsonRpcError<CommonError>>
  > {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }

    const res = await this._root.ncWalletJsonRpcClient.call(
      'currencies.fiat.list',
      undefined,
      options,
    );

    if (!res.success) {
      if (res.left.kind === TIMEOUT_ERROR) {
        this._root.sentryLog.write(
          createJsonRpcTimeoutErrorMessage('[currencies.fiat.list]'),
        );
        return error(this._createTimeoutError(res.left));
      }
    }

    if (res.success && !isEqual(this._fiatCurrencies, res.right)) {
      const _runInAction = options?.postpone ?? runInAction;
      _runInAction(() => {
        this._fiatCurrencies = res.right.slice();
      });
    }
    return res;
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }

  private _createTimeoutError(cause?: unknown) {
    return this._root.errorRepository.create<TimeoutError>({
      kind: TIMEOUT_ERROR,
      raw: cause,
    });
  }

  @action.bound
  reset() {
    this._cryptoCurrencies = undefined;
    this._fiatCurrencies = undefined;
  }
}
