import type {CurrencyCode} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core';
import type {WalletActionKind} from '@ncwallet-app/ui/src';
import {useIsDimensions, WalletActionItem} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {View} from 'react-native';

export type CurrencyListItemProps = {
  currencyCode?: CurrencyCode;
  type: WalletActionKind;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  onPress: () => void;
  showRightArrow?: boolean;
  dataSet: Record<string, string>;
  offsetBottom?: boolean;
  currencyName?: string;
  smallCard?: boolean;
};

export default observer(function CurrencyListItem(
  props: CurrencyListItemProps,
) {
  const {
    currencyCode,
    type,
    style,
    disabled,
    onPress,
    showRightArrow,
    dataSet,
    currencyName,
    smallCard,
  } = props;
  const isLg = useIsDimensions('lg');

  if (isLg) {
    return (
      <Wrapper dataSet={disabled ? {app: 'currency-item-disabled'} : dataSet}>
        <WalletActionItem
          currencyCode={currencyCode}
          type={type}
          onPress={onPress}
          currencyName={currencyName}
          disabled={disabled}
          style={style}
          showRightArrow={showRightArrow}
          smallCard={smallCard}
        />
      </Wrapper>
    );
  }

  return (
    <WalletActionItem
      currencyCode={currencyCode}
      type={type}
      onPress={onPress}
      currencyName={currencyName}
      disabled={disabled}
      style={style}
      showRightArrow={showRightArrow}
      smallCard={smallCard}
    />
  );
});

const Wrapper = variance(View)(() => ({
  root: {
    flex: 1,
  },
  offsetBottom: {
    marginBottom: 20,
  },
}));
