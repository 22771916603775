import type {Core, Root, RootServiceFactory, Service} from '@ncwallet-app/core';
import {CommonCoreService} from '@ncwallet-app/core';

import WebRootService from './WebRootService';

export default class WebRootServiceFactoryImpl implements RootServiceFactory {
  createCore(): Core & Service {
    return new CommonCoreService();
  }

  createRoot(core: Core): Root & Service {
    return new WebRootService(core);
  }
}
