import {useDateFormatting, useStrings, variance} from '@ncwallet-app/core';
import {DeviceIcon} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import type {SessionInfo} from './SessionInfo';
import {SessionItemContainer} from './SessionItemContainer';

export type ActiveSessionsItemProps = {
  data: SessionInfo;
  onPress?: () => void;
};

export const SessionsItem = observer((props: ActiveSessionsItemProps) => {
  const {onPress, data} = props;
  const strings = useStrings();

  const {formatDateTimeWithYear} = useDateFormatting();
  const date = formatDateTimeWithYear(props.data.time);

  const status =
    data.status === 'blocked'
      ? strings['secureSettingsScreen.blocked']
      : data.hasActiveConnection
        ? strings['secureSettingsScreen.activeSessions.onlineStatus']
        : strings['secureSettingsScreen.activeSessions.loggedInStatus'];

  return (
    <SessionItemContainer onPress={onPress}>
      <Icon>
        <DeviceIcon type={props.data.platform} />
      </Icon>
      <Col>
        <TitleWrapper>
          <TitleTop>
            <Date>{date}</Date>
            <Status
              error={data.status === 'blocked'}
              hasActiveConnection={data.hasActiveConnection}>
              {status}
            </Status>
          </TitleTop>
          <Title numberOfLines={1}>{props.data.title} </Title>
        </TitleWrapper>
        <InfoText>{props.data.info}</InfoText>
      </Col>
    </SessionItemContainer>
  );
});

const Status = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    lineHeight: 15,
    fontSize: 11,
    color: theme.palette.success,
  },
  hasActiveConnection: {
    color: theme.palette.info,
  },
  error: {
    color: theme.palette.error,
  },
}));

const Icon = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const Col = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: 15,
    justifyContent: 'center',
  },
}));

const TitleWrapper = variance(View)(() => ({
  root: {},
}));

const TitleTop = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Date = variance(Text)(theme => ({
  root: {
    marginRight: 5,
    ...theme.fontByWeight('500'),
    fontSize: 11,
    lineHeight: 15,
    color: theme.palette.textAdditional2,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    flex: 1,
    ...theme.fontByWeight('700'),
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.textPrimary,
  },
}));

const InfoText = variance(Text)(theme => ({
  root: {
    marginTop: 3,
    ...theme.fontByWeight('500'),
    fontSize: 12,
    lineHeight: 15,
    color: theme.palette.textAdditional2,
  },
}));
