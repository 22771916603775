import type {
  CryptoAddress,
  CryptoCurrencyCode,
  DecimalString,
} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, runInAction} from 'mobx';
import {useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../hooks';
import {GeneratePayMeLinkBindingState} from './GeneratePayMeLinkBindingState';

interface GeneratePayMeLinkBindingStateProps {
  address: CryptoAddress;
  currency?: CryptoCurrencyCode;
  amount?: DecimalString;
}

// eslint-disable-next-line import-x/prefer-default-export
export const useGeneratePayMeLinkBindingState = (
  props: GeneratePayMeLinkBindingStateProps,
) => {
  const {amount, currency, address} = props;
  const root = useRoot();
  const [state] = useState(new GeneratePayMeLinkBindingState(root));
  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (address && currency) {
      state.setRequiredData(address, currency);
      state.setAmount(amount || '0');
    }
  }, [amount, currency, address, state]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused() && address && currency) {
          runInAction(() => {
            void state.refresh();
          });
        }
      }),
    [getIsReady, getIsFocused, currency, address, state],
  );

  return state;
};
