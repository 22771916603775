import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import type {HistorySessionInfo} from './HistorySessionInfo';
import {SessionsHistoryList} from './SessionsHistoryList';

export type SessionsHistoryScreenProps = BaseSafeAreaProps & {
  history: HistorySessionInfo[] | undefined;
  onEndReached: () => void;
};

export const SessionsHistoryScreen = observer(
  (props: SessionsHistoryScreenProps) => {
    const {history, ...rest} = props;
    return <SessionsHistoryList data={history} {...rest} />;
  },
);
