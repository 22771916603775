import type {DecimalString} from '@ncwallet-app/core';
import {BigNumber} from 'bignumber.js';

export enum SendAmountValidatorError {
  ShouldBeMoreThanZero,
  MoreThanMax,
  MoreThanMaxWithFee,
  LessThanMin,
  MoreThanLimit,
  MoreThanLimitWithFee,
}

export class SendAmountValidator {
  // eslint-disable-next-line @typescript-eslint/class-methods-use-this
  validate(
    n: DecimalString | undefined,
    min: DecimalString,
    max: DecimalString,
    maxIncludesFee: boolean,
    haveActiveLimit: boolean,
  ) {
    if (!n) {
      return SendAmountValidatorError.ShouldBeMoreThanZero;
    }
    const decimal = BigNumber(n);
    if (decimal.isZero() || decimal.isNegative()) {
      return SendAmountValidatorError.ShouldBeMoreThanZero;
    } else if (decimal.isGreaterThan(max)) {
      return getErrorTextForMaxCondition(maxIncludesFee, haveActiveLimit);
    } else if (decimal.isLessThan(min)) {
      return SendAmountValidatorError.LessThanMin;
    } else {
      return undefined;
    }
  }
}

function getErrorTextForMaxCondition(
  maxIncludesFee: boolean,
  haveActiveLimit: boolean,
) {
  if (maxIncludesFee) {
    return haveActiveLimit
      ? SendAmountValidatorError.MoreThanLimitWithFee
      : SendAmountValidatorError.MoreThanMaxWithFee;
  } else {
    return haveActiveLimit
      ? SendAmountValidatorError.MoreThanLimit
      : SendAmountValidatorError.MoreThanMax;
  }
}
