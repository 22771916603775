import type {Millisecond, Uri, Url} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {AdvertContext} from '@ncwallet-app/core/src/Advert';
import {useCallback, useMemo} from 'react';

export type AdvertAction = {
  link?: Url;
  title: string;
  icon?: Uri;
  action?: string;
};

export default function useAdvertProps(
  context: AdvertContext,
  hideAd: (now: Millisecond) => Promise<void>,
): [
  () => Promise<void>,
  (link?: string) => Promise<void>,
  AdvertAction[] | undefined,
] {
  const root = useRoot();

  const closeBanner = useCallback(
    () => hideAd(root.time.now()),
    [hideAd, root],
  );

  const onActionPress = useCallback(
    async (link?: string) => {
      await closeBanner();
      if (!link) {
        return;
      }
      if (link.startsWith('/')) {
        root.navigation.navigate(link);
      } else {
        await root.location.open(link as Url);
      }
    },
    [closeBanner, root],
  );

  const actions = useMemo(
    () =>
      context.advert.actions?.map<AdvertAction>((_, i) => ({
        link: _.link ?? undefined,
        title: context.text.actions?.[i].title ?? '',
        icon: _.icon ?? undefined,
        action: _.action,
      })),
    [context],
  );

  return [closeBanner, onActionPress, actions];
}
