import {variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT, TouchableListItem} from '@ncwallet-app/ui';
import {Text, View} from 'react-native';

export const HistoryItemContainer = variance(TouchableListItem)(theme => ({
  root: {
    width: '100%',
    paddingHorizontal: 14,
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingVertical: 10,
      },
    }),
  },
}));

export const HistoryItemTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.textPrimary,
    marginLeft: 15,
    marginRight: 15,
  },
  isRight: {
    marginLeft: 0,
  },
}));
export const HistoryItemColumn = variance(View)(() => ({
  root: {
    marginLeft: 'auto',
  },
  isRight: {
    marginLeft: 15,
  },
}));
export const HistoryItemFiatCurrency = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    textAlign: 'right',
    fontSize: 16,
    lineHeight: 20,
    color: theme.palette.textSecondary,
  },
}));
export const HistoryItemCurrency = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    textAlign: 'right',
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.textAdditional2,
    marginTop: 5,
  },
  isRight: {
    textAlign: 'left',
  },
}));
