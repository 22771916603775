import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {Input, InputVariant, useIsDimensions} from '@ncwallet-app/ui';
import {AtAddressSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {TextInput} from 'react-native';
import {View} from 'react-native';

export type SendScreenUserFormProps = {
  value?: string;
  inputRef?: React.Ref<TextInput>;
  showKeyboard?: boolean;
  onFocus?: () => void;
  onInputChange?: (value: string) => void;
  onActionPress?: () => void;
  wideStyle?: boolean;
  error?: boolean;
};

export const SendScreenUserForm = observer((props: SendScreenUserFormProps) => {
  const strings = useStrings();
  const theme = useTheme();

  const AtAddressSvgColored = useCallback(() => {
    return <AtAddressSvg color={theme.palette.textPrimary} />;
  }, [theme]);

  const isLg = useIsDimensions('lg');

  return (
    <Container wideStyle={props.wideStyle}>
      <Input
        ref={props.inputRef}
        value={props.value}
        label={strings['sendCrypto.userForm.label']}
        message={isLg ? undefined : strings['sendCrypto.userForm.message']}
        placeholder={strings['sendCrypto.userForm.placeholder']}
        placeholderTextColor={theme.palette.textAdditional3}
        showSoftInputOnFocus={props.showKeyboard}
        Icon={AtAddressSvgColored}
        onFocus={props.onFocus}
        onChangeText={props.onInputChange}
        variant={props.error ? InputVariant.Error : InputVariant.Default}
      />
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  wideStyle: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    flex: 1,
  },
}));
