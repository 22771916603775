import {DefaultTheme} from '@react-navigation/native';

import type {Appearance} from '../Appearance';
import {expr} from '../mobx-toolbox';
import type {NavigationContainerTheme} from './NavigationContainerTheme';

export default class NavigationContainerThemeImpl
  implements NavigationContainerTheme
{
  constructor(
    private readonly _root: {
      readonly appearance: Appearance;
    },
  ) {}

  get theme() {
    const palette = expr(() => this._root.appearance.theme.palette);

    return {
      ...DefaultTheme,
      dark: this._root.appearance.isDark,
      colors: {
        ...DefaultTheme.colors,
        primary: palette.primary,
        background: palette.background,
        card: palette.uiAdditional2,
        border: palette.uiSecondary,
        text: palette.textAdditional2,
      },
    };
  }
}
