import type {WalletId} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {getDefaultAddressInfo} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';

// eslint-disable-next-line import-x/prefer-default-export
export const useGetDefaultInAddress = (walletId: WalletId) => {
  const {walletStore} = useRoot();
  return (network: AddressNetwork) =>
    getDefaultAddressInfo(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      walletStore.getWalletById(walletId)!.addresses,
      network,
      undefined,
      true,
    )?.address;
};
