import type {GlobalError} from '../Error';
import {NOT_IMPLEMENTED} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error} from '../fp';
import type {Sharing} from './Sharing';

export default class StubSharingImpl implements Sharing {
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
    },
  ) {}

  async shareFile(): Promise<Either<void, GlobalError>> {
    return error(this._root.errorRepository.create({kind: NOT_IMPLEMENTED}));
  }

  async shareMessage(): Promise<void> {
    error(this._root.errorRepository.create({kind: NOT_IMPLEMENTED}));
  }
}
