import {observer} from 'mobx-react-lite';
import React from 'react';
import {Modal} from 'react-native';

import {useIsDimensions} from '../../util';
import {ModalContainer, Spinner} from '../atoms';

type SendTwoFaSpinnerProps = {
  visible: boolean;
};

// eslint-disable-next-line import-x/prefer-default-export
export const SendTwoFaSpinner = observer((props: SendTwoFaSpinnerProps) => {
  const isLg = useIsDimensions('lg');

  return isLg ? (
    props.visible ? (
      <ModalContainer>
        <Spinner />
      </ModalContainer>
    ) : null
  ) : (
    <Modal
      transparent={true}
      statusBarTranslucent
      visible={props.visible}
      animationType="fade">
      <ModalContainer>
        <Spinner />
      </ModalContainer>
    </Modal>
  );
});
