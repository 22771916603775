import type {AvailableLanguageCode} from '@ncwallet-app/core/src/Localization/constant';
import type {SafeAreaFlatListProps} from '@ncwallet-app/ui/';
import {SafeAreaFlatList, Separator} from '@ncwallet-app/ui/';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import type {FlatListProps, ListRenderItem} from 'react-native';
import type {ReadonlyDeep} from 'type-fest';

import {LANGUAGES} from './Languages';
import type {LanguageItem} from './LanguageSettingsListItem';
import LanguageSettingsListItem from './LanguageSettingsListItem';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LanguageSettingsListProps<ItemT = any> = Partial<
  SafeAreaFlatListProps<ItemT>
> & {
  activeItem: AvailableLanguageCode;
  onLanguagePress: (locale: ItemT) => void;
};

export default observer(function LanguageSettingsList(
  props: LanguageSettingsListProps,
) {
  const {onLanguagePress, activeItem, ...rest} = props;

  const renderItem: ListRenderItem<LanguageItem> = useCallback(
    ({item}) => {
      const active = activeItem === item.locale;
      return (
        <LanguageSettingsListItem
          item={item}
          onPress={() => {
            onLanguagePress(item.locale);
          }}
          selected={active}
        />
      );
    },
    [onLanguagePress, activeItem],
  );

  const data = useMemo(
    () =>
      Object.entries(LANGUAGES).map(([key]) => {
        return {
          ...LANGUAGES[key as AvailableLanguageCode],
          locale: key as AvailableLanguageCode,
        };
      }),
    [],
  );

  return (
    <SafeAreaFlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={Separator}
      {...rest}
    />
  );
});

export type ListProps = FlatListProps<ReadonlyDeep<LanguageItem>>;

const keyExtractor: ListProps['keyExtractor'] = entry => entry.locale;
