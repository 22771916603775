import {useMemo} from 'react';

import type {PromiseState} from '../AsyncAtom';
import {FULFILLED} from '../AsyncAtom';
import type {GlobalError} from '../Error';
import type {Account} from '../NCWalletServer';
import useRoot from '../Root/hooks/useRoot';

export const useAccountState = () => {
  const root = useRoot();
  const account = getAccountFromState(root.accountStore.state);

  return useMemo(
    () => ({
      account,
      update: root.accountStore.update,
    }),
    [account, root.accountStore],
  );
};

export const getAccountFromState = (
  state: PromiseState<Account, GlobalError> | undefined,
) => (state?.status === FULFILLED ? state.result : undefined);
