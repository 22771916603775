import type {CryptoCurrencyCode, WalletId} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {Receipt} from '@ncwallet-app/core/src/AddressParser';
import {getCurrencyCodeByAddressKind} from '@ncwallet-app/core/src/AddressParser';
import {useCallback} from 'react';
import type {LiteralUnion} from 'type-fest';

export type ParamsForOutput = {
  addressTo?: string;
  walletId?: WalletId;
  addressNetwork?: string;
  amount?: string;
  addressCurrency?: CryptoCurrencyCode;
};

export const useGetParamsForOutputAfterQrScan = () => {
  const {walletStore, currencyStore} = useRoot();
  return useCallback(
    (receipt: Receipt) => {
      const addressCurrency =
        receipt.kind && getCurrencyCodeByAddressKind(receipt.kind);
      let params: ParamsForOutput = {
        addressTo: receipt.address,
        amount: receipt.amount,
      };
      const defaultAddressNetwork =
        addressCurrency &&
        currencyStore.getCryptoCurrency(addressCurrency)?.options
          .default_network;
      const addressNetwork = receipt.network ?? defaultAddressNetwork;
      const walletId =
        addressCurrency && walletStore.getWalletsByCode(addressCurrency)[0]?.id;
      const updateParams = (
        value: LiteralUnion<CryptoCurrencyCode, string> | undefined,
        name: keyof ParamsForOutput,
      ) => {
        if (value) {
          params = {
            ...params,
            [name]: value,
          };
        }
      };
      updateParams(addressCurrency, 'addressCurrency');
      updateParams(walletId, 'walletId');
      updateParams(addressNetwork, 'addressNetwork');

      return params;
    },
    [currencyStore, walletStore],
  );
};
