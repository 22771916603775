import type {AvailableLanguageCode} from './constant';
import {AVAILABLE_LANGUAGE_CODES} from './constant';
import type {LanguageTranslationBridge} from './LanguageTranslationBridge';

export default class LanguageTranslationBridgeImpl
  implements LanguageTranslationBridge
{
  pickClosestLanguage(
    languageCode: string,
    getFallback?: () => AvailableLanguageCode,
  ): AvailableLanguageCode {
    const codes = AVAILABLE_LANGUAGE_CODES as Set<string>;
    if (codes.has(languageCode)) {
      return languageCode as AvailableLanguageCode;
    }
    return getFallback?.() ?? 'en';
  }
}
