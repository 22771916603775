import type {BaseAsyncOptions} from '../Async';
import {ReadyState} from '../Connection';
import type {
  CancellationError,
  ConnectionError,
  GlobalError,
  UnknownError,
} from '../Error';
import {CANCELLATION_ERROR, CONNECTION_ERROR, UNKNOWN_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import {
  SequenceNumberGeneratorImpl,
  WeakObjectEnumeratorImpl,
} from '../ObjectEnumerator';
import {RouterImpl} from '../structure';
import type {
  Connection,
  ConnectionId,
  ConnectionStatusRouterMap,
} from './Connection';
import {ConnectionStatus} from './Connection';
import type {Tunnel, TunnelIoRouterMap} from './Tunnel';
import {
  TUNNEL_INCOMING_MESSAGE,
  TUNNEL_OUTGOING_ERROR,
  TUNNEL_OUTGOING_MESSAGE,
} from './Tunnel';
import type {WebSocketFactory} from './WebSocketFactory';

export default class WebSocketConnectionTunnelImpl
  implements Connection, Tunnel<string, string>
{
  private readonly _status = new RouterImpl<ConnectionStatusRouterMap>();
  private readonly _io = new RouterImpl<TunnelIoRouterMap<string, string>>();
  private _errorMessage?: string;
  private _ws?: WebSocket;
  private _connectionIds = new SequenceNumberGeneratorImpl<ConnectionId>(1);
  private _webSocketEnumerator = new WeakObjectEnumeratorImpl<
    WebSocket,
    ConnectionId
  >(this._connectionIds);

  constructor(
    private readonly _root: {readonly errorRepository: ErrorRepository},
    private readonly _wsFactory: WebSocketFactory,
  ) {}

  get io(): Tunnel<string, string>['io'] {
    return this._io;
  }

  get status(): Connection['status'] {
    return this._status;
  }

  private readonly _onOpen = () => {
    this._status.send(ConnectionStatus.Open);
  };

  private readonly _onError = (event: WebSocketErrorEvent | Event) => {
    const message = 'message' in event ? event.message : undefined;
    this._errorMessage =
      message ?? 'The connection was lost for an unknown reason';
  };

  private readonly _onClose = (event: WebSocketCloseEvent | CloseEvent) => {
    const wasClean =
      'wasClean' in event ? event.wasClean : event.message === undefined;
    const {code} = event;
    const reason = event.reason ?? (event as WebSocketCloseEvent).message;
    const report = {wasClean, code, reason};
    this._status.send(ConnectionStatus.Closed, report);
  };

  private readonly _onMessage = (event: WebSocketMessageEvent) => {
    this._io.send(TUNNEL_INCOMING_MESSAGE, event.data as string);
  };

  private _bindSocket(ws: WebSocket) {
    this._errorMessage = undefined;
    ws.addEventListener('open', this._onOpen);
    ws.addEventListener('error', this._onError);
    ws.addEventListener('close', this._onClose);
    ws.addEventListener('message', this._onMessage);
  }

  private _unbindSocket(ws: WebSocket) {
    ws.removeEventListener('open', this._onOpen);
    ws.removeEventListener('error', this._onError);
    ws.removeEventListener('close', this._onClose);
    ws.removeEventListener('message', this._onMessage);
  }

  async connect(): Promise<Either<void, GlobalError>> {
    if (
      this._ws?.readyState === ReadyState.Connecting ||
      this._ws?.readyState === ReadyState.Open
    ) {
      return success();
    }
    await this.disconnect();
    const ws_ = await this._wsFactory.create();
    if (!ws_.success) {
      return ws_;
    }
    this._status.send(ConnectionStatus.Connecting);
    this._ws = ws_.right;
    this._bindSocket(this._ws);
    return success();
  }

  async disconnect(): Promise<Either<void, GlobalError>> {
    if (
      !this._ws ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      this._ws.readyState === ReadyState.Closing ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      this._ws.readyState === ReadyState.Closed
    ) {
      return success();
    }
    const wsJustClosed = new Promise<Either<void, ConnectionError>>(resolve => {
      const listener = ({theme: status}: {theme: ConnectionStatus}) => {
        switch (status) {
          case ConnectionStatus.Closed:
            if (this._ws) {
              this._unbindSocket(this._ws);
            }
            this._ws = undefined;
            this._status.domain.forget(listener);
            resolve(success());
            break;
          case ConnectionStatus.Connecting:
          case ConnectionStatus.Open:
            resolve(
              error(
                this._root.errorRepository.create({
                  kind: CONNECTION_ERROR,
                  description: `Got an unexpected ${ConnectionStatus[status]} state while closing the WebSocket`,
                  readyState: this._ws?.readyState,
                }),
              ),
            );
        }
      };
      this._status.domain.listen(listener);
    });
    try {
      this._ws.close();
    } catch (raw) {
      return error(
        this._root.errorRepository.create<ConnectionError>({
          kind: CONNECTION_ERROR,
          description: 'Failed to close the WebSocket',
          readyState: this._ws.readyState,
          raw,
        }),
      );
    }
    this._status.send(ConnectionStatus.Closing);
    return wsJustClosed;
  }

  async reconnect() {
    const disconnect_ = await this.disconnect();
    if (!disconnect_.success) {
      return disconnect_;
    }
    return this.connect();
  }

  getStatus() {
    return this._ws?.readyState ?? ConnectionStatus.Closed;
  }

  private _latestId = 0 as ConnectionId;

  getId() {
    if (this._ws) {
      this._latestId = this._webSocketEnumerator.getOrAssign(this._ws);
    }
    return this._latestId;
  }

  async send(
    message: string,
    options?: BaseAsyncOptions,
  ): Promise<Either<void, GlobalError>> {
    if (options?.signal?.aborted) {
      return error(
        this._root.errorRepository.create<CancellationError>({
          kind: CANCELLATION_ERROR,
        }),
      );
    }
    if (this._ws?.readyState !== ReadyState.Open) {
      if (!this._ws) {
        return error(
          this._root.errorRepository.create<ConnectionError>({
            kind: CONNECTION_ERROR,
            description: 'Trying to send a message when there is no socket',
          }),
        );
      }
      const statusText = ReadyState[this._ws.readyState];
      return error(
        this._root.errorRepository.create<ConnectionError>({
          kind: CONNECTION_ERROR,
          description: `Trying to send the message into the socket with a status: ${statusText}`,
          readyState: this._ws.readyState,
        }),
      );
    }
    try {
      this._ws.send(message);
    } catch (raw) {
      const e = this._root.errorRepository.create<UnknownError>({
        kind: UNKNOWN_ERROR,
        description: 'Failed to send a message into the WebSocket',
        raw,
      });
      this._io.send(TUNNEL_OUTGOING_ERROR, e);
      return error(e);
    }
    this._io.send(TUNNEL_OUTGOING_MESSAGE, message);
    return success();
  }
}
