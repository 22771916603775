import type {
  CurrencyDescription,
  DecimalString,
  Millisecond,
  TransactionFilterKind,
  WalletId,
} from '@ncwallet-app/core';
import {useStrings, useStyles, useTheme, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
  SafeAreaScrollView,
  Spinner,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  CheckSvg,
  CrossSvg,
  FileDownloadSvg,
  FilterSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

import HistoryCalendarItem from './HistoryCalendarItem';
import HistoryCurrencyItem from './HistoryCurrencyItem';
import HistoryOperationItem from './HistoryOperationItem';

export type HistoryFilterScreenProps = BaseSafeAreaProps & {
  walletId?: WalletId;
  goToWalletList: () => void;
  goToOperationList: () => void;
  goToCurrencyList: () => void;
  goToDateSelection: () => void;
  getWalletCurrency: () => CurrencyDescription | undefined;
  getWalletTotal: () => DecimalString | undefined;
  transactionFilterKind?: TransactionFilterKind;
  from?: Millisecond;
  to?: Millisecond;
  onAccept: () => void;
  onReset: () => void;
  onTransactionReport?: () => Promise<void>;
  isLoading?: boolean;
  error?: string | null;
};

export default observer(function HistoryFilterScreen(
  props: HistoryFilterScreenProps,
) {
  const {
    walletId,
    goToOperationList,
    goToCurrencyList,
    goToDateSelection,
    getWalletCurrency,
    getWalletTotal,
    transactionFilterKind,
    from,
    to,
    onAccept,
    onReset,
    onTransactionReport,
    isLoading,
    error,
    ...rest
  } = props;
  const theme = useTheme();

  const isLg = useIsDimensions('lg');
  const strings = useStrings();
  const walletCurrency = getWalletCurrency();
  const walletTotal = getWalletTotal();

  const styles = useHistoryFilterScreenStyles();

  const showReset = useMemo(
    () => transactionFilterKind || from || walletId,
    [transactionFilterKind, from, walletId],
  );

  return (
    <Root contentContainerStyle={styles.container} {...rest}>
      <Inner>
        {isLg && (
          <MdHeader>
            <FilterSvg color={theme.palette.primary} />
            <MdHeaderTitle>{strings['screenTitle.filters']}</MdHeaderTitle>
            <MdBackButton
              title=""
              onPress={
                showReset
                  ? () => {
                      onReset();
                    }
                  : onAccept
              }
              Icon={showReset ? CrossSvg : CheckSvg}
              iconCustomColor={theme.palette.uiAdditional1}
            />
          </MdHeader>
        )}
        <Content>
          <Col offsetBottom offsetRight>
            <FilterTitle>
              {strings['historyFilterScreen.selectCoin']}:
            </FilterTitle>
            <HistoryCurrencyItem
              goToCurrencyList={goToCurrencyList}
              walletCurrency={walletCurrency}
              walletTotal={walletTotal}
            />
          </Col>
          <Col offsetBottom>
            <FilterTitle>
              {strings['historyFilterScreen.selectOperation']}:
            </FilterTitle>
            <HistoryOperationItem
              goToOperationList={goToOperationList}
              transactionFilterKind={transactionFilterKind}
            />
          </Col>
          <Col offsetBottom>
            <FilterTitle>
              {strings['historyFilterScreen.selectPeriod']}:
            </FilterTitle>
            <HistoryCalendarItem
              from={from}
              to={to}
              goToDateSelection={goToDateSelection}
            />
          </Col>
        </Content>
        <Footer>
          {error && <ErrorText>{error}</ErrorText>}
          <ButtonGroup>
            <ButtonApply
              Icon={CheckSvg}
              title={strings['historyFilterScreen.submit']}
              variant={ButtonVariant.Highlighted}
              color={ButtonColor.Secondary}
              onPress={onAccept}
            />
            {isLoading ? (
              <SpinnerWrapper>
                <Spinner size={52} />
              </SpinnerWrapper>
            ) : (
              <ButtonApply
                Icon={FileDownloadSvg}
                title={strings['createStatement.button']}
                variant={ButtonVariant.PrimaryLight}
                onPress={onTransactionReport}
              />
            )}
          </ButtonGroup>
        </Footer>
      </Inner>
    </Root>
  );
});

const Root = variance(SafeAreaScrollView)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        backgroundColor: theme.palette.uiPrimary,
        paddingBottom: 0,
        ...theme.bar(10),
      },
    }),
  },
}));

const Inner = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.background,
        borderRadius: 10,
        padding: 30,
        paddingBottom: 29,
      },
    }),
  },
}));

const MdBackButton = variance(Button)(() => ({
  root: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    borderWidth: 0,
    marginLeft: 'auto',
  },
}));

const MdHeader = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.palette.uiSecondary,
    marginBottom: 20,
    paddingRight: 6,
  },
}));

const MdHeaderTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.textPrimary,
    marginLeft: 17,
  },
}));

const Content = variance(View)(theme => ({
  root: {
    marginBottom: 20,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        marginHorizontal: -10,
        marginBottom: 0,
      },
    }),
  },
}));

const SpinnerWrapper = variance(View)(() => ({
  root: {
    marginLeft: 20,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ButtonGroup = variance(View)(theme => ({
  root: {
    flexDirection: 'column',
    gap: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
    }),
  },
}));

const Footer = variance(View)(theme => ({
  root: {
    marginTop: 'auto',
    gap: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        gap: 10,
      },
    }),
  },
}));

const ButtonApply = variance(Button)(theme => ({
  root: {
    marginTop: 10,
    marginLeft: 15,
    marginRight: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 345,
        flexGrow: 1,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
}));

const ErrorText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.error,
    fontSize: 14,
    textAlign: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        textAlign: 'left',
      },
    }),
  },
}));

const Col = variance(View)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 10,
        flexBasis: '50%',
      },
    }),
  },

  offsetRight: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        // marginRight: 20,
      },
    }),
  },

  offsetBottom: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 20,
      },
    }),
  },
}));

const FilterTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 18,
    lineHeight: 22,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    paddingLeft: 15,
    paddingTop: 20,
    paddingBottom: 15,
    paddingRight: 15,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        display: 'none',
      },
    }),
  },
}));

const useHistoryFilterScreenStyles = () => {
  return useStyles(theme => ({
    container: {
      flexGrow: 1,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          paddingBottom: 0,
        },
      }),
    },

    show: {
      display: 'flex',
      maxHeight: 120,
      borderWidth: 1,
      borderTopWidth: 0,
      borderColor: theme.palette.uiSecondary,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },

    list: {
      display: 'none',
    },
  }));
};
