import {variance} from '@ncwallet-app/core';
import {BaseSkeleton} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export default observer(function IpListSkeleton() {
  return (
    <>
      <SkeletonView>
        <SkeletonRow>
          <BaseSkeleton width={55} height={15} />
          <BaseSkeleton width={105} height={15} />
        </SkeletonRow>
        <SkeletonRow>
          <BaseSkeleton width={55} height={15} />
          <BaseSkeleton width={105} height={15} />
        </SkeletonRow>
      </SkeletonView>
      <SkeletonView>
        <SkeletonRow>
          <BaseSkeleton width={55} height={15} />
          <BaseSkeleton width={105} height={15} />
        </SkeletonRow>
        <SkeletonRow>
          <BaseSkeleton width={55} height={15} />
          <BaseSkeleton width={105} height={15} />
        </SkeletonRow>
      </SkeletonView>
    </>
  );
});

const SkeletonView = variance(View)(theme => ({
  root: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const SkeletonRow = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 7,
  },
}));
