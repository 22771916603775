import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import useLogExporter from '../LogExporter/useLogExporter';
import {useRoot} from '../Root';

export default function useLogButton() {
  const {platformSpecificComponents, debug} = useRoot();
  const {saveLog, copyLog} = useLogExporter();

  return useMemo(
    () =>
      observer(function LogButton() {
        if (debug.debugEnabled) {
          return (
            <platformSpecificComponents.LogButton
              saveLog={saveLog}
              copyLog={copyLog}
            />
          );
        }
        return null;
      }),
    [copyLog, debug, platformSpecificComponents, saveLog],
  );
}
