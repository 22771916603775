import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {Path, Rect, Svg} from 'react-native-svg';

import type {AnimatedLogoMaskProps} from './types';

export default observer<AnimatedLogoMaskProps, View>(
  React.forwardRef(function AnimatedLogoMask(props, ref) {
    return (
      <AnimatedRootView ref={ref} {...props}>
        <Svg width="88" height="88" viewBox="0 0 88 88" fill="none">
          <Path
            d="M78.4631 17.0713H72.1996C71.4902 14.6879 70.4871 12.431 69.2323 10.3425H75.1011C77.8881 10.3425 80.1474 12.6018 80.1474 15.3888V17.2002C79.5983 17.1153 79.0358 17.0713 78.4631 17.0713Z"
            fill="white"
          />
          <Path
            d="M18.7321 10.3425C17.4772 12.431 16.4741 14.6879 15.7648 17.0713H9.49774C8.92649 17.0713 8.36548 17.1151 7.81787 17.1995V15.3888C7.81787 12.6018 10.0772 10.3425 12.8641 10.3425H18.7321Z"
            fill="white"
          />
          <Path
            d="M62.882 17.0705H67.7764C64.3126 7.27094 54.9667 0.25 43.981 0.25C32.9952 0.25 23.6493 7.27094 20.1855 17.0705H25.1162C28.1267 9.5958 35.4468 4.31983 43.9991 4.31983C52.5514 4.31983 59.8714 9.5958 62.882 17.0705Z"
            fill="white"
          />
          <Path
            d="M58.3999 17.0705H29.5983C32.3279 11.9077 37.7527 8.3896 43.9991 8.3896C50.2455 8.3896 55.6702 11.9077 58.3999 17.0705Z"
            fill="white"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.25 28.0036C0.25 24.2876 3.26237 21.2753 6.97832 21.2753H80.9899C84.7058 21.2753 87.7182 24.2876 87.7182 28.0036V43.9828H83.5141C78.8692 43.9828 75.1037 47.7483 75.1037 52.3932C75.1037 57.0382 78.8692 60.8036 83.5141 60.8036H87.7182V76.7839C87.7182 80.4999 84.7058 83.5122 80.9899 83.5122H6.97832C3.26237 83.5122 0.25 80.4999 0.25 76.7839V28.0036ZM64.9602 48.336H72.16C71.9872 46.5708 71.5408 45.0023 70.8208 43.6304C70.1009 42.2585 69.1649 41.1025 68.0129 40.1623C66.8609 39.2125 65.5265 38.493 64.0098 38.0037C62.493 37.5048 60.8418 37.2554 59.0563 37.2554C56.4355 37.2554 54.0692 37.8406 51.9572 39.011C49.8452 40.1815 48.1701 41.8939 46.9317 44.1484C45.6933 46.4029 45.0741 49.1515 45.0741 52.3941C45.0741 55.6272 45.6837 58.371 46.9029 60.6255C48.1221 62.88 49.7828 64.5972 51.8852 65.7772C53.9972 66.9477 56.3875 67.5329 59.0563 67.5329C61.0818 67.5329 62.877 67.2259 64.4418 66.6119C66.0161 65.9979 67.3505 65.1728 68.4449 64.1367C69.5393 63.091 70.3984 61.935 71.0224 60.6686C71.656 59.4023 72.0352 58.1167 72.16 56.812L64.9602 56.7688C64.8354 57.4788 64.6098 58.1167 64.2834 58.6828C63.9666 59.2392 63.5586 59.7141 63.0594 60.1074C62.5698 60.4912 61.9986 60.7838 61.3458 60.9852C60.7026 61.1867 59.9874 61.2874 59.2003 61.2874C57.7987 61.2874 56.5843 60.9564 55.5571 60.2945C54.5396 59.6229 53.7476 58.63 53.1812 57.3157C52.6244 55.9917 52.346 54.3512 52.346 52.3941C52.346 50.5138 52.6196 48.9117 53.1668 47.5877C53.7236 46.2638 54.5156 45.2517 55.5427 44.5514C56.5795 43.851 57.8131 43.5009 59.2435 43.5009C60.0498 43.5009 60.7842 43.616 61.4466 43.8462C62.1186 44.0669 62.6994 44.3883 63.189 44.8104C63.6786 45.2325 64.0722 45.741 64.3698 46.3358C64.6674 46.9306 64.8642 47.5973 64.9602 48.336ZM40.909 67.1299V37.6583H33.7956V54.639H33.5508L21.9446 37.6583H15.8103V67.1299H22.9382V50.1205H23.1254L34.8611 67.1299H40.909Z"
            fill="white"
          />
          <Rect
            x="79.3093"
            y="48.189"
            width="8.41041"
            height="8.41041"
            rx="4.2052"
            fill="white"
          />
        </Svg>
      </AnimatedRootView>
    );
  }),
);

const AnimatedRootView = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
