import type {CryptoAddress, WalletId} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {
  AddressNetwork,
  AddressType,
} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {autorun, reaction, runInAction} from 'mobx';
import {useCallback, useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../hooks';
import {ReceiveCryptoBindingState} from './ReceiveCryptoBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const useReceiveCryptoBindingState = (
  walletId: WalletId,
  address: CryptoAddress,
  network?: AddressNetwork,
  type?: AddressType,
) => {
  const root = useRoot();
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  const [state] = useState(() => new ReceiveCryptoBindingState(root));
  useEffect(() => state.subscribe(), [state]);

  const refresh = useCallback(() => state.refresh(walletId), [state, walletId]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, refresh],
  );

  useEffect(
    () =>
      reaction(
        () => state.isTimeoutError,
        shouldFire => {
          if (shouldFire) {
            root.rpcTimeoutErrorVisibility.registerAction(async () => {
              await refresh();
            });
          }
        },
      ),
    [state, root.rpcTimeoutErrorVisibility, root.walletsState, refresh],
  );

  useEffect(() => {
    void state.setAddressParams(address, network, type);
  }, [address, network, state, type]);

  return state;
};
