import type {PinCode} from '@ncwallet-app/core';
import {urlLinks, useRoot, useStrings} from '@ncwallet-app/core';
import {LoadingIndicatorStatus} from '@ncwallet-app/core/src/LoadingIndicator';
import {flow} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo} from 'react';
import {Linking} from 'react-native';

import {CreatePinScreen} from '../../../screens/CreatePinScreen';
import {
  useConfirmPinTexts,
  useSecurePinTextsNew,
} from '../../../screens/PinScreen/PinScreenTexts';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptNewPinBindingProps =
  SmallHomeStackBindingProps<'PromptNewPin'>;

export default observer(function PromptNewPinBinding(
  props: PromptNewPinBindingProps,
) {
  const {navigation, route} = props;
  const {deviceInfo, ncWalletJsonRpcClient, loadingIndicator, flashMessage} =
    useRoot();
  const strings = useStrings();
  const appVersion = deviceInfo.getAppVersion();

  useEffect(() => {
    if (!route.params?.oldPin) {
      navigation.reset({index: 0, routes: [{name: 'Root'}]});
    }
  }, [navigation, route.params?.oldPin]);
  const onSuccess = useMemo(
    () =>
      flow(function* (pinCode: PinCode) {
        loadingIndicator.setLoadingStatus(LoadingIndicatorStatus.Active);
        yield ncWalletJsonRpcClient.call('accounts.pin.set', {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          old_pin: route.params!.oldPin,
          pin: pinCode,
        });
        loadingIndicator.setLoadingStatus(LoadingIndicatorStatus.Inactive);
        flashMessage.showMessage({
          title: strings['createPinScreen.successMessage'],
          variant: 'success',
        });
        if (navigation.canGoBack()) {
          navigation.goBack();
          return;
        }
        navigation.replace('ListSecuritySettings');
      }),
    [
      flashMessage,
      loadingIndicator,
      navigation,
      ncWalletJsonRpcClient,
      route.params,
      strings,
    ],
  );
  const onCancel = useCallback(() => {
    navigation.goBack();
  }, [navigation]);
  const onErrorActionPress = useCallback(() => {
    void Linking.openURL(urlLinks.supportPin);
  }, []);
  const screenTexts = useCreatePinText();

  return (
    <CreatePinScreen
      appVersion={appVersion}
      onSuccess={onSuccess}
      onCancel={onCancel}
      screenTexts={screenTexts}
      onErrorActionPress={onErrorActionPress}
    />
  );
});

export const useCreatePinText = () => {
  const enterNewPinTexts = useSecurePinTextsNew();
  const confirmTexts = useConfirmPinTexts();
  return useMemo(
    () => ({
      create: enterNewPinTexts,
      confirm: {...confirmTexts, backText: ''},
    }),
    [confirmTexts, enterNewPinTexts],
  );
};
