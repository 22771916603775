import type {AccountPersonalData} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import useAccountVerificationBindingState from '../../../CommonNavigationContainers/hooks/AccountVerification';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {IdentityVerificationPersonalInfoScreen} from '../../../screens/IdentityVerificationPersonalInfoScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptNameAndBirthdateBindingProps =
  SmallHomeStackBindingProps<'PromptNameAndBirthdate'>;

export default observer(function PromptNameAndBirthdateBinding(
  props: PromptNameAndBirthdateBindingProps,
) {
  const {navigation} = props;
  const verificationState = useAccountVerificationBindingState();

  const onSubmit = useCallback(
    async (form: AccountPersonalData) => {
      const res = await verificationState.savePersonalData(form);
      if (res.success) {
        navigation.navigate('PromptResidentialAddress');
      }
    },
    [navigation, verificationState],
  );

  return (
    <IdentityVerificationPersonalInfoScreen
      value={verificationState.personalData?.data}
      onSubmit={onSubmit}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});
