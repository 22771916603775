import {useRoot, variance} from '@ncwallet-app/core';
import {
  CenteredCardLayout,
  LG_BREAKPOINT,
  SafeAreaInset,
  SafeAreaLayout,
} from '@ncwallet-app/ui';
import {
  NCWalletLogoDarkSvg,
  NCWalletLogoSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {observer} from 'mobx-react-lite';
import type {ComponentType} from 'react';
import React from 'react';
import {Text, View} from 'react-native';

export type PendingRequestScreenProps = {
  title: string;
  cancelText: string;
  onCancel: () => void;
  LogButton?: ComponentType;
  appVersion?: string;
};

export default observer((props: PendingRequestScreenProps) => {
  const LogButton = props.LogButton;
  return (
    <CenteredCardLayout appVersion={props.appVersion} hideBackButton={true}>
      <Content insets={SafeAreaInset.BOTTOM}>
        <CenterBlock>
          <NCWalletIcon />
          <WaitingText>{props.title}</WaitingText>
        </CenterBlock>
        <FooterView>{LogButton && <LogButton />}</FooterView>
      </Content>
    </CenteredCardLayout>
  );
});

const FooterView = variance(View)(theme => ({
  root: {
    marginTop: 'auto',
    marginBottom: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 35,
        maxWidth: 345,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
}));

const CenterBlock = variance(View)(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
  },
}));

const Content = variance(SafeAreaLayout)(theme => ({
  root: {
    padding: 15,
    justifyContent: 'center',
    flex: 1,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: theme.palette.background,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        paddingTop: 0,
      },
    }),
  },
}));

const WaitingText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('600'),
    color: theme.palette.textPrimary,
    fontSize: 18,
    lineHeight: 28,
    marginTop: 40,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 24,
        lineHeight: 46,
      },
    }),
  },
}));

const NCWalletIcon = observer(() => {
  const {appearance} = useRoot();
  return appearance.isDark ? <NCWalletLogoSvg /> : <NCWalletLogoDarkSvg />;
});
