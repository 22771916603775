import {RateHistoryPeriod} from '@ncwallet-app/core';
import type {TabRowBaseItem} from '@ncwallet-app/ui';
import {TabRow} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {StyleSheet} from 'react-native';

export type RangeTabsProps = {
  activeTabKey: RateHistoryPeriod;
  onTabPress: (tabKey: RateHistoryPeriod) => void;
};

export default observer((props: RangeTabsProps) => {
  const tabs: TabRowBaseItem<RateHistoryPeriod>[] = useMemo(
    () => [
      {
        key: RateHistoryPeriod.Day,
        text: 'currencyScreen.tab.day',
      },
      {
        key: RateHistoryPeriod.Week,
        text: 'currencyScreen.tab.week',
      },
      {
        key: RateHistoryPeriod.Month,
        text: 'currencyScreen.tab.month',
      },
      {
        key: RateHistoryPeriod.Year,
        text: 'currencyScreen.tab.year',
      },
      {
        key: RateHistoryPeriod.All,
        text: 'currencyScreen.tab.all',
      },
    ],
    [],
  );
  return (
    <TabRow
      items={tabs}
      onPress={tab => {
        props.onTabPress(tab.key);
      }}
      idKey={tab => tab.key}
      active={tab => tab.key === props.activeTabKey}
      textKey={tab => tab.text}
      style={tabRowStyles.root}
    />
  );
});

const tabRowStyles = StyleSheet.create({
  root: {
    marginHorizontal: 15,
  },
});
