import {FiatCircleLogo, useIsDimensions} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import type {FC} from 'react';
import React from 'react';

import type {SettingsItemProps} from '../SharedComponents/SettingsItem';
import {
  ItemCol,
  ItemLabel,
  ItemTouchable,
  TitleWithLabel,
} from '../SharedComponents/SettingsItem';

interface SettingsItemPropsFiat extends SettingsItemProps {
  fiatCode: string;
  value?: string;
}

// eslint-disable-next-line import-x/prefer-default-export
export const FiatSectionItem: FC<SettingsItemPropsFiat> = observer(props => {
  const title = props.getTitle?.() ?? props.title;
  const error = props.getError?.() ?? props.error;
  const isLg = useIsDimensions('lg');
  const iconSize = isLg ? 22 : 40;
  return (
    <ItemTouchable onPress={props.onPress} second>
      <FiatCircleLogo
        active={!!props.active}
        code={props.fiatCode}
        size={iconSize}
      />
      <ItemCol icon>
        <TitleWithLabel error={error} noMargin>
          {title}
        </TitleWithLabel>
        {!!props.label && <ItemLabel withBottomMargin>{props.label}</ItemLabel>}
      </ItemCol>
    </ItemTouchable>
  );
});
