import type {Millisecond} from '@ncwallet-app/core';
import type {BlockchainNetworkStatusMessage} from '@ncwallet-app/core/src/NCWalletServer/BlockchainNetworkList';
import {useIsDimensions} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {BlockchainNetworkWarningBottomSheet} from '../../screens/BlockchainNetworkWarningScreen/BlockchainNetworkWarningBottomSheet';
import {BlockchainNetworkWarningModal} from '../../screens/BlockchainNetworkWarningScreen/BlockchainNetworkWarningModal';
import {useBlockChainNetworkProps} from './useBlockchainNetworkProps';

export type BlockchainNetworkWarningProps = {
  context: BlockchainNetworkStatusMessage;
  hideModal: (now: Millisecond) => Promise<void>;
};

export default observer(function BlockchainNetworkWarningBinding({
  context,
  hideModal,
}: BlockchainNetworkWarningProps) {
  const props = useBlockChainNetworkProps(context, hideModal);
  const isLg = useIsDimensions('lg');

  if (isLg) {
    return <BlockchainNetworkWarningModal {...props} />;
  }
  return <BlockchainNetworkWarningBottomSheet {...props} />;
});
