import type {GlobalError, ISODateString, OtpCode} from '@ncwallet-app/core';
import {CommonErrorCode, useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {
  NotifyAboutSuccessfulExchangeRoute,
  PromptConfirmationForExchangeRoute,
  PromptOtpToExchangeRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransitionMap} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransitionMap';
import type {WalletsTransactionsExchange} from '@ncwallet-app/core/src/NCWalletServer/WalletsTransactionsExchange';
import dayjs from 'dayjs';
import {autorun, runInAction} from 'mobx';
import {useCallback, useEffect, useRef, useState} from 'react';

import {useNavigationGetIsFocused} from '../../Navigation/hooks';
import useBaseErrorDescription from './useBaseErrorDescription';

export type PromptOtpToExchangeContainerProps = {
  params: RouteParams<PromptOtpToExchangeRoute>;
} & RouteTransitionMap<
  NotifyAboutSuccessfulExchangeRoute | PromptConfirmationForExchangeRoute
>;

export const usePromptOtpToExchangeContainer = (
  props: PromptOtpToExchangeContainerProps,
) => {
  const {params, notifyAboutSuccessfulExchange, promptConfirmationForExchange} =
    props;

  const root = useRoot();
  const strings = useStrings();

  const isSubmittingRef = useRef(false);
  const [getError, setError, resetError] = useBaseErrorDescription();
  const {token, currencyFrom, currencyTo, valueFrom, valueTo} = params;

  const handleExchangeError = useCallback(
    (submitError: GlobalError) => {
      const error = root.errorParser.describe(submitError);
      if (
        error.code === CommonErrorCode.ExchangeTokenExpired ||
        error.code === CommonErrorCode.ExchangeRateChanged
      ) {
        promptConfirmationForExchange({
          ...params,
          errorCode: error.code,
        });
      } else {
        setError(submitError);
      }
    },
    [params, promptConfirmationForExchange, root.errorParser, setError],
  );

  const exchange = useCallback(
    async (reqParams: WalletsTransactionsExchange['params']) => {
      if (isSubmittingRef.current) {
        return;
      }

      isSubmittingRef.current = true;
      const res = await root.ncWalletJsonRpcClient.call(
        'wallets.transactions.exchange',
        reqParams,
      );
      isSubmittingRef.current = false;

      if (res.success) {
        notifyAboutSuccessfulExchange({
          currencyFrom,
          currencyTo,
          valueFrom,
          valueTo,
        });
      } else {
        handleExchangeError(res.left);
      }
    },
    [
      currencyFrom,
      currencyTo,
      handleExchangeError,
      notifyAboutSuccessfulExchange,
      root.ncWalletJsonRpcClient,
      valueFrom,
      valueTo,
    ],
  );

  const onSubmit = useCallback(
    async (code: string) =>
      exchange({
        token,
        code_2fa: code as OtpCode,
        utc_2fa: dayjs().utc().toISOString() as ISODateString,
      }),
    [exchange, token],
  );

  const exchangeByBiometricSignature = useCallback(async () => {
    const signatureRes = await root.biometrics.createSignature({
      payload: token,
      promptMessage: strings['promptOtpToExchange.biometricConfirm'],
      cancelButtonText: strings['promptOtpToExchange.code.enter'],
    });

    if (!signatureRes.success) {
      return;
    }

    return exchange({
      biometry_signature: signatureRes.right,
      token,
    });
  }, [exchange, root.biometrics, strings, token]);

  const onBiometricPress = useCallback(async () => {
    if (isSubmittingRef.current) {
      return;
    }

    await exchangeByBiometricSignature();
  }, [exchangeByBiometricSignature]);

  const canPassTwoFaWithBiometrics = useCanPassTwoFaWithBiometrics(
    exchangeByBiometricSignature,
  );

  return {
    showBiometricButton: canPassTwoFaWithBiometrics,
    onBiometricPress,
    onSubmit,
    getError,
    resetError,
  };
};

export const useCanPassTwoFaWithBiometrics = (action: () => Promise<void>) => {
  const {biometricStateHelper} = useRoot();
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  const [canPassTwoFaWithBiometrics, setCanPassTwoFaWithBiometrics] =
    useState(false);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          void runInAction(async () => {
            const _canPassTwoFaWithBiometrics =
              await biometricStateHelper.canPassTwoFaWithBiometrics();
            setCanPassTwoFaWithBiometrics(_canPassTwoFaWithBiometrics);
            if (_canPassTwoFaWithBiometrics) {
              await action();
            }
          });
        }
      }),
    [biometricStateHelper, action, getIsFocused, getIsReady],
  );

  return canPassTwoFaWithBiometrics;
};
