import {useCallback, useEffect, useState} from 'react';

import type {TaskState, TaskStatus} from './TaskController';

export default function useTaskStatus<R, E, A extends unknown[] = []>(
  state: TaskState<R, E, A>,
) {
  const [, setStatus] = useState(() => state.getStatus());
  const updateStatus = useCallback((event: {theme: TaskStatus}) => {
    setStatus(event.theme);
  }, []);
  useEffect(
    () => state.outcome.domain.listen(updateStatus),
    [state, updateStatus],
  );
  return state.getStatus();
}
