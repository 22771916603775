import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import CalendarCell from './CalendarCell';
import type {State} from './CalendarModalReducer';
import {getCellCalendarInfo, getMonthInfo} from './CalendarModalSettings';

export type CalendarModalMonthViewProps = {
  state: State;
  setNewDay: (day: number, month: number) => void;
  onBack: () => void;
};

export default observer(function CalendarModalMonthView({
  setNewDay,
  state,
  onBack,
}: CalendarModalMonthViewProps) {
  const monthInfo = getMonthInfo(state.month, state.year, state.date);
  const cellCount = 42;
  const calendarData = getCellCalendarInfo(monthInfo, cellCount);
  const onCellPress = (index: number, day: number) => {
    return index < monthInfo.firstDate
      ? () => {
          setNewDay(day, monthInfo.activeMonth - 1);
        }
      : index >= monthInfo.firstDate + monthInfo.daysInMonth
        ? () => {
            setNewDay(day, monthInfo.activeMonth + 1);
          }
        : day === monthInfo.activeDay
          ? onBack
          : () => {
              setNewDay(day, monthInfo.activeMonth);
            };
  };

  const today = new Date();
  const currentDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
  );

  return (
    <Container>
      {calendarData.map((day, index) => {
        const cellDate = new Date(state.year, monthInfo.activeMonth, day);
        const isDateInCurrentMonth =
          cellDate.getMonth() === new Date().getMonth();
        const isFutureDate = isDateInCurrentMonth && cellDate > currentDate;
        return (
          <CalendarCell
            active={
              day === monthInfo.activeDay &&
              monthInfo.activeMonth === state.date.getMonth()
            }
            day={day}
            key={index}
            disabled={
              isFutureDate &&
              index > monthInfo.firstDate &&
              index <= monthInfo.daysInMonth
            }
            index={index}
            last={index === calendarData.length - 1}
            first={index === 0}
            onPress={onCellPress(index, day)}
            isDaysOutsideTheMonth={
              index < monthInfo.firstDate ||
              index >= monthInfo.firstDate + monthInfo.daysInMonth
            }
          />
        );
      })}
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));
