import {error, success} from '@ncwallet-app/core';
import type {AccountDataRequestHelper} from '@ncwallet-app/core/src/AccountDataRequestHelper';
import type {AccountDocumentManager} from '@ncwallet-app/core/src/AccountDocumentManager/AccountDocumentManager';
import type {AccountDocumentTypeManager} from '@ncwallet-app/core/src/AccountDocumentManager/AccountDocumentTypeManager';
import type {FlashMessage} from '@ncwallet-app/core/src/FlashMessage';
import type {
  AccountAddressData,
  AccountAddressDataWrapper,
  AccountPersonalData,
  AccountPersonalDataWrapper,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {dataCanBeReviewed} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import type {
  AccountDocumentId,
  AccountDocumentType,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import type {AccountDocumentFileName} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments/AccountDocumentFileInfo';
import {isEmpty} from 'lodash';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

export default class AccountVerificationBindingState {
  constructor(
    private readonly _root: {
      readonly accountDocumentManager: AccountDocumentManager;
      readonly accountDocumentTypeManager: AccountDocumentTypeManager;
      readonly flashMessage: FlashMessage;
    },
    private _accountDataRequestHelper: AccountDataRequestHelper,
  ) {
    makeObservable(this);
  }

  @observable private _isLoaded = false;
  @observable private _personalData: AccountPersonalDataWrapper | undefined;
  @observable private _addressData: AccountAddressDataWrapper | undefined;

  @computed get personalData() {
    return this._personalData;
  }

  @computed get addressData() {
    return this._addressData;
  }

  @computed get documentTypes() {
    return this._root.accountDocumentTypeManager.getAll() ?? [];
  }

  @computed get documents() {
    return this._root.accountDocumentManager.getAll() ?? [];
  }

  @computed
  get isLoaded() {
    return this._isLoaded;
  }

  async uploadFile(
    fileData: string,
    documentType: AccountDocumentType,
    fileName?: string,
  ) {
    return this._root.accountDocumentManager.uploadFile(
      fileData,
      documentType,
      fileName,
    );
  }

  async savePersonalData(data: AccountPersonalData) {
    const res = await this._accountDataRequestHelper.savePersonalData(
      this._personalData?.id,
      data,
    );
    if (res.success) {
      this._setPersonalData(res.right);
    }
    return res;
  }

  async saveAddressData(data: AccountAddressData) {
    // если дата пустая и не была создана, то сохранение не нужно
    if (Object.values(data).every(isEmpty) && !this._addressData) {
      return success();
    }
    const res = await this._accountDataRequestHelper.saveAddressData(
      this._addressData?.id,
      data,
    );
    if (res.success) {
      this._setAddressData(res.right);
    }
    return res;
  }

  @action.bound
  async deleteFile(id: AccountDocumentId, file: AccountDocumentFileName) {
    return this._root.accountDocumentManager.deleteFiles(id, [file]);
  }

  @action.bound
  async sendToReview() {
    if (!this._personalData) {
      this._root.flashMessage.showMessage({
        title: 'validationError.enterName',
        variant: 'danger',
      });
      return error('!this._personalData');
    }
    if (this.documents.length === 0) {
      this._root.flashMessage.showMessage({
        title: 'validationError.uploadDocument',
        variant: 'danger',
      });
      return error('this.documents.length === 0');
    }
    const res = await Promise.all([
      this._sendPersonalDataToReviewIfPossible(),
      this._sendAddressDataToReviewIfPossible(),
      this._root.accountDocumentManager.sendDocumentsToReview(),
    ]);
    return res.every(r => !r || r.success) ? success() : error('');
  }

  @action.bound
  async refresh(): Promise<void> {
    void this._root.accountDocumentTypeManager.refresh();
    void this._root.accountDocumentManager.refresh();

    const accountDataRes = await this._accountDataRequestHelper.getAll();

    if (accountDataRes.success) {
      this._setPersonalData(accountDataRes.right.personal);
      this._setAddressData(accountDataRes.right.address);
      runInAction(() => {
        this._isLoaded = true;
      });
    }
  }

  @action.bound
  private _setPersonalData(data: AccountPersonalDataWrapper | undefined) {
    this._personalData = data;
  }

  @action.bound
  private _setAddressData(data: AccountAddressDataWrapper | undefined) {
    this._addressData = data;
  }

  private async _sendPersonalDataToReviewIfPossible() {
    if (!this._personalData || !dataCanBeReviewed(this._personalData)) {
      return;
    }

    const res = await this._accountDataRequestHelper.sendToReview(
      this._personalData.id,
    );
    if (res.success) {
      this._setPersonalData(res.right as AccountPersonalDataWrapper);
    }
    return res;
  }

  private async _sendAddressDataToReviewIfPossible() {
    if (!this._addressData || !dataCanBeReviewed(this._addressData)) {
      return;
    }

    const res = await this._accountDataRequestHelper.sendToReview(
      this._addressData.id,
    );
    if (res.success) {
      this._setAddressData(res.right as AccountAddressDataWrapper);
    }
    return res;
  }
}
