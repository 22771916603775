import {variance} from '@ncwallet-app/core';
import {BaseSkeleton, LG_BREAKPOINT} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export default observer(function HistoryDetailsSkeleton() {
  return (
    <HistoryDetailsSkeletonView>
      <Row>
        <BaseSkeleton width={70} height={17} />
        <BaseSkeleton width={170} height={17} />
      </Row>
      <Row>
        <BaseSkeleton width={70} height={17} />
        <BaseSkeleton width={170} height={17} />
      </Row>
      <Row>
        <BaseSkeleton width={70} height={17} />
        <BaseSkeleton width={170} height={17} />
      </Row>
      <Row>
        <BaseSkeleton width={70} height={17} />
        <BaseSkeleton width={170} height={17} />
      </Row>
      <Row last>
        <BaseSkeleton width={70} height={17} />
        <BaseSkeleton width={170} height={17} />
      </Row>
    </HistoryDetailsSkeletonView>
  );
});

const HistoryDetailsSkeletonView = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 5,
    borderTopWidth: 1,
    borderTopColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiPrimary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
        paddingVertical: 0,
      },
    }),
  },
}));

const Row = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 10,
        paddingVertical: 15,
      },
    }),
  },
  last: {
    borderBottomWidth: 0,
  },
}));
