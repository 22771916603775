import type {Receipt} from '../AddressParser';
import type {AddressParams} from '../CommonNavigationScheme';
import type {CryptoCurrencyCode} from '../Money';
import type {AddressNetwork, AddressType} from './AddressInfo';
import type {CryptoCurrency} from './CryptoCurrency';

export type InOutCurrency = {
  network: AddressNetwork;
  name: string;
  currency: CryptoCurrencyCode;
  default_type: AddressType | undefined;
  address_types: AddressType[];
  contract_type?: string | null;
  allow_comment?: boolean;
};
export const getDefaultInOutCurrency = (
  currency: CryptoCurrency,
  direction: 'in' | 'out',
): InOutCurrency => {
  const options = currency.options;
  const currencies =
    direction === 'out' ? options.currencies_out : options.currencies_in;

  const defaultCurrency = currencies.find(
    c => c.network === options.default_network,
  );
  return defaultCurrency ?? currencies[0];
};

export const getDefaultAddressParams = (
  c: InOutCurrency,
  receipt?: Partial<Receipt>,
): AddressParams => ({
  addressNetwork: receipt?.network ?? c.network,
  addressCurrency: c.currency,
});

export const getCompositeId = (c: InOutCurrency) =>
  `${c.currency}-${c.network}`;
