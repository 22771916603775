import {variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui';
import {View} from 'react-native';

export const Root = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.uiPrimary,
      },
    }),
  },
}));

export const ListWrapper = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 8,
        borderColor: theme.palette.uiSecondary,
        backgroundColor: theme.palette.uiPrimary,
        borderWidth: 1,
      },
    }),
  },
}));

export const Inner = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        backgroundColor: theme.palette.background,
        padding: 30,
        paddingBottom: 30,
        ...theme.bar(10),
      },
    }),
  },
}));
