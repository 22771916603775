import {useRoot, useStrings, variance} from '@ncwallet-app/core';
import {
  NCWalletLogoDarkSvg,
  NCWalletLogoSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {PlusSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';

import {AuthKind} from '../../screens/AuthScreen';
import {MdAuthLayout} from '../../screens/MdAuthLayout';
import type {AuthStackBindingProps} from './AuthStackBindingProps';
import useOnLogInPress from './useOnLogInPress';

export type MdCreateWalletBindingProps = AuthStackBindingProps<'CreateWallet'>;

export default observer(function MdCreateWalletBinding(
  props: MdCreateWalletBindingProps,
) {
  const {deviceInfo} = useRoot();
  const strings = useStrings();
  const onBackPress = useCallback(() => {
    props.navigation.navigate('Welcome');
  }, [props.navigation]);
  const onLogInPress = useOnLogInPress();

  return (
    <MdAuthLayout
      authKind={AuthKind.SignIn}
      title={strings['mdCreateWallet.title']}
      description={strings['mdCreateWallet.description']}
      onBackPress={onBackPress}
      Icon={CreateWalletIcon}
      appVersion={deviceInfo.getAppVersionWithBuildNumber()}
      onButtonPress={onLogInPress}
    />
  );
});

const IconContainer = variance(View)(() => ({
  root: {
    position: 'relative',
  },
}));

const PlusIcon = variance(View)(theme => ({
  root: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
    bottom: 0,
    right: -9,
    height: 35,
    borderRadius: 100,
    borderWidth: 5,
    borderColor: theme.palette.background,
    backgroundColor: theme.palette.info,
  },
}));
const CreateWalletIcon = observer(() => {
  const {appearance} = useRoot();
  return (
    <IconContainer>
      <View>
        {appearance.isDark ? <NCWalletLogoSvg /> : <NCWalletLogoDarkSvg />}
      </View>
      <PlusIcon>
        <PlusSvg color={'#fff'} />
      </PlusIcon>
    </IconContainer>
  );
});
