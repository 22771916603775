import {useStrings} from '@ncwallet-app/core';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {InputScreen} from '../../../components';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptEmailBindingProps = SmallHomeStackBindingProps<'PromptEmail'>;

export default observer(function PromptEmailBinding(
  props: PromptEmailBindingProps,
) {
  const {route, navigation} = props;
  const strings = useStrings();

  const handleSubmit = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <InputScreen
      title="Title"
      onSubmit={handleSubmit}
      value={route.params?.email ?? ''}
      label={strings['profileSettingsScreen.updateEmailLabel']}
      placeholder={strings['profileSettingsScreen.updateEmailPlaceholder']}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      keyboardAvoiding
    />
  );
});
