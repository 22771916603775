import {FULFILLED, useRoot} from '@ncwallet-app/core';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {CommonActions} from '@react-navigation/native';
import {observable, reaction, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';

import {useSelectionForAddBindingState} from '../../../../../Navigation/HomeStack/SelectionForAddBinding/useSelectionForAddBindingState';
import {filterCurrencySelectionItems} from '../../../../../Navigation/hooks';
import {EXTRA_BOTTOM_OFFSET} from '../../../../../screens/constants';
import {SelectionForAddScreen} from '../../../../../screens/SelectionForAddScreen';
import {ScreenType} from '../../../../../screens/SelectionForExchangeScreen/ScreenType';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptNewWalletBindingProps =
  LargeSwitchBindingProps<'PromptNewWallet'>;

export default observer(function PromptNewWalletBinding(
  props: PromptNewWalletBindingProps,
) {
  const {route, navigation} = props;
  const root = useRoot();
  const state = useSelectionForAddBindingState();

  const [searchBox] = useState(() =>
    observable.box<string | undefined>(route.params?.search),
  );

  useLayoutEffect(() => {
    runInAction(() => {
      searchBox.set(route.params?.search);
    });
  }, [searchBox, route.params?.search]);
  const getSearch = useCallback(() => searchBox.get(), [searchBox]);
  const setSearch = useCallback(
    (search: string) => {
      navigation.setParams({search});
    },
    [navigation],
  );

  const onRefresh = useCallback(async () => {
    return Promise.all([
      (async () => {
        if (root.accountStore.state?.status !== FULFILLED) {
          return;
        }
        return state.refresh(root.accountStore.state.result.base_fiat);
      })(),
    ]);
  }, [root, state]);

  useEffect(
    () =>
      reaction(
        () => state.isTimeoutError,
        shouldFire => {
          if (shouldFire) {
            root.rpcTimeoutErrorVisibility.registerAction(onRefresh);
          }
        },
      ),
    [state, root.rpcTimeoutErrorVisibility, onRefresh],
  );

  const onCurrencyPress = useCallback(
    async (item: CurrencySelectionListItemData) => {
      if (!state.isSubmitting()) {
        const res = await state.submit(item);
        if (res.success && res.right) {
          const wallet = res.right;
          navigation.dispatch(navState => {
            const routes = navState.routes.slice(0, -1);
            return CommonActions.reset({
              ...navState,
              index: routes.length,
              routes: [
                ...routes,
                {
                  name: 'ShowWallet',
                  params: {
                    walletId: wallet.id,
                    currencyCode: wallet.currency,
                  },
                },
              ],
            });
          });
        }
      }
    },
    [navigation, state],
  );

  const navigateToReceive = useCallback(() => {
    navigation.navigate('PromptCryptoToReceive');
  }, [navigation]);

  const navigateToSend = useCallback(() => {
    navigation.navigate('PromptCryptoToSend');
  }, [navigation]);

  const navigateToExchange = useCallback(() => {
    navigation.navigate('PromptSourceCryptoToExchange');
  }, [navigation]);

  const handleBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    } else {
      navigation.replace('Root');
    }
  }, [navigation]);

  const getFilteredList = useCallback(() => {
    return filterCurrencySelectionItems(state.currencies, searchBox.get());
  }, [searchBox, state.currencies]);

  return (
    <SelectionForAddScreen
      screenType={ScreenType.Modal}
      getIsLoading={state.isLoading}
      onRefresh={onRefresh}
      getCurrencies={state.getCurrencies}
      getShownCurrencies={getFilteredList}
      onBack={handleBack}
      onCurrencyPress={onCurrencyPress}
      getSearch={getSearch}
      setSearch={setSearch}
      goToReceive={navigateToReceive}
      goToExchange={navigateToExchange}
      goToSend={navigateToSend}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});
