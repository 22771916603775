import type {ReactInstance, RefObject} from 'react';
import {useCallback} from 'react';
import {findDOMNode} from 'react-dom';

import {expr} from '../mobx-toolbox';

export default function useGetElement(
  ref: RefObject<ReactInstance | null | undefined>,
) {
  return useCallback(
    () =>
      expr(() => {
        const element = findDOMNode(ref.current);
        return element instanceof HTMLElement ? element : null;
      }),
    [ref],
  );
}
