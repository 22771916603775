import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, runInAction} from 'mobx';
import {useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../hooks';
import {BaseFiatSettingsBindingState} from './BaseFiatSettingsBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const useBaseFiatSettingsBindingState = () => {
  const root = useRoot();
  const [state] = useState(() => new BaseFiatSettingsBindingState(root));
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, state],
  );
  return state;
};
