import type {Maybe} from '../Maybe';
import type {Biometrics} from './Biometrics';
import type {BiometricSignature} from './PublicKey';

export default class StubBiometricsImpl implements Biometrics {
  localKeysExist = false;

  isEnrolled() {
    return Promise.resolve(false);
  }

  isSensorAvailable(): Promise<boolean> {
    return Promise.resolve(false);
  }

  simplePrompt(): Promise<Maybe<boolean>> {
    throw new Error('prompt unavailable in StubBiometricsImpl');
  }

  createSignature(): Promise<Maybe<BiometricSignature>> {
    throw new Error('createSignature unavailable in StubBiometricsImpl');
  }

  signInByBiometric(): Promise<void> {
    throw new Error('showBiometricOsView unavailable in StubBiometricsImpl');
  }
}
