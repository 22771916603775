import {variance} from '@ncwallet-app/core';
import {
  Button,
  LG_BREAKPOINT,
  PressableNativeFeedback,
  TouchableListItem,
  TouchableOpacity,
} from '@ncwallet-app/ui';
import {Text, View} from 'react-native';

export const WalletAddressContainer = variance(TouchableOpacity)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'stretch',
    backgroundColor: theme.palette.uiPrimary,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
  affiliate: {
    marginTop: 10,
  },
}));

export const WalletAddressLabel = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    marginRight: 7,
  },
}));

export const WalletAddressLabelPress = variance(PressableNativeFeedback)(
  theme => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          marginBottom: 10,
        },
      }),
    },
  }),
);

export const WalletAddressValueContainer = variance(View)(() => ({
  root: {
    flex: 8,
    padding: 15,
  },
}));

export const WalletAddressValue = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    flexDirection: 'row',
    display: 'flex',
  },
}));

export const ActionButton = variance(Button)(() => ({
  root: {
    flex: 1,
  },
  offsetRight: {
    marginRight: 15,
  },
  offsetLeft: {
    marginLeft: 15,
  },
}));

export const WalletAddressActions = variance(View)(() => ({
  root: {
    flex: 4,
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
  },
}));

export const Divider = variance(View)(theme => ({
  root: {
    width: 1,
    backgroundColor: theme.palette.uiSecondary,
  },
}));

export const WalletAddressAction = variance(TouchableListItem)(theme => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    flex: 1,
    backgroundColor: theme.palette.uiPrimary,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },

  simple: {
    backgroundColor: 'transparent',
    width: 40,
    maxWidth: 40,
    height: 40,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
}));
