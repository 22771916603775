import {
  type CryptoCurrency,
  type CryptoCurrencyCode,
  type CurrencyStore,
  type FiatCurrencyCode,
  type OnTimeoutError,
  TIMEOUT_ERROR,
  type Wallet,
  type WalletId,
  type WalletStore,
} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import {getAccountFromState} from '@ncwallet-app/core/src/AccountStore';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {SelectionForReceiveTabKey} from '../../../screens/SelectionForReceiveScreen/SelectionForReceiveTabKey';
import type {CurrencyHistoryRefresher} from '../../../shared/CurrencyHistoryRefresher';
import type {CurrencySelectionListConverter} from '../../../shared/CurrencySelectionListConverter';
import {
  getCurrencyWalletPairsForAllCurrencies,
  getCurrencyWalletPairsForUserWallets,
} from '../../../shared/currencySelectionListConverterHelpers';

export default class SelectionForReceiveBindingState implements OnTimeoutError {
  @observable _tab: SelectionForReceiveTabKey =
    SelectionForReceiveTabKey.Unknown;

  @observable isSubmitting = false;
  @observable private _isLoading: boolean = false;
  @observable private _isManualRefresh = false;

  @observable private _isTimeoutError = false;

  @computed get isTimeoutError() {
    return this._isTimeoutError;
  }

  @computed
  get tab() {
    return this._tab;
  }

  set tab(tab: SelectionForReceiveTabKey) {
    this._tab = tab;
  }

  @computed
  private get _baseFiat(): FiatCurrencyCode | undefined {
    return getAccountFromState(this._root.accountStore.state)?.base_fiat;
  }

  @computed get currencies(): CurrencySelectionListItemData[] | undefined {
    const wallets = this._root.walletStore
      .getWallets()
      ?.filter(this._filterWalletForNotAddCurrency);
    const currencies = this._root.currencyStore
      .getCryptoCurrencies()
      ?.filter(filterNotAddCurrency);
    if (!this._baseFiat || !wallets || !currencies) {
      return;
    }

    return this.getItems(currencies, wallets);
  }

  @computed get allCurrencies(): CurrencySelectionListItemData[] | undefined {
    const wallets = this._root.walletStore
      .getWallets()
      ?.filter(this._filterWalletForNotAddCurrency);
    const currencies = this._root.currencyStore
      .getCryptoCurrencies()
      ?.filter(filterNotAddCurrency);
    if (!this._baseFiat || !wallets || !currencies) {
      return;
    }

    return this.getItems(currencies, wallets, true);
  }

  constructor(
    private readonly _root: {
      readonly accountStore: AccountStore;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
    },
    private readonly itemConverter: CurrencySelectionListConverter,
    private readonly historyRefresher: CurrencyHistoryRefresher,
  ) {
    makeObservable(this);
  }

  getIsLoading = () => this._isLoading;
  getIsManualRefresh = () => this._isManualRefresh;

  manualRefresh = async () => {
    try {
      runInAction(() => {
        this._isManualRefresh = true;
      });
      await this.refresh();
    } finally {
      runInAction(() => {
        this._isManualRefresh = false;
      });
    }
  };

  @action.bound
  async refresh() {
    runInAction(() => {
      this._isLoading = true;
    });
    this.checkForSingleWallet();
    const responses = await Promise.all([
      this._root.currencyStore.refreshFiatCurrencies(),
      this._root.walletStore.refreshWallets(),
      this._root.currencyStore.refreshCryptoCurrencies(),
    ]);
    runInAction(() => {
      this._isTimeoutError = responses.some(
        it => !it.success && it.left.kind === TIMEOUT_ERROR,
      );
      this._isLoading = false;
    });
    const [, , cryptoRes] = responses;
    if (!cryptoRes.success) {
      return;
    }
    // история и рейты обновляются для всех валют и не обновляются при переключении табов
    this.historyRefresher.setStaleCodes(cryptoRes.right.map(c => c.code));
  }

  setTab = action((tab: SelectionForReceiveTabKey) => {
    if (tab !== this.tab) {
      this.tab = tab;
    }
  });

  subscribe() {
    return this.historyRefresher.subscribe();
  }

  checkForSingleWallet() {
    const wallets = this._root.walletStore.getWallets();

    if (!wallets) {
      return;
    }
    if (wallets.length === 1) {
      this.setTab(SelectionForReceiveTabKey.All);
    } else {
      this.setTab(SelectionForReceiveTabKey.OnWallet);
    }
  }

  activateRateHistoryLoad() {
    this.historyRefresher.activate();
  }

  deactivateRateHistoryLoad() {
    this.historyRefresher.deactivate();
  }

  private getItems(
    currencies: CryptoCurrency[],
    wallets: Wallet[],
    all?: boolean,
  ) {
    const disabledCodes = new Set(
      currencies.filter(c => !c.options.incoming).map(c => c.code),
    );

    if (this.tab === SelectionForReceiveTabKey.Unknown) {
      this.checkForSingleWallet();
    }

    const pairs =
      this.tab === SelectionForReceiveTabKey.OnWallet
        ? getCurrencyWalletPairsForUserWallets(currencies, wallets)
        : getCurrencyWalletPairsForAllCurrencies(currencies, wallets);

    if (all) {
      return pairs.map(([c, w]) =>
        this.itemConverter.toItem(c, w, disabledCodes),
      );
    }
    return pairs
      .filter(([, w]) => !w || w.is_visible)
      .map(([c, w]) => this.itemConverter.toItem(c, w, disabledCodes));
  }

  @action.bound
  async create(item: CurrencySelectionListItemData) {
    this.isSubmitting = true;
    const res = await this._root.walletStore.createWallet(
      item.cryptoCurrency.code as CryptoCurrencyCode,
      '',
    );
    runInAction(() => {
      this.isSubmitting = false;
    });
    return res;
  }

  private _filterWalletForNotAddCurrency = (w: Wallet) => {
    return (
      this._root.currencyStore.getCryptoCurrency(w.currency)?.options
        .addable !== false
    );
  };

  @action.bound
  async updateWalletVisibility(
    item: CurrencySelectionListItemData,
    isVisible: boolean,
  ) {
    this.isSubmitting = true;
    const res = await this._root.walletStore.updateWalletVisibility(
      item.walletId as WalletId,
      isVisible,
    );
    runInAction(() => {
      this.isSubmitting = false;
    });
    return res;
  }
}

function filterNotAddCurrency(c: CryptoCurrency) {
  return c.options.addable;
}
