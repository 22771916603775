import {FULFILLED} from '../AsyncAtom';
import type {AuthState} from '../Auth';
import {AUTHORIZED} from '../Auth';
import type {JwtHelper} from '../Auth/JwtHelper';
import {BaseFreshTokenStateImpl} from '../FreshTokenState';
import type {Millisecond} from '../Time';
import type {TimeState} from '../TimeState';
import type {AccessToken} from '../units';
import type {FreshAccessTokenState} from './FreshAccessTokenState';

export default class FreshAccessTokenStateImpl
  extends BaseFreshTokenStateImpl<AccessToken>
  implements FreshAccessTokenState
{
  constructor(
    protected readonly _root: {
      readonly authState: AuthState;
      readonly timeState: TimeState;
      readonly jwtHelper: JwtHelper;
    },
  ) {
    super(_root, 1000 as Millisecond);
  }

  protected _getToken(): AccessToken | undefined {
    const {latest} = this._root.authState;
    if (latest?.status !== FULFILLED || latest.result.kind !== AUTHORIZED) {
      return undefined;
    }
    return latest.result.credentials.accessToken;
  }
}
