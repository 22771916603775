import {useTheme, variance} from '@ncwallet-app/core';
import {Button, ButtonColor, ButtonVariant} from '@ncwallet-app/ui';
import {LinearGradient} from 'expo-linear-gradient';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Image, Text, View} from 'react-native';
import type {SvgProps} from 'react-native-svg';

export type WalletBannerProps = {
  title: string;
  text: string[];
  theme: WalletBannerTheme;
  offsetBottom?: boolean;
  backgroundImage: number;
  buttonText: string;
  buttonIcon: React.ComponentType<SvgProps>;
  onButtonPress: () => void;
};

export enum WalletBannerTheme {
  Orange,
  Blue,
}

export default observer(function WalletBanner({
  title,
  text,
  offsetBottom,
  backgroundImage,
  buttonText,
  buttonIcon,
  onButtonPress,
  ...props
}: WalletBannerProps) {
  const theme = useTheme();
  let gradient: string[] = [];
  let buttonColor: ButtonColor = ButtonColor.Primary;

  if (props.theme === WalletBannerTheme.Orange) {
    gradient = [theme.palette.primary, theme.palette.orangeGradientEnd];
    buttonColor = ButtonColor.Primary;
  }

  if (props.theme === WalletBannerTheme.Blue) {
    gradient = [theme.palette.blueGradientStart, theme.palette.blueGradientEnd];
    buttonColor = ButtonColor.Secondary;
  }

  return (
    <Root
      colors={gradient}
      start={
        props.theme === WalletBannerTheme.Blue ? {x: 0, y: 0.5} : undefined
      }
      end={props.theme === WalletBannerTheme.Blue ? {x: 1, y: 0.5} : undefined}
      offsetBottom={offsetBottom}>
      <Background source={backgroundImage} resizeMode={'cover'} />
      <BannerContent>
        <Title>{title}</Title>
        {text.map((t, i, arr) => (
          <Paragraph key={t} offsetTop={arr.length > 1 && i === arr.length - 1}>
            {t}
          </Paragraph>
        ))}
        <ButtonContainer>
          <Button
            title={buttonText}
            Icon={buttonIcon}
            variant={ButtonVariant.TabletDefault}
            color={buttonColor}
            onPress={onButtonPress}
          />
        </ButtonContainer>
      </BannerContent>
    </Root>
  );
});

const Root = variance(LinearGradient)(theme => ({
  root: {
    width: '100%',
    position: 'relative',
    borderRadius: 10,
    flex: 1,
    maxHeight: 255,
    shadowColor: theme.palette.blackout,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    borderColor: theme.palette.uiSecondary,
    borderWidth: 1,
  },

  offsetBottom: {
    marginBottom: 20,
  },
}));

const BannerContent = variance(View)(() => ({
  root: {
    padding: 30,
    flex: 1,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.whitey,
    fontSize: 20,
    lineHeight: 24,
    marginBottom: 10,
  },
}));

const Paragraph = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    maxWidth: '61%',
    marginBottom: 10,
    color: theme.palette.whitey,
    opacity: 0.8,
  },

  offsetTop: {
    marginTop: 'auto',
  },
}));

const Background = variance(Image)(() => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    borderRadius: 10,
  },
}));

const ButtonContainer = variance(View)(() => ({
  root: {
    marginTop: 'auto',
    flexDirection: 'row',
  },
}));
