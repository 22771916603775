import {urlLinks, useStrings, variance} from '@ncwallet-app/core';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Linking, Text} from 'react-native';

import {LimitCard} from '../shared/LimitCard';
import {LimitCardBody} from '../shared/LimitCardBody';
import {LimitCardHeader, LimitCardHeaderTitle} from '../shared/LimitCardHeader';

export default observer(function FaqLimitCard() {
  const strings = useStrings();

  return (
    <LimitCard>
      <LimitCardHeader>
        <LimitCardHeaderTitle>
          {strings['limitsSettingsScreen.faq']}
        </LimitCardHeaderTitle>
      </LimitCardHeader>
      <LimitCardBody>
        <LimitCardText>
          {strings['limitsSettingsScreen.faq.subtitle']}
        </LimitCardText>
        <LimitCardLink
          onPress={() => {
            void Linking.openURL(urlLinks.whatIsWalletLimitLink);
          }}>
          <LimitCardLinkText>
            {`1. ${strings['limitsSettingsScreen.faq.whatIsWithdrawalLimit']}`}
          </LimitCardLinkText>
        </LimitCardLink>
        <LimitCardLink
          onPress={() => {
            void Linking.openURL(urlLinks.changeWalletLimitLink);
          }}>
          <LimitCardLinkText>
            {`2. ${strings['limitsSettingsScreen.faq.changeWithdrawalLimit']}`}
          </LimitCardLinkText>
        </LimitCardLink>
      </LimitCardBody>
    </LimitCard>
  );
});

const LimitCardText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 16,
    color: theme.palette.textAdditional1,
  },
}));

const LimitCardLink = variance(TouchableOpacity)(() => ({
  root: {
    marginTop: 20,
  },
}));

const LimitCardLinkText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.info,
  },
}));
