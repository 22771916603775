import type {ColorKey, Millisecond} from '@ncwallet-app/core';
import {useDateFormatting, useTheme, variance} from '@ncwallet-app/core';
import {
  GearOutlinedSvg,
  KeySvg,
  LockThinSvg,
  ReceiptFragmentSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import type {FC} from 'react';
import React from 'react';
import {Text, View} from 'react-native';
import type {SvgProps} from 'react-native-svg';

export type NotificationItemProps = {
  data: Notification;
};

export type Notification = {
  id: string;
  title: string;
  body: string;
  group: NotificationGroup;
  createdAt: Millisecond;
  isRead?: boolean;
};

export enum NotificationGroup {
  Auth,
  Wallet,
  Settings,
  Transactions,
}

export default observer(function NotificationsItem(
  props: NotificationItemProps,
) {
  const {
    data: {title, body, group, createdAt, isRead = true},
  } = props;
  const theme = useTheme();
  const {formatDateTimeWithYear} = useDateFormatting();
  // параметр по-умолчанию добавлен чтобы при появлении нового,
  // не добавленного в словарь ниже параметра компонент все еще был работоспособен
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const [IconSvg, colorKey] = GROUP_ICON_MAP[group] ?? [
    GearOutlinedSvg,
    'uiMain',
  ];
  return (
    <Root>
      <Row>
        <View>
          <Label>
            <LabelText>{formatDateTimeWithYear(createdAt)}</LabelText>
            {isRead ? null : <LabelDot />}
          </Label>
          <Title>{title}</Title>
        </View>
        <Icon>
          <IconSvg color={theme.palette[colorKey]} />
        </Icon>
      </Row>
      <ItemText>{body}</ItemText>
    </Root>
  );
});

const GROUP_ICON_MAP: Record<NotificationGroup, [FC<SvgProps>, ColorKey]> = {
  [NotificationGroup.Auth]: [LockThinSvg, 'error'],
  [NotificationGroup.Wallet]: [KeySvg, 'uiMain'],
  [NotificationGroup.Settings]: [GearOutlinedSvg, 'uiMain'],
  [NotificationGroup.Transactions]: [ReceiptFragmentSvg, 'primary'],
};

const Root = variance(View)(() => ({
  root: {
    paddingVertical: 15,
  },
}));

const Row = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Label = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const LabelDot = variance(View)(theme => ({
  root: {
    width: 6,
    height: 6,
    borderRadius: 50,
    marginLeft: 10,
    backgroundColor: theme.palette.error,
  },
}));

const LabelText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 15,
    color: theme.palette.textAdditional2,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 24,
    color: theme.palette.textPrimary,
    marginTop: 5,
  },
}));

const Icon = variance(View)(theme => ({
  root: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    borderRadius: 50,
    marginLeft: 15,
  },
}));

const ItemText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    paddingHorizontal: 15,
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.textAdditional1,
    marginTop: 5,
  },
}));
