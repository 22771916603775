import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import type {Opaque} from '../Opaque';
import type {RouterSource} from '../structure';

export const REQUEST = Symbol();
export const RESPONSE = Symbol();
export const RESPONSE_BODY = Symbol();
export const NEW_WEB_SOCKET = Symbol();

export interface Http {
  fetch(
    input: RequestInfo,
    init?: RequestInit,
  ): Promise<Either<Response, GlobalError>>;
  createWebSocket(
    uri: string,
    protocols?: string | string[],
  ): Either<WebSocket, GlobalError>;
  readonly io: RouterSource<{
    [REQUEST]: (params: RequestParams) => void;
    [RESPONSE]: (params: ResponseParams) => void;
    [NEW_WEB_SOCKET]: (params: NewWebSocketParams) => void;
    [RESPONSE_BODY]: (params: ResponseBodyParams) => void;
  }>;
}

export interface RequestParams {
  correlationId: CorrelationId;
  input: RequestInfo;
  init?: RequestInit;
}

export interface ResponseParams {
  correlationId: CorrelationId;
  response: Response;
}

export interface NewWebSocketParams {
  correlationId: CorrelationId;
  uri: string;
  protocols?: string | string[];
  socket: WebSocket;
}

export interface ResponseBodyParams {
  correlationId: CorrelationId;
  responseBody: string;
}

export const CORRELATION_ID = Symbol();
export type CorrelationId = Opaque<number, typeof CORRELATION_ID>;
