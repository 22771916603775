import {useStrings} from '@ncwallet-app/core';
import type {
  PromptOtpToUpdateIpRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {useHeaderHeight} from '@react-navigation/elements';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useUpdateIp} from '../../../CommonNavigationContainers/hooks/ipSettings/useUpdateIp';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {IpModal} from '../../../screens/UserSettingsScreens/IpListScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {createResetToIpListAction} from '../util/createResetToIpListAction';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptUpdateIpBindingBindingProps =
  SmallHomeStackBindingProps<'PromptUpdateIp'>;

export default observer(function PromptUpdateIpBinding(
  props: PromptUpdateIpBindingBindingProps,
) {
  const {navigation, route} = props;
  const strings = useStrings();
  const headerHeight = useHeaderHeight();

  const handleDelete = useCallback(() => {
    navigation.navigate('PromptToDeleteIp', {
      type: route.params.type,
      id: route.params.id,
      ip: route.params.ip,
    });
  }, [navigation, route.params]);

  const goToPromptOtp = useCallback(
    (params: RouteParams<PromptOtpToUpdateIpRoute>) => {
      navigation.navigate('PromptOtpToUpdateIp', params);
    },
    [navigation],
  );

  const toIpList = useCallback(() => {
    const navigateTo =
      route.params.type === IpType.Allowed ? 'ListAllowedIp' : 'ListBlockedIp';
    if (hasRouteInHistory(navigation.getState(), navigateTo)) {
      navigation.navigate(navigateTo);
    } else {
      navigation.dispatch(createResetToIpListAction(route.params.type));
    }
  }, [navigation, route.params.type]);

  const {updateIp, getErrorText, resetErrorText} = useUpdateIp(
    goToPromptOtp,
    toIpList,
  );

  const handleSubmit = useCallback(
    (ip: string, name: string) =>
      updateIp(route.params.id, route.params.type, ip, name),
    [route.params.id, route.params.type, updateIp],
  );

  return (
    <IpModal
      onSubmit={handleSubmit}
      onDeletePress={handleDelete}
      getError={getErrorText}
      resetError={resetErrorText}
      ipName={props.route.params.name}
      ip={props.route.params.ip}
      placeholder={
        route.params.type === IpType.Allowed
          ? strings['secureSettings.ipList.placeholderIpAllow']
          : strings['secureSettings.ipList.placeholderIpBlock']
      }
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      keyboardVerticalOffset={headerHeight}
      keyboardAvoiding
    />
  );
});
