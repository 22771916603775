import type {
  CryptoCurrency,
  CurrenciesRateStore,
  CurrencyStore,
  FiatCurrencyCode,
  OnTimeoutError,
  WalletStore,
} from '@ncwallet-app/core';
import {
  FULFILLED,
  TIMEOUT_ERROR,
  toCurrencyDescriptionFromFiat,
} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import type {FiatCurrency} from '@ncwallet-app/core/src/NCWalletServer/FiatCurrency';
import {isEmpty, isNil, partition} from 'lodash';
import {action, computed, makeObservable, observable} from 'mobx';

import type {CryptoSectionItemData} from '../../../screens/UserSettingsScreens/BaseCryptoSettingScreen/CryptoSectionItem';

// eslint-disable-next-line import-x/prefer-default-export
export class BaseCryptoSettingsBindingState implements OnTimeoutError {
  @observable filter = '';
  @observable _baseFiat: FiatCurrencyCode | undefined;

  @observable private _isTimeoutError = false;

  @computed get isTimeoutError() {
    return this._isTimeoutError;
  }

  @computed
  private get _baseCrypto() {
    const account =
      this._root.accountStore.state?.status === FULFILLED &&
      this._root.accountStore.state.result;

    if (!account) {
      return undefined;
    }

    return this._root.currencyStore.getCryptoCurrency(account.base_crypto);
  }

  @computed
  private get _otherCryptos() {
    const cryptos = this._root.currencyStore.getCryptoCurrencies();
    //const wallets = this._root.walletStore.getWallets();
    const base = this._baseCrypto;
    if (!cryptos || !base) {
      return undefined;
    }

    const unsorted = cryptos.filter(c => c.code !== base.code);

    // порядок сортировки - сначала идут те валюты, которые есть у пользователя,
    // затем по популярности
    const [owned, nowOwned] = partition(
      unsorted,
      c => !isEmpty(this._root.walletStore.getWalletsByCode(c.code)),
    );

    return [...owned, ...nowOwned];
  }

  @computed
  private get _isAllRatesLoaded() {
    const cryptos = this._root.currencyStore.getCryptoCurrencies();
    if (!cryptos || !this._baseFiat) {
      return false;
    }

    return cryptos.every(
      c =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        !isNil(this._root.currenciesRateStore.getRate(c.code, this._baseFiat!)),
    );
  }

  constructor(
    private readonly _root: {
      readonly accountStore: AccountStore;
      readonly currencyStore: CurrencyStore;
      readonly currenciesRateStore: CurrenciesRateStore;
      readonly walletStore: WalletStore;
    },
  ) {
    makeObservable(this);
  }

  @action.bound
  setFilter(filter: string) {
    this.filter = filter;
  }

  @action.bound
  async refresh(baseFiat: FiatCurrencyCode) {
    this._isTimeoutError = false;
    this._baseFiat = baseFiat;
    this._root.currencyStore.getFiatCurrency(baseFiat);
    const cryptosRes = await this._root.currencyStore.refreshCryptoCurrencies();
    if (!cryptosRes.success) {
      if (cryptosRes.left.kind === TIMEOUT_ERROR) {
        this._isTimeoutError = true;
      }
      return;
    }

    const rates = await this._root.currenciesRateStore.batchRefreshRates(
      cryptosRes.right.map(c => ({from: c.code, to: baseFiat})),
    );

    if (rates.some(it => !it.success && it.left.kind === TIMEOUT_ERROR)) {
      this._isTimeoutError = true;
      return;
    }
  }

  getBaseCrypto = (): CryptoSectionItemData | undefined => {
    const crypto = this._baseCrypto;
    const fiat =
      this._baseFiat &&
      this._root.currencyStore.getFiatCurrency(this._baseFiat);

    if (!fiat || !crypto || !this._isAllRatesLoaded) {
      return undefined;
    }

    return this.toItemData(crypto, fiat);
  };

  getOtherCryptos = (): CryptoSectionItemData[] | undefined => {
    const cryptos = this._otherCryptos;
    const fiat =
      this._baseFiat &&
      this._root.currencyStore.getFiatCurrency(this._baseFiat);
    const trimmedFilter = this.filter.trim().toLowerCase();

    if (!fiat || !cryptos || !this._isAllRatesLoaded) {
      return undefined;
    }

    const usedCryptos =
      trimmedFilter.length > 0
        ? cryptos.filter(filterPredicate(trimmedFilter))
        : cryptos;

    return usedCryptos.map(c => this.toItemData(c, fiat));
  };

  update = (itemData: CryptoSectionItemData) => {
    return this._root.accountStore.update({base_crypto: itemData.crypto.code});
  };

  private toItemData = (
    c: CryptoCurrency,
    fiat: FiatCurrency,
  ): CryptoSectionItemData => ({
    crypto: c,
    baseFiat: toCurrencyDescriptionFromFiat(fiat),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain
    value: this._root.currenciesRateStore.getRate(c.code, fiat.code)?.rate!,
  });
}

function filterPredicate(filter: string) {
  return (c: CryptoCurrency) => {
    return (
      c.code.toLowerCase().includes(filter) ||
      c.name.toLowerCase().includes(filter)
    );
  };
}
