import type {AvailableLanguageCode} from '@ncwallet-app/core/src/Localization/constant';
import {nanoid} from 'nanoid';
import {useMemo} from 'react';

import type {TipItemData} from './TipList';

type Tips = {
  [key in AvailableLanguageCode]?: TipItemData[];
};

const tipsList: Tips = {
  en: [
    {
      id: nanoid(),
      title: 'Protection of your savings',
      description:
        'NC Wallet is a wallet that goes beyond standard security and safety measures. Be assured that your funds are fully protected with a multi-factor transaction confirmation system.',
    },
    {
      id: nanoid(),
      title: 'No commissions and transaction fees',
      description:
        'Send, exchange, and withdraw crypto for free! NC Wallet is the first wallet that allows you to withdraw cryptocurrency without paying network fees.',
    },
    {
      id: nanoid(),
      title: 'No withdrawal minimum',
      description:
        'There are no transaction limits. Manage your cryptocurrency the way you want, enjoy a convenient system.',
    },
  ],
  de: [
    {
      id: nanoid(),
      title: 'Schutz Ihrer Ersparnisse',
      description:
        'NC Wallet ist eine Wallet, die über die Standard-Sicherheitsmaßnahmen hinausgeht. Sie können sicher sein, dass Ihre Gelder durch ein Multi-Faktor-Transaktionsbestätigungssystem vollständig geschützt sind.',
    },
    {
      id: nanoid(),
      title: 'Keine Provisionen und Transaktionsgebühren',
      description:
        'Senden, wechseln und zahlen Sie Kryptowährungen kostenlos aus! NC Wallet ist die erste Wallet, mit der Sie Kryptowährungen auszahlen können, ohne Netzwerkgebühren zahlen zu müssen.',
    },
    {
      id: nanoid(),
      title: 'Kein Abhebungsminimum',
      description:
        'ThEs gibt keine Transaktionslimits. Verwalten Sie Ihre Kryptowährungen so, wie Sie es wünschen, und genießen Sie ein bequemes System.',
    },
  ],
  fr: [
    {
      id: nanoid(),
      title: 'Protection de vos économies',
      description:
        "NC Wallet est un portefeuille qui va au-delà des mesures de sécurité et de sûreté standards. Vous avez l'assurance que vos fonds sont entièrement protégés grâce à un système de confirmation des transactions à plusieurs facteurs.",
    },
    {
      id: nanoid(),
      title: 'Aucune commission ni frais de transaction',
      description:
        "Envoyez, échangez et retirez gratuitement de la cryptomonnaie ! NC Wallet est le premier portefeuille qui vous permet d'en retirer sans payer de frais de réseau.",
    },
    {
      id: nanoid(),
      title: 'Aucun minimum de retrait',
      description:
        "Il n'y a aucune limite de transaction. Gérez vos crypto-monnaies comme bon vous semble et profitez d'un système facile d'utilisation.",
    },
  ],
  es: [
    {
      id: nanoid(),
      title: 'Protección para tus ahorros',
      description:
        'NC Wallet es un monedero que va más allá de las medidas de seguridad estándar. Ten la seguridad de que tus fondos estarán totalmente protegidos con un sistema de confirmación de transacciones de múltiples fases.',
    },
    {
      id: nanoid(),
      title: 'Sin comisiones ni gastos de transacción',
      description:
        'Envíe, intercambie y retire criptomonedas de forma gratuita. NC Wallet es el primer monedero que permite retirar criptodivisas sin tener que pagar comisiones en la red.',
    },
    {
      id: nanoid(),
      title: 'Sin mínimo de retirada',
      description:
        'No hay límites en las transacciones. Gestiona tus criptomonedas como quieras y disfruta de un práctico sistema.',
    },
  ],
  ru: [
    {
      id: nanoid(),
      title: 'Защита ваших сбережений',
      description:
        'NC Wallet — кошелек, который не ограничивается использованием стандартных мер защиты и безопасности. Будьте уверены в полной сохранности своих средств с многофакторной системой подтверждения транзакций.',
    },
    {
      id: nanoid(),
      title: 'Отсутствие комиссий',
      description:
        'Отправляйте, обменивайте и выводите средства без комиссий! NC Wallet — первый кошелек, позволяющий выводить криптовалюту без оплаты расходов сети.',
    },
    {
      id: nanoid(),
      title: 'Нет ограничений на вывод средств',
      description:
        'В NC Wallet отсутствуют минимальные лимиты на транзакции. Распоряжайтесь криптовалютой так, как удобно вам, и наслаждайтесь комфортным сервисом.',
    },
  ],
  it: [
    {
      id: nanoid(),
      title: 'Protezione dei tuoi risparmi',
      description:
        'NC Wallet è un portafoglio che va oltre le misure di sicurezza standard. Siate certi che i tuoi fondi sono completamente protetti con un sistema di verifica delle transazioni a più fattori.',
    },
    {
      id: nanoid(),
      title: 'Nessuna commissione e spese di transazione',
      description:
        'Invia, scambia e preleva criptovalute gratuitamente! NC Wallet è il primo wallet che ti consente di prelevare criptovaluta senza commissioni di rete.',
    },
    {
      id: nanoid(),
      title: 'Nessun prelievo minimo',
      description:
        'Non ci sono limiti alle transazioni. Gestisci la tua criptovaluta come vuoi, approfitta di un sistema conveniente.',
    },
  ],
  pt: [
    {
      id: nanoid(),
      title: 'Proteção para suas economias',
      description:
        'A NC Wallet é uma carteira que vai muito além das medidas usuais de segurança e proteção. Tenha a certeza de que seus fundos estão totalmente protegidos com um sistema multifatorial de confirmação de transações.',
    },
    {
      id: nanoid(),
      title: 'Sem comissões e taxas de transação',
      description:
        'Envie, negocie e saque de graça! A NC Wallet é a primeira carteira que permite que você saque criptomoedas sem pagar custos de rede.',
    },
    {
      id: nanoid(),
      title: 'Sem limite para saque',
      description:
        'Não há limites para transações. Gerencie suas criptomoedas como quiser e aproveite um sistema prático.',
    },
  ],
};

// eslint-disable-next-line import-x/prefer-default-export
export const useFetchWalletTips = (lang: AvailableLanguageCode) => {
  return useMemo(() => {
    const tips = tipsList[lang];
    if (!tips) {
      return [];
    }

    return tips;
  }, [lang]);
};
