import {useStrings} from '@ncwallet-app/core';
import dayjs from 'dayjs';
import {useCallback, useMemo} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useComputeEndPeriodLimitTime = () => {
  const strings = useStrings();
  const dChar = strings['limitsScreen.days'];
  const hChar = strings['limitsScreen.hours'];

  const computeDaily = useCallback(() => {
    const diff = dayjs().endOf('day').diff(dayjs(), 'minute'); //get diff between now and the end of the day
    const hourlyDiff = diff / 60;
    const hours = hourlyDiff < 1 ? 1 : Math.trunc(hourlyDiff);
    const lessThenChar = hours > 1 ? '' : '<';

    return `${lessThenChar}${hours}${hChar}`;
  }, [hChar]);

  const computeMonthly = useCallback(() => {
    const diff = dayjs().endOf('months').diff(dayjs(), 'minute');
    const dailyDiff = diff / (60 * 24);
    const days = dailyDiff < 1 ? 1 : Math.trunc(dailyDiff);
    const lessThenChar = days > 1 ? '' : '<';

    return `${lessThenChar}${days}${dChar}`;
  }, [dChar]);

  return useMemo(
    () => [computeDaily(), computeMonthly()],
    [computeDaily, computeMonthly],
  );
};
