import {useMemo} from 'react';
import {Platform} from 'react-native';

import {useRoot} from '../Root';
import {getOSKind, OSKind} from '../util/getOSKind';

// Share button should be available on Android and iOS app, in Telegram, but not in desktop and mobile browser
const useShareAvailable = () => {
  const {telegramMiniApp} = useRoot();

  return useMemo(() => {
    const kind = getOSKind();
    const isMobileApp =
      (kind === OSKind.Android || kind === OSKind.IOs) && Platform.OS !== 'web';

    return telegramMiniApp.isAvailable || isMobileApp;
  }, [telegramMiniApp]);
};

export default useShareAvailable;
