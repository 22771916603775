import {computed, makeObservable} from 'mobx';

import type {LayoutHelperState} from '../LayoutHelperState';
import type {NavigationContainer} from './NavigationContainer';
import type {NavigationContainerBinding} from './NavigationContainerBinding';

export default class JugglerNavigationContainer implements NavigationContainer {
  constructor(
    private readonly _root: {
      readonly layoutHelperState: LayoutHelperState;
      readonly largeNavigationContainerBinding: NavigationContainerBinding &
        NavigationContainer;
      readonly smallNavigationContainerBinding: NavigationContainerBinding &
        NavigationContainer;
    },
  ) {
    makeObservable(this);
  }

  @computed
  get isReady() {
    if (this._root.layoutHelperState.isLarge) {
      return this._root.largeNavigationContainerBinding.isReady;
    }
    return this._root.smallNavigationContainerBinding.isReady;
  }

  @computed
  get isConfigured() {
    if (this._root.layoutHelperState.isLarge) {
      return this._root.largeNavigationContainerBinding.isConfigured;
    }
    return this._root.smallNavigationContainerBinding.isConfigured;
  }

  get state() {
    if (this._root.layoutHelperState.isLarge) {
      return this._root.largeNavigationContainerBinding.state;
    }
    return this._root.smallNavigationContainerBinding.state;
  }

  get ref() {
    if (this._root.layoutHelperState.isLarge) {
      return this._root.largeNavigationContainerBinding.ref;
    }
    return this._root.smallNavigationContainerBinding.ref;
  }
}
