import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React from 'react';
import type {ViewProps} from 'react-native';
import {View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

type WalletActionListProps = ViewProps &
  PropsWithChildren<{
    contentContainerStyle?: ViewProps['style'];
  }>;

export default observer(function WalletActionList(
  props: WalletActionListProps,
) {
  const {children, contentContainerStyle, ...rest} = props;
  return (
    <View {...rest}>
      <ScrollView
        overScrollMode="never"
        contentContainerStyle={contentContainerStyle}>
        {children}
      </ScrollView>
    </View>
  );
});
