import {Platform} from 'react-native';
import {getBundleId} from 'react-native-device-info';

import type {Url} from '../units';
import {getOwnKeys, turnOut} from '../util';

export const MOBILE_PREFIX = (
  Platform.OS === 'android' || Platform.OS === 'ios'
    ? `${getBundleId()}://`
    : 'com.ncwallet://'
) as Url;

export const LOCAL_WEB_PREFIX = (
  Platform.OS === 'web' ? globalThis.location.origin : 'http://localhost:3000'
) as Url;

export const PRODUCTION_WEB_PREFIX = 'https://app.ncwallet.net' as Url;
export const DEVELOPMENT_WEB_PREFIX = 'https://ncwallet-app.dvlt.net' as Url;

export const CHROME_EXTENSION_ID = 'imlcamfeniaidioeflifonfjeeppblda';

export const PREFIXES = [
  MOBILE_PREFIX,
  LOCAL_WEB_PREFIX,
  PRODUCTION_WEB_PREFIX,
  DEVELOPMENT_WEB_PREFIX,
  `chrome-extension://${CHROME_EXTENSION_ID}` as Url,
] as const;

export const CRYPTO_PROTOCOL_TO_CODE_MAP = {
  bitcoin: 'BTC',
  ethereum: 'ETH',
  tether: 'USDT',
  matic: 'MATIC',
  ton: 'TON',
  tron: 'TRX',
} as const;

export type CryptoProtocol = keyof typeof CRYPTO_PROTOCOL_TO_CODE_MAP;

export const CRYPTO_CODE_TO_PROTOCOL_MAP = turnOut(CRYPTO_PROTOCOL_TO_CODE_MAP);

export const KNOWN_CRYPTO_PROTOCOLS = getOwnKeys(CRYPTO_PROTOCOL_TO_CODE_MAP);
