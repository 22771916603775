import type {Either} from '@ncwallet-app/core';
import {success, useRoot} from '@ncwallet-app/core';
import {useIdTokenAuthRequest} from 'expo-auth-session/providers/google';
import {useCallback, useEffect} from 'react';

import shouldUseNativeAuth from './shouldUseNativeAuth';
import useNativeGoogleAuth from './useNativeGoogleAuth';

export default function useGoogleAuth(
  onGoogleIdTokenReceived: (outcome: Either<string, unknown>) => void,
) {
  const {log, configuration, specialLocation} = useRoot();
  const values = configuration.values;
  const redirectUri = specialLocation.getOauthRedirect();
  const nativeGoogleAuth = useNativeGoogleAuth(onGoogleIdTokenReceived);
  const [, browserResponse, browserPromptAsync] = useIdTokenAuthRequest({
    iosClientId: values.googleMobileOauthClientId,
    androidClientId: values.googleMobileOauthClientId,
    webClientId: values.googleWebOauthClientId,
    redirectUri,
  });
  useEffect(() => {
    if (browserResponse?.type === 'success') {
      const {params} = browserResponse;
      // https://github.com/expo/expo/issues/12808
      if (params.id_token) {
        const responseRaw = JSON.stringify(browserResponse, null, 2);
        log.write({body: `Google -> ${responseRaw}`});
        onGoogleIdTokenReceived(success(params.id_token));
      }
    }
  }, [browserResponse, log, onGoogleIdTokenReceived]);

  return useCallback(async () => {
    if (await shouldUseNativeAuth()) {
      nativeGoogleAuth();
    } else {
      await browserPromptAsync();
    }
  }, [browserPromptAsync, nativeGoogleAuth]);
}
