import {useStyles} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text as RNText, View} from 'react-native';
import type {SvgProps} from 'react-native-svg';

import type {PressableProps} from './Pressable';
import {PressableNativeFeedback} from './PressableNativeFeedback';

export type SimpleButtonProps = Omit<PressableProps, 'children'> & {
  title: string;
  Icon?: React.ComponentType<SvgProps>;
  disabled?: boolean;
};

export const SimpleButton = observer(function SimpleButton(
  props: SimpleButtonProps,
) {
  const {title, disabled = false, Icon, ...rest} = props;
  const styles = useStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background,
      paddingVertical: 15,
      paddingHorizontal: 40,
    },
    text: {
      ...theme.fontByWeight('600'),
      textAlign: 'center',
      color: theme.palette.textMain,
    },
    textView: {
      alignItems: 'center',
      paddingHorizontal: 10,
      textAlign: 'center',
    },
    iconView: {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: 50,
      justifyContent: 'center',
      alignItems: 'center',
    },
    border: {
      borderWidth: 1,
      borderColor: theme.palette.uiSecondary,
      borderRadius: 6,
    },
  }));
  return (
    <PressableNativeFeedback
      disabled={disabled}
      borderStyle={styles.border}
      containerStyle={styles.root}
      {...rest}>
      {Icon && (
        <View style={styles.iconView}>
          <Icon />
        </View>
      )}
      <View style={styles.textView}>
        <RNText style={styles.text}>{title}</RNText>
      </View>
    </PressableNativeFeedback>
  );
});
