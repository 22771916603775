import {action, makeObservable, observable, reaction, runInAction} from 'mobx';

import {unwrap} from '../EitherAdapter';
import type {ConnectionState, JsonRpcClient} from '../JsonRpc';
import {ConnectionStatus} from '../JsonRpc';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {ConfigExchangeInfo} from '../NCWalletServer/ConfigExchange';
import type {Resettable} from '../OnLogoutResetService';
import type {Service} from '../structure';
import type {ConfigExchange} from './ConfigExchange';

export default class ConfigExchangeService
  implements ConfigExchange, Service, Resettable
{
  @observable.ref private _exchangeConfig: ConfigExchangeInfo | undefined;

  constructor(
    private readonly _root: {
      readonly connectionState: ConnectionState;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {
    makeObservable(this);
  }

  get exchangeConfig() {
    return this._exchangeConfig;
  }

  subscribe() {
    return reaction(
      () => this._root.connectionState.latestStatus === ConnectionStatus.Open,
      async isConnected => {
        if (isConnected) {
          await this._load();
        }
      },
    );
  }

  private async _load() {
    const result = await unwrap(
      this._root.ncWalletJsonRpcClient.call('config.exchange', {}),
    );
    runInAction(() => {
      this._exchangeConfig = result;
    });
  }

  @action.bound
  reset() {
    this._exchangeConfig = undefined;
  }
}
