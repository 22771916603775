import type GorhomBottomSheet from '@gorhom/bottom-sheet';
import {useStrings} from '@ncwallet-app/core';
import {BottomSheet, useIsDimensions} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {ShowQrModalScreen} from './ShowQrModalScreen';
import type {ShowQrScreenBodyProps} from './ShowQrScreenBody';
import ShowQrScreenBody from './ShowQrScreenBody';

export type ShowQrScreenProps = ShowQrScreenBodyProps & {
  onClosePress: () => void;
};

const backdropProps = {appearsOnIndex: 0, disappearsOnIndex: -1};

export default observer<Omit<ShowQrScreenProps, 'ref'>, GorhomBottomSheet>(
  React.forwardRef(function ShowQrScreen(props, refBottom) {
    const {onClosePress, ...rest} = props;
    const strings = useStrings();
    const onChange = useCallback(
      (index: number) => {
        if (index === -1) {
          onClosePress();
        }
      },
      [onClosePress],
    );

    const isLg = useIsDimensions('lg');

    if (isLg) {
      return (
        <ShowQrModalScreen
          title={strings['showQr.screenTitle']}
          onClose={onClosePress}>
          <ShowQrScreenBody {...rest} />
        </ShowQrModalScreen>
      );
    }

    return (
      <BottomSheet
        ref={refBottom}
        index={0}
        animateOnMount
        onClose={onClosePress}
        onChange={onChange}
        enablePanDownToClose
        backdropProps={backdropProps}
        snapPoints={[470]}
        title={strings['showQr.screenTitle']}
        children={<ShowQrScreenBody {...rest} />}
      />
    );
  }),
);
