import {useRoot} from '@ncwallet-app/core';
import type {ThemeKind} from '@ncwallet-app/core/src/Appearance';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ThemeSelectScreen from '../../../screens/ThemeSelectScreen/ThemeSelectScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptThemeBindingProps =
  SmallHomeStackBindingProps<'PromptThemeSelect'>;

export default observer(function PromptThemeBinding(
  props: PromptThemeBindingProps,
) {
  const {navigation} = props;
  const {appearance} = useRoot();

  const handleClose = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({
      index: 0,
      routes: [{name: 'ListMenuCategories'}],
    });
  }, [navigation]);

  const handleSetTheme = useCallback(
    (theme: ThemeKind) => {
      void appearance.setThemeMode(theme);
      handleClose();
    },
    [appearance, handleClose],
  );

  return (
    <ThemeSelectScreen
      onClose={handleClose}
      onChangeTheme={handleSetTheme}
      currentTheme={appearance.preferredThemeKind}
    />
  );
});
