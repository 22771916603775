import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {Pressable} from '@ncwallet-app/ui';
import {ArrowDownWideSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import formatCurrencyShortDescription from '@ncwallet-app/ui/src/util/formatCurrencyShortDescription';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text} from 'react-native';

export type LabelWithNetworkProps = {
  networkChangeEnabled: boolean;
  addressNameShown: boolean;
  addressName?: string;
  onAddressNamePress: () => void;
  contractType: string | null | undefined;
};

export default observer(function LabelWithNetwork(
  props: LabelWithNetworkProps,
) {
  const strings = useStrings();
  const theme = useTheme();
  return (
    <Label
      disabled={!props.networkChangeEnabled}
      onPress={props.onAddressNamePress}>
      <Network simple>
        {strings['sendCrypto.addressForm.labelWithNetwork']}
      </Network>
      {props.addressNameShown && (
        <Network simple={!props.networkChangeEnabled}>
          {formatCurrencyShortDescription(
            props.addressName,
            props.contractType,
          )}
        </Network>
      )}
      {props.networkChangeEnabled && props.addressNameShown && (
        <ArrowDownWideSvg
          width={14}
          height={14}
          preserveAspectRatio="none"
          color={theme.palette.uiAdditional1}
        />
      )}
    </Label>
  );
});

const Label = variance(Pressable)(() => ({
  root: {
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Network = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.info,
    marginRight: 5,
  },
  simple: {
    color: theme.palette.textPrimary,
  },
}));
