import {useRoot, useStrings, useTheme, variance} from '@ncwallet-app/core';
import {
  AffiliateUserSVG,
  BellSvg,
  CircleHalfSvg,
  HelpSvg,
  LimitsSvg,
  SecureSvg,
  UserSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import type {SvgProps} from 'react-native-svg';

import {LANGUAGES} from '../../../../../components';
import SettingButtonBase from './SettingButtonBase';
import SettingsMenuItem from './SettingsMenuItem';

export type SettingsBarProps = {
  isLanguageMenuVisible: boolean;
  isThemeMenuVisible: boolean;
  onNotificationSettings: () => void;
  onProfileSettings: () => void;
  onHelpPress: () => void;
  onSecurityPress: () => void;
  onLimitsPress: () => void;
  onLanguageMenuPress: () => void;
  onThemeMenuPress: () => void;
  onMenuClose: () => void;
  onAffiliatePress: () => void;
  isAffiliateFocused: boolean;
  isProfileFocused: boolean;
  isSecurityFocused: boolean;
  areLimitsFocused: boolean;
  areNotificationsFocused: boolean;
};

export default observer(function SettingsBar(props: SettingsBarProps) {
  const {
    isProfileFocused,
    isSecurityFocused,
    isAffiliateFocused,
    areLimitsFocused,
    areNotificationsFocused,
  } = props;
  const theme = useTheme();
  const strings = useStrings();
  const {userPreferenceState} = useRoot();

  const LanguageIcon = LANGUAGES[userPreferenceState.languageCode].Icon;

  return (
    <Container>
      <Menu>
        <SettingsMenuItem
          icon={<MenuItemIcon Icon={UserSvg} active={isProfileFocused} />}
          title={strings['userSettings.profile']}
          onPress={props.onProfileSettings}
          active={isProfileFocused}
          testID="menu-profile"
        />
        <SettingsMenuItem
          icon={<MenuItemIcon Icon={SecureSvg} active={isSecurityFocused} />}
          title={strings['userSettings.secure']}
          onPress={props.onSecurityPress}
          active={isSecurityFocused}
          testID="menu-secure"
        />
        <SettingsMenuItem
          icon={<MenuItemIcon Icon={LimitsSvg} active={areLimitsFocused} />}
          title={strings['userSettings.limits']}
          onPress={props.onLimitsPress}
          active={areLimitsFocused}
          testID="menu-limits"
        />
        <SettingsMenuItem
          icon={
            <MenuItemIcon Icon={BellSvg} active={areNotificationsFocused} />
          }
          title={strings['userSettings.notifications']}
          onPress={props.onNotificationSettings}
          active={areNotificationsFocused}
          testID="menu-notifications"
        />
        <SettingsMenuItem
          icon={
            <MenuItemIcon Icon={AffiliateUserSVG} active={isAffiliateFocused} />
          }
          title={strings['userSettings.affiliate.short']}
          onPress={props.onAffiliatePress}
          active={isAffiliateFocused}
          testID="menu-affiliate"
        />
        <SettingsMenuItem
          icon={<MenuItemIcon Icon={HelpSvg} />}
          title={strings['userSettings.help']}
          onPress={props.onHelpPress}
          active={false}
          testID="menu-help"
        />
      </Menu>
      <SettingsButtons>
        <SettingButton onPress={props.onLanguageMenuPress}>
          <LanguageIcon width={33} height={20} />
        </SettingButton>
        <SettingButton onPress={props.onThemeMenuPress}>
          <CircleHalfSvg color={theme.palette.uiMain} />
        </SettingButton>
      </SettingsButtons>
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    position: 'relative',
    borderRadius: 10,
    maxWidth: 1180,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background,
    ...theme.bar(10),
  },
}));

const Menu = variance(View)(() => ({
  root: {
    position: 'relative',
    flexDirection: 'row',
    overflow: 'hidden',
  },
}));

const SettingsButtons = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const SettingButton = variance(SettingButtonBase)(() => ({
  root: {
    marginLeft: 15,
  },
}));

type MenuItemIconProps = {
  active?: boolean;
  Icon: React.ComponentType<SvgProps>;
};

const MenuItemIcon = observer((props: MenuItemIconProps) => {
  const {active, Icon} = props;
  const theme = useTheme();
  return <Icon color={active ? theme.palette.primary : theme.palette.uiMain} />;
});
