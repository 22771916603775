import {useRoot, useStrings} from '@ncwallet-app/core';
import type {
  PromptOtpToAddIpRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {Ip} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import type {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {isEmpty} from 'lodash';
import {useCallback, useState} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useAddIp = (
  goToPromptOtp: (params: RouteParams<PromptOtpToAddIpRoute>) => void,
  goToIpList: (type: IpType) => void,
) => {
  const strings = useStrings();
  const root = useRoot();
  const [errorText, setErrorText] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addIp = useCallback(
    async (ip: string, name: string, type: IpType) => {
      if (isEmpty(ip) || isEmpty(name)) {
        setErrorText(strings['createIp.addIpErrorOnIpOrName']);
        return;
      }

      if (isSubmitting) {
        return;
      }

      setIsSubmitting(true);
      const isTwoFaRequiredRes =
        await root.twoFaHelper.isTwoFaRequiredForIpSettings();
      if (!isTwoFaRequiredRes.success) {
        setIsSubmitting(false);
        return;
      }

      if (isTwoFaRequiredRes.right) {
        setIsSubmitting(false);
        goToPromptOtp({
          ip: ip as Ip,
          name,
          type,
        });
        return;
      }

      const res = await root.ncWalletJsonRpcClient.call(
        'accounts.ip_settings.add',
        {ip, name, type},
      );
      setIsSubmitting(false);

      if (res.success) {
        setErrorText(undefined);
        goToIpList(type);
      } else {
        setErrorText(root.errorParser.describe(res.left).summary);
      }
    },
    [
      goToIpList,
      goToPromptOtp,
      isSubmitting,
      root.errorParser,
      root.ncWalletJsonRpcClient,
      root.twoFaHelper,
      strings,
    ],
  );

  const resetErrorText = useCallback(() => {
    setErrorText('');
  }, []);
  const getErrorText = useCallback(() => errorText, [errorText]);

  return {
    addIp,
    getErrorText,
    resetErrorText,
  };
};
