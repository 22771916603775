import {Platform} from 'react-native';
import {getInstallReferrer} from 'react-native-device-info';

import {unwrap} from '../EitherAdapter';
import type {JsonKeyValueMap, JsonKeyValueStore} from '../JsonKeyValueStore';
import type {LocationSource} from '../Location';
import type {Service} from '../structure';
import type {Url} from '../units';
import oneTaskAtATime from '../util/oneTaskAtATime';
import type {InstallReferrerIdentification} from './InstallReferrerIdentification';
import {EXTERNAL_REF} from './parseInstallReferrer';

export default class InstallReferrerIdentificationService
  implements InstallReferrerIdentification, Service
{
  private _cache?: {installReferrer?: string};

  constructor(
    private readonly _root: {
      readonly locationSource: LocationSource;
      readonly jsonKeyValueStore: JsonKeyValueStore<JsonKeyValueMap>;
    },
    private _parseInstallReferrer: (
      _: Url,
    ) => string | typeof EXTERNAL_REF | undefined,
  ) {}

  async getInstallReferrer() {
    return InstallReferrerIdentificationService._getInstallReferrer.call(this);
  }

  private static _getInstallReferrer = oneTaskAtATime(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    InstallReferrerIdentificationService.prototype._getInstallReferrer,
  );

  private async _getInstallReferrer() {
    if (this._cache) {
      return this._cache.installReferrer;
    }
    const record = await unwrap(
      this._root.jsonKeyValueStore.get('INSTALL_IDENTIFICATION'),
    );
    const {installReferrer} = record ?? {};
    this._cache = {installReferrer};
    return installReferrer;
  }

  subscribe() {
    this._handleInitialSources().catch(() => {});
    return this._handleIncomingLink();
  }

  private async _handleInitialSources() {
    const link = await unwrap(this._root.locationSource.getInitial());
    await this._handleLink(link);
  }

  private _handleIncomingLink() {
    return this._root.locationSource.updates.listen(this._handleLink);
  }

  private readonly _handleLink = async (_: Url) => {
    const referrer = this._parseInstallReferrer(_);
    if (typeof referrer === 'string') {
      await this._handleInstallReferrer(referrer);
    } else if (Platform.OS === 'android' && referrer === EXTERNAL_REF) {
      const referrerFromGooglePlay = await getInstallReferrer();
      if (
        referrerFromGooglePlay !== '' &&
        referrerFromGooglePlay !== 'unknown'
      ) {
        await this._handleInstallReferrer(referrerFromGooglePlay);
      }
    }
  };

  private async _handleInstallReferrer(_: string) {
    await unwrap(
      this._root.jsonKeyValueStore.set('INSTALL_IDENTIFICATION', {
        installReferrer: _,
      }),
    );
    this._cache = {installReferrer: _};
  }
}
