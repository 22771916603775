import {variance} from '@ncwallet-app/core';
import {Pressable, SafeAreaInset, SafeAreaLayout} from '@ncwallet-app/ui';
import {TakePhotoSVG} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export type CameraFooterProps = {
  onTakePhoto: () => void;
  disabled?: boolean;
};

export default observer(function CameraFooter(props: CameraFooterProps) {
  const {onTakePhoto, disabled} = props;
  return (
    <ContainerView insets={SafeAreaInset.BOTTOM}>
      <ContentView>
        <Pressable disabled={disabled} hitSlop={20} onPress={onTakePhoto}>
          <TakePhotoSVG />
        </Pressable>
      </ContentView>
    </ContainerView>
  );
});

const ContainerView = variance(SafeAreaLayout)(() => ({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
}));

const ContentView = variance(View)(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 10,
  },
}));
