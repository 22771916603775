import type {SimpleRoute} from '../CommonNavigationScheme';
import type {GlobalError} from '../Error';

export interface AuthNavigationHelper {
  readonly route: AuthRoute | undefined;
}

export const SHOW_CRITICAL_ERROR = Symbol();
export type ShowCriticalErrorRoute = CommonErrorRoute<
  typeof SHOW_CRITICAL_ERROR
>;

export const PRE_CLOSE_MESSAGE = Symbol();
export type PreCloseMessageRoute = SimpleRoute<typeof PRE_CLOSE_MESSAGE>;

export const SHOW_PENDING_AUTH = Symbol();
export type ShowPendingAuthRoute = SimpleRoute<typeof SHOW_PENDING_AUTH>;

export const ASK_PIN = Symbol();
export type AskPinRoute = SimpleRoute<typeof ASK_PIN>;

export const TOKEN_EXPIRED_ERROR = Symbol();
export type TokenExpiredErrorRoute = SimpleRoute<typeof TOKEN_EXPIRED_ERROR>;

export const SHOW_AUTH_ERROR = Symbol();
export type ShowAuthErrorRoute = CommonErrorRoute<typeof SHOW_AUTH_ERROR>;

export const WELCOME = Symbol();
export type WelcomeRoute = SimpleRoute<typeof WELCOME>;

export const ASK_OTP = Symbol();
export type AskOtpRoute = SimpleRoute<typeof ASK_OTP>;

export const CONGRATULATE = Symbol();
export type CongratulateRoute = SimpleRoute<typeof CONGRATULATE>;

export const ASK_NEW_PIN = Symbol();
export type AskNewPinRoute = SimpleRoute<typeof ASK_NEW_PIN>;

export const SHOW_PENDING_CONNECTION = Symbol();
export type ShowPendingConnectionRoute = SimpleRoute<
  typeof SHOW_PENDING_CONNECTION
>;

export const SHOW_CONNECTION_ERROR = Symbol();
export type ShowConnectionErrorRoute = CommonErrorRoute<
  typeof SHOW_CONNECTION_ERROR
>;

export const SHOW_ACCOUNT_ERROR = Symbol();
export type ShowAccountErrorRoute = CommonErrorRoute<typeof SHOW_ACCOUNT_ERROR>;

export type CommonErrorRoute<T> = {
  kind: T;
  params: CommonErrorRouteParams;
};

export type CommonErrorRouteParams = {
  /**
   * This property is not JSON-serializable,
   * thus cannot be used in a navigator bound to URLs
   */
  error: GlobalError;
};

export type AuthRoute =
  | ShowCriticalErrorRoute
  | ShowPendingAuthRoute
  | AskPinRoute
  | TokenExpiredErrorRoute
  | ShowAuthErrorRoute
  | WelcomeRoute
  | AskOtpRoute
  | CongratulateRoute
  | AskNewPinRoute
  | ShowPendingConnectionRoute
  | ShowConnectionErrorRoute
  | ShowAccountErrorRoute
  | PreCloseMessageRoute;
