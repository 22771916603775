import type {FiatCurrencyCodeNumberMap} from '@ncwallet-app/core';
import {useStrings} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FlatList} from 'react-native-gesture-handler';

import {SettingsGroupTitle} from '../../SharedComponents/SettingsGroupTitle';
import type {CryptoSectionItemData} from '../CryptoSectionItem';
import LgCryptoItem from './LgCryptoItem';
import useLgCryptoSectionListStyle, {
  EmptyListTitle,
} from './useLgCryptoSectionListStyle';

export type LgCryptoSectionListProps = {
  onCryptoSelect: (crypto: CryptoSectionItemData) => void;
  baseCurrency: CryptoSectionItemData | undefined;
  othersCurrency: CryptoSectionItemData[] | undefined;
  baseFiatCode?: keyof FiatCurrencyCodeNumberMap;
};

export default observer(function LgCryptoSectionList({
  onCryptoSelect,
  baseCurrency,
  othersCurrency,
  baseFiatCode,
}: LgCryptoSectionListProps) {
  const strings = useStrings();

  const styles = useLgCryptoSectionListStyle();

  if (baseCurrency === undefined || othersCurrency === undefined) {
    return null;
  }

  return (
    <>
      <SettingsGroupTitle title={strings['updateBaseCryptScreen.selected']} />
      <LgCryptoItem
        title={baseCurrency.crypto.name}
        cryptoCode={baseCurrency.crypto.code}
        value={baseCurrency.value}
        baseFiatCode={baseFiatCode}
        base
      />
      <SettingsGroupTitle
        title={strings['updateBaseCryptScreen.available']}
        first={false}
      />
      {othersCurrency.length > 0 ? (
        <FlatList
          overScrollMode="never"
          data={othersCurrency}
          style={styles.list}
          contentContainerStyle={styles.root}
          renderItem={({item}) => (
            <LgCryptoItem
              onPress={() => {
                onCryptoSelect(item);
              }}
              title={item.crypto.name}
              cryptoCode={item.crypto.code}
              baseFiatCode={baseFiatCode}
              value={item.value}
            />
          )}
          keyExtractor={(item, index) => `${index} + ${item.crypto.code}`}
        />
      ) : (
        <EmptyListTitle>{strings['sectionList.empty']}</EmptyListTitle>
      )}
    </>
  );
});
