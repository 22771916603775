import type {WalletId, WalletLimit} from '@ncwallet-app/core';
import {useRoot, WalletLimitPeriod} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, runInAction} from 'mobx';
import {useCallback, useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../../Navigation/hooks';

// eslint-disable-next-line import-x/prefer-default-export
export const useGetWalletLimits = (walletId: WalletId) => {
  const [dailyLimit, setDailyLimit] = useState<WalletLimit>();
  const [monthlyLimit, setMonthlyLimit] = useState<WalletLimit>();
  const {ncWalletJsonRpcClient} = useRoot();
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  const getWalletLimits = useCallback(async () => {
    const res = await ncWalletJsonRpcClient.call('wallets.limits', {});
    if (!res.success) {
      return;
    }

    const dayLimit = res.right.limits.find(
      l =>
        l.wallet_id === walletId &&
        !!l.activated_at &&
        l.period === WalletLimitPeriod.Daily,
    );
    const monthLimit = res.right.limits.find(
      l =>
        l.wallet_id === walletId &&
        !!l.activated_at &&
        l.period === WalletLimitPeriod.Monthly,
    );

    setDailyLimit(dayLimit);
    setMonthlyLimit(monthLimit);
  }, [walletId, ncWalletJsonRpcClient]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void getWalletLimits();
          });
        }
      }),
    [getIsFocused, getIsReady, getWalletLimits],
  );

  return {dailyLimit, monthlyLimit};
};
