import type {CurrencyDescription, DecimalString} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {SafeAreaScrollView, WalletHeader} from '@ncwallet-app/ui';
import {ArrowDownWideSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useRef, useState} from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {Platform, StyleSheet} from 'react-native';
import type {ScrollView} from 'react-native-gesture-handler';

import {AddressTabView} from './AddressTabView';
import {SendTabKey} from './SendTabKey';
import {UserTabView} from './UserTabView';

export type SendScreenProps = BaseSafeAreaProps & {
  addressNameShown: boolean;
  addressName: string | undefined;
  networkChangeEnabled: boolean;
  onAddressNamePress: () => void;
  cryptoCurrency: CurrencyDescription | undefined;
  walletTotal: DecimalString | undefined;
  fiatCurrency: CurrencyDescription | undefined;
  fiatValue: DecimalString | undefined;
  walletName: string;
  userTo: string;
  addressTo: string;
  addressToError?: string;
  stepCurrent: number;
  stepTotal: number;
  networkCurrency: string | undefined;
  onAddressToChange: (addressTo: string) => void;
  isEditable?: boolean;
  onUserActionPress: () => void;
  showAddressAction: boolean;
  onAddressActionPress: () => void;
  onUserToChange: (userTo: string) => void;
  onSubmit: () => void;
  onBlurAddressInput: () => void;
  contentContainerStyle?: StyleProp<ViewStyle>;
  contractType: string | undefined | null;
  isBlockchainComment: boolean;
  goToFAQ: () => void;
};

type SmSendScreenProps = SendScreenProps & {
  onCurrencyPress: () => void;
  // Amount
  amountError?: string;
  amount: DecimalString | undefined;
  maxError?: string;
  maxAmount: DecimalString | undefined;
  maxAmountRestrictedByWalletLimit: boolean;
  onAmountChange: (amount: DecimalString | undefined) => void;
  comment?: string;
  onCommentChange: (comment: string) => void;
  /// Comission
  getHasNoCommission: () => boolean;
  setHasNoCommission: (c: boolean) => void;
  commissionSwitcherHidden: boolean;
  isEnoughAmountForFreeSend: boolean;
  fees?: string[] | undefined;
  recommendedFee?: string | undefined;
  fee?: string;
  setFee: (value: string) => void;
  feeError?: string;
  minFreeAmount: string | undefined;
  disabled?: boolean;
};

export const SendScreen = observer((props: SmSendScreenProps) => {
  const {
    onCurrencyPress,
    cryptoCurrency,
    onSubmit,
    isEditable,
    walletTotal,
    fiatCurrency,
    stepCurrent,
    stepTotal,
    fiatValue,
    walletName,
    userTo,
    onUserActionPress,
    onUserToChange,
    addressToError,
    goToFAQ,
    ...rest
  } = props;
  const [activeTab] = useState<SendTabKey>(SendTabKey.Address);
  const ref = useRef<ScrollView>(null);

  return (
    <SafeAreaScrollView style={styles.container} ref={ref} {...rest}>
      {/* этот функционал потребуется позже */}
      {/* <SendTabs activeTabKey={activeTab} onTabPress={setActiveTab} /> */}
      <WalletHeader
        onCurrencyPress={onCurrencyPress}
        cryptoValue={walletTotal}
        cryptoCurrency={cryptoCurrency}
        fiatCurrency={fiatCurrency}
        fiatValue={fiatValue}
        walletName={walletName}
        Icon={ArrowDownWideSvg}
      />
      {activeTab === SendTabKey.Address && (
        <AddressTabView
          cryptoCurrency={cryptoCurrency}
          onSubmit={onSubmit}
          isEditable={isEditable}
          addressToError={addressToError}
          goToFAQ={goToFAQ}
          {...rest}
        />
      )}
      {activeTab === SendTabKey.Username && (
        <UserTabView
          cryptoCurrency={cryptoCurrency}
          userTo={userTo}
          onUserToChange={onUserToChange}
          error={addressToError}
          isEditable={isEditable}
          stepCurrent={stepCurrent}
          stepTotal={stepTotal}
          onUserActionPress={onUserActionPress}
          onSubmit={onSubmit}
        />
      )}
    </SafeAreaScrollView>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...Platform.select({
      web: {
        paddingBottom: 15,
      },
    }),
  },
});
