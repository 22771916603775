import type {PinCode} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {View} from 'react-native';

import {useGlobalWebNumberInput} from '../../Navigation/hooks';
import {CreatePinScreenStateImpl} from '../CreatePinScreen/CreatePinScreen';
import {FULL_PIN_LENGTH} from '../PinScreen/constants';
import {PinForm} from '../PinScreen/PinForm';
import PinKeyboard from '../PinScreen/PinKeyboard';
import type {PinScreenTexts} from '../PinScreen/PinScreenTexts';

export type UpdatePinCardProps = {
  onSuccess: (pin: PinCode) => void;
  screenTexts: {
    create: PinScreenTexts;
    confirm: PinScreenTexts;
  };
  smallText?: boolean;
};

export default observer(function UpdatePinCard(props: UpdatePinCardProps) {
  const [state] = useState(() => new CreatePinScreenStateImpl(props));
  const getPin = state.getPin;
  const setPin = state.setPin;
  state.setProps(props);
  const getFilledLength = useCallback(() => getPin().length, [getPin]);
  const getErrorShown = useCallback(() => state.errorShown, [state]);
  const [currentKey, setCurrentKey] = useState({value: 0});

  const handleNumberPress = useCallback(
    (n: number) => {
      const pin = getPin();
      if (pin.length === FULL_PIN_LENGTH) {
        return;
      }
      setPin(pin.length < FULL_PIN_LENGTH ? `${pin}${n}` : pin);
      setCurrentKey(prev => ({...prev, value: n}));
    },
    [getPin, setPin],
  );
  const handleBackspacePress = useCallback(() => {
    const pin = getPin();
    setPin(pin.substring(0, pin.length - 1));
    setCurrentKey(prev => ({...prev, value: -1}));
  }, [getPin, setPin]);
  const text = state.isConfirming
    ? props.screenTexts.confirm
    : props.screenTexts.create;

  useGlobalWebNumberInput(handleNumberPress, handleBackspacePress);

  return (
    <PinContainer>
      <PinForm
        title={text.title}
        getFilledLength={getFilledLength}
        fullPinLength={FULL_PIN_LENGTH}
        hasError={getErrorShown()}
        errorText={text.errorText}
        errorAction={text.errorActionText}
        smallText={props.smallText}
      />
      <PinKeyboard
        currentKey={currentKey}
        onNumberPress={handleNumberPress}
        onBackspacePress={handleBackspacePress}
      />
    </PinContainer>
  );
});

const PinContainer = variance(View)(() => ({
  root: {
    alignItems: 'center',
    flex: 1,
  },
}));
