import {useStrings, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui/src';
import {SafeAreaScrollView} from '@ncwallet-app/ui/src';
import {useHeaderHeight} from '@react-navigation/elements';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import type {PersonalFormStateProps} from '../../shared/AccountDataForm/PersonalFormState/usePersonalFormState';
import PersonalInfoForm from './PersonalForm';

export type IdentityVerificationPersonalInfoScreenProps = BaseSafeAreaProps &
  PersonalFormStateProps;

export default observer(function IdentityVerificationPersonalInfoScreen(
  props: IdentityVerificationPersonalInfoScreenProps,
) {
  const {insets, extra, value, onSubmit} = props;
  const header = useHeaderHeight();
  const strings = useStrings();

  return (
    <SafeAreaScrollView
      insets={insets}
      extra={extra}
      keyboardVerticalOffset={header}
      keyboardAvoiding>
      <PersonalInfoTopView>
        <PersonalInfoTopText>
          {strings['profileSettingsScreen.verificationInfoText']}
        </PersonalInfoTopText>
      </PersonalInfoTopView>
      <PersonalInfoFormView>
        <PersonalInfoForm onSubmit={onSubmit} value={value} />
      </PersonalInfoFormView>
    </SafeAreaScrollView>
  );
});

const PersonalInfoTopView = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
}));

const PersonalInfoTopText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 14,
  },
}));

const PersonalInfoFormView = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    flex: 1,
  },
}));
