import {useStrings} from '@ncwallet-app/core';
import type {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {LgProfileCardContainer} from '../../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import TwoFaCardSetCode from '../TwoFaCardSetCode';

export type PromptOtpToChangeTwoFaSettingsCardProps = {
  getError: () => string | undefined;
  resetError: () => void;
  onBack: () => void;
  onSubmit: (code: string) => void;
  onTwoFaResendCode: () => Promise<void>;
  currentTwoFaProvider: TwoFaProviderKind | undefined;
  resendCodeInterval: number;
  resetInterval: () => void;
};

export default observer(function PromptOtpToChangeTwoFaSettingsCard(
  props: PromptOtpToChangeTwoFaSettingsCardProps,
) {
  const strings = useStrings();

  return (
    <LgProfileCardContainer>
      <TwoFaCardSetCode
        currentTwoFaProvider={props.currentTwoFaProvider}
        onTwoFaResendCode={props.onTwoFaResendCode}
        headerText={strings['screenTitle.promptOtpToChange2FASettings']}
        resetError={props.resetError}
        titleText={strings['promptOtpToChange2FASettings.title']}
        onBack={props.onBack}
        getError={props.getError}
        submitText={strings['promptOtpToChange2FASettings.submit']}
        resendCodeInterval={props.resendCodeInterval}
        resetInterval={props.resetInterval}
        onSubmit={props.onSubmit}
      />
    </LgProfileCardContainer>
  );
});
