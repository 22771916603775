import {variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import type {BaseCardProps} from './LgProfileCards/BaseCard';
import BaseCard from './LgProfileCards/BaseCard';
import type {DetailsCardProps} from './LgProfileCards/DetailsCard';
import DetailsCard from './LgProfileCards/DetailsCard';
import type {VerificationCardProps} from './LgProfileCards/VerificationCard';
import VerificationCard from './LgProfileCards/VerificationCard';

export type LgProfileSettingsProps = DetailsCardProps &
  VerificationCardProps &
  BaseCardProps;

export default observer(function LgProfileSettings(
  props: LgProfileSettingsProps,
) {
  return (
    <Container>
      <DetailsCard {...props} />
      <VerificationCard {...props} />
      <BaseCard {...props} />
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    overflow: 'visible',
    paddingBottom: 30,
    paddingTop: 0,

    ...theme.mediaQuery({
      [1220]: {
        paddingHorizontal: 30,
      },

      [LG_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
}));
