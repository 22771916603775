import type {CurrencyDescription} from '@ncwallet-app/core';
import {
  currencyGraphFormatter,
  RateHistoryPeriod,
  useDateFormatting,
  useStrings,
  variance,
} from '@ncwallet-app/core';
import dayjs from 'dayjs';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {Platform, StyleSheet, Text} from 'react-native';
import type {SharedValue} from 'react-native-reanimated';
import Animated, {
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useDerivedValue,
  withSpring,
} from 'react-native-reanimated';
import type {Vector} from 'react-native-redash';

import {CHART_HEIGHT} from './constants';

export type TooltipProps = {
  cursorTranslation: Vector<Animated.SharedValue<number>>;
  isActiveCursor: SharedValue<boolean>;
  width: number;
  minRate: number;
  maxRate: number;
  fromDate: number;
  toDate: number;
  activePeriod: RateHistoryPeriod;
  userBaseFiat: CurrencyDescription | undefined;
};

const TOOLTIP_WIDTH = 130;
const OFFSET_FROM_LINE_BY_X = 6;
const OFFSET_FROM_LINE_BY_Y = 10;
const TOOLTIP_HEIGHT = 40;

export default observer(function Tooltip(props: TooltipProps) {
  const {
    cursorTranslation,
    isActiveCursor,
    width,
    minRate,
    maxRate,
    toDate,
    fromDate,
    userBaseFiat,
    activePeriod,
  } = props;
  const [formattedRate, setFormattedRate] = useState<string | undefined>();
  const [time, setTime] = useState<string | undefined>();

  const infoStyle = useAnimatedStyle(() => {
    const x = cursorTranslation.x.value;
    const y = cursorTranslation.y.value;
    const isLeft = x + TOOLTIP_WIDTH + OFFSET_FROM_LINE_BY_X > width;
    const responseX = isLeft ? x - TOOLTIP_WIDTH : x;

    const translateX = isLeft
      ? responseX - OFFSET_FROM_LINE_BY_X
      : responseX + OFFSET_FROM_LINE_BY_X;
    const translateY = y - TOOLTIP_HEIGHT - OFFSET_FROM_LINE_BY_Y;
    const opacity = withSpring(isActiveCursor.value ? 1 : 0);
    return {
      opacity,
      transform: [{translateX}, {translateY: translateY}],
    };
  }, [cursorTranslation, isActiveCursor, width]);

  const rate = useDerivedValue(() => {
    return interpolate(
      cursorTranslation.y.value,
      [0, CHART_HEIGHT],
      [maxRate, minRate],
    );
  }, [cursorTranslation, maxRate, minRate]);
  const xDate = useDerivedValue(() => {
    return interpolate(
      cursorTranslation.x.value,
      [0, width],
      [fromDate, toDate],
    );
  }, [cursorTranslation, fromDate, toDate, width]);

  const formatDate = useFormatTooltipDate();

  const formatResult = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    (p: Animated.SharedValue<number>, d: Animated.SharedValue<number>) => {
      setFormattedRate(currencyGraphFormatter(p.value, minRate, maxRate));
      setTime(formatDate(d.value, activePeriod));
    },
    [activePeriod, formatDate, minRate, maxRate],
  );

  useDerivedValue(() => {
    runOnJS(formatResult)(rate, xDate);
  }, [formatResult, rate, xDate]);

  if (isNil(formattedRate) || isNil(time)) {
    return null;
  }

  return (
    <Animated.View style={StyleSheet.absoluteFill}>
      <AnimatedRoot
        style={infoStyle}
        {...(Platform.OS === 'web' ? tooltipMarker : nothing)}>
        <Price adjustsFontSizeToFit numberOfLines={1}>
          {formattedRate} {userBaseFiat?.code}
        </Price>
        <CurrentDate adjustsFontSizeToFit numberOfLines={1}>
          {time}
        </CurrentDate>
      </AnimatedRoot>
    </Animated.View>
  );
});

const tooltipMarker = {dataSet: {'app-chart-animated-tooltip': '1'}} as const;
const nothing = {} as const;

const useFormatTooltipDate = () => {
  const {formatTime, formatWeekDay, formatDate} = useDateFormatting();
  const strings = useStrings();

  return useCallback(
    (v: number, activePeriod: RateHistoryPeriod) => {
      const date = dayjs(v * 1000);
      let formattedDate;
      switch (activePeriod) {
        case RateHistoryPeriod.Day: {
          const isAfter =
            date.utcOffset(180).valueOf() >= dayjs().startOf('d').unix() * 1000;
          formattedDate = `${
            isAfter
              ? strings['rateHistoryPeriod.day']
              : strings['rateHistoryPeriod.yesterday']
          } ${formatTime(date)}`;
          break;
        }
        case RateHistoryPeriod.Week:
          formattedDate = formatWeekDay(date);
          break;
        default:
          formattedDate = formatDate(date);
      }
      return formattedDate;
    },
    [formatDate, formatTime, formatWeekDay, strings],
  );
};

const AnimatedRoot = variance(Animated.View)(theme => ({
  root: {
    height: TOOLTIP_HEIGHT,
    width: TOOLTIP_WIDTH,
    backgroundColor: theme.palette.primary,
    borderRadius: 4,
    paddingHorizontal: 5,
    paddingVertical: 5,
    justifyContent: 'center',
  },
}));

const Price = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: '#fff',
    fontSize: 12,
    lineHeight: 14,
  },
}));

const CurrentDate = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight(),
    color: '#fff',
    fontSize: 11,
    lineHeight: 14,
  },
}));
