/* eslint-disable @typescript-eslint/unbound-method */
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useBaseCryptoSettingsBindingState} from '../../../Navigation/HomeStack/BaseCryptoSettingsBinding/useBaseCryptoSettingsBindingState';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {BaseCryptoSettingScreen} from '../../../screens/UserSettingsScreens/BaseCryptoSettingScreen';
import type {CryptoSectionItemData} from '../../../screens/UserSettingsScreens/BaseCryptoSettingScreen/CryptoSectionItem';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptBaseCryptoBindingProps =
  SmallHomeStackBindingProps<'PromptBaseCryptoSetting'>;

export default observer(function PromptBaseCryptoBinding(
  props: PromptBaseCryptoBindingProps,
) {
  const {navigation} = props;
  const state = useBaseCryptoSettingsBindingState();

  const handleCryptoSelect = useCallback(
    async (crypto: CryptoSectionItemData) => {
      void state.update(crypto);
      navigation.goBack();
    },
    [navigation, state],
  );

  return (
    <BaseCryptoSettingScreen
      getBaseCrypto={state.getBaseCrypto}
      getOtherCryptos={state.getOtherCryptos}
      filter={state.filter}
      onFilterChange={state.setFilter}
      onCryptoSelect={handleCryptoSelect}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      keyboardAvoiding
    />
  );
});
