import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {AuthScreen} from '../../screens/AuthScreen';
import type {AuthStackBindingProps} from './AuthStackBindingProps';
import useOnLogInPress from './useOnLogInPress';

export type ForcedAuthBindingProps = AuthStackBindingProps<'Welcome'>;

export const WelcomeBinding = observer((props: ForcedAuthBindingProps) => {
  const {deviceInfo, configuration, flashMessage, platformSpecificComponents} =
    useRoot();
  const AuthScreenFooter = platformSpecificComponents.AuthScreenFooter;

  const onLogInPress = useOnLogInPress();

  const onMdLoginPress = useCallback(() => {
    props.navigation.navigate('MdLogin');
  }, [props.navigation]);

  const onMdCreateWalletPress = useCallback(() => {
    props.navigation.navigate('CreateWallet');
  }, [props.navigation]);

  const onNextEnvironment = useCallback(async () => {
    await configuration.nextEnvironment();
    const current = configuration.current;
    flashMessage.showMessage({
      title: `switch to next env:   ${
        current.isDefault ? 'default' : `custom ${current.id}`
      }`,
      variant: 'primary',
    });
  }, [configuration, flashMessage]);

  return (
    <AuthScreen
      nextEnvironmentButtonShown={configuration.hasMultipleEnvironments()}
      onNextEnvironmentPress={onNextEnvironment}
      onLogInPress={onLogInPress}
      onSignUpPress={onLogInPress}
      appVersion={deviceInfo.getAppVersionWithBuildNumber()}
      onMdLoginPress={onMdLoginPress}
      onMdCreateWalletPress={onMdCreateWalletPress}
      AuthScreenFooter={AuthScreenFooter}
    />
  );
});
