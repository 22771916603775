import {useRoot} from '@ncwallet-app/core';
import type {AccountDocumentType} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import useAccountVerificationBindingState from '../../../CommonNavigationContainers/hooks/AccountVerification/useAccountVerificationBindingState';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import ListDocumentsScreen from '../../../screens/ListDocumentsScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ListDocumentsBindingProps =
  SmallHomeStackBindingProps<'ListDocuments'>;

export default observer(function ListDocumentsBinding(
  props: ListDocumentsBindingProps,
) {
  const {navigation} = props;
  const root = useRoot();
  const {accountDocumentManager, accountDocumentTypeManager} = root;
  const state = useAccountVerificationBindingState();

  const handleSubmit = useCallback(async () => {
    const res = await state.sendToReview();
    if (res.success) {
      navigation.navigate('ListPersonalData');
    }
  }, [state, navigation]);

  const onGoBack = useCallback(() => {
    navigation.navigate('ListPersonalData');
  }, [navigation]);

  const onDocumentPress = useCallback(
    (documentType: AccountDocumentType) => {
      navigation.navigate('IdentityVerificationUploadDocument', {
        type: documentType,
      });
    },
    [navigation],
  );

  return (
    <ListDocumentsScreen
      documents={accountDocumentManager.getAll() ?? []}
      documentTypes={accountDocumentTypeManager.getAll() ?? []}
      onDocumentPress={onDocumentPress}
      onSubmit={handleSubmit}
      onGoBack={onGoBack}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});
