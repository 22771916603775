import {useBottomTabBarHeight} from '@react-navigation/bottom-tabs';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import DebugContainer from '../../../../../CommonNavigationContainers/containers/DebugContainer';
import type {SmallBottomTabBindingProps} from '../SmallBottomTabBindingProps';

export type DebugBindingProps = SmallBottomTabBindingProps<'Debug'>;

export default observer(function DebugBinding(props: DebugBindingProps) {
  const {navigation} = props;
  const goToLogs = () => {
    navigation.navigate('ListLogs');
  };
  const goToSignOutReasonLogs = () => {
    navigation.navigate('ListSignOutReasonLogs');
  };

  const goToEnvironmentList = useCallback(() => {
    navigation.navigate('ListEnvironment');
  }, [navigation]);
  const goToEnvironmentForm = useCallback(() => {
    navigation.navigate('PromptEnvironmentForm');
  }, [navigation]);

  const bottomTabBarHeight = useBottomTabBarHeight();

  return (
    <DebugContainer
      goToEnvironmentForm={goToEnvironmentForm}
      goToSignOutReasonLogs={goToSignOutReasonLogs}
      goToLogs={goToLogs}
      goToEnvironmentList={goToEnvironmentList}
      bottomTabBarHeight={bottomTabBarHeight}
    />
  );
});
