import type {CryptoCurrencyCode, CurrencyValue, DecimalString} from '../Money';
import type {Tagged} from '../Opaque';
import type {ISODateString} from '../Time';
import type {WalletId} from './Wallet';

export type WalletsTransactionsGet = {
  params: WalletsTransactionsGetParams;
  result: {
    transaction: Transaction;
  };
};

export type WalletsTransactionsGetParams = {
  transaction_id: TransactionId;
};

export type WalletsTransactions = {
  params: WalletsTransactionsParams;
  result: WalletsTransactionsResult;
};

export type WalletsTransactionsParams = {
  wallets_ids?: WalletId[];
  limit?: number;
  offset?: number;
  date_from?: ISODateString;
  date_to?: ISODateString;
  last_ts?: ISODateString;
  direction?: TransactionDirection;
  types?: TransactionType[];
};

export type WalletsTransactionsResult = {
  items: Transaction[];
  total: number;
};

export type Transaction<
  From extends CryptoCurrencyCode = CryptoCurrencyCode,
  To extends CryptoCurrencyCode = CryptoCurrencyCode,
> = {
  id: TransactionId;
  created_at: ISODateString;
  type: TransactionType;
  from_wallet_id: WalletId;
  to_wallet_id: WalletId;
  from_currency: From;
  to_currency: To;
  from_amount: CurrencyValue<From>;
  to_amount: CurrencyValue<To>;
  comment: string | null;
  front_info?: TransactionFrontInfo;
  info: WalletTransactionsInfo;
};
type TransactionFrontInfoItem = string | null;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TRANSACTION_ID = Symbol();
export type TransactionId = Tagged<string, typeof TRANSACTION_ID>;
export type TransactionFrontInfo = {
  comment: TransactionFrontInfoItem;
  type: string;
  frontType: Exclude<TransactionType, 'refund'>;
  icon: string; // Url;
  currency: TransactionFrontInfoItem;
  fromAddress: TransactionFrontInfoItem;
  fromCurrency: TransactionFrontInfoItem;
  minerFee: TransactionFrontInfoItem;
  network: TransactionFrontInfoItem;
  rate: TransactionFrontInfoItem;
  service: TransactionFrontInfoItem;
  toAddress: TransactionFrontInfoItem;
  toCurrency: TransactionFrontInfoItem;
  txId: TransactionFrontInfoItem;
  walletFee: TransactionFrontInfoItem;
  showFields: DetailsTransactionFields[];
};

export enum DetailsTransactionFields {
  FromAddress = 'fromAddress',
  TxId = 'txId',
  Comment = 'comment',
  Network = 'network',
  ToCurrency = 'toCurrency',
  FromCurrency = 'fromCurrency',
  WalletFee = 'walletFee',
  MinerFee = 'minerFee',
  Rate = 'rate',
  Currency = 'currency',
  ToAddress = 'toAddress',
}

export type TransactionType =
  | 'exchange'
  | 'withdraw'
  | 'rollback'
  | 'incoming'
  | 'referral_incoming'
  | 'refund';

export type TransactionDirection = 'from' | 'to';

export type WalletTransactionsInfo = {
  referral_incoming: ReferralIncomingInfo | undefined;
  // заполнено только при типе = withdraw и exchange
  withdraw: WithdrawInfo | undefined;
  exchange: ExchangeInfo | undefined;
  incoming: IncomingInfo | undefined;
};

export type ReferralIncomingInfo = {
  referrals_count: number;
};

export type WithdrawInfo = {
  status: WithdrawStatus;
  created_ad: ISODateString;
  fee: DecimalString;
  comment: string | undefined;
  txid: string;
  to_address: string;
  service: string;
  currency: CryptoCurrencyCode;
  transfer_options: {network?: string};
  meta?: {
    // address currency selected during withdraw
    currency_to: CryptoCurrencyCode;
  };
};

export type ExchangeInfo = {
  rate: number;
  to_currency: CryptoCurrencyCode;
  from_currency: CryptoCurrencyCode;
};

export type IncomingInfo = {
  from_address?: string;
  to_address: string;
  to_currency?: CryptoCurrencyCode;
  from_currency?: CryptoCurrencyCode;
  txid?: string;
  network?: string;
};

export type WithdrawStatus =
  | 'created'
  | 'confirmed'
  | 'canceled'
  | 'pending'
  | 'completed'
  | 'error'
  | 'rejected';
