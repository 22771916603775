import type {
  Transaction,
  WithdrawStatus,
} from '../NCWalletServer/WalletsTransactions';

export enum AggregationItemStatus {
  Created,
  Canceled,
  Pending,
  Completed,
  Error,
  Rejected,
  Reversed,
}

export const getAggregationItemStatus = (w: Transaction) => {
  const islWithdrawToExternalAddress =
    w.type === 'withdraw' && w.info.withdraw?.status;
  if (islWithdrawToExternalAddress) {
    return fromWithdrawalStatus(
      w.info.withdraw ? w.info.withdraw.status : 'pending',
    );
  } else if (w.type === 'rollback') {
    return AggregationItemStatus.Reversed;
  } else {
    return AggregationItemStatus.Completed;
  }
};
export const fromWithdrawalStatus = (
  s: WithdrawStatus,
): AggregationItemStatus => {
  switch (s) {
    case 'created':
      return AggregationItemStatus.Created;
    case 'confirmed':
      return AggregationItemStatus.Pending;
    case 'canceled':
      return AggregationItemStatus.Canceled;
    case 'pending':
      return AggregationItemStatus.Pending;
    case 'completed':
      return AggregationItemStatus.Completed;
    case 'error':
      return AggregationItemStatus.Error;
    case 'rejected':
      return AggregationItemStatus.Rejected;
    default:
      return AggregationItemStatus.Pending;
  }
};
