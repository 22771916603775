import type {CryptoCurrencyCode, WalletId} from '@ncwallet-app/core';
import type {
  RouteTransition,
  ShowQrToReceiveCryptoRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ListInputAddressesContainer from '../../../../../CommonNavigationContainers/containers/ListInputAddressesContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type ListInputAddressesBindingProps =
  LargeSwitchBindingProps<'ListInputAddresses'>;

type PageParams = {
  walletId: WalletId;
  addressNetwork: AddressNetwork;
  addressCurrency: CryptoCurrencyCode;
};

export default observer(function ListInputAddressesBinding(
  props: ListInputAddressesBindingProps,
) {
  const {navigation, route} = props;
  const showQrToReceiveCrypto = useCallback<
    RouteTransition<ShowQrToReceiveCryptoRoute>
  >(
    params => {
      navigation.navigate('ShowQrToReceiveCrypto', params);
    },
    [navigation],
  );
  const promptInputNetwork = useCallback(
    (params: PageParams) => {
      navigation.navigate('PromptInputNetwork', params);
    },
    [navigation],
  );

  const promptAddressFormat = useCallback(
    (params: PageParams) => {
      navigation.navigate('PromptAddressFormat', params);
    },
    [navigation],
  );

  const goToChangeNetwork = useCallback(() => {
    navigation.navigate('PromptReceiveNetwork', route.params);
  }, [navigation, route.params]);

  const onBackPress = useCallback(() => {
    const {walletId, address, addressNetwork, addressCurrency} = route.params;
    navigation.navigate('ShowQrToReceiveCrypto', {
      addressCurrency,
      addressNetwork,
      walletId,
      address: address,
    });
  }, [navigation, route.params]);

  return (
    <ListInputAddressesContainer
      params={route.params}
      goToChangeNetwork={goToChangeNetwork}
      promptInputNetwork={promptInputNetwork}
      promptAddressFormat={promptAddressFormat}
      showQrToReceiveCrypto={showQrToReceiveCrypto}
      onBackPress={onBackPress}
    />
  );
});
