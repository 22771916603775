import type {
  RouteTransition,
  ShowQrToReceiveCryptoRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useAddressTypeSelectionScreenProps} from '../../../../../CommonNavigationContainers/hooks/useAddressTypeSelectionScreenProps';
import LgAddressTypeScreen from '../../../../../screens/LgAddressTypeScreen/LgAddressTypeScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptAddressFormatBindingProps =
  LargeSwitchBindingProps<'PromptAddressFormat'>;

export default observer(function PromptAddressFormatBinding(
  props: PromptAddressFormatBindingProps,
) {
  const {navigation, route} = props;

  const onBackPress = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({routes: [{name: 'PromptCryptoToReceive'}], index: 0});
  }, [navigation]);

  const handleSelect: RouteTransition<ShowQrToReceiveCryptoRoute> = useCallback(
    params => {
      navigation.navigate('ShowQrToReceiveCrypto', params);
    },
    [navigation],
  );

  const screenProps = useAddressTypeSelectionScreenProps({
    params: route.params,
    goBack: onBackPress,
    showQrToReceiveCrypto: handleSelect,
  });
  return <LgAddressTypeScreen {...screenProps} />;
});
