import {readAsStringAsync} from 'expo-file-system';
import {Platform} from 'react-native';

// eslint-disable-next-line import-x/prefer-default-export
export async function uriToFileData(uri: string): Promise<string> {
  // в вебе конвертация в base64 происходит автоматически
  return Platform.OS === 'web'
    ? uri
    : readAsStringAsync(uri, {encoding: 'base64'});
}
