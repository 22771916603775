import {formatCryptoValue, useStrings, useTemplates} from '@ncwallet-app/core';
import type {
  NotifyAboutSuccessfulExchangeRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import useGoToSupport from '../../Navigation/hooks/useGoToSupport';
import {EXTRA_BOTTOM_OFFSET} from '../../screens/constants';
import {CryptoSuccessScreen} from '../../screens/CryptoSuccessScreen';

export type NotifyAboutSuccessfulExchangeContainerProps = {
  params: RouteParams<NotifyAboutSuccessfulExchangeRoute>;
  onSubmit: () => void;
  onBack: () => void;
};

export default observer(function NotifyAboutSuccessfulExchangeContainer(
  props: NotifyAboutSuccessfulExchangeContainerProps,
) {
  const {params, onBack, onSubmit} = props;

  const templates = useTemplates();
  const strings = useStrings();

  const exchangeCurrencyInfoText = templates['exchangeCrypto.exchangeResult']({
    from: `${formatCryptoValue(params.valueFrom)} ${params.currencyFrom}`,
    to: `${formatCryptoValue(params.valueTo)} ${params.currencyTo}`,
  });

  const goToSupport = useGoToSupport();

  return (
    <CryptoSuccessScreen
      onBack={onBack}
      goToSupport={goToSupport}
      title={strings['userSettings.notifications.exchange.exchangeSuccess']}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      onSubmit={onSubmit}
      paymentText={exchangeCurrencyInfoText}
      paymentValue={strings['exchangeComfirmScreen.competed']}
      buttonTitle={strings['exchangeCrypto.successButton']}
    />
  );
});
