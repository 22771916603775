import {useLogButton, useRoot, useStrings} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import {AuthErrorScreen} from '../../screens/AuthErrorScreen';

export default observer(function TokenExpiredErrorBinding() {
  const strings = useStrings();
  const {deviceInfo, expiredTokenRecovery, telegramMiniApp} = useRoot();
  const appVersion = deviceInfo.getAppVersionWithBuildNumber();
  const resetAuthState = useCallback(
    () => expiredTokenRecovery.recover(),
    [expiredTokenRecovery],
  );
  const logButtonComponent = useLogButton();

  const {isAvailable} = telegramMiniApp;
  const errorDescriptions = useMemo(() => {
    return isAvailable
      ? strings.restartTheAppAndRetry
      : strings['restartTheAppAndRetry(1)'];
  }, [strings, isAvailable]);

  return (
    <AuthErrorScreen
      appVersion={appVersion}
      cancelText={strings.okGotIt}
      onCancel={resetAuthState}
      errorMessage={strings.timeForLoginExpired}
      errorDescription={errorDescriptions}
      onBack={resetAuthState}
      LogButton={logButtonComponent}
    />
  );
});
