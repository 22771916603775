/* eslint-disable @typescript-eslint/unbound-method */
import type {CountryNameData} from '@ncwallet-app/core/src/Localization/constant';
import type {
  AccountAddressData,
  AccountPersonalData,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {
  AccountDataStatus,
  dataCanBeEdited,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import type {
  AccountDocumentId,
  AccountDocumentType,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import type {AccountDocumentFileInfo} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments/AccountDocumentFileInfo';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import type {AccountVerificationBindingState} from '../../../CommonNavigationContainers/hooks/AccountVerification';
import type {PromptToSelectVerificationCountryBindingState} from '../../../CommonNavigationContainers/hooks/usePromptToSelectVerificationCountryBindingState/PromptToSelectVerificationCountryBindingState';
import {LgProfileCardContainer} from './useCardStyles';
import AddDocumentKind from './Verification/AddDocumentKind';
import AddressForm from './Verification/AddressForm';
import Countries from './Verification/Countries';
import DocumentList from './Verification/DocumentList';
import PersonalForm from './Verification/PersonalForm';
import RemoveDocument from './Verification/RemoveDocument';
import UploadDocument from './Verification/UploadDocument';
import Verification from './Verification/Verification';
import {VerificationSteps} from './Verification/VerificationStep';

export type VerificationCardProps = {
  verificationStep: VerificationSteps;
  editedLocallyCountry: string | undefined;
  goToStart: () => void;
  goToPersonalInfoNameStep: () => void;
  goToAddress: () => void;
  goToCountrySelect: () => void;
  handleCountrySelect: (country: CountryNameData) => void;
  goToDocumentsStep: () => void;
  isVerified: boolean;
  isVerificationRequired: boolean;
  goToUploadDocument: (id: AccountDocumentType) => void;
  promptToDeleteDocumentFile: (
    documentId: AccountDocumentId,
    file: AccountDocumentFileInfo,
  ) => void;
  onDeleteFile: () => void;
  verificationState: AccountVerificationBindingState;
  countrySelectState: PromptToSelectVerificationCountryBindingState;
};

export default observer(function VerificationCard(
  props: VerificationCardProps,
) {
  const {
    verificationStep,
    goToStart,
    goToPersonalInfoNameStep,
    goToAddress,
    goToCountrySelect,
    handleCountrySelect,
    goToDocumentsStep,
    goToUploadDocument,
    promptToDeleteDocumentFile,
    onDeleteFile,
    verificationState,
    isVerified,
    isVerificationRequired,
    countrySelectState,
  } = props;
  const {personalData, addressData, documentTypes, documents} =
    verificationState;

  const handleUpload = useCallback(
    (
      fileData: string,
      documentType: AccountDocumentType,
      fileName: string | undefined,
    ) => verificationState.uploadFile(fileData, documentType, fileName),
    [verificationState],
  );

  const handlePersonalFormSubmit = useCallback(
    async (form: AccountPersonalData) => {
      const res = await verificationState.savePersonalData(form);
      if (res.success) {
        goToAddress();
      }
    },
    [goToAddress, verificationState],
  );

  const handleAddressFormSubmit = useCallback(
    async (data: AccountAddressData) => {
      const res = await verificationState.saveAddressData(data);
      if (res.success) {
        goToDocumentsStep();
      }
    },
    [goToDocumentsStep, verificationState],
  );

  const sendToReview = useCallback(async () => {
    const res = await verificationState.sendToReview();
    if (res.success) {
      goToStart();
    }
  }, [goToStart, verificationState]);

  return (
    <LgProfileCardContainer horizontalOffset>
      {verificationStep === VerificationSteps.Start && (
        <Verification
          personalData={personalData?.data}
          addressData={addressData?.data}
          personalDataStatus={personalData?.status}
          addressDataStatus={addressData?.status}
          isPersonalDataVerified={
            personalData?.status === AccountDataStatus.Verified
          }
          isAddressDataVerified={
            addressData?.status === AccountDataStatus.Verified
          }
          isAvailableEditPersonalData={
            !personalData || dataCanBeEdited(personalData)
          }
          isAvailableEditAddressData={
            !addressData || dataCanBeEdited(addressData)
          }
          isVerifiedAccount={isVerified}
          isVerificationRequired={isVerificationRequired}
          onNamePress={goToPersonalInfoNameStep}
          onAddressPress={goToAddress}
          onDocumentsUploadPress={goToDocumentsStep}
        />
      )}
      {verificationStep === VerificationSteps.PersonalInfoNameStep && (
        <PersonalForm
          value={personalData?.data}
          onBack={goToStart}
          onSubmit={handlePersonalFormSubmit}
        />
      )}
      {verificationStep === VerificationSteps.Address && (
        <AddressForm
          value={addressData?.data}
          country={props.editedLocallyCountry}
          onCountrySelect={goToCountrySelect}
          onBack={goToStart}
          onSubmit={handleAddressFormSubmit}
        />
      )}
      {verificationStep === VerificationSteps.SelectCountry && (
        <Countries
          countries={countrySelectState.countries}
          searchQuery={countrySelectState.searchQuery}
          resetSearch={countrySelectState.resetSearch}
          onSearch={searchValue => {
            countrySelectState.search(searchValue);
          }}
          onCountrySelect={handleCountrySelect}
          onBack={goToAddress}
        />
      )}
      {verificationStep === VerificationSteps.DocumentsStep && (
        <DocumentList
          documentTypes={documentTypes}
          documents={documents}
          onBack={goToStart}
          onDocumentPress={goToUploadDocument}
          onSubmit={sendToReview}
        />
      )}
      {verificationStep === VerificationSteps.AddDocumentKindStep && (
        <AddDocumentKind onBack={goToStart} onDocumentPress={goToStart} />
      )}
      {verificationStep === VerificationSteps.UploadDocumentStep && (
        <UploadDocument
          documents={documents}
          onBack={goToDocumentsStep}
          onUpload={handleUpload}
          onDeleteFile={promptToDeleteDocumentFile}
        />
      )}
      {verificationStep === VerificationSteps.RemoveUploadedDocumentStep && (
        <RemoveDocument onBack={goToDocumentsStep} onDelete={onDeleteFile} />
      )}
    </LgProfileCardContainer>
  );
});
