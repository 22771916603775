import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import SecuritySettingsList from './SecuritySettingsList';

export type SecureSettingsScreenProps = BaseSafeAreaProps & {
  onSessionHistoryPress: () => void;
  onActiveSessionsPress: () => void;
  onBlockedIpPress: () => void;
  onAllowedIpPress: () => void;
  onTwoFaSettingsPress: () => void;
  onBiometricPress: () => void;
  onPinPress: () => void;
};

export default observer(function SecuritySettingsScreen(
  props: SecureSettingsScreenProps,
) {
  return <SecuritySettingsList {...props} />;
});
