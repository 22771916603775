import type {Disposer} from './Disposer';

export default function batchDisposers(...args: DisposerList) {
  return (() => {
    for (let i = args.length - 1; i >= 0; i--) {
      const arg = args[i];
      if (arg !== undefined) {
        arg();
      }
    }
  }) as Disposer;
}

export type DisposerList = (Disposer | undefined)[];
