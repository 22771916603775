import type {TimeoutError} from '../../Error';
import type {Either} from '../../fp';
import type {CurrencyCode} from '../../Money';
import type {CurrenciesRateHistoryItem} from '../../NCWalletServer';

// Получение таймаута при запросе истории рейта валют считается штатной ситуацией,
// ошибка хранится чтобы можно было понять что запрос на историю был завершён
export type AcceptedRateHistoryResponse<
  From extends CurrencyCode = CurrencyCode,
  To extends CurrencyCode = CurrencyCode,
> = Either<CurrenciesRateHistoryItem<From, To>[], TimeoutError>;

export const getHistoryFromResponse = <
  From extends CurrencyCode,
  To extends CurrencyCode,
>(
  res: AcceptedRateHistoryResponse<From, To>,
) => {
  return res.success ? res.right : undefined;
};
