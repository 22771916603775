import {useStrings, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonVariant,
  LG_BREAKPOINT,
  SafeAreaScrollView,
  useIsDimensions,
} from '@ncwallet-app/ui';
import TwoFaWelcomeImage from '@ncwallet-app/ui/src/assets/images/two_fa_welcome.png';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Image, Text, View} from 'react-native';

export type TwoFaWelcomeScreenProps = {
  onTwoFaEnable: () => void;
};

export default observer(function TwoFaWelcomeScreen(
  props: TwoFaWelcomeScreenProps,
) {
  const {onTwoFaEnable} = props;
  const isLg = useIsDimensions('lg');
  const strings = useStrings();

  const getListItems = useCallback(
    () => strings['twoFaWelcomeScreen.body.benefits'].split(';'),
    [strings],
  );

  return (
    <SafeAreaScrollView>
      <RootView>
        <TopView>
          <TopImage source={TwoFaWelcomeImage} small={isLg} />
          <TitleText>{strings['twoFaWelcomeScreen.body.title']}</TitleText>
          <DescriptionText>
            {strings['twoFaWelcomeScreen.body.description']}
          </DescriptionText>
          <ColoredListView>
            {getListItems().map((it, idx) => (
              <DottedListItem key={idx} text={it} />
            ))}
          </ColoredListView>
        </TopView>
        <ActionsView>
          <Button
            testID="2fa-enable"
            title={strings['secureTwoFactorAuthScreen.enableButton']}
            onPress={onTwoFaEnable}
            variant={ButtonVariant.Primary}
          />
        </ActionsView>
      </RootView>
    </SafeAreaScrollView>
  );
});

const DottedListItem = observer(({text}: {text: string}) => {
  return (
    <DottedListItemView>
      <DottedListItemDecoration />
      <DottedListItemText>{text}</DottedListItemText>
    </DottedListItemView>
  );
});

const RootView = variance(View)(theme => ({
  root: {
    flex: 1,
    gap: 10,
    paddingHorizontal: 15,
    paddingVertical: 35,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingVertical: 10,
      },
    }),
  },
}));

const TopView = variance(View)(() => ({
  root: {
    gap: 10,
  },
}));

const TopImage = variance(Image)(() => ({
  root: {
    alignSelf: 'center',
    marginVertical: 20,
    height: 120,
    aspectRatio: 16 / 9,
  },
  small: {
    height: 90,
    marginVertical: 8,
  },
}));

const TitleText = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('700'),
    fontSize: 26,
    textAlign: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 16,
        lineHeight: 28,
      },
    }),
  },
}));

const DescriptionText = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('400'),
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 14,
        lineHeight: 20,
      },
    }),
  },
}));

const ColoredListView = variance(View)(theme => ({
  root: {
    gap: 10,
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    borderColor: theme.palette.info,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: theme.palette.additional3,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginTop: 0,
        gap: 5,
      },
    }),
  },
}));

const DottedListItemDecoration = variance(View)(theme => ({
  root: {
    flexShrink: 0,
    width: 4,
    height: 4,
    marginTop: 13,
    borderRadius: 4,
    backgroundColor: theme.palette.textAdditional1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginTop: 'auto',
        marginBottom: 'auto',
      },
    }),
  },
}));

const DottedListItemView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    gap: 5,
  },
}));

const DottedListItemText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional1,
    fontSize: 16,
    lineHeight: 26,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 14,
        lineHeight: 20,
      },
    }),
  },
}));

const ActionsView = variance(View)(() => ({
  root: {
    marginTop: 'auto',
  },
}));
