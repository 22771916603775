import type {CryptoCurrencyCode} from '@ncwallet-app/core';
import {useStrings, variance} from '@ncwallet-app/core';
import type {SafeAreaScrollViewProps} from '@ncwallet-app/ui';
import {
  LG_BREAKPOINT,
  Pressable,
  SendCryptoModal,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {CommissionSwitcher} from '../SendScreen/CommissionSwitcher';
import LgSendCommissionHeader from './LgSendCommissionHeader';
import MinerFeeSlider from './MinerFeeSlider';

export type SendCommissionScreenProps = SafeAreaScrollViewProps & {
  commissionSwitcherHidden: boolean;
  isEnoughAmountForFreeSend: boolean;
  getHasNoCommission: () => boolean;
  setHasNoCommission: (c: boolean) => void;
  onBackPress: () => void;

  stepCurrent: number;
  stepTotal: number;

  minFreeAmount: string | undefined;
  fee?: string;
  setFee: (value: string) => void;
  disabled?: boolean;
  isEditable?: boolean;
  currency: CryptoCurrencyCode;
  onSubmit: () => void;
  fees?: string[] | undefined;
  recommendedFee?: string | undefined;
  error?: string;
};

export default observer(function SendCommissionScreen(
  props: SendCommissionScreenProps,
) {
  const {
    stepCurrent,
    currency,
    stepTotal,
    isEditable,
    disabled,
    onSubmit,
    error,
    ...rest
  } = props;

  const feeEditingHidden = !props.fees || props.fees.length <= 1;
  const feeEditingDisabled = props.getHasNoCommission();

  const strings = useStrings();
  const isLg = useIsDimensions('lg');
  return (
    <Root
      error={error}
      stepCurrent={stepCurrent}
      stepTotal={stepTotal}
      contentOffset={{x: 0, y: 30}}
      isEditable={isEditable}
      onSubmit={onSubmit}
      disabled={disabled}
      {...rest}>
      {isLg && !props.commissionSwitcherHidden ? (
        <LgSendCommissionHeader
          minFreeAmount={props.minFreeAmount}
          isEnoughAmountForFreeSend={props.isEnoughAmountForFreeSend}
          setHasNoCommission={props.setHasNoCommission}
          getHasNoCommission={props.getHasNoCommission}
          onBackPress={props.onBackPress}
        />
      ) : (
        !props.commissionSwitcherHidden && (
          <CommissionSwitcher
            minFreeAmount={props.minFreeAmount}
            isEnoughAmountForFreeSend={props.isEnoughAmountForFreeSend}
            zeroOffset
            getHasNoCommission={props.getHasNoCommission}
            setHasNoCommission={props.setHasNoCommission}
          />
        )
      )}
      <Title>{strings['sendCrypto.minerFee.title']}</Title>
      <Value>
        {props.fee} {currency}
      </Value>

      {!feeEditingHidden && (
        <>
          <MinerFeeSlider
            sliderValue={0}
            onSliderValueChange={props.setFee}
            disabled={feeEditingDisabled}
            fees={props.fees?.length === 1 ? undefined : props.fees}
          />
          <Row>
            <Pressable
              disabled={feeEditingDisabled}
              onPress={() => {
                props.setFee(props.recommendedFee || '0');
              }}>
              <Recommended disabled={feeEditingDisabled}>
                {strings['sendCrypto.commission.recommended']}
              </Recommended>
            </Pressable>
            <Value disabled={feeEditingDisabled}>
              {props.recommendedFee || '0'} {currency}
            </Value>
          </Row>
        </>
      )}
    </Root>
  );
});

const Root = variance(SendCryptoModal)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        ...theme.bar(10),
      },
    }),
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.textPrimary,
    marginTop: 20,
    marginBottom: 10,
  },
}));

const Value = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    color: theme.palette.textAdditional2,
  },
  disabled: {
    opacity: 0.5,
  },
}));

const Row = variance(View)(() => ({
  root: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Recommended = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.info,
  },
  disabled: {
    opacity: 0.5,
  },
}));
