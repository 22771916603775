import type {TelegramCredentials} from '@ncwallet-app/core';
import SearchParams from '@ncwallet-app/core/src/util/searchParams';
import {Buffer} from 'buffer';
import React, {useCallback} from 'react';
import {Modal} from 'react-native';
import type {
  WebViewMessageEvent,
  WebViewNavigation,
} from 'react-native-webview';
import {WebView} from 'react-native-webview';

type TelegramWidgetProps = {
  botId: string;
  returnTo: string;
  origin: string;
  visible: boolean;
  onRequestClose: () => void;
  onLogged: (token: TelegramCredentials) => void;
};

const CANCEL_KEY = 'cancelButtonClicked';
const INJECTED_JAVASCRIPT = `
    function findCancelButton() {
       const buttons = document.querySelectorAll('button');
       for (const button of buttons) {
         if (button.hasAttribute('onclick')) {
           const onclickValue = button.getAttribute('onclick');
           if (onclickValue.toLowerCase().includes('cancel')) {
             return button;
           }
         }
       }
       return null;
     }
    const cancelButton = findCancelButton();
    if (cancelButton) {
      cancelButton.removeAttribute('onclick');
      cancelButton.addEventListener('click', () => {
        window.ReactNativeWebView.postMessage('"${CANCEL_KEY}"');
      });
    }
  `;

const TelegramWidget = ({
  botId,
  returnTo,
  origin,
  visible,
  onRequestClose,
  onLogged,
}: TelegramWidgetProps) => {
  const handleNavigationStateChange = useCallback(
    (_: WebViewNavigation) => {
      const hash = SearchParams.getPropertyFromQueryString(
        decodeURIComponent(_.url),
        'tgAuthResult',
      );
      if (hash) {
        const buffer = Buffer.from(hash, 'base64');
        const dataString = buffer.toString('utf-8');
        onLogged(dataString as TelegramCredentials);
      }
    },
    [onLogged],
  );

  const onMessage = useCallback(
    (event: WebViewMessageEvent) => {
      if (event.nativeEvent.data.includes(CANCEL_KEY)) {
        onRequestClose();
      }
    },
    [onRequestClose],
  );

  return (
    <Modal
      animationType="slide"
      visible={visible}
      onRequestClose={onRequestClose}
      presentationStyle="pageSheet">
      <WebView
        webviewDebuggingEnabled
        bounces={false}
        allowsBackForwardNavigationGestures={false}
        originWhitelist={['*']}
        onNavigationStateChange={handleNavigationStateChange}
        source={{uri: generateTelegramAuthUrl(botId, origin, returnTo)}}
        setSupportMultipleWindows={false}
        injectedJavaScript={INJECTED_JAVASCRIPT}
        onMessage={onMessage}
      />
    </Modal>
  );
};

export default TelegramWidget;

function generateTelegramAuthUrl(
  botId: string,
  origin: string,
  returnTo: string,
) {
  const baseUrl = 'https://oauth.telegram.org/auth';
  const queryParams = new URLSearchParams();
  queryParams.append('bot_id', botId);
  queryParams.append('origin', origin);
  queryParams.append('embed', '1');
  queryParams.append('return_to', returnTo);
  return `${baseUrl}?${queryParams.toString()}`;
}
