import type {Either, Failure, GlobalError, PinCode} from '@ncwallet-app/core';
import {
  COMMON_ERROR_TO_LOCALE,
  CommonErrorCode,
  error,
  ErrorRecognizerStatic,
  isCommonErrorResponse,
  success,
  urlLinks,
  useRoot,
} from '@ncwallet-app/core';
import type {
  PinInvalidError,
  PinNotSetError,
} from '@ncwallet-app/core/src/ErrorRecognizer/PinError';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useCheckPinContainer} from '../../../CommonNavigationContainers/hooks/useCheckPinContainer';
import {PinScreen} from '../../../screens/PinScreen';
import {useCheckPinTexts} from '../../../screens/PinScreen/PinScreenTexts';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type CheckPinBiometricsBindingProps =
  SmallHomeStackBindingProps<'CheckBiometricsPin'>;

export default observer(function CheckBiometricsPinBinding(
  props: CheckPinBiometricsBindingProps,
) {
  const {navigation} = props;
  const {deviceInfo} = useRoot();
  const appVersion = deviceInfo.getAppVersionWithBuildNumber();
  const baseTexts = useCheckPinTexts(true);
  const setPinCodeCallBack = useCallback(() => {
    navigation.reset({
      index: 0,
      routes: [{name: 'Root'}, {name: 'PromptBiometricSettings'}],
    });
  }, [navigation]);

  const addPinBiometricsByRpc = useAddPinBiometricsByRpc();

  const {getErrorShown, onErrorActionPress, texts, getPinCode, setPinCode} =
    useCheckPinContainer(addPinBiometricsByRpc, setPinCodeCallBack, baseTexts);

  const onCancel = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.replace('PromptBiometricSettings');
  }, [navigation]);

  return (
    <PinScreen
      appVersion={appVersion}
      texts={texts}
      getPin={getPinCode}
      setPin={setPinCode}
      getErrorShown={getErrorShown}
      onCancel={onCancel}
      onErrorActionPress={onErrorActionPress}
    />
  );
});

export const useAddPinBiometricsByRpc = () => {
  const {biometricSettingsManager} = useRoot();
  const checkBiometryAreadySet = useBiometryAlreadySetErrorDetect();
  return useCallback(
    async (
      pinCode: PinCode,
    ): Promise<
      Either<void, PinInvalidError | PinNotSetError | GlobalError>
    > => {
      const check_ =
        await biometricSettingsManager.addSignInBiometrics(pinCode);

      if (check_.success) {
        return success();
      }
      checkBiometryAreadySet(check_);
      const pinError = ErrorRecognizerStatic.getPinError(check_.left);
      return pinError ? error(pinError) : check_;
    },
    [biometricSettingsManager, checkBiometryAreadySet],
  );
};

export const useBiometryAlreadySetErrorDetect = () => {
  const {flashMessage, navigationContainer} = useRoot();
  return useCallback(
    (res: Failure<GlobalError>) => {
      if (
        isCommonErrorResponse(res.left) &&
        res.left.body.code === CommonErrorCode.BiometryAlreadySet
      ) {
        flashMessage.showMessage({
          title: COMMON_ERROR_TO_LOCALE[CommonErrorCode.BiometryAlreadySet],
          variant: 'danger',
          link: urlLinks.biometryNotSetFaq,
        });
        navigationContainer.ref?.goBack();
      }
    },
    [flashMessage, navigationContainer],
  );
};
