import type {Service} from '@ncwallet-app/core';
import type {NotificationPermissions} from '@ncwallet-app/core/src/NotificationPermissions';

import {isNotificationApiSupported} from '../LocalNotifications/isNotificationApiSupported';
import WebPrepareNotificationsService from './WebPrepareNotificationsService';

export default class WebPrepareNotificationsFactory {
  constructor(
    private readonly _root: {
      readonly notificationPermissions: NotificationPermissions;
    },
  ) {}

  create(): Service | undefined {
    if (isNotificationApiSupported()) {
      return new WebPrepareNotificationsService(this._root);
    }
    return undefined;
  }
}
