import {urlLinks, useStrings, useTheme, variance} from '@ncwallet-app/core';
import {Pressable, Switch} from '@ncwallet-app/ui';
import {InfoSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type BiometricSettingsScreenProps = {
  localKeysExist: boolean;
  getIsActiveLogin?: () => boolean;
  getIsActiveTransaction?: () => boolean;
  onLoginToggle: () => void;
  onTransactionToggle: () => void;
  goToInfo: (url: string) => void;
  isWeb?: boolean;
};

export default observer(function BiometricSettingsScreen(
  props: BiometricSettingsScreenProps,
) {
  const {
    getIsActiveLogin,
    getIsActiveTransaction,
    onTransactionToggle,
    onLoginToggle,
    goToInfo,
    isWeb,
  } = props;
  const theme = useTheme();
  const strings = useStrings();
  return (
    <Container>
      {!isWeb && <Title>{strings['biometricSettings.enable']}</Title>}
      <Description danger={isWeb}>
        {isWeb
          ? strings['biometricsError.notAvailiableOnBrowser']
          : strings['biometricSettings.choose']}
      </Description>
      <Title withBorderBottom>{strings['biometricSettings.confirm']}</Title>
      <Row>
        <RowLabel>{strings['biometricSettings.login']}</RowLabel>
        <Info
          onPress={() => {
            goToInfo(urlLinks.biometricsLogFaq);
          }}>
          <InfoSvg color={theme.palette.uiAdditional1} />
        </Info>
        <Switch
          disabled={isWeb}
          getIsActive={getIsActiveLogin}
          onChange={onLoginToggle}
        />
      </Row>
      <Row>
        <RowLabel>{strings['biometricSettings.transaction']}</RowLabel>
        <Info
          onPress={() => {
            goToInfo(urlLinks.support);
          }}>
          <InfoSvg color={theme.palette.uiAdditional1} />
        </Info>
        <Switch
          disabled={isWeb}
          getIsActive={getIsActiveTransaction}
          onChange={onTransactionToggle}
        />
      </Row>
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    paddingVertical: 20,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    paddingHorizontal: 15,
    marginBottom: 10,
    color: theme.palette.textMain,
  },

  withBorderBottom: {
    marginBottom: 0,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 13,
    lineHeight: 20,
    paddingHorizontal: 15,
    marginBottom: 30,
    color: theme.palette.textAdditional2,
  },

  danger: {
    color: theme.palette.error,
  },
}));

const Row = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 15,
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const RowLabel = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('600'),
    fontSize: 16,
    color: theme.palette.textMain,
  },
}));

const Info = variance(Pressable)(() => ({
  root: {
    marginLeft: 5,
    marginRight: 'auto',
  },
}));
