import {useStrings, variance} from '@ncwallet-app/core';
import {BottomSheet} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import LinkToTelegramScreenBody from './LinkToTelegramScreenBody';

export type LinkToTelegramProps = {
  telegramBotId: string;
  telegramOauthOrigin: string;
  redirectUriForTelegramLinking: string;
  onClose: () => void;
};

export default observer(function LinkToTelegramScreen({
  telegramBotId,
  telegramOauthOrigin,
  redirectUriForTelegramLinking,
  onClose,
}: LinkToTelegramProps) {
  const {bottom} = useSafeAreaInsets();
  const strings = useStrings();
  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        onClose();
      }
    },
    [onClose],
  );
  return (
    <BottomSheet
      index={0}
      onChange={onChange}
      animateOnMount
      snapPoints={[560]}
      enablePanDownToClose
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      title={strings['linkToTelegram.title']}
      onClose={onClose}
      children={
        <BodyWrapper style={{paddingBottom: bottom}}>
          <LinkToTelegramScreenBody
            inBottomSheet
            telegramBotId={telegramBotId}
            telegramOauthOrigin={telegramOauthOrigin}
            redirectUriForTelegramLinking={redirectUriForTelegramLinking}
            onClose={onClose}
          />
        </BodyWrapper>
      }
    />
  );
});

const BodyWrapper = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));
