import type {Disposer, Service} from '@ncwallet-app/core/src';
import {BusImpl} from '@ncwallet-app/core/src';
import type {
  MessageContext,
  MessageDescription,
  MessageHandler,
  Messaging,
} from '@ncwallet-app/core/src/Messaging';
import {getMessaging, onMessage} from 'firebase/messaging';

export default class WebMessagingService implements Messaging, Service {
  constructor(
    private readonly _parent: {readonly messageHandler: MessageHandler},
  ) {}

  public readonly income = new BusImpl<(context: MessageContext) => void>();

  subscribe() {
    if (!('serviceWorker' in navigator)) {
      return;
    }
    const messaging = getMessaging();
    return onMessage(messaging, async message => {
      if (message.notification) {
        const registration = await window.navigator.serviceWorker.ready;
        await registration.showNotification(
          message.notification.title ?? 'NCWallet',
          {
            body: message.notification.body,
            // @ts-expect-error https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/showNotification#image
            image: message.notification.image,
          },
        );
      } else {
        if (message.data) {
          const description = message.data as MessageDescription;
          const context_ =
            await this._parent.messageHandler.handle(description);
          if (!context_.success) {
            return;
          }
          this.income.send(context_.right);
        }
      }
    }) as Disposer;
  }
}
