import {useStrings} from '@ncwallet-app/core';
import type {FiatCurrency} from '@ncwallet-app/core/src/NCWalletServer/FiatCurrency';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {SafeAreaSectionList} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import EmptySectionTitle from '../SharedComponents/EmptySectionTitle';
import {SettingsGroupTitle} from '../SharedComponents/SettingsGroupTitle';
import {FiatSectionItem} from './FiatSectionItem';

export type FiatSectionListProps = BaseSafeAreaProps & {
  baseFiat: () => FiatCurrency | undefined;
  otherFiat: () => FiatCurrency[] | undefined;
  onFiatSelect: (fiat: FiatCurrency) => void;
};

type SectionInfo = {
  title: string;
  data: FiatCurrency[];
};

export const FiatSectionList = observer((props: FiatSectionListProps) => {
  const strings = useStrings();

  const {baseFiat, otherFiat, onFiatSelect, ...rest} = props;
  const base = baseFiat();
  const other = otherFiat();

  if (!base || !other) {
    return null;
  }

  const data: SectionInfo[] = [
    {
      title: strings['selectFiatScreen.currentFiat'],
      data: [base],
    },
    {
      title: strings['selectFiatScreen.allFiat'],
      data: other,
    },
  ];

  return (
    <SafeAreaSectionList
      sections={data}
      keyExtractor={(item, index) => `${index} + ${item.code}`}
      renderSectionHeader={({section}) => (
        <>
          <SettingsGroupTitle
            title={section.title}
            first={data.indexOf(section) === 0}
          />
          {other.length === 0 && data.indexOf(section) !== 0 && (
            <EmptySectionTitle />
          )}
        </>
      )}
      stickySectionHeadersEnabled={false}
      renderItem={({item}) => {
        return (
          <FiatSectionItem
            title={item.code}
            onPress={() => {
              onFiatSelect(item);
            }}
            active={item.code === base.code}
            fiatCode={item.code}
          />
        );
      }}
      {...rest}
    />
  );
});
