import type GorhomBottomSheet from '@gorhom/bottom-sheet';
import {useStrings} from '@ncwallet-app/core';
import type {AddressType} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {BottomSheet} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import AddressBottomSheetList from './AddressBottomSheetList';

export type ReceiveCryptoAddressesBottomSheetProps = {
  onTypeSelect: (t: AddressType) => void;
  onClosePress: () => void;
  defaultType: AddressType | undefined;
  types: AddressType[] | undefined;
};

export default observer<
  ReceiveCryptoAddressesBottomSheetProps,
  GorhomBottomSheet
>(
  React.forwardRef(function ReceiveCryptoAddressTypeBottomSheet(props, ref) {
    const {onClosePress} = props;
    const strings = useStrings();

    const onChange = useCallback(
      (index: number) => {
        if (index === -1) {
          onClosePress();
        }
      },
      [onClosePress],
    );

    if (!props.types) {
      return null;
    }

    return (
      <BottomSheet
        ref={ref}
        center
        index={0}
        animateOnMount
        onClose={onClosePress}
        onChange={onChange}
        enablePanDownToClose
        backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
        snapPoints={[250]}
        title={strings['receiveCryptoScreen.bottomSheetTitle']}
        children={
          <AddressBottomSheetList
            defaultType={props.defaultType}
            onPress={props.onTypeSelect}
            addressTypes={props.types}
          />
        }
      />
    );
  }),
);
