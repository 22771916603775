import type {BottomSheetMethods} from '@gorhom/bottom-sheet/lib/typescript/types';
import {useStrings, variance} from '@ncwallet-app/core';
import type {Ip} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {BottomSheet, Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {Text, View} from 'react-native';

export type PromptToDeleteIpScreenProps = {
  ip: Ip;
  type: IpType;
  onClose: () => void;
  onConfirm: () => void;
};

export default observer(function PromptToDeleteIpScreen(
  props: PromptToDeleteIpScreenProps,
) {
  const {ip, type, onConfirm, onClose} = props;
  const strings = useStrings();

  const ref = useRef<BottomSheetMethods>(null);

  // isConfirmedRef и все что с ним связано - костыль над миганием
  // боттом шита при открытии следующего скрина
  const isConfirmedRef = useRef(false);
  const handleConfirm = useCallback(() => {
    isConfirmedRef.current = true;
    ref.current?.close();
    onConfirm();
  }, [onConfirm]);

  const onChange = useCallback(
    (index: number) => {
      if (index === -1 && !isConfirmedRef.current) {
        onClose();
      }
    },
    [onClose],
  );
  return (
    <BottomSheet
      ref={ref}
      index={0}
      onChange={onChange}
      animateOnMount
      snapPoints={[290]}
      enablePanDownToClose
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      title={strings['deleteAllowedIpModal.title'].replace('{ipName}', ip)}
      onClose={onClose}
      children={
        <Container>
          <DeleteIpText>
            {type === IpType.Allowed
              ? strings['deleteAllowedIpModal.text'].replace('{ipName}', ip)
              : strings['deleteBlockedIpModal.text'].replace('{ipName}', ip)}
          </DeleteIpText>
          <Button
            onPress={handleConfirm}
            title={strings['deleteIpModal.confirm']}
            variant={ButtonVariant.PrimaryLight}
          />
          <Divider />
          <Button
            onPress={onClose}
            title={strings['deleteIpModal.cancel']}
            variant={ButtonVariant.Danger}
          />
        </Container>
      }
    />
  );
});

const Container = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingTop: 20,
  },
}));

const Divider = variance(View)(() => ({
  root: {
    height: 20,
  },
}));

const DeleteIpText = variance(Text)(theme => ({
  root: {
    marginBottom: 20,
    ...theme.fontByWeight('400'),
    fontSize: 14,
    color: theme.palette.textAdditional1,
  },
}));
