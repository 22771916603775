import {variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui';
import {View} from 'react-native';

export const AsideColumn = variance(View)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingRight: 25,
        width: 410,
      },
    }),
  },
}));

export const MainColumn = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 16,
      },
    }),
  },
}));
