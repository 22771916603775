import {useRoot} from '@ncwallet-app/core';
import {expr} from 'mobx-utils';

import {
  LG_BREAKPOINT,
  MD_BREAKPOINT,
  SM_BREAKPOINT,
  XL_BREAKPOINT,
} from '../components/grid/constants';

export type LiteralBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export function useBreakpoint() {
  const {windowDimensionsState} = useRoot();
  return expr(() =>
    translatePixelsToBreakpoint(windowDimensionsState.window.width),
  );
}

export function useIsDimensions(breakpoint: LiteralBreakpoint) {
  const {windowDimensionsState} = useRoot();
  return expr(
    () =>
      windowDimensionsState.window.width >=
      translateBreakpointToPixels(breakpoint),
  );
}

export function translateBreakpointToPixels(breakpoint: LiteralBreakpoint) {
  switch (breakpoint) {
    case 'sm':
      return SM_BREAKPOINT;
    case 'md':
      return MD_BREAKPOINT;
    case 'lg':
      return LG_BREAKPOINT;
    case 'xl':
      return XL_BREAKPOINT;
    case 'xs':
    default:
      return 0;
  }
}

export function translatePixelsToBreakpoint(pixels: number): LiteralBreakpoint {
  if (pixels >= XL_BREAKPOINT) {
    return 'xl';
  } else if (pixels >= LG_BREAKPOINT) {
    return 'lg';
  } else if (pixels >= MD_BREAKPOINT) {
    return 'md';
  } else if (pixels >= SM_BREAKPOINT) {
    return 'sm';
  } else {
    return 'xs';
  }
}
