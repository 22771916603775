import {colored, sized} from '@ncwallet-app/core';
import {Pressable, SafeAreaInset, SafeAreaLayout} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';

export type CameraHeaderProps = {
  onClose: () => void;
};

export default observer(function CameraHeader({onClose}: CameraHeaderProps) {
  return (
    <SafeAreaLayout style={styles.root} insets={SafeAreaInset.TOP}>
      <View style={styles.content}>
        <Pressable hitSlop={20} style={styles.closeButton} onPress={onClose}>
          <CrossIcon />
        </Pressable>
      </View>
    </SafeAreaLayout>
  );
});

const CrossIcon = colored(sized(CrossSvg, 25), '#fff');

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  closeButton: {
    position: 'absolute',
    left: 20,
  },
});
