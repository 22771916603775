function last<Last>(_: readonly [...unknown[], Last]): Last;
function last<First, Rest>(_: readonly [First, ...Rest[]]): First | Rest;
function last<T>(_: readonly T[] | undefined): T | undefined;
function last(_: undefined): undefined;
function last<T>(_: readonly T[] | undefined): T | undefined {
  if (!_ || _.length === 0) {
    return undefined;
  }
  return _[_.length - 1];
}

export default last;
