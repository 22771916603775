import {useRoot, useStrings} from '@ncwallet-app/core';
import type {Receipt} from '@ncwallet-app/core/src/AddressParser';
import {getCurrencyCodeByAddressKind} from '@ncwallet-app/core/src/AddressParser';
import type {
  PromptAmountToSendRoute,
  PromptOutputAddressRoute,
  RouteTransition,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {NavigationProp, ParamListBase} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/routers';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';

import useStackNavigateBack from '../../../CommonNavigationContainers/hooks/useStackNavigateBack';
import {
  useNavigationGetIsFocused,
  useNavigationGetIsTransitioning,
} from '../../../Navigation/hooks';
import {useNavigateToSendByQr} from '../../../Navigation/hooks/useNavigateToSendByQr';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ShowQrCodeScannerBindingProps =
  SmallHomeStackBindingProps<'ScanQrCodeScanner'>;

export default observer(function ShowQrCodeScannerBinding(
  props: ShowQrCodeScannerBindingProps,
) {
  const {navigation} = props;
  const strings = useStrings();
  const navigateBack = useStackNavigateBack(navigation);
  const processRef = useRef(false);
  const promptOutputAddress = useCallback<
    RouteTransition<PromptOutputAddressRoute>
  >(
    params => {
      navigateBack('PromptOutputAddress', params);
    },
    [navigateBack],
  );
  const promptAmountToSend = useCallback<
    RouteTransition<PromptAmountToSendRoute>
  >(
    params => {
      navigateBack('PromptAmountToSend', params);
    },
    [navigateBack],
  );
  const onError = useCallback(() => {
    navigation.replace('ListQrCodeHistory');
  }, [navigation]);
  const _navigateToSendByQr = useNavigateToSendByQr(
    promptOutputAddress,
    promptAmountToSend,
    onError,
  );
  const navigateToSendByQr = useCallback(
    async (receipt: Receipt) => {
      await _navigateToSendByQr(
        receipt.address,
        receipt.code ??
          (receipt.kind && getCurrencyCodeByAddressKind(receipt.kind)),
        receipt.amount,
        receipt.network,
      );
    },
    [_navigateToSendByQr],
  );
  const getIsTransitioning = useNavigationGetIsTransitioning(navigation);
  const getIsFocused = useNavigationGetIsFocused();
  const root = useRoot();
  const {QrCodeScreen} = root.platformSpecificComponents;
  const onCodeScanned = useCallback(
    async (code: string) => {
      if (processRef.current || !getIsFocused()) {
        return;
      }
      processRef.current = true;
      const outcome = await root.addressHistoryRepository.add(code);
      if (!outcome.success) {
        return;
      }

      const prevRoute = getPrevRoute(navigation);
      const isSendScanner = prevRoute.name === 'PromptOutputAddress';
      const receipt = root.addressParser.parse(code);
      void setStringAsync(receipt.address);

      if (!receipt.kind && !receipt.finished) {
        root.flashMessage.showMessage({
          title: strings['qrScan.validation.noCryptoCodeInQr'],
          variant: 'success',
          timeout: 6000,
        });
        onError();
        return;
      }

      if (isSendScanner) {
        navigation.dispatch(
          CommonActions.navigate({
            key: prevRoute.key,
            params: {
              ...prevRoute.params,
              addressTo: receipt.address,
              amount: receipt.amount,
            },
            merge: true,
          }),
        );
        return;
      }

      // noinspection ES6MissingAwait
      await navigateToSendByQr(receipt);
      processRef.current = false;
    },
    [
      getIsFocused,
      root.addressHistoryRepository,
      root.addressParser,
      onError,
      root.flashMessage,
      strings,
      navigation,
      navigateToSendByQr,
    ],
  );

  const onHistoryPress = useCallback(() => {
    navigation.replace('ListQrCodeHistory');
  }, [navigation]);

  const handleClose = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({index: 0, routes: [{name: 'Root'}]});
  }, [navigation]);

  return (
    <QrCodeScreen
      onClose={handleClose}
      getIsFocused={getIsFocused}
      getIsTransitioning={getIsTransitioning}
      onCodeScanned={onCodeScanned}
      onHistoryPress={onHistoryPress}
    />
  );
});

function getPrevRoute(navigation: NavigationProp<ParamListBase>) {
  const navigationState = navigation.getState();
  const currentRoteIndex = navigationState.index;
  return navigationState.routes[currentRoteIndex - 1];
}
