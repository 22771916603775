import type {DecimalString} from '@ncwallet-app/core';
import {formatCryptoValue} from '@ncwallet-app/core';
import {BigNumber} from 'bignumber.js';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import type {ForwardedRef} from 'react';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import type {TextInput} from 'react-native';

import type {InputProps} from './Input';
import {Input} from './Input';

export type DecimalInputProps = {
  onChangeDecimal: (n: DecimalString | undefined) => void;
  fractionDigits: number;
  value: DecimalString | undefined;
} & Omit<InputProps, 'onChangeText' | 'keyboardType' | 'value'>;

// int input and negative input is not supported
export default observer(
  React.forwardRef(function DecimalInput(
    props: DecimalInputProps,
    ref: ForwardedRef<TextInput>,
  ) {
    const {onChangeDecimal, value: formValue, fractionDigits} = props;
    const [inputValue, setInputValue] = useState<string>('');
    const previousInputValueRef = useRef<string>(inputValue);

    const handleChangeText = useCallback(
      (value: string) => {
        const newInputValue = value.replace(/[^0-9.,]+/g, '');
        const text = processText(newInputValue, fractionDigits);
        setInputValue(text);
        previousInputValueRef.current = text;
        if (!isInputValueEqualsFormValue(text, formValue)) {
          onChangeDecimal(fromInputValue(text));
        }
      },
      [fractionDigits, onChangeDecimal, formValue],
    );

    useEffect(() => {
      if (
        !isInputValueEqualsFormValue(previousInputValueRef.current, formValue)
      ) {
        const newInputValue = toInputValue(formValue, fractionDigits);
        setInputValue(newInputValue);
        previousInputValueRef.current = newInputValue;
      }
    }, [fractionDigits, formValue]);

    return (
      <Input
        {...props}
        ref={ref}
        onChangeText={handleChangeText}
        value={inputValue}
        keyboardType="numeric"
      />
    );
  }),
);

const toInputValue = (
  value: DecimalString | undefined,
  fractionDigits: number,
) => {
  return isNil(value) ? '' : formatCryptoValue(value, fractionDigits);
};

const fromInputValue = (value: string): DecimalString | undefined =>
  BigNumber(value).isFinite() ? value : undefined;

const isInputValueEqualsFormValue = (
  inputValue: string,
  formValue: DecimalString | undefined,
) => {
  const numberInputValue = BigNumber(inputValue);

  return isNil(formValue)
    ? !numberInputValue.isFinite()
    : numberInputValue.isEqualTo(formValue);
};

const processText = (text: string, fractionDigits: number) => {
  const matches = text.match(/\s*(\d+)[.,](\d+)\s*/);
  if (matches === null || matches.length < 3) {
    return text;
  }
  const [, left, right] = matches;
  return `${left}.${right.substring(0, fractionDigits)}`;
};
