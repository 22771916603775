import {urlLinks, useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo} from 'react';

import type {PromptOtpToDeleteAccountContainerProps} from '../../../CommonNavigationContainers/hooks/usePromptOtpToDeleteAccountContainer';
import {usePromptOtpToDeleteAccountContainer} from '../../../CommonNavigationContainers/hooks/usePromptOtpToDeleteAccountContainer';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {useSendTelegramOtp} from '../../../Navigation/hooks/useSendTelegramOtp';
import {
  CARD,
  SubmitOtpScreen,
} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptOtpToDeleteAccountBindingProps =
  SmallHomeStackBindingProps<'PromptOtpToDeleteAccount'>;

export default observer(function PromptOtpToDeleteAccountBinding({
  navigation,
}: PromptOtpToDeleteAccountBindingProps) {
  const root = useRoot();
  const strings = useStrings();
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();

  const handleTwoFaResendCode = useCallback(async () => {
    try {
      await sendOtp(null, {forceChannel: false});
    } catch {
      /* empty */
    }
  }, [sendOtp]);

  const containerProps = useMemo<PromptOtpToDeleteAccountContainerProps>(
    () => ({
      navigationReset: () => {
        navigation.reset({index: 0, routes: [{name: 'Root'}]});
      },
    }),
    [navigation],
  );

  const screenProps = usePromptOtpToDeleteAccountContainer(containerProps);

  const handleExternalLink = useCallback(() => {
    const url = urlLinks.support;
    void root.location.open(url);
  }, [root]);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (getIsReady() && getIsFocused()) {
      if (
        root.twoFaSettingsState.currentTwoFaProvider ===
        TwoFaProviderKind.Telegram
      ) {
        void handleTwoFaResendCode();
      }
    }
  }, [getIsReady, getIsFocused, root, handleTwoFaResendCode]);

  return (
    <SubmitOtpScreen
      layout={CARD}
      titleText={strings['promptOtpToDeleteAccount.title']}
      submitText={strings['promptOtpToDeleteAccount.submit']}
      onExternalLinkPress={handleExternalLink}
      twoFaProvider={root.twoFaSettingsState.currentTwoFaProvider}
      onTwoFaResendCode={handleTwoFaResendCode}
      resendCodeInterval={interval}
      resetInterval={resetInterval}
      {...screenProps}
    />
  );
});
