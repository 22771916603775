import type {ISODateString, OtpCode, OtpCredentials} from '@ncwallet-app/core';
import {FULFILLED, urlLinks, useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {CommonActions} from '@react-navigation/native';
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef} from 'react';
import {Linking, Platform} from 'react-native';

import {useBaseErrorDescription} from '../../../CommonNavigationContainers/hooks';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {useSendTelegramOtp} from '../../../Navigation/hooks/useSendTelegramOtp';
import useSetTwoFaGenerateSecret from '../../../Navigation/hooks/useSetTwoFaGenerateSecret/useSetTwoFaGenerateSecret';
import {
  CARD,
  SubmitOtpScreen,
} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptOtpToEnableTwoFaBindingProps =
  SmallHomeStackBindingProps<'PromptOtpToEnableTwoFa'>;

export default observer(function PromptOtpToEnableTwoFaBinding(
  props: PromptOtpToEnableTwoFaBindingProps,
) {
  const {navigation, route} = props;
  const {secret, onTwoFaEnabled} = route.params;
  const [getError, setError, resetError] = useBaseErrorDescription();
  const {getSecret} = useSetTwoFaGenerateSecret();
  const root = useRoot();
  const strings = useStrings();
  const _secret = getSecret();
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();

  const onSubmit = useCallback(
    async (code: string) => {
      const payload: OtpCredentials = {
        code: code as OtpCode,
        utc_2fa: dayjs().utc().toISOString() as ISODateString,
        channel: route.params.providerKind,
      };

      if (root.accountStore.state?.status !== FULFILLED) {
        return;
      }

      if (!root.accountStore.state.result.tfa) {
        payload.secret = secret ?? _secret;
      }

      const set_ = await root.ncWalletJsonRpcClient.call(
        'accounts.totp.set',
        payload,
      );

      if (!set_.success) {
        setError(set_.left);
        return;
      }

      root.flashMessage.showMessage({
        title:
          strings[
            'secureTwoFactorAuthScreen.activateTwoFactorAuth.successMessage'
          ],
        variant: 'success',
      });

      if (onTwoFaEnabled) {
        root.navigation.navigate(onTwoFaEnabled.toString());
      } else {
        navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [{name: 'Root'}, {name: 'ShowTwoFaSettings'}],
          }),
        );
      }
    },
    [
      root,
      secret,
      strings,
      onTwoFaEnabled,
      setError,
      navigation,
      route.params.providerKind,
      _secret,
    ],
  );

  const handleFaqHelpPress = useCallback(async () => {
    const faqUrl =
      Platform.OS === 'ios'
        ? urlLinks.twoFaHelpIosLink
        : urlLinks.twoFaHelpAndroidLink;
    await Linking.openURL(faqUrl);
  }, []);

  const handleTwoFaResendCode = useCallback(async () => {
    if (_secret === undefined) {
      return;
    }
    try {
      await sendOtp(
        route.params.providerKind ?? null,
        {forceChannel: true},
        _secret,
      );
    } catch {
      root.flashMessage.showMessage({
        title: 'Something went wrong. Try later',
        variant: 'danger',
      });
      return;
    }
  }, [root, sendOtp, route.params.providerKind, _secret]);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();
  const isSending = useRef(false);

  useEffect(() => {
    if (getIsReady() && getIsFocused() && _secret) {
      if (!route.params.providerKind) {
        navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [{name: 'Root'}, {name: 'ListSecuritySettings'}],
          }),
        );
        return;
      }
      if (route.params.providerKind === TwoFaProviderKind.Telegram) {
        if (!isSending.current) {
          if (
            !root.twoFaSettingsState.currentTwoFaProvider ||
            root.twoFaSettingsState.currentTwoFaProvider ===
              TwoFaProviderKind.Telegram
          ) {
            void handleTwoFaResendCode();
            isSending.current = true;
          }
        }
      }
    }
  }, [
    getIsReady,
    getIsFocused,
    _secret,
    route.params.providerKind,
    handleTwoFaResendCode,
    root.otp.telegramOtpInterval,
    root.twoFaSettingsState.currentTwoFaProvider,
    navigation,
  ]);

  return (
    <SubmitOtpScreen
      layout={CARD}
      resetError={resetError}
      getError={getError}
      twoFaProvider={route.params.providerKind}
      resendCodeInterval={interval}
      resetInterval={resetInterval}
      onTwoFaResendCode={
        !root.twoFaSettingsState.currentTwoFaProvider ||
        root.twoFaSettingsState.currentTwoFaProvider ===
          TwoFaProviderKind.Telegram
          ? handleTwoFaResendCode
          : undefined
      }
      titleText={
        strings['secureTwoFactorAuthScreen.activateTwoFactorAuth.title']
      }
      submitText={
        strings['secureTwoFactorAuthScreen.activateTwoFactorAuth.button']
      }
      onSubmit={onSubmit}
      onFaqHelpPress={handleFaqHelpPress}
      appVersion={root.deviceInfo.getAppVersionWithBuildNumber()}
    />
  );
});
