import {unwrap} from '../EitherAdapter';
import type {JsonKeyValueMap, JsonKeyValueStore} from '../JsonKeyValueStore';
import {CompactAdvertSpot} from '../KeyValueStore';
import type {AdvertId, AdvertSpot} from '../NCWalletServer';
import type {Millisecond, Second} from '../Time';
import {toSecond} from '../Time';
import AdSuspensionQueryImpl from './AdSuspensionQueryImpl';
import type {
  AdSuspensionQuery,
  AdSuspensionRepository,
} from './AdSuspensionRepository';

export default class AdSuspensionRepositoryImpl
  implements AdSuspensionRepository
{
  constructor(
    private readonly _root: {
      readonly jsonKeyValueStore: JsonKeyValueStore<JsonKeyValueMap>;
    },
  ) {}

  async createAdsQuery(): Promise<AdSuspensionQuery> {
    const record =
      (await unwrap(this._root.jsonKeyValueStore.get('ads'))) ?? {};
    return new AdSuspensionQueryImpl(record);
  }

  async suspendAd(id: AdvertId, _spot: AdvertSpot, now: Millisecond) {
    // The record is imperatively mutated here to enable the type-checking.
    // Due to some unknown reason TypeScript fails to statically detect mistypes in the immutable code.
    const record =
      (await unwrap(this._root.jsonKeyValueStore.get('ads'))) ?? {};
    const bySpotById = (record.bySpotById = record.bySpotById ?? {});
    const pickedId = (bySpotById[id] = bySpotById[id] ?? {});
    const bySpot = (pickedId.bySpot = pickedId.bySpot ?? {});
    const spot = CompactAdvertSpot[_spot];
    const suspended = (bySpot[spot] = bySpot[spot] ?? {});
    suspended.at = Math.floor(toSecond(now)) as Second;
    await unwrap(this._root.jsonKeyValueStore.set('ads', record));
  }
}
