import {useStrings} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {SafeAreaInset, SafeAreaSectionList} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {EXTRA_BOTTOM_OFFSET} from '../../constants';
import EmptySectionTitle from '../SharedComponents/EmptySectionTitle';
import {SettingsGroupTitle} from '../SharedComponents/SettingsGroupTitle';
import type {CryptoSectionItemData} from './CryptoSectionItem';
import {CryptoSectionItem} from './CryptoSectionItem';

export type CryptoSectionListProps = BaseSafeAreaProps & {
  getBaseCrypto: () => CryptoSectionItemData | undefined;
  getOtherCryptos: () => CryptoSectionItemData[] | undefined;
  onCryptoSelect: (crypto: CryptoSectionItemData) => void;
};

export type SectionInfo = {
  title: string;
  data: CryptoSectionItemData[];
  value?: string;
};

export const CryptoSectionList = observer((props: CryptoSectionListProps) => {
  const strings = useStrings();
  const {getBaseCrypto, getOtherCryptos, onCryptoSelect, ...rest} = props;
  const base = getBaseCrypto();
  const others = getOtherCryptos();
  if (!base || !others) {
    return null;
  }

  const data: SectionInfo[] = [
    {
      title: strings['updateBaseCryptScreen.currentCrypt'],
      data: [base],
    },
    {
      title: strings['updateBaseCryptScreen.allCrypt'],
      data: others,
    },
  ];
  return (
    <SafeAreaSectionList
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      stickySectionHeadersEnabled={false}
      sections={data}
      keyExtractor={(item, index) => `${index} + ${item.crypto.code}`}
      renderSectionHeader={({section}) => (
        <>
          <SettingsGroupTitle
            title={section.title}
            first={data.indexOf(section) === 0}
          />
          {others.length === 0 && data.indexOf(section) !== 0 && (
            <EmptySectionTitle />
          )}
        </>
      )}
      renderItem={({item}) => {
        return (
          <CryptoSectionItem
            onPress={() => {
              onCryptoSelect(item);
            }}
            title={item.crypto.name}
            {...item}
          />
        );
      }}
      {...rest}
    />
  );
});
