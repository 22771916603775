import {observer} from 'mobx-react-lite';
import React from 'react';

import {useLgSecuritySettingsPinCardBindingState} from '../../../../../Navigation/HomeStack/SecuritySettingsBinding/LgSecuritySettingsBinding/useLgSecuritySettingsPinCardBindingState';
import {useLgSecuritySettingsSessionsCardBindingState} from '../../../../../Navigation/HomeStack/SecuritySettingsBinding/LgSecuritySettingsBinding/useLgSecuritySettingsSessionsCardBindingState';
import {useLgSecuritySettingsTwoFaCardBindingState} from '../../../../../Navigation/HomeStack/SecuritySettingsBinding/LgSecuritySettingsBinding/useLgSecuritySettingsTwoFaCardBindingState';
import {LgSecuritySettingsScreen} from '../../../../../screens/LgSecuritySettingsScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export default observer(function LgSecuritySettingsBinding(
  props: LargeSwitchBindingProps<'ListSecuritySettings'>,
) {
  const twoFaCardProps = useLgSecuritySettingsTwoFaCardBindingState(props);
  const pinCardProps = useLgSecuritySettingsPinCardBindingState(props);
  const sessionsCardProps =
    useLgSecuritySettingsSessionsCardBindingState(props);

  return (
    <LgSecuritySettingsScreen
      {...twoFaCardProps}
      {...pinCardProps}
      {...sessionsCardProps}
      currentTwoFaProvider={twoFaCardProps.currentTwoFaProvider}
      resendCodeInterval={twoFaCardProps.interval}
      resetInterval={twoFaCardProps.resetInterval}
      onTwoFaResendCode={twoFaCardProps.handleSendTelegramOpt}
    />
  );
});
