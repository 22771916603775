import type {
  PromptAddressFormatRoute,
  RouteParams,
  ShowQrToReceiveCryptoRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransitionMap} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransitionMap';
import type {AddressType} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {getLastCreatedAddressInfo} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {useCallback} from 'react';

import {useAddressTypeSelectionBindingState} from '../../Navigation/HomeStack/LgAddressTypeSelectionBinding/useAddressTypeSelectionBindingState';

export type ContainerProps = {
  params: RouteParams<PromptAddressFormatRoute>;
  goBack: () => void;
} & RouteTransitionMap<ShowQrToReceiveCryptoRoute>;
export const useAddressTypeSelectionScreenProps = (props: ContainerProps) => {
  const {params, goBack, showQrToReceiveCrypto} = props;
  const {walletId} = params;
  const state = useAddressTypeSelectionBindingState(
    params.walletId,
    params.addressNetwork,
  );

  const handleSelect = useCallback(
    async (type: AddressType) => {
      if (state.isCreating) {
        return;
      }

      const res = await state.createAddress(type);
      if (!res.success) {
        return;
      }
      const addressInfo = getLastCreatedAddressInfo(
        res.right.addresses,
        params.addressNetwork,
        type,
      );

      if (!addressInfo) {
        return;
      }

      showQrToReceiveCrypto({
        addressCurrency: params.addressCurrency,
        addressNetwork: params.addressNetwork,
        address: addressInfo.address,
        walletId,
      });
    },
    [params, showQrToReceiveCrypto, state, walletId],
  );

  return {
    onBack: goBack,
    onSelect: handleSelect,
    addressTypes: state.addressTypes,
    defaultAddressType: state.defaultAddressType,
  };
};
