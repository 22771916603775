import {variance} from '@ncwallet-app/core';
import React, {useEffect, useState} from 'react';
import type {LayoutChangeEvent} from 'react-native';
import {View} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

type CollapsableContainerProps = {
  children: React.ReactNode;
  expanded: boolean;
  topOffset?: number;
};

// eslint-disable-next-line import-x/prefer-default-export
export const CollapsableContainer = (props: CollapsableContainerProps) => {
  const {children, expanded, topOffset = 0} = props;
  const [height, setHeight] = useState(0);

  const animatedHeight = useSharedValue(expanded ? height : 0);
  const animatedMarginTop = useSharedValue(expanded ? topOffset : 0);

  useEffect(() => {
    animatedHeight.value = withTiming(expanded ? height : 0);
    animatedMarginTop.value = withTiming(expanded ? topOffset : 0);
  }, [expanded, height, animatedHeight, animatedMarginTop, topOffset]);

  const onLayout = (event: LayoutChangeEvent) => {
    const onLayoutHeight = event.nativeEvent.layout.height;

    if (onLayoutHeight > 0 && height !== onLayoutHeight) {
      setHeight(onLayoutHeight);
    }
  };

  const collapsableStyle = useAnimatedStyle(() => {
    return {
      marginTop: animatedMarginTop.value,
      height: animatedHeight.value,
    };
  });

  return (
    <AnimatedRoot style={[collapsableStyle]}>
      <Container onLayout={onLayout}>{children}</Container>
    </AnimatedRoot>
  );
};

const AnimatedRoot = variance(Animated.View)(() => ({
  root: {
    overflow: 'hidden',
  },
}));

const Container = variance(View)(() => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
}));
