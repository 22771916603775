import {useTheme} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import {nanoid} from 'nanoid/non-secure';
import React, {useMemo} from 'react';
import type {Path} from 'react-native-redash';
import {serialize} from 'react-native-redash';
import {
  Defs,
  LinearGradient,
  Stop,
  Svg,
  Path as SvgPath,
} from 'react-native-svg';

import {CHART_HEIGHT} from './constants';

export type ChartProps = {
  chartWidth: number;
  chartHeight?: number;
  currentPath: Path;
};

export default observer(function Chart(props: ChartProps) {
  const {currentPath, chartWidth, chartHeight = CHART_HEIGHT} = props;
  const theme = useTheme();
  const id = useMemo(() => nanoid(), []);

  const serializedLinePath = useMemo(() => {
    return serialize(currentPath);
  }, [currentPath]);

  const serializedGradientPath = useMemo(() => {
    return `${serialize(
      currentPath,
    )} L ${chartWidth} ${chartHeight} L 0 ${chartHeight}`;
  }, [currentPath, chartWidth, chartHeight]);

  return (
    <Svg
      preserveAspectRatio="none"
      style={{maxWidth: chartWidth}}
      viewBox={`0 0 ${chartWidth} ${chartHeight}`}
      fill="none">
      <Defs>
        <LinearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
          <Stop
            offset="10%"
            stopColor={theme.palette.primary}
            stopOpacity={0.6}
          />
          <Stop
            offset="100%"
            stopColor={theme.palette.background}
            stopOpacity={0.3}
          />
        </LinearGradient>
      </Defs>
      <SvgPath
        d={serializedGradientPath}
        fill={`url(#${id})`}
        strokeWidth={1}
      />
      <SvgPath
        d={serializedLinePath}
        fill="transparent"
        stroke={theme.palette.primary}
        strokeWidth={1}
      />
    </Svg>
  );
});
