import {
  comparer,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';

import {unwrap} from '../EitherAdapter';
import type {ConnectionState, JsonRpcClient} from '../JsonRpc';
import {ConnectionStatus} from '../JsonRpc';
import type {_Set} from '../Mut';
import type {
  Advert,
  AdvertSpot,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {Service} from '../structure';
import type {Millisecond} from '../Time';
import {fromSecond} from '../Time';
import type {
  AdBySpotById,
  AdRepositoryState,
  ByAdvertSpot,
} from './AdRepositoryState';
import type {TimeoutsBySpotById} from './AdSuspensionRepository';

export default class AdRepositoryStateService
  implements AdRepositoryState, Service
{
  @observable.ref private _items?: Advert[];

  constructor(
    private readonly _root: {
      readonly connectionState: ConnectionState;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {
    makeObservable(this);
  }

  @computed get adBySpotById(): AdBySpotById | undefined {
    if (!this._items) {
      return undefined;
    }
    const byId: AdBySpotById<true> = new Map();
    for (const ad of this._items) {
      const bySpot = byId.get(ad.id);
      if (bySpot) {
        bySpot.set(ad.spot, ad);
      } else {
        byId.set(ad.id, new Map([[ad.spot, ad]]));
      }
    }
    return byId;
  }

  @computed get localTimeouts(): TimeoutsBySpotById | undefined {
    const {adBySpotById} = this;
    if (!adBySpotById) {
      return undefined;
    }
    return translateAdsToTimeouts(adBySpotById, _ =>
      fromSecond(_.options?.close_time ?? undefined),
    );
  }

  @computed get globalTimeouts(): TimeoutsBySpotById | undefined {
    const {adBySpotById} = this;
    if (!adBySpotById) {
      return undefined;
    }
    return translateAdsToTimeouts(adBySpotById, _ =>
      fromSecond(_.options?.ttl ?? undefined),
    );
  }

  @computed({equals: comparer.shallow})
  get spots(): _Set<AdvertSpot> | undefined {
    if (!this._items) {
      return undefined;
    }
    const spots = new Set<AdvertSpot>();
    for (const item of this._items) {
      spots.add(item.spot);
    }
    return spots;
  }

  @computed get forcedAdsBySpot(): ByAdvertSpot<readonly Advert[]> | undefined {
    if (!this._items) {
      return undefined;
    }
    return indexAdsBySpot(this._items.filter(_ => _.options?.force));
  }

  subscribe() {
    return reaction(
      () => this._root.connectionState.latestStatus === ConnectionStatus.Open,
      async isConnected => {
        if (isConnected) {
          await this._load();
        }
      },
    );
  }

  private async _load() {
    const result = await unwrap(
      this._root.ncWalletJsonRpcClient.call('ads.get', {}),
    );
    runInAction(() => {
      this._items = result.items;
    });
  }
}

function indexAdsBySpot(ads: Advert[]): ByAdvertSpot<Advert[], true> {
  const bySpot = new Map<AdvertSpot, Advert[]>();
  for (const ad of ads) {
    const list = bySpot.get(ad.spot);
    if (list) {
      list.push(ad);
    } else {
      bySpot.set(ad.spot, [ad]);
    }
  }
  return bySpot;
}

function translateAdsToTimeouts(
  adBySpotById: AdBySpotById,
  getTimeout: (_: Advert) => Millisecond | undefined,
): TimeoutsBySpotById<true> {
  const bySpotById: TimeoutsBySpotById<true> = new Map();
  for (const [id, adBySpot] of adBySpotById.entries()) {
    const bySpot = new Map<AdvertSpot, Millisecond>();
    for (const _ of adBySpot.values()) {
      const timeout = getTimeout(_);
      if (timeout !== undefined) {
        bySpot.set(_.spot, timeout);
      }
    }
    if (bySpot.size > 0) {
      bySpotById.set(id, bySpot);
    }
  }
  return bySpotById;
}
