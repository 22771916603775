import {useStrings, useTemplates, variance} from '@ncwallet-app/core';
import {Tooltip, Switch as UISwitch} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Text, View} from 'react-native';

export type CommissionSwitcherProps = {
  isEnoughAmountForFreeSend: boolean;
  minFreeAmount: string | undefined;
  getHasNoCommission: () => boolean;
  setHasNoCommission: (value: boolean) => void;
  wideStyle?: boolean;
  zeroOffset?: boolean;
};

export const CommissionSwitcher = observer((props: CommissionSwitcherProps) => {
  const strings = useStrings();
  const templates = useTemplates();
  const {setHasNoCommission} = props;

  const switchHasCommission = useCallback(
    (prevValue: boolean) => {
      setHasNoCommission(!prevValue);
    },
    [setHasNoCommission],
  );

  return (
    <CommissionSwitcherContainer
      wideStyle={props.wideStyle}
      zeroOffset={props.zeroOffset}>
      <CommissionSwitcherText>
        {strings['sendCrypto.comission']}
      </CommissionSwitcherText>
      <InfoTooltip
        title={strings['sendCrypto.comission.tooltip.title']}
        text={templates['sendCrypto.minerFee.info']({
          minFreeAmount: props.minFreeAmount,
        })}
        closeText={strings['sendCrypto.comission.tooltip.close']}
      />

      <Switch
        disabled={!props.isEnoughAmountForFreeSend}
        getIsActive={props.getHasNoCommission}
        onChange={switchHasCommission}
      />
    </CommissionSwitcherContainer>
  );
});

const Switch = variance(UISwitch)(() => ({
  root: {
    marginLeft: 'auto',
  },
}));

const CommissionSwitcherContainer = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
  wideStyle: {
    backgroundColor: theme.palette.uiPrimary,
    marginTop: 26,
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginHorizontal: 0,
    marginBottom: 0,
    borderRadius: 8,
    borderWidth: 1,
    width: 260,
    maxHeight: 50,
  },
  zeroOffset: {
    marginHorizontal: 0,
    paddingTop: 0,
  },
}));

const CommissionSwitcherText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    color: theme.palette.textPrimary,
  },
}));

const InfoTooltip = variance(Tooltip)(() => ({
  root: {
    marginLeft: 8,
    marginRight: 'auto',
    zIndex: 5000,
  },
}));
