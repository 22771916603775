import {variance} from '@ncwallet-app/core';
import {Button} from '@ncwallet-app/ui/src';
import {Text} from 'react-native';

export const LimitFormSubmitButton = variance(Button)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

export const LimitError = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    textAlign: 'center',
    marginVertical: 10,
    color: theme.palette.error,
    fontSize: 13,
  },
}));
