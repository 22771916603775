import {BigNumber} from 'bignumber.js';
import dayjs from 'dayjs';

import type {CurrencyCode, DecimalString, RateValue} from '../../Money';
import type {ISODateString} from '../../Time';
import {first, last} from '../../util';

export type CurrenciesRateHistory<
  From extends CurrencyCode = CurrencyCode,
  To extends CurrencyCode = CurrencyCode,
> = {
  params: CurrenciesRateHistoryParams;
  result: CurrenciesRateHistoryResult<From, To>;
};

export type CurrenciesRateHistoryParams = {
  from_currency: CurrencyCode;
  to_currency: CurrencyCode;
  interval: 'daily' | 'hourly';
  time_start: ISODateString;
  time_end: ISODateString;
};

export type CurrenciesRateHistoryResult<
  From extends CurrencyCode,
  To extends CurrencyCode,
> = {
  items: CurrenciesRateHistoryItem<From, To>[];
};

export type CurrenciesRateHistoryItem<
  From extends CurrencyCode = CurrencyCode,
  To extends CurrencyCode = CurrencyCode,
> = {
  timestamp: ISODateString;
  rate: RateValue<From, To>;
};

export const getHistoryItemChange = <
  From extends CurrencyCode,
  To extends CurrencyCode,
>(
  items: CurrenciesRateHistoryItem<From, To>[],
  allPeriod: boolean = true,
  isDaily: boolean = false,
): DecimalString => {
  if (items.length < 2) {
    return '0';
  }
  const to = last(items);
  let from;
  if (isDaily) {
    from = getFirstHistoryItemOfLastDay(items);
  } else if (allPeriod) {
    from = items[0];
  } else {
    from = items[items.length - 2];
  }

  if (!to || !from || BigNumber(from.rate).isLessThanOrEqualTo(0)) {
    return '0';
  }

  return BigNumber(from.rate).minus(to.rate).div(from.rate, 10).toString();
};

function getFirstHistoryItemOfLastDay(items: CurrenciesRateHistoryItem[]) {
  const sorted = [...items].sort((a, b) =>
    dayjs(a.timestamp).isAfter(dayjs(b.timestamp)) ? 1 : -1,
  );
  const fistDayItem =
    sorted.find(item => item.timestamp.includes('T00')) ?? first(sorted);
  if (!fistDayItem) {
    return undefined;
  }
  return fistDayItem;
}

export const toLineChartDatum = (c: CurrenciesRateHistoryItem) => ({
  value: parseFloat(c.rate),
});
