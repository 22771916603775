import {useRoot} from '@ncwallet-app/core';
import {useState} from 'react';

import {PromptToSelectVerificationCountryBindingState} from './PromptToSelectVerificationCountryBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const usePromptToSelectVerificationCountryBindingState = () => {
  const root = useRoot();

  const [state] = useState(
    () => new PromptToSelectVerificationCountryBindingState(root),
  );

  return state;
};
