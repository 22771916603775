import type {
  Either,
  ErrorRepository,
  GlobalError,
} from '@ncwallet-app/core/src';
import {
  BusImpl,
  error,
  NATIVE_METHOD_ERROR,
  success,
  UNKNOWN_ERROR,
} from '@ncwallet-app/core/src';
import type {Analytics} from '@ncwallet-app/core/src/Analytics';
import type {Json} from '@ncwallet-app/core/src/Json';
import type {
  HeadlessLocalNotifications,
  LocalNotificationContext,
  LocalNotificationId,
  LocalNotifications,
} from '@ncwallet-app/core/src/LocalNotifications';
import {EventType} from '@ncwallet-app/core/src/LocalNotifications';
import type {Location} from '@ncwallet-app/core/src/Location';
import type {
  HeadlessMessagingHelper,
  MessageContext,
} from '@ncwallet-app/core/src/Messaging';

import BaseWebHeadlessLocalNotificationsImpl from './BaseWebHeadlessLocalNotificationsImpl';

export default class WebHeadlessLocalNotificationsImpl
  extends BaseWebHeadlessLocalNotificationsImpl
  implements HeadlessLocalNotifications, LocalNotifications
{
  constructor(
    protected readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly json: Json;
      readonly analytics: Analytics;
      readonly location: Location;
      readonly headlessMessagingHelper: HeadlessMessagingHelper;
    },
  ) {
    super(_root);
  }

  public income = new BusImpl<(context: LocalNotificationContext) => void>();

  async scheduleNotification(
    context: MessageContext,
  ): Promise<Either<void, GlobalError>> {
    const result_ = await super.scheduleNotification(context);
    if (result_.success || result_.left.kind !== NATIVE_METHOD_ERROR) {
      return result_;
    }
    const data_ = await this._wrapContext(context);
    if (!data_.success) {
      return data_;
    }
    try {
      const args = this._getNotificationArguments(context, data_.right, false);
      const notification = new window.Notification(...args);
      notification.addEventListener('click', async event => {
        event.preventDefault();
        const _context: LocalNotificationContext = {
          id: notification.tag as LocalNotificationId | undefined,
          type: EventType.Press,
          data: data_.right,
        };
        this.income.send(_context);
        await this.handle(_context);
      });
      notification.addEventListener('close', async () => {
        const _context: LocalNotificationContext = {
          id: notification.tag as LocalNotificationId | undefined,
          type: EventType.Dismissed,
          data: data_.right,
        };
        this.income.send(_context);
        await this.handle(_context);
      });
      notification.addEventListener('show', async () => {
        const _context: LocalNotificationContext = {
          id: notification.tag as LocalNotificationId | undefined,
          type: EventType.Delivered,
          data: data_.right,
        };
        this.income.send(_context);
        await this.handle(_context);
      });
    } catch (raw) {
      return error(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          raw,
        }),
      );
    }
    return success();
  }

  protected _getRegistration(): Promise<ServiceWorkerRegistration> {
    return window.navigator.serviceWorker.ready;
  }
}
