import type {Millisecond} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {AdvertContext} from '@ncwallet-app/core/src/Advert';
import type {LargeSwitchParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {NavigationState, PartialState} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import InstallTelegramScreen, {
  InstallTelegramModal,
} from '../../screens/InstallTelegramScreen';
import useAdvertProps from './useAdvertProps';

export type InstallTelegramSpotBindingProps = {
  context: AdvertContext;
  hideAd: (now: Millisecond) => Promise<void>;
};

export default observer(function InstallTelegramSpotBinding(
  props: InstallTelegramSpotBindingProps,
) {
  const {context, hideAd} = props;
  const {navigationContainer, layoutHelperState} = useRoot();
  const [closeBanner, onActionPress, actions] = useAdvertProps(context, hideAd);
  const isLarge = layoutHelperState.isLarge;

  const handleLinkingEmail = useCallback(() => {
    const switchState: PartialState<NavigationState<LargeSwitchParamList>> = {
      index: 0,
      routes: [{name: 'ShowProfile', params: {target: 'email'}}],
    };
    void closeBanner();
    navigationContainer.ref?.dispatch(CommonActions.reset(switchState));
  }, [closeBanner, navigationContainer.ref]);

  return isLarge ? (
    <InstallTelegramModal
      body={context.text.body}
      title={context.text.title}
      icon={context.images.icon}
      actions={actions}
      onActionPress={onActionPress}
      onClose={closeBanner}
      handleLinkingEmail={handleLinkingEmail}
    />
  ) : (
    <InstallTelegramScreen
      body={context.text.body}
      title={context.text.title}
      icon={context.images.icon}
      actions={actions}
      onActionPress={onActionPress}
      onClose={closeBanner}
      handleLinkingEmail={handleLinkingEmail}
    />
  );
});
