import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import type {InputNetworkSelectionContainerProps} from '../../../CommonNavigationContainers/hooks/useInputNetworkSelectionScreenProps';
import {useInputNetworkSelectionScreenProps} from '../../../CommonNavigationContainers/hooks/useInputNetworkSelectionScreenProps';
import NetworkSelectionScreen from '../../../screens/NetworkSelectionScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptInputNetworkBindingProps =
  SmallHomeStackBindingProps<'PromptInputNetwork'>;

export default observer(function PromptInputNetworkBinding(
  props: PromptInputNetworkBindingProps,
) {
  const {navigation, route} = props;

  const containerProps = useMemo<InputNetworkSelectionContainerProps>(
    () => ({
      promptAddressFormat: params => {
        navigation.navigate('PromptAddressFormat', params);
      },
      showQrToReceiveCrypto: params => {
        navigation.navigate('ShowQrToReceiveCrypto', params);
      },
      params: route.params,
    }),
    [navigation, route.params],
  );

  const screenParams = useInputNetworkSelectionScreenProps(containerProps);

  return <NetworkSelectionScreen {...screenParams} />;
});
