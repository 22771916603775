import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import {nanoid} from 'nanoid';
import React, {useRef} from 'react';
import {Defs, G, Mask, Path, Rect, Svg} from 'react-native-svg';

import {FRAME} from './constants';
import PositionHelperStatic from './PositionHelperStatic';

// eslint-disable-next-line import-x/prefer-default-export
export const CameraBackgroundMask = observer(() => {
  const window = useRoot().windowDimensionsState.window;
  const frameLeft = expr(() =>
    PositionHelperStatic.getFrameLeftPosition(window),
  );
  const frameTop = expr(() => PositionHelperStatic.getFrameTopPosition(window));
  const topBorderPosition = expr(() =>
    PositionHelperStatic.getFrameTopPosition(window, true),
  );
  const leftBorderPosition = expr(() =>
    PositionHelperStatic.getFrameLeftPosition(window, true),
  );
  const maskId = useRef(nanoid());
  return (
    <Svg width="100%" height="100%">
      <Defs>
        <Mask id={maskId.current} x="0" y="0">
          <Rect x="0" y="0" width="100%" height="100%" fill="#666666" />
          <Rect
            x={frameLeft}
            y={frameTop}
            width={FRAME}
            height={FRAME}
            fill="#000000"
          />
        </Mask>
      </Defs>
      <Rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill="#000000"
        mask={`url(#${maskId.current})`}
      />
      <G x={leftBorderPosition} y={topBorderPosition}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280 18.6667C280 8.35735 271.643 0 261.333 0H186.666V7.46667H263.066C268.294 7.46667 272.533 11.705 272.533 16.9333V93.3333H280V18.6667ZM93.3326 7.46667H16.9326C11.7043 7.46667 7.46594 11.705 7.46594 16.9333V93.3333H0V18.6667C0 8.35735 8.35735 0 18.6667 0H93.3326V7.46667ZM93.3326 272.533V280H18.6667C8.35735 280 0 271.643 0 261.333V186.667H7.46594V263.067C7.46594 268.295 11.7043 272.533 16.9326 272.533H93.3326ZM186.666 280H261.333C271.643 280 280 271.643 280 261.333V186.667H272.533V263.067C272.533 268.295 268.294 272.533 263.066 272.533H186.666V280Z"
          fill="#F7931A"
        />
      </G>
    </Svg>
  );
});
