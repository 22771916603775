import {useTheme, variance} from '@ncwallet-app/core';
import type {PressableProps} from '@ncwallet-app/ui/src/components/';
import {Pressable} from '@ncwallet-app/ui/src/components/';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

export type TabBarItemProps = PressableProps & {
  isFocused: boolean;
  label: string;
  icon:
    | ((props: {
        focused: boolean;
        color: string;
        size: number;
      }) => React.ReactNode)
    | undefined;
};

export const TabBarItem = observer((props: TabBarItemProps) => {
  const {isFocused, label, icon, ...rest} = props;
  const theme = useTheme();
  const color = isFocused ? theme.palette.primary : theme.palette.uiMain;
  return (
    <Pressable
      style={styles.root}
      accessibilityRole="button"
      accessibilityState={isFocused ? {selected: true} : {}}
      {...rest}>
      {icon && (
        <IconContainer>
          {icon({focused: isFocused, color, size: 30})}
        </IconContainer>
      )}
      <Label style={{color}}>{label}</Label>
    </Pressable>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
  },
});

const Label = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('600'),
    marginBottom: 3,
    fontSize: 10,
    color: theme.palette.textPrimary,
  },
}));

const IconContainer = variance(View)(() => ({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
