import {
  useDateFormatting,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import type {SessionId} from '@ncwallet-app/core/src/NCWalletServer/sessions/Session';
import {DeviceIcon, PressableNativeFeedback} from '@ncwallet-app/ui/src';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

import type {SessionInfo} from '../../ActiveSessionsScreen/SessionInfo';

export type SessionListItemProps = {
  session: SessionInfo;
  isHighlighted?: boolean;
  isSingle?: boolean;
  isFirst?: boolean;
  onPress?: (sessionId: SessionId) => void;
};

export default observer(function SessionActiveListItem(
  props: SessionListItemProps,
) {
  const {session, isHighlighted, isSingle, isFirst, onPress: _onPress} = props;
  const theme = useTheme();
  const strings = useStrings();

  const {formatDateTimeWithYear} = useDateFormatting();
  const date = formatDateTimeWithYear(props.session.time);
  const status =
    session.status === 'blocked'
      ? strings['secureSettingsScreen.blocked']
      : session.hasActiveConnection
        ? strings['secureSettingsScreen.activeSessions.onlineStatus']
        : strings['secureSettingsScreen.activeSessions.loggedInStatus'];

  const disabled = _onPress === undefined;
  const onPress = useMemo(
    () =>
      _onPress &&
      (() => {
        _onPress(session.id);
      }),
    [_onPress, session.id],
  );

  return (
    <SessionItemView
      disabled={disabled}
      onPress={onPress}
      isHighlighted={isHighlighted}
      single={isSingle}
      isBorderVisible={!isFirst}
      first={isFirst}>
      <SessionItemIconView>
        <DeviceIcon type={session.platform} />
      </SessionItemIconView>
      <SessionItemBodyView>
        <SessionItemTitleWrapper>
          <SessionDate>{date}</SessionDate>
          <Status
            error={session.status === 'blocked'}
            hasActiveConnection={session.hasActiveConnection}>
            {status}
          </Status>
        </SessionItemTitleWrapper>
        <SessionItemTitle ellipsizeMode={'tail'} numberOfLines={1}>
          {session.title}
        </SessionItemTitle>

        <SessionItemInfo testID="ip">{session.info}</SessionItemInfo>
      </SessionItemBodyView>
      {onPress && (
        <SessionItemAction onPress={onPress}>
          <CrossSvg color={theme.palette.uiSecondary} />
        </SessionItemAction>
      )}
    </SessionItemView>
  );
});

const SessionItemView = variance(PressableNativeFeedback)(
  theme => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
      paddingRight: 15,
      borderTopWidth: 1,
      borderColor: theme.palette.uiSecondary,
    },
    isHighlighted: {
      borderColor: theme.palette.additional2,
      backgroundColor: theme.palette.additional4,
    },
    first: {
      borderTopWidth: 0,
    },
    single: {
      borderRadius: 8,
      borderWidth: 1,
    },
  }),
  theme => ({
    borderStyle: {
      borderTopWidth: 1,
      borderColor: theme.palette.uiSecondary,
    },
  }),
);

const SessionItemIconView = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const SessionItemBodyView = variance(View)(() => ({
  root: {
    flex: 1,
    marginLeft: 15,
  },
}));

const SessionItemTitleWrapper = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const SessionDate = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 11,
  },
}));

const SessionItemTitle = variance(Text)(theme => ({
  root: {
    flex: 1,
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 13,
  },
}));

const SessionItemInfo = variance(Text)(theme => ({
  root: {
    marginTop: 5,
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 12,
  },
}));

const SessionItemAction = variance(PressableNativeFeedback)(() => ({
  root: {
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    marginLeft: 'auto',
  },
}));

const Status = variance(Text)(theme => ({
  root: {
    marginLeft: 5,
    ...theme.fontByWeight('500'),
    fontSize: 11,
    lineHeight: 15,
    color: theme.palette.success,
  },
  error: {
    color: theme.palette.error,
  },
  hasActiveConnection: {
    color: theme.palette.info,
  },
}));
