import {useTheme, variance} from '@ncwallet-app/core';
import {TouchableWithoutFeedback} from '@ncwallet-app/ui';
import {BlurView} from 'expo-blur';
import {observer} from 'mobx-react-lite';
import type {ReactNode} from 'react';
import React from 'react';
import {StyleSheet, View} from 'react-native';

export type SettingsOverlayProps = {
  onMenuClose: () => void;
  children?: ReactNode;
};

export default observer(function SettingsOverlay(props: SettingsOverlayProps) {
  const {onMenuClose, children} = props;
  const theme = useTheme();
  return (
    <View style={StyleSheet.absoluteFill}>
      <TouchableWithoutFeedback
        onPress={onMenuClose}
        style={StyleSheet.absoluteFill}
        containerStyle={StyleSheet.absoluteFill}>
        <BlurView
          tint={theme.select('light', 'dark')}
          intensity={15}
          style={StyleSheet.absoluteFill}>
          <BlackOverlay style={StyleSheet.absoluteFill} />
        </BlurView>
      </TouchableWithoutFeedback>
      <View style={styles.wrapper}>
        <Container>{children}</Container>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  wrapper: {
    pointerEvents: 'box-none',
    ...StyleSheet.absoluteFillObject,
  },
});

const BlackOverlay = variance(View)(theme => ({
  root: {
    backgroundColor: theme.chroma('1E1F21').alpha(0.2).hex(),
  },
}));

const Container = variance(View)(() => ({
  root: {
    pointerEvents: 'box-none',
    maxWidth: 1180,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
