import type {AvailableLanguageCode} from './constant';
import type {LanguageTranslationBridge} from './LanguageTranslationBridge';
import type {SystemLanguageProvider} from './SystemLanguageProvider';
import type {SystemLocaleProvider} from './SystemLocaleProvider';

export default class SystemLanguageProviderImpl
  implements SystemLanguageProvider
{
  constructor(
    private readonly _root: {
      readonly systemLocaleProvider: SystemLocaleProvider;
      readonly languageTranslationBridge: LanguageTranslationBridge;
    },
  ) {}

  getLanguage(): AvailableLanguageCode {
    const code = this._root.systemLocaleProvider.getLocales()[0].languageCode;
    return this._root.languageTranslationBridge.pickClosestLanguage(code);
  }
}
