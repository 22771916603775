import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, reaction, runInAction} from 'mobx';
import {useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {CurrencyHistoryRefresher} from '../../../shared/CurrencyHistoryRefresher';
import {CurrencySelectionListConverter} from '../../../shared/CurrencySelectionListConverter';
import {useRateHistoryStateOnWideScreen} from '../../../shared/useRateHistoryStateOnWideScreen';
import SelectionForSendBindingState from './SelectionForSendBindingState';

export default function useSelectionForSendBindingState() {
  const root = useRoot();
  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  const [state] = useState(
    () =>
      new SelectionForSendBindingState(
        root,
        new CurrencySelectionListConverter(root),
        new CurrencyHistoryRefresher(root),
      ),
  );

  useEffect(() => state.subscribe(), [state]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, state],
  );

  useEffect(
    () =>
      reaction(
        () => state.isTimeoutError,
        shouldFire => {
          if (shouldFire) {
            root.rpcTimeoutErrorVisibility.registerAction(async () => {
              await root.walletsState.refresh();
              await state.refresh();
            });
          }
        },
      ),
    [state, root.rpcTimeoutErrorVisibility, root.walletsState],
  );

  useRateHistoryStateOnWideScreen(state);

  return state;
}
