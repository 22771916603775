import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
  Qr,
  SafeAreaScrollView,
  SM_BREAKPOINT,
  TouchableOpacity,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  CopySvg,
  DownloadSvg,
  KeySvg,
  QrSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import isWebIOS from '../../../Navigation/hooks/useSetTwoFaGenerateSecret/isWebIOS';

export type TwoFaSecretScreenProps = BaseSafeAreaProps & {
  providerKind?: TwoFaProviderKind;
  onFaqHelpPress?: () => void;
  getSecret: () => string | undefined;
  getOtpAuthUri: () => string;
  getError: () => string | undefined;
  getHasAuthenticator: () => boolean | undefined;
  onOpenAuthenticator: () => void;
  onDirectlyOpenAuthenticator: () => void;
  onInstallAuthenticator: () => void;
  onCopySecret: () => void;
  onEnterCode: () => void;
  goToSetCode?: () => void;
  goToAuth?: () => void;
};

export default observer((props: TwoFaSecretScreenProps) => {
  const {
    providerKind,
    getSecret,
    getOtpAuthUri,
    getError,
    getHasAuthenticator,
    onOpenAuthenticator,
    onDirectlyOpenAuthenticator,
    onInstallAuthenticator,
    onCopySecret,
    onEnterCode,
    goToSetCode,
    goToAuth,
    onFaqHelpPress,
    ...rest
  } = props;
  const [isQrShown, setIsQrShown] = useState(false);
  const toggleQrVisibility = useCallback(() => {
    setIsQrShown(_ => !_);
  }, []);
  const strings = useStrings();
  const hasAuthenticator = getHasAuthenticator();
  const secret = getSecret();
  const theme = useTheme();
  const isLg = useIsDimensions('lg');
  return (
    <SafeAreaScrollView
      contentContainerStyle={isLg ? styles.wide : styles.content}
      {...rest}>
      <TopTextContainer>
        <TitleText>{strings['secureTwoFactorAuthScreen.title']}</TitleText>
        {onFaqHelpPress && (
          <TouchableOpacity onPress={onFaqHelpPress} hitSlop={HIT_SLOP}>
            <HelpText>{strings['2fa.tooltip']}</HelpText>
          </TouchableOpacity>
        )}
      </TopTextContainer>
      <Label>{strings['secureTwoFactorAuthScreen.label']}</Label>
      {secret !== undefined && (
        <KeyBlock>
          {isQrShown ? (
            <QrContainer>
              <Qr text={getOtpAuthUri()} />
            </QrContainer>
          ) : (
            <CodeContainer>
              <TextCode numberOfLines={2} testID="2fa-key">
                {secret}
              </TextCode>
              <TouchableOpacity onPress={onCopySecret}>
                <CopySvg color={theme.palette.primary} />
              </TouchableOpacity>
            </CodeContainer>
          )}
        </KeyBlock>
      )}

      <Col force>
        {isQrShown ? (
          <SecurityButton
            variant={ButtonVariant.PrimaryLight}
            color={ButtonColor.Secondary}
            title={strings['secureTwoFactorAuthScreen.buttonKey']}
            Icon={KeySvg}
            dataSet={{['key']: 'true'}}
            onPress={toggleQrVisibility}
          />
        ) : (
          <SecurityButton
            variant={ButtonVariant.PrimaryLight}
            color={ButtonColor.Secondary}
            title={strings['secureTwoFactorAuthScreen.buttonQRcode']}
            Icon={QrSvg}
            onPress={toggleQrVisibility}
          />
        )}
        {providerKind === TwoFaProviderKind.Akm && (
          <SecurityButton
            style={styles.button}
            title={strings['secureTwoFactorAuthScreen.open']}
            color={ButtonColor.Secondary}
            variant={ButtonVariant.PrimaryLight}
            onPress={onOpenAuthenticator}
          />
        )}
        {!isLg && providerKind !== TwoFaProviderKind.Akm && (
          <AuthButton
            providerKind={providerKind}
            hasAuthenticator={hasAuthenticator}
            onInstallAuthenticator={onInstallAuthenticator}
            onOpenAuthenticator={onOpenAuthenticator}
            onDirectlyOpenAuthenticator={onDirectlyOpenAuthenticator}
          />
        )}
      </Col>
      {isLg && !isQrShown && (
        <WarningTwoFa>
          {strings['secureTwoFactorAuthScreen.warningText']}
        </WarningTwoFa>
      )}

      {!isLg && (
        <WarningTwoFa>
          {strings['secureTwoFactorAuthScreen.warningText']}
        </WarningTwoFa>
      )}

      {getError() !== undefined && <WarningTwoFa>{getError()}</WarningTwoFa>}

      {isLg ? (
        <Col offsetTop>
          <SecurityButton
            style={styles.button}
            title={strings['secureTwoFactorAuthScreen.enableButton']}
            color={ButtonColor.Secondary}
            variant={ButtonVariant.PrimaryLight}
            onPress={goToSetCode}
            testID="2fa-enable"
          />
          {providerKind === TwoFaProviderKind.Akm ? (
            <></>
          ) : (
            <>
              <SecurityButton
                style={styles.button}
                title={strings['secureTwoFactorAuthScreen.shortInstallBtn']}
                color={ButtonColor.Secondary}
                variant={ButtonVariant.PrimaryLight}
                onPress={goToAuth}
              />
            </>
          )}
        </Col>
      ) : (
        <Button
          style={styles.button}
          title={strings['secureTwoFactorAuthScreen.enableButton']}
          color={ButtonColor.Secondary}
          variant={ButtonVariant.Highlighted}
          onPress={onEnterCode}
          testID="2fa-enable"
        />
      )}
    </SafeAreaScrollView>
  );
});

type AuthButtonProps = {
  providerKind?: TwoFaProviderKind;
  onOpenAuthenticator: () => void;
  onInstallAuthenticator: () => void;
  onDirectlyOpenAuthenticator: () => void;
  hasAuthenticator: boolean | undefined;
};

const AuthButton = observer((props: AuthButtonProps) => {
  const {
    onOpenAuthenticator,
    onInstallAuthenticator,
    providerKind,
    hasAuthenticator,
  } = props;
  const strings = useStrings();
  const install = (
    <SecurityButton
      variant={ButtonVariant.PrimaryLight}
      color={ButtonColor.Secondary}
      title={strings['secureTwoFactorAuthScreen.install']}
      Icon={DownloadSvg}
      onPress={onInstallAuthenticator}
    />
  );
  return hasAuthenticator === undefined ? (
    <SecurityButton
      variant={ButtonVariant.PrimaryLight}
      color={ButtonColor.Default}
      disabled
      title={strings['secureTwoFactorAuthScreen.open']}
    />
  ) : hasAuthenticator ? (
    <SecurityButton
      variant={ButtonVariant.PrimaryLight}
      color={ButtonColor.Secondary}
      title={strings['secureTwoFactorAuthScreen.open']}
      disabled={providerKind === TwoFaProviderKind.Ga && isWebIOS()}
      onPress={onOpenAuthenticator}
    />
  ) : (
    install
  );
});

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 15,
    paddingTop: 20,
  },
  wide: {
    paddingTop: 20,
    paddingBottom: 20,
    padding: 20,
  },
  button: {
    marginTop: 'auto',
  },
  input: {
    marginTop: 30,
    marginBottom: 30,
  },
});

const CodeContainer = variance(View)(theme => ({
  root: {
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.additional4,
    borderRadius: 6,
    padding: 18,
    minHeight: 90,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    ...theme.mediaQuery({
      [SM_BREAKPOINT]: {
        flexDirection: 'row',
      },
      [LG_BREAKPOINT]: {
        borderColor: theme.palette.additional2,
      },
    }),
  },
}));

const TopTextContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

const KeyBlock = variance(View)(theme => ({
  root: {
    height: 186,
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        height: 'auto',
        maxHeight: 160,
      },
    }),
  },
}));

const TextCode = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    marginRight: 0,
    lineHeight: 26,
    color: theme.palette.primary,
    textAlign: 'center',
    textAlignVertical: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 10,
    ...theme.mediaQuery({
      [SM_BREAKPOINT]: {
        marginBottom: 0,
        marginRight: 10,
      },
    }),
  },
}));

const TitleText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 14,
    lineHeight: 20,
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 5,
    color: theme.palette.textAdditional1,
  },
}));

const HelpText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.info,
  },
}));

const Col = variance(View)(theme => ({
  root: {
    flexDirection: 'column',
    rowGap: 15,
    columnGap: 7,
    marginTop: 15,
    ...theme.mediaQuery({
      [SM_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
  force: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'column',
      },
    }),
  },
  offsetTop: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginTop: 'auto',
      },
    }),
  },
}));

const WarningTwoFa = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    textAlign: 'center',
    color: theme.palette.error,
    fontSize: 14,
    lineHeight: 20,
    marginTop: 15,
    marginBottom: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        textAlign: 'left',
      },
    }),
  },
}));

const SecurityButton = variance(Button)(theme => ({
  root: {
    flex: 1,
    width: '100%',
    borderColor: theme.palette.uiSecondary,
  },
}));

const Label = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.textPrimary,
    marginTop: 20,
  },
}));

const QrContainer = variance(View)(theme => ({
  root: {
    width: 160,
    height: 160,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: '#fff',
    padding: 12,
  },
}));

const HIT_SLOP = {top: 20, bottom: 20, left: 20, right: 20};
