import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {useIsExchangeAllowed} from '@ncwallet-app/navigation/src/Navigation/hooks/useIsExchangeAllowed';
import type {
  BaseSafeAreaProps,
  CurrencySelectionListItemData,
} from '@ncwallet-app/ui';
import {
  Button,
  CommonRefreshControl,
  CurrenciesListState,
  CurrencySelectionList,
  CurrencySelectionViewContainer,
  CurrencySkeletonList,
  FilteredToEmptyCurrencySelectionList,
  getCurrenciesListState,
  SelectionCryptoTopMessage,
  useIsDimensions,
  WalletActionKind,
  WalletActionList,
} from '@ncwallet-app/ui';
import {ArrowLeftWide} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {StyleSheet, View} from 'react-native';

import EmptyWalletList from '../../shared/EmptyWalletList';
import CurrencyListItem from '../CurrencyScreen/CurrencyListItem';
import type {ScreenType} from '../SelectionForExchangeScreen/ScreenType';
import SelectionAdditionalOptions from './SelectionAdditionalOptions';

export type SelectionForSendScreenProps = BaseSafeAreaProps & {
  isLg: boolean;
  screenType?: ScreenType;
  shownCurrencies: CurrencySelectionListItemData[] | undefined;
  allCurrencies: CurrencySelectionListItemData[] | undefined;
  onCurrencyPress: (currency: CurrencySelectionListItemData) => void;
  getIsRefreshing: () => boolean;
  onRefresh: () => void;
  onBack?: () => void;
  onExchangePress: () => void;
  onReceivePress: () => void;
  onAddPress: () => void;
};

export default observer((props: SelectionForSendScreenProps) => {
  const {
    getIsRefreshing,
    onRefresh,
    allCurrencies,
    shownCurrencies,
    onExchangePress,
    onReceivePress,
    onBack,
    onAddPress,
    ...rest
  } = props;
  const strings = useStrings();
  const isLg = useIsDimensions('lg');
  const theme = useTheme();

  const listState = getCurrenciesListState(allCurrencies, shownCurrencies);
  const {isExchangeAllowed} = useIsExchangeAllowed();

  const refreshControl = useMemo(
    () => (
      <CommonRefreshControl
        getIsRefreshing={getIsRefreshing}
        onRefresh={onRefresh}
      />
    ),
    [getIsRefreshing, onRefresh],
  );

  const SelectionListFooter = useMemo(() => {
    return isExchangeAllowed ? (
      <SelectionAdditionalOptions onExchangePress={onExchangePress} />
    ) : undefined;
  }, [onExchangePress, isExchangeAllowed]);

  return (
    <CurrencySelectionViewContainer>
      {isLg && (
        <Header>
          <BackButton
            title={
              listState === CurrenciesListState.Empty
                ? strings['screenTitle.sendCrypto']
                : strings.backToChooseCoin
            }
            onPress={onBack}
            Icon={ArrowLeftWide}
            iconCustomColor={theme.palette.uiAdditional1}
          />
        </Header>
      )}

      {listState === CurrenciesListState.Loading && (
        <CurrencySkeletonList itemCount={4} />
      )}

      {listState === CurrenciesListState.Normal && (
        <CurrencySelectionList
          currencies={shownCurrencies}
          refreshControl={refreshControl}
          ListFooter={SelectionListFooter}
          {...rest}
        />
      )}

      {listState === CurrenciesListState.FilteredToEmpty && (
        <FilteredToEmptyCurrencySelectionList
          refreshControl={refreshControl}
          {...props}
        />
      )}

      {listState === CurrenciesListState.Empty &&
        (isLg ? (
          <NoCurrenciesView
            onReceivePress={onReceivePress}
            onAddPress={onAddPress}
          />
        ) : (
          <EmptyWalletList
            title={strings['selectionForSend.empty.title']}
            description={strings['selectionForExchange.empty.text']}
            onReceivePress={onReceivePress}
            onAddPress={onAddPress}
          />
        ))}
    </CurrencySelectionViewContainer>
  );
});

const BackButton = variance(Button)(() => ({
  root: {
    marginBottom: 20,
    padding: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));

const Header = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 60,
  },
}));

type NoCurrenciesViewProps = {
  onReceivePress?: () => void;
  onAddPress: () => void;
};

export const NoCurrenciesView = (props: NoCurrenciesViewProps) => {
  return (
    <>
      <SelectionCryptoTopMessage
        texts={{
          title: 'selectionForSend.empty.title',
          message: 'selectionForExchange.empty.text',
        }}
      />
      <WalletActionList contentContainerStyle={styles.container}>
        {props.onReceivePress && (
          <CurrencyListItem
            type={WalletActionKind.Receive}
            onPress={props.onReceivePress}
            dataSet={{app: 'currency-item'}}
          />
        )}
        <CurrencyListItem
          type={WalletActionKind.Add}
          onPress={props.onAddPress}
          dataSet={{app: 'currency-item'}}
        />
      </WalletActionList>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 15,
  },
});
