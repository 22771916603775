import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {LogsScreen} from '../../screens/LogsScreen';

export default observer(function ListLogsContainer() {
  const {log, logFilter} = useRoot();
  const clearLogs = useCallback(() => {
    log.reset();
  }, [log]);
  return (
    <LogsScreen
      onButtonPress={clearLogs}
      logRecords={logFilter.records}
      buttonText="Clear"
    />
  );
});
