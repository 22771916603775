import {useStrings, variance} from '@ncwallet-app/core';
import type {ThemeKind} from '@ncwallet-app/core/src/Appearance';
import {BottomSheet} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';

import ThemeSelectBody from './ThemeSelectBody';

export type ThemeSelectScreenProps = {
  currentTheme: ThemeKind;
  onClose: () => void;
  onChangeTheme: (theme: ThemeKind) => void;
};

export default observer(function ThemeSelectScreen(
  props: ThemeSelectScreenProps,
) {
  const strings = useStrings();
  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        props.onClose();
      }
    },
    [props],
  );
  return (
    <BottomSheet
      index={0}
      onChange={onChange}
      animateOnMount
      snapPoints={[477]}
      enablePanDownToClose
      title={strings['themeSelect.title']}
      onClose={props.onClose}
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      children={
        <Container>
          <ThemeSelectBody
            currentTheme={props.currentTheme}
            onClose={props.onClose}
            onChangeTheme={props.onChangeTheme}
          />
        </Container>
      }
    />
  );
});

const Container = variance(View)(() => ({
  root: {
    width: '100%',
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
  },
}));
