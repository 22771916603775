import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';

import type {MessageViewProps} from './MessageView';
import MessageView from './MessageView';

export default observer(function BurningMessage(props: MessageViewProps) {
  const {onDismiss, message} = props;
  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss(message.id);
    }, message.timeout);
    return () => {
      clearTimeout(timer);
    };
  }, [onDismiss, message]);

  return <MessageView {...props} />;
});
