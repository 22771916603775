import type {WalletSlideData} from '@ncwallet-app/core';
import {
  keepMeaningfulDigitsInFiat,
  useStrings,
  useStyles,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {getBalanceHistoryItemChange} from '@ncwallet-app/core/src/NCWalletServer/WalletsBalanceHistory';
import {Spinner, SpinnerKind} from '@ncwallet-app/ui';
import {LinearGradient} from 'expo-linear-gradient';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useMemo} from 'react';
import {Platform, Text, View} from 'react-native';

import PriceDiff from '../../../components/PriceDiff';
import WalletSlideTransaction from './WalletSlideTransaction';

export type WalletSlideProps = {
  getWallet: () => WalletSlideData | undefined;
  onLastTransactionPress: () => void;
};

export default observer(function WalletSlide(props: WalletSlideProps) {
  const {getWallet, onLastTransactionPress} = props;
  const strings = useStrings();
  const theme = useTheme();

  const isReady = expr(
    () =>
      getWallet() &&
      getWallet()?.getFiatCurrency() &&
      getWallet()?.getCryptoCurrency(),
  );

  const wallet = getWallet();
  const balanceHistory = wallet?.getBalanceHistory();
  const chartData = balanceHistory?.map(d => ({value: d.amount}));
  const firstPriceItem = chartData?.find(it => it.value > 0);
  const firstPrice = firstPriceItem?.value
    ? firstPriceItem.value.toString(10)
    : '0';
  const diff = balanceHistory && getBalanceHistoryItemChange(balanceHistory);
  const lastTransaction = useMemo(() => wallet?.getLastTransaction(), [wallet]);
  const isWeb = Platform.OS === 'web';
  const walletSlide = useStyles(() => ({
    root: {
      height: isWeb ? 190 : 210,
    },
  }));

  return (
    <Container
      colors={[
        theme.palette.mainCardGradientStart,
        theme.palette.mainCardGradientEnd,
      ]}
      style={walletSlide.root}>
      <Title>{strings['walletsScreen.totalBalance']}</Title>
      {isReady ? (
        <SlideBody>
          <Row>
            <FiatValueText>
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              {keepMeaningfulDigitsInFiat(getWallet()!.fiatValue)}{' '}
            </FiatValueText>
            <FiatCodeText>{getWallet()?.getFiatCurrency()?.code}</FiatCodeText>
          </Row>
          <CryptoStatsRow>
            {!isNil(diff) && (
              <PriceDiff firstPrice={firstPrice} diff={diff.toString(10)} />
            )}
          </CryptoStatsRow>
          <WalletSlideTransaction
            transaction={lastTransaction}
            onPress={onLastTransactionPress}
          />
        </SlideBody>
      ) : (
        <SpinnerContainer>
          <Spinner size={54} kind={SpinnerKind.Light} />
        </SpinnerContainer>
      )}
    </Container>
  );
});

const Row = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Container = variance(LinearGradient)(theme => ({
  root: {
    width: '100%',
    paddingTop: 20,
    borderRadius: 20,
    overflow: 'hidden',
    ...theme.bar(5),
  },
}));

const SlideBody = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    marginBottom: 5,
    fontSize: 14,
    color: theme.palette.textAdditional1,
    textAlign: 'center',
  },
}));

const FiatValueText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    fontSize: 32,
    color: theme.palette.textPrimary,
  },
}));

const FiatCodeText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    fontSize: 32,
    color: theme.palette.primary,
  },
}));

const CryptoStatsRow = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 14,
    paddingHorizontal: 10,
    paddingVertical: 2,
  },
}));

const SpinnerContainer = variance(View)(() => ({
  root: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingBottom: 25,
  },
}));
