import type {
  PromptCryptoToReceiveRoute,
  PromptExchangeReceiptRoute,
  PromptNewWalletRoute,
  PromptSourceCryptoToExchangeRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransitionMap} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransitionMap';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {useCallback} from 'react';

import {filterCurrencySelectionItems} from '../../../Navigation/hooks';
import type {SelectionForExchangeScreenProps} from '../../../screens/SelectionForExchangeScreen/SelectionForExchangeScreen';
import {useSelectionForExchangeBindingState} from './useSelectionForExchangeBindingState';

export type PromptSourceCryptoToExchangeContainerProps = {
  params: RouteParams<PromptSourceCryptoToExchangeRoute>;
  goBack: () => void;
  setParams: (
    params: Partial<RouteParams<PromptSourceCryptoToExchangeRoute>>,
  ) => void;
} & RouteTransitionMap<
  PromptExchangeReceiptRoute | PromptCryptoToReceiveRoute | PromptNewWalletRoute
>;

export const usePromptSourceCryptoToExchangeContainer = (
  props: PromptSourceCryptoToExchangeContainerProps,
): Omit<SelectionForExchangeScreenProps, 'isLg'> => {
  const {state, handleManualRefresh} =
    useSelectionForExchangeBindingState(true);
  const {
    goBack,
    promptCryptoToReceive,
    promptExchangeReceipt,
    setParams,
    promptNewWallet,
    params,
  } = props;
  const search = params?.search;

  const goToExchange = useCallback(
    (currency: CurrencySelectionListItemData) => {
      if (currency.walletId === undefined) {
        return;
      }
      promptExchangeReceipt({walletIdFrom: currency.walletId});
    },
    [promptExchangeReceipt],
  );

  const setSearch = useCallback(
    (s: string) => {
      setParams({search: s});
    },
    [setParams],
  );

  const shownCurrencies = filterCurrencySelectionItems(
    state.getCurrenciesForSource(),
    search,
  );

  const getIsRefreshing = useCallback(() => {
    return (
      state.getIsLoading() &&
      (!state.getCurrenciesForSource() || state.getIsManualRefresh())
    );
  }, [state]);

  return {
    shownCurrencies,
    search,
    allCurrencies: state.getCurrenciesForSource(),
    onReceivePress: promptCryptoToReceive,
    onSearchChange: setSearch,
    onCurrencyPress: goToExchange,
    onRefresh: handleManualRefresh,
    getIsRefreshing: getIsRefreshing,
    onBack: goBack,
    onAddPress: promptNewWallet,
  };
};
