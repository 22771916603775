import {useRoot, useTheme, variance} from '@ncwallet-app/core';
import {
  LG_BREAKPOINT,
  TouchableOpacity,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  NCWalletLogoDarkSvg,
  NCWalletLogoSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {ArrowLeftSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {round} from 'lodash';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

export type PinScreenHeaderProps = {
  backText: string;
  onCancel: () => void;
  version: string;
};

export const PinScreenHeader = observer((props: PinScreenHeaderProps) => {
  const theme = useTheme();
  const {windowDimensionsState} = useRoot();
  const marginBottom = expr(() => {
    const {width, height} = windowDimensionsState.window;
    const aspectRatio = round(height / width, 2);
    return aspectRatio < 2 ? 30 : 80;
  });

  const style = useMemo(() => ({marginBottom}), [marginBottom]);

  const isLg = useIsDimensions('lg');

  return (
    <Content>
      {!isLg && (
        <TopRow style={style}>
          <TouchableOpacity onPress={props.onCancel}>
            {props.backText.length ? (
              <LogOutText>{props.backText}</LogOutText>
            ) : (
              <ArrowLeftSvg color={theme.palette.uiSystem} />
            )}
          </TouchableOpacity>
          <VersionText>v{props.version}</VersionText>
        </TopRow>
      )}

      <LogoContainer>
        <NCWalletIcon />
      </LogoContainer>
    </Content>
  );
});

const Content = variance(View)(() => ({
  root: {
    width: '100%',
  },
}));

const TopRow = variance(View)(() => ({
  root: {
    paddingTop: 12,
    paddingHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

const LogOutText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textMain,
    fontSize: 15,
  },
}));

const VersionText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight(),
    color: theme.palette.textMain,
    fontSize: 15,
  },
}));

const LogoContainer = variance(View)(theme => ({
  root: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginTop: 45,
        marginBottom: 10,
      },
    }),
  },
}));

const NCWalletIcon = observer(() => {
  const {appearance} = useRoot();
  const size = 80;
  return appearance.isDark ? (
    <NCWalletLogoSvg width={size} height={size} />
  ) : (
    <NCWalletLogoDarkSvg width={size} height={size} />
  );
});
