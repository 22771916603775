import type {NotificationPermissions} from './NotificationPermissions';

export default class StubNotificationPermissionsImpl
  implements NotificationPermissions
{
  get granted() {
    return false;
  }

  async ask() {}
}
