import type {
  CryptoAddress,
  CryptoCurrencyCode,
  DecimalString,
} from '@ncwallet-app/core';
import type {
  PromptAmountToSendRoute,
  PromptOutputAddressRoute,
  RouteTransition,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {LargeHomeStackParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeHomeStackParamList';
import type {LargeSwitchParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {PartialState} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';
import {CommonActions} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ListQrCodeHistoryContainer from '../../../../../CommonNavigationContainers/containers/ListQrCodeHistoryContainer';
import {useNavigateToSendByQr} from '../../../../../Navigation/hooks/useNavigateToSendByQr';
import {useOnAddressUriRedirectErrorHandler} from '../../../../../shared/useOnAddressUriRedirectErrorHandler';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type ListQrCodeHistoryBindingProps =
  LargeSwitchBindingProps<'ListQrCodeHistory'>;

export default observer(function ListQrCodeHistoryBinding(
  props: ListQrCodeHistoryBindingProps,
) {
  const {navigation, route} = props;
  const promptOutputAddress = useCallback<
    RouteTransition<PromptOutputAddressRoute | PromptAmountToSendRoute>
  >(
    params => {
      const switchState: PartialState<NavigationState<LargeSwitchParamList>> = {
        index: 0,
        routes: [{name: 'PromptOutputAddress', params}],
      };
      const homeStackState: PartialState<
        NavigationState<LargeHomeStackParamList>
      > = {index: 0, routes: [{name: 'Root', state: switchState}]};
      navigation.dispatch(CommonActions.reset(homeStackState));
    },
    [navigation],
  );
  const navigateToSendByQr = useNavigateToSendByQr(
    promptOutputAddress,
    promptOutputAddress,
  );

  const resetParams = useCallback(() => {
    navigation.setParams({
      address: undefined,
      error: undefined,
    });
  }, [navigation]);

  useOnAddressUriRedirectErrorHandler(
    route.params?.address,
    route.params?.error,
    resetParams,
  );

  const onEntryNavigate = useCallback(
    (
      address: CryptoAddress,
      amount?: DecimalString,
      currency?: CryptoCurrencyCode,
    ) => {
      const state = navigation.getState();
      const switchState = state.routes[state.index].state as
        | NavigationState<LargeSwitchParamList>
        | undefined;
      const switchRoute = switchState?.routes[0];
      if (switchRoute?.name === 'PromptOutputAddress') {
        const nextSwitchState: PartialState<
          NavigationState<LargeSwitchParamList>
        > = {
          index: 0,
          routes: [
            {
              name: 'PromptOutputAddress',
              key: switchRoute.key,
              path: switchRoute.path,
              params: {
                ...switchRoute.params,
                addressTo: address,
                amount,
              },
            },
          ],
        };
        const homeStackState: PartialState<
          NavigationState<LargeHomeStackParamList>
        > = {index: 0, routes: [{name: 'Root', state: nextSwitchState}]};
        navigation.dispatch(CommonActions.reset(homeStackState));
      } else {
        void navigateToSendByQr(address, currency, amount);
      }
    },
    [navigateToSendByQr, navigation],
  );

  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.replace('ScanQrCodeScanner');
    }
  }, [navigation]);

  return (
    <ListQrCodeHistoryContainer
      goBack={goBack}
      onEntryNavigate={onEntryNavigate}
    />
  );
});
