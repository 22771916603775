import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {LampSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import TipItem from './TipItem';

export type TipItemData = {
  id: string;
  title: string;
  description: string;
};

export type TipProps = {
  data: TipItemData[];
};

export default observer(function TipsList({data}: TipProps) {
  const renderTipItem = ({item}: {item: TipItemData}) => (
    <TipItem title={item.title} description={item.description} />
  );
  const strings = useStrings();
  const theme = useTheme();
  return (
    <Container>
      <Header>
        <LampSvg color={theme.palette.background} />
        <HeaderTitle>{strings['tips.title']}</HeaderTitle>
      </Header>
      <FlatList
        overScrollMode="never"
        data={data}
        renderItem={renderTipItem}
        keyExtractor={(item: TipItemData) => item.id}
      />
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    flex: 1,
    borderRadius: 10,
    backgroundColor: theme.palette.background,
    shadowColor: theme.palette.blackout,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
  },
}));

const Header = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderBottomColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
  },
}));

const HeaderTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 18,
    lineHeight: 22,
    marginLeft: 10,
  },
}));
