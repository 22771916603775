import {variance} from '@ncwallet-app/core';
import {BaseSkeleton, SkeletonColor} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export default observer(function SessionHistorySkeleton() {
  return (
    <SkeletonView>
      <BaseSkeleton width={40} height={40} isRounded />
      <SkeletonBodyView>
        <BaseSkeleton width={120} height={15} />
        <BaseSkeleton width={140} height={15} gap={5} />
        <BaseSkeleton width={96} height={15} color={SkeletonColor.Light} />
      </SkeletonBodyView>
    </SkeletonView>
  );
});

const SkeletonView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const SkeletonBodyView = variance(View)(() => ({
  root: {
    flex: 1,
    marginLeft: 15,
  },
}));
