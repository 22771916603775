import type {CurrencyCode} from '../Money';
import type {ISODateString} from '../Time';

export type WalletsBalanceHistory = {
  result: WalletsBalanceHistoryResult;
};

export type WalletsBalanceHistoryResult = {
  fiat: CurrencyCode;
  items: WalletsBalanceHistoryItem[];
};

export type WalletsBalanceHistoryItem = {
  amount: number;
  timestamp: ISODateString;
};

export const getBalanceHistoryItemChange = (
  items: WalletsBalanceHistoryItem[],
): number => {
  if (items.length < 2) {
    return 0;
  }

  const firstItem = items.find(it => it.amount !== 0);
  const lastItem = items[items.length - 1];

  if (!firstItem) {
    return 0;
  }

  return lastItem.amount > 0
    ? (firstItem.amount - lastItem.amount) / firstItem.amount
    : 1;
};
