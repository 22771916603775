/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import type {
  CurrencyDescription,
  DecimalString,
  WalletLimitPeriod,
} from '@ncwallet-app/core';
import {formatCryptoValue, useStrings} from '@ncwallet-app/core';
import {
  ButtonVariant,
  CurrencyCircleLogo,
  DecimalInput,
} from '@ncwallet-app/ui';
import {BigNumber} from 'bignumber.js';
import {observer} from 'mobx-react-lite';
import React, {useMemo, useState} from 'react';

import {useMapLimitPeriodTabToLabelText} from '../../../../shared/useMapLimitPeriodTabToLabelText';
import {LimitCard} from '../../shared/LimitCard';
import {LimitCardBody} from '../../shared/LimitCardBody';
import {
  LimitCardHeader,
  LimitCardHeaderCurrency,
  LimitCardHeaderCurrencyTitle,
  LimitCardHeaderTitle,
} from '../../shared/LimitCardHeader';
import {LimitError, LimitFormSubmitButton} from './LimitFormSubmitButton';
import SetLimitTabs from './SetLimitTabs';

export type CreateLimitFormProps = {
  crypto: CurrencyDescription;
  period: WalletLimitPeriod;
  showPeriodTabs?: boolean;
  onSubmit: (limit: DecimalString, period: WalletLimitPeriod) => void;
  error: LocaleKeys | undefined;
  resetError: () => void;
};

export default observer(function CreateLimitForm(props: CreateLimitFormProps) {
  const {crypto, onSubmit, error, showPeriodTabs} = props;
  const strings = useStrings();
  const [period, setPeriod] = useState(props.period);
  const [value, setValue] = useState<DecimalString | undefined>();
  const periodToLabelTextMap = useMapLimitPeriodTabToLabelText();
  const inputLabel = useMemo(() => {
    const label = strings['limitsSettingsScreen.inputLabel'];
    const periodText = periodToLabelTextMap.get(period);

    return `${label} (${periodText ?? ''})`;
  }, [strings, period, periodToLabelTextMap]);

  const inputPlaceholder = useMemo(() => {
    const formattedCryptoValue = formatCryptoValue(0, crypto.fractionDigits);

    return `${formattedCryptoValue} ${crypto.code}`;
  }, [crypto.fractionDigits, crypto.code]);

  const isDisabledButton = useMemo(() => {
    return !value || BigNumber(value).isLessThanOrEqualTo(0);
  }, [value]);
  return (
    <LimitCard>
      <LimitCardHeader>
        <LimitCardHeaderTitle>
          {strings['limitsSettingsScreen.setLimit']}
        </LimitCardHeaderTitle>
        <LimitCardHeaderCurrency>
          <CurrencyCircleLogo code={crypto.code} size={20} />
          <LimitCardHeaderCurrencyTitle>
            {crypto.code}
          </LimitCardHeaderCurrencyTitle>
        </LimitCardHeaderCurrency>
      </LimitCardHeader>
      <LimitCardBody>
        {showPeriodTabs && (
          <SetLimitTabs period={period} onTabPress={setPeriod} />
        )}
        <DecimalInput
          fractionDigits={crypto.fractionDigits}
          label={inputLabel}
          value={value}
          onChangeDecimal={setValue}
          placeholder={inputPlaceholder}
          testID="limit-input"
        />
        {!!error && <LimitError>{strings[error]}</LimitError>}
        <LimitFormSubmitButton
          variant={ButtonVariant.Primary}
          disabled={isDisabledButton}
          title={strings['limitsSettingsScreen.enableButton']}
          onPress={() => {
            onSubmit(value!, period);
          }}
          testID="enable-limit-btn"
        />
      </LimitCardBody>
    </LimitCard>
  );
});
