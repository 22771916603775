import {useTheme, variance} from '@ncwallet-app/core';
import type {OptionalGetter} from '@ncwallet-app/core/src/mobx-toolbox';
import {use} from '@ncwallet-app/core/src/mobx-toolbox';
import {LG_BREAKPOINT, TouchableOpacity} from '@ncwallet-app/ui';
import {StarSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {range} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export type RateModalStarsProps = {
  onRatingChange: (rating: number) => void;
  getRating: OptionalGetter<number>;
};

const maxRating = 5;

export default observer(function RateModalStars(props: RateModalStarsProps) {
  const {getRating, onRatingChange} = props;
  const theme = useTheme();
  const rating = use(getRating);
  return (
    <Container>
      {range(0, maxRating).map((starNumber, index) => (
        <TouchableOpacity
          onPress={() => {
            onRatingChange(starNumber + 1);
          }}
          key={index}>
          <StarSvg
            color={
              rating < index + 1
                ? theme.palette.additional2
                : theme.palette.primary
            }
          />
        </TouchableOpacity>
      ))}
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    maxWidth: 234,
    width: '100%',
    flexDirection: 'row',
    marginHorizontal: 'auto',
    justifyContent: 'space-between',
    marginBottom: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: '100%',
        paddingVertical: 20,
        paddingHorizontal: 50,
        backgroundColor: theme.palette.uiPrimary,
        borderWidth: 1,
        borderColor: theme.palette.uiSecondary,
        borderRadius: 8,
      },
    }),
  },
}));
