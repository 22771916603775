import {variance} from '@ncwallet-app/core';
import {View} from 'react-native';

// eslint-disable-next-line import-x/prefer-default-export
export const Separator = variance(View)(theme => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.uiSecondary,
    marginRight: 25,
    marginLeft: 15,
  },
}));
