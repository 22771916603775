import {useStrings, variance} from '@ncwallet-app/core';
import {
  LG_BREAKPOINT,
  SelectionCryptoTitle,
  WalletActionKind,
} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import CurrencyListItem from '../CurrencyScreen/CurrencyListItem';

export type SelectionAdditionalOptionsProps = {
  onExchangePress: () => void;
};

export default observer(function SelectionAdditionalOptions(
  props: SelectionAdditionalOptionsProps,
) {
  const {onExchangePress} = props;
  const strings = useStrings();

  return (
    <>
      <SelectionCryptoTitle
        title={strings['selectionForSendScreen.additionOptions']}
      />
      <SelectionAdditionalOptionsView>
        <SelectionCurrencyListItem
          dataSet={{app: 'currency-item'}}
          type={WalletActionKind.Exchange}
          onPress={onExchangePress}
          smallCard
        />
      </SelectionAdditionalOptionsView>
    </>
  );
});

const SelectionAdditionalOptionsView = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: undefined,
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
    }),
  },
}));

const SelectionCurrencyListItem = variance(CurrencyListItem)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: '50%',
      },
    }),
  },
}));
