import type {LocaleDict} from '@nc-wallet/localization/locale/LocaleStrings';
import {useRoot, useStrings} from '@ncwallet-app/core';
import type {
  RouteParams,
  ShowProfileRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {expr} from '@ncwallet-app/core/src/mobx-toolbox';
import {AccountVerificationProgress} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import type {SafeAreaScrollViewProps} from '@ncwallet-app/ui';
import {SafeAreaScrollView} from '@ncwallet-app/ui';
import {setStringAsync} from 'expo-clipboard';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {useThrottledCallback} from 'use-debounce';

import {SettingsGroupTitle} from '../UserSettingsScreens/SharedComponents/SettingsGroupTitle';
import ProfileSettingsItem from './ProfileSettingsItem';
import type {ProfileUser} from './ProfileSettingsScreen';

export type ProfileSettingsListProps = SafeAreaScrollViewProps & {
  getUser: () => ProfileUser | undefined;
  onDisplayNamePress: (value: string) => void;
  onUserIdPress: (value: string) => void;
  onEmailPress: (value: string) => void;
  onLinkToEmailPress: () => void;
  onLinkToTelegramPress: () => void;
  onIdentityPress: () => void;
  onBaseCryptoPress: () => void;
  onBaseFiatPress: () => void;
  onDeletePress: () => void;
  verificationProgress: AccountVerificationProgress | undefined;
  params: RouteParams<ShowProfileRoute>;
};

export default observer(function ProfileSettingsList(
  props: ProfileSettingsListProps,
) {
  const {
    getUser,
    onDisplayNamePress: _onDisplayNamePress,
    onEmailPress: _onEmailPress,
    onLinkToEmailPress,
    onLinkToTelegramPress,
    onBaseCryptoPress,
    onBaseFiatPress,
    onIdentityPress,
    onDeletePress,
    verificationProgress,
    params,
    ...rest
  } = props;

  const {flashMessage, platformSpecificComponents, telegramMiniApp} = useRoot();
  const {ProfileEmailButton, ProfileTelegramButton} =
    platformSpecificComponents;
  const strings = useStrings();
  const getDisplayName = useCallback(() => getUser()?.displayName, [getUser]);
  const DEBOUNCE_INTERVAL = 3000;
  const onDisplayNamePress = useCallback(() => {
    const current = getUser()?.displayName;
    if (current !== undefined) {
      _onDisplayNamePress(current);
    }
  }, [_onDisplayNamePress, getUser]);

  const onUserIdPress = useCallback(() => {
    const current = getUser()?.userId;
    void setStringAsync(current ?? '');
    flashMessage.showMessage({
      title: strings.copied,
      variant: 'success',
    });
  }, [getUser, flashMessage, strings]);

  const userIdPressThrottled = useThrottledCallback(
    onUserIdPress,
    DEBOUNCE_INTERVAL,
    {
      trailing: false,
    },
  );

  const getEmail = useCallback(() => getUser()?.email, [getUser]);
  const onEmailPress = useCallback(() => {
    const current = getUser()?.email;
    if (current !== undefined) {
      _onEmailPress(current);
    }
  }, [_onEmailPress, getUser]);
  const getEmailError = useCallback(() => {
    const {isEmailConfirmed} = getUser() ?? {};
    if (isEmailConfirmed === undefined) {
      return undefined;
    }
    return !isEmailConfirmed;
  }, [getUser]);

  const getTelegramTitle = useCallback(() => {
    const telegramUsername = getUser()?.telegramUsername;
    return telegramUsername ? telegramUsername : '-';
  }, [getUser]);

  const getDisabled = useCallback(
    () =>
      telegramMiniApp.isAvailable || expr(() => !!getUser()?.telegramUsername),
    [getUser, telegramMiniApp],
  );

  const getIdentifyError = useCallback(() => {
    const {isIdentityVerify} = getUser() ?? {};
    if (isIdentityVerify === undefined) {
      return undefined;
    }
    return !isIdentityVerify;
  }, [getUser]);

  const getBaseCrypto = useCallback(() => {
    const user = getUser();
    if (!user) {
      return undefined;
    }
    return `${user.baseCryptoCurrency.name} (${user.baseCryptoCurrency.code})`;
  }, [getUser]);

  const getBaseFiat = useCallback(
    () => getUser()?.defaultFiatCurrency.code,
    [getUser],
  );

  const isDisabled =
    isNil(verificationProgress) ||
    verificationProgress === AccountVerificationProgress.Verified ||
    verificationProgress === AccountVerificationProgress.NotRequired;

  const getStatusText = useCallback(() => {
    return !isNil(verificationProgress)
      ? getVerificationStatusText(verificationProgress, strings)
      : '';
  }, [verificationProgress, strings]);
  const isSelectedEmail = Boolean(params?.target === 'email' && !getEmail());
  return (
    <SafeAreaScrollView {...rest}>
      <SettingsGroupTitle
        title={strings['profileSettingsScreen.detailsTitle']}
        first={true}
      />
      <ProfileSettingsItem
        label={strings['profileSettingsScreen.nicknameLabel']}
        getTitle={getDisplayName}
        onPress={onDisplayNamePress}
      />
      <ProfileSettingsItem
        label={strings['profileSettingsScreen.userIdLabel']}
        getTitle={() => getUser()?.userId}
        onPress={userIdPressThrottled}
      />
      <ProfileTelegramButton
        $disabled={getDisabled}
        getTitle={getTelegramTitle}
        onPress={onLinkToTelegramPress}
      />
      <ProfileEmailButton
        getTitle={getEmail}
        onEmailPress={onEmailPress}
        onLinkToEmailPress={onLinkToEmailPress}
        getError={getEmailError}
        selected={isSelectedEmail}
      />
      <SettingsGroupTitle
        title={strings['profileSettingsScreen.verificationTitle']}
      />
      <ProfileSettingsItem
        label={strings['profileSettingsScreen.identityLabel']}
        getTitle={getStatusText}
        error={verificationProgress === AccountVerificationProgress.Required}
        success={verificationProgress === AccountVerificationProgress.Verified}
        warning={verificationProgress === AccountVerificationProgress.OnReview}
        onPress={onIdentityPress}
        getError={getIdentifyError}
        disabled={isDisabled}
      />
      <SettingsGroupTitle
        title={strings['profileSettingsScreen.baseCurrencyTitle']}
      />
      <ProfileSettingsItem
        label={strings['profileSettingsScreen.crypt']}
        getTitle={getBaseCrypto}
        onPress={onBaseCryptoPress}
      />
      <ProfileSettingsItem
        label={strings['profileSettingsScreen.fiat']}
        getTitle={getBaseFiat}
        onPress={onBaseFiatPress}
      />
      <SettingsGroupTitle title={strings['deleteAccountModal.title']} />
      <ProfileSettingsItem
        getTitle={() => strings['profileSettingsScreen.deleteAccount']}
        onPress={onDeletePress}
      />
    </SafeAreaScrollView>
  );
});

const getVerificationStatusText = (
  step: AccountVerificationProgress,
  strings: LocaleDict,
) => {
  switch (step) {
    case AccountVerificationProgress.Verified:
      return strings['profileSettingsScreen.verification.verifiedStatus'];
    case AccountVerificationProgress.NotRequired:
      return strings['profileSettingsScreen.verificationNotTag'];
    case AccountVerificationProgress.OnReview:
      return strings[
        'profileSettingsScreen.verification.identityDocument.status.waiting'
      ];
    case AccountVerificationProgress.Required:
      return strings[
        'profileSettingsScreen.verification.verifiedStatusRequired'
      ];
  }
};
