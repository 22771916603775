import {observer} from 'mobx-react-lite';
import React from 'react';

import {flags} from './flags';

export type FlagIconProps = {
  code: string;
};

export default observer(function FlagIcon(props: FlagIconProps) {
  const {code} = props;
  const codeKey = code.toUpperCase();
  const Icon = codeKey in flags ? flags[codeKey] : null;
  return Icon ? <Icon /> : null;
});
