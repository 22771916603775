import type {
  CryptoAddress,
  CryptoCurrencyCode,
  DecimalString,
} from '@ncwallet-app/core';
import type {
  PromptAmountToSendRoute,
  PromptOutputAddressRoute,
  RouteTransition,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {NavigationProp, ParamListBase} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ListQrCodeHistoryContainer from '../../../CommonNavigationContainers/containers/ListQrCodeHistoryContainer';
import useStackNavigateBack from '../../../CommonNavigationContainers/hooks/useStackNavigateBack';
import {useNavigateToSendByQr} from '../../../Navigation/hooks/useNavigateToSendByQr';
import {useOnAddressUriRedirectErrorHandler} from '../../../shared/useOnAddressUriRedirectErrorHandler';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ListQrCodeHistoryBindingProps =
  SmallHomeStackBindingProps<'ListQrCodeHistory'>;

export default observer(function ListQrCodeHistoryBinding(
  props: ListQrCodeHistoryBindingProps,
) {
  const {navigation, route} = props;
  const navigateBack = useStackNavigateBack(navigation);
  const promptOutputAddress = useCallback<
    RouteTransition<PromptOutputAddressRoute>
  >(
    params => {
      navigateBack('PromptOutputAddress', params);
    },
    [navigateBack],
  );
  const promptAmountToSend = useCallback<
    RouteTransition<PromptAmountToSendRoute>
  >(
    params => {
      navigateBack('PromptAmountToSend', params);
    },
    [navigateBack],
  );
  const navigateToSendByQr = useNavigateToSendByQr(
    promptOutputAddress,
    promptAmountToSend,
  );

  const resetParams = useCallback(() => {
    navigation.setParams({
      address: undefined,
      error: undefined,
    });
  }, [navigation]);

  useOnAddressUriRedirectErrorHandler(
    route.params?.address,
    route.params?.error,
    resetParams,
  );

  const onEntryNavigate = useCallback(
    (
      address: CryptoAddress,
      amount?: DecimalString,
      currency?: CryptoCurrencyCode,
    ) => {
      const routeBeforeOpeningQr = getPrevRoute(navigation);
      if (routeBeforeOpeningQr.name === 'PromptOutputAddress') {
        navigation.dispatch(
          CommonActions.navigate({
            key: routeBeforeOpeningQr.key,
            params: {addressTo: address, amount},
            merge: true,
          }),
        );
      } else {
        void navigateToSendByQr(address, currency, amount);
      }
    },
    [navigateToSendByQr, navigation],
  );

  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.replace('ScanQrCodeScanner');
    }
  }, [navigation]);

  return (
    <ListQrCodeHistoryContainer
      goBack={goBack}
      onEntryNavigate={onEntryNavigate}
    />
  );
});

function getPrevRoute(navigation: NavigationProp<ParamListBase>) {
  const navigationState = navigation.getState();
  const currentRoteIndex = navigationState.index;
  return navigationState.routes[currentRoteIndex - 1];
}
