import {useTheme, variance} from '@ncwallet-app/core';
import {BaseSkeleton, SkeletonColor} from '@ncwallet-app/ui/src';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {range} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

export type AddressListSkeletonProps = {
  itemCount?: number;
};

export default observer(function AddressListSkeleton(
  props: AddressListSkeletonProps,
) {
  const {itemCount = 1} = props;

  return (
    <ScrollView overScrollMode="never">
      {range(0, itemCount).map(idx => (
        <AddressSkeleton key={idx} />
      ))}
    </ScrollView>
  );
});

const AddressSkeleton = observer(() => {
  const theme = useTheme();

  return (
    <SkeletonContainerView>
      <SkeletonBodyView>
        <SkeletonTopView>
          <SkeletonTopLeftView>
            <BaseSkeleton width={85} height={15} gap={5} />
            <BaseSkeleton width={110} height={15} color={SkeletonColor.Light} />
          </SkeletonTopLeftView>
          <SkeletonTopRightView>
            <BaseSkeleton width={100} height={15} gap={5} />
            <BaseSkeleton width={105} height={15} color={SkeletonColor.Light} />
          </SkeletonTopRightView>
        </SkeletonTopView>
        <BaseSkeleton height={15} gap={5} color={SkeletonColor.Light} />
      </SkeletonBodyView>
      <SkeletonIconView>
        <ArrowRightSvg color={theme.palette.uiAdditional1} />
      </SkeletonIconView>
    </SkeletonContainerView>
  );
});

const SkeletonContainerView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderBottomWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const SkeletonBodyView = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const SkeletonTopView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
}));

const SkeletonTopLeftView = variance(View)(() => ({
  root: {},
}));

const SkeletonTopRightView = variance(View)(() => ({
  root: {
    alignItems: 'flex-end',
  },
}));

const SkeletonIconView = variance(View)(() => ({
  root: {
    marginLeft: 15,
  },
}));
