import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptCommissionForSendingContainer from '../../../CommonNavigationContainers/containers/PromptCommissionForSendingContainer';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptCommissionForSendingBindingProps =
  SmallHomeStackBindingProps<'PromptCommissionForSending'>;

export default observer(function PromptCommissionForSendingBinding(
  props: PromptCommissionForSendingBindingProps,
) {
  const {navigation, route} = props;

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.replace('PromptOutputAddress', {
      isEditable: true,
      ...route.params,
    });
  }, [navigation, route.params]);

  const onSubmit = useCallback(
    (fee: string | undefined) => {
      navigation.setParams({fee: fee});
      navigation.navigate('PromptConfirmationToSendCrypto', {
        ...route.params,
        fee,
      });
    },
    [navigation, route.params],
  );

  return (
    <PromptCommissionForSendingContainer
      params={route.params}
      onBack={onBack}
      onSubmit={onSubmit}
    />
  );
});
