import {sized, variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT, PressableNativeFeedback} from '@ncwallet-app/ui';
import {CopySvg, OpenLinkSVG} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {Text, View} from 'react-native';

export const DetailsRow = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 10,
        paddingVertical: 15,
      },
    }),
  },
  last: {
    borderBottomWidth: 0,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 10,
      },
    }),
  },
}));
export const DetailsLabel = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    marginRight: 10,
  },
}));
export const DetailsValue = variance(Text)(theme => ({
  root: {
    flex: 1,
    ...theme.fontByWeight('400'),
    textAlign: 'right',
    color: theme.palette.textSecondary,
  },
  small: {
    fontSize: 13,
  },

  address: {
    marginLeft: 10,
  },
}));

const ShareIconSvg = sized(OpenLinkSVG, 16, 16);
export const ShareIcon = variance(ShareIconSvg)(() => ({
  root: {
    padding: 3,
  },
}));

export const DetailsIcon = variance(PressableNativeFeedback)(() => ({
  root: {
    marginLeft: 10,
    padding: 3,
  },
}));
export const CopyIcon = sized(CopySvg, 14, 14);
