import {useRoot} from '@ncwallet-app/core/';
import type {OAuth2ProviderMap} from '@ncwallet-app/core/src/OAuth2RestClient';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import LinkToEmailScreen from '../../../screens/LinkToEmailScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptAccountDeletionBindingProps =
  SmallHomeStackBindingProps<'PromptLinkToEmail'>;

export default observer(function PromptAccountDeletionBinding({
  navigation,
}: PromptAccountDeletionBindingProps) {
  const {linkToEmailService} = useRoot();
  const onClose = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({
      index: 1,
      routes: [{name: 'Root'}, {name: 'ShowProfile'}],
    });
  }, [navigation]);

  const goToRoot = useCallback(() => {
    navigation.navigate('Root');
  }, [navigation]);

  const handleLinkPress = useCallback(
    async (type: keyof OAuth2ProviderMap) => {
      linkToEmailService.linkWithEmail(type, goToRoot);
    },
    [goToRoot, linkToEmailService],
  );

  return (
    <LinkToEmailScreen onClose={onClose} handleLinkPress={handleLinkPress} />
  );
});
