import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import type {MessageContext} from '../Messaging';
import type {Opaque} from '../Opaque';
import type {ActionId} from '../units';
import type {NotificationData} from './NotificationData';

export interface HeadlessLocalNotifications {
  scheduleNotification(
    context: MessageContext,
  ): Promise<Either<void, GlobalError>>;
  cancelNotification(
    id: LocalNotificationId,
  ): Promise<Either<void, GlobalError>>;
  disposeNotification(
    id: LocalNotificationId,
    groupId: string,
  ): Promise<Either<void, GlobalError>>;
  hideNotificationDrawer(): Promise<Either<void, GlobalError>>;
  handle(context: LocalNotificationContext): Promise<Either<void, GlobalError>>;
}

export type LocalNotificationContext = {
  type: EventType;
  data: NotificationData;
  id?: LocalNotificationId;
  actionId?: ActionId;
};

export const LOCAL_NOTIFICATION_ID = Symbol();
export type LocalNotificationId = Opaque<string, typeof LOCAL_NOTIFICATION_ID>;

export enum EventType {
  Initial,
  Delivered,
  Press,
  Dismissed,
}
