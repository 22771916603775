import type {Millisecond} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import BlockchainNetworkWarningBinding from './BlockchainNetworkWarningBinding';

export default observer(function BlockchainNetworksModalStack() {
  const {blockchainNetworksVisibilityState} = useRoot();
  const {modal} = blockchainNetworksVisibilityState;

  const blockchainNetwork = modal.networkToDisplay;

  const hideBlockchainNetwork = useCallback(
    async (now: Millisecond) => {
      if (modal.networkToDisplay) {
        await modal.hideNetwork(now);
      }
    },
    [modal],
  );

  if (
    blockchainNetwork &&
    blockchainNetwork.status_message &&
    Object.keys(blockchainNetwork.status_message).length > 0
  ) {
    return (
      <BlockchainNetworkWarningBinding
        context={blockchainNetwork.status_message}
        hideModal={hideBlockchainNetwork}
      />
    );
  }

  return null;
});
