import {useStrings} from '@ncwallet-app/core';
import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';

import type {WalletLimitsItemData} from '../../../shared/WalletLimitsItemData';
import {LimitCard} from '../shared/LimitCard';
import {LimitCardBody} from '../shared/LimitCardBody';
import {LimitCardHeader, LimitCardHeaderTitle} from '../shared/LimitCardHeader';
import WalletLimitsCardBody from './WalletLimitsCardBody';

export type WalletLimitsCardProps = {
  getSearch: () => string | undefined;
  onSearchChange: (s: string) => void;
  getLimits: () => WalletLimitsItemData[] | undefined;
  onSelect: (limit: WalletLimitsItemData) => void;
};

export default observer(function WalletLimitsCard(
  props: WalletLimitsCardProps,
) {
  const strings = useStrings();
  const search = props.getSearch();
  const limits = props.getLimits();

  return (
    <LimitCard horizontalOffset>
      <LimitCardHeader>
        <LimitCardHeaderTitle>
          {strings['limitsSettingsScreen.activeTitle']}
        </LimitCardHeaderTitle>
      </LimitCardHeader>
      <LimitCardBody>
        <WalletLimitsCardBody
          limits={limits}
          searchValue={search}
          onSelect={props.onSelect}
          onSubmit={noop}
          onSearchChange={props.onSearchChange}
        />
      </LimitCardBody>
    </LimitCard>
  );
});
