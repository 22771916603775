/* eslint-disable @typescript-eslint/unbound-method */
import {useRoot, useStrings} from '@ncwallet-app/core';
import {
  PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE,
  stateToPath,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {LazyPathImpl} from '@ncwallet-app/core/src/CommonNavigationScheme/Path';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptConfirmationToSendCryptoContainer from '../../../../../CommonNavigationContainers/containers/PromptConfirmationToSendCryptoContainer';
import {checkPrevRoute} from '../../../../../SmallNavigationRoot/SmallHomeStack/util/checkPrevRoute';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptConfirmationToSendCryptoBindingProps =
  LargeSwitchBindingProps<'PromptConfirmationToSendCrypto'>;

export default observer(function PromptConfirmationToSendCryptoBinding(
  props: PromptConfirmationToSendCryptoBindingProps,
) {
  const {navigation, route} = props;
  const {amount, addressTo, currency} = route.params;
  const {flashMessage} = useRoot();
  const strings = useStrings();

  const promptOtpToSendCrypto = useCallback(
    (withdrawId: string) => {
      navigation.navigate('PromptOtpToSendCrypto', {
        withdrawId,
        currency,
        amount,
        addressTo,
      });
    },
    [addressTo, amount, currency, navigation],
  );

  const promptTwoFaEnabling = useCallback(() => {
    const path =
      route.path === undefined ? undefined : new LazyPathImpl(route.path);
    const onTwoFaEnabled = stateToPath([
      {
        path,
        route: {
          kind: PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE,
          params: route.params,
        },
      },
    ]);
    navigation.navigate('ListSecuritySettings', {onTwoFaEnabled});
    flashMessage.showMessage({
      title: strings['send.enableTwoFABeforeSend'],
      variant: 'success', // success selected as more contrast variant than info
    });
  }, [flashMessage, navigation, route.params, route.path, strings]);

  const toSendScreen = useCallback(() => {
    navigation.navigate('PromptOutputAddress', {
      ...route.params,
      isEditable: true,
    });
  }, [navigation, route.params]);

  const onMinerFeeEdit = useCallback(() => {
    navigation.navigate('PromptCommissionForSending', {
      ...route.params,
      isEditable: true,
    });
  }, [navigation, route.params]);

  const onNetworkEdit = useCallback(() => {
    navigation.navigate('PromptOutputNetwork', {
      ...route.params,
      isEditable: true,
    });
  }, [navigation, route.params]);

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({
      index: 0,
      routes: [{name: 'PromptOutputAddress', params: route.params}],
    });
  }, [navigation, route.params]);
  return (
    <PromptConfirmationToSendCryptoContainer
      setParams={navigation.setParams}
      params={route.params}
      cameFromChangeNetwork={checkPrevRoute(
        navigation.getState(),
        'PromptOutputNetwork',
      )}
      onBack={onBack}
      onAmountEdit={toSendScreen}
      onAddressEdit={toSendScreen}
      onCommentEdit={toSendScreen}
      onMinerFeeEdit={onMinerFeeEdit}
      onNetworkEdit={onNetworkEdit}
      promptOtpToSendCrypto={promptOtpToSendCrypto}
      promptTwoFaEnabling={promptTwoFaEnabling}
    />
  );
});
