import {variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import type {MoreCardProps} from './MoreCard';
import MoreCard from './MoreCard';
import type {SharingCardProps} from './SharingCard';
import SharingCard from './SharingCard';
import type {StatisticCardProps} from './StatisticCard';
import StatisticCard from './StatisticCard';

export type LgAffiliateProgramProps = MoreCardProps &
  SharingCardProps &
  StatisticCardProps;

export default observer(function LgAffiliateProgram(
  props: LgAffiliateProgramProps,
) {
  return (
    <Container>
      <MoreCard {...props} />
      <SharingCard {...props} />
      <StatisticCard {...props} />
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    overflow: 'visible',
    paddingBottom: 30,
    paddingTop: 0,

    ...theme.mediaQuery({
      [1220]: {
        paddingHorizontal: 30,
      },

      [LG_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
}));
