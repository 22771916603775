import {useTheme, variance} from '@ncwallet-app/core';
import type {AggregationItemStatus} from '@ncwallet-app/core/src/WalletTransactionsRequestHelper';
import {RoundShape} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

import {useGetTransactionStatusText} from '../../../shared/useGetTransactionStatusText';
import {mapStatusToColor} from './mapStatusToColor';

export type WalletSlideTransactionStatusProps = {
  status: AggregationItemStatus;
};

export default observer(function WalletSlideTransactionStatus(
  props: WalletSlideTransactionStatusProps,
) {
  const {status} = props;
  const theme = useTheme();
  const getStatusText = useGetTransactionStatusText();
  const statusThemeColor = useMemo(() => mapStatusToColor(theme), [theme]);

  return (
    <TransactionStatusContainerView>
      <TransactionStatusView
        style={{backgroundColor: statusThemeColor.get(status)?.secondary}}>
        <TransactionStatusText
          style={{color: statusThemeColor.get(status)?.primary}}>
          {getStatusText(status)}
        </TransactionStatusText>
      </TransactionStatusView>
      <RoundIconView>
        <RoundIcon color={statusThemeColor.get(status)?.secondary} />
      </RoundIconView>
    </TransactionStatusContainerView>
  );
});

const TransactionStatusContainerView = variance(View)(() => ({
  root: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'flex-end',
    transform: [{translateX: -15}],
    zIndex: 1,
  },
}));

const TransactionStatusView = variance(View)(() => ({
  root: {
    paddingVertical: 5,
    paddingRight: 15,
    paddingLeft: 20,
    borderTopRightRadius: 6,
    backgroundColor: 'red',
  },
}));

const RoundIconView = variance(View)(() => ({
  root: {
    transform: [{scaleX: -1}],
  },
}));

const RoundIcon = variance(RoundShape)(
  () => ({
    root: {},
  }),
  () => ({
    width: 9,
    height: 9,
  }),
);

const TransactionStatusText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 12,
  },
}));
