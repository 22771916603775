import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import type {
  CryptoCurrencyCode,
  DecimalString,
  WalletsLimitsCreate,
} from '@ncwallet-app/core';
import {
  toCurrencyDescriptionFromCrypto,
  useRoot,
  WalletLimitPeriod,
} from '@ncwallet-app/core';
import type {
  PromptOtpToLimitUpdateRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {
  PROMPT_NEW_LIMIT_ROUTE,
  PROMPT_OTP_TO_LIMIT_CREATE_ROUTE,
  SHOW_LIMIT_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {isNil} from 'lodash';
import {useCallback, useState} from 'react';

import {useCreateLimit} from '../../../../../../CommonNavigationContainers/hooks/walletLimits/useCreateLimit';
import type {PromptNewLimitCardProps} from '../../../../../../screens/LgLimitsSettingsScreen/LimitCard/PromptNewLimitCard';
import {
  mapValidateErrorToString,
  validateLimitValue,
} from '../../../../../../screens/LgLimitsSettingsScreen/validateLimitValue';
import type {WalletLimitsItemData} from '../../../../../../shared/WalletLimitsItemData';
import type {LargeSwitchBindingProps} from '../../LargeSwitchBindingProps';

export default function usePromptNewLimitCard(
  refresh: () => void,
  loadLimit: (c: CryptoCurrencyCode) => WalletLimitsItemData | undefined,
  props: LargeSwitchBindingProps<'ListLimits'>,
): PromptNewLimitCardProps {
  const root = useRoot();
  const {navigation, route} = props;
  const [error, setError] = useState<LocaleKeys | undefined>(undefined);
  const selectedCard = route.params?.focusedSelectedLimit;
  const onSuccess = useCallback(
    (res: WalletsLimitsCreate['result']) => {
      refresh();

      navigation.setParams({
        focusedSelectedLimit: {
          kind: SHOW_LIMIT_ROUTE,
          params: {
            walletId: res.wallet_limit.wallet_id,
            period: res.wallet_limit.period,
          },
        },
      });
    },
    [navigation, refresh],
  );

  const goToOtp = useCallback(
    (params: RouteParams<PromptOtpToLimitUpdateRoute>) => {
      navigation.setParams({
        focusedSelectedLimit: {
          kind: PROMPT_OTP_TO_LIMIT_CREATE_ROUTE,
          params,
        },
      });
    },
    [navigation],
  );

  const {createLimit} = useCreateLimit(goToOtp, onSuccess);

  const onSubmit = useCallback(
    (limit: DecimalString, period: WalletLimitPeriod) => {
      if (selectedCard?.kind !== PROMPT_NEW_LIMIT_ROUTE) {
        return;
      }
      const {params} = selectedCard;
      const limitInfo = loadLimit(params.currency);
      const errorKind = validateLimitValue(
        period,
        limit,
        limitInfo?.monthlyLimit?.limit,
        limitInfo?.dailyLimit?.limit,
      );

      if (isNil(errorKind)) {
        setError(undefined);
        void createLimit(selectedCard.params.walletId, limit, period);
        return;
      }

      const errorLocaleKey = mapValidateErrorToString(errorKind);
      setError(errorLocaleKey);
    },
    [createLimit, loadLimit, selectedCard],
  );
  if (selectedCard?.kind !== PROMPT_NEW_LIMIT_ROUTE) {
    return {
      onSubmit,
      crypto: undefined,
      period: WalletLimitPeriod.Daily,
      error,
      resetError: () => {
        setError(undefined);
      },
    };
  }

  const {currency} = selectedCard.params;
  const crypto = root.currencyStore.getCryptoCurrency(currency);
  return {
    period: selectedCard.params.period,
    crypto: crypto && toCurrencyDescriptionFromCrypto(crypto),
    perOnePeriodOnly: selectedCard.params.perOnePeriodOnly,
    onSubmit,
    error,
    resetError: () => {
      setError(undefined);
    },
  };
}
