import type {InfiniteGenerator} from './InfiniteGenerator';
import type {ObjectEnumerator} from './ObjectEnumerator';

export default class WeakObjectEnumeratorImpl<T extends object, N>
  implements ObjectEnumerator<T, N>
{
  private readonly _currentMembers = new WeakMap<T, N>();

  constructor(private readonly _upcomingValues: InfiniteGenerator<N>) {}

  getOrAssign(member: T): N {
    const current = this._currentMembers.get(member);
    if (current !== undefined) {
      return current;
    } else {
      const value = this._upcomingValues.next();
      this._currentMembers.set(member, value);
      return value;
    }
  }
}
