import type {LogContent} from './LogContent';

export default class FetchResponseBodyLogContent implements LogContent {
  constructor(
    public readonly correlationId: number,
    public readonly responseBody: string,
  ) {}

  get body() {
    return `#${this.correlationId} ${this.responseBody}`;
  }
}
