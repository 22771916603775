import type {BiometricStateHelper} from './BiometricStateHelper';

export default class StubBiometricStateHelperImpl
  implements BiometricStateHelper
{
  canPassTwoFaWithBiometrics(): Promise<boolean> {
    return Promise.resolve(false);
  }

  canSignInWithBiometrics(): Promise<boolean> {
    return Promise.resolve(false);
  }
}
