import {useStrings, variance} from '@ncwallet-app/core';
import type {
  AccountDocument,
  AccountDocumentId,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import {fileCanBeUploadedForDocument} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import type {AccountDocumentFileInfo} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments/AccountDocumentFileInfo';
import {
  Button,
  ButtonIconPosition,
  ButtonVariant,
  SafeAreaInset,
  SafeAreaScrollView,
} from '@ncwallet-app/ui/';
import {UploadSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {EXTRA_BOTTOM_OFFSET} from '../constants';
import UploadedResultItem from './UploadedResultItem';

export type IdentityVerificationUploadDocumentProps = {
  accountDocument?: AccountDocument;
  onUploadPickerPress: () => void;
  onContinuePress: () => void;
  onDeleteFile: (id: AccountDocumentId, file: AccountDocumentFileInfo) => void;
};

export default observer(function IdentityVerificationUploadDocument(
  props: IdentityVerificationUploadDocumentProps,
) {
  const {accountDocument, onUploadPickerPress, onContinuePress, onDeleteFile} =
    props;
  const strings = useStrings();

  return (
    <RootView
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}>
      <TopView>
        <TopViewText>
          {strings['profileSettingsScreen.verification.upload.topText']}
        </TopViewText>
      </TopView>
      <BodyView>
        {accountDocument?.files && (
          <>
            <UploadedTitleText>
              {strings['profileSettingsScreen.verification.upload.formTitle']}
            </UploadedTitleText>
            <UploadedBodyView>
              {accountDocument.files.map((file, idx) => (
                <UploadedResultItem
                  key={file.name}
                  file={file.name}
                  isFirst={idx === 0}
                  uploadedAt={file.created_at}
                  onDelete={() => {
                    onDeleteFile(accountDocument.id, file);
                  }}
                />
              ))}
            </UploadedBodyView>
          </>
        )}
      </BodyView>
      <BottomView>
        {!accountDocument || fileCanBeUploadedForDocument(accountDocument) ? (
          <UploadButton
            title={strings['profileSettingsScreen.verification.upload.submit']}
            Icon={UploadSvg}
            iconPosition={ButtonIconPosition.Right}
            variant={ButtonVariant.PrimaryLight}
            onPress={onUploadPickerPress}
          />
        ) : (
          <MaxDocumentView>
            <MaxDocumentText>{strings['DocumentsScreen.text']}</MaxDocumentText>
          </MaxDocumentView>
        )}
        <Button
          title={strings['profileSettingsScreen.nextButton']}
          variant={ButtonVariant.Primary}
          onPress={onContinuePress}
        />
      </BottomView>
    </RootView>
  );
});

const RootView = variance(SafeAreaScrollView)(() => ({
  root: {
    flex: 1,
  },
}));

const TopView = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
}));

const TopViewText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.textAdditional2,
  },
}));

const BodyView = variance(View)(() => ({
  root: {
    flex: 1,
    paddingHorizontal: 15,
  },
}));

const BottomView = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
  },
}));

const UploadButton = variance(Button)(() => ({
  root: {
    marginBottom: 20,
  },
}));

const UploadedTitleText = variance(Text)(theme => ({
  root: {
    marginBottom: 20,
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.textMain,
  },
}));

const UploadedBodyView = variance(View)(() => ({
  root: {
    marginBottom: 20,
  },
}));

const MaxDocumentView = variance(View)(() => ({
  root: {
    marginBottom: 20,
    alignItems: 'center',
  },
}));

const MaxDocumentText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 14,
    color: theme.palette.textMain,
  },
}));
