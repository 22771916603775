import type {CurrencyCode} from '@ncwallet-app/core';
import {useStrings, variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/core/src/Layout';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {Pressable, Text, View} from 'react-native';

import {useGetWalletData} from './useGetWalletData';
import type {WalletActionKind} from './WalletAction';

export type WalletActionItemProps = {
  type: WalletActionKind;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  onPress: () => void;
  showRightArrow?: boolean;
  currencyCode?: CurrencyCode;
  currencyName?: string;
  smallCard?: boolean;
};

export default observer(function WalletActionItem(
  props: WalletActionItemProps,
) {
  const {type, onPress, style, disabled, currencyName, smallCard} = props;
  const action = useGetWalletData();
  const {icon, name, description} = action[type];
  const strings = useStrings();
  return (
    <WalletActionTouchable onPress={onPress} disabled={disabled} style={style}>
      <WalletActionTouchableBody smallCard={smallCard}>
        <IconCircle>{icon}</IconCircle>
        <TextContainer>
          <ActionNameText>
            {strings[name]} {currencyName || ''}
          </ActionNameText>
          <DescriptionText>{strings[description]}</DescriptionText>
        </TextContainer>
      </WalletActionTouchableBody>
    </WalletActionTouchable>
  );
});

const WalletActionTouchable = variance(Pressable)(theme => ({
  root: {
    padding: 15,
    flexDirection: 'row',
    borderColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderWidth: 1,
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderRadius: 8,
      },
    }),
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.3,
  },
}));

const WalletActionTouchableBody = variance(View)(theme => ({
  root: {
    flex: 1,
    gap: 15,
    flexDirection: 'row',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'column',
      },
    }),
  },
  smallCard: {
    flexDirection: 'row',
  },
}));

const TextContainer = variance(View)(() => ({
  root: {
    gap: 5,
  },
}));

const ActionNameText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    fontSize: 14,
    color: theme.palette.textMain,
  },
}));

const DescriptionText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 13,
    color: theme.palette.textAdditional2,
  },
}));

const IconCircle = variance(View)(theme => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));
