export default function formatCurrencyShortDescription(
  name: string = '',
  contract: string | null | undefined,
) {
  const result = [];
  if (name !== '') {
    result.push(name);
  }
  if (contract) {
    result.push(`(${contract})`);
  }
  return result.join(' ');
}
