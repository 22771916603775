export type CurrencyValue<T> = string & {readonly __currency__: T};

export type FiatCurrencyCodeNumberMap = {
  USD: 840;
  EUR: 978;
  RUB: 643;
};

export type FiatCurrencyCode = keyof FiatCurrencyCodeNumberMap;

export type CryptoCurrencyCode =
  | 'BTC'
  | 'ETH'
  | 'USDT'
  | 'MATIC'
  | 'TON'
  | 'TRX';

export type CurrencyCode = FiatCurrencyCode | CryptoCurrencyCode;

export type RateValue<From, To> = string & {
  readonly __from__: From;
  readonly __to__: To;
};

export type RateCode<From, To> = string & {
  readonly __from__: From;
  readonly __to__: To;
};

export const toRateCode = <From extends CurrencyCode, To extends CurrencyCode>(
  from: From,
  to: To,
) => `${from}${to}` as RateCode<From, To>;
