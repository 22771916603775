import {AnalyticsImpl} from '../Analytics';
import type {AppStateHelper} from '../AppStateHelper';
import type {ErrorRepository} from '../ErrorRepository';
import type {Http} from '../Http';
import type {Json} from '../Json';
import type {JsonRpcClient} from '../JsonRpc';
import {
  HeadlessMessagingHelperImpl,
  HeadlessMessagingImpl,
  RemoteMessageStoreImpl,
} from '../Messaging';
import type {MessagingIdentity} from '../MessagingIdentity';
import {MessagingIdentityNotifierService} from '../MessagingIdentity';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import type {MessagingRoot} from './MessagingRoot';

export type MessagingRootServiceDependencies = {
  readonly errorRepository: ErrorRepository;
  readonly json: Json;
  readonly http: Http;
  readonly messagingIdentity: MessagingIdentity;
  readonly appStateHelper: AppStateHelper;
  readonly ncWalletJsonRpcClient: JsonRpcClient<
    NCWalletCallScheme,
    NCWalletNotificationScheme
  >;
};

export default class MessagingRootService implements MessagingRoot, Service {
  constructor(private readonly _root: MessagingRootServiceDependencies) {}

  get errorRepository() {
    return this._root.errorRepository;
  }

  get json() {
    return this._root.json;
  }

  get http() {
    return this._root.http;
  }

  get messagingIdentity() {
    return this._root.messagingIdentity;
  }

  get appStateHelper() {
    return this._root.appStateHelper;
  }

  get ncWalletJsonRpcClient() {
    return this._root.ncWalletJsonRpcClient;
  }

  readonly analytics = new AnalyticsImpl(this);
  private readonly _messagingIdentityNotifierService =
    new MessagingIdentityNotifierService(this);
  readonly remoteMessageStore = new RemoteMessageStoreImpl(this);
  readonly headlessMessaging = new HeadlessMessagingImpl(this);
  readonly headlessMessagingHelper = new HeadlessMessagingHelperImpl(this);

  subscribe() {
    return batchDisposers(this._messagingIdentityNotifierService.subscribe());
  }
}
