import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {IpInfo} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {useHeaderHeight} from '@react-navigation/elements';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';

import {useIpSettings} from '../../../CommonNavigationContainers/hooks/ipSettings/useIpSettings';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {IpListScreen} from '../../../screens/UserSettingsScreens/IpListScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ListBlockedIpsBindingProps =
  SmallHomeStackBindingProps<'ListBlockedIp'>;

export default observer(function ListBlockedIpsBinding(
  props: ListBlockedIpsBindingProps,
) {
  const {navigation} = props;
  const goToFormIpModal = useCallback(
    (ipInfo: IpInfo) => {
      navigation.navigate('PromptUpdateIp', {
        id: ipInfo.id,
        name: ipInfo.name,
        ip: ipInfo.ip,
        type: IpType.Blocked,
      });
    },
    [navigation],
  );
  const toAdd = useCallback(() => {
    navigation.navigate('PromptAddIp', {type: IpType.Blocked});
  }, [navigation]);

  const {ipSettings, loadIpSettings} = useIpSettings();
  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();
  const headerHeight = useHeaderHeight();

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void loadIpSettings();
          });
        }
      }),
    [getIsFocused, getIsReady, loadIpSettings],
  );

  const handleOnClearAllowedList = useCallback(() => {
    navigation.navigate('PromptToClearAllowedList');
  }, [navigation]);

  return (
    <IpListScreen
      onClearAllowedList={handleOnClearAllowedList}
      isWarningShown={ipSettings && ipSettings.white_ips.length > 0}
      type={IpType.Blocked}
      ipInfos={ipSettings ? ipSettings.black_ips : undefined}
      onIpPress={goToFormIpModal}
      onAdd={toAdd}
      keyboardAvoiding
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      keyboardVerticalOffset={headerHeight}
    />
  );
});
