import {useStyles} from '@ncwallet-app/core';
import type {SessionId} from '@ncwallet-app/core/src/NCWalletServer/sessions/Session';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {FlatListProps, ListRenderItem} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import type {SessionInfo} from '../../ActiveSessionsScreen/SessionInfo';
import type {SessionListItemProps} from './SessionActiveListItem';

export type SessionListProps = Partial<FlatListProps<SessionInfo>> & {
  getSessions: () => SessionInfo[] | undefined;
  onSessionPress?: (sessionId: SessionId) => void;
  RenderItemComponent: React.ComponentType<SessionListItemProps>;
  preventShrink?: boolean;
};

const keyExtractor = (entry: SessionInfo) => entry.id;

export default observer(function SessionList(props: SessionListProps) {
  const {
    getSessions,
    onSessionPress,
    RenderItemComponent,
    preventShrink = false,
    ...rest
  } = props;

  const renderItem: ListRenderItem<SessionInfo> = useCallback(
    ({item, index}) => {
      return (
        <RenderItemComponent
          isFirst={index === 0}
          session={item}
          onPress={onSessionPress}
        />
      );
    },
    [RenderItemComponent, onSessionPress],
  );

  const style = useStyles(theme => ({
    container: {
      flexShrink: preventShrink ? 0 : 1,
      flexGrow: 0,
      borderWidth: 1,
      borderRadius: 8,
      borderColor: theme.palette.uiSecondary,
      backgroundColor: theme.palette.uiPrimary,
    },
  }));

  return (
    <FlatList
      overScrollMode="never"
      data={getSessions()}
      renderItem={renderItem}
      extraData={onSessionPress}
      keyExtractor={keyExtractor}
      style={style.container}
      {...rest}
    />
  );
});
