import {useBoolean, useRoot, WindowDimensionsStatic} from '@ncwallet-app/core';
import {action, observable, runInAction} from 'mobx';
import React, {useCallback, useEffect, useState} from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import {TAB_BAR_CONTENT_HEIGHT} from './BottomTabBar';
import type {TabBarContextProps, TabBarLayout} from './TabBarContext';
import {TabBarContext} from './TabBarContext';

export type TabBarProviderProps = {
  children: React.ReactNode;
};

export const TabBarProvider = ({children}: TabBarProviderProps) => {
  const [visible, open, hide] = useBoolean(false);
  const {bottom} = useSafeAreaInsets();
  const {windowDimensions} = useRoot();
  const height = bottom + TAB_BAR_CONTENT_HEIGHT;
  const [layoutBox] = useState(() =>
    observable.box<TabBarLayout>({
      width: WindowDimensionsStatic.getInitialDimensions().window.width,
      height,
    }),
  );
  useEffect(
    () =>
      windowDimensions.updates.listen(
        action(update => {
          layoutBox.set({...layoutBox.get(), width: update.window.width});
        }),
      ),
    [layoutBox, windowDimensions],
  );
  const onChangeLayout = useCallback(
    (newLayout: TabBarLayout) => {
      runInAction(() => {
        layoutBox.set(newLayout);
      });
    },
    [layoutBox],
  );
  const getTabBarLayout = useCallback(() => layoutBox.get(), [layoutBox]);
  const value: TabBarContextProps = {
    changeLayout: onChangeLayout,
    visible,
    open,
    hide,
    getTabBarLayout,
  };
  return (
    <TabBarContext.Provider value={value}>{children}</TabBarContext.Provider>
  );
};
