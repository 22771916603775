import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React from 'react';
import type {ViewProps} from 'react-native';
import {StyleSheet, View} from 'react-native';

import {useBreakpoint} from '../../util';
import {CONTAINER_XL_HORIZONTAL_OFFSET, XL_CONTAINER_WIDTH} from './constants';

export function useResponsiveContainerWidth() {
  const breakpoint = useBreakpoint();
  let width;
  switch (breakpoint) {
    case 'xl':
      width = XL_CONTAINER_WIDTH;
  }
  return width;
}

export default observer(function Container(
  props: PropsWithChildren<ViewProps>,
) {
  const {children, style, ...rest} = props;
  const containerWidth = useResponsiveContainerWidth();
  return (
    <View
      {...rest}
      style={[
        styles.container,
        containerWidth ? {width: containerWidth} : undefined,
        style,
      ]}>
      {children}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: '100%',
    paddingHorizontal: CONTAINER_XL_HORIZONTAL_OFFSET,
  },
});
