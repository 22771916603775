import {sized, useTheme, variance} from '@ncwallet-app/core';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {HelpSvg, InfoSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Linking, Text, View} from 'react-native';

export type HistoryDetailsCommentProps = {
  comment?: string;
};

type templatedCommentType =
  | {
      names: string[];
      links: string[];
      emptyComment: string[];
    }
  | undefined;

export default observer(function HistoryDetailsComment({
  comment,
}: HistoryDetailsCommentProps) {
  const theme = useTheme();
  const openLink = useCallback(async (link: string) => {
    await Linking.openURL(link);
  }, []);
  const templatedComment = useMemo((): templatedCommentType => {
    if (!comment) {
      return undefined;
    }
    const namesReg = /\[(.*?)]/gi;
    const linksReg = /\((.*?)\)/gi;
    const names = comment.match(namesReg);
    const links = comment.match(linksReg);
    if (!names || !links) {
      return undefined;
    }
    const emptyComment = comment
      .replace(namesReg, '')
      .replace(linksReg, '#')
      .split('#');
    return {
      names: names.map(i => i.replace(/[[\]]/gi, '').trim()),
      links: links.map(i => i.replace(/[()]/gi, '').trim()),
      emptyComment,
    };
  }, [comment]);
  return (
    <Container>
      {!comment ? (
        <ContainerText>-</ContainerText>
      ) : templatedComment === undefined ? (
        <ContainerText>{comment}</ContainerText>
      ) : (
        templatedComment.emptyComment.map((em, i) => {
          const name = templatedComment.names[i];
          const link = templatedComment.links[i];
          return (
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            <React.Fragment key={em + i}>
              <ContainerText>{em}</ContainerText>
              {link && name && (
                <LinkContainer
                  offsetLeft={name === '|!|' || name === '|?|'}
                  onPress={() => openLink(link)}>
                  {name === '|!|' ? (
                    <InfoIcon color={theme.palette.uiAdditional1} />
                  ) : name === '|?|' ? (
                    <HelpIcon color={theme.palette.uiAdditional1} />
                  ) : (
                    <Link>{name}</Link>
                  )}
                </LinkContainer>
              )}
            </React.Fragment>
          );
        })
      )}
    </Container>
  );
});

const HelpIcon = sized(HelpSvg, 17);
const InfoIcon = sized(InfoSvg, 17);

const LinkContainer = variance(TouchableOpacity)(() => ({
  root: {},
  offsetLeft: {
    marginLeft: 5,
  },
}));

const Container = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    flex: 1,
  },
}));

const ContainerText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    color: theme.palette.textSecondary,
  },
}));

const Link = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    color: theme.palette.info,
    padding: 0,
    margin: 0,
  },
}));
