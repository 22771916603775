import type {
  DecimalString,
  ISODateString,
  OtpCode,
  WalletId,
  WalletLimitPeriod,
  WalletsLimitsCreate,
} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import dayjs from 'dayjs';
import {useCallback, useRef} from 'react';

import {useBaseErrorDescription} from '..';

// eslint-disable-next-line import-x/prefer-default-export
export const useCreateLimitWithOtp = (
  onSuccess: (res: WalletsLimitsCreate['result']) => void,
) => {
  const root = useRoot();
  const isSubmittingRef = useRef(false);
  const [getErrorText, setErrorText, resetErrorText] =
    useBaseErrorDescription();

  const createLimitWithOpt = useCallback(
    async (
      walletId: WalletId,
      limit: DecimalString,
      period: WalletLimitPeriod,
      code: string,
    ) => {
      if (isSubmittingRef.current) {
        return;
      }
      isSubmittingRef.current = true;
      const res = await root.ncWalletJsonRpcClient.call(
        'wallets.limits.create',
        {
          code_2fa: code as OtpCode,
          utc_2fa: dayjs().utc().toISOString() as ISODateString,
          wallet_id: walletId,
          limit,
          limit_period: period,
        },
      );
      isSubmittingRef.current = false;

      if (res.success) {
        onSuccess(res.right);
        return;
      } else {
        setErrorText(res.left);
      }
    },
    [onSuccess, root.ncWalletJsonRpcClient, setErrorText],
  );

  return {
    getErrorText,
    resetErrorText,
    createLimitWithOpt,
  };
};
