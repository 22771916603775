import {CommonActions} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import NotifyAboutSuccessfulSendingContainer from '../../../CommonNavigationContainers/containers/NotifyAboutSuccessfulSendingContainer';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type NotifyAboutSuccessfulSendingBindingProps =
  SmallHomeStackBindingProps<'NotifyAboutSuccessfulSending'>;

export default observer(function NotifyAboutSuccessfulSendingBinding(
  props: NotifyAboutSuccessfulSendingBindingProps,
) {
  const {navigation, route} = props;

  const onSubmit = useCallback(() => {
    if (hasRouteInHistory(navigation.getState(), 'Root')) {
      navigation.navigate('Root');
    } else {
      navigation.dispatch(
        CommonActions.reset({index: 0, routes: [{name: 'Root'}]}),
      );
    }
  }, [navigation]);

  const onBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <NotifyAboutSuccessfulSendingContainer
      params={route.params}
      onBack={onBack}
      onSubmit={onSubmit}
    />
  );
});
