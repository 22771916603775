import type {AvailableLanguageCode, CountryNameData} from './constant';
import {COUNTRIES_SETS, TRANSLATION_SETS} from './constant';
import type {
  TemplateSubstitution,
  TranslationKey,
  TranslationProvider,
} from './TranslationProvider';

export default class TranslationProviderImpl implements TranslationProvider {
  getTranslation(
    language: AvailableLanguageCode,
    translation: TranslationKey,
  ): string {
    return TRANSLATION_SETS[language][translation];
  }

  getCountryNamesData(language: AvailableLanguageCode): CountryNameData[] {
    return COUNTRIES_SETS[language];
  }

  executeTemplate(
    language: AvailableLanguageCode,
    translation: TranslationKey,
    substitution: TemplateSubstitution,
  ): string {
    return this.getTranslation(language, translation).replaceAll(
      /{(.+?)}/g,
      (_: string, key: string) => String(substitution[key] ?? ''),
    );
  }
}
