import {urlLinks, variance} from '@ncwallet-app/core';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import queryString from 'query-string';
import React from 'react';
import {Linking, View} from 'react-native';

import {config} from './config';

const {stringify} = queryString;

export type ShareBlockProps = {
  url: string;
};

type ShareLinkProps = Partial<{
  link_id: string;
  url: string;
  description: string;
  image: string;
  t: string;
}>;

const getShareLink = (props: ShareLinkProps) => {
  const params = stringify({
    ...props,
    title: 'NC Wallet',
    description: '',
  });
  return `${urlLinks.share}?${params}`;
};

export default observer(function ShareBlock(props: ShareBlockProps) {
  const {url} = props;

  return (
    <Container>
      {config.map(({t, Icon}) => (
        <IconPress
          key={t}
          onPress={() => Linking.openURL(getShareLink({url, t}))}>
          {Icon}
        </IconPress>
      ))}
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    marginTop: 10,
  },
}));

const IconPress = variance(TouchableOpacity)(() => ({
  root: {
    marginLeft: 8,
  },
}));
