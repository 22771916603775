import {observer} from 'mobx-react-lite';
import React from 'react';

import {SendScreenUserForm} from '../SendScreenUserForm';
import {InputsRow} from './Shared';

export type LgUserTabViewProps = {
  userTo: string;
  userToError?: string;
  onUserActionPress: () => void;
  onUserToChange: (userTo: string) => void;
};
export default observer(function LgUserTabView(props: LgUserTabViewProps) {
  return (
    <>
      <InputsRow>
        <SendScreenUserForm
          wideStyle
          error={!!props.userToError}
          value={props.userTo}
          onInputChange={props.onUserToChange}
          onActionPress={props.onUserActionPress}
        />
      </InputsRow>
    </>
  );
});
