import {useStrings, variance} from '@ncwallet-app/core';
import type {
  AccountDocument,
  AccountDocumentType,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import {AccountDocumentStatus} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {Text, View} from 'react-native';

import type {DocumentKind} from '../../../ListDocumentsScreen/DocumentButton';
import {getDocumentStrings} from '../../../ListDocumentsScreen/DocumentButton';
import {getDocumentBadge} from '../../../ListDocumentsScreen/ListDocumentsScreen';
import TermsCheckbox from '../../../ListDocumentsScreen/TermsCheckbox';
import {
  BodyTag,
  Divider,
  HeaderTag,
  HeaderTitleTag,
  StepText,
} from '../useCardStyles';
import BackButton from './BackButton';
import VerificationTouchableListItem from './VerificationTouchableListItem';

export type DocumentsListProps = {
  documentTypes: AccountDocumentType[];
  documents: AccountDocument[];
  onDocumentPress: (documentType: AccountDocumentType) => void;
  onDocumentAddPress?: () => void;
  onSubmit: () => void;
  onBack: () => void;
};

export default observer(function DocumentList(props: DocumentsListProps) {
  const {onBack, onDocumentPress, onSubmit, documentTypes, documents} = props;
  const strings = useStrings();
  const [isCheckedTerms, setIsCheckedTerms] = useState(false);

  const onToggleTerms = () => {
    setIsCheckedTerms(prev => !prev);
  };

  return (
    <>
      <HeaderTag>
        <BackButton onPress={onBack} />
        <HeaderTitleTag>
          {strings['profileSettingsScreen.verification.documents.title']}
        </HeaderTitleTag>
        <StepText>
          {strings['profileSettingsScreen.verificationStep']} 3/3
        </StepText>
      </HeaderTag>
      <BodyTag>
        <TopText>
          {strings['profileSettingsScreen.verification.documents.topText']}
        </TopText>
        <BorderedView>
          {documentTypes.map((documentType, index) => {
            const isLast = index === documentTypes.length - 1;
            const onPress = () => {
              onDocumentPress(documentType);
            };
            const document = documents.find(
              (doc: AccountDocument) => doc.type === documentType,
            );
            const badge = getDocumentBadge(document?.status, strings);
            const labelTranslated =
              getDocumentStrings(strings)[documentType as DocumentKind];
            const disabled = !(
              document?.status === undefined ||
              document.status === AccountDocumentStatus.Uploaded
            );
            return (
              <React.Fragment key={documentType}>
                <VerificationTouchableListItem
                  badge={badge}
                  label={labelTranslated}
                  isLabelLarge
                  large
                  Icon={ArrowRightSvg}
                  onPress={onPress}
                  isInlineValue={true}
                  disabled={disabled}
                />
                {!isLast && <Divider />}
              </React.Fragment>
            );
          })}
        </BorderedView>

        <BottomView>
          <TermsCheckbox
            isChecked={isCheckedTerms}
            onPress={onToggleTerms}
            label={strings['profileSettingsScreen.verification.documents.term']}
          />
          <SubmitButton
            title={strings['feedbackModalScreen.submitButton']}
            variant={ButtonVariant.Primary}
            onPress={onSubmit}
            disabled={!isCheckedTerms}
          />
        </BottomView>
      </BodyTag>
    </>
  );
});

const BorderedView = variance(View)(theme => ({
  root: {
    borderRadius: 12,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    overflow: 'hidden',
  },
}));

const TopText = variance(Text)(theme => ({
  root: {
    marginBottom: 20,
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 14,
  },
}));

const BottomView = variance(View)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const SubmitButton = variance(Button)(() => ({
  root: {
    marginTop: 20,
  },
}));
