import {useStrings, useStyles, useTheme, variance} from '@ncwallet-app/core';
import {
  Input,
  InputType,
  InputVariant,
  LG_BREAKPOINT,
  TouchableOpacity,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import FeedbackModalSubmitButtons from './FeedbackModalSubmitButtons';

export type FeedbackModalBodyProps = {
  email: string;
  message: string;
  onEmailChange: (email: string) => void;
  onMessageChange: (message: string) => void;
  emailErrorShown: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isGoToAppStoreButtonVisible: boolean;
  onGoToAppStoreButtonPress: () => void;
};

export default observer(function FeedBackModalBody(
  props: FeedbackModalBodyProps,
) {
  const strings = useStrings();
  const isLg = useIsDimensions('lg');
  const theme = useTheme();
  const styles = useBodyStyles();

  return (
    <>
      <Container>
        {isLg && (
          <FeedbackModalTitleWebContainer>
            <FeedbackModalTitleWeb>
              {strings['feedbackModalScreen.header']}
            </FeedbackModalTitleWeb>
            <TouchableOpacity onPress={props.onClose}>
              <CrossSvg color={theme.palette.uiMain} />
            </TouchableOpacity>
          </FeedbackModalTitleWebContainer>
        )}
        <Subtitle>{strings['feedbackModalScreen.bodySubtitle']}</Subtitle>
        <FeedbackNote>{strings['feedbackModalScreen.note']}</FeedbackNote>
        <Label>{strings['feedbackModalScreen.inputLabel']}</Label>
        <Input
          variant={
            props.emailErrorShown ? InputVariant.Error : InputVariant.Default
          }
          value={props.email}
          placeholder={strings['feedbackModalScreen.inputPlaceholder']}
          type={InputType.Email}
          onChangeText={props.onEmailChange}
        />
        <Label style={styles.secondLabel}>
          {strings['feedbackModalScreen.textareaPlaceholder']}
        </Label>
        <Input
          value={props.message}
          placeholder={strings['feedbackModalScreen.textareaPlaceholder']}
          onChangeText={props.onMessageChange}
          multiline
          innerContainerStyle={styles.textArea}
        />
        {isLg && (
          <FeedbackModalSubmitButtons
            onSubmit={props.onSubmit}
            isGoToAppStoreButtonVisible={props.isGoToAppStoreButtonVisible}
            onGoToAppStoreButtonPress={props.onGoToAppStoreButtonPress}
          />
        )}
      </Container>
      {!isLg && (
        <FeedbackModalSubmitButtons
          onSubmit={props.onSubmit}
          isGoToAppStoreButtonVisible={props.isGoToAppStoreButtonVisible}
          onGoToAppStoreButtonPress={props.onGoToAppStoreButtonPress}
        />
      )}
    </>
  );
});

const Container = variance(View)(theme => ({
  root: {
    paddingVertical: 20,
    paddingHorizontal: 10,
    backgroundColor: theme.palette.background,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: '100%',
        maxWidth: 450,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingHorizontal: 20,
        borderRadius: 6,
      },
    }),
  },
}));

const Subtitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional2,
    fontSize: 14,
    lineHeight: 17,
    marginBottom: 15,
  },
}));

const Label = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 16,
    marginBottom: 10,
  },
}));

const FeedbackModalTitleWeb = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 18,
    lineHeight: 22,
  },
}));

const FeedbackModalTitleWebContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
}));

const FeedbackNote = variance(Text)(theme => ({
  root: {
    marginBottom: 10,
    ...theme.fontByWeight('700'),
    color: theme.palette.error,
  },
}));

const useBodyStyles = () =>
  useStyles(theme => ({
    secondLabel: {
      marginTop: 20,
    },

    textArea: {
      height: 100,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          justifyContent: 'space-between',
          alignItems: 'stretch',
          height: 144,
          lineHeight: 35,
          marginBottom: 20,
        },
      }),
    },
  }));
