import {useMemo} from 'react';

import useRoot from '../../Root/hooks/useRoot';
import useStrings from '../../Root/hooks/useStrings';
import {UserStatusError} from '../types';

export default function useStateErrorText() {
  const {userStatusErrorDetector} = useRoot();
  const strings = useStrings();
  return useMemo((): string | null => {
    switch (userStatusErrorDetector.userStatusError) {
      case UserStatusError.Verification:
        return strings.Status_KYC_Required;
      case UserStatusError.Suspended:
      case UserStatusError.Banned:
        return strings.Status_Suspended;
      default:
        return null;
    }
  }, [
    strings.Status_KYC_Required,
    strings.Status_Suspended,
    userStatusErrorDetector.userStatusError,
  ]);
}
