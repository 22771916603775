import {useRoot, useStrings, useTheme} from '@ncwallet-app/core';
import {Pressable} from '@ncwallet-app/ui';
import {RestoreSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import type {StackHeaderProps} from '@react-navigation/stack';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import NavigationBar, {BackButton} from './NavigationBar';

export default observer((props: StackHeaderProps) => {
  const strings = useStrings();
  const {twoFaSettingsState} = useRoot();
  const theme = useTheme();

  const button = twoFaSettingsState.isEdited ? (
    <Pressable onPress={twoFaSettingsState.reset}>
      <RestoreSvg color={theme.palette.info} />
    </Pressable>
  ) : null;

  const handleGoBack = useCallback(() => {
    if (props.navigation.canGoBack()) {
      props.navigation.goBack();
    } else {
      props.navigation.replace('ListMenuCategories');
    }
  }, [props.navigation]);

  const leftButton = <BackButton onPress={handleGoBack} />;

  return (
    <NavigationBar
      title={strings['screenTitle.userSettingsTwoFactorAuth']}
      rightButton={button}
      leftButton={leftButton}
    />
  );
});
