import type {ReadonlyTunnel, Request, Response} from '../JsonRpc';
import {TUNNEL_INCOMING_MESSAGE, TUNNEL_OUTGOING_MESSAGE} from '../JsonRpc';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import JsonRpcLogContent from './JsonRpcLogContent';
import type {Log} from './Log';

export default class DuplexTunnelLogBridgeService implements Service {
  constructor(
    private readonly _root: {readonly log: Log},
    private readonly _tunnel: ReadonlyTunnel<
      Request | Response,
      Request | Response
    >,
  ) {}

  private _onMessage(incoming: boolean, message: Request | Response) {
    this._root.log.write(new JsonRpcLogContent(incoming, message));
  }

  subscribe() {
    return batchDisposers(
      this._tunnel.io.listen(TUNNEL_INCOMING_MESSAGE, message => {
        this._onMessage(true, message);
      }),
      this._tunnel.io.listen(TUNNEL_OUTGOING_MESSAGE, message => {
        this._onMessage(false, message);
      }),
    );
  }
}
