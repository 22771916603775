import {useRoot} from '@ncwallet-app/core';
import {useEffect} from 'react';
import {BackHandler, Platform} from 'react-native';

// eslint-disable-next-line import-x/prefer-default-export
export const useHandleBack = () => {
  const {navigationContainerState, largeNavigationContainerBinding: binding} =
    useRoot();

  useEffect(() => {
    if (Platform.OS === 'android') {
      const subscription = BackHandler.addEventListener(
        'hardwareBackPress',
        () => {
          const canGoBack = binding.ref?.canGoBack();
          if (canGoBack) {
            return false;
          }
          binding.ref?.navigate('PromptCloseApp');
          return true;
        },
      );

      return () => {
        subscription.remove();
      };
    }
  }, [navigationContainerState.latestState, binding]);
};
