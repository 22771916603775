import {variance} from '@ncwallet-app/core';
import {BaseSkeleton, SkeletonColor, TouchableListItem} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export default observer(function WalletSkeleton() {
  return (
    <SkeletonListItem>
      <View>
        <BaseSkeleton width={40} height={40} isRounded />
      </View>
      <SkeletonListItemMiddle>
        <BaseSkeleton width={55} height={20} />
        <BaseSkeleton width={70} height={15} gap={5} />
        <BaseSkeleton width={96} height={15} color={SkeletonColor.Light} />
      </SkeletonListItemMiddle>
      <SkeletonListItemEnd>
        <BaseSkeleton width={100} height={25} />
        <BaseSkeleton width={95} height={15} gap={5} />
        <BaseSkeleton width={75} height={15} color={SkeletonColor.Light} />
      </SkeletonListItemEnd>
    </SkeletonListItem>
  );
});

const SkeletonListItem = variance(TouchableListItem)(theme => ({
  root: {
    paddingRight: 25,
    paddingLeft: 15,
    paddingVertical: 17,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const SkeletonListItemMiddle = variance(View)(() => ({
  root: {
    marginRight: 'auto',
    marginLeft: 15,
  },
}));

const SkeletonListItemEnd = variance(View)(() => ({
  root: {
    alignItems: 'flex-end',
  },
}));
