import type {WalletLimit, WalletLimitId} from '@ncwallet-app/core';
import type {
  PromptOtpToLimitRemovalRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {
  LimitRemovalAction,
  PROMPT_LIMIT_REMOVAL_ROUTE,
  PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE,
  SHOW_LIMIT_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {isNil} from 'lodash';
import {useCallback} from 'react';

import {useDeleteLimit} from '../../../../../../CommonNavigationContainers/hooks/walletLimits/useDeleteLimit';
import type {PromptLimitRemovalCardProps} from '../../../../../../screens/LgLimitsSettingsScreen/LimitCard/PromptLimitRemovalCard';
import type {LargeSwitchBindingProps} from '../../LargeSwitchBindingProps';

export default function usePromptLimitRemovalCard(
  getLimit: (limitId: WalletLimitId) => WalletLimit | undefined,
  refresh: () => void,
  props: LargeSwitchBindingProps<'ListLimits'>,
): PromptLimitRemovalCardProps {
  const {navigation, route} = props;
  const selectedCard = route.params?.focusedSelectedLimit;

  const onSuccess = useCallback(() => {
    if (selectedCard?.kind !== PROMPT_LIMIT_REMOVAL_ROUTE) {
      return;
    }
    const limit = getLimit(selectedCard.params.limitId);
    if (isNil(limit)) {
      return;
    }
    refresh();
    navigation.setParams({
      focusedSelectedLimit: {
        kind: SHOW_LIMIT_ROUTE,
        params: {
          walletId: limit.wallet_id,
          period: limit.period,
        },
      },
    });
  }, [navigation, refresh, selectedCard, getLimit]);

  const goToOtp = useCallback(
    (params: RouteParams<PromptOtpToLimitRemovalRoute>) => {
      navigation.setParams({
        focusedSelectedLimit: {
          kind: PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE,
          params,
        },
      });
    },
    [navigation],
  );

  const {deleteLimit} = useDeleteLimit(goToOtp, onSuccess);

  const onSubmit = useCallback(() => {
    if (selectedCard?.kind !== PROMPT_LIMIT_REMOVAL_ROUTE) {
      return;
    }
    return deleteLimit(selectedCard.params.limitId, selectedCard.params.action);
  }, [deleteLimit, selectedCard]);

  const onBack = useCallback(() => {
    if (selectedCard?.kind !== PROMPT_LIMIT_REMOVAL_ROUTE) {
      return;
    }

    const limit = getLimit(selectedCard.params.limitId);
    if (isNil(limit)) {
      return;
    }

    navigation.setParams({
      focusedSelectedLimit: {
        kind: SHOW_LIMIT_ROUTE,
        params: {
          walletId: limit.wallet_id,
          period: limit.period,
        },
      },
    });
  }, [navigation, selectedCard, getLimit]);

  const isCancel =
    selectedCard?.kind === PROMPT_LIMIT_REMOVAL_ROUTE
      ? selectedCard.params.action !== LimitRemovalAction.Delete
      : false;

  return {onBack, onSubmit, isCancel};
}
