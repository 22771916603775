import {formatCryptoValue, useRoot, useStrings} from '@ncwallet-app/core';
import type {
  NotifyAboutSuccessfulSendingRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {useThrottledCallback} from 'use-debounce';

import useGoToSupport from '../../Navigation/hooks/useGoToSupport';
import {EXTRA_BOTTOM_OFFSET} from '../../screens/constants';
import {CryptoSuccessScreen} from '../../screens/CryptoSuccessScreen';

export type NotifyAboutSuccessfulSendingContainerProps = {
  params: RouteParams<NotifyAboutSuccessfulSendingRoute>;
  onSubmit: () => void;
  onBack: () => void;
};

export default observer(function NotifyAboutSuccessfulSendingContainer(
  props: NotifyAboutSuccessfulSendingContainerProps,
) {
  const {params, onBack, onSubmit} = props;
  const {currency, amount, addressTo, error} = params;
  const strings = useStrings();
  const {flashMessage} = useRoot();
  const DEBOUNCE_INTERVAL = 3000;

  const goToSupport = useGoToSupport();

  const handleCopyAddress = useCallback(
    (address: string) => {
      void setStringAsync(address);
      flashMessage.showMessage({
        variant: 'success',
        title: strings.copied,
      });
    },
    [strings, flashMessage],
  );

  const copyAddressThrottled = useThrottledCallback(
    handleCopyAddress,
    DEBOUNCE_INTERVAL,
    {
      trailing: false,
    },
  );

  return (
    <CryptoSuccessScreen
      keyboardAvoiding
      onBack={onBack}
      goToSupport={goToSupport}
      title={strings['screenTitle.sendSuccess']}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      onSubmit={onSubmit}
      paymentValue={`${formatCryptoValue(amount)} ${currency}`}
      paymentText={strings['sendCrypto.success.sendToAddressText']}
      addressTo={addressTo}
      onCopy={copyAddressThrottled}
      error={error}
    />
  );
});
