import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type SessionHeaderProps = {
  title: string;
};

export default observer(function SessionHeader(props: SessionHeaderProps) {
  const {title} = props;
  return (
    <Container>
      <SessionHeaderText>{title}</SessionHeaderText>
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 15,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const SessionHeaderText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
    fontSize: 18,
  },
}));
