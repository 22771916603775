import {useStrings} from '@ncwallet-app/core';
import type {LargeHomeStackParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeHomeStackParamList';
import {createStackNavigator} from '@react-navigation/stack';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Platform} from 'react-native';

import {ANIMATION_ENABLED} from '../../Navigation/constants';
import ShowTransactionReportBinding from './bindings/LargeSwitch/bindings/ShowTransactionReportBinding';
import LargeSwitchBinding from './bindings/LargeSwitch/LargeSwitchBinding';
import PromptAccountDeletionBinding from './bindings/PromptAccountDeletionBinding';
import PromptCloseAppBinding from './bindings/PromptCloseAppBinding';
import PromptDateOfHistoryPeriodBinding from './bindings/PromptDateOfHistoryPeriodBinding';
import PromptFeedbackBinding from './bindings/PromptFeedbackBinding';
import PromptLinkToEmailBinding from './bindings/PromptLinkToEmailBinding';
import PromptLinkToTelegramBinding from './bindings/PromptLinkToTelegramBinding';
import PromptOtpToDeleteAccountBinding from './bindings/PromptOtpToDeleteAccountBinding';
import PromptRateBinding from './bindings/PromptRateBinding';
import ShowQrBinding from './bindings/ShowQrBinding';
import ShowQrCodeScannerBinding from './bindings/ShowQrCodeScannerBinding';
import {useHandleBack} from './useHandleBack';

export default observer(function LargeHomeStack() {
  const strings = useStrings();
  useHandleBack();
  return (
    <Navigator
      screenOptions={{
        headerShown: false,
        animationEnabled: ANIMATION_ENABLED,
        cardStyle: {
          flex: 1,
        },
      }}
      initialRouteName="Root">
      <Screen name="Root" component={LargeSwitchBinding} />
      <Screen
        name="PromptRate"
        component={PromptRateBinding}
        options={{
          headerShown: false,
          title: strings['screenTitle.rate'],
          presentation: 'transparentModal',
        }}
      />
      <Screen
        name="PromptLinkToEmail"
        component={PromptLinkToEmailBinding}
        options={{
          headerShown: false,
          title: strings['linkToEmail.title'],
          presentation: 'transparentModal',
        }}
      />
      <Screen
        name="PromptLinkToTelegram"
        component={PromptLinkToTelegramBinding}
        options={{
          headerShown: false,
          title: strings['linkToTelegram.title'],
          presentation: 'transparentModal',
        }}
      />
      <Screen
        name="PromptAccountDeletion"
        component={PromptAccountDeletionBinding}
        options={{
          headerShown: false,
          title: strings['profileSettingsScreen.deleteAccount'],
          presentation: 'transparentModal',
        }}
      />
      <Screen
        name="PromptCloseApp"
        component={PromptCloseAppBinding}
        options={{
          headerShown: false,
          presentation: 'transparentModal',
        }}
      />
      <Screen
        name="PromptOtpToDeleteAccount"
        component={PromptOtpToDeleteAccountBinding}
        options={{
          headerShown: false,
          title: strings['profileSettingsScreen.deleteAccount'],
          presentation: 'transparentModal',
        }}
      />
      <Screen
        name="PromptDateOfHistoryPeriod"
        component={PromptDateOfHistoryPeriodBinding}
        options={{
          headerShown: false,
          title: strings['screenTitle.calendar'],
          presentation: 'transparentModal',
        }}
      />
      <Screen
        name="ScanQrCodeScanner"
        component={ShowQrCodeScannerBinding}
        options={{
          title: strings['screenTitle.qrCode'],
          presentation: 'transparentModal',
          cardOverlayEnabled: Platform.OS === 'ios',
          headerShown: false,
          animationEnabled: false,
        }}
      />
      <Screen
        name="PromptFeedback"
        component={PromptFeedbackBinding}
        options={{
          title: strings['feedbackModalScreen.header'],
          presentation: 'transparentModal',
          cardOverlayEnabled: Platform.OS === 'ios',
          headerShown: false,
          animationEnabled: false,
        }}
      />
      <Screen
        name="ShowTransactionReport"
        component={ShowTransactionReportBinding}
        options={{
          title: strings['feedbackModalScreen.header'],
          presentation: 'transparentModal',
          cardOverlayEnabled: Platform.OS === 'ios',
          headerShown: false,
          animationEnabled: false,
        }}
      />
      <Screen
        name="ShowQr"
        component={ShowQrBinding}
        options={{
          title: strings['screenTitle.qrCode'],
          presentation: 'transparentModal',
          cardOverlayEnabled: Platform.OS === 'ios',
          headerShown: false,
          animationEnabled: false,
        }}
      />
    </Navigator>
  );
});

const {Navigator, Screen} = createStackNavigator<LargeHomeStackParamList>();
