import dayjs from 'dayjs';

import type {Credentials, PreAuthCredentials} from '../Credentials';
import type {DeviceIdentification} from '../DeviceIdentification';
import type {BusyError, GlobalError, UnknownError} from '../Error';
import {BUSY_ERROR, UNKNOWN_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {FlashMessage} from '../FlashMessage';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {
  JsonKeyValueStore,
  JsonSecureKeyValueMap,
} from '../JsonKeyValueStore';
import type {JsonRpcClient} from '../JsonRpc';
import type {SystemLanguageProvider} from '../Localization';
import type {Log} from '../Log';
import type {Maybe} from '../Maybe';
import type {
  AuthResult,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  OtpCode,
} from '../NCWalletServer';
import type {
  BaseOAuth2PreSignInParams,
  OAuth2ProviderMap,
} from '../OAuth2RestClient';
import type {SentryLog} from '../SentryLog';
import type {SessionContextProvider} from '../SessionContext/SessionContextProvider';
import type {BusSource, Disposer, RouterSource, Service} from '../structure';
import {BusImpl, RouterImpl} from '../structure';
import type {ISODateString, Time} from '../Time';
import type {DeviceId, MultiFactorToken, RefreshToken} from '../units';
import type {PinCode} from '../UserIdentity';
import type {AuthClient, AuthRequestMap, AuthResponseMap} from './AuthClient';
import {
  COMPLETE_LINKAGE,
  PRE_SIGN_IN,
  RESET,
  SIGN_IN_BY_BIOMETRICS,
  SIGN_IN_BY_OTP,
  SIGN_IN_BY_PIN,
  SIGN_IN_BY_REFRESH_TOKEN,
  SIGN_OUT,
  TOUCH,
} from './AuthClient';
import type {
  AuthHelper,
  BiometricAuthParams,
  SignOutOptions,
} from './AuthHelper';
import {BiometricAuthKind} from './AuthHelper';
import type {AuthQuery, AuthResponse} from './AuthQuery';
import {
  AUTHORIZED,
  MULTI_FACTOR,
  NO_LOCAL_RECORD,
  PRE_AUTH,
  UNAUTHORIZED,
} from './AuthQuery';
import type {JwtHelper} from './JwtHelper';
import type {OAuth2RestClientHelper} from './Oauth2RestClientHelper';

export type LocalAuthClientServiceDependencies = {
  readonly errorRepository: ErrorRepository;
  readonly time: Time;
  readonly jwtHelper: JwtHelper;
  readonly deviceIdentification: DeviceIdentification;
  readonly jsonSecureKeyValueStore: JsonKeyValueStore<JsonSecureKeyValueMap>;
  readonly oAuth2RestClientHelper: OAuth2RestClientHelper;
  readonly authQuery: AuthQuery;
  readonly sessionContextProvider: SessionContextProvider;
  readonly flashMessage: FlashMessage;
  readonly ncWalletJsonRpcClient: JsonRpcClient<
    NCWalletCallScheme,
    NCWalletNotificationScheme
  >;
  readonly systemLanguageProvider: SystemLanguageProvider;
  readonly log: Log;
  readonly sentryLog: SentryLog;
  readonly signOutReasonLog: Log;
};

export default class LocalAuthClientService
  implements AuthClient, AuthHelper, Service
{
  private _busy = false;
  private readonly _requests = new RouterImpl<AuthRequestMap>();
  private readonly _responses = new RouterImpl<AuthResponseMap>();
  private readonly _errors = new BusImpl<(_: GlobalError) => unknown>();

  constructor(protected readonly _root: LocalAuthClientServiceDependencies) {}

  get requests(): RouterSource<AuthRequestMap> {
    return this._requests;
  }

  get responses(): RouterSource<AuthResponseMap> {
    return this._responses;
  }

  get errors(): BusSource<(_: GlobalError) => unknown> {
    return this._errors;
  }

  subscribe(): Disposer | undefined {
    void this.reset();
    return undefined;
  }

  async reset() {
    return this._guard(RESET, [], () => this.getInitialState());
  }

  protected async getInitialState() {
    return this._root.authQuery.query();
  }

  preSignIn<T extends keyof OAuth2ProviderMap>(
    params: BaseOAuth2PreSignInParams<T>,
  ) {
    return this._guard(PRE_SIGN_IN, [params], () =>
      this.unsafePreSignIn(params),
    );
  }

  protected async unsafePreSignIn<T extends keyof OAuth2ProviderMap>(
    params: BaseOAuth2PreSignInParams<T>,
  ) {
    const deviceId_ = await this._root.deviceIdentification.getDeviceId();
    if (!deviceId_.success) {
      return deviceId_;
    }

    const sessionContext = this._root.sessionContextProvider.getContext();
    const credentials_ = await this._root.oAuth2RestClientHelper.preSignIn({
      ...sessionContext,
      ...params,
      device_id: deviceId_.right,
      lang: this._root.systemLanguageProvider.getLanguage(),
    });
    return this._processFreshCredentials(credentials_);
  }

  signInBySetPin(pin: PinCode): Promise<Maybe<AuthResponse>> {
    return this._guard(SIGN_IN_BY_PIN, [pin], async () => {
      const [preAuth_, deviceId_] = await Promise.all([
        this._getPreAuthCredentialsIfPossible(),
        this._root.deviceIdentification.getDeviceId(),
      ]);
      if (!deviceId_.success) {
        return deviceId_;
      }
      if (!preAuth_.success) {
        return preAuth_;
      }
      const sessionContext = this._root.sessionContextProvider.getContext();
      const credentials_ =
        await this._root.oAuth2RestClientHelper.signInBySetPin({
          ...sessionContext,
          pin,
          multi_factor_token: preAuth_.right.multiFactorToken,
          device_id: deviceId_.right,
        });
      return this._processFreshCredentials(credentials_);
    });
  }

  signInByPin(pin: PinCode): Promise<Maybe<AuthResponse>> {
    return this._guard(SIGN_IN_BY_PIN, [pin], async () => {
      const response_ = await this._root.authQuery.query();
      if (!response_.success) {
        return response_;
      }

      if (response_.right.kind === AUTHORIZED) {
        return this._signInByPinAndRefreshToken(
          pin,
          response_.right.credentials.refreshToken,
        );
      } else if (response_.right.kind === PRE_AUTH) {
        return this._signInByPinAndMultiFactorToken(
          pin,
          response_.right.credentials.multiFactorToken,
        );
      } else {
        return error(
          this._root.errorRepository.create({
            kind: UNKNOWN_ERROR,
            description: 'Cannot sign in',
          }),
        );
      }
    });
  }

  signInByBiometrics(
    params: BiometricAuthParams,
  ): Promise<Maybe<AuthResponse>> {
    return this._guard(SIGN_IN_BY_BIOMETRICS, [params], async () => {
      const deviceId_ = await this._root.deviceIdentification.getDeviceId();
      const sessionContext = this._root.sessionContextProvider.getContext();
      if (!deviceId_.success) {
        return deviceId_;
      }
      const res =
        params.kind === BiometricAuthKind.SignIn
          ? await this._root.oAuth2RestClientHelper.signIn({
              ...sessionContext,
              device_id: deviceId_.right,
              multi_factor_token: params.token,
              biometry_signature: params.signature,
            })
          : await this._root.oAuth2RestClientHelper.refresh({
              ...sessionContext,
              device_id: deviceId_.right,
              token: params.token,
              biometry_signature: params.signature,
            });

      return this._processFreshCredentials(res);
    });
  }

  signInByOtp(code: OtpCode) {
    return this._guard(SIGN_IN_BY_OTP, [code], async () => {
      const response_ = await this._root.authQuery.query();
      if (!response_.success) {
        return response_;
      }
      const response = response_.right;
      switch (response.kind) {
        case UNAUTHORIZED:
          return success(response);
        case PRE_AUTH:
        case AUTHORIZED:
          return error(
            this._root.errorRepository.create({
              kind: UNKNOWN_ERROR,
              description: 'Cannot sign in by OTP without multifactor token',
            }),
          );
      }
      const deviceId_ = await this._root.deviceIdentification.getDeviceId();
      if (!deviceId_.success) {
        return deviceId_;
      }
      const sessionContext = this._root.sessionContextProvider.getContext();
      const credentials_ = await this._root.oAuth2RestClientHelper.twoFa({
        ...sessionContext,
        multi_factor_token: response.credentials.multiFactorToken,
        code,
        utc_2fa: dayjs().utc().toISOString() as ISODateString,
        device_id: deviceId_.right,
      });
      return this._processFreshCredentials(credentials_);
    });
  }

  signInByRefreshToken(pin: PinCode, token: RefreshToken, deviceId: DeviceId) {
    return this._guard(
      SIGN_IN_BY_REFRESH_TOKEN,
      [token, deviceId],
      async () => {
        const spoof_ =
          await this._root.deviceIdentification.spoofDeviceId(deviceId);
        if (!spoof_.success) {
          return spoof_;
        }

        return this._signInByPinAndRefreshToken(pin, token);
      },
    );
  }

  completeLinkage(params: AuthResult) {
    return this._guard(COMPLETE_LINKAGE, [params], async () => {
      if ('multi_factor_token' in params) {
        return this._processFreshCredentials(
          success({
            isPreAuth: false,
            isDirect: false,
            multiFactorToken: params.multi_factor_token,
          }),
        );
      } else {
        return this._processFreshCredentials(
          success({
            isPreAuth: false,
            isDirect: true,
            accessToken: params.access_token,
            refreshToken: params.refresh_token,
          }),
        );
      }
    });
  }

  touch() {
    return this._guard(TOUCH, [], async () => {
      const response_ = await this._root.authQuery.query();
      if (!response_.success) {
        return response_;
      }
      const response = response_.right;
      if (response.kind !== PRE_AUTH) {
        return error(
          this._root.errorRepository.create<UnknownError>({
            kind: UNKNOWN_ERROR,
            description: 'state can be touched only when PRE_AUTH',
          }),
        );
      }

      if (!response.credentials.isNew) {
        return success(response);
      }

      const credentials: PreAuthCredentials = {
        ...response.credentials,
        isNew: false,
        isFirstSignIn: true,
      };
      const set_ = await this._root.jsonSecureKeyValueStore.set(
        'auth2',
        credentials,
      );
      if (!set_.success) {
        return set_;
      }
      return success({kind: PRE_AUTH, credentials});
    });
  }

  signOut(options?: SignOutOptions) {
    return this._guard(SIGN_OUT, [options?.local], async () => {
      if (!options?.local) {
        const reason = options?.reason ?? 'No reason provided';
        const message = 'Deleting session, ' + reason;
        this._root.log.write({body: message});
        this._root.signOutReasonLog.write({body: message});
        this._root.sentryLog.write(message);
        await this._root.ncWalletJsonRpcClient.call('sessions.delete', {});
      }
      const delete_ = await this._root.jsonSecureKeyValueStore.delete('auth2');
      if (!delete_.success) {
        return delete_;
      }
      return success({
        kind: UNAUTHORIZED,
        reason: NO_LOCAL_RECORD,
      });
    });
  }

  private async _getPreAuthCredentialsIfPossible(): Promise<
    Either<PreAuthCredentials, GlobalError>
  > {
    const response_ = await this._root.authQuery.query();
    if (!response_.success) {
      return response_;
    }
    const response = response_.right;
    switch (response.kind) {
      case UNAUTHORIZED:
      case MULTI_FACTOR:
      case AUTHORIZED:
        return error(
          this._root.errorRepository.create({
            kind: UNKNOWN_ERROR,
            description: 'Cannot get pre auth response',
          }),
        );
    }

    return success(response.credentials);
  }

  private async _signInByPinAndMultiFactorToken(
    pin: PinCode,
    token: MultiFactorToken,
  ) {
    const deviceId_ = await this._root.deviceIdentification.getDeviceId();
    const sessionContext = this._root.sessionContextProvider.getContext();
    if (!deviceId_.success) {
      return deviceId_;
    }

    const credentials_ = await this._root.oAuth2RestClientHelper.signIn({
      ...sessionContext,
      pin,
      multi_factor_token: token,
      device_id: deviceId_.right,
    });
    return this._processFreshCredentials(credentials_);
  }

  private async _signInByPinAndRefreshToken(
    pin: PinCode,
    token: RefreshToken,
  ): Promise<Maybe<AuthResponse>> {
    const deviceId_ = await this._root.deviceIdentification.getDeviceId();
    const sessionContext = this._root.sessionContextProvider.getContext();
    if (!deviceId_.success) {
      return deviceId_;
    }

    const credentials_ = await this._root.oAuth2RestClientHelper.refresh({
      ...sessionContext,
      pin,
      token,
      device_id: deviceId_.right,
    });
    return this._processFreshCredentials(credentials_);
  }

  private async _processFreshCredentials(
    credentials_: Either<Credentials, GlobalError>,
  ): Promise<Maybe<AuthResponse>> {
    if (!credentials_.success) {
      return credentials_;
    }
    const set_ = await this._root.jsonSecureKeyValueStore.set(
      'auth2',
      credentials_.right,
    );
    if (!set_.success) {
      return set_;
    }

    if (credentials_.right.isPreAuth) {
      await this._root.jsonSecureKeyValueStore.set(
        'hasRemoteChannel',
        credentials_.right.hasRemote2faChannels,
      );

      return success({kind: PRE_AUTH, credentials: credentials_.right});
    } else if (!credentials_.right.isDirect) {
      return success({
        kind: MULTI_FACTOR,
        credentials: credentials_.right,
      });
    }

    return success({
      kind: AUTHORIZED,
      credentials: credentials_.right,
    });
  }

  private async _guard<K extends keyof AuthRequestMap>(
    key: K,
    args: Parameters<AuthRequestMap[K]>,
    op: () => Promise<Maybe<AuthResponse>>,
  ): Promise<Maybe<AuthResponse>> {
    if (this._busy) {
      return error(
        this._root.errorRepository.create<BusyError>({kind: BUSY_ERROR}),
      );
    }
    this._busy = true;
    try {
      this._requests.send(key, ...args);
      const outcome = await op();
      if (outcome.success) {
        this._responses.send(outcome.right.kind, outcome.right as never);
      } else {
        this._errors.send(outcome.left);
      }
      return outcome;
    } catch (raw) {
      const e = this._root.errorRepository.create<UnknownError>({
        kind: UNKNOWN_ERROR,
        raw,
      });
      this._errors.send(e);
      return error(e);
    } finally {
      this._busy = false;
    }
  }
}
