import type {CryptoCurrencyCode} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {CryptoCurrency} from '@ncwallet-app/core/src/NCWalletServer/CryptoCurrency';
import {observable, runInAction} from 'mobx';
import {useCallback, useMemo, useState} from 'react';

// eslint-disable-next-line import-x/no-anonymous-default-export
export default () => {
  const root = useRoot();
  const [cryptoMapLoadingBox] = useState(() => observable.box(false));
  const [cryptoMapBox] = useState(() =>
    observable.box<Map<CryptoCurrencyCode, CryptoCurrency> | undefined>(),
  );
  const [timeoutErrorBox] = useState(() => observable.box<boolean>());
  const loadCryptoCurrencies = useCallback(async () => {
    runInAction(() => {
      cryptoMapLoadingBox.set(true);
    });
    try {
      const cryptos_ = await root.currencyStore.refreshCryptoCurrencies();
      if (!cryptos_.success) {
        timeoutErrorBox.set(true);
        return;
      }
      runInAction(() => {
        cryptoMapBox.set(new Map(cryptos_.right.map(_ => [_.code, _])));
      });
    } finally {
      runInAction(() => {
        cryptoMapLoadingBox.set(false);
      });
    }
  }, [cryptoMapBox, cryptoMapLoadingBox, root.currencyStore, timeoutErrorBox]);
  const getCryptoCurrency = useCallback(
    (code: CryptoCurrencyCode): CryptoCurrency | undefined => {
      const map = cryptoMapBox.get();
      if (!map) {
        return undefined;
      }
      return map.get(code);
    },
    [cryptoMapBox],
  );
  const areCryptoCurrenciesLoading = useCallback(
    () => cryptoMapLoadingBox.get(),
    [cryptoMapLoadingBox],
  );
  return useMemo(
    () => ({
      areCryptoCurrenciesLoading,
      loadCryptoCurrencies,
      getCryptoCurrency,
      timeoutErrorBox,
    }),
    [
      areCryptoCurrenciesLoading,
      getCryptoCurrency,
      loadCryptoCurrencies,
      timeoutErrorBox,
    ],
  );
};
