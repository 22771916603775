import {variance} from '@ncwallet-app/core';
import type {SessionId} from '@ncwallet-app/core/src/NCWalletServer/sessions/Session';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';

import {OtherSessionsList} from './OtherSessionsList';
import type {SessionInfo} from './SessionInfo';

export type ActiveSessionsScreenProps = BaseSafeAreaProps & {
  other: SessionInfo[] | undefined;
  current: SessionInfo | undefined;
  onSessionDelete: (sessionId: SessionId) => void;
  selectedSessionId: SessionId | undefined;
  promptConfirmationToEndSession: (id: SessionId) => void;
  listActiveSessions: () => void;
};

export const ActiveSessionsScreen = observer(
  (props: ActiveSessionsScreenProps) => {
    const {other, current, promptConfirmationToEndSession, ...rest} = props;

    const handleSelect = useCallback(
      (id: SessionId) => {
        promptConfirmationToEndSession(id);
      },
      [promptConfirmationToEndSession],
    );

    return (
      <Root>
        <OtherSessionsList
          currentSession={current}
          data={other}
          onPress={handleSelect}
          {...rest}
        />
      </Root>
    );
  },
);

const Root = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));
