import type {Theme} from '@ncwallet-app/core';

export enum DocumentButtonBadgeVariant {
  Success,
  Error,
  Warning,
}

export const mapBadgeVariantToColor = (theme: Theme) => {
  return new Map([
    [DocumentButtonBadgeVariant.Success, {primary: theme.palette.success}],
    [
      DocumentButtonBadgeVariant.Error,
      {primary: theme.palette.error, secondary: theme.palette.additional5},
    ],
    [
      DocumentButtonBadgeVariant.Warning,
      {primary: theme.palette.primary, secondary: theme.palette.additional4},
    ],
  ]);
};
