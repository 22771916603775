import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import dayjs from 'dayjs';

const ADULT_AGE_DATE = dayjs().subtract(18, 'year');
const MIN_YEAR_BIRTHDAY = 1900;
const MIN_NUMBER_DAY_OR_MOUNTH = 1;
const MAX_NUMBER_DAY = 31;
const MAX_NUMBER_MOUNTH = 12;
const dayjsFormat = 'YYYY-MM-DD';

export enum BirthdayErrors {
  Invalid = 'Invalid',
  Underage = 'Underage',
  Empty = 'Empty',
}

export const BIRTHDAY_ERROR_TO_LOCALE: Record<BirthdayErrors, LocaleKeys> = {
  [BirthdayErrors.Invalid]: 'verification.validate.error',
  [BirthdayErrors.Underage]: 'verification.validate.errorUnderTheAge',
  [BirthdayErrors.Empty]: 'profileSettingsScreen.verificationError',
};

export const getPersonalFormErrors = (f: {
  firstName: string;
  lastName: string;
  birthday: string;
}) => {
  return {
    firstName: f.firstName.trim().length === 0,
    lastName: f.lastName.trim().length === 0,
    birthday: getBirthdayError(f.birthday),
  };
};

const getBirthdayError = (birthday: string): false | BirthdayErrors => {
  if (birthday.trim().length === 0) {
    return BirthdayErrors.Empty;
  }
  const date = dayjs(birthday, dayjsFormat, true);

  if (!date.isValid()) {
    return BirthdayErrors.Invalid;
  }

  if (date.isAfter(ADULT_AGE_DATE)) {
    return BirthdayErrors.Underage;
  }

  return false;
};

export function formatDate(input: string): string {
  const cleanedInput = input.replace(/\D/g, '');
  const currentYear = new Date().getFullYear();

  let year = '';
  let month = '';
  let day = '';

  if (cleanedInput.length >= 4) {
    year = cleanedInput.slice(0, 4);
    const parsed = parseInt(year, 10);
    if (parsed < MIN_YEAR_BIRTHDAY) {
      year = MIN_YEAR_BIRTHDAY.toString();
    }
    if (parsed > currentYear) {
      year = currentYear.toString();
    }
  } else {
    year = cleanedInput.slice(0, 4);
  }

  if (cleanedInput.length >= 6) {
    month = cleanedInput.slice(4, 6);
    const parsed = parseInt(month, 10);
    if (parsed < MIN_NUMBER_DAY_OR_MOUNTH) {
      month = '01';
    }
    if (parsed > MAX_NUMBER_MOUNTH) {
      month = '12';
    }
  } else if (cleanedInput.length > 4) {
    month = cleanedInput.slice(4);
  }

  if (cleanedInput.length >= 8) {
    day = cleanedInput.slice(6, 8);
    const parsed = parseInt(day, 10);
    if (parsed < MIN_NUMBER_DAY_OR_MOUNTH) {
      day = '01';
    }
    if (parsed > MAX_NUMBER_DAY) {
      day = '31';
    }
  } else if (cleanedInput.length > 6) {
    day = cleanedInput.slice(6);
  }

  let formattedDate = year;
  if (month) {
    formattedDate += `-${month}`;
  }
  if (day) {
    formattedDate += `-${day}`;
  }
  return formattedDate;
}
