import {makeObservable, observable, runInAction} from 'mobx';

import type {PixelRatio} from '../DevicePixelRatio';
import type {Disposer, Service} from '../structure';
import type {DeviceScreenState} from './DeviceScreenState';

export default class WebDeviceScreenService
  implements DeviceScreenState, Service
{
  @observable.ref private _pixelRatio = window.devicePixelRatio;

  constructor() {
    makeObservable(this);
  }

  get pixelRatio() {
    return this._pixelRatio as PixelRatio;
  }

  subscribe() {
    const controller = new AbortController();
    const {signal} = controller;
    const updatePixelRatio = () => {
      const mqString = `(resolution: ${window.devicePixelRatio}dppx)`;
      const media = matchMedia(mqString);
      media.addEventListener('change', updatePixelRatio, {once: true, signal});
      runInAction(() => {
        this._pixelRatio = window.devicePixelRatio;
      });
    };

    updatePixelRatio();

    return (() => {
      controller.abort();
    }) as Disposer;
  }
}
