import type {WalletId} from '@ncwallet-app/core';
import {useRoot, useStrings} from '@ncwallet-app/core';
import type {StackHeaderProps} from '@react-navigation/stack';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import NavigationBar, {BackButton} from './NavigationBar';

export default observer(function CurrencyShowLimitNavigationBar(
  props: StackHeaderProps,
) {
  const {route} = props;
  const strings = useStrings();
  const {walletStore} = useRoot();
  const walletId = (route.params as {walletId?: WalletId}).walletId;
  const wallet = walletId ? walletStore.getWalletById(walletId) : undefined;

  const title = useMemo(() => {
    if (wallet) {
      return strings['screenTitle.currencyLimit'].replace(
        '{name}',
        wallet.currency,
      );
    }
  }, [wallet, strings]);

  const handleGoBack = useCallback(() => {
    if (props.navigation.canGoBack()) {
      props.navigation.goBack();
    } else {
      props.navigation.replace('ListMenuCategories');
    }
  }, [props.navigation]);

  const leftButton = <BackButton onPress={handleGoBack} />;

  return <NavigationBar title={title} leftButton={leftButton} />;
});
