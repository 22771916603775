import type {
  DecimalString,
  FiatCurrencyCodeNumberMap,
} from '@ncwallet-app/core';
import {keepMeaningfulDigitsInFiat} from '@ncwallet-app/core';
import {CurrencyCircleLogo, useIsDimensions} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {TitleWithLabel} from '../../SharedComponents/SettingsItem';
import {ItemRowTag, ItemTag, ItemValueTag} from './useLgCryptoSectionListStyle';

export type LgCryptoItemProps = {
  cryptoCode: string;
  base?: boolean;
  value?: DecimalString;
  title: string;
  baseFiatCode?: keyof FiatCurrencyCodeNumberMap;
  onPress?: () => void;
};

export default observer(function LgCryptoItem(props: LgCryptoItemProps) {
  const isLg = useIsDimensions('lg');
  const iconSize = isLg ? 22 : 40;
  return (
    <ItemTag onPress={props.onPress} second base={props.base}>
      <CurrencyCircleLogo code={props.cryptoCode} size={iconSize} />
      <ItemRowTag icon>
        <TitleWithLabel noMargin>{props.title}</TitleWithLabel>
        <ItemValueTag>{`${
          (props.value && keepMeaningfulDigitsInFiat(props.value)) ?? ''
        } ${props.baseFiatCode || ''}`}</ItemValueTag>
      </ItemRowTag>
    </ItemTag>
  );
});
