import {action, observable, reaction, runInAction} from 'mobx';

import type {AccountStore} from '../AccountStore';
import type {AppStateHelper} from '../AppStateHelper';
import {PathImpl} from '../CommonNavigationScheme/Path';
import type {LocationSource} from '../Location';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import type {Url} from '../units';
import {first} from '../util';
import splitByPrefix from '../util/splitByPrefix';

const TAG_BRACKETS_REGEXP = /\[|\]/gi;
const TAG_SEPARATOR = ',';

export default class TagIdentificationService implements Service {
  private readonly _queue = observable.set<string>();

  constructor(
    private readonly _root: {
      readonly locationSource: LocationSource;
      readonly accountStore: AccountStore;
      readonly appStateHelper: AppStateHelper;
    },
  ) {}

  subscribe() {
    void this._handleInitialLink();
    return batchDisposers(
      this._sendPendingTagsWhenTheNetworkIsReady(),
      this._root.locationSource.updates.listen(this._enqueueTags),
    );
  }

  private async _handleInitialLink() {
    const initial_ = await this._root.locationSource.getInitial();
    if (!initial_.success) {
      return;
    }
    this._enqueueTags(initial_.right);
  }

  private _sendPendingTagsWhenTheNetworkIsReady() {
    return reaction(
      () =>
        this._root.appStateHelper.isReadyToMakeRequests && this._queue.size > 0,
      async shouldSend => {
        if (shouldSend) {
          await this._root.accountStore.update({tags: [...this._queue]});
          runInAction(() => {
            this._queue.clear();
          });
        }
      },
      {fireImmediately: true},
    );
  }

  private readonly _enqueueTags = action((url: Url) => {
    const tags = this._find(url);
    for (const tag of tags) {
      this._queue.add(tag);
    }
  });

  private _find(url: Url): Array<string> {
    const split = splitByPrefix(url);
    if (!split) {
      return [];
    }
    const [, rest] = split;
    const path = PathImpl.parse(rest);
    const tags = first(path.params?.tag);
    const searchParamNewUser = first(path.params?.new_user);

    const isValidToSave = !searchParamNewUser;

    if (tags && isValidToSave) {
      return tags.replace(TAG_BRACKETS_REGEXP, '').split(TAG_SEPARATOR);
    }

    return [];
  }
}
