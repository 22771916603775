import {
  colored,
  sized,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {Pressable, SafeAreaInset, SafeAreaLayout} from '@ncwallet-app/ui';
import {CrossSvg, QrSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

export type CameraHeaderProps = {
  onClose: () => void;
};

export default observer(function CameraHeader({onClose}: CameraHeaderProps) {
  const strings = useStrings();
  const theme = useTheme();
  return (
    <SafeAreaLayout style={styles.root} insets={SafeAreaInset.TOP}>
      <View style={styles.content}>
        <Pressable hitSlop={20} style={styles.closeButton} onPress={onClose}>
          <CrossIcon />
        </Pressable>
        <View style={styles.qrIcon}>
          <QrIcon color={theme.palette.primary} />
        </View>
        <CameraTitleText>{strings['screenTitle.qrCode']}</CameraTitleText>
      </View>
    </SafeAreaLayout>
  );
});

const CrossIcon = colored(sized(CrossSvg, 25), '#fff');
const QrIcon = sized(QrSvg, 25);

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  qrIcon: {
    marginRight: 10,
    marginTop: 2,
  },
  closeButton: {
    position: 'absolute',
    left: 20,
  },
});

const CameraTitleText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    color: theme.palette.whitey,
  },
}));
