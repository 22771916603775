import type {NavigationContainer} from '../NavigationContainer';
import type {QrCodeScanner} from './QrCodeScanner';

export default class CommonQrCodeScannerImpl implements QrCodeScanner {
  constructor(
    private readonly _root: {
      readonly navigationContainer: NavigationContainer;
    },
  ) {}

  async open() {
    this._root.navigationContainer.ref?.navigate('ScanQrCodeScanner');
  }
}
