import {useTheme, variance} from '@ncwallet-app/core';
import {LIST_NOTIFICATIONS_ROUTE} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {WalletSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import {useIsHeaderModalsFocused} from '../hooks/useIsHeaderModalsFocused';
import type {ScreenHeaderWideProps} from '../hooks/useScreenHeaderWideProps';
import HeaderFrame from './HeaderFrame';

export type SettingsHeaderProps = ScreenHeaderWideProps;

export default observer(function SettingsHeader(props: SettingsHeaderProps) {
  const {onWalletsPress, focusedMenu, toggleMenu} = props;
  const theme = useTheme();

  const {areNotificationsVisible} = useIsHeaderModalsFocused(focusedMenu);
  const toggleNotifications = useCallback(() => {
    toggleMenu(LIST_NOTIFICATIONS_ROUTE);
  }, [toggleMenu]);

  const tail = useMemo(
    () => (
      <WalletMenuTouchable onPress={onWalletsPress}>
        <WalletSvg color={theme.palette.uiMain} />
      </WalletMenuTouchable>
    ),
    [onWalletsPress, theme],
  );

  return (
    <HeaderFrame
      tail={tail}
      areNotificationsVisible={areNotificationsVisible}
      toggleNotifications={toggleNotifications}
      {...props}
    />
  );
});

const WalletMenuTouchable = variance(TouchableOpacity)(() => ({
  root: {
    padding: 15,
    paddingRight: 7,
  },
}));
