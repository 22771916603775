import {useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {CommonActions} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';

import {useClearAllowedIpListWithOtp} from '../../../CommonNavigationContainers/hooks/ipSettings/useClearAllowedIpListWithOtp';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {useSendTelegramOtp} from '../../../Navigation/hooks/useSendTelegramOtp';
import {
  CARD,
  SubmitOtpScreen,
} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptOtpToClearAllowedListBindingProps =
  SmallHomeStackBindingProps<'PromptOtpToClearAllowedList'>;

export default observer(function PromptOtpToClearAllowedListBinding(
  props: PromptOtpToClearAllowedListBindingProps,
) {
  const {navigation} = props;
  const root = useRoot();
  const strings = useStrings();
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();

  const resetToIpList = useCallback(() => {
    if (hasRouteInHistory(navigation.getState(), 'ListBlockedIp')) {
      navigation.navigate('ListBlockedIp');
    } else {
      navigation.dispatch(
        CommonActions.reset({
          index: 2,
          routes: [
            {name: 'Root'},
            {name: 'ListSecuritySettings'},
            {name: 'ListBlockedIp'},
          ],
        }),
      );
    }
  }, [navigation]);

  const {getErrorText, resetErrorText, clearAllowedIpListWithOtp} =
    useClearAllowedIpListWithOtp(resetToIpList);

  const handleTwoFaResendCode = useCallback(async () => {
    try {
      await sendOtp(null, {forceChannel: false});
    } catch {
      /* empty */
    }
  }, [sendOtp]);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (getIsReady() && getIsFocused()) {
      if (
        root.twoFaSettingsState.currentTwoFaProvider ===
        TwoFaProviderKind.Telegram
      ) {
        void handleTwoFaResendCode();
      }
    }
  }, [getIsReady, getIsFocused, root, handleTwoFaResendCode]);

  return (
    <SubmitOtpScreen
      layout={CARD}
      resetError={resetErrorText}
      getError={getErrorText}
      titleText={strings['promptOtpToUpdateIpList.title']}
      submitText={strings['promptOtpToUpdateIpList.submit']}
      onSubmit={clearAllowedIpListWithOtp}
      appVersion={root.deviceInfo.getAppVersionWithBuildNumber()}
      twoFaProvider={root.twoFaSettingsState.currentTwoFaProvider}
      onTwoFaResendCode={handleTwoFaResendCode}
      resendCodeInterval={interval}
      resetInterval={resetInterval}
    />
  );
});
