import {useTheme, variance} from '@ncwallet-app/core';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {
  ArrowLeftWide,
  ArrowRightSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type CalendarModalMonthSwitcherProps = {
  date: string;
  onPrevMonth: () => void;
  onNextMonth: () => void;
  lastMonth: boolean;
};

export default observer(function CalendarModalMonthSwitcher({
  date,
  onPrevMonth,
  onNextMonth,
  lastMonth,
}: CalendarModalMonthSwitcherProps) {
  const theme = useTheme();

  return (
    <Container>
      <Arrow
        onPress={() => {
          onPrevMonth();
        }}>
        <ArrowLeftWide color={theme.palette.textPrimary} />
      </Arrow>
      <Title>{date}</Title>
      <Arrow onPress={onNextMonth} end>
        {!lastMonth && <ArrowRightSvg color={theme.palette.textPrimary} />}
      </Arrow>
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 16,
    lineHeight: 19,
  },
}));

const Arrow = variance(TouchableOpacity)(() => ({
  root: {
    width: 50,
    flex: 1,
    justifyContent: 'center',
  },
  end: {
    alignItems: 'flex-end',
  },
}));
