import type {
  AddressInfo,
  CryptoAddress,
  CurrencyStore,
  InOutCurrency,
  Wallet,
  WalletId,
  WalletStore,
} from '@ncwallet-app/core';
import type {
  AddressNetwork,
  AddressType,
} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {orderBy} from 'lodash';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

// eslint-disable-next-line import-x/prefer-default-export
export class ReceiveCryptoAddressesBindingState {
  @observable private _walletId: WalletId | undefined;
  @observable private _isLoading: boolean = false;
  @observable private _address: CryptoAddress | undefined;
  @observable private _network: AddressNetwork | undefined;
  @observable private _availableNetworks: InOutCurrency[] | undefined;
  @observable private _networkFilter: AddressNetwork | undefined;

  @computed private get _wallet(): Wallet | undefined {
    return (
      this._walletId && this._root.walletStore.getWalletById(this._walletId)
    );
  }

  @computed get addresses(): AddressInfo[] | undefined {
    return (
      this._wallet &&
      orderBy(
        this._wallet.addresses,
        _ => new Date(_.created_at),
        'desc',
      ).filter(w => w.network === (this._networkFilter || this.defaultNetwork))
    );
  }

  @computed get addressInfo(): AddressInfo | undefined {
    if (!this._address || !this._wallet) {
      return undefined;
    }
    return this._wallet.addresses.find(
      a => a.address === this._address && a.network === this._network,
    );
  }

  @computed get showNetwork(): boolean {
    const currency =
      this._wallet &&
      this._root.currencyStore.getCryptoCurrency(this._wallet.currency);

    return !!currency?.options.show_network;
  }

  @computed get availableNetworks(): InOutCurrency[] | undefined {
    const currency =
      this._wallet &&
      this._root.currencyStore.getCryptoCurrency(this._wallet.currency);
    return currency?.options.currencies_in;
  }

  @computed get defaultNetwork(): AddressNetwork | undefined {
    const currency =
      this._wallet &&
      this._root.currencyStore.getCryptoCurrency(this._wallet.currency);

    return currency?.options.default_network;
  }

  constructor(
    private readonly _root: {
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
    },
  ) {
    makeObservable(this);
  }

  getIsLoading = () => this._isLoading;

  @computed get crypto() {
    const code = this._wallet?.currency;
    return code && this._root.currencyStore.getCryptoCurrency(code);
  }

  @action.bound
  async refresh(walletId: WalletId) {
    this._isLoading = true;
    this._walletId = walletId;
    // noinspection JSIgnoredPromiseFromCall
    await Promise.all([
      this._root.walletStore.refreshWallets(),
      this._root.currencyStore.refreshCryptoCurrencies(),
    ]);

    runInAction(() => {
      this._isLoading = false;
    });
  }

  @action.bound
  async setAddressParams(
    address?: CryptoAddress,
    network?: AddressNetwork,
    networkFilter?: AddressNetwork,
  ) {
    this._address = address;
    this._network = network;
    this._networkFilter = networkFilter ?? network;
  }

  @action.bound
  async createAddress(network: AddressNetwork, type?: AddressType) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._root.walletStore.createAddress(this._walletId!, network, type);
  }
}
