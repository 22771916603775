import {variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT, useIsDimensions} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {StyleSheet, Text, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import type {SvgProps} from 'react-native-svg';

export type SlideItem = {
  Icon: React.ComponentType<SvgProps>;
  title: string;
  description: string;
  style?: StyleProp<ViewStyle>;
  dataSet?: Record<string, unknown>;
};

export type SlideProps = SlideItem & {};

export const Slide = observer((props: SlideProps) => {
  const {Icon, title, description, style, dataSet} = props;
  const isLg = useIsDimensions('lg');
  return (
    <ScrollView
      overScrollMode="never"
      style={[styles.root, isLg && styles.rootMd, style]}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.container}
      dataSet={dataSet}>
      <SlideIcon>
        <Icon />
      </SlideIcon>
      <Title>{title}</Title>
      <Description lineBreakMode="clip">{description}</Description>
    </ScrollView>
  );
});

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  root: {
    padding: 15,
  },
  rootMd: {
    padding: 0,
    paddingHorizontal: 50,
  },
});

const SlideIcon = variance(View)(() => ({
  root: {
    alignItems: 'center',
    marginBottom: 30,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    textAlign: 'center',
    fontSize: 26,
    lineHeight: 36,
    color: theme.palette.textMain,
    marginBottom: 15,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 36,
        lineHeight: 46,
        marginBottom: 10,
      },
    }),
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 26,
    color: theme.palette.textMain,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 18,
        lineHeight: 28,
      },
    }),
  },
}));
