import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import NotifyAboutSuccessfulExchangeContainer from '../../../../../CommonNavigationContainers/containers/NotifyAboutSuccessfulExchangeContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type NotifyAboutSuccessfulExchangeBindingProps =
  LargeSwitchBindingProps<'NotifyAboutSuccessfulExchange'>;

export default observer(function NotifyAboutSuccessfulExchangeBinding(
  props: NotifyAboutSuccessfulExchangeBindingProps,
) {
  const {navigation, route} = props;
  const navigateToRoot = useCallback(() => {
    navigation.reset({index: 0, routes: [{name: 'ListWallets'}]});
  }, [navigation]);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <NotifyAboutSuccessfulExchangeContainer
      params={route.params}
      onBack={goBack}
      onSubmit={navigateToRoot}
    />
  );
});
