/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {useStrings} from '@ncwallet-app/core';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import type {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import {LgProfileCardContainer} from '../../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import TwoFaCardSetCode from '../TwoFaCardSetCode';

export type IpSettingsOtpCardProps = {
  type: IpType;
  onSubmit: (code: string) => void;
  getError: () => string | undefined;
  onResetError: () => void;
  currentTwoFaProvider: TwoFaProviderKind | undefined;
  onTwoFaResendCode?: (withSecret?: boolean) => Promise<void>;
  resendCodeInterval?: number;
  resetInterval?: () => void;
  onBack: () => void;
};

export default observer(function IpSettingsOtpCard(
  props: IpSettingsOtpCardProps,
) {
  const {type, onSubmit, onBack, currentTwoFaProvider, onResetError} = props;
  const strings = useStrings();

  const headerText = useMemo(() => {
    return type === IpType.Blocked
      ? strings['lgSecureSettingsScreen.sessionsBlackList']
      : strings['lgSecureSettingsScreen.sessionsWhiteList'];
  }, [strings, type]);

  return (
    <LgProfileCardContainer>
      <TwoFaCardSetCode
        headerText={headerText}
        resetError={onResetError}
        titleText={strings['promptOtpToUpdateIpList.title']}
        onBack={onBack}
        getError={props.getError}
        currentTwoFaProvider={currentTwoFaProvider}
        submitText={strings['promptOtpToUpdateIpList.submit']}
        onTwoFaResendCode={() => props.onTwoFaResendCode!(false)}
        resetInterval={props.resetInterval}
        resendCodeInterval={props.resendCodeInterval}
        onSubmit={onSubmit}
      />
    </LgProfileCardContainer>
  );
});
