import {useStrings, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {SearchHeader} from '@ncwallet-app/ui';
import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import type {WalletItemData} from '../../shared/WalletItem';
import type {WalletLimitsItemData} from '../../shared/WalletLimitsItemData';
import LimitsSettingsEmptyList from './LimitsSettingsEmptyList';
import LimitsSettingsList from './LimitsSettingsList';

export type LimitsSettingsScreenProps = BaseSafeAreaProps & {
  getWallets: () => WalletItemData[] | undefined;
  getLimits: () => WalletLimitsItemData[] | undefined;
  getValue: () => string | undefined;
  onFilter: (value: string) => void;
  onLimitPress: (l: WalletLimitsItemData) => void;
  onWalletPress: (w: WalletItemData) => void;
};

export const LimitsSettingsScreen = observer(
  (props: LimitsSettingsScreenProps) => {
    const {
      onFilter,
      onLimitPress,
      onWalletPress,
      getWallets,
      getLimits,
      getValue,
      ...rest
    } = props;
    const strings = useStrings();
    const wallets = getWallets();
    const limits = getLimits();
    const searchValue = getValue();

    return (
      <Root>
        <LimitsSearchHeader
          getValue={getValue}
          onValueChange={onFilter}
          onSubmit={noop}
          placeholder={
            strings['profileSettingsScreen.searchCryptoCurrencyPlaceholder']
          }
        />
        {limits && wallets ? (
          <LimitsSettingsList
            searchValue={searchValue}
            limitsData={limits}
            walletsWithoutLimitsData={wallets}
            onLimitPress={onLimitPress}
            onWalletPress={onWalletPress}
            {...rest}
          />
        ) : (
          <LimitsSettingsEmptyList />
        )}
      </Root>
    );
  },
);

const Root = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const LimitsSearchHeader = variance(SearchHeader)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingBottom: 13,
    paddingTop: 0,
    backgroundColor: theme.palette.uiPrimary,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));
