import type {WalletId} from '@ncwallet-app/core';
import {CommonActions, StackActions} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptWalletToFilterHistoryContainer from '../../../CommonNavigationContainers/containers/PromptWalletToFilterHistoryContainer';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptWalletToFilterHistoryBindingProps =
  SmallHomeStackBindingProps<'PromptWalletToFilterHistory'>;

export default observer(function PromptWalletToFilterHistoryBinding(
  props: PromptWalletToFilterHistoryBindingProps,
) {
  const {navigation, route} = props;

  const onSearchChange = useCallback(
    (search: string) => {
      navigation.setParams({search});
    },
    [navigation],
  );

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.replace('ListHistoryFilters');
  }, [navigation]);

  const onSelect = useCallback(
    (walletId: WalletId | undefined) => {
      navigation.dispatch(navState => {
        const caller = navState.routes.find(
          _ => _.name === 'ListHistoryFilters',
        );
        const params = {walletId};
        return caller
          ? CommonActions.navigate({
              key: caller.key,
              params,
              merge: true,
            })
          : StackActions.replace('ListHistoryFilters', params);
      });
    },
    [navigation],
  );

  return (
    <PromptWalletToFilterHistoryContainer
      search={route.params?.search}
      onSearchChange={onSearchChange}
      onBack={onBack}
      onSelect={onSelect}
    />
  );
});
