import type {AccountAddressData} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {useCallback, useEffect, useState} from 'react';

import {AddressFormState} from './AddressFormState';

export type AddressFormStateProps = {
  value: AccountAddressData | undefined;
  country: string | undefined;
  onSubmit: (data: AccountAddressData) => void;
};

export const CITY_NAME_LENGTH = 100;
export const STREET_NAME_LENGTH = 200;
export const ADDRESS_INDEX_LENGTH = 30;
export const useAddressFormState = (props: AddressFormStateProps) => {
  const {value, onSubmit, country} = props;
  const [formState] = useState(() => new AddressFormState());

  useEffect(() => {
    formState.setInitialValue(value, country);
  }, [country, formState, value]);

  const handleSubmit = useCallback(() => {
    onSubmit(formState.getValue());
  }, [formState, onSubmit]);

  return {formState, handleSubmit};
};
