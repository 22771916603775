import {fromSession} from '@ncwallet-app/navigation/src/screens/ActiveSessionsScreen/SessionInfo';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import type {AccountIdStore} from '../../Auth';
import type {JsonRpcClient} from '../../JsonRpc';
import type {Log} from '../../Log';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../../NCWalletServer';
import type {Session, SessionId} from '../../NCWalletServer/sessions/Session';
import type {ActiveSessionStore} from './ActiveSessionStore';

export default class ActiveSessionStoreImpl implements ActiveSessionStore {
  @observable private sessions: Session[] | undefined;

  @computed get other() {
    return this.sessions
      ?.filter(s => !s.is_current_session)
      .map(fromSession)
      .sort((a, b) => (a.time > b.time ? -1 : a.time > b.time ? 1 : 0));
  }

  @computed get current() {
    const current = this.sessions?.find(s => s.is_current_session);
    return current && fromSession(current);
  }

  getCurrentSession() {
    return this.current;
  }

  getOtherSessions() {
    return this.other;
  }

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly accountIdStore: AccountIdStore;
      readonly log: Log;
    },
  ) {
    makeObservable(this);
  }

  @action.bound
  async refresh() {
    const sessionsRes = await this._root.ncWalletJsonRpcClient.call(
      'sessions.list',
      undefined,
    );
    if (!sessionsRes.success) {
      return;
    }

    runInAction(() => {
      this.sessions = sessionsRes.right;
    });
  }

  @action.bound
  delete(sessionId: SessionId) {
    this.sessions = this.sessions?.filter(s => s.id !== sessionId);
    this._root.log.write({
      body: 'Delete specific session with id: ' + sessionId,
    });
    void this._root.ncWalletJsonRpcClient.call('sessions.delete', {
      session_id: sessionId,
    });
  }

  @action.bound
  reset() {
    this.sessions = undefined;
  }
}
