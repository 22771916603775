import getWebOsKind from '@ncwallet-app/core/src/util/getWebOsKind';
import {OSKind} from '@ncwallet-app/core/src/util/OSKind';
import {Platform} from 'react-native';

import type {RateModalBindingState} from './RateModalBindingState';

export default function getStoresToDisplay(
  state: RateModalBindingState,
): Set<'AppStore' | 'GooglePlay'> | undefined {
  if ((Platform.OS === 'ios' || Platform.OS === 'macos') && state.isRatingSet) {
    return new Set(['AppStore']);
  }

  let webOsKind;
  if (
    (Platform.OS === 'android' ||
      (Platform.OS === 'web' &&
        (webOsKind = getWebOsKind()) === OSKind.Android)) &&
    state.isRatingGood
  ) {
    return new Set(['GooglePlay']);
  }

  if (
    Platform.OS === 'web' &&
    ((webOsKind ??= getWebOsKind()) === OSKind.IOs ||
      webOsKind === OSKind.MacOS)
  ) {
    return undefined;
  }

  if (state.isRatingGood) {
    return new Set(['AppStore', 'GooglePlay']);
  }

  return undefined;
}
