/* eslint-disable @typescript-eslint/no-deprecated */
import {useStyles, useTheme} from '@ncwallet-app/core/src';
import {autorun, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import type {ReactNode} from 'react';
import React, {useCallback, useLayoutEffect, useState} from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import type {SharedValue} from 'react-native-reanimated';
import Animated, {
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import {Pressable} from './Pressable';

export enum SwitchVariant {
  Primary = 'primary',
  Success = 'success',
  Info = 'info',
  Error = 'error',
}

export type SwitchProps = {
  /**
   * @deprecated
   */
  active?: boolean;
  /**
   * @deprecated
   */
  disabled?: boolean;
  onPress?: (value: boolean) => void;
  getIsActive?: () => boolean;
  onChange?: (active: boolean) => void;
  variant?: SwitchVariant;
  style?: StyleProp<ViewStyle>;
};

export default observer(function Switch(props: SwitchProps) {
  const {
    getIsActive: _getIsActive,
    onChange: _onChange,
    variant = SwitchVariant.Primary,
    active: _active,
    onPress: _onPress,
    style,
  } = props;
  const [activeBox] = useState(() =>
    observable.box<boolean | undefined>(_active),
  );
  useLayoutEffect(() => {
    runInAction(() => {
      activeBox.set(_active);
    });
  }, [_active, activeBox]);
  const getIsActive = useCallback(
    () => (_getIsActive ? _getIsActive() : activeBox.get()),
    [_getIsActive, activeBox],
  );
  const onChange = _onChange ?? _onPress;
  const onPress = useCallback(
    () => onChange?.(getIsActive() ?? true),
    [getIsActive, onChange],
  );
  const animatedValue = useSharedValue(getIsActive() ? 1 : 0);
  useLayoutEffect(
    () =>
      autorun(() => {
        animatedValue.value = withTiming(getIsActive() ? 1 : 0);
      }),
    [animatedValue, getIsActive],
  );
  return (
    <Pressable
      onPress={onPress}
      disabled={props.disabled}
      style={style}
      activeOpacity={0}
      rippleColor="transparent">
      <SwitchContainer
        active={animatedValue}
        variant={variant}
        disabled={props.disabled}>
        <SwitchHandle active={animatedValue} />
      </SwitchContainer>
    </Pressable>
  );
});

type SwitchContainerProps = {
  active: SharedValue<number>;
  children: ReactNode;
  variant: SwitchVariant;
  disabled?: boolean;
};

const SwitchContainer = observer((props: SwitchContainerProps) => {
  const {active, children, variant} = props;

  const {palette} = useTheme();
  const containerAnimatedStyles = useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor(
        active.value,
        [0, 1],
        [palette.uiSecondary, palette[variant]],
      ),
    };
  }, [active, palette, variant]);

  const styles = useStyles(() => ({
    root: {
      position: 'relative',
      width: 50,
      height: 30,
      borderRadius: 15,
    },
    disabled: {
      opacity: 0.5,
    },
  }));

  return (
    <Animated.View
      style={[
        styles.root,
        containerAnimatedStyles,
        props.disabled && styles.disabled,
      ]}>
      {children}
    </Animated.View>
  );
});

type SwitchHandleProps = {
  active: SharedValue<number>;
};

const SwitchHandle = observer((props: SwitchHandleProps) => {
  const {active} = props;

  const handleAnimatedStyles = useAnimatedStyle(() => {
    const position = interpolate(active.value, [0, 1], [0, 20]);
    return {
      transform: [{translateX: position}],
    };
  }, [active]);

  const styles = useStyles(theme => ({
    handle: {
      position: 'absolute',
      top: 3,
      left: 3,
      width: 24,
      height: 24,
      borderRadius: 24,
      backgroundColor: theme.palette.whitey,
    },
  }));

  return <Animated.View style={[styles.handle, handleAnimatedStyles]} />;
});
