import type {ShallowCommonState} from '@ncwallet-app/core/src/CommonNavigationScheme';
import commonToLarge from '@ncwallet-app/core/src/LargeNavigationScheme/commonToLarge';
import {CommonActions} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import RedirectToSendContainer from '../../../../../CommonNavigationContainers/containers/RedirectToSendContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type RedirectToSendBindingProps =
  LargeSwitchBindingProps<'RedirectToSend'>;

export default observer(function RedirectToSendBinding(
  props: RedirectToSendBindingProps,
) {
  const {navigation, route} = props;
  const listWallets = useCallback(() => {
    navigation.replace('Root');
  }, [navigation]);
  const resetRoot = useCallback(
    (state: ShallowCommonState) => {
      navigation
        .getParent()
        ?.dispatch(CommonActions.reset(commonToLarge(state)));
    },
    [navigation],
  );
  return (
    <RedirectToSendContainer
      params={route.params}
      resetRoot={resetRoot}
      listWallets={listWallets}
    />
  );
});
