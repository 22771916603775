import {useRoot, useTheme, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import type {ReactNode} from 'react';
import React, {useMemo} from 'react';
import {Platform, View} from 'react-native';

import {useIsDimensions} from '../../../util';
import {LG_BREAKPOINT} from '../../grid';
import {TooltipVariant} from './TooltipVariant';

export type TooltipModalProps = {
  variant?: TooltipVariant;
  children: ReactNode;
  visible: boolean;
  position: {x: number; y: number};
  onClose: () => void;
};

export const TooltipModal = observer(function TooltipModal(
  props: TooltipModalProps,
) {
  const {variant = TooltipVariant.Primary} = props;
  const {windowDimensionsState} = useRoot();
  const {width} = windowDimensionsState.window;
  const theme = useTheme();
  const tooltipWidth = width * 0.9;
  const isLg = useIsDimensions('lg');
  const isStylesForWebOrLg = useMemo(
    () => Platform.OS === 'web' || isLg,
    [isLg],
  );
  const styles = useMemo(() => {
    return {
      root: {
        maxWidth: 370,
        width: tooltipWidth,
        top: isStylesForWebOrLg ? props.position.y + 30 : props.position.y + 15,
        left: isStylesForWebOrLg ? 20 : (width - tooltipWidth) / 2,
        backgroundColor: theme.palette[variant],
        ...theme.mediaQuery({
          [LG_BREAKPOINT]: {
            left: isStylesForWebOrLg
              ? props.position.x - 20
              : (width - tooltipWidth) / 2,
          },
        }),
      },
      arrow: {
        left: props.position.x - (width - tooltipWidth) / 2,
        backgroundColor: theme.palette[variant],
      },
      arrowWeb: {
        top: -8,
        left: props.position.x - 20,
        ...theme.mediaQuery({
          [LG_BREAKPOINT]: {
            top: -8,
            left: 20,
          },
        }),
      },
    };
  }, [
    tooltipWidth,
    isStylesForWebOrLg,
    props.position.y,
    props.position.x,
    width,
    theme,
    variant,
  ]);

  return props.visible ? (
    <ModalBody>
      <ModalOverlay />
      <TooltipContainer style={styles.root}>
        {props.children}
        <TooltipArrow
          style={[styles.arrow, isStylesForWebOrLg && styles.arrowWeb]}
        />
      </TooltipContainer>
    </ModalBody>
  ) : null;
});

const ModalBody = variance(View)(() => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

const TooltipContainer = variance(View)(theme => ({
  root: {
    position: 'absolute',
    width: 345,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 2,
    borderRadius: 6,
    backgroundColor: theme.palette.primary,
  },
}));

const TooltipArrow = variance(View)(theme => ({
  root: {
    position: 'absolute',
    top: -8,
    left: 5,
    width: 20,
    height: 20,
    borderRadius: 4,
    transform: [{rotateZ: '45deg'}],
    backgroundColor: theme.palette.primary,
    zIndex: -1,
  },
}));

const ModalOverlay = variance(View)(theme => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.chroma('#000000').alpha(0.5).hex(),
  },
}));
