import {FULFILLED, useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {expr} from '@ncwallet-app/core/src/mobx-toolbox';
import type {
  GroupId,
  GroupMessageDescription,
  MessageId,
} from '@ncwallet-app/core/src/NCWalletServer/Logs';
import {LogLevel} from '@ncwallet-app/core/src/NCWalletServer/Logs';
import type {
  ExcludeGroup,
  IncludeMessages,
  LogSettings,
} from '@ncwallet-app/core/src/NCWalletServer/LogSettings';
import type {ObservableMap, ObservableSet} from 'mobx';
import {
  action,
  autorun,
  comparer,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useNavigationGetIsFocused} from '../../Navigation/hooks';
import {SwitchHierarchyScreen} from '../../screens/SwitchHierarchyScreen';
import type {HierarchySection} from '../../screens/SwitchHierarchyScreen/SwitchHierarchyScreen';

export type ListNotificationSettingsContainerProps = {
  getIsTransitioning: () => boolean;
  goToLinkTelegram: () => void;
};

export default observer(function ListNotificationSettingsContainer(
  props: ListNotificationSettingsContainerProps,
) {
  const {getIsTransitioning, goToLinkTelegram} = props;
  const {getAreSettingsPending, getSettings, refreshSettings} = useSettings();
  const [hierarchyBox] = useState(() =>
    observable.box<HierarchyState | undefined>(),
  );
  const [telegramBox] = useState(() => observable.box(false));
  const root = useRoot();

  const sectionTitles = useGetSectionTexts();
  useEffect(
    () =>
      autorun(() => {
        if (getIsTransitioning()) {
          return;
        }
        const settings = getSettings();
        if (settings) {
          const {state, telegram} = settingsToState(settings, sectionTitles);
          runInAction(() => {
            hierarchyBox.set(state);
            telegramBox.set(telegram);
          });
        }
      }),
    [getIsTransitioning, getSettings, hierarchyBox, sectionTitles, telegramBox],
  );
  const getIsActive = useCallback(
    () =>
      expr(() => {
        const hierarchy = hierarchyBox.get();
        return (
          hierarchy &&
          hierarchy.size > 0 &&
          [...hierarchy].some(([, messages]) => messages.size > 0)
        );
      }),
    [hierarchyBox],
  );

  const getIsTelegramActive = useCallback(
    () => telegramBox.get(),
    [telegramBox],
  );

  const onTelegramToggle = useCallback(() => {
    const telegram = telegramBox.get();
    const account =
      root.accountStore.state?.status === FULFILLED &&
      root.accountStore.state.result;

    if (!account) {
      return;
    }

    if (!account.telegram_username && !telegramBox.get()) {
      goToLinkTelegram();
      return;
    }

    telegramBox.set(!telegram);
  }, [goToLinkTelegram, telegramBox, root]);

  const onToggle = useMemo(
    () =>
      action(() => {
        const hierarchy = hierarchyBox.get();
        if (!hierarchy) {
          return;
        }
        if (hierarchy.size === 0) {
          for (const entry of Object.entries(sectionTitles)) {
            const key = entry[0] as GroupId;
            const messages = Object.keys(entry[1].messages) as MessageId[];
            const _ = hierarchy.get(key);
            if (_ === undefined) {
              hierarchy.set(key, observable.set(messages));
            } else {
              _.replace(messages);
            }
          }
        } else {
          hierarchy.clear();
          telegramBox.set(false);
        }
      }),
    [hierarchyBox, sectionTitles, telegramBox],
  );

  const getSections = useCallback(
    (): HierarchySection[] | undefined =>
      expr(() => {
        const hierarchy = hierarchyBox.get();
        if (!hierarchy) {
          return undefined;
        }
        return Object.entries(sectionTitles).map(entry => {
          const groupId = entry[0] as GroupId;
          const titleByMessageId = entry[1];
          const messages = Object.keys(
            titleByMessageId.messages,
          ) as MessageId[];
          const group = hierarchy.get(groupId);
          return {
            id: groupId,
            title: titleByMessageId.title,
            isDisabled: false,
            getIsActive: () =>
              expr(() => group !== undefined && group.size > 0),
            items: [...messages].map(messageId => {
              const m =
                messageId as keyof (typeof titleByMessageId)['messages'];

              return {
                id: messageId,
                isDisabled: m === 'withdrawal_limit' || m === 'exchange_limit',
                title: titleByMessageId.messages[m],
                getIsActive: () =>
                  group !== undefined &&
                  group.has(messageId) &&
                  m !== 'withdrawal_limit' &&
                  m !== 'exchange_limit',
              };
            }),
          };
        });
      }),
    [hierarchyBox, sectionTitles],
  );

  const onSectionToggle = useMemo(
    () =>
      action((sectionId: string) => {
        const groupId = sectionId as GroupId;
        const hierarchy = hierarchyBox.get();
        if (!hierarchy) {
          return;
        }
        const _ = hierarchy.get(groupId);
        if (_ === undefined) {
          const messages = Object.keys(
            sectionTitles[groupId].messages,
          ) as MessageId[];
          hierarchy.set(groupId, observable.set(messages));
        } else {
          if (_.size > 0) {
            hierarchy.delete(groupId);
          } else {
            const messages = Object.keys(
              sectionTitles[groupId].messages,
            ) as MessageId[];
            _.replace(messages);
          }
        }
      }),
    [hierarchyBox, sectionTitles],
  );

  const onItemToggle = useMemo(
    () =>
      action((sectionId: string, itemId: string) => {
        const groupId = sectionId as GroupId;
        const messageId = itemId as MessageId;
        const hierarchy = hierarchyBox.get();

        if (!hierarchy) {
          return;
        }

        const _ = hierarchy.get(groupId);
        if (_ === undefined) {
          const messages = Object.keys(sectionTitles[groupId].messages).filter(
            m => m === itemId,
          ) as MessageId[];
          hierarchy.set(groupId, observable.set(messages));
        } else {
          if (_.has(messageId)) {
            if (_.size === 1) {
              hierarchy.delete(groupId);
            } else {
              _.delete(messageId);
            }
          } else {
            _.add(messageId);
          }
        }
      }),
    [hierarchyBox, sectionTitles],
  );

  useEffect(
    () =>
      reaction(
        () => {
          const hierarchy = hierarchyBox.get();
          const telegram = telegramBox.get();
          return (
            hierarchy && stateToSettings(telegram, hierarchy, sectionTitles)
          );
        },
        async settings => {
          if (settings) {
            const update_ = await root.ncWalletJsonRpcClient.call(
              'logs.update_settings',
              {settings},
            );
            if (!update_.success) {
              const previous = getSettings();
              if (previous) {
                const {state, telegram} = settingsToState(
                  previous,
                  sectionTitles,
                );
                runInAction(() => {
                  hierarchyBox.set(state);
                  telegramBox.set(telegram);
                });
              }
            }
          }
        },
        {
          equals: comparer.identity,
          delay: 1000,
        },
      ),
    [getSettings, hierarchyBox, root, sectionTitles, telegramBox],
  );
  useEffect(
    () => () => {
      void (async () => {
        const hierarchy = hierarchyBox.get();
        const telegram = telegramBox.get();
        if (!hierarchy) {
          return;
        }
        const previous = getSettings();
        const current = stateToSettings(telegram, hierarchy, sectionTitles);
        if (!comparer.structural(current, previous)) {
          await root.ncWalletJsonRpcClient.call('logs.update_settings', {
            settings: current,
          });
        }
      })();
    },
    [getSettings, hierarchyBox, root, sectionTitles, telegramBox],
  );

  return (
    <SwitchHierarchyScreen
      title={'userSettings.notifications.showNotification'}
      getIsActive={getIsActive}
      onToggle={onToggle}
      getIsTelegramActive={getIsTelegramActive}
      onTelegramToggle={onTelegramToggle}
      getSections={getSections}
      onSectionToggle={onSectionToggle}
      onItemToggle={onItemToggle}
      getIsRefreshing={getAreSettingsPending}
      onRefresh={refreshSettings}
    />
  );
});

const useSettings = () => {
  const [pendingBox] = useState(() => observable.box(false));
  const [settingsBox] = useState(() =>
    observable.box<LogSettings | undefined>(),
  );
  const root = useRoot();
  const refresh = useCallback(async () => {
    runInAction(() => {
      pendingBox.set(true);
    });
    try {
      const settings_ = await root.ncWalletJsonRpcClient.call(
        'logs.get_settings',
        undefined,
      );
      if (!settings_.success) {
        return;
      }
      runInAction(() => {
        settingsBox.set(settings_.right.settings);
      });
    } finally {
      runInAction(() => {
        pendingBox.set(false);
      });
    }
  }, [pendingBox, root, settingsBox]);
  const getIsPending = useCallback(() => pendingBox.get(), [pendingBox]);
  const getSettings = useCallback(() => settingsBox.get(), [settingsBox]);
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, refresh],
  );
  return useMemo(
    () => ({
      getAreSettingsPending: getIsPending,
      getSettings,
      refreshSettings: refresh,
    }),
    [getIsPending, getSettings, refresh],
  );
};

const useGetSectionTexts = (): GroupMessageDescription => {
  return useMemo(() => {
    return {
      auth: {
        title: 'userSettings.notifications.2fa.title',
        messages: {
          login_success: 'userSettings.notifications.settings.loginSuccess',
          login_new_ip: 'userSettings.notifications.settings.newIpLogin',
          '2fa_enabled': 'userSettings.notifications.2fa.enabled',
          '2fa_disabled': 'userSettings.notifications.2fa.disabled',
          '2fa_settings_updated': 'userSettings.notifications.2fa.updated',
        },
      },
      transactions: {
        title: 'userSettings.notifications.transactions.title',
        messages: {
          wallet_deposit: 'userSettings.notifications.wallet.deposit',
          withdrawal_request:
            'userSettings.notifications.exchange.withdrawalRequest',
          withdrawal_completed:
            'userSettings.notifications.exchange.withdrawalCompleted',
          exchange_completed:
            'userSettings.notifications.exchange.exchangeCompleted',
        },
      },
      settings: {
        title: 'userSettings.notifications.settings.title',
        messages: {
          withdrawal_limit:
            'userSettings.notifications.exchange.withdrawalLimit',
          exchange_limit: 'userSettings.notifications.settings.exchangeLimit',
          allowed_ip_manage:
            'userSettings.notifications.settings.allowedListUpdated',
          blocked_ip_manage:
            'userSettings.notifications.settings.blockedListUpdated',
        },
      },
    };
  }, []);
};

type HierarchyState = ObservableMap<GroupId, ObservableSet<MessageId>>;

const stateToSettings = (
  telegram: boolean,
  state: HierarchyState,
  sectionDescriptions: GroupMessageDescription,
): LogSettings => {
  if (state.size === 0 || [...state.values()].every(_ => _.size === 0)) {
    return {exclude: true, groups: {}, use_telegram: false};
  }

  const selected = [...state.values()].map(s => [...s.values()]).flat();

  let groups = {};

  Object.keys(sectionDescriptions).forEach(topic => {
    const messages = Object.keys(
      sectionDescriptions[topic as GroupId].messages,
    );
    const filteredMessages = messages.filter(
      message => !selected.includes(message as MessageId),
    );

    groups = {
      ...groups,
      [topic]: {
        exclude: false,
        exclude_messages: filteredMessages,
        log_level: LogLevel.Debug,
      },
    };
  });

  return {
    exclude: false,
    groups: groups,
    use_telegram: telegram,
  };
};

const settingsToState = (
  settings: LogSettings,
  sectionDescriptions: GroupMessageDescription,
): {state: HierarchyState; telegram: boolean} => {
  const state = new Map<GroupId, ObservableSet<MessageId>>();
  if (settings.exclude) {
    return {state: observable.map(state), telegram: false};
  }
  for (const entry of Object.entries(settings.groups)) {
    const groupId = entry[0] as GroupId;
    const group = entry[1] as ExcludeGroup | IncludeMessages;
    if (!(groupId in sectionDescriptions)) {
      continue;
    }
    if (group.exclude) {
      continue;
    }
    const excluded = new Set(group.exclude_messages);
    const universe = Object.keys(
      sectionDescriptions[groupId].messages,
    ) as MessageId[];
    const messages = observable.set(universe.filter(_ => !excluded.has(_)));
    state.set(groupId, messages);
  }

  return {state: observable.map(state), telegram: settings.use_telegram};
};
