import type {Disposer, Service} from '@ncwallet-app/core';
import type {NotificationPermissions} from '@ncwallet-app/core/src/NotificationPermissions';

export default class WebPrepareNotificationsService implements Service {
  constructor(
    private readonly _root: {
      readonly notificationPermissions: NotificationPermissions;
    },
  ) {}

  subscribe() {
    const {notificationPermissions} = this._root;
    if (notificationPermissions.granted) {
      return;
    }
    void notificationPermissions.ask();
    const listener = () => {
      void notificationPermissions.ask();
      if (notificationPermissions.granted) {
        document.removeEventListener('click', listener);
      }
    };
    document.addEventListener('click', listener);
    return (() => {
      document.removeEventListener('click', listener);
    }) as Disposer;
  }
}
