import {ReadyState} from '../Connection';
import type {Http, NewWebSocketParams} from '../Http';
import {NEW_WEB_SOCKET} from '../Http';
import type {Service} from '../structure';
import type {Log} from './Log';
import WebSocketErrorLogContent from './WebSocketErrorLogContent';
import WebSocketReadyStateLogContent from './WebSocketReadyStateLogContent';

export default class WebSocketLogBridgeService implements Service {
  constructor(
    private readonly _root: {readonly log: Log; readonly http: Http},
  ) {}

  private _listenOpen(url: string, ws: WebSocket, connectionId: number) {
    const listener = () => {
      this._root.log.write(
        new WebSocketReadyStateLogContent(url, connectionId, ReadyState.Open),
      );
      ws.removeEventListener('open', listener);
    };
    ws.addEventListener('open', listener);
  }

  private _listenClosed(url: string, ws: WebSocket, connectionId: number) {
    const listener = () => {
      this._root.log.write(
        new WebSocketReadyStateLogContent(url, connectionId, ReadyState.Closed),
      );
      ws.removeEventListener('close', listener);
    };
    ws.addEventListener('close', listener);
  }

  private _listenError(ws: WebSocket, connectionId: number) {
    const listener = (event: unknown) => {
      this._root.log.write(new WebSocketErrorLogContent(connectionId, event));
      ws.removeEventListener('error', listener);
    };
    ws.addEventListener('error', listener);
  }

  private readonly _onNewWebSocket = (params: NewWebSocketParams) => {
    const {correlationId, socket, uri} = params;
    this._root.log.write(
      new WebSocketReadyStateLogContent(
        uri,
        correlationId,
        ReadyState.Connecting,
      ),
    );
    this._listenOpen(uri, socket, correlationId);
    this._listenClosed(uri, socket, correlationId);
    this._listenError(socket, correlationId);
  };

  subscribe() {
    return this._root.http.io.listen(NEW_WEB_SOCKET, this._onNewWebSocket);
  }
}
