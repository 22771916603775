import {detect} from 'detect-browser';

export default function isWebIOS(forceMacOs = true) {
  const d = detect();
  return (
    d &&
    d.type === 'browser' &&
    (d.os === 'iOS' || (forceMacOs && d.os === 'Mac OS'))
  );
}
