import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptSignOutScreen from '../../../screens/PromptSignOutScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptSignOutBindingProps =
  SmallHomeStackBindingProps<'PromptSignOut'>;

export default observer(function PromptSignOutBinding(
  props: PromptSignOutBindingProps,
) {
  const {navigation} = props;

  const {authHelper} = useRoot();

  const signOut = useCallback(() => {
    void authHelper.signOut({reason: 'PromptSignOutBinding'});
    navigation.reset({index: 0, routes: [{name: 'Root'}]});
  }, [authHelper, navigation]);

  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.reset({index: 0, routes: [{name: 'Root'}]});
    }
  }, [navigation]);

  return <PromptSignOutScreen onConfirm={signOut} onCancel={goBack} />;
});
