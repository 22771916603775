import {useRoot, useTheme, variance} from '@ncwallet-app/core';
import sized from '@ncwallet-app/core/src/Svg/sized';
import {LG_BREAKPOINT} from '@ncwallet-app/ui';
import {
  BackspaceSymbolSvg,
  FingerprintSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {clamp, round} from 'lodash';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useMemo} from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {Platform, Text, View} from 'react-native';

import {PinKeyboardButton} from './PinKeyboardButton';
import PinKeyboardButtonWeb from './PinKeyboardButtonWeb';
import PinKeyboardNumberRow from './PinKeyboardNumberRow';

export type PinKeyboardProps = {
  currentKey: {
    value: number | undefined;
  };
  onNumberPress: (n: number) => void;
  onBackspacePress: () => void;
  onBiometryPress?: () => void;
  loginOptionsShown?: boolean;
  onLoginOptionsPress?: () => void;
};

const BUTTON_INITIAL_SIZE = 27;

export default observer(function PinKeyboard(props: PinKeyboardProps) {
  const theme = useTheme();
  const {windowDimensionsState} = useRoot();
  const size = expr(() => {
    const {width, height} = windowDimensionsState.window;
    const aspectRatio = round(height / width, 2);
    return clamp(BUTTON_INITIAL_SIZE * aspectRatio, 65, 65);
  });

  const paddingBottom = expr(() => {
    const {width} = windowDimensionsState.window;
    if (width >= LG_BREAKPOINT) {
      return 0;
    }
    const {height} = windowDimensionsState.window;
    const aspectRatio = round(height / width, 2);
    return aspectRatio < 2 ? 20 : 60;
  });

  const isWeb = Platform.OS === 'web';

  const keyStyle = useMemo(() => ({width: size, height: size}), [size]);

  const keyboardStyle = useMemo(() => ({paddingBottom}), [paddingBottom]);

  return (
    <KeyBoardContainer style={keyboardStyle}>
      <PinKeyboardNumberRow
        keys={[1, 2, 3]}
        onNumberPress={props.onNumberPress}
        currentSelectedKey={props.currentKey}
      />
      <PinKeyboardNumberRow
        keys={[4, 5, 6]}
        onNumberPress={props.onNumberPress}
        currentSelectedKey={props.currentKey}
      />
      <PinKeyboardNumberRow
        keys={[7, 8, 9]}
        onNumberPress={props.onNumberPress}
        currentSelectedKey={props.currentKey}
      />

      <Row>
        {props.onBiometryPress ? (
          <KeyPressable onPress={props.onBiometryPress} style={keyStyle}>
            <FingerprintIcon color={theme.palette.textMain} />
          </KeyPressable>
        ) : (
          <KeyPressable disabled style={keyStyle} />
        )}
        <NumberKey
          number={0}
          currentKey={props.currentKey.value}
          style={keyStyle}
          onPress={props.onNumberPress}
        />
        {!isWeb ? (
          <KeyPressable
            onPress={props.onBackspacePress}
            style={keyStyle}
            testID="pin-key-backspace">
            <BackspaceIcon color={theme.palette.textMain} />
          </KeyPressable>
        ) : (
          <KeyPressableWeb
            isActive={props.currentKey.value === -1}
            onPress={props.onBackspacePress}
            style={keyStyle}>
            <BackspaceIcon color={theme.palette.textMain} />
          </KeyPressableWeb>
        )}
      </Row>
    </KeyBoardContainer>
  );
});

type RenderNumberKeyProps = {
  number: number;
  currentKey: number | undefined;
  style: StyleProp<ViewStyle>;
  onPress: (n: number) => void;
};

const NumberKey = ({
  number,
  currentKey,
  style,
  onPress,
}: RenderNumberKeyProps) => {
  const isWeb = Platform.OS === 'web';
  return (
    <KeyboardWrapper key={number}>
      {!isWeb ? (
        <KeyPressable
          key={number}
          onPress={() => {
            onPress(number);
          }}
          style={style}>
          <KeyButtonText selectable={false}>{number}</KeyButtonText>
        </KeyPressable>
      ) : (
        <KeyPressableWeb
          key={number}
          isActive={number === currentKey}
          onPress={() => {
            onPress(number);
          }}
          style={style}>
          <KeyButtonText selectable={false}>{number}</KeyButtonText>
        </KeyPressableWeb>
      )}
    </KeyboardWrapper>
  );
};

const KeyBoardContainer = variance(View)(theme => ({
  root: {
    maxWidth: 500,
    width: '100%',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 285,
        marginTop: 'auto',
      },
    }),
  },
}));

const KeyboardWrapper = variance(View)(() => ({
  root: {
    width: 'auto',
  },
}));

const Row = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingVertical: 5,
    width: '100%',

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        justifyContent: 'space-between',
        paddingVertical: 15,
      },
    }),
  },
}));

const KeyPressableWeb = variance(PinKeyboardButtonWeb)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 5,
    cursor: 'pointer',
  },
}));

const KeyPressable = variance(PinKeyboardButton)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 55,
    height: 55,
  },
}));

const KeyButtonText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textMain,
    fontSize: 36,
    lineHeight: 42,
  },
}));

const BackspaceIcon = sized(BackspaceSymbolSvg, 25, 18);
const FingerprintIcon = sized(FingerprintSvg, 26);
