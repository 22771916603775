import type {
  CryptoCurrencyCode,
  InOutCurrency,
  WalletId,
} from '@ncwallet-app/core';
import {useStrings, useTheme} from '@ncwallet-app/core';
import type {ShowQrToReceiveCryptoRoute} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransition} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransition';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {
  BlockchainSvg,
  LinkSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ListInputAddressesContainer from '../../../CommonNavigationContainers/containers/ListInputAddressesContainer';
import {BackButton} from '../../../components';
import NavigationBar from '../../../components/NavigationBar';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ListInputAddressesBindingProps =
  SmallHomeStackBindingProps<'ListInputAddresses'>;

type PageParams = {
  walletId: WalletId;
  addressNetwork: AddressNetwork;
  addressCurrency: CryptoCurrencyCode;
};

export default observer(function ListInputAddressesBinding(
  props: ListInputAddressesBindingProps,
) {
  const {navigation, route} = props;
  const strings = useStrings();
  const theme = useTheme();
  const showQrToReceiveCrypto = useCallback<
    RouteTransition<ShowQrToReceiveCryptoRoute>
  >(
    params => {
      navigation.navigate('ShowQrToReceiveCrypto', params);
    },
    [navigation],
  );
  const promptInputNetwork = useCallback(
    (params: PageParams) => {
      navigation.navigate('PromptInputNetwork', params);
    },
    [navigation],
  );

  const promptAddressFormat = useCallback(
    (params: PageParams) => {
      navigation.navigate('PromptAddressFormat', params);
    },
    [navigation],
  );
  const goToChangeNetwork = useCallback(() => {
    navigation.navigate('PromptReceiveNetwork', route.params);
  }, [navigation, route.params]);

  const onBackPress = useCallback(() => {
    const {walletId, address, addressNetwork, addressCurrency} = route.params;
    navigation.navigate('ShowQrToReceiveCrypto', {
      walletId,
      address: address,
      addressNetwork: addressNetwork,
      addressCurrency: addressCurrency,
    });
  }, [navigation, route.params]);

  const updateNavigationHeader = useCallback(
    (availableNetworks: InOutCurrency[] | undefined) => {
      const isAvailableNetworks =
        availableNetworks && availableNetworks.length > 1;

      navigation.setOptions({
        header: () => (
          <NavigationBar
            title={strings['screenTitle.receive']}
            titleIcon={<LinkSvg color={theme.palette.info} />}
            leftButton={<BackButton onPress={onBackPress} />}
            rightButton={
              isAvailableNetworks ? (
                <BackButton
                  Icon={BlockchainSvg}
                  onPress={() => {
                    navigation.navigate('PromptReceiveNetwork', route.params);
                  }}
                />
              ) : null
            }
          />
        ),
      });
    },
    [navigation, onBackPress, route.params, strings, theme.palette.info],
  );

  return (
    <ListInputAddressesContainer
      goToChangeNetwork={goToChangeNetwork}
      promptInputNetwork={promptInputNetwork}
      promptAddressFormat={promptAddressFormat}
      updateHeaderAction={updateNavigationHeader}
      params={route.params}
      onBackPress={onBackPress}
      showQrToReceiveCrypto={showQrToReceiveCrypto}
    />
  );
});
