import type {SmallBottomTabParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallBottomTab/SmallBottomTabParamList';
import type {SmallHomeStackParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallHomeStackParamList';
import type {PartialState} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ShowTransactionContainer from '../../../CommonNavigationContainers/containers/ShowTransactionContainer';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ShowTransactionBindingProps =
  SmallHomeStackBindingProps<'ShowTransaction'>;

export default observer(function ShowTransactionBinding(
  props: ShowTransactionBindingProps,
) {
  const {route, navigation} = props;

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    const bottomTabState: PartialState<
      NavigationState<SmallBottomTabParamList>
    > = {index: 0, routes: [{name: 'ListHistory'}]};
    const homeStackState: PartialState<
      NavigationState<SmallHomeStackParamList>
    > = {index: 0, routes: [{name: 'Root', state: bottomTabState}]};
    navigation.dispatch(CommonActions.reset(homeStackState));
  }, [navigation]);

  const toNotFound = useCallback(() => {
    navigation.navigate('FallBack');
  }, [navigation]);

  const promptTransactionReport = useCallback(
    (id: string) => {
      navigation.navigate('ShowTransactionReport', {id});
    },
    [navigation],
  );

  return (
    <ShowTransactionContainer
      toNotFound={toNotFound}
      promptTransactionReport={promptTransactionReport}
      historyId={route.params.id}
      onBack={onBack}
    />
  );
});
