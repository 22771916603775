import {PromiseQueueImpl} from '../PromiseQueue';
import type {PermissionQueueProtector} from './PermissionQueueProtector';

/**
 * Apple won't let you request multiple permissions in a row, so you need to set a delay of 1500ms
 */
export default class PermissionQueueProtectorImpl
  implements PermissionQueueProtector
{
  private readonly _promiseQueue = new PromiseQueueImpl();

  async requestPermission<T>(permission: () => Promise<T>): Promise<T> {
    return this._promiseQueue.enqueue(permission, {
      delayBetween: 1500,
    });
  }
}
