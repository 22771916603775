import {action, comparer, computed, makeObservable, observable} from 'mobx';

import type {AppStateHelper} from '../AppStateHelper';
import type {LayoutHelperState} from '../LayoutHelperState';
import {Op} from '../Math';
import {expr} from '../mobx-toolbox';
import type {ReadonlyNavigationContainerState} from '../NavigationContainer';
import type {Advert, AdvertId, AdvertSpot} from '../NCWalletServer';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import type {Millisecond} from '../Time';
import type {TimeState} from '../TimeState';
import type {AdRepositoryState, ByAdvertSpot} from './AdRepositoryState';
import type {AdSuspensionQuery} from './AdSuspensionRepository';
import type {AdSuspensionState} from './AdSuspensionState';
import type {AdVisibilityState} from './AdVisibilityState';
import type {CommonAdvertSpotState} from './CommonAdvertSpotState';
import InstallNativeSpotStateService from './InstallNativeSpotStateService';
import InstallTelegramSpotStateService from './InstallTelegramSpotStateService';
import NewModalAdvertSpotStateService from './NewModalAdvertSpotStateService';
import SplashAdvertSpotStateService from './SplashAdvertSpotStateService';

export default class AdVisibilityStateService
  implements AdVisibilityState, CommonAdvertSpotState, Service
{
  @observable.ref private _mobileModal?: Advert;
  @observable.ref private _desktopModal?: Advert;

  readonly splashMobile;
  readonly splashDesktop;
  readonly newModalMobile;
  readonly newModalDesktop;
  readonly installNativeMobile;
  readonly installNativeDesktop;
  readonly installTelegramMobile;
  readonly installTelegramDesktop;

  constructor(
    private readonly _root: {
      readonly adRepositoryState: AdRepositoryState;
      readonly adSuspensionState: AdSuspensionState;
      readonly timeState: TimeState;
      readonly navigationContainerState: ReadonlyNavigationContainerState;
      readonly appStateHelper: AppStateHelper;
      readonly layoutHelperState: LayoutHelperState;
    },
  ) {
    makeObservable(this);
    this.splashMobile = new SplashAdvertSpotStateService(false, this, _root);
    this.splashDesktop = new SplashAdvertSpotStateService(true, this, _root);

    this.newModalMobile = new NewModalAdvertSpotStateService(
      false,
      this,
      _root,
    );
    this.newModalDesktop = new NewModalAdvertSpotStateService(
      true,
      this,
      _root,
    );

    this.installNativeMobile = new InstallNativeSpotStateService(
      false,
      this,
      _root,
    );

    this.installNativeDesktop = new InstallNativeSpotStateService(
      true,
      this,
      _root,
    );
    this.installTelegramMobile = new InstallTelegramSpotStateService(
      false,
      this,
      _root,
    );

    this.installTelegramDesktop = new InstallTelegramSpotStateService(
      true,
      this,
      _root,
    );
  }

  getAdToDisplay(spot: AdvertSpot): Advert | undefined {
    if (this._desktopModal?.spot === spot) {
      return this._desktopModal;
    }
    if (this._mobileModal?.spot === spot) {
      return this._mobileModal;
    }
    return undefined;
  }

  getPendingAd(spot: AdvertSpot): Advert | undefined {
    return this._pendingAds?.get(spot);
  }

  @action async displayAd(ad: Advert): Promise<void> {
    switch (ad.spot) {
      case 'splash_desktop':
      case 'new_modal_desktop':
      case 'install_native_desktop':
      case 'install_telegram_desktop':
        if (this._desktopModal === undefined) {
          this._desktopModal = ad;
        }
        break;
      case 'splash_mobile':
      case 'new_modal_mobile':
      case 'install_telegram_mobile':
      case 'install_native_mobile':
        if (this._mobileModal === undefined) {
          this._mobileModal = ad;
        }
    }
  }
  async hideAd(spot: AdvertSpot, now: Millisecond) {
    const ad = this.getAdToDisplay(spot);
    if (!ad) {
      return;
    }
    await this._root.adSuspensionState.suspendAd(ad.id, spot, now);
    this._hideAd(ad.id);
  }

  subscribe() {
    return batchDisposers(
      this.splashMobile.subscribe(),
      this.splashDesktop.subscribe(),
      this.newModalMobile.subscribe(),
      this.newModalDesktop.subscribe(),
      this.installNativeMobile.subscribe(),
      this.installNativeDesktop.subscribe(),
      this.installTelegramMobile.subscribe(),
      this.installTelegramDesktop.subscribe(),
    );
  }

  @action private _hideAd(id: AdvertId) {
    if (this._desktopModal?.id === id) {
      this._desktopModal = undefined;
    }
    if (this._mobileModal?.id === id) {
      this._mobileModal = undefined;
    }
  }

  @computed({equals: comparer.shallow}) private get _pendingAds():
    | ByAdvertSpot<Advert>
    | undefined {
    const {adRepositoryState, adSuspensionState} = this._root;

    const {globalTimeouts} = adRepositoryState;
    if (!globalTimeouts) {
      return undefined;
    }

    const {adSuspensionQuery} = adSuspensionState;
    if (!adSuspensionQuery) {
      return undefined;
    }

    const $now = () => Op.add(this._root.timeState.getNow(INTERVAL), INTERVAL);

    const areSuspendedGlobally = expr(() =>
      adSuspensionQuery.areSuspendedGlobally(globalTimeouts, $now),
    );

    const forcedIdsBySpot = this._getForcedIdsBySpot();

    if (areSuspendedGlobally) {
      return this._selectPendingAdBySpot(
        undefined,
        forcedIdsBySpot,
        adSuspensionQuery,
      );
    }

    const pendingIdsBySpot = expr(() =>
      this._getPendingIdsBySpot(adSuspensionQuery, $now),
    );

    if (!pendingIdsBySpot && !forcedIdsBySpot) {
      return undefined;
    }

    return this._selectPendingAdBySpot(
      pendingIdsBySpot,
      forcedIdsBySpot,
      adSuspensionQuery,
    );
  }

  private _getPendingIdsBySpot(
    adSuspensionQuery: AdSuspensionQuery,
    $now: () => Millisecond,
  ): ByAdvertSpot<Set<AdvertId>, true> | undefined {
    const {localTimeouts} = this._root.adRepositoryState;
    if (!localTimeouts) {
      return undefined;
    }

    const pendingIdsWithForced = adSuspensionQuery.selectPending(
      localTimeouts,
      $now,
    );

    const pendingIdsBySpot: ByAdvertSpot<Set<AdvertId>, true> = new Map();
    for (const id of pendingIdsWithForced) {
      const adsBySpot = this._root.adRepositoryState.adBySpotById?.get(id);
      if (!adsBySpot) {
        continue;
      }
      for (const ad of adsBySpot.values()) {
        if (!ad.options?.force) {
          const idsOfTheSpot = pendingIdsBySpot.get(ad.spot);
          if (idsOfTheSpot) {
            idsOfTheSpot.add(id);
          } else {
            pendingIdsBySpot.set(ad.spot, new Set([id]));
          }
        }
      }
    }

    if (pendingIdsBySpot.size === 0) {
      return undefined;
    }

    return pendingIdsBySpot;
  }

  private _getForcedIdsBySpot(): ByAdvertSpot<Set<AdvertId>, true> | undefined {
    const forcedIdsBySpot: ByAdvertSpot<Set<AdvertId>, true> = new Map();
    const {forcedAdsBySpot} = this._root.adRepositoryState;
    if (forcedAdsBySpot) {
      for (const [spot, ads] of forcedAdsBySpot) {
        forcedIdsBySpot.set(spot, new Set(ads.map(_ => _.id)));
      }
    }
    if (forcedIdsBySpot.size === 0) {
      return undefined;
    }
    return forcedIdsBySpot;
  }

  private _selectPendingAdBySpot(
    pendingIdsBySpot: ByAdvertSpot<ReadonlySet<AdvertId>> | undefined,
    forcedIdsBySpot: ByAdvertSpot<ReadonlySet<AdvertId>> | undefined,
    adSuspensionQuery: AdSuspensionQuery,
  ): ByAdvertSpot<Advert, true> | undefined {
    const {spots} = this._root.adRepositoryState;
    if (!spots) {
      return undefined;
    }

    const pendingAds: ByAdvertSpot<Advert, true> = new Map();
    for (const spot of spots) {
      const pendingIds = pendingIdsBySpot?.get(spot);
      const pendingId =
        pendingIds && adSuspensionQuery.selectLongestIntact(pendingIds, spot);
      let id;
      if (pendingId) {
        id = pendingId;
      } else {
        const forcedIds = forcedIdsBySpot?.get(spot);
        id =
          forcedIds && adSuspensionQuery.selectLongestIntact(forcedIds, spot);
      }
      const ad =
        id && this._root.adRepositoryState.adBySpotById?.get(id)?.get(spot);
      if (ad) {
        pendingAds.set(spot, ad);
      }
    }

    if (pendingAds.size > 0) {
      return pendingAds;
    }
    return undefined;
  }
}

const INTERVAL = 10000 as Millisecond;
