import type {CurrencyStore, WalletId, WalletStore} from '@ncwallet-app/core';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {action, computed, makeObservable, observable} from 'mobx';

// eslint-disable-next-line import-x/prefer-default-export
export class AddressNetworkSelectionBindingState {
  constructor(
    private readonly _root: {
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
    },
  ) {
    makeObservable(this);
  }

  @observable private _walletId: WalletId | undefined;
  @observable private _network: AddressNetwork | undefined;

  @action.bound
  async setAddressParams(walletId?: WalletId, network?: AddressNetwork) {
    this._walletId = walletId;
    this._network = network || this.currency?.options.default_network;
  }

  @computed
  private get currency() {
    const wallet =
      this._walletId && this._root.walletStore.getWalletById(this._walletId);
    return (
      wallet && this._root.currencyStore.getCryptoCurrency(wallet.currency)
    );
  }

  @computed get networks() {
    return this.currency?.options.currencies_in;
  }
}
