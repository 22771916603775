import {
  createNullableContext,
  useForcedContext,
  variance,
} from '@ncwallet-app/core';
import type {LogRecord} from '@ncwallet-app/core/src/Log';
import {SafeAreaFlatList, SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {FlatListProps} from 'react-native';
import {Button, StyleSheet, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

import LogItem from './LogItem';

export interface LogScreenProps {
  logRecords: readonly LogRecord[];
  buttonText: string;
  onButtonPress: () => void;
}

export const LogsScreen = observer((props: LogScreenProps) => {
  return (
    <LogScreenContext.Provider value={props}>
      <SafeAreaFlatList
        insets={[SafeAreaInset.BOTTOM]}
        ListHeaderComponent={ListHeaderComponent}
        data={props.logRecords}
        extraData={props.logRecords.length}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ItemSeparatorComponent={Separator}
      />
    </LogScreenContext.Provider>
  );
});

const LogScreenContext = createNullableContext<LogScreenProps>();

type ListProps = FlatListProps<LogRecord>;

const keyExtractor: NonNullable<ListProps['keyExtractor']> = item =>
  String(item.id);

const renderItem: ListProps['renderItem'] = ({item}) => (
  <LogItem content={item.content} capturedAt={item.capturedAt} />
);

const Separator = variance(View)(theme => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.foreground,
  },
}));

const layout = StyleSheet.create({
  filter: {flexDirection: 'row'},
  criteria: {margin: 8},
});

const ListHeaderComponent = observer(() => {
  const {onButtonPress, buttonText} = useForcedContext(LogScreenContext);
  return (
    <ScrollView
      overScrollMode="never"
      horizontal
      style={layout.filter}
      showsHorizontalScrollIndicator={false}>
      <View style={layout.criteria}>
        <Button onPress={onButtonPress} color="red" title={buttonText} />
      </View>
    </ScrollView>
  );
});
