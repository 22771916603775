import type {CommonRoute} from '../CommonNavigationScheme';
import {
  CHECK_BIOMETRICS_PIN_ROUTE,
  CHECK_PIN_ROUTE,
  LIST_INPUT_ADDRESSES_ROUTE,
  NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE,
  PROMPT_ACCOUNT_DELETION_ROUTE,
  PROMPT_AFFILIATE_PROGRAM_ROUTE,
  PROMPT_AMOUNT_TO_SEND_ROUTE,
  PROMPT_COMMENT_TO_SEND_ROUTE,
  PROMPT_COMMISSION_FOR_SENDING_ROUTE,
  PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE,
  PROMPT_FEEDBACK_ROUTE,
  PROMPT_INPUT_NETWORK_ROUTE,
  PROMPT_LANGUAGE_ROUTE,
  PROMPT_NEW_PIN_ROUTE,
  PROMPT_NEW_WALLET_ROUTE,
  PROMPT_OTP_TO_ADD_IP_ROUTE,
  PROMPT_OTP_TO_BIOMETRICS_ROUTE,
  PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE,
  PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE,
  PROMPT_OTP_TO_DELETE_ACCOUNT_ROUTE,
  PROMPT_OTP_TO_DELETE_IP_ROUTE,
  PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_EXCHANGE_ROUTE,
  PROMPT_OTP_TO_LIMIT_CREATE_ROUTE,
  PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE,
  PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE,
  PROMPT_OTP_TO_SEND_CRYPTO_ROUTE,
  PROMPT_OTP_TO_UPDATE_IP_ROUTE,
  PROMPT_OUTPUT_ADDRESS_ROUTE,
  PROMPT_OUTPUT_NETWORK_ROUTE,
  PROMPT_RATE_ROUTE,
  PROMPT_RECEIVE_NETWORK_ROUTE,
  PROMPT_SIGN_OUT_ROUTE,
  PROMPT_THEME_ROUTE,
  REDIRECT_TO_SEND_ROUTE,
  SHOW_QR_CODE_SCANNER_ROUTE,
  SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE,
} from '../CommonNavigationScheme';

// eslint-disable-next-line import-x/prefer-default-export
export const routesWithoutAds = new Set<CommonRoute['kind']>([
  SHOW_QR_CODE_SCANNER_ROUTE,
  SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE,
  REDIRECT_TO_SEND_ROUTE,
  PROMPT_OUTPUT_ADDRESS_ROUTE,
  PROMPT_AMOUNT_TO_SEND_ROUTE,
  PROMPT_OUTPUT_NETWORK_ROUTE,
  PROMPT_COMMENT_TO_SEND_ROUTE,
  PROMPT_COMMISSION_FOR_SENDING_ROUTE,
  PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE,
  PROMPT_NEW_WALLET_ROUTE,
  PROMPT_RECEIVE_NETWORK_ROUTE,
  LIST_INPUT_ADDRESSES_ROUTE,
  PROMPT_INPUT_NETWORK_ROUTE,
  PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE,
  PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE,
  PROMPT_OTP_TO_ADD_IP_ROUTE,
  PROMPT_OTP_TO_UPDATE_IP_ROUTE,
  PROMPT_OTP_TO_DELETE_IP_ROUTE,
  PROMPT_OTP_TO_BIOMETRICS_ROUTE,
  PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE,
  PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE,
  PROMPT_OTP_TO_LIMIT_CREATE_ROUTE,
  PROMPT_OTP_TO_SEND_CRYPTO_ROUTE,
  PROMPT_OTP_TO_EXCHANGE_ROUTE,
  PROMPT_OTP_TO_DELETE_ACCOUNT_ROUTE,
  CHECK_PIN_ROUTE,
  CHECK_BIOMETRICS_PIN_ROUTE,
  PROMPT_NEW_PIN_ROUTE,
  NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE,
  PROMPT_RATE_ROUTE,
  PROMPT_FEEDBACK_ROUTE,
  PROMPT_AFFILIATE_PROGRAM_ROUTE,
  PROMPT_ACCOUNT_DELETION_ROUTE,
  PROMPT_SIGN_OUT_ROUTE,
  PROMPT_THEME_ROUTE,
  PROMPT_LANGUAGE_ROUTE,
]);
