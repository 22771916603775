import type {BottomSheetBackgroundProps} from '@gorhom/bottom-sheet';
import {useStyles} from '@ncwallet-app/core';
import React from 'react';
import {View} from 'react-native';

// eslint-disable-next-line import-x/prefer-default-export
export const Background = ({style, ...rest}: BottomSheetBackgroundProps) => {
  const styles = useStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
    },
  }));
  return <View style={[style, styles.root]} {...rest} />;
};
