import {useCallback} from 'react';

import useRoot from '../Root/hooks/useRoot';

export function useGetIsReadyToShowUserInterface() {
  const root = useRoot();
  return useCallback(
    () => root.appStateHelper.isReadyToShowUserInterface,
    [root],
  );
}

export function useGetIsReadyToMakeRequests() {
  const root = useRoot();
  return useCallback(() => root.appStateHelper.isReadyToMakeRequests, [root]);
}
