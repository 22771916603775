import {variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import type {PinCardProps} from './PinCard';
import PinCard from './PinCard';
import type {SessionsProps} from './Sessions/SessionsHolder';
import Sessions from './Sessions/SessionsHolder';
import type {TwoFaCardProps} from './TwoFaCard/TwoFaCard';
import TwoFaCard from './TwoFaCard/TwoFaCard';

export type LgSecuritySettingsScreenProps = TwoFaCardProps &
  PinCardProps &
  SessionsProps;

export default observer(function LgSecuritySettingsScreen(
  props: LgSecuritySettingsScreenProps,
) {
  return (
    <Container>
      <TwoFaCard {...props} />
      <PinCard {...props} />
      <Sessions {...props} />
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    overflow: 'visible',
    paddingBottom: 30,
    paddingTop: 0,

    ...theme.mediaQuery({
      [1220]: {
        paddingHorizontal: 30,
      },

      [LG_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
}));
