import type GorhomBottomSheet from '@gorhom/bottom-sheet';
import type {AuthScreenFooterProps} from '@ncwallet-app/core/src/Components/AuthScreenFooter/AuthScreenFooter';
import {CenteredCardLayout} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import type {ComponentType} from 'react';
import React, {useCallback, useRef} from 'react';

import type {AuthVariant} from './types';
import {WelcomeSlider} from './WelcomeSlider';

export type AuthScreenProps = {
  AuthScreenFooter: ComponentType<AuthScreenFooterProps>;
  onLogInPress: (variant: AuthVariant) => void;
  onSignUpPress: (variant: AuthVariant) => void;
  appVersion?: string;
  nextEnvironmentButtonShown: boolean;
  onNextEnvironmentPress: () => void;
  onMdLoginPress: () => void;
  onMdCreateWalletPress: () => void;
};

export default observer(function AuthScreen(props: AuthScreenProps) {
  const {
    appVersion,
    nextEnvironmentButtonShown,
    AuthScreenFooter,
    onLogInPress,
    onMdCreateWalletPress,
    onNextEnvironmentPress,
    onMdLoginPress,
    onSignUpPress: _onSignUpPress,
  } = props;
  const logInRef = useRef<GorhomBottomSheet>(null);
  const signUpRef = useRef<GorhomBottomSheet>(null);
  const closeBottomSheet = useCallback(() => {
    logInRef.current?.close();
    signUpRef.current?.close();
  }, []);

  const onSignUpPress = useCallback(
    (_: AuthVariant) => {
      _onSignUpPress(_);
      closeBottomSheet();
    },
    [_onSignUpPress, closeBottomSheet],
  );

  const openLogInVariant = useCallback(() => {
    logInRef.current?.snapToIndex(0);
  }, []);

  const openSignUpVariant = useCallback(() => {
    signUpRef.current?.snapToIndex(0);
  }, []);

  return (
    <CenteredCardLayout appVersion={appVersion} hideBackButton>
      <WelcomeSlider />
      <AuthScreenFooter
        nextEnvironmentButtonShown={nextEnvironmentButtonShown}
        onNextEnvironmentPress={onNextEnvironmentPress}
        logInRef={logInRef}
        signUpRef={signUpRef}
        openLogInVariant={openLogInVariant}
        openSignUpVariant={openSignUpVariant}
        onLogInPress={onLogInPress}
        onMdLoginPress={onMdLoginPress}
        onSignUpPress={onSignUpPress}
        onCloseBottomSheet={closeBottomSheet}
        onMdCreateWalletPress={onMdCreateWalletPress}
      />
    </CenteredCardLayout>
  );
});
