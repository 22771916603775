import type {OptionalGetter} from '@ncwallet-app/core/src/mobx-toolbox';
import {use} from '@ncwallet-app/core/src/mobx-toolbox';
import ProfileSettingsItem from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/ProfileSettingsItem';
import {observer} from 'mobx-react-lite';
import React from 'react';

export type ProfileTelegramButtonProps = {
  $disabled?: OptionalGetter<boolean | undefined>;
  getTitle: () => string | undefined;
  onPress: () => void;
};

const ProfileTelegramButton = observer((props: ProfileTelegramButtonProps) => {
  const {$disabled, getTitle, onPress} = props;
  const disabled = use($disabled);
  return (
    <ProfileSettingsItem
      disabled={disabled}
      label="Telegram"
      getTitle={getTitle}
      onPress={onPress}
    />
  );
});

export default ProfileTelegramButton;
