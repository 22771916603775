import {useStrings, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type CountryListEmptyProps = {
  searchQuery: string;
};

export default observer(function CountryListEmpty(
  props: CountryListEmptyProps,
) {
  const {searchQuery} = props;
  const strings = useStrings();

  return (
    <ContainerView>
      <EmptyText>
        {strings['profileVerification.selectCountry.search.emptyText'].replace(
          '{searchQuery}',
          searchQuery,
        )}
      </EmptyText>
      <EmptyTextAdditional>
        {
          strings[
            'profileVerification.selectCountry.search.emptyTextAdditional'
          ]
        }
      </EmptyTextAdditional>
    </ContainerView>
  );
});

const ContainerView = variance(View)(() => ({
  root: {
    paddingTop: 20,
    paddingHorizontal: 15,
  },
}));

const EmptyText = variance(Text)(theme => ({
  root: {
    marginBottom: 5,
    ...theme.fontByWeight('700'),
    fontSize: 16,
    color: theme.palette.textPrimary,
  },
}));

const EmptyTextAdditional = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 13,
    color: theme.palette.textAdditional2,
  },
}));
