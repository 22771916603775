import type {CryptoCurrencyCode, DecimalString} from '@ncwallet-app/core';
import {
  formatCryptoValue,
  useStrings,
  useStyles,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import type {ErrorDetails} from '@ncwallet-app/core/src/ErrorParser';
import type {SafeAreaScrollViewProps} from '@ncwallet-app/ui';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  CurrencyCircleLogo,
  LG_BREAKPOINT,
  Pressable,
  SafeAreaScrollView,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  ArrowLeftWide,
  EditSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {BigNumber} from 'bignumber.js';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Platform, Text, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

type SendConfirmScreenProps = SafeAreaScrollViewProps & {
  addressNameShown: boolean;
  addressName: string | undefined;
  comment: string | undefined;
  networkChangeEnabled: boolean;
  addressTo: string;
  amount: DecimalString;
  fee: DecimalString;
  totalBalance: DecimalString;
  currency: CryptoCurrencyCode;
  confirmError: ErrorDetails | null;
  onBackPress: () => void;
  onAddressEdit: () => void;
  onAmountEdit: () => void;
  onNetworkEdit: () => void;
  onCommentEdit: () => void;
  onMinerFeeEdit: () => void;
  onSubmit: () => void;
  feeChangeDisabled: boolean;
  isBlockchainComment: boolean;
};

// eslint-disable-next-line import-x/prefer-default-export
export const SendConfirmScreen = observer((props: SendConfirmScreenProps) => {
  const {
    currency,
    onBackPress,
    onAddressEdit,
    onMinerFeeEdit,
    onNetworkEdit,
    onAmountEdit,
    onCommentEdit,
    onSubmit,
    fee,
    amount,
    totalBalance,
    confirmError,
    isBlockchainComment,
    ...rest
  } = props;
  const strings = useStrings();
  const isLg = useIsDimensions('lg');
  const lgStyle = useLgStyle();
  const theme = useTheme();

  const minerFee = 0;
  const total = BigNumber(amount).plus(fee).plus(minerFee).toString();
  const notEnoughMoney = BigNumber(total).isGreaterThan(totalBalance);

  return (
    <Root
      style={isLg ? lgStyle.root : lgStyle.rootSm}
      contentContainerStyle={isLg && lgStyle.container}
      {...rest}>
      {isLg ? (
        <>
          <BackButton
            title={strings['historyOperationFilterScreen.title']}
            onPress={onBackPress}
            Icon={ArrowLeftWide}
            iconCustomColor={theme.palette.uiAdditional1}
          />
          <CryptoBanner>
            <CurrencyCircleLogo code={currency} size={40} />
            <CurrencyValue>{formatCryptoValue(amount)}</CurrencyValue>
            <CurrencyCode>{currency}</CurrencyCode>
          </CryptoBanner>
        </>
      ) : (
        <SendDescriptionView>
          <SendDescription>
            {strings['sendCrypto.confirm.description']}
          </SendDescription>
        </SendDescriptionView>
      )}

      <PaymentScrollContainerView>
        <ConfirmPaymentInfo>
          <ConfirmPaymentInfoItem>
            <PaymentInfoItemLabel>
              {strings['sendCrypto.confirm.to']}:
            </PaymentInfoItemLabel>
            <PaymentInfoSlot>
              <PaymentInfoItemValue
                ellipsizeMode="middle"
                numberOfLines={1}
                error={!!confirmError}>
                {props.addressTo}
              </PaymentInfoItemValue>
              <Pressable onPress={onAddressEdit}>
                <EditSvgIcon />
              </Pressable>
            </PaymentInfoSlot>
          </ConfirmPaymentInfoItem>

          {props.addressNameShown && (
            <ConfirmPaymentInfoItem>
              <PaymentInfoItemLabel>
                {strings['sendCrypto.confirm.network']}:
              </PaymentInfoItemLabel>
              <PaymentInfoSlot>
                <PaymentInfoItemValue
                  ellipsizeMode="middle"
                  numberOfLines={1}
                  error={!!confirmError}>
                  {props.addressName}
                </PaymentInfoItemValue>
                {props.networkChangeEnabled && (
                  <Pressable onPress={onNetworkEdit}>
                    <EditSvgIcon />
                  </Pressable>
                )}
              </PaymentInfoSlot>
            </ConfirmPaymentInfoItem>
          )}

          <ConfirmPaymentInfoItem>
            <PaymentInfoItemLabel>
              {strings['sendCrypto.confirm.amount']}:
            </PaymentInfoItemLabel>
            <PaymentInfoSlot>
              <PaymentInfoItemValue highlighted>
                {formatCryptoValue(amount)} {currency}
              </PaymentInfoItemValue>
              <Pressable onPress={onAmountEdit}>
                <EditSvgIcon />
              </Pressable>
            </PaymentInfoSlot>
          </ConfirmPaymentInfoItem>

          <ConfirmPaymentInfoItem>
            <PaymentInfoItemLabel>
              {strings['sendCrypto.confirm.minerFee']}:
            </PaymentInfoItemLabel>
            <PaymentInfoSlot>
              <PaymentInfoItemValue>
                {formatCryptoValue(fee)} {currency}
              </PaymentInfoItemValue>

              {!props.feeChangeDisabled && (
                <Pressable
                  onPress={onMinerFeeEdit}
                  disabled={props.feeChangeDisabled}>
                  <EditSvgIcon />
                </Pressable>
              )}
            </PaymentInfoSlot>
          </ConfirmPaymentInfoItem>

          <ConfirmPaymentInfoItem>
            <PaymentInfoItemLabel>
              {strings['sendCrypto.confirm.walletFee']}:
            </PaymentInfoItemLabel>
            <PaymentInfoItemValue>
              {formatCryptoValue(minerFee)} {currency}
            </PaymentInfoItemValue>
          </ConfirmPaymentInfoItem>

          <ConfirmPaymentInfoItem>
            <PaymentInfoItemLabel>
              {strings['sendCrypto.confirm.total']}:
            </PaymentInfoItemLabel>
            <PaymentInfoSlot>
              <PaymentInfoItemValue error={notEnoughMoney}>
                {formatCryptoValue(total)} {currency}
              </PaymentInfoItemValue>
            </PaymentInfoSlot>
          </ConfirmPaymentInfoItem>

          <ConfirmPaymentInfoItem last>
            <PaymentInfoItemLabel>
              {isBlockchainComment
                ? strings['BlockchainComment']
                : strings['sendCrypto.confirm.comment']}
              :
            </PaymentInfoItemLabel>
            <PaymentInfoSlot>
              <PaymentInfoItemValue ellipsizeMode="middle" numberOfLines={2}>
                {props.comment || '-'}
              </PaymentInfoItemValue>
            </PaymentInfoSlot>
            <Pressable onPress={onCommentEdit}>
              <EditSvgIcon />
            </Pressable>
          </ConfirmPaymentInfoItem>
        </ConfirmPaymentInfo>
      </PaymentScrollContainerView>

      <PaymentBottomView>
        <ConfirmErrorMessage>
          {confirmError && confirmError.summary}
        </ConfirmErrorMessage>

        <ConfirmButton
          title={strings['sendCrypto.confirm.submit']}
          onPress={onSubmit}
          color={ButtonColor.Secondary}
          variant={ButtonVariant.Highlighted}
          disabled={!!confirmError}
          testID="send-submit-btn"
        />
      </PaymentBottomView>
    </Root>
  );
});

const Root = variance(SafeAreaScrollView)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        ...theme.bar(10),
      },
    }),
  },
}));

const PaymentScrollContainerView = variance(ScrollView)(theme => ({
  root: {
    maxHeight: 490,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxHeight: 220,
      },
    }),
  },
}));

const CryptoBanner = variance(View)(theme => ({
  root: {
    marginBottom: 20,
    padding: 17,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.palette.primary,
    backgroundColor: theme.palette.additional4,
  },
}));

const CurrencyValue = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 36,
    lineHeight: 44,
    marginLeft: 20,
    color: theme.palette.textPrimary,
  },
}));

const CurrencyCode = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 36,
    lineHeight: 44,
    marginLeft: 10,
    color: theme.palette.primary,
  },
}));

const ConfirmPaymentInfo = variance(View)(theme => ({
  root: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.palette.uiSecondary,
        backgroundColor: theme.palette.uiPrimary,
      },
    }),
  },
}));

const SendDescriptionView = variance(View)(() => ({
  root: {
    padding: 20,
  },
}));

const SendDescription = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
  },
}));

const ConfirmPaymentInfoItem = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    marginHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginHorizontal: 0,
        paddingHorizontal: 10,
      },
    }),
  },
  last: {
    borderBottomWidth: 0,
  },
}));

const PaymentInfoItemLabel = variance(Text)(theme => ({
  root: {
    paddingRight: 10,
    marginRight: 'auto',
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
  },
}));

const PaymentInfoSlot = variance(View)(() => ({
  root: {
    flex: 1,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const PaymentInfoItemValue = variance(Text)(theme => ({
  root: {
    flex: 1,
    textAlign: 'right',
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
  },
  disabled: {
    color: theme.palette.textAdditional3,
    opacity: 0.5,
  },
  error: {
    color: theme.palette.error,
    ...theme.fontByWeight('700'),
  },
  highlighted: {
    ...theme.fontByWeight('700'),
    color: theme.palette.primary,
  },
}));

const ConfirmButton = variance(Button)(() => ({
  root: {
    width: 345,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}));

const EditSvgIcon = variance(EditSvg)(theme => ({
  root: {
    color: theme.palette.uiSecondary,
    marginLeft: 10,
  },
}));

const useLgStyle = () =>
  useStyles(theme => ({
    root: {
      flex: 1,
      backgroundColor: theme.palette.uiPrimary,
    },
    rootSm: {
      ...Platform.select({
        web: {
          paddingBottom: 15,
        },
      }),
    },
    container: {
      flex: 1,
      borderRadius: 8,
      backgroundColor: theme.palette.background,
      padding: 30,
      paddingBottom: 30,
    },
  }));

const BackButton = variance(Button)(() => ({
  root: {
    marginBottom: 20,
    padding: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));

const PaymentBottomView = variance(View)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const ConfirmErrorMessage = variance(Text)(theme => ({
  root: {
    marginVertical: 20,
    ...theme.fontByWeight('500'),
    color: theme.palette.error,
    textAlign: 'center',
    fontSize: 13,
  },
}));
