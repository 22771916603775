import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ListNotificationSettingsContainer from '../../../../../CommonNavigationContainers/containers/ListNotificationSettingsContainer';
import type {SmallHomeStackBindingProps} from '../../../../../SmallNavigationRoot/SmallHomeStack';

export type ListNotificationSettingsBindingProps =
  SmallHomeStackBindingProps<'ListNotificationSettings'>;

export default observer(function ListNotificationSettingsBinding(
  props: ListNotificationSettingsBindingProps,
) {
  const {navigation} = props;
  const handleNavigateToLinkTelegram = useCallback(() => {
    navigation.navigate('PromptLinkToTelegram');
  }, [navigation]);

  return (
    <ListNotificationSettingsContainer
      getIsTransitioning={getIsTransitioning}
      goToLinkTelegram={handleNavigateToLinkTelegram}
    />
  );
});

const getIsTransitioning = () => false;
