import {useRoot, useStyles} from '@ncwallet-app/core';
import {setStringAsync} from 'expo-clipboard';
import React, {forwardRef, useCallback} from 'react';
import type {GestureResponderEvent, TextProps} from 'react-native';
import {Text} from 'react-native';

interface CopyableProps extends TextProps {
  children?: string;
}

export default forwardRef<Text, CopyableProps>(
  ({children, onPress: _onPress, style, ...rest}, ref) => {
    const {alert} = useRoot();
    const onPress = useCallback(
      (event: GestureResponderEvent) => {
        if (children) {
          void setStringAsync(children);
          alert.alert(
            'The value has been copied to clipboard',
            children,
            [{style: 'default', text: 'OK'}],
            {cancelable: true},
          );
        }
        if (_onPress) {
          _onPress(event);
        }
      },
      [_onPress, alert, children],
    );
    const styles = useStyles(theme => ({
      root: {
        color: theme.palette.textPrimary,
      },
    }));
    return (
      <Text style={[styles.root, style]} {...rest} onPress={onPress} ref={ref}>
        {children}
      </Text>
    );
  },
);
