import type {BaseAsyncOptions} from '../Async';
import {createTimeoutSignal, performWithAnySignal} from '../Async';
import type {
  CancellationError,
  GeneralJsonRpcError,
  GlobalError,
  TimeoutError,
} from '../Error';
import {
  CANCELLATION_ERROR,
  GENERAL_JSON_RPC_ERROR,
  TIMEOUT_ERROR,
  USER_CANCELLATION_ERROR,
} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {JsonSerializable} from '../Json';
import {BusImpl, RouterImpl} from '../structure';
import type {Millisecond} from '../Time';
import type {
  CallOptions,
  JsonRpcClient,
  UndefinedToNull,
} from './JsonRpcClient';
import type {JsonRpcClientDefaultError} from './JsonRpcClientDefaultError';
import type {
  JsonRpcSettledCallRouterMap,
  JsonRpcSettledCallRouterSource,
} from './JsonRpcSettledCallRouterSource';
import type {JsonRpcError, JsonRpcId, Request, Response} from './Protocol';
import {isError, isResponse, isSuccess} from './Protocol';
import type {CallScheme, NotificationScheme} from './Scheme';
import type {Tunnel} from './Tunnel';
import {TUNNEL_INCOMING_MESSAGE} from './Tunnel';

export default class JsonRpcClientService<
  C extends CallScheme = CallScheme,
  N extends NotificationScheme = NotificationScheme,
  E extends JsonRpcError = JsonRpcError,
> implements JsonRpcClient<C, N>
{
  private readonly _responsesById = new RouterImpl<ResponseMap>();
  private readonly _responsesWithNullId = new BusImpl<(_: Response) => void>();

  constructor(
    private readonly _root: {readonly errorRepository: ErrorRepository},
    private readonly _tunnel: Tunnel<JsonSerializable, JsonSerializable>,
    private readonly _ids: Generator<JsonRpcId, never>,
    private readonly _timeout: Millisecond,
  ) {}

  async notify<K extends keyof N>(
    ...args: N[K]['params'] extends undefined
      ? [K, undefined?, BaseAsyncOptions?]
      : [K, N[K]['params'], BaseAsyncOptions?]
  ): Promise<Either<void, GlobalError>> {
    const [method, params, options] = args;
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }
    const timeout = this._createTimeoutSignal();
    return performWithAnySignal(
      async signal => {
        const request: Request = {
          jsonrpc: '2.0',
          method: String(method),
          params,
        };
        const send_ = await this._tunnel.send(request, {signal});
        if (
          timeout?.aborted &&
          !send_.success &&
          (send_.left.kind === CANCELLATION_ERROR ||
            send_.left.kind === USER_CANCELLATION_ERROR)
        ) {
          return error(
            this._root.errorRepository.create<TimeoutError>({
              kind: TIMEOUT_ERROR,
              raw: send_.left.raw,
              description: send_.left.description,
            }),
          );
        }
        return send_;
      },
      [timeout, options?.signal],
    );
  }

  /**
   * Call the server method and wait for a result
   * @throws {never}
   */
  async call<K extends keyof C>(
    ...args: C[K]['params'] extends undefined
      ? [K, undefined?, CallOptions?]
      : [K, C[K]['params'], CallOptions?]
  ): Promise<
    Either<
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      'result' extends keyof C[K] ? UndefinedToNull<C[K]['result']> : void,
      GlobalError | GeneralJsonRpcError<E>
    >
  > {
    const [method, params, options] = args;
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }
    const timeout = this._createTimeoutSignal();
    const id = options?.id ?? this._nextId();
    return performWithAnySignal(
      async signal => {
        const request: Request = {
          jsonrpc: '2.0',
          method: String(method),
          params,
          id,
        };
        const send_ = await this._tunnel.send(request, {signal});
        if (!send_.success) {
          return send_;
        }
        return new Promise(resolve => {
          signal?.addEventListener('abort', onAbort, {once: true});

          function cleanupSignal() {
            signal?.removeEventListener('abort', onAbort);
          }

          let cleanupResponse: () => void;
          if (id === null) {
            this._responsesWithNullId.once(onResponse);
            cleanupResponse = () => {
              this._responsesWithNullId.forget(onResponse);
            };
          } else {
            this._responsesById.once(id, onResponse);
            cleanupResponse = () => {
              this._responsesById.forget(id, onResponse);
            };
          }
          const that = this;

          function onAbort() {
            cleanupResponse();
            if (timeout?.aborted) {
              resolve(error(that._createTimeoutError(timeout.reason)));
            } else {
              resolve(
                error(that._createCancellationError(options?.signal?.reason)),
              );
            }
          }

          function onResponse(_: Response) {
            cleanupSignal();
            const outcome = isSuccess(_)
              ? success(
                  _.result as 'result' extends keyof C[K]
                    ? UndefinedToNull<C[K]['result']>
                    : // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
                      void,
                )
              : isError(_)
                ? error(that._wrapJsonRpcError(_.error as E))
                : error(
                    that._wrapJsonRpcError(
                      that._createUnknownJsonRpcError(_) as E,
                    ),
                  );
            resolve(outcome);
            // @ts-expect-error This is definitely a bug of TypeScript <=5.6.3
            that._settledCalls.send(method, {params, result: outcome});
          }
        });
      },
      [timeout, options?.signal],
    );
  }

  private _wrapJsonRpcError(_: JsonRpcClientDefaultError | E) {
    return this._root.errorRepository.create<GeneralJsonRpcError<E>>({
      kind: GENERAL_JSON_RPC_ERROR,
      body: _,
      description: `An error was received in JSON-RPC: ${_.code} ${_.message}`,
    });
  }

  private _nextId() {
    const next = this._ids.next();
    if (next.done) {
      console.warn('The id generator has no values left');
      return Math.random() * Number.MAX_SAFE_INTEGER;
    }
    return next.value;
  }

  private _emitResponse(response: Response) {
    if (response.id === null) {
      this._responsesWithNullId.send(response);
      return;
    }
    this._responsesById.send(response.id, response);
  }

  private readonly _onMessage = (raw: unknown) => {
    if (Array.isArray(raw)) {
      for (const item of raw) {
        if (isResponse(item)) {
          this._emitResponse(item);
        }
      }
    } else if (isResponse(raw)) {
      this._emitResponse(raw);
    }
  };

  private _settledCalls = new RouterImpl<JsonRpcSettledCallRouterMap<C>>();

  get settledCalls(): JsonRpcSettledCallRouterSource<C> {
    return this._settledCalls;
  }

  subscribe() {
    return this._tunnel.io.listen(TUNNEL_INCOMING_MESSAGE, this._onMessage);
  }

  private _createTimeoutSignal() {
    return isFinite(this._timeout)
      ? createTimeoutSignal(this._timeout)
      : undefined;
  }

  private _createTimeoutError(cause?: unknown) {
    return this._root.errorRepository.create<TimeoutError>({
      kind: TIMEOUT_ERROR,
      raw: cause,
    });
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }

  private _createUnknownJsonRpcError(_: JsonSerializable) {
    return {
      code: -32703,
      message: 'Unknown response',
      data: _,
    } as JsonRpcError;
  }
}

type ResponseMap = Record<string | number, (_: Response) => void>;
