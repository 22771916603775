import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import {success} from '../fp';
import type {
  JsonKeyValueStore,
  JsonSecureKeyValueMap,
} from '../JsonKeyValueStore';
import type {JwtString} from '../Jwt';
import type {Log} from '../Log';
import type {Time} from '../Time';
import type {AuthQuery, AuthResponse} from './AuthQuery';
import {
  AUTHORIZED,
  MULTI_FACTOR,
  NO_LOCAL_RECORD,
  PRE_AUTH,
  RECORD_EXPIRED,
  UNAUTHORIZED,
} from './AuthQuery';
import type {JwtHelper} from './JwtHelper';

export default class AuthQueryImpl implements AuthQuery {
  constructor(
    private readonly _root: {
      readonly signOutReasonLog: Log;
      readonly time: Time;
      readonly jwtHelper: JwtHelper;
      readonly jsonSecureKeyValueStore: JsonKeyValueStore<JsonSecureKeyValueMap>;
    },
  ) {}

  async query(): Promise<Either<AuthResponse, GlobalError>> {
    const now = this._root.time.now();
    const get_ = await this._root.jsonSecureKeyValueStore.get('auth2');
    if (!get_.success) {
      return get_;
    }
    if (!get_.right) {
      this._root.signOutReasonLog.write({
        body: 'UNAUTHORIZED - no local record',
      });
      return success({kind: UNAUTHORIZED, reason: NO_LOCAL_RECORD});
    }
    const credentials = get_.right;
    const crucial: JwtString = credentials.isDirect
      ? credentials.refreshToken
      : credentials.multiFactorToken;
    const isExpired_ = this._root.jwtHelper.isExpired(crucial, now);
    if (!isExpired_.success) {
      return isExpired_;
    }
    if (isExpired_.right) {
      this._root.signOutReasonLog.write({
        body: `UNAUTHORIZED - Record expired jwt - ${crucial}, now - ${now}`,
      });
      return success({kind: UNAUTHORIZED, reason: RECORD_EXPIRED});
    }
    if (credentials.isPreAuth) {
      return success({kind: PRE_AUTH, credentials});
    } else if (!credentials.isDirect) {
      return success({kind: MULTI_FACTOR, credentials});
    } else {
      return success({kind: AUTHORIZED, credentials});
    }
  }
}
