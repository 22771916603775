import {useRoot} from '@ncwallet-app/core';
import {useState} from 'react';

import ExchangeCryptoConfirmBindingState from './ExchangeCryptoConfirmBindingState';

export default function useExchangeCryptoConfirmBindingState() {
  const root = useRoot();
  const [state] = useState(() => new ExchangeCryptoConfirmBindingState(root));
  return state;
}
