import type {GlobalError} from '../Error';
import {UNKNOWN_ERROR} from '../Error';
import type {Either} from '../fp';
import {error, success} from '../fp';
import BaseFileSystemImpl from './BaseFileSystemImpl';
import type {FileSystem} from './FileSystem';

export default class WebFileSystemImpl
  extends BaseFileSystemImpl
  implements FileSystem
{
  async save(
    content: string,
    filename: string,
    mimeType: string,
  ): Promise<Either<void, GlobalError>> {
    try {
      const a = document.createElement('a');
      a.download = filename;
      a.href = URL.createObjectURL(new Blob([content], {type: mimeType}));
      a.click();
      return success(undefined);
    } catch (raw) {
      return error(
        this._root.errorRepository.create({kind: UNKNOWN_ERROR, raw}),
      );
    }
  }
}
