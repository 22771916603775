import {variance} from '@ncwallet-app/core';
import {BaseSkeleton} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export default observer(function WalletLimitsSkeleton() {
  return (
    <>
      <WalletLimitsSkeletonView>
        <WalletLimitsSkeletonRow>
          <BaseSkeleton width={22} height={22} isRounded />
          <BaseSkeleton width={55} height={20} />
        </WalletLimitsSkeletonRow>
        <SizedBox />
        <WalletLimitsSkeletonRow>
          <BaseSkeleton width={160} height={15} />
        </WalletLimitsSkeletonRow>
        <SizedBox small />
        <WalletLimitsSkeletonRow>
          <BaseSkeletonProgress width={310} height={4} />
        </WalletLimitsSkeletonRow>
      </WalletLimitsSkeletonView>
      <WalletLimitsSkeletonView>
        <WalletLimitsSkeletonRow>
          <BaseSkeleton width={22} height={22} isRounded />
          <BaseSkeleton width={55} height={20} />
        </WalletLimitsSkeletonRow>
        <SizedBox />
        <WalletLimitsSkeletonRow>
          <BaseSkeleton width={160} height={15} />
        </WalletLimitsSkeletonRow>
        <SizedBox small />
        <WalletLimitsSkeletonRow>
          <BaseSkeletonProgress width={310} height={4} />
        </WalletLimitsSkeletonRow>
      </WalletLimitsSkeletonView>
      <WalletLimitsSkeletonView>
        <WalletLimitsSkeletonRow>
          <BaseSkeleton width={22} height={22} isRounded />
          <BaseSkeleton width={55} height={20} />
        </WalletLimitsSkeletonRow>
        <SizedBox />
        <WalletLimitsSkeletonRow>
          <BaseSkeleton width={160} height={15} />
        </WalletLimitsSkeletonRow>
        <SizedBox small />
        <WalletLimitsSkeletonRow>
          <BaseSkeletonProgress width={310} height={4} />
        </WalletLimitsSkeletonRow>
      </WalletLimitsSkeletonView>
      <WalletLimitsSkeletonView>
        <WalletLimitsSkeletonRow>
          <BaseSkeleton width={22} height={22} isRounded />
          <BaseSkeleton width={55} height={20} />
        </WalletLimitsSkeletonRow>
        <SizedBox />
        <WalletLimitsSkeletonRow>
          <BaseSkeleton width={160} height={15} />
        </WalletLimitsSkeletonRow>
        <SizedBox small />
        <WalletLimitsSkeletonRow>
          <BaseSkeletonProgress width={310} height={4} />
        </WalletLimitsSkeletonRow>
      </WalletLimitsSkeletonView>
    </>
  );
});

const WalletLimitsSkeletonView = variance(View)(theme => ({
  root: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const WalletLimitsSkeletonRow = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const BaseSkeletonProgress = variance(BaseSkeleton)(() => ({
  root: {
    borderRadius: 6,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 15,
  },
  small: {
    height: 5,
  },
}));
