import type {CurrencyDescription, DecimalString} from '@ncwallet-app/core';
import {formatCryptoValue, useStrings, variance} from '@ncwallet-app/core';
import {
  CurrencyCircleLogo,
  LG_BREAKPOINT,
  Pressable,
  TouchableListItem,
  useIsDimensions,
} from '@ncwallet-app/ui/src';
import {OrangeCircleSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {
  ArrowDownWideSvg,
  ArrowRightSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type HistoryCurrencyItemProps = {
  goToCurrencyList: () => void;
  walletTotal: DecimalString | undefined;
  walletCurrency: CurrencyDescription | undefined;
};

export default observer(function HistoryCurrencyItem({
  goToCurrencyList,
  walletTotal,
  walletCurrency,
}: HistoryCurrencyItemProps) {
  const isLg = useIsDimensions('lg');
  const strings = useStrings();

  return (
    <Pressable dataSet={{['app']: 'history-filter-item'}}>
      <Item
        Icon={isLg ? ArrowDownWideSvg : ArrowRightSvg}
        onPress={goToCurrencyList}>
        {walletCurrency ? (
          <CurrencyCircleLogo code={walletCurrency.code} size={40} />
        ) : (
          <OrangeCircleSvg />
        )}
        <Block>
          <Title>
            {walletCurrency?.name ??
              strings['historyFilterScreen.selectCoin.all']}
          </Title>
          {walletCurrency && (
            <CurrencyText>
              {walletTotal &&
                formatCryptoValue(
                  walletTotal,
                  walletCurrency.fractionDigits,
                )}{' '}
              {walletCurrency.code}
            </CurrencyText>
          )}
        </Block>
      </Item>
    </Pressable>
  );
});

const Item = variance(TouchableListItem)(theme => ({
  root: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    paddingHorizontal: 15,
    paddingVertical: 15,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderWidth: 1,
        borderColor: theme.palette.uiSecondary,
        borderRadius: 8,
        paddingVertical: 10,
      },
    }),
  },
}));

const Block = variance(View)(() => ({
  root: {
    marginLeft: 15,
    marginRight: 15,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 17,
  },
}));

const CurrencyText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 13,
    lineHeight: 16,
  },
}));
