import type {CurrencyStore, WalletId, WalletStore} from '@ncwallet-app/core';
import {getDefaultInOutCurrency} from '@ncwallet-app/core';
import type {
  AddressNetwork,
  AddressType,
} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

// eslint-disable-next-line import-x/prefer-default-export
export class LgAddressTypeSelectionBindingState {
  @observable isCreating = false;

  @computed get defaultAddressType() {
    return (
      this.currency && getDefaultInOutCurrency(this.currency, 'in').default_type
    );
  }

  @computed get addressTypes(): AddressType[] | undefined {
    return (
      this.currency &&
      getDefaultInOutCurrency(this.currency, 'in').address_types
    );
  }

  @observable private _walletId: WalletId | undefined;
  @observable private _network: AddressNetwork | undefined;

  constructor(
    private readonly _root: {
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
    },
  ) {
    makeObservable(this);
  }

  @action.bound
  async refresh(walletId: WalletId, network: AddressNetwork) {
    this._walletId = walletId;
    this._network = network;
    void this._root.walletStore.refreshWallets();
    void this._root.currencyStore.refreshCryptoCurrencies();
  }

  @action.bound
  async createAddress(type: AddressType) {
    this.isCreating = true;
    const res = await this._root.walletStore.createAddress(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._walletId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._network!,
      type,
    );
    runInAction(() => {
      this.isCreating = false;
    });

    return res;
  }

  @computed
  private get currency() {
    const wallet =
      this._walletId && this._root.walletStore.getWalletById(this._walletId);
    return (
      wallet && this._root.currencyStore.getCryptoCurrency(wallet.currency)
    );
  }
}
