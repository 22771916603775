import {useStrings, variance} from '@ncwallet-app/core';
import {ThemeKind} from '@ncwallet-app/core/src/Appearance';
import {Button, ButtonIconPosition, ButtonVariant} from '@ncwallet-app/ui';
import {
  LightningSvg,
  LunaSvg,
  SunSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type ThemeSelectBodyProps = {
  currentTheme: ThemeKind;
  onChangeTheme: (theme: ThemeKind) => void;
};

export default observer(function ThemeSelect(props: ThemeSelectBodyProps) {
  const strings = useStrings();

  return (
    <LgContainer>
      <Subtitle>{strings['themeSelect.subtitle']}</Subtitle>
      <ThemeButton
        title={strings['themeSelect.buttonAuto']}
        iconPosition={ButtonIconPosition.Left}
        variant={ButtonVariant.ThemeButton}
        Icon={LightningSvg}
        checked={props.currentTheme === ThemeKind.Auto}
        onPress={() => {
          props.onChangeTheme(ThemeKind.Auto);
        }}
      />
      <ThemeButton
        title={strings['themeSelect.buttonLight']}
        iconPosition={ButtonIconPosition.Left}
        variant={ButtonVariant.ThemeButton}
        checked={props.currentTheme === ThemeKind.Light}
        onPress={() => {
          props.onChangeTheme(ThemeKind.Light);
        }}
        Icon={SunSvg}
      />
      <ThemeButton
        title={strings['themeSelect.buttonDark']}
        iconPosition={ButtonIconPosition.Left}
        variant={ButtonVariant.ThemeButton}
        Icon={LunaSvg}
        checked={props.currentTheme === ThemeKind.Dark}
        onPress={() => {
          props.onChangeTheme(ThemeKind.Dark);
        }}
      />
    </LgContainer>
  );
});

const LgContainer = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingTop: 10,
    paddingBottom: 0,
    backgroundColor: 'transparent',
  },
}));

const Subtitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 24,
    color: theme.palette.textAdditional1,
    marginBottom: 20,
    textAlign: 'center',
  },
}));

const ThemeButton = variance(Button)(() => ({
  root: {
    paddingLeft: 20,
    marginBottom: 20,
  },
}));
