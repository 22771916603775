import {useStrings, variance} from '@ncwallet-app/core';
import {useIsSuspendedOrBanned} from '@ncwallet-app/core/src/UserStatusErrorDetector';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';

import {useContactUs} from '../../shared/useContactUs';
import Warning from '../../shared/Warning';

export default observer(function SuspendedOrBannedWarning() {
  const isSuspendedOrBanned = useIsSuspendedOrBanned();
  const strings = useStrings();
  const contactUs = useContactUs();

  if (!isSuspendedOrBanned) {
    return <></>;
  }

  return (
    <InfoBlockWrapper>
      <Warning
        content={
          <>
            <View>
              <MainText>{strings.suspendMessage}</MainText>
            </View>
            <LinkRow>
              <TouchableOpacity onPress={contactUs}>
                <Link>{strings.contactSupport}.</Link>
              </TouchableOpacity>
            </LinkRow>
          </>
        }
      />
    </InfoBlockWrapper>
  );
});

const InfoBlockWrapper = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingTop: 15,
  },
}));

const LinkRow = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const MainText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    fontSize: 14,
    lineHeight: 24,
  },
}));

const Link = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 24,
    color: theme.palette.info,
  },
}));
