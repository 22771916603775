import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {BottomSheet, SafeAreaLayout} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {StyleSheet} from 'react-native';

import type {AdvertScreenBodyProps} from './AdvertScreenBody';
import AdvertScreenBody from './AdvertScreenBody';

export type AdvertScreenProps = BaseSafeAreaProps & AdvertScreenBodyProps;

export default observer(function AdvertScreen(props: AdvertScreenProps) {
  const {onClose} = props;
  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <SafeAreaLayout {...props} style={style.area}>
      <BottomSheet
        index={0}
        onChange={onChange}
        animateOnMount
        snapPoints={['100%']}
        enablePanDownToClose
        backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
        onClose={onClose}
        children={<AdvertScreenBody {...props} />}
      />
    </SafeAreaLayout>
  );
});

const style = StyleSheet.create({
  area: {
    flex: 1,
  },
});
