import {useRoot} from '@ncwallet-app/core/';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import RPCTimeoutErrorBinding from './RPCTimeoutErrorBinding';

export default observer(function RPCTimeoutErrorModalStack() {
  const {rpcTimeoutErrorVisibility} = useRoot();

  const handleClose = useCallback(() => {
    rpcTimeoutErrorVisibility.callRegisteredAction();
  }, [rpcTimeoutErrorVisibility]);

  if (rpcTimeoutErrorVisibility.isVisible) {
    return <RPCTimeoutErrorBinding onClose={handleClose} />;
  }

  return null;
});
