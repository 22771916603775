import type {
  CryptoAddress,
  CryptoCurrencyCode,
  DecimalString,
  QrCodeHistoryEntry,
} from '@ncwallet-app/core';
import {
  toCurrencyDescriptionFromCrypto,
  useRoot,
  useStrings,
} from '@ncwallet-app/core';
import type {AddressHistoryItem} from '@ncwallet-app/core/src/AddressHistoryRepository';
import {getCurrencyCodeByAddressKind} from '@ncwallet-app/core/src/AddressParser';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {setStringAsync} from 'expo-clipboard';
import {autorun, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {
  useCryptoCurrencies,
  useNavigationGetIsFocused,
} from '../../Navigation/hooks';
import QrCodeHistoryScreen from '../../screens/QrCodeHistoryScreen/QrCodeHistoryScreen';

export type ListQrCodeHistoryContainerProps = {
  onEntryNavigate: (
    address: CryptoAddress,
    amount?: DecimalString,
    currency?: CryptoCurrencyCode,
  ) => void;
  goBack: () => void;
};

export default observer(function ListQrCodeHistoryContainer(
  props: ListQrCodeHistoryContainerProps,
) {
  const {onEntryNavigate: _onEntryNavigate} = props;
  const addressHistory = useAddressHistory();
  const crypto = useCryptoCurrencies();
  const strings = useStrings();
  const root = useRoot();
  const getEntries = useCallback(
    (): QrCodeHistoryEntry[] | undefined =>
      crypto.areCryptoCurrenciesLoading()
        ? undefined
        : addressHistory.getResult()?.map(_ => {
            const receipt = root.addressParser.parse(_.address);
            const currency =
              receipt.kind &&
              crypto.getCryptoCurrency(
                getCurrencyCodeByAddressKind(receipt.kind),
              );
            const currencyDescription =
              currency && toCurrencyDescriptionFromCrypto(currency);
            return {
              id: _.address,
              address: receipt.address,
              createdAt: _.updatedAt,
              currency: currencyDescription ?? null,
              amount: receipt.amount,
            };
          }),
    [addressHistory, crypto, root.addressParser],
  );
  const onEntrySelect = useCallback(
    async (entry: QrCodeHistoryEntry) => {
      await setStringAsync(entry.address);
      const toastMessage = strings['qrCodeHistory.onCopyMessage'];
      root.flashMessage.showMessage({
        title: toastMessage,
        variant: 'success',
      });
    },
    [root.flashMessage, strings],
  );
  const onEntryNavigate = useCallback(
    (entry: QrCodeHistoryEntry) => {
      _onEntryNavigate(
        entry.address as CryptoAddress,
        entry.amount,
        entry.currency?.code as CryptoCurrencyCode | undefined,
      );
    },
    [_onEntryNavigate],
  );

  const onRefresh = useCallback(
    () =>
      Promise.all([addressHistory.refresh(), crypto.loadCryptoCurrencies()]),
    [addressHistory, crypto],
  );
  const getIsRefreshing = useCallback(
    () => addressHistory.getIsPending() || crypto.areCryptoCurrenciesLoading(),
    [addressHistory, crypto],
  );

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void onRefresh();
          });
        }
      }),
    [getIsFocused, getIsReady, onRefresh],
  );

  return (
    <QrCodeHistoryScreen
      goBack={props.goBack}
      getEntries={getEntries}
      onEntrySelect={onEntrySelect}
      onEntryNavigate={onEntryNavigate}
      onRefresh={onRefresh}
      getIsRefreshing={getIsRefreshing}
      insets={SafeAreaInset.BOTTOM}
    />
  );
});

const useAddressHistory = () => {
  const [pendingBox] = useState(() => observable.box(false));
  const [resultBox] = useState(() =>
    observable.box<AddressHistoryItem[] | undefined>(),
  );
  const root = useRoot();
  const refresh = useCallback(async () => {
    runInAction(() => {
      pendingBox.set(true);
    });
    try {
      const outcome_ = await root.addressHistoryRepository.getAll();
      if (!outcome_.success) {
        return;
      }
      runInAction(() => {
        resultBox.set(outcome_.right);
      });
    } finally {
      runInAction(() => {
        pendingBox.set(false);
      });
    }
  }, [pendingBox, root, resultBox]);
  const getIsPending = useCallback(() => pendingBox.get(), [pendingBox]);
  const getResult = useCallback(() => resultBox.get(), [resultBox]);
  return useMemo(
    () => ({getIsPending, getResult, refresh}),
    [getIsPending, getResult, refresh],
  );
};
