import type {
  ListHistoryFiltersRoute,
  RouteParams,
  ShowTransactionRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import ListHistoryContainer from '../../../../../CommonNavigationContainers/containers/ListHistoryContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type ListHistoryBindingProps = LargeSwitchBindingProps<'ListHistory'>;

export default observer(function ListHistoryBinding(
  props: ListHistoryBindingProps,
) {
  const {navigation, route} = props;
  const listHistoryFilters = useCallback(
    (params: RouteParams<ListHistoryFiltersRoute>) => {
      navigation.navigate('ListHistoryFilters', params);
    },
    [navigation],
  );
  const showTransaction = useCallback(
    (params: RouteParams<ShowTransactionRoute>) => {
      navigation.navigate('ShowTransaction', params);
    },
    [navigation],
  );
  return (
    <ListHistoryContainer
      params={route.params}
      listHistoryFilters={listHistoryFilters}
      showTransaction={showTransaction}
    />
  );
});
