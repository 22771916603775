import type {CurrencyCode, DecimalString} from '@ncwallet-app/core';
import {
  formatCryptoValue,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {
  DecimalInput,
  IconPosition,
  InputVariant,
  TouchableOpacity,
} from '@ncwallet-app/ui';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {TextInput, TextInputProps} from 'react-native';
import {Text, View} from 'react-native';

type SendAmountInputProps = TextInputProps & {
  max: DecimalString | undefined;
  maxRestrictedByWalletLimit: boolean;
  value: DecimalString | undefined;
  cryptoCurrency: CurrencyCode | undefined;
  showKeyboard?: boolean;
  onInputChange: (value: DecimalString | undefined) => void;
  onFocus?: () => void;
  inputRef?: React.Ref<TextInput>;
  wideStyle?: boolean;
  maxValueAvailable?: boolean;
  error?: boolean;
};

export default observer(function (props: SendAmountInputProps) {
  const {
    value,
    onFocus,
    showKeyboard,
    onInputChange,
    max,
    maxRestrictedByWalletLimit,
    cryptoCurrency,
    wideStyle,
    error,
    inputRef,
    maxValueAvailable = true,
    ...rest
  } = props;
  const strings = useStrings();
  const theme = useTheme();

  const renderActionView = useCallback(() => {
    return (
      <AmountActionView>
        {cryptoCurrency && (
          <AmountCryptoCode>{cryptoCurrency}</AmountCryptoCode>
        )}
      </AmountActionView>
    );
  }, [cryptoCurrency]);

  const handleMaxPress = useCallback(() => {
    onInputChange(max ?? '0');
  }, [max, onInputChange]);

  const maxText = maxRestrictedByWalletLimit
    ? strings['sendCrypto.paymentForm.enterMaxWithLimitRestriction']
    : strings['sendCrypto.paymentForm.enterAllAmout'];

  return (
    <Container wideStyle={wideStyle}>
      <DecimalInput
        ref={inputRef}
        fractionDigits={8}
        value={value !== '0' ? value : undefined}
        label={strings['sendCrypto.paymentForm.label']}
        placeholder={strings['sendCrypto.paymentForm.placeholder']}
        placeholderTextColor={theme.palette.textAdditional3}
        iconPosition={IconPosition.Right}
        onFocus={onFocus}
        showSoftInputOnFocus={showKeyboard}
        onChangeDecimal={onInputChange}
        Slot={renderActionView}
        variant={error ? InputVariant.Error : InputVariant.Default}
        {...rest}
      />
      <AllAmountView>
        {maxValueAvailable && (
          <TouchableContainer onPress={handleMaxPress}>
            <AllAmountTouchableText>{maxText + '  '}</AllAmountTouchableText>
          </TouchableContainer>
        )}
        {!isNil(max) && maxValueAvailable ? (
          <AmountCryptoValue>
            {formatCryptoValue(max)} {cryptoCurrency}
          </AmountCryptoValue>
        ) : null}
      </AllAmountView>
    </Container>
  );
});

const AmountActionView = variance(View)(theme => ({
  root: {
    justifyContent: 'center',
    paddingHorizontal: 18,
    backgroundColor: theme.palette.uiPrimary,
    borderLeftWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const AmountCryptoCode = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    textTransform: 'uppercase',
  },
}));

const Container = variance(View)(() => ({
  root: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  wideStyle: {
    flex: 1,
  },
}));

const AllAmountView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    marginTop: 10,
    flexWrap: 'wrap',
  },
}));

const AllAmountTouchableText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.info,
  },
}));

const TouchableContainer = variance(TouchableOpacity)(() => ({
  root: {
    marginRight: 'auto',
  },
}));

const AmountCryptoValue = variance(Text)(theme => ({
  root: {
    marginLeft: 'auto',
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional2,
  },
}));
