import {LoadingIndicatorStatus} from '@ncwallet-app/core/src/LoadingIndicator';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {ModalContainer, Spinner} from '../../atoms';

export type LoaderProps = {
  loadingStatus: LoadingIndicatorStatus;
  duration?: number;
};

export default observer(function Loader(props: LoaderProps) {
  const {loadingStatus, duration} = props;

  return loadingStatus === LoadingIndicatorStatus.Active ? (
    <ModalContainer>
      <Spinner spinDuration={duration} />
    </ModalContainer>
  ) : null;
});
