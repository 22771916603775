import type {LogContent} from './LogContent';

export default class WebSocketErrorLogContent implements LogContent {
  constructor(
    public readonly connectionId: number,
    public readonly error: unknown,
  ) {}

  get body() {
    const error = JSON.stringify(this.error, undefined, 2);
    return `#${this.connectionId} ERROR\n${error}`;
  }
}
