import type {CurrencyDescription, RateHistoryPeriod} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';
import type {SharedValue} from 'react-native-reanimated';
import {useSharedValue} from 'react-native-reanimated';
import type {Vector} from 'react-native-redash';
import {clamp, getYForX} from 'react-native-redash';

import Chart from './Chart';
import ChartHelpers from './ChartHelpers';
import {CHART_HEIGHT} from './constants';
import type {CurrencyGraphData} from './CurrencyGraphData';
import WebHoverWrap from './WebHoverWrap';

export type GraphProps = {
  currencyGraph: CurrencyGraphData;
  chartWidth: number;
  cursorTranslation: Vector<SharedValue<number>>;
  isActiveCursor: SharedValue<boolean>;
  activePeriod: RateHistoryPeriod;
  fiatCurrency: CurrencyDescription | undefined;
  Container?: View | null;
};

export default observer(function Graph(props: GraphProps) {
  const {
    currencyGraph,
    chartWidth,
    cursorTranslation,
    isActiveCursor,
    activePeriod,
    fiatCurrency,
  } = props;
  const currentGraphData = currencyGraph;
  const currentPath = currentGraphData.path;
  const showInfo = useSharedValue(false);
  const setHelperToEnd = useCallback(() => {
    showInfo.value = false;
    const startX = clamp(chartWidth - 1, 0, chartWidth);
    cursorTranslation.x.value = startX;
    cursorTranslation.y.value =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (currentPath && getYForX(currentPath, startX)) || 0;
  }, [
    chartWidth,
    currentPath,
    cursorTranslation.x,
    cursorTranslation.y,
    showInfo,
  ]);
  return (
    <RootView>
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {currentPath && (
        <Chart currentPath={currentPath} chartWidth={chartWidth} />
      )}
      <WebHoverWrap
        width={chartWidth}
        showInfo={showInfo}
        currentPath={currentPath}
        setHelperToEnd={setHelperToEnd}
        isActiveCursor={isActiveCursor}
        cursorTranslation={cursorTranslation}
        Container={props.Container}>
        <ChartHelpers
          showInfo={showInfo}
          chartWidth={chartWidth}
          currentPath={currentPath}
          isActiveCursor={isActiveCursor}
          cursorTranslation={cursorTranslation}
          currentGraphData={currentGraphData}
          activePeriod={activePeriod}
          userBaseFiat={fiatCurrency}
          setHelperToEnd={setHelperToEnd}
        />
      </WebHoverWrap>
    </RootView>
  );
});

const RootView = variance(View)(theme => ({
  root: {
    height: CHART_HEIGHT,
    backgroundColor: theme.palette.background,
    alignItems: 'center',
    position: 'relative',
  },
}));
