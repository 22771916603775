import type {LogContent} from './LogContent';

export default class FetchResponseLogContent implements LogContent {
  constructor(
    public readonly correlationId: number,
    public readonly response: Response,
  ) {}

  get body() {
    const {status, statusText} = this.response;
    return `#${this.correlationId} ${status} ${statusText}`;
  }
}
