import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import WalletLimitProgressBar from './WalletLimitProgressBar';

export type LimitInfoUnsetProps = {
  limitTitle: string;
  onAction: () => void;
};

export default observer(function LimitInfoUnset(props: LimitInfoUnsetProps) {
  const {limitTitle, onAction} = props;
  const strings = useStrings();

  return (
    <LimitInfoContainer>
      <LimitInfoSingle>
        <LimitInfoTitle>{limitTitle}</LimitInfoTitle>
        <LimitInfoCrypto>
          <LimitInfoAccruedValue>
            {strings['limitsSettingsScreen.limitUnset']}
          </LimitInfoAccruedValue>
        </LimitInfoCrypto>
        <WalletLimitProgressBar total={'0'} value={'0'} />
        <WalletLimitRemainTime />
        <SizedBox />
        <LimitAction
          onPress={onAction}
          variant={ButtonVariant.Primary}
          title={strings['currencyLimitScreen.changeButton']}
        />
      </LimitInfoSingle>
    </LimitInfoContainer>
  );
});

const LimitInfoContainer = variance(View)(theme => ({
  root: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    textAlign: 'center',
  },
}));

const WalletLimitRemainTime = variance(Text)(theme => ({
  root: {
    marginTop: 10,
    ...theme.fontByWeight('500'),
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.textAdditional3,
    textAlign: 'center',
  },
}));

const LimitInfoSingle = variance(View)(theme => ({
  root: {
    paddingHorizontal: 10,
    paddingTop: 20,
    paddingBottom: 10,
  },
  withBackground: {
    backgroundColor: theme.palette.uiPrimary,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const LimitInfoTitle = variance(Text)(theme => ({
  root: {
    marginBottom: 10,
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.textPrimary,
    textAlign: 'center',
  },
}));

const LimitInfoCrypto = variance(Text)(theme => ({
  root: {
    marginBottom: 15,
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 18,
    color: theme.palette.textAdditional2,
    textAlign: 'center',
  },
}));

const LimitInfoAccruedValue = variance(Text)(theme => ({
  root: {
    color: theme.palette.error,
  },
  success: {
    color: theme.palette.success,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));

const LimitAction = variance(Button)(() => ({
  root: {},
}));
