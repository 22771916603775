import AccountDocumentManagerImpl from '../AccountDocumentManager/AccountDocumentManagerImpl';
import {AccountDocumentRequestHelperImpl} from '../AccountDocumentManager/AccountDocumentRequestHelperImpl';
import {AccountDocumentStoreImpl} from '../AccountDocumentManager/AccountDocumentStoreImpl';
import {AccountDocumentTypeManagerImpl} from '../AccountDocumentManager/AccountDocumentTypeManagerImpl';
import {AccountLinkageHandlerImpl} from '../AccountLinkageHandler';
import {AccountStoreService} from '../AccountStore';
import {AddressHistoryRepositoryImpl} from '../AddressHistoryRepository';
import {AddressParserImpl} from '../AddressParser';
import {AddressUriHelperImpl} from '../AddressUriHelper';
import {
  AdRepositoryStateService,
  AdSuspensionRepositoryImpl,
  AdSuspensionStateService,
  AdvertHelperImpl,
  AdVisibilityStateService,
} from '../Advert';
import type {AdvertisingIdentifier} from '../AdvertisingIdentifier';
import type {Alert} from '../Alert';
import {AppearanceService} from '../Appearance';
import {AppStateHelperService} from '../AppStateHelper';
import type {AppViewport} from '../AppViewport';
import {AppWindowService, AppWindowStateService} from '../AppWindow';
import type {AuthClient, AuthHelper} from '../Auth';
import {AccountIdStoreService, AuthQueryImpl, AuthStateService} from '../Auth';
import JwtHelperImpl from '../Auth/JwtHelperImpl';
import OAuth2RestClientHelperImpl from '../Auth/Oauth2RestClientHelperImpl';
import {AuthNavigationHelperImpl} from '../AuthNavigationHelper';
import type {
  Biometrics,
  BiometricSettingsManager,
  BiometricStateHelper,
} from '../Biometrics';
import {
  BlockchainNetworksSuspensionRepositoryImpl,
  BlockchainNetworksVisibilityStateService,
} from '../BlockchainNetworks';
import BlockchainNetworksRepositoryStateService from '../BlockchainNetworks/BlockchainNetworksRepositoryStateService';
import BlockchainNetworksSuspensionStateService from '../BlockchainNetworks/BlockchainNetworksSuspensionStateService';
import type {BootSplash} from '../BootSplash';
import type {Camera} from '../Camera/Camera';
import type {PlatformSpecific} from '../Components/CommonPlatformSpecificComponents';
import {ConfigExchangeService} from '../ConfigExchange';
import {ContentServiceRestClientImpl} from '../ContentServiceRestClient';
import type {Core} from '../Core';
import {CriticalErrorDetectorService} from '../CriticalErrorDetector';
import {CriticalErrorStateService} from '../CriticalErrorState';
import {CryptoImpl} from '../Crypto';
import {
  ActiveSessionStoreImpl,
  CurrenciesRateHistoryStoreImpl,
  CurrenciesRateStoreService,
  CurrencyStoreImpl,
  LastTransactionStoreService,
  WalletsBalanceHistoryStoreImpl,
  WalletStoreService,
} from '../dataStores';
import {
  DeviceIdentificationImpl,
  DeviceIdentificationStateImpl,
} from '../DeviceIdentification';
import type {DeviceInfo} from '../DeviceInfo';
import {DeviceRestClientImpl} from '../DeviceRestClient';
import type {DeviceScreenState} from '../DeviceScreenState';
import {ErrorParserImpl} from '../ErrorParser';
import CommonErrorSummaryTranslatorImpl from '../ErrorParser/CommonErrorSummaryTranslatorImpl';
import {ErrorRepositoryImpl} from '../ErrorRepository';
import {EventLoopHelperImpl} from '../EventLoop';
import type {ExpiredTokenRecovery} from '../ExpiredTokenRecovery';
import {FileSystemFactoryImpl} from '../FileSystem';
import {FlashMessageImpl} from '../FlashMessage';
import {FreshAccessTokenStateImpl} from '../FreshAccessTokenState';
import {FreshMultiFactorTokenStateImpl} from '../FreshMultiFactorTokenState';
import type {Haptics} from '../Haptics';
import type {Http} from '../Http';
import type {InstallReferrerIdentification} from '../InstallReferrerIdentification';
import type {JsonString} from '../Json';
import {JsonImpl} from '../Json';
import type {
  JsonKeyValueMap,
  JsonKeyValueStore,
  JsonSecureKeyValueMap,
} from '../JsonKeyValueStore';
import type {ReadonlyTunnel, Request, Response, Tunnel} from '../JsonRpc';
import {
  ConnectionSpawnerService,
  ConnectionStateService,
  increment,
  IntervalRetryStrategyFactory,
  JsonRpcClientService,
  JsonRpcServerService,
  StringToJsonTunnelAdapter,
  WebSocketConnectionTunnelImpl,
  WebSocketFactoryImpl,
} from '../JsonRpc';
import {CachingJwtImpl, JwtImpl} from '../Jwt';
import {LayoutHelperStateImpl} from '../LayoutHelperState';
import LinkingOptionsProviderImpl from '../LinkingOptionsProvider/LinkingOptionsProviderImpl';
import type {LinkToEmailService} from '../LinkToEmailService';
import {LinkToTelegramImpl} from '../LinkToTelegram';
import {LoadingIndicatorImpl} from '../LoadingIndicator';
import {
  LanguageTranslationBridgeImpl,
  LegacyLocalizationImpl,
  LocalizationImpl,
  SystemLanguageProviderImpl,
  SystemLocaleProviderService,
  TranslationProviderImpl,
  UserPreferenceStateImpl,
} from '../Localization';
import type {Location, LocationSource} from '../Location';
import {
  DuplexTunnelLogBridgeService,
  FetchLogBridgeService,
  LogProviderService,
  WebSocketLogBridgeService,
} from '../Log';
import SignOutReasonLog from '../Log/SignOutReasonLog/SignOutReasonLog';
import {LogExporterImpl} from '../LogExporter';
import {LogFilterImpl} from '../LogFilter';
import type {ManualTestHelper} from '../ManualTestHelper';
import {BaseNavigationImpl} from '../Navigation';
import {
  JugglerNavigationContainer,
  LargeNavigationContainerBindingImpl,
  SmallNavigationContainerBindingImpl,
} from '../NavigationContainer';
import {NavigationContainerThemeImpl} from '../NavigationContainerTheme';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  NCWalletReverseCallScheme,
  NCWalletReverseNotificationScheme,
} from '../NCWalletServer';
import {OAuth2ConsumerService} from '../OAuth2Consumer';
import type {AppleOAuth2Provider} from '../OAuth2Provider';
import {OAuth2RestClientImpl} from '../OAuth2RestClient';
import {OnLogoutResetServiceImpl} from '../OnLogoutResetService';
import {OtpImpl} from '../Otp';
import {PermissionQueueProtectorImpl} from '../PermissionManager';
import type {QrCodeScanner} from '../QrCodeScanner';
import {Rfc4648Impl} from '../Rfc4648';
import RPCTimeoutErrorVisibilityImpl from '../RPCTimeoutErrorVisibility/RPCTimeoutErrorVisibilityImpl';
import type {SentryLog} from '../SentryLog';
import type {SessionContextProvider} from '../SessionContext/SessionContextProvider';
import type {Sharing} from '../Sharing';
import {SpecialLocationImpl} from '../SpecialLocation';
import {SplashScreenStateImpl} from '../SplashScreen';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import {TagIdentificationService} from '../TagIdentification';
import type {TelegramCredentialsProvider} from '../TelegramCredentialsProvider';
import type {TelegramMiniApp} from '../TelegramMiniApp';
import {TextEndecImpl} from '../TextEndec';
import {TimeImpl} from '../Time';
import {TimeStateImpl} from '../TimeState';
import type {TransactionReport} from '../TransactionReport';
import {TransactionReportHelperImpl} from '../TransactionReport';
import TwoFaHelperImpl from '../TwoFaHelper';
import {TwoFaSettingsStateImpl} from '../TwoFaSettingsState/TwoFaSettingsStateImpl';
import UserStatusErrorDetectorServiceImpl from '../UserStatusErrorDetector/UserStatusErrorDetectorServiceImpl';
import {WalletsStateImpl} from '../WalletsStateService';
import {WalletTransactionsRequestHelperImpl} from '../WalletTransactionsRequestHelper';
import {
  WindowDimensionsService,
  WindowDimensionsStateService,
} from '../WindowDimensions';
import type {Root} from './Root';

export default abstract class BaseRootService implements Root, Service {
  protected constructor(protected readonly _core: Core) {}

  readonly splashScreenState = new SplashScreenStateImpl();
  readonly splashScreenBinding = this.splashScreenState;
  readonly fileSystem = new FileSystemFactoryImpl(this).create();
  readonly logExporter = new LogExporterImpl(this);
  readonly time = new TimeImpl();
  readonly timeState = new TimeStateImpl();
  readonly errorRepository = new ErrorRepositoryImpl();
  readonly textEndec = new TextEndecImpl(this);
  readonly jwt = new CachingJwtImpl(new JwtImpl(this));
  readonly jwtHelper = new JwtHelperImpl(this);
  readonly eventLoopHelper = new EventLoopHelperImpl(this);
  readonly json = new JsonImpl(this);
  readonly errorParser = new ErrorParserImpl(
    this,
    new CommonErrorSummaryTranslatorImpl(this),
  );

  readonly oAuth2RestClient = new OAuth2RestClientImpl(this);
  readonly oAuth2RestClientHelper = new OAuth2RestClientHelperImpl(this);

  readonly contentServiceRestClient = new ContentServiceRestClientImpl(this);
  readonly authQuery = new AuthQueryImpl(this);
  readonly accountIdStore = new AccountIdStoreService(this);
  readonly authState = new AuthStateService(this);
  readonly freshAccessTokenState = new FreshAccessTokenStateImpl(this);
  readonly freshMultiFactorTokenState = new FreshMultiFactorTokenStateImpl(
    this,
  );
  readonly oAuth2Consumer = new OAuth2ConsumerService(this);
  readonly deviceRestClient = new DeviceRestClientImpl(this);
  private readonly _originalDeviceIdentification = new DeviceIdentificationImpl(
    this,
  );
  readonly deviceIdentificationState = new DeviceIdentificationStateImpl({
    deviceIdentification: this._originalDeviceIdentification,
  });
  readonly deviceIdentification = this.deviceIdentificationState;
  readonly accountStore = new AccountStoreService(this);
  readonly layoutHelperState = new LayoutHelperStateImpl(this);
  readonly navigationContainer: JugglerNavigationContainer =
    new JugglerNavigationContainer(this);
  readonly appStateHelper = new AppStateHelperService(this);
  readonly addressUriHelper = new AddressUriHelperImpl(this);
  readonly smallNavigationContainerBinding =
    new SmallNavigationContainerBindingImpl(this);
  readonly largeNavigationContainerBinding =
    new LargeNavigationContainerBindingImpl(this);
  readonly linkingOptionsProvider = new LinkingOptionsProviderImpl(this);
  readonly navigationContainerTheme = new NavigationContainerThemeImpl(this);
  readonly specialLocation = new SpecialLocationImpl();
  readonly navigation = new BaseNavigationImpl(this);
  readonly appWindow = new AppWindowService();
  readonly appWindowState = new AppWindowStateService(this);
  readonly windowDimensions = new WindowDimensionsService();
  readonly windowDimensionsState = new WindowDimensionsStateService(this);
  readonly languageTranslationBridge = new LanguageTranslationBridgeImpl();
  readonly systemLocaleProvider = new SystemLocaleProviderService(this);
  readonly translationProvider = new TranslationProviderImpl();
  readonly localization = new LocalizationImpl(this);
  readonly systemLanguageProvider = new SystemLanguageProviderImpl(this);
  readonly userPreferenceState = new UserPreferenceStateImpl(this);
  readonly translation = new LegacyLocalizationImpl(this);
  readonly appearance = new AppearanceService(this);
  readonly crypto = new CryptoImpl(this);
  readonly rfc4648 = new Rfc4648Impl(this);
  readonly otp = new OtpImpl(this);
  private readonly _wsFactory = new WebSocketFactoryImpl(this);
  private readonly _wsTunnel = new WebSocketConnectionTunnelImpl(
    this,
    this._wsFactory,
  );
  readonly connection = this._wsTunnel;
  readonly connectionState = new ConnectionStateService(this);
  private readonly _jsonTunnel = new StringToJsonTunnelAdapter(
    this,
    this._wsTunnel as unknown as Tunnel<JsonString, JsonString>,
  );
  private readonly _ids = increment();
  readonly ncWalletJsonRpcClient = new JsonRpcClientService<
    NCWalletCallScheme,
    NCWalletNotificationScheme
  >(
    this,
    this._jsonTunnel,
    this._ids,
    this.configuration.current.values.ncWalletJsonRpcTimeout,
  );
  readonly ncWalletJsonRpcCallRouterSource =
    this.ncWalletJsonRpcClient.settledCalls;
  readonly ncWalletJsonRpcServer = new JsonRpcServerService<
    NCWalletReverseCallScheme,
    NCWalletReverseNotificationScheme
  >(this._jsonTunnel);
  private readonly _duplexTunnelLogBridge = new DuplexTunnelLogBridgeService(
    this,
    this._jsonTunnel as unknown as ReadonlyTunnel<
      Request | Response,
      Request | Response
    >,
  );
  private readonly _retryStrategyFactory = new IntervalRetryStrategyFactory(
    this,
  );
  readonly retryStrategy = this._retryStrategyFactory.create();
  readonly retryStrategyState = this.retryStrategy;
  readonly connectionSpawner = new ConnectionSpawnerService(this);
  readonly fetchLogBridge = new FetchLogBridgeService(this);
  readonly webSocketLogBridge = new WebSocketLogBridgeService(this);
  readonly log = new LogProviderService(this);
  readonly signOutReasonLog = new SignOutReasonLog();
  readonly logFilter = new LogFilterImpl(this);
  readonly loadingIndicator = new LoadingIndicatorImpl();
  readonly walletsState = new WalletsStateImpl(this);
  readonly walletTransactionsRequestHelper =
    new WalletTransactionsRequestHelperImpl(this);
  readonly walletStore = new WalletStoreService(this);
  readonly walletsBalanceHistoryStore = new WalletsBalanceHistoryStoreImpl(
    this,
  );
  readonly currenciesRateStore = new CurrenciesRateStoreService(this);
  readonly currencyStore = new CurrencyStoreImpl(this);
  readonly currenciesRateHistoryStore = new CurrenciesRateHistoryStoreImpl(
    this,
  );
  readonly blockchainNetworksVisibilityState =
    new BlockchainNetworksVisibilityStateService(this);
  readonly blockchainNetworksRepositoryState =
    new BlockchainNetworksRepositoryStateService(this);
  readonly blockchainNetworksSuspensionState =
    new BlockchainNetworksSuspensionStateService(this);
  readonly blockchainNetworksSuspensionRepository =
    new BlockchainNetworksSuspensionRepositoryImpl(this);
  readonly addressParser = new AddressParserImpl(this);
  readonly addressHistoryRepository = new AddressHistoryRepositoryImpl(this);
  readonly flashMessage = new FlashMessageImpl();
  readonly criticalErrorState = new CriticalErrorStateService(
    this,
    this._jsonTunnel,
  );
  readonly authNavigationHelper = new AuthNavigationHelperImpl(this);
  readonly criticalErrorDetector = new CriticalErrorDetectorService(this);
  readonly userStatusErrorDetector = new UserStatusErrorDetectorServiceImpl(
    this,
    this._jsonTunnel,
  );
  readonly twoFaSettingsState = new TwoFaSettingsStateImpl(this);
  readonly lastTransactionStore = new LastTransactionStoreService(this);
  readonly twoFaHelper = new TwoFaHelperImpl(this);
  readonly tagIdentification = new TagIdentificationService(this);
  readonly activeSessionsStore = new ActiveSessionStoreImpl(this);

  readonly accountDocumentStore = new AccountDocumentStoreImpl();
  readonly accountDocumentManager = new AccountDocumentManagerImpl(
    this,
    new AccountDocumentRequestHelperImpl(this),
  );
  readonly accountDocumentTypeManager = new AccountDocumentTypeManagerImpl(
    this,
  );
  readonly advertHelper = new AdvertHelperImpl(this);
  readonly adSuspensionRepository = new AdSuspensionRepositoryImpl(this);
  readonly adSuspensionState = new AdSuspensionStateService(this);
  readonly adRepositoryState = new AdRepositoryStateService(this);
  readonly adVisibilityState = new AdVisibilityStateService(this);
  readonly accountLinkageHandler = new AccountLinkageHandlerImpl(this);
  readonly linkToTelegram = new LinkToTelegramImpl(this);
  readonly permissonQueueProtector = new PermissionQueueProtectorImpl();

  readonly onLogoutResetService = new OnLogoutResetServiceImpl(this);

  readonly configExchange = new ConfigExchangeService(this);
  readonly transactionReportHelper = new TransactionReportHelperImpl(this);
  readonly rpcTimeoutErrorVisibility = new RPCTimeoutErrorVisibilityImpl();

  get debug() {
    return this._core.debug;
  }

  get appLifecycle() {
    return this._core.appLifecycle;
  }

  get configuration() {
    return this._core.configuration;
  }

  get navigationContainerState() {
    return this._core.navigationContainerState;
  }

  subscribe() {
    return batchDisposers(
      this.accountIdStore.subscribe(),
      this.authState.subscribe(),
      this.oAuth2Consumer.subscribe(),
      this.appWindow.subscribe(),
      this.appWindowState.subscribe(),
      this.windowDimensions.subscribe(),
      this.windowDimensionsState.subscribe(),
      this.systemLocaleProvider.subscribe(),
      this.appearance.subscribe(),
      this.accountStore.subscribe(),
      this.appStateHelper.subscribe(),
      this.fetchLogBridge.subscribe(),
      this.webSocketLogBridge.subscribe(),
      this.connectionState.subscribe(),
      this.tagIdentification.subscribe(),
      this._jsonTunnel.subscribe(),
      this.ncWalletJsonRpcClient.subscribe(),
      this.ncWalletJsonRpcServer.subscribe(),
      this._duplexTunnelLogBridge.subscribe(),
      this.connectionSpawner.subscribe(),
      this.walletStore.subscribe(),
      this.currenciesRateStore.subscribe(),
      this.debugLocationDetector.subscribe(),
      this.criticalErrorState.subscribe(),
      this.criticalErrorDetector.subscribe(),
      this.userStatusErrorDetector.subscribe(),
      this.lastTransactionStore.subscribe(),
      this.adSuspensionState.subscribe(),
      this.adRepositoryState.subscribe(),
      this.adVisibilityState.subscribe(),
      this.onLogoutResetService.subscribe(),
      this.signOutReasonLog.subscribe(),
      this.blockchainNetworksVisibilityState.subscribe(),
      this.blockchainNetworksSuspensionState.subscribe(),
      this.blockchainNetworksRepositoryState.subscribe(),
      this.configExchange.subscribe(),
    );
  }

  abstract readonly installReferrerIdentification: InstallReferrerIdentification;
  abstract readonly alert: Alert;
  abstract readonly deviceInfo: DeviceInfo;
  abstract readonly http: Http;
  abstract readonly jsonKeyValueStore: JsonKeyValueStore<JsonKeyValueMap>;
  abstract readonly jsonSecureKeyValueStore: JsonKeyValueStore<JsonSecureKeyValueMap>;
  abstract readonly location: Location;
  abstract readonly locationSource: LocationSource;
  abstract readonly appleOAuth2Provider: AppleOAuth2Provider;
  abstract readonly biometrics: Biometrics;
  abstract readonly biometricSettingsManager: BiometricSettingsManager;
  abstract readonly biometricStateHelper: BiometricStateHelper;
  abstract readonly bootSplash: BootSplash;
  abstract readonly camera: Camera;
  abstract readonly platformSpecificComponents: PlatformSpecific;
  abstract readonly manualTestHelper: ManualTestHelper;
  abstract readonly haptics: Haptics;
  abstract readonly sharing: Sharing;
  abstract readonly sessionContextProvider: SessionContextProvider;
  abstract readonly advertisingIdentifier: AdvertisingIdentifier;
  abstract readonly sentryLog: SentryLog;
  abstract readonly deviceScreenState: DeviceScreenState;
  abstract readonly expiredTokenRecovery: ExpiredTokenRecovery;
  abstract readonly telegramMiniApp: TelegramMiniApp;
  abstract readonly debugLocationDetector: Service;
  abstract readonly authClient: AuthClient;
  abstract readonly authHelper: AuthHelper;
  abstract readonly telegramCredentialsProvider: TelegramCredentialsProvider;
  abstract readonly linkToEmailService: LinkToEmailService;
  abstract readonly transactionReport: TransactionReport;
  abstract readonly qrCodeScanner: QrCodeScanner;
  abstract readonly appViewport: AppViewport;
}
