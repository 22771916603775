import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import type {TotpActionType} from '@ncwallet-app/core/src/NCWalletServer/AccountsTotpActions';

const textMap: Record<TotpActionType, LocaleKeys | undefined> = {
  change_totp_settings: undefined,
  delete_totp_key: undefined,
  confirm_withdrawal: undefined,
  exchange: 'totpAction.exchange',
  login: 'totpAction.login',
  delete_account: 'totpAction.delete_account',
  set_limit: 'totpAction.set_limit',
  manage_ips: 'totpAction.manage_ips',
};

// eslint-disable-next-line import-x/prefer-default-export
export const getLocaleKeyByTotpActionType = (t: TotpActionType) => textMap[t];
