import {variance} from '@ncwallet-app/core';
import {StyleSheet, View} from 'react-native';

export const LAUNCHER_ANIMATION_DELAY = 300;
export const LAUNCHER_ANIMATION_DURATION = 600;

export const SHRINK = 0.8;
const COMPENSATION_PERCENT = (100 * (1 - 1 / SHRINK)) / 2;

export const launcherStyles = StyleSheet.create({
  content: {
    flex: 1,
  },
  logo: {
    position: 'absolute',
    top: `${COMPENSATION_PERCENT}%`,
    right: `${COMPENSATION_PERCENT}%`,
    bottom: `${COMPENSATION_PERCENT}%`,
    left: `${COMPENSATION_PERCENT}%`,
  },
});
export const LauncherRoot = variance(View)(theme => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background,
  },
}));
