import {NavigationContainerStateImpl} from '../NavigationContainer';
import type {Service} from '../structure';
import BaseCoreService from './BaseCoreService';
import type {Core} from './Core';

export default class CommonCoreService
  extends BaseCoreService
  implements Core, Service
{
  public constructor() {
    super();
  }

  readonly navigationContainerState = new NavigationContainerStateImpl();
}
