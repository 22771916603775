import {
  formatCryptoValue,
  sized,
  urlLinks,
  useDateFormatting,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {
  CurrencyCircleLogo,
  TouchableOpacity,
  TransactionIcon,
} from '@ncwallet-app/ui';
import {HelpSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {BigNumber} from 'bignumber.js';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Linking, Text, View} from 'react-native';

import {useGetTransactionStatusText} from '../../../shared/useGetTransactionStatusText';
import type {HistoryListItemData} from './HistoryListItemData';

export type HistoryListItemProps = {
  data: HistoryListItemData;
  onPress: () => void;
};

export const HistoryListItem = observer((props: HistoryListItemProps) => {
  const {formatDateTimeWithoutYear} = useDateFormatting();
  const getStatusText = useGetTransactionStatusText();
  const theme = useTheme();

  const handleHelpPress = useCallback(async () => {
    await Linking.openURL(urlLinks.transactionStatusFaq);
  }, []);
  return (
    <Container onPress={props.onPress}>
      <Inner>
        <CurrencyCircleLogo code={props.data.cryptoCode} size={40} />
        <Info>
          <Row>
            <Col>
              <Subtitle>{props.data.cryptoName}</Subtitle>
            </Col>
            <Col>
              <Subtitle
                textRight
                flex
                positive={BigNumber(props.data.cryptoValue).isPositive()}
                negative={BigNumber(props.data.cryptoValue).isNegative()}>
                {BigNumber(props.data.cryptoValue).isPositive() ? '+' : ''}
                {formatCryptoValue(
                  props.data.cryptoValue,
                  props.data.cryptoFractionDigits,
                )}{' '}
                {props.data.cryptoCode}
              </Subtitle>
            </Col>
          </Row>
          <Row>
            <Col>
              <Block>
                <TransactionIcon size={20} kind={props.data.kind} />
                <TextHistory offsetLeft>
                  {formatDateTimeWithoutYear(props.data.date)}
                </TextHistory>
              </Block>
            </Col>
            <Col>
              <TouchableOpacity onPress={handleHelpPress}>
                <Row>
                  <TextHistory textRight>
                    {getStatusText(props.data.status)}
                  </TextHistory>
                  <SizedBox />
                  <HelpIcon color={theme.palette.uiAdditional1} />
                </Row>
              </TouchableOpacity>
            </Col>
          </Row>
        </Info>
      </Inner>
      <ItemDivider />
    </Container>
  );
});

const HelpIcon = sized(HelpSvg, 17);

const Container = variance(TouchableOpacity)(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background,
  },
}));

const Info = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const Inner = variance(View)(() => ({
  root: {
    padding: 15,
    flexDirection: 'row',
    flex: 1,
  },
}));

const Row = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Col = variance(View)(() => ({
  root: {
    paddingLeft: 15,
  },
}));

const Block = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Subtitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 17,
    marginBottom: 5,
  },
  textRight: {
    textAlign: 'right',
  },
  positive: {
    color: theme.palette.success,
  },
  negative: {
    color: theme.palette.error,
  },

  flex: {
    flex: 1,
  },
}));

const TextHistory = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textPrimary,
    fontSize: 13,
    lineHeight: 16,
  },
  textRight: {
    textAlign: 'right',
  },
  offsetTop: {
    marginTop: 5,
  },
  offsetLeft: {
    marginLeft: 10,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    width: 5,
  },
}));

const ItemDivider = variance(View)(theme => ({
  root: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.uiSecondary,
  },
}));
