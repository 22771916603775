import {action, makeObservable, observable} from 'mobx';

import type {Millisecond} from '../Time';
import type {Log, LogRecord} from './Log';
import type {LogContent} from './LogContent';

export default class LogService implements Log {
  private readonly _records = observable.array<LogRecord>();
  private _id = 0;

  constructor() {
    makeObservable(this);
  }

  get records() {
    return this._records;
  }

  @action write(content: LogContent) {
    this._records.push({
      id: this._id++,
      capturedAt: new Date().getTime() as Millisecond,
      content,
    });
  }

  @action reset() {
    this._id = 0;
    this._records.clear();
  }
}
