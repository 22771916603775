/* eslint-disable @typescript-eslint/unbound-method */
import {useRoot, useStrings} from '@ncwallet-app/core';
import {
  PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE,
  PROMPT_OUTPUT_NETWORK_ROUTE,
  stateToPath,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {LazyPathImpl} from '@ncwallet-app/core/src/CommonNavigationScheme/Path';
import {CommonActions} from '@react-navigation/native';
import {last} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import PromptConfirmationToSendCryptoContainer from '../../../CommonNavigationContainers/containers/PromptConfirmationToSendCryptoContainer';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {checkPrevRoute} from '../util/checkPrevRoute';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptConfirmationToSendCryptoBindingProps =
  SmallHomeStackBindingProps<'PromptConfirmationToSendCrypto'>;

export default observer(function PromptConfirmationToSendCryptoBinding(
  props: PromptConfirmationToSendCryptoBindingProps,
) {
  const {navigation, route} = props;
  const {amount, addressTo, currency} = route.params;
  const {flashMessage, navigationContainerState, twoFaSettingsState} =
    useRoot();
  const strings = useStrings();
  const promptOtpToSendCrypto = useCallback(
    (withdrawId: string) => {
      navigation.navigate('PromptOtpToSendCrypto', {
        providerKind: twoFaSettingsState.currentTwoFaProvider,
        withdrawId,
        currency,
        amount,
        addressTo,
      });
    },
    [twoFaSettingsState, addressTo, amount, currency, navigation],
  );

  const promptTwoFaEnabling = useCallback(() => {
    const path =
      route.path === undefined ? undefined : new LazyPathImpl(route.path);
    const onTwoFaEnabled = stateToPath([
      {
        path,
        route: {
          kind: PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE,
          params: route.params,
        },
      },
    ]);
    navigation.navigate('TwoFaWelcome', {onTwoFaEnabled});
    flashMessage.showMessage({
      title: strings['send.enableTwoFABeforeSend'],
      variant: 'success', // success selected as more contrast variant than info
    });
  }, [flashMessage, navigation, route.params, route.path, strings]);

  const onAddressEdit = useCallback(() => {
    navigation.navigate('PromptOutputAddress', {
      ...route.params,
      isEditable: true,
    });
  }, [navigation, route.params]);

  const onMinerFeeEdit = useCallback(() => {
    navigation.navigate('PromptCommissionForSending', {
      ...route.params,
      isEditable: true,
    });
  }, [navigation, route.params]);

  const onAmountEdit = useCallback(() => {
    navigation.navigate('PromptAmountToSend', {
      ...route.params,
      isEditable: true,
    });
  }, [navigation, route.params]);

  const onCommentEdit = useCallback(() => {
    navigation.navigate('PromptCommentToSend', {
      ...route.params,
    });
  }, [navigation, route.params]);

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }

    if (hasRouteInHistory(navigation.getState(), 'PromptOutputAddress')) {
      navigation.navigate('PromptOutputAddress', route.params);
    } else {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            {name: 'Root'},
            {name: 'PromptOutputAddress', params: route.params},
          ],
        }),
      );
    }
  }, [navigation, route.params]);

  const onNetworkEdit = useCallback(() => {
    navigation.navigate('PromptOutputNetwork', {
      ...route.params,
      isEditable: true,
    });
  }, [navigation, route.params]);
  const cameFromChangeNetwork = useMemo(
    () =>
      checkPrevRoute(navigation.getState(), 'PromptOutputNetwork') ||
      last(navigationContainerState.latestState)?.route.kind ===
        PROMPT_OUTPUT_NETWORK_ROUTE,
    [navigation, navigationContainerState.latestState],
  );
  return (
    <PromptConfirmationToSendCryptoContainer
      setParams={navigation.setParams}
      params={route.params}
      onBack={onBack}
      cameFromChangeNetwork={cameFromChangeNetwork}
      onAmountEdit={onAmountEdit}
      onMinerFeeEdit={onMinerFeeEdit}
      onAddressEdit={onAddressEdit}
      onCommentEdit={onCommentEdit}
      onNetworkEdit={onNetworkEdit}
      promptOtpToSendCrypto={promptOtpToSendCrypto}
      promptTwoFaEnabling={promptTwoFaEnabling}
    />
  );
});
