import type {Dayjs} from 'dayjs';
import dayjs from 'dayjs';
import {useMemo} from 'react';

import type {LanguageTag} from '../Localization';
import type {AvailableLanguageCode} from '../Localization/constant';
import useRoot from '../Root/hooks/useRoot';
import type {ISODateString, Millisecond} from '../Time';

const DATE_FORMAT = 'DD MMM YYYY';
const TIME_FORMAT = 'HH:mm';
const DATE_TIME_FORMAT_WITHOUT_YEAR = 'D MMM HH:mm';
const DATE_TIME_FORMAT_WITH_YEAR = 'D MMM YYYY HH:mm';
const WEEK_DAY_FORMAT = 'dddd HH:mm';
const LOCALE_MONTH = 'MMMM';
const LOCALE_DAYS = 'ddd';
const LOCALE_SHORT_DAYS = 'dd';
const DATE_FORMAT_WITHOUT_TIMEZONE = 'YYYY-MM-DDTHH:mm';

type DateLike = Date | Dayjs | Millisecond | ISODateString;

const dateFormatter = (format: string, l: LanguageTag) => (d: DateLike) => {
  return dayjs(d)
    .add(new Date().getTimezoneOffset() * -1, 'minute')
    .locale(l)
    .format(format);
};

const getDateLocale =
  (l: LanguageTag, format: string, type: dayjs.UnitType) =>
  (number: number) => {
    return dayjs().locale(l).set(type, number).format(format);
  };

const getNowDateWithoutTimezone = () =>
  dayjs().utc().format(DATE_FORMAT_WITHOUT_TIMEZONE);

export const useDateFormatting = () => {
  const {userPreferenceState} = useRoot();
  const language = userPreferenceState.languageCode;
  return useMemo(
    () => ({
      formatDate: dateFormatter(DATE_FORMAT, language),
      formatTime: dateFormatter(TIME_FORMAT, language),
      formatWeekDay: dateFormatter(WEEK_DAY_FORMAT, language),
      formatDateTimeWithoutYear: dateFormatter(
        DATE_TIME_FORMAT_WITHOUT_YEAR,
        language,
      ),
      formatDateTimeWithYear: dateFormatter(
        DATE_TIME_FORMAT_WITH_YEAR,
        language,
      ),
      formatLocaleMonth: getDateLocale(language, LOCALE_MONTH, 'month'),
      formatLocaleDays: getDateLocale(language, LOCALE_DAYS, 'days'),
      formatLocaleShortDays: getDateLocale(language, LOCALE_SHORT_DAYS, 'days'),
    }),
    [language],
  );
};

export const isUtcDateAfterNow = (date: string | ISODateString) =>
  dayjs(getNowDateWithoutTimezone()).isBefore(
    dayjs(date).format(DATE_FORMAT_WITHOUT_TIMEZONE),
  );

export const getFormattedTimeFromNow = (
  date: string | ISODateString,
  lang: AvailableLanguageCode,
) => dayjs(date).utc(true).locale(lang).fromNow(true);
