import {useTheme, variance} from '@ncwallet-app/core';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {ArrowLeftSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef} from 'react';

import {useNavigationGetIsFocused} from '../../Navigation/hooks';
import {
  CARD,
  SubmitOtpScreen,
} from '../UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {PromptLimitOtpTelegram} from './LimitCard/PromptLimitOtpTelegram';
import {LimitCard} from './shared/LimitCard';
import {LimitCardBody} from './shared/LimitCardBody';
import {LimitCardHeader, LimitCardHeaderTitle} from './shared/LimitCardHeader';

export type TwoFaLimitCardSetCodeProps = {
  onBack: () => void;
  getError: () => string | undefined;
  headerText: string;
  titleText: string;
  submitText: string;
  onSubmit: (code: string) => void;
  resetError: () => void;
} & PromptLimitOtpTelegram;

export default observer(function TwoFaLimitCardSetCode(
  props: TwoFaLimitCardSetCodeProps,
) {
  const {
    resetError,
    getError,
    titleText,
    submitText,
    onSubmit,
    headerText,
    onBack,
  } = props;
  const theme = useTheme();
  const once = useRef(false);
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (getIsFocused()) {
      if (!once.current) {
        if (props.currentTwoFaProvider === TwoFaProviderKind.Telegram) {
          void props.onTwoFaResendCode?.();
          once.current = true;
        }
      }
    }
  }, [getIsFocused, props]);

  return (
    <LimitCard>
      <LimitCardHeader>
        <HeaderGoBack onPress={onBack}>
          <ArrowLeftSvg color={theme.palette.uiMain} />
        </HeaderGoBack>
        <LimitCardHeaderTitle>{headerText}</LimitCardHeaderTitle>
      </LimitCardHeader>
      <LimitCardBody>
        <SubmitOtpScreen
          resetError={resetError}
          getError={getError}
          titleText={titleText}
          submitText={submitText}
          onSubmit={onSubmit}
          twoFaProvider={props.currentTwoFaProvider}
          onTwoFaResendCode={props.onTwoFaResendCode}
          resendCodeInterval={props.resendCodeInterval}
          resetInterval={props.resetInterval}
          layout={CARD}
        />
      </LimitCardBody>
    </LimitCard>
  );
});

const HeaderGoBack = variance(TouchableOpacity)(() => ({
  root: {
    marginRight: 15,
  },
}));
