import type {GlobalError} from '../Error';
import type {AuthResult, OtpCode} from '../NCWalletServer';
import type {
  BaseOAuth2PreSignInParams,
  OAuth2ProviderMap,
} from '../OAuth2RestClient';
import type {BusSource, RouterSource} from '../structure';
import type {DeviceId, RefreshToken} from '../units';
import type {PinCode} from '../UserIdentity';
import type {BiometricAuthParams} from './AuthHelper';
import type {
  AUTHORIZED,
  AuthorizedResponse,
  MULTI_FACTOR,
  MultiFactorResponse,
  PRE_AUTH,
  PreAuthResponse,
  UNAUTHORIZED,
  UnauthorizedResponse,
} from './AuthQuery';

export interface AuthClient {
  readonly requests: RouterSource<AuthRequestMap>;
  readonly responses: RouterSource<AuthResponseMap>;
  readonly errors: BusSource<(_: GlobalError) => unknown>;
}

export const RESET = Symbol();
export const PRE_SIGN_IN = Symbol();
export const SIGN_IN_BY_PIN = Symbol();
export const SIGN_IN_BY_OTP = Symbol();
export const SIGN_IN_BY_BIOMETRICS = Symbol();
export const SIGN_IN_BY_REFRESH_TOKEN = Symbol();
export const COMPLETE_LINKAGE = Symbol();
export const TOUCH = Symbol();
export const SIGN_OUT = Symbol();

export type AuthRequestMap = {
  [RESET]: () => unknown;
  [PRE_SIGN_IN]: <T extends keyof OAuth2ProviderMap>(
    params: BaseOAuth2PreSignInParams<T>,
  ) => unknown;
  [SIGN_IN_BY_PIN]: (pin: PinCode) => unknown;
  [SIGN_IN_BY_OTP]: (code: OtpCode) => unknown;
  [SIGN_IN_BY_REFRESH_TOKEN]: (
    token: RefreshToken,
    deviceId: DeviceId,
  ) => unknown;
  [SIGN_IN_BY_BIOMETRICS]: (params: BiometricAuthParams) => unknown;
  [COMPLETE_LINKAGE]: (params: AuthResult) => unknown;
  [TOUCH]: () => unknown;
  [SIGN_OUT]: (local?: boolean) => unknown;
};

export type AuthResponseMap = {
  [UNAUTHORIZED]: (_: UnauthorizedResponse) => unknown;
  [PRE_AUTH]: (_: PreAuthResponse) => unknown;
  [AUTHORIZED]: (_: AuthorizedResponse) => unknown;
  [MULTI_FACTOR]: (_: MultiFactorResponse) => unknown;
};
