import type {SmallHomeStackParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallHomeStackParamList';
import type {NavigationState} from '@react-navigation/routers';

// eslint-disable-next-line import-x/prefer-default-export
export const hasRouteInHistory = (
  state: NavigationState,
  routeName: keyof SmallHomeStackParamList,
) => {
  const {routes} = state;
  return routes.some(it => it.name === routeName);
};
