import {useReducer} from 'react';

import {
  NEXT_MONTH,
  PREV_MONTH,
  SET_NEW_DAY,
} from './CalendarModalActionsConstant';

export const useCalendarState = (value?: Date) => {
  const initialSatate: State = {
    date: value ? value : new Date(),
    month: value ? value.getMonth() : new Date().getMonth(),
    year: value ? value.getFullYear() : new Date().getFullYear(),
  };

  const reducer = (states: State, action: ActionReducerType) => {
    switch (action.type) {
      case NEXT_MONTH: {
        return states.month + 1 > 11
          ? {...states, month: 0, year: states.year + 1}
          : {...states, month: states.month + 1};
      }
      case PREV_MONTH: {
        return states.month - 1 < 0
          ? {...states, month: 11, year: states.year - 1}
          : {...states, month: states.month - 1};
      }
      case SET_NEW_DAY: {
        const date =
          action.payload &&
          new Date(states.year, +action.payload.month, +action.payload.day);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        if (date && date > currentDate) {
          return {
            ...states,
            date: currentDate,
            month: currentDate.getMonth(),
            year: currentDate.getFullYear(),
          };
        }
        return date
          ? {
              ...states,
              date: date,
              month: date.getMonth(),
              year: date.getFullYear(),
            }
          : {...states};
      }
      default:
        return states;
    }
  };

  return useReducer(reducer, initialSatate);
};

export type State = {
  date: Date;
  month: number;
  year: number;
};

export type ActionReducerType = {
  type: typeof NEXT_MONTH | typeof PREV_MONTH | typeof SET_NEW_DAY;
  payload?: {day: number; month: number};
};
