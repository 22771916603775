import type {Millisecond} from '@ncwallet-app/core';
import {useStrings} from '@ncwallet-app/core';
import type {SafeAreaFlatListProps} from '@ncwallet-app/ui';
import {SafeAreaFlatList, useIsDimensions} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {EmptyHistoryList} from './EmptyHistoryList';
import {HistoryListItem} from './HistoryListItem';
import type {HistoryListItemData} from './HistoryListItemData';
import HistorySkeletonList from './HistorySkeletonList';

export type HistoryListProps<ItemT = HistoryListItemData> = Partial<
  SafeAreaFlatListProps<ItemT>
> & {
  getHistoryItems: () => ItemT[] | undefined;
  onPress: (d: ItemT) => void;
  onFiltersPress: () => void;
  getCurrencyCode: () => string | undefined;
  from?: Millisecond;
  to?: Millisecond;
};

export const HistoryList = observer((props: HistoryListProps) => {
  const strings = useStrings();
  const {getHistoryItems, onPress, ...rest} = props;
  const data = getHistoryItems();
  const isLg = useIsDimensions('lg');

  if (data && data.length === 0) {
    return (
      <EmptyHistoryList
        title={strings['emptyHistoryScreen.title']}
        description={strings['emptyHistoryScreen.description']}
      />
    );
  }

  return (
    <SafeAreaFlatList
      data={data}
      ListEmptyComponent={<HistorySkeletonList itemsCount={isLg ? 6 : 4} />}
      renderItem={({item}) => (
        <HistoryListItem
          data={item}
          onPress={() => {
            onPress(item);
          }}
        />
      )}
      keyExtractor={(item: HistoryListItemData) => item.id}
      {...rest}
    />
  );
});
