import {useStyles, useTheme, variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {View} from 'react-native';
import Animated, {
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

interface PinMaskDotProps {
  filled: boolean;
  smallDots?: boolean;
}

// eslint-disable-next-line import-x/prefer-default-export
export const PinMaskDot = observer((props: PinMaskDotProps) => {
  const animatedValue = useSharedValue(0);

  useEffect(() => {
    animatedValue.value = withSpring(props.filled ? 1 : 0);
  }, [props.filled, animatedValue]);

  const dotStyles = useStyles(theme => ({
    root: {
      width: 14,
      height: 14,
      borderRadius: 10,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          width: 18,
          height: 18,
        },
      }),
    },
    smallDots: {
      width: 14,
      height: 14,
    },
  }));

  const {palette} = useTheme();
  const animatedStyles = useAnimatedStyle(() => {
    const scale = interpolate(animatedValue.value, [0, 1], [1, 1.25]);

    const backgroundColor = interpolateColor(
      animatedValue.value,
      [0, 1],
      ['#8d8d8d', palette.primary],
    );
    return {
      backgroundColor: backgroundColor,
      transform: [{scaleX: scale}, {scaleY: scale}],
    };
  }, [animatedValue, palette]);

  return (
    <DotContainer>
      <Animated.View
        style={
          props.smallDots
            ? [dotStyles.root, animatedStyles, dotStyles.smallDots]
            : [dotStyles.root, animatedStyles]
        }
      />
    </DotContainer>
  );
});

const DotContainer = variance(View)(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    width: 20,
  },
}));
