import {variance} from '@ncwallet-app/core';
import {View} from 'react-native';

// eslint-disable-next-line import-x/prefer-default-export
export const LimitCardBody = variance(View)(() => ({
  root: {
    flex: 1,
    padding: 20,
  },
}));
