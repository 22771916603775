import {useStrings} from '@ncwallet-app/core';
import type {
  ListInputAddressesRoute,
  RouteTransition,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import probe from '@ncwallet-app/core/src/util/probe';
import {last} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useAddressNetworkSelectionScreenProps} from '../../../../../CommonNavigationContainers/hooks/useAddressNetworkSelectionScreenProps';
import {useCreateDefaultAddress} from '../../../../../Navigation/hooks/useCreateDefaultAddress';
import {useGetDefaultInAddress} from '../../../../../Navigation/hooks/useGetDefaultInAddress';
import NetworkSelectionScreen from '../../../../../screens/NetworkSelectionScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptReceiveNetworkBindingProps =
  LargeSwitchBindingProps<'PromptReceiveNetwork'>;

export default observer(function PromptReceiveNetworkBinding(
  props: PromptReceiveNetworkBindingProps,
) {
  const {navigation, route} = props;
  const {walletId} = route.params;
  const onBackPress = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.navigate('ListInputAddresses', route.params);
  }, [navigation, route.params]);
  const getDefaultAddress = useGetDefaultInAddress(walletId);
  const createDefaultAddress = useCreateDefaultAddress();
  const listInputAddresses = useCallback<
    RouteTransition<ListInputAddressesRoute>
  >(
    async params => {
      const {routes, history} = navigation.getState();
      const name =
        probe(routes, routes.length - 2)?.name ?? last(history)?.name;
      if (name === 'ShowQrToReceiveCrypto') {
        const defaultAddress = getDefaultAddress(params.networkFilter);
        let createdDefaultAddress;
        if (!defaultAddress) {
          createdDefaultAddress = await createDefaultAddress(params);
        }
        navigation.navigate('ShowQrToReceiveCrypto', {
          ...params,
          address: createdDefaultAddress || defaultAddress || params.address,
          addressCurrency: params.currencyFilter,
          addressNetwork: params.networkFilter,
        });
      } else {
        navigation.navigate('ListInputAddresses', params);
      }
    },
    [createDefaultAddress, getDefaultAddress, navigation],
  );
  const {networks, network, onSelect, currency} =
    useAddressNetworkSelectionScreenProps({
      params: route.params,
      goBack: onBackPress,
      listInputAddresses: listInputAddresses,
    });

  const strings = useStrings();
  return (
    <NetworkSelectionScreen
      goBack={onBackPress}
      network={network}
      currency={currency}
      networks={networks}
      title={strings['receiveCryptoScreen.networkBottomSheet.title']}
      onSelect={onSelect}
    />
  );
});
