import {useRoot, useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {
  CloseAppDarkSvg,
  CloseAppSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored/';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type PromptSignOutScreenProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export default observer(function PromptSignOutScreen(
  props: PromptSignOutScreenProps,
) {
  const {onCancel, onConfirm} = props;
  const {appearance, layoutHelperState} = useRoot();
  const strings = useStrings();
  const {bottom} = useSafeAreaInsets();
  const isLarge = layoutHelperState.isLarge;

  const containerStyle = useMemo(() => {
    return isLarge ? {} : {paddingBottom: bottom};
  }, [bottom, isLarge]);

  return (
    <Container style={containerStyle}>
      <BodyWrapper>
        {appearance.isDark ? <CloseAppDarkSvg /> : <CloseAppSvg />}
        <Title>{strings['closeAppScreen.title']}</Title>
      </BodyWrapper>
      <ButtonWrapper>
        <Button
          title={strings.yes}
          variant={ButtonVariant.Primary}
          onPress={onConfirm}
        />
        <Button
          title={strings.no}
          variant={ButtonVariant.Danger}
          onPress={onCancel}
        />
      </ButtonWrapper>
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
  },
}));
const BodyWrapper = variance(View)(() => ({
  root: {
    gap: 20,
    paddingVertical: 20,
    alignItems: 'center',
  },
}));
export const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 26,
    lineHeight: 36,
    color: theme.palette.textPrimary,
  },
}));
const ButtonWrapper = variance(View)(() => ({
  root: {
    gap: 15,
    paddingVertical: 20,
  },
}));
