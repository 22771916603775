import {isNil, isNumber} from 'lodash';

import type {Millisecond} from './units';

export default function parseMillisecond(
  val: string | number | undefined,
): Millisecond | undefined {
  if (isNil(val)) {
    return undefined;
  }

  const parsed = isNumber(val) ? val : Number.parseInt(val, 10);
  if (isFinite(parsed) && parsed >= 0) {
    return parsed as Millisecond;
  } else {
    return undefined;
  }
}
