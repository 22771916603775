import type {
  CryptoCurrencyCode,
  DecimalString,
  JsonRpcClient,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  WalletId,
} from '@ncwallet-app/core';
import {COMMON_ERROR_TO_LOCALE, CommonErrorCode} from '@ncwallet-app/core';
import type {
  ErrorDetails,
  ErrorParser,
} from '@ncwallet-app/core/src/ErrorParser';
import type {Localization} from '@ncwallet-app/core/src/Localization';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

export default class ExchangeCryptoConfirmBindingState {
  @observable errorDetails: ErrorDetails | undefined;
  @observable isPending = false;

  @computed get isRateChangedError() {
    return (
      this.errorDetails?.code === CommonErrorCode.ExchangeTokenExpired ||
      this.errorDetails?.code === CommonErrorCode.ExchangeRateChanged ||
      this.errorDetails?.code === CommonErrorCode.ExchangeRateNotFound
    );
  }

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly errorParser: ErrorParser;
      readonly localization: Localization;
    },
  ) {
    makeObservable(this);
  }

  async exchange(token: string) {
    runInAction(() => {
      this.isPending = true;
    });

    const res = await this._root.ncWalletJsonRpcClient.call(
      'wallets.transactions.exchange',
      {token},
    );

    runInAction(() => {
      this.isPending = false;

      if (!res.success) {
        this.errorDetails = this._root.errorParser.describe(res.left);
      }
    });

    return res;
  }

  @action.bound
  async refresh(
    walletIdFrom: WalletId,
    currencyFrom: CryptoCurrencyCode,
    currencyTo: CryptoCurrencyCode,
    valueFrom: DecimalString,
    valueTo: DecimalString,
    isTargetValueLastChanged: boolean,
    walletIdTo?: WalletId,
  ) {
    if (this.isPending) {
      return;
    }
    const baseParams = {
      from_wallet_id: walletIdFrom,
      to_wallet_id: walletIdTo,
      from_currency: currencyFrom,
      to_currency: currencyTo,
    };

    const res = await this._root.ncWalletJsonRpcClient.call(
      'wallets.transactions.exchange_token.create',
      isTargetValueLastChanged
        ? {...baseParams, to_amount: valueTo}
        : {...baseParams, from_amount: valueFrom},
    );
    runInAction(() => {
      this.isPending = false;
      this.errorDetails = res.success
        ? undefined
        : this._root.errorParser.describe(res.left);
    });
    return res;
  }

  @action.bound
  handleOutsideError(
    errorCode:
      | CommonErrorCode.ExchangeRateChanged
      | CommonErrorCode.ExchangeRateNotFound
      | CommonErrorCode.ExchangeTokenExpired
      | CommonErrorCode.AccountSuspended,
  ) {
    this.errorDetails = {
      code: errorCode,
      summary: this._root.localization.getTranslation(
        COMMON_ERROR_TO_LOCALE[errorCode],
      ),
    };
  }
}
