import type {AvailableLanguageCode} from '@ncwallet-app/core/src/Localization/constant';
import {
  DeFlagSvg,
  EnFlagSvg,
  EsFlagSvg,
  FrFlagSvg,
  ItFlagSvg,
  PtBRFlagSvg,
  RuFlagSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';

import type {ListItem} from './LanguageSettingsListItem';

// eslint-disable-next-line import-x/prefer-default-export
export const LANGUAGES: Record<AvailableLanguageCode, ListItem> = {
  en: {
    Icon: EnFlagSvg,
    text: 'English',
  },
  fr: {
    Icon: FrFlagSvg,
    text: 'French',
  },
  de: {
    Icon: DeFlagSvg,
    text: 'German',
  },
  it: {
    Icon: ItFlagSvg,
    text: 'Italian',
  },
  ru: {
    Icon: RuFlagSvg,
    text: 'Russian',
  },
  es: {
    Icon: EsFlagSvg,
    text: 'Spanish',
  },
  pt: {
    Icon: PtBRFlagSvg,
    text: 'Portuguese (Brazil)',
  },
};
