import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, runInAction} from 'mobx';
import {useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../hooks';
import AffiliateProgramBindingState from './AffiliateProgramBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const useAffiliateProgramBindingState = () => {
  const root = useRoot();
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  const [state] = useState(() => new AffiliateProgramBindingState(root));

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            // noinspection JSIgnoredPromiseFromCall
            state.init();
          });
        }
      }),
    [root, getIsFocused, getIsReady, state],
  );

  return state;
};
