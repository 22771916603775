import {Platform} from 'react-native';

import type {ErrorRepository} from '../ErrorRepository';
import AndroidFileSystemImpl from './AndroidFileSystemImpl';
import type {FileSystem} from './FileSystem';
import IosFileSystemImpl from './IosFileSystemImpl';
import WebFileSystemImpl from './WebFileSystemImpl';

export default class FileSystemFactoryImpl {
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
    },
  ) {}

  create() {
    return Platform.select<FileSystem>({
      ios: new IosFileSystemImpl(this._root),
      android: new AndroidFileSystemImpl(this._root),
      default: new WebFileSystemImpl(this._root),
    });
  }
}
