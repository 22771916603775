import type {
  ListInputAddressesRoute,
  RouteTransition,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useAddressNetworkSelectionScreenProps} from '../../../CommonNavigationContainers/hooks/useAddressNetworkSelectionScreenProps';
import {useCreateDefaultAddress} from '../../../Navigation/hooks/useCreateDefaultAddress';
import {useGetDefaultInAddress} from '../../../Navigation/hooks/useGetDefaultInAddress';
import ReceiveCryptoAddressNetworkBottomSheet from '../../../screens/ReceiveCryptoScreen/ReceiveCryptoAddressNetworkBottomSheet';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptReceiveNetworkBindingProps =
  SmallHomeStackBindingProps<'PromptReceiveNetwork'>;

export default observer(function PromptReceiveNetworkBinding(
  props: PromptReceiveNetworkBindingProps,
) {
  const {navigation, route} = props;
  const {walletId} = route.params;
  const getDefaultAddress = useGetDefaultInAddress(walletId);
  const createDefaultAddress = useCreateDefaultAddress();
  const onBackPress = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.navigate('ListInputAddresses', route.params);
  }, [navigation, route.params]);
  const listInputAddresses: RouteTransition<ListInputAddressesRoute> =
    useCallback(
      async params => {
        if (
          navigation.getState().routes[navigation.getState().routes.length - 2]
            .name === 'ShowQrToReceiveCrypto'
        ) {
          const defaultAddress = getDefaultAddress(params.networkFilter);
          let createdDefaultAddress;
          if (!defaultAddress) {
            createdDefaultAddress = await createDefaultAddress(params);
          }
          navigation.navigate('ShowQrToReceiveCrypto', {
            ...params,
            address: createdDefaultAddress || defaultAddress || params.address,
            addressCurrency: params.currencyFilter,
            addressNetwork: params.networkFilter,
          });
        } else {
          navigation.navigate('ListInputAddresses', params);
        }
      },
      [createDefaultAddress, getDefaultAddress, navigation],
    );
  const screenProps = useAddressNetworkSelectionScreenProps({
    params: route.params,
    goBack: onBackPress,
    listInputAddresses: listInputAddresses,
  });

  return (
    <ReceiveCryptoAddressNetworkBottomSheet
      {...screenProps}
      onClosePress={onBackPress}
    />
  );
});
