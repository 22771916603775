import {variance} from '@ncwallet-app/core';
import {Button, LG_BREAKPOINT, TouchableOpacity} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {Image, Text, View} from 'react-native';

export const ModalBody = variance(View)(theme => ({
  root: {
    borderRadius: 6,
    backgroundColor: theme.palette.background,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 640,
        ...theme.bar(11),
      },
    }),
  },
}));

export const ModalClose = variance(TouchableOpacity)(
  theme => ({
    root: {},
    container: {
      marginLeft: 'auto',
      width: 22,
      height: 22,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          marginLeft: 0,
          position: 'absolute',
          top: 15,
          right: 15,
          zIndex: 1,
          borderRadius: 50,
          width: 32,
          height: 32,
          backgroundColor: theme.palette.uiSecondary,
          color: theme.palette.textPrimary,
        },
      }),
    },
  }),
  (theme, styles) => ({
    containerStyle: styles.container,
  }),
);

export const CloseIcon = variance(CrossSvg)(theme => ({
  root: {
    color: theme.palette.uiMain,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        padding: 5,
        color: theme.palette.whitey,
      },
    }),
  },
}));

export const ModalCenter = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 20,
    flexDirection: 'column',
    alignItems: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 40,
        paddingVertical: 40,
      },
    }),
  },
}));

export const CenterTitle = variance(Text)(theme => ({
  root: {
    textAlign: 'center',
    marginBottom: 5,
    ...theme.fontByWeight('700'),
    fontSize: 26,
    color: theme.palette.textPrimary,
  },
}));

export const CenterDescription = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    color: theme.palette.textAdditional1,
    textAlign: 'center',
  },
}));

export const CenterAppIcon = variance(View)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 74,
    height: 74,
    marginBottom: 20,
  },
}));

export const Icon = variance(Image)(() => ({
  root: {
    width: 100,
    height: 100,
  },
}));

export const ModalBottom = variance(View)(theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginTop: 40,
      },
    }),
  },
}));

export const BottomButton = variance(Button)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 150,
      },
    }),
  },
}));
