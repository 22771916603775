import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {variance} from '../../styling';

export type LogButtonProps = {
  saveLog?: () => void;
  copyLog?: () => void;
};

const LogButton = observer(({saveLog}: LogButtonProps) => {
  if (saveLog) {
    return (
      <StyledButton
        variant={ButtonVariant.ThemeButton}
        title="SAVE LOG"
        onPress={saveLog}
      />
    );
  } else {
    return null;
  }
});

export default LogButton;

const StyledButton = variance(Button)(() => ({
  root: {
    marginBottom: 10,
  },
}));
