import type {WalletListItemData} from '@ncwallet-app/core';
import {useRoot, variance} from '@ncwallet-app/core';
import UserStateErrorLg from '@ncwallet-app/ui/src/components/organisms/UserStateError/UserStateErrorLg';
import type {NavigationProp, ParamListBase} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';

import WideLayoutBackground from '../../../../../components/WideLayoutBackground';
import {useFetchWalletNews} from './WalletLayout/useFetchWalletNews';
import {useFetchWalletTips} from './WalletLayout/useFetchWalletTips';
import WideWalletLayout from './WalletLayout/WideWalletLayout';

export type MainLayoutProps = {
  children: React.ReactNode;
  onCurrencyAdd: () => void;
  onWalletSelect: (_: WalletListItemData) => void;
  onLastTransactionPress: () => void;
  Header: React.ReactNode;
  Modals: React.ReactNode;
  navigation: NavigationProp<ParamListBase>;
};

export default observer(function MainLayout(props: MainLayoutProps) {
  const {
    children,
    onCurrencyAdd,
    onWalletSelect,
    onLastTransactionPress,
    Modals,
    Header,
    navigation,
  } = props;
  const {
    userPreferenceState,
    contentServiceRestClient,
    userStatusErrorDetector,
  } = useRoot();
  const [news] = useFetchWalletNews(
    userPreferenceState.languageCode,
    contentServiceRestClient,
  );

  const tips = useFetchWalletTips(userPreferenceState.languageCode);

  return (
    <Container>
      <WideLayoutBackground style={styles.background}>
        <Container>
          <Container>
            <WideWalletLayout
              visibleLgComponents
              withAside
              onCurrencyAdd={onCurrencyAdd}
              onWalletSelect={onWalletSelect}
              onLastTransactionPress={onLastTransactionPress}
              news={news}
              tips={tips}>
              {children}
            </WideWalletLayout>
            {userStatusErrorDetector.userStatusError && (
              <UserStateErrorLg navigation={navigation} />
            )}
          </Container>
          {Modals}
        </Container>
        {Header}
      </WideLayoutBackground>
    </Container>
  );
});

const styles = StyleSheet.create({
  background: {
    flexDirection: 'column-reverse',
  },
});

const Container = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));
