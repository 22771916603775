import type {
  JsonRpcClient,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '@ncwallet-app/core';
import {isValidEmail} from '@ncwallet-app/core';
import {unwrap} from '@ncwallet-app/core/src/EitherAdapter';
import {action, makeObservable, observable, runInAction} from 'mobx';

export default class FeedbackModalBindingState {
  @observable email: string = '';
  @observable message: string = '';
  @observable rating: number | undefined;
  @observable emailErrorShown: boolean = false;

  constructor(
    private readonly rpcClient: JsonRpcClient<
      NCWalletCallScheme,
      NCWalletNotificationScheme
    >,
  ) {
    makeObservable(this);
  }

  readonly setRating = action((rating: number) => {
    this.rating = rating;
  });

  readonly setEmail = action((email: string) => {
    this.email = email;
    this.emailErrorShown = false;
  });

  readonly setMessage = action((message: string) => {
    this.message = message;
  });

  async submit() {
    const rating = this.rating;
    if (rating === undefined) {
      throw new Error('No rating provided');
    }
    const trimmedEmail = this.email.trim();
    if (!isValidEmail(trimmedEmail)) {
      runInAction(() => {
        this.emailErrorShown = true;
      });
      throw new Error('No email provided');
    }
    await unwrap(
      this.rpcClient.call('rating.create', {
        rating,
        message: this.message.trim(),
        email: trimmedEmail,
      }),
    );
  }
}
