import {createContext} from 'react';

import {WindowDimensionsStatic} from '../../WindowDimensions';
import lightPalette from './lightPalette';
import type {Theme} from './Theme';
import ThemeImpl from './ThemeImpl';

export default createContext<Theme>(
  new ThemeImpl(
    lightPalette,
    WindowDimensionsStatic.getInitialDimensions().window,
    WindowDimensionsStatic.getInitialDimensions().screen,
  ),
);
