import {FiatCircleLogo, useIsDimensions} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {
  ItemRowTag,
  ItemTag,
} from '../../BaseCryptoSettingScreen/LgCryptoSectionList/useLgCryptoSectionListStyle';
import {TitleWithLabel} from '../../SharedComponents/SettingsItem';

export type LgFiatItemItemProps = {
  fiatCode: string;
  active?: boolean;
  title: string;
  onPress?: () => void;
};

export default observer(function LgFiatItem(props: LgFiatItemItemProps) {
  const isLg = useIsDimensions('lg');
  const iconSize = isLg ? 22 : 40;

  return (
    <ItemTag onPress={props.onPress} second active={props.active}>
      <FiatCircleLogo
        active={!!props.active}
        code={props.fiatCode}
        size={iconSize}
      />
      <ItemRowTag icon>
        <TitleWithLabel noMargin>{props.title}</TitleWithLabel>
      </ItemRowTag>
    </ItemTag>
  );
});
