import type {InOutCurrency} from '@ncwallet-app/core';
import type {
  ListInputAddressesRoute,
  PromptReceiveNetworkRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransitionMap} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransitionMap';
import {useCallback} from 'react';

import {useAddressNetworkSelectionBindingState} from '../../Navigation/HomeStack/AddressNetworkTypeSelectionBinding/useAddressNetworkSelectionBindingState';

export type useAddressNetworkSelectionScreenPropsProps = {
  params: RouteParams<PromptReceiveNetworkRoute>;
  goBack: () => void;
} & RouteTransitionMap<ListInputAddressesRoute>;

export const useAddressNetworkSelectionScreenProps = (
  props: useAddressNetworkSelectionScreenPropsProps,
) => {
  const {params, listInputAddresses} = props;
  const {walletId, networkFilter, currencyFilter} = params;
  const state = useAddressNetworkSelectionBindingState(walletId, networkFilter);
  const onSelect = useCallback(
    (t: InOutCurrency) => {
      listInputAddresses({
        ...params,
        networkFilter: t.network,
        currencyFilter: t.currency,
      });
    },
    [listInputAddresses, params],
  );
  return {
    network: networkFilter,
    currency: currencyFilter,
    networks: state.networks,
    onSelect,
  };
};
