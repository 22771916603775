import {sized} from '@ncwallet-app/core';
import {TouchableWithoutFeedback} from '@ncwallet-app/ui';
import {CircledTrashSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import DefaultEnvironmentItem from './DefaultEnvironmentItem';

export interface CustomEnvironmentItemProps {
  selected: boolean;
  url: string;
  onPress: () => void;
  onDeletePress: () => void;
}

export default observer((props: CustomEnvironmentItemProps) => {
  const {onDeletePress, ...rest} = props;
  const right = useMemo(
    () => (
      <TouchableWithoutFeedback onPress={onDeletePress}>
        <SizedDelete />
      </TouchableWithoutFeedback>
    ),
    [onDeletePress],
  );
  return <DefaultEnvironmentItem right={right} {...rest} />;
});

const SizedDelete = sized(CircledTrashSvg, 24);
