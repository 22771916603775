import {useStrings} from '@ncwallet-app/core';
import type {
  BaseSafeAreaProps,
  CurrencySelectionListItemData,
} from '@ncwallet-app/ui';
import {
  CommonRefreshControl,
  CurrenciesListState,
  CurrencySelectionHeader,
  CurrencySelectionList,
  CurrencySelectionViewContainer,
  CurrencySkeletonList,
  FilteredToEmptyCurrencySelectionList,
  getCurrenciesListState,
  SelectionCryptoTopMessage,
  WalletActionKind,
  WalletActionList,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {StyleSheet} from 'react-native';
import {isTablet} from 'react-native-device-info';

import EmptyWalletList from '../../shared/EmptyWalletList';
import CurrencyListItem from '../CurrencyScreen/CurrencyListItem';
import {ScreenType} from './ScreenType';

export type SelectionForExchangeScreenProps = BaseSafeAreaProps & {
  isLg: boolean;
  screenType?: ScreenType;
  search: string | undefined;
  onSearchChange: (search: string) => void;
  shownCurrencies: CurrencySelectionListItemData[] | undefined;
  allCurrencies: CurrencySelectionListItemData[] | undefined;
  onCurrencyPress: (currency: CurrencySelectionListItemData) => void;
  getIsRefreshing: () => boolean;
  onRefresh: () => void;
  onReceivePress: () => void;
  onBack?: () => void;
  onAddPress: () => void;
};

export default observer(function SelectionForExchangeScreen(
  props: SelectionForExchangeScreenProps,
) {
  const {
    isLg,
    onReceivePress,
    screenType = ScreenType.Default,
    getIsRefreshing,
    onRefresh,
    search,
    onSearchChange,
    onAddPress,
    onBack,
    allCurrencies,
    shownCurrencies,
    ...rest
  } = props;

  const listState = getCurrenciesListState(allCurrencies, shownCurrencies);
  const strings = useStrings();
  const refreshControl = useMemo(
    () => (
      <CommonRefreshControl
        getIsRefreshing={getIsRefreshing}
        onRefresh={onRefresh}
      />
    ),
    [getIsRefreshing, onRefresh],
  );

  return (
    <CurrencySelectionViewContainer>
      <CurrencySelectionHeader
        withSecondaryBackground={screenType === ScreenType.Modal}
        search={search}
        onSearchChange={onSearchChange}
        title={
          listState === CurrenciesListState.Empty
            ? strings['screenTitle.change']
            : strings.backToChooseCoin
        }
        hideSearch={isTablet() || listState === CurrenciesListState.Empty}
        onBackPress={onBack}
      />

      {listState === CurrenciesListState.Loading && (
        <CurrencySkeletonList itemCount={4} />
      )}

      {listState === CurrenciesListState.Empty &&
        (isLg ? (
          <NoCurrenciesView
            onReceivePress={onReceivePress}
            onAddPress={onAddPress}
          />
        ) : (
          <EmptyWalletList
            title={strings['selectionForExchange.empty.title']}
            description={strings['selectionForExchange.empty.text']}
            onAddPress={onAddPress}
            onReceivePress={onReceivePress}
          />
        ))}

      {listState === CurrenciesListState.Normal && (
        <CurrencySelectionList
          currencies={shownCurrencies}
          refreshControl={refreshControl}
          {...rest}
        />
      )}

      {listState === CurrenciesListState.FilteredToEmpty && (
        <FilteredToEmptyCurrencySelectionList
          refreshControl={refreshControl}
          {...props}
        />
      )}
    </CurrencySelectionViewContainer>
  );
});

type NoCurrenciesViewProps = {
  onReceivePress: () => void;
  onAddPress: () => void;
};

export const NoCurrenciesView = (props: NoCurrenciesViewProps) => {
  return (
    <>
      <SelectionCryptoTopMessage
        texts={{
          title: 'selectionForExchange.empty.title',
          message: 'selectionForExchange.empty.text',
        }}
      />
      <WalletActionList contentContainerStyle={styles.container}>
        <CurrencyListItem
          type={WalletActionKind.Receive}
          onPress={props.onReceivePress}
          dataSet={{app: 'currency-item'}}
        />
        <CurrencyListItem
          type={WalletActionKind.Add}
          onPress={props.onAddPress}
          dataSet={{app: 'currency-item'}}
        />
      </WalletActionList>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 15,
  },
});
