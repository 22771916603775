import type {GlobalError} from '../Error';
import {UNKNOWN_ERROR} from '../Error';
import type {Failure} from '../fp';
import {failure, success} from '../fp';
import type {Maybe} from '../Maybe';
import isPromise from '../util/isPromise';

function wrap<T>(f: () => Promise<T>): Promise<Maybe<T>>;
function wrap<T>(f: () => T): Maybe<T>;
function wrap<T>(f: () => Promise<T> | T): Promise<Maybe<T>> | Maybe<T> {
  try {
    const value = f();
    if (isPromise(value)) {
      return value.then(_ => success(_)).catch(failureByRaw);
    }
    return success(value);
  } catch (raw) {
    return failureByRaw(raw);
  }
}

export default wrap;

function failureByRaw(_: unknown): Failure<GlobalError> {
  return failure({kind: UNKNOWN_ERROR, raw: _, description: String(_)});
}
