import {comparer, computed, makeObservable, reaction} from 'mobx';

import type {AppStateHelper} from '../AppStateHelper';
import type {ShallowCommonState} from '../CommonNavigationScheme';
import type {ReadonlyNavigationContainerState} from '../NavigationContainer';
import type {Service} from '../structure';
import type {Millisecond} from '../Time';
import {last} from '../util';
import getCurrentRouteKind from '../util/getCurrentRouteKind';
import {routesWithBlockchainNetworksWarning} from '../util/routesWithBlockchainNetworksWarning';
import type {
  BlockchainNetworkModalState,
  CommonBlockchainNetworkState,
} from './BlockchainNetworksVisibilityState';

export default class BlockchainNetworkModalStateService
  implements BlockchainNetworkModalState, Service
{
  constructor(
    protected readonly _common: CommonBlockchainNetworkState,
    protected readonly _root: {
      readonly navigationContainerState: ReadonlyNavigationContainerState;
      readonly appStateHelper: AppStateHelper;
    },
  ) {
    makeObservable(this);
  }

  @computed get networkToDisplay() {
    const walletNetwork = this._getNetworkFromRoute;

    if (walletNetwork) {
      return this._common.getNetworkToDisplay(walletNetwork);
    }
  }

  async hideNetwork(now: Millisecond) {
    const walletNetwork = this._getNetworkFromRoute;
    if (walletNetwork) {
      return this._common.hideNetwork(walletNetwork, now);
    }
  }

  @computed protected get _getNetworkFromRoute() {
    const network = extractAddressNetwork(
      this._root.navigationContainerState.latestState,
    );

    if (network) {
      return network;
    }
  }

  @computed protected get _isOnSpot() {
    const {appStateHelper, navigationContainerState} = this._root;
    return (
      appStateHelper.isReadyToShowUserInterface &&
      isAllowedBlockchainScreen(navigationContainerState.latestState)
    );
  }

  subscribe() {
    return reaction(this._computeChanges, this._processChanges, {
      equals: comparer.shallow,
    });
  }

  private readonly _computeChanges = (): TriggerChanges => {
    const isInSpot = this._isOnSpot;

    return [
      isInSpot,
      isInSpot &&
        getCurrentRouteKind(this._root.navigationContainerState.latestState),
    ];
  };

  private readonly _processChanges = async (
    current: TriggerChanges,
    previous: TriggerChanges,
  ) => {
    if (current.length === 0 || previous.length === 0) {
      return;
    }
    const [isOnSpot, trigger] = current;
    const [, _trigger] = previous;
    const triggered = isOnSpot && !Object.is(trigger, _trigger);

    const network = this._getNetworkFromRoute;
    if (!network) {
      return [];
    }
    const pending = this._common.getPendingNetwork(network);
    if (!pending) {
      return [];
    }
    if (triggered) {
      await this._common.displayNetwork(pending);
    }
  };
}

export function isAllowedBlockchainScreen(_?: ShallowCommonState) {
  return !!_ && routesWithBlockchainNetworksWarning.has(getCurrentRouteKind(_));
}

const extractAddressNetwork = (_?: ShallowCommonState) => {
  const route = _ && last(_);

  if (
    route &&
    routesWithBlockchainNetworksWarning.has(route.route.kind) &&
    'params' in route.route &&
    route.route.params &&
    'addressNetwork' in route.route.params
  ) {
    return route.route.params.addressNetwork;
  }
};

export type TriggerChanges = [] | [isOnSpot: boolean, trigger: unknown];
