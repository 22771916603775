import {PREFIXES} from '../LinkingOptionsProvider/constant';
import type {Url} from '../units';

export default function splitByPrefix(
  locator: Url,
): [prefix: Url, rest: string] | undefined {
  for (const prefix of PREFIXES) {
    if (locator.startsWith(prefix)) {
      return [prefix, locator.substring(prefix.length)];
    }
  }
  return undefined;
}
