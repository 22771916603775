import type {DecimalString} from '@ncwallet-app/core';
import {keepMeaningfulDigitsInFiat, variance} from '@ncwallet-app/core';
import {BigNumber} from 'bignumber.js';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text} from 'react-native';

export type PriceDiffProps = {
  firstPrice: DecimalString;
  diff: DecimalString;
  large?: boolean;
  offsetTop?: boolean;
  bold?: boolean;
  medium?: boolean;
};

export default observer(function PriceDiff(props: PriceDiffProps) {
  const {firstPrice, diff, large, offsetTop, bold, medium} = props;
  const formattedPercent = BigNumber(diff).multipliedBy(100).abs().toFixed(2);
  const rawPriceDiff = BigNumber(firstPrice)
    .multipliedBy(diff)
    .abs()
    .toString();
  const formattedDiff = keepMeaningfulDigitsInFiat(rawPriceDiff);
  const isFiatApproximately = +formattedDiff === 0;
  const isPercentApproximately = +formattedPercent === 0;
  const isIncrease = BigNumber(diff).isLessThan(0);
  const fiatMark = isFiatApproximately ? '~' : isIncrease ? '+' : '-';
  const percentMark = isPercentApproximately ? '~' : isIncrease ? '+' : '-';

  const formattedInfo = `${fiatMark}${formattedDiff} (${
    formattedDiff !== formattedPercent ? percentMark : ''
  }${formattedPercent}%)`;

  return (
    <ChangeInfoText
      isIncrease={isIncrease}
      default={isFiatApproximately && isPercentApproximately}
      large={large}
      offsetTop={offsetTop}
      bold={bold}
      medium={medium}>
      {formattedInfo}
    </ChangeInfoText>
  );
});

const ChangeInfoText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight(),
    fontSize: 11,
    color: theme.palette.error,
  },
  medium: {
    ...theme.fontByWeight('500'),
    fontSize: 12,
    lineHeight: 15,
  },
  isIncrease: {
    color: theme.palette.success,
  },
  default: {
    color: theme.palette.textMain,
  },
  large: {
    ...theme.fontByWeight('500'),
    fontSize: 16,
    lineHeight: 19,
  },
  offsetTop: {
    marginTop: 5,
  },
  bold: {
    ...theme.fontByWeight('500'),
    lineHeight: 15,
  },
}));
