import {useStrings, WalletLimitPeriod} from '@ncwallet-app/core';

// eslint-disable-next-line import-x/prefer-default-export
export const useMapLimitPeriodToLabelText = () => {
  const strings = useStrings();

  return new Map([
    [WalletLimitPeriod.Daily, strings['limitsSettingsScreen.limitDaily']],
    [WalletLimitPeriod.Monthly, strings['limitsSettingsScreen.limitMonthly']],
  ]);
};
