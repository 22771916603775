import type {LogContent} from './LogContent';

export default class FetchRequestLogContent implements LogContent {
  constructor(
    public readonly correlationId: number,
    public readonly input: RequestInfo,
    public readonly init?: RequestInit,
  ) {}

  get body() {
    const method = this.init?.method ?? 'GET';
    const url = typeof this.input === 'string' ? this.input : this.input.url;
    const body = typeof this.init?.body === 'string' ? this.init.body : '';
    return `#${this.correlationId} ${method} ${url}${body ? '\n' : ''}${body}`;
  }
}
