import type {NavigationState, PartialState} from '@react-navigation/routers';

import type {
  CommonRoute,
  DistinctPartialRoute,
  ListActiveSessionsRoute,
  NameToKindMap,
  PromptSessionDeletionRoute,
  ShallowCommonRouteMeta,
  ShallowCommonState,
  ShowQrToReceiveCryptoRoute,
} from '../CommonNavigationScheme';
import {
  FALL_BACK_ROUTE,
  LIST_ACTIVE_SESSIONS_ROUTE,
  nameToKindMap,
  PROMPT_EMAIL_ROUTE,
  PROMPT_NAME_AND_BIRTHDATE_ROUTE,
  PROMPT_RESIDENTIAL_ADDRESS_ROUTE,
  PROMPT_SESSION_DELETION,
  SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE,
} from '../CommonNavigationScheme';
import {LazyPathImpl} from '../CommonNavigationScheme/Path';
import type {SmallBottomTabParamList} from './SmallHomeStack/SmallBottomTab/SmallBottomTabParamList';
import type {SmallHomeStackParamList} from './SmallHomeStack/SmallHomeStackParamList';

export default function smallToCommon(
  small: PartialState<NavigationState<SmallHomeStackParamList>>,
): ShallowCommonState {
  const smallRoutes =
    small.routes as DistinctPartialRoute<SmallHomeStackParamList>[];
  if (smallRoutes.length === 0) {
    return [{route: {kind: FALL_BACK_ROUTE}}];
  }
  return smallRoutes.flatMap(translateHomeStackRoute) as ShallowCommonState;
}

const translateHomeStackRoute = (
  _: DistinctPartialRoute<SmallHomeStackParamList>,
): ShallowCommonRouteMeta[] => {
  if (_.name === 'Root') {
    const state = _.state as
      | PartialState<NavigationState<SmallBottomTabParamList>>
      | undefined;
    const current = state?.routes[state.index ?? 0] as
      | DistinctPartialRoute<SmallBottomTabParamList>
      | undefined;
    return current ? [translateBottomTabRoute(current)] : [];
  }
  const path = _.path === undefined ? undefined : new LazyPathImpl(_.path);
  const kind = nameToKindMap[_.name];
  switch (kind) {
    case PROMPT_NAME_AND_BIRTHDATE_ROUTE:
    case PROMPT_RESIDENTIAL_ADDRESS_ROUTE:
    case PROMPT_EMAIL_ROUTE:
      return [translateRoute(_, 'state')];
    case LIST_ACTIVE_SESSIONS_ROUTE: {
      const stackRoute = _ as DistinctPartialRoute<
        Pick<SmallHomeStackParamList, 'ListActiveSessions'>
      >;
      const {promptConfirmationToEndSession} = stackRoute.params ?? {};
      const route: ListActiveSessionsRoute = {kind: LIST_ACTIVE_SESSIONS_ROUTE};
      const state: ShallowCommonRouteMeta[] = [{route, path}];
      if (promptConfirmationToEndSession) {
        const promptRoute: PromptSessionDeletionRoute = {
          kind: PROMPT_SESSION_DELETION,
          params: promptConfirmationToEndSession,
        };
        state.push({route: promptRoute, path});
      }
      return state;
    }
    case PROMPT_SESSION_DELETION: {
      const stackRoute = _ as DistinctPartialRoute<
        Pick<SmallHomeStackParamList, 'PromptSessionDeletion'>
      >;
      const route: PromptSessionDeletionRoute = {
        kind: PROMPT_SESSION_DELETION,
        params: stackRoute.params,
      };
      return [{route, path}];
    }
    case SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE: {
      const stackRoute = _ as DistinctPartialRoute<
        Pick<SmallHomeStackParamList, 'ShowQrToReceiveCrypto'>
      >;
      const route: ShowQrToReceiveCryptoRoute = {
        kind: SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE,
        params: stackRoute.params,
      };
      return [{route, path}];
    }
  }
  return [translateRoute(_)];
};

const translateBottomTabRoute = (
  _: DistinctPartialRoute<SmallBottomTabParamList>,
): ShallowCommonRouteMeta => {
  switch (_.name) {
    case 'ListWallets':
    case 'Debug':
      return translateRoute(_);
    case 'PromptCryptoToReceive':
    case 'PromptCryptoToSend':
    case 'PromptSourceCryptoToExchange':
    case 'ListHistory':
      return translateRoute(_, 'params');
  }
};

function translateRoute(
  route: {
    name: keyof NameToKindMap;
    path?: string;
    params?: object | undefined;
  },
  argumentsKey: 'state' | 'params' = 'params',
): ShallowCommonRouteMeta {
  const kind = nameToKindMap[route.name];
  const path =
    route.path === undefined ? route.path : new LazyPathImpl(route.path);
  const common = {kind} as CommonRoute;
  if (route.params) {
    Reflect.set(common, argumentsKey, route.params);
  }
  return {route: common, path};
}
