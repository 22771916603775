import type {Uri, Url} from '@ncwallet-app/core';
import {useTheme, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonIconPosition,
  ButtonVariant,
  LG_BREAKPOINT,
  Pressable,
} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {ImageStyle} from 'react-native';
import {Image, Text, View} from 'react-native';

export type AffiliateAdvertBodyProps = {
  image: Uri;
  title: string;
  body: string;
  actions?: AdvertAction[];
  onActionPress: (link?: string) => void;
  onClose: () => void;
};

export type AdvertAction = {
  link?: Url;
  title: string;
  icon?: Uri;
};

export default observer(function AffiliateAdvertBody(
  props: AffiliateAdvertBodyProps,
) {
  const {title, body, actions, onActionPress, onClose, image} = props;
  const theme = useTheme();
  return (
    <Container>
      <Cross onPress={onClose}>
        <CrossSvg color={theme.palette.whitey} />
      </Cross>
      <Img resizeMode="cover" resizeMethod="resize" source={{uri: image}} />
      <Body>
        <Title>{title}</Title>
        <Paragraph>{body}</Paragraph>
        <BtnContainer>
          {actions?.map((_, i, array) => (
            <Btn
              key={_.link}
              title={_.title}
              onPress={() => {
                onActionPress(_.link);
              }}
              variant={
                i === array.length - 1
                  ? ButtonVariant.Primary
                  : ButtonVariant.PrimaryLight
              }
              iconPosition={ButtonIconPosition.Right}
              Icon={_.icon ? () => <Image source={{uri: _.icon}} /> : undefined}
            />
          ))}
        </BtnContainer>
      </Body>
    </Container>
  );
});

const Cross = variance(Pressable)(theme => ({
  root: {
    position: 'absolute',
    top: 15,
    backgroundColor: theme.chroma(theme.palette.blackout).alpha(0.2).hex(),
    right: 15,
    zIndex: 15,
    elevation: 15,
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 32,
  },
}));

const Img = variance(Image)(theme => ({
  root: {
    height: 246,
    width: '100%',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    ...(theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    }) as ImageStyle),
  },
}));

const Body = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 30,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingVertical: 20,
        paddingHorizontal: 40,
      },
    }),
  },
}));

const Container = variance(View)(theme => ({
  root: {
    flex: 1,
    position: 'relative',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: undefined,
        backgroundColor: theme.palette.background,
        borderRadius: 6,
        overflow: 'hidden',
        maxWidth: 620,
        width: '100%',
      },
    }),
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 26,
    lineHeight: 36,
    textAlign: 'center',
    color: theme.palette.textMain,
    marginBottom: 10,
  },
}));

const Paragraph = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'center',
    color: theme.palette.textAdditional1,
    marginBottom: 40,
  },
}));

const Btn = variance(Button)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 1,
      },
    }),
  },
}));

const BtnContainer = variance(View)(theme => ({
  root: {
    gap: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        width: '100%',
      },
    }),
  },
}));
