import {useStrings} from '@ncwallet-app/core';
import {useMemo} from 'react';

const useGetPermissionTextsByKind = () => {
  const strings = useStrings();
  return useMemo(() => {
    return {
      camera: {
        title:
          strings['deleteIdentityUploadedDocument.permissions.camera.title'],
        text: strings['deleteIdentityUploadedDocument.permissions.camera.text'],
      },
      gallery: {
        title:
          strings['deleteIdentityUploadedDocument.permissions.gallery.title'],
        text: strings[
          'deleteIdentityUploadedDocument.permissions.gallery.text'
        ],
      },
    };
  }, [strings]);
};

// eslint-disable-next-line import-x/prefer-default-export
export const useGetPermissionTexts = (permissionKind: 'camera' | 'gallery') => {
  const permissionTexts = useGetPermissionTextsByKind();

  return useMemo(() => {
    return permissionTexts[permissionKind];
  }, [permissionTexts, permissionKind]);
};
