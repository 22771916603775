import {variance} from '@ncwallet-app/core';
import type {TabRowBaseItem} from '@ncwallet-app/ui';
import {LG_BREAKPOINT, TabRow} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {View} from 'react-native';

import {ReceiveCryptoTabKey} from './ReceiveCryptoTabKey';

type CurrencyReceiveTabKeyProps = {
  activeTabKey: ReceiveCryptoTabKey;
  onTabPress: (tabKey: ReceiveCryptoTabKey) => void;
  style?: StyleProp<ViewStyle>;
};

// eslint-disable-next-line import-x/prefer-default-export
export const Tabs = observer((props: CurrencyReceiveTabKeyProps) => {
  const tabs: TabRowBaseItem<ReceiveCryptoTabKey>[] = useMemo(
    () => [
      {
        key: ReceiveCryptoTabKey.Receive,
        text: 'depositScreen.tab.receive',
      },
      {
        key: ReceiveCryptoTabKey.Buy,
        text: 'receiveCryptoScreen.fiat.button',
      },
    ],
    [],
  );
  return (
    <TabContainer>
      <TabRow
        items={tabs}
        onPress={tab => {
          props.onTabPress(tab.key);
        }}
        idKey={tab => tab.key}
        style={props.style}
        active={tab => tab.key === props.activeTabKey}
        textKey={tab => tab.text}
      />
    </TabContainer>
  );
});

const TabContainer = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
        maxWidth: 345,
      },
    }),
  },
}));
