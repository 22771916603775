import {sized, useStrings, useTheme, variance} from '@ncwallet-app/core';
import type {SafeAreaScrollViewProps} from '@ncwallet-app/ui';
import {Input, SendCryptoModal} from '@ncwallet-app/ui';
import {HelpSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useRef} from 'react';
import type {TextInput} from 'react-native';
import {Pressable, View} from 'react-native';

import {useFocusInputOnNavigationFocus} from '../Navigation/hooks';

export type PromptCommentToSendScreenProps = {
  comment: string;
  onCommentChange: (s: string) => void;
  onSubmit: () => void;
  goToFAQ: () => void;
} & SafeAreaScrollViewProps;

export default observer(function PromptCommentToSendScreen(
  props: PromptCommentToSendScreenProps,
) {
  const strings = useStrings();
  const {comment, onCommentChange, onSubmit, goToFAQ, ...rest} = props;
  const inputRef = useRef<TextInput>(null);
  const theme = useTheme();
  useFocusInputOnNavigationFocus(inputRef);
  const Label = () => {
    return (
      <>
        {strings['receiveCryptoScreen.commentFormLabel']}
        <Pressable onPress={goToFAQ}>
          <HelpIcon
            style={{marginLeft: 5}}
            color={theme.palette.uiAdditional1}
          />
        </Pressable>
      </>
    );
  };
  return (
    <SendCryptoModal isEditable={true} onSubmit={onSubmit} {...rest}>
      <Container>
        <Input
          ref={inputRef}
          label={<Label />}
          placeholder={strings['receiveCryptoScreen.commentFormPlaceholder']}
          value={comment}
          onChangeText={onCommentChange}
        />
      </Container>
    </SendCryptoModal>
  );
});

const Container = variance(View)(() => ({
  root: {
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  wideStyle: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    flex: 1,
  },
}));
const HelpIcon = sized(HelpSvg, 17);
