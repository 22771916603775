import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import {TransactionFilterKind} from '@ncwallet-app/core';
import {useMemo} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export function useGetHistoryFilterKindStrings(): Record<
  TransactionFilterKind,
  LocaleKeys
> {
  return useMemo(
    () => ({
      [TransactionFilterKind.All]: 'historyOperationFilterScreen.operation.all',
      [TransactionFilterKind.Incoming]:
        'historyOperationFilterScreen.operation.deposit',
      [TransactionFilterKind.Withdraw]:
        'historyOperationFilterScreen.operation.widthrawal',
      [TransactionFilterKind.To]:
        'historyOperationFilterScreen.operation.incoming',
      [TransactionFilterKind.From]:
        'historyOperationFilterScreen.operation.outgoing',
      [TransactionFilterKind.Exchange]:
        'historyOperationFilterScreen.operation.exchange',
      [TransactionFilterKind.Rollback]:
        'historyOperationFilterScreen.operation.rollback',
      [TransactionFilterKind.ReferralIncoming]:
        'historyOperationFilterScreen.operation.incoming',
      [TransactionFilterKind.PromoIncoming]:
        'historyOperationFilterScreen.operation.incoming',
    }),
    [],
  );
}
