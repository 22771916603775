import {useRoot, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React, {useMemo} from 'react';
import {Platform, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

type HeaderDropdownProps = {
  isVisible: boolean;
};

export default observer(function HeaderDropdown(
  props: PropsWithChildren<HeaderDropdownProps>,
) {
  const {top, bottom} = useSafeAreaInsets();
  const {windowDimensionsState} = useRoot();
  const {height, width} = windowDimensionsState.window;

  const containerStyle = useMemo(
    () => ({
      maxHeight: height - top - bottom - 100,
      right: (width - 1210) / 2,
      ...Platform.select({
        web: {
          right: 30,
        },
      }),
    }),
    [bottom, height, top, width],
  );

  const {children, isVisible} = props;
  return isVisible ? (
    <HeaderDropdownContainer style={containerStyle}>
      {children}
    </HeaderDropdownContainer>
  ) : null;
});

const HeaderDropdownContainer = variance(View)(theme => ({
  root: {
    position: 'absolute',
    width: 375,
    paddingTop: 20,
    borderRadius: 10,
    flex: 1,
    marginTop: -5,
    borderTopRightRadius: 0,
    backgroundColor: theme.palette.uiPrimary,
  },
}));
