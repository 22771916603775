import {
  PROMPT_LIMIT_REMOVAL_ROUTE,
  PROMPT_LIMIT_UPDATE_ROUTE,
  PROMPT_NEW_LIMIT_ROUTE,
  PROMPT_OTP_TO_LIMIT_CREATE_ROUTE,
  PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE,
  PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE,
  SHOW_LIMIT_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {SelectedLimitRoute} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {observer} from 'mobx-react-lite';
import React from 'react';

import type {PromptLimitUpdateCardProps} from './CreateLimitForm/PromptLimitUpdateCard';
import PromptLimitUpdateCard from './CreateLimitForm/PromptLimitUpdateCard';
import FaqLimitCard from './FaqLimitCard';
import type {PromptLimitOtpCardProps} from './PromptLimitOtpCard';
import PromptLimitOtpCard from './PromptLimitOtpCard';
import type {PromptLimitRemovalCardProps} from './PromptLimitRemovalCard';
import PromptLimitRemovalCard from './PromptLimitRemovalCard';
import type {PromptNewLimitCardProps} from './PromptNewLimitCard';
import PromptNewLimitCard from './PromptNewLimitCard';
import type {ShowLimitCardProps} from './ShowLimitCard/ShowLimitCard';
import ShowLimitCard from './ShowLimitCard/ShowLimitCard';

export type LimitCardProps = {
  selectedLimitRouteKind: SelectedLimitRoute['kind'] | undefined;
  showLimitCardProps: ShowLimitCardProps;
  promptLimitUpdateCardProps: PromptLimitUpdateCardProps;
  promptNewLimitCardProps: PromptNewLimitCardProps;
  promptLimitRemovalCardProps: PromptLimitRemovalCardProps;
  promptOtpToLimitCreateCardProps: PromptLimitOtpCardProps;
  promptOtpToLimitUpdateCardProps: PromptLimitOtpCardProps;
  promptOtpToLimitRemovalCardProps: PromptLimitOtpCardProps;
};

export default observer(function LimitCard(props: LimitCardProps) {
  const routeKind = props.selectedLimitRouteKind;
  if (!routeKind) {
    return <FaqLimitCard />;
  }

  switch (routeKind) {
    case PROMPT_NEW_LIMIT_ROUTE:
      return <PromptNewLimitCard {...props.promptNewLimitCardProps} />;
    case SHOW_LIMIT_ROUTE:
      return <ShowLimitCard {...props.showLimitCardProps} />;
    case PROMPT_LIMIT_UPDATE_ROUTE:
      return <PromptLimitUpdateCard {...props.promptLimitUpdateCardProps} />;
    case PROMPT_LIMIT_REMOVAL_ROUTE:
      return <PromptLimitRemovalCard {...props.promptLimitRemovalCardProps} />;
    case PROMPT_OTP_TO_LIMIT_CREATE_ROUTE:
      return <PromptLimitOtpCard {...props.promptOtpToLimitCreateCardProps} />;
    case PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE:
      return <PromptLimitOtpCard {...props.promptOtpToLimitUpdateCardProps} />;
    case PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE:
      return <PromptLimitOtpCard {...props.promptOtpToLimitRemovalCardProps} />;
  }
});
