import {useStrings, variance} from '@ncwallet-app/core';
import type {
  AccountAddressDataWrapper,
  AccountPersonalDataWrapper,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {
  AccountDataStatus,
  getAddress,
  getFullName,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {useIsSuspendedOrBanned} from '@ncwallet-app/core/src/UserStatusErrorDetector';
import {SafeAreaScrollView} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {VerificationStatusText} from '../ProfileSettingsScreen/LgProfileCards/Verification/VerificationStatusText';
import {SettingsGroupTitle} from '../UserSettingsScreens/SharedComponents/SettingsGroupTitle';
import IdentityVerificationItem from './IdentityVerificationItem';
import SuspendedOrBannedWarning from './SuspendedOrBannedWarning';

export type IdentityVerificationListProps = {
  isVerificationRequired: boolean;
  personalData: AccountPersonalDataWrapper | undefined;
  addressData: AccountAddressDataWrapper | undefined;
  onUsernamePress: () => void;
  onBirthDatePress: () => void;
  onAddressPress: () => void;
  onUploadPress: () => void;
};

export default observer(function IdentityVerificationList(
  props: IdentityVerificationListProps,
) {
  const {
    isVerificationRequired,
    personalData,
    addressData,
    onUsernamePress,
    onBirthDatePress,
    onAddressPress,
    onUploadPress,
  } = props;
  const strings = useStrings();
  const isPersonalDataOnReview =
    personalData?.status === AccountDataStatus.OnReview;
  const isAddressDataOnReview =
    addressData?.status === AccountDataStatus.OnReview;
  const isSuspendedOrBanned = useIsSuspendedOrBanned();

  return (
    <SafeAreaScrollView>
      <SuspendedOrBannedWarning />
      <SettingsGroupTitleRow
        title={strings['screenTitle.identityInfo']}
        Tag={
          isVerificationRequired ? (
            <VerificationStatusText status={personalData?.status} />
          ) : null
        }
        first={true}
      />
      <IdentityVerificationItem
        title={personalData ? getFullName(personalData.data) : '-'}
        label={strings['profileSettingsScreen.verificationLabel']}
        onPress={onUsernamePress}
        disabled={isPersonalDataOnReview}
      />
      <IdentityVerificationItem
        title={personalData?.data.birthday ?? '-'}
        label={
          strings[
            'profileSettingsScreen.verification.personInfo.birthDateLabel'
          ]
        }
        onPress={onBirthDatePress}
        disabled={isPersonalDataOnReview}
      />
      {!isSuspendedOrBanned && (
        <>
          <IdentityAddressListTitle
            title={strings['profileSettingsScreen.verification.address.title']}
            Tag={
              isVerificationRequired ? (
                <VerificationStatusText status={addressData?.status} />
              ) : null
            }
          />
          <IdentityVerificationItem
            title={addressData ? getAddress(addressData.data) : '-'}
            label={strings['profileSettingsScreen.verification.address.title']}
            onPress={onAddressPress}
            disabled={isAddressDataOnReview}
          />
          <IdentityAddressListTitle
            title={
              strings['profileSettingsScreen.verification.documents.title']
            }
          />
          <IdentityVerificationItem
            title={
              strings['profileSettingsScreen.verification.document.upload']
            }
            onPress={onUploadPress}
          />
        </>
      )}
    </SafeAreaScrollView>
  );
});

const SettingsGroupTitleRow = variance(SettingsGroupTitle)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const IdentityAddressListTitle = variance(SettingsGroupTitleRow)(() => ({
  root: {
    marginTop: 20,
  },
}));
