import type {Localization} from '../Localization';
import type {
  AmountTooSmallError,
  CommonError,
  EstimatedFeeChangedError,
} from '../NCWalletServer';
import {COMMON_ERROR_TO_LOCALE, CommonErrorCode} from '../NCWalletServer';
import type {CommonErrorSummaryTranslator} from './CommonErrorSummaryTranslator';

export default class CommonErrorSummaryTranslatorImpl
  implements CommonErrorSummaryTranslator
{
  constructor(private readonly _root: {readonly localization: Localization}) {}

  translate(e: CommonError) {
    const code = e.code;
    if (code === CommonErrorCode.AmountTooSmall) {
      return this._translateAmountTooSmall(e);
    } else if (code === CommonErrorCode.EstimatedFeeChanged) {
      return this._translateEstimatedFeeChanged(e);
    } else {
      return this._root.localization.getTranslation(
        COMMON_ERROR_TO_LOCALE[code],
      );
    }
  }

  _translateAmountTooSmall(e: AmountTooSmallError): string {
    return this._root.localization.executeTemplate('errors.amountTooSmall', {
      amount: e.data.min_from_amount || e.data.min_to_amount,
    });
  }

  _translateEstimatedFeeChanged(e: EstimatedFeeChangedError): string {
    return this._root.localization.executeTemplate(
      'errors.estimatedFeeChanged',
      {
        newFee: e.data.new_fee,
        oldFee: e.data.old_fee,
      },
    );
  }
}
