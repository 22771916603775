import type {Tagged} from '../../Opaque';

export enum AccountDataStatus {
  Created = 'created',
  OnReview = 'on_review',
  Verified = 'verified',
  Failed = 'failed',
  Deleted = 'deleted',
}

export enum AccountDataType {
  Personal = 'personal',
  Address = 'address',
}

export type AccountPersonalData = {
  first_name: string;
  last_name: string;
  birthday?: string;
};

export type AccountAddressData = {
  country?: string;
  city?: string;
  street?: string;
  address?: string;
  index?: string;
};

export type AccountPersonalDataWrapper = {
  id: AccountDataId;
  status: AccountDataStatus;
  type: AccountDataType.Personal;
  data: AccountPersonalData;
};

export type AccountAddressDataWrapper = {
  id: AccountDataId;
  status: AccountDataStatus;
  type: AccountDataType.Address;
  data: AccountAddressData;
};

export type AccountDataWrapper =
  | AccountPersonalDataWrapper
  | AccountAddressDataWrapper;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCOUNT_DATA_ID = Symbol();
export type AccountDataId = Tagged<string, typeof ACCOUNT_DATA_ID>;

export const getFullName = (data: AccountPersonalData) =>
  `${data.first_name} ${data.last_name}`;

export const getAddress = (data: AccountAddressData) =>
  [data.country, data.city, data.street, data.index]
    .filter(value => !!value)
    .join(', ');

export const dataCanBeReviewed = (d: AccountDataWrapper) =>
  d.status === AccountDataStatus.Created ||
  d.status === AccountDataStatus.Failed;

export const dataCanBeEdited = dataCanBeReviewed;
