import type {CameraComponent} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';

export type CameraLayerProps = {
  cameraRef: React.RefObject<CameraComponent>;
};

export default observer(function CameraLayer(props: CameraLayerProps) {
  const {cameraRef} = props;
  const {
    camera: {DocCameraComponent},
  } = useRoot();
  return (
    <View style={styles.root}>
      {DocCameraComponent && <DocCameraComponent ref={cameraRef} />}
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
