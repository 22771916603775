/* eslint-disable @typescript-eslint/unbound-method */
import {urlLinks, useRoot} from '@ncwallet-app/core';
import {autorun} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';
import {Linking} from 'react-native';

import {PinScreen} from '../../../screens/PinScreen';
import {useCheckPinTexts} from '../../../screens/PinScreen/PinScreenTexts';
import {useNavigationGetIsFocused} from '../../hooks';
import {CheckPinBindingState} from './CheckPinBindingState';

export default observer(function CheckPinBinding() {
  const root = useRoot();

  const [state] = useState(() => new CheckPinBindingState(root));
  const getIsFocused = useNavigationGetIsFocused();
  useEffect(
    () =>
      autorun(() => {
        if (getIsFocused() && state.canSignInWithBiometrics) {
          void state.activateBiometrics();
        }
      }),
    [getIsFocused, state],
  );

  useEffect(() => {
    void state.refresh();
    state.clearBiometryIfKeysNotExist();
  }, [state]);

  useEffect(() => () => {
    state.onUnmount();
  });

  const onErrorActionPress = useCallback(() => {
    void Linking.openURL(urlLinks.supportPin);
  }, []);

  const texts = useCheckPinTexts();

  return (
    <PinScreen
      appVersion={root.deviceInfo.getAppVersionWithBuildNumber()}
      texts={texts}
      getPin={state.getPinCode}
      setPin={state.setPinCode}
      getErrorText={state.errorText}
      onCancel={state.signOut}
      onBiometryPress={
        state.canSignInWithBiometrics ? state.activateBiometrics : undefined
      }
      onErrorActionPress={onErrorActionPress}
    />
  );
});
