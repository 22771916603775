import type {Wallet} from '@ncwallet-app/core';
import {isEnoughMoneyForSendOrExchange, useRoot} from '@ncwallet-app/core';
import {useEffect} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useGoToCurrencyScreenIfNotMoney = (wallet: Wallet | undefined) => {
  const {navigationContainer} = useRoot();
  useEffect(() => {
    if (wallet && !isEnoughMoneyForSendOrExchange(wallet)) {
      navigationContainer.ref?.navigate('ShowWallet', {
        walletId: wallet.id,
        currencyCode: wallet.currency,
      });
    }
  }, [navigationContainer, wallet]);
};
