import {openPlatformSettings, useStrings, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
  SafeAreaInset,
  SafeAreaLayout,
} from '@ncwallet-app/ui';
import {LockSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';

import {EXTRA_BOTTOM_OFFSET} from '../constants';
import {Description, Title} from './LabelLayer';

export type NeedAccessProps = {
  requestPermission: () => void;
  canAsk: boolean | undefined;
};

export default observer(function NeedAccess({
  requestPermission,
  canAsk,
}: NeedAccessProps) {
  const strings = useStrings();
  const title = canAsk
    ? strings['qrCodeScreen.canAskTitle']
    : strings['qrCodeScreen.cantAskTitle'];
  const description = canAsk
    ? strings['qrCodeScreen.canAskDesc']
    : strings['qrCodeScreen.cantAskDesc'];
  const buttonTitle = canAsk
    ? strings['qrCodeScreen.canAskButton']
    : strings['qrCodeScreen.cantAskButton'];
  const onSubmitPress = useCallback(() => {
    if (canAsk) {
      requestPermission();
    } else {
      openPlatformSettings();
    }
  }, [canAsk, requestPermission]);
  return (
    <Root insets={SafeAreaInset.BOTTOM} extra={{bottom: EXTRA_BOTTOM_OFFSET}}>
      <Content>
        <BlockIcon>
          <LockIcon />
        </BlockIcon>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Content>
      <Footer>
        <AllowButton
          title={buttonTitle}
          variant={ButtonVariant.Highlighted}
          color={ButtonColor.Secondary}
          onPress={onSubmitPress}
        />
      </Footer>
    </Root>
  );
});

const Root = variance(SafeAreaLayout)(theme => ({
  root: {
    flex: 1,
    paddingHorizontal: 15,
    backgroundColor: theme.chroma(theme.palette.blackout).alpha(0.6).hex(),
  },
}));

const Content = variance(View)(() => ({
  root: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 30,
  },
}));

const BlockIcon = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 80,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
    borderRadius: 999,
    backgroundColor: theme.palette.primary,
    color: theme.palette.whitey,
  },
}));

const LockIcon = variance(LockSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    width: 35,
    height: 45,
    color: theme.palette.whitey,
  }),
);

const Footer = variance(View)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const AllowButton = variance(Button)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        minWidth: 345,
      },
    }),
  },
}));
