import {useTheme, variance} from '@ncwallet-app/core';
import {TouchableWithoutFeedback} from '@ncwallet-app/ui';
import {BlurView} from 'expo-blur';
import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';

export type OverlayProps = PropsWithChildren<{
  onClose?: () => void;
}>;

export default observer(function Overlay(props: OverlayProps) {
  const {onClose, children} = props;
  const theme = useTheme();
  return (
    <View style={styles.root}>
      <TouchableWithoutFeedback
        onPress={onClose}
        style={styles.touchableOverlay}
        containerStyle={styles.touchableOverlay}>
        <BlurView
          tint={theme.select('light', 'dark')}
          intensity={15}
          style={styles.decoration}>
          <BlackOverlay style={styles.decoration} />
        </BlurView>
      </TouchableWithoutFeedback>
      <WebContainer>{children}</WebContainer>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
  },
  touchableOverlay: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
  },
  decoration: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
});

const BlackOverlay = variance(View)(theme => ({
  root: {
    backgroundColor: theme.chroma('1E1F21').alpha(0.2).hex(),
  },
}));

const WebContainer = ({children}: {children: React.ReactNode}) => {
  if (Platform.OS === 'web') {
    return <WebWrap>{children}</WebWrap>;
  }
  return <>{children}</>;
};

const WebWrap = variance(View)(() => ({
  root: {
    maxWidth: 1240,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
