import {variance} from '@ncwallet-app/core';
import type {TabRowBaseItem} from '@ncwallet-app/ui';
import {TabRow} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {View} from 'react-native';

import {SelectionForReceiveTabKey} from './SelectionForReceiveTabKey';

type CurrencyReceiveTabKeyProps = {
  activeTabKey: SelectionForReceiveTabKey;
  onTabPress: (tabKey: SelectionForReceiveTabKey) => void;
  wideStyle?: boolean;
};

// eslint-disable-next-line import-x/prefer-default-export
export const Tabs = observer((props: CurrencyReceiveTabKeyProps) => {
  const tabs: TabRowBaseItem<SelectionForReceiveTabKey>[] = useMemo(
    () => [
      {
        key: SelectionForReceiveTabKey.OnWallet,
        text: 'selectionForReceiveScreen.tab.onWallet',
      },
      {
        key: SelectionForReceiveTabKey.All,
        text: 'selectionForReceiveScreen.tab.all',
      },
    ],
    [],
  );

  return (
    <TabContainer wideStyle={props.wideStyle}>
      <TabRow
        items={tabs}
        textKey={tab => tab.text}
        active={tab => tab.key === props.activeTabKey}
        onPress={tab => {
          props.onTabPress(tab.key);
        }}
        idKey={tab => tab.key}
      />
    </TabContainer>
  );
});

const TabContainer = variance(View)(() => ({
  root: {
    padding: 15,
  },
  wideStyle: {
    padding: 0,
    maxWidth: 345,
    width: '100%',
  },
}));
