import {BottomSheet} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import type {AffiliateAdvertBodyProps} from './AffiliateAdvertBody';
import AffiliateAdvertBody from './AffiliateAdvertBody';

export type AffiliateAdvertScreenProps = AffiliateAdvertBodyProps;

export default observer(function AffiliateAdvertScreen(
  props: AffiliateAdvertScreenProps,
) {
  const {onClose, ...rest} = props;
  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <BottomSheet
      index={0}
      onChange={onChange}
      animateOnMount
      snapPoints={[620]}
      enablePanDownToClose
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      onClose={onClose}
      children={<AffiliateAdvertBody onClose={onClose} {...rest} />}
    />
  );
});
