import type {PinCode, Semver} from '@ncwallet-app/core';
import {computed, makeObservable, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';

import {DELAY_FOR_VISIBLE_FULL_PIN} from '../../Navigation/AuthStack/CheckPinBinding/constants';
import {PinScreen} from '../PinScreen';
import type {PinScreenTexts} from '../PinScreen/PinScreenTexts';

export type CreatePinScreenProps = {
  appVersion: Semver;
  screenTexts: {
    create: PinScreenTexts;
    confirm: PinScreenTexts;
  };
  onSuccess: (pin: PinCode) => void;
  onCancel: () => void;
  onErrorActionPress?: () => void;
};

export class CreatePinScreenStateImpl {
  public static FULL_PIN_LENGTH = 4;

  @observable private _pinToCheck?: PinCode;
  @observable private _pin = '' as PinCode;
  @observable private _isConfirming = false;
  @observable private _errorShown = false;

  constructor(private _props: {onSuccess: (pin: PinCode) => void}) {
    makeObservable(this);
  }

  setProps(props: {onSuccess: (pin: PinCode) => void}) {
    this._props = props;
  }

  get isConfirming() {
    return this._isConfirming;
  }

  @computed get errorShown() {
    return this._errorShown;
  }

  setPin = (pin: string) => {
    runInAction(() => {
      this._pin = pin as PinCode;
      this._errorShown = false;
    });
    if (pin.length === CreatePinScreenStateImpl.FULL_PIN_LENGTH) {
      setTimeout(() => {
        runInAction(() => {
          if (this._isConfirming && this._pin === this._pinToCheck) {
            this._props.onSuccess(this._pin);
          } else {
            this._errorShown = true;
          }
          this._pinToCheck = this._pin;
          this._pin = '' as PinCode;
          this._isConfirming = !this._isConfirming;
        });
      }, DELAY_FOR_VISIBLE_FULL_PIN);
    }
  };

  getPin = () => this._pin;
}

export default observer(function CreatePinScreen(props: CreatePinScreenProps) {
  const [state] = useState(() => new CreatePinScreenStateImpl(props));
  state.setProps(props);
  const getErrorShown = useCallback(() => state.errorShown, [state]);

  return (
    <PinScreen
      appVersion={props.appVersion}
      onCancel={props.onCancel}
      setPin={state.setPin}
      getPin={state.getPin}
      getErrorShown={getErrorShown}
      onErrorActionPress={props.onErrorActionPress}
      texts={
        state.isConfirming
          ? props.screenTexts.confirm
          : props.screenTexts.create
      }
    />
  );
});
