import dayjs from 'dayjs';

import {RateHistoryPeriod} from '../../dataStores';
import type {CurrencyCode} from '../../Money';
import {last} from '../../util';
import hasMany from '../../util/hasMany';
import type {CurrenciesRateResult} from '../CurrenciesRate';
import type {CurrenciesRateHistoryItem} from './CurrenciesRateHistory';

// В графиках курсов включается текущее значение курса.
// (чтобы последнее значение совпадало с актуальным курсом над графиком)
// Для дневного включается как последний элемент, для остальных - вместо последнего.
// Логика про последний элемент костыль, добавленный потому, что график написан с
// предположением, что все данные распределены равномерно по времени.
// (что нарушается при добавлении точки из-за чего нарушается масштаб у отрезка между последней точкой и предпоследней)
export default function patchRateHistoryWithLatestRate<
  From extends CurrencyCode,
  To extends CurrencyCode,
>(
  items: CurrenciesRateHistoryItem<From, To>[],
  rateResult: CurrenciesRateResult<From, To>,
  period: RateHistoryPeriod,
): CurrenciesRateHistoryItem<From, To>[] {
  if (!hasMany(items)) {
    return items;
  }
  const lastItem = last(items);

  if (!dayjs(lastItem.timestamp).isBefore(rateResult.last_updated)) {
    return items;
  }

  const newLastItem = {
    timestamp: rateResult.last_updated,
    rate: rateResult.rate,
  };

  if (period === RateHistoryPeriod.Day) {
    return [...items, newLastItem];
  }

  return items.map(item => (item === lastItem ? newLastItem : item));
}
