import type {ISODateString, OtpCode} from '@ncwallet-app/core';
import {useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {WalletsWithdrawalsConfirm} from '@ncwallet-app/core/src/NCWalletServer/WalletsWithdrawalsConfirm';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {SendTwoFaSpinner, useIsDimensions} from '@ncwallet-app/ui';
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../Navigation/hooks';
import {useSendTelegramOtp} from '../../Navigation/hooks/useSendTelegramOtp';
import {
  CARD,
  SubmitOtpScreen,
  SWITCH_SCREEN,
} from '../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import {useBaseErrorDescription} from '../hooks';
import {useCanPassTwoFaWithBiometrics} from '../hooks/usePromptOtpToExchangeContainer';

export type PromptOtpToSendCryptoContainerProps = {
  withdrawId: string;
  notifyAboutSuccessfulSending: () => void;
  onBack: () => void;
};

export default observer(function PromptOtpToSendCryptoContainer(
  props: PromptOtpToSendCryptoContainerProps,
) {
  const {withdrawId, notifyAboutSuccessfulSending, onBack} = props;
  const {twoFaSettingsState, ncWalletJsonRpcClient, biometrics} = useRoot();
  const [loading, setLoading] = useState(false);
  const [getError, setError, resetError] = useBaseErrorDescription();
  const strings = useStrings();
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();

  const handleTwoFaResendCode = useCallback(async () => {
    try {
      await sendOtp(null, {forceChannel: false});
    } catch {
      /* empty */
    }
  }, [sendOtp]);

  const handleCodeSubmit = useCallback(
    async (payload: string, isBiometric?: boolean) => {
      setLoading(true);

      const params: WalletsWithdrawalsConfirm = {
        params: {
          withdrawal_id: withdrawId,
        },
      };

      if (isBiometric) {
        params.params.biometry_signature = payload;
      } else {
        params.params.code_2fa = payload as OtpCode;
        params.params.utc_2fa = dayjs().utc().toISOString() as ISODateString;
      }
      const res = await ncWalletJsonRpcClient.call(
        'wallets.withdrawals.confirm',
        params.params,
      );

      setLoading(false);

      if (!res.success) {
        setError(res.left);
      } else {
        notifyAboutSuccessfulSending();
      }
    },
    [ncWalletJsonRpcClient, withdrawId, setError, notifyAboutSuccessfulSending],
  );

  const submitByBiometric = useCallback(async () => {
    const res = await biometrics.createSignature({
      payload: withdrawId,
      promptMessage: strings['sendCrypto.biometricConfirm'],
      cancelButtonText: strings['sendCrypto.code.enter'],
    });

    if (!res.success) {
      return;
    }

    return handleCodeSubmit(res.right, true);
  }, [biometrics, handleCodeSubmit, strings, withdrawId]);

  const isLg = useIsDimensions('lg');
  const canPassTwoFaWithBiometrics =
    useCanPassTwoFaWithBiometrics(submitByBiometric);

  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (getIsReady() && getIsFocused()) {
      if (
        twoFaSettingsState.currentTwoFaProvider === TwoFaProviderKind.Telegram
      ) {
        void handleTwoFaResendCode();
      }
    }
  }, [getIsReady, getIsFocused, twoFaSettingsState, handleTwoFaResendCode]);

  return (
    <>
      <SubmitOtpScreen
        layout={isLg ? SWITCH_SCREEN : CARD}
        backText={strings['screenTitle.confirmCode']}
        titleText={strings['sendCrypto.code.twoFa']}
        submitText={strings['sendCrypto.code.submit']}
        resetError={resetError}
        onCancel={onBack}
        onSubmit={handleCodeSubmit}
        getError={getError}
        showBiometricButton={canPassTwoFaWithBiometrics}
        onBiometricPress={submitByBiometric}
        twoFaProvider={twoFaSettingsState.currentTwoFaProvider}
        resendCodeInterval={interval}
        resetInterval={resetInterval}
        onTwoFaResendCode={handleTwoFaResendCode}
      />

      <SendTwoFaSpinner visible={loading} />
    </>
  );
});
