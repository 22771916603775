import {PlatformType} from '@ncwallet-app/core/src/models/PlatformType';
import type {
  Session,
  SessionDeviceInfo,
  SessionId,
} from '@ncwallet-app/core/src/NCWalletServer/sessions/Session';
import type {ISODateString} from '@ncwallet-app/core/src/Time/units';
import {capitalize, isEmpty} from 'lodash';

export type SessionInfo = {
  id: SessionId;
  platform: PlatformType;
  title: string;
  info: string;
  time: ISODateString;
  hasActiveConnection: boolean;
  status: 'active' | 'blocked';
};

export const fromSession = (s: Session): SessionInfo => ({
  id: s.id,
  title: getSessionTitle(s),
  platform: s.device_info.platform,
  hasActiveConnection: s.has_active_connection,
  info: safeConcat([s.ip, s.city], ' - '),
  time: s.created_at,
  status: s.status,
});

export const getSessionTitle = (s: Session) => {
  const device = s.device_info;
  const firstPart =
    device.platform === PlatformType.WEB ||
    device.platform === PlatformType.TELEGRAM
      ? getBrowserDescription(device)
      : device.device_name;
  return safeConcat([firstPart, getPlatformDescription(device)]);
};

const getBrowserDescription = (d: SessionDeviceInfo) => {
  if (!d.browser) {
    return undefined;
  }

  return d.browser_version ? `${d.browser} ${d.browser_version}` : d.browser;
};

const getPlatformDescription = (d: SessionDeviceInfo) => {
  const name = getPlatformName(d);
  if (!name) {
    return undefined;
  }

  return d.platform_version ? `${name} ${d.platform_version}` : name;
};

const getPlatformName = (d: SessionDeviceInfo) => {
  if (d.platform === PlatformType.ANDROID || d.platform === PlatformType.IOS) {
    return capitalize(d.platform);
  } else if (
    d.platform === PlatformType.WEB ||
    d.platform === PlatformType.TELEGRAM
  ) {
    return d.browser_os;
  } else {
    return undefined;
  }
};

const safeConcat = (
  strings: (string | undefined)[],
  separator = ', ',
): string => {
  return strings.filter(s => !isEmpty(s)).join(separator);
};
