import type {WalletId} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptWalletToFilterHistoryContainer from '../../../../../CommonNavigationContainers/containers/PromptWalletToFilterHistoryContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptWalletToFilterHistoryBindingProps =
  LargeSwitchBindingProps<'PromptWalletToFilterHistory'>;

export default observer(function PromptWalletToFilterHistoryBinding(
  props: PromptWalletToFilterHistoryBindingProps,
) {
  const {navigation, route} = props;

  const onSearchChange = useCallback(
    (search: string) => {
      navigation.setParams({search});
    },
    [navigation],
  );

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({index: 0, routes: [{name: 'ListHistoryFilters'}]});
  }, [navigation]);

  const onSelect = useCallback(
    (walletId: WalletId | undefined) => {
      navigation.navigate('ListHistoryFilters', {
        ...route.params,
        walletId: walletId,
      });
    },
    [navigation, route.params],
  );

  return (
    <PromptWalletToFilterHistoryContainer
      search={route.params?.search}
      onSearchChange={onSearchChange}
      onBack={onBack}
      onSelect={onSelect}
    />
  );
});
