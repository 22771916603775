import type {
  BottomSheetBackdropProps,
  BottomSheetBackgroundProps,
  BottomSheetProps as GorhomBottomSheetProps,
} from '@gorhom/bottom-sheet';
import GorhomBottomSheet, {
  ANIMATION_CONFIGS as BASE_ANIMATION_CONFIGS,
  BottomSheetScrollView,
} from '@gorhom/bottom-sheet';
import type {BottomSheetDefaultBackdropProps} from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheetBackdrop/types';
import {useStyles} from '@ncwallet-app/core';
import React, {forwardRef, useCallback, useMemo} from 'react';
import {Platform} from 'react-native';
import Animated, { // eslint-disable-next-line import-x/no-deprecated
  Extrapolate,
  interpolate,
  ReduceMotion,
  useAnimatedStyle,
} from 'react-native-reanimated';

import {Background} from './Background';
import {HeaderTitle} from './HeaderTitle';

export type BottomSheetProps = Omit<GorhomBottomSheetProps, 'children'> & {
  children: React.ReactNode[] | React.ReactNode;
  title?: React.ReactNode;
  disappearsOnIndex?: number;
  appearsOnIndex?: number;
  backdropProps?: Partial<BottomSheetDefaultBackdropProps>;
  onClose?: () => void;
  center?: boolean;
  enableAutoHeight?: boolean;
};

export const BottomSheet = forwardRef<GorhomBottomSheet, BottomSheetProps>(
  (props, ref) => {
    return (
      <ObserverBottomSheet
        forwardedRef={ref}
        {...props}
        onClose={props.onClose}
        children={props.children}
        center={props.center}
        animationConfigs={ANIMATION_CONFIGS}
        enableAutoHeight={props.enableAutoHeight}
        enablePanDownToClose={Platform.OS !== 'web'}
      />
    );
  },
);

const ANIMATION_CONFIGS = {
  ...BASE_ANIMATION_CONFIGS,
  reduceMotion: ReduceMotion.Never,
};

type ObserverBottomSheetProps = BottomSheetProps & {
  forwardedRef: React.Ref<GorhomBottomSheet>;
  onClose?: () => void;
  enableAutoHeight?: boolean;
};

export const ObserverBottomSheet = ({
  title,
  children,
  forwardedRef,
  backdropProps,
  center,
  enableAutoHeight,
  onClose,
  ...rest
}: ObserverBottomSheetProps) => {
  const renderBackground: React.FC<BottomSheetBackgroundProps> = useCallback(
    props => <Background {...props} />,
    [],
  );
  const styles = useStyles(() => ({
    root: {
      zIndex: 2,
    },
  }));

  const renderBackdrop: React.FC<BottomSheetBackdropProps> = useCallback(
    props => <CustomBackdrop {...props} {...backdropProps} onPress={onClose} />,
    [backdropProps, onClose],
  );
  if (!enableAutoHeight) {
    return (
      <GorhomBottomSheet
        ref={forwardedRef}
        style={styles.root}
        backdropComponent={renderBackdrop}
        backgroundComponent={renderBackground}
        handleComponent={null}
        {...rest}>
        <HeaderTitle center={center} title={title} onClose={onClose} />
        {children}
      </GorhomBottomSheet>
    );
  }
  return (
    <GorhomBottomSheet
      ref={forwardedRef}
      style={styles.root}
      backdropComponent={renderBackdrop}
      backgroundComponent={renderBackground}
      handleComponent={null}
      {...rest}>
      <BottomSheetScrollView scrollEnabled={false}>
        <>
          <HeaderTitle center={center} title={title} onClose={onClose} />
          {children}
        </>
      </BottomSheetScrollView>
    </GorhomBottomSheet>
  );
};

const CustomBackdrop = ({
  animatedIndex,
  style,
  onPress,
}: BottomSheetDefaultBackdropProps) => {
  const containerAnimatedStyle = useAnimatedStyle(
    () => ({
      opacity: interpolate(
        animatedIndex.value,
        [0, -1],
        [0.5, 0],
        // eslint-disable-next-line @typescript-eslint/no-deprecated,import-x/no-deprecated
        Extrapolate.CLAMP,
      ),
      display: animatedIndex.value === -1 ? 'none' : 'flex',
    }),
    [animatedIndex],
  );

  const containerStyle = useMemo(
    () => [
      style,
      {
        backgroundColor: '#000000',
      },
      containerAnimatedStyle,
    ],
    [style, containerAnimatedStyle],
  );

  return (
    <Animated.View
      style={containerStyle}
      onStartShouldSetResponder={() => true}
      onTouchStart={onPress}
    />
  );
};
