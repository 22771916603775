import type {
  CryptoCurrencyCode,
  WalletLimit,
  WalletLimitPeriod,
} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {LimitRemovalAction} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../../../Navigation/hooks';
import {CurrencyLimitScreen} from '../../../../screens/UserSettingsScreens/CurrencyLimitScreen';
import type {SmallHomeStackBindingProps} from '../../SmallHomeStackBindingProps';
import {ShowLimitBindingState} from './ShowLimitBindingState';

export type ShowLimitBindingProps = SmallHomeStackBindingProps<'ShowLimit'>;

export default observer(function ShowLimitBinding(
  props: ShowLimitBindingProps,
) {
  const {navigation, route} = props;
  const root = useRoot();
  const [state] = useState(() => new ShowLimitBindingState(root));

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh(route.params.walletId);
          });
        }
      }),
    [getIsFocused, getIsReady, route.params.walletId, state],
  );

  const goToChangeCurrencyLimit = useCallback(
    (limit: WalletLimit) => {
      navigation.navigate('PromptLimitUpdate', {
        limit: limit.limit,
        currency: limit.currency,
        walletId: limit.wallet_id,
        period: limit.period,
      });
    },
    [navigation],
  );

  const onDeletePress = useCallback(
    (l: WalletLimit) => {
      navigation.navigate('PromptLimitRemoval', {
        limitId: l.id,
        action: LimitRemovalAction.Delete,
      });
    },
    [navigation],
  );
  const onCancelDeletePress = useCallback(
    (l: WalletLimit) => {
      navigation.navigate('PromptLimitRemoval', {
        limitId: l.id,
        action: LimitRemovalAction.CancelDelete,
      });
    },
    [navigation],
  );

  const onDeleteWaitingForActivationLimitPress = useCallback(
    (l: WalletLimit) => {
      navigation.navigate('PromptLimitRemoval', {
        limitId: l.id,
        action: LimitRemovalAction.DeleteWaitingForActivationLimit,
      });
    },
    [navigation],
  );

  const onCreatePress = useCallback(
    (
      currency: CryptoCurrencyCode,
      period: WalletLimitPeriod,
      perOnePeriodOnly?: boolean,
    ) => {
      navigation.navigate('PromptNewLimit', {
        walletId: route.params.walletId,
        currency,
        period,
        perOnePeriodOnly,
      });
    },
    [navigation, route.params.walletId],
  );

  return (
    <CurrencyLimitScreen
      getDailyLimit={state.getDailyActiveLimit}
      getNewDailyLimit={state.getWaitingForActivationDailyLimit}
      getMonthlyLimit={state.getActiveMonthlyLimit}
      getNewMonthlyLimit={state.getWaitingForActivationMonthlyLimit}
      onChangePress={goToChangeCurrencyLimit}
      onCancelDeletePress={onCancelDeletePress}
      onDeleteWaitingForActivationLimitPress={
        onDeleteWaitingForActivationLimitPress
      }
      code={state.currency?.code}
      onDeletePress={onDeletePress}
      onCreatePress={onCreatePress}
    />
  );
});
