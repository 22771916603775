import {observer} from 'mobx-react-lite';
import {QR} from 'qr-svg';
import React, {useMemo} from 'react';

import type {QrProps} from './Qr';

// цвет qr кода не должен зависеть от темы так как это ломает некоторые сканеры (в хром браузере на телефоне, к примеру)
// http://qrcode.meetheed.com/question2.php
export default observer(function Qr({text}: QrProps) {
  const qr = useMemo(() => QR(text), [text]);

  return <img src={'data:image/svg+xml;utf8,' + qr} />;
});
