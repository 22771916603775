import type {InOutCurrency} from '@ncwallet-app/core';
import {getDefaultAddressParams} from '@ncwallet-app/core/src/NCWalletServer/InOutCurrency';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {usePromptOutputNetworkBindingState} from '../../../../../CommonNavigationContainers/hooks/usePromptOutputNetworkBindingState/usePromptOutputNetworkBindingState';
import NetworkSelectionScreen from '../../../../../screens/NetworkSelectionScreen/NetworkSelectionScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptOutputNetworkBindingProps =
  LargeSwitchBindingProps<'PromptOutputNetwork'>;

export default observer(function PromptOutputNetworkBinding(
  props: PromptOutputNetworkBindingProps,
) {
  const {navigation, route} = props;
  const {
    addressNetwork,
    currency,
    addressTo,
    amount,
    isEditable,
    addressCurrency,
  } = route.params;
  const state = usePromptOutputNetworkBindingState(currency);

  const handleSelect = useCallback(
    (output: InOutCurrency) => {
      if (isEditable && addressTo && !isNil(amount)) {
        navigation.navigate('PromptConfirmationToSendCrypto', {
          ...route.params,
          addressTo,
          amount,
          ...getDefaultAddressParams(output),
        });
      } else {
        navigation.navigate('PromptOutputAddress', {
          ...route.params,
          ...getDefaultAddressParams(output),
        });
      }
    },
    [addressTo, amount, isEditable, navigation, route.params],
  );

  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('PromptOutputAddress', route.params);
    }
  }, [navigation, route.params]);

  return (
    <NetworkSelectionScreen
      goBack={goBack}
      network={addressNetwork}
      currency={addressCurrency}
      networks={state.networks}
      onSelect={handleSelect}
    />
  );
});
