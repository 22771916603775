import type {WalletLimit, WalletLimitId} from '@ncwallet-app/core';
import {
  PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE,
  SHOW_LIMIT_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {isNil} from 'lodash';
import {useCallback, useEffect} from 'react';

import {useDeleteLimitWithOtp} from '../../../../../../CommonNavigationContainers/hooks/walletLimits/useDeleteLimitWithOtp';
import type {
  PromptLimitOtpCardProps,
  PromptLimitOtpTelegram,
} from '../../../../../../screens/LgLimitsSettingsScreen/LimitCard/PromptLimitOtpCard';
import type {LargeSwitchBindingProps} from '../../LargeSwitchBindingProps';

// eslint-disable-next-line import-x/prefer-default-export
export const usePromptOtpToLimitRemovalCard = (
  getLimit: (limitId: WalletLimitId) => WalletLimit | undefined,
  refresh: () => void,
  telegramOtp: PromptLimitOtpTelegram,
  props: LargeSwitchBindingProps<'ListLimits'>,
): PromptLimitOtpCardProps => {
  const {navigation, route} = props;
  const selectedCard = route.params?.focusedSelectedLimit;

  const onSuccess = useCallback(() => {
    refresh();
    navigation.setParams({focusedSelectedLimit: undefined});
  }, [navigation, refresh]);

  const {deleteLimitWithOtp, resetErrorText, getErrorText} =
    useDeleteLimitWithOtp(onSuccess);

  useEffect(() => {
    if (selectedCard?.kind !== PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE) {
      resetErrorText();
    }
  }, [resetErrorText, selectedCard?.kind]);

  const onSubmit = useCallback(
    (code: string) => {
      if (selectedCard?.kind !== PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE) {
        return;
      }
      return deleteLimitWithOtp(
        selectedCard.params.limitId,
        code,
        selectedCard.params.action,
      );
    },
    [deleteLimitWithOtp, selectedCard],
  );

  const onBack = useCallback(() => {
    if (selectedCard?.kind !== PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE) {
      return;
    }

    const limit = getLimit(selectedCard.params.limitId);
    if (isNil(limit)) {
      return;
    }

    navigation.setParams({
      focusedSelectedLimit: {
        kind: SHOW_LIMIT_ROUTE,
        params: {
          walletId: limit.wallet_id,
          period: limit.period,
        },
      },
    });
  }, [navigation, selectedCard, getLimit]);

  return {
    onBack,
    onSubmit,
    resetErrorText,
    getErrorText,
    ...telegramOtp,
  };
};
