import type {Base32, Millisecond, OtpSecret, Url} from '@ncwallet-app/core';
import {
  APP_WINDOW_ACTIVE,
  FULFILLED,
  SHA1,
  TOTP,
  useRoot,
  useStrings,
} from '@ncwallet-app/core';
import type {RequestedMessage} from '@ncwallet-app/core/src/FlashMessage';
import {getOSKind, OSKind} from '@ncwallet-app/core/src/util/getOSKind';
import {setStringAsync, StringFormat} from 'expo-clipboard';
import {observable, reaction, runInAction} from 'mobx';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Platform} from 'react-native';

import {useBaseErrorDescription} from '../../../CommonNavigationContainers/hooks';
import {useNavigationGetIsFocused} from '../useNavigationGetIsFocused';
import isWebIOS from './isWebIOS';

export default function useSetTwoFaGenerateSecret(
  setCodeParam?: (c: string) => void,
  code?: string,
) {
  const root = useRoot();
  const {flashMessage, telegramMiniApp} = useRoot();
  const isTelegram = telegramMiniApp.isAvailable;
  const isWebOnAndroid =
    Platform.OS === 'web' && getOSKind() === OSKind.Android;
  const strings = useStrings();
  const [secretBox] = useState(() =>
    observable.box<Base32<OtpSecret> | undefined>(code as Base32<OtpSecret>),
  );
  const getSecret = useCallback(() => secretBox.get(), [secretBox]);

  const [getError, setError] = useBaseErrorDescription();

  const getOtpAuthUri = useCallback(
    () =>
      root.otp.formAuthenticatorLink(TOTP, {
        accountName:
          root.accountStore.state?.status === FULFILLED
            ? root.accountStore.state.result.email ||
              root.accountStore.state.result.id
            : '',
        issuer: 'NCWallet',
        algorithm: SHA1,
        secret: secretBox.get() ?? ('' as Base32<OtpSecret>),
        digits: 6,
      }),
    [root, secretBox],
  );

  const [hasAuthenticatorBox] = useState(() =>
    observable.box<boolean | undefined>(),
  );
  const getHasAuthenticator = useCallback(() => {
    if (Platform.OS === 'web' && isWebIOS()) {
      return true;
    }
    return hasAuthenticatorBox.get();
  }, [hasAuthenticatorBox]);

  const getIsFocused = useNavigationGetIsFocused();
  useEffect(
    () =>
      reaction(
        () =>
          getIsFocused() &&
          root.appWindowState.status === APP_WINDOW_ACTIVE &&
          root.appWindowState.isFocused,
        async shouldRequest => {
          if (shouldRequest) {
            const canOpen_ = await root.location.canOpen(
              getOtpAuthUri() as Url,
            );
            if (!canOpen_.success) {
              setError(canOpen_.left);
            } else {
              runInAction(() => {
                hasAuthenticatorBox.set(canOpen_.right);
              });
            }
          }
        },
        {fireImmediately: true},
      ),
    [getIsFocused, getOtpAuthUri, hasAuthenticatorBox, root, setError],
  );

  const getAuthenticatorStoreLinks = useCallback(() => {
    const googleWebLink =
      'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
    const googleMobLink =
      'market://details?id=com.google.android.apps.authenticator2';

    const iosWebLink = `https://apps.apple.com/app/id388497605?l=${root.userPreferenceState.languageCode}`;
    const iosMobLink = `itms-apps://apps.apple.com/app/id388497605?l=${root.userPreferenceState.languageCode}`;

    const extensionLink = `https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=${root.userPreferenceState.languageCode}`;
    return {
      googleWebLink,
      googleMobLink,
      iosWebLink,
      iosMobLink,
      extensionLink,
    };
  }, [root.userPreferenceState.languageCode]);

  const onInstallAuthenticator = useCallback(async () => {
    const {extensionLink} = getAuthenticatorStoreLinks();
    const androidUrl =
      'market://details?id=com.google.android.apps.authenticator2' as Url;
    const iosUrl =
      `itms-apps://apps.apple.com/app/id388497605?l=${root.userPreferenceState.languageCode}` as Url;
    const defaultUrl =
      'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2' as Url;
    const os = getOSKind();
    const open_ = await root.location.open(
      os === OSKind.Android
        ? androidUrl
        : os === OSKind.IOs || os === OSKind.MacOS
          ? iosUrl
          : os === OSKind.Windows || os === OSKind.Linux
            ? (extensionLink as Url)
            : defaultUrl,
    );

    if (!open_.success) {
      setError(open_.left);
    }
  }, [
    getAuthenticatorStoreLinks,
    root.location,
    root.userPreferenceState.languageCode,
    setError,
  ]);

  const onCopySecret = useCallback(
    async (message?: Partial<RequestedMessage>) => {
      const secret = secretBox.get();
      if (!secret) {
        return;
      }
      try {
        const wasSet = await setStringAsync(secret, {
          inputFormat: StringFormat.PLAIN_TEXT,
        });
        if (wasSet) {
          flashMessage.showMessage({
            id: secret,
            timeout: TIMEOUT,
            title: strings.copied,
            variant: 'success',
            ...message,
          });
        }
      } catch {
        /* empty */
      }
    },
    [flashMessage, secretBox, strings],
  );

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const onDirectlyOpenAuthenticator = useCallback(async () => {
    await onCopySecret({
      title: strings.CodeCopied,
      onPress: openGoogleAuthenticator,
    });

    if (timeoutRef.current === undefined) {
      timeoutRef.current = setTimeout(openGoogleAuthenticator, TIMEOUT);
    }

    async function openGoogleAuthenticator() {
      const open_ = await root.location.open(GOOGLE_AUTHENTICATOR_URI);
      if (!open_.success) {
        await onInstallAuthenticator();
      }
      timeoutRef.current = undefined;
    }
  }, [onCopySecret, root, strings, onInstallAuthenticator]);

  useEffect(
    () =>
      reaction(
        () => getIsFocused() && secretBox.get() === undefined,
        async shouldGenerate => {
          if (shouldGenerate) {
            const prevTwoFaSecret =
              await root.jsonSecureKeyValueStore.get('twoFaSecret');
            if (!prevTwoFaSecret.success) {
              setError(prevTwoFaSecret.left);
              return;
            }

            if (prevTwoFaSecret.right) {
              runInAction(() => {
                secretBox.set(prevTwoFaSecret.right);
              });
              return;
            }

            const generate_ = await root.otp.generateSecret();
            if (!generate_.success) {
              setError(generate_.left);
            } else {
              runInAction(() => {
                secretBox.set(generate_.right);
                setCodeParam?.(generate_.right);
              });
            }
          }
        },
        {fireImmediately: true},
      ),
    [setCodeParam, secretBox, root, getIsFocused, setError],
  );

  const openAppleAuthenticator = useCallback(async () => {
    const authenticatorUrl = new URL(
      `${root.configuration.values.miniAppUrl}/telegram-authenticator.html`,
    );

    if (Platform.OS === 'web') {
      if (isTelegram) {
        if (isWebIOS(false)) {
          authenticatorUrl.searchParams.set(
            'deepLink',
            encodeURIComponent(getOtpAuthUri()),
          );
          telegramMiniApp.openLink(authenticatorUrl.toString() as Url);
          return;
        } else {
          authenticatorUrl.searchParams.set(
            'link',
            encodeURIComponent(getOtpAuthUri()),
          );
          telegramMiniApp.openLink(authenticatorUrl.toString() as Url);
          return;
        }
      }

      if (isWebIOS(false)) {
        authenticatorUrl.searchParams.set(
          'deepLink',
          encodeURIComponent(getOtpAuthUri()),
        );
        return root.location.open(authenticatorUrl.toString() as Url);
      }
    }

    const open_ = await root.location.open(getOtpAuthUri() as Url);
    if (!open_.success) {
      setError(open_.left);
    }
  }, [root, getOtpAuthUri, setError, isTelegram, telegramMiniApp]);

  const openGoogleAuthenticator = useCallback(async () => {
    const {extensionLink} = getAuthenticatorStoreLinks();
    if (isWebOnAndroid) {
      const authenticatorUrl = new URL(
        `${root.configuration.values.miniAppUrl}/telegram-authenticator.html`,
      );
      authenticatorUrl.searchParams.set(
        'deepLink',
        encodeURIComponent(getOtpAuthUri()),
      );
      authenticatorUrl.searchParams.set(
        'apn',
        'com.google.android.apps.authenticator2',
      );
      authenticatorUrl.searchParams.set('link', extensionLink);
      authenticatorUrl.searchParams.set('text', strings['helperScreen.desc']);
      authenticatorUrl.searchParams.set(
        'buttonText',
        strings['helperScreen.button'],
      );
      if (isTelegram) {
        telegramMiniApp.openLink(authenticatorUrl.toString() as Url);
        return;
      }
      return root.location.open(authenticatorUrl.toString() as Url);
    }

    if (Platform.OS === 'ios') {
      const canOpen_ = await root.location.canOpen(GOOGLE_AUTHENTICATOR_URI);
      if (canOpen_.success) {
        await onDirectlyOpenAuthenticator();
        return;
      }
    }

    if (Platform.OS === 'web') {
      if (isWebIOS(false)) {
        const authenticatorUrl = new URL(
          `${root.configuration.values.miniAppUrl}/telegram-authenticator.html`,
        );
        authenticatorUrl.searchParams.set(
          'deepLink',
          encodeURIComponent(getOtpAuthUri()),
        );
        const open_ = await root.location.open(
          authenticatorUrl.toString() as Url,
        );
        if (!open_.success) {
          setError(open_.left);
        }
      } else if (isWebIOS()) {
        const open_ = await root.location.open(getOtpAuthUri() as Url);
        if (!open_.success) {
          setError(open_.left);
        }
      } else {
        await onInstallAuthenticator();
        return;
      }
    }

    const open_ = await root.location.open(getOtpAuthUri() as Url);
    if (!open_.success) {
      setError(open_.left);
    }
  }, [
    getAuthenticatorStoreLinks,
    isTelegram,
    isWebOnAndroid,
    root.location,
    root.configuration.values.miniAppUrl,
    getOtpAuthUri,
    strings,
    telegramMiniApp,
    onDirectlyOpenAuthenticator,
    onInstallAuthenticator,
    setError,
  ]);

  return {
    onCopySecret: onCopySecret,
    onInstallAuthenticator: onInstallAuthenticator,
    onOpenGoogleAuthenticator: openGoogleAuthenticator,
    onOpenAuthenticator: openGoogleAuthenticator,
    onOpenAppleKeyManager: openAppleAuthenticator,
    onDirectlyOpenAuthenticator,
    getHasAuthenticator: getHasAuthenticator,
    getOtpAuthUri: getOtpAuthUri,
    getSecret: getSecret,
    getError: getError,
    secretBox: secretBox,
    getIsFocused: getIsFocused,
    setError: setError,
    getAuthenticatorStoreLinks: getAuthenticatorStoreLinks,
  };
}

const GOOGLE_AUTHENTICATOR_URI = 'googleauthenticator://' as Url;
const TIMEOUT = 1000 as Millisecond;
