import dayjs from 'dayjs';
import {action, makeObservable, observable, runInAction} from 'mobx';

import type {BaseAsyncOptions} from '../../Async';
import type {AccountIdStore} from '../../Auth';
import type {
  CancellationError,
  GeneralJsonRpcError,
  GlobalError,
} from '../../Error';
import {CANCELLATION_ERROR, TIMEOUT_ERROR} from '../../Error';
import type {ErrorRepository} from '../../ErrorRepository';
import type {Either} from '../../fp';
import {error, success} from '../../fp';
import type {JsonRpcClient, JsonRpcServer} from '../../JsonRpc';
import type {CurrencyCode, RateCode, RateValue} from '../../Money';
import {toRateCode} from '../../Money';
import type {
  CommonError,
  CurrenciesRateResult,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  NCWalletReverseCallScheme,
  NCWalletReverseNotificationScheme,
} from '../../NCWalletServer';
import type {SentryLog} from '../../SentryLog';
import createJsonRpcTimeoutErrorMessage from '../../SentryLog/createJsonRpcTimeoutErrorMessage';
import type {Service} from '../../structure';
import {batchDisposers} from '../../structure';
import type {ISODateString, Time} from '../../Time';
import {fromSecond, toISODateString} from '../../Time';
import type {BaseTransactionOptions} from '../../util';
import type {CurrenciesRateStore} from './CurrenciesRateStore';

export default class CurrenciesRateStoreService
  implements CurrenciesRateStore, Service
{
  @observable.ref private _codeToRateMap = new Map<
    RateCode<CurrencyCode, CurrencyCode>,
    CurrenciesRateResult<CurrencyCode, CurrencyCode>
  >();

  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly time: Time;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly ncWalletJsonRpcServer: JsonRpcServer<
        NCWalletReverseCallScheme,
        NCWalletReverseNotificationScheme
      >;
      readonly accountIdStore: AccountIdStore;
      readonly sentryLog: SentryLog;
    },
  ) {
    makeObservable(this);
  }

  private _loaded = false;

  getRate = <From extends CurrencyCode, To extends CurrencyCode>(
    from: From,
    to: To,
  ): CurrenciesRateResult<From, To> | undefined => {
    if (Object.is(from, to)) {
      return this.createCurrencyRateResultToItself(from, to);
    }

    const resp = this._codeToRateMap.get(toRateCode(from, to));

    if (!resp && !this._loaded) {
      void this.requestSingleRate(from, to);
    }

    return resp as CurrenciesRateResult<From, To> | undefined;
  };

  async refreshRate<From extends CurrencyCode, To extends CurrencyCode>(
    from: From,
    to: To,
  ): Promise<
    Either<
      CurrenciesRateResult<From, To>,
      GlobalError | GeneralJsonRpcError<CommonError>
    >
  > {
    const rateRes = await this._rateRequest(from, to);

    if (rateRes.success) {
      const map = new Map(this._codeToRateMap);
      map.set(
        toRateCode(rateRes.right.from_currency, rateRes.right.to_currency),
        rateRes.right,
      );
      runInAction(() => {
        this._codeToRateMap = map;
      });
      return rateRes;
    }
    return rateRes;
  }

  async requestSingleRate(from: CurrencyCode, to: CurrencyCode) {
    this._loaded = true;
    const rateRes = await this._rateRequest(from, to);
    if (rateRes.success) {
      this._codeToRateMap.set(
        toRateCode(rateRes.right.from_currency, rateRes.right.to_currency),
        rateRes.right,
      );
    }
    this._loaded = false;
  }

  async batchRefreshRates(
    codesPairs: {from: CurrencyCode; to: CurrencyCode}[],
    options?: BaseAsyncOptions & BaseTransactionOptions,
  ): Promise<
    Either<
      CurrenciesRateResult<CurrencyCode, CurrencyCode>,
      GlobalError | GeneralJsonRpcError<CommonError>
    >[]
  > {
    if (options?.signal?.aborted) {
      return [error(this._createCancellationError(options.signal.reason))];
    }

    const results = await Promise.all(
      codesPairs.map(pair => this._rateRequest(pair.from, pair.to, options)),
    );

    const map = new Map(this._codeToRateMap);
    results
      .flatMap(res => (res.success ? [res.right] : []))
      .forEach(rate =>
        map.set(toRateCode(rate.from_currency, rate.to_currency), rate),
      );

    const _runInAction = options?.postpone ?? runInAction;
    _runInAction(() => {
      this._codeToRateMap = map;
    });

    return results;
  }

  private async _rateRequest<
    From extends CurrencyCode,
    To extends CurrencyCode,
  >(
    from: From,
    to: To,
    options?: BaseAsyncOptions,
  ): Promise<
    Either<
      CurrenciesRateResult<From, To>,
      GlobalError | GeneralJsonRpcError<CommonError>
    >
  > {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }

    if (Object.is(from, to)) {
      return success(this.createCurrencyRateResultToItself(from, to));
    }

    const res = (await this._root.ncWalletJsonRpcClient.call(
      'currencies.rate',
      {from_currency: from, to_currency: to},
      options,
    )) as Either<
      CurrenciesRateResult<From, To>,
      GlobalError | GeneralJsonRpcError<CommonError>
    >;

    if (res.success) {
      return success(
        CurrenciesRateStoreService.patchRateLastUpdatedWithZeroTimeZone(
          res.right,
        ),
      );
    } else {
      if (res.left.kind === TIMEOUT_ERROR) {
        this._root.sentryLog.write(
          createJsonRpcTimeoutErrorMessage('[currencies.rate]', {from, to}),
        );
      }
      return res;
    }
  }

  private createCurrencyRateResultToItself<
    From extends CurrencyCode,
    To extends CurrencyCode,
  >(from: From, to: To): CurrenciesRateResult<From, To> {
    return {
      from_currency: from,
      to_currency: to,
      last_updated: toISODateString(this._root.time.now()),
      rate: '1' as RateValue<From, To>,
    };
  }

  // temporary fix for https://yt.wcode.team/issue/NCW-724
  private static patchRateLastUpdatedWithZeroTimeZone<
    From extends CurrencyCode,
    To extends CurrencyCode,
  >(rate: CurrenciesRateResult<From, To>): CurrenciesRateResult<From, To> {
    return {
      ...rate,
      last_updated: dayjs(rate.last_updated).toISOString() as ISODateString,
    };
  }

  private _updateRatesOnNotification() {
    return this._root.ncWalletJsonRpcServer.notification(
      'event',
      (params, _response, next) => {
        if (params.type === 'currencies_rates_update') {
          const map = new Map(this._codeToRateMap);
          for (const rate of params.data.rates) {
            map.set(toRateCode(rate.from_currency, rate.to_currency), {
              ...rate,
              rate: rate.rate,
              last_updated: dayjs(fromSecond(params.ts))
                .subtract(dayjs().utcOffset(), 'minute')
                .toISOString() as ISODateString,
            });
          }
          runInAction(() => {
            this._codeToRateMap = map;
          });
          return;
        }
        next();
      },
    );
  }

  @action.bound
  reset() {
    this._codeToRateMap = new Map<
      RateCode<CurrencyCode, CurrencyCode>,
      CurrenciesRateResult<CurrencyCode, CurrencyCode>
    >();
  }

  subscribe() {
    return batchDisposers(this._updateRatesOnNotification());
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }
}
