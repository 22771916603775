import {computed, makeObservable} from 'mobx';

import type {AccountStore} from '../AccountStore';
import {FULFILLED} from '../AsyncAtom';
import type {AuthHelper} from '../Auth';
import type {FlashMessage} from '../FlashMessage';
import type {JsonSerializable} from '../Json';
import type {Tunnel} from '../JsonRpc';
import {isError, isResponse, TUNNEL_INCOMING_MESSAGE} from '../JsonRpc';
import type {LayoutHelperState} from '../LayoutHelperState';
import type {Localization} from '../Localization';
import type {NavigationContainer} from '../NavigationContainer';
import {COMMON_ERROR_TO_LOCALE, CommonErrorCode} from '../NCWalletServer';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import {UserStatusError} from './types';
import type {UserStatusErrorDetectorService} from './UserStatusErrorDetectorService';

export default class UserStatusErrorDetectorServiceImpl
  implements UserStatusErrorDetectorService, Service
{
  constructor(
    private readonly _root: {
      readonly authHelper: AuthHelper;
      readonly accountStore: AccountStore;
      readonly flashMessage: FlashMessage;
      readonly navigationContainer: NavigationContainer;
      readonly layoutHelperState: LayoutHelperState;
      readonly localization: Localization;
    },
    private readonly _tunnel: Tunnel<JsonSerializable, JsonSerializable>,
  ) {
    makeObservable(this);
  }

  subscribe() {
    return batchDisposers(
      this._tunnel.io.listen(TUNNEL_INCOMING_MESSAGE, async _ => {
        if (isResponse(_) && isError(_)) {
          switch (_.error.code) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            case CommonErrorCode.AccountSuspended: {
              await this._root.authHelper.signOut({
                reason: 'Account was suspended',
              });
              break;
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            case CommonErrorCode.CurrencyActionUnavailable: {
              this._root.flashMessage.showMessage({
                variant: 'danger',
                title:
                  COMMON_ERROR_TO_LOCALE[
                    CommonErrorCode.CurrencyActionUnavailable
                  ],
              });
              break;
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            case CommonErrorCode.AccountVerificationRequired: {
              this._root.flashMessage.showMessage({
                variant: 'danger',
                title:
                  COMMON_ERROR_TO_LOCALE[
                    CommonErrorCode.AccountVerificationRequired
                  ],
              });
              break;
            }
          }
        }
      }),
    );
  }

  @computed get userStatusError(): UserStatusError | null {
    const accountState = this._root.accountStore.state;
    if (accountState?.status !== FULFILLED) {
      return null;
    }
    const accountSettings = accountState.result.settings;
    if (!accountSettings.is_verified && accountSettings.verification_required) {
      return UserStatusError.Verification;
    }
    if (accountSettings.is_suspended) {
      return UserStatusError.Suspended;
    }
    if (accountSettings.is_banned) {
      return UserStatusError.Banned;
    }
    return null;
  }
}
