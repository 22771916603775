import type {BaseAsyncOptions} from '../Async';
import type {CancellationError, GlobalError} from '../Error';
import {CANCELLATION_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error} from '../fp';
import type {Json, JsonSerializable, JsonString} from '../Json';
import type {Service} from '../structure';
import {RouterImpl} from '../structure';
import type {Tunnel, TunnelIoRouterMap} from './Tunnel';
import {
  TUNNEL_INCOMING_ERROR,
  TUNNEL_INCOMING_MESSAGE,
  TUNNEL_OUTGOING_ERROR,
  TUNNEL_OUTGOING_MESSAGE,
} from './Tunnel';

export default class StringToJsonTunnelAdapter
  implements Tunnel<JsonSerializable, JsonSerializable>, Service
{
  private readonly _io = new RouterImpl<
    TunnelIoRouterMap<JsonSerializable, JsonSerializable>
  >();

  constructor(
    private readonly _root: {
      readonly json: Json;
      readonly errorRepository: ErrorRepository;
    },
    private readonly _tunnel: Tunnel<JsonString, JsonString>,
  ) {}

  get io(): Tunnel<JsonSerializable, JsonSerializable>['io'] {
    return this._io;
  }

  async send(
    message: JsonSerializable,
    options?: BaseAsyncOptions,
  ): Promise<Either<void, GlobalError>> {
    if (options?.signal?.aborted) {
      const wrapped = this._root.errorRepository.create<CancellationError>({
        kind: CANCELLATION_ERROR,
      });
      this._io.send(TUNNEL_OUTGOING_ERROR, wrapped);
      return error(wrapped);
    }
    const raw_ = this._root.json.stringify(message);
    if (!raw_.success) {
      this._io.send(TUNNEL_OUTGOING_ERROR, raw_.left);
      return raw_;
    }
    const send_ = await this._tunnel.send(raw_.right, options);
    if (send_.success) {
      this._io.send(TUNNEL_OUTGOING_MESSAGE, message);
    } else {
      this._io.send(TUNNEL_OUTGOING_ERROR, send_.left);
    }
    return send_;
  }

  subscribe() {
    return this._tunnel.io.domain.listen(event => {
      switch (event.theme) {
        case TUNNEL_INCOMING_ERROR:
          this._io.send(TUNNEL_INCOMING_ERROR, ...event.args);
          break;
        case TUNNEL_INCOMING_MESSAGE: {
          const [message] = event.args;
          const structured_ = this._root.json.parse(message);
          if (!structured_.success) {
            this._io.send(TUNNEL_INCOMING_ERROR, structured_.left);
          } else {
            this._io.send(TUNNEL_INCOMING_MESSAGE, structured_.right);
          }
        }
      }
    });
  }
}
