import {sized, useStrings, variance} from '@ncwallet-app/core';
import {
  LG_BREAKPOINT,
  SearchHeader,
  TouchableOpacity,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {HelpSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {isNil, noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {Insets} from 'react-native';
import {View} from 'react-native';

import LimitsSettingsEmptySearch from '../../../shared/LimitsSettingsEmptySearch';
import type {WalletItemData} from '../../../shared/WalletItem';
import {LimitCard} from '../shared/LimitCard';
import {LimitCardBody} from '../shared/LimitCardBody';
import {LimitCardHeader, LimitCardHeaderTitle} from '../shared/LimitCardHeader';
import WalletList from './WalletList';

export type WalletsWithoutLimitsCardProps = {
  onFaqHelpPress?: () => void;
  getSearch: () => string | undefined;
  onSearchChange: (s: string) => void;
  getWallets: () => WalletItemData[] | undefined;
  onSelect: (w: WalletItemData) => void;
  onAddPress: () => void;
};

export default observer(function WalletsWithoutLimitsCard(
  props: WalletsWithoutLimitsCardProps,
) {
  const strings = useStrings();
  const isLg = useIsDimensions('lg');
  const wallets = props.getWallets();
  const searchValue = props.getSearch();
  const isSearchEmptyResult = searchValue && !isNil(wallets) && !wallets.length;

  return (
    <LimitCard>
      <LimitCardHeader>
        <LimitCardHeaderTitle>
          {strings['limitsSettingsScreen.noSetTitle']}
          {props.onFaqHelpPress && (
            <TouchableOpacity onPress={props.onFaqHelpPress} hitSlop={HIT_SLOP}>
              <FaqIcon />
            </TouchableOpacity>
          )}
        </LimitCardHeaderTitle>
      </LimitCardHeader>
      <LimitCardBody>
        <NoSetLimitSearchHeader
          value={searchValue}
          onValueChange={props.onSearchChange}
          onSubmit={noop}
          placeholder={
            strings['profileSettingsScreen.searchCryptoCurrencyPlaceholder']
          }
        />
        <SizedBox />
        {isSearchEmptyResult ? (
          <LimitsSettingsEmptySearch searchValue={searchValue} isLg={isLg} />
        ) : (
          <LimitListView>
            <WalletList
              data={wallets}
              onSelect={props.onSelect}
              onAddPress={props.onAddPress}
            />
          </LimitListView>
        )}
      </LimitCardBody>
    </LimitCard>
  );
});

const HIT_SLOP: Insets = {top: 16, right: 16, bottom: 16, left: 16};

const NoSetLimitSearchHeader = variance(SearchHeader)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingBottom: 13,
    paddingTop: 0,
    backgroundColor: theme.palette.uiPrimary,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        paddingBottom: 0,
        backgroundColor: 'transparent',
        borderBottomWidth: 0,
      },
    }),
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));

const LimitListView = variance(View)(theme => ({
  root: {
    flex: 1,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiPrimary,
    overflow: 'hidden',
  },
}));

const HelpIcon = sized(HelpSvg, 17);
const FaqIcon = variance(HelpIcon)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.uiSecondary,
    width: 20,
    height: 20,
  }),
);
