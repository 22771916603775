import {useRoot, variance} from '@ncwallet-app/core';
import type {BottomTabBarProps} from '@react-navigation/bottom-tabs';
import {BlurView} from 'expo-blur';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import type {Edges} from 'react-native-safe-area-context';
import {SafeAreaView} from 'react-native-safe-area-context';

import {TabBarItem} from './TabBarItem';

export const BottomTabBar = observer(
  ({state, descriptors, navigation}: BottomTabBarProps) => {
    return (
      <Root>
        <Background />
        <SafeAreaView edges={EDGES}>
          <Content>
            {state.routes.map((route, index) => {
              const {options} = descriptors[route.key];
              const label =
                options.tabBarLabel !== undefined &&
                typeof options.tabBarLabel === 'string'
                  ? options.tabBarLabel
                  : options.title !== undefined
                    ? options.title
                    : route.name;
              const icon = options.tabBarIcon;

              const isFocused = state.index === index;

              const onPress = () => {
                const event = navigation.emit({
                  type: 'tabPress',
                  target: route.key,
                  canPreventDefault: true,
                });

                if (!isFocused && !event.defaultPrevented) {
                  // The `merge: true` option makes sure that the params inside the tab screen are preserved
                  // @ts-expect-error FIXME Fix type error
                  navigation.navigate({name: route.name, merge: true});
                }
              };

              const onLongPress = () => {
                navigation.emit({
                  type: 'tabLongPress',
                  target: route.key,
                });
              };
              return (
                <TabBarItem
                  key={route.key}
                  isFocused={isFocused}
                  onPress={onPress}
                  onLongPress={onLongPress}
                  accessibilityLabel={options.tabBarAccessibilityLabel}
                  label={label}
                  icon={icon}
                  testID={route.name}
                />
              );
            })}
          </Content>
        </SafeAreaView>
      </Root>
    );
  },
);

const EDGES: Edges = ['bottom'];

const Background = observer(() => {
  const {appearance} = useRoot();
  if (Platform.OS === 'ios') {
    return (
      <BlurView
        tint={appearance.isDark ? 'dark' : 'light'}
        intensity={100}
        style={StyleSheet.absoluteFill}
      />
    );
  }
  return <BackgroundView style={StyleSheet.absoluteFill} />;
});

const BORDER_TOP_WIDTH = 1;
export const TAB_BAR_CONTENT_HEIGHT = 55;

const Root = variance(View)(theme => ({
  root: {
    shadowColor: theme.palette.background,
    paddingBottom: 5,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2.2,
    elevation: 3,
    borderTopWidth: BORDER_TOP_WIDTH,
    borderTopColor: theme.palette.uiSecondary,
  },
}));

const Content = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    height: TAB_BAR_CONTENT_HEIGHT - BORDER_TOP_WIDTH,
  },
}));

const BackgroundView = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.uiAdditional2,
  },
}));
