import type {SmallBottomTabParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallBottomTab/SmallBottomTabParamList';
import type {SmallHomeStackParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallHomeStackParamList';
import {SafeAreaInset} from '@ncwallet-app/ui';
import type {PartialState} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import type {PromptConfirmationForExchangeContainerProps} from '../../../CommonNavigationContainers/hooks/usePromptConfirmationForExchangeContainer';
import {usePromptConfirmationForExchangeContainer} from '../../../CommonNavigationContainers/hooks/usePromptConfirmationForExchangeContainer';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {ExchangeCryptoConfirmScreen} from '../../../screens/ExchangeCryptoConfirmScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptConfirmationForExchangeBindingProps =
  SmallHomeStackBindingProps<'PromptConfirmationForExchange'>;

export default observer(function PromptConfirmationForExchangeBinding(
  props: PromptConfirmationForExchangeBindingProps,
) {
  const {route, navigation} = props;
  const containerProps = useMemo<PromptConfirmationForExchangeContainerProps>(
    () => ({
      params: route.params,
      setParams: params => {
        navigation.setParams(params);
      },
      notifyAboutSuccessfulExchange: params => {
        navigation.navigate('NotifyAboutSuccessfulExchange', params);
      },
      promptOtpToExchange: params => {
        navigation.navigate('PromptOtpToExchange', params);
      },
      goBack: () => {
        if (navigation.canGoBack()) {
          navigation.goBack();
          return;
        }
        const bottomTabState: PartialState<
          NavigationState<SmallBottomTabParamList>
        > = {index: 0, routes: [{name: 'PromptSourceCryptoToExchange'}]};
        const rootState: PartialState<
          NavigationState<SmallHomeStackParamList>
        > = {
          index: 0,
          routes: [{name: 'Root', state: bottomTabState}],
        };
        if (hasRouteInHistory(navigation.getState(), 'Root')) {
          navigation.navigate('Root');
        } else {
          navigation.dispatch(CommonActions.reset(rootState));
        }
      },
    }),
    [navigation, route.params],
  );

  const screenProps = usePromptConfirmationForExchangeContainer(containerProps);

  return (
    <ExchangeCryptoConfirmScreen
      {...screenProps}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});
