/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import type {CurrencyDescription, DecimalString} from '@ncwallet-app/core';
import {
  formatCryptoValue,
  useStrings,
  variance,
  WalletLimitPeriod,
} from '@ncwallet-app/core';
import type {SafeAreaScrollViewProps} from '@ncwallet-app/ui/src';
import {
  BaseSkeleton,
  Button,
  ButtonColor,
  ButtonVariant,
  DecimalInput,
  InputType,
  SafeAreaScrollView,
} from '@ncwallet-app/ui/src';
import {BigNumber} from 'bignumber.js';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo, useState} from 'react';
import {Text, View} from 'react-native';

import {useMapLimitPeriodTabToLabelText} from '../../../shared/useMapLimitPeriodTabToLabelText';
import WalletLimitsWarning from '../../../shared/WalletLimitsWarning';
import {LimitError} from '../../LgLimitsSettingsScreen/LimitCard/CreateLimitForm/LimitFormSubmitButton';
import SetLimitTabs from '../../LgLimitsSettingsScreen/LimitCard/CreateLimitForm/SetLimitTabs';

export type ChangeLimitScreenProps = SafeAreaScrollViewProps & {
  crypto: CurrencyDescription | undefined;
  initialValue: string | undefined;
  initialPeriod: WalletLimitPeriod;
  showWarning?: boolean;
  periodTabsShown?: boolean;
  onSubmit: (limit: DecimalString, period: WalletLimitPeriod) => void;
  isUpdate?: boolean;
  error: LocaleKeys | undefined;
  resetError: () => void;
};

export default observer(function SetLimitScreen(props: ChangeLimitScreenProps) {
  const {
    crypto,
    initialPeriod = WalletLimitPeriod.Daily,
    onSubmit,
    initialValue,
    showWarning = false,
    periodTabsShown = false,
    isUpdate,
    error,
    resetError,
    ...rest
  } = props;
  const strings = useStrings();
  const periodToLabelTextMap = useMapLimitPeriodTabToLabelText();
  const [period, setPeriod] = useState(initialPeriod);
  const inputLabelText = useMemo(() => {
    return `${
      strings['limitsSettingsScreen.inputLabel']
    } (${periodToLabelTextMap.get(period) ?? ''})`;
  }, [strings, period, periodToLabelTextMap]);
  const inputPlaceholderText = useMemo(() => {
    return crypto
      ? `${formatCryptoValue(0, crypto.fractionDigits)} ${crypto.code}`
      : undefined;
  }, [crypto]);

  const [limitValue, setLimitValue] = useState<DecimalString | undefined>(
    initialValue,
  );

  const isDisabledButton = useMemo(() => {
    return limitValue ? BigNumber(limitValue).isLessThanOrEqualTo(0) : false;
  }, [limitValue]);

  const onChangeLimit = useCallback(
    (v: DecimalString | undefined) => {
      resetError();
      setLimitValue(v);
    },
    [resetError],
  );

  return (
    <ChangeLimitScrollView {...rest}>
      {showWarning && (
        <>
          <WalletLimitsWarning>
            <WalletLimitsWarningText>
              {strings['currencyLimitScreen.warningLabel']}
            </WalletLimitsWarningText>
          </WalletLimitsWarning>
          <SizedBox />
        </>
      )}
      {periodTabsShown && (
        <>
          <SetLimitTabs period={period} onTabPress={setPeriod} />
          <SizedBox />
        </>
      )}
      {!isNil(props.crypto) ? (
        <ChangeLimitInput
          type={InputType.Default}
          label={inputLabelText}
          placeholder={inputPlaceholderText}
          value={limitValue}
          onChangeDecimal={onChangeLimit}
          fractionDigits={props.crypto.fractionDigits || 1}
          autoFocus
        />
      ) : (
        <BaseSkeleton height={50} />
      )}
      {!!error && <LimitError>{strings[error]}</LimitError>}
      <ChangeLimitSubmitButton
        variant={ButtonVariant.Highlighted}
        color={ButtonColor.Secondary}
        onPress={() => {
          onSubmit(limitValue!, period);
        }}
        disabled={isDisabledButton}
        title={
          isUpdate
            ? strings['currencyLimitScreen.changeButton']
            : strings['limitsSettingsScreen.enableButton']
        }
      />
    </ChangeLimitScrollView>
  );
});

const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));

const ChangeLimitScrollView = variance(SafeAreaScrollView)(
  () => ({
    root: {},
    container: {
      paddingTop: 20,
      paddingHorizontal: 10,
    },
  }),
  (_, styles) => ({
    contentContainerStyle: styles.container,
  }),
);

const WalletLimitsWarningText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 20,
    color: theme.palette.textSecondary,
  },
}));

const ChangeLimitInput = variance(DecimalInput)(() => ({
  root: {},
}));

const ChangeLimitSubmitButton = variance(Button)(() => ({
  root: {
    marginTop: 'auto',
  },
}));
