import {
  sized,
  useRoot,
  useStrings,
  useStyles,
  variance,
} from '@ncwallet-app/core/src';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  CenteredCardLayout,
  LG_BREAKPOINT,
  SafeAreaInset,
  SafeAreaLayout,
} from '@ncwallet-app/ui/src';
import {
  NCWalletLogoDarkSvg,
  NCWalletLogoSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {
  CheckRoundedSVG,
  SmoothStarSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';
import Animated from 'react-native-reanimated';

import {EXTRA_BOTTOM_OFFSET} from '../constants';

export type CongratulationsScreenProps = {
  goToApp: () => void;
  appVersion?: string;
};

export const CongratulationsScreen = observer(
  (props: CongratulationsScreenProps) => {
    const {goToApp} = props;
    const strings = useStrings();

    return (
      <Root appVersion={props.appVersion} hideBackButton={true}>
        <Content insets={SafeAreaInset.ALL}>
          <Center>
            <IconContainer>
              <NCWalletIcon />
              <CheckRoundedIcon />
            </IconContainer>
            <Title>{strings['congratulationsScreen.title']}</Title>
            <Description>
              {strings['congratulationsScreen.description']}
            </Description>
            <Small>{strings['congratulationsScreen.additionalText']}</Small>
          </Center>
          <ButtonCustom
            variant={ButtonVariant.Highlighted}
            color={ButtonColor.Secondary}
            onPress={goToApp}
            title={strings['congratulationsScreen.start']}
          />
        </Content>
      </Root>
    );
  },
);

const NCWalletIcon = observer(() => {
  const {appearance} = useRoot();
  const Icon = appearance.isDark ? NCWalletLogoIcon : NCWalletLogoDarkIcon;
  const styles = useStyles(() => ({
    first: {
      position: 'absolute',
      bottom: 70,
      left: 0,
    },
    second: {
      position: 'absolute',
      top: 10,
      left: 30,
    },
    third: {
      position: 'absolute',
      top: 0,
      right: 30,
    },
    fourth: {
      position: 'absolute',
      bottom: 70,
      right: 0,
    },
  }));
  return (
    <IconRoot>
      <Icon />
      <Animated.View style={styles.first}>
        <SmallSmoothStar color={appearance.theme.palette.additional1} />
      </Animated.View>
      <Animated.View style={styles.second}>
        <BigSmoothStar color={appearance.theme.palette.additional2} />
      </Animated.View>
      <Animated.View style={styles.third}>
        <SmallSmoothStar color={appearance.theme.palette.additional1} />
      </Animated.View>
      <Animated.View style={styles.fourth}>
        <BigSmoothStar color={appearance.theme.palette.additional2} />
      </Animated.View>
    </IconRoot>
  );
});

const BigSmoothStar = sized(SmoothStarSvg, 25);
const SmallSmoothStar = sized(SmoothStarSvg, 20);

const NCWalletLogoIcon = sized(NCWalletLogoSvg, 110, 104);
const NCWalletLogoDarkIcon = sized(NCWalletLogoDarkSvg, 110, 104);

const Root = variance(CenteredCardLayout)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingBottom: EXTRA_BOTTOM_OFFSET,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingBottom: 50,
        paddingHorizontal: 0,
      },
    }),
  },
}));

const ButtonCustom = variance(Button)(theme => ({
  root: {
    marginTop: 'auto',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 345,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
      },
    }),
  },
}));

const Content = variance(SafeAreaLayout)(theme => ({
  root: {
    flex: 1,
    justifyContent: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 15,
      },
    }),
  },
}));

const IconRoot = variance(View)(() => ({
  root: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 40,
    width: 210,
    height: 160,
  },
}));

const Center = variance(View)(() => ({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    textAlign: 'center',
    fontSize: 26,
    lineHeight: 36,
    color: theme.palette.textMain,
    marginBottom: 30,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 24,
        lineHeight: 46,
        marginBottom: 10,
      },
    }),
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 26,
    color: theme.palette.textAdditional1,
    marginBottom: 12,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 10,
        fontSize: 18,
        lineHeight: 28,
      },
    }),
  },
}));

const Small = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 26,
    color: theme.palette.primary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 16,
        lineHeight: 28,
      },
    }),
  },
}));

const IconContainer = variance(View)(() => ({
  root: {
    position: 'relative',
  },
}));

const CheckRoundedIcon = variance(CheckRoundedSVG)(
  () => ({
    root: {
      position: 'absolute',
      bottom: 30,
      right: 35,
    },
  }),
  theme => ({
    color: theme.palette.background,
  }),
);
