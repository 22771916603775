import {createStylesHook, TextInputState, useRoot} from '@ncwallet-app/core';
import type {ConfigurationValues} from '@ncwallet-app/core/src/Configuration';
import {SafeAreaInset, SafeAreaScrollView} from '@ncwallet-app/ui';
import {useHeaderHeight} from '@react-navigation/elements';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {Button, ScrollView, TextInput, View} from 'react-native';

import {EXTRA_BOTTOM_OFFSET} from '../constants';

export default observer(function EnvironmentFormScreen() {
  const {configuration} = useRoot();
  const [environment] = useState(() => new TextInputState());
  const create = useCallback(async () => {
    const patch = JSON.parse(
      environment.value ?? '',
    ) as Partial<ConfigurationValues>;
    await configuration.createEnvironment(patch);
  }, [configuration, environment]);
  const styles = useStyles();
  const header = useHeaderHeight();
  return (
    <SafeAreaScrollView
      keyboardAvoiding
      contentContainerStyle={styles.container}
      insets={SafeAreaInset.BOTTOM}
      keyboardVerticalOffset={header}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}>
      <ScrollView contentContainerStyle={styles.root} style={styles.root}>
        <TextInput style={styles.input} multiline {...environment.handlers} />
      </ScrollView>
      <View style={styles.row}>
        <Button title="CREATE" color="green" onPress={create} />
      </View>
    </SafeAreaScrollView>
  );
});

const useStyles = createStylesHook(theme => ({
  root: {
    flex: 1,
  },
  container: {
    paddingVertical: 0,
    flex: 1,
    paddingBottom: 30,
  },
  input: {
    padding: 15,
    paddingTop: 30,
    flexGrow: 1,
    flexShrink: 1,
    margin: 0,
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.palette.textPrimary,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));
