import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import NotFoundScreen from '../../../../../screens/NotFoundScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type FallBackBindingProps = LargeSwitchBindingProps<'FallBack'>;

export default observer(function FallBackBinding({
  navigation,
}: FallBackBindingProps) {
  const toMain = useCallback(() => {
    navigation.replace('Root');
  }, [navigation]);
  return <NotFoundScreen toMain={toMain} />;
});
