import {colored, sized, useStrings, variance} from '@ncwallet-app/core';
import {BaseSkeleton, TouchableOpacity} from '@ncwallet-app/ui';
import {PlusSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type CurrencyListHeaderProps = {
  getWalletCount: () => number | undefined;
  onAddCurrencyPress: () => void;
};

export const WalletListHeader = observer(function WalletListHeader(
  props: CurrencyListHeaderProps,
) {
  const strings = useStrings();
  const total = props.getWalletCount();
  return (
    <Container>
      <Title>
        <TitleText>{strings['walletsScreen.currencyListTitle']}</TitleText>
        {total !== undefined ? (
          <TotalContainer>
            <Total>{total}</Total>
          </TotalContainer>
        ) : (
          <TotalPlaceholder />
        )}
      </Title>
      <AddCurrency onPress={props.onAddCurrencyPress}>
        <AddCurrencyIcon />
      </AddCurrency>
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.uiSecondary,
  },
}));

const Title = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const TitleText = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('bold'),
    fontSize: 18,
  },
}));

const TotalContainer = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 26,
    height: 20,
    marginTop: 3,
    marginLeft: 5,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiPrimary,
  },
}));

const Total = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textAdditional3,
    fontSize: 11,
  },
}));

const TotalPlaceholder = variance(BaseSkeleton)(() => ({
  root: {
    marginTop: 3,
    marginLeft: 5,
    width: 24,
    height: 20,
    borderRadius: 10,
  },
}));

const AddCurrency = variance(TouchableOpacity)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiPrimary,
  },
}));

const AddCurrencyIcon = sized(colored(PlusSvg, '#4285F4'), 15, 15);
