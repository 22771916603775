import {
  formatCryptoValue,
  sized,
  TransactionFilterKind,
  useDateFormatting,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {
  CurrencyCircleLogo,
  Pressable,
  TransactionIcon,
} from '@ncwallet-app/ui/';
import {HelpSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {BigNumber} from 'bignumber.js';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Linking, Text, View} from 'react-native';

import {useGetTransactionStatusText} from '../../shared/useGetTransactionStatusText';
import type {HistoryDetailItem} from './HistoryDetailItem';

export type DetailsHeaderProps = Pick<
  HistoryDetailItem,
  | 'cryptoCode'
  | 'cryptoName'
  | 'cryptoValue'
  | 'status'
  | 'cryptoFractionDigits'
  | 'kind'
  | 'date'
  | 'uri'
>;

export default observer(function DetailsHeader(props: DetailsHeaderProps) {
  const {
    cryptoValue,
    cryptoCode,
    cryptoName,
    status,
    date,
    kind,
    cryptoFractionDigits,
  } = props;
  const {formatDateTimeWithoutYear} = useDateFormatting();
  const getStatusText = useGetTransactionStatusText();
  const theme = useTheme();

  const handleHelpPress = useCallback(() => {
    void Linking.openURL(
      'https://ncwallet.net/faq/how-can-i-check-the-transaction-status%3F-what-do-the-statuses-mean/',
    );
  }, []);

  return (
    <DetailHeaderView>
      <CurrencyCircleLogo code={cryptoCode} size={40} />
      <HeaderBodyView>
        <HeaderBodyRowView>
          <HeaderCryptoName>{cryptoName}</HeaderCryptoName>
          <HeaderCryptoValue negative={BigNumber(cryptoValue).isNegative()}>
            {BigNumber(cryptoValue).isPositive() ? '+' : ''}
            {formatCryptoValue(cryptoValue, cryptoFractionDigits)} {cryptoCode}
          </HeaderCryptoValue>
        </HeaderBodyRowView>
        <SizedBox />
        <HeaderBodyRowView>
          <TransactionIcon size={20} kind={props.kind} />
          <HeaderCryptoDate>{formatDateTimeWithoutYear(date)}</HeaderCryptoDate>
          {kind !== TransactionFilterKind.Exchange && (
            <HelpPressable onPress={handleHelpPress}>
              <HeaderOperationType>{getStatusText(status)}</HeaderOperationType>
              <HelpIcon color={theme.palette.uiAdditional1} />
            </HelpPressable>
          )}
        </HeaderBodyRowView>
      </HeaderBodyView>
    </DetailHeaderView>
  );
});

const DetailHeaderView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    padding: 15,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 2,
  },
}));

const HeaderBodyView = variance(View)(() => ({
  root: {
    flex: 1,
    marginLeft: 15,
  },
}));

const HeaderBodyRowView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const HeaderCryptoName = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
  },
}));

const HeaderCryptoValue = variance(Text)(theme => ({
  root: {
    marginLeft: 'auto',
    ...theme.fontByWeight('700'),
    color: theme.palette.success,
  },
  negative: {
    color: theme.palette.error,
  },
}));

const HeaderCryptoDate = variance(Text)(theme => ({
  root: {
    marginLeft: 5,
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 13,
  },
}));

const HeaderOperationType = variance(Text)(theme => ({
  root: {
    marginRight: 5,
    ...theme.fontByWeight('500'),
    color: theme.palette.textPrimary,
    fontSize: 13,
    textTransform: 'uppercase',
  },
}));

const HelpPressable = variance(Pressable)(() => ({
  root: {
    flexDirection: 'row',
    marginLeft: 'auto',
  },
}));

const HelpIcon = sized(HelpSvg, 17);
