import {TransactionFilterKind} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptTransactionKindToFilterHistoryContainer from '../../../../../CommonNavigationContainers/containers/PromptTransactionKindToFilterHistoryContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptTransactionKindToFilterHistoryBindingProps =
  LargeSwitchBindingProps<'PromptTransactionKindToFilterHistory'>;

export default observer(function PromptTransactionKindToFilterHistoryBinding(
  props: PromptTransactionKindToFilterHistoryBindingProps,
) {
  const {navigation, route} = props;
  const onSelect = useCallback(
    (transactionFilterKind: TransactionFilterKind) => {
      navigation.navigate('ListHistoryFilters', {
        ...route.params,
        transactionFilterKind:
          transactionFilterKind === TransactionFilterKind.All
            ? undefined
            : transactionFilterKind,
      });
    },
    [navigation, route.params],
  );

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({index: 0, routes: [{name: 'ListHistoryFilters'}]});
  }, [navigation]);

  return (
    <PromptTransactionKindToFilterHistoryContainer
      onSelect={onSelect}
      onBack={onBack}
    />
  );
});
