import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {useIsDimensions} from '@ncwallet-app/ui';
import {autorun, runInAction} from 'mobx';
import {useEffect} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useRequestWalletLayoutDataOnLg = () => {
  const {walletsState, lastTransactionStore, walletStore} = useRoot();

  const getIsReady = useGetIsReadyToMakeRequests();
  const isLg = useIsDimensions('lg');
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && isLg) {
          runInAction(() => {
            void walletsState.refresh();
          });
        }
      }),
    [getIsReady, isLg, walletsState, lastTransactionStore, walletStore],
  );

  useEffect(() => {
    walletsState.activateBackgroundRefresh();
    return () => {
      walletsState.deactivateBackgroundRefresh();
    };
  }, [walletsState]);

  return walletsState;
};
