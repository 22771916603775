import type {WalletId} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {autorun, runInAction} from 'mobx';
import {useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../hooks';
import {AddressNetworkSelectionBindingState} from './AddressNetworkSelectionBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const useAddressNetworkSelectionBindingState = (
  walletId: WalletId,
  network?: AddressNetwork,
) => {
  const root = useRoot();
  const [state] = useState(() => new AddressNetworkSelectionBindingState(root));

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.setAddressParams(walletId, network);
          });
        }
      }),
    [getIsFocused, getIsReady, network, state, walletId],
  );

  return state;
};
