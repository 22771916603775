import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {Input, TouchableOpacity} from '@ncwallet-app/ui';
import {CheckSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';
import {useThrottledCallback} from 'use-debounce';

export type CommentFormProps = {
  comment: string;
  onChangeComment: (comment: string) => void;
  onSavePress: () => void;
  onFocus?:
    | ((options?: {x: number; y: number; animated: boolean}) => void)
    | undefined;
};

export const CommentForm = observer(function (props: CommentFormProps) {
  const {comment, onSavePress, onChangeComment} = props;
  const theme = useTheme();
  const strings = useStrings();
  const DEBOUNCE_INTERVAL = 3000;

  const saveCommentThrottled = useThrottledCallback(
    onSavePress,
    DEBOUNCE_INTERVAL,
    {
      trailing: false,
    },
  );

  const renderCommentAction = useCallback(() => {
    return (
      <CommentActionView>
        <CommentActionTouchable onPress={saveCommentThrottled}>
          <CheckSvg color={theme.palette.info} />
        </CommentActionTouchable>
      </CommentActionView>
    );
  }, [saveCommentThrottled, theme.palette.info]);

  return (
    <View>
      <Input
        label={strings['receiveCryptoScreen.commentFormLabel']}
        placeholder={strings['receiveCryptoScreen.commentFormPlaceholder']}
        value={comment}
        autoFocus={false}
        onPressIn={() => props.onFocus?.({x: 0, y: 10000, animated: true})}
        onChangeText={onChangeComment}
        placeholderTextColor={theme.palette.textAdditional3}
        Slot={renderCommentAction}
      />
    </View>
  );
});

const CommentActionView = variance(View)(theme => ({
  root: {
    justifyContent: 'center',
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.uiSecondary,
  },
}));

const CommentActionTouchable = variance(TouchableOpacity)(theme => ({
  root: {
    padding: 15,
    backgroundColor: theme.palette.uiPrimary,
  },
}));
