import type {
  CurrencyDescription,
  CurrencyStore,
  JsonRpcClient,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  WalletId,
  WalletLimit,
  WalletStore,
} from '@ncwallet-app/core';
import {
  toCurrencyDescriptionFromCrypto,
  WalletLimitPeriod,
  WalletLimitStatus,
} from '@ncwallet-app/core';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

// eslint-disable-next-line import-x/prefer-default-export
export class ShowLimitBindingState {
  @observable _limits: WalletLimit[] | undefined;

  @computed get _dailyActiveLimit(): WalletLimit | undefined {
    return this._limits?.find(
      l =>
        l.period === WalletLimitPeriod.Daily &&
        l.status === WalletLimitStatus.Active,
    );
  }

  @computed get _waitingForActivationDailyLimit(): WalletLimit | undefined {
    return this._limits?.find(
      l =>
        l.period === WalletLimitPeriod.Daily &&
        l.status === WalletLimitStatus.WaitingForActivation,
    );
  }

  @computed get _activeMonthlyLimit(): WalletLimit | undefined {
    return this._limits?.find(
      l =>
        l.period === WalletLimitPeriod.Monthly &&
        l.status === WalletLimitStatus.Active,
    );
  }
  @computed get _waitingForActivationMonthlyLimit(): WalletLimit | undefined {
    return this._limits?.find(
      l =>
        l.period === WalletLimitPeriod.Monthly &&
        l.status === WalletLimitStatus.WaitingForActivation,
    );
  }

  @computed get currency(): CurrencyDescription | undefined {
    const limit = this._dailyActiveLimit ?? this._activeMonthlyLimit;
    const currency =
      limit && this._root.currencyStore.getCryptoCurrency(limit.currency);
    return currency && toCurrencyDescriptionFromCrypto(currency);
  }

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
    },
  ) {
    makeObservable(this);
  }

  getDailyActiveLimit = () => this._dailyActiveLimit;
  getWaitingForActivationDailyLimit = () =>
    this._waitingForActivationDailyLimit;
  getActiveMonthlyLimit = () => this._activeMonthlyLimit;
  getWaitingForActivationMonthlyLimit = () =>
    this._waitingForActivationMonthlyLimit;

  async refresh(walletId: WalletId) {
    void this._root.currencyStore.refreshCryptoCurrencies();
    // noinspection ES6MissingAwait
    const res = await this._root.ncWalletJsonRpcClient.call('wallets.limits', {
      wallets_ids: [walletId],
    });
    if (res.success) {
      runInAction(() => {
        this._limits = res.right.limits;
      });
    }
  }

  @action.bound
  reset() {
    this._limits = undefined;
  }
}
