import type {
  CurrenciesRateHistoryItem,
  CurrencyCode,
  CurrencyDescription,
  DecimalString,
  RateHistoryPeriod,
} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core';
import {
  LG_BREAKPOINT,
  SafeAreaInset,
  SafeAreaScrollView,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef, useState} from 'react';
import type {LayoutChangeEvent} from 'react-native';
import {View} from 'react-native';

import {EXTRA_BOTTOM_OFFSET} from '../constants';
import CurrencyActionList from './CurrencyActionList';
import {CurrencyGraph} from './CurrencyGraph';
import Header from './CurrencyGraph/Header';
import {WalletInfo} from './WalletInfo';

export type CurrencyScreenProps = {
  walletName: string | undefined;
  address: string | undefined;
  onCopy: () => void;
  cryptoCurrency: CurrencyDescription | undefined;
  cryptoValue: DecimalString | undefined;
  fiatCurrency: CurrencyDescription | undefined;
  fiatValue: DecimalString | undefined;
  fiatRate: DecimalString | undefined;
  activePeriod: RateHistoryPeriod;
  rateHistory: CurrenciesRateHistoryItem[] | undefined;
  isGraphLoading: boolean;

  receiveEnabled: boolean;
  exchangeEnabled: boolean;
  sendEnabled: boolean;
  buyEnabled: boolean;
  onBuyPress: () => void;
  onReceivePress: () => void;
  onSendPress: () => void;
  onExchangePress: () => void;
  onHistoryPress: () => void;
  onGraphPeriodChange: (period: RateHistoryPeriod) => void;
};

export default observer(function CurrencyScreen(props: CurrencyScreenProps) {
  const {
    walletName,
    cryptoCurrency,
    cryptoValue,
    fiatCurrency,
    fiatValue,
    rateHistory,
    onReceivePress,
    onSendPress,
    onBuyPress,
    onExchangePress,
    onHistoryPress,
    onGraphPeriodChange,
    address,
    onCopy,
  } = props;

  const isLg = useIsDimensions('lg');
  const [chartWidth, setChartWidth] = useState(0);

  const onContentLayout = useCallback((event: LayoutChangeEvent) => {
    setChartWidth(event.nativeEvent.layout.width);
  }, []);

  const firstPrice =
    rateHistory && rateHistory.length > 0 ? rateHistory[0].rate : undefined;

  const ref = useRef<View>(null);
  return (
    <RootView
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: isLg ? 0 : EXTRA_BOTTOM_OFFSET}}>
      <InnerView ref={ref}>
        <Container onLayout={onContentLayout}>
          {isLg && (
            <Header
              firstPrice={firstPrice}
              fiatRate={props.fiatRate}
              cryptoCurrency={cryptoCurrency}
              fiatCurrency={fiatCurrency}
              cryptoValue={cryptoValue}
              fiatValue={fiatValue}
              address={address}
              onCopy={onCopy}
            />
          )}
          <CurrencyGraph
            firstPrice={firstPrice}
            rateHistory={rateHistory}
            chartWidth={chartWidth}
            isLoading={props.isGraphLoading}
            activePeriod={props.activePeriod}
            fiatRate={props.fiatRate}
            fiatCurrency={fiatCurrency}
            cryptoCurrency={cryptoCurrency}
            cryptoValue={cryptoValue}
            fiatValue={fiatValue}
            onPeriodChange={onGraphPeriodChange}
            Container={ref.current}
          />
          {!isLg && (
            <WalletInfo
              address={address}
              onCopy={onCopy}
              walletName={walletName}
              cryptoCurrency={cryptoCurrency}
              cryptoValue={cryptoValue}
              fiatCurrency={fiatCurrency}
              fiatValue={fiatValue}
            />
          )}

          <CurrencyActionList
            currencyCode={cryptoCurrency?.code as CurrencyCode | undefined}
            onExchangePress={onExchangePress}
            currencyName={cryptoCurrency?.name}
            onSendPress={onSendPress}
            onHistoryPress={onHistoryPress}
            onReceivePress={onReceivePress}
            receiveEnabled={props.receiveEnabled}
            exchangeEnabled={props.exchangeEnabled}
            sendEnabled={props.sendEnabled}
            onBuyPress={onBuyPress}
            buyEnabled={props.buyEnabled}
          />
        </Container>
      </InnerView>
    </RootView>
  );
});

const Container = variance(View)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        height: 470,
      },
    }),
  },
}));

const RootView = variance(SafeAreaScrollView)(theme => ({
  root: {
    height: '100%',
    paddingTop: 20,
    backgroundColor: theme.palette.background,
    flex: 1,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.uiPrimary,
        paddingTop: 0,
        overflow: 'visible',
      },
    }),
  },
}));

const InnerView = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: undefined,
        backgroundColor: theme.palette.background,
        borderRadius: 10,
        padding: 30,
        ...theme.bar(10),
      },
    }),
  },
}));
