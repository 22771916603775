import type {CurrencyDescription} from '@ncwallet-app/core';
import {useStrings, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Pressable, Text, View} from 'react-native';

import MinerFeeSlider from '../SendCommisionScreen/MinerFeeSlider';
import {CommissionSwitcher} from './CommissionSwitcher';

type CommissionViewProps = {
  cryptoCurrency: CurrencyDescription | undefined;
  getHasNoCommission: () => boolean;
  setHasNoCommission: (c: boolean) => void;
  commissionSwitcherHidden: boolean;
  isEnoughAmountForFreeSend: boolean;
  fees?: string[] | undefined;
  recommendedFee?: string | undefined;
  fee?: string;
  setFee: (value: string) => void;
  minFreeAmount: string | undefined;
};

export default observer(function CommissionView(props: CommissionViewProps) {
  const strings = useStrings();
  const feeEditingHidden = !props.fees || props.fees.length <= 1;
  const feeEditingDisabled = props.getHasNoCommission();
  return (
    <Root>
      <CommissionSwitcher
        minFreeAmount={props.minFreeAmount}
        isEnoughAmountForFreeSend={props.isEnoughAmountForFreeSend}
        getHasNoCommission={props.getHasNoCommission}
        setHasNoCommission={props.setHasNoCommission}
      />
      <NetworkFee>
        <Title>{strings['sendCrypto.minerFee.title']}</Title>
        <Value>
          {props.fee} {props.cryptoCurrency?.code ?? ''}
        </Value>
      </NetworkFee>

      {!feeEditingHidden && (
        <>
          <MinerFeeSlider
            sliderValue={0}
            onSliderValueChange={props.setFee}
            disabled={feeEditingDisabled}
            fees={props.fees?.length === 1 ? undefined : props.fees}
          />
          <Row>
            <Pressable
              disabled={feeEditingDisabled}
              onPress={() => {
                props.setFee(props.recommendedFee || '0');
              }}>
              <Recommended disabled={feeEditingDisabled}>
                {strings['sendCrypto.commission.recommended']}
              </Recommended>
            </Pressable>
            <Value disabled={feeEditingDisabled}>
              {props.recommendedFee ?? '0'} {props.cryptoCurrency?.code ?? ''}
            </Value>
          </Row>
        </>
      )}
    </Root>
  );
});

const Root = variance(View)(theme => ({
  root: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    overflow: 'hidden',
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.textPrimary,
  },
}));

const Value = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    color: theme.palette.textAdditional2,
  },
  disabled: {
    opacity: 0.5,
  },
}));

const NetworkFee = variance(View)(theme => ({
  root: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.uiPrimary,
  },
}));

const Row = variance(View)(() => ({
  root: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Recommended = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.info,
  },
  disabled: {
    opacity: 0.5,
  },
}));
