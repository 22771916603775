import {useTheme, variance} from '@ncwallet-app/core';
import {
  SafeAreaInset,
  SafeAreaLayout,
  TouchableOpacity,
} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type UserSettingsHeaderProps = {
  name: string;
  id: string;
  onClosePress?: () => void;
  onCopyPress?: () => void;
};
export default observer(function UserSettingsHeader(
  props: UserSettingsHeaderProps,
) {
  const theme = useTheme();

  return (
    <Root insets={SafeAreaInset.TOP}>
      <Container>
        <CrossIconContainer onPress={props.onClosePress}>
          <CrossSvg color={theme.palette.whitey} />
        </CrossIconContainer>
        <RightContainer>
          <Info>
            <UserName>{props.name}</UserName>
          </Info>
        </RightContainer>
      </Container>
    </Root>
  );
});

const Root = variance(SafeAreaLayout)(theme => ({
  root: {
    backgroundColor: theme.palette.primary,
  },
}));

const CrossIconContainer = variance(TouchableOpacity)(() => ({
  root: {},
}));

const Container = variance(View)(() => ({
  root: {
    minHeight: 50,
    flexDirection: 'row',
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,
    position: 'relative',
    alignItems: 'center',
  },
}));

const RightContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const Info = variance(View)(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -32, //cross icon width, compensate left offset
  },
}));

const UserName = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.whitey,
    fontSize: 16,
    lineHeight: 19,
  },
}));
