import type {
  Ip,
  IpInfoId,
} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import type {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';

export enum SessionKind {
  ListSecuritySettings,
  ListAllowedIps,
  ListBlockedIps,
  PromptUpdateIp,
  ListSessionHistory,
  ListActiveSessions,
  PromptToAddIp,
  PromptToDeleteIp,
  PromptOtpToDeleteIp,
  PromptOtpToUpdateIp,
  PromptOtpToAddIp,
  PromptToClearAllowedList,
  PromptOtpToClearAllowedList,
}

export type SessionCardState =
  | {
      kind:
        | SessionKind.ListSecuritySettings
        | SessionKind.ListBlockedIps
        | SessionKind.ListAllowedIps
        | SessionKind.ListSessionHistory
        | SessionKind.ListActiveSessions
        | SessionKind.PromptToClearAllowedList
        | SessionKind.PromptOtpToClearAllowedList;
    }
  | {kind: SessionKind.PromptToAddIp; type: IpType}
  | {
      kind: SessionKind.PromptToDeleteIp;
      id: IpInfoId;
      ip: Ip;
      type: IpType;
    }
  | {
      kind: SessionKind.PromptOtpToDeleteIp;
      id: IpInfoId;
      type: IpType;
    }
  | {
      kind: SessionKind.PromptUpdateIp;
      type: IpType;
      id: IpInfoId;
      ip: Ip;
      name: string;
    }
  | {
      kind: SessionKind.PromptOtpToUpdateIp;
      id: IpInfoId;
      name: string;
      ip: string;
      type: IpType;
    }
  | {
      kind: SessionKind.PromptOtpToAddIp;
      ip: string;
      name: string;
      type: IpType;
    };
