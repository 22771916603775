import ProfileSettingsItem from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/ProfileSettingsItem';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {useStrings} from '../../Root';

export type ProfileEmailButtonProps = {
  getTitle: () => string | undefined;
  getError: () => boolean | undefined;
  onEmailPress: () => void;
  onLinkToEmailPress: () => void;
  selected?: boolean;
};

const ProfileEmailButton = observer(
  ({getTitle, getError, onEmailPress}: ProfileEmailButtonProps) => {
    const strings = useStrings();
    return (
      <ProfileSettingsItem
        disabled
        label={strings['profileSettingsScreen.emailLabel']}
        getTitle={getTitle}
        onPress={onEmailPress}
        getError={getError}
      />
    );
  },
);

export default ProfileEmailButton;
