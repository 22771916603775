import extractPathChain from './extractPathChain';
import packPathChain from './packPathChain';
import type {Path} from './Path';
import type {PathChain} from './PathChain';

export default function popPathFromChain(current: Path): Path | undefined {
  const chain = extractPathChain(current);
  if (chain.length < 2) {
    return undefined;
  }
  const rest = chain.slice(0, -1) as PathChain<true>;
  return packPathChain(rest);
}
