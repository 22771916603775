import {useGetIsReadyToShowUserInterface} from '@ncwallet-app/core/src/AppStateHelper';
import type {
  LargeSwitchParamList,
  MainRouteName,
  SettingsRouteName,
} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {observer} from 'mobx-react-lite';
import type {ComponentType} from 'react';
import React from 'react';

import ListLogsContainer from '../../../../../CommonNavigationContainers/containers/ListLogsContainer';
import ListSignOutLogsContainer from '../../../../../CommonNavigationContainers/containers/ListSignOutLogsContainer';
import PromptWalletGroupToFilterHistoryContainer from '../../../../../CommonNavigationContainers/containers/PromptWalletGroupToFilterHistoryContainer';
import EnvironmentFormScreen from '../../../../../screens/DebugScreen/EnvironmentFormScreen';
import EnvironmentListScreen from '../../../../../screens/DebugScreen/EnvironmentListScreen';
import PromptSelectTwoFaProviderBinding from '../../../../../SmallNavigationRoot/SmallHomeStack/bindings/PromptSelectTwoFaProviderBinding';
import TwoFaWelcomeBinding from '../../../../../SmallNavigationRoot/SmallHomeStack/bindings/TwoFaWelcomeBinding';
import MainLayoutContainer from '../components/MainLayoutContainer';
import SettingsLayoutContainer from '../components/SettingsLayoutContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';
import DebugBinding from './DebugBinding';
import FallBackBinding from './FallBackBinding';
import GeneratePayMeLinkBinding from './GeneratePayMeLinkBinding';
import ListAffiliateSettingsBinding from './ListAffiliateSettingsBinding';
import ListHistoryBinding from './ListHistoryBinding';
import ListHistoryFiltersBinding from './ListHistoryFiltersBinding';
import ListInputAddressesBinding from './ListInputAddressesBinding';
import ListLimitsBinding from './ListLimitsBinding/ListLimitsBinding';
import ListNotificationSettingsBinding from './ListNotificationSettingsBinding';
import ListQrCodeHistoryBinding from './ListQrCodeHistoryBinding';
import ListSecuritySettingsBinding from './ListSecuritySettingsBinding';
import ListWalletsBinding from './ListWalletsBinding';
import NotifyAboutSuccessfulExchangeBinding from './NotifyAboutSuccessfulExchangeBinding';
import NotifyAboutSuccessfulSendingBinding from './NotifyAboutSuccessfulSendingBinding';
import PromptAddressFormatBinding from './PromptAddressFormatBinding';
import PromptCommissionForSendingBinding from './PromptCommissionForSendingBinding';
import PromptConfirmationForExchangeBinding from './PromptConfirmationForExchangeBinding';
import PromptConfirmationToSendCryptoBinding from './PromptConfirmationToSendCryptoBinding';
import PromptCryptoToExchangeBinding from './PromptCryptoToExchangeBinding';
import PromptCryptoToReceiveBinding from './PromptCryptoToReceiveBinding';
import PromptCryptoToSendBinding from './PromptCryptoToSendBinding';
import PromptExchangeReceiptBinding from './PromptExchangeReceiptBinding';
import PromptInputNetworkBinding from './PromptInputNetworkBinding';
import PromptNewWalletBinding from './PromptNewWalletBinding';
import PromptOtpToExchangeBinding from './PromptOtpToExchangeBinding';
import PromptOtpToSendCryptoBinding from './PromptOtpToSendCryptoBinding';
import PromptOutputAddressBinding from './PromptOutputAddressBinding';
import PromptOutputNetworkBinding from './PromptOutputNetworkBinding';
import PromptPeriodToFilterHistoryBinding from './PromptPeriodToFilterHistoryBinding';
import PromptReceiveNetworkBinding from './PromptReceiveNetworkBinding';
import PromptSourceCryptoToExchangeBinding from './PromptSourceCryptoToExchangeBinding';
import PromptTransactionKindToFilterHistoryBinding from './PromptTransactionKindToFilterHistoryBinding';
import PromptWalletToFilterHistoryBinding from './PromptWalletToFilterHistoryBinding';
import RedirectToSendBinding from './RedirectToSendBinding';
import ShowProfileBinding from './ShowProfileBinding';
import ShowQrToReceiveCryptoBinding from './ShowQrToReceiveCryptoBinding';
import ShowTransactionBinding from './ShowTransactionBinding';
import ShowWalletBinding from './ShowWalletBinding';

export type CompositeSwitchBindingProps = LargeSwitchBindingProps<
  keyof LargeSwitchParamList
>;

export default observer(function CompositeSwitchBinding(
  props: CompositeSwitchBindingProps,
) {
  const {route} = props;
  const getIsReady = useGetIsReadyToShowUserInterface();
  const isReady = getIsReady();
  const Binding = bindingMap[route.name];
  const screen = Binding && <Binding {...props} />;
  switch (route.name) {
    case 'ListNotificationSettings':
    case 'TwoFaWelcome':
    case 'ShowProfile':
    case 'ListSecuritySettings':
    case 'ListLimits':
    case 'PromptAffiliateProgram':
      return (
        <SettingsLayoutContainer
          isReady={isReady}
          {...(props as LargeSwitchBindingProps<SettingsRouteName>)}>
          {screen}
        </SettingsLayoutContainer>
      );
  }
  return (
    <MainLayoutContainer
      isReady={isReady}
      {...(props as LargeSwitchBindingProps<MainRouteName>)}>
      {screen}
    </MainLayoutContainer>
  );
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const bindingMap: Partial<Record<string, ComponentType<any>>> = {
  FallBack: FallBackBinding,
  ListNotificationSettings: ListNotificationSettingsBinding,
  ListSecuritySettings: ListSecuritySettingsBinding,
  ListWallets: ListWalletsBinding,
  PromptNewWallet: PromptNewWalletBinding,
  ShowProfile: ShowProfileBinding,
  ListLimits: ListLimitsBinding,
  ShowWallet: ShowWalletBinding,
  Debug: DebugBinding,
  PromptCryptoToReceive: PromptCryptoToReceiveBinding,
  PromptCryptoToSend: PromptCryptoToSendBinding,
  PromptSourceCryptoToExchange: PromptSourceCryptoToExchangeBinding,
  ListHistory: ListHistoryBinding,
  ListInputAddresses: ListInputAddressesBinding,
  ShowQrToReceiveCrypto: ShowQrToReceiveCryptoBinding,
  PromptAddressFormat: PromptAddressFormatBinding,
  PromptInputNetwork: PromptInputNetworkBinding,
  PromptOutputAddress: PromptOutputAddressBinding,
  PromptOutputNetwork: PromptOutputNetworkBinding,
  PromptCommissionForSending: PromptCommissionForSendingBinding,
  PromptConfirmationToSendCrypto: PromptConfirmationToSendCryptoBinding,
  PromptOtpToSendCrypto: PromptOtpToSendCryptoBinding,
  NotifyAboutSuccessfulSending: NotifyAboutSuccessfulSendingBinding,
  PromptExchangeReceipt: PromptExchangeReceiptBinding,
  PromptCryptoToExchange: PromptCryptoToExchangeBinding,
  PromptConfirmationForExchange: PromptConfirmationForExchangeBinding,
  PromptOtpToExchange: PromptOtpToExchangeBinding,
  NotifyAboutSuccessfulExchange: NotifyAboutSuccessfulExchangeBinding,
  ListHistoryFilters: ListHistoryFiltersBinding,
  PromptWalletGroupToFilterHistory: PromptWalletGroupToFilterHistoryContainer,
  PromptWalletToFilterHistory: PromptWalletToFilterHistoryBinding,
  PromptTransactionKindToFilterHistory:
    PromptTransactionKindToFilterHistoryBinding,
  PromptPeriodToFilterHistory: PromptPeriodToFilterHistoryBinding,
  ShowTransaction: ShowTransactionBinding,
  ListQrCodeHistory: ListQrCodeHistoryBinding,
  ListLogs: ListLogsContainer,
  ListSignOutReasonLogs: ListSignOutLogsContainer,
  ListEnvironment: EnvironmentListScreen,
  PromptEnvironmentForm: EnvironmentFormScreen,
  PromptReceiveNetwork: PromptReceiveNetworkBinding,
  RedirectToSend: RedirectToSendBinding,
  PromptAffiliateProgram: ListAffiliateSettingsBinding,
  TwoFaWelcome: TwoFaWelcomeBinding,
  PromptSelectTwoFaProvider: PromptSelectTwoFaProviderBinding,
  GeneratePayMeLink: GeneratePayMeLinkBinding,
};
