import type {CurrencyDescription, RateHistoryPeriod} from '@ncwallet-app/core';
import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import type {SharedValue} from 'react-native-reanimated';
import type {Path, Vector} from 'react-native-redash';

import type {CurrencyGraphData} from './CurrencyGraphData';
import DotCursor from './DotCursor';
import GestureHandler from './GestureHandler';
import Line from './Line';
import Tooltip from './Tooltip';

export type ChartHelpersProps = {
  cursorTranslation: Vector<SharedValue<number>>;
  isActiveCursor: SharedValue<boolean>;
  chartWidth: number;
  currentPath?: Path;
  currentGraphData?: CurrencyGraphData;
  activePeriod: RateHistoryPeriod;
  userBaseFiat: CurrencyDescription | undefined;
  showInfo: SharedValue<boolean>;
  setHelperToEnd?: () => void;
};

export default observer(function ChartHelpers(props: ChartHelpersProps) {
  const {
    cursorTranslation,
    isActiveCursor,
    currentPath,
    chartWidth,
    currentGraphData,
    activePeriod,
    userBaseFiat,
    showInfo,
  } = props;
  return (
    <RootView>
      <Line
        cursorTranslation={cursorTranslation}
        isActiveCursor={isActiveCursor}
      />
      <DotCursor
        cursorTranslation={cursorTranslation}
        isActiveCursor={isActiveCursor}
      />
      {currentPath && currentGraphData && (
        <>
          <Tooltip
            minRate={currentGraphData.minRate}
            maxRate={currentGraphData.maxRate}
            fromDate={currentGraphData.fromDate}
            toDate={currentGraphData.toDate}
            cursorTranslation={cursorTranslation}
            isActiveCursor={showInfo}
            width={chartWidth}
            activePeriod={activePeriod}
            userBaseFiat={userBaseFiat}
          />
          <GestureHandler
            cursorTranslation={cursorTranslation}
            showInfo={showInfo}
            currentPath={currentPath}
            width={chartWidth}
          />
        </>
      )}
    </RootView>
  );
});

const RootView = variance(View)(() => ({
  root: {
    ...StyleSheet.absoluteFillObject,
  },
}));
