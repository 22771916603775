import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';

import type {SettingsItemProps} from '../UserSettingsScreens/SharedComponents/SettingsItem';
import {
  ItemCol,
  ItemLabel,
  ItemTouchableWithLabel,
  TitleWithLabel,
} from '../UserSettingsScreens/SharedComponents/SettingsItem';

export default observer(function IdentityVerificationItem(
  props: SettingsItemProps,
) {
  const {onPress, title, error, label, disabled} = props;

  return (
    <ItemTouchableWithLabel
      onPress={onPress}
      Icon={ArrowRightSvg}
      disabled={disabled}>
      <ItemCol>
        {!!label && <ItemLabel>{label}</ItemLabel>}
        <TitleWithLabel error={error}>{title}</TitleWithLabel>
      </ItemCol>
    </ItemTouchableWithLabel>
  );
});
