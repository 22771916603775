import type {CurrencyStore} from '@ncwallet-app/core';
import {
  FULFILLED,
  toCurrencyDescriptionFromCrypto,
  toCurrencyDescriptionFromFiat,
} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';

import type {ProfileUser} from '../../../../screens/ProfileSettingsScreen';

// eslint-disable-next-line import-x/prefer-default-export
export class SmProfileSettingsBindingState {
  constructor(
    private readonly _root: {
      accountStore: AccountStore;
      readonly currencyStore: CurrencyStore;
    },
  ) {}

  getUser = (): ProfileUser | undefined => {
    const account =
      this._root.accountStore.state?.status === FULFILLED &&
      this._root.accountStore.state.result;

    if (!account) {
      return undefined;
    }

    const baseFiat = this._root.currencyStore.getFiatCurrency(
      account.base_fiat,
    );
    const baseCrypto = this._root.currencyStore.getCryptoCurrency(
      account.base_crypto,
    );

    if (!baseFiat || !baseCrypto) {
      return undefined;
    }

    return {
      userId: account.user_id,
      displayName: account.name,
      email: account.email,
      telegramUsername: account.telegram_username,
      identify: 'verified',
      baseCryptoCurrency: toCurrencyDescriptionFromCrypto(baseCrypto),
      defaultFiatCurrency: toCurrencyDescriptionFromFiat(baseFiat),
      isEmailConfirmed: true,
      isIdentityVerify: true,
      isVerified: account.settings.is_verified,
      isVerificationRequired: account.settings.verification_required,
    };
  };

  async refresh() {
    void this._root.currencyStore.refreshFiatCurrencies();
    void this._root.currencyStore.refreshCryptoCurrencies();
  }

  update = (params: Parameters<AccountStore['update']>[0]) => {
    return this._root.accountStore.update(params);
  };
}
