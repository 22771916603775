import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import PromptOtpToSendCryptoContainer from '../../../../../CommonNavigationContainers/containers/PromptOtpToSendCryptoContainer';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptOtpToSendCryptoBindingProps =
  LargeSwitchBindingProps<'PromptOtpToSendCrypto'>;

export default observer(function PromptOtpToSendCryptoBinding(
  props: PromptOtpToSendCryptoBindingProps,
) {
  const {navigation, route} = props;
  const {withdrawId} = route.params;

  const notifyAboutSuccessfulSending = useCallback(() => {
    navigation.reset({
      index: 0,
      routes: [{name: 'NotifyAboutSuccessfulSending', params: route.params}],
    });
  }, [navigation, route.params]);

  const onBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <PromptOtpToSendCryptoContainer
      withdrawId={withdrawId}
      onBack={onBack}
      notifyAboutSuccessfulSending={notifyAboutSuccessfulSending}
    />
  );
});
