import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import type {InputNetworkSelectionContainerProps} from '../../../../../CommonNavigationContainers/hooks/useInputNetworkSelectionScreenProps';
import {useInputNetworkSelectionScreenProps} from '../../../../../CommonNavigationContainers/hooks/useInputNetworkSelectionScreenProps';
import NetworkSelectionScreen from '../../../../../screens/NetworkSelectionScreen/NetworkSelectionScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptInputNetworkBindingProps =
  LargeSwitchBindingProps<'PromptInputNetwork'>;

export default observer(function PromptInputNetworkBinding(
  props: PromptInputNetworkBindingProps,
) {
  const {navigation, route} = props;

  const containerProps = useMemo<InputNetworkSelectionContainerProps>(
    () => ({
      promptAddressFormat: params => {
        navigation.navigate('PromptAddressFormat', params);
      },
      showQrToReceiveCrypto: params => {
        navigation.navigate('ShowQrToReceiveCrypto', params);
      },
      params: route.params,
    }),
    [navigation, route.params],
  );

  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('PromptCryptoToReceive');
    }
  }, [navigation]);

  const screenParams = useInputNetworkSelectionScreenProps(containerProps);

  return <NetworkSelectionScreen goBack={goBack} {...screenParams} />;
});
