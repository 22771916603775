import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import type {ViewStyle} from 'react-native';
import {StyleSheet, View} from 'react-native';

import {OrangeShapeBackgroundSvg} from '../../assets/svg/colored';

// eslint-disable-next-line import-x/prefer-default-export
export const OrangeShapeBackground = observer(function OrangeShapeBackground() {
  const {windowDimensionsState} = useRoot();
  const {width} = windowDimensionsState.window;
  const boundary = useMemo<ViewStyle | undefined>(
    () => ({
      width,
      height: width >= 435 ? 230 : width * aspectRatio,
    }),
    [width],
  );

  return (
    <View style={StyleSheet.absoluteFill}>
      <OrangeShapeBackgroundSvg style={boundary} />
    </View>
  );
});

const aspectRatio = 187 / 375;
