import {useRoot, useShareAvailable, useStrings} from '@ncwallet-app/core';
import type {LargeSwitchParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {PartialState} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';
import {CommonActions} from '@react-navigation/routers';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';
import {Share} from 'react-native';

import ShowQrScreen from '../../../screens/ShowQrScreen';
import type {LargeHomeStackBindingProps} from '../LargeHomeStackBindingProps';

export type ShowQrBindingProps = LargeHomeStackBindingProps<'ShowQr'>;

export default observer(function ShowQrBinding(props: ShowQrBindingProps) {
  const {navigation, route} = props;
  const onBackPress = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }

    const switchState: PartialState<NavigationState<LargeSwitchParamList>> = {
      index: 0,
      routes: [{name: 'ShowQrToReceiveCrypto', params: route.params}],
    };
    navigation.dispatch(CommonActions.reset(switchState));
  }, [navigation, route.params]);

  useEffect(() => {
    if (!navigation.canGoBack()) {
      const switchState: PartialState<NavigationState<LargeSwitchParamList>> = {
        index: 0,
        routes: [{name: 'ShowQrToReceiveCrypto', params: route.params}],
      };
      navigation.dispatch(CommonActions.reset(switchState));
    }
  }, [navigation, route.params]);

  const {address, addressCurrency} = route.params;
  const {flashMessage} = useRoot();
  const strings = useStrings();

  const copy = useCallback(
    (data: string) => {
      void setStringAsync(data);
      flashMessage.showMessage({
        title: strings.copied,
        variant: 'success',
      });
    },
    [flashMessage, strings.copied],
  );

  const share = useCallback(async (data: string) => {
    try {
      await Share.share({
        title: 'Share this',
        message: data,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const shareShown = useShareAvailable();

  return (
    <ShowQrScreen
      shareShown={shareShown}
      walletAddress={address}
      onShare={share}
      onCopy={copy}
      onClosePress={onBackPress}
      networkCurrency={addressCurrency}
    />
  );
});
