import {useStyles, variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui/src';
import {Text} from 'react-native';

import {ItemCol, ItemTouchable} from '../../SharedComponents/SettingsItem';

export default function useLgCryptoSectionListStyle() {
  const styles = useStyles(theme => ({
    root: {
      borderWidth: 0,
    },

    list: {
      borderRadius: 8,
      backgroundColor: theme.palette.uiPrimary,
      maxHeight: 128,
      borderWidth: 1,
      borderColor: theme.palette.uiSecondary,
    },
  }));

  return styles;
}

export const ItemValueTag = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 13,
    lineHeight: 16,
    marginLeft: 'auto',
  },
}));

export const ItemRowTag = variance(ItemCol)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1,
      },
    }),
  },
}));

export const ItemTag = variance(ItemTouchable)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderBottomColor: theme.palette.uiSecondary,
      },
    }),
  },

  active: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderBottomWidth: 0,
        backgroundColor: theme.palette.additional4,
        borderRadius: 8,
      },
    }),
  },

  base: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderBottomWidth: 0,
    backgroundColor: theme.palette.additional4,
    borderRadius: 8,
  },
}));

export const EmptyListTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.uiMain,
    borderRadius: 8,
    backgroundColor: theme.palette.uiPrimary,
    maxHeight: 128,
    paddingHorizontal: 15,
    height: '100%',
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));
