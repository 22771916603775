import type {EventMapBase} from '@react-navigation/core/src/types';
import type {
  DefaultNavigatorOptions,
  ParamListBase,
} from '@react-navigation/native';
import {
  createNavigatorFactory,
  useNavigationBuilder,
} from '@react-navigation/native';
import type {StyleProp, ViewStyle} from 'react-native';
import {StyleSheet, View} from 'react-native';

import probe from '../../util/probe';
import type {SwitchNavigationState, SwitchRouterOptions} from '../SwitchRouter';
import {SwitchRouter} from '../SwitchRouter';

export type SwitchNavigationConfig = {
  contentStyle?: StyleProp<ViewStyle>;
};

export type SwitchNavigationOptions = object;

export type SwitchNavigationEventMap = EventMapBase;

type Props = DefaultNavigatorOptions<
  ParamListBase,
  SwitchNavigationState<ParamListBase>,
  SwitchNavigationOptions,
  SwitchNavigationEventMap
> &
  SwitchRouterOptions &
  SwitchNavigationConfig;

function SwitchNavigator(props: Props) {
  const {initialRouteName, children, screenOptions, contentStyle} = props;
  const {state, descriptors, NavigationContent} = useNavigationBuilder<
    SwitchNavigationState<ParamListBase>,
    SwitchRouterOptions,
    Record<string, () => void>,
    SwitchNavigationOptions,
    SwitchNavigationEventMap
  >(SwitchRouter, {
    children,
    screenOptions,
    initialRouteName,
  });

  const currentRoute = probe(state.routes, state.index);

  return (
    <NavigationContent>
      <View style={[styles.content, contentStyle]}>
        {currentRoute && (
          <View key={currentRoute.key} style={StyleSheet.absoluteFill}>
            {descriptors[currentRoute.key].render()}
          </View>
        )}
      </View>
    </NavigationContent>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
});

export default createNavigatorFactory<
  SwitchNavigationState<ParamListBase>,
  SwitchNavigationOptions,
  SwitchNavigationEventMap,
  typeof SwitchNavigator
>(SwitchNavigator);
