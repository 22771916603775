import type {NotificationPermissions} from '@ncwallet-app/core/src/NotificationPermissions';
import {action, makeObservable, observable} from 'mobx';

export default class WebNotificationPermissionsImpl
  implements NotificationPermissions
{
  @observable.ref _granted = Notification.permission === 'granted';

  constructor() {
    makeObservable(this);
  }

  get granted() {
    return this._granted;
  }

  private _onResolve = action((permission: NotificationPermission) => {
    this._granted = permission === 'granted';
  });

  ask = () => {
    return Notification.requestPermission(this._onResolve).then(
      this._onResolve,
    );
  };
}
