import {variance} from '@ncwallet-app/core/src';
import {BaseSkeleton} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export default observer(function CurrencyLimitSkeleton() {
  return (
    <LimitSkeletonView>
      <LimitSkeletonBody>
        <LimitSkeletonTop>
          <BaseSkeletonTitle width={64} height={16} />
          <BaseSkeleton width={225} height={16} />
          <BaseSkeletonProgress height={4} />
        </LimitSkeletonTop>
        <LimitsSkeletonBottom>
          <BaseSkeletonButton height={50} />
          <SizedBox />
          <BaseSkeletonButton height={50} />
        </LimitsSkeletonBottom>
      </LimitSkeletonBody>
    </LimitSkeletonView>
  );
});

const LimitSkeletonView = variance(View)(() => ({
  root: {
    padding: 15,
  },
}));

const LimitSkeletonBody = variance(View)(theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const LimitSkeletonTop = variance(View)(() => ({
  root: {
    alignItems: 'center',
  },
}));

const BaseSkeletonTitle = variance(BaseSkeleton)(() => ({
  root: {
    marginBottom: 10,
  },
}));

const BaseSkeletonProgress = variance(BaseSkeleton)(() => ({
  root: {
    marginTop: 15,
  },
}));

const LimitsSkeletonBottom = variance(View)(() => ({
  root: {
    marginTop: 20,
    flexDirection: 'row',
  },
}));

const BaseSkeletonButton = variance(BaseSkeleton)(() => ({
  root: {
    flex: 1,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    width: 20,
  },
}));
