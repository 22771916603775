import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {UserSettingsList} from '../../components';
import UserSettingsHeader from './UserSettingsHeader';

export type UserSettingsScreenProps = BaseSafeAreaProps & {
  displayName: string;
  userId: string;
  onClosePress: () => void;
  onLanguagePress: () => void;
  onProfilePress: () => void;
  onSecurityPress: () => void;
  onLimitsPress: () => void;
  onNotificationPress: () => void;
  onAffiliatePress: () => void;
  onRatePress: () => void;
  onHelpPress: () => void;
  onLogoutPress: () => void;
  goToStyle: () => void;
};

export default observer(function UserSettingsScreen(
  props: UserSettingsScreenProps,
) {
  const {
    displayName,
    userId,
    onClosePress,
    onLanguagePress,
    onAffiliatePress,
    onProfilePress,
    onSecurityPress,
    onLimitsPress,
    onNotificationPress,
    onRatePress,
    onHelpPress,
    onLogoutPress,
    goToStyle,
  } = props;
  const copyToClipboard = (): void => {
    void setStringAsync(userId.toString());
  };

  return (
    <>
      <UserSettingsHeader
        onClosePress={onClosePress}
        name={displayName}
        id={userId}
        onCopyPress={copyToClipboard}
      />
      <UserSettingsList
        goToStyle={goToStyle}
        onAffiliatePress={onAffiliatePress}
        onLanguagePress={onLanguagePress}
        onProfilePress={onProfilePress}
        onSecurityPress={onSecurityPress}
        onLimitsPress={onLimitsPress}
        onNotificationPress={onNotificationPress}
        onRatePress={onRatePress}
        onHelpPress={onHelpPress}
        onLogoutPress={onLogoutPress}
      />
    </>
  );
});
