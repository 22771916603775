import type {AvailableLanguageCode} from '@ncwallet-app/core/src/Localization/constant';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {LanguageSettingsList} from '../../../components';

export type LanguageSettingsScreenProps = BaseSafeAreaProps & {
  activeItem: AvailableLanguageCode;
  onLanguagePress: (locale: AvailableLanguageCode) => void;
};

export const LanguageSettingsScreen = observer(
  (props: LanguageSettingsScreenProps) => {
    return <LanguageSettingsList {...props} />;
  },
);
