import {useRoot} from '@ncwallet-app/core/src';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo} from 'react';

import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import SessionRemovalBottomSheetScreen from '../../../screens/PromtpSessionDeletionScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptToRemoveSessionBottomSheetBindingProps =
  SmallHomeStackBindingProps<'PromptSessionDeletion'>;

export default observer(function PromptToRemoveSessionBottomSheetBinding(
  props: PromptToRemoveSessionBottomSheetBindingProps,
) {
  const {activeSessionsStore} = useRoot();
  const {navigation, route} = props;

  const handleClose = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }

    navigation.replace('ListActiveSessions');
  }, [navigation]);

  const handleDelete = useCallback(() => {
    activeSessionsStore.delete(route.params.sessionId);
    handleClose();
  }, [activeSessionsStore, route.params.sessionId, handleClose]);

  const session = useMemo(
    () =>
      activeSessionsStore.other?.find(it => it.id === route.params.sessionId),
    [activeSessionsStore.other, route.params],
  );

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void activeSessionsStore.refresh();
          });
        }
      }),
    [getIsReady, getIsFocused, activeSessionsStore],
  );

  return (
    <SessionRemovalBottomSheetScreen
      onDeletePress={handleDelete}
      onCancelPress={handleClose}
      session={session}
    />
  );
});
