import {Linking} from 'react-native';

import {CachedConsumableImpl} from '../CachedConsumable';
import type {GlobalError} from '../Error';
import {UNKNOWN_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {Disposer, Service} from '../structure';
import {BusImpl} from '../structure';
import type {Url} from '../units';
import type {LocationSource} from './LocationSource';

export default class DeepLinkingLocationSourceService
  implements LocationSource, Service
{
  private readonly _initialLink = new CachedConsumableImpl(() =>
    Linking.getInitialURL(),
  );

  constructor(
    private readonly _root: {readonly errorRepository: ErrorRepository},
  ) {}

  async getInitial(): Promise<Either<Url, GlobalError>> {
    try {
      const link = await this._initialLink.getCachedConsumable();
      if (link) {
        return success(link as Url);
      }
      return error(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          description: 'There is no initial url',
        }),
      );
    } catch (err) {
      return error(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          description: 'error getting cached initial url',
          raw: err,
        }),
      );
    }
  }

  private readonly _updates = new BusImpl<(current: Url) => void>();

  get updates(): LocationSource['updates'] {
    return this._updates;
  }

  private readonly _onLinking = (event: {url: string}) => {
    this._updates.send(event.url as Url);
  };

  subscribe() {
    const _ = Linking.addEventListener('url', this._onLinking);
    return (() => {
      _.remove();
    }) as Disposer;
  }
}
