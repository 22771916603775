import {computed, makeObservable} from 'mobx';

import type {AccountStore} from '../AccountStore';
import {PENDING, REJECTED} from '../AsyncAtom';
import type {AuthState} from '../Auth';
import {MULTI_FACTOR, PRE_AUTH, UNAUTHORIZED} from '../Auth';
import type {CriticalErrorState} from '../CriticalErrorState';
import type {UnknownError} from '../Error';
import {UNKNOWN_ERROR} from '../Error';
import {ErrorRecognizerStatic} from '../ErrorRecognizer';
import type {ErrorRepository} from '../ErrorRepository';
import type {FreshAccessTokenState} from '../FreshAccessTokenState';
import type {FreshTokenState} from '../FreshTokenState';
import type {ConnectionState, RetryStrategyState} from '../JsonRpc';
import {
  ConnectionStatus,
  RETRY_STRATEGY_PENDING,
  RETRY_STRATEGY_SUCCEEDED,
} from '../JsonRpc';
import type {Localization} from '../Localization';
import type {TelegramMiniApp} from '../TelegramMiniApp';
import type {MultiFactorToken} from '../units';
import type {AuthNavigationHelper, AuthRoute} from './AuthNavigationHelper';
import {
  ASK_NEW_PIN,
  ASK_OTP,
  ASK_PIN,
  CONGRATULATE,
  PRE_CLOSE_MESSAGE,
  SHOW_ACCOUNT_ERROR,
  SHOW_AUTH_ERROR,
  SHOW_CONNECTION_ERROR,
  SHOW_CRITICAL_ERROR,
  SHOW_PENDING_AUTH,
  SHOW_PENDING_CONNECTION,
  TOKEN_EXPIRED_ERROR,
  WELCOME,
} from './AuthNavigationHelper';

export default class AuthNavigationHelperImpl implements AuthNavigationHelper {
  private readonly _integrityError;

  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly localization: Localization;
      readonly criticalErrorState: CriticalErrorState;
      readonly authState: AuthState;
      readonly retryStrategyState: RetryStrategyState;
      readonly connectionState: ConnectionState;
      readonly freshAccessTokenState: FreshAccessTokenState;
      readonly freshMultiFactorTokenState: FreshTokenState<MultiFactorToken>;
      readonly telegramMiniApp: TelegramMiniApp;
      readonly accountStore: AccountStore;
    },
  ) {
    makeObservable(this);
    this._integrityError = _root.errorRepository.create<UnknownError>({
      kind: UNKNOWN_ERROR,
      description: _root.localization.getTranslation('exception.stage.auth05'),
    });
  }

  @computed get route(): AuthRoute | undefined {
    const {
      criticalErrorState,
      authState,
      retryStrategyState,
      connectionState,
      freshAccessTokenState,
      freshMultiFactorTokenState,
      accountStore,
      telegramMiniApp,
    } = this._root;

    if (criticalErrorState.latest !== undefined) {
      return {
        kind: SHOW_CRITICAL_ERROR,
        params: {error: criticalErrorState.latest},
      };
    }

    if (telegramMiniApp.appShouldBeClosed) {
      return {kind: PRE_CLOSE_MESSAGE};
    }

    if (!authState.latest || authState.latest.status === PENDING) {
      return {kind: SHOW_PENDING_AUTH};
    }

    if (authState.latest.status === REJECTED) {
      if (
        ErrorRecognizerStatic.getPinError(authState.latest.error) !== undefined
      ) {
        // theoretically never happens
        return {kind: ASK_PIN};
      }

      return {kind: SHOW_AUTH_ERROR, params: {error: authState.latest.error}};
    }

    if (authState.latest.result.kind === UNAUTHORIZED) {
      return {kind: WELCOME};
    }

    if (authState.latest.result.kind === MULTI_FACTOR) {
      if (freshMultiFactorTokenState.isStale) {
        return {kind: TOKEN_EXPIRED_ERROR};
      }

      return {kind: ASK_OTP};
    }

    if (authState.latest.result.kind === PRE_AUTH) {
      const credentials = authState.latest.result.credentials;
      if (credentials.isNew) {
        return {kind: CONGRATULATE};
      }

      if (freshMultiFactorTokenState.isStale) {
        return {kind: TOKEN_EXPIRED_ERROR};
      }

      if (credentials.hasPin) {
        return {kind: ASK_PIN};
      }

      return {kind: ASK_NEW_PIN};
    }

    if (retryStrategyState.latestStatus === RETRY_STRATEGY_PENDING) {
      if (retryStrategyState.latestResult === RETRY_STRATEGY_SUCCEEDED) {
        return undefined;
      }

      return {kind: SHOW_PENDING_CONNECTION};
    }

    if (connectionState.latestStatus !== ConnectionStatus.Open) {
      if (!freshAccessTokenState.isFresh) {
        return {kind: ASK_PIN};
      }

      if (retryStrategyState.latestResult === RETRY_STRATEGY_SUCCEEDED) {
        return undefined;
      }

      if (retryStrategyState.latestResult === undefined) {
        // ready to connect, not actually connecting
        return {kind: SHOW_PENDING_CONNECTION};
      }

      return {
        kind: SHOW_CONNECTION_ERROR,
        params: {error: connectionState.latestError ?? this._integrityError},
      };
    }

    if (!accountStore.state) {
      return {kind: SHOW_PENDING_AUTH};
    }

    if (accountStore.state.status === PENDING) {
      if (retryStrategyState.previousResult === RETRY_STRATEGY_SUCCEEDED) {
        return undefined;
      }

      return {kind: SHOW_PENDING_AUTH};
    }

    if (accountStore.state.status === REJECTED) {
      return {
        kind: SHOW_ACCOUNT_ERROR,
        params: {error: accountStore.state.error},
      };
    }

    return undefined;
  }
}
