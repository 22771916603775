import {useStrings, WalletLimitPeriod} from '@ncwallet-app/core';

// eslint-disable-next-line import-x/prefer-default-export
export const useMapLimitPeriodTabToLabelText = () => {
  const strings = useStrings();

  return new Map([
    [WalletLimitPeriod.Daily, strings['limitsSettingsScreen.tabLimitDay']],
    [WalletLimitPeriod.Monthly, strings['limitsSettingsScreen.tabLimitMonth']],
  ]);
};
