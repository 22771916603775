import {Linking} from 'react-native';

import type {GlobalError, UnknownError} from '../Error';
import {UNKNOWN_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {Url} from '../units';
import BaseHeadlessLocationImpl from './BaseHeadlessLocationImpl';
import type {Location} from './Location';

export default abstract class HeadlessLocationImpl
  extends BaseHeadlessLocationImpl
  implements Location
{
  protected constructor(
    protected readonly _root: {readonly errorRepository: ErrorRepository},
  ) {
    super(_root);
  }

  async open(locator: Url): Promise<Either<void, GlobalError>> {
    try {
      await Linking.openURL(locator);
      return success();
    } catch (raw) {
      return error(
        this._root.errorRepository.create<UnknownError>({
          kind: UNKNOWN_ERROR,
          description: 'Opening url failed',
          raw,
        }),
      );
    }
  }
}
