import type {TransactionFilterKind} from '@ncwallet-app/core';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {EXTRA_BOTTOM_OFFSET} from '../../screens/constants';
import HistoryFilterKindScreen from '../../screens/HistoryFilterKindScreen/HistoryFilterKindScreen';

export type PromptTransactionKindToFilterHistoryContainerProps = {
  onSelect: (_: TransactionFilterKind) => void;
  onBack: () => void;
};

export default observer(function PromptTransactionKindToFilterHistoryContainer(
  props: PromptTransactionKindToFilterHistoryContainerProps,
) {
  const {onSelect, onBack} = props;

  return (
    <HistoryFilterKindScreen
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      onSelect={onSelect}
      onBack={onBack}
    />
  );
});
