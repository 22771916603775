import type {Color} from 'chroma-js';
import chroma from 'chroma-js';
import type {ScaledSize, ViewStyle} from 'react-native';
import {Platform} from 'react-native';

import type {UniversalColor} from '../util';
import {color} from '../util';
import getContrastColor from '../util/getContrastColor';
import type {MediaQuery} from './Adaptive';
import type {Palette} from './Coloring';
import type {FontWeight} from './Fonts';
import type {Theme} from './Theme';

export default abstract class BaseThemeImpl implements Theme {
  protected constructor(
    private readonly _window: ScaledSize,
    private readonly _screen: ScaledSize,
  ) {}

  mediaQuery(query: MediaQuery) {
    const queryKeys = Object.keys(query).map(Number);
    let result = {};
    const screenWidth = this._window.width;
    for (const q of queryKeys) {
      if (screenWidth >= q) {
        result = {...result, ...query[q]};
      }
    }
    return result;
  }

  abstract get palette(): Palette;
  contrast<T extends UniversalColor>(this: Theme, _color: T): T {
    const result = getContrastColor(
      color(_color),
      this.palette.foreground,
      this.palette.background,
    );
    return (
      typeof _color === 'object'
        ? color(result)
        : typeof _color === 'number'
          ? color(result).num()
          : result
    ) as T;
  }

  chroma(this: Theme, _color: UniversalColor): Color {
    return color(_color);
  }

  mix(_back: UniversalColor, _front: UniversalColor) {
    const back = chroma(_back);
    const front = chroma(_front);
    const alpha = front.alpha();
    const r = alpha * front.get('rgb.r') + (1 - alpha) * back.get('rgb.r');
    const g = alpha * front.get('rgb.g') + (1 - alpha) * back.get('rgb.g');
    const b = alpha * front.get('rgb.b') + (1 - alpha) * back.get('rgb.b');
    return chroma.rgb(r, g, b);
  }

  fontByWeight(weight: FontWeight = 'normal') {
    return Platform.select({
      android: {fontFamily: fontByWeightMap[weight]},
      default: {fontFamily: 'Inter', fontWeight: weight},
    });
  }

  bar(elevation: number, _background?: string): ViewStyle {
    const background = _background ?? this.palette.background;
    const borderColor = this.palette.uiSecondary;
    const shadowColor = this.palette.blackout;
    return this.select(
      {
        backgroundColor: background,
        shadowColor: shadowColor,
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: (elevation / 24) * 0.58,
        shadowRadius: (elevation / 2) * 2,
        ...Platform.select({
          android: {
            borderColor: borderColor,
            borderWidth: 1,
            borderStyle: 'solid',
          },
        }),
      },
      {
        borderColor: borderColor,
        backgroundColor: background,
      },
    );
  }

  readonly spacing = [0, 4, 8, 12, 16, 24, 32, 48, 64] as const;

  select<T>(this: Theme, light: T, dark: T): T {
    return chroma(this.palette.background).luminance() >= 0.5 ? light : dark;
  }
}

export const fontByWeightMap = {
  100: 'Inter-Thin',
  200: 'Inter-ExtraLight',
  300: 'Inter-Light',
  400: 'Inter-Regular',
  normal: 'Inter-Regular',
  500: 'Inter-Medium',
  600: 'Inter-SemiBold',
  700: 'Inter-Bold',
  bold: 'Inter-Bold',
  800: 'Inter-ExtraBold',
  900: 'Inter-Black',
} as const;

export const DESKTOP_BREAKPOINT = 1100;
