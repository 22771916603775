import type {NavigationState, PartialState} from '@react-navigation/routers';

import type {
  CommonRoute,
  DistinctPartialRoute,
  NameToKindMap,
  ShallowCommonRouteMeta,
  ShallowCommonState,
  SwitchNavigationState,
} from '../CommonNavigationScheme';
import {
  FALL_BACK_ROUTE,
  LIST_SECURITY_SETTINGS_ROUTE,
  nameToKindMap,
} from '../CommonNavigationScheme';
import {LazyPathImpl} from '../CommonNavigationScheme/Path';
import type {LargeHomeStackParamList} from './LargeHomeStack/LargeHomeStackParamList';
import type {LargeSwitchParamList} from './LargeHomeStack/LargeSwitch/LargeSwitchParamList';

export default function largeToCommon(
  large: PartialState<NavigationState<LargeHomeStackParamList>>,
): ShallowCommonState {
  const largeRoutes =
    large.routes as DistinctPartialRoute<LargeHomeStackParamList>[];
  if (largeRoutes.length === 0) {
    return [{route: {kind: FALL_BACK_ROUTE}}];
  }
  return largeRoutes.flatMap(translateHomeStackRoute) as ShallowCommonState;
}

function translateHomeStackRoute(
  _: DistinctPartialRoute<LargeHomeStackParamList>,
): ShallowCommonRouteMeta[] {
  if (_.name === 'Root') {
    const state = _.state as
      | PartialState<SwitchNavigationState<LargeSwitchParamList>>
      | undefined;
    const routes = [
      ...(state?.history ?? []),
      ...(state?.routes ?? []),
    ] as DistinctPartialRoute<LargeSwitchParamList>[];
    return routes.flatMap(translateSwitchRoute);
  }
  return [translateRoute(_, 'params')];
}

function translateSwitchRoute(
  _: DistinctPartialRoute<LargeSwitchParamList>,
): ShallowCommonRouteMeta[] {
  const path = _.path === undefined ? undefined : new LazyPathImpl(_.path);
  switch (_.name) {
    case 'ListNotificationSettings': {
      const state = [translateRoute(_)];
      if (_.params?.focusedHeader) {
        state.push({route: _.params.focusedHeader, path});
      }
      return state;
    }
    case 'ShowProfile': {
      const state = [translateRoute(_)];
      const {
        focusedDetails,
        focusedBaseCurrency,
        focusedHeader,
        focusedVerification,
      } = _.params ?? {};
      if (focusedHeader) {
        state.push({route: focusedHeader, path});
      }
      if (focusedBaseCurrency) {
        state.push({route: focusedBaseCurrency, path});
      }
      if (focusedVerification) {
        state.push({route: focusedVerification, path});
      }
      if (focusedDetails) {
        state.push({route: focusedDetails, path});
      }
      return state;
    }
    case 'ListSecuritySettings': {
      const state: ShallowCommonState = [
        {
          route: {
            kind: LIST_SECURITY_SETTINGS_ROUTE,
            params: {onTwoFaEnabled: _.params?.onTwoFaEnabled},
          },
          path: _.path === undefined ? _.path : new LazyPathImpl(_.path),
        },
      ];
      const {focusedHeader, focusedSessions, focusedPin, focusedTwoFa} =
        _.params ?? {};
      if (focusedHeader) {
        state.push({route: focusedHeader, path});
      }
      if (focusedSessions) {
        state.push({route: focusedSessions, path});
      }
      if (focusedPin) {
        state.push({route: focusedPin, path});
      }
      if (focusedTwoFa) {
        state.push({route: focusedTwoFa, path});
      }
      return state;
    }
    case 'ListLimits': {
      const state = [translateRoute(_, 'params')];
      const {focusedHeader, focusedSelectedLimit} = _.params ?? {};
      if (focusedHeader) {
        state.push({route: focusedHeader, path});
      }
      if (focusedSelectedLimit) {
        state.push({route: focusedSelectedLimit, path});
      }
      return state;
    }
  }
  const state = [translateRoute(_, 'params')];
  if (_.params?.focusedHeader) {
    state.push({route: _.params.focusedHeader, path});
  }
  return state;
}

function translateRoute(
  route: {
    name: keyof NameToKindMap;
    path?: string;
    params?: object | undefined;
  },
  argumentsKey?: 'params' | 'state',
): ShallowCommonRouteMeta {
  const kind = nameToKindMap[route.name];
  const path =
    route.path === undefined ? route.path : new LazyPathImpl(route.path);
  const common = {kind} as CommonRoute;
  if (argumentsKey && route.params) {
    Reflect.set(common, argumentsKey, route.params);
  }
  return {route: common, path};
}
