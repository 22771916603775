import {useRoot} from '@ncwallet-app/core';
import type {CustomEnvironment} from '@ncwallet-app/core/src/Configuration';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useCallback} from 'react';
import type {FlatListProps} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import type {ReadonlyDeep} from 'type-fest';

import CustomEnvironmentItem from './CustomEnvironmentItem';
import DefaultEnvironmentItem from './DefaultEnvironmentItem';

export default observer(() => {
  const {configuration} = useRoot();
  return (
    <FlatList
      overScrollMode="never"
      ListHeaderComponent={HeaderBinding}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      data={configuration.customEnvironments}
    />
  );
});

type ListProps = FlatListProps<CustomEnvironment>;

const keyExtractor: NonNullable<ListProps['keyExtractor']> = item =>
  String(item.id);

const renderItem: ListProps['renderItem'] = ({item}) => (
  <CustomEnvironmentBinding item={item} />
);

interface CustomEnvironmentBindingProps {
  item: ReadonlyDeep<CustomEnvironment>;
}

const CustomEnvironmentBinding = observer(
  (props: CustomEnvironmentBindingProps) => {
    const {item} = props;
    const {configuration, authHelper} = useRoot();
    const selected = expr(
      () =>
        !configuration.current.isDefault &&
        configuration.current.id === item.id,
    );
    const onPress = useCallback(async () => {
      await configuration.setEnvironment(item.id);
      await authHelper.signOut({reason: 'CustomEnvironmentBinding'});
    }, [authHelper, configuration, item.id]);
    const onDeletePress = useCallback(
      () => configuration.deleteEnvironment(item.id),
      [configuration, item.id],
    );
    return (
      <CustomEnvironmentItem
        url={JSON.stringify(item.values)}
        selected={selected}
        onPress={onPress}
        onDeletePress={onDeletePress}
      />
    );
  },
);

const HeaderBinding = observer(() => {
  const {configuration} = useRoot();
  const environment = configuration.defaultEnvironment;
  const selected = expr(() => configuration.current.isDefault);
  const onPress = useCallback(
    () => configuration.setEnvironment(),
    [configuration],
  );
  return (
    <DefaultEnvironmentItem
      url={JSON.stringify(environment.values)}
      selected={selected}
      onPress={onPress}
    />
  );
});
