import type {Route} from '@react-navigation/core';
import {getActionFromState as getActionFromStateDefault} from '@react-navigation/core';
import type {PathConfig} from '@react-navigation/core/src/types';
import type {
  InitialState,
  LinkingOptions,
  ParamListBase,
} from '@react-navigation/native';
import type {DocumentTitleOptions} from '@react-navigation/native/lib/typescript/src/types';
import type {NavigationState, PartialState} from '@react-navigation/routers';

import type {
  DistinctPartialRoute,
  NavigationContainerConfig,
  ShallowCommonState,
} from '../CommonNavigationScheme';
import {pathToState, stateToPath} from '../CommonNavigationScheme';
import {LazyPathImpl} from '../CommonNavigationScheme/Path';
import type {LocationSource} from '../Location';
import commonToSmall from '../SmallNavigationScheme/commonToSmall';
import type {SmallBottomTabParamList} from '../SmallNavigationScheme/SmallHomeStack/SmallBottomTab/SmallBottomTabParamList';
import type {SmallHomeStackParamList} from '../SmallNavigationScheme/SmallHomeStack/SmallHomeStackParamList';
import smallToCommon from '../SmallNavigationScheme/smallToCommon';
import type {SpecialLocation} from '../SpecialLocation';
import BaseNavigationContainerBindingImpl from './BaseNavigationContainerBindingImpl';
import type {NavigationContainerBinding} from './NavigationContainerBinding';
import type {NavigationContainerState} from './NavigationContainerState';

export default class SmallNavigationContainerBindingImpl
  extends BaseNavigationContainerBindingImpl
  implements NavigationContainerBinding
{
  constructor(
    protected readonly _root: {
      readonly navigationContainerState: NavigationContainerState;
      readonly locationSource: LocationSource;
      readonly specialLocation: SpecialLocation;
    },
  ) {
    super(_root);
  }

  get initialState(): InitialState | undefined {
    return this._latestState && commonToSmall(this._latestState);
  }

  readonly onStateChange = (state: NavigationState | undefined) => {
    this._onStateChange(state);
    if (!state) {
      this._root.navigationContainerState.setState(undefined);
      return;
    }
    this._root.navigationContainerState.setState(
      smallToCommon(
        state as InitialState as PartialState<
          NavigationState<SmallHomeStackParamList>
        >,
      ),
    );
  };

  get linking(): LinkingOptions<ParamListBase> {
    return {
      ...super.linking,
      getPathFromState(state) {
        const common = smallToCommon(
          state as PartialState<NavigationState<SmallHomeStackParamList>>,
        );
        return stateToPath(common).toString();
      },
      getStateFromPath(path) {
        const common = pathToState(new LazyPathImpl(path));
        return commonToSmall(common);
      },
      getActionFromState(state) {
        return getActionFromStateDefault(
          state,
          SmallNavigationContainerBindingImpl._SMALL_HOME_STACK_CONFIG,
        );
      },
    };
  }

  get documentTitle(): DocumentTitleOptions {
    return {
      enabled: true,
      formatter(
        _options: Record<string, unknown> | undefined,
        _route: Route<string> | undefined,
      ) {
        const route = _route as
          | DistinctPartialRoute<
              SmallHomeStackParamList & SmallBottomTabParamList
            >
          | undefined;
        switch (route?.name) {
          case 'FallBack':
            return 'Not Found';
          default:
            return 'NCWallet';
        }
      },
    };
  }

  protected _getCurrentState(): ShallowCommonState | undefined {
    const state = this.ref?.getState();
    return (
      state &&
      smallToCommon(
        state as InitialState as PartialState<
          NavigationState<SmallHomeStackParamList>
        >,
      )
    );
  }

  private static readonly _SMALL_BOTTOM_TABS_CONFIG: PathConfig<SmallBottomTabParamList> =
    {
      initialRouteName: 'ListWallets',
    };

  private static readonly _SMALL_HOME_STACK_CONFIG: NavigationContainerConfig<SmallHomeStackParamList> =
    {
      initialRouteName: 'Root',
      screens: {
        Root: SmallNavigationContainerBindingImpl._SMALL_BOTTOM_TABS_CONFIG as PathConfig<object>,
      },
    };
}
