/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {useStrings} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';

import TwoFaLimitCardSetCode from '../TwoFaLimitCardSetCode';
import type {PromptLimitOtpTelegram} from './PromptLimitOtpTelegram';

export type * from './PromptLimitOtpTelegram';

export type PromptLimitOtpCardProps = {
  onBack: () => void;
  onSubmit: (code: string) => void;
  getErrorText: () => string | undefined;
  resetErrorText: () => void;
} & PromptLimitOtpTelegram;

export default observer(function PromptLimitOtpCard(
  props: PromptLimitOtpCardProps,
) {
  const {onSubmit, getErrorText, resetErrorText} = props;
  const strings = useStrings();
  return (
    <TwoFaLimitCardSetCode
      headerText={strings['promptOtpToUpdateLimitList.back']}
      titleText={strings['promptOtpToUpdateLimitList.title']}
      onSubmit={onSubmit}
      submitText={strings['promptOtpToUpdateLimitList.submit']}
      onBack={props.onBack}
      getError={getErrorText}
      resetError={resetErrorText}
      currentTwoFaProvider={props.currentTwoFaProvider}
      onTwoFaResendCode={() => props.onTwoFaResendCode!(false)}
      resendCodeInterval={props.resendCodeInterval}
      resetInterval={props.resetInterval}
    />
  );
});
