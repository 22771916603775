import type {
  Either,
  ErrorRepository,
  GlobalError,
  NativeMethodError,
} from '@ncwallet-app/core';
import {
  error,
  NATIVE_METHOD_ERROR,
  success,
  UNKNOWN_ERROR,
} from '@ncwallet-app/core';
import type {Analytics} from '@ncwallet-app/core/src/Analytics';
import type {Json} from '@ncwallet-app/core/src/Json';
import type {
  HeadlessLocalNotifications,
  LocalNotificationData,
  LocalNotificationId,
} from '@ncwallet-app/core/src/LocalNotifications';
import {
  BaseHeadlessLocalNotificationsImpl,
  EventType,
} from '@ncwallet-app/core/src/LocalNotifications';
import type {Location} from '@ncwallet-app/core/src/Location';
import type {
  HeadlessMessagingHelper,
  MessageContext,
} from '@ncwallet-app/core/src/Messaging';

import {isNotificationApiSupported} from './isNotificationApiSupported';

export default abstract class BaseWebHeadlessLocalNotificationsImpl
  extends BaseHeadlessLocalNotificationsImpl
  implements HeadlessLocalNotifications
{
  protected constructor(
    protected readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly json: Json;
      readonly analytics: Analytics;
      readonly location: Location;
      readonly headlessMessagingHelper: HeadlessMessagingHelper;
    },
  ) {
    super(_root);
  }

  protected _getNotificationArguments(
    context: MessageContext,
    data: LocalNotificationData,
    areActionsSupported: boolean,
  ): [string, NotificationOptions?] {
    const {message, meta} = context;
    return [
      message.title,
      {
        data: data,
        body: message.body,
        // @ts-expect-error https://developer.mozilla.org/en-US/docs/Web/API/Notification/actions
        actions: areActionsSupported
          ? message.actions?.map(_ => ({
              action: _.action,
              title: _.title,
              icon: _.icon,
            }))
          : undefined,
        icon: message.icon,
        badge: require('../assets/ic_small_icon.png') as string,
        image: message.image,
        tag: message.tags ?? meta.id,
        renotify: !!message.tags,
        requireInteraction: message.requireInteraction,
        silent: !message.vibrate && !message.sound,
        vibrate: message.vibrate ? [300, 500] : undefined,
      },
    ];
  }

  protected abstract _getRegistration(): Promise<ServiceWorkerRegistration>;

  async scheduleNotification(
    context: MessageContext,
  ): Promise<Either<void, GlobalError>> {
    const data_ = await this._wrapContext(context);
    if (!data_.success) {
      return data_;
    }
    if (
      !isNotificationApiSupported() ||
      Notification.permission !== 'granted'
    ) {
      return error(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          description: 'Notifications permission have not been granted',
        }),
      );
    }
    try {
      const registration = await this._getRegistration();
      const args = this._getNotificationArguments(context, data_.right, true);
      await registration.showNotification(...args);
      const [, options] = args;
      return await this.handle({
        id: options?.tag as LocalNotificationId | undefined,
        type: EventType.Delivered,
        data: data_.right,
      });
    } catch (raw) {
      return error(
        this._root.errorRepository.create<NativeMethodError>({
          kind: NATIVE_METHOD_ERROR,
          description: 'Notification native display method failed',
          raw,
        }),
      );
    }
  }

  async cancelNotification(
    id: LocalNotificationId,
  ): Promise<Either<void, GlobalError>> {
    try {
      const registration = await this._getRegistration();
      const notifications = await registration.getNotifications({tag: id});
      for (const notification of notifications) {
        notification.close();
      }
    } catch (raw) {
      return error(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          raw,
        }),
      );
    }
    return success();
  }
}
