import {useStrings, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

export type FeedbackModalSubmitButtonsProps = {
  onSubmit: () => void;
  isGoToAppStoreButtonVisible: boolean;
  onGoToAppStoreButtonPress: () => void;
};

export default observer(function FeedbackModalSubmitButtons(
  props: FeedbackModalSubmitButtonsProps,
) {
  const strings = useStrings();

  return (
    <>
      {props.isGoToAppStoreButtonVisible && (
        <GoToAppStoreButton
          onPress={props.onGoToAppStoreButtonPress}
          title={strings['feedbackModalScreen.goToAppStoreButton']}
          color={ButtonColor.Secondary}
        />
      )}
      <SubmitButton
        onPress={props.onSubmit}
        title={strings['feedbackModalScreen.submitButton']}
        variant={ButtonVariant.Primary}
      />
    </>
  );
});

const SubmitButton = variance(Button)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.whitey,
    textAlign: 'center',
    boxShadow: 'none',
    borderWidth: 0,
    marginHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: '100%',
        marginHorizontal: 'auto',
      },
    }),
  },
}));

const GoToAppStoreButton = variance(Button)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.info,
    borderWidth: 0,
    marginTop: 'auto',
    marginBottom: 17,
    backgroundColor: theme.palette.uiPrimary,
    marginHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: '100%',
        marginHorizontal: 0,
      },
    }),
  },
}));
