import type {CryptoCurrencyCode, DecimalString} from '@ncwallet-app/core';
import {formatCryptoValue, useStrings, variance} from '@ncwallet-app/core';
import {
  BaseSkeleton,
  CurrencyCircleLogo,
  DecimalInput,
  InputVariant,
  TouchableOpacity,
  XS_BREAKPOINT,
} from '@ncwallet-app/ui';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {Text, View} from 'react-native';

import {CurrencySelectButton} from './CurrencySelectButton';

type ExchangeInputProps = {
  label?: string;
  placeholder: string;
  crypto: CryptoCurrencyCode | undefined;
  maxValue: DecimalString | undefined;
  maxRestrictedByWalletLimit: boolean;
  value: DecimalString | undefined;
  error: boolean;
  onChange: (value: DecimalString | undefined) => void;
  onSelectButtonPress: () => void;
  onMaxPress: () => void;
  style?: StyleProp<ViewStyle>;
  direction?: string;
};

export default observer(function ExchangeInput(props: ExchangeInputProps) {
  const {
    label,
    placeholder,
    value,
    crypto,
    error,
    onChange,
    onSelectButtonPress,
    onMaxPress,
    style,
    direction,
  } = props;
  const strings = useStrings();
  return (
    <InputView style={style}>
      {!crypto ? (
        <>
          {label ? (
            <BaseSkeleton width={110} height={17} gap={5} />
          ) : (
            <EmptyLabel />
          )}
          <BaseSkeleton height={50} />
        </>
      ) : (
        <DecimalInput
          label={label}
          fractionDigits={8}
          placeholder={placeholder}
          variant={error ? InputVariant.Error : InputVariant.Default}
          iconBackgroundShown={false}
          value={value}
          onChangeDecimal={onChange}
          Icon={() => (
            <CurrencyCircleLogo code={props.crypto ?? ''} size={30} />
          )}
          Slot={() => (
            <CurrencySelectButton
              currencyCode={crypto}
              onPress={onSelectButtonPress}
              testID={`exchange-${direction ?? ''}-currency`}
            />
          )}
          testID={`exchange-${direction ?? ''}-input`}
        />
      )}
      <InputViewBottom onPress={onMaxPress}>
        <View>
          <EnterMaxText>
            {props.maxRestrictedByWalletLimit
              ? strings['exchangeCrypto.enterMaxWithLimitRestriction']
              : strings['exchangeCrypto.enterMax']}
          </EnterMaxText>
        </View>
        {!isNil(props.maxValue) ? (
          <BalanceText>
            {`${formatCryptoValue(props.maxValue)} ${crypto ?? ''}`}
          </BalanceText>
        ) : (
          <BaseSkeleton width={110} height={17} />
        )}
      </InputViewBottom>
    </InputView>
  );
});

const InputView = variance(View)(() => ({
  root: {
    marginVertical: 14,
  },
}));

const InputViewBottom = variance(TouchableOpacity)(theme => ({
  root: {
    marginTop: 15,
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    ...theme.mediaQuery({
      [XS_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
}));

const BalanceText = variance(Text)(theme => ({
  root: {
    marginTop: 5,
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional2,
    ...theme.mediaQuery({
      [XS_BREAKPOINT]: {
        marginTop: 0,
      },
    }),
  },
}));

const EnterMaxText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.info,
    fontSize: 14,
  },
}));

const EmptyLabel = variance(View)(() => ({
  root: {
    height: 17,
  },
}));
