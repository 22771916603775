import type {
  CryptoAddress,
  CryptoCurrencyCode,
  DecimalString,
} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {autorun, runInAction} from 'mobx';
import {useEffect, useState} from 'react';

import {WalletLimitHelper} from '../../../CommonNavigationContainers/hooks/usePromptExchangeReceiptContainer/WalletLimitHelper';
import {useNavigationGetIsFocused} from '../../hooks';
import {SendCommissionBindingState} from './SendCommissionBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const useSendCommissionBindingState = (
  currency: CryptoCurrencyCode,
  addressNetwork: AddressNetwork,
  addressCurrency: CryptoCurrencyCode,
  addressTo: CryptoAddress,
  amount: DecimalString,
  fee: DecimalString | undefined,
  minFreeWithdrawAmount?: string,
) => {
  const root = useRoot();
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  const [state] = useState(
    () => new SendCommissionBindingState(root, new WalletLimitHelper(root)),
  );

  useEffect(() => {
    if (fee && state.fee !== fee) {
      void state.setHasNoCommission(false);
      state.setFee(fee);
    }
  }, [fee, state]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh(
              currency,
              addressNetwork,
              addressCurrency,
              addressTo,
              amount,
              fee,
              minFreeWithdrawAmount,
            );
          });
        }
      }),
    [
      addressCurrency,
      addressTo,
      amount,
      currency,
      fee,
      getIsFocused,
      getIsReady,
      addressNetwork,
      state,
      minFreeWithdrawAmount,
    ],
  );

  return state;
};
