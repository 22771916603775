import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import {success} from '../fp';
import type {Jwt, JwtString} from '../Jwt';
import type {Millisecond} from '../Time';
import {fromSecond} from '../Time';
import type {JwtHelper} from './JwtHelper';

export default class JwtHelperImpl implements JwtHelper {
  constructor(
    private readonly _root: {
      readonly jwt: Jwt;
    },
  ) {}

  isExpired(input: JwtString, now: Millisecond): Either<boolean, GlobalError> {
    const jwt_ = this._root.jwt.parse(input);
    if (!jwt_.success) {
      return jwt_;
    }
    const expiry = fromSecond(jwt_.right.payload.exp);
    return success(now > expiry);
  }
}
