import type {MessageContext} from '../Messaging';
import type {LogContent} from './LogContent';

export default class MessageContextLogContent implements LogContent {
  constructor(public readonly context: MessageContext) {}

  get body() {
    return JSON.stringify(this.context, undefined, 2);
  }
}
