import dayjs from 'dayjs';
import {action, makeObservable, observable, runInAction} from 'mobx';

import type {BaseAsyncOptions} from '../../Async';
import type {AccountIdStore} from '../../Auth';
import type {CancellationError} from '../../Error';
import {CANCELLATION_ERROR} from '../../Error';
import type {ErrorRepository} from '../../ErrorRepository';
import {error} from '../../fp';
import type {JsonRpcClient, JsonRpcServer} from '../../JsonRpc';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  NCWalletReverseCallScheme,
  NCWalletReverseNotificationScheme,
  Wallet,
  WalletId,
} from '../../NCWalletServer';
import type {Service} from '../../structure';
import {batchDisposers} from '../../structure';
import type {Millisecond} from '../../Time';
import type {BaseTransactionOptions} from '../../util';
import {first} from '../../util';
import type {WalletTransactions} from '../../WalletTransactionsRequestHelper';
import type {WalletStore} from '../WalletStore';
import type {LastTransactionStore} from './LastTransactionStore';

export default class LastTransactionStoreService
  implements LastTransactionStore, Service
{
  private isTransactionUpdateActivated: boolean = false;
  @observable.ref private _lastTransaction:
    | WalletTransactions.AggregationItem
    | undefined = undefined;

  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly ncWalletJsonRpcServer: JsonRpcServer<
        NCWalletReverseCallScheme,
        NCWalletReverseNotificationScheme
      >;
      readonly walletStore: WalletStore;
      readonly walletTransactionsRequestHelper: WalletTransactions.RequestHelper;
      readonly accountIdStore: AccountIdStore;
    },
  ) {
    makeObservable(this);
  }

  async refreshLastTransaction(
    walletIds: Set<WalletId>,
    options?: BaseAsyncOptions & BaseTransactionOptions,
  ) {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }

    const transactionsRes =
      await this._root.walletTransactionsRequestHelper.getNext(
        walletIds,
        undefined,
        undefined,
        {
          to: dayjs().endOf('day').valueOf() as Millisecond,
          limit: 1,
        },
        options,
      );

    if (transactionsRes.success) {
      const _runInAction = options?.postpone ?? runInAction;
      _runInAction(() => {
        this._lastTransaction = first(transactionsRes.right.items);
      });
    }

    return transactionsRes;
  }

  private _walletToWalletId = (w: Wallet): WalletId => w.id;

  getLastTransaction(): WalletTransactions.AggregationItem | undefined {
    return this._lastTransaction;
  }

  activateTransactionsUpdate() {
    this.isTransactionUpdateActivated = true;
  }

  deactivateTransactionsUpdate() {
    this.isTransactionUpdateActivated = false;
  }

  @action.bound
  reset() {
    this.isTransactionUpdateActivated = false;
    this._lastTransaction = undefined;
  }

  private _updateLastTransactionOnNotification() {
    return this._root.ncWalletJsonRpcServer.notification(
      'event',
      async (params, response, next) => {
        if (
          params.type === 'wallet_balance_update' ||
          params.type === 'withdrawal_complete'
        ) {
          if (!this.isTransactionUpdateActivated) {
            return;
          }
          let walletIds;
          if (params.type === 'wallet_balance_update') {
            walletIds = new Set([params.data.wallet_id]);
          } else {
            const wallets = this._root.walletStore.getWallets();
            walletIds = new Set(wallets?.map(this._walletToWalletId));
          }

          await this.refreshLastTransaction(walletIds);
        }
        next();
      },
    );
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }

  subscribe() {
    return batchDisposers(this._updateLastTransactionOnNotification());
  }
}
