import {useStrings} from '@ncwallet-app/core';
import type {SafeAreaScrollViewProps} from '@ncwallet-app/ui';
import {SafeAreaScrollView} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {SettingsGroupTitle} from '../UserSettingsScreens/SharedComponents/SettingsGroupTitle';
import SecuritySettingsItem from './SecuritySettingsItem';

export type SecuritySettingsListProps = SafeAreaScrollViewProps & {
  onSessionHistoryPress: () => void;
  onActiveSessionsPress: () => void;
  onAllowedIpPress: () => void;
  onBlockedIpPress: () => void;
  onTwoFaSettingsPress: () => void;
  onPinPress: () => void;
  onBiometricPress: () => void;
};

export default observer(function SecuritySettingsList(
  props: SecuritySettingsListProps,
) {
  const {
    onSessionHistoryPress,
    onActiveSessionsPress,
    onAllowedIpPress,
    onBlockedIpPress,
    onTwoFaSettingsPress,
    onPinPress,
    onBiometricPress,
    ...rest
  } = props;
  const strings = useStrings();

  return (
    <SafeAreaScrollView {...rest}>
      <SettingsGroupTitle
        title={strings['secureSettingsScreen.protectionTitle']}
        first
      />
      <SecuritySettingsItem
        title={strings['secureSettingsScreen.twoFactorAuth']}
        onPress={onTwoFaSettingsPress}
        testID="mobile-secure-2fa"
      />
      <SecuritySettingsItem
        title={strings['secureSettingsScreen.pinCode']}
        onPress={onPinPress}
        testID="mobile-secure-pin"
      />
      <SecuritySettingsItem
        title={strings['secureSettingsScreen.biometric']}
        onPress={onBiometricPress}
        testID="mobile-secure-bio"
      />
      <SettingsGroupTitle
        title={strings['secureSettingsScreen.sessionsTitle']}
      />
      <SecuritySettingsItem
        title={strings['secureSettingsScreen.whiteListIp']}
        onPress={onAllowedIpPress}
        testID="allow-ip-btn"
      />
      <SecuritySettingsItem
        title={strings['secureSettingsScreen.blackListIp']}
        onPress={onBlockedIpPress}
        testID="block-ip-btn"
      />
      <SecuritySettingsItem
        title={strings['secureSettingsScreen.history']}
        onPress={onSessionHistoryPress}
        testID="session-history-btn"
      />
      <SecuritySettingsItem
        title={strings['secureSettingsScreen.active']}
        onPress={onActiveSessionsPress}
        testID="active-sessions-btn"
      />
    </SafeAreaScrollView>
  );
});
