import type {CryptoAddress, WalletId} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {autorun, runInAction} from 'mobx';
import {useCallback, useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../hooks';
import {ReceiveCryptoAddressesBindingState} from './ReceiveCryptoAddressesBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const useReceiveCryptoAddressesBindingState = (
  walletId: WalletId,
  address?: CryptoAddress,
  network?: AddressNetwork,
  networkFilter?: AddressNetwork,
) => {
  const root = useRoot();
  const [state] = useState(() => new ReceiveCryptoAddressesBindingState(root));
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  const refresh = useCallback(() => state.refresh(walletId), [state, walletId]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, refresh, state],
  );

  useEffect(() => {
    void state.setAddressParams(address, network, networkFilter);
  }, [address, network, state, networkFilter]);

  return [state, refresh] as const;
};
