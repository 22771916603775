import type {
  CurrencyCode,
  CurrencyDescription,
  DecimalString,
} from '@ncwallet-app/core';
import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonColor, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef, useState} from 'react';
import type {TextInput} from 'react-native';
import {Text, View} from 'react-native';

import {useIsSuspended} from '../../Navigation/hooks/useIsSuspended';
import SendAmountInput from '../SendAmountInputScreen/SendAmountInput';
import CommissionView from './CommissionView';
import {SendAddressInput} from './SendAddressInput';
import SendCommentInput from './SendCommentInput';
import {BottomContainer, ErrorTitle} from './SharedComponents';

export type AddressTabViewProps = {
  addressNameShown: boolean;
  addressName: string | undefined;
  networkCurrency: string | undefined;
  networkChangeEnabled: boolean;
  onAddressNamePress: () => void;
  addressTo: string;
  cryptoCurrency: CurrencyDescription | undefined;
  onAddressActionPress: () => void;
  showAddressAction: boolean;
  onAddressToChange: (addressTo: string) => void;
  addressToError?: string;
  onSubmit: () => void;
  isEditable?: boolean;
  contractType: string | undefined | null;
  onBlurAddressInput: () => void;
  onScroll?:
    | ((options?: {x: number; y: number; animated: boolean}) => void)
    | undefined;
  // Amount
  maxAmount: DecimalString | undefined;
  maxAmountRestrictedByWalletLimit: boolean;
  amountError?: string;
  maxError?: string;
  amount: DecimalString | undefined;
  onAmountChange: (value: DecimalString | undefined) => void;
  comment?: string;
  onCommentChange: (comment: string) => void;
  /// Comission
  getHasNoCommission: () => boolean;
  setHasNoCommission: (c: boolean) => void;
  commissionSwitcherHidden: boolean;
  isEnoughAmountForFreeSend: boolean;
  isBlockchainComment: boolean;
  fees?: string[] | undefined;
  recommendedFee?: string | undefined;
  fee?: string;
  setFee: (value: string) => void;
  minFreeAmount: string | undefined;
  disabled?: boolean;
  goToFAQ: () => void;
};

export const AddressTabView = observer((props: AddressTabViewProps) => {
  const strings = useStrings();
  const isSuspended = useIsSuspended();
  const isDisabled = props.disabled || isSuspended;
  const error = props.addressToError || props.amountError || props.maxError;
  const addressRef = useRef<TextInput>(null);
  const amountRef = useRef<TextInput>(null);
  const commentRef = useRef<TextInput>(null);

  const [isShown, setIsShown] = useState(false);

  const toggleComment = useCallback(() => {
    setIsShown(_isShown => !_isShown);
  }, []);

  return (
    <Root>
      <SendAddressInput
        networkChangeEnabled={props.networkChangeEnabled}
        onAddressNamePress={props.onAddressNamePress}
        addressNameShown={props.addressNameShown}
        addressName={props.addressName}
        value={props.addressTo}
        onActionPress={props.onAddressActionPress}
        showAction={props.showAddressAction}
        inputRef={addressRef}
        onInputChange={props.onAddressToChange}
        error={!!props.addressToError}
        onBlur={props.onBlurAddressInput}
        networkCurrency={props.networkCurrency}
        contractType={props.contractType}
        returnKeyType={'next'}
        autoFocus={false}
        blurOnSubmit={false}
        testID="wallet-input"
        onSubmitEditing={() => {
          amountRef.current?.focus();
        }}
      />
      <SendAmountInput
        inputRef={amountRef}
        cryptoCurrency={props.cryptoCurrency?.code as CurrencyCode}
        max={props.maxAmount}
        maxRestrictedByWalletLimit={props.maxAmountRestrictedByWalletLimit}
        maxValueAvailable={!props.maxError}
        error={!!props.amountError}
        value={props.amount}
        onInputChange={props.onAmountChange}
        returnKeyType={isShown ? 'next' : 'done'}
        onSubmitEditing={() => {
          commentRef.current?.focus();
        }}
        blurOnSubmit={!isShown}
        testID="amount-input"
      />
      <SendCommentInput
        onInputChange={props.onCommentChange}
        inputRef={isShown ? commentRef : undefined}
        withHiding
        isShown={isShown}
        toggleComment={toggleComment}
        isBlockchainComment={props.isBlockchainComment}
        goToFAQ={props.goToFAQ}
      />
      {!props.commissionSwitcherHidden && (
        <CommissionView
          cryptoCurrency={props.cryptoCurrency}
          getHasNoCommission={props.getHasNoCommission}
          setHasNoCommission={props.setHasNoCommission}
          commissionSwitcherHidden={props.commissionSwitcherHidden}
          isEnoughAmountForFreeSend={props.isEnoughAmountForFreeSend}
          fees={props.fees?.length === 1 ? undefined : props.fees}
          recommendedFee={props.recommendedFee}
          fee={props.fee}
          setFee={props.setFee}
          minFreeAmount={props.minFreeAmount}
        />
      )}
      <BottomContainer>
        <ErrorTitle testID="amount-error">{error}</ErrorTitle>
        {!props.commissionSwitcherHidden && props.getHasNoCommission() && (
          <TextNoCommision>
            {strings['Send.Step.Message.NoCommission']}
          </TextNoCommision>
        )}
        <SubmitButton
          title={strings['sendCrypto.confirm.submit']}
          variant={ButtonVariant.Highlighted}
          color={ButtonColor.Secondary}
          onPress={props.onSubmit}
          disabled={isDisabled}
        />
      </BottomContainer>
    </Root>
  );
});

const Root = variance(View)(() => ({
  root: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    gap: 20,
    flex: 1,
  },
}));

const SubmitButton = variance(Button)(() => ({
  root: {
    marginHorizontal: 15,
  },
  offsetTop: {
    marginTop: 'auto',
  },
}));

const TextNoCommision = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional2,
    marginBottom: 20,
    textAlign: 'center',
  },
}));
