import type {DecimalString} from '@ncwallet-app/core';
import {WalletLimitPeriod} from '@ncwallet-app/core';
import {BigNumber} from 'bignumber.js';

export enum LimitValidationError {
  DailyLimitOverMonthly,
  MonthlyLimitOverDaily,
}

export function validateLimitValue(
  period: WalletLimitPeriod,
  limit: DecimalString,
  monthLimit?: DecimalString,
  dayLimit?: DecimalString,
): LimitValidationError | undefined {
  if (period === WalletLimitPeriod.Daily) {
    if (monthLimit && BigNumber(limit).isGreaterThan(BigNumber(monthLimit))) {
      return LimitValidationError.DailyLimitOverMonthly;
    }
  }

  if (period === WalletLimitPeriod.Monthly) {
    if (dayLimit && BigNumber(limit).isLessThan(BigNumber(dayLimit))) {
      return LimitValidationError.MonthlyLimitOverDaily;
    }
  }
}

export const mapValidateErrorToString = (errorKind: LimitValidationError) => {
  switch (errorKind) {
    case LimitValidationError.DailyLimitOverMonthly:
      return 'limitsSettingsScreen.dailyError';
    case LimitValidationError.MonthlyLimitOverDaily:
      return 'limitsSettingsScreen.montlyError';
  }
};
