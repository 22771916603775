import type {CurrencyDescription, WalletLimit} from '@ncwallet-app/core';
import {formatCryptoValue, useStrings, variance} from '@ncwallet-app/core';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import WalletLimitProgressBar from './WalletLimitProgressBar';

export type WalletLimitProgressProps = {
  currency: CurrencyDescription;
  dailyLimit?: WalletLimit;
  monthlyLimit?: WalletLimit;
};

export default observer(function WalletLimitPeriods(
  props: WalletLimitProgressProps,
) {
  const {dailyLimit, monthlyLimit, currency} = props;
  const strings = useStrings();
  return (
    <>
      {dailyLimit && !isNil(dailyLimit.limit) && (
        <>
          <WalletLimitPeriodsTop>
            <WalletLimitPeriodLabel>
              {strings['limitsSettingsScreen.limitDaily']}
              {': '}
            </WalletLimitPeriodLabel>
            <WalletLimitPeriodCryptoValue>
              {formatCryptoValue(dailyLimit.limit, currency.fractionDigits)}{' '}
              {currency.code}
            </WalletLimitPeriodCryptoValue>
          </WalletLimitPeriodsTop>
          <WalletLimitProgressBar
            value={dailyLimit.accrued}
            total={dailyLimit.limit}
          />
        </>
      )}
      <SizedBox />
      {monthlyLimit && !isNil(monthlyLimit.limit) && (
        <>
          <WalletLimitPeriodsTop>
            <WalletLimitPeriodLabel>
              {strings['limitsSettingsScreen.limitMonthly']}
              {': '}
            </WalletLimitPeriodLabel>
            <WalletLimitPeriodCryptoValue>
              {formatCryptoValue(monthlyLimit.limit, currency.fractionDigits)}{' '}
              {currency.code}
            </WalletLimitPeriodCryptoValue>
          </WalletLimitPeriodsTop>
          <WalletLimitProgressBar
            value={monthlyLimit.accrued}
            total={monthlyLimit.limit}
          />
        </>
      )}
    </>
  );
});

const WalletLimitPeriodsTop = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    marginBottom: 5,
  },
}));

const WalletLimitPeriodLabel = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 12,
    lineHeight: 15,
    color: theme.palette.textAdditional1,
  },
}));

const WalletLimitPeriodCryptoValue = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 12,
    lineHeight: 15,
    color: theme.palette.textAdditional2,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 10,
  },
}));
