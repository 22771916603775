import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import type {SharedValue} from 'react-native-reanimated';
import Animated, {useAnimatedStyle, withSpring} from 'react-native-reanimated';
import type {Vector} from 'react-native-redash';

import {CHART_HEIGHT} from './constants';

export type LineProps = {
  cursorTranslation: Vector<Animated.SharedValue<number>>;
  isActiveCursor: SharedValue<boolean>;
};

export default observer((props: LineProps) => {
  const {cursorTranslation, isActiveCursor} = props;

  const lineStyle = useAnimatedStyle(() => {
    const translateX = cursorTranslation.x.value;
    const opacity = withSpring(isActiveCursor.value ? 1 : 0);
    return {
      opacity,
      transform: [{translateX}],
    };
  }, [cursorTranslation, isActiveCursor]);

  return (
    <View style={StyleSheet.absoluteFill}>
      <Animated.View style={StyleSheet.absoluteFill}>
        <AnimatedRoot style={lineStyle} />
      </Animated.View>
    </View>
  );
});

const AnimatedRoot = variance(Animated.View)(theme => ({
  root: {
    width: 1,
    height: CHART_HEIGHT,
    backgroundColor: theme.palette.primary,
  },
}));
