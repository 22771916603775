/* eslint-disable @typescript-eslint/unbound-method */
import {useRoot, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant, Input} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import type {PersonalFormStateProps} from '../../shared/AccountDataForm/PersonalFormState/usePersonalFormState';
import {
  BIRTHDAY_LENGTH,
  FIRST_NAME_LENGTH,
  LAST_NAME_LENGTH,
  usePersonalFormState,
} from '../../shared/AccountDataForm/PersonalFormState/usePersonalFormState';
import {BIRTHDAY_ERROR_TO_LOCALE} from '../../shared/AccountDataForm/PersonalFormState/validatePersonalForm';
import {getInputVariant} from '../ProfileSettingsScreen/LgProfileCards/Verification/PersonalForm';

export type PersonalFormProps = PersonalFormStateProps;

export default observer(function PersonalForm(props: PersonalFormProps) {
  const {formState, handleSubmit} = usePersonalFormState(props);
  const {localization} = useRoot();
  const getErrorMessage = () => {
    if (formState.errorMap?.firstName || formState.errorMap?.lastName) {
      return localization.getTranslation(
        'profileSettingsScreen.verificationError',
      );
    }

    if (formState.errorMap?.birthday) {
      return localization.getTranslation(
        BIRTHDAY_ERROR_TO_LOCALE[formState.errorMap.birthday],
      );
    }

    return '';
  };
  return (
    <PersonalInfoFormHolder>
      <Input
        label={`${localization.getTranslation(
          'profileSettingsScreen.verificationLabel',
        )} *`}
        placeholder={localization.getTranslation(
          'profileSettingsScreen.verificationPlaceholderFirst',
        )}
        value={formState.firstName}
        autoFocus
        onChangeText={formState.setFirstName}
        variant={getInputVariant(!formState.errorMap?.firstName)}
        maxLength={FIRST_NAME_LENGTH}
      />
      <SizedBox />
      <Input
        placeholder={localization.getTranslation(
          'profileSettingsScreen.verificationPlaceholderSecond',
        )}
        value={formState.lastName}
        onChangeText={formState.setLastName}
        variant={getInputVariant(!formState.errorMap?.lastName)}
        maxLength={LAST_NAME_LENGTH}
      />
      <SizedBox large />
      <Input
        label={`${localization.getTranslation(
          'profileSettingsScreen.verification.personInfo.birthDateLabel',
        )} (${localization.getTranslation('datePlaceholder')}) *`}
        placeholder={localization.getTranslation('datePlaceholder')}
        value={formState.birthday}
        onChangeText={formState.setBirthday}
        variant={getInputVariant(!formState.errorMap?.birthday)}
        keyboardType="numeric"
        maxLength={BIRTHDAY_LENGTH}
      />
      {(formState.errorMap?.firstName ||
        formState.errorMap?.lastName ||
        formState.errorMap?.birthday) && (
        <ErrorText>{getErrorMessage()}</ErrorText>
      )}

      <Space />
      <FormButton
        title={localization.getTranslation('profileSettingsScreen.nextButton')}
        variant={ButtonVariant.Primary}
        onPress={handleSubmit}
      />
    </PersonalInfoFormHolder>
  );
});

const PersonalInfoFormHolder = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 10,
  },
  large: {
    height: 30,
  },
}));

const FormButton = variance(Button)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const Space = variance(View)(() => ({
  root: {
    height: 20,
    width: '100%',
    flexShrink: 0,
  },
}));

const ErrorText = variance(Text)(theme => ({
  root: {
    marginTop: 20,
    ...theme.fontByWeight('500'),
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.error,
  },
}));
