import {useBalanceCryptoValue, variance} from '@ncwallet-app/core/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {ArrowRightSvg} from '../../../assets/svg/colorless';
import {CurrencyCircleLogo, TouchableListItem} from '../../atoms';
import type {CurrencySelectionListItemData} from './CurrencySelectionListItemData';

export type CurrencyListItemProps = {
  data: CurrencySelectionListItemData;
  onPress: (currency: CurrencySelectionListItemData) => void;
  showValueAsRate?: boolean;
  testID?: string;
};

export const CurrencyListItem = observer((props: CurrencyListItemProps) => {
  const {data, onPress, showValueAsRate = true} = props;
  const formatCryptoValue = useBalanceCryptoValue(
    data.value,
    data.cryptoCurrency.fractionDigits,
  );

  return (
    <Root
      Icon={ArrowRightSvg}
      onPress={() => {
        onPress(data);
      }}
      disabled={data.disabled}
      testID={props.testID}>
      <CurrencyCircleLogo code={data.cryptoCurrency.code} size={40} />
      <CurrencyListItemBody>
        <CryptoTitle>{data.cryptoCurrency.name}</CryptoTitle>
        {showValueAsRate && (
          <CryptoValue>
            {formatCryptoValue} {data.valueCurrency.code}
          </CryptoValue>
        )}
      </CurrencyListItemBody>
    </Root>
  );
});

const Root = variance(TouchableListItem)(() => ({
  root: {
    padding: 15,
  },
}));

const CurrencyListItemBody = variance(View)(() => ({
  root: {
    marginLeft: 15,
  },
}));

const CryptoTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 16,
  },
}));

const CryptoValue = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 13,
    color: theme.palette.textAdditional2,
  },
}));
