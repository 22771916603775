export enum CurrenciesListState {
  Loading,
  Empty,
  FilteredToEmpty,
  Normal,
}

export const getCurrenciesListState = <T,>(
  all: T[] | undefined,
  shown: T[] | undefined,
): CurrenciesListState => {
  if (!all || !shown) {
    return CurrenciesListState.Loading;
  } else if (all.length === 0) {
    return CurrenciesListState.Empty;
  } else if (shown.length === 0) {
    return CurrenciesListState.FilteredToEmpty;
  } else {
    return CurrenciesListState.Normal;
  }
};
