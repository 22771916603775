import {urlLinks, useRoot} from '@ncwallet-app/core';
import type {LIST_NOTIFICATIONS_ROUTE} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {
  LIST_MENU_CATEGORIES_ROUTE,
  PROMPT_LANGUAGE_ROUTE,
  PROMPT_THEME_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {LargeSwitchParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {RouteProp} from '@react-navigation/native';
import {useMemo} from 'react';

import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type ScreenHeaderWideProps = {
  unreadNotificationsTotal: number;
  onNotificationsPress: () => void;
  onQrCodePress: () => void;
  onWalletsPress: () => void;
  onReceivePress: () => void;
  onAffiliatePress: () => void;
  onExchangePress: () => void;
  onSendPress: () => void;
  onHistoryPress: () => void;
  onDebugPress: () => void;
  onNotificationSettings: () => void;
  onProfileSettings: () => void;
  onRatePress: () => void;
  onHelpPress: () => void;
  onSecurityPress: () => void;
  onLimitsPress: () => void;
  onLogoutPress: () => void;
  focusedMenu: MenuRouteKind | undefined;
  toggleMenu: (kind?: MenuRouteKind) => void;
};

export type MenuRouteKind =
  | typeof LIST_MENU_CATEGORIES_ROUTE
  | typeof PROMPT_THEME_ROUTE
  | typeof PROMPT_LANGUAGE_ROUTE
  | typeof LIST_NOTIFICATIONS_ROUTE;

export default function useScreenHeaderWideProps(
  props: LargeSwitchBindingProps<keyof LargeSwitchParamList>,
): ScreenHeaderWideProps {
  const {navigation, route} = props;
  const {authHelper, location} = useRoot();

  return useMemo(
    () => ({
      unreadNotificationsTotal: 0,
      onNotificationsPress: () => {
        navigation.setParams({
          focusedHeader: {kind: 'LIST_NOTIFICATIONS_ROUTE'},
        });
      },
      onAffiliatePress: () => {
        navigation.navigate('PromptAffiliateProgram');
      },
      onQrCodePress: () => {
        navigation.navigate('ScanQrCodeScanner');
      },
      onWalletsPress: () => {
        navigation.jumpTo('ListWallets');
      },
      onReceivePress: () => {
        navigation.jumpTo('PromptCryptoToReceive');
      },
      onExchangePress: () => {
        navigation.jumpTo('PromptSourceCryptoToExchange');
      },
      onSendPress: () => {
        navigation.jumpTo('PromptCryptoToSend');
      },
      onHistoryPress: () => {
        navigation.jumpTo('ListHistory');
      },
      onDebugPress: () => {
        navigation.jumpTo('Debug');
      },
      onNotificationSettings: () => {
        navigation.jumpTo('ListNotificationSettings');
      },
      onProfileSettings: () => {
        navigation.jumpTo('ShowProfile');
      },
      onRatePress: () => {
        navigation.navigate('PromptRate');
      },
      onHelpPress: () => location.open(urlLinks.support),
      onSecurityPress: () => {
        navigation.navigate('ListSecuritySettings');
      }, //navigation.navigate('TwoFaWelcome'),
      onLimitsPress: () => {
        navigation.navigate('ListLimits');
      },
      onLogoutPress: async () => {
        await authHelper.signOut({reason: 'useScreenHeaderWideProps'});
        navigation.navigate('ListWallets');
      },
      focusedMenu: route.params?.focusedHeader?.kind,
      toggleMenu: (nextKind?: MenuRouteKind) => {
        const areSettingsFocused = isSettingsRoute(route);
        const caseIsStaticallyRestricted =
          areSettingsFocused && nextKind === LIST_MENU_CATEGORIES_ROUTE;
        if (caseIsStaticallyRestricted) {
          return;
        }
        const currentKind = route.params?.focusedHeader?.kind;

        if (currentKind !== nextKind) {
          navigation.setParams({
            focusedHeader: nextKind && {kind: nextKind},
          });
          return;
        }
        if (
          !areSettingsFocused &&
          (nextKind === PROMPT_THEME_ROUTE ||
            nextKind === PROMPT_LANGUAGE_ROUTE)
        ) {
          navigation.setParams({
            focusedHeader: {kind: LIST_MENU_CATEGORIES_ROUTE},
          });
          return;
        }
        navigation.setParams({focusedHeader: undefined});
      },
    }),
    [authHelper, navigation, route, location],
  );
}

export function isSettingsRoute(
  route: LargeSwitchBindingProps<keyof LargeSwitchParamList>['route'],
): route is RouteProp<
  LargeSwitchParamList,
  | 'ListNotificationSettings'
  | 'ShowProfile'
  | 'ListSecuritySettings'
  | 'ListLimits'
> {
  switch (route.name) {
    case 'ListNotificationSettings':
    case 'ShowProfile':
    case 'ListSecuritySettings':
    case 'ListLimits':
      return true;
  }
  return false;
}
