import {
  UserCardDarkSvg,
  UserCardSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored/';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {View} from 'react-native';

import {useRoot} from '../../Root';
import {useTheme, variance} from '../../styling';

export type UserCardIconProps = {
  Icon?: React.ReactNode;
  secondaryIconColor?: string;
};

const UserCardIcon = observer((props: UserCardIconProps) => {
  const {secondaryIconColor, Icon} = props;
  const {appearance} = useRoot();
  const theme = useTheme();

  const _secondaryIconColor = useMemo(() => {
    return secondaryIconColor ? secondaryIconColor : theme.palette.primary;
  }, [secondaryIconColor, theme.palette.primary]);

  return (
    <View>
      {appearance.isDark ? <UserCardDarkSvg /> : <UserCardSvg />}
      {Icon && (
        <SecondaryIcon style={{backgroundColor: _secondaryIconColor}}>
          {Icon}
        </SecondaryIcon>
      )}
    </View>
  );
});

export default UserCardIcon;

const SecondaryIcon = variance(View)(theme => ({
  root: {
    position: 'absolute',
    top: 16,
    right: 8,
    width: 38,
    height: 38,
    borderRadius: 19,
    backgroundColor: theme.palette.primary,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
