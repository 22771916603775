import {variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import EmptyWalletList from '../../../shared/EmptyWalletList';
import type {WalletItemData} from '../../../shared/WalletItem';
import WalletItem from '../../../shared/WalletItem';
import WalletListSkeleton from './WalletListSkeleton';

export type CurrencyListProps = {
  data: WalletItemData[] | undefined;
  onSelect: (c: WalletItemData) => void;
  onAddPress: () => void;
};

export default observer(function WalletList(props: CurrencyListProps) {
  const {data, onSelect, onAddPress} = props;
  return (
    <FlatList
      data={data}
      renderItem={({item, index}) => (
        <WalletItem
          data={item}
          onPress={() => {
            onSelect(item);
          }}
          testID={`${index + 1}-coin-item`}
        />
      )}
      contentContainerStyle={data === undefined ? {} : styles.emptyList}
      ListEmptyComponent={
        data === undefined ? (
          <WalletListSkeleton />
        ) : (
          <EmptyWalletList onAddPress={onAddPress} />
        )
      }
      ItemSeparatorComponent={ListSeparator}
    />
  );
});

const styles = StyleSheet.create({
  emptyList: {
    flex: 1,
  },
});

const ListSeparator = variance(View)(theme => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.uiSecondary,
  },
}));
