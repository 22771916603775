import {variance} from '@ncwallet-app/core';
import {BaseSkeleton} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

export default observer(function NotificationListSkeletonItem() {
  return (
    <NotificationListSkeletonView>
      <NotificationSkeletonTopView>
        <NotificationSkeletonTopLeftView>
          <BaseSkeleton width={115} height={15} />
          <BaseSkeleton width={160} height={15} gap={10} />
        </NotificationSkeletonTopLeftView>
        <NotificationSkeletonTopRightView>
          <BaseSkeleton width={40} height={40} isRounded />
        </NotificationSkeletonTopRightView>
      </NotificationSkeletonTopView>
      <BaseSkeleton width={300} height={15} />
    </NotificationListSkeletonView>
  );
});

const NotificationListSkeletonView = variance(View)(theme => ({
  root: {
    paddingVertical: 13,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const NotificationSkeletonTopView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
}));

const NotificationSkeletonTopLeftView = variance(View)(() => ({
  root: {},
}));

const NotificationSkeletonTopRightView = variance(View)(() => ({
  root: {
    marginLeft: 'auto',
  },
}));
