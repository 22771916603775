import dayjs from 'dayjs';

import type {GlobalError} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {FileSystem} from '../FileSystem';
import type {Either} from '../fp';
import type {Log} from '../Log';
import type {Sharing} from '../Sharing';
import type {FileOptions, LogExporter, Markdown} from './LogExporter';

export default class LogExporterImpl implements LogExporter {
  constructor(
    private readonly _root: {
      readonly sharing: Sharing;
      readonly log: Log;
      readonly errorRepository: ErrorRepository;
      readonly fileSystem: FileSystem;
    },
  ) {}

  async save(): Promise<Either<void, GlobalError>> {
    const options = this._getFileOptions(this._root.log);
    return this._root.fileSystem.save(
      options.content,
      options.fileName,
      'text/plain',
    );
  }

  async share(): Promise<Either<void, GlobalError>> {
    const options = this._getFileOptions(this._root.log);
    return this._root.sharing.shareFile(options.fileName, options.content);
  }

  getContent(history: Log) {
    const body = history.records.map(_ => _.content.body).join('\n');
    return `${CODE}\n${body}\n${CODE}` as Markdown;
  }

  private _getFileOptions(history: Log): FileOptions {
    return {
      fileName: `${dayjs().format('HH.mm.ss__DD.MM')}_Logs.txt`,
      content: this.getContent(history),
    };
  }
}

const CODE = '```';
