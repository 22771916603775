import type {
  CryptoCurrencyCode,
  CurrencyStore,
  FiatCurrencyCode,
  Service,
  WalletStore,
} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import {getAccountFromState} from '@ncwallet-app/core/src/AccountStore';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {computed, makeObservable, observable, runInAction} from 'mobx';

import type {CurrencyHistoryRefresher} from '../../../shared/CurrencyHistoryRefresher';
import type {CurrencySelectionListConverter} from '../../../shared/CurrencySelectionListConverter';
import {getCurrencyWalletPairsForUserWallets} from '../../../shared/currencySelectionListConverterHelpers';

// eslint-disable-next-line import-x/prefer-default-export
export class SelectionForHistoryFilterBindingState implements Service {
  @computed private get _baseFiat(): FiatCurrencyCode | undefined {
    return getAccountFromState(this._root.accountStore.state)?.base_fiat;
  }
  @observable private _isLoading: boolean = false;

  @computed get currencies(): CurrencySelectionListItemData[] | undefined {
    const wallets = this._root.walletStore.getWallets();
    const currencies = this._root.currencyStore.getCryptoCurrencies();
    if (!this._baseFiat || !wallets || !currencies) {
      return;
    }

    return getCurrencyWalletPairsForUserWallets(currencies, wallets).map(
      ([c, w]) => this.itemConverter.toItem(c, w),
    );
  }

  constructor(
    private readonly _root: {
      readonly accountStore: AccountStore;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
    },
    private readonly itemConverter: CurrencySelectionListConverter,
    private readonly historyRefresher: CurrencyHistoryRefresher,
  ) {
    makeObservable(this);
  }

  getIsLoading = () => this._isLoading;

  async refresh() {
    runInAction(() => {
      this._isLoading = true;
    });
    void this._root.currencyStore.refreshFiatCurrencies();
    await Promise.all([
      this._root.walletStore.refreshWallets(),
      this._root.currencyStore.refreshCryptoCurrencies(),
    ]);
    runInAction(() => {
      this._isLoading = false;

      this.historyRefresher.setStaleCodes(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.currencies!.map(c => c.cryptoCurrency.code as CryptoCurrencyCode),
      );
    });
  }

  subscribe() {
    return this.historyRefresher.subscribe();
  }

  activateRateHistoryLoad() {
    this.historyRefresher.activate();
  }

  deactivateRateHistoryLoad() {
    this.historyRefresher.deactivate();
  }
}
