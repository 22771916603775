import {variance} from '@ncwallet-app/core';
import {WarningSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React from 'react';
import {View} from 'react-native';

export type WalletLimitsWarningProps = PropsWithChildren;

export default observer(function WalletLimitsWarning(
  props: WalletLimitsWarningProps,
) {
  const {children} = props;
  return (
    <WalletLimitWarningContainer>
      <WalletLimitWarningIcon>
        <WarningIcon />
      </WalletLimitWarningIcon>
      <WalletLimitWarningBody>{children}</WalletLimitWarningBody>
    </WalletLimitWarningContainer>
  );
});

const WalletLimitWarningContainer = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'stretch',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.primary,
    backgroundColor: theme.palette.additional2,
    overflow: 'hidden',
  },
}));

const WalletLimitWarningIcon = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    backgroundColor: theme.palette.primary,
  },
}));

const WalletLimitWarningBody = variance(View)(() => ({
  root: {
    flex: 1,
    padding: 10,
  },
}));

const WarningIcon = variance(WarningSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.whitey,
  }),
);
