import type {Either, GlobalError, PinCode} from '@ncwallet-app/core';
import {error, success, useRoot} from '@ncwallet-app/core';
import type {PinInvalidError} from '@ncwallet-app/core/src/ErrorRecognizer/PinError';
import {createPinInvalidError} from '@ncwallet-app/core/src/ErrorRecognizer/PinError';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useCheckPinContainer} from '../../../CommonNavigationContainers/hooks/useCheckPinContainer';
import {PinScreen} from '../../../screens/PinScreen';
import type {PinScreenTexts} from '../../../screens/PinScreen/PinScreenTexts';
import {useSecurePinTextsOld} from '../../../screens/PinScreen/PinScreenTexts';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type CheckPinBindingProps = SmallHomeStackBindingProps<'CheckPin'>;

export type CheckPinInfo = {
  showError: boolean;
  texts: PinScreenTexts;
};
export default observer(function CheckPinBinding(props: CheckPinBindingProps) {
  const {navigation} = props;
  const {deviceInfo} = useRoot();
  const appVersion = deviceInfo.getAppVersion();
  const enterOldPinTexts = useSecurePinTextsOld();
  const setPinCodeCallBack = useCallback(
    (pin: PinCode) => {
      navigation.replace('PromptNewPin', {oldPin: pin});
    },
    [navigation],
  );

  const checkPin = usePinValidation();

  const {texts, getPinCode, setPinCode, getErrorShown, onErrorActionPress} =
    useCheckPinContainer(checkPin, setPinCodeCallBack, enterOldPinTexts);
  const onCancel = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.replace('ListSecuritySettings');
  }, [navigation]);

  return (
    <PinScreen
      appVersion={appVersion}
      texts={texts}
      getPin={getPinCode}
      setPin={setPinCode}
      getErrorShown={getErrorShown}
      onCancel={onCancel}
      onErrorActionPress={onErrorActionPress}
    />
  );
});

export const usePinValidation = () => {
  const {ncWalletJsonRpcClient} = useRoot();
  return useCallback(
    async (
      pinCode: PinCode,
    ): Promise<Either<void, PinInvalidError | GlobalError>> => {
      const check_ = await ncWalletJsonRpcClient.call('accounts.pin.validate', {
        pin: pinCode,
      });

      if (check_.success) {
        const nextAllowedAttempt = check_.right.details.next_allowed_attempt;

        return check_.right.is_valid
          ? success()
          : error(createPinInvalidError(nextAllowedAttempt));
      } else {
        return check_;
      }
    },
    [ncWalletJsonRpcClient],
  );
};
