import {getActionFromState} from '@react-navigation/core';

import type {GlobalError} from '../Error';
import {UNKNOWN_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {
  LinkingOptionsProvider,
  NavigationContainer,
} from '../NavigationContainer';
import type {Navigation} from './Navigation';

export default class BaseNavigationImpl implements Navigation {
  constructor(
    protected readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly navigationContainer: NavigationContainer;
      readonly linkingOptionsProvider: LinkingOptionsProvider;
    },
  ) {}

  navigate(path: string): Either<void, GlobalError> {
    if (!path.startsWith('/')) {
      return error(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          description: `The path must start with '/' (${path}).`,
        }),
      );
    }

    const navigation = this._root.navigationContainer.ref;
    if (navigation === undefined) {
      return error(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          description:
            "Couldn't find a navigation object. Seems like navigation container is not configured yet.",
        }),
      );
    }

    const options = this._root.linkingOptionsProvider.linking;

    if (!options.getStateFromPath) {
      return error(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          description:
            'The links are not configured: getStateFromPath in undefined',
        }),
      );
    }

    const state = options.getStateFromPath(path, options.config);

    if (state) {
      const action = (options.getActionFromState ?? getActionFromState)(
        state,
        options.config,
      );

      if (action !== undefined) {
        navigation.dispatch(action);
      } else {
        navigation.reset(state);
      }

      return success();
    } else {
      return error(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          description: 'Failed to parse the path to a navigation state.',
        }),
      );
    }
  }
}
