import {sized, useStrings, useTheme} from '@ncwallet-app/core';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {HelpSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Linking} from 'react-native';

import {
  BodyTag,
  ClickableIcon,
  ClickableTag,
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import AddIpScreen from '../../UserSettingsScreens/AddIpScreen/AddIpScreen';

export type SessionIpNewProps = {
  onBack: () => void;
  type: IpType;
  onSubmit: (ip: string, name: string) => void;
  getError: () => string | undefined;
  resetError: () => void;
  faqLink: string;
};

export default observer(function SessionIpNew({
  onBack,
  type,
  onSubmit,
  resetError,
  getError,
  faqLink,
}: SessionIpNewProps) {
  const strings = useStrings();
  const theme = useTheme();
  const handleHelpPress = useCallback(async () => {
    await Linking.openURL(faqLink);
  }, [faqLink]);
  return (
    <LgProfileCardContainer>
      <HeaderTag>
        <ClickableTag onPress={onBack}>
          <ClickableIcon />
          <HeaderTitleTag offsetLeft>
            {type === IpType.Blocked
              ? strings['lgSecureSettingsScreen.newBlockedIps.new']
              : strings['lgSecureSettingsScreen.newAllowedIpsTitle']}
          </HeaderTitleTag>
        </ClickableTag>
        <TouchableOpacity onPress={handleHelpPress}>
          <HelpIcon color={theme.palette.uiAdditional1} />
        </TouchableOpacity>
      </HeaderTag>
      <BodyTag>
        <AddIpScreen
          wide
          onAdd={onSubmit}
          type={type}
          getError={getError}
          resetError={resetError}
        />
      </BodyTag>
    </LgProfileCardContainer>
  );
});

const HelpIcon = sized(HelpSvg, 17);
