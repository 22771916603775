import {variance} from '@ncwallet-app/core';
import {useIsDimensions} from '@ncwallet-app/ui';
import type {BottomTabBarProps} from '@react-navigation/bottom-tabs';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useCallback, useEffect} from 'react';
import type {LayoutChangeEvent} from 'react-native';
import {View} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import {BottomTabBar} from './BottomTabBar';
import {useTabBarContext} from './useTabBarContext';

export type AnimatedBottomTabBarProps = BottomTabBarProps & {};

export const AnimatedBottomTabBar = observer(
  (props: AnimatedBottomTabBarProps) => {
    const {...rest} = props;
    const {getTabBarLayout, visible} = useTabBarContext();
    const {changeLayout} = useTabBarContext();
    const layoutHeight = expr(() => getTabBarLayout().height);
    const offset = useSharedValue(visible ? 0 : layoutHeight);
    const animatedStyles = useAnimatedStyle(() => {
      return {
        transform: [{translateY: offset.value}],
      };
    }, [offset]);

    useEffect(() => {
      if (visible) {
        offset.value = withTiming(0);
      } else {
        offset.value = withTiming(layoutHeight);
      }
    }, [layoutHeight, offset, visible]);

    const onLayoutChange = useCallback(
      (e: LayoutChangeEvent) => {
        changeLayout(e.nativeEvent.layout);
      },
      [changeLayout],
    );

    const isLg = useIsDimensions('lg');
    if (isLg) {
      return null;
    }

    return (
      <AnimatedRoot style={animatedStyles}>
        <View onLayout={onLayoutChange}>
          <BottomTabBar {...rest} />
        </View>
      </AnimatedRoot>
    );
  },
);

const AnimatedRoot = variance(Animated.View)(() => ({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
}));
