import {useTheme, variance} from '@ncwallet-app/core';
import type {PressableNativeFeedbackProps} from '@ncwallet-app/ui';
import {PressableNativeFeedback} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {View} from 'react-native';

type PinKeyboardKeyProps = PressableNativeFeedbackProps & {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
};

// eslint-disable-next-line import-x/prefer-default-export
export const PinKeyboardButton = observer(function PinKeyboardButton(
  props: PropsWithChildren<PinKeyboardKeyProps>,
) {
  const {onPress, style, children, ...rest} = props;
  const theme = useTheme();
  const rippleColor = theme.chroma(theme.palette.textMain).alpha(0.3).hex();

  return (
    <PinKeyboardButtonContainer>
      <PressableNativeFeedback
        androidFlashColor={rippleColor}
        onPress={onPress}
        style={style}
        {...rest}>
        {children}
      </PressableNativeFeedback>
    </PinKeyboardButtonContainer>
  );
});

const PinKeyboardButtonContainer = variance(View)(() => ({
  root: {
    overflow: 'hidden',
    borderRadius: 5,
  },
}));
