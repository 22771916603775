import rawEnv from './rawEnv';

export enum EnvKind {
  Canary = 'canary',
  Prod = 'prod',
  Dev = 'dev', // default
}

export type Env = {
  NC_WALLET_SENTRY_DSN_CHROME_EXTENSION?: string;
  NC_WALLET_SENTRY_DSN_ANDROID?: string;
  NC_WALLET_SENTRY_DSN_IOS?: string;
  NC_WALLET_SENTRY_DSN_WEB?: string;
  NC_WALLET_SENTRY_DSN_TELEGRAM?: string;
  NC_WALLET_ENV: EnvKind;
};

const toEnvKind = (str: string | undefined): EnvKind => {
  if (!str) {
    return EnvKind.Dev;
  }
  const lowerCased = str.toLowerCase() as EnvKind;
  return Object.values(EnvKind).includes(lowerCased) ? lowerCased : EnvKind.Dev;
};

const NC_WALLET = 'NC_WALLET_';
export const fromRawEnv = (raw: Record<string, string | undefined>): Env => {
  const entries = Object.entries(raw).filter(([key]) =>
    key.includes(NC_WALLET),
  );

  const filtered = Object.fromEntries(entries);

  const env = {
    ...filtered,
    NC_WALLET_ENV: toEnvKind(filtered.NC_WALLET_ENV),
  };

  return env as Env;
};

export const env = fromRawEnv(rawEnv);
