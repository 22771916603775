import {useLogButton, useRoot, useStrings} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {PendingRequestScreen} from '../../screens/PendingRequestScreen';
import type {AuthStackBindingProps} from './AuthStackBindingProps';

export type PendingAuthBindingProps = AuthStackBindingProps<'PendingAuth'>;

export default observer(function PendingAuthBinding() {
  const {authHelper, deviceInfo} = useRoot();
  const logButtonComponent = useLogButton();
  const onCancel = useCallback(
    () => authHelper.signOut({reason: 'PendingAuthBinding'}),
    [authHelper],
  );
  const strings = useStrings();
  return (
    <PendingRequestScreen
      title={strings['waitingAuthScreen.title']}
      cancelText={strings['waitingAuthScreen.cancelButton']}
      onCancel={onCancel}
      LogButton={logButtonComponent}
      appVersion={deviceInfo.getAppVersionWithBuildNumber()}
    />
  );
});
