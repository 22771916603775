/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import type {CurrencyDescription, DecimalString} from '@ncwallet-app/core';
import {urlLinks, useStrings, variance} from '@ncwallet-app/core';
import {
  ButtonVariant,
  CurrencyCircleLogo,
  DecimalInput,
  TouchableOpacity,
} from '@ncwallet-app/ui';
import {HelpSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {BigNumber} from 'bignumber.js';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {Linking, Text, View} from 'react-native';

import WalletLimitsWarning from '../../../../shared/WalletLimitsWarning';
import {LimitCard} from '../../shared/LimitCard';
import {LimitCardBody} from '../../shared/LimitCardBody';
import {
  LimitCardHeader,
  LimitCardHeaderCurrency,
  LimitCardHeaderCurrencyTitle,
  LimitCardHeaderTitle,
} from '../../shared/LimitCardHeader';
import {LimitError, LimitFormSubmitButton} from './LimitFormSubmitButton';

export type EditLimitFormProps = {
  initialValue: string;
  currency: CurrencyDescription;
  onSubmit: (limit: DecimalString) => void;
  error: LocaleKeys | undefined;
  resetError: () => void;
};

export default observer(function EditLimitForm(props: EditLimitFormProps) {
  const strings = useStrings();
  const {currency, initialValue, onSubmit, error, resetError} = props;

  const [value, setValue] = useState<DecimalString | undefined>(initialValue);
  const onChangeValue = useCallback(
    (v: DecimalString | undefined) => {
      resetError();
      setValue(v);
    },
    [resetError],
  );

  return (
    <LimitCard>
      <LimitCardHeader>
        <LimitCardHeaderTitle>
          {strings['limitsSettingsScreen.setLimit']}
        </LimitCardHeaderTitle>
        <LimitCardHeaderCurrency>
          <CurrencyCircleLogo code={currency.code} size={20} />
          <LimitCardHeaderCurrencyTitle>
            {currency.code}
          </LimitCardHeaderCurrencyTitle>
        </LimitCardHeaderCurrency>
      </LimitCardHeader>
      <LimitCardBody>
        <WalletLimitsWarning>
          <WalletLimitsRow>
            <WalletLimitsWarningText>
              {strings['currencyLimitScreen.warningLabel']}
            </WalletLimitsWarningText>
            <WalletLimitsWarningHelp
              onPress={() => {
                void Linking.openURL(urlLinks.changeWalletLimitLink);
              }}>
              <HelpIcon />
            </WalletLimitsWarningHelp>
          </WalletLimitsRow>
        </WalletLimitsWarning>
        <SizedBox />
        <DecimalInput
          fractionDigits={props.currency.fractionDigits}
          label={strings['limitsSettingsScreen.inputLabel']}
          value={value}
          onChangeDecimal={onChangeValue}
        />
        {!!error && <LimitError>{strings[error]}</LimitError>}
        <LimitFormSubmitButton
          variant={ButtonVariant.Primary}
          disabled={!value || BigNumber(value).isLessThanOrEqualTo(0)}
          title={strings['currencyLimitScreen.changeButton']}
          onPress={() => {
            onSubmit(value!);
          }}
        />
      </LimitCardBody>
    </LimitCard>
  );
});

const WalletLimitsWarningText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 20,
    color: theme.palette.textSecondary,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 25,
  },
}));

const WalletLimitsRow = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const WalletLimitsWarningHelp = variance(TouchableOpacity)(
  () => ({
    root: {},
    container: {
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 'auto',
      width: 18,
      height: 18,
    },
  }),
  (_, styles) => ({
    containerStyle: styles.container,
  }),
);

const HelpIcon = variance(HelpSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.additional4,
  }),
);
