import {BottomSheet, ButtonVariant} from '@ncwallet-app/ui';
import React from 'react';

import {
  BottomButton,
  CenterAppIcon,
  CenterDescription,
  CenterTitle,
  Icon,
  ModalBody,
  ModalBottom,
  ModalCenter,
} from './BlockchainNetworkWarningBody';
import type {BlockchainNetworkWarningScreenProps} from './BlockchainNetworkWarningScreenProps';

// eslint-disable-next-line import-x/prefer-default-export
export const BlockchainNetworkWarningBottomSheet = (
  props: BlockchainNetworkWarningScreenProps,
) => {
  const {onClose, title, img, desc, btnText} = props;

  return (
    <BottomSheet
      index={0}
      animateOnMount
      onClose={onClose}
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      snapPoints={[400]}
      enablePanDownToClose
      title={title}>
      <ModalBody>
        <ModalCenter>
          <CenterAppIcon>
            <Icon
              source={{uri: img}}
              resizeMode={'cover'}
              resizeMethod="resize"
            />
          </CenterAppIcon>
          <CenterTitle>{title}</CenterTitle>
          {desc && <CenterDescription>{desc}</CenterDescription>}
          {btnText && (
            <ModalBottom>
              <BottomButton
                variant={ButtonVariant.Primary}
                onPress={onClose}
                title={btnText}
              />
            </ModalBottom>
          )}
        </ModalCenter>
      </ModalBody>
    </BottomSheet>
  );
};
