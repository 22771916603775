/* eslint-disable import-x/no-deprecated,@typescript-eslint/no-deprecated */
import type {
  LocaleDict,
  LocaleKeys,
} from '@nc-wallet/localization/locale/LocaleStrings';

import type {
  LegacyLocalization,
  LegacyTemplateExecutor,
} from './LegacyLocalization';
import type {Localization} from './Localization';

export default class LegacyLocalizationImpl implements LegacyLocalization {
  public readonly strings: Readonly<LocaleDict>;
  public readonly templates: Readonly<
    Record<LocaleKeys, LegacyTemplateExecutor>
  >;

  constructor(private readonly _root: {readonly localization: Localization}) {
    const that = this;
    this.strings = new Proxy<LocaleDict>({} as LocaleDict, {
      get(target: object, translation: keyof LocaleDict): string {
        return that._root.localization.getTranslation(translation);
      },
    });
    this.templates = new Proxy<Record<LocaleKeys, LegacyTemplateExecutor>>(
      {} as Record<LocaleKeys, LegacyTemplateExecutor>,
      {
        get(
          target: object,
          translation: keyof LocaleDict,
        ): LegacyTemplateExecutor {
          return substitution => {
            return that._root.localization.executeTemplate(
              translation,
              substitution,
            );
          };
        },
      },
    );
  }
}
