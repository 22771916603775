import type {WalletId} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useSelectionForHistoryFilterBindingState} from '../../Navigation/HomeStack/SelectionForHistoryFilterBinding/useSelectionForHistoryFilterBindingState';
import {filterCurrencySelectionItems} from '../../Navigation/hooks';
import {EXTRA_BOTTOM_OFFSET} from '../../screens/constants';
import {SelectionForHistoryScreen} from '../../screens/SelectionForHistoryScreen';

export type PromptWalletToFilterHistoryContainerProps = {
  search: string | undefined;
  onSearchChange: (search: string) => void;
  onBack: () => void;
  onSelect: (walletId: WalletId | undefined) => void;
};

export default observer(function PromptWalletToFilterHistoryContainer(
  props: PromptWalletToFilterHistoryContainerProps,
) {
  const {onSelect: _onSelect, search, onSearchChange, onBack} = props;
  const root = useRoot();

  const [state, refresh] = useSelectionForHistoryFilterBindingState();

  const shownCurrencies = filterCurrencySelectionItems(
    state.currencies,
    search,
  );

  const onSelect = useCallback(
    (itemData: CurrencySelectionListItemData) => {
      _onSelect(itemData.walletId);
    },
    [_onSelect],
  );

  const onAll = useCallback(() => {
    _onSelect(undefined);
  }, [_onSelect]);

  return (
    <SelectionForHistoryScreen
      totalShownCurrencies={root.walletsState.getTotal()}
      shownCurrencies={shownCurrencies}
      allCurrencies={state.currencies}
      search={search}
      onSearchChange={onSearchChange}
      onCurrencyPress={onSelect}
      onAllPress={onAll}
      onRefresh={refresh}
      getIsLoading={state.getIsLoading}
      onBack={onBack}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});
