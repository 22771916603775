import {variance} from '@ncwallet-app/core';
import {
  BottomSheet,
  Button,
  ButtonColor,
  ButtonVariant,
  Spinner,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Text, View} from 'react-native';

import type {ShowTransactionReportBottomSheetScreenProps} from './ShowTransactionReportProps';

export default observer(function ShowTransactionReportBottomSheetScreen(
  props: ShowTransactionReportBottomSheetScreenProps,
) {
  const {title, description, Icon, onClose, isLoading, actions} = props;

  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        props.onClose();
      }
    },
    [props],
  );

  return (
    <BottomSheet
      ref={props.sheetRef}
      index={0}
      onChange={onChange}
      snapPoints={[400]}
      enablePanDownToClose
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      title={title}
      onClose={onClose}
      children={
        <BottomSheetBody>
          <Icon />
          <Description>{description}</Description>
          <ButtonGroup>
            {isLoading ? (
              <SpinnerWrapper>
                <Spinner size={50} />
              </SpinnerWrapper>
            ) : (
              <>
                {actions.map(action => (
                  <Button
                    key={action.title}
                    Icon={action.Icon}
                    variant={ButtonVariant.PrimaryLight}
                    title={action.title}
                    onPress={action.onPress}
                    color={ButtonColor.Secondary}
                  />
                ))}
              </>
            )}
          </ButtonGroup>
        </BottomSheetBody>
      }
    />
  );
});

const SpinnerWrapper = variance(View)(() => ({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const BottomSheetBody = variance(View)(() => ({
  root: {
    flex: 1,
    alignItems: 'center',
    padding: 20,
    gap: 20,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    fontSize: 16,
    lineHeight: 16,
    alignSelf: 'flex-start',
  },
}));

const ButtonGroup = variance(View)(() => ({
  root: {
    alignSelf: 'stretch',
    marginTop: 20,
    gap: 10,
  },
}));
