import type {SecureKeyValueMap} from './SecureKeyValueMap';

// eslint-disable-next-line import-x/prefer-default-export
export const secureKeys = new Set(
  Object.keys({
    auth: 1,
    auth2: 1,
    identity: 1,
    identity2: 1,
    device: 1,
    biometricSettings: 1,
    hasRemoteChannel: 1,
    twoFaSecret: 1,
  } satisfies Record<
    keyof SecureKeyValueMap,
    unknown
  >) as (keyof SecureKeyValueMap)[],
);
