import type {CryptoAddress, WalletId} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useIsDimensions} from '@ncwallet-app/ui';
import {useCallback, useEffect} from 'react';

export function useRedirectToNotFoundIfNoWalletWithId(
  id: WalletId | undefined,
) {
  const {walletStore, navigationContainer} = useRoot();
  const isLoaded = walletStore.isLoaded();
  const isLg = useIsDimensions('lg');
  const toNotFound = useNavigateToNotFound();
  useEffect(() => {
    if (id && isLoaded && !walletStore.getWalletById(id)) {
      toNotFound();
    }
  }, [navigationContainer, id, isLoaded, walletStore, isLg, toNotFound]);
}

export function useRedirectToNotFoundIfNoAddress(
  walletId: WalletId | undefined,
  address: CryptoAddress,
) {
  const {walletStore} = useRoot();
  const isLoaded = walletStore.isLoaded();
  const toNotFound = useNavigateToNotFound();
  useEffect(() => {
    if (
      walletId &&
      address &&
      isLoaded &&
      !walletStore
        .getWalletById(walletId)
        ?.addresses.find(a => a.address === address)
    ) {
      toNotFound();
    }
  }, [address, toNotFound, walletId, walletStore, isLoaded]);
}

const useNavigateToNotFound = () => {
  const {navigationContainer} = useRoot();
  const isLg = useIsDimensions('lg');

  return useCallback(() => {
    if (isLg) {
      navigationContainer.ref?.reset({
        index: 0,
        routes: [
          {name: 'Root', state: {index: 0, routes: [{name: 'FallBack'}]}},
        ],
      });
    } else {
      navigationContainer.ref?.reset({
        index: 0,
        routes: [{name: 'FallBack'}],
      });
    }
  }, [isLg, navigationContainer.ref]);
};
