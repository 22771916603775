import type {AuthHelper, AuthQuery} from '../Auth';
import {AUTHORIZED} from '../Auth';
import {unwrap} from '../EitherAdapter';
import type {JsonRpcClient} from '../JsonRpc';
import type {Jwt} from '../Jwt';
import {isDirectResult} from '../NCWalletServer';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {AccountProviderMap} from '../NCWalletServer/LinkOauth';
import type {AccountLinkageHandler} from './AccountLinkageHandler';

export default class AccountLinkageHandlerImpl
  implements AccountLinkageHandler
{
  constructor(
    protected readonly _root: {
      readonly authHelper: AuthHelper;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly authQuery: AuthQuery;
      readonly jwt: Jwt;
    },
  ) {}

  async link<T extends keyof AccountProviderMap>(
    provider: T,
    token: AccountProviderMap[T],
  ): Promise<void> {
    const newTokenResponse = await unwrap(
      this._root.ncWalletJsonRpcClient.call('accounts.link_oauth', {
        provider,
        token,
      }),
    );
    const authQuery = await unwrap(this._root.authQuery.query());
    if (isDirectResult(newTokenResponse) && authQuery.kind === AUTHORIZED) {
      const oldTokenParsed = unwrap(
        this._root.jwt.parse(authQuery.credentials.accessToken),
      );
      const newTokenParsed = unwrap(
        this._root.jwt.parse(newTokenResponse.access_token),
      );

      if (oldTokenParsed.payload.sub === newTokenParsed.payload.sub) {
        await unwrap(this._root.authHelper.completeLinkage(newTokenResponse));
        return;
      }
    }
    await unwrap(this._root.authHelper.signOut());
    await unwrap(this._root.authHelper.completeLinkage(newTokenResponse));
  }
}
