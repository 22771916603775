/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import type {AddressType} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {
  Button,
  CurrencySelectionViewContainer,
  Pressable,
} from '@ncwallet-app/ui/src';
import {ArrowLeftWide} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type LgAddressTypeScreenProps = {
  onBack: () => void;
  onSelect: (addressType: AddressType) => void;
  defaultAddressType: AddressType | undefined;
  addressTypes: AddressType[] | undefined;
};

export default observer(function LgAddressTypeScreen({
  onBack,
  onSelect,
  defaultAddressType,
  addressTypes,
}: LgAddressTypeScreenProps) {
  const theme = useTheme();
  const strings = useStrings();

  return (
    <CurrencySelectionViewContainer>
      <BackButton
        title={strings['lgAddressTypeScreen.backButton']}
        onPress={onBack}
        Icon={ArrowLeftWide}
        iconCustomColor={theme.palette.uiAdditional1}
      />
      <ListContainer>
        {addressTypes?.map((type, index) => (
          <AddressPress
            key={index}
            onPress={() => {
              onSelect(type);
            }}
            isBorderVisible={addressTypes && index !== addressTypes.length - 1}
            last={addressTypes && index === addressTypes.length - 1}>
            <AddressTitle>{type}</AddressTitle>
            {!isNil(defaultAddressType) && type === defaultAddressType && (
              <Tag>{strings['lgAddressTypeScreen.recommended']}</Tag>
            )}
          </AddressPress>
        ))}
      </ListContainer>
    </CurrencySelectionViewContainer>
  );
});

const BackButton = variance(Button)(() => ({
  root: {
    marginBottom: 20,
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));

const ListContainer = variance(View)(theme => ({
  root: {
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    borderRadius: 8,
  },
}));

const AddressPress = variance(Pressable)(
  () => ({
    root: {
      paddingHorizontal: 15,
      paddingVertical: 20,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    last: {
      borderBottomWidth: 0,
    },
  }),
  theme => ({
    borderStyle: {
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.uiSecondary,
    },
  }),
);

const AddressTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 19,
    color: theme.palette.textPrimary,
  },
}));

const Tag = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 11,
    paddingHorizontal: 5,
    lineHeight: 15,
    color: theme.palette.primary,
    borderWidth: 1,
    borderColor: theme.palette.primary,
    borderRadius: 20,
    marginLeft: 15,
    marginRight: 'auto',
  },
}));
