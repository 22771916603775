import {action, computed, makeObservable, observable, when} from 'mobx';

import type {AccountStore} from '../AccountStore';
import {FULFILLED} from '../AsyncAtom';
import type {AuthNavigationHelper} from '../AuthNavigationHelper';
import {
  SHOW_PENDING_AUTH,
  SHOW_PENDING_CONNECTION,
} from '../AuthNavigationHelper';
import type {ConnectionState} from '../JsonRpc';
import {ConnectionStatus} from '../JsonRpc';
import type {Service} from '../structure';
import type {AppStateHelper} from './AppStateHelper';

export default class AppStateHelperService implements AppStateHelper, Service {
  @observable private _isReadyToHideSplashScreen = false;

  constructor(
    protected readonly _root: {
      readonly authNavigationHelper: AuthNavigationHelper;
      readonly connectionState: ConnectionState;
      readonly accountStore: AccountStore;
    },
  ) {
    makeObservable(this);
  }

  @computed get isReadyToShowUserInterface() {
    return this._root.authNavigationHelper.route === undefined;
  }

  @computed get isReadyToMakeRequests() {
    return (
      this._root.connectionState.latestStatus === ConnectionStatus.Open &&
      this._root.accountStore.state?.status === FULFILLED &&
      this._root.accountStore.state.result.connectionId ===
        this._root.connectionState.id
    );
  }

  @computed get isReadyToHideSplashScreen() {
    return this._isReadyToHideSplashScreen;
  }

  subscribe() {
    return when(
      this._authHasCompletedOrRequiresUserAction,
      action(() => {
        this._isReadyToHideSplashScreen = true;
      }),
    );
  }

  private readonly _authHasCompletedOrRequiresUserAction = () => {
    const {route} = this._root.authNavigationHelper;
    switch (route?.kind) {
      case SHOW_PENDING_AUTH:
      case SHOW_PENDING_CONNECTION:
        return false;
    }
    return true;
  };
}
