import type {
  DirectCredentials,
  MultiFactorCredentials,
  PreAuthCredentials,
} from '../Credentials';
import type {GlobalError} from '../Error';
import type {Either} from '../fp';

export interface AuthQuery {
  query(): Promise<Either<AuthResponse, GlobalError>>;
}

export const UNAUTHORIZED = Symbol();

export const PRE_AUTH = Symbol();
export const AUTHORIZED = Symbol();
export const MULTI_FACTOR = Symbol();

export type AuthResponse =
  | UnauthorizedResponse
  | PreAuthResponse
  | AuthorizedResponse
  | MultiFactorResponse;

export type UnauthorizedResponse = {
  kind: typeof UNAUTHORIZED;
  reason: UnauthorizedResponseReason;
};

export type PreAuthResponse = {
  kind: typeof PRE_AUTH;
  credentials: PreAuthCredentials;
};

export type AuthorizedResponse = {
  kind: typeof AUTHORIZED;
  credentials: DirectCredentials;
};

export type MultiFactorResponse = {
  kind: typeof MULTI_FACTOR;
  credentials: MultiFactorCredentials;
};

export const NO_LOCAL_RECORD = Symbol();
export const RECORD_EXPIRED = Symbol();

export type UnauthorizedResponseReason =
  | typeof NO_LOCAL_RECORD
  | typeof RECORD_EXPIRED;
