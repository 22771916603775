import {useStrings, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type LimitsSettingsEmptySearchProps = {
  searchValue: string;
  isLg?: boolean;
};

export default observer(function LimitsSettingsEmptySearch(
  props: LimitsSettingsEmptySearchProps,
) {
  const {searchValue, isLg} = props;
  const strings = useStrings();

  return (
    <EmptySearchView lg={isLg}>
      <EmptySearchTitle>
        {strings['limitsSettingsScreen.emptySearch.title'].replace(
          '{searchValue}',
          searchValue,
        )}
      </EmptySearchTitle>
      <EmptySearchText>
        {strings['limitsSettingsScreen.emptySearch.text']}
      </EmptySearchText>
    </EmptySearchView>
  );
});

const EmptySearchView = variance(View)(() => ({
  root: {
    paddingTop: 20,
    paddingHorizontal: 15,
  },
  lg: {
    paddingTop: 0,
    paddingHorizontal: 10,
  },
}));

const EmptySearchTitle = variance(Text)(theme => ({
  root: {
    marginBottom: 5,
    ...theme.fontByWeight('700'),
    fontSize: 16,
    color: theme.palette.textPrimary,
  },
}));

const EmptySearchText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 13,
    color: theme.palette.textAdditional2,
  },
}));
