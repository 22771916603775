import {computed, makeObservable} from 'mobx';

import type {Log} from '../Log';
import type {LogFilter} from './LogFilter';

export default class LogFilterImpl implements LogFilter {
  constructor(private readonly _root: {readonly log: Log}) {
    makeObservable(this);
  }

  @computed get records() {
    const {records} = this._root.log;
    return records;
  }
}
