import {
  LIST_MENU_CATEGORIES_ROUTE,
  LIST_NOTIFICATIONS_ROUTE,
  PROMPT_LANGUAGE_ROUTE,
  PROMPT_THEME_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {useMemo} from 'react';

import type {MenuRouteKind} from './useScreenHeaderWideProps';

// eslint-disable-next-line import-x/prefer-default-export
export const useIsHeaderModalsFocused = (
  focusedMenu: MenuRouteKind | undefined,
) =>
  useMemo(() => {
    const isLanguageModalFocused = focusedMenu === PROMPT_LANGUAGE_ROUTE;
    const areNotificationsVisible = focusedMenu === LIST_NOTIFICATIONS_ROUTE;
    const isThemeModalFocused = focusedMenu === PROMPT_THEME_ROUTE;
    const isMenuVisible =
      focusedMenu === LIST_MENU_CATEGORIES_ROUTE ||
      isThemeModalFocused ||
      isLanguageModalFocused;

    return {
      isMenuVisible,
      isThemeModalFocused,
      isLanguageModalFocused,
      areNotificationsVisible,
    };
  }, [focusedMenu]);
