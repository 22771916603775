import {last} from '.';
import type {CommonRoute, ShallowCommonState} from '../CommonNavigationScheme';

function getCurrentRouteKind(_: ShallowCommonState): CommonRoute['kind'];
function getCurrentRouteKind(
  _?: ShallowCommonState,
): CommonRoute['kind'] | undefined;
function getCurrentRouteKind(
  _?: ShallowCommonState,
): CommonRoute['kind'] | undefined {
  return _ && last(_).route.kind;
}

export default getCurrentRouteKind;
