import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import type {ReactNode} from 'react';

export enum WalletActionKind {
  Buy,
  Receive,
  Send,
  Exchange,
  History,
  Add,
  CreateNew,
}

export type WalletData = {
  [key in WalletActionKind]: {
    name: LocaleKeys;
    description: LocaleKeys;
    icon: ReactNode;
  };
};
