import {useStrings, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
  SafeAreaInset,
  SafeAreaLayout,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {EXTRA_BOTTOM_OFFSET} from '../constants';

export type AskCameraPermissionsViewProps = {
  permissionTexts: {title: string; text: string};
  canAskPermissionsAgain: boolean | undefined;
  onSettingsPress: () => void;
};

export const AskCameraPermissionsView = observer(
  (props: AskCameraPermissionsViewProps) => {
    const strings = useStrings();
    const buttonTitle = props.canAskPermissionsAgain
      ? strings['qrCodeScreen.canAskButton']
      : strings[
          'deleteIdentityUploadedDocument.permissions.actions.toSettings'
        ];

    return (
      <>
        <AskPermissionView>
          <Title>{props.permissionTexts.title}</Title>
          <Description>{props.permissionTexts.text}</Description>
        </AskPermissionView>
        <Footer
          insets={SafeAreaInset.BOTTOM}
          extra={{bottom: EXTRA_BOTTOM_OFFSET}}>
          <AllowButton
            title={buttonTitle}
            variant={ButtonVariant.Highlighted}
            color={ButtonColor.Secondary}
            onPress={props.onSettingsPress}
          />
        </Footer>
      </>
    );
  },
);

const AskPermissionView = variance(View)(() => ({
  root: {
    marginTop: 'auto',
    marginBottom: 'auto',
    alignItems: 'center',
  },
}));

const AllowButton = variance(Button)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        minWidth: 345,
      },
    }),
  },
}));

const Footer = variance(SafeAreaLayout)(() => ({
  root: {},
}));

const Title = variance(Text)(theme => ({
  root: {
    color: theme.palette.whitey,
    ...theme.fontByWeight('700'),
    marginBottom: 15,
    textAlign: 'center',
    fontSize: 20,
    lineHeight: 24,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.textAdditional3,
    textAlign: 'center',
  },
}));
