import type {DecimalString} from '@ncwallet-app/core';
import {useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {useHeaderHeight} from '@react-navigation/elements';
import {isNil} from 'lodash';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import {SendAmountValidator} from '../../../../CommonNavigationContainers/hooks/PromptOutputAddressBindingState/SendAmountValidator';
import {useSendAmountErrorTexts} from '../../../../CommonNavigationContainers/hooks/PromptOutputAddressBindingState/useSendAmountErrorTexts';
import {WalletLimitHelper} from '../../../../CommonNavigationContainers/hooks/usePromptExchangeReceiptContainer/WalletLimitHelper';
import {useNavigationGetIsFocused} from '../../../../Navigation/hooks';
import {EXTRA_BOTTOM_OFFSET} from '../../../../screens/constants';
import {SendAmountInputScreen} from '../../../../screens/SendAmountInputScreen/SendAmountInputScreen';
import type {SmallHomeStackBindingProps} from '../../SmallHomeStackBindingProps';
import {PromptAmountToSendBindingState} from './PromptAmountToSendBindingState';

export type PromptAmountToSendBindingProps =
  SmallHomeStackBindingProps<'PromptAmountToSend'>;

export default observer(function PromptAmountToSendBinding(
  props: PromptAmountToSendBindingProps,
) {
  const {route, navigation} = props;
  const {
    fee,
    currency,
    addressTo,
    isEditable,
    walletId,
    addressNetwork,
    addressCurrency,
    feeChangeDisabled,
    minFreeWithdrawAmount,
  } = route.params;
  const [error, setError] = useState<string | undefined>(undefined);
  const [amount, setAmount] = useState(route.params.amount);
  const root = useRoot();
  const strings = useStrings();
  const header = useHeaderHeight();

  const [state] = useState(
    () =>
      new PromptAmountToSendBindingState(
        root,
        new WalletLimitHelper(root),
        new SendAmountValidator(),
      ),
  );

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh(
              currency,
              walletId,
              addressNetwork,
              addressCurrency,
              addressTo,
              amount,
              minFreeWithdrawAmount,
            );
          });
        }
      }),
    [
      addressTo,
      minFreeWithdrawAmount,
      addressCurrency,
      addressNetwork,
      currency,
      getIsFocused,
      getIsReady,
      state,
      amount,
      walletId,
    ],
  );

  const navigateToCommission = useCallback(
    (amount_: DecimalString) => {
      navigation.navigate('PromptCommissionForSending', {
        addressCurrency,
        addressNetwork,
        amount: amount_,
        addressTo,
        currency,
        walletId,
        minFreeWithdrawAmount,
      });
    },
    [
      minFreeWithdrawAmount,
      addressCurrency,
      addressNetwork,
      addressTo,
      currency,
      navigation,
      walletId,
    ],
  );
  const amountErrorTexts = useSendAmountErrorTexts(state.min, state.minFee);
  const handleSubmit = useCallback(
    async (amount_: DecimalString | undefined) => {
      if (!state.crypto || !state.fees || isNil(state.max)) {
        return;
      }
      const error_ = state.validate(amount_);

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (amountErrorTexts[error_!]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setError(amountErrorTexts[error_!]);
        return;
      }

      navigation.setParams({amount: amount_});

      if (isEditable) {
        navigation.navigate('PromptConfirmationToSendCrypto', {
          addressCurrency,
          addressNetwork: state.addressNetwork,
          minFreeWithdrawAmount,
          feeChangeDisabled,
          fee,
          walletId,
          addressTo: addressTo,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          amount: amount_!,
          currency,
        });
        return;
      }

      if (state.fees.length === 1 && state.isFreeWithdrawalUnavailable) {
        navigation.navigate('PromptConfirmationToSendCrypto', {
          addressNetwork: state.addressNetwork,
          addressCurrency: addressCurrency,
          fee: state.fees[0].fee,
          minFreeWithdrawAmount,
          feeChangeDisabled: true,
          walletId,
          addressTo: addressTo,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          amount: amount_!,
          currency,
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        navigateToCommission(amount_!);
      }
    },
    [
      state,
      minFreeWithdrawAmount,
      amountErrorTexts,
      navigation,
      isEditable,
      addressCurrency,
      addressTo,
      feeChangeDisabled,
      fee,
      walletId,
      currency,
      navigateToCommission,
    ],
  );

  const handleAmountChange = useCallback((a: DecimalString | undefined) => {
    setAmount(a);
    setError(undefined);
  }, []);

  return (
    <SendAmountInputScreen
      isEditable={isEditable}
      max={state.max}
      maxRestrictedByWalletLimit={state.maxRestrictedByWalletLimit}
      error={error}
      maxError={
        state.isMinFeeMoreThanAvailableMax
          ? strings['sendCrypto.validation.networkFeeExceedsMax']
          : undefined
      }
      currency={currency}
      amount={amount}
      onAmountChange={handleAmountChange}
      stepCurrent={2}
      stepTotal={3}
      onSubmit={handleSubmit}
      keyboardAvoiding
      keyboardVerticalOffset={header}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
    />
  );
});
