import {makeObservable, observable, runInAction} from 'mobx';

import {success} from '../fp';
import type {JsonRpcClient} from '../JsonRpc';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {AccountDocumentType} from '../NCWalletServer/AccountsDocuments';
import type {AccountDocumentTypeManager} from './AccountDocumentTypeManager';

// eslint-disable-next-line import-x/prefer-default-export
export class AccountDocumentTypeManagerImpl
  implements AccountDocumentTypeManager
{
  @observable _documentTypes: AccountDocumentType[] | undefined;
  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {
    makeObservable(this);
  }
  getAll(): AccountDocumentType[] | undefined {
    return this._documentTypes;
  }

  async refresh() {
    const documentTypesRes = await this._getList();
    if (documentTypesRes.success) {
      runInAction(() => {
        this._documentTypes = documentTypesRes.right;
      });
    }
    return documentTypesRes;
  }

  private async _getList() {
    const res = await this._root.ncWalletJsonRpcClient.call(
      'accounts.documents.types.get.list',
      {},
    );

    return res.success ? success(res.right.items) : res;
  }
}
