import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonColor, ButtonVariant} from '@ncwallet-app/ui/';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {BodyTag, HeaderTag, HeaderTitleTag} from '../useCardStyles';
import BackButton from './BackButton';

export type RemoveDocumentProps = {
  onBack: () => void;
  onDelete: () => void;
};

export default observer(function RemoveDocument(props: RemoveDocumentProps) {
  const {onBack, onDelete} = props;
  const strings = useStrings();

  return (
    <>
      <HeaderTag>
        <BackButton onPress={onBack} />
        <HeaderTitleTag>
          {strings['deleteIdentityUploadedDocument.title']}
        </HeaderTitleTag>
      </HeaderTag>
      <BodyTag>
        <BodyActions>
          <Button
            title={strings['deleteIdentityUploadedDocument.action.confirm']}
            variant={ButtonVariant.PrimaryLight}
            color={ButtonColor.Secondary}
            onPress={onDelete}
          />
          <Button
            title={strings['deleteIdentityUploadedDocument.action.cancel']}
            variant={ButtonVariant.Danger}
            onPress={onBack}
          />
        </BodyActions>
      </BodyTag>
    </>
  );
});

const BodyActions = variance(View)(() => ({
  root: {
    marginTop: 'auto',
    gap: 20,
  },
}));
