import type {Disposer} from '@ncwallet-app/core';
import {batchDisposers} from '@ncwallet-app/core';
import type {ParamListBase, RouteProp} from '@react-navigation/native';
import {useRoute} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';
import {action, observable} from 'mobx';
import {useCallback, useLayoutEffect, useState} from 'react';

// eslint-disable-next-line import-x/no-anonymous-default-export
export default (navigation: StackNavigationProp<ParamListBase>) => {
  const route = useRoute<RouteProp<ParamListBase>>();
  const [box] = useState(() =>
    observable.box(true, {name: `${route.name} transition`}),
  );
  useLayoutEffect(() => {
    const onStart = action(() => {
      box.set(true);
    });
    const onEnd = action(() => {
      box.set(false);
    });
    return batchDisposers(
      navigation.addListener('transitionStart', onStart) as Disposer,
      navigation.addListener('transitionEnd', onEnd) as Disposer,
    );
  }, [box, navigation]);
  return useCallback(() => box.get(), [box]);
};
