import type {IpInfo} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import type {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {IpList} from './IpList';

export type IpListScreenProps = BaseSafeAreaProps & {
  type: IpType;
  ipInfos?: IpInfo[];
  isWarningShown?: boolean;
  onClearAllowedList?: () => void;
  onIpPress: (ipInfo: IpInfo) => void;
  onAdd: () => void;
};

export const IpListScreen = observer((props: IpListScreenProps) => {
  const {
    ipInfos,
    isWarningShown,
    onClearAllowedList,
    onIpPress,
    onAdd,
    ...rest
  } = props;
  return (
    <IpList
      onAdd={onAdd}
      ipInfos={ipInfos}
      onIpPress={onIpPress}
      isWarningShown={isWarningShown}
      onClearAllowedList={onClearAllowedList}
      {...rest}
    />
  );
});
