import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Linking, Platform} from 'react-native';

import {usePromptBiometricSettingsContainer} from '../../../CommonNavigationContainers/hooks/usePromptBiometricSettingsContainer';
import BiometricSettingsScreen from '../../../screens/UserSettingsScreens/BiometricSettingsScreen/BiometricSettingsScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

type PromptBiometricSettingsBindingProps =
  SmallHomeStackBindingProps<'PromptBiometricSettings'>;

export default observer(function PromptBiometricSettingsBinding(
  props: PromptBiometricSettingsBindingProps,
) {
  const {navigation} = props;
  const goToInfo = useCallback((url: string) => {
    void Linking.openURL(url);
  }, []);

  const containerProps = useMemo(
    () => ({
      checkBiometricsPin: () => {
        navigation.navigate('CheckBiometricsPin');
      },
      promptOtpToBiometrics: () => {
        navigation.navigate('PromptOtpToBiometrics');
      },
    }),
    [navigation],
  );

  const {
    hasSignInBiometrics,
    hasTwoFaBiometrics,
    toggleTwoFaBiometrics,
    toggleSignInBiometrics,
  } = usePromptBiometricSettingsContainer(containerProps);

  return (
    <BiometricSettingsScreen
      localKeysExist={true}
      goToInfo={goToInfo}
      isWeb={Platform.OS === 'web'}
      getIsActiveLogin={hasSignInBiometrics}
      getIsActiveTransaction={hasTwoFaBiometrics}
      onLoginToggle={toggleSignInBiometrics}
      onTransactionToggle={toggleTwoFaBiometrics}
    />
  );
});
