import type {Millisecond} from '../Time';
import type {Uri, Url} from '../units';
import type {ConfigurationValues} from './ConfigurationValues';

const prodValues: ConfigurationValues = {
  ncWalletSiteUrl: 'https://app.ncwallet.net' as Url,
  ncWalletRestApiUrl: 'https://api.ncwallet.net/' as Url,
  ncWalletRestApiTimeout: 10000 as Millisecond,
  ncWalletJsonRpcUrl: 'wss://api.ncwallet.net/api/v1/ws' as Url,
  ncWalletJsonRpcTimeout: 15000 as Millisecond,
  googleMobileOauthClientId:
    '198164349577-arhggpicqdg808gc2iu75trphumd7j5j.apps.googleusercontent.com',
  googleWebOauthClientId:
    '198164349577-0g1rgov0a0jc1bm1ir04feof9bvudo94.apps.googleusercontent.com',
  appleOauthRedirectUri: 'https://app.ncwallet.net/oauth' as Uri,
  appleOauthClientId: 'com.ncwallet.oauth',
  telegramBotId: '6995169174',
  telegramBotName: 'ncwallet_bot',
  miniAppUrl: 'https://tg.ncwallet.net',
  telegramOauthOrigin: 'https://app.ncwallet.net',
  googleRedirectUriForEmileLinking:
    'https://api.ncwallet.net/api/v1/google/oauth',
  appleRedirectUriForEmileLinking:
    'https://api.ncwallet.net/api/v1/apple/oauth',
};

export default prodValues;
