import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {SendSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type LgSendHeaderProps = {
  tabs: React.ReactNode;
  smallStyle?: boolean;
};

export default observer(({tabs, smallStyle}: LgSendHeaderProps) => {
  const theme = useTheme();
  const strings = useStrings();
  return (
    <Container smallStyle={smallStyle}>
      <SectionLogo>
        <SendSvg color={theme.palette.foreground} />
        <Title>{strings['screenTitle.sendCrypto']}</Title>
      </SectionLogo>
      {tabs}
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  smallStyle: {
    marginBottom: 0,
    width: 'auto',
  },
}));

const SectionLogo = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 22,
    marginLeft: 5,
    color: theme.palette.textMain,
  },
}));
