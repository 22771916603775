import {useStrings, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import {AffiliateTabKind} from '../UserSettingsScreens/AffiliateProgramScreen/AffiliateKind';
import AffiliateProgramAddress from '../UserSettingsScreens/AffiliateProgramScreen/AffiliateProgramAddress';
import ShareBlock from './ShareBlock/ShareBlock';

export type SharingCardProps = {
  onCopy: (data: string) => void;
  onShare: (data: string) => void;
  shareAvailable: boolean;
  firstLink: string;
  secondLink: string;
  telegramLink: string;
};

export default observer(function SharingCard(props: SharingCardProps) {
  const {onCopy, onShare, shareAvailable, firstLink, secondLink, telegramLink} =
    props;
  const strings = useStrings();
  return (
    <LgProfileCardContainer horizontalOffset>
      <HeaderTag>
        <HeaderTitleTag>{strings['affiliateScreen.sharing']}</HeaderTitleTag>
      </HeaderTag>
      <Cell topPosition>
        <Label>{strings['affiliateScreen.tell']}</Label>
        <AffiliateProgramAddress
          appKind={AffiliateTabKind.Landing}
          onShare={onShare}
          onCopy={onCopy}
          shareShown={shareAvailable}
          info={firstLink}
        />
        <ShareBlock url={firstLink} />
      </Cell>
      <Cell topPosition>
        <Label>{strings['affiliateScreen.invite']}</Label>
        <AffiliateProgramAddress
          appKind={AffiliateTabKind.App}
          onShare={onShare}
          onCopy={onCopy}
          shareShown={shareAvailable}
          info={secondLink}
        />
        <ShareBlock url={secondLink} />
      </Cell>
      <Cell>
        <Label>{strings['affiliateScreen.tell.telegram']}</Label>
        <AffiliateProgramAddress
          appKind={AffiliateTabKind.Telegram}
          onShare={onShare}
          onCopy={onCopy}
          shareShown={shareAvailable}
          info={telegramLink}
        />
        <ShareBlock url={telegramLink} />
      </Cell>
    </LgProfileCardContainer>
  );
});

const Cell = variance(View)(theme => ({
  root: {
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  topPosition: {
    borderBottomColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
  },
}));

const Label = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    color: theme.palette.textMain,
  },
}));
