import {variance} from '@ncwallet-app/core';
import {BaseSkeleton} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {LimitCard} from './LimitCard';
import {LimitCardBody} from './LimitCardBody';
import {LimitCardHeader, LimitCardHeaderCurrency} from './LimitCardHeader';

export default observer(function LimitSkeleton() {
  return (
    <LimitCard>
      <LimitCardHeader>
        <BaseSkeletonTitle width={90} height={20} />
        <LimitCardHeaderCurrency>
          <BaseSkeletonIcon width={20} height={20} isRounded />
          <BaseSkeleton width={30} height={20} />
        </LimitCardHeaderCurrency>
      </LimitCardHeader>
      <LimitCardBody>
        <BaseSkeletonTabRow height={35} />
        <BaseSkeleton width={200} height={16} />
        <BaseSkeletonInput height={50} />
      </LimitCardBody>
    </LimitCard>
  );
});

const BaseSkeletonTitle = variance(BaseSkeleton)(() => ({
  root: {
    marginRight: 'auto',
  },
}));

const BaseSkeletonIcon = variance(BaseSkeleton)(() => ({
  root: {
    marginRight: 10,
  },
}));

const BaseSkeletonInput = variance(BaseSkeleton)(() => ({
  root: {
    marginTop: 10,
  },
}));

const BaseSkeletonTabRow = variance(BaseSkeleton)(() => ({
  root: {
    marginBottom: 35,
  },
}));
