import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import LargeListWalletsScreen from '../../../../../screens/LargeListWalletsScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type ListWalletsBindingProps = LargeSwitchBindingProps<'ListWallets'>;

export default observer(function ListWalletsBinding(
  props: ListWalletsBindingProps,
) {
  const {navigation} = props;

  const onReceive = useCallback(() => {
    navigation.navigate('PromptCryptoToReceive');
  }, [navigation]);

  const onExchange = useCallback(() => {
    navigation.navigate('PromptSourceCryptoToExchange', {});
  }, [navigation]);

  const onSend = useCallback(() => {
    navigation.navigate('PromptCryptoToSend');
  }, [navigation]);

  return (
    <LargeListWalletsScreen
      onReceive={onReceive}
      onExchange={onExchange}
      onSend={onSend}
    />
  );
});
