import {takeRight} from 'lodash';
import {action} from 'mobx';

export default class EvictingQueue<T> {
  private _items: Array<T> = [];

  constructor(
    private _maxLength: number,
    initial: Array<T> = [],
  ) {
    this._items = takeRight(initial, _maxLength);
  }

  @action.bound
  push(item: T) {
    const prevItems =
      this._maxLength === 0 ? [] : takeRight(this._items, this._maxLength - 1);
    this._items = [...prevItems, item];
  }

  getContent() {
    return this._items;
  }
}
