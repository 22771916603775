import type {CurrencyDescription, DecimalString} from '@ncwallet-app/core';
import {keepMeaningfulDigitsInFiat, variance} from '@ncwallet-app/core';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text} from 'react-native';

export type FiatValueTextProps = {
  getFiatCurrency: () => CurrencyDescription | undefined;
  getFiatValue: () => DecimalString | undefined;
};

export default observer(function FiatValueText(props: FiatValueTextProps) {
  const currency = props.getFiatCurrency();
  const value = props.getFiatValue();
  if (isNil(value)) {
    return null;
  }

  return (
    <FiatValue>
      {keepMeaningfulDigitsInFiat(value)} {currency?.code}
    </FiatValue>
  );
});

const FiatValue = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional2,
    fontSize: 12,
    marginTop: 5,
  },
}));
