import {urlLinks} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {getOSKind, OSKind} from '@ncwallet-app/core/src/util/getOSKind';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {autorun} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';
import {Linking} from 'react-native';

import {
  useNavigationGetIsFocused,
  useSetTwoFaGenerateSecret,
} from '../../../Navigation/hooks';
import {isChromeExtension} from '../../../Navigation/hooks/isChromeExtension';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {TwoFaSecretScreen} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ShowGeneratedSecretBindingProps =
  SmallHomeStackBindingProps<'ShowTwoFaGenerateSecret'>;

export default observer(function ShowGeneratedSecretBinding(
  props: ShowGeneratedSecretBindingProps,
) {
  const {navigation, route} = props;
  const {onTwoFaEnabled, code, providerKind} = route.params ?? {};

  const isExtension = isChromeExtension();
  const setCode = useCallback(
    (c: string) => {
      if (isExtension) {
        navigation.setParams({...route.params, code: c});
      }
    },
    [navigation, route.params, isExtension],
  );

  const {
    onCopySecret,
    secretBox,
    getError,
    getHasAuthenticator,
    getSecret,
    getOtpAuthUri,
    onOpenAuthenticator,
    onDirectlyOpenAuthenticator,
    onInstallAuthenticator,
    onOpenAppleKeyManager,
  } = useSetTwoFaGenerateSecret(setCode, code);

  const os = getOSKind();

  const onEnterCode = useCallback(() => {
    const secret = secretBox.get();
    if (secret === undefined) {
      return;
    }

    navigation.navigate('PromptOtpToEnableTwoFa', {
      secret,
      providerKind,
      onTwoFaEnabled,
    });
  }, [navigation, secretBox, providerKind, onTwoFaEnabled]);

  const handleFaqHelpPress = useCallback(async () => {
    const isAppleDevice = os === OSKind.IOs || os === OSKind.MacOS;
    const faqUrl = isAppleDevice
      ? urlLinks.twoFaHelpIosLink
      : urlLinks.twoFaHelpAndroidLink;
    await Linking.openURL(faqUrl);
  }, [os]);

  const handleAuthenticatorOpen = useCallback(() => {
    switch (providerKind) {
      case TwoFaProviderKind.Akm:
        return onOpenAppleKeyManager();
      case TwoFaProviderKind.Ga:
        return onOpenAuthenticator();
    }
  }, [providerKind, onOpenAppleKeyManager, onOpenAuthenticator]);

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          if (!providerKind) {
            navigation.replace('TwoFaWelcome');
            return;
          }
        }
      }),
    [getIsFocused, getIsReady, navigation, providerKind],
  );

  return (
    <TwoFaSecretScreen
      providerKind={providerKind}
      getSecret={getSecret}
      getOtpAuthUri={getOtpAuthUri}
      getError={getError}
      getHasAuthenticator={getHasAuthenticator}
      onOpenAuthenticator={handleAuthenticatorOpen}
      onDirectlyOpenAuthenticator={onDirectlyOpenAuthenticator}
      onInstallAuthenticator={onInstallAuthenticator}
      onCopySecret={onCopySecret}
      onEnterCode={onEnterCode}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      onFaqHelpPress={handleFaqHelpPress}
    />
  );
});
