import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';

import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {NotificationsScreen} from '../../../screens/NotificationsScreen';
import {useNotifications} from '../../../screens/NotificationsScreen/useNotifications';

export default observer(function ListNotificationsBinding() {
  const {refresh, getIsRefreshing, getNotificationItems, onEndReached} =
    useNotifications();

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, refresh],
  );

  return (
    <NotificationsScreen
      getIsRefreshing={getIsRefreshing}
      getNotificationItems={getNotificationItems}
      onRefresh={refresh}
      onEndReached={onEndReached}
    />
  );
});
