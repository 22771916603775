import {useStrings, variance} from '@ncwallet-app/core/src';
import {SimpleButton} from '@ncwallet-app/ui/src';
import React, {useMemo} from 'react';
import {View} from 'react-native';

import {AppleIcon, GoogleIcon} from './icons';
import type {AuthVariant, VariantItem} from './types';
import {OAuthVariant} from './types';

export type CreateWalletVariantListProps = {
  onItemPress: (variant: AuthVariant) => void;
};

export const CreateWalletVariantList = ({
  onItemPress,
}: CreateWalletVariantListProps) => {
  const strings = useStrings();
  const listData = useMemo((): VariantItem[] => {
    return [
      {
        title: strings['welcomeScreen.signInVia.google'],
        Icon: GoogleIcon,
        variant: OAuthVariant.Google,
      },
      {
        title: strings['welcomeScreen.signInVia.apple'],
        Icon: AppleIcon,
        variant: OAuthVariant.Apple,
      },
    ];
  }, [strings]);

  return (
    <Container>
      {listData.map(item => (
        <ItemRoot key={item.variant}>
          <SimpleButton
            title={item.title}
            Icon={item.Icon}
            onPress={() => {
              onItemPress(item.variant);
            }}
          />
        </ItemRoot>
      ))}
    </Container>
  );
};

const Container = variance(View)(() => ({
  root: {
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
}));

const ItemRoot = variance(View)(() => ({
  root: {
    marginBottom: 20,
  },
}));
