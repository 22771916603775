import {FULFILLED, useRoot} from '@ncwallet-app/core';
import {useEffect, useState} from 'react';

import {SmProfileSettingsBindingState} from './SmProfileSettingsBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const useSmProfileSettingsBindingState = () => {
  const root = useRoot();
  const {accountStore} = root;
  const [state] = useState(() => new SmProfileSettingsBindingState(root));

  useEffect(() => {
    // after link to telegram data on the Profile screen should refresh
    if (accountStore.state?.status === FULFILLED) {
      void state.refresh();
    }
  }, [accountStore.state?.status, state]);
  return state;
};
