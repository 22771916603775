import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useSessionHistoryBindingState} from '../../../Navigation/HomeStack/SessionHistoryBinding/useSessionHistoryBindingState';
import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import {SessionsHistoryScreen} from '../../../screens/SessionsHistoryScreen';

export default observer(function ListSessionHistoryBinding() {
  const state = useSessionHistoryBindingState();
  const getSessions = useCallback(() => {
    return state.sessionHistory;
  }, [state.sessionHistory]);

  const onEndReachedCallback = useCallback(async () => {
    return state.onEndReached();
  }, [state]);
  return (
    <SessionsHistoryScreen
      history={getSessions()}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      onEndReached={onEndReachedCallback}
    />
  );
});
