/// <reference lib="dom" />
import type {TypedArray} from 'type-fest';

import type {GlobalError} from '../Error';
import {UNKNOWN_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {Crypto} from './Crypto';

export default class CryptoImpl implements Crypto {
  constructor(
    private readonly _root: {readonly errorRepository: ErrorRepository},
  ) {}

  async fillWithRandomBytes(
    input: ArrayBuffer | TypedArray,
  ): Promise<Either<void, GlobalError>> {
    try {
      const view = input instanceof ArrayBuffer ? new Uint8Array(input) : input;
      window.crypto.getRandomValues(view);
    } catch (raw) {
      return error(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          raw,
          description: 'Failed to get random values',
        }),
      );
    }
    return success();
  }
}
