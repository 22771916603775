import type {SmallHomeStackParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallHomeStackParamList';
import {SafeAreaInset} from '@ncwallet-app/ui';
import type {NavigationProp} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import type {PromptSourceCryptoToExchangeContainerProps} from '../../../../../CommonNavigationContainers/hooks/usePromptSourceCryptoToExchangeContainer';
import {usePromptSourceCryptoToExchangeContainer} from '../../../../../CommonNavigationContainers/hooks/usePromptSourceCryptoToExchangeContainer';
import {useNavigatePurgingDuplicates} from '../../../../../CommonNavigationScheme';
import {useTabBarLayout} from '../../../../../components';
import {SelectionForExchangeScreen} from '../../../../../screens/SelectionForExchangeScreen';
import type {SmallBottomTabBindingProps} from '../SmallBottomTabBindingProps';

export type PromptSourceCryptoToExchangeBindingProps =
  SmallBottomTabBindingProps<'PromptSourceCryptoToExchange'>;

export default observer(function PromptSourceCryptoToExchangeBinding(
  props: PromptSourceCryptoToExchangeBindingProps,
) {
  const {navigation, route} = props;
  const navigatePurgingDuplicates = useNavigatePurgingDuplicates(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    navigation.getParent() as NavigationProp<SmallHomeStackParamList>,
  );
  const containerProps = useMemo<PromptSourceCryptoToExchangeContainerProps>(
    () => ({
      params: route.params,
      goBack: () => {
        navigation.goBack();
      },
      setParams: params => {
        navigation.setParams(params);
      },
      promptExchangeReceipt: params => {
        navigatePurgingDuplicates('PromptExchangeReceipt', params);
      },
      promptCryptoToReceive: params => {
        navigation.navigate('PromptCryptoToReceive', params);
      },
      promptNewWallet: params => {
        navigation.navigate('PromptNewWallet', params);
      },
    }),
    [navigatePurgingDuplicates, navigation, route.params],
  );

  const screenProps = usePromptSourceCryptoToExchangeContainer(containerProps);
  const layout = useTabBarLayout();

  return (
    <SelectionForExchangeScreen
      {...screenProps}
      isLg={false}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: layout.height}}
    />
  );
});
