import {useStrings, variance} from '@ncwallet-app/core';
import type {Ip} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpInfo';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import {Button, ButtonVariant} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {
  BodyTag,
  ClickableIcon,
  ClickableTag,
  DeleteIpActionContainer,
  DeleteIpText,
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../../ProfileSettingsScreen/LgProfileCards/useCardStyles';

export type SessionDeleteIpProps = {
  type: IpType;
  ip: Ip;
  onBack: () => void;
  onConfirm: () => void;
};

export default observer(function SessionDeleteIp(props: SessionDeleteIpProps) {
  const {type, ip, onBack, onConfirm} = props;
  const strings = useStrings();
  return (
    <LgProfileCardContainer>
      <HeaderTag>
        <ClickableTag onPress={onBack}>
          <ClickableIcon />
          <HeaderTitleTag>
            {type === IpType.Allowed
              ? strings['deleteAllowedIpModal.title'].replace('{ipName}', ip)
              : strings['deleteBlockedIpModal.title'].replace('{ipName}', ip)}
          </HeaderTitleTag>
        </ClickableTag>
      </HeaderTag>
      <BodyTag>
        <DeleteIpText>
          {type === IpType.Allowed
            ? strings['deleteAllowedIpModal.text'].replace('{ipName}', ip)
            : strings['deleteBlockedIpModal.text'].replace('{ipName}', ip)}
        </DeleteIpText>
        <DeleteIpActionContainer>
          <Button
            variant={ButtonVariant.PrimaryLight}
            title={strings['deleteIpModal.confirm']}
            onPress={onConfirm}
            testID="rem-submit-btn"
          />
          <SizedBox />
          <Button
            variant={ButtonVariant.Danger}
            title={strings['deleteIpModal.cancel']}
            onPress={onBack}
            testID="rem-cancel-btn"
          />
        </DeleteIpActionContainer>
      </BodyTag>
    </LgProfileCardContainer>
  );
});

const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));
