import {NETWORK_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {EventLoopHelper} from '../EventLoop';
import {RouterHelperImpl} from '../structure';
import type {Millisecond} from '../Time';
import type {Connection} from './Connection';
import {ConnectionStatus} from './Connection';
import IntervalRetryStrategyImpl from './IntervalRetryStrategyImpl';
import type {AttemptResult} from './RetryStrategy';
import {ATTEMPT_RESULT_DONE, ATTEMPT_RESULT_TRY_AGAIN} from './RetryStrategy';

export default class IntervalRetryStrategyFactory {
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly eventLoopHelper: EventLoopHelper;
      readonly connection: Connection;
    },
  ) {}

  create() {
    return new IntervalRetryStrategyImpl(
      this._root,
      {
        attempt: async () => {
          const connect_ = await this._root.connection.reconnect();
          if (!connect_.success && connect_.left.kind === NETWORK_ERROR) {
            return ATTEMPT_RESULT_TRY_AGAIN;
          }
          const helper = new RouterHelperImpl(
            this._root,
            this._root.connection.status,
          );
          const whenOpen = helper.when(ConnectionStatus.Open);
          const whenClose = helper.when(ConnectionStatus.Closed);
          const result = await Promise.race<AttemptResult>([
            whenOpen.then(() => ATTEMPT_RESULT_DONE),
            whenClose.then(_ => {
              if (_.success) {
                return ATTEMPT_RESULT_TRY_AGAIN;
              }
              return ATTEMPT_RESULT_DONE;
            }),
          ]);
          whenOpen.cancel();
          whenClose.cancel();
          return result;
        },
      },
      10000 as Millisecond,
      1000 as Millisecond,
      3,
    );
  }
}
