import {useStrings} from '@ncwallet-app/core';
import type {CountryNameData} from '@ncwallet-app/core/src/Localization/constant';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {CountryList} from '../../../PromptToSelectVerificationCountryScreen/CountryList';
import CountryListSearch from '../../../PromptToSelectVerificationCountryScreen/CountryList/CountryListSearch';
import {BodyTag, HeaderTag, HeaderTitleTag, StepText} from '../useCardStyles';
import BackButton from './BackButton';

export type CountriesProps = {
  countries: CountryNameData[];
  searchQuery: string;
  resetSearch: () => void;
  onBack: () => void;
  onSearch: (searchQuery: string) => void;
  onCountrySelect: (country: CountryNameData) => void;
};

export default observer(function Countries(props: CountriesProps) {
  const {
    countries,
    searchQuery,
    resetSearch,
    onSearch,
    onBack,
    onCountrySelect,
  } = props;
  const strings = useStrings();

  const handleBackPress = useCallback(() => {
    resetSearch();
    onBack();
  }, [resetSearch, onBack]);

  return (
    <>
      <HeaderTag>
        <BackButton onPress={handleBackPress} />
        <HeaderTitleTag>
          {strings['screenTitle.identityCountry']}
        </HeaderTitleTag>
        <StepText>
          {strings['profileSettingsScreen.verificationStep']} 2/3
        </StepText>
      </HeaderTag>
      <BodyTag>
        <CountryListSearch onSearch={onSearch} />
        <CountryList
          data={countries}
          searchQuery={searchQuery}
          onCountrySelect={onCountrySelect}
        />
      </BodyTag>
    </>
  );
});
