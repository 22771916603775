import {useStrings, variance} from '@ncwallet-app/core';
import {useIsExchangeAllowed} from '@ncwallet-app/navigation/src/Navigation/hooks/useIsExchangeAllowed';
import {
  ExchangeTabSvg,
  ReceiveTabSvg,
  SendTabSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import exchangeBackground from './banner-exchange.png';
import receiveBackground from './banner-receive.png';
import WalletBanner, {WalletBannerTheme} from './WalletBanner';

export type WalletsScreenLargeProps = {
  onReceive: () => void;
  onExchange: () => void;
  onSend: () => void;
};

export default observer(function WalletsScreenLarge({
  onReceive,
  onExchange,
  onSend,
}: WalletsScreenLargeProps) {
  const strings = useStrings();
  const {isExchangeAllowed} = useIsExchangeAllowed();
  return (
    <Container>
      <WalletBanner
        title={strings['bannerReceive.title']}
        text={[
          strings['bannerRecaive.firstParagraph'],
          strings['bannerReceive.secondParagraph'],
        ]}
        theme={WalletBannerTheme.Orange}
        offsetBottom={true}
        backgroundImage={receiveBackground}
        buttonIcon={ReceiveTabSvg}
        buttonText={strings['bannerReceive.button']}
        onButtonPress={onReceive}
      />
      {isExchangeAllowed ? (
        <WalletBanner
          title={strings['bannerExchange.title']}
          text={[
            strings['bannerExchange.firstParagraph'],
            strings['bannerExchange.secondParagraph'],
          ]}
          theme={WalletBannerTheme.Blue}
          backgroundImage={exchangeBackground}
          buttonIcon={ExchangeTabSvg}
          buttonText={strings['bannerExchange.button']}
          onButtonPress={onExchange}
        />
      ) : (
        <WalletBanner
          title={strings['bannerSend.title']}
          text={[strings['bannerSend.firstParagraph']]}
          theme={WalletBannerTheme.Blue}
          backgroundImage={exchangeBackground}
          buttonIcon={SendTabSvg}
          buttonText={strings['bannerSend.button']}
          onButtonPress={onSend}
        />
      )}
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));
