import {useIsDimensions} from '@ncwallet-app/ui/src';
import {useMemo} from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import {EXTRA_BOTTOM_OFFSET} from '../../screens/constants';

export default function useNavigationSafeAreaCardStyles() {
  const safeArea = useSafeAreaInsets();
  const isLg = useIsDimensions('lg');
  return useMemo(
    () => ({
      paddingBottom: isLg
        ? safeArea.bottom
        : safeArea.bottom + EXTRA_BOTTOM_OFFSET,
    }),
    [isLg, safeArea.bottom],
  );
}
