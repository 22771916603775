import {useStrings} from '@ncwallet-app/core';
import {useMemo} from 'react';

import {AffiliateTabKind} from './AffiliateKind';

// eslint-disable-next-line import-x/prefer-default-export
export const useExtraAffiliateShareString = (appKind: AffiliateTabKind) => {
  const strings = useStrings();

  return useMemo((): string | undefined => {
    switch (appKind) {
      case AffiliateTabKind.Landing:
        return strings['NCW.affiliate.shareLanding'];
      case AffiliateTabKind.App:
        return strings['NCW.affiliate.shareApp'];
      case AffiliateTabKind.Telegram:
        return strings['NCW.affiliate.shareOnTelegram'];
      default:
        return undefined;
    }
  }, [appKind, strings]);
};
