import type {Credentials} from '../Credentials';
import type {GlobalError} from '../Error';
import type {AccountId} from '../NCWalletServer';
import type {BusSource, RouterSource} from '../structure';

export interface AccountIdStore {
  readonly events: RouterSource<AccountEventsMap>;
  readonly errors: BusSource<(_: GlobalError) => unknown>;
}

export const INITIALIZE = Symbol();
export const LOG_IN = Symbol();
export const SWITCH = Symbol();
export const LOG_OUT = Symbol();

export type AccountEventsMap = {
  [INITIALIZE]: (current?: AccountIdentity) => unknown;
  [LOG_IN]: (next: AccountIdentity) => unknown;
  [SWITCH]: (next: AccountIdentity, previous: AccountIdentity) => unknown;
  [LOG_OUT]: (previous: AccountIdentity) => unknown;
};

export type AccountIdentity = {
  accountId: AccountId;
  credentials: Credentials;
};
