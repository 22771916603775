import type {ISODateString, OtpCode, WalletLimitId} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {LimitRemovalAction} from '@ncwallet-app/core/src/CommonNavigationScheme';
import dayjs from 'dayjs';
import {useCallback, useRef} from 'react';

import {useBaseErrorDescription} from '..';

// eslint-disable-next-line import-x/prefer-default-export
export const useDeleteLimitWithOtp = (onSuccess: () => void) => {
  const root = useRoot();
  const isSubmittedRef = useRef(false);
  const [getErrorText, setErrorText, resetErrorText] =
    useBaseErrorDescription();

  const deleteLimitWithOtp = useCallback(
    async (
      limitId: WalletLimitId,
      code: string,
      action: LimitRemovalAction,
    ) => {
      if (isSubmittedRef.current) {
        return;
      }

      isSubmittedRef.current = true;

      const res = await root.ncWalletJsonRpcClient.call(
        action === LimitRemovalAction.CancelDelete
          ? 'wallets.limits.delete.cancel'
          : 'wallets.limits.delete',
        {
          limit_id: limitId,
          code_2fa: code as OtpCode,
          utc_2fa: dayjs().utc().toISOString() as ISODateString,
        },
      );
      isSubmittedRef.current = false;

      if (res.success) {
        onSuccess();
      } else {
        setErrorText(res.left);
      }
    },
    [onSuccess, root.ncWalletJsonRpcClient, setErrorText],
  );

  return {
    getErrorText,
    resetErrorText,
    deleteLimitWithOtp,
  };
};
