import type {OtpCode} from '@ncwallet-app/core';
import {useRoot, useStrings} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {CommonActions} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef} from 'react';

import {useBaseErrorDescription} from '../../../CommonNavigationContainers/hooks';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {useSendTelegramOtp} from '../../../Navigation/hooks/useSendTelegramOtp';
import {
  CARD,
  SubmitOtpScreen,
} from '../../../screens/UserSettingsScreens/SecurityTwoFaAuthScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {hasRouteInHistory} from '../util/hasRouteInHistory';

export type PromptOtpToChangeTwoFaSettingsBindingProps =
  SmallHomeStackBindingProps<'PromptOtpToChangeTwoFaSettings'>;

export default observer(function PromptOtpToChangeTwoFaSettingsBinding(
  props: PromptOtpToChangeTwoFaSettingsBindingProps,
) {
  const {route, navigation} = props;
  const root = useRoot();
  const isSubmittingRef = useRef(false);
  const [getError, setError, resetError] = useBaseErrorDescription();
  const strings = useStrings();
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();
  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();

  useEffect(() => {
    if (getIsFocused()) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!route.params) {
        navigation.replace('ShowTwoFaSettings');
        return;
      }
    }
  }, [getIsFocused, getIsReady, navigation, route.params]);

  const handleSubmit = useCallback(
    async (code: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!route.params) {
        return;
      }
      if (isSubmittingRef.current) {
        return;
      }
      isSubmittingRef.current = true;
      const res = await root.twoFaSettingsState.update(
        code as OtpCode,
        route.params.excluded,
        route.params.included,
      );
      isSubmittingRef.current = false;
      if (!res.success) {
        setError(res.left);
      } else {
        if (hasRouteInHistory(navigation.getState(), 'ShowTwoFaSettings')) {
          navigation.navigate('ShowTwoFaSettings');
        } else {
          navigation.dispatch(
            CommonActions.reset({
              index: 2,
              routes: [
                {name: 'Root'},
                {name: 'ListSecuritySettings'},
                {name: 'ShowTwoFaSettings'},
              ],
            }),
          );
        }

        root.flashMessage.showMessage({
          title: strings['promptOtpToChange2FASettings.successMessage'],
          variant: 'success',
        });
      }
    },

    [root, route.params, setError, navigation, strings],
  );

  const handleTwoFaResendCode = useCallback(async () => {
    try {
      await sendOtp(null, {forceChannel: true});
    } catch {
      /* empty */
    }
  }, [sendOtp]);

  return (
    <SubmitOtpScreen
      layout={CARD}
      twoFaProvider={root.twoFaSettingsState.currentTwoFaProvider}
      onTwoFaResendCode={handleTwoFaResendCode}
      resetError={resetError}
      getError={getError}
      titleText={strings['promptOtpToChange2FASettings.title']}
      submitText={strings['promptOtpToChange2FASettings.submit']}
      resendCodeInterval={interval}
      resetInterval={resetInterval}
      onSubmit={handleSubmit}
    />
  );
});
