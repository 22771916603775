import type {DecimalString} from '@ncwallet-app/core';
import type {Localization} from '@ncwallet-app/core/src/Localization';
import {BigNumber} from 'bignumber.js';

import {ValueKind} from './ExchangeFormBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export class ExchangeFormClientSideValidator {
  constructor(
    private readonly _root: {
      readonly localization: Localization;
    },
  ) {}

  validateOnChange(form: {
    valueFrom: DecimalString | undefined;
    valueTo: DecimalString | undefined;
    maxValueFrom: DecimalString | undefined;
    maxValueTo: DecimalString | undefined;
    _lastChangedByUserValue: ValueKind;
  }) {
    const {localization} = this._root;
    if (
      form._lastChangedByUserValue === ValueKind.From &&
      form.valueFrom &&
      form.maxValueFrom &&
      BigNumber(form.valueFrom).isGreaterThan(form.maxValueFrom)
    ) {
      return localization.getTranslation(
        'exchangeCrypto.validation.valueFromMoreThanMax',
      );
    } else if (
      form._lastChangedByUserValue === ValueKind.To &&
      form.valueTo &&
      form.maxValueTo &&
      BigNumber(form.valueTo).isGreaterThan(form.maxValueTo)
    ) {
      return localization.getTranslation(
        'exchangeCrypto.validation.valueToMoreThanMax',
      );
    }
  }

  hasError(form: {
    valueFrom: DecimalString | undefined;
    valueTo: DecimalString | undefined;
    maxValueFrom: DecimalString | undefined;
    maxValueTo: DecimalString | undefined;
    _lastChangedByUserValue: ValueKind;
  }) {
    return !!this.validateAfterSubmit(form);
  }

  // ошибка valueShouldBeMoreThanZero спрятана до сабмита, чтобы не мешать пользователю вводить данные
  validateAfterSubmit(form: {
    valueFrom: DecimalString | undefined;
    valueTo: DecimalString | undefined;
    maxValueFrom: DecimalString | undefined;
    maxValueTo: DecimalString | undefined;
    _lastChangedByUserValue: ValueKind;
  }) {
    const {localization} = this._root;
    const onChangeError = this.validateOnChange(form);
    if (onChangeError) {
      return onChangeError;
    }

    const lastChangedValue =
      form._lastChangedByUserValue === ValueKind.To
        ? form.valueTo
        : form.valueFrom;

    if (!lastChangedValue) {
      return localization.getTranslation(
        'exchangeCrypto.validation.valueShouldBeMoreThanZero',
      );
    }
  }
}
