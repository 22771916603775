import type {ReactNode} from 'react';
import React, {memo} from 'react';
import {
  TouchableOpacity as RNTouchableOpacity,
  StyleSheet,
  View,
} from 'react-native';

import splitViewStyle from '../../organisms/splitViewStyle';
import type {TouchableOpacityProps} from './TouchableOpacityProps';

function TouchableOpacity(props: TouchableOpacityProps): ReactNode {
  const {
    nativeID,
    id,
    style,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldActivateOnStart,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    disallowInterruption,
    containerStyle,
    ...rest
  } = props;
  if (containerStyle) {
    const {inner, outer, other} = splitViewStyle(
      StyleSheet.flatten(style) ?? {},
    );
    return (
      <View style={[containerStyle, outer, other]}>
        <RNTouchableOpacity
          id={id ?? nativeID}
          style={[StyleSheet.absoluteFill, inner, other]}
          {...rest}
        />
      </View>
    );
  }
  return <RNTouchableOpacity id={id ?? nativeID} style={style} {...rest} />;
}

export default memo(TouchableOpacity);
