import type {RateHistoryPeriod, WalletId} from '@ncwallet-app/core';
import {FULFILLED, useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, reaction, runInAction} from 'mobx';
import {useCallback, useEffect, useState} from 'react';

import {useNavigationGetIsFocused} from '../../hooks';
import {CurrencyBindingStateService} from './CurrencyBindingStateService';

// eslint-disable-next-line import-x/prefer-default-export
export const useCurrencyBindingState = (
  walletId: WalletId,
  period: RateHistoryPeriod,
) => {
  const root = useRoot();
  const [state] = useState(() => new CurrencyBindingStateService(root));
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  const refresh = useCallback(async () => {
    if (root.accountStore.state?.status === FULFILLED) {
      const {base_fiat} = root.accountStore.state.result;
      runInAction(() => {
        void state.refreshWalletsAndCurrencies(walletId, base_fiat);
      });
    }
  }, [root.accountStore, state, walletId]);

  useEffect(() => {
    state.subscribe();
  }, [state]);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void refresh();
          });
        }
      }),
    [getIsFocused, root.accountStore, state, getIsReady, walletId, refresh],
  );

  useEffect(
    () =>
      reaction(
        () => state.isTimeoutError,
        shouldFire => {
          if (shouldFire) {
            root.rpcTimeoutErrorVisibility.registerAction(async () => {
              await root.walletsState.refresh();
              await refresh();
            });
          }
        },
      ),
    [state, root.rpcTimeoutErrorVisibility, root.walletsState, refresh],
  );

  useEffect(() => {
    state.changeGraphPeriod(period);
  }, [state, period]);

  return state;
};
