import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {
  PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE,
  PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE,
  PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {SecuritySettingsCard} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {autorun, runInAction} from 'mobx';
import {useCallback, useEffect} from 'react';

import type {LargeSwitchBindingProps} from '../../../../LargeNavigationRoot/LargeHomeStack/bindings/LargeSwitch/LargeSwitchBindingProps';
import type {TwoFaSettingsCardProps} from '../../../../screens/LgSecuritySettingsScreen/TwoFaSettingsCard';
import {useNavigationGetIsFocused} from '../../../hooks';

// eslint-disable-next-line import-x/prefer-default-export
export const useTwoFaSettingsState = (
  props: LargeSwitchBindingProps<'ListSecuritySettings'>,
  settingsShown: boolean,
): TwoFaSettingsCardProps => {
  const navigation = props.navigation;
  const root = useRoot();
  const {twoFaSettingsState} = root;
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused() && settingsShown) {
          runInAction(() => {
            void twoFaSettingsState.refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, twoFaSettingsState, settingsShown],
  );
  const onDisableTwoFaPress = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.TwoFa,
      providerKind: twoFaSettingsState.currentTwoFaProvider,
      focusedTwoFa: {kind: PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE},
    });
  }, [navigation, twoFaSettingsState.currentTwoFaProvider]);

  const onSubmitPress = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.TwoFa,
      providerKind: twoFaSettingsState.currentTwoFaProvider,
      focusedTwoFa: {
        kind: PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE,
        params: {
          included: twoFaSettingsState.getIncluded(),
          excluded: twoFaSettingsState.getExcluded(),
        },
      },
    });
  }, [navigation, twoFaSettingsState]);

  const handleTwoFaSelect = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.TwoFa,
      focusedTwoFa: {kind: PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE},
    });
  }, [navigation]);

  return {
    currentTwoFaProvider: undefined,
    selectedTwoFaProvider: root.twoFaSettingsState.currentTwoFaProvider,
    isEdited: twoFaSettingsState.isEdited,
    actions: twoFaSettingsState.actions,
    onActionPress: twoFaSettingsState.switchAction,
    onReset: twoFaSettingsState.reset,
    onTwoFaSelect: handleTwoFaSelect,
    onSubmitPress,
    onDisableTwoFaPress,
  };
};
