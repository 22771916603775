import {Slider} from '@miblanchard/react-native-slider';
import {useStyles, useTheme, variance} from '@ncwallet-app/core';
import {isArray} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Text, View} from 'react-native';

export type MinerFeeSliderProps = {
  sliderValue: number;
  onSliderValueChange: (sliderValue: string) => void;
  fees?: string[];
  disabled?: boolean;
};

export default observer(function MinerFeeSlider(props: MinerFeeSliderProps) {
  const {onSliderValueChange} = props;
  const sliderStyles = useSliderStyles();
  const theme = useTheme();
  const handleSliderValueChange = useCallback(
    (value: number | number[] | string[] | string) => {
      const index = isArray(value) ? Number(value[0]) : Number(value);
      onSliderValueChange(props.fees?.[index] || '0');
    },
    [onSliderValueChange, props.fees],
  );

  return (
    <Container>
      <View>
        <Slider
          value={props.sliderValue}
          animateTransitions
          disabled={props.disabled}
          minimumTrackTintColor={
            props.disabled ? theme.palette.uiSecondary : theme.palette.primary
          }
          onValueChange={handleSliderValueChange}
          thumbStyle={{
            ...sliderStyles.thumb,
            backgroundColor: props.disabled
              ? theme.palette.uiSecondary
              : theme.palette.primary,
          }}
          trackStyle={{
            ...sliderStyles.track,
            backgroundColor: props.disabled
              ? theme.palette.uiPrimary
              : theme.palette.additional2,
          }}
          minimumValue={0}
          step={1}
          maximumValue={
            props.fees && props.fees.length === 1
              ? 1
              : props.fees && props.fees.length - 1
          }
        />
      </View>
      <MinerFeeRangeContainer>
        {props.fees?.map(fee => <Point key={fee}>{fee}</Point>)}
      </MinerFeeRangeContainer>
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
}));

const MinerFeeRangeContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 5,
  },
}));

const Point = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 10,
    lineHeight: 12,
    color: theme.palette.info,
  },
}));

const useSliderStyles = () =>
  useStyles(theme => ({
    thumb: {
      borderRadius: 50,
      borderWidth: 2,
      borderColor: theme.palette.uiPrimary,
      height: 15,
      width: 15,
      shadowColor: theme.palette.blackout,
      shadowOffset: {
        width: 3,
        height: 4,
      },
      shadowOpacity: 0.3,
      shadowRadius: 3.2,
      elevation: 3,
    },
    track: {
      borderRadius: 50,
      height: 10,
    },
  }));
