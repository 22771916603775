import {urlLinks, useRoot} from '@ncwallet-app/core';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {useHeaderHeight} from '@react-navigation/elements';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {EXTRA_BOTTOM_OFFSET} from '../../../screens/constants';
import PromptCommentToSendScreen from '../../../screens/PromptCommentToSendScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptCommentToSendBindingProps =
  SmallHomeStackBindingProps<'PromptCommentToSend'>;

export default observer(function PromptCommentToSendBinding(
  props: PromptCommentToSendBindingProps,
) {
  const comment = props.route.params.comment ?? '';
  const root = useRoot();
  const handleCommentChange = useCallback(
    (v: string) => {
      props.navigation.setParams({comment: v});
    },
    [props.navigation],
  );

  const handleSubmit = useCallback(() => {
    props.navigation.replace(
      'PromptConfirmationToSendCrypto',
      props.route.params,
    );
  }, [props.navigation, props.route.params]);
  const goToFAQ = useCallback(() => {
    void root.location.open(urlLinks.internalComment);
  }, [root.location]);

  const header = useHeaderHeight();
  return (
    <PromptCommentToSendScreen
      comment={comment}
      onCommentChange={handleCommentChange}
      onSubmit={handleSubmit}
      keyboardAvoiding
      keyboardVerticalOffset={header}
      insets={SafeAreaInset.BOTTOM}
      extra={EXTRA}
      goToFAQ={goToFAQ}
    />
  );
});

const EXTRA = {bottom: EXTRA_BOTTOM_OFFSET};
