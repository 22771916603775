import type {
  Either,
  ErrorRepository,
  GlobalError,
  Location,
  SpecialLocation,
  Url,
} from '@ncwallet-app/core';
import {EXTERNAL, HeadlessLocationImpl} from '@ncwallet-app/core';
import type {Navigation} from '@ncwallet-app/core/src/Navigation';

export default class WebLocationImpl
  extends HeadlessLocationImpl
  implements Location
{
  constructor(
    protected readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly specialLocation: SpecialLocation;
      readonly navigation: Navigation;
    },
  ) {
    super(_root);
  }

  async open(locator: Url): Promise<Either<void, GlobalError>> {
    const parsed = this._root.specialLocation.parse(locator);
    if (parsed.kind === EXTERNAL) {
      return super.open(locator);
    }

    return this._root.navigation.navigate(parsed.rest);
  }
}
