import type {Opaque} from '../Opaque';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const CONTEXT_TAG: unique symbol;

export type Bound<F extends (...args: never[]) => unknown, C = void> = Opaque<
  F,
  C,
  typeof CONTEXT_TAG
>;

function bind<F extends (this: C, ...args: never[]) => unknown, C>(
  f: F,
  context: C,
): Bound<(...args: Parameters<F>) => ReturnType<F>, C> {
  return f.bind(context) as Bound<(...args: Parameters<F>) => ReturnType<F>, C>;
}

export default bind;
